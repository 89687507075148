import { useState, useRef, useCallback, useEffect } from 'react';
import { isMobile } from '../utils/utils';

export const useCanvas = () => {
  // States
  const [stageSize, setStageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  const initialPosition = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2
  };
  
  const [position, setPosition] = useState(initialPosition);

  // Core refs
  const stageRef = useRef(null);
  const scaleRef = useRef(isMobile() ? .09 : .2);
  const positionRef = useRef(initialPosition);
  const animationFrameRef = useRef(null);
  const isAnimatingRef = useRef(false);

  // Animation refs
  const initialAnimationRef = useRef(null);

  // Sync position state with ref on mount
  useEffect(() => {
    positionRef.current = position;
  }, []);

  // Core canvas update functions
  const updatePositionRefOnly = useCallback((newPos) => {
    positionRef.current = newPos;
  }, []);

  const updatePositionState = useCallback((newPos) => {
    setPosition(newPos);
  }, []);

  const syncPositionState = useCallback(() => {
    setPosition(positionRef.current);
  }, []);

  const setScale = useCallback((newScale) => {
    scaleRef.current = newScale;
  }, []);

  // Stage update utilities
  const updateStage = useCallback(() => {
    if (stageRef.current) {
      stageRef.current.scale({ x: scaleRef.current, y: scaleRef.current });
      stageRef.current.position(positionRef.current);
      stageRef.current.batchDraw();
    }
    animationFrameRef.current = null;
  }, []);

  // Window resize handler
  const handleResize = useCallback(() => {
    if (stageRef.current) {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      setStageSize({ width: newWidth, height: newHeight });
      stageRef.current.width(newWidth);
      stageRef.current.height(newHeight);
      stageRef.current.batchDraw();
    }
  }, []);

  // Cleanup function
  const cleanup = useCallback(() => {
    if (initialAnimationRef.current) {
      cancelAnimationFrame(initialAnimationRef.current);
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
  }, []);

  return {
    // Refs
    stageRef,
    scaleRef,
    isAnimatingRef,
    positionRef,

    // State
    stageSize,
    position,

    // Functions
    setScale,
    updatePositionRefOnly,
    updatePositionState,
    syncPositionState,
    updateStage,
    handleResize,
    cleanup,

    // Stage props
    stageProps: {
      width: stageSize.width,
      height: stageSize.height,
      x: position.x,
      y: position.y,
      scaleX: scaleRef.current,
      scaleY: scaleRef.current,
      draggable: !isAnimatingRef.current
    }
  };
};