import React from 'react';
import { Circle, FastLayer, Text, Group } from 'react-konva';

const DistanceCircles = React.memo(({ pixelsPerMile }) => {
  const cardinalPoints = [
    { angle: 0, label: 'E' },    // East
    { angle: 90, label: 'N' },   // North
    { angle: 180, label: 'W' },  // West
    { angle: 270, label: 'S' }   // South
  ];

  return (
    <FastLayer>
      {[1, 2, 3, 4, 5].map((multiplier) => {
        const radius = pixelsPerMile * multiplier;
        
        return (
          <Group key={multiplier}>
            <Circle
              x={0}
              y={0}
              radius={radius}
              stroke="rgba(0, 0, 0, 0.3)"
              strokeWidth={4}
              perfectDrawEnabled={false}
              shadowForStrokeEnabled={false}
              transformsEnabled="position"
            />
            {cardinalPoints.map(({ angle, label }) => (
              <Text
                key={`${multiplier}-${label}`}
                x={radius * Math.cos(angle * Math.PI / 180)}
                y={radius * Math.sin(angle * Math.PI / 180)}
                text={`${multiplier} mile${multiplier > 1 ? 's' : ''}`}
                fontSize={24}
                fill="rgba(0, 0, 0, 0.5)"
                align="center"
                verticalAlign="middle"
                rotation={angle + 90}
                offsetX={25}
                perfectDrawEnabled={false}
                transformsEnabled="position"
              />
            ))}
          </Group>
        );
      })}
    </FastLayer>
  );
});

export default DistanceCircles; 