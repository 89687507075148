const ZipcodeToCoordinates = [
	{latitude: 41.882130, longitude: -87.646859, zipCode: "ilovealina"},
	{latitude: 40.750649, longitude: -73.997298, zipCode: "10001"},
	{latitude: 40.715969, longitude: -73.986895, zipCode: "10002"},
	{latitude: 40.731865, longitude: -73.989124, zipCode: "10003"},
	{latitude: 40.689805, longitude: -74.017389, zipCode: "10004"},
	{latitude: 40.706215, longitude: -74.008687, zipCode: "10005"},
	{latitude: 40.70949, longitude: -74.013054, zipCode: "10006"},
	{latitude: 40.713868, longitude: -74.007866, zipCode: "10007"},
	{latitude: 40.726392, longitude: -73.978604, zipCode: "10009"},
	{latitude: 40.739045, longitude: -73.982616, zipCode: "10010"},
	{latitude: 40.74184, longitude: -74.000528, zipCode: "10011"},
	{latitude: 40.725625, longitude: -73.997936, zipCode: "10012"},
	{latitude: 40.720051, longitude: -74.004809, zipCode: "10013"},
	{latitude: 40.734145, longitude: -74.006026, zipCode: "10014"},
	{latitude: 40.745182, longitude: -73.978053, zipCode: "10016"},
	{latitude: 40.752387, longitude: -73.972678, zipCode: "10017"},
	{latitude: 40.755327, longitude: -73.992696, zipCode: "10018"},
	{latitude: 40.765735, longitude: -73.985065, zipCode: "10019"},
	{latitude: 40.759174, longitude: -73.980715, zipCode: "10020"},
	{latitude: 40.76964, longitude: -73.959018, zipCode: "10021"},
	{latitude: 40.75857, longitude: -73.96793, zipCode: "10022"},
	{latitude: 40.774567, longitude: -73.979016, zipCode: "10023"},
	{latitude: 40.78566, longitude: -73.971269, zipCode: "10024"},
	{latitude: 40.798251, longitude: -73.968336, zipCode: "10025"},
	{latitude: 40.802978, longitude: -73.953529, zipCode: "10026"},
	{latitude: 40.812657, longitude: -73.954983, zipCode: "10027"},
	{latitude: 40.776447, longitude: -73.953383, zipCode: "10028"},
	{latitude: 40.792249, longitude: -73.947333, zipCode: "10029"},
	{latitude: 40.818247, longitude: -73.942834, zipCode: "10030"},
	{latitude: 40.824804, longitude: -73.950206, zipCode: "10031"},
	{latitude: 40.839041, longitude: -73.942812, zipCode: "10032"},
	{latitude: 40.847476, longitude: -73.93551, zipCode: "10033"},
	{latitude: 40.867076, longitude: -73.924312, zipCode: "10034"},
	{latitude: 40.795251, longitude: -73.930418, zipCode: "10035"},
	{latitude: 40.759792, longitude: -73.990685, zipCode: "10036"},
	{latitude: 40.813027, longitude: -73.937714, zipCode: "10037"},
	{latitude: 40.709264, longitude: -74.002903, zipCode: "10038"},
	{latitude: 40.826287, longitude: -73.937526, zipCode: "10039"},
	{latitude: 40.858097, longitude: -73.9296, zipCode: "10040"},
	{latitude: 40.761907, longitude: -73.949967, zipCode: "10044"},
	{latitude: 40.764689, longitude: -73.963264, zipCode: "10065"},
	{latitude: 40.777382, longitude: -73.98883, zipCode: "10069"},
	{latitude: 40.773413, longitude: -73.956291, zipCode: "10075"},
	{latitude: 40.76078, longitude: -73.97767, zipCode: "10103"},
	{latitude: 40.754499, longitude: -73.982256, zipCode: "10110"},
	{latitude: 40.759531, longitude: -73.978585, zipCode: "10111"},
	{latitude: 40.758904, longitude: -73.979044, zipCode: "10112"},
	{latitude: 40.810852, longitude: -73.963744, zipCode: "10115"},
	{latitude: 40.750644, longitude: -73.992733, zipCode: "10119"},
	{latitude: 40.781498, longitude: -73.950199, zipCode: "10128"},
	{latitude: 40.758404, longitude: -73.972031, zipCode: "10152"},
	{latitude: 40.763622, longitude: -73.972439, zipCode: "10153"},
	{latitude: 40.757779, longitude: -73.972487, zipCode: "10154"},
	{latitude: 40.769267, longitude: -73.949795, zipCode: "10162"},
	{latitude: 40.752131, longitude: -73.978722, zipCode: "10165"},
	{latitude: 40.754648, longitude: -73.974771, zipCode: "10167"},
	{latitude: 40.751448, longitude: -73.977103, zipCode: "10168"},
	{latitude: 40.754515, longitude: -73.976393, zipCode: "10169"},
	{latitude: 40.752625, longitude: -73.975877, zipCode: "10170"},
	{latitude: 40.755899, longitude: -73.973858, zipCode: "10171"},
	{latitude: 40.755273, longitude: -73.974315, zipCode: "10172"},
	{latitude: 40.754131, longitude: -73.979364, zipCode: "10173"},
	{latitude: 40.751441, longitude: -73.975003, zipCode: "10174"},
	{latitude: 40.755139, longitude: -73.975934, zipCode: "10177"},
	{latitude: 40.751383, longitude: -73.997152, zipCode: "10199"},
	{latitude: 40.708236, longitude: -74.010541, zipCode: "10271"},
	{latitude: 40.715138, longitude: -74.003717, zipCode: "10278"},
	{latitude: 40.712626, longitude: -74.008669, zipCode: "10279"},
	{latitude: 40.709705, longitude: -74.016586, zipCode: "10280"},
	{latitude: 40.716381, longitude: -74.015004, zipCode: "10282"},
	{latitude: 40.627505, longitude: -74.094377, zipCode: "10301"},
	{latitude: 40.630806, longitude: -74.137664, zipCode: "10302"},
	{latitude: 40.629885, longitude: -74.17413, zipCode: "10303"},
	{latitude: 40.605965, longitude: -74.093535, zipCode: "10304"},
	{latitude: 40.596549, longitude: -74.075779, zipCode: "10305"},
	{latitude: 40.571714, longitude: -74.124264, zipCode: "10306"},
	{latitude: 40.507753, longitude: -74.242091, zipCode: "10307"},
	{latitude: 40.552828, longitude: -74.151525, zipCode: "10308"},
	{latitude: 40.529842, longitude: -74.219777, zipCode: "10309"},
	{latitude: 40.632612, longitude: -74.116148, zipCode: "10310"},
	{latitude: 40.605231, longitude: -74.179534, zipCode: "10311"},
	{latitude: 40.545237, longitude: -74.180443, zipCode: "10312"},
	{latitude: 40.600699, longitude: -74.164314, zipCode: "10314"},
	{latitude: 40.822168, longitude: -73.922616, zipCode: "10451"},
	{latitude: 40.837791, longitude: -73.923339, zipCode: "10452"},
	{latitude: 40.852508, longitude: -73.913291, zipCode: "10453"},
	{latitude: 40.805583, longitude: -73.916595, zipCode: "10454"},
	{latitude: 40.814798, longitude: -73.908601, zipCode: "10455"},
	{latitude: 40.830669, longitude: -73.908161, zipCode: "10456"},
	{latitude: 40.845707, longitude: -73.897923, zipCode: "10457"},
	{latitude: 40.862486, longitude: -73.886448, zipCode: "10458"},
	{latitude: 40.825692, longitude: -73.892998, zipCode: "10459"},
	{latitude: 40.842344, longitude: -73.879062, zipCode: "10460"},
	{latitude: 40.846741, longitude: -73.840788, zipCode: "10461"},
	{latitude: 40.843267, longitude: -73.860417, zipCode: "10462"},
	{latitude: 40.886345, longitude: -73.901547, zipCode: "10463"},
	{latitude: 40.869594, longitude: -73.795848, zipCode: "10464"},
	{latitude: 40.827329, longitude: -73.82217, zipCode: "10465"},
	{latitude: 40.890964, longitude: -73.846239, zipCode: "10466"},
	{latitude: 40.875262, longitude: -73.872027, zipCode: "10467"},
	{latitude: 40.86894, longitude: -73.899995, zipCode: "10468"},
	{latitude: 40.868509, longitude: -73.846691, zipCode: "10469"},
	{latitude: 40.889527, longitude: -73.872585, zipCode: "10470"},
	{latitude: 40.899984, longitude: -73.906751, zipCode: "10471"},
	{latitude: 40.829685, longitude: -73.869373, zipCode: "10472"},
	{latitude: 40.81869, longitude: -73.858474, zipCode: "10473"},
	{latitude: 40.81134, longitude: -73.885173, zipCode: "10474"},
	{latitude: 40.875635, longitude: -73.826934, zipCode: "10475"},
	{latitude: 41.295591, longitude: -73.759425, zipCode: "10501"},
	{latitude: 41.011282, longitude: -73.840846, zipCode: "10502"},
	{latitude: 41.026556, longitude: -73.87531, zipCode: "10503"},
	{latitude: 41.128468, longitude: -73.707521, zipCode: "10504"},
	{latitude: 41.342072, longitude: -73.747803, zipCode: "10505"},
	{latitude: 41.189652, longitude: -73.638509, zipCode: "10506"},
	{latitude: 41.232188, longitude: -73.691689, zipCode: "10507"},
	{latitude: 41.410855, longitude: -73.59434, zipCode: "10509"},
	{latitude: 41.139472, longitude: -73.835704, zipCode: "10510"},
	{latitude: 41.263168, longitude: -73.944966, zipCode: "10511"},
	{latitude: 41.447747, longitude: -73.709884, zipCode: "10512"},
	{latitude: 41.172119, longitude: -73.769217, zipCode: "10514"},
	{latitude: 41.461973, longitude: -73.874913, zipCode: "10516"},
	{latitude: 41.298466, longitude: -73.86014, zipCode: "10517"},
	{latitude: 41.266143, longitude: -73.588296, zipCode: "10518"},
	{latitude: 41.347163, longitude: -73.663362, zipCode: "10519"},
	{latitude: 41.226601, longitude: -73.867641, zipCode: "10520"},
	{latitude: 41.009696, longitude: -73.863304, zipCode: "10522"},
	{latitude: 41.059498, longitude: -73.819254, zipCode: "10523"},
	{latitude: 41.375325, longitude: -73.926217, zipCode: "10524"},
	{latitude: 41.294095, longitude: -73.6594, zipCode: "10526"},
	{latitude: 41.321169, longitude: -73.76994, zipCode: "10527"},
	{latitude: 40.975047, longitude: -73.724926, zipCode: "10528"},
	{latitude: 41.023712, longitude: -73.81281, zipCode: "10530"},
	{latitude: 41.100261, longitude: -73.80073, zipCode: "10532"},
	{latitude: 41.036927, longitude: -73.854864, zipCode: "10533"},
	{latitude: 41.335169, longitude: -73.793919, zipCode: "10535"},
	{latitude: 41.269182, longitude: -73.688726, zipCode: "10536"},
	{latitude: 41.344088, longitude: -73.881002, zipCode: "10537"},
	{latitude: 40.937752, longitude: -73.756473, zipCode: "10538"},
	{latitude: 41.325982, longitude: -73.727712, zipCode: "10540"},
	{latitude: 41.382046, longitude: -73.751818, zipCode: "10541"},
	{latitude: 41.371147, longitude: -73.764367, zipCode: "10542"},
	{latitude: 40.952693, longitude: -73.73604, zipCode: "10543"},
	{latitude: 41.178573, longitude: -73.835465, zipCode: "10545"},
	{latitude: 41.196605, longitude: -73.800569, zipCode: "10546"},
	{latitude: 41.313334, longitude: -73.846039, zipCode: "10547"},
	{latitude: 41.244987, longitude: -73.938738, zipCode: "10548"},
	{latitude: 41.200584, longitude: -73.723498, zipCode: "10549"},
	{latitude: 40.905448, longitude: -73.835253, zipCode: "10550"},
	{latitude: 40.923972, longitude: -73.825555, zipCode: "10552"},
	{latitude: 40.908607, longitude: -73.821566, zipCode: "10553"},
	{latitude: 41.340527, longitude: -73.597609, zipCode: "10560"},
	{latitude: 41.194423, longitude: -73.825285, zipCode: "10562"},
	{latitude: 41.289483, longitude: -73.916847, zipCode: "10566"},
	{latitude: 41.288405, longitude: -73.895008, zipCode: "10567"},
	{latitude: 41.128068, longitude: -73.78922, zipCode: "10570"},
	{latitude: 41.015742, longitude: -73.677303, zipCode: "10573"},
	{latitude: 41.221577, longitude: -73.572332, zipCode: "10576"},
	{latitude: 41.038225, longitude: -73.710736, zipCode: "10577"},
	{latitude: 41.314083, longitude: -73.677051, zipCode: "10578"},
	{latitude: 41.395364, longitude: -73.839053, zipCode: "10579"},
	{latitude: 40.979049, longitude: -73.693202, zipCode: "10580"},
	{latitude: 40.988647, longitude: -73.788879, zipCode: "10583"},
	{latitude: 41.331483, longitude: -73.828476, zipCode: "10588"},
	{latitude: 41.332433, longitude: -73.692669, zipCode: "10589"},
	{latitude: 41.256234, longitude: -73.53786, zipCode: "10590"},
	{latitude: 41.082659, longitude: -73.848578, zipCode: "10591"},
	{latitude: 41.115794, longitude: -73.770977, zipCode: "10594"},
	{latitude: 41.087378, longitude: -73.780369, zipCode: "10595"},
	{latitude: 41.255981, longitude: -73.959468, zipCode: "10596"},
	{latitude: 41.293021, longitude: -73.596568, zipCode: "10597"},
	{latitude: 41.282372, longitude: -73.796206, zipCode: "10598"},
	{latitude: 41.03289, longitude: -73.764783, zipCode: "10601"},
	{latitude: 41.054219, longitude: -73.780227, zipCode: "10603"},
	{latitude: 41.071512, longitude: -73.747093, zipCode: "10604"},
	{latitude: 41.010566, longitude: -73.745129, zipCode: "10605"},
	{latitude: 41.020695, longitude: -73.775877, zipCode: "10606"},
	{latitude: 41.039089, longitude: -73.811368, zipCode: "10607"},
	{latitude: 40.946966, longitude: -73.88042, zipCode: "10701"},
	{latitude: 40.957649, longitude: -73.881135, zipCode: "10703"},
	{latitude: 40.919729, longitude: -73.862651, zipCode: "10704"},
	{latitude: 40.919339, longitude: -73.889335, zipCode: "10705"},
	{latitude: 40.990503, longitude: -73.869502, zipCode: "10706"},
	{latitude: 40.959633, longitude: -73.82294, zipCode: "10707"},
	{latitude: 40.938267, longitude: -73.829922, zipCode: "10708"},
	{latitude: 40.954635, longitude: -73.808184, zipCode: "10709"},
	{latitude: 40.969635, longitude: -73.845691, zipCode: "10710"},
	{latitude: 40.91757, longitude: -73.784858, zipCode: "10801"},
	{latitude: 40.899532, longitude: -73.805883, zipCode: "10803"},
	{latitude: 40.946859, longitude: -73.788035, zipCode: "10804"},
	{latitude: 40.88637, longitude: -73.757674, zipCode: "10805"},
	{latitude: 41.155398, longitude: -74.129008, zipCode: "10901"},
	{latitude: 41.28157, longitude: -74.137957, zipCode: "10910"},
	{latitude: 41.308426, longitude: -73.995965, zipCode: "10911"},
	{latitude: 41.068872, longitude: -73.956016, zipCode: "10913"},
	{latitude: 41.417717, longitude: -74.200026, zipCode: "10914"},
	{latitude: 41.54018, longitude: -74.356996, zipCode: "10915"},
	{latitude: 41.449335, longitude: -74.244749, zipCode: "10916"},
	{latitude: 41.321824, longitude: -74.108011, zipCode: "10917"},
	{latitude: 41.344192, longitude: -74.262177, zipCode: "10918"},
	{latitude: 41.525959, longitude: -74.387618, zipCode: "10919"},
	{latitude: 41.156495, longitude: -73.938239, zipCode: "10920"},
	{latitude: 41.332065, longitude: -74.363422, zipCode: "10921"},
	{latitude: 41.336897, longitude: -73.987572, zipCode: "10922"},
	{latitude: 41.203116, longitude: -74.002718, zipCode: "10923"},
	{latitude: 41.381156, longitude: -74.352485, zipCode: "10924"},
	{latitude: 41.185855, longitude: -74.288878, zipCode: "10925"},
	{latitude: 41.255799, longitude: -74.127856, zipCode: "10926"},
	{latitude: 41.191052, longitude: -73.967562, zipCode: "10927"},
	{latitude: 41.34897, longitude: -73.998495, zipCode: "10928"},
	{latitude: 41.382395, longitude: -74.114404, zipCode: "10930"},
	{latitude: 41.127177, longitude: -74.170503, zipCode: "10931"},
	{latitude: 41.48216, longitude: -74.464285, zipCode: "10932"},
	{latitude: 41.368021, longitude: -74.512999, zipCode: "10933"},
	{latitude: 41.446544, longitude: -74.478741, zipCode: "10940"},
	{latitude: 41.490413, longitude: -74.345272, zipCode: "10941"},
	{latitude: 41.317346, longitude: -74.199881, zipCode: "10950"},
	{latitude: 41.111118, longitude: -74.07854, zipCode: "10952"},
	{latitude: 41.405855, longitude: -74.077917, zipCode: "10953"},
	{latitude: 41.099198, longitude: -74.013021, zipCode: "10954"},
	{latitude: 41.154436, longitude: -73.994072, zipCode: "10956"},
	{latitude: 41.379275, longitude: -74.423769, zipCode: "10958"},
	{latitude: 41.088841, longitude: -73.926471, zipCode: "10960"},
	{latitude: 41.049007, longitude: -73.962103, zipCode: "10962"},
	{latitude: 41.466587, longitude: -74.540707, zipCode: "10963"},
	{latitude: 41.016357, longitude: -73.914268, zipCode: "10964"},
	{latitude: 41.061561, longitude: -74.007832, zipCode: "10965"},
	{latitude: 41.037017, longitude: -73.921259, zipCode: "10968"},
	{latitude: 41.294858, longitude: -74.488006, zipCode: "10969"},
	{latitude: 41.18436, longitude: -74.062727, zipCode: "10970"},
	{latitude: 41.380516, longitude: -74.480462, zipCode: "10973"},
	{latitude: 41.159484, longitude: -74.189903, zipCode: "10974"},
	{latitude: 41.257008, longitude: -74.167732, zipCode: "10975"},
	{latitude: 41.02296, longitude: -73.928488, zipCode: "10976"},
	{latitude: 41.118914, longitude: -74.048154, zipCode: "10977"},
	{latitude: 41.18237, longitude: -74.318814, zipCode: "10979"},
	{latitude: 41.239234, longitude: -74.056261, zipCode: "10980"},
	{latitude: 41.031088, longitude: -73.947703, zipCode: "10983"},
	{latitude: 41.203123, longitude: -74.022156, zipCode: "10984"},
	{latitude: 41.577032, longitude: -74.358035, zipCode: "10985"},
	{latitude: 41.285209, longitude: -73.998672, zipCode: "10986"},
	{latitude: 41.199212, longitude: -74.224545, zipCode: "10987"},
	{latitude: 41.301494, longitude: -74.562447, zipCode: "10988"},
	{latitude: 41.122955, longitude: -73.938031, zipCode: "10989"},
	{latitude: 41.267448, longitude: -74.364011, zipCode: "10990"},
	{latitude: 41.426089, longitude: -74.164664, zipCode: "10992"},
	{latitude: 41.209904, longitude: -73.973872, zipCode: "10993"},
	{latitude: 41.098072, longitude: -73.972649, zipCode: "10994"},
	{latitude: 41.3937, longitude: -73.972175, zipCode: "10996"},
	{latitude: 41.324076, longitude: -74.541179, zipCode: "10998"},
	{latitude: 40.723317, longitude: -73.704949, zipCode: "11001"},
	{latitude: 40.699176, longitude: -73.706166, zipCode: "11003"},
	{latitude: 40.746191, longitude: -73.711507, zipCode: "11004"},
	{latitude: 40.756643, longitude: -73.71424, zipCode: "11005"},
	{latitude: 40.700653, longitude: -73.675133, zipCode: "11010"},
	{latitude: 40.771442, longitude: -73.714819, zipCode: "11020"},
	{latitude: 40.784319, longitude: -73.731488, zipCode: "11021"},
	{latitude: 40.798909, longitude: -73.733653, zipCode: "11023"},
	{latitude: 40.816251, longitude: -73.742872, zipCode: "11024"},
	{latitude: 40.793409, longitude: -73.688549, zipCode: "11030"},
	{latitude: 40.745347, longitude: -73.680292, zipCode: "11040"},
	{latitude: 40.758542, longitude: -73.697451, zipCode: "11042"},
	{latitude: 40.839895, longitude: -73.693123, zipCode: "11050"},
	{latitude: 40.621346, longitude: -73.75699, zipCode: "11096"},
	{latitude: 40.747113, longitude: -73.939708, zipCode: "11101"},
	{latitude: 40.7716, longitude: -73.925603, zipCode: "11102"},
	{latitude: 40.762596, longitude: -73.913282, zipCode: "11103"},
	{latitude: 40.744845, longitude: -73.920468, zipCode: "11104"},
	{latitude: 40.779087, longitude: -73.906522, zipCode: "11105"},
	{latitude: 40.762211, longitude: -73.931528, zipCode: "11106"},
	{latitude: 40.746616, longitude: -73.957009, zipCode: "11109"},
	{latitude: 40.6937, longitude: -73.989859, zipCode: "11201"},
	{latitude: 40.649569, longitude: -73.934327, zipCode: "11203"},
	{latitude: 40.618815, longitude: -73.984942, zipCode: "11204"},
	{latitude: 40.696891, longitude: -73.968074, zipCode: "11205"},
	{latitude: 40.70187, longitude: -73.942552, zipCode: "11206"},
	{latitude: 40.670157, longitude: -73.893835, zipCode: "11207"},
	{latitude: 40.674847, longitude: -73.87248, zipCode: "11208"},
	{latitude: 40.621803, longitude: -74.03015, zipCode: "11209"},
	{latitude: 40.62806, longitude: -73.946309, zipCode: "11210"},
	{latitude: 40.714084, longitude: -73.945168, zipCode: "11211"},
	{latitude: 40.662905, longitude: -73.913071, zipCode: "11212"},
	{latitude: 40.671155, longitude: -73.936266, zipCode: "11213"},
	{latitude: 40.59969, longitude: -73.996372, zipCode: "11214"},
	{latitude: 40.662688, longitude: -73.98674, zipCode: "11215"},
	{latitude: 40.680844, longitude: -73.949222, zipCode: "11216"},
	{latitude: 40.681671, longitude: -73.978529, zipCode: "11217"},
	{latitude: 40.64312, longitude: -73.976446, zipCode: "11218"},
	{latitude: 40.63262, longitude: -73.99677, zipCode: "11219"},
	{latitude: 40.641026, longitude: -74.016688, zipCode: "11220"},
	{latitude: 40.691426, longitude: -73.927626, zipCode: "11221"},
	{latitude: 40.72779, longitude: -73.947605, zipCode: "11222"},
	{latitude: 40.597091, longitude: -73.973469, zipCode: "11223"},
	{latitude: 40.577372, longitude: -73.988706, zipCode: "11224"},
	{latitude: 40.663119, longitude: -73.953867, zipCode: "11225"},
	{latitude: 40.646409, longitude: -73.956663, zipCode: "11226"},
	{latitude: 40.616725, longitude: -74.013432, zipCode: "11228"},
	{latitude: 40.601293, longitude: -73.944493, zipCode: "11229"},
	{latitude: 40.622089, longitude: -73.96519, zipCode: "11230"},
	{latitude: 40.677916, longitude: -74.005154, zipCode: "11231"},
	{latitude: 40.656541, longitude: -74.005182, zipCode: "11232"},
	{latitude: 40.678301, longitude: -73.919932, zipCode: "11233"},
	{latitude: 40.607723, longitude: -73.910649, zipCode: "11234"},
	{latitude: 40.58422, longitude: -73.943167, zipCode: "11235"},
	{latitude: 40.639413, longitude: -73.900664, zipCode: "11236"},
	{latitude: 40.70416, longitude: -73.921139, zipCode: "11237"},
	{latitude: 40.671331, longitude: -73.965937, zipCode: "11238"},
	{latitude: 40.64689, longitude: -73.864014, zipCode: "11239"},
	{latitude: 40.704501, longitude: -73.966002, zipCode: "11249"},
	{latitude: 40.768213, longitude: -73.827392, zipCode: "11354"},
	{latitude: 40.751435, longitude: -73.821227, zipCode: "11355"},
	{latitude: 40.784871, longitude: -73.841528, zipCode: "11356"},
	{latitude: 40.785129, longitude: -73.810037, zipCode: "11357"},
	{latitude: 40.760363, longitude: -73.796525, zipCode: "11358"},
	{latitude: 40.791605, longitude: -73.776724, zipCode: "11359"},
	{latitude: 40.780419, longitude: -73.781838, zipCode: "11360"},
	{latitude: 40.764191, longitude: -73.772775, zipCode: "11361"},
	{latitude: 40.760204, longitude: -73.732417, zipCode: "11362"},
	{latitude: 40.772348, longitude: -73.746292, zipCode: "11363"},
	{latitude: 40.746251, longitude: -73.756062, zipCode: "11364"},
	{latitude: 40.739634, longitude: -73.79449, zipCode: "11365"},
	{latitude: 40.731121, longitude: -73.780458, zipCode: "11366"},
	{latitude: 40.730474, longitude: -73.822749, zipCode: "11367"},
	{latitude: 40.749737, longitude: -73.853045, zipCode: "11368"},
	{latitude: 40.763491, longitude: -73.87222, zipCode: "11369"},
	{latitude: 40.765393, longitude: -73.894216, zipCode: "11370"},
	{latitude: 40.774411, longitude: -73.873272, zipCode: "11371"},
	{latitude: 40.751719, longitude: -73.88367, zipCode: "11372"},
	{latitude: 40.738945, longitude: -73.878561, zipCode: "11373"},
	{latitude: 40.726457, longitude: -73.861459, zipCode: "11374"},
	{latitude: 40.722975, longitude: -73.843179, zipCode: "11375"},
	{latitude: 40.744775, longitude: -73.90516, zipCode: "11377"},
	{latitude: 40.724744, longitude: -73.909639, zipCode: "11378"},
	{latitude: 40.717061, longitude: -73.880519, zipCode: "11379"},
	{latitude: 40.700647, longitude: -73.889423, zipCode: "11385"},
	{latitude: 40.694064, longitude: -73.736265, zipCode: "11411"},
	{latitude: 40.69802, longitude: -73.759081, zipCode: "11412"},
	{latitude: 40.67117, longitude: -73.752063, zipCode: "11413"},
	{latitude: 40.657604, longitude: -73.844804, zipCode: "11414"},
	{latitude: 40.708085, longitude: -73.828044, zipCode: "11415"},
	{latitude: 40.684586, longitude: -73.849713, zipCode: "11416"},
	{latitude: 40.676406, longitude: -73.844595, zipCode: "11417"},
	{latitude: 40.700315, longitude: -73.836019, zipCode: "11418"},
	{latitude: 40.688612, longitude: -73.823062, zipCode: "11419"},
	{latitude: 40.673502, longitude: -73.817702, zipCode: "11420"},
	{latitude: 40.694035, longitude: -73.858659, zipCode: "11421"},
	{latitude: 40.660787, longitude: -73.73757, zipCode: "11422"},
	{latitude: 40.715669, longitude: -73.768299, zipCode: "11423"},
	{latitude: 40.713968, longitude: -73.828087, zipCode: "11424"},
	{latitude: 40.736407, longitude: -73.722357, zipCode: "11426"},
	{latitude: 40.730942, longitude: -73.745716, zipCode: "11427"},
	{latitude: 40.720993, longitude: -73.742203, zipCode: "11428"},
	{latitude: 40.709556, longitude: -73.73848, zipCode: "11429"},
	{latitude: 40.646965, longitude: -73.787295, zipCode: "11430"},
	{latitude: 40.714934, longitude: -73.792955, zipCode: "11432"},
	{latitude: 40.69809, longitude: -73.786752, zipCode: "11433"},
	{latitude: 40.677857, longitude: -73.777073, zipCode: "11434"},
	{latitude: 40.70108, longitude: -73.80964, zipCode: "11435"},
	{latitude: 40.675864, longitude: -73.796647, zipCode: "11436"},
	{latitude: 40.721935, longitude: -73.794587, zipCode: "11439"},
	{latitude: 40.701188, longitude: -73.795778, zipCode: "11451"},
	{latitude: 40.74633, longitude: -73.639178, zipCode: "11501"},
	{latitude: 40.770829, longitude: -73.652103, zipCode: "11507"},
	{latitude: 40.587963, longitude: -73.728528, zipCode: "11509"},
	{latitude: 40.650127, longitude: -73.607709, zipCode: "11510"},
	{latitude: 40.749778, longitude: -73.61291, zipCode: "11514"},
	{latitude: 40.626223, longitude: -73.726779, zipCode: "11516"},
	{latitude: 40.637472, longitude: -73.666807, zipCode: "11518"},
	{latitude: 40.649401, longitude: -73.582951, zipCode: "11520"},
	{latitude: 40.726854, longitude: -73.637009, zipCode: "11530"},
	{latitude: 40.872605, longitude: -73.628622, zipCode: "11542"},
	{latitude: 40.826321, longitude: -73.589365, zipCode: "11545"},
	{latitude: 40.829066, longitude: -73.644718, zipCode: "11547"},
	{latitude: 40.811078, longitude: -73.613879, zipCode: "11548"},
	{latitude: 40.719009, longitude: -73.599677, zipCode: "11549"},
	{latitude: 40.701475, longitude: -73.621108, zipCode: "11550"},
	{latitude: 40.691752, longitude: -73.654204, zipCode: "11552"},
	{latitude: 40.705272, longitude: -73.592328, zipCode: "11553"},
	{latitude: 40.720115, longitude: -73.558861, zipCode: "11554"},
	{latitude: 40.719939, longitude: -73.583402, zipCode: "11556"},
	{latitude: 40.636083, longitude: -73.693921, zipCode: "11557"},
	{latitude: 40.605357, longitude: -73.649046, zipCode: "11558"},
	{latitude: 40.602924, longitude: -73.714434, zipCode: "11559"},
	{latitude: 40.880757, longitude: -73.588724, zipCode: "11560"},
	{latitude: 40.590904, longitude: -73.644668, zipCode: "11561"},
	{latitude: 40.657434, longitude: -73.674104, zipCode: "11563"},
	{latitude: 40.67502, longitude: -73.671722, zipCode: "11565"},
	{latitude: 40.662291, longitude: -73.556443, zipCode: "11566"},
	{latitude: 40.786614, longitude: -73.597169, zipCode: "11568"},
	{latitude: 40.590374, longitude: -73.580415, zipCode: "11569"},
	{latitude: 40.665341, longitude: -73.638921, zipCode: "11570"},
	{latitude: 40.631772, longitude: -73.636624, zipCode: "11572"},
	{latitude: 40.680422, longitude: -73.584877, zipCode: "11575"},
	{latitude: 40.796569, longitude: -73.646885, zipCode: "11576"},
	{latitude: 40.782867, longitude: -73.639109, zipCode: "11577"},
	{latitude: 40.844018, longitude: -73.644089, zipCode: "11579"},
	{latitude: 40.6749, longitude: -73.702154, zipCode: "11580"},
	{latitude: 40.651028, longitude: -73.715325, zipCode: "11581"},
	{latitude: 40.755182, longitude: -73.574338, zipCode: "11590"},
	{latitude: 40.759718, longitude: -73.64222, zipCode: "11596"},
	{latitude: 40.630935, longitude: -73.712339, zipCode: "11598"},
	{latitude: 40.602096, longitude: -73.761794, zipCode: "11691"},
	{latitude: 40.594154, longitude: -73.791986, zipCode: "11692"},
	{latitude: 40.591662, longitude: -73.809668, zipCode: "11693"},
	{latitude: 40.575314, longitude: -73.851235, zipCode: "11694"},
	{latitude: 40.557132, longitude: -73.913722, zipCode: "11697"},
	{latitude: 40.682451, longitude: -73.414382, zipCode: "11701"},
	{latitude: 40.636151, longitude: -73.383775, zipCode: "11702"},
	{latitude: 40.732676, longitude: -73.325003, zipCode: "11703"},
	{latitude: 40.715775, longitude: -73.359257, zipCode: "11704"},
	{latitude: 40.743264, longitude: -73.05585, zipCode: "11705"},
	{latitude: 40.710632, longitude: -73.242913, zipCode: "11706"},
	{latitude: 40.906313, longitude: -73.558244, zipCode: "11709"},
	{latitude: 40.672203, longitude: -73.534825, zipCode: "11710"},
	{latitude: 40.778396, longitude: -72.942761, zipCode: "11713"},
	{latitude: 40.742649, longitude: -73.486164, zipCode: "11714"},
	{latitude: 40.750968, longitude: -73.034924, zipCode: "11715"},
	{latitude: 40.777011, longitude: -73.13509, zipCode: "11716"},
	{latitude: 40.784072, longitude: -73.252235, zipCode: "11717"},
	{latitude: 40.716435, longitude: -73.261514, zipCode: "11718"},
	{latitude: 40.776671, longitude: -72.907833, zipCode: "11719"},
	{latitude: 40.87024, longitude: -73.082046, zipCode: "11720"},
	{latitude: 40.902425, longitude: -73.371427, zipCode: "11721"},
	{latitude: 40.78358, longitude: -73.196618, zipCode: "11722"},
	{latitude: 40.864322, longitude: -73.456406, zipCode: "11724"},
	{latitude: 40.840584, longitude: -73.280817, zipCode: "11725"},
	{latitude: 40.678993, longitude: -73.394445, zipCode: "11726"},
	{latitude: 40.880174, longitude: -73.003691, zipCode: "11727"},
	{latitude: 40.761136, longitude: -73.32526, zipCode: "11729"},
	{latitude: 40.727603, longitude: -73.18495, zipCode: "11730"},
	{latitude: 40.862614, longitude: -73.31704, zipCode: "11731"},
	{latitude: 40.842406, longitude: -73.539303, zipCode: "11732"},
	{latitude: 40.940235, longitude: -73.11278, zipCode: "11733"},
	{latitude: 40.732747, longitude: -73.432789, zipCode: "11735"},
	{latitude: 40.837767, longitude: -73.037682, zipCode: "11738"},
	{latitude: 40.710579, longitude: -73.165716, zipCode: "11739"},
	{latitude: 40.865347, longitude: -73.361205, zipCode: "11740"},
	{latitude: 40.794727, longitude: -73.070427, zipCode: "11741"},
	{latitude: 40.810354, longitude: -73.041875, zipCode: "11742"},
	{latitude: 40.883412, longitude: -73.423563, zipCode: "11743"},
	{latitude: 40.814268, longitude: -73.362276, zipCode: "11746"},
	{latitude: 40.783762, longitude: -73.409295, zipCode: "11747"},
	{latitude: 40.806766, longitude: -73.170802, zipCode: "11749"},
	{latitude: 40.730486, longitude: -73.213924, zipCode: "11751"},
	{latitude: 40.758346, longitude: -73.174234, zipCode: "11752"},
	{latitude: 40.789721, longitude: -73.541036, zipCode: "11753"},
	{latitude: 40.885566, longitude: -73.249901, zipCode: "11754"},
	{latitude: 40.857817, longitude: -73.116844, zipCode: "11755"},
	{latitude: 40.724512, longitude: -73.516326, zipCode: "11756"},
	{latitude: 40.689009, longitude: -73.37335, zipCode: "11757"},
	{latitude: 40.653822, longitude: -73.457076, zipCode: "11758"},
	{latitude: 40.682744, longitude: -73.446664, zipCode: "11762"},
	{latitude: 40.825275, longitude: -72.982436, zipCode: "11763"},
	{latitude: 40.92986, longitude: -72.973063, zipCode: "11764"},
	{latitude: 40.882132, longitude: -73.558968, zipCode: "11765"},
	{latitude: 40.939219, longitude: -73.018666, zipCode: "11766"},
	{latitude: 40.847754, longitude: -73.145703, zipCode: "11767"},
	{latitude: 40.923631, longitude: -73.339309, zipCode: "11768"},
	{latitude: 40.734504, longitude: -73.125222, zipCode: "11769"},
	{latitude: 40.649645, longitude: -73.140324, zipCode: "11770"},
	{latitude: 40.87373, longitude: -73.5253, zipCode: "11771"},
	{latitude: 40.76178, longitude: -72.987407, zipCode: "11772"},
	{latitude: 40.913456, longitude: -73.045674, zipCode: "11776"},
	{latitude: 40.956068, longitude: -73.066659, zipCode: "11777"},
	{latitude: 40.948046, longitude: -72.937093, zipCode: "11778"},
	{latitude: 40.812958, longitude: -73.11449, zipCode: "11779"},
	{latitude: 40.911113, longitude: -73.172509, zipCode: "11780"},
	{latitude: 40.736729, longitude: -73.079999, zipCode: "11782"},
	{latitude: 40.677612, longitude: -73.490014, zipCode: "11783"},
	{latitude: 40.868615, longitude: -73.042202, zipCode: "11784"},
	{latitude: 40.951398, longitude: -72.888417, zipCode: "11786"},
	{latitude: 40.852924, longitude: -73.21126, zipCode: "11787"},
	{latitude: 40.818085, longitude: -73.21319, zipCode: "11788"},
	{latitude: 40.962028, longitude: -72.971008, zipCode: "11789"},
	{latitude: 40.907804, longitude: -73.127479, zipCode: "11790"},
	{latitude: 40.827771, longitude: -73.502767, zipCode: "11791"},
	{latitude: 40.955033, longitude: -72.825569, zipCode: "11792"},
	{latitude: 40.647228, longitude: -73.51146, zipCode: "11793"},
	{latitude: 40.91919, longitude: -73.125015, zipCode: "11794"},
	{latitude: 40.709005, longitude: -73.296337, zipCode: "11795"},
	{latitude: 40.731247, longitude: -73.099419, zipCode: "11796"},
	{latitude: 40.820208, longitude: -73.47245, zipCode: "11797"},
	{latitude: 40.752632, longitude: -73.378372, zipCode: "11798"},
	{latitude: 40.762304, longitude: -73.524452, zipCode: "11801"},
	{latitude: 40.781771, longitude: -73.473118, zipCode: "11803"},
	{latitude: 40.758116, longitude: -73.456946, zipCode: "11804"},
	{latitude: 40.928102, longitude: -72.648249, zipCode: "11901"},
	{latitude: 40.991419, longitude: -72.114455, zipCode: "11930"},
	{latitude: 40.932628, longitude: -72.622259, zipCode: "11931"},
	{latitude: 40.9265, longitude: -72.305037, zipCode: "11932"},
	{latitude: 40.942231, longitude: -72.765594, zipCode: "11933"},
	{latitude: 40.802046, longitude: -72.795166, zipCode: "11934"},
	{latitude: 41.021223, longitude: -72.486958, zipCode: "11935"},
	{latitude: 40.994548, longitude: -72.198754, zipCode: "11937"},
	{latitude: 41.138956, longitude: -72.318687, zipCode: "11939"},
	{latitude: 40.808772, longitude: -72.756601, zipCode: "11940"},
	{latitude: 40.829543, longitude: -72.723395, zipCode: "11941"},
	{latitude: 40.848578, longitude: -72.572159, zipCode: "11942"},
	{latitude: 41.102475, longitude: -72.373228, zipCode: "11944"},
	{latitude: 40.864652, longitude: -72.518104, zipCode: "11946"},
	{latitude: 40.94301, longitude: -72.581883, zipCode: "11947"},
	{latitude: 40.960725, longitude: -72.54988, zipCode: "11948"},
	{latitude: 40.866503, longitude: -72.804643, zipCode: "11949"},
	{latitude: 40.806142, longitude: -72.847182, zipCode: "11950"},
	{latitude: 40.765722, longitude: -72.836722, zipCode: "11951"},
	{latitude: 41.001648, longitude: -72.547599, zipCode: "11952"},
	{latitude: 40.892509, longitude: -72.951175, zipCode: "11953"},
	{latitude: 41.046357, longitude: -71.948827, zipCode: "11954"},
	{latitude: 40.813511, longitude: -72.823823, zipCode: "11955"},
	{latitude: 40.991089, longitude: -72.472124, zipCode: "11956"},
	{latitude: 41.202981, longitude: -72.117081, zipCode: "11957"},
	{latitude: 41.039067, longitude: -72.464419, zipCode: "11958"},
	{latitude: 40.83029, longitude: -72.60976, zipCode: "11959"},
	{latitude: 40.810615, longitude: -72.706062, zipCode: "11960"},
	{latitude: 40.903814, longitude: -72.88588, zipCode: "11961"},
	{latitude: 40.930895, longitude: -72.268719, zipCode: "11962"},
	{latitude: 41.001696, longitude: -72.31962, zipCode: "11963"},
	{latitude: 41.049483, longitude: -72.302316, zipCode: "11964"},
	{latitude: 41.082501, longitude: -72.303379, zipCode: "11965"},
	{latitude: 40.801566, longitude: -72.878028, zipCode: "11967"},
	{latitude: 40.900359, longitude: -72.428605, zipCode: "11968"},
	{latitude: 40.941972, longitude: -72.572477, zipCode: "11970"},
	{latitude: 41.064714, longitude: -72.42906, zipCode: "11971"},
	{latitude: 40.822663, longitude: -72.700008, zipCode: "11972"},
	{latitude: 40.865846, longitude: -72.887206, zipCode: "11973"},
	{latitude: 40.948268, longitude: -72.249625, zipCode: "11975"},
	{latitude: 40.929237, longitude: -72.34741, zipCode: "11976"},
	{latitude: 40.828481, longitude: -72.679186, zipCode: "11977"},
	{latitude: 40.825621, longitude: -72.637502, zipCode: "11978"},
	{latitude: 40.831662, longitude: -72.924805, zipCode: "11980"},
	{latitude: 42.505651, longitude: -73.93531, zipCode: "12007"},
	{latitude: 42.853488, longitude: -73.906535, zipCode: "12008"},
	{latitude: 42.697723, longitude: -74.03535, zipCode: "12009"},
	{latitude: 42.937632, longitude: -74.173325, zipCode: "12010"},
	{latitude: 42.292243, longitude: -73.821532, zipCode: "12015"},
	{latitude: 42.32111, longitude: -73.455997, zipCode: "12017"},
	{latitude: 42.628833, longitude: -73.520771, zipCode: "12018"},
	{latitude: 42.934351, longitude: -73.881808, zipCode: "12019"},
	{latitude: 43.001663, longitude: -73.870089, zipCode: "12020"},
	{latitude: 42.666547, longitude: -73.333967, zipCode: "12022"},
	{latitude: 42.598558, longitude: -74.183022, zipCode: "12023"},
	{latitude: 42.481337, longitude: -73.534211, zipCode: "12024"},
	{latitude: 43.091396, longitude: -74.121889, zipCode: "12025"},
	{latitude: 42.921339, longitude: -73.907787, zipCode: "12027"},
	{latitude: 42.944934, longitude: -73.440913, zipCode: "12028"},
	{latitude: 42.41595, longitude: -73.424961, zipCode: "12029"},
	{latitude: 42.765752, longitude: -74.466129, zipCode: "12031"},
	{latitude: 43.287999, longitude: -74.591429, zipCode: "12032"},
	{latitude: 42.533097, longitude: -73.700086, zipCode: "12033"},
	{latitude: 42.733746, longitude: -74.371881, zipCode: "12035"},
	{latitude: 42.532547, longitude: -74.689392, zipCode: "12036"},
	{latitude: 42.338765, longitude: -73.565764, zipCode: "12037"},
	{latitude: 42.630519, longitude: -73.359094, zipCode: "12040"},
	{latitude: 42.543675, longitude: -73.979677, zipCode: "12041"},
	{latitude: 42.352516, longitude: -73.883817, zipCode: "12042"},
	{latitude: 42.69397, longitude: -74.530506, zipCode: "12043"},
	{latitude: 42.47966, longitude: -73.796833, zipCode: "12045"},
	{latitude: 42.50913, longitude: -73.917246, zipCode: "12046"},
	{latitude: 42.784054, longitude: -73.726219, zipCode: "12047"},
	{latitude: 42.346369, longitude: -73.838401, zipCode: "12051"},
	{latitude: 42.757369, longitude: -73.480124, zipCode: "12052"},
	{latitude: 42.75747, longitude: -74.191095, zipCode: "12053"},
	{latitude: 42.604548, longitude: -73.866239, zipCode: "12054"},
	{latitude: 42.764859, longitude: -74.100303, zipCode: "12056"},
	{latitude: 42.971284, longitude: -73.336406, zipCode: "12057"},
	{latitude: 42.344418, longitude: -73.917633, zipCode: "12058"},
	{latitude: 42.619223, longitude: -74.060881, zipCode: "12059"},
	{latitude: 42.410631, longitude: -73.505286, zipCode: "12060"},
	{latitude: 42.599312, longitude: -73.657175, zipCode: "12061"},
	{latitude: 42.538976, longitude: -73.504976, zipCode: "12062"},
	{latitude: 42.567308, longitude: -73.639545, zipCode: "12063"},
	{latitude: 42.57943, longitude: -74.646481, zipCode: "12064"},
	{latitude: 42.852284, longitude: -73.785597, zipCode: "12065"},
	{latitude: 42.781106, longitude: -74.303881, zipCode: "12066"},
	{latitude: 42.557461, longitude: -73.920294, zipCode: "12067"},
	{latitude: 42.956069, longitude: -74.401829, zipCode: "12068"},
	{latitude: 42.946036, longitude: -74.279938, zipCode: "12069"},
	{latitude: 42.986022, longitude: -74.26301, zipCode: "12070"},
	{latitude: 42.569706, longitude: -74.41519, zipCode: "12071"},
	{latitude: 42.884893, longitude: -74.356737, zipCode: "12072"},
	{latitude: 43.050577, longitude: -74.052937, zipCode: "12074"},
	{latitude: 42.304843, longitude: -73.641096, zipCode: "12075"},
	{latitude: 42.399716, longitude: -74.390523, zipCode: "12076"},
	{latitude: 42.58817, longitude: -73.777693, zipCode: "12077"},
	{latitude: 43.134351, longitude: -74.339632, zipCode: "12078"},
	{latitude: 42.769967, longitude: -73.446682, zipCode: "12082"},
	{latitude: 42.424859, longitude: -74.026509, zipCode: "12083"},
	{latitude: 42.704669, longitude: -73.899364, zipCode: "12084"},
	{latitude: 42.700531, longitude: -73.966639, zipCode: "12085"},
	{latitude: 42.996145, longitude: -74.1086, zipCode: "12086"},
	{latitude: 42.43225, longitude: -73.892894, zipCode: "12087"},
	{latitude: 42.876802, longitude: -73.351525, zipCode: "12090"},
	{latitude: 42.705938, longitude: -74.379565, zipCode: "12092"},
	{latitude: 42.497791, longitude: -74.628105, zipCode: "12093"},
	{latitude: 42.889977, longitude: -73.485945, zipCode: "12094"},
	{latitude: 43.034887, longitude: -74.423556, zipCode: "12095"},
	{latitude: 42.38851, longitude: -73.713796, zipCode: "12106"},
	{latitude: 43.467735, longitude: -74.435462, zipCode: "12108"},
	{latitude: 42.750565, longitude: -73.776095, zipCode: "12110"},
	{latitude: 42.467606, longitude: -73.602221, zipCode: "12115"},
	{latitude: 42.538795, longitude: -74.911164, zipCode: "12116"},
	{latitude: 43.167143, longitude: -74.261237, zipCode: "12117"},
	{latitude: 42.920932, longitude: -73.717031, zipCode: "12118"},
	{latitude: 42.465026, longitude: -74.135365, zipCode: "12120"},
	{latitude: 42.839913, longitude: -73.60738, zipCode: "12121"},
	{latitude: 42.54202, longitude: -74.326145, zipCode: "12122"},
	{latitude: 42.52214, longitude: -73.606447, zipCode: "12123"},
	{latitude: 42.448502, longitude: -73.788651, zipCode: "12124"},
	{latitude: 42.484748, longitude: -73.399844, zipCode: "12125"},
	{latitude: 42.441653, longitude: -73.658349, zipCode: "12130"},
	{latitude: 42.453371, longitude: -74.463913, zipCode: "12131"},
	{latitude: 42.471013, longitude: -73.633667, zipCode: "12132"},
	{latitude: 43.262075, longitude: -74.228016, zipCode: "12134"},
	{latitude: 42.420121, longitude: -73.565107, zipCode: "12136"},
	{latitude: 42.860344, longitude: -74.133085, zipCode: "12137"},
	{latitude: 42.748836, longitude: -73.370379, zipCode: "12138"},
	{latitude: 43.500221, longitude: -74.579544, zipCode: "12139"},
	{latitude: 42.68195, longitude: -73.571973, zipCode: "12140"},
	{latitude: 42.49156, longitude: -73.84266, zipCode: "12143"},
	{latitude: 42.627836, longitude: -73.717842, zipCode: "12144"},
	{latitude: 42.518611, longitude: -74.15884, zipCode: "12147"},
	{latitude: 42.827793, longitude: -73.848164, zipCode: "12148"},
	{latitude: 42.617973, longitude: -74.570557, zipCode: "12149"},
	{latitude: 42.880523, longitude: -74.052689, zipCode: "12150"},
	{latitude: 42.922667, longitude: -73.780649, zipCode: "12151"},
	{latitude: 42.63222, longitude: -73.4722, zipCode: "12153"},
	{latitude: 42.937482, longitude: -73.605806, zipCode: "12154"},
	{latitude: 42.596357, longitude: -74.827655, zipCode: "12155"},
	{latitude: 42.483504, longitude: -73.747254, zipCode: "12156"},
	{latitude: 42.669798, longitude: -74.286077, zipCode: "12157"},
	{latitude: 42.53301, longitude: -73.813578, zipCode: "12158"},
	{latitude: 42.651428, longitude: -73.885894, zipCode: "12159"},
	{latitude: 42.763359, longitude: -74.363539, zipCode: "12160"},
	{latitude: 42.5337, longitude: -73.846164, zipCode: "12161"},
	{latitude: 43.648293, longitude: -74.45158, zipCode: "12164"},
	{latitude: 42.315193, longitude: -73.507601, zipCode: "12165"},
	{latitude: 42.844631, longitude: -74.460191, zipCode: "12166"},
	{latitude: 42.423174, longitude: -74.598846, zipCode: "12167"},
	{latitude: 42.573634, longitude: -73.370724, zipCode: "12168"},
	{latitude: 42.592887, longitude: -73.448036, zipCode: "12169"},
	{latitude: 43.001956, longitude: -73.667271, zipCode: "12170"},
	{latitude: 42.289129, longitude: -73.738949, zipCode: "12172"},
	{latitude: 42.395485, longitude: -73.755478, zipCode: "12173"},
	{latitude: 42.353199, longitude: -73.72802, zipCode: "12174"},
	{latitude: 42.559492, longitude: -74.560411, zipCode: "12175"},
	{latitude: 42.384523, longitude: -73.966117, zipCode: "12176"},
	{latitude: 42.950858, longitude: -74.287087, zipCode: "12177"},
	{latitude: 42.748588, longitude: -73.599536, zipCode: "12180"},
	{latitude: 42.800534, longitude: -73.627434, zipCode: "12182"},
	{latitude: 42.747904, longitude: -73.692466, zipCode: "12183"},
	{latitude: 42.419893, longitude: -73.653359, zipCode: "12184"},
	{latitude: 42.862521, longitude: -73.554112, zipCode: "12185"},
	{latitude: 42.633112, longitude: -73.977172, zipCode: "12186"},
	{latitude: 42.628169, longitude: -74.464285, zipCode: "12187"},
	{latitude: 42.821216, longitude: -73.695525, zipCode: "12188"},
	{latitude: 42.736342, longitude: -73.717521, zipCode: "12189"},
	{latitude: 43.475631, longitude: -74.28541, zipCode: "12190"},
	{latitude: 42.400523, longitude: -73.825058, zipCode: "12192"},
	{latitude: 42.52276, longitude: -74.043268, zipCode: "12193"},
	{latitude: 42.538337, longitude: -74.454298, zipCode: "12194"},
	{latitude: 42.478801, longitude: -73.465002, zipCode: "12195"},
	{latitude: 42.631786, longitude: -73.613946, zipCode: "12196"},
	{latitude: 42.609999, longitude: -74.724749, zipCode: "12197"},
	{latitude: 42.672768, longitude: -73.633574, zipCode: "12198"},
	{latitude: 42.635166, longitude: -73.760028, zipCode: "12202"},
	{latitude: 42.682899, longitude: -73.849361, zipCode: "12203"},
	{latitude: 42.691516, longitude: -73.733751, zipCode: "12204"},
	{latitude: 42.718696, longitude: -73.824087, zipCode: "12205"},
	{latitude: 42.67419, longitude: -73.782047, zipCode: "12206"},
	{latitude: 42.655489, longitude: -73.747618, zipCode: "12207"},
	{latitude: 42.653031, longitude: -73.809978, zipCode: "12208"},
	{latitude: 42.638686, longitude: -73.790469, zipCode: "12209"},
	{latitude: 42.655709, longitude: -73.754868, zipCode: "12210"},
	{latitude: 42.70973, longitude: -73.763079, zipCode: "12211"},
	{latitude: 42.685261, longitude: -73.82323, zipCode: "12222"},
	{latitude: 42.679296, longitude: -73.80987, zipCode: "12226"},
	{latitude: 42.878182, longitude: -73.982505, zipCode: "12302"},
	{latitude: 42.748985, longitude: -73.929694, zipCode: "12303"},
	{latitude: 42.77469, longitude: -73.898297, zipCode: "12304"},
	{latitude: 42.812011, longitude: -73.950342, zipCode: "12305"},
	{latitude: 42.805429, longitude: -74.044227, zipCode: "12306"},
	{latitude: 42.804981, longitude: -73.932822, zipCode: "12307"},
	{latitude: 42.82134, longitude: -73.92093, zipCode: "12308"},
	{latitude: 42.79987, longitude: -73.864965, zipCode: "12309"},
	{latitude: 41.987541, longitude: -74.010273, zipCode: "12401"},
	{latitude: 41.818958, longitude: -74.236082, zipCode: "12404"},
	{latitude: 42.306958, longitude: -74.10097, zipCode: "12405"},
	{latitude: 42.094825, longitude: -74.576038, zipCode: "12406"},
	{latitude: 42.296731, longitude: -74.362067, zipCode: "12407"},
	{latitude: 42.055483, longitude: -74.165388, zipCode: "12409"},
	{latitude: 42.06578, longitude: -74.424757, zipCode: "12410"},
	{latitude: 41.879005, longitude: -74.039774, zipCode: "12411"},
	{latitude: 42.012194, longitude: -74.276989, zipCode: "12412"},
	{latitude: 42.308296, longitude: -74.025401, zipCode: "12413"},
	{latitude: 42.235006, longitude: -73.924019, zipCode: "12414"},
	{latitude: 42.095928, longitude: -74.279125, zipCode: "12416"},
	{latitude: 41.906422, longitude: -73.990723, zipCode: "12417"},
	{latitude: 42.358618, longitude: -74.160884, zipCode: "12418"},
	{latitude: 41.861119, longitude: -74.102017, zipCode: "12419"},
	{latitude: 41.675289, longitude: -74.393678, zipCode: "12420"},
	{latitude: 42.257055, longitude: -74.535228, zipCode: "12421"},
	{latitude: 42.391829, longitude: -74.213288, zipCode: "12422"},
	{latitude: 42.379743, longitude: -74.111368, zipCode: "12423"},
	{latitude: 42.247617, longitude: -74.115847, zipCode: "12424"},
	{latitude: 42.150748, longitude: -74.12407, zipCode: "12427"},
	{latitude: 41.734369, longitude: -74.457205, zipCode: "12428"},
	{latitude: 41.83327, longitude: -73.961001, zipCode: "12429"},
	{latitude: 42.200758, longitude: -74.49916, zipCode: "12430"},
	{latitude: 42.362023, longitude: -74.023533, zipCode: "12431"},
	{latitude: 42.047059, longitude: -73.94395, zipCode: "12432"},
	{latitude: 42.005335, longitude: -74.15527, zipCode: "12433"},
	{latitude: 42.362378, longitude: -74.508698, zipCode: "12434"},
	{latitude: 41.72174, longitude: -74.502139, zipCode: "12435"},
	{latitude: 42.213077, longitude: -74.078284, zipCode: "12436"},
	{latitude: 42.2103, longitude: -74.601029, zipCode: "12438"},
	{latitude: 42.277817, longitude: -74.21039, zipCode: "12439"},
	{latitude: 41.781151, longitude: -74.172877, zipCode: "12440"},
	{latitude: 42.150709, longitude: -74.504477, zipCode: "12441"},
	{latitude: 42.226884, longitude: -74.244054, zipCode: "12442"},
	{latitude: 41.934991, longitude: -74.084925, zipCode: "12443"},
	{latitude: 42.247485, longitude: -74.290618, zipCode: "12444"},
	{latitude: 41.789893, longitude: -74.313533, zipCode: "12446"},
	{latitude: 42.079556, longitude: -74.200687, zipCode: "12448"},
	{latitude: 41.992378, longitude: -73.993774, zipCode: "12449"},
	{latitude: 42.142232, longitude: -74.223318, zipCode: "12450"},
	{latitude: 42.304893, longitude: -73.948176, zipCode: "12451"},
	{latitude: 42.237405, longitude: -74.359025, zipCode: "12452"},
	{latitude: 42.092947, longitude: -73.936225, zipCode: "12453"},
	{latitude: 42.279821, longitude: -74.142025, zipCode: "12454"},
	{latitude: 42.149301, longitude: -74.661616, zipCode: "12455"},
	{latitude: 42.032705, longitude: -73.997133, zipCode: "12456"},
	{latitude: 42.03999, longitude: -74.246589, zipCode: "12457"},
	{latitude: 41.822918, longitude: -74.43208, zipCode: "12458"},
	{latitude: 42.240382, longitude: -74.678893, zipCode: "12459"},
	{latitude: 42.429272, longitude: -74.169025, zipCode: "12460"},
	{latitude: 41.908566, longitude: -74.247857, zipCode: "12461"},
	{latitude: 42.191625, longitude: -74.009276, zipCode: "12463"},
	{latitude: 42.06509, longitude: -74.346334, zipCode: "12464"},
	{latitude: 42.157153, longitude: -74.460016, zipCode: "12465"},
	{latitude: 41.906849, longitude: -73.977827, zipCode: "12466"},
	{latitude: 42.299033, longitude: -74.400732, zipCode: "12468"},
	{latitude: 42.448595, longitude: -74.246097, zipCode: "12469"},
	{latitude: 42.284999, longitude: -74.078273, zipCode: "12470"},
	{latitude: 41.837924, longitude: -74.04193, zipCode: "12471"},
	{latitude: 41.848783, longitude: -74.067404, zipCode: "12472"},
	{latitude: 42.260675, longitude: -74.031311, zipCode: "12473"},
	{latitude: 42.285102, longitude: -74.588008, zipCode: "12474"},
	{latitude: 42.013988, longitude: -74.013305, zipCode: "12475"},
	{latitude: 42.092591, longitude: -73.98811, zipCode: "12477"},
	{latitude: 42.135381, longitude: -74.391274, zipCode: "12480"},
	{latitude: 41.976695, longitude: -74.227962, zipCode: "12481"},
	{latitude: 42.268399, longitude: -73.955913, zipCode: "12482"},
	{latitude: 41.67459, longitude: -74.454933, zipCode: "12483"},
	{latitude: 41.86194, longitude: -74.176524, zipCode: "12484"},
	{latitude: 42.207224, longitude: -74.162231, zipCode: "12485"},
	{latitude: 41.832417, longitude: -74.063984, zipCode: "12486"},
	{latitude: 41.869399, longitude: -73.998805, zipCode: "12487"},
	{latitude: 41.754008, longitude: -74.35221, zipCode: "12489"},
	{latitude: 42.122854, longitude: -73.924476, zipCode: "12490"},
	{latitude: 41.965192, longitude: -74.141459, zipCode: "12491"},
	{latitude: 42.187583, longitude: -74.334525, zipCode: "12492"},
	{latitude: 41.794731, longitude: -73.971819, zipCode: "12493"},
	{latitude: 41.948566, longitude: -74.301701, zipCode: "12494"},
	{latitude: 42.082674, longitude: -74.245663, zipCode: "12495"},
	{latitude: 42.338036, longitude: -74.270087, zipCode: "12496"},
	{latitude: 42.045663, longitude: -74.109618, zipCode: "12498"},
	{latitude: 41.853415, longitude: -73.557726, zipCode: "12501"},
	{latitude: 42.086568, longitude: -73.664724, zipCode: "12502"},
	{latitude: 42.035959, longitude: -73.580146, zipCode: "12503"},
	{latitude: 42.023418, longitude: -73.909349, zipCode: "12504"},
	{latitude: 42.009241, longitude: -73.917141, zipCode: "12507"},
	{latitude: 41.495996, longitude: -73.953632, zipCode: "12508"},
	{latitude: 41.555184, longitude: -73.962452, zipCode: "12512"},
	{latitude: 42.219307, longitude: -73.720481, zipCode: "12513"},
	{latitude: 41.877409, longitude: -73.764423, zipCode: "12514"},
	{latitude: 41.679567, longitude: -74.065058, zipCode: "12515"},
	{latitude: 42.108104, longitude: -73.551361, zipCode: "12516"},
	{latitude: 42.128263, longitude: -73.508622, zipCode: "12517"},
	{latitude: 41.416157, longitude: -74.043064, zipCode: "12518"},
	{latitude: 41.428017, longitude: -73.996195, zipCode: "12520"},
	{latitude: 42.171335, longitude: -73.650487, zipCode: "12521"},
	{latitude: 41.719979, longitude: -73.598384, zipCode: "12522"},
	{latitude: 42.08687, longitude: -73.759509, zipCode: "12523"},
	{latitude: 41.533367, longitude: -73.884911, zipCode: "12524"},
	{latitude: 41.686274, longitude: -74.192293, zipCode: "12525"},
	{latitude: 42.122234, longitude: -73.858769, zipCode: "12526"},
	{latitude: 41.519348, longitude: -73.936846, zipCode: "12527"},
	{latitude: 41.713899, longitude: -73.99509, zipCode: "12528"},
	{latitude: 42.195932, longitude: -73.543215, zipCode: "12529"},
	{latitude: 42.206908, longitude: -73.68415, zipCode: "12530"},
	{latitude: 41.540534, longitude: -73.671592, zipCode: "12531"},
	{latitude: 41.559772, longitude: -73.787099, zipCode: "12533"},
	{latitude: 42.226412, longitude: -73.753552, zipCode: "12534"},
	{latitude: 41.79223, longitude: -73.893767, zipCode: "12538"},
	{latitude: 41.671202, longitude: -73.724891, zipCode: "12540"},
	{latitude: 41.613876, longitude: -74.015014, zipCode: "12542"},
	{latitude: 41.485945, longitude: -74.223538, zipCode: "12543"},
	{latitude: 42.261204, longitude: -73.668154, zipCode: "12544"},
	{latitude: 41.791481, longitude: -73.677166, zipCode: "12545"},
	{latitude: 41.948, longitude: -73.523572, zipCode: "12546"},
	{latitude: 41.660687, longitude: -73.986567, zipCode: "12547"},
	{latitude: 41.657925, longitude: -74.101503, zipCode: "12548"},
	{latitude: 41.528031, longitude: -74.259365, zipCode: "12549"},
	{latitude: 41.539574, longitude: -74.057223, zipCode: "12550"},
	{latitude: 41.460412, longitude: -74.071917, zipCode: "12553"},
	{latitude: 41.76404, longitude: -74.089495, zipCode: "12561"},
	{latitude: 41.499189, longitude: -73.587226, zipCode: "12563"},
	{latitude: 41.582614, longitude: -73.582577, zipCode: "12564"},
	{latitude: 42.249004, longitude: -73.646902, zipCode: "12565"},
	{latitude: 41.633519, longitude: -74.319661, zipCode: "12566"},
	{latitude: 41.985205, longitude: -73.653984, zipCode: "12567"},
	{latitude: 41.734556, longitude: -73.792835, zipCode: "12569"},
	{latitude: 41.634064, longitude: -73.672481, zipCode: "12570"},
	{latitude: 42.003049, longitude: -73.809806, zipCode: "12571"},
	{latitude: 41.924885, longitude: -73.863664, zipCode: "12572"},
	{latitude: 41.917615, longitude: -73.945701, zipCode: "12574"},
	{latitude: 41.486109, longitude: -74.158896, zipCode: "12575"},
	{latitude: 41.421483, longitude: -74.116815, zipCode: "12577"},
	{latitude: 41.806348, longitude: -73.797584, zipCode: "12578"},
	{latitude: 41.863647, longitude: -73.871536, zipCode: "12580"},
	{latitude: 41.901615, longitude: -73.700592, zipCode: "12581"},
	{latitude: 41.543465, longitude: -73.724951, zipCode: "12582"},
	{latitude: 42.05959, longitude: -73.883622, zipCode: "12583"},
	{latitude: 41.726404, longitude: -73.69151, zipCode: "12585"},
	{latitude: 41.565134, longitude: -74.171721, zipCode: "12586"},
	{latitude: 41.623791, longitude: -74.158771, zipCode: "12589"},
	{latitude: 41.596424, longitude: -73.888844, zipCode: "12590"},
	{latitude: 41.783874, longitude: -73.548857, zipCode: "12592"},
	{latitude: 41.658399, longitude: -73.573493, zipCode: "12594"},
	{latitude: 41.707792, longitude: -73.910601, zipCode: "12601"},
	{latitude: 41.675868, longitude: -73.864484, zipCode: "12603"},
	{latitude: 41.688427, longitude: -73.894464, zipCode: "12604"},
	{latitude: 41.650059, longitude: -74.698803, zipCode: "12701"},
	{latitude: 41.485553, longitude: -74.892326, zipCode: "12719"},
	{latitude: 41.645922, longitude: -74.900851, zipCode: "12720"},
	{latitude: 41.567327, longitude: -74.427273, zipCode: "12721"},
	{latitude: 41.59043, longitude: -74.37488, zipCode: "12722"},
	{latitude: 41.7531, longitude: -74.987694, zipCode: "12723"},
	{latitude: 41.859973, longitude: -74.936777, zipCode: "12724"},
	{latitude: 41.983183, longitude: -74.570105, zipCode: "12725"},
	{latitude: 41.695085, longitude: -74.966495, zipCode: "12726"},
	{latitude: 41.489701, longitude: -74.613104, zipCode: "12729"},
	{latitude: 41.554808, longitude: -74.873913, zipCode: "12732"},
	{latitude: 41.738991, longitude: -74.613862, zipCode: "12733"},
	{latitude: 41.734585, longitude: -74.75046, zipCode: "12734"},
	{latitude: 41.853249, longitude: -75.019224, zipCode: "12736"},
	{latitude: 41.501481, longitude: -74.798632, zipCode: "12737"},
	{latitude: 41.66569, longitude: -74.596987, zipCode: "12738"},
	{latitude: 41.938832, longitude: -74.434026, zipCode: "12740"},
	{latitude: 41.839141, longitude: -75.077725, zipCode: "12741"},
	{latitude: 41.722976, longitude: -74.716303, zipCode: "12742"},
	{latitude: 41.535072, longitude: -74.840382, zipCode: "12743"},
	{latitude: 41.78502, longitude: -75.026358, zipCode: "12745"},
	{latitude: 41.439355, longitude: -74.652385, zipCode: "12746"},
	{latitude: 41.751382, longitude: -74.641917, zipCode: "12747"},
	{latitude: 41.774776, longitude: -74.915179, zipCode: "12748"},
	{latitude: 41.695218, longitude: -74.83142, zipCode: "12749"},
	{latitude: 41.739083, longitude: -74.96733, zipCode: "12750"},
	{latitude: 41.687756, longitude: -74.662069, zipCode: "12751"},
	{latitude: 41.681031, longitude: -74.985485, zipCode: "12752"},
	{latitude: 41.798195, longitude: -74.736889, zipCode: "12754"},
	{latitude: 41.940283, longitude: -74.739118, zipCode: "12758"},
	{latitude: 41.786481, longitude: -74.660721, zipCode: "12759"},
	{latitude: 41.903935, longitude: -75.107847, zipCode: "12760"},
	{latitude: 41.660537, longitude: -74.791529, zipCode: "12762"},
	{latitude: 41.682429, longitude: -74.509779, zipCode: "12763"},
	{latitude: 41.591553, longitude: -74.995556, zipCode: "12764"},
	{latitude: 41.852341, longitude: -74.621165, zipCode: "12765"},
	{latitude: 41.815208, longitude: -74.978854, zipCode: "12766"},
	{latitude: 41.832509, longitude: -75.003384, zipCode: "12767"},
	{latitude: 41.864443, longitude: -74.730022, zipCode: "12768"},
	{latitude: 41.659255, longitude: -74.470685, zipCode: "12769"},
	{latitude: 41.447019, longitude: -74.841017, zipCode: "12770"},
	{latitude: 41.375285, longitude: -74.631515, zipCode: "12771"},
	{latitude: 41.621456, longitude: -74.581765, zipCode: "12775"},
	{latitude: 41.96339, longitude: -74.957793, zipCode: "12776"},
	{latitude: 41.561783, longitude: -74.709708, zipCode: "12777"},
	{latitude: 41.661739, longitude: -74.814205, zipCode: "12778"},
	{latitude: 41.690801, longitude: -74.62442, zipCode: "12779"},
	{latitude: 41.440146, longitude: -74.72735, zipCode: "12780"},
	{latitude: 41.614496, longitude: -74.472066, zipCode: "12781"},
	{latitude: 41.738633, longitude: -74.833724, zipCode: "12783"},
	{latitude: 41.6672, longitude: -74.611776, zipCode: "12784"},
	{latitude: 41.54996, longitude: -74.586143, zipCode: "12785"},
	{latitude: 41.63871, longitude: -74.857609, zipCode: "12786"},
	{latitude: 41.795394, longitude: -74.845029, zipCode: "12787"},
	{latitude: 41.795101, longitude: -74.590896, zipCode: "12788"},
	{latitude: 41.718402, longitude: -74.579965, zipCode: "12789"},
	{latitude: 41.563429, longitude: -74.527401, zipCode: "12790"},
	{latitude: 41.817275, longitude: -74.89287, zipCode: "12791"},
	{latitude: 41.51392, longitude: -74.95982, zipCode: "12792"},
	{latitude: 43.31116, longitude: -73.645286, zipCode: "12801"},
	{latitude: 43.288444, longitude: -73.631773, zipCode: "12803"},
	{latitude: 43.337295, longitude: -73.682664, zipCode: "12804"},
	{latitude: 43.749724, longitude: -73.747233, zipCode: "12808"},
	{latitude: 43.241628, longitude: -73.471797, zipCode: "12809"},
	{latitude: 43.491968, longitude: -73.985954, zipCode: "12810"},
	{latitude: 43.558108, longitude: -74.077663, zipCode: "12811"},
	{latitude: 43.875794, longitude: -74.389767, zipCode: "12812"},
	{latitude: 43.631894, longitude: -73.632551, zipCode: "12814"},
	{latitude: 43.701044, longitude: -73.691928, zipCode: "12815"},
	{latitude: 43.044399, longitude: -73.38195, zipCode: "12816"},
	{latitude: 43.639585, longitude: -73.823628, zipCode: "12817"},
	{latitude: 43.591007, longitude: -73.46881, zipCode: "12819"},
	{latitude: 43.460074, longitude: -73.640713, zipCode: "12820"},
	{latitude: 43.453952, longitude: -73.423436, zipCode: "12821"},
	{latitude: 43.228593, longitude: -73.922212, zipCode: "12822"},
	{latitude: 43.179961, longitude: -73.409117, zipCode: "12823"},
	{latitude: 43.524468, longitude: -73.711288, zipCode: "12824"},
	{latitude: 43.441986, longitude: -73.53086, zipCode: "12827"},
	{latitude: 43.254759, longitude: -73.559347, zipCode: "12828"},
	{latitude: 43.195329, longitude: -73.691811, zipCode: "12831"},
	{latitude: 43.358467, longitude: -73.330271, zipCode: "12832"},
	{latitude: 43.15, longitude: -73.840896, zipCode: "12833"},
	{latitude: 43.097971, longitude: -73.505306, zipCode: "12834"},
	{latitude: 43.332787, longitude: -74.006311, zipCode: "12835"},
	{latitude: 43.712347, longitude: -73.605597, zipCode: "12836"},
	{latitude: 43.476445, longitude: -73.255601, zipCode: "12837"},
	{latitude: 43.32986, longitude: -73.406378, zipCode: "12838"},
	{latitude: 43.354486, longitude: -73.553953, zipCode: "12839"},
	{latitude: 43.577274, longitude: -73.508701, zipCode: "12841"},
	{latitude: 43.719634, longitude: -74.279891, zipCode: "12842"},
	{latitude: 43.59448, longitude: -73.981571, zipCode: "12843"},
	{latitude: 43.485236, longitude: -73.653991, zipCode: "12844"},
	{latitude: 43.427874, longitude: -73.71105, zipCode: "12845"},
	{latitude: 43.346108, longitude: -73.789495, zipCode: "12846"},
	{latitude: 43.965763, longitude: -74.634962, zipCode: "12847"},
	{latitude: 43.441613, longitude: -73.300072, zipCode: "12849"},
	{latitude: 43.102616, longitude: -73.981441, zipCode: "12850"},
	{latitude: 43.855332, longitude: -74.118359, zipCode: "12851"},
	{latitude: 43.987997, longitude: -74.133336, zipCode: "12852"},
	{latitude: 43.662075, longitude: -74.064334, zipCode: "12853"},
	{latitude: 43.450009, longitude: -73.327576, zipCode: "12854"},
	{latitude: 44.02701, longitude: -73.758053, zipCode: "12855"},
	{latitude: 43.66594, longitude: -74.142961, zipCode: "12856"},
	{latitude: 43.833191, longitude: -73.946808, zipCode: "12857"},
	{latitude: 43.877982, longitude: -73.703373, zipCode: "12858"},
	{latitude: 43.155477, longitude: -73.904837, zipCode: "12859"},
	{latitude: 43.722132, longitude: -73.814178, zipCode: "12860"},
	{latitude: 43.746887, longitude: -73.417527, zipCode: "12861"},
	{latitude: 43.688347, longitude: -73.931958, zipCode: "12862"},
	{latitude: 43.060305, longitude: -73.931602, zipCode: "12863"},
	{latitude: 43.727403, longitude: -74.305353, zipCode: "12864"},
	{latitude: 43.214159, longitude: -73.347451, zipCode: "12865"},
	{latitude: 43.073715, longitude: -73.740236, zipCode: "12866"},
	{latitude: 43.839222, longitude: -73.765598, zipCode: "12870"},
	{latitude: 43.090571, longitude: -73.60425, zipCode: "12871"},
	{latitude: 43.883923, longitude: -73.722977, zipCode: "12872"},
	{latitude: 43.117451, longitude: -73.311646, zipCode: "12873"},
	{latitude: 43.690561, longitude: -73.549743, zipCode: "12874"},
	{latitude: 43.438184, longitude: -74.064361, zipCode: "12878"},
	{latitude: 43.83636, longitude: -73.553222, zipCode: "12883"},
	{latitude: 43.088495, longitude: -73.592146, zipCode: "12884"},
	{latitude: 43.52943, longitude: -73.835546, zipCode: "12885"},
	{latitude: 43.635164, longitude: -73.931497, zipCode: "12886"},
	{latitude: 43.53676, longitude: -73.365798, zipCode: "12887"},
	{latitude: 44.709098, longitude: -73.473547, zipCode: "12901"},
	{latitude: 44.656927, longitude: -73.46311, zipCode: "12903"},
	{latitude: 44.857044, longitude: -73.607875, zipCode: "12910"},
	{latitude: 44.535492, longitude: -73.468293, zipCode: "12911"},
	{latitude: 44.480941, longitude: -73.772482, zipCode: "12912"},
	{latitude: 44.430097, longitude: -74.002212, zipCode: "12913"},
	{latitude: 44.934151, longitude: -74.593651, zipCode: "12914"},
	{latitude: 44.853444, longitude: -74.034955, zipCode: "12915"},
	{latitude: 44.838194, longitude: -74.524672, zipCode: "12916"},
	{latitude: 44.924652, longitude: -74.173821, zipCode: "12917"},
	{latitude: 44.691158, longitude: -73.675119, zipCode: "12918"},
	{latitude: 44.96026, longitude: -73.44996, zipCode: "12919"},
	{latitude: 44.890975, longitude: -74.056569, zipCode: "12920"},
	{latitude: 44.894014, longitude: -73.432451, zipCode: "12921"},
	{latitude: 44.367987, longitude: -74.814407, zipCode: "12922"},
	{latitude: 44.945466, longitude: -73.958013, zipCode: "12923"},
	{latitude: 44.480055, longitude: -73.582645, zipCode: "12924"},
	{latitude: 44.950821, longitude: -74.329, zipCode: "12926"},
	{latitude: 44.20725, longitude: -74.809746, zipCode: "12927"},
	{latitude: 43.963837, longitude: -73.583249, zipCode: "12928"},
	{latitude: 44.719713, longitude: -73.719082, zipCode: "12929"},
	{latitude: 44.728144, longitude: -74.5397, zipCode: "12930"},
	{latitude: 44.214405, longitude: -73.608948, zipCode: "12932"},
	{latitude: 44.890994, longitude: -73.845341, zipCode: "12933"},
	{latitude: 44.811802, longitude: -73.864913, zipCode: "12934"},
	{latitude: 44.844657, longitude: -73.796933, zipCode: "12935"},
	{latitude: 44.285062, longitude: -73.40145, zipCode: "12936"},
	{latitude: 44.955224, longitude: -74.488146, zipCode: "12937"},
	{latitude: 44.432877, longitude: -74.16073, zipCode: "12939"},
	{latitude: 44.35493, longitude: -73.700216, zipCode: "12941"},
	{latitude: 44.261252, longitude: -73.796612, zipCode: "12942"},
	{latitude: 44.122772, longitude: -73.8835, zipCode: "12943"},
	{latitude: 44.433107, longitude: -73.533246, zipCode: "12944"},
	{latitude: 44.309091, longitude: -74.24044, zipCode: "12945"},
	{latitude: 44.206567, longitude: -74.046051, zipCode: "12946"},
	{latitude: 44.318369, longitude: -73.581246, zipCode: "12950"},
	{latitude: 44.710805, longitude: -73.903855, zipCode: "12952"},
	{latitude: 44.745352, longitude: -74.260037, zipCode: "12953"},
	{latitude: 44.780377, longitude: -73.944242, zipCode: "12955"},
	{latitude: 44.097612, longitude: -73.481333, zipCode: "12956"},
	{latitude: 44.853516, longitude: -74.577063, zipCode: "12957"},
	{latitude: 44.953539, longitude: -73.572924, zipCode: "12958"},
	{latitude: 44.952351, longitude: -73.719912, zipCode: "12959"},
	{latitude: 44.015756, longitude: -73.572348, zipCode: "12960"},
	{latitude: 44.061246, longitude: -73.587569, zipCode: "12961"},
	{latitude: 44.709232, longitude: -73.577065, zipCode: "12962"},
	{latitude: 44.131639, longitude: -73.633787, zipCode: "12964"},
	{latitude: 44.705892, longitude: -74.683958, zipCode: "12965"},
	{latitude: 44.793279, longitude: -74.425131, zipCode: "12966"},
	{latitude: 44.760957, longitude: -74.660517, zipCode: "12967"},
	{latitude: 44.710897, longitude: -74.09815, zipCode: "12969"},
	{latitude: 44.44625, longitude: -74.336722, zipCode: "12970"},
	{latitude: 44.561741, longitude: -73.559657, zipCode: "12972"},
	{latitude: 44.2841, longitude: -74.568534, zipCode: "12973"},
	{latitude: 44.060043, longitude: -73.464841, zipCode: "12974"},
	{latitude: 44.529758, longitude: -73.431095, zipCode: "12975"},
	{latitude: 44.503733, longitude: -74.230251, zipCode: "12976"},
	{latitude: 44.296612, longitude: -74.09891, zipCode: "12977"},
	{latitude: 44.61294, longitude: -73.789918, zipCode: "12978"},
	{latitude: 44.989281, longitude: -73.372178, zipCode: "12979"},
	{latitude: 44.563851, longitude: -74.524079, zipCode: "12980"},
	{latitude: 44.627099, longitude: -73.845851, zipCode: "12981"},
	{latitude: 44.348425, longitude: -74.143066, zipCode: "12983"},
	{latitude: 44.560709, longitude: -73.738967, zipCode: "12985"},
	{latitude: 44.224043, longitude: -74.433457, zipCode: "12986"},
	{latitude: 44.364183, longitude: -73.759382, zipCode: "12987"},
	{latitude: 44.538805, longitude: -74.060987, zipCode: "12989"},
	{latitude: 44.818238, longitude: -73.515569, zipCode: "12992"},
	{latitude: 44.193835, longitude: -73.488545, zipCode: "12993"},
	{latitude: 44.355266, longitude: -73.448055, zipCode: "12996"},
	{latitude: 44.372798, longitude: -73.892043, zipCode: "12997"},
	{latitude: 44.091296, longitude: -73.603669, zipCode: "12998"},
	{latitude: 42.816424, longitude: -76.074504, zipCode: "13020"},
	{latitude: 42.917704, longitude: -76.554611, zipCode: "13021"},
	{latitude: 42.934596, longitude: -76.574232, zipCode: "13024"},
	{latitude: 42.744043, longitude: -76.652266, zipCode: "13026"},
	{latitude: 43.171636, longitude: -76.35623, zipCode: "13027"},
	{latitude: 43.3028, longitude: -75.933212, zipCode: "13028"},
	{latitude: 43.225942, longitude: -76.151343, zipCode: "13029"},
	{latitude: 43.159236, longitude: -75.963913, zipCode: "13030"},
	{latitude: 43.045456, longitude: -76.306671, zipCode: "13031"},
	{latitude: 43.081617, longitude: -75.766018, zipCode: "13032"},
	{latitude: 43.187542, longitude: -76.573279, zipCode: "13033"},
	{latitude: 42.95678, longitude: -76.707909, zipCode: "13034"},
	{latitude: 42.940849, longitude: -75.830103, zipCode: "13035"},
	{latitude: 43.323525, longitude: -76.188961, zipCode: "13036"},
	{latitude: 43.067797, longitude: -75.854933, zipCode: "13037"},
	{latitude: 43.172142, longitude: -76.056249, zipCode: "13039"},
	{latitude: 42.559649, longitude: -75.930761, zipCode: "13040"},
	{latitude: 43.192089, longitude: -76.192302, zipCode: "13041"},
	{latitude: 43.26045, longitude: -75.85258, zipCode: "13042"},
	{latitude: 43.304757, longitude: -75.993273, zipCode: "13044"},
	{latitude: 42.584512, longitude: -76.190686, zipCode: "13045"},
	{latitude: 42.883189, longitude: -75.910112, zipCode: "13051"},
	{latitude: 42.73449, longitude: -75.869152, zipCode: "13052"},
	{latitude: 42.479575, longitude: -76.267918, zipCode: "13053"},
	{latitude: 43.169352, longitude: -75.666823, zipCode: "13054"},
	{latitude: 43.101955, longitude: -76.037845, zipCode: "13057"},
	{latitude: 43.022862, longitude: -76.411872, zipCode: "13060"},
	{latitude: 42.858748, longitude: -75.745465, zipCode: "13061"},
	{latitude: 42.481368, longitude: -76.386027, zipCode: "13062"},
	{latitude: 42.847861, longitude: -75.977956, zipCode: "13063"},
	{latitude: 42.818812, longitude: -76.815768, zipCode: "13065"},
	{latitude: 43.032344, longitude: -76.000553, zipCode: "13066"},
	{latitude: 42.492411, longitude: -76.359185, zipCode: "13068"},
	{latitude: 43.329276, longitude: -76.380655, zipCode: "13069"},
	{latitude: 42.675114, longitude: -76.542579, zipCode: "13071"},
	{latitude: 42.761342, longitude: -75.751696, zipCode: "13072"},
	{latitude: 42.58367, longitude: -76.387923, zipCode: "13073"},
	{latitude: 43.310917, longitude: -76.549843, zipCode: "13074"},
	{latitude: 43.353715, longitude: -76.149309, zipCode: "13076"},
	{latitude: 42.727973, longitude: -76.211042, zipCode: "13077"},
	{latitude: 42.958469, longitude: -76.06117, zipCode: "13078"},
	{latitude: 43.09321, longitude: -76.485258, zipCode: "13080"},
	{latitude: 42.673617, longitude: -76.627152, zipCode: "13081"},
	{latitude: 43.10273, longitude: -75.959888, zipCode: "13082"},
	{latitude: 43.645775, longitude: -75.98168, zipCode: "13083"},
	{latitude: 42.881106, longitude: -76.124074, zipCode: "13084"},
	{latitude: 42.696836, longitude: -76.169764, zipCode: "13087"},
	{latitude: 43.107862, longitude: -76.190473, zipCode: "13088"},
	{latitude: 43.153084, longitude: -76.212402, zipCode: "13090"},
	{latitude: 42.651791, longitude: -76.426069, zipCode: "13092"},
	{latitude: 42.613308, longitude: -76.066405, zipCode: "13101"},
	{latitude: 42.550304, longitude: -76.292002, zipCode: "13102"},
	{latitude: 43.326604, longitude: -76.104944, zipCode: "13103"},
	{latitude: 42.96303, longitude: -75.948439, zipCode: "13104"},
	{latitude: 42.964845, longitude: -76.331113, zipCode: "13108"},
	{latitude: 42.888747, longitude: -76.290192, zipCode: "13110"},
	{latitude: 43.258488, longitude: -76.614264, zipCode: "13111"},
	{latitude: 43.106266, longitude: -76.417239, zipCode: "13112"},
	{latitude: 43.164221, longitude: -76.534883, zipCode: "13113"},
	{latitude: 43.465743, longitude: -76.242836, zipCode: "13114"},
	{latitude: 43.393725, longitude: -76.474679, zipCode: "13115"},
	{latitude: 43.074532, longitude: -76.007925, zipCode: "13116"},
	{latitude: 43.008063, longitude: -76.707683, zipCode: "13117"},
	{latitude: 42.754959, longitude: -76.401439, zipCode: "13118"},
	{latitude: 42.976556, longitude: -76.444902, zipCode: "13119"},
	{latitude: 42.945178, longitude: -76.164321, zipCode: "13120"},
	{latitude: 43.484889, longitude: -76.309694, zipCode: "13121"},
	{latitude: 42.837754, longitude: -75.86152, zipCode: "13122"},
	{latitude: 43.227964, longitude: -75.75321, zipCode: "13123"},
	{latitude: 42.661074, longitude: -75.823604, zipCode: "13124"},
	{latitude: 43.438798, longitude: -76.457454, zipCode: "13126"},
	{latitude: 43.423243, longitude: -76.091802, zipCode: "13131"},
	{latitude: 43.271918, longitude: -76.251052, zipCode: "13132"},
	{latitude: 42.967368, longitude: -75.684678, zipCode: "13134"},
	{latitude: 43.253687, longitude: -76.314375, zipCode: "13135"},
	{latitude: 42.607066, longitude: -75.853761, zipCode: "13136"},
	{latitude: 42.899118, longitude: -76.01468, zipCode: "13138"},
	{latitude: 43.060381, longitude: -76.650274, zipCode: "13140"},
	{latitude: 42.752495, longitude: -76.185697, zipCode: "13141"},
	{latitude: 43.556184, longitude: -76.138199, zipCode: "13142"},
	{latitude: 43.229857, longitude: -76.716549, zipCode: "13143"},
	{latitude: 43.568755, longitude: -75.977019, zipCode: "13144"},
	{latitude: 43.651893, longitude: -76.123188, zipCode: "13145"},
	{latitude: 43.100853, longitude: -76.756859, zipCode: "13146"},
	{latitude: 42.775406, longitude: -76.569608, zipCode: "13147"},
	{latitude: 42.921458, longitude: -76.778324, zipCode: "13148"},
	{latitude: 42.905241, longitude: -76.381517, zipCode: "13152"},
	{latitude: 42.994629, longitude: -76.454808, zipCode: "13153"},
	{latitude: 43.133795, longitude: -76.761877, zipCode: "13154"},
	{latitude: 42.674546, longitude: -75.776726, zipCode: "13155"},
	{latitude: 43.32599, longitude: -76.661306, zipCode: "13156"},
	{latitude: 43.202419, longitude: -75.725183, zipCode: "13157"},
	{latitude: 42.717351, longitude: -75.973408, zipCode: "13158"},
	{latitude: 42.800989, longitude: -76.118111, zipCode: "13159"},
	{latitude: 42.827598, longitude: -76.648904, zipCode: "13160"},
	{latitude: 43.185787, longitude: -75.721032, zipCode: "13162"},
	{latitude: 43.078917, longitude: -75.706892, zipCode: "13163"},
	{latitude: 43.096855, longitude: -76.31264, zipCode: "13164"},
	{latitude: 42.910746, longitude: -76.878565, zipCode: "13165"},
	{latitude: 43.078935, longitude: -76.565379, zipCode: "13166"},
	{latitude: 43.326421, longitude: -76.06026, zipCode: "13167"},
	{latitude: 43.043513, longitude: -76.150663, zipCode: "13202"},
	{latitude: 43.060978, longitude: -76.134868, zipCode: "13203"},
	{latitude: 43.051756, longitude: -76.178, zipCode: "13204"},
	{latitude: 43.004691, longitude: -76.141596, zipCode: "13205"},
	{latitude: 43.073525, longitude: -76.105813, zipCode: "13206"},
	{latitude: 43.012893, longitude: -76.163281, zipCode: "13207"},
	{latitude: 43.078747, longitude: -76.145223, zipCode: "13208"},
	{latitude: 43.081013, longitude: -76.236271, zipCode: "13209"},
	{latitude: 43.03142, longitude: -76.125816, zipCode: "13210"},
	{latitude: 43.10332, longitude: -76.119545, zipCode: "13211"},
	{latitude: 43.128583, longitude: -76.129694, zipCode: "13212"},
	{latitude: 43.040531, longitude: -76.076762, zipCode: "13214"},
	{latitude: 42.980793, longitude: -76.223386, zipCode: "13215"},
	{latitude: 43.039094, longitude: -76.22332, zipCode: "13219"},
	{latitude: 43.038917, longitude: -76.102603, zipCode: "13224"},
	{latitude: 43.02304, longitude: -76.123331, zipCode: "13244"},
	{latitude: 43.069136, longitude: -76.173017, zipCode: "13290"},
	{latitude: 43.422874, longitude: -75.215877, zipCode: "13301"},
	{latitude: 43.498155, longitude: -75.965146, zipCode: "13302"},
	{latitude: 43.367414, longitude: -75.468212, zipCode: "13303"},
	{latitude: 43.240367, longitude: -75.157043, zipCode: "13304"},
	{latitude: 43.895228, longitude: -75.430765, zipCode: "13305"},
	{latitude: 43.251404, longitude: -75.6578, zipCode: "13308"},
	{latitude: 43.478607, longitude: -75.339026, zipCode: "13309"},
	{latitude: 42.88962, longitude: -75.571545, zipCode: "13310"},
	{latitude: 43.691814, longitude: -75.20452, zipCode: "13312"},
	{latitude: 42.879135, longitude: -75.245341, zipCode: "13313"},
	{latitude: 42.827552, longitude: -75.301026, zipCode: "13314"},
	{latitude: 42.739121, longitude: -75.136119, zipCode: "13315"},
	{latitude: 43.418917, longitude: -75.73974, zipCode: "13316"},
	{latitude: 42.850257, longitude: -74.586055, zipCode: "13317"},
	{latitude: 42.921536, longitude: -75.260063, zipCode: "13318"},
	{latitude: 43.02709, longitude: -75.265991, zipCode: "13319"},
	{latitude: 42.777805, longitude: -74.740798, zipCode: "13320"},
	{latitude: 43.08896, longitude: -75.372252, zipCode: "13321"},
	{latitude: 42.977305, longitude: -75.198364, zipCode: "13322"},
	{latitude: 43.039981, longitude: -75.379353, zipCode: "13323"},
	{latitude: 43.319228, longitude: -74.916822, zipCode: "13324"},
	{latitude: 43.582505, longitude: -75.54406, zipCode: "13325"},
	{latitude: 42.722511, longitude: -74.895371, zipCode: "13326"},
	{latitude: 43.972873, longitude: -75.262642, zipCode: "13327"},
	{latitude: 42.98251, longitude: -75.428351, zipCode: "13328"},
	{latitude: 43.107462, longitude: -74.708431, zipCode: "13329"},
	{latitude: 43.821769, longitude: -75.040114, zipCode: "13331"},
	{latitude: 42.764159, longitude: -75.581736, zipCode: "13332"},
	{latitude: 42.825234, longitude: -74.827936, zipCode: "13333"},
	{latitude: 42.83071, longitude: -75.662889, zipCode: "13334"},
	{latitude: 42.70226, longitude: -75.248224, zipCode: "13335"},
	{latitude: 42.757804, longitude: -74.999545, zipCode: "13337"},
	{latitude: 43.528032, longitude: -74.987121, zipCode: "13338"},
	{latitude: 42.936895, longitude: -74.658658, zipCode: "13339"},
	{latitude: 43.061642, longitude: -75.12857, zipCode: "13340"},
	{latitude: 42.644136, longitude: -75.191592, zipCode: "13342"},
	{latitude: 43.751155, longitude: -75.309368, zipCode: "13343"},
	{latitude: 43.687077, longitude: -75.32333, zipCode: "13345"},
	{latitude: 42.820785, longitude: -75.546393, zipCode: "13346"},
	{latitude: 42.707256, longitude: -75.057189, zipCode: "13348"},
	{latitude: 43.061013, longitude: -74.986356, zipCode: "13350"},
	{latitude: 43.308948, longitude: -75.126654, zipCode: "13352"},
	{latitude: 43.447279, longitude: -74.694629, zipCode: "13353"},
	{latitude: 43.264185, longitude: -75.278559, zipCode: "13354"},
	{latitude: 42.81045, longitude: -75.421645, zipCode: "13355"},
	{latitude: 42.971061, longitude: -75.081209, zipCode: "13357"},
	{latitude: 43.722272, longitude: -74.714466, zipCode: "13360"},
	{latitude: 42.898111, longitude: -74.865747, zipCode: "13361"},
	{latitude: 43.337328, longitude: -75.512822, zipCode: "13363"},
	{latitude: 42.804969, longitude: -75.261098, zipCode: "13364"},
	{latitude: 43.098217, longitude: -74.848379, zipCode: "13365"},
	{latitude: 43.733064, longitude: -75.537, zipCode: "13367"},
	{latitude: 43.643649, longitude: -75.333839, zipCode: "13368"},
	{latitude: 42.901621, longitude: -75.509748, zipCode: "13402"},
	{latitude: 43.164533, longitude: -75.267645, zipCode: "13403"},
	{latitude: 43.738437, longitude: -75.480939, zipCode: "13404"},
	{latitude: 43.141426, longitude: -74.918203, zipCode: "13406"},
	{latitude: 42.965377, longitude: -74.944407, zipCode: "13407"},
	{latitude: 42.91902, longitude: -75.664315, zipCode: "13408"},
	{latitude: 42.976021, longitude: -75.591288, zipCode: "13409"},
	{latitude: 42.928889, longitude: -74.611014, zipCode: "13410"},
	{latitude: 42.633789, longitude: -75.306457, zipCode: "13411"},
	{latitude: 43.06156, longitude: -75.276199, zipCode: "13413"},
	{latitude: 42.597126, longitude: -75.197316, zipCode: "13415"},
	{latitude: 43.187371, longitude: -74.970659, zipCode: "13416"},
	{latitude: 43.100039, longitude: -75.293915, zipCode: "13417"},
	{latitude: 42.845704, longitude: -75.38046, zipCode: "13418"},
	{latitude: 43.654518, longitude: -75.013441, zipCode: "13420"},
	{latitude: 43.061915, longitude: -75.660092, zipCode: "13421"},
	{latitude: 43.152276, longitude: -75.362931, zipCode: "13424"},
	{latitude: 42.963463, longitude: -75.486698, zipCode: "13425"},
	{latitude: 42.92189, longitude: -74.529374, zipCode: "13428"},
	{latitude: 43.209081, longitude: -75.073326, zipCode: "13431"},
	{latitude: 43.594911, longitude: -75.248869, zipCode: "13433"},
	{latitude: 43.302212, longitude: -75.152526, zipCode: "13435"},
	{latitude: 43.780383, longitude: -74.6591, zipCode: "13436"},
	{latitude: 43.580319, longitude: -75.803835, zipCode: "13437"},
	{latitude: 43.354138, longitude: -75.159613, zipCode: "13438"},
	{latitude: 42.855311, longitude: -74.997219, zipCode: "13439"},
	{latitude: 43.217939, longitude: -75.459191, zipCode: "13440"},
	{latitude: 43.2271, longitude: -75.407607, zipCode: "13441"},
	{latitude: 42.702585, longitude: -74.812602, zipCode: "13450"},
	{latitude: 43.043056, longitude: -74.63047, zipCode: "13452"},
	{latitude: 43.170329, longitude: -74.75841, zipCode: "13454"},
	{latitude: 42.917938, longitude: -75.376567, zipCode: "13455"},
	{latitude: 43.001459, longitude: -75.258555, zipCode: "13456"},
	{latitude: 42.775248, longitude: -75.036865, zipCode: "13457"},
	{latitude: 42.775008, longitude: -74.588678, zipCode: "13459"},
	{latitude: 42.687707, longitude: -75.44229, zipCode: "13460"},
	{latitude: 43.071174, longitude: -75.598623, zipCode: "13461"},
	{latitude: 42.687973, longitude: -75.615788, zipCode: "13464"},
	{latitude: 42.84962, longitude: -74.873605, zipCode: "13468"},
	{latitude: 43.214828, longitude: -75.302367, zipCode: "13469"},
	{latitude: 43.209225, longitude: -74.666463, zipCode: "13470"},
	{latitude: 43.378027, longitude: -75.610579, zipCode: "13471"},
	{latitude: 43.697447, longitude: -75.068069, zipCode: "13472"},
	{latitude: 43.644146, longitude: -75.445192, zipCode: "13473"},
	{latitude: 42.887908, longitude: -74.811902, zipCode: "13475"},
	{latitude: 43.087503, longitude: -75.509514, zipCode: "13476"},
	{latitude: 43.03635, longitude: -75.514428, zipCode: "13477"},
	{latitude: 43.144012, longitude: -75.581897, zipCode: "13478"},
	{latitude: 42.917854, longitude: -75.363169, zipCode: "13480"},
	{latitude: 42.695183, longitude: -75.168175, zipCode: "13482"},
	{latitude: 43.406118, longitude: -75.814663, zipCode: "13483"},
	{latitude: 42.854205, longitude: -75.655448, zipCode: "13484"},
	{latitude: 42.787528, longitude: -75.315224, zipCode: "13485"},
	{latitude: 43.357311, longitude: -75.336896, zipCode: "13486"},
	{latitude: 42.674472, longitude: -74.76798, zipCode: "13488"},
	{latitude: 43.466569, longitude: -75.522386, zipCode: "13489"},
	{latitude: 43.106158, longitude: -75.429918, zipCode: "13490"},
	{latitude: 42.864135, longitude: -75.168133, zipCode: "13491"},
	{latitude: 43.114604, longitude: -75.33768, zipCode: "13492"},
	{latitude: 43.432843, longitude: -75.897509, zipCode: "13493"},
	{latitude: 43.5345, longitude: -75.152109, zipCode: "13494"},
	{latitude: 43.110927, longitude: -75.277596, zipCode: "13495"},
	{latitude: 43.081283, longitude: -75.225833, zipCode: "13501"},
	{latitude: 43.141264, longitude: -75.154707, zipCode: "13502"},
	{latitude: 43.969033, longitude: -75.910451, zipCode: "13601"},
	{latitude: 44.049565, longitude: -75.74891, zipCode: "13602"},
	{latitude: 44.029919, longitude: -75.803567, zipCode: "13603"},
	{latitude: 43.807377, longitude: -76.050124, zipCode: "13605"},
	{latitude: 43.869772, longitude: -76.016098, zipCode: "13606"},
	{latitude: 44.331832, longitude: -75.909506, zipCode: "13607"},
	{latitude: 44.26094, longitude: -75.613698, zipCode: "13608"},
	{latitude: 43.770091, longitude: -76.131182, zipCode: "13611"},
	{latitude: 43.985237, longitude: -75.76937, zipCode: "13612"},
	{latitude: 44.843198, longitude: -74.749024, zipCode: "13613"},
	{latitude: 44.530819, longitude: -75.691959, zipCode: "13614"},
	{latitude: 44.005307, longitude: -75.98307, zipCode: "13615"},
	{latitude: 44.030078, longitude: -75.857573, zipCode: "13616"},
	{latitude: 44.580531, longitude: -75.142767, zipCode: "13617"},
	{latitude: 44.114766, longitude: -76.290981, zipCode: "13618"},
	{latitude: 43.977357, longitude: -75.592254, zipCode: "13619"},
	{latitude: 43.908782, longitude: -75.448325, zipCode: "13620"},
	{latitude: 44.839402, longitude: -75.061698, zipCode: "13621"},
	{latitude: 44.095693, longitude: -76.115168, zipCode: "13622"},
	{latitude: 44.448693, longitude: -75.75305, zipCode: "13623"},
	{latitude: 44.229421, longitude: -76.093817, zipCode: "13624"},
	{latitude: 44.542508, longitude: -74.910389, zipCode: "13625"},
	{latitude: 43.853872, longitude: -75.718531, zipCode: "13626"},
	{latitude: 44.03904, longitude: -75.682765, zipCode: "13628"},
	{latitude: 44.498619, longitude: -75.311532, zipCode: "13630"},
	{latitude: 44.126946, longitude: -76.068232, zipCode: "13632"},
	{latitude: 44.484318, longitude: -75.499185, zipCode: "13633"},
	{latitude: 44.015036, longitude: -76.071765, zipCode: "13634"},
	{latitude: 44.286952, longitude: -75.281419, zipCode: "13635"},
	{latitude: 43.741087, longitude: -76.127692, zipCode: "13636"},
	{latitude: 44.104235, longitude: -75.842942, zipCode: "13637"},
	{latitude: 44.0196, longitude: -75.753501, zipCode: "13638"},
	{latitude: 44.263676, longitude: -75.150529, zipCode: "13639"},
	{latitude: 44.332661, longitude: -75.990153, zipCode: "13640"},
	{latitude: 44.27035, longitude: -76.006427, zipCode: "13641"},
	{latitude: 44.326672, longitude: -75.467588, zipCode: "13642"},
	{latitude: 44.032212, longitude: -75.717601, zipCode: "13643"},
	{latitude: 44.30878, longitude: -75.446555, zipCode: "13645"},
	{latitude: 44.443945, longitude: -75.691973, zipCode: "13646"},
	{latitude: 44.601656, longitude: -74.992702, zipCode: "13647"},
	{latitude: 44.150796, longitude: -75.304321, zipCode: "13648"},
	{latitude: 44.912408, longitude: -74.699785, zipCode: "13649"},
	{latitude: 43.81131, longitude: -76.216097, zipCode: "13650"},
	{latitude: 43.866158, longitude: -76.176966, zipCode: "13651"},
	{latitude: 44.434622, longitude: -75.213569, zipCode: "13652"},
	{latitude: 44.571772, longitude: -75.488098, zipCode: "13654"},
	{latitude: 44.978707, longitude: -74.643075, zipCode: "13655"},
	{latitude: 44.193265, longitude: -75.954645, zipCode: "13656"},
	{latitude: 44.747782, longitude: -75.271203, zipCode: "13658"},
	{latitude: 43.738005, longitude: -75.86338, zipCode: "13659"},
	{latitude: 44.773868, longitude: -75.160958, zipCode: "13660"},
	{latitude: 43.70962, longitude: -76.098396, zipCode: "13661"},
	{latitude: 44.937303, longitude: -74.894161, zipCode: "13662"},
	{latitude: 44.584013, longitude: -75.64546, zipCode: "13664"},
	{latitude: 44.04846, longitude: -75.434366, zipCode: "13665"},
	{latitude: 44.219176, longitude: -74.947563, zipCode: "13666"},
	{latitude: 44.802907, longitude: -75.020475, zipCode: "13667"},
	{latitude: 44.75482, longitude: -74.986306, zipCode: "13668"},
	{latitude: 44.659238, longitude: -75.491627, zipCode: "13669"},
	{latitude: 44.18679, longitude: -75.099813, zipCode: "13670"},
	{latitude: 44.470543, longitude: -74.660706, zipCode: "13672"},
	{latitude: 44.180845, longitude: -75.726044, zipCode: "13673"},
	{latitude: 43.737328, longitude: -76.051881, zipCode: "13674"},
	{latitude: 44.277033, longitude: -75.843656, zipCode: "13675"},
	{latitude: 44.655468, longitude: -74.910189, zipCode: "13676"},
	{latitude: 44.831635, longitude: -74.967796, zipCode: "13678"},
	{latitude: 44.326117, longitude: -75.765192, zipCode: "13679"},
	{latitude: 44.593601, longitude: -75.329147, zipCode: "13680"},
	{latitude: 44.428054, longitude: -75.373151, zipCode: "13681"},
	{latitude: 43.843312, longitude: -75.901905, zipCode: "13682"},
	{latitude: 44.973878, longitude: -74.735483, zipCode: "13683"},
	{latitude: 44.356596, longitude: -75.013141, zipCode: "13684"},
	{latitude: 43.925469, longitude: -76.085527, zipCode: "13685"},
	{latitude: 44.439018, longitude: -74.838177, zipCode: "13687"},
	{latitude: 44.136508, longitude: -75.01441, zipCode: "13690"},
	{latitude: 44.231562, longitude: -75.770537, zipCode: "13691"},
	{latitude: 44.288621, longitude: -76.02574, zipCode: "13692"},
	{latitude: 43.985747, longitude: -76.246105, zipCode: "13693"},
	{latitude: 44.859437, longitude: -75.170668, zipCode: "13694"},
	{latitude: 44.102929, longitude: -74.922258, zipCode: "13695"},
	{latitude: 44.70411, longitude: -74.889639, zipCode: "13696"},
	{latitude: 44.754714, longitude: -74.810347, zipCode: "13697"},
	{latitude: 44.663703, longitude: -74.998445, zipCode: "13699"},
	{latitude: 42.231996, longitude: -75.532645, zipCode: "13730"},
	{latitude: 42.12963, longitude: -74.787804, zipCode: "13731"},
	{latitude: 42.049447, longitude: -76.167535, zipCode: "13732"},
	{latitude: 42.301807, longitude: -75.479756, zipCode: "13733"},
	{latitude: 42.069955, longitude: -76.409653, zipCode: "13734"},
	{latitude: 42.323529, longitude: -76.192941, zipCode: "13736"},
	{latitude: 42.562317, longitude: -76.119762, zipCode: "13738"},
	{latitude: 42.349124, longitude: -74.789149, zipCode: "13739"},
	{latitude: 42.273812, longitude: -74.756997, zipCode: "13740"},
	{latitude: 42.211894, longitude: -76.338222, zipCode: "13743"},
	{latitude: 42.243494, longitude: -75.907999, zipCode: "13744"},
	{latitude: 42.237222, longitude: -75.862775, zipCode: "13746"},
	{latitude: 42.48997, longitude: -74.982049, zipCode: "13747"},
	{latitude: 42.042575, longitude: -75.822509, zipCode: "13748"},
	{latitude: 42.477535, longitude: -74.835779, zipCode: "13750"},
	{latitude: 42.45823, longitude: -74.875158, zipCode: "13751"},
	{latitude: 42.172981, longitude: -74.903366, zipCode: "13752"},
	{latitude: 42.30696, longitude: -74.926915, zipCode: "13753"},
	{latitude: 42.088461, longitude: -75.447935, zipCode: "13754"},
	{latitude: 42.071439, longitude: -75.023923, zipCode: "13755"},
	{latitude: 42.004099, longitude: -75.10067, zipCode: "13756"},
	{latitude: 42.43209, longitude: -74.837828, zipCode: "13757"},
	{latitude: 42.133332, longitude: -76.084622, zipCode: "13760"},
	{latitude: 41.958737, longitude: -75.184604, zipCode: "13774"},
	{latitude: 42.327909, longitude: -75.134022, zipCode: "13775"},
	{latitude: 42.471138, longitude: -75.34124, zipCode: "13776"},
	{latitude: 42.258939, longitude: -75.986621, zipCode: "13777"},
	{latitude: 42.359214, longitude: -75.759499, zipCode: "13778"},
	{latitude: 42.422485, longitude: -75.476583, zipCode: "13780"},
	{latitude: 42.170115, longitude: -74.991251, zipCode: "13782"},
	{latitude: 41.986818, longitude: -75.271017, zipCode: "13783"},
	{latitude: 42.435289, longitude: -76.217749, zipCode: "13784"},
	{latitude: 42.445214, longitude: -74.702862, zipCode: "13786"},
	{latitude: 42.2184, longitude: -75.676696, zipCode: "13787"},
	{latitude: 42.339007, longitude: -74.666257, zipCode: "13788"},
	{latitude: 42.165361, longitude: -76.001942, zipCode: "13790"},
	{latitude: 42.056466, longitude: -75.779234, zipCode: "13795"},
	{latitude: 42.557188, longitude: -75.13213, zipCode: "13796"},
	{latitude: 42.333665, longitude: -76.040401, zipCode: "13797"},
	{latitude: 42.505085, longitude: -75.77697, zipCode: "13801"},
	{latitude: 42.242134, longitude: -76.041266, zipCode: "13802"},
	{latitude: 42.457979, longitude: -76.053971, zipCode: "13803"},
	{latitude: 42.225722, longitude: -75.384222, zipCode: "13804"},
	{latitude: 42.380639, longitude: -74.976099, zipCode: "13806"},
	{latitude: 42.61747, longitude: -74.97282, zipCode: "13807"},
	{latitude: 42.525233, longitude: -75.2622, zipCode: "13808"},
	{latitude: 42.408479, longitude: -75.391387, zipCode: "13809"},
	{latitude: 42.606817, longitude: -75.108363, zipCode: "13810"},
	{latitude: 42.234926, longitude: -76.164054, zipCode: "13811"},
	{latitude: 42.03006, longitude: -76.354752, zipCode: "13812"},
	{latitude: 42.180591, longitude: -75.550314, zipCode: "13813"},
	{latitude: 42.616514, longitude: -75.525978, zipCode: "13814"},
	{latitude: 42.547416, longitude: -75.533555, zipCode: "13815"},
	{latitude: 42.483412, longitude: -75.036892, zipCode: "13820"},
	{latitude: 42.435577, longitude: -75.204553, zipCode: "13825"},
	{latitude: 42.091802, longitude: -75.633696, zipCode: "13826"},
	{latitude: 42.117827, longitude: -76.249166, zipCode: "13827"},
	{latitude: 42.442748, longitude: -75.644652, zipCode: "13830"},
	{latitude: 42.654592, longitude: -75.662548, zipCode: "13832"},
	{latitude: 42.19801, longitude: -75.761634, zipCode: "13833"},
	{latitude: 42.55051, longitude: -74.967342, zipCode: "13834"},
	{latitude: 42.383214, longitude: -76.178996, zipCode: "13835"},
	{latitude: 42.288548, longitude: -75.396089, zipCode: "13838"},
	{latitude: 42.240354, longitude: -75.259059, zipCode: "13839"},
	{latitude: 42.40746, longitude: -75.840256, zipCode: "13841"},
	{latitude: 42.367883, longitude: -74.721761, zipCode: "13842"},
	{latitude: 42.556073, longitude: -75.349014, zipCode: "13843"},
	{latitude: 42.605699, longitude: -75.689554, zipCode: "13844"},
	{latitude: 42.363766, longitude: -75.054796, zipCode: "13846"},
	{latitude: 42.204496, longitude: -75.277712, zipCode: "13847"},
	{latitude: 42.341173, longitude: -75.321965, zipCode: "13849"},
	{latitude: 42.049395, longitude: -76.017594, zipCode: "13850"},
	{latitude: 42.171041, longitude: -75.183316, zipCode: "13856"},
	{latitude: 42.371214, longitude: -75.249146, zipCode: "13859"},
	{latitude: 42.458443, longitude: -74.945534, zipCode: "13860"},
	{latitude: 42.504527, longitude: -75.138979, zipCode: "13861"},
	{latitude: 42.33092, longitude: -75.931125, zipCode: "13862"},
	{latitude: 42.435402, longitude: -75.906243, zipCode: "13863"},
	{latitude: 42.274969, longitude: -76.390479, zipCode: "13864"},
	{latitude: 42.058561, longitude: -75.62477, zipCode: "13865"},
	{latitude: 42.183203, longitude: -75.876048, zipCode: "13901"},
	{latitude: 42.088824, longitude: -75.968883, zipCode: "13902"},
	{latitude: 42.04187, longitude: -75.889077, zipCode: "13903"},
	{latitude: 42.134123, longitude: -75.820206, zipCode: "13904"},
	{latitude: 42.170436, longitude: -75.943434, zipCode: "13905"},
	{latitude: 43.036573, longitude: -78.510831, zipCode: "14001"},
	{latitude: 42.891377, longitude: -78.503698, zipCode: "14004"},
	{latitude: 42.920586, longitude: -78.250065, zipCode: "14005"},
	{latitude: 42.63313, longitude: -79.021745, zipCode: "14006"},
	{latitude: 43.312726, longitude: -78.623166, zipCode: "14008"},
	{latitude: 42.593154, longitude: -78.397461, zipCode: "14009"},
	{latitude: 42.830674, longitude: -78.299709, zipCode: "14011"},
	{latitude: 43.332173, longitude: -78.532622, zipCode: "14012"},
	{latitude: 43.07623, longitude: -78.39429, zipCode: "14013"},
	{latitude: 42.99619, longitude: -78.213412, zipCode: "14020"},
	{latitude: 42.581363, longitude: -78.245468, zipCode: "14024"},
	{latitude: 42.623195, longitude: -78.726064, zipCode: "14025"},
	{latitude: 42.942195, longitude: -78.687972, zipCode: "14026"},
	{latitude: 42.594092, longitude: -79.020662, zipCode: "14027"},
	{latitude: 43.314596, longitude: -78.717056, zipCode: "14028"},
	{latitude: 42.472913, longitude: -78.263441, zipCode: "14029"},
	{latitude: 42.559702, longitude: -78.490061, zipCode: "14030"},
	{latitude: 42.983128, longitude: -78.614258, zipCode: "14031"},
	{latitude: 43.045872, longitude: -78.631896, zipCode: "14032"},
	{latitude: 42.660303, longitude: -78.684251, zipCode: "14033"},
	{latitude: 42.500569, longitude: -78.865375, zipCode: "14034"},
	{latitude: 42.490983, longitude: -78.859431, zipCode: "14035"},
	{latitude: 42.973851, longitude: -78.389527, zipCode: "14036"},
	{latitude: 42.802789, longitude: -78.453328, zipCode: "14037"},
	{latitude: 42.85148, longitude: -78.177691, zipCode: "14039"},
	{latitude: 42.882168, longitude: -78.377535, zipCode: "14040"},
	{latitude: 42.400393, longitude: -78.976646, zipCode: "14041"},
	{latitude: 42.475564, longitude: -78.488807, zipCode: "14042"},
	{latitude: 42.900724, longitude: -78.704522, zipCode: "14043"},
	{latitude: 42.687367, longitude: -78.986636, zipCode: "14047"},
	{latitude: 42.486763, longitude: -79.313622, zipCode: "14048"},
	{latitude: 43.042842, longitude: -78.699413, zipCode: "14051"},
	{latitude: 42.772531, longitude: -78.584321, zipCode: "14052"},
	{latitude: 42.908974, longitude: -78.127841, zipCode: "14054"},
	{latitude: 42.56288, longitude: -78.600125, zipCode: "14055"},
	{latitude: 42.991805, longitude: -78.306603, zipCode: "14056"},
	{latitude: 42.646574, longitude: -78.874782, zipCode: "14057"},
	{latitude: 43.102049, longitude: -78.168857, zipCode: "14058"},
	{latitude: 42.833552, longitude: -78.634046, zipCode: "14059"},
	{latitude: 42.441626, longitude: -78.316063, zipCode: "14060"},
	{latitude: 42.5911, longitude: -79.089486, zipCode: "14061"},
	{latitude: 42.450893, longitude: -79.152595, zipCode: "14062"},
	{latitude: 42.40869, longitude: -79.331304, zipCode: "14063"},
	{latitude: 42.484905, longitude: -78.312811, zipCode: "14065"},
	{latitude: 42.629054, longitude: -78.185536, zipCode: "14066"},
	{latitude: 43.21216, longitude: -78.556274, zipCode: "14067"},
	{latitude: 43.02877, longitude: -78.758797, zipCode: "14068"},
	{latitude: 42.600306, longitude: -78.638389, zipCode: "14069"},
	{latitude: 42.419658, longitude: -78.917725, zipCode: "14070"},
	{latitude: 43.017901, longitude: -78.962657, zipCode: "14072"},
	{latitude: 42.713375, longitude: -78.830255, zipCode: "14075"},
	{latitude: 42.634728, longitude: -78.560834, zipCode: "14080"},
	{latitude: 42.563203, longitude: -79.067834, zipCode: "14081"},
	{latitude: 42.656563, longitude: -78.385184, zipCode: "14082"},
	{latitude: 42.679203, longitude: -78.434411, zipCode: "14083"},
	{latitude: 42.714292, longitude: -78.927674, zipCode: "14085"},
	{latitude: 42.905511, longitude: -78.629121, zipCode: "14086"},
	{latitude: 42.538233, longitude: -78.893008, zipCode: "14091"},
	{latitude: 43.173245, longitude: -78.993068, zipCode: "14092"},
	{latitude: 43.157758, longitude: -78.701995, zipCode: "14094"},
	{latitude: 43.334633, longitude: -78.378309, zipCode: "14098"},
	{latitude: 42.392651, longitude: -78.538203, zipCode: "14101"},
	{latitude: 42.842726, longitude: -78.554821, zipCode: "14102"},
	{latitude: 43.211161, longitude: -78.37677, zipCode: "14103"},
	{latitude: 43.180186, longitude: -78.486578, zipCode: "14105"},
	{latitude: 43.263678, longitude: -78.727477, zipCode: "14108"},
	{latitude: 43.137212, longitude: -79.035127, zipCode: "14109"},
	{latitude: 42.584777, longitude: -78.895112, zipCode: "14111"},
	{latitude: 42.698262, longitude: -78.939582, zipCode: "14112"},
	{latitude: 42.660593, longitude: -78.322567, zipCode: "14113"},
	{latitude: 43.079176, longitude: -78.842777, zipCode: "14120"},
	{latitude: 43.084919, longitude: -78.274609, zipCode: "14125"},
	{latitude: 43.335884, longitude: -78.72858, zipCode: "14126"},
	{latitude: 42.752803, longitude: -78.739697, zipCode: "14127"},
	{latitude: 42.481182, longitude: -79.017384, zipCode: "14129"},
	{latitude: 42.553172, longitude: -78.149542, zipCode: "14130"},
	{latitude: 43.238603, longitude: -78.899823, zipCode: "14131"},
	{latitude: 43.147873, longitude: -78.878565, zipCode: "14132"},
	{latitude: 42.53055, longitude: -78.517385, zipCode: "14134"},
	{latitude: 42.484894, longitude: -79.243699, zipCode: "14135"},
	{latitude: 42.517379, longitude: -79.174998, zipCode: "14136"},
	{latitude: 42.383178, longitude: -79.05262, zipCode: "14138"},
	{latitude: 42.718405, longitude: -78.541402, zipCode: "14139"},
	{latitude: 42.524967, longitude: -78.712076, zipCode: "14141"},
	{latitude: 42.977044, longitude: -78.0758, zipCode: "14143"},
	{latitude: 42.734036, longitude: -78.428432, zipCode: "14145"},
	{latitude: 42.998084, longitude: -78.878256, zipCode: "14150"},
	{latitude: 42.450971, longitude: -79.416054, zipCode: "14166"},
	{latitude: 42.74841, longitude: -78.323002, zipCode: "14167"},
	{latitude: 42.511876, longitude: -78.987407, zipCode: "14168"},
	{latitude: 42.764514, longitude: -78.52935, zipCode: "14169"},
	{latitude: 42.700245, longitude: -78.674307, zipCode: "14170"},
	{latitude: 42.424674, longitude: -78.649832, zipCode: "14171"},
	{latitude: 43.272672, longitude: -78.812943, zipCode: "14172"},
	{latitude: 42.525944, longitude: -78.473112, zipCode: "14173"},
	{latitude: 43.249139, longitude: -78.998325, zipCode: "14174"},
	{latitude: 42.89763, longitude: -78.887897, zipCode: "14201"},
	{latitude: 42.881493, longitude: -78.877484, zipCode: "14202"},
	{latitude: 42.861134, longitude: -78.863606, zipCode: "14203"},
	{latitude: 42.881789, longitude: -78.861904, zipCode: "14204"},
	{latitude: 42.88081, longitude: -78.810156, zipCode: "14206"},
	{latitude: 42.951932, longitude: -78.898883, zipCode: "14207"},
	{latitude: 42.914615, longitude: -78.851459, zipCode: "14208"},
	{latitude: 42.91367, longitude: -78.866209, zipCode: "14209"},
	{latitude: 42.862657, longitude: -78.828768, zipCode: "14210"},
	{latitude: 42.906731, longitude: -78.819891, zipCode: "14211"},
	{latitude: 42.895001, longitude: -78.819515, zipCode: "14212"},
	{latitude: 42.917239, longitude: -78.891814, zipCode: "14213"},
	{latitude: 42.939793, longitude: -78.841077, zipCode: "14214"},
	{latitude: 42.934545, longitude: -78.810223, zipCode: "14215"},
	{latitude: 42.949629, longitude: -78.862483, zipCode: "14216"},
	{latitude: 42.971927, longitude: -78.876759, zipCode: "14217"},
	{latitude: 42.818798, longitude: -78.82904, zipCode: "14218"},
	{latitude: 42.788675, longitude: -78.826431, zipCode: "14219"},
	{latitude: 42.845738, longitude: -78.822076, zipCode: "14220"},
	{latitude: 42.984481, longitude: -78.722761, zipCode: "14221"},
	{latitude: 42.920027, longitude: -78.877108, zipCode: "14222"},
	{latitude: 42.973459, longitude: -78.846106, zipCode: "14223"},
	{latitude: 42.837759, longitude: -78.747821, zipCode: "14224"},
	{latitude: 42.928949, longitude: -78.750273, zipCode: "14225"},
	{latitude: 42.970345, longitude: -78.798005, zipCode: "14226"},
	{latitude: 42.883995, longitude: -78.746668, zipCode: "14227"},
	{latitude: 43.044371, longitude: -78.777335, zipCode: "14228"},
	{latitude: 43.001682, longitude: -78.790638, zipCode: "14260"},
	{latitude: 42.997126, longitude: -78.792129, zipCode: "14261"},
	{latitude: 43.097013, longitude: -79.037322, zipCode: "14301"},
	{latitude: 43.093896, longitude: -79.049193, zipCode: "14302"},
	{latitude: 43.084183, longitude: -79.036746, zipCode: "14303"},
	{latitude: 43.104953, longitude: -78.94805, zipCode: "14304"},
	{latitude: 43.122831, longitude: -79.023218, zipCode: "14305"},
	{latitude: 43.235972, longitude: -78.216477, zipCode: "14411"},
	{latitude: 43.222891, longitude: -76.970649, zipCode: "14413"},
	{latitude: 42.890961, longitude: -77.743757, zipCode: "14414"},
	{latitude: 42.755894, longitude: -77.016502, zipCode: "14415"},
	{latitude: 43.082693, longitude: -77.980115, zipCode: "14416"},
	{latitude: 42.60443, longitude: -77.216428, zipCode: "14418"},
	{latitude: 43.21444, longitude: -77.929197, zipCode: "14420"},
	{latitude: 43.084761, longitude: -78.066965, zipCode: "14422"},
	{latitude: 42.937674, longitude: -77.835232, zipCode: "14423"},
	{latitude: 42.856357, longitude: -77.303497, zipCode: "14424"},
	{latitude: 42.98961, longitude: -77.337344, zipCode: "14425"},
	{latitude: 42.614856, longitude: -78.051232, zipCode: "14427"},
	{latitude: 43.080517, longitude: -77.853568, zipCode: "14428"},
	{latitude: 42.955669, longitude: -77.140753, zipCode: "14432"},
	{latitude: 43.077171, longitude: -76.8787, zipCode: "14433"},
	{latitude: 42.712552, longitude: -77.663312, zipCode: "14435"},
	{latitude: 42.570141, longitude: -77.733764, zipCode: "14437"},
	{latitude: 42.686444, longitude: -76.958456, zipCode: "14441"},
	{latitude: 43.113062, longitude: -77.490306, zipCode: "14445"},
	{latitude: 43.092445, longitude: -77.420338, zipCode: "14450"},
	{latitude: 43.016056, longitude: -77.473575, zipCode: "14453"},
	{latitude: 42.802701, longitude: -77.767162, zipCode: "14454"},
	{latitude: 42.847695, longitude: -76.998972, zipCode: "14456"},
	{latitude: 42.686511, longitude: -77.751115, zipCode: "14462"},
	{latitude: 42.795434, longitude: -77.068892, zipCode: "14463"},
	{latitude: 43.325801, longitude: -77.939474, zipCode: "14464"},
	{latitude: 42.776194, longitude: -77.577488, zipCode: "14466"},
	{latitude: 43.036374, longitude: -77.612048, zipCode: "14467"},
	{latitude: 43.293956, longitude: -77.802049, zipCode: "14468"},
	{latitude: 42.877919, longitude: -77.471014, zipCode: "14469"},
	{latitude: 43.205212, longitude: -78.057685, zipCode: "14470"},
	{latitude: 42.743071, longitude: -77.491175, zipCode: "14471"},
	{latitude: 42.965247, longitude: -77.583045, zipCode: "14472"},
	{latitude: 42.936829, longitude: -77.497884, zipCode: "14475"},
	{latitude: 43.329379, longitude: -78.0428, zipCode: "14476"},
	{latitude: 43.335707, longitude: -78.133393, zipCode: "14477"},
	{latitude: 42.580088, longitude: -77.127889, zipCode: "14478"},
	{latitude: 43.236298, longitude: -78.304495, zipCode: "14479"},
	{latitude: 42.838209, longitude: -77.707442, zipCode: "14480"},
	{latitude: 42.771735, longitude: -77.916407, zipCode: "14481"},
	{latitude: 42.967367, longitude: -77.971924, zipCode: "14482"},
	{latitude: 42.882635, longitude: -77.601026, zipCode: "14485"},
	{latitude: 42.892916, longitude: -77.922957, zipCode: "14486"},
	{latitude: 42.808205, longitude: -77.640012, zipCode: "14487"},
	{latitude: 43.089845, longitude: -76.994319, zipCode: "14489"},
	{latitude: 43.096594, longitude: -77.335388, zipCode: "14502"},
	{latitude: 42.968432, longitude: -77.232698, zipCode: "14504"},
	{latitude: 43.158564, longitude: -77.174327, zipCode: "14505"},
	{latitude: 43.002223, longitude: -77.505265, zipCode: "14506"},
	{latitude: 42.684916, longitude: -77.254886, zipCode: "14507"},
	{latitude: 42.696029, longitude: -77.866903, zipCode: "14510"},
	{latitude: 42.999374, longitude: -77.89179, zipCode: "14511"},
	{latitude: 42.645223, longitude: -77.39977, zipCode: "14512"},
	{latitude: 43.075907, longitude: -77.100601, zipCode: "14513"},
	{latitude: 43.078872, longitude: -77.81083, zipCode: "14514"},
	{latitude: 43.252837, longitude: -77.731642, zipCode: "14515"},
	{latitude: 43.19786, longitude: -76.911334, zipCode: "14516"},
	{latitude: 42.592188, longitude: -77.897084, zipCode: "14517"},
	{latitude: 43.233972, longitude: -77.314631, zipCode: "14519"},
	{latitude: 42.676642, longitude: -76.805003, zipCode: "14521"},
	{latitude: 43.059829, longitude: -77.220283, zipCode: "14522"},
	{latitude: 42.877513, longitude: -78.013345, zipCode: "14525"},
	{latitude: 43.151464, longitude: -77.444396, zipCode: "14526"},
	{latitude: 42.666152, longitude: -77.063252, zipCode: "14527"},
	{latitude: 42.537549, longitude: -77.644143, zipCode: "14529"},
	{latitude: 42.73991, longitude: -77.998746, zipCode: "14530"},
	{latitude: 42.964101, longitude: -77.032088, zipCode: "14532"},
	{latitude: 42.847473, longitude: -77.883014, zipCode: "14533"},
	{latitude: 43.05689, longitude: -77.521042, zipCode: "14534"},
	{latitude: 42.537621, longitude: -78.07872, zipCode: "14536"},
	{latitude: 43.035317, longitude: -77.155992, zipCode: "14537"},
	{latitude: 42.834075, longitude: -77.875281, zipCode: "14539"},
	{latitude: 42.765059, longitude: -76.848967, zipCode: "14541"},
	{latitude: 43.153106, longitude: -76.875842, zipCode: "14542"},
	{latitude: 42.983831, longitude: -77.678535, zipCode: "14543"},
	{latitude: 42.756082, longitude: -77.240018, zipCode: "14544"},
	{latitude: 42.66163, longitude: -77.710568, zipCode: "14545"},
	{latitude: 43.032728, longitude: -77.78011, zipCode: "14546"},
	{latitude: 42.975396, longitude: -77.243463, zipCode: "14548"},
	{latitude: 42.696731, longitude: -78.022097, zipCode: "14549"},
	{latitude: 42.679902, longitude: -78.093487, zipCode: "14550"},
	{latitude: 43.218114, longitude: -77.049402, zipCode: "14551"},
	{latitude: 43.268001, longitude: -76.977887, zipCode: "14555"},
	{latitude: 42.679036, longitude: -77.832218, zipCode: "14556"},
	{latitude: 43.043449, longitude: -78.068195, zipCode: "14557"},
	{latitude: 43.18998, longitude: -77.819052, zipCode: "14559"},
	{latitude: 42.690702, longitude: -77.582963, zipCode: "14560"},
	{latitude: 42.822162, longitude: -77.131349, zipCode: "14561"},
	{latitude: 42.986733, longitude: -77.434439, zipCode: "14564"},
	{latitude: 43.143826, longitude: -77.286397, zipCode: "14568"},
	{latitude: 42.750485, longitude: -78.169399, zipCode: "14569"},
	{latitude: 43.341652, longitude: -78.251742, zipCode: "14571"},
	{latitude: 42.564925, longitude: -77.562598, zipCode: "14572"},
	{latitude: 43.217283, longitude: -77.444534, zipCode: "14580"},
	{latitude: 42.905821, longitude: -77.552937, zipCode: "14585"},
	{latitude: 43.041675, longitude: -77.689904, zipCode: "14586"},
	{latitude: 42.679746, longitude: -76.870399, zipCode: "14588"},
	{latitude: 43.239092, longitude: -77.169093, zipCode: "14589"},
	{latitude: 43.245357, longitude: -76.832127, zipCode: "14590"},
	{latitude: 42.832085, longitude: -78.107386, zipCode: "14591"},
	{latitude: 42.868786, longitude: -77.890565, zipCode: "14592"},
	{latitude: 43.156696, longitude: -77.605248, zipCode: "14604"},
	{latitude: 43.168418, longitude: -77.600394, zipCode: "14605"},
	{latitude: 43.171047, longitude: -77.697953, zipCode: "14606"},
	{latitude: 43.150932, longitude: -77.586809, zipCode: "14607"},
	{latitude: 43.154099, longitude: -77.62293, zipCode: "14608"},
	{latitude: 43.177125, longitude: -77.551821, zipCode: "14609"},
	{latitude: 43.140967, longitude: -77.544661, zipCode: "14610"},
	{latitude: 43.148316, longitude: -77.646987, zipCode: "14611"},
	{latitude: 43.263641, longitude: -77.672599, zipCode: "14612"},
	{latitude: 43.182171, longitude: -77.640175, zipCode: "14613"},
	{latitude: 43.157614, longitude: -77.614742, zipCode: "14614"},
	{latitude: 43.201207, longitude: -77.654749, zipCode: "14615"},
	{latitude: 43.234333, longitude: -77.658213, zipCode: "14616"},
	{latitude: 43.225816, longitude: -77.586572, zipCode: "14617"},
	{latitude: 43.114542, longitude: -77.554976, zipCode: "14618"},
	{latitude: 43.136456, longitude: -77.649477, zipCode: "14619"},
	{latitude: 43.128342, longitude: -77.604424, zipCode: "14620"},
	{latitude: 43.189939, longitude: -77.603649, zipCode: "14621"},
	{latitude: 43.214044, longitude: -77.550946, zipCode: "14622"},
	{latitude: 43.087349, longitude: -77.641862, zipCode: "14623"},
	{latitude: 43.1283, longitude: -77.731411, zipCode: "14624"},
	{latitude: 43.150045, longitude: -77.505526, zipCode: "14625"},
	{latitude: 43.213733, longitude: -77.712294, zipCode: "14626"},
	{latitude: 43.128552, longitude: -77.630071, zipCode: "14627"},
	{latitude: 43.122834, longitude: -77.625114, zipCode: "14642"},
	{latitude: 42.077769, longitude: -79.259072, zipCode: "14701"},
	{latitude: 42.106183, longitude: -78.528294, zipCode: "14706"},
	{latitude: 42.082618, longitude: -78.061983, zipCode: "14707"},
	{latitude: 42.019219, longitude: -78.063617, zipCode: "14708"},
	{latitude: 42.356494, longitude: -77.982064, zipCode: "14709"},
	{latitude: 42.087902, longitude: -79.419847, zipCode: "14710"},
	{latitude: 42.318416, longitude: -78.127907, zipCode: "14711"},
	{latitude: 42.183745, longitude: -79.361923, zipCode: "14712"},
	{latitude: 42.289988, longitude: -78.241131, zipCode: "14714"},
	{latitude: 42.07653, longitude: -78.14475, zipCode: "14715"},
	{latitude: 42.3811, longitude: -79.437249, zipCode: "14716"},
	{latitude: 42.361827, longitude: -78.179534, zipCode: "14717"},
	{latitude: 42.345879, longitude: -79.287596, zipCode: "14718"},
	{latitude: 42.341958, longitude: -78.875953, zipCode: "14719"},
	{latitude: 42.108569, longitude: -79.279928, zipCode: "14720"},
	{latitude: 42.016554, longitude: -78.268048, zipCode: "14721"},
	{latitude: 42.209608, longitude: -79.468799, zipCode: "14722"},
	{latitude: 42.317615, longitude: -79.157121, zipCode: "14723"},
	{latitude: 42.046328, longitude: -79.66983, zipCode: "14724"},
	{latitude: 42.253585, longitude: -79.020279, zipCode: "14726"},
	{latitude: 42.213577, longitude: -78.293095, zipCode: "14727"},
	{latitude: 42.272615, longitude: -79.410541, zipCode: "14728"},
	{latitude: 42.408377, longitude: -78.743584, zipCode: "14729"},
	{latitude: 42.307351, longitude: -78.655503, zipCode: "14731"},
	{latitude: 42.218904, longitude: -79.107166, zipCode: "14732"},
	{latitude: 42.16022, longitude: -79.172137, zipCode: "14733"},
	{latitude: 42.45269, longitude: -78.096169, zipCode: "14735"},
	{latitude: 42.137107, longitude: -79.746404, zipCode: "14736"},
	{latitude: 42.32905, longitude: -78.428611, zipCode: "14737"},
	{latitude: 42.052922, longitude: -79.057633, zipCode: "14738"},
	{latitude: 42.186661, longitude: -78.156823, zipCode: "14739"},
	{latitude: 42.223203, longitude: -79.181435, zipCode: "14740"},
	{latitude: 42.219966, longitude: -78.611624, zipCode: "14741"},
	{latitude: 42.119453, longitude: -79.308881, zipCode: "14742"},
	{latitude: 42.21157, longitude: -78.410443, zipCode: "14743"},
	{latitude: 42.4281, longitude: -78.206654, zipCode: "14744"},
	{latitude: 42.153715, longitude: -79.094518, zipCode: "14747"},
	{latitude: 42.157106, longitude: -78.643074, zipCode: "14748"},
	{latitude: 42.079366, longitude: -79.327543, zipCode: "14750"},
	{latitude: 42.351735, longitude: -79.319805, zipCode: "14752"},
	{latitude: 42.043502, longitude: -78.636095, zipCode: "14753"},
	{latitude: 42.021908, longitude: -78.201569, zipCode: "14754"},
	{latitude: 42.228815, longitude: -78.815928, zipCode: "14755"},
	{latitude: 42.197034, longitude: -79.423654, zipCode: "14756"},
	{latitude: 42.237507, longitude: -79.505285, zipCode: "14757"},
	{latitude: 42.073087, longitude: -78.417553, zipCode: "14760"},
	{latitude: 42.057352, longitude: -79.514135, zipCode: "14767"},
	{latitude: 42.385908, longitude: -79.472681, zipCode: "14769"},
	{latitude: 42.037929, longitude: -78.300296, zipCode: "14770"},
	{latitude: 42.144376, longitude: -78.954425, zipCode: "14772"},
	{latitude: 42.088784, longitude: -78.146662, zipCode: "14774"},
	{latitude: 42.228753, longitude: -79.699933, zipCode: "14775"},
	{latitude: 42.38608, longitude: -78.273772, zipCode: "14777"},
	{latitude: 42.077618, longitude: -78.482745, zipCode: "14778"},
	{latitude: 42.106257, longitude: -78.769659, zipCode: "14779"},
	{latitude: 42.166709, longitude: -79.608737, zipCode: "14781"},
	{latitude: 42.266572, longitude: -79.251727, zipCode: "14782"},
	{latitude: 42.067192, longitude: -78.92861, zipCode: "14783"},
	{latitude: 42.311143, longitude: -79.385369, zipCode: "14784"},
	{latitude: 42.155777, longitude: -79.403387, zipCode: "14785"},
	{latitude: 42.317521, longitude: -79.569503, zipCode: "14787"},
	{latitude: 42.061465, longitude: -78.380575, zipCode: "14788"},
	{latitude: 42.104056, longitude: -77.292968, zipCode: "14801"},
	{latitude: 42.253779, longitude: -77.789892, zipCode: "14802"},
	{latitude: 42.233277, longitude: -77.795082, zipCode: "14803"},
	{latitude: 42.314417, longitude: -77.851435, zipCode: "14804"},
	{latitude: 42.350109, longitude: -76.719369, zipCode: "14805"},
	{latitude: 42.158771, longitude: -77.787955, zipCode: "14806"},
	{latitude: 42.419611, longitude: -77.720511, zipCode: "14807"},
	{latitude: 42.55807, longitude: -77.468832, zipCode: "14808"},
	{latitude: 42.424318, longitude: -77.448755, zipCode: "14809"},
	{latitude: 42.347484, longitude: -77.346851, zipCode: "14810"},
	{latitude: 42.300727, longitude: -77.005249, zipCode: "14812"},
	{latitude: 42.252647, longitude: -77.995104, zipCode: "14813"},
	{latitude: 42.155636, longitude: -76.9593, zipCode: "14814"},
	{latitude: 42.374287, longitude: -77.086786, zipCode: "14815"},
	{latitude: 42.197734, longitude: -76.735603, zipCode: "14816"},
	{latitude: 42.357625, longitude: -76.335167, zipCode: "14817"},
	{latitude: 42.454933, longitude: -76.818927, zipCode: "14818"},
	{latitude: 42.224194, longitude: -77.441939, zipCode: "14819"},
	{latitude: 42.195309, longitude: -77.370388, zipCode: "14820"},
	{latitude: 42.241574, longitude: -77.219254, zipCode: "14821"},
	{latitude: 42.439046, longitude: -77.846855, zipCode: "14822"},
	{latitude: 42.2421, longitude: -77.529481, zipCode: "14823"},
	{latitude: 42.265778, longitude: -76.69308, zipCode: "14824"},
	{latitude: 42.077665, longitude: -76.613219, zipCode: "14825"},
	{latitude: 42.496244, longitude: -77.4925, zipCode: "14826"},
	{latitude: 42.180651, longitude: -77.142885, zipCode: "14827"},
	{latitude: 42.128266, longitude: -77.026279, zipCode: "14830"},
	{latitude: 42.53301, longitude: -77.869746, zipCode: "14836"},
	{latitude: 42.500223, longitude: -77.019376, zipCode: "14837"},
	{latitude: 42.189364, longitude: -76.667087, zipCode: "14838"},
	{latitude: 42.147524, longitude: -77.627811, zipCode: "14839"},
	{latitude: 42.441653, longitude: -77.194396, zipCode: "14840"},
	{latitude: 42.527, longitude: -76.842484, zipCode: "14841"},
	{latitude: 42.60062, longitude: -76.981464, zipCode: "14842"},
	{latitude: 42.334255, longitude: -77.635279, zipCode: "14843"},
	{latitude: 42.203208, longitude: -76.83934, zipCode: "14845"},
	{latitude: 42.528543, longitude: -78.004367, zipCode: "14846"},
	{latitude: 42.60849, longitude: -76.734238, zipCode: "14847"},
	{latitude: 42.431831, longitude: -76.500228, zipCode: "14850"},
	{latitude: 42.446404, longitude: -76.481231, zipCode: "14853"},
	{latitude: 42.511524, longitude: -76.607446, zipCode: "14854"},
	{latitude: 42.1457, longitude: -77.497396, zipCode: "14855"},
	{latitude: 42.377324, longitude: -77.369404, zipCode: "14856"},
	{latitude: 42.02979, longitude: -77.134956, zipCode: "14858"},
	{latitude: 42.124099, longitude: -76.536873, zipCode: "14859"},
	{latitude: 42.591759, longitude: -76.842356, zipCode: "14860"},
	{latitude: 42.088212, longitude: -76.686236, zipCode: "14861"},
	{latitude: 42.286689, longitude: -76.844478, zipCode: "14864"},
	{latitude: 42.352721, longitude: -76.816924, zipCode: "14865"},
	{latitude: 42.33714, longitude: -76.610245, zipCode: "14867"},
	{latitude: 42.36232, longitude: -76.768965, zipCode: "14869"},
	{latitude: 42.160375, longitude: -77.127609, zipCode: "14870"},
	{latitude: 42.039591, longitude: -76.911352, zipCode: "14871"},
	{latitude: 42.24134, longitude: -76.870803, zipCode: "14872"},
	{latitude: 42.522912, longitude: -77.298359, zipCode: "14873"},
	{latitude: 42.529349, longitude: -77.164465, zipCode: "14874"},
	{latitude: 42.060625, longitude: -77.687308, zipCode: "14877"},
	{latitude: 42.449442, longitude: -76.94403, zipCode: "14878"},
	{latitude: 42.304935, longitude: -77.196963, zipCode: "14879"},
	{latitude: 42.174064, longitude: -77.97535, zipCode: "14880"},
	{latitude: 42.399919, longitude: -76.358882, zipCode: "14881"},
	{latitude: 42.585625, longitude: -76.579264, zipCode: "14882"},
	{latitude: 42.237682, longitude: -76.475575, zipCode: "14883"},
	{latitude: 42.479522, longitude: -77.893852, zipCode: "14884"},
	{latitude: 42.04793, longitude: -77.571205, zipCode: "14885"},
	{latitude: 42.500727, longitude: -76.690271, zipCode: "14886"},
	{latitude: 42.234191, longitude: -76.579111, zipCode: "14889"},
	{latitude: 42.377325, longitude: -76.945021, zipCode: "14891"},
	{latitude: 42.046025, longitude: -76.529114, zipCode: "14892"},
	{latitude: 42.469472, longitude: -77.111769, zipCode: "14893"},
	{latitude: 42.019072, longitude: -76.777083, zipCode: "14894"},
	{latitude: 42.08144, longitude: -77.940922, zipCode: "14895"},
	{latitude: 42.020695, longitude: -77.792636, zipCode: "14897"},
	{latitude: 42.053364, longitude: -77.4295, zipCode: "14898"},
	{latitude: 42.073505, longitude: -76.73953, zipCode: "14901"},
	{latitude: 42.117267, longitude: -76.878487, zipCode: "14903"},
	{latitude: 42.070022, longitude: -76.808203, zipCode: "14904"},
	{latitude: 42.087725, longitude: -76.843282, zipCode: "14905"},
	{latitude: 40.595359, longitude: -80.322827, zipCode: "15001"},
	{latitude: 40.60364, longitude: -80.216429, zipCode: "15003"},
	{latitude: 40.342219, longitude: -80.382854, zipCode: "15004"},
	{latitude: 40.643656, longitude: -80.186297, zipCode: "15005"},
	{latitude: 40.633651, longitude: -79.876829, zipCode: "15006"},
	{latitude: 40.651253, longitude: -79.931616, zipCode: "15007"},
	{latitude: 40.697912, longitude: -80.365277, zipCode: "15009"},
	{latitude: 40.770856, longitude: -80.351417, zipCode: "15010"},
	{latitude: 40.155521, longitude: -79.812981, zipCode: "15012"},
	{latitude: 40.608597, longitude: -79.741512, zipCode: "15014"},
	{latitude: 40.636282, longitude: -80.08158, zipCode: "15015"},
	{latitude: 40.346829, longitude: -80.132565, zipCode: "15017"},
	{latitude: 40.280412, longitude: -79.799805, zipCode: "15018"},
	{latitude: 40.406974, longitude: -80.328574, zipCode: "15019"},
	{latitude: 40.22754, longitude: -79.954104, zipCode: "15020"},
	{latitude: 40.388565, longitude: -80.441527, zipCode: "15021"},
	{latitude: 40.124908, longitude: -79.939333, zipCode: "15022"},
	{latitude: 40.584969, longitude: -79.847315, zipCode: "15024"},
	{latitude: 40.29737, longitude: -79.920967, zipCode: "15025"},
	{latitude: 40.50581, longitude: -80.357346, zipCode: "15026"},
	{latitude: 40.666338, longitude: -80.240179, zipCode: "15027"},
	{latitude: 40.302875, longitude: -79.802557, zipCode: "15028"},
	{latitude: 40.592312, longitude: -79.783101, zipCode: "15030"},
	{latitude: 40.348052, longitude: -80.162213, zipCode: "15031"},
	{latitude: 40.180136, longitude: -79.864813, zipCode: "15033"},
	{latitude: 40.350648, longitude: -79.890176, zipCode: "15034"},
	{latitude: 40.384884, longitude: -79.807338, zipCode: "15035"},
	{latitude: 40.258036, longitude: -79.85259, zipCode: "15037"},
	{latitude: 40.250206, longitude: -79.926042, zipCode: "15038"},
	{latitude: 40.690315, longitude: -80.204823, zipCode: "15042"},
	{latitude: 40.551695, longitude: -80.487856, zipCode: "15043"},
	{latitude: 40.637988, longitude: -79.947933, zipCode: "15044"},
	{latitude: 40.325814, longitude: -79.885985, zipCode: "15045"},
	{latitude: 40.555653, longitude: -80.228561, zipCode: "15046"},
	{latitude: 40.313453, longitude: -79.796224, zipCode: "15047"},
	{latitude: 40.555534, longitude: -79.803608, zipCode: "15049"},
	{latitude: 40.562142, longitude: -80.443812, zipCode: "15050"},
	{latitude: 40.568163, longitude: -79.860785, zipCode: "15051"},
	{latitude: 40.665807, longitude: -80.427024, zipCode: "15052"},
	{latitude: 40.380542, longitude: -80.359796, zipCode: "15053"},
	{latitude: 40.362659, longitude: -80.397184, zipCode: "15054"},
	{latitude: 40.306293, longitude: -80.123516, zipCode: "15055"},
	{latitude: 40.56389, longitude: -80.216015, zipCode: "15056"},
	{latitude: 40.358656, longitude: -80.243904, zipCode: "15057"},
	{latitude: 40.673732, longitude: -80.508937, zipCode: "15059"},
	{latitude: 40.370151, longitude: -80.28885, zipCode: "15060"},
	{latitude: 40.663745, longitude: -80.30837, zipCode: "15061"},
	{latitude: 40.151966, longitude: -79.883008, zipCode: "15062"},
	{latitude: 40.191166, longitude: -79.922476, zipCode: "15063"},
	{latitude: 40.355253, longitude: -80.148391, zipCode: "15064"},
	{latitude: 40.644174, longitude: -79.725395, zipCode: "15065"},
	{latitude: 40.747074, longitude: -80.256143, zipCode: "15066"},
	{latitude: 40.20727, longitude: -79.962433, zipCode: "15067"},
	{latitude: 40.557182, longitude: -79.725823, zipCode: "15068"},
	{latitude: 40.414854, longitude: -80.189832, zipCode: "15071"},
	{latitude: 40.139324, longitude: -79.856063, zipCode: "15072"},
	{latitude: 40.734035, longitude: -80.208354, zipCode: "15074"},
	{latitude: 40.586508, longitude: -79.827063, zipCode: "15075"},
	{latitude: 40.609486, longitude: -79.837348, zipCode: "15076"},
	{latitude: 40.625093, longitude: -80.417391, zipCode: "15077"},
	{latitude: 40.359497, longitude: -80.385056, zipCode: "15078"},
	{latitude: 40.575072, longitude: -80.236104, zipCode: "15081"},
	{latitude: 40.380317, longitude: -80.21059, zipCode: "15082"},
	{latitude: 40.259661, longitude: -79.786199, zipCode: "15083"},
	{latitude: 40.62859, longitude: -79.803264, zipCode: "15084"},
	{latitude: 40.384965, longitude: -79.725433, zipCode: "15085"},
	{latitude: 40.674947, longitude: -80.108515, zipCode: "15086"},
	{latitude: 40.192436, longitude: -79.850726, zipCode: "15087"},
	{latitude: 40.270567, longitude: -79.896214, zipCode: "15088"},
	{latitude: 40.225233, longitude: -79.748806, zipCode: "15089"},
	{latitude: 40.625035, longitude: -80.067068, zipCode: "15090"},
	{latitude: 40.580328, longitude: -79.955241, zipCode: "15101"},
	{latitude: 40.321232, longitude: -80.037054, zipCode: "15102"},
	{latitude: 40.404037, longitude: -79.8624, zipCode: "15104"},
	{latitude: 40.408805, longitude: -80.108676, zipCode: "15106"},
	{latitude: 40.500472, longitude: -80.201802, zipCode: "15108"},
	{latitude: 40.373461, longitude: -79.850162, zipCode: "15110"},
	{latitude: 40.404583, longitude: -79.837367, zipCode: "15112"},
	{latitude: 40.525942, longitude: -79.93393, zipCode: "15116"},
	{latitude: 40.395872, longitude: -79.907511, zipCode: "15120"},
	{latitude: 40.361206, longitude: -79.895641, zipCode: "15122"},
	{latitude: 40.348186, longitude: -79.934828, zipCode: "15123"},
	{latitude: 40.460217, longitude: -80.287327, zipCode: "15126"},
	{latitude: 40.293457, longitude: -79.99522, zipCode: "15129"},
	{latitude: 40.338791, longitude: -79.796071, zipCode: "15131"},
	{latitude: 40.339767, longitude: -79.842782, zipCode: "15132"},
	{latitude: 40.326705, longitude: -79.866805, zipCode: "15133"},
	{latitude: 40.299719, longitude: -79.812169, zipCode: "15135"},
	{latitude: 40.463474, longitude: -80.106694, zipCode: "15136"},
	{latitude: 40.375736, longitude: -79.808765, zipCode: "15137"},
	{latitude: 40.522823, longitude: -79.836255, zipCode: "15139"},
	{latitude: 40.407198, longitude: -79.778254, zipCode: "15140"},
	{latitude: 40.384773, longitude: -80.122319, zipCode: "15142"},
	{latitude: 40.568138, longitude: -80.146807, zipCode: "15143"},
	{latitude: 40.547244, longitude: -79.782565, zipCode: "15144"},
	{latitude: 40.414512, longitude: -79.823506, zipCode: "15145"},
	{latitude: 40.428211, longitude: -79.761076, zipCode: "15146"},
	{latitude: 40.497431, longitude: -79.831096, zipCode: "15147"},
	{latitude: 40.393473, longitude: -79.795152, zipCode: "15148"},
	{latitude: 40.482322, longitude: -79.9415, zipCode: "15201"},
	{latitude: 40.506862, longitude: -80.071737, zipCode: "15202"},
	{latitude: 40.425424, longitude: -79.975758, zipCode: "15203"},
	{latitude: 40.456867, longitude: -80.06063, zipCode: "15204"},
	{latitude: 40.438107, longitude: -80.105533, zipCode: "15205"},
	{latitude: 40.472272, longitude: -79.913156, zipCode: "15206"},
	{latitude: 40.399268, longitude: -79.934801, zipCode: "15207"},
	{latitude: 40.453891, longitude: -79.898794, zipCode: "15208"},
	{latitude: 40.498846, longitude: -79.969725, zipCode: "15209"},
	{latitude: 40.406954, longitude: -79.985004, zipCode: "15210"},
	{latitude: 40.43022, longitude: -80.01538, zipCode: "15211"},
	{latitude: 40.45712, longitude: -79.994555, zipCode: "15212"},
	{latitude: 40.435563, longitude: -79.964078, zipCode: "15213"},
	{latitude: 40.486209, longitude: -80.012771, zipCode: "15214"},
	{latitude: 40.501392, longitude: -79.91267, zipCode: "15215"},
	{latitude: 40.402773, longitude: -80.034798, zipCode: "15216"},
	{latitude: 40.430821, longitude: -79.920089, zipCode: "15217"},
	{latitude: 40.42361, longitude: -79.889803, zipCode: "15218"},
	{latitude: 40.442774, longitude: -79.984077, zipCode: "15219"},
	{latitude: 40.418809, longitude: -80.04899, zipCode: "15220"},
	{latitude: 40.435477, longitude: -79.8658, zipCode: "15221"},
	{latitude: 40.449644, longitude: -79.990714, zipCode: "15222"},
	{latitude: 40.50424, longitude: -79.950973, zipCode: "15223"},
	{latitude: 40.467376, longitude: -79.946266, zipCode: "15224"},
	{latitude: 40.50668, longitude: -80.113844, zipCode: "15225"},
	{latitude: 40.395222, longitude: -80.013788, zipCode: "15226"},
	{latitude: 40.374972, longitude: -79.970778, zipCode: "15227"},
	{latitude: 40.370747, longitude: -80.043981, zipCode: "15228"},
	{latitude: 40.519897, longitude: -80.038656, zipCode: "15229"},
	{latitude: 40.452474, longitude: -79.931957, zipCode: "15232"},
	{latitude: 40.460275, longitude: -80.032597, zipCode: "15233"},
	{latitude: 40.368073, longitude: -80.017812, zipCode: "15234"},
	{latitude: 40.459625, longitude: -79.821992, zipCode: "15235"},
	{latitude: 40.347587, longitude: -79.976476, zipCode: "15236"},
	{latitude: 40.548408, longitude: -80.046196, zipCode: "15237"},
	{latitude: 40.537912, longitude: -79.879419, zipCode: "15238"},
	{latitude: 40.484415, longitude: -79.737599, zipCode: "15239"},
	{latitude: 40.332932, longitude: -80.082908, zipCode: "15241"},
	{latitude: 40.376497, longitude: -80.071389, zipCode: "15243"},
	{latitude: 40.44319, longitude: -79.953094, zipCode: "15260"},
	{latitude: 40.452443, longitude: -80.184114, zipCode: "15275"},
	{latitude: 40.436016, longitude: -79.990515, zipCode: "15282"},
	{latitude: 40.45733, longitude: -80.019291, zipCode: "15290"},
	{latitude: 40.164274, longitude: -80.252356, zipCode: "15301"},
	{latitude: 39.792537, longitude: -80.481074, zipCode: "15310"},
	{latitude: 40.046081, longitude: -80.185911, zipCode: "15311"},
	{latitude: 40.251528, longitude: -80.440702, zipCode: "15312"},
	{latitude: 40.063248, longitude: -80.029391, zipCode: "15313"},
	{latitude: 40.146306, longitude: -80.016809, zipCode: "15314"},
	{latitude: 39.75743, longitude: -79.983181, zipCode: "15315"},
	{latitude: 39.763513, longitude: -80.267735, zipCode: "15316"},
	{latitude: 40.276322, longitude: -80.171662, zipCode: "15317"},
	{latitude: 39.869225, longitude: -79.989277, zipCode: "15320"},
	{latitude: 40.321722, longitude: -80.187182, zipCode: "15321"},
	{latitude: 39.98823, longitude: -80.053284, zipCode: "15322"},
	{latitude: 40.103153, longitude: -80.417436, zipCode: "15323"},
	{latitude: 40.100705, longitude: -80.065386, zipCode: "15324"},
	{latitude: 39.949256, longitude: -79.963512, zipCode: "15325"},
	{latitude: 39.754872, longitude: -79.969127, zipCode: "15327"},
	{latitude: 40.027715, longitude: -80.279928, zipCode: "15329"},
	{latitude: 40.179368, longitude: -80.089973, zipCode: "15330"},
	{latitude: 40.105341, longitude: -80.022613, zipCode: "15331"},
	{latitude: 40.240209, longitude: -79.993477, zipCode: "15332"},
	{latitude: 40.030786, longitude: -80.01321, zipCode: "15333"},
	{latitude: 39.812914, longitude: -79.967235, zipCode: "15334"},
	{latitude: 39.952088, longitude: -80.378324, zipCode: "15337"},
	{latitude: 39.840909, longitude: -79.983792, zipCode: "15338"},
	{latitude: 40.286547, longitude: -80.322631, zipCode: "15340"},
	{latitude: 39.8242, longitude: -80.343863, zipCode: "15341"},
	{latitude: 40.245354, longitude: -80.220585, zipCode: "15342"},
	{latitude: 39.922901, longitude: -80.050723, zipCode: "15344"},
	{latitude: 40.021743, longitude: -80.107431, zipCode: "15345"},
	{latitude: 39.935584, longitude: -80.074474, zipCode: "15346"},
	{latitude: 40.217986, longitude: -80.224595, zipCode: "15347"},
	{latitude: 39.985733, longitude: -79.996203, zipCode: "15348"},
	{latitude: 39.761375, longitude: -80.07323, zipCode: "15349"},
	{latitude: 40.293789, longitude: -80.200647, zipCode: "15350"},
	{latitude: 39.878508, longitude: -79.93059, zipCode: "15351"},
	{latitude: 39.754934, longitude: -80.402579, zipCode: "15352"},
	{latitude: 39.962227, longitude: -80.311151, zipCode: "15353"},
	{latitude: 39.941608, longitude: -79.980409, zipCode: "15357"},
	{latitude: 40.054956, longitude: -80.003108, zipCode: "15358"},
	{latitude: 39.876616, longitude: -80.275632, zipCode: "15359"},
	{latitude: 40.088462, longitude: -80.081472, zipCode: "15360"},
	{latitude: 40.332798, longitude: -80.262324, zipCode: "15361"},
	{latitude: 39.757355, longitude: -80.214428, zipCode: "15362"},
	{latitude: 40.251564, longitude: -80.197734, zipCode: "15363"},
	{latitude: 39.954249, longitude: -80.283863, zipCode: "15364"},
	{latitude: 40.155676, longitude: -80.38545, zipCode: "15365"},
	{latitude: 40.159736, longitude: -79.973851, zipCode: "15366"},
	{latitude: 40.261092, longitude: -80.055668, zipCode: "15367"},
	{latitude: 40.013872, longitude: -79.989284, zipCode: "15368"},
	{latitude: 39.873868, longitude: -80.186106, zipCode: "15370"},
	{latitude: 40.108883, longitude: -80.47905, zipCode: "15376"},
	{latitude: 39.988535, longitude: -80.449029, zipCode: "15377"},
	{latitude: 40.277789, longitude: -80.274385, zipCode: "15378"},
	{latitude: 40.243194, longitude: -80.425176, zipCode: "15379"},
	{latitude: 39.878425, longitude: -80.467323, zipCode: "15380"},
	{latitude: 39.900708, longitude: -79.747923, zipCode: "15401"},
	{latitude: 39.908458, longitude: -79.906042, zipCode: "15410"},
	{latitude: 39.747331, longitude: -79.343516, zipCode: "15411"},
	{latitude: 40.100867, longitude: -79.853088, zipCode: "15412"},
	{latitude: 39.984949, longitude: -79.871403, zipCode: "15413"},
	{latitude: 39.859065, longitude: -79.722806, zipCode: "15416"},
	{latitude: 40.005611, longitude: -79.921185, zipCode: "15417"},
	{latitude: 40.056614, longitude: -79.890461, zipCode: "15419"},
	{latitude: 39.961153, longitude: -79.864333, zipCode: "15420"},
	{latitude: 39.845249, longitude: -79.597386, zipCode: "15421"},
	{latitude: 39.978967, longitude: -79.80924, zipCode: "15422"},
	{latitude: 40.093759, longitude: -79.928213, zipCode: "15423"},
	{latitude: 39.826852, longitude: -79.356323, zipCode: "15424"},
	{latitude: 40.029726, longitude: -79.548821, zipCode: "15425"},
	{latitude: 40.067457, longitude: -79.970701, zipCode: "15427"},
	{latitude: 40.084267, longitude: -79.682026, zipCode: "15428"},
	{latitude: 40.008301, longitude: -79.942513, zipCode: "15429"},
	{latitude: 40.039418, longitude: -79.654371, zipCode: "15430"},
	{latitude: 39.931349, longitude: -79.572521, zipCode: "15431"},
	{latitude: 40.109575, longitude: -79.858756, zipCode: "15432"},
	{latitude: 39.982683, longitude: -79.948732, zipCode: "15433"},
	{latitude: 40.081239, longitude: -79.890788, zipCode: "15434"},
	{latitude: 39.943193, longitude: -79.847283, zipCode: "15435"},
	{latitude: 39.818456, longitude: -79.725456, zipCode: "15436"},
	{latitude: 39.794455, longitude: -79.590384, zipCode: "15437"},
	{latitude: 40.072886, longitude: -79.859286, zipCode: "15438"},
	{latitude: 39.737724, longitude: -79.643632, zipCode: "15440"},
	{latitude: 40.019171, longitude: -79.839788, zipCode: "15442"},
	{latitude: 39.919239, longitude: -79.884503, zipCode: "15443"},
	{latitude: 40.013407, longitude: -79.908458, zipCode: "15444"},
	{latitude: 39.876113, longitude: -79.667849, zipCode: "15445"},
	{latitude: 40.026316, longitude: -79.386487, zipCode: "15446"},
	{latitude: 39.951695, longitude: -79.932369, zipCode: "15447"},
	{latitude: 40.136048, longitude: -79.732527, zipCode: "15448"},
	{latitude: 39.972659, longitude: -79.780603, zipCode: "15449"},
	{latitude: 40.008664, longitude: -79.975019, zipCode: "15450"},
	{latitude: 39.736891, longitude: -79.83578, zipCode: "15451"},
	{latitude: 39.860368, longitude: -79.865503, zipCode: "15454"},
	{latitude: 40.006114, longitude: -79.646071, zipCode: "15455"},
	{latitude: 39.926647, longitude: -79.652106, zipCode: "15456"},
	{latitude: 39.887158, longitude: -79.850967, zipCode: "15458"},
	{latitude: 39.765218, longitude: -79.464533, zipCode: "15459"},
	{latitude: 39.807134, longitude: -79.90761, zipCode: "15460"},
	{latitude: 39.839993, longitude: -79.900013, zipCode: "15461"},
	{latitude: 40.061342, longitude: -79.381134, zipCode: "15462"},
	{latitude: 39.962896, longitude: -79.899153, zipCode: "15463"},
	{latitude: 39.930139, longitude: -79.436198, zipCode: "15464"},
	{latitude: 39.93886, longitude: -79.64128, zipCode: "15465"},
	{latitude: 40.07802, longitude: -79.888889, zipCode: "15466"},
	{latitude: 39.782542, longitude: -79.898746, zipCode: "15467"},
	{latitude: 39.950112, longitude: -79.83257, zipCode: "15468"},
	{latitude: 40.013641, longitude: -79.418581, zipCode: "15469"},
	{latitude: 39.852796, longitude: -79.531691, zipCode: "15470"},
	{latitude: 39.919407, longitude: -79.716834, zipCode: "15472"},
	{latitude: 40.075054, longitude: -79.767881, zipCode: "15473"},
	{latitude: 39.752931, longitude: -79.902677, zipCode: "15474"},
	{latitude: 39.958156, longitude: -79.880625, zipCode: "15475"},
	{latitude: 39.867997, longitude: -79.922808, zipCode: "15476"},
	{latitude: 40.078773, longitude: -79.86294, zipCode: "15477"},
	{latitude: 39.787462, longitude: -79.804657, zipCode: "15478"},
	{latitude: 40.151237, longitude: -79.707539, zipCode: "15479"},
	{latitude: 39.985608, longitude: -79.773558, zipCode: "15480"},
	{latitude: 40.060711, longitude: -79.766471, zipCode: "15482"},
	{latitude: 40.083084, longitude: -79.850793, zipCode: "15483"},
	{latitude: 39.892355, longitude: -79.778644, zipCode: "15484"},
	{latitude: 40.025886, longitude: -79.711164, zipCode: "15486"},
	{latitude: 39.977508, longitude: -79.759736, zipCode: "15488"},
	{latitude: 39.955252, longitude: -79.697548, zipCode: "15489"},
	{latitude: 40.076685, longitude: -79.481513, zipCode: "15490"},
	{latitude: 40.119795, longitude: -79.763063, zipCode: "15492"},
	{latitude: 40.039742, longitude: -79.12982, zipCode: "15501"},
	{latitude: 40.027236, longitude: -79.244025, zipCode: "15502"},
	{latitude: 39.964326, longitude: -79.039955, zipCode: "15510"},
	{latitude: 40.106288, longitude: -79.060245, zipCode: "15520"},
	{latitude: 40.199817, longitude: -78.630167, zipCode: "15521"},
	{latitude: 39.941733, longitude: -78.548408, zipCode: "15522"},
	{latitude: 39.940809, longitude: -78.924027, zipCode: "15530"},
	{latitude: 40.183936, longitude: -79.082097, zipCode: "15531"},
	{latitude: 39.762264, longitude: -79.060773, zipCode: "15532"},
	{latitude: 39.979572, longitude: -78.258667, zipCode: "15533"},
	{latitude: 39.899265, longitude: -78.691197, zipCode: "15534"},
	{latitude: 39.831046, longitude: -78.443913, zipCode: "15535"},
	{latitude: 39.937562, longitude: -78.206377, zipCode: "15536"},
	{latitude: 39.99068, longitude: -78.374336, zipCode: "15537"},
	{latitude: 39.863715, longitude: -78.834458, zipCode: "15538"},
	{latitude: 40.128381, longitude: -78.59338, zipCode: "15539"},
	{latitude: 39.797439, longitude: -79.239272, zipCode: "15540"},
	{latitude: 40.04821, longitude: -78.980902, zipCode: "15541"},
	{latitude: 39.86741, longitude: -79.078543, zipCode: "15542"},
	{latitude: 40.132537, longitude: -79.09651, zipCode: "15544"},
	{latitude: 39.80216, longitude: -78.752463, zipCode: "15545"},
	{latitude: 40.137105, longitude: -79.047558, zipCode: "15546"},
	{latitude: 40.162911, longitude: -79.070287, zipCode: "15547"},
	{latitude: 39.9946, longitude: -78.653116, zipCode: "15550"},
	{latitude: 39.868154, longitude: -79.284798, zipCode: "15551"},
	{latitude: 39.790334, longitude: -78.987716, zipCode: "15552"},
	{latitude: 40.122918, longitude: -78.621785, zipCode: "15554"},
	{latitude: 40.09751, longitude: -79.081368, zipCode: "15555"},
	{latitude: 39.916078, longitude: -79.205332, zipCode: "15557"},
	{latitude: 39.76235, longitude: -79.079881, zipCode: "15558"},
	{latitude: 40.060248, longitude: -78.691065, zipCode: "15559"},
	{latitude: 40.016912, longitude: -78.906652, zipCode: "15560"},
	{latitude: 40.093771, longitude: -79.090687, zipCode: "15561"},
	{latitude: 39.740592, longitude: -79.107556, zipCode: "15562"},
	{latitude: 40.090355, longitude: -78.936657, zipCode: "15563"},
	{latitude: 39.729714, longitude: -78.845443, zipCode: "15564"},
	{latitude: 39.759046, longitude: -79.115599, zipCode: "15565"},
	{latitude: 40.313271, longitude: -79.536379, zipCode: "15601"},
	{latitude: 40.131269, longitude: -79.415343, zipCode: "15610"},
	{latitude: 40.306554, longitude: -79.652674, zipCode: "15611"},
	{latitude: 40.137911, longitude: -79.599339, zipCode: "15612"},
	{latitude: 40.546577, longitude: -79.561663, zipCode: "15613"},
	{latitude: 40.361254, longitude: -79.733977, zipCode: "15615"},
	{latitude: 40.235062, longitude: -79.550908, zipCode: "15616"},
	{latitude: 40.268182, longitude: -79.655373, zipCode: "15617"},
	{latitude: 40.571026, longitude: -79.439869, zipCode: "15618"},
	{latitude: 40.32059, longitude: -79.497083, zipCode: "15619"},
	{latitude: 40.323357, longitude: -79.338834, zipCode: "15620"},
	{latitude: 40.038146, longitude: -79.319711, zipCode: "15622"},
	{latitude: 40.368858, longitude: -79.62269, zipCode: "15623"},
	{latitude: 40.366379, longitude: -79.471261, zipCode: "15624"},
	{latitude: 40.27049, longitude: -79.679242, zipCode: "15625"},
	{latitude: 40.408673, longitude: -79.575945, zipCode: "15626"},
	{latitude: 40.351642, longitude: -79.302551, zipCode: "15627"},
	{latitude: 40.100108, longitude: -79.370169, zipCode: "15628"},
	{latitude: 40.597437, longitude: -79.562908, zipCode: "15629"},
	{latitude: 40.089664, longitude: -79.585343, zipCode: "15631"},
	{latitude: 40.435719, longitude: -79.605521, zipCode: "15632"},
	{latitude: 40.359348, longitude: -79.517061, zipCode: "15633"},
	{latitude: 40.325272, longitude: -79.605972, zipCode: "15634"},
	{latitude: 40.347904, longitude: -79.501557, zipCode: "15635"},
	{latitude: 40.36476, longitude: -79.656116, zipCode: "15636"},
	{latitude: 40.261247, longitude: -79.712053, zipCode: "15637"},
	{latitude: 40.264648, longitude: -79.401638, zipCode: "15638"},
	{latitude: 40.203588, longitude: -79.593819, zipCode: "15639"},
	{latitude: 40.223124, longitude: -79.732499, zipCode: "15640"},
	{latitude: 40.631576, longitude: -79.588973, zipCode: "15641"},
	{latitude: 40.319284, longitude: -79.723697, zipCode: "15642"},
	{latitude: 40.346798, longitude: -79.612811, zipCode: "15644"},
	{latitude: 40.095153, longitude: -79.344493, zipCode: "15646"},
	{latitude: 40.342641, longitude: -79.720034, zipCode: "15647"},
	{latitude: 40.277177, longitude: -79.392906, zipCode: "15650"},
	{latitude: 40.197815, longitude: -79.177819, zipCode: "15655"},
	{latitude: 40.641222, longitude: -79.617811, zipCode: "15656"},
	{latitude: 40.268832, longitude: -79.216333, zipCode: "15658"},
	{latitude: 40.246724, longitude: -79.77302, zipCode: "15660"},
	{latitude: 40.321924, longitude: -79.359943, zipCode: "15661"},
	{latitude: 40.338912, longitude: -79.479745, zipCode: "15662"},
	{latitude: 40.252332, longitude: -79.679611, zipCode: "15663"},
	{latitude: 40.339357, longitude: -79.663493, zipCode: "15665"},
	{latitude: 40.161024, longitude: -79.509814, zipCode: "15666"},
	{latitude: 40.464826, longitude: -79.671391, zipCode: "15668"},
	{latitude: 40.407268, longitude: -79.443692, zipCode: "15670"},
	{latitude: 40.360293, longitude: -79.321431, zipCode: "15671"},
	{latitude: 40.24379, longitude: -79.621587, zipCode: "15672"},
	{latitude: 40.593808, longitude: -79.556616, zipCode: "15673"},
	{latitude: 40.206798, longitude: -79.496381, zipCode: "15674"},
	{latitude: 40.333605, longitude: -79.638737, zipCode: "15675"},
	{latitude: 40.243019, longitude: -79.459103, zipCode: "15676"},
	{latitude: 40.150411, longitude: -79.231115, zipCode: "15677"},
	{latitude: 40.284478, longitude: -79.725789, zipCode: "15678"},
	{latitude: 40.187654, longitude: -79.655809, zipCode: "15679"},
	{latitude: 40.521066, longitude: -79.496354, zipCode: "15680"},
	{latitude: 40.502305, longitude: -79.439025, zipCode: "15681"},
	{latitude: 40.681552, longitude: -79.632781, zipCode: "15682"},
	{latitude: 40.107158, longitude: -79.60822, zipCode: "15683"},
	{latitude: 40.462888, longitude: -79.506388, zipCode: "15684"},
	{latitude: 40.615013, longitude: -79.437107, zipCode: "15686"},
	{latitude: 40.136223, longitude: -79.316448, zipCode: "15687"},
	{latitude: 40.17352, longitude: -79.586074, zipCode: "15688"},
	{latitude: 40.225268, longitude: -79.491661, zipCode: "15689"},
	{latitude: 40.64135, longitude: -79.549422, zipCode: "15690"},
	{latitude: 40.296609, longitude: -79.683992, zipCode: "15691"},
	{latitude: 40.3308, longitude: -79.67861, zipCode: "15692"},
	{latitude: 40.249388, longitude: -79.405909, zipCode: "15693"},
	{latitude: 40.196984, longitude: -79.692834, zipCode: "15695"},
	{latitude: 40.279783, longitude: -79.365884, zipCode: "15696"},
	{latitude: 40.239867, longitude: -79.5806, zipCode: "15697"},
	{latitude: 40.214407, longitude: -79.688503, zipCode: "15698"},
	{latitude: 40.629099, longitude: -79.150059, zipCode: "15701"},
	{latitude: 40.615743, longitude: -79.159936, zipCode: "15705"},
	{latitude: 40.642463, longitude: -78.87167, zipCode: "15710"},
	{latitude: 41.018425, longitude: -78.95562, zipCode: "15711"},
	{latitude: 40.793174, longitude: -78.846905, zipCode: "15712"},
	{latitude: 40.569793, longitude: -79.261842, zipCode: "15713"},
	{latitude: 40.643946, longitude: -78.83337, zipCode: "15714"},
	{latitude: 40.970657, longitude: -78.877426, zipCode: "15715"},
	{latitude: 40.469136, longitude: -79.188058, zipCode: "15716"},
	{latitude: 40.457206, longitude: -79.243213, zipCode: "15717"},
	{latitude: 40.538944, longitude: -79.06576, zipCode: "15720"},
	{latitude: 40.81977, longitude: -78.783865, zipCode: "15721"},
	{latitude: 40.601067, longitude: -78.720514, zipCode: "15722"},
	{latitude: 40.698747, longitude: -79.158676, zipCode: "15723"},
	{latitude: 40.735096, longitude: -78.813634, zipCode: "15724"},
	{latitude: 40.518948, longitude: -79.348779, zipCode: "15725"},
	{latitude: 40.565021, longitude: -79.314942, zipCode: "15727"},
	{latitude: 40.676415, longitude: -78.966784, zipCode: "15728"},
	{latitude: 40.706445, longitude: -78.915125, zipCode: "15729"},
	{latitude: 41.026533, longitude: -79.082706, zipCode: "15730"},
	{latitude: 40.501216, longitude: -79.172943, zipCode: "15731"},
	{latitude: 40.73273, longitude: -79.216471, zipCode: "15732"},
	{latitude: 40.988862, longitude: -78.959295, zipCode: "15733"},
	{latitude: 40.719731, longitude: -79.009041, zipCode: "15734"},
	{latitude: 40.699417, longitude: -79.369284, zipCode: "15736"},
	{latitude: 40.598755, longitude: -78.753568, zipCode: "15737"},
	{latitude: 40.690378, longitude: -78.781719, zipCode: "15738"},
	{latitude: 40.67872, longitude: -79.164571, zipCode: "15739"},
	{latitude: 40.802488, longitude: -78.891033, zipCode: "15741"},
	{latitude: 40.810515, longitude: -78.870088, zipCode: "15742"},
	{latitude: 40.919441, longitude: -79.072979, zipCode: "15744"},
	{latitude: 40.622615, longitude: -78.913493, zipCode: "15745"},
	{latitude: 40.753891, longitude: -78.873164, zipCode: "15746"},
	{latitude: 40.764093, longitude: -79.143307, zipCode: "15747"},
	{latitude: 40.523638, longitude: -79.08415, zipCode: "15748"},
	{latitude: 40.48379, longitude: -79.182622, zipCode: "15750"},
	{latitude: 40.540915, longitude: -79.281757, zipCode: "15752"},
	{latitude: 40.783321, longitude: -78.630901, zipCode: "15753"},
	{latitude: 40.55667, longitude: -79.151511, zipCode: "15754"},
	{latitude: 40.569827, longitude: -79.302292, zipCode: "15756"},
	{latitude: 40.902975, longitude: -78.731671, zipCode: "15757"},
	{latitude: 40.773017, longitude: -79.031699, zipCode: "15759"},
	{latitude: 40.645828, longitude: -78.805073, zipCode: "15760"},
	{latitude: 40.63405, longitude: -78.890103, zipCode: "15761"},
	{latitude: 40.594306, longitude: -78.829302, zipCode: "15762"},
	{latitude: 40.907143, longitude: -79.128005, zipCode: "15763"},
	{latitude: 40.994569, longitude: -79.02696, zipCode: "15764"},
	{latitude: 40.592635, longitude: -79.000291, zipCode: "15765"},
	{latitude: 40.965616, longitude: -78.975453, zipCode: "15767"},
	{latitude: 41.009097, longitude: -79.136749, zipCode: "15770"},
	{latitude: 40.831556, longitude: -78.989145, zipCode: "15771"},
	{latitude: 40.870222, longitude: -78.898677, zipCode: "15772"},
	{latitude: 40.630731, longitude: -78.730313, zipCode: "15773"},
	{latitude: 40.65469, longitude: -79.323835, zipCode: "15774"},
	{latitude: 40.634811, longitude: -78.783992, zipCode: "15775"},
	{latitude: 41.010988, longitude: -79.111592, zipCode: "15776"},
	{latitude: 40.697385, longitude: -78.962591, zipCode: "15777"},
	{latitude: 40.968969, longitude: -79.198434, zipCode: "15778"},
	{latitude: 40.40011, longitude: -79.218444, zipCode: "15779"},
	{latitude: 40.92093, longitude: -79.043298, zipCode: "15780"},
	{latitude: 40.963747, longitude: -78.986856, zipCode: "15781"},
	{latitude: 40.602966, longitude: -79.345018, zipCode: "15783"},
	{latitude: 41.024489, longitude: -79.141065, zipCode: "15784"},
	{latitude: 41.129961, longitude: -78.728348, zipCode: "15801"},
	{latitude: 41.371766, longitude: -78.39709, zipCode: "15821"},
	{latitude: 41.259876, longitude: -78.71568, zipCode: "15823"},
	{latitude: 41.246173, longitude: -78.837488, zipCode: "15824"},
	{latitude: 41.169993, longitude: -79.040345, zipCode: "15825"},
	{latitude: 41.294709, longitude: -78.50579, zipCode: "15827"},
	{latitude: 41.385437, longitude: -79.12333, zipCode: "15828"},
	{latitude: 41.209546, longitude: -79.244037, zipCode: "15829"},
	{latitude: 41.353064, longitude: -78.604278, zipCode: "15831"},
	{latitude: 41.356513, longitude: -78.197279, zipCode: "15832"},
	{latitude: 41.50076, longitude: -78.322783, zipCode: "15834"},
	{latitude: 41.171972, longitude: -78.822649, zipCode: "15840"},
	{latitude: 41.257905, longitude: -78.504358, zipCode: "15841"},
	{latitude: 41.508371, longitude: -78.706975, zipCode: "15845"},
	{latitude: 41.339978, longitude: -78.608516, zipCode: "15846"},
	{latitude: 41.085923, longitude: -79.029677, zipCode: "15847"},
	{latitude: 41.030154, longitude: -78.71058, zipCode: "15848"},
	{latitude: 41.187082, longitude: -78.573007, zipCode: "15849"},
	{latitude: 41.102906, longitude: -78.910416, zipCode: "15851"},
	{latitude: 41.392343, longitude: -78.791631, zipCode: "15853"},
	{latitude: 41.082255, longitude: -78.614302, zipCode: "15856"},
	{latitude: 41.455038, longitude: -78.52019, zipCode: "15857"},
	{latitude: 41.345562, longitude: -79.036837, zipCode: "15860"},
	{latitude: 41.352795, longitude: -78.036198, zipCode: "15861"},
	{latitude: 41.015727, longitude: -78.838473, zipCode: "15863"},
	{latitude: 41.102233, longitude: -79.190133, zipCode: "15864"},
	{latitude: 41.050335, longitude: -78.813877, zipCode: "15865"},
	{latitude: 41.029383, longitude: -78.787682, zipCode: "15866"},
	{latitude: 41.300528, longitude: -78.408847, zipCode: "15868"},
	{latitude: 41.582792, longitude: -78.582366, zipCode: "15870"},
	{latitude: 40.328162, longitude: -78.911913, zipCode: "15901"},
	{latitude: 40.319169, longitude: -78.868818, zipCode: "15902"},
	{latitude: 40.312663, longitude: -78.83957, zipCode: "15904"},
	{latitude: 40.290709, longitude: -78.969945, zipCode: "15905"},
	{latitude: 40.384556, longitude: -78.934058, zipCode: "15906"},
	{latitude: 40.409742, longitude: -78.869741, zipCode: "15909"},
	{latitude: 40.468027, longitude: -79.046847, zipCode: "15920"},
	{latitude: 40.31643, longitude: -78.697865, zipCode: "15921"},
	{latitude: 40.5185, longitude: -78.878018, zipCode: "15922"},
	{latitude: 40.35522, longitude: -79.158504, zipCode: "15923"},
	{latitude: 40.103423, longitude: -78.762858, zipCode: "15924"},
	{latitude: 40.408556, longitude: -78.640712, zipCode: "15925"},
	{latitude: 40.026799, longitude: -78.816836, zipCode: "15926"},
	{latitude: 40.541487, longitude: -78.780181, zipCode: "15927"},
	{latitude: 40.239333, longitude: -78.918675, zipCode: "15928"},
	{latitude: 40.480233, longitude: -79.011209, zipCode: "15929"},
	{latitude: 40.294672, longitude: -78.717499, zipCode: "15930"},
	{latitude: 40.51485, longitude: -78.783636, zipCode: "15931"},
	{latitude: 40.28096, longitude: -78.796162, zipCode: "15934"},
	{latitude: 40.19794, longitude: -78.967164, zipCode: "15935"},
	{latitude: 40.160887, longitude: -78.892633, zipCode: "15936"},
	{latitude: 40.214616, longitude: -78.981041, zipCode: "15937"},
	{latitude: 40.415496, longitude: -78.597216, zipCode: "15938"},
	{latitude: 40.526533, longitude: -78.643563, zipCode: "15940"},
	{latitude: 40.398324, longitude: -78.811023, zipCode: "15942"},
	{latitude: 40.474825, longitude: -78.835526, zipCode: "15943"},
	{latitude: 40.363889, longitude: -79.079158, zipCode: "15944"},
	{latitude: 40.359077, longitude: -78.868714, zipCode: "15945"},
	{latitude: 40.370105, longitude: -78.618107, zipCode: "15946"},
	{latitude: 40.489733, longitude: -78.767045, zipCode: "15948"},
	{latitude: 40.406525, longitude: -79.135235, zipCode: "15949"},
	{latitude: 40.333185, longitude: -78.770688, zipCode: "15951"},
	{latitude: 40.303506, longitude: -78.776084, zipCode: "15952"},
	{latitude: 40.208407, longitude: -78.8849, zipCode: "15953"},
	{latitude: 40.417823, longitude: -78.99995, zipCode: "15954"},
	{latitude: 40.308872, longitude: -78.693186, zipCode: "15955"},
	{latitude: 40.350739, longitude: -78.796048, zipCode: "15956"},
	{latitude: 40.546257, longitude: -78.899995, zipCode: "15957"},
	{latitude: 40.387495, longitude: -78.730751, zipCode: "15958"},
	{latitude: 40.498446, longitude: -78.851794, zipCode: "15960"},
	{latitude: 40.466443, longitude: -78.943367, zipCode: "15961"},
	{latitude: 40.389404, longitude: -78.716371, zipCode: "15962"},
	{latitude: 40.219638, longitude: -78.785278, zipCode: "15963"},
	{latitude: 40.910025, longitude: -79.945191, zipCode: "16001"},
	{latitude: 40.815104, longitude: -79.855349, zipCode: "16002"},
	{latitude: 41.116493, longitude: -79.896534, zipCode: "16020"},
	{latitude: 41.054553, longitude: -79.733232, zipCode: "16022"},
	{latitude: 40.784971, longitude: -79.749208, zipCode: "16023"},
	{latitude: 40.73975, longitude: -80.037228, zipCode: "16024"},
	{latitude: 40.939936, longitude: -79.771294, zipCode: "16025"},
	{latitude: 40.818332, longitude: -80.015411, zipCode: "16027"},
	{latitude: 40.951294, longitude: -79.635593, zipCode: "16028"},
	{latitude: 40.87994, longitude: -79.845998, zipCode: "16029"},
	{latitude: 41.136202, longitude: -79.796907, zipCode: "16030"},
	{latitude: 40.796445, longitude: -80.048964, zipCode: "16033"},
	{latitude: 40.857866, longitude: -79.714977, zipCode: "16034"},
	{latitude: 41.108993, longitude: -80.014526, zipCode: "16035"},
	{latitude: 41.134163, longitude: -79.668713, zipCode: "16036"},
	{latitude: 40.842611, longitude: -80.133751, zipCode: "16037"},
	{latitude: 41.164486, longitude: -79.953346, zipCode: "16038"},
	{latitude: 41.093869, longitude: -79.841337, zipCode: "16040"},
	{latitude: 41.006953, longitude: -79.713925, zipCode: "16041"},
	{latitude: 40.852766, longitude: -79.916305, zipCode: "16045"},
	{latitude: 40.702612, longitude: -80.023973, zipCode: "16046"},
	{latitude: 41.052185, longitude: -79.80349, zipCode: "16048"},
	{latitude: 41.094067, longitude: -79.671823, zipCode: "16049"},
	{latitude: 41.044024, longitude: -79.761496, zipCode: "16050"},
	{latitude: 40.942678, longitude: -80.138832, zipCode: "16051"},
	{latitude: 40.899334, longitude: -80.061602, zipCode: "16052"},
	{latitude: 40.811954, longitude: -79.982575, zipCode: "16053"},
	{latitude: 41.151962, longitude: -79.661156, zipCode: "16054"},
	{latitude: 40.716859, longitude: -79.749717, zipCode: "16055"},
	{latitude: 40.728403, longitude: -79.841812, zipCode: "16056"},
	{latitude: 41.034074, longitude: -80.0603, zipCode: "16057"},
	{latitude: 40.707082, longitude: -79.927088, zipCode: "16059"},
	{latitude: 41.005133, longitude: -79.884697, zipCode: "16061"},
	{latitude: 40.756232, longitude: -80.131076, zipCode: "16063"},
	{latitude: 40.709967, longitude: -80.105642, zipCode: "16066"},
	{latitude: 40.983987, longitude: -80.29024, zipCode: "16101"},
	{latitude: 40.962242, longitude: -80.415441, zipCode: "16102"},
	{latitude: 41.05404, longitude: -80.339414, zipCode: "16105"},
	{latitude: 41.494656, longitude: -80.387377, zipCode: "16110"},
	{latitude: 41.518516, longitude: -80.283532, zipCode: "16111"},
	{latitude: 40.960467, longitude: -80.498397, zipCode: "16112"},
	{latitude: 41.280826, longitude: -80.424148, zipCode: "16113"},
	{latitude: 41.404303, longitude: -80.187679, zipCode: "16114"},
	{latitude: 40.796494, longitude: -80.463523, zipCode: "16115"},
	{latitude: 41.044195, longitude: -80.449588, zipCode: "16116"},
	{latitude: 40.871835, longitude: -80.256207, zipCode: "16117"},
	{latitude: 40.885261, longitude: -80.479165, zipCode: "16120"},
	{latitude: 41.211094, longitude: -80.497338, zipCode: "16121"},
	{latitude: 40.821932, longitude: -80.196513, zipCode: "16123"},
	{latitude: 41.333371, longitude: -80.264847, zipCode: "16124"},
	{latitude: 41.406506, longitude: -80.369729, zipCode: "16125"},
	{latitude: 41.173009, longitude: -80.072998, zipCode: "16127"},
	{latitude: 41.444638, longitude: -80.222959, zipCode: "16130"},
	{latitude: 41.548571, longitude: -80.376918, zipCode: "16131"},
	{latitude: 41.005848, longitude: -80.505148, zipCode: "16132"},
	{latitude: 41.267778, longitude: -80.114451, zipCode: "16133"},
	{latitude: 41.526836, longitude: -80.490189, zipCode: "16134"},
	{latitude: 40.834932, longitude: -80.323771, zipCode: "16136"},
	{latitude: 41.231515, longitude: -80.239942, zipCode: "16137"},
	{latitude: 41.093646, longitude: -80.510732, zipCode: "16140"},
	{latitude: 40.880265, longitude: -80.399156, zipCode: "16141"},
	{latitude: 41.135233, longitude: -80.332802, zipCode: "16142"},
	{latitude: 41.105081, longitude: -80.454418, zipCode: "16143"},
	{latitude: 41.380199, longitude: -80.078624, zipCode: "16145"},
	{latitude: 41.234788, longitude: -80.499379, zipCode: "16146"},
	{latitude: 41.228478, longitude: -80.417623, zipCode: "16148"},
	{latitude: 41.281804, longitude: -80.449145, zipCode: "16150"},
	{latitude: 41.442851, longitude: -80.20567, zipCode: "16151"},
	{latitude: 41.32224, longitude: -80.086586, zipCode: "16153"},
	{latitude: 41.3259, longitude: -80.420111, zipCode: "16154"},
	{latitude: 41.067732, longitude: -80.510731, zipCode: "16155"},
	{latitude: 41.097996, longitude: -80.227214, zipCode: "16156"},
	{latitude: 40.888212, longitude: -80.333383, zipCode: "16157"},
	{latitude: 41.153413, longitude: -80.475263, zipCode: "16159"},
	{latitude: 40.928358, longitude: -80.362385, zipCode: "16160"},
	{latitude: 41.202311, longitude: -80.492827, zipCode: "16161"},
	{latitude: 41.117708, longitude: -80.331045, zipCode: "16172"},
	{latitude: 40.812096, longitude: -79.4725, zipCode: "16201"},
	{latitude: 40.901015, longitude: -79.516619, zipCode: "16210"},
	{latitude: 40.794334, longitude: -79.202375, zipCode: "16211"},
	{latitude: 40.752466, longitude: -79.577167, zipCode: "16212"},
	{latitude: 41.125548, longitude: -79.558302, zipCode: "16213"},
	{latitude: 41.198441, longitude: -79.354159, zipCode: "16214"},
	{latitude: 41.346142, longitude: -79.181312, zipCode: "16217"},
	{latitude: 40.92612, longitude: -79.592645, zipCode: "16218"},
	{latitude: 41.418091, longitude: -79.26819, zipCode: "16220"},
	{latitude: 40.873698, longitude: -79.256993, zipCode: "16222"},
	{latitude: 40.986973, longitude: -79.353821, zipCode: "16223"},
	{latitude: 41.066542, longitude: -79.290454, zipCode: "16224"},
	{latitude: 40.714719, longitude: -79.487423, zipCode: "16226"},
	{latitude: 40.760813, longitude: -79.535765, zipCode: "16228"},
	{latitude: 40.711759, longitude: -79.656252, zipCode: "16229"},
	{latitude: 41.020482, longitude: -79.283246, zipCode: "16230"},
	{latitude: 41.213584, longitude: -79.551244, zipCode: "16232"},
	{latitude: 41.367965, longitude: -79.285336, zipCode: "16233"},
	{latitude: 41.30579, longitude: -79.347387, zipCode: "16235"},
	{latitude: 40.781222, longitude: -79.523713, zipCode: "16236"},
	{latitude: 40.787653, longitude: -79.520411, zipCode: "16238"},
	{latitude: 41.496972, longitude: -79.137779, zipCode: "16239"},
	{latitude: 41.036013, longitude: -79.225, zipCode: "16240"},
	{latitude: 40.98542, longitude: -79.372346, zipCode: "16242"},
	{latitude: 40.804985, longitude: -79.279083, zipCode: "16244"},
	{latitude: 41.001559, longitude: -79.293138, zipCode: "16245"},
	{latitude: 40.791717, longitude: -79.182161, zipCode: "16246"},
	{latitude: 41.034876, longitude: -79.504761, zipCode: "16248"},
	{latitude: 40.759381, longitude: -79.326416, zipCode: "16249"},
	{latitude: 40.775652, longitude: -79.237832, zipCode: "16250"},
	{latitude: 40.956694, longitude: -79.343547, zipCode: "16253"},
	{latitude: 41.255013, longitude: -79.450807, zipCode: "16254"},
	{latitude: 41.128813, longitude: -79.466757, zipCode: "16255"},
	{latitude: 40.855267, longitude: -79.134458, zipCode: "16256"},
	{latitude: 41.230105, longitude: -79.272223, zipCode: "16258"},
	{latitude: 40.925711, longitude: -79.451859, zipCode: "16259"},
	{latitude: 41.410882, longitude: -79.239273, zipCode: "16260"},
	{latitude: 40.837675, longitude: -79.652691, zipCode: "16262"},
	{latitude: 40.801168, longitude: -79.341109, zipCode: "16263"},
	{latitude: 41.453285, longitude: -79.622427, zipCode: "16301"},
	{latitude: 41.462171, longitude: -80.042364, zipCode: "16311"},
	{latitude: 41.932135, longitude: -79.303214, zipCode: "16312"},
	{latitude: 41.714594, longitude: -79.145177, zipCode: "16313"},
	{latitude: 41.520675, longitude: -80.07707, zipCode: "16314"},
	{latitude: 41.595311, longitude: -80.284483, zipCode: "16316"},
	{latitude: 41.532335, longitude: -79.85262, zipCode: "16317"},
	{latitude: 41.324204, longitude: -79.623458, zipCode: "16319"},
	{latitude: 41.568325, longitude: -79.400859, zipCode: "16321"},
	{latitude: 41.605636, longitude: -79.384341, zipCode: "16322"},
	{latitude: 41.417265, longitude: -79.830556, zipCode: "16323"},
	{latitude: 41.376158, longitude: -79.43174, zipCode: "16326"},
	{latitude: 41.637082, longitude: -79.959566, zipCode: "16327"},
	{latitude: 41.650983, longitude: -79.72712, zipCode: "16328"},
	{latitude: 41.852731, longitude: -79.26496, zipCode: "16329"},
	{latitude: 41.287515, longitude: -79.559279, zipCode: "16331"},
	{latitude: 41.356717, longitude: -79.369545, zipCode: "16332"},
	{latitude: 41.749226, longitude: -78.932294, zipCode: "16333"},
	{latitude: 41.304495, longitude: -79.44628, zipCode: "16334"},
	{latitude: 41.630635, longitude: -80.153636, zipCode: "16335"},
	{latitude: 41.798615, longitude: -79.417505, zipCode: "16340"},
	{latitude: 41.562876, longitude: -79.547033, zipCode: "16341"},
	{latitude: 41.314848, longitude: -79.928008, zipCode: "16342"},
	{latitude: 41.425982, longitude: -79.752686, zipCode: "16343"},
	{latitude: 41.470477, longitude: -79.683991, zipCode: "16344"},
	{latitude: 41.944404, longitude: -79.082916, zipCode: "16345"},
	{latitude: 41.378475, longitude: -79.672744, zipCode: "16346"},
	{latitude: 41.660063, longitude: -79.070267, zipCode: "16347"},
	{latitude: 41.956923, longitude: -79.334404, zipCode: "16350"},
	{latitude: 41.675353, longitude: -79.370344, zipCode: "16351"},
	{latitude: 41.766135, longitude: -79.036428, zipCode: "16352"},
	{latitude: 41.492112, longitude: -79.389517, zipCode: "16353"},
	{latitude: 41.614736, longitude: -79.706434, zipCode: "16354"},
	{latitude: 41.679673, longitude: -79.890349, zipCode: "16360"},
	{latitude: 41.391037, longitude: -79.340239, zipCode: "16361"},
	{latitude: 41.450497, longitude: -79.965179, zipCode: "16362"},
	{latitude: 41.351416, longitude: -79.527305, zipCode: "16364"},
	{latitude: 41.840178, longitude: -79.157366, zipCode: "16365"},
	{latitude: 41.557054, longitude: -79.449018, zipCode: "16370"},
	{latitude: 41.867526, longitude: -79.318692, zipCode: "16371"},
	{latitude: 41.201025, longitude: -79.874512, zipCode: "16372"},
	{latitude: 41.208469, longitude: -79.698409, zipCode: "16373"},
	{latitude: 41.263498, longitude: -79.815085, zipCode: "16374"},
	{latitude: 41.878476, longitude: -80.40516, zipCode: "16401"},
	{latitude: 41.961016, longitude: -79.469793, zipCode: "16402"},
	{latitude: 41.792998, longitude: -80.014121, zipCode: "16403"},
	{latitude: 41.725553, longitude: -79.789045, zipCode: "16404"},
	{latitude: 41.937995, longitude: -79.533511, zipCode: "16405"},
	{latitude: 41.750993, longitude: -80.363549, zipCode: "16406"},
	{latitude: 41.915795, longitude: -79.68467, zipCode: "16407"},
	{latitude: 41.922751, longitude: -80.308509, zipCode: "16410"},
	{latitude: 41.978036, longitude: -80.447108, zipCode: "16411"},
	{latitude: 41.882087, longitude: -80.159697, zipCode: "16412"},
	{latitude: 42.037572, longitude: -80.230286, zipCode: "16415"},
	{latitude: 41.826056, longitude: -79.473138, zipCode: "16416"},
	{latitude: 41.969286, longitude: -80.310636, zipCode: "16417"},
	{latitude: 41.694782, longitude: -79.545454, zipCode: "16420"},
	{latitude: 42.169579, longitude: -79.939918, zipCode: "16421"},
	{latitude: 41.667548, longitude: -80.310188, zipCode: "16422"},
	{latitude: 42.021099, longitude: -80.340132, zipCode: "16423"},
	{latitude: 41.661717, longitude: -80.448219, zipCode: "16424"},
	{latitude: 41.984082, longitude: -80.125891, zipCode: "16426"},
	{latitude: 41.874637, longitude: -79.971279, zipCode: "16427"},
	{latitude: 42.171464, longitude: -79.831488, zipCode: "16428"},
	{latitude: 42.001124, longitude: -80.41894, zipCode: "16430"},
	{latitude: 41.737079, longitude: -80.140914, zipCode: "16433"},
	{latitude: 41.79672, longitude: -79.668697, zipCode: "16434"},
	{latitude: 41.822381, longitude: -80.382821, zipCode: "16435"},
	{latitude: 41.862447, longitude: -79.506491, zipCode: "16436"},
	{latitude: 41.89124, longitude: -79.847468, zipCode: "16438"},
	{latitude: 41.782549, longitude: -80.126018, zipCode: "16440"},
	{latitude: 41.95849, longitude: -79.983551, zipCode: "16441"},
	{latitude: 42.048573, longitude: -79.825747, zipCode: "16442"},
	{latitude: 41.940114, longitude: -80.47184, zipCode: "16443"},
	{latitude: 41.871224, longitude: -80.120756, zipCode: "16444"},
	{latitude: 41.876432, longitude: -80.361052, zipCode: "16475"},
	{latitude: 42.121053, longitude: -80.089435, zipCode: "16501"},
	{latitude: 42.110735, longitude: -80.101294, zipCode: "16502"},
	{latitude: 42.127155, longitude: -80.061023, zipCode: "16503"},
	{latitude: 42.109409, longitude: -80.048842, zipCode: "16504"},
	{latitude: 42.11914, longitude: -80.127883, zipCode: "16505"},
	{latitude: 42.063038, longitude: -80.156034, zipCode: "16506"},
	{latitude: 42.139495, longitude: -80.084963, zipCode: "16507"},
	{latitude: 42.097774, longitude: -80.091462, zipCode: "16508"},
	{latitude: 42.060488, longitude: -80.045846, zipCode: "16509"},
	{latitude: 42.108991, longitude: -79.954821, zipCode: "16510"},
	{latitude: 42.167495, longitude: -80.005796, zipCode: "16511"},
	{latitude: 42.103873, longitude: -80.053348, zipCode: "16546"},
	{latitude: 42.133783, longitude: -80.084949, zipCode: "16550"},
	{latitude: 42.117759, longitude: -79.98577, zipCode: "16563"},
	{latitude: 40.552519, longitude: -78.372994, zipCode: "16601"},
	{latitude: 40.484994, longitude: -78.397114, zipCode: "16602"},
	{latitude: 40.571045, longitude: -78.091199, zipCode: "16611"},
	{latitude: 40.537222, longitude: -78.532129, zipCode: "16613"},
	{latitude: 40.769816, longitude: -78.458443, zipCode: "16616"},
	{latitude: 40.603389, longitude: -78.329834, zipCode: "16617"},
	{latitude: 40.683688, longitude: -78.415842, zipCode: "16619"},
	{latitude: 40.838548, longitude: -78.351235, zipCode: "16620"},
	{latitude: 40.258409, longitude: -78.108868, zipCode: "16621"},
	{latitude: 40.328538, longitude: -78.069211, zipCode: "16622"},
	{latitude: 40.27623, longitude: -78.045563, zipCode: "16623"},
	{latitude: 40.565126, longitude: -78.606652, zipCode: "16624"},
	{latitude: 40.282385, longitude: -78.519082, zipCode: "16625"},
	{latitude: 40.752924, longitude: -78.504133, zipCode: "16627"},
	{latitude: 40.462586, longitude: -78.580523, zipCode: "16630"},
	{latitude: 40.27141, longitude: -78.347488, zipCode: "16631"},
	{latitude: 40.159722, longitude: -78.232513, zipCode: "16633"},
	{latitude: 40.210519, longitude: -78.173071, zipCode: "16634"},
	{latitude: 40.407743, longitude: -78.477386, zipCode: "16635"},
	{latitude: 40.600253, longitude: -78.502014, zipCode: "16636"},
	{latitude: 40.342127, longitude: -78.470774, zipCode: "16637"},
	{latitude: 40.338208, longitude: -78.20095, zipCode: "16638"},
	{latitude: 40.681716, longitude: -78.463519, zipCode: "16639"},
	{latitude: 40.708001, longitude: -78.572338, zipCode: "16640"},
	{latitude: 40.507723, longitude: -78.571434, zipCode: "16641"},
	{latitude: 40.803608, longitude: -78.500731, zipCode: "16645"},
	{latitude: 40.689663, longitude: -78.726013, zipCode: "16646"},
	{latitude: 40.380477, longitude: -78.113284, zipCode: "16647"},
	{latitude: 40.441691, longitude: -78.33231, zipCode: "16648"},
	{latitude: 40.112053, longitude: -78.291955, zipCode: "16650"},
	{latitude: 40.851464, longitude: -78.380424, zipCode: "16651"},
	{latitude: 40.516022, longitude: -77.950096, zipCode: "16652"},
	{latitude: 40.491883, longitude: -78.028557, zipCode: "16654"},
	{latitude: 40.227952, longitude: -78.502963, zipCode: "16655"},
	{latitude: 40.807227, longitude: -78.555986, zipCode: "16656"},
	{latitude: 40.338382, longitude: -78.160584, zipCode: "16657"},
	{latitude: 40.160029, longitude: -78.388322, zipCode: "16659"},
	{latitude: 40.831088, longitude: -78.460928, zipCode: "16661"},
	{latitude: 40.295116, longitude: -78.297418, zipCode: "16662"},
	{latitude: 40.177862, longitude: -78.420196, zipCode: "16664"},
	{latitude: 40.389037, longitude: -78.434914, zipCode: "16665"},
	{latitude: 40.867714, longitude: -78.313969, zipCode: "16666"},
	{latitude: 40.182751, longitude: -78.52519, zipCode: "16667"},
	{latitude: 40.653348, longitude: -78.622324, zipCode: "16668"},
	{latitude: 40.655953, longitude: -77.906687, zipCode: "16669"},
	{latitude: 40.259035, longitude: -78.507702, zipCode: "16670"},
	{latitude: 40.787627, longitude: -78.39824, zipCode: "16671"},
	{latitude: 40.185501, longitude: -78.237228, zipCode: "16672"},
	{latitude: 40.305163, longitude: -78.388972, zipCode: "16673"},
	{latitude: 40.186476, longitude: -78.079259, zipCode: "16674"},
	{latitude: 40.687866, longitude: -78.678982, zipCode: "16675"},
	{latitude: 40.815095, longitude: -78.245636, zipCode: "16677"},
	{latitude: 40.225832, longitude: -78.2325, zipCode: "16678"},
	{latitude: 40.155253, longitude: -78.194384, zipCode: "16679"},
	{latitude: 40.743336, longitude: -78.377926, zipCode: "16680"},
	{latitude: 40.271993, longitude: -78.456469, zipCode: "16682"},
	{latitude: 40.663867, longitude: -78.065558, zipCode: "16683"},
	{latitude: 40.635431, longitude: -78.296894, zipCode: "16684"},
	{latitude: 40.280149, longitude: -78.080636, zipCode: "16685"},
	{latitude: 40.670411, longitude: -78.240506, zipCode: "16686"},
	{latitude: 40.075057, longitude: -78.084313, zipCode: "16689"},
	{latitude: 40.07745, longitude: -78.147309, zipCode: "16691"},
	{latitude: 40.750874, longitude: -78.710529, zipCode: "16692"},
	{latitude: 40.465298, longitude: -78.224595, zipCode: "16693"},
	{latitude: 40.189981, longitude: -78.1343, zipCode: "16694"},
	{latitude: 40.204653, longitude: -78.336929, zipCode: "16695"},
	{latitude: 40.447211, longitude: -78.560505, zipCode: "16699"},
	{latitude: 41.918671, longitude: -78.750365, zipCode: "16701"},
	{latitude: 41.603181, longitude: -78.009756, zipCode: "16720"},
	{latitude: 41.746136, longitude: -78.389766, zipCode: "16724"},
	{latitude: 41.899312, longitude: -78.661955, zipCode: "16725"},
	{latitude: 41.802392, longitude: -78.575869, zipCode: "16726"},
	{latitude: 41.98504, longitude: -78.533797, zipCode: "16727"},
	{latitude: 41.969668, longitude: -78.481818, zipCode: "16729"},
	{latitude: 41.817823, longitude: -78.422791, zipCode: "16730"},
	{latitude: 41.951416, longitude: -78.360051, zipCode: "16731"},
	{latitude: 41.8592, longitude: -78.608394, zipCode: "16732"},
	{latitude: 41.700389, longitude: -78.572341, zipCode: "16733"},
	{latitude: 41.615684, longitude: -78.847378, zipCode: "16734"},
	{latitude: 41.628758, longitude: -78.806743, zipCode: "16735"},
	{latitude: 41.806868, longitude: -78.732143, zipCode: "16738"},
	{latitude: 41.652656, longitude: -78.509599, zipCode: "16740"},
	{latitude: 41.767638, longitude: -78.232953, zipCode: "16743"},
	{latitude: 41.875647, longitude: -78.569451, zipCode: "16744"},
	{latitude: 41.925551, longitude: -78.489736, zipCode: "16745"},
	{latitude: 41.793875, longitude: -78.122491, zipCode: "16746"},
	{latitude: 41.942013, longitude: -78.144807, zipCode: "16748"},
	{latitude: 41.759754, longitude: -78.420964, zipCode: "16749"},
	{latitude: 41.901571, longitude: -78.291724, zipCode: "16750"},
	{latitude: 40.78521, longitude: -77.842297, zipCode: "16801"},
	{latitude: 40.798586, longitude: -77.864859, zipCode: "16802"},
	{latitude: 40.808517, longitude: -77.894436, zipCode: "16803"},
	{latitude: 40.919984, longitude: -77.386928, zipCode: "16820"},
	{latitude: 40.957848, longitude: -78.194577, zipCode: "16821"},
	{latitude: 41.137258, longitude: -77.707713, zipCode: "16822"},
	{latitude: 40.931203, longitude: -77.759053, zipCode: "16823"},
	{latitude: 40.98763, longitude: -78.31155, zipCode: "16825"},
	{latitude: 41.062067, longitude: -77.586019, zipCode: "16826"},
	{latitude: 40.76784, longitude: -77.772638, zipCode: "16827"},
	{latitude: 40.804311, longitude: -77.695937, zipCode: "16828"},
	{latitude: 41.084949, longitude: -77.887495, zipCode: "16829"},
	{latitude: 41.090593, longitude: -78.438945, zipCode: "16830"},
	{latitude: 40.84277, longitude: -77.425943, zipCode: "16832"},
	{latitude: 40.943955, longitude: -78.572434, zipCode: "16833"},
	{latitude: 41.051702, longitude: -78.087218, zipCode: "16834"},
	{latitude: 40.906681, longitude: -77.877341, zipCode: "16835"},
	{latitude: 41.145458, longitude: -78.26483, zipCode: "16836"},
	{latitude: 40.94544, longitude: -78.474451, zipCode: "16837"},
	{latitude: 41.005831, longitude: -78.651483, zipCode: "16838"},
	{latitude: 41.003239, longitude: -78.106435, zipCode: "16839"},
	{latitude: 40.924257, longitude: -78.20308, zipCode: "16840"},
	{latitude: 41.035527, longitude: -77.690859, zipCode: "16841"},
	{latitude: 41.011798, longitude: -78.46194, zipCode: "16843"},
	{latitude: 40.912809, longitude: -77.93263, zipCode: "16844"},
	{latitude: 41.113099, longitude: -77.927734, zipCode: "16845"},
	{latitude: 40.999285, longitude: -78.165498, zipCode: "16847"},
	{latitude: 41.011313, longitude: -77.533288, zipCode: "16848"},
	{latitude: 40.961123, longitude: -78.115673, zipCode: "16849"},
	{latitude: 40.812401, longitude: -77.80154, zipCode: "16851"},
	{latitude: 40.936383, longitude: -77.538555, zipCode: "16852"},
	{latitude: 40.940357, longitude: -77.786143, zipCode: "16853"},
	{latitude: 40.896976, longitude: -77.472324, zipCode: "16854"},
	{latitude: 41.005278, longitude: -78.353963, zipCode: "16855"},
	{latitude: 40.928771, longitude: -77.650034, zipCode: "16856"},
	{latitude: 41.003352, longitude: -78.209655, zipCode: "16858"},
	{latitude: 41.046594, longitude: -78.041808, zipCode: "16859"},
	{latitude: 40.937991, longitude: -78.179764, zipCode: "16860"},
	{latitude: 40.873405, longitude: -78.519324, zipCode: "16861"},
	{latitude: 40.907843, longitude: -78.474635, zipCode: "16863"},
	{latitude: 41.154254, longitude: -77.798547, zipCode: "16864"},
	{latitude: 40.724005, longitude: -77.955973, zipCode: "16865"},
	{latitude: 40.867022, longitude: -78.165573, zipCode: "16866"},
	{latitude: 40.727271, longitude: -77.89064, zipCode: "16868"},
	{latitude: 40.801279, longitude: -78.049352, zipCode: "16870"},
	{latitude: 41.191089, longitude: -78.035921, zipCode: "16871"},
	{latitude: 40.967214, longitude: -77.359431, zipCode: "16872"},
	{latitude: 40.985042, longitude: -77.951515, zipCode: "16874"},
	{latitude: 40.840418, longitude: -77.587993, zipCode: "16875"},
	{latitude: 40.965638, longitude: -78.287646, zipCode: "16876"},
	{latitude: 40.733197, longitude: -78.067952, zipCode: "16877"},
	{latitude: 40.940342, longitude: -78.351798, zipCode: "16878"},
	{latitude: 40.964382, longitude: -78.140732, zipCode: "16879"},
	{latitude: 41.02678, longitude: -78.320437, zipCode: "16881"},
	{latitude: 40.907077, longitude: -77.338465, zipCode: "16882"},
	{latitude: 41.734791, longitude: -77.315756, zipCode: "16901"},
	{latitude: 41.7046, longitude: -76.831056, zipCode: "16910"},
	{latitude: 41.676937, longitude: -77.101712, zipCode: "16911"},
	{latitude: 41.666056, longitude: -77.067212, zipCode: "16912"},
	{latitude: 41.854233, longitude: -76.783676, zipCode: "16914"},
	{latitude: 41.774893, longitude: -77.96139, zipCode: "16915"},
	{latitude: 41.722079, longitude: -77.056714, zipCode: "16917"},
	{latitude: 41.973761, longitude: -77.283566, zipCode: "16920"},
	{latitude: 41.723136, longitude: -77.522427, zipCode: "16921"},
	{latitude: 41.680658, longitude: -77.7107, zipCode: "16922"},
	{latitude: 41.950555, longitude: -77.870564, zipCode: "16923"},
	{latitude: 41.948567, longitude: -76.78381, zipCode: "16925"},
	{latitude: 41.715735, longitude: -76.700292, zipCode: "16926"},
	{latitude: 41.965439, longitude: -77.659294, zipCode: "16927"},
	{latitude: 41.952024, longitude: -77.433603, zipCode: "16928"},
	{latitude: 41.963406, longitude: -77.134908, zipCode: "16929"},
	{latitude: 41.583782, longitude: -77.138826, zipCode: "16930"},
	{latitude: 41.779128, longitude: -76.939472, zipCode: "16932"},
	{latitude: 41.819424, longitude: -77.062502, zipCode: "16933"},
	{latitude: 41.869179, longitude: -77.333696, zipCode: "16935"},
	{latitude: 41.951125, longitude: -76.967113, zipCode: "16936"},
	{latitude: 41.967896, longitude: -77.710941, zipCode: "16937"},
	{latitude: 41.591145, longitude: -77.370357, zipCode: "16938"},
	{latitude: 41.680994, longitude: -77.014099, zipCode: "16939"},
	{latitude: 41.977481, longitude: -77.233718, zipCode: "16940"},
	{latitude: 41.988296, longitude: -77.758047, zipCode: "16941"},
	{latitude: 41.965466, longitude: -77.354219, zipCode: "16942"},
	{latitude: 41.848117, longitude: -77.616217, zipCode: "16943"},
	{latitude: 41.915816, longitude: -77.142243, zipCode: "16946"},
	{latitude: 41.759502, longitude: -76.804051, zipCode: "16947"},
	{latitude: 41.854715, longitude: -77.759215, zipCode: "16948"},
	{latitude: 41.895764, longitude: -77.543654, zipCode: "16950"},
	{latitude: 40.46087, longitude: -77.869294, zipCode: "17002"},
	{latitude: 40.356692, longitude: -76.552401, zipCode: "17003"},
	{latitude: 40.601046, longitude: -77.730437, zipCode: "17004"},
	{latitude: 40.603061, longitude: -76.810622, zipCode: "17005"},
	{latitude: 40.335644, longitude: -77.492146, zipCode: "17006"},
	{latitude: 40.139031, longitude: -77.118466, zipCode: "17007"},
	{latitude: 40.636112, longitude: -77.565287, zipCode: "17009"},
	{latitude: 40.278122, longitude: -76.583335, zipCode: "17010"},
	{latitude: 40.23483, longitude: -76.928846, zipCode: "17011"},
	{latitude: 40.241159, longitude: -77.183824, zipCode: "17013"},
	{latitude: 40.6596, longitude: -77.193604, zipCode: "17014"},
	{latitude: 40.183404, longitude: -77.232115, zipCode: "17015"},
	{latitude: 40.273106, longitude: -76.406884, zipCode: "17016"},
	{latitude: 40.627036, longitude: -76.899583, zipCode: "17017"},
	{latitude: 40.422061, longitude: -76.826124, zipCode: "17018"},
	{latitude: 40.094021, longitude: -77.022075, zipCode: "17019"},
	{latitude: 40.414171, longitude: -77.041503, zipCode: "17020"},
	{latitude: 40.330526, longitude: -77.668192, zipCode: "17021"},
	{latitude: 40.167037, longitude: -76.608892, zipCode: "17022"},
	{latitude: 40.581304, longitude: -76.811737, zipCode: "17023"},
	{latitude: 40.406934, longitude: -77.306825, zipCode: "17024"},
	{latitude: 40.294091, longitude: -76.974475, zipCode: "17025"},
	{latitude: 40.473584, longitude: -76.437195, zipCode: "17026"},
	{latitude: 40.157418, longitude: -76.982115, zipCode: "17027"},
	{latitude: 40.402526, longitude: -76.675121, zipCode: "17028"},
	{latitude: 40.558017, longitude: -77.616997, zipCode: "17029"},
	{latitude: 40.608291, longitude: -76.744193, zipCode: "17030"},
	{latitude: 40.493937, longitude: -76.813511, zipCode: "17032"},
	{latitude: 40.269174, longitude: -76.628239, zipCode: "17033"},
	{latitude: 40.20861, longitude: -76.785533, zipCode: "17034"},
	{latitude: 40.403786, longitude: -77.581315, zipCode: "17035"},
	{latitude: 40.258186, longitude: -76.720181, zipCode: "17036"},
	{latitude: 40.432466, longitude: -77.427756, zipCode: "17037"},
	{latitude: 40.467819, longitude: -76.538202, zipCode: "17038"},
	{latitude: 40.292709, longitude: -76.240707, zipCode: "17039"},
	{latitude: 40.296219, longitude: -77.340009, zipCode: "17040"},
	{latitude: 40.216634, longitude: -76.539605, zipCode: "17041"},
	{latitude: 40.296378, longitude: -76.421124, zipCode: "17042"},
	{latitude: 40.247611, longitude: -76.900134, zipCode: "17043"},
	{latitude: 40.582082, longitude: -77.590269, zipCode: "17044"},
	{latitude: 40.597093, longitude: -77.000928, zipCode: "17045"},
	{latitude: 40.381655, longitude: -76.429383, zipCode: "17046"},
	{latitude: 40.371068, longitude: -77.411986, zipCode: "17047"},
	{latitude: 40.582253, longitude: -76.691987, zipCode: "17048"},
	{latitude: 40.651715, longitude: -77.259458, zipCode: "17049"},
	{latitude: 40.247547, longitude: -77.030441, zipCode: "17050"},
	{latitude: 40.459362, longitude: -77.764129, zipCode: "17051"},
	{latitude: 40.276899, longitude: -77.986339, zipCode: "17052"},
	{latitude: 40.32504, longitude: -77.026474, zipCode: "17053"},
	{latitude: 40.496849, longitude: -77.727253, zipCode: "17054"},
	{latitude: 40.177673, longitude: -76.997703, zipCode: "17055"},
	{latitude: 40.533518, longitude: -77.355554, zipCode: "17056"},
	{latitude: 40.19435, longitude: -76.726246, zipCode: "17057"},
	{latitude: 40.501043, longitude: -77.548014, zipCode: "17058"},
	{latitude: 40.589128, longitude: -77.390558, zipCode: "17059"},
	{latitude: 40.512957, longitude: -77.860364, zipCode: "17060"},
	{latitude: 40.563855, longitude: -76.900146, zipCode: "17061"},
	{latitude: 40.546773, longitude: -77.187656, zipCode: "17062"},
	{latitude: 40.761026, longitude: -77.484947, zipCode: "17063"},
	{latitude: 40.241695, longitude: -76.475272, zipCode: "17064"},
	{latitude: 40.070864, longitude: -77.232432, zipCode: "17065"},
	{latitude: 40.359304, longitude: -77.854619, zipCode: "17066"},
	{latitude: 40.390423, longitude: -76.315048, zipCode: "17067"},
	{latitude: 40.399127, longitude: -77.182392, zipCode: "17068"},
	{latitude: 40.458213, longitude: -76.98063, zipCode: "17069"},
	{latitude: 40.199561, longitude: -76.854444, zipCode: "17070"},
	{latitude: 40.295755, longitude: -77.614282, zipCode: "17071"},
	{latitude: 40.302838, longitude: -76.255161, zipCode: "17073"},
	{latitude: 40.479283, longitude: -77.148375, zipCode: "17074"},
	{latitude: 40.39366, longitude: -77.834499, zipCode: "17075"},
	{latitude: 40.620926, longitude: -77.307498, zipCode: "17076"},
	{latitude: 40.402378, longitude: -76.536464, zipCode: "17077"},
	{latitude: 40.287647, longitude: -76.580858, zipCode: "17078"},
	{latitude: 40.640543, longitude: -76.802909, zipCode: "17080"},
	{latitude: 40.201172, longitude: -77.283279, zipCode: "17081"},
	{latitude: 40.495064, longitude: -77.44839, zipCode: "17082"},
	{latitude: 40.675663, longitude: -77.626501, zipCode: "17084"},
	{latitude: 40.690624, longitude: -77.122007, zipCode: "17086"},
	{latitude: 40.404009, longitude: -76.268426, zipCode: "17087"},
	{latitude: 40.300443, longitude: -76.295163, zipCode: "17088"},
	{latitude: 40.318207, longitude: -77.181043, zipCode: "17090"},
	{latitude: 40.310323, longitude: -76.927888, zipCode: "17093"},
	{latitude: 40.585896, longitude: -77.222303, zipCode: "17094"},
	{latitude: 40.574148, longitude: -76.676881, zipCode: "17097"},
	{latitude: 40.589993, longitude: -76.630709, zipCode: "17098"},
	{latitude: 40.63673, longitude: -77.580106, zipCode: "17099"},
	{latitude: 40.258414, longitude: -76.89291, zipCode: "17101"},
	{latitude: 40.267798, longitude: -76.902414, zipCode: "17102"},
	{latitude: 40.274984, longitude: -76.863341, zipCode: "17103"},
	{latitude: 40.255017, longitude: -76.8672, zipCode: "17104"},
	{latitude: 40.289842, longitude: -76.824112, zipCode: "17109"},
	{latitude: 40.323487, longitude: -76.888179, zipCode: "17110"},
	{latitude: 40.270309, longitude: -76.780575, zipCode: "17111"},
	{latitude: 40.373989, longitude: -76.770206, zipCode: "17112"},
	{latitude: 40.230191, longitude: -76.821173, zipCode: "17113"},
	{latitude: 40.265123, longitude: -76.882763, zipCode: "17120"},
	{latitude: 39.96007, longitude: -77.657179, zipCode: "17201"},
	{latitude: 39.923181, longitude: -77.690468, zipCode: "17202"},
	{latitude: 40.191357, longitude: -77.661053, zipCode: "17210"},
	{latitude: 39.754707, longitude: -78.414571, zipCode: "17211"},
	{latitude: 39.812634, longitude: -78.064287, zipCode: "17212"},
	{latitude: 40.261245, longitude: -77.764539, zipCode: "17213"},
	{latitude: 39.748134, longitude: -77.470274, zipCode: "17214"},
	{latitude: 40.083767, longitude: -77.878633, zipCode: "17215"},
	{latitude: 40.238223, longitude: -77.722346, zipCode: "17217"},
	{latitude: 40.223951, longitude: -77.69282, zipCode: "17219"},
	{latitude: 40.174575, longitude: -77.73861, zipCode: "17220"},
	{latitude: 40.049213, longitude: -77.822182, zipCode: "17221"},
	{latitude: 39.895963, longitude: -77.493997, zipCode: "17222"},
	{latitude: 40.064649, longitude: -77.941989, zipCode: "17223"},
	{latitude: 39.979215, longitude: -77.884511, zipCode: "17224"},
	{latitude: 39.783506, longitude: -77.76287, zipCode: "17225"},
	{latitude: 39.995102, longitude: -78.098342, zipCode: "17228"},
	{latitude: 40.084004, longitude: -78.010139, zipCode: "17229"},
	{latitude: 40.094631, longitude: -77.633439, zipCode: "17232"},
	{latitude: 39.975605, longitude: -77.98716, zipCode: "17233"},
	{latitude: 39.857018, longitude: -77.697535, zipCode: "17235"},
	{latitude: 39.800185, longitude: -77.947396, zipCode: "17236"},
	{latitude: 39.837041, longitude: -77.540851, zipCode: "17237"},
	{latitude: 39.861119, longitude: -78.130033, zipCode: "17238"},
	{latitude: 40.139771, longitude: -77.830069, zipCode: "17239"},
	{latitude: 40.150871, longitude: -77.579686, zipCode: "17240"},
	{latitude: 40.181041, longitude: -77.406793, zipCode: "17241"},
	{latitude: 40.283629, longitude: -77.815059, zipCode: "17243"},
	{latitude: 40.090333, longitude: -77.672277, zipCode: "17244"},
	{latitude: 40.060726, longitude: -77.675777, zipCode: "17246"},
	{latitude: 39.798776, longitude: -77.579352, zipCode: "17247"},
	{latitude: 40.241883, longitude: -77.900179, zipCode: "17249"},
	{latitude: 39.736238, longitude: -77.522871, zipCode: "17250"},
	{latitude: 40.12287, longitude: -77.666668, zipCode: "17251"},
	{latitude: 39.912329, longitude: -77.82691, zipCode: "17252"},
	{latitude: 40.212399, longitude: -78.006251, zipCode: "17253"},
	{latitude: 39.96848, longitude: -77.58932, zipCode: "17254"},
	{latitude: 40.14924, longitude: -77.866728, zipCode: "17255"},
	{latitude: 40.055951, longitude: -77.496665, zipCode: "17257"},
	{latitude: 40.296045, longitude: -77.893701, zipCode: "17260"},
	{latitude: 39.859807, longitude: -77.511706, zipCode: "17261"},
	{latitude: 40.127086, longitude: -77.767903, zipCode: "17262"},
	{latitude: 39.724078, longitude: -77.717245, zipCode: "17263"},
	{latitude: 40.179907, longitude: -77.993335, zipCode: "17264"},
	{latitude: 40.032178, longitude: -77.774993, zipCode: "17265"},
	{latitude: 40.088568, longitude: -77.412933, zipCode: "17266"},
	{latitude: 39.807955, longitude: -78.239657, zipCode: "17267"},
	{latitude: 39.774913, longitude: -77.575622, zipCode: "17268"},
	{latitude: 40.101875, longitude: -77.803623, zipCode: "17271"},
	{latitude: 39.895227, longitude: -76.978982, zipCode: "17301"},
	{latitude: 39.81726, longitude: -76.41368, zipCode: "17302"},
	{latitude: 39.92609, longitude: -77.29762, zipCode: "17303"},
	{latitude: 39.977245, longitude: -77.232742, zipCode: "17304"},
	{latitude: 39.983798, longitude: -77.249695, zipCode: "17306"},
	{latitude: 39.956679, longitude: -77.315185, zipCode: "17307"},
	{latitude: 39.87358, longitude: -76.448883, zipCode: "17309"},
	{latitude: 39.816966, longitude: -76.841834, zipCode: "17311"},
	{latitude: 39.884759, longitude: -76.657175, zipCode: "17313"},
	{latitude: 39.749243, longitude: -76.325673, zipCode: "17314"},
	{latitude: 40.013828, longitude: -76.868711, zipCode: "17315"},
	{latitude: 39.965466, longitude: -77.007959, zipCode: "17316"},
	{latitude: 39.971309, longitude: -76.522617, zipCode: "17317"},
	{latitude: 40.020192, longitude: -76.726062, zipCode: "17318"},
	{latitude: 40.160489, longitude: -76.793975, zipCode: "17319"},
	{latitude: 39.770206, longitude: -77.387568, zipCode: "17320"},
	{latitude: 39.749675, longitude: -76.444345, zipCode: "17321"},
	{latitude: 39.855457, longitude: -76.535188, zipCode: "17322"},
	{latitude: 40.075225, longitude: -77.026693, zipCode: "17323"},
	{latitude: 40.030395, longitude: -77.236846, zipCode: "17324"},
	{latitude: 39.826258, longitude: -77.227606, zipCode: "17325"},
	{latitude: 39.779298, longitude: -76.753816, zipCode: "17327"},
	{latitude: 39.758637, longitude: -76.852508, zipCode: "17329"},
	{latitude: 39.791218, longitude: -76.980323, zipCode: "17331"},
	{latitude: 40.134907, longitude: -76.876143, zipCode: "17339"},
	{latitude: 39.755221, longitude: -77.116774, zipCode: "17340"},
	{latitude: 39.852798, longitude: -76.707836, zipCode: "17342"},
	{latitude: 39.868625, longitude: -77.329616, zipCode: "17343"},
	{latitude: 39.805322, longitude: -77.018876, zipCode: "17344"},
	{latitude: 40.072987, longitude: -76.727551, zipCode: "17345"},
	{latitude: 40.046858, longitude: -76.675341, zipCode: "17347"},
	{latitude: 39.745102, longitude: -76.65516, zipCode: "17349"},
	{latitude: 39.890088, longitude: -77.079399, zipCode: "17350"},
	{latitude: 39.761946, longitude: -76.500063, zipCode: "17352"},
	{latitude: 39.887165, longitude: -77.393094, zipCode: "17353"},
	{latitude: 39.75982, longitude: -76.696024, zipCode: "17355"},
	{latitude: 39.898326, longitude: -76.583705, zipCode: "17356"},
	{latitude: 39.851962, longitude: -76.753723, zipCode: "17360"},
	{latitude: 39.766082, longitude: -76.676983, zipCode: "17361"},
	{latitude: 39.847679, longitude: -76.868704, zipCode: "17362"},
	{latitude: 39.767343, longitude: -76.58501, zipCode: "17363"},
	{latitude: 39.92987, longitude: -76.900444, zipCode: "17364"},
	{latitude: 40.061392, longitude: -76.940309, zipCode: "17365"},
	{latitude: 39.93279, longitude: -76.556703, zipCode: "17366"},
	{latitude: 39.984559, longitude: -76.519611, zipCode: "17368"},
	{latitude: 40.118209, longitude: -76.782812, zipCode: "17370"},
	{latitude: 39.902613, longitude: -76.787991, zipCode: "17371"},
	{latitude: 39.992447, longitude: -77.107953, zipCode: "17372"},
	{latitude: 39.958779, longitude: -76.733219, zipCode: "17401"},
	{latitude: 39.956996, longitude: -76.659977, zipCode: "17402"},
	{latitude: 39.919821, longitude: -76.711182, zipCode: "17403"},
	{latitude: 40.002636, longitude: -76.773535, zipCode: "17404"},
	{latitude: 40.014709, longitude: -76.640873, zipCode: "17406"},
	{latitude: 39.887079, longitude: -76.71275, zipCode: "17407"},
	{latitude: 39.93282, longitude: -76.799214, zipCode: "17408"},
	{latitude: 40.156733, longitude: -76.20608, zipCode: "17501"},
	{latitude: 40.100904, longitude: -76.660147, zipCode: "17502"},
	{latitude: 40.024472, longitude: -76.328494, zipCode: "17504"},
	{latitude: 40.063501, longitude: -76.192436, zipCode: "17505"},
	{latitude: 40.117172, longitude: -76.052736, zipCode: "17506"},
	{latitude: 40.198053, longitude: -76.01624, zipCode: "17507"},
	{latitude: 40.124577, longitude: -76.213904, zipCode: "17508"},
	{latitude: 39.906919, longitude: -76.029457, zipCode: "17509"},
	{latitude: 40.040069, longitude: -76.486021, zipCode: "17512"},
	{latitude: 39.926859, longitude: -76.384989, zipCode: "17516"},
	{latitude: 40.244751, longitude: -76.132881, zipCode: "17517"},
	{latitude: 39.80893, longitude: -76.253014, zipCode: "17518"},
	{latitude: 40.141438, longitude: -76.023423, zipCode: "17519"},
	{latitude: 40.097518, longitude: -76.349193, zipCode: "17520"},
	{latitude: 40.173688, longitude: -76.170494, zipCode: "17522"},
	{latitude: 40.016431, longitude: -75.994145, zipCode: "17527"},
	{latitude: 40.041933, longitude: -76.097142, zipCode: "17529"},
	{latitude: 39.858222, longitude: -76.287916, zipCode: "17532"},
	{latitude: 40.036612, longitude: -76.112164, zipCode: "17534"},
	{latitude: 40.012085, longitude: -76.036127, zipCode: "17535"},
	{latitude: 39.848034, longitude: -76.073774, zipCode: "17536"},
	{latitude: 40.082629, longitude: -76.414548, zipCode: "17538"},
	{latitude: 40.097608, longitude: -76.189987, zipCode: "17540"},
	{latitude: 40.181032, longitude: -76.29538, zipCode: "17543"},
	{latitude: 40.172979, longitude: -76.422168, zipCode: "17545"},
	{latitude: 40.070915, longitude: -76.584858, zipCode: "17547"},
	{latitude: 40.074909, longitude: -76.583516, zipCode: "17550"},
	{latitude: 39.978498, longitude: -76.372983, zipCode: "17551"},
	{latitude: 40.108352, longitude: -76.51092, zipCode: "17552"},
	{latitude: 40.040303, longitude: -76.424176, zipCode: "17554"},
	{latitude: 40.122377, longitude: -75.959392, zipCode: "17555"},
	{latitude: 40.102865, longitude: -76.075182, zipCode: "17557"},
	{latitude: 39.912675, longitude: -76.227681, zipCode: "17560"},
	{latitude: 39.969976, longitude: -76.091632, zipCode: "17562"},
	{latitude: 39.760748, longitude: -76.193306, zipCode: "17563"},
	{latitude: 39.890772, longitude: -76.335311, zipCode: "17565"},
	{latitude: 39.867186, longitude: -76.147513, zipCode: "17566"},
	{latitude: 39.948019, longitude: -76.234129, zipCode: "17568"},
	{latitude: 40.271433, longitude: -76.096577, zipCode: "17569"},
	{latitude: 40.12568, longitude: -76.570628, zipCode: "17570"},
	{latitude: 40.005624, longitude: -76.15216, zipCode: "17572"},
	{latitude: 40.037111, longitude: -76.197806, zipCode: "17576"},
	{latitude: 40.229906, longitude: -76.166163, zipCode: "17578"},
	{latitude: 39.960406, longitude: -76.180752, zipCode: "17579"},
	{latitude: 40.155776, longitude: -76.054995, zipCode: "17581"},
	{latitude: 39.988249, longitude: -76.454665, zipCode: "17582"},
	{latitude: 39.959837, longitude: -76.265024, zipCode: "17584"},
	{latitude: 40.072957, longitude: -76.313729, zipCode: "17601"},
	{latitude: 40.014623, longitude: -76.246351, zipCode: "17602"},
	{latitude: 40.030839, longitude: -76.330432, zipCode: "17603"},
	{latitude: 40.112734, longitude: -76.304715, zipCode: "17606"},
	{latitude: 41.345044, longitude: -76.857249, zipCode: "17701"},
	{latitude: 41.183488, longitude: -77.077271, zipCode: "17702"},
	{latitude: 41.179084, longitude: -77.202589, zipCode: "17720"},
	{latitude: 41.181568, longitude: -77.319403, zipCode: "17721"},
	{latitude: 41.435297, longitude: -77.471356, zipCode: "17723"},
	{latitude: 41.6377, longitude: -76.800688, zipCode: "17724"},
	{latitude: 41.529261, longitude: -77.464763, zipCode: "17727"},
	{latitude: 41.334774, longitude: -77.078567, zipCode: "17728"},
	{latitude: 41.511239, longitude: -77.736103, zipCode: "17729"},
	{latitude: 41.106905, longitude: -76.875924, zipCode: "17730"},
	{latitude: 41.42941, longitude: -76.578672, zipCode: "17731"},
	{latitude: 41.285628, longitude: -76.683033, zipCode: "17737"},
	{latitude: 41.416293, longitude: -77.393562, zipCode: "17739"},
	{latitude: 41.235613, longitude: -77.256778, zipCode: "17740"},
	{latitude: 41.239052, longitude: -76.579668, zipCode: "17742"},
	{latitude: 41.24097, longitude: -77.153274, zipCode: "17744"},
	{latitude: 41.285072, longitude: -77.471166, zipCode: "17745"},
	{latitude: 41.032551, longitude: -77.331831, zipCode: "17747"},
	{latitude: 41.147811, longitude: -77.340152, zipCode: "17748"},
	{latitude: 41.072303, longitude: -76.81353, zipCode: "17749"},
	{latitude: 41.057866, longitude: -77.482253, zipCode: "17750"},
	{latitude: 41.129525, longitude: -77.521238, zipCode: "17751"},
	{latitude: 41.183141, longitude: -76.91983, zipCode: "17752"},
	{latitude: 41.311612, longitude: -76.889212, zipCode: "17754"},
	{latitude: 41.204764, longitude: -76.729928, zipCode: "17756"},
	{latitude: 41.350305, longitude: -76.544903, zipCode: "17758"},
	{latitude: 41.413576, longitude: -77.657357, zipCode: "17760"},
	{latitude: 41.284336, longitude: -76.699941, zipCode: "17762"},
	{latitude: 41.502453, longitude: -76.912176, zipCode: "17763"},
	{latitude: 41.342778, longitude: -77.826495, zipCode: "17764"},
	{latitude: 41.581828, longitude: -76.982013, zipCode: "17765"},
	{latitude: 41.558688, longitude: -76.755993, zipCode: "17768"},
	{latitude: 41.510689, longitude: -77.496445, zipCode: "17769"},
	{latitude: 41.437339, longitude: -77.03554, zipCode: "17771"},
	{latitude: 41.125057, longitude: -76.713033, zipCode: "17772"},
	{latitude: 41.251867, longitude: -76.546733, zipCode: "17774"},
	{latitude: 41.41464, longitude: -77.292868, zipCode: "17776"},
	{latitude: 41.106517, longitude: -76.821696, zipCode: "17777"},
	{latitude: 41.296316, longitude: -77.979525, zipCode: "17778"},
	{latitude: 41.208075, longitude: -77.37699, zipCode: "17779"},
	{latitude: 40.837399, longitude: -76.760701, zipCode: "17801"},
	{latitude: 41.100951, longitude: -77.03905, zipCode: "17810"},
	{latitude: 40.733279, longitude: -77.23524, zipCode: "17812"},
	{latitude: 40.786898, longitude: -77.184303, zipCode: "17813"},
	{latitude: 41.258909, longitude: -76.371219, zipCode: "17814"},
	{latitude: 41.022328, longitude: -76.429517, zipCode: "17815"},
	{latitude: 40.895639, longitude: -76.403739, zipCode: "17820"},
	{latitude: 40.990147, longitude: -76.644205, zipCode: "17821"},
	{latitude: 40.968662, longitude: -76.603296, zipCode: "17822"},
	{latitude: 40.744977, longitude: -76.777978, zipCode: "17823"},
	{latitude: 40.867611, longitude: -76.492771, zipCode: "17824"},
	{latitude: 40.75286, longitude: -76.968032, zipCode: "17827"},
	{latitude: 40.900568, longitude: -77.1554, zipCode: "17829"},
	{latitude: 40.686062, longitude: -76.805198, zipCode: "17830"},
	{latitude: 40.805568, longitude: -76.455006, zipCode: "17832"},
	{latitude: 40.78021, longitude: -76.466875, zipCode: "17834"},
	{latitude: 40.883523, longitude: -77.197994, zipCode: "17835"},
	{latitude: 40.714342, longitude: -76.606462, zipCode: "17836"},
	{latitude: 40.974977, longitude: -76.94515, zipCode: "17837"},
	{latitude: 40.774382, longitude: -76.426873, zipCode: "17840"},
	{latitude: 40.72538, longitude: -77.363424, zipCode: "17841"},
	{latitude: 40.801316, longitude: -77.043454, zipCode: "17842"},
	{latitude: 40.959839, longitude: -77.085195, zipCode: "17844"},
	{latitude: 40.88437, longitude: -77.21157, zipCode: "17845"},
	{latitude: 41.141745, longitude: -76.517122, zipCode: "17846"},
	{latitude: 41.007985, longitude: -76.810949, zipCode: "17847"},
	{latitude: 40.967179, longitude: -76.858577, zipCode: "17850"},
	{latitude: 40.8028, longitude: -76.424701, zipCode: "17851"},
	{latitude: 40.687984, longitude: -77.010671, zipCode: "17853"},
	{latitude: 40.889993, longitude: -76.967853, zipCode: "17855"},
	{latitude: 41.045019, longitude: -77.008116, zipCode: "17856"},
	{latitude: 40.932136, longitude: -76.772972, zipCode: "17857"},
	{latitude: 40.84241, longitude: -76.423278, zipCode: "17858"},
	{latitude: 41.116755, longitude: -76.398537, zipCode: "17859"},
	{latitude: 40.839179, longitude: -76.603574, zipCode: "17860"},
	{latitude: 40.77463, longitude: -77.076157, zipCode: "17861"},
	{latitude: 40.860506, longitude: -77.056395, zipCode: "17862"},
	{latitude: 40.704727, longitude: -76.905819, zipCode: "17864"},
	{latitude: 40.986673, longitude: -76.789771, zipCode: "17865"},
	{latitude: 40.771113, longitude: -76.596493, zipCode: "17866"},
	{latitude: 40.714661, longitude: -76.690322, zipCode: "17867"},
	{latitude: 40.955445, longitude: -76.630962, zipCode: "17868"},
	{latitude: 40.80804, longitude: -76.887755, zipCode: "17870"},
	{latitude: 40.758168, longitude: -76.688372, zipCode: "17872"},
	{latitude: 40.858162, longitude: -76.825783, zipCode: "17876"},
	{latitude: 41.176018, longitude: -76.323488, zipCode: "17878"},
	{latitude: 40.891097, longitude: -77.123864, zipCode: "17880"},
	{latitude: 40.780997, longitude: -76.686347, zipCode: "17881"},
	{latitude: 41.051986, longitude: -76.674978, zipCode: "17884"},
	{latitude: 40.866345, longitude: -77.314017, zipCode: "17885"},
	{latitude: 41.018188, longitude: -76.876117, zipCode: "17886"},
	{latitude: 41.07531, longitude: -76.895759, zipCode: "17887"},
	{latitude: 40.818676, longitude: -76.378947, zipCode: "17888"},
	{latitude: 40.877739, longitude: -76.921282, zipCode: "17889"},
	{latitude: 40.694711, longitude: -76.274753, zipCode: "17901"},
	{latitude: 40.808425, longitude: -76.32783, zipCode: "17920"},
	{latitude: 40.751575, longitude: -76.360586, zipCode: "17921"},
	{latitude: 40.582473, longitude: -76.110632, zipCode: "17922"},
	{latitude: 40.661402, longitude: -76.318735, zipCode: "17923"},
	{latitude: 40.754797, longitude: -76.072504, zipCode: "17925"},
	{latitude: 40.631054, longitude: -76.192766, zipCode: "17929"},
	{latitude: 40.708675, longitude: -76.115346, zipCode: "17930"},
	{latitude: 40.786491, longitude: -76.214575, zipCode: "17931"},
	{latitude: 40.603688, longitude: -76.242608, zipCode: "17933"},
	{latitude: 40.799168, longitude: -76.213444, zipCode: "17934"},
	{latitude: 40.794324, longitude: -76.280037, zipCode: "17935"},
	{latitude: 40.750402, longitude: -76.340065, zipCode: "17936"},
	{latitude: 40.654247, longitude: -76.501236, zipCode: "17938"},
	{latitude: 40.686254, longitude: -76.613215, zipCode: "17941"},
	{latitude: 40.761663, longitude: -76.379394, zipCode: "17943"},
	{latitude: 40.66048, longitude: -76.289032, zipCode: "17944"},
	{latitude: 40.785343, longitude: -76.375466, zipCode: "17945"},
	{latitude: 40.810364, longitude: -76.256372, zipCode: "17946"},
	{latitude: 40.820393, longitude: -76.144031, zipCode: "17948"},
	{latitude: 40.796066, longitude: -76.236125, zipCode: "17949"},
	{latitude: 40.679171, longitude: -76.244185, zipCode: "17951"},
	{latitude: 40.764362, longitude: -76.060032, zipCode: "17952"},
	{latitude: 40.746816, longitude: -76.100452, zipCode: "17953"},
	{latitude: 40.690459, longitude: -76.259866, zipCode: "17954"},
	{latitude: 40.593575, longitude: -76.516637, zipCode: "17957"},
	{latitude: 40.739797, longitude: -76.164668, zipCode: "17959"},
	{latitude: 40.697241, longitude: -75.947044, zipCode: "17960"},
	{latitude: 40.649699, longitude: -76.060949, zipCode: "17961"},
	{latitude: 40.559863, longitude: -76.394119, zipCode: "17963"},
	{latitude: 40.702954, longitude: -76.514515, zipCode: "17964"},
	{latitude: 40.699246, longitude: -76.165356, zipCode: "17965"},
	{latitude: 40.869818, longitude: -76.207085, zipCode: "17967"},
	{latitude: 40.641705, longitude: -76.626286, zipCode: "17968"},
	{latitude: 40.720663, longitude: -76.190544, zipCode: "17970"},
	{latitude: 40.579336, longitude: -76.227913, zipCode: "17972"},
	{latitude: 40.695924, longitude: -76.236127, zipCode: "17974"},
	{latitude: 40.819899, longitude: -76.210503, zipCode: "17976"},
	{latitude: 40.616479, longitude: -76.652263, zipCode: "17978"},
	{latitude: 40.567253, longitude: -76.202053, zipCode: "17979"},
	{latitude: 40.518684, longitude: -76.644559, zipCode: "17980"},
	{latitude: 40.630984, longitude: -76.392688, zipCode: "17981"},
	{latitude: 40.768001, longitude: -76.038084, zipCode: "17982"},
	{latitude: 40.643994, longitude: -76.55038, zipCode: "17983"},
	{latitude: 40.918455, longitude: -76.23871, zipCode: "17985"},
	{latitude: 40.477013, longitude: -75.646129, zipCode: "18011"},
	{latitude: 40.811314, longitude: -75.591615, zipCode: "18012"},
	{latitude: 40.853468, longitude: -75.165323, zipCode: "18013"},
	{latitude: 40.76715, longitude: -75.413067, zipCode: "18014"},
	{latitude: 40.587439, longitude: -75.366758, zipCode: "18015"},
	{latitude: 40.659957, longitude: -75.387306, zipCode: "18017"},
	{latitude: 40.627594, longitude: -75.395584, zipCode: "18018"},
	{latitude: 40.672385, longitude: -75.327459, zipCode: "18020"},
	{latitude: 40.804067, longitude: -75.663487, zipCode: "18030"},
	{latitude: 40.559553, longitude: -75.648875, zipCode: "18031"},
	{latitude: 40.656191, longitude: -75.467788, zipCode: "18032"},
	{latitude: 40.541387, longitude: -75.41898, zipCode: "18034"},
	{latitude: 40.751946, longitude: -75.537984, zipCode: "18035"},
	{latitude: 40.509206, longitude: -75.388539, zipCode: "18036"},
	{latitude: 40.683132, longitude: -75.547957, zipCode: "18037"},
	{latitude: 40.794842, longitude: -75.486224, zipCode: "18038"},
	{latitude: 40.745941, longitude: -75.225667, zipCode: "18040"},
	{latitude: 40.420404, longitude: -75.50972, zipCode: "18041"},
	{latitude: 40.643635, longitude: -75.226627, zipCode: "18042"},
	{latitude: 40.691748, longitude: -75.274923, zipCode: "18045"},
	{latitude: 40.548009, longitude: -75.560346, zipCode: "18046"},
	{latitude: 40.522207, longitude: -75.490269, zipCode: "18049"},
	{latitude: 40.59561, longitude: -75.666932, zipCode: "18051"},
	{latitude: 40.657428, longitude: -75.504255, zipCode: "18052"},
	{latitude: 40.71923, longitude: -75.704117, zipCode: "18053"},
	{latitude: 40.352369, longitude: -75.438736, zipCode: "18054"},
	{latitude: 40.591583, longitude: -75.304353, zipCode: "18055"},
	{latitude: 40.447051, longitude: -75.547229, zipCode: "18056"},
	{latitude: 40.888739, longitude: -75.49429, zipCode: "18058"},
	{latitude: 40.723499, longitude: -75.543084, zipCode: "18059"},
	{latitude: 40.505829, longitude: -75.579888, zipCode: "18062"},
	{latitude: 40.782777, longitude: -75.181792, zipCode: "18063"},
	{latitude: 40.757008, longitude: -75.315962, zipCode: "18064"},
	{latitude: 40.699331, longitude: -75.612269, zipCode: "18065"},
	{latitude: 40.664462, longitude: -75.7401, zipCode: "18066"},
	{latitude: 40.717001, longitude: -75.482335, zipCode: "18067"},
	{latitude: 40.625246, longitude: -75.615098, zipCode: "18069"},
	{latitude: 40.430437, longitude: -75.539196, zipCode: "18070"},
	{latitude: 40.834214, longitude: -75.555278, zipCode: "18071"},
	{latitude: 40.84414, longitude: -75.259135, zipCode: "18072"},
	{latitude: 40.386499, longitude: -75.47443, zipCode: "18073"},
	{latitude: 40.316737, longitude: -75.515686, zipCode: "18074"},
	{latitude: 40.373755, longitude: -75.481545, zipCode: "18076"},
	{latitude: 40.570065, longitude: -75.237115, zipCode: "18077"},
	{latitude: 40.670888, longitude: -75.620714, zipCode: "18078"},
	{latitude: 40.744052, longitude: -75.656866, zipCode: "18079"},
	{latitude: 40.730808, longitude: -75.638089, zipCode: "18080"},
	{latitude: 40.563698, longitude: -75.281584, zipCode: "18081"},
	{latitude: 40.755137, longitude: -75.264548, zipCode: "18083"},
	{latitude: 40.741702, longitude: -75.254287, zipCode: "18085"},
	{latitude: 40.739162, longitude: -75.548368, zipCode: "18086"},
	{latitude: 40.556831, longitude: -75.593645, zipCode: "18087"},
	{latitude: 40.765692, longitude: -75.557509, zipCode: "18088"},
	{latitude: 40.824846, longitude: -75.325651, zipCode: "18091"},
	{latitude: 40.469697, longitude: -75.51314, zipCode: "18092"},
	{latitude: 40.603231, longitude: -75.469505, zipCode: "18101"},
	{latitude: 40.607938, longitude: -75.476203, zipCode: "18102"},
	{latitude: 40.570308, longitude: -75.488926, zipCode: "18103"},
	{latitude: 40.611658, longitude: -75.546853, zipCode: "18104"},
	{latitude: 40.563735, longitude: -75.565198, zipCode: "18106"},
	{latitude: 40.635486, longitude: -75.441922, zipCode: "18109"},
	{latitude: 40.584272, longitude: -75.624789, zipCode: "18195"},
	{latitude: 40.951974, longitude: -75.953925, zipCode: "18201"},
	{latitude: 40.954433, longitude: -76.045987, zipCode: "18202"},
	{latitude: 41.001862, longitude: -75.59105, zipCode: "18210"},
	{latitude: 40.744079, longitude: -75.830407, zipCode: "18211"},
	{latitude: 40.772951, longitude: -75.718831, zipCode: "18212"},
	{latitude: 40.805771, longitude: -76.088524, zipCode: "18214"},
	{latitude: 40.942664, longitude: -75.889032, zipCode: "18216"},
	{latitude: 40.81974, longitude: -75.916102, zipCode: "18218"},
	{latitude: 40.991045, longitude: -76.058262, zipCode: "18219"},
	{latitude: 40.837322, longitude: -76.081715, zipCode: "18220"},
	{latitude: 41.001462, longitude: -75.918954, zipCode: "18221"},
	{latitude: 41.035227, longitude: -76.003468, zipCode: "18222"},
	{latitude: 40.98226, longitude: -75.950004, zipCode: "18223"},
	{latitude: 41.024571, longitude: -75.889265, zipCode: "18224"},
	{latitude: 40.981311, longitude: -75.971311, zipCode: "18225"},
	{latitude: 40.931517, longitude: -75.682343, zipCode: "18229"},
	{latitude: 40.920118, longitude: -75.930569, zipCode: "18230"},
	{latitude: 40.900734, longitude: -76.004552, zipCode: "18231"},
	{latitude: 40.837221, longitude: -75.873962, zipCode: "18232"},
	{latitude: 40.992592, longitude: -75.964513, zipCode: "18234"},
	{latitude: 40.835018, longitude: -75.691474, zipCode: "18235"},
	{latitude: 40.878534, longitude: -76.050441, zipCode: "18237"},
	{latitude: 40.989676, longitude: -75.981362, zipCode: "18239"},
	{latitude: 40.862917, longitude: -75.842359, zipCode: "18240"},
	{latitude: 40.944364, longitude: -76.164982, zipCode: "18241"},
	{latitude: 40.90602, longitude: -76.123495, zipCode: "18242"},
	{latitude: 40.823663, longitude: -75.66874, zipCode: "18244"},
	{latitude: 40.85279, longitude: -76.030107, zipCode: "18245"},
	{latitude: 40.95744, longitude: -76.195028, zipCode: "18246"},
	{latitude: 40.900868, longitude: -76.094503, zipCode: "18248"},
	{latitude: 40.987794, longitude: -76.116117, zipCode: "18249"},
	{latitude: 40.812659, longitude: -75.858686, zipCode: "18250"},
	{latitude: 40.781606, longitude: -75.972444, zipCode: "18252"},
	{latitude: 40.915913, longitude: -75.965651, zipCode: "18254"},
	{latitude: 40.933491, longitude: -75.829695, zipCode: "18255"},
	{latitude: 40.940396, longitude: -76.14732, zipCode: "18256"},
	{latitude: 41.04236, longitude: -75.175818, zipCode: "18301"},
	{latitude: 41.096574, longitude: -75.111116, zipCode: "18302"},
	{latitude: 41.026293, longitude: -75.282886, zipCode: "18321"},
	{latitude: 40.918523, longitude: -75.393279, zipCode: "18322"},
	{latitude: 41.195599, longitude: -75.272693, zipCode: "18323"},
	{latitude: 41.158143, longitude: -75.00069, zipCode: "18324"},
	{latitude: 41.26195, longitude: -75.266692, zipCode: "18325"},
	{latitude: 41.154998, longitude: -75.247188, zipCode: "18326"},
	{latitude: 40.983375, longitude: -75.146645, zipCode: "18327"},
	{latitude: 41.2285, longitude: -74.973985, zipCode: "18328"},
	{latitude: 40.962546, longitude: -75.449916, zipCode: "18330"},
	{latitude: 40.908412, longitude: -75.440228, zipCode: "18331"},
	{latitude: 41.094747, longitude: -75.261341, zipCode: "18332"},
	{latitude: 40.898344, longitude: -75.490978, zipCode: "18333"},
	{latitude: 41.072851, longitude: -75.441764, zipCode: "18334"},
	{latitude: 41.058231, longitude: -75.107671, zipCode: "18335"},
	{latitude: 41.373373, longitude: -74.73975, zipCode: "18336"},
	{latitude: 41.32911, longitude: -74.871787, zipCode: "18337"},
	{latitude: 41.417768, longitude: -74.765898, zipCode: "18340"},
	{latitude: 41.170421, longitude: -75.304875, zipCode: "18342"},
	{latitude: 40.889898, longitude: -75.109437, zipCode: "18343"},
	{latitude: 41.121414, longitude: -75.334054, zipCode: "18344"},
	{latitude: 41.142754, longitude: -75.421191, zipCode: "18346"},
	{latitude: 41.154683, longitude: -75.542841, zipCode: "18347"},
	{latitude: 41.099516, longitude: -75.368194, zipCode: "18349"},
	{latitude: 41.114427, longitude: -75.458202, zipCode: "18350"},
	{latitude: 40.923109, longitude: -75.099884, zipCode: "18351"},
	{latitude: 40.895341, longitude: -75.357667, zipCode: "18353"},
	{latitude: 40.922603, longitude: -75.322095, zipCode: "18354"},
	{latitude: 41.076955, longitude: -75.362899, zipCode: "18355"},
	{latitude: 41.01578, longitude: -75.111962, zipCode: "18356"},
	{latitude: 41.231653, longitude: -75.210713, zipCode: "18357"},
	{latitude: 40.967289, longitude: -75.288004, zipCode: "18360"},
	{latitude: 41.088357, longitude: -75.336595, zipCode: "18370"},
	{latitude: 41.156225, longitude: -75.036657, zipCode: "18371"},
	{latitude: 41.044043, longitude: -75.334289, zipCode: "18372"},
	{latitude: 41.508998, longitude: -75.534936, zipCode: "18403"},
	{latitude: 41.599854, longitude: -75.103874, zipCode: "18405"},
	{latitude: 41.591754, longitude: -75.534853, zipCode: "18407"},
	{latitude: 41.459175, longitude: -75.746872, zipCode: "18411"},
	{latitude: 41.647649, longitude: -75.532704, zipCode: "18413"},
	{latitude: 41.541941, longitude: -75.745401, zipCode: "18414"},
	{latitude: 41.755374, longitude: -75.082464, zipCode: "18415"},
	{latitude: 41.809703, longitude: -75.201115, zipCode: "18417"},
	{latitude: 41.577245, longitude: -75.79753, zipCode: "18419"},
	{latitude: 41.60768, longitude: -75.699862, zipCode: "18420"},
	{latitude: 41.664189, longitude: -75.492862, zipCode: "18421"},
	{latitude: 41.244183, longitude: -75.517295, zipCode: "18424"},
	{latitude: 41.438455, longitude: -75.043136, zipCode: "18425"},
	{latitude: 41.325507, longitude: -75.239061, zipCode: "18426"},
	{latitude: 41.450408, longitude: -75.109392, zipCode: "18428"},
	{latitude: 41.740828, longitude: -75.474185, zipCode: "18430"},
	{latitude: 41.61647, longitude: -75.257711, zipCode: "18431"},
	{latitude: 41.573307, longitude: -75.58133, zipCode: "18433"},
	{latitude: 41.452222, longitude: -75.54128, zipCode: "18434"},
	{latitude: 41.490583, longitude: -75.015021, zipCode: "18435"},
	{latitude: 41.442792, longitude: -75.393451, zipCode: "18436"},
	{latitude: 41.875251, longitude: -75.285748, zipCode: "18437"},
	{latitude: 41.432453, longitude: -75.245288, zipCode: "18438"},
	{latitude: 41.825503, longitude: -75.348966, zipCode: "18439"},
	{latitude: 41.559335, longitude: -75.765121, zipCode: "18440"},
	{latitude: 41.663281, longitude: -75.619249, zipCode: "18441"},
	{latitude: 41.662927, longitude: -75.102094, zipCode: "18443"},
	{latitude: 41.340521, longitude: -75.551731, zipCode: "18444"},
	{latitude: 41.297908, longitude: -75.359362, zipCode: "18445"},
	{latitude: 41.656175, longitude: -75.759143, zipCode: "18446"},
	{latitude: 41.510737, longitude: -75.62048, zipCode: "18447"},
	{latitude: 41.396999, longitude: -75.217929, zipCode: "18451"},
	{latitude: 41.483875, longitude: -75.589585, zipCode: "18452"},
	{latitude: 41.745101, longitude: -75.367184, zipCode: "18453"},
	{latitude: 41.821496, longitude: -75.432908, zipCode: "18454"},
	{latitude: 41.893001, longitude: -75.359193, zipCode: "18455"},
	{latitude: 41.620762, longitude: -75.336858, zipCode: "18456"},
	{latitude: 41.472094, longitude: -75.049165, zipCode: "18457"},
	{latitude: 41.395767, longitude: -74.919277, zipCode: "18458"},
	{latitude: 41.268824, longitude: -75.344812, zipCode: "18460"},
	{latitude: 41.929003, longitude: -75.327674, zipCode: "18461"},
	{latitude: 41.899549, longitude: -75.43553, zipCode: "18462"},
	{latitude: 41.346946, longitude: -75.380412, zipCode: "18463"},
	{latitude: 41.40379, longitude: -75.177744, zipCode: "18464"},
	{latitude: 41.831299, longitude: -75.516849, zipCode: "18465"},
	{latitude: 41.195722, longitude: -75.376895, zipCode: "18466"},
	{latitude: 41.702468, longitude: -75.151986, zipCode: "18469"},
	{latitude: 41.735054, longitude: -75.52909, zipCode: "18470"},
	{latitude: 41.529124, longitude: -75.697314, zipCode: "18471"},
	{latitude: 41.588123, longitude: -75.399611, zipCode: "18472"},
	{latitude: 41.530249, longitude: -75.206824, zipCode: "18473"},
	{latitude: 41.41008, longitude: -75.667703, zipCode: "18503"},
	{latitude: 41.42347, longitude: -75.705416, zipCode: "18504"},
	{latitude: 41.387986, longitude: -75.648355, zipCode: "18505"},
	{latitude: 41.359075, longitude: -75.681879, zipCode: "18507"},
	{latitude: 41.455072, longitude: -75.658252, zipCode: "18508"},
	{latitude: 41.430641, longitude: -75.643228, zipCode: "18509"},
	{latitude: 41.407325, longitude: -75.643713, zipCode: "18510"},
	{latitude: 41.426455, longitude: -75.59293, zipCode: "18512"},
	{latitude: 41.395238, longitude: -75.714626, zipCode: "18517"},
	{latitude: 41.374961, longitude: -75.739617, zipCode: "18518"},
	{latitude: 41.462045, longitude: -75.630622, zipCode: "18519"},
	{latitude: 41.186018, longitude: -75.753988, zipCode: "18602"},
	{latitude: 41.082935, longitude: -76.247853, zipCode: "18603"},
	{latitude: 41.058966, longitude: -75.533827, zipCode: "18610"},
	{latitude: 41.349715, longitude: -75.980488, zipCode: "18612"},
	{latitude: 41.483404, longitude: -76.356396, zipCode: "18614"},
	{latitude: 41.457799, longitude: -75.858674, zipCode: "18615"},
	{latitude: 41.519364, longitude: -76.640201, zipCode: "18616"},
	{latitude: 41.17054, longitude: -76.077533, zipCode: "18617"},
	{latitude: 41.380285, longitude: -76.038202, zipCode: "18618"},
	{latitude: 41.431411, longitude: -76.713084, zipCode: "18619"},
	{latitude: 41.255347, longitude: -76.091065, zipCode: "18621"},
	{latitude: 41.201232, longitude: -76.273515, zipCode: "18622"},
	{latitude: 41.664801, longitude: -76.151984, zipCode: "18623"},
	{latitude: 41.060706, longitude: -75.64656, zipCode: "18624"},
	{latitude: 41.515032, longitude: -75.850156, zipCode: "18625"},
	{latitude: 41.413567, longitude: -76.454466, zipCode: "18626"},
	{latitude: 41.315591, longitude: -76.022356, zipCode: "18627"},
	{latitude: 41.430143, longitude: -76.313808, zipCode: "18628"},
	{latitude: 41.517604, longitude: -76.141339, zipCode: "18629"},
	{latitude: 41.662144, longitude: -76.023636, zipCode: "18630"},
	{latitude: 41.030711, longitude: -76.302779, zipCode: "18631"},
	{latitude: 41.45936, longitude: -76.37747, zipCode: "18632"},
	{latitude: 41.185511, longitude: -76.022618, zipCode: "18634"},
	{latitude: 41.02924, longitude: -76.201347, zipCode: "18635"},
	{latitude: 41.399204, longitude: -76.081856, zipCode: "18636"},
	{latitude: 41.297358, longitude: -75.740248, zipCode: "18640"},
	{latitude: 41.336398, longitude: -75.728394, zipCode: "18641"},
	{latitude: 41.356348, longitude: -75.773181, zipCode: "18642"},
	{latitude: 41.361912, longitude: -75.838549, zipCode: "18643"},
	{latitude: 41.327989, longitude: -75.881964, zipCode: "18644"},
	{latitude: 41.254051, longitude: -75.959827, zipCode: "18651"},
	{latitude: 41.187724, longitude: -76.203214, zipCode: "18655"},
	{latitude: 41.332543, longitude: -76.185438, zipCode: "18656"},
	{latitude: 41.52251, longitude: -75.959485, zipCode: "18657"},
	{latitude: 41.098662, longitude: -76.062912, zipCode: "18660"},
	{latitude: 41.076392, longitude: -75.740795, zipCode: "18661"},
	{latitude: 41.243237, longitude: -75.885302, zipCode: "18701"},
	{latitude: 41.230478, longitude: -75.757439, zipCode: "18702"},
	{latitude: 41.276189, longitude: -75.895218, zipCode: "18704"},
	{latitude: 41.271848, longitude: -75.842772, zipCode: "18705"},
	{latitude: 41.203289, longitude: -75.914803, zipCode: "18706"},
	{latitude: 41.133883, longitude: -75.918347, zipCode: "18707"},
	{latitude: 41.295848, longitude: -75.963463, zipCode: "18708"},
	{latitude: 41.286326, longitude: -75.89673, zipCode: "18709"},
	{latitude: 41.826389, longitude: -75.939711, zipCode: "18801"},
	{latitude: 41.931063, longitude: -76.501007, zipCode: "18810"},
	{latitude: 41.973202, longitude: -75.964751, zipCode: "18812"},
	{latitude: 41.783946, longitude: -76.600757, zipCode: "18814"},
	{latitude: 41.739764, longitude: -75.909739, zipCode: "18816"},
	{latitude: 41.87785, longitude: -76.62082, zipCode: "18817"},
	{latitude: 41.914932, longitude: -76.050887, zipCode: "18818"},
	{latitude: 41.777665, longitude: -75.650371, zipCode: "18820"},
	{latitude: 41.98982, longitude: -75.722697, zipCode: "18821"},
	{latitude: 41.940124, longitude: -75.794962, zipCode: "18822"},
	{latitude: 41.773948, longitude: -75.691554, zipCode: "18823"},
	{latitude: 41.697366, longitude: -75.787183, zipCode: "18824"},
	{latitude: 41.825618, longitude: -75.607459, zipCode: "18825"},
	{latitude: 41.748621, longitude: -75.727481, zipCode: "18826"},
	{latitude: 41.797443, longitude: -76.110303, zipCode: "18828"},
	{latitude: 41.837644, longitude: -76.173693, zipCode: "18829"},
	{latitude: 41.959087, longitude: -76.114452, zipCode: "18830"},
	{latitude: 41.894223, longitude: -76.598118, zipCode: "18831"},
	{latitude: 41.678216, longitude: -76.568867, zipCode: "18832"},
	{latitude: 41.59067, longitude: -76.46939, zipCode: "18833"},
	{latitude: 41.830967, longitude: -75.720934, zipCode: "18834"},
	{latitude: 41.915072, longitude: -76.30286, zipCode: "18837"},
	{latitude: 41.973067, longitude: -76.530371, zipCode: "18840"},
	{latitude: 41.768961, longitude: -75.612955, zipCode: "18842"},
	{latitude: 41.804449, longitude: -75.89414, zipCode: "18843"},
	{latitude: 41.69097, longitude: -75.905239, zipCode: "18844"},
	{latitude: 41.787336, longitude: -76.18212, zipCode: "18845"},
	{latitude: 41.621411, longitude: -76.229986, zipCode: "18846"},
	{latitude: 41.928841, longitude: -75.579051, zipCode: "18847"},
	{latitude: 41.753437, longitude: -76.457497, zipCode: "18848"},
	{latitude: 41.871983, longitude: -76.472515, zipCode: "18850"},
	{latitude: 41.936675, longitude: -76.179694, zipCode: "18851"},
	{latitude: 41.721414, longitude: -76.276925, zipCode: "18853"},
	{latitude: 41.790666, longitude: -76.355714, zipCode: "18854"},
	{latitude: 40.304772, longitude: -75.150789, zipCode: "18901"},
	{latitude: 40.353296, longitude: -75.097808, zipCode: "18902"},
	{latitude: 40.315655, longitude: -75.063721, zipCode: "18912"},
	{latitude: 40.372212, longitude: -75.056125, zipCode: "18913"},
	{latitude: 40.289488, longitude: -75.2107, zipCode: "18914"},
	{latitude: 40.272505, longitude: -75.256209, zipCode: "18915"},
	{latitude: 40.369133, longitude: -75.200018, zipCode: "18917"},
	{latitude: 40.493003, longitude: -75.086697, zipCode: "18920"},
	{latitude: 40.529626, longitude: -75.171066, zipCode: "18921"},
	{latitude: 40.353617, longitude: -75.166702, zipCode: "18923"},
	{latitude: 40.283671, longitude: -75.062906, zipCode: "18925"},
	{latitude: 40.324302, longitude: -75.240948, zipCode: "18927"},
	{latitude: 40.258108, longitude: -75.079119, zipCode: "18929"},
	{latitude: 40.532189, longitude: -75.212624, zipCode: "18930"},
	{latitude: 40.291585, longitude: -75.250486, zipCode: "18932"},
	{latitude: 40.402463, longitude: -75.039241, zipCode: "18933"},
	{latitude: 40.432826, longitude: -75.394995, zipCode: "18935"},
	{latitude: 40.226493, longitude: -75.228091, zipCode: "18936"},
	{latitude: 40.351048, longitude: -74.998049, zipCode: "18938"},
	{latitude: 40.260594, longitude: -74.93707, zipCode: "18940"},
	{latitude: 40.474101, longitude: -75.166632, zipCode: "18942"},
	{latitude: 40.396234, longitude: -75.231167, zipCode: "18944"},
	{latitude: 40.286153, longitude: -75.005153, zipCode: "18946"},
	{latitude: 40.422899, longitude: -75.117839, zipCode: "18947"},
	{latitude: 40.434635, longitude: -75.066443, zipCode: "18950"},
	{latitude: 40.459182, longitude: -75.347403, zipCode: "18951"},
	{latitude: 40.225413, longitude: -74.992957, zipCode: "18954"},
	{latitude: 40.482445, longitude: -75.311319, zipCode: "18955"},
	{latitude: 40.366295, longitude: -75.325895, zipCode: "18960"},
	{latitude: 40.344604, longitude: -75.270454, zipCode: "18962"},
	{latitude: 40.30347, longitude: -75.337889, zipCode: "18964"},
	{latitude: 40.188468, longitude: -75.009898, zipCode: "18966"},
	{latitude: 40.326304, longitude: -75.368422, zipCode: "18969"},
	{latitude: 40.412019, longitude: -75.381111, zipCode: "18970"},
	{latitude: 40.52732, longitude: -75.124666, zipCode: "18972"},
	{latitude: 40.217062, longitude: -75.072803, zipCode: "18974"},
	{latitude: 40.248821, longitude: -75.143522, zipCode: "18976"},
	{latitude: 40.284173, longitude: -74.87417, zipCode: "18977"},
	{latitude: 40.27276, longitude: -75.010943, zipCode: "18980"},
	{latitude: 40.12623, longitude: -75.126511, zipCode: "19001"},
	{latitude: 40.187753, longitude: -75.215847, zipCode: "19002"},
	{latitude: 40.002113, longitude: -75.298395, zipCode: "19003"},
	{latitude: 40.009753, longitude: -75.231508, zipCode: "19004"},
	{latitude: 40.128573, longitude: -75.059719, zipCode: "19006"},
	{latitude: 40.116388, longitude: -74.857078, zipCode: "19007"},
	{latitude: 39.974219, longitude: -75.361244, zipCode: "19008"},
	{latitude: 40.137798, longitude: -75.063121, zipCode: "19009"},
	{latitude: 40.023764, longitude: -75.330471, zipCode: "19010"},
	{latitude: 40.059691, longitude: -75.105874, zipCode: "19012"},
	{latitude: 39.845414, longitude: -75.374817, zipCode: "19013"},
	{latitude: 39.864907, longitude: -75.432958, zipCode: "19014"},
	{latitude: 39.86653, longitude: -75.391496, zipCode: "19015"},
	{latitude: 39.890842, longitude: -75.462953, zipCode: "19017"},
	{latitude: 39.923146, longitude: -75.298322, zipCode: "19018"},
	{latitude: 40.105952, longitude: -74.936276, zipCode: "19020"},
	{latitude: 40.088944, longitude: -74.894079, zipCode: "19021"},
	{latitude: 39.857445, longitude: -75.336965, zipCode: "19022"},
	{latitude: 39.91724, longitude: -75.267087, zipCode: "19023"},
	{latitude: 40.145584, longitude: -75.163224, zipCode: "19025"},
	{latitude: 39.950354, longitude: -75.304047, zipCode: "19026"},
	{latitude: 40.073118, longitude: -75.124431, zipCode: "19027"},
	{latitude: 39.862699, longitude: -75.292734, zipCode: "19029"},
	{latitude: 40.182098, longitude: -74.8346, zipCode: "19030"},
	{latitude: 40.10825, longitude: -75.217468, zipCode: "19031"},
	{latitude: 39.888246, longitude: -75.275774, zipCode: "19032"},
	{latitude: 39.890804, longitude: -75.32885, zipCode: "19033"},
	{latitude: 40.133515, longitude: -75.209509, zipCode: "19034"},
	{latitude: 40.051349, longitude: -75.277642, zipCode: "19035"},
	{latitude: 39.903712, longitude: -75.293236, zipCode: "19036"},
	{latitude: 40.10521, longitude: -75.169812, zipCode: "19038"},
	{latitude: 40.177294, longitude: -75.10619, zipCode: "19040"},
	{latitude: 40.004484, longitude: -75.320002, zipCode: "19041"},
	{latitude: 39.900483, longitude: -75.308894, zipCode: "19043"},
	{latitude: 40.188988, longitude: -75.152483, zipCode: "19044"},
	{latitude: 40.101341, longitude: -75.105577, zipCode: "19046"},
	{latitude: 40.180448, longitude: -74.912451, zipCode: "19047"},
	{latitude: 39.937646, longitude: -75.263526, zipCode: "19050"},
	{latitude: 39.897179, longitude: -75.445336, zipCode: "19052"},
	{latitude: 40.153918, longitude: -74.974991, zipCode: "19053"},
	{latitude: 40.170692, longitude: -74.816584, zipCode: "19054"},
	{latitude: 40.150307, longitude: -74.839218, zipCode: "19055"},
	{latitude: 40.149508, longitude: -74.884744, zipCode: "19056"},
	{latitude: 40.14089, longitude: -74.858362, zipCode: "19057"},
	{latitude: 39.850699, longitude: -75.491965, zipCode: "19060"},
	{latitude: 39.827818, longitude: -75.431926, zipCode: "19061"},
	{latitude: 39.922229, longitude: -75.414058, zipCode: "19063"},
	{latitude: 39.932078, longitude: -75.338962, zipCode: "19064"},
	{latitude: 40.002176, longitude: -75.248899, zipCode: "19066"},
	{latitude: 40.229997, longitude: -74.85935, zipCode: "19067"},
	{latitude: 39.906316, longitude: -75.324539, zipCode: "19070"},
	{latitude: 40.023891, longitude: -75.257741, zipCode: "19072"},
	{latitude: 39.983253, longitude: -75.429218, zipCode: "19073"},
	{latitude: 39.88599, longitude: -75.295756, zipCode: "19074"},
	{latitude: 40.112298, longitude: -75.186672, zipCode: "19075"},
	{latitude: 39.886097, longitude: -75.30737, zipCode: "19076"},
	{latitude: 39.873641, longitude: -75.321331, zipCode: "19078"},
	{latitude: 39.902263, longitude: -75.265718, zipCode: "19079"},
	{latitude: 39.898604, longitude: -75.34751, zipCode: "19081"},
	{latitude: 39.960428, longitude: -75.27032, zipCode: "19082"},
	{latitude: 39.977153, longitude: -75.311947, zipCode: "19083"},
	{latitude: 40.036973, longitude: -75.349913, zipCode: "19085"},
	{latitude: 39.890943, longitude: -75.37016, zipCode: "19086"},
	{latitude: 40.062099, longitude: -75.400394, zipCode: "19087"},
	{latitude: 40.148072, longitude: -75.124082, zipCode: "19090"},
	{latitude: 39.875033, longitude: -75.346694, zipCode: "19094"},
	{latitude: 40.085875, longitude: -75.151366, zipCode: "19095"},
	{latitude: 39.998147, longitude: -75.273908, zipCode: "19096"},
	{latitude: 39.952983, longitude: -75.165474, zipCode: "19102"},
	{latitude: 39.953372, longitude: -75.174187, zipCode: "19103"},
	{latitude: 39.958518, longitude: -75.198856, zipCode: "19104"},
	{latitude: 39.946649, longitude: -75.142232, zipCode: "19106"},
	{latitude: 39.951764, longitude: -75.158667, zipCode: "19107"},
	{latitude: 39.959865, longitude: -75.160829, zipCode: "19108"},
	{latitude: 39.949652, longitude: -75.163654, zipCode: "19109"},
	{latitude: 40.060612, longitude: -75.080176, zipCode: "19111"},
	{latitude: 39.889857, longitude: -75.16939, zipCode: "19112"},
	{latitude: 39.871363, longitude: -75.272947, zipCode: "19113"},
	{latitude: 40.069399, longitude: -75.000162, zipCode: "19114"},
	{latitude: 40.090698, longitude: -75.042868, zipCode: "19115"},
	{latitude: 40.117418, longitude: -75.015101, zipCode: "19116"},
	{latitude: 40.073872, longitude: -75.211217, zipCode: "19118"},
	{latitude: 40.052811, longitude: -75.191167, zipCode: "19119"},
	{latitude: 40.034147, longitude: -75.119198, zipCode: "19120"},
	{latitude: 39.982071, longitude: -75.179825, zipCode: "19121"},
	{latitude: 39.97771, longitude: -75.145722, zipCode: "19122"},
	{latitude: 39.963734, longitude: -75.145482, zipCode: "19123"},
	{latitude: 40.017257, longitude: -75.095261, zipCode: "19124"},
	{latitude: 39.976249, longitude: -75.125105, zipCode: "19125"},
	{latitude: 40.056119, longitude: -75.136564, zipCode: "19126"},
	{latitude: 40.029238, longitude: -75.226914, zipCode: "19127"},
	{latitude: 40.049525, longitude: -75.230253, zipCode: "19128"},
	{latitude: 40.013014, longitude: -75.185402, zipCode: "19129"},
	{latitude: 39.967502, longitude: -75.174671, zipCode: "19130"},
	{latitude: 39.986937, longitude: -75.219246, zipCode: "19131"},
	{latitude: 39.996864, longitude: -75.170871, zipCode: "19132"},
	{latitude: 39.993007, longitude: -75.141016, zipCode: "19133"},
	{latitude: 39.989223, longitude: -75.108756, zipCode: "19134"},
	{latitude: 40.021728, longitude: -75.050671, zipCode: "19135"},
	{latitude: 40.039406, longitude: -75.018555, zipCode: "19136"},
	{latitude: 39.993, longitude: -75.072052, zipCode: "19137"},
	{latitude: 40.055957, longitude: -75.159485, zipCode: "19138"},
	{latitude: 39.961575, longitude: -75.231146, zipCode: "19139"},
	{latitude: 40.012279, longitude: -75.145084, zipCode: "19140"},
	{latitude: 40.037921, longitude: -75.145377, zipCode: "19141"},
	{latitude: 39.921752, longitude: -75.233279, zipCode: "19142"},
	{latitude: 39.943806, longitude: -75.226551, zipCode: "19143"},
	{latitude: 40.031929, longitude: -75.176099, zipCode: "19144"},
	{latitude: 39.914501, longitude: -75.191217, zipCode: "19145"},
	{latitude: 39.939496, longitude: -75.184146, zipCode: "19146"},
	{latitude: 39.936404, longitude: -75.154729, zipCode: "19147"},
	{latitude: 39.911612, longitude: -75.151475, zipCode: "19148"},
	{latitude: 40.03783, longitude: -75.065542, zipCode: "19149"},
	{latitude: 40.072512, longitude: -75.171694, zipCode: "19150"},
	{latitude: 39.979613, longitude: -75.257043, zipCode: "19151"},
	{latitude: 40.061595, longitude: -75.046385, zipCode: "19152"},
	{latitude: 39.879986, longitude: -75.237908, zipCode: "19153"},
	{latitude: 40.096483, longitude: -74.983217, zipCode: "19154"},
	{latitude: 40.040279, longitude: -75.480341, zipCode: "19301"},
	{latitude: 39.935094, longitude: -75.972833, zipCode: "19310"},
	{latitude: 39.821095, longitude: -75.771347, zipCode: "19311"},
	{latitude: 40.032159, longitude: -75.451354, zipCode: "19312"},
	{latitude: 40.054083, longitude: -75.833836, zipCode: "19316"},
	{latitude: 39.862461, longitude: -75.602787, zipCode: "19317"},
	{latitude: 39.926816, longitude: -75.524481, zipCode: "19319"},
	{latitude: 39.962991, longitude: -75.832026, zipCode: "19320"},
	{latitude: 39.868292, longitude: -75.913785, zipCode: "19330"},
	{latitude: 40.041872, longitude: -75.423597, zipCode: "19333"},
	{latitude: 40.022618, longitude: -75.721218, zipCode: "19335"},
	{latitude: 40.039967, longitude: -75.640752, zipCode: "19341"},
	{latitude: 39.907638, longitude: -75.500025, zipCode: "19342"},
	{latitude: 40.100772, longitude: -75.754945, zipCode: "19343"},
	{latitude: 40.083577, longitude: -75.881364, zipCode: "19344"},
	{latitude: 40.029699, longitude: -75.569519, zipCode: "19345"},
	{latitude: 39.866024, longitude: -75.720243, zipCode: "19348"},
	{latitude: 39.760276, longitude: -75.796353, zipCode: "19350"},
	{latitude: 39.783773, longitude: -75.888327, zipCode: "19352"},
	{latitude: 40.045497, longitude: -75.527903, zipCode: "19355"},
	{latitude: 39.964404, longitude: -75.807075, zipCode: "19358"},
	{latitude: 39.750743, longitude: -76.070383, zipCode: "19362"},
	{latitude: 39.787821, longitude: -75.968115, zipCode: "19363"},
	{latitude: 39.966371, longitude: -75.925129, zipCode: "19365"},
	{latitude: 39.962894, longitude: -75.883869, zipCode: "19367"},
	{latitude: 39.98069, longitude: -75.894734, zipCode: "19369"},
	{latitude: 39.999056, longitude: -75.757445, zipCode: "19372"},
	{latitude: 39.901103, longitude: -75.533499, zipCode: "19373"},
	{latitude: 39.829318, longitude: -75.7512, zipCode: "19374"},
	{latitude: 39.900053, longitude: -75.73698, zipCode: "19375"},
	{latitude: 39.987218, longitude: -75.603138, zipCode: "19380"},
	{latitude: 39.927665, longitude: -75.613166, zipCode: "19382"},
	{latitude: 39.936617, longitude: -75.606001, zipCode: "19383"},
	{latitude: 39.836097, longitude: -75.842359, zipCode: "19390"},
	{latitude: 40.13018, longitude: -75.331629, zipCode: "19401"},
	{latitude: 40.148523, longitude: -75.37908, zipCode: "19403"},
	{latitude: 40.099644, longitude: -75.334292, zipCode: "19405"},
	{latitude: 40.094879, longitude: -75.385685, zipCode: "19406"},
	{latitude: 40.15681, longitude: -75.279393, zipCode: "19422"},
	{latitude: 40.104954, longitude: -75.643665, zipCode: "19425"},
	{latitude: 40.189235, longitude: -75.438102, zipCode: "19426"},
	{latitude: 40.080216, longitude: -75.302504, zipCode: "19428"},
	{latitude: 40.327903, longitude: -75.565205, zipCode: "19435"},
	{latitude: 40.202502, longitude: -75.248805, zipCode: "19436"},
	{latitude: 40.180101, longitude: -75.260459, zipCode: "19437"},
	{latitude: 40.269439, longitude: -75.390006, zipCode: "19438"},
	{latitude: 40.285137, longitude: -75.290907, zipCode: "19440"},
	{latitude: 40.1327, longitude: -75.578788, zipCode: "19442"},
	{latitude: 40.086857, longitude: -75.254274, zipCode: "19444"},
	{latitude: 40.23068, longitude: -75.303647, zipCode: "19446"},
	{latitude: 40.138397, longitude: -75.502225, zipCode: "19453"},
	{latitude: 40.226113, longitude: -75.243786, zipCode: "19454"},
	{latitude: 40.136643, longitude: -75.459637, zipCode: "19456"},
	{latitude: 40.206881, longitude: -75.585751, zipCode: "19457"},
	{latitude: 40.126329, longitude: -75.529946, zipCode: "19460"},
	{latitude: 40.115345, longitude: -75.28199, zipCode: "19462"},
	{latitude: 40.258928, longitude: -75.615861, zipCode: "19464"},
	{latitude: 40.190386, longitude: -75.68457, zipCode: "19465"},
	{latitude: 40.207638, longitude: -75.532067, zipCode: "19468"},
	{latitude: 40.340446, longitude: -75.581887, zipCode: "19472"},
	{latitude: 40.256168, longitude: -75.482534, zipCode: "19473"},
	{latitude: 40.224264, longitude: -75.404995, zipCode: "19474"},
	{latitude: 40.171654, longitude: -75.598754, zipCode: "19475"},
	{latitude: 40.184453, longitude: -75.235796, zipCode: "19477"},
	{latitude: 40.196723, longitude: -75.354004, zipCode: "19490"},
	{latitude: 40.282294, longitude: -75.493445, zipCode: "19492"},
	{latitude: 40.244477, longitude: -76.064701, zipCode: "19501"},
	{latitude: 40.408009, longitude: -75.571478, zipCode: "19503"},
	{latitude: 40.416215, longitude: -75.588554, zipCode: "19504"},
	{latitude: 40.379095, longitude: -75.62242, zipCode: "19505"},
	{latitude: 40.454218, longitude: -76.12869, zipCode: "19506"},
	{latitude: 40.505167, longitude: -76.270312, zipCode: "19507"},
	{latitude: 40.260389, longitude: -75.832122, zipCode: "19508"},
	{latitude: 40.445918, longitude: -75.882015, zipCode: "19510"},
	{latitude: 40.485917, longitude: -75.74323, zipCode: "19511"},
	{latitude: 40.350632, longitude: -75.679126, zipCode: "19512"},
	{latitude: 40.486855, longitude: -76.005264, zipCode: "19516"},
	{latitude: 40.271635, longitude: -75.750052, zipCode: "19518"},
	{latitude: 40.157484, longitude: -75.80073, zipCode: "19520"},
	{latitude: 40.449419, longitude: -75.816732, zipCode: "19522"},
	{latitude: 40.200867, longitude: -75.850772, zipCode: "19523"},
	{latitude: 40.308839, longitude: -75.586107, zipCode: "19525"},
	{latitude: 40.547096, longitude: -75.996638, zipCode: "19526"},
	{latitude: 40.64112, longitude: -75.858751, zipCode: "19529"},
	{latitude: 40.539073, longitude: -75.781444, zipCode: "19530"},
	{latitude: 40.425173, longitude: -75.992673, zipCode: "19533"},
	{latitude: 40.582788, longitude: -75.879334, zipCode: "19534"},
	{latitude: 40.339729, longitude: -75.794304, zipCode: "19535"},
	{latitude: 40.480578, longitude: -75.758948, zipCode: "19536"},
	{latitude: 40.547577, longitude: -75.702623, zipCode: "19538"},
	{latitude: 40.496313, longitude: -75.683558, zipCode: "19539"},
	{latitude: 40.236277, longitude: -75.96661, zipCode: "19540"},
	{latitude: 40.478321, longitude: -76.017793, zipCode: "19541"},
	{latitude: 40.175621, longitude: -75.894981, zipCode: "19543"},
	{latitude: 40.416876, longitude: -76.296566, zipCode: "19544"},
	{latitude: 40.34051, longitude: -75.630476, zipCode: "19545"},
	{latitude: 40.380716, longitude: -75.772948, zipCode: "19547"},
	{latitude: 40.583253, longitude: -76.030814, zipCode: "19549"},
	{latitude: 40.454939, longitude: -76.249432, zipCode: "19550"},
	{latitude: 40.361782, longitude: -76.137324, zipCode: "19551"},
	{latitude: 40.512879, longitude: -76.103733, zipCode: "19554"},
	{latitude: 40.494381, longitude: -75.953921, zipCode: "19555"},
	{latitude: 40.493171, longitude: -76.185127, zipCode: "19559"},
	{latitude: 40.403867, longitude: -75.880401, zipCode: "19560"},
	{latitude: 40.502989, longitude: -75.701767, zipCode: "19562"},
	{latitude: 40.518015, longitude: -75.872059, zipCode: "19564"},
	{latitude: 40.342507, longitude: -76.088241, zipCode: "19565"},
	{latitude: 40.39907, longitude: -76.209365, zipCode: "19567"},
	{latitude: 40.360982, longitude: -75.936935, zipCode: "19601"},
	{latitude: 40.327563, longitude: -75.916724, zipCode: "19602"},
	{latitude: 40.358299, longitude: -75.90947, zipCode: "19604"},
	{latitude: 40.39857, longitude: -75.946123, zipCode: "19605"},
	{latitude: 40.338916, longitude: -75.857079, zipCode: "19606"},
	{latitude: 40.291483, longitude: -75.944791, zipCode: "19607"},
	{latitude: 40.314491, longitude: -76.035213, zipCode: "19608"},
	{latitude: 40.327682, longitude: -75.996519, zipCode: "19609"},
	{latitude: 40.341535, longitude: -75.974921, zipCode: "19610"},
	{latitude: 40.324674, longitude: -75.942857, zipCode: "19611"},
	{latitude: 39.583895, longitude: -75.696995, zipCode: "19701"},
	{latitude: 39.6182, longitude: -75.729724, zipCode: "19702"},
	{latitude: 39.801514, longitude: -75.453853, zipCode: "19703"},
	{latitude: 39.574954, longitude: -75.590676, zipCode: "19706"},
	{latitude: 39.785594, longitude: -75.68364, zipCode: "19707"},
	{latitude: 39.494369, longitude: -75.67747, zipCode: "19709"},
	{latitude: 39.789573, longitude: -75.584684, zipCode: "19710"},
	{latitude: 39.713781, longitude: -75.740826, zipCode: "19711"},
	{latitude: 39.670751, longitude: -75.710123, zipCode: "19713"},
	{latitude: 39.667959, longitude: -75.746184, zipCode: "19716"},
	{latitude: 39.678689, longitude: -75.749962, zipCode: "19717"},
	{latitude: 39.645467, longitude: -75.604496, zipCode: "19720"},
	{latitude: 39.454954, longitude: -75.661037, zipCode: "19730"},
	{latitude: 39.509434, longitude: -75.601291, zipCode: "19731"},
	{latitude: 39.800296, longitude: -75.574599, zipCode: "19732"},
	{latitude: 39.559085, longitude: -75.651523, zipCode: "19733"},
	{latitude: 39.381644, longitude: -75.653322, zipCode: "19734"},
	{latitude: 39.806643, longitude: -75.597331, zipCode: "19735"},
	{latitude: 39.780331, longitude: -75.642813, zipCode: "19736"},
	{latitude: 39.723588, longitude: -75.541208, zipCode: "19801"},
	{latitude: 39.755042, longitude: -75.526462, zipCode: "19802"},
	{latitude: 39.799872, longitude: -75.542232, zipCode: "19803"},
	{latitude: 39.71876, longitude: -75.621866, zipCode: "19804"},
	{latitude: 39.74521, longitude: -75.593712, zipCode: "19805"},
	{latitude: 39.762542, longitude: -75.563298, zipCode: "19806"},
	{latitude: 39.7965, longitude: -75.61284, zipCode: "19807"},
	{latitude: 39.737615, longitude: -75.667057, zipCode: "19808"},
	{latitude: 39.756674, longitude: -75.501035, zipCode: "19809"},
	{latitude: 39.818682, longitude: -75.506183, zipCode: "19810"},
	{latitude: 39.181542, longitude: -75.47352, zipCode: "19901"},
	{latitude: 39.127656, longitude: -75.466911, zipCode: "19902"},
	{latitude: 39.171279, longitude: -75.587619, zipCode: "19904"},
	{latitude: 38.552863, longitude: -75.069397, zipCode: "19930"},
	{latitude: 38.569298, longitude: -75.610451, zipCode: "19931"},
	{latitude: 38.733018, longitude: -75.603361, zipCode: "19933"},
	{latitude: 39.084727, longitude: -75.627355, zipCode: "19934"},
	{latitude: 39.218635, longitude: -75.585114, zipCode: "19936"},
	{latitude: 39.256465, longitude: -75.698822, zipCode: "19938"},
	{latitude: 38.568957, longitude: -75.207538, zipCode: "19939"},
	{latitude: 38.477562, longitude: -75.564931, zipCode: "19940"},
	{latitude: 38.793658, longitude: -75.426062, zipCode: "19941"},
	{latitude: 39.004436, longitude: -75.606986, zipCode: "19943"},
	{latitude: 38.477868, longitude: -75.053083, zipCode: "19944"},
	{latitude: 38.497894, longitude: -75.229033, zipCode: "19945"},
	{latitude: 39.034802, longitude: -75.449585, zipCode: "19946"},
	{latitude: 38.670266, longitude: -75.404663, zipCode: "19947"},
	{latitude: 38.818451, longitude: -75.596743, zipCode: "19950"},
	{latitude: 38.685257, longitude: -75.255153, zipCode: "19951"},
	{latitude: 38.916699, longitude: -75.612104, zipCode: "19952"},
	{latitude: 39.152487, longitude: -75.694133, zipCode: "19953"},
	{latitude: 38.89701, longitude: -75.520457, zipCode: "19954"},
	{latitude: 39.226969, longitude: -75.664724, zipCode: "19955"},
	{latitude: 38.547645, longitude: -75.538186, zipCode: "19956"},
	{latitude: 38.728714, longitude: -75.168507, zipCode: "19958"},
	{latitude: 38.850913, longitude: -75.408716, zipCode: "19960"},
	{latitude: 39.068808, longitude: -75.488388, zipCode: "19962"},
	{latitude: 38.943253, longitude: -75.362567, zipCode: "19963"},
	{latitude: 39.096027, longitude: -75.725463, zipCode: "19964"},
	{latitude: 38.579422, longitude: -75.277196, zipCode: "19966"},
	{latitude: 38.539706, longitude: -75.120863, zipCode: "19967"},
	{latitude: 38.78292, longitude: -75.279802, zipCode: "19968"},
	{latitude: 38.554973, longitude: -75.099405, zipCode: "19970"},
	{latitude: 38.694504, longitude: -75.0997, zipCode: "19971"},
	{latitude: 38.634462, longitude: -75.623572, zipCode: "19973"},
	{latitude: 38.468327, longitude: -75.16688, zipCode: "19975"},
	{latitude: 39.298946, longitude: -75.546122, zipCode: "19977"},
	{latitude: 39.050367, longitude: -75.576462, zipCode: "19979"},
	{latitude: 39.073522, longitude: -75.570169, zipCode: "19980"},
	{latitude: 38.910353, longitude: -77.017739, zipCode: "20001"},
	{latitude: 38.905026, longitude: -76.983607, zipCode: "20002"},
	{latitude: 38.88184, longitude: -76.99094, zipCode: "20003"},
	{latitude: 38.895093, longitude: -77.026584, zipCode: "20004"},
	{latitude: 38.904438, longitude: -77.031597, zipCode: "20005"},
	{latitude: 38.898482, longitude: -77.041205, zipCode: "20006"},
	{latitude: 38.917716, longitude: -77.090635, zipCode: "20007"},
	{latitude: 38.934815, longitude: -77.05894, zipCode: "20008"},
	{latitude: 38.919144, longitude: -77.037381, zipCode: "20009"},
	{latitude: 38.932825, longitude: -77.031798, zipCode: "20010"},
	{latitude: 38.952514, longitude: -77.022991, zipCode: "20011"},
	{latitude: 38.979021, longitude: -77.030801, zipCode: "20012"},
	{latitude: 38.966889, longitude: -77.058323, zipCode: "20015"},
	{latitude: 38.936909, longitude: -77.090037, zipCode: "20016"},
	{latitude: 38.938154, longitude: -76.992909, zipCode: "20017"},
	{latitude: 38.926576, longitude: -76.974446, zipCode: "20018"},
	{latitude: 38.892246, longitude: -76.941772, zipCode: "20019"},
	{latitude: 38.860382, longitude: -76.978961, zipCode: "20020"},
	{latitude: 38.870543, longitude: -77.026826, zipCode: "20024"},
	{latitude: 38.831882, longitude: -77.016556, zipCode: "20032"},
	{latitude: 38.907034, longitude: -77.041677, zipCode: "20036"},
	{latitude: 38.892459, longitude: -77.053811, zipCode: "20037"},
	{latitude: 38.896731, longitude: -77.030798, zipCode: "20045"},
	{latitude: 38.89879, longitude: -77.047787, zipCode: "20052"},
	{latitude: 38.908971, longitude: -77.075171, zipCode: "20057"},
	{latitude: 38.92246, longitude: -77.020042, zipCode: "20059"},
	{latitude: 38.936371, longitude: -76.99921, zipCode: "20064"},
	{latitude: 38.953169, longitude: -77.603594, zipCode: "20105"},
	{latitude: 38.699346, longitude: -78.002209, zipCode: "20106"},
	{latitude: 38.793898, longitude: -77.530962, zipCode: "20109"},
	{latitude: 38.746071, longitude: -77.487673, zipCode: "20110"},
	{latitude: 38.748968, longitude: -77.427873, zipCode: "20111"},
	{latitude: 38.670807, longitude: -77.430106, zipCode: "20112"},
	{latitude: 38.816307, longitude: -77.88957, zipCode: "20115"},
	{latitude: 38.998822, longitude: -77.733937, zipCode: "20117"},
	{latitude: 38.62063, longitude: -77.625662, zipCode: "20119"},
	{latitude: 38.856974, longitude: -77.484219, zipCode: "20120"},
	{latitude: 38.811033, longitude: -77.462944, zipCode: "20121"},
	{latitude: 38.781332, longitude: -77.391333, zipCode: "20124"},
	{latitude: 39.163358, longitude: -77.593204, zipCode: "20129"},
	{latitude: 39.039676, longitude: -77.941872, zipCode: "20130"},
	{latitude: 39.165816, longitude: -77.726945, zipCode: "20132"},
	{latitude: 39.086313, longitude: -77.887226, zipCode: "20135"},
	{latitude: 38.729834, longitude: -77.544163, zipCode: "20136"},
	{latitude: 38.806579, longitude: -77.722437, zipCode: "20137"},
	{latitude: 39.121311, longitude: -77.790923, zipCode: "20141"},
	{latitude: 38.875302, longitude: -77.562249, zipCode: "20143"},
	{latitude: 38.920726, longitude: -77.935434, zipCode: "20144"},
	{latitude: 39.041947, longitude: -77.47813, zipCode: "20147"},
	{latitude: 38.993905, longitude: -77.532196, zipCode: "20148"},
	{latitude: 38.897187, longitude: -77.443874, zipCode: "20151"},
	{latitude: 38.899353, longitude: -77.518706, zipCode: "20152"},
	{latitude: 38.806662, longitude: -77.621357, zipCode: "20155"},
	{latitude: 39.139647, longitude: -77.658859, zipCode: "20158"},
	{latitude: 39.011103, longitude: -77.396849, zipCode: "20164"},
	{latitude: 39.057538, longitude: -77.392009, zipCode: "20165"},
	{latitude: 38.970032, longitude: -77.464002, zipCode: "20166"},
	{latitude: 38.872078, longitude: -77.641383, zipCode: "20169"},
	{latitude: 38.979842, longitude: -77.37975, zipCode: "20170"},
	{latitude: 38.926874, longitude: -77.396775, zipCode: "20171"},
	{latitude: 39.064398, longitude: -77.603768, zipCode: "20175"},
	{latitude: 39.180323, longitude: -77.533459, zipCode: "20176"},
	{latitude: 39.268591, longitude: -77.637636, zipCode: "20180"},
	{latitude: 38.66911, longitude: -77.549571, zipCode: "20181"},
	{latitude: 39.001419, longitude: -77.878577, zipCode: "20184"},
	{latitude: 38.687799, longitude: -77.839607, zipCode: "20186"},
	{latitude: 38.722131, longitude: -77.736919, zipCode: "20187"},
	{latitude: 38.959187, longitude: -77.34107, zipCode: "20190"},
	{latitude: 38.933315, longitude: -77.350118, zipCode: "20191"},
	{latitude: 38.981041, longitude: -77.340785, zipCode: "20194"},
	{latitude: 39.19521, longitude: -77.62801, zipCode: "20197"},
	{latitude: 38.884142, longitude: -77.744559, zipCode: "20198"},
	{latitude: 38.885559, longitude: -77.014429, zipCode: "20204"},
	{latitude: 38.897117, longitude: -77.034359, zipCode: "20220"},
	{latitude: 38.893744, longitude: -77.035673, zipCode: "20230"},
	{latitude: 38.894456, longitude: -77.042605, zipCode: "20240"},
	{latitude: 38.893583, longitude: -77.04504, zipCode: "20245"},
	{latitude: 38.885607, longitude: -77.032805, zipCode: "20250"},
	{latitude: 38.883544, longitude: -77.025032, zipCode: "20260"},
	{latitude: 38.934841, longitude: -77.014387, zipCode: "20317"},
	{latitude: 38.866947, longitude: -77.016965, zipCode: "20319"},
	{latitude: 38.854523, longitude: -77.013071, zipCode: "20373"},
	{latitude: 38.872429, longitude: -76.9939, zipCode: "20388"},
	{latitude: 38.879019, longitude: -76.993695, zipCode: "20390"},
	{latitude: 38.892845, longitude: -77.023011, zipCode: "20408"},
	{latitude: 38.894933, longitude: -77.044173, zipCode: "20415"},
	{latitude: 38.8928, longitude: -77.047764, zipCode: "20418"},
	{latitude: 38.927128, longitude: -77.010316, zipCode: "20422"},
	{latitude: 38.902057, longitude: -77.047558, zipCode: "20427"},
	{latitude: 38.89895, longitude: -77.044186, zipCode: "20431"},
	{latitude: 38.89278, longitude: -77.00689, zipCode: "20510"},
	{latitude: 38.886816, longitude: -77.009012, zipCode: "20515"},
	{latitude: 38.8947, longitude: -77.048481, zipCode: "20520"},
	{latitude: 38.893124, longitude: -77.025023, zipCode: "20530"},
	{latitude: 38.895136, longitude: -77.024933, zipCode: "20535"},
	{latitude: 38.887929, longitude: -77.004713, zipCode: "20540"},
	{latitude: 38.941356, longitude: -77.02801, zipCode: "20542"},
	{latitude: 38.892802, longitude: -77.0458, zipCode: "20551"},
	{latitude: 38.890126, longitude: -77.027245, zipCode: "20560"},
	{latitude: 38.89013, longitude: -77.019487, zipCode: "20565"},
	{latitude: 38.895752, longitude: -77.055634, zipCode: "20566"},
	{latitude: 38.886946, longitude: -77.022968, zipCode: "20591"},
	{latitude: 38.619558, longitude: -76.856042, zipCode: "20601"},
	{latitude: 38.583248, longitude: -76.894703, zipCode: "20602"},
	{latitude: 38.62913, longitude: -76.975586, zipCode: "20603"},
	{latitude: 38.258011, longitude: -76.743306, zipCode: "20606"},
	{latitude: 38.673313, longitude: -77.02684, zipCode: "20607"},
	{latitude: 38.582405, longitude: -76.700998, zipCode: "20608"},
	{latitude: 38.281215, longitude: -76.748472, zipCode: "20609"},
	{latitude: 38.454964, longitude: -76.987618, zipCode: "20611"},
	{latitude: 38.509514, longitude: -76.679329, zipCode: "20612"},
	{latitude: 38.671168, longitude: -76.805304, zipCode: "20613"},
	{latitude: 38.411433, longitude: -76.548803, zipCode: "20615"},
	{latitude: 38.661948, longitude: -77.098458, zipCode: "20616"},
	{latitude: 38.543841, longitude: -76.852511, zipCode: "20617"},
	{latitude: 38.276012, longitude: -76.799881, zipCode: "20618"},
	{latitude: 38.292531, longitude: -76.527662, zipCode: "20619"},
	{latitude: 38.233122, longitude: -76.52183, zipCode: "20620"},
	{latitude: 38.32615, longitude: -76.801054, zipCode: "20621"},
	{latitude: 38.417355, longitude: -76.878012, zipCode: "20622"},
	{latitude: 38.741615, longitude: -76.842456, zipCode: "20623"},
	{latitude: 38.33808, longitude: -76.733499, zipCode: "20624"},
	{latitude: 38.257481, longitude: -76.84453, zipCode: "20625"},
	{latitude: 38.236528, longitude: -76.763481, zipCode: "20626"},
	{latitude: 38.152998, longitude: -76.339859, zipCode: "20628"},
	{latitude: 38.344109, longitude: -76.456029, zipCode: "20629"},
	{latitude: 38.160103, longitude: -76.463456, zipCode: "20630"},
	{latitude: 38.422801, longitude: -76.95482, zipCode: "20632"},
	{latitude: 38.241711, longitude: -76.498277, zipCode: "20634"},
	{latitude: 38.352141, longitude: -76.567187, zipCode: "20636"},
	{latitude: 38.525112, longitude: -76.755989, zipCode: "20637"},
	{latitude: 38.601241, longitude: -76.605476, zipCode: "20639"},
	{latitude: 38.526733, longitude: -77.201604, zipCode: "20640"},
	{latitude: 38.297219, longitude: -76.916184, zipCode: "20645"},
	{latitude: 38.520653, longitude: -76.999915, zipCode: "20646"},
	{latitude: 38.25993, longitude: -76.63242, zipCode: "20650"},
	{latitude: 38.237089, longitude: -76.428692, zipCode: "20653"},
	{latitude: 38.338457, longitude: -76.681318, zipCode: "20656"},
	{latitude: 38.384195, longitude: -76.440057, zipCode: "20657"},
	{latitude: 38.57071, longitude: -77.158356, zipCode: "20658"},
	{latitude: 38.407894, longitude: -76.732045, zipCode: "20659"},
	{latitude: 38.3693, longitude: -76.70731, zipCode: "20660"},
	{latitude: 38.263698, longitude: -76.848986, zipCode: "20661"},
	{latitude: 38.428507, longitude: -77.205325, zipCode: "20662"},
	{latitude: 38.342701, longitude: -76.910242, zipCode: "20664"},
	{latitude: 38.215237, longitude: -76.447756, zipCode: "20667"},
	{latitude: 38.284848, longitude: -76.407661, zipCode: "20670"},
	{latitude: 38.147123, longitude: -76.535249, zipCode: "20674"},
	{latitude: 38.583482, longitude: -77.015238, zipCode: "20675"},
	{latitude: 38.487361, longitude: -76.545326, zipCode: "20676"},
	{latitude: 38.495253, longitude: -77.036717, zipCode: "20677"},
	{latitude: 38.525112, longitude: -76.5954, zipCode: "20678"},
	{latitude: 38.10913, longitude: -76.385886, zipCode: "20680"},
	{latitude: 38.125487, longitude: -76.420544, zipCode: "20684"},
	{latitude: 38.434378, longitude: -76.528681, zipCode: "20685"},
	{latitude: 38.187079, longitude: -76.432826, zipCode: "20686"},
	{latitude: 38.063698, longitude: -76.339689, zipCode: "20687"},
	{latitude: 38.327259, longitude: -76.464324, zipCode: "20688"},
	{latitude: 38.670275, longitude: -76.585916, zipCode: "20689"},
	{latitude: 38.158366, longitude: -76.53478, zipCode: "20690"},
	{latitude: 38.180353, longitude: -76.499612, zipCode: "20692"},
	{latitude: 38.459058, longitude: -77.087352, zipCode: "20693"},
	{latitude: 38.587767, longitude: -76.9788, zipCode: "20695"},
	{latitude: 39.132522, longitude: -76.788264, zipCode: "20701"},
	{latitude: 39.047104, longitude: -76.898079, zipCode: "20705"},
	{latitude: 38.96588, longitude: -76.851092, zipCode: "20706"},
	{latitude: 39.095593, longitude: -76.883877, zipCode: "20707"},
	{latitude: 39.042551, longitude: -76.826485, zipCode: "20708"},
	{latitude: 38.94168, longitude: -76.925876, zipCode: "20710"},
	{latitude: 38.801059, longitude: -76.645107, zipCode: "20711"},
	{latitude: 38.942361, longitude: -76.964578, zipCode: "20712"},
	{latitude: 38.703075, longitude: -76.532853, zipCode: "20714"},
	{latitude: 38.986806, longitude: -76.736266, zipCode: "20715"},
	{latitude: 38.926428, longitude: -76.715028, zipCode: "20716"},
	{latitude: 38.996376, longitude: -76.783377, zipCode: "20720"},
	{latitude: 38.915222, longitude: -76.785129, zipCode: "20721"},
	{latitude: 38.934557, longitude: -76.950596, zipCode: "20722"},
	{latitude: 39.137678, longitude: -76.867942, zipCode: "20723"},
	{latitude: 39.08027, longitude: -76.790561, zipCode: "20724"},
	{latitude: 38.65514, longitude: -76.542571, zipCode: "20732"},
	{latitude: 38.805653, longitude: -76.532229, zipCode: "20733"},
	{latitude: 38.750917, longitude: -76.902669, zipCode: "20735"},
	{latitude: 38.689913, longitude: -76.617273, zipCode: "20736"},
	{latitude: 38.962373, longitude: -76.915191, zipCode: "20737"},
	{latitude: 39.002743, longitude: -76.930804, zipCode: "20740"},
	{latitude: 38.994183, longitude: -76.94344, zipCode: "20742"},
	{latitude: 38.883854, longitude: -76.894232, zipCode: "20743"},
	{latitude: 38.745892, longitude: -76.983201, zipCode: "20744"},
	{latitude: 38.805571, longitude: -76.994654, zipCode: "20745"},
	{latitude: 38.836403, longitude: -76.918265, zipCode: "20746"},
	{latitude: 38.853309, longitude: -76.883028, zipCode: "20747"},
	{latitude: 38.814868, longitude: -76.933447, zipCode: "20748"},
	{latitude: 38.794437, longitude: -76.561199, zipCode: "20751"},
	{latitude: 38.731807, longitude: -76.65658, zipCode: "20754"},
	{latitude: 39.098497, longitude: -76.745479, zipCode: "20755"},
	{latitude: 38.734005, longitude: -76.589616, zipCode: "20758"},
	{latitude: 39.153977, longitude: -76.931171, zipCode: "20759"},
	{latitude: 38.809803, longitude: -76.869158, zipCode: "20762"},
	{latitude: 39.137262, longitude: -76.820091, zipCode: "20763"},
	{latitude: 38.831941, longitude: -76.504324, zipCode: "20764"},
	{latitude: 38.846396, longitude: -76.547617, zipCode: "20765"},
	{latitude: 38.991875, longitude: -76.809211, zipCode: "20769"},
	{latitude: 38.999726, longitude: -76.889112, zipCode: "20770"},
	{latitude: 39.008593, longitude: -76.855976, zipCode: "20771"},
	{latitude: 38.780274, longitude: -76.76699, zipCode: "20772"},
	{latitude: 38.876255, longitude: -76.769603, zipCode: "20774"},
	{latitude: 38.878351, longitude: -76.592005, zipCode: "20776"},
	{latitude: 39.174938, longitude: -76.969085, zipCode: "20777"},
	{latitude: 38.828701, longitude: -76.572747, zipCode: "20778"},
	{latitude: 38.75859, longitude: -76.569497, zipCode: "20779"},
	{latitude: 38.95286, longitude: -76.94629, zipCode: "20781"},
	{latitude: 38.965877, longitude: -76.966076, zipCode: "20782"},
	{latitude: 38.997067, longitude: -76.966783, zipCode: "20783"},
	{latitude: 38.950549, longitude: -76.89172, zipCode: "20784"},
	{latitude: 38.918291, longitude: -76.881948, zipCode: "20785"},
	{latitude: 39.151475, longitude: -76.794703, zipCode: "20794"},
	{latitude: 38.967041, longitude: -77.143881, zipCode: "20812"},
	{latitude: 39.004839, longitude: -77.103004, zipCode: "20814"},
	{latitude: 38.983386, longitude: -77.079337, zipCode: "20815"},
	{latitude: 38.95695, longitude: -77.120006, zipCode: "20816"},
	{latitude: 38.997279, longitude: -77.151816, zipCode: "20817"},
	{latitude: 38.973486, longitude: -77.162389, zipCode: "20818"},
	{latitude: 39.150349, longitude: -77.078251, zipCode: "20832"},
	{latitude: 39.210355, longitude: -77.061622, zipCode: "20833"},
	{latitude: 39.116506, longitude: -77.407115, zipCode: "20837"},
	{latitude: 39.22409, longitude: -77.370287, zipCode: "20838"},
	{latitude: 39.181539, longitude: -77.430796, zipCode: "20839"},
	{latitude: 39.181961, longitude: -77.32247, zipCode: "20841"},
	{latitude: 39.199879, longitude: -77.415266, zipCode: "20842"},
	{latitude: 39.089211, longitude: -77.180501, zipCode: "20850"},
	{latitude: 39.07807, longitude: -77.120838, zipCode: "20851"},
	{latitude: 39.051511, longitude: -77.123973, zipCode: "20852"},
	{latitude: 39.101747, longitude: -77.094602, zipCode: "20853"},
	{latitude: 39.032772, longitude: -77.22083, zipCode: "20854"},
	{latitude: 39.136974, longitude: -77.131801, zipCode: "20855"},
	{latitude: 39.150964, longitude: -77.034962, zipCode: "20860"},
	{latitude: 39.148685, longitude: -76.999085, zipCode: "20861"},
	{latitude: 39.1808, longitude: -77.017395, zipCode: "20862"},
	{latitude: 39.10928, longitude: -76.933948, zipCode: "20866"},
	{latitude: 39.125872, longitude: -76.961413, zipCode: "20868"},
	{latitude: 39.258885, longitude: -77.281829, zipCode: "20871"},
	{latitude: 39.299203, longitude: -77.206561, zipCode: "20872"},
	{latitude: 39.133056, longitude: -77.301828, zipCode: "20874"},
	{latitude: 39.206734, longitude: -77.233675, zipCode: "20876"},
	{latitude: 39.140601, longitude: -77.19206, zipCode: "20877"},
	{latitude: 39.112637, longitude: -77.250436, zipCode: "20878"},
	{latitude: 39.167298, longitude: -77.164147, zipCode: "20879"},
	{latitude: 39.140699, longitude: -77.174533, zipCode: "20880"},
	{latitude: 39.228682, longitude: -77.162652, zipCode: "20882"},
	{latitude: 39.188512, longitude: -77.205143, zipCode: "20886"},
	{latitude: 39.003756, longitude: -77.086964, zipCode: "20889"},
	{latitude: 39.000635, longitude: -77.103711, zipCode: "20892"},
	{latitude: 38.99586, longitude: -77.102139, zipCode: "20894"},
	{latitude: 39.027048, longitude: -77.07759, zipCode: "20895"},
	{latitude: 39.035252, longitude: -77.095044, zipCode: "20896"},
	{latitude: 39.1444, longitude: -77.216175, zipCode: "20899"},
	{latitude: 39.021495, longitude: -77.009779, zipCode: "20901"},
	{latitude: 39.043755, longitude: -77.042369, zipCode: "20902"},
	{latitude: 39.021433, longitude: -76.980774, zipCode: "20903"},
	{latitude: 39.070379, longitude: -76.977379, zipCode: "20904"},
	{latitude: 39.109866, longitude: -76.988077, zipCode: "20905"},
	{latitude: 39.087235, longitude: -77.054952, zipCode: "20906"},
	{latitude: 39.00286, longitude: -77.036646, zipCode: "20910"},
	{latitude: 38.981601, longitude: -77.001159, zipCode: "20912"},
	{latitude: 39.504488, longitude: -76.201049, zipCode: "21001"},
	{latitude: 39.472632, longitude: -76.110666, zipCode: "21005"},
	{latitude: 39.471468, longitude: -76.284636, zipCode: "21009"},
	{latitude: 39.390932, longitude: -76.292629, zipCode: "21010"},
	{latitude: 39.046562, longitude: -76.495626, zipCode: "21012"},
	{latitude: 39.51149, longitude: -76.4908, zipCode: "21013"},
	{latitude: 39.53584, longitude: -76.35161, zipCode: "21014"},
	{latitude: 39.544176, longitude: -76.292469, zipCode: "21015"},
	{latitude: 39.474831, longitude: -76.234575, zipCode: "21017"},
	{latitude: 39.523198, longitude: -76.740196, zipCode: "21023"},
	{latitude: 39.565965, longitude: -76.249527, zipCode: "21028"},
	{latitude: 39.21356, longitude: -76.959392, zipCode: "21029"},
	{latitude: 39.51582, longitude: -76.698751, zipCode: "21030"},
	{latitude: 39.487392, longitude: -76.657938, zipCode: "21031"},
	{latitude: 39.028343, longitude: -76.604145, zipCode: "21032"},
	{latitude: 39.640582, longitude: -76.223104, zipCode: "21034"},
	{latitude: 38.9383, longitude: -76.626588, zipCode: "21035"},
	{latitude: 39.234644, longitude: -77.006739, zipCode: "21036"},
	{latitude: 38.919253, longitude: -76.543492, zipCode: "21037"},
	{latitude: 39.429886, longitude: -76.28361, zipCode: "21040"},
	{latitude: 39.277181, longitude: -76.894602, zipCode: "21042"},
	{latitude: 39.255836, longitude: -76.801491, zipCode: "21043"},
	{latitude: 39.205274, longitude: -76.878737, zipCode: "21044"},
	{latitude: 39.206042, longitude: -76.828234, zipCode: "21045"},
	{latitude: 39.174058, longitude: -76.835354, zipCode: "21046"},
	{latitude: 39.526941, longitude: -76.438078, zipCode: "21047"},
	{latitude: 39.500375, longitude: -76.924538, zipCode: "21048"},
	{latitude: 39.585343, longitude: -76.392164, zipCode: "21050"},
	{latitude: 39.472691, longitude: -76.455841, zipCode: "21051"},
	{latitude: 39.205023, longitude: -76.439629, zipCode: "21052"},
	{latitude: 39.696792, longitude: -76.714672, zipCode: "21053"},
	{latitude: 39.055593, longitude: -76.6905, zipCode: "21054"},
	{latitude: 39.078951, longitude: -76.436246, zipCode: "21056"},
	{latitude: 39.448749, longitude: -76.512474, zipCode: "21057"},
	{latitude: 39.17364, longitude: -76.577642, zipCode: "21060"},
	{latitude: 39.160897, longitude: -76.632112, zipCode: "21061"},
	{latitude: 39.483808, longitude: -76.81036, zipCode: "21071"},
	{latitude: 39.621599, longitude: -76.839931, zipCode: "21074"},
	{latitude: 39.203083, longitude: -76.750721, zipCode: "21075"},
	{latitude: 39.171402, longitude: -76.723341, zipCode: "21076"},
	{latitude: 39.158365, longitude: -76.699539, zipCode: "21077"},
	{latitude: 39.559229, longitude: -76.141248, zipCode: "21078"},
	{latitude: 39.478111, longitude: -76.473472, zipCode: "21082"},
	{latitude: 39.613561, longitude: -76.466441, zipCode: "21084"},
	{latitude: 39.44588, longitude: -76.357015, zipCode: "21085"},
	{latitude: 39.449314, longitude: -76.41324, zipCode: "21087"},
	{latitude: 39.209567, longitude: -76.664741, zipCode: "21090"},
	{latitude: 39.437973, longitude: -76.642173, zipCode: "21093"},
	{latitude: 39.692165, longitude: -76.847645, zipCode: "21102"},
	{latitude: 39.346904, longitude: -76.912757, zipCode: "21104"},
	{latitude: 39.715129, longitude: -76.652282, zipCode: "21105"},
	{latitude: 39.090307, longitude: -76.621263, zipCode: "21108"},
	{latitude: 39.575535, longitude: -76.589691, zipCode: "21111"},
	{latitude: 39.062685, longitude: -76.712417, zipCode: "21113"},
	{latitude: 39.009687, longitude: -76.68386, zipCode: "21114"},
	{latitude: 39.427569, longitude: -76.775759, zipCode: "21117"},
	{latitude: 39.646204, longitude: -76.674854, zipCode: "21120"},
	{latitude: 39.114283, longitude: -76.487394, zipCode: "21122"},
	{latitude: 39.40467, longitude: -76.448223, zipCode: "21128"},
	{latitude: 39.474769, longitude: -76.189397, zipCode: "21130"},
	{latitude: 39.504492, longitude: -76.589196, zipCode: "21131"},
	{latitude: 39.695537, longitude: -76.422201, zipCode: "21132"},
	{latitude: 39.378361, longitude: -76.811336, zipCode: "21133"},
	{latitude: 39.439136, longitude: -76.844548, zipCode: "21136"},
	{latitude: 38.950842, longitude: -76.586726, zipCode: "21140"},
	{latitude: 39.12057, longitude: -76.677621, zipCode: "21144"},
	{latitude: 39.079136, longitude: -76.558699, zipCode: "21146"},
	{latitude: 39.555195, longitude: -76.685924, zipCode: "21152"},
	{latitude: 39.417844, longitude: -76.70052, zipCode: "21153"},
	{latitude: 39.657449, longitude: -76.360475, zipCode: "21154"},
	{latitude: 39.573619, longitude: -76.806079, zipCode: "21155"},
	{latitude: 39.429159, longitude: -76.403873, zipCode: "21156"},
	{latitude: 39.548569, longitude: -76.983558, zipCode: "21157"},
	{latitude: 39.653717, longitude: -77.035127, zipCode: "21158"},
	{latitude: 39.70025, longitude: -76.306543, zipCode: "21160"},
	{latitude: 39.659903, longitude: -76.56506, zipCode: "21161"},
	{latitude: 39.390116, longitude: -76.405582, zipCode: "21162"},
	{latitude: 39.337527, longitude: -76.848247, zipCode: "21163"},
	{latitude: 39.294961, longitude: -76.622341, zipCode: "21201"},
	{latitude: 39.297425, longitude: -76.607722, zipCode: "21202"},
	{latitude: 39.406389, longitude: -76.626072, zipCode: "21204"},
	{latitude: 39.30224, longitude: -76.564078, zipCode: "21205"},
	{latitude: 39.338428, longitude: -76.538877, zipCode: "21206"},
	{latitude: 39.324167, longitude: -76.719484, zipCode: "21207"},
	{latitude: 39.383959, longitude: -76.724261, zipCode: "21208"},
	{latitude: 39.375252, longitude: -76.662617, zipCode: "21209"},
	{latitude: 39.357633, longitude: -76.634986, zipCode: "21210"},
	{latitude: 39.328746, longitude: -76.639281, zipCode: "21211"},
	{latitude: 39.367306, longitude: -76.614586, zipCode: "21212"},
	{latitude: 39.315031, longitude: -76.577429, zipCode: "21213"},
	{latitude: 39.352143, longitude: -76.564332, zipCode: "21214"},
	{latitude: 39.345947, longitude: -76.682206, zipCode: "21215"},
	{latitude: 39.310595, longitude: -76.671717, zipCode: "21216"},
	{latitude: 39.30704, longitude: -76.638328, zipCode: "21217"},
	{latitude: 39.330107, longitude: -76.601451, zipCode: "21218"},
	{latitude: 39.230192, longitude: -76.436077, zipCode: "21219"},
	{latitude: 39.343079, longitude: -76.390883, zipCode: "21220"},
	{latitude: 39.292125, longitude: -76.436995, zipCode: "21221"},
	{latitude: 39.264602, longitude: -76.495557, zipCode: "21222"},
	{latitude: 39.282951, longitude: -76.653877, zipCode: "21223"},
	{latitude: 39.275909, longitude: -76.541928, zipCode: "21224"},
	{latitude: 39.226117, longitude: -76.615735, zipCode: "21225"},
	{latitude: 39.208888, longitude: -76.562926, zipCode: "21226"},
	{latitude: 39.23997, longitude: -76.67945, zipCode: "21227"},
	{latitude: 39.272857, longitude: -76.747741, zipCode: "21228"},
	{latitude: 39.284239, longitude: -76.691403, zipCode: "21229"},
	{latitude: 39.268123, longitude: -76.626716, zipCode: "21230"},
	{latitude: 39.288507, longitude: -76.592024, zipCode: "21231"},
	{latitude: 39.292463, longitude: -76.604958, zipCode: "21233"},
	{latitude: 39.393254, longitude: -76.531822, zipCode: "21234"},
	{latitude: 39.388231, longitude: -76.483685, zipCode: "21236"},
	{latitude: 39.341537, longitude: -76.49527, zipCode: "21237"},
	{latitude: 39.365159, longitude: -76.587101, zipCode: "21239"},
	{latitude: 39.174827, longitude: -76.671802, zipCode: "21240"},
	{latitude: 39.335005, longitude: -76.776706, zipCode: "21244"},
	{latitude: 39.255611, longitude: -76.711179, zipCode: "21250"},
	{latitude: 39.349922, longitude: -76.580231, zipCode: "21251"},
	{latitude: 39.394116, longitude: -76.611383, zipCode: "21252"},
	{latitude: 39.38749, longitude: -76.617005, zipCode: "21285"},
	{latitude: 39.411836, longitude: -76.573235, zipCode: "21286"},
	{latitude: 39.29916, longitude: -76.588327, zipCode: "21287"},
	{latitude: 38.987941, longitude: -76.552716, zipCode: "21401"},
	{latitude: 38.988393, longitude: -76.470681, zipCode: "21402"},
	{latitude: 38.942123, longitude: -76.489628, zipCode: "21403"},
	{latitude: 39.028145, longitude: -76.548016, zipCode: "21405"},
	{latitude: 39.018435, longitude: -76.442533, zipCode: "21409"},
	{latitude: 39.641477, longitude: -78.756088, zipCode: "21502"},
	{latitude: 39.636619, longitude: -79.307843, zipCode: "21520"},
	{latitude: 39.549674, longitude: -79.046462, zipCode: "21521"},
	{latitude: 39.481331, longitude: -79.07851, zipCode: "21523"},
	{latitude: 39.706666, longitude: -78.802058, zipCode: "21524"},
	{latitude: 39.713709, longitude: -78.776181, zipCode: "21529"},
	{latitude: 39.67964, longitude: -78.546122, zipCode: "21530"},
	{latitude: 39.643045, longitude: -79.428086, zipCode: "21531"},
	{latitude: 39.649077, longitude: -78.984732, zipCode: "21532"},
	{latitude: 39.65696, longitude: -79.165566, zipCode: "21536"},
	{latitude: 39.409932, longitude: -79.22429, zipCode: "21538"},
	{latitude: 39.595218, longitude: -79.009519, zipCode: "21539"},
	{latitude: 39.478781, longitude: -79.058903, zipCode: "21540"},
	{latitude: 39.541471, longitude: -79.380028, zipCode: "21541"},
	{latitude: 39.591216, longitude: -78.950784, zipCode: "21542"},
	{latitude: 39.643622, longitude: -78.961006, zipCode: "21543"},
	{latitude: 39.704066, longitude: -78.854511, zipCode: "21545"},
	{latitude: 39.391733, longitude: -79.392385, zipCode: "21550"},
	{latitude: 39.57514, longitude: -78.562574, zipCode: "21555"},
	{latitude: 39.522914, longitude: -78.927734, zipCode: "21557"},
	{latitude: 39.565048, longitude: -78.706213, zipCode: "21560"},
	{latitude: 39.491589, longitude: -79.194401, zipCode: "21561"},
	{latitude: 39.513851, longitude: -79.03938, zipCode: "21562"},
	{latitude: 38.792467, longitude: -76.087881, zipCode: "21601"},
	{latitude: 39.133987, longitude: -75.856435, zipCode: "21607"},
	{latitude: 39.367115, longitude: -76.072496, zipCode: "21610"},
	{latitude: 38.75217, longitude: -76.266789, zipCode: "21612"},
	{latitude: 38.502789, longitude: -76.078875, zipCode: "21613"},
	{latitude: 39.049357, longitude: -76.044545, zipCode: "21617"},
	{latitude: 38.942447, longitude: -76.265742, zipCode: "21619"},
	{latitude: 39.208022, longitude: -76.082748, zipCode: "21620"},
	{latitude: 38.414465, longitude: -76.159253, zipCode: "21622"},
	{latitude: 39.124035, longitude: -75.966748, zipCode: "21623"},
	{latitude: 38.834413, longitude: -76.275743, zipCode: "21624"},
	{latitude: 38.864116, longitude: -75.981494, zipCode: "21625"},
	{latitude: 38.342712, longitude: -76.067945, zipCode: "21626"},
	{latitude: 38.233114, longitude: -76.046404, zipCode: "21627"},
	{latitude: 39.233174, longitude: -75.922049, zipCode: "21628"},
	{latitude: 38.858672, longitude: -75.825079, zipCode: "21629"},
	{latitude: 38.582391, longitude: -75.938981, zipCode: "21631"},
	{latitude: 38.731398, longitude: -75.772428, zipCode: "21632"},
	{latitude: 38.285946, longitude: -76.194292, zipCode: "21634"},
	{latitude: 39.334791, longitude: -75.846624, zipCode: "21635"},
	{latitude: 39.014984, longitude: -75.795778, zipCode: "21636"},
	{latitude: 38.940201, longitude: -76.207604, zipCode: "21638"},
	{latitude: 38.958732, longitude: -75.782499, zipCode: "21639"},
	{latitude: 39.068141, longitude: -75.820948, zipCode: "21640"},
	{latitude: 38.917043, longitude: -75.941779, zipCode: "21641"},
	{latitude: 38.645582, longitude: -75.869896, zipCode: "21643"},
	{latitude: 39.117239, longitude: -75.874393, zipCode: "21644"},
	{latitude: 39.315819, longitude: -75.956165, zipCode: "21645"},
	{latitude: 38.796108, longitude: -76.284812, zipCode: "21647"},
	{latitude: 38.5088, longitude: -76.239792, zipCode: "21648"},
	{latitude: 39.145558, longitude: -75.78419, zipCode: "21649"},
	{latitude: 39.310581, longitude: -75.808633, zipCode: "21650"},
	{latitude: 39.256052, longitude: -75.858394, zipCode: "21651"},
	{latitude: 38.718952, longitude: -76.281023, zipCode: "21652"},
	{latitude: 38.744016, longitude: -76.175776, zipCode: "21653"},
	{latitude: 38.690105, longitude: -76.131594, zipCode: "21654"},
	{latitude: 38.744968, longitude: -75.912768, zipCode: "21655"},
	{latitude: 38.938607, longitude: -75.981087, zipCode: "21657"},
	{latitude: 38.944268, longitude: -76.143938, zipCode: "21658"},
	{latitude: 38.575046, longitude: -75.80042, zipCode: "21659"},
	{latitude: 38.95622, longitude: -75.888948, zipCode: "21660"},
	{latitude: 39.096121, longitude: -76.219488, zipCode: "21661"},
	{latitude: 38.714248, longitude: -76.20068, zipCode: "21662"},
	{latitude: 38.788029, longitude: -76.231183, zipCode: "21663"},
	{latitude: 38.607572, longitude: -75.947113, zipCode: "21664"},
	{latitude: 38.754862, longitude: -76.368111, zipCode: "21665"},
	{latitude: 38.940139, longitude: -76.33579, zipCode: "21666"},
	{latitude: 39.343735, longitude: -76.051873, zipCode: "21667"},
	{latitude: 39.190554, longitude: -75.850527, zipCode: "21668"},
	{latitude: 38.461963, longitude: -76.295171, zipCode: "21669"},
	{latitude: 38.695574, longitude: -76.335128, zipCode: "21671"},
	{latitude: 38.271699, longitude: -76.052704, zipCode: "21672"},
	{latitude: 38.642285, longitude: -76.043492, zipCode: "21673"},
	{latitude: 38.288979, longitude: -76.0896, zipCode: "21675"},
	{latitude: 38.787435, longitude: -76.301138, zipCode: "21676"},
	{latitude: 38.472182, longitude: -76.173844, zipCode: "21677"},
	{latitude: 39.3136, longitude: -76.113348, zipCode: "21678"},
	{latitude: 38.920478, longitude: -76.094115, zipCode: "21679"},
	{latitude: 39.443584, longitude: -77.332891, zipCode: "21701"},
	{latitude: 39.479251, longitude: -77.443194, zipCode: "21702"},
	{latitude: 39.367031, longitude: -77.473482, zipCode: "21703"},
	{latitude: 39.354883, longitude: -77.375902, zipCode: "21704"},
	{latitude: 39.40886, longitude: -77.410008, zipCode: "21705"},
	{latitude: 39.307904, longitude: -77.432061, zipCode: "21710"},
	{latitude: 39.668396, longitude: -78.019569, zipCode: "21711"},
	{latitude: 39.525079, longitude: -77.66965, zipCode: "21713"},
	{latitude: 39.410591, longitude: -77.510051, zipCode: "21714"},
	{latitude: 39.37876, longitude: -77.660722, zipCode: "21715"},
	{latitude: 39.317799, longitude: -77.626706, zipCode: "21716"},
	{latitude: 39.338627, longitude: -77.431527, zipCode: "21717"},
	{latitude: 39.393497, longitude: -77.627788, zipCode: "21718"},
	{latitude: 39.704543, longitude: -77.500455, zipCode: "21719"},
	{latitude: 39.664505, longitude: -77.913452, zipCode: "21722"},
	{latitude: 39.329107, longitude: -77.002611, zipCode: "21723"},
	{latitude: 39.688615, longitude: -77.329281, zipCode: "21727"},
	{latitude: 39.550014, longitude: -77.758355, zipCode: "21733"},
	{latitude: 39.605788, longitude: -77.703842, zipCode: "21734"},
	{latitude: 39.24826, longitude: -77.027125, zipCode: "21737"},
	{latitude: 39.279844, longitude: -77.026706, zipCode: "21738"},
	{latitude: 39.631905, longitude: -77.743908, zipCode: "21740"},
	{latitude: 39.678219, longitude: -77.65272, zipCode: "21742"},
	{latitude: 39.565909, longitude: -77.714139, zipCode: "21746"},
	{latitude: 39.662262, longitude: -78.235975, zipCode: "21750"},
	{latitude: 39.332114, longitude: -77.316213, zipCode: "21754"},
	{latitude: 39.359655, longitude: -77.568128, zipCode: "21755"},
	{latitude: 39.459836, longitude: -77.693503, zipCode: "21756"},
	{latitude: 39.593861, longitude: -77.258139, zipCode: "21757"},
	{latitude: 39.353205, longitude: -77.656813, zipCode: "21758"},
	{latitude: 39.48059, longitude: -77.248289, zipCode: "21762"},
	{latitude: 39.670471, longitude: -78.38789, zipCode: "21766"},
	{latitude: 39.697793, longitude: -77.746536, zipCode: "21767"},
	{latitude: 39.442464, longitude: -77.568908, zipCode: "21769"},
	{latitude: 39.350189, longitude: -77.25624, zipCode: "21770"},
	{latitude: 39.394937, longitude: -77.158193, zipCode: "21771"},
	{latitude: 39.542655, longitude: -77.551543, zipCode: "21773"},
	{latitude: 39.410168, longitude: -77.274193, zipCode: "21774"},
	{latitude: 39.518006, longitude: -77.099709, zipCode: "21776"},
	{latitude: 39.279296, longitude: -77.528727, zipCode: "21777"},
	{latitude: 39.615806, longitude: -77.328421, zipCode: "21778"},
	{latitude: 39.424484, longitude: -77.653994, zipCode: "21779"},
	{latitude: 39.672151, longitude: -77.471618, zipCode: "21780"},
	{latitude: 39.571253, longitude: -77.759805, zipCode: "21781"},
	{latitude: 39.455471, longitude: -77.760235, zipCode: "21782"},
	{latitude: 39.651955, longitude: -77.552583, zipCode: "21783"},
	{latitude: 39.401084, longitude: -76.973736, zipCode: "21784"},
	{latitude: 39.67763, longitude: -77.172591, zipCode: "21787"},
	{latitude: 39.589979, longitude: -77.413861, zipCode: "21788"},
	{latitude: 39.262737, longitude: -77.502746, zipCode: "21790"},
	{latitude: 39.533053, longitude: -77.18856, zipCode: "21791"},
	{latitude: 39.489155, longitude: -77.344216, zipCode: "21793"},
	{latitude: 39.298422, longitude: -76.966129, zipCode: "21794"},
	{latitude: 39.580609, longitude: -77.823698, zipCode: "21795"},
	{latitude: 39.331175, longitude: -77.068606, zipCode: "21797"},
	{latitude: 39.53775, longitude: -77.295078, zipCode: "21798"},
	{latitude: 38.349417, longitude: -75.67847, zipCode: "21801"},
	{latitude: 38.314082, longitude: -75.532106, zipCode: "21804"},
	{latitude: 38.290675, longitude: -75.689266, zipCode: "21810"},
	{latitude: 38.321068, longitude: -75.222208, zipCode: "21811"},
	{latitude: 38.422799, longitude: -75.179686, zipCode: "21813"},
	{latitude: 38.293076, longitude: -75.899296, zipCode: "21814"},
	{latitude: 37.981025, longitude: -75.836069, zipCode: "21817"},
	{latitude: 38.164595, longitude: -75.91666, zipCode: "21821"},
	{latitude: 38.288379, longitude: -75.660963, zipCode: "21822"},
	{latitude: 37.984532, longitude: -76.037389, zipCode: "21824"},
	{latitude: 38.320393, longitude: -75.626502, zipCode: "21826"},
	{latitude: 38.097659, longitude: -75.371456, zipCode: "21829"},
	{latitude: 38.397363, longitude: -75.734768, zipCode: "21830"},
	{latitude: 38.534209, longitude: -75.936035, zipCode: "21835"},
	{latitude: 38.454586, longitude: -75.766294, zipCode: "21837"},
	{latitude: 38.017905, longitude: -75.73792, zipCode: "21838"},
	{latitude: 38.248736, longitude: -75.900009, zipCode: "21840"},
	{latitude: 38.223771, longitude: -75.281087, zipCode: "21841"},
	{latitude: 38.363088, longitude: -75.085032, zipCode: "21842"},
	{latitude: 38.364688, longitude: -75.456326, zipCode: "21849"},
	{latitude: 38.361731, longitude: -75.397078, zipCode: "21850"},
	{latitude: 38.07765, longitude: -75.543516, zipCode: "21851"},
	{latitude: 38.20354, longitude: -75.720887, zipCode: "21853"},
	{latitude: 38.330729, longitude: -75.794885, zipCode: "21856"},
	{latitude: 38.53644, longitude: -75.727352, zipCode: "21861"},
	{latitude: 38.395818, longitude: -75.212931, zipCode: "21862"},
	{latitude: 38.187913, longitude: -75.399041, zipCode: "21863"},
	{latitude: 38.031572, longitude: -75.405812, zipCode: "21864"},
	{latitude: 38.282757, longitude: -75.826995, zipCode: "21865"},
	{latitude: 37.967128, longitude: -76.021365, zipCode: "21866"},
	{latitude: 38.110276, longitude: -75.793973, zipCode: "21867"},
	{latitude: 38.386801, longitude: -75.915994, zipCode: "21869"},
	{latitude: 38.1068, longitude: -75.741522, zipCode: "21871"},
	{latitude: 38.415131, longitude: -75.29926, zipCode: "21872"},
	{latitude: 38.393841, longitude: -75.352002, zipCode: "21874"},
	{latitude: 38.439999, longitude: -75.544695, zipCode: "21875"},
	{latitude: 38.158081, longitude: -75.700905, zipCode: "21890"},
	{latitude: 39.583057, longitude: -75.9668, zipCode: "21901"},
	{latitude: 39.552352, longitude: -76.063758, zipCode: "21902"},
	{latitude: 39.574107, longitude: -76.045237, zipCode: "21903"},
	{latitude: 39.61549, longitude: -76.067988, zipCode: "21904"},
	{latitude: 39.695057, longitude: -76.031812, zipCode: "21911"},
	{latitude: 39.419414, longitude: -75.818631, zipCode: "21912"},
	{latitude: 39.414022, longitude: -75.869924, zipCode: "21913"},
	{latitude: 39.575123, longitude: -75.984569, zipCode: "21914"},
	{latitude: 39.496147, longitude: -75.848168, zipCode: "21915"},
	{latitude: 39.671632, longitude: -76.097397, zipCode: "21917"},
	{latitude: 39.678194, longitude: -76.175888, zipCode: "21918"},
	{latitude: 39.418553, longitude: -75.933709, zipCode: "21919"},
	{latitude: 39.656246, longitude: -75.841007, zipCode: "21920"},
	{latitude: 39.627145, longitude: -75.859891, zipCode: "21921"},
	{latitude: 39.37013, longitude: -75.887336, zipCode: "21930"},
	{latitude: 38.830236, longitude: -77.214725, zipCode: "22003"},
	{latitude: 38.786024, longitude: -77.280015, zipCode: "22015"},
	{latitude: 38.591896, longitude: -77.349496, zipCode: "22025"},
	{latitude: 38.560068, longitude: -77.296211, zipCode: "22026"},
	{latitude: 38.894853, longitude: -77.223048, zipCode: "22027"},
	{latitude: 38.837185, longitude: -77.34048, zipCode: "22030"},
	{latitude: 38.85892, longitude: -77.260273, zipCode: "22031"},
	{latitude: 38.817673, longitude: -77.290222, zipCode: "22032"},
	{latitude: 38.875218, longitude: -77.384713, zipCode: "22033"},
	{latitude: 38.856315, longitude: -77.362851, zipCode: "22035"},
	{latitude: 38.748577, longitude: -77.314493, zipCode: "22039"},
	{latitude: 38.849321, longitude: -77.141472, zipCode: "22041"},
	{latitude: 38.865143, longitude: -77.193913, zipCode: "22042"},
	{latitude: 38.901488, longitude: -77.197424, zipCode: "22043"},
	{latitude: 38.862044, longitude: -77.154372, zipCode: "22044"},
	{latitude: 38.886334, longitude: -77.180975, zipCode: "22046"},
	{latitude: 38.699353, longitude: -77.157497, zipCode: "22060"},
	{latitude: 39.012357, longitude: -77.301942, zipCode: "22066"},
	{latitude: 38.675735, longitude: -77.20384, zipCode: "22079"},
	{latitude: 38.940203, longitude: -77.164882, zipCode: "22101"},
	{latitude: 38.950951, longitude: -77.229553, zipCode: "22102"},
	{latitude: 38.894871, longitude: -77.329515, zipCode: "22124"},
	{latitude: 38.681729, longitude: -77.261861, zipCode: "22125"},
	{latitude: 38.532506, longitude: -77.405024, zipCode: "22134"},
	{latitude: 38.772863, longitude: -77.186523, zipCode: "22150"},
	{latitude: 38.804093, longitude: -77.207804, zipCode: "22151"},
	{latitude: 38.774493, longitude: -77.232029, zipCode: "22152"},
	{latitude: 38.744969, longitude: -77.23564, zipCode: "22153"},
	{latitude: 38.578957, longitude: -77.399914, zipCode: "22172"},
	{latitude: 38.895439, longitude: -77.256133, zipCode: "22180"},
	{latitude: 38.905545, longitude: -77.289778, zipCode: "22181"},
	{latitude: 38.938421, longitude: -77.27552, zipCode: "22182"},
	{latitude: 38.622914, longitude: -77.262938, zipCode: "22191"},
	{latitude: 38.684218, longitude: -77.317942, zipCode: "22192"},
	{latitude: 38.644065, longitude: -77.350545, zipCode: "22193"},
	{latitude: 38.886804, longitude: -77.094992, zipCode: "22201"},
	{latitude: 38.856854, longitude: -77.051495, zipCode: "22202"},
	{latitude: 38.873777, longitude: -77.116418, zipCode: "22203"},
	{latitude: 38.860742, longitude: -77.098955, zipCode: "22204"},
	{latitude: 38.883371, longitude: -77.139537, zipCode: "22205"},
	{latitude: 38.844026, longitude: -77.087233, zipCode: "22206"},
	{latitude: 38.906665, longitude: -77.124238, zipCode: "22207"},
	{latitude: 38.89443, longitude: -77.073406, zipCode: "22209"},
	{latitude: 38.880114, longitude: -77.074791, zipCode: "22211"},
	{latitude: 38.894645, longitude: -77.162396, zipCode: "22213"},
	{latitude: 38.874906, longitude: -77.06505, zipCode: "22214"},
	{latitude: 38.819957, longitude: -77.059285, zipCode: "22301"},
	{latitude: 38.827888, longitude: -77.083132, zipCode: "22302"},
	{latitude: 38.793999, longitude: -77.081843, zipCode: "22303"},
	{latitude: 38.813204, longitude: -77.112268, zipCode: "22304"},
	{latitude: 38.836463, longitude: -77.062098, zipCode: "22305"},
	{latitude: 38.74979, longitude: -77.1074, zipCode: "22306"},
	{latitude: 38.77036, longitude: -77.059083, zipCode: "22307"},
	{latitude: 38.729282, longitude: -77.060994, zipCode: "22308"},
	{latitude: 38.720336, longitude: -77.106155, zipCode: "22309"},
	{latitude: 38.784656, longitude: -77.122182, zipCode: "22310"},
	{latitude: 38.833991, longitude: -77.125511, zipCode: "22311"},
	{latitude: 38.817531, longitude: -77.153866, zipCode: "22312"},
	{latitude: 38.806853, longitude: -77.056374, zipCode: "22314"},
	{latitude: 38.756764, longitude: -77.147432, zipCode: "22315"},
	{latitude: 38.299272, longitude: -77.486658, zipCode: "22401"},
	{latitude: 38.313604, longitude: -77.403928, zipCode: "22405"},
	{latitude: 38.399721, longitude: -77.54673, zipCode: "22406"},
	{latitude: 38.28492, longitude: -77.580598, zipCode: "22407"},
	{latitude: 38.211478, longitude: -77.452502, zipCode: "22408"},
	{latitude: 38.072345, longitude: -77.28859, zipCode: "22427"},
	{latitude: 37.860855, longitude: -76.355437, zipCode: "22432"},
	{latitude: 38.367057, longitude: -77.859386, zipCode: "22433"},
	{latitude: 37.966791, longitude: -76.572106, zipCode: "22435"},
	{latitude: 38.084168, longitude: -77.117456, zipCode: "22436"},
	{latitude: 37.780353, longitude: -76.811767, zipCode: "22437"},
	{latitude: 38.042972, longitude: -76.995776, zipCode: "22438"},
	{latitude: 38.169514, longitude: -76.986805, zipCode: "22443"},
	{latitude: 38.33631, longitude: -77.028454, zipCode: "22448"},
	{latitude: 37.832662, longitude: -76.845107, zipCode: "22454"},
	{latitude: 37.869283, longitude: -76.607359, zipCode: "22460"},
	{latitude: 38.063577, longitude: -76.643481, zipCode: "22469"},
	{latitude: 37.958833, longitude: -76.672509, zipCode: "22472"},
	{latitude: 37.881713, longitude: -76.411838, zipCode: "22473"},
	{latitude: 38.038045, longitude: -77.067419, zipCode: "22476"},
	{latitude: 37.662553, longitude: -76.412226, zipCode: "22480"},
	{latitude: 37.736613, longitude: -76.34665, zipCode: "22482"},
	{latitude: 38.27718, longitude: -77.162636, zipCode: "22485"},
	{latitude: 38.049455, longitude: -76.587481, zipCode: "22488"},
	{latitude: 37.750474, longitude: -76.515338, zipCode: "22503"},
	{latitude: 37.768922, longitude: -76.720684, zipCode: "22504"},
	{latitude: 38.313187, longitude: -77.798472, zipCode: "22508"},
	{latitude: 38.106151, longitude: -77.073566, zipCode: "22509"},
	{latitude: 37.994449, longitude: -76.504382, zipCode: "22511"},
	{latitude: 38.008001, longitude: -77.32938, zipCode: "22514"},
	{latitude: 38.118583, longitude: -76.812419, zipCode: "22520"},
	{latitude: 38.011551, longitude: -76.689548, zipCode: "22529"},
	{latitude: 38.087247, longitude: -77.668986, zipCode: "22534"},
	{latitude: 38.118181, longitude: -77.196631, zipCode: "22535"},
	{latitude: 38.192794, longitude: -77.27549, zipCode: "22538"},
	{latitude: 37.860613, longitude: -76.286036, zipCode: "22539"},
	{latitude: 38.293155, longitude: -77.892539, zipCode: "22542"},
	{latitude: 37.944471, longitude: -77.446105, zipCode: "22546"},
	{latitude: 37.827225, longitude: -76.701525, zipCode: "22548"},
	{latitude: 38.187032, longitude: -77.700356, zipCode: "22551"},
	{latitude: 38.271762, longitude: -77.640048, zipCode: "22553"},
	{latitude: 38.42068, longitude: -77.37468, zipCode: "22554"},
	{latitude: 38.488667, longitude: -77.515136, zipCode: "22556"},
	{latitude: 37.91585, longitude: -76.961086, zipCode: "22560"},
	{latitude: 38.237857, longitude: -77.927575, zipCode: "22567"},
	{latitude: 37.965301, longitude: -76.771067, zipCode: "22572"},
	{latitude: 37.68743, longitude: -76.440681, zipCode: "22576"},
	{latitude: 37.636571, longitude: -76.356134, zipCode: "22578"},
	{latitude: 38.112561, longitude: -77.43676, zipCode: "22580"},
	{latitude: 39.174203, longitude: -78.174289, zipCode: "22601"},
	{latitude: 39.144249, longitude: -78.284807, zipCode: "22602"},
	{latitude: 39.28096, longitude: -78.206522, zipCode: "22603"},
	{latitude: 38.824656, longitude: -78.279143, zipCode: "22610"},
	{latitude: 39.163192, longitude: -77.985448, zipCode: "22611"},
	{latitude: 39.060451, longitude: -78.034213, zipCode: "22620"},
	{latitude: 38.848927, longitude: -78.140664, zipCode: "22623"},
	{latitude: 39.26121, longitude: -78.096283, zipCode: "22624"},
	{latitude: 39.376805, longitude: -78.309326, zipCode: "22625"},
	{latitude: 38.772295, longitude: -78.148771, zipCode: "22627"},
	{latitude: 38.930914, longitude: -78.17648, zipCode: "22630"},
	{latitude: 39.25674, longitude: -78.352778, zipCode: "22637"},
	{latitude: 38.845038, longitude: -78.034518, zipCode: "22639"},
	{latitude: 38.811028, longitude: -78.14057, zipCode: "22640"},
	{latitude: 39.085212, longitude: -78.393228, zipCode: "22641"},
	{latitude: 38.948118, longitude: -78.027722, zipCode: "22642"},
	{latitude: 38.892758, longitude: -78.012721, zipCode: "22643"},
	{latitude: 38.965785, longitude: -78.545389, zipCode: "22644"},
	{latitude: 39.019181, longitude: -78.274706, zipCode: "22645"},
	{latitude: 39.072518, longitude: -78.050929, zipCode: "22646"},
	{latitude: 38.765084, longitude: -78.364632, zipCode: "22650"},
	{latitude: 38.840091, longitude: -78.431748, zipCode: "22652"},
	{latitude: 39.066258, longitude: -78.463997, zipCode: "22654"},
	{latitude: 39.066335, longitude: -78.209173, zipCode: "22655"},
	{latitude: 39.219483, longitude: -78.100685, zipCode: "22656"},
	{latitude: 39.001087, longitude: -78.370608, zipCode: "22657"},
	{latitude: 38.954941, longitude: -78.440236, zipCode: "22660"},
	{latitude: 39.052545, longitude: -78.11232, zipCode: "22663"},
	{latitude: 38.888919, longitude: -78.532453, zipCode: "22664"},
	{latitude: 38.426381, longitude: -78.010127, zipCode: "22701"},
	{latitude: 38.328731, longitude: -78.213737, zipCode: "22709"},
	{latitude: 38.464504, longitude: -78.278863, zipCode: "22711"},
	{latitude: 38.554149, longitude: -77.751298, zipCode: "22712"},
	{latitude: 38.533494, longitude: -78.135946, zipCode: "22713"},
	{latitude: 38.510253, longitude: -77.890506, zipCode: "22714"},
	{latitude: 38.413954, longitude: -78.184695, zipCode: "22715"},
	{latitude: 38.62999, longitude: -78.103016, zipCode: "22716"},
	{latitude: 38.459898, longitude: -77.827949, zipCode: "22718"},
	{latitude: 38.52823, longitude: -78.281341, zipCode: "22719"},
	{latitude: 38.480496, longitude: -77.648214, zipCode: "22720"},
	{latitude: 38.46625, longitude: -78.227059, zipCode: "22722"},
	{latitude: 38.334605, longitude: -78.380361, zipCode: "22723"},
	{latitude: 38.631729, longitude: -77.903995, zipCode: "22724"},
	{latitude: 38.44051, longitude: -78.156075, zipCode: "22725"},
	{latitude: 38.3982, longitude: -77.816535, zipCode: "22726"},
	{latitude: 38.407594, longitude: -78.295292, zipCode: "22727"},
	{latitude: 38.575006, longitude: -77.680261, zipCode: "22728"},
	{latitude: 38.352682, longitude: -78.017859, zipCode: "22729"},
	{latitude: 38.362889, longitude: -78.16386, zipCode: "22730"},
	{latitude: 38.344129, longitude: -78.261453, zipCode: "22731"},
	{latitude: 38.308877, longitude: -78.19153, zipCode: "22732"},
	{latitude: 38.321109, longitude: -78.03975, zipCode: "22733"},
	{latitude: 38.528534, longitude: -77.809735, zipCode: "22734"},
	{latitude: 38.480865, longitude: -78.184506, zipCode: "22735"},
	{latitude: 38.398297, longitude: -77.694463, zipCode: "22736"},
	{latitude: 38.59205, longitude: -78.001812, zipCode: "22737"},
	{latitude: 38.285571, longitude: -78.288404, zipCode: "22738"},
	{latitude: 38.64569, longitude: -78.286953, zipCode: "22740"},
	{latitude: 38.449608, longitude: -77.863964, zipCode: "22741"},
	{latitude: 38.464605, longitude: -77.708333, zipCode: "22742"},
	{latitude: 38.54004, longitude: -78.381412, zipCode: "22743"},
	{latitude: 38.626432, longitude: -78.021061, zipCode: "22746"},
	{latitude: 38.709056, longitude: -78.165674, zipCode: "22747"},
	{latitude: 38.619494, longitude: -78.184253, zipCode: "22749"},
	{latitude: 38.405147, longitude: -78.880723, zipCode: "22801"},
	{latitude: 38.494489, longitude: -78.86316, zipCode: "22802"},
	{latitude: 38.43459, longitude: -78.86887, zipCode: "22807"},
	{latitude: 38.833949, longitude: -78.801308, zipCode: "22810"},
	{latitude: 38.811097, longitude: -78.991521, zipCode: "22811"},
	{latitude: 38.378077, longitude: -79.02717, zipCode: "22812"},
	{latitude: 38.712509, longitude: -78.841209, zipCode: "22815"},
	{latitude: 38.744829, longitude: -78.983243, zipCode: "22820"},
	{latitude: 38.48053, longitude: -79.128405, zipCode: "22821"},
	{latitude: 38.846712, longitude: -78.628319, zipCode: "22824"},
	{latitude: 38.392549, longitude: -78.614901, zipCode: "22827"},
	{latitude: 38.667244, longitude: -78.975023, zipCode: "22830"},
	{latitude: 38.559898, longitude: -79.122141, zipCode: "22831"},
	{latitude: 38.458216, longitude: -78.75725, zipCode: "22832"},
	{latitude: 38.559622, longitude: -78.854678, zipCode: "22834"},
	{latitude: 38.670431, longitude: -78.455301, zipCode: "22835"},
	{latitude: 38.392398, longitude: -78.744139, zipCode: "22840"},
	{latitude: 38.341793, longitude: -78.892489, zipCode: "22841"},
	{latitude: 38.778048, longitude: -78.699661, zipCode: "22842"},
	{latitude: 38.368428, longitude: -79.146789, zipCode: "22843"},
	{latitude: 38.661561, longitude: -78.658119, zipCode: "22844"},
	{latitude: 38.776665, longitude: -78.845149, zipCode: "22845"},
	{latitude: 38.384894, longitude: -78.796195, zipCode: "22846"},
	{latitude: 38.717338, longitude: -78.714964, zipCode: "22847"},
	{latitude: 38.525324, longitude: -78.629627, zipCode: "22849"},
	{latitude: 38.564704, longitude: -78.917823, zipCode: "22850"},
	{latitude: 38.54379, longitude: -78.503015, zipCode: "22851"},
	{latitude: 38.651705, longitude: -78.758708, zipCode: "22853"},
	{latitude: 38.087705, longitude: -78.552744, zipCode: "22901"},
	{latitude: 37.910276, longitude: -78.52212, zipCode: "22902"},
	{latitude: 38.006972, longitude: -78.59506, zipCode: "22903"},
	{latitude: 38.035436, longitude: -78.511249, zipCode: "22904"},
	{latitude: 38.030979, longitude: -78.496585, zipCode: "22908"},
	{latitude: 38.097005, longitude: -78.410147, zipCode: "22911"},
	{latitude: 37.968714, longitude: -78.805539, zipCode: "22920"},
	{latitude: 37.685755, longitude: -78.921503, zipCode: "22922"},
	{latitude: 38.189313, longitude: -78.31668, zipCode: "22923"},
	{latitude: 37.898931, longitude: -78.727429, zipCode: "22931"},
	{latitude: 38.147507, longitude: -78.696458, zipCode: "22932"},
	{latitude: 38.253962, longitude: -78.562656, zipCode: "22935"},
	{latitude: 38.159998, longitude: -78.499128, zipCode: "22936"},
	{latitude: 37.814467, longitude: -78.612075, zipCode: "22937"},
	{latitude: 37.860653, longitude: -78.775381, zipCode: "22938"},
	{latitude: 38.103454, longitude: -78.971468, zipCode: "22939"},
	{latitude: 38.210538, longitude: -78.594617, zipCode: "22940"},
	{latitude: 38.101443, longitude: -78.202129, zipCode: "22942"},
	{latitude: 38.040171, longitude: -78.778343, zipCode: "22943"},
	{latitude: 37.847883, longitude: -78.563525, zipCode: "22946"},
	{latitude: 38.043989, longitude: -78.324721, zipCode: "22947"},
	{latitude: 38.363165, longitude: -78.128968, zipCode: "22948"},
	{latitude: 37.789079, longitude: -78.88344, zipCode: "22949"},
	{latitude: 37.934219, longitude: -79.014248, zipCode: "22952"},
	{latitude: 38.229674, longitude: -78.191677, zipCode: "22957"},
	{latitude: 37.892769, longitude: -78.894292, zipCode: "22958"},
	{latitude: 37.937283, longitude: -78.656583, zipCode: "22959"},
	{latitude: 38.219521, longitude: -78.06326, zipCode: "22960"},
	{latitude: 37.846661, longitude: -78.293974, zipCode: "22963"},
	{latitude: 37.723829, longitude: -78.991072, zipCode: "22964"},
	{latitude: 37.797305, longitude: -79.014082, zipCode: "22967"},
	{latitude: 38.241722, longitude: -78.393753, zipCode: "22968"},
	{latitude: 37.7975, longitude: -78.693372, zipCode: "22969"},
	{latitude: 37.747785, longitude: -78.800276, zipCode: "22971"},
	{latitude: 38.217008, longitude: -78.233485, zipCode: "22972"},
	{latitude: 38.335462, longitude: -78.467868, zipCode: "22973"},
	{latitude: 37.966327, longitude: -78.267358, zipCode: "22974"},
	{latitude: 37.848859, longitude: -79.045839, zipCode: "22976"},
	{latitude: 38.099837, longitude: -78.88055, zipCode: "22980"},
	{latitude: 38.29321, longitude: -78.11901, zipCode: "22989"},
	{latitude: 37.349684, longitude: -77.959361, zipCode: "23002"},
	{latitude: 37.688288, longitude: -78.399202, zipCode: "23004"},
	{latitude: 37.760037, longitude: -77.48366, zipCode: "23005"},
	{latitude: 37.811987, longitude: -77.175001, zipCode: "23009"},
	{latitude: 37.477354, longitude: -76.812452, zipCode: "23011"},
	{latitude: 37.929642, longitude: -77.642802, zipCode: "23015"},
	{latitude: 37.400964, longitude: -76.371839, zipCode: "23021"},
	{latitude: 37.737052, longitude: -78.26416, zipCode: "23022"},
	{latitude: 37.768958, longitude: -76.963729, zipCode: "23023"},
	{latitude: 37.927752, longitude: -77.779274, zipCode: "23024"},
	{latitude: 37.41516, longitude: -76.362416, zipCode: "23025"},
	{latitude: 37.646476, longitude: -78.130174, zipCode: "23027"},
	{latitude: 37.345958, longitude: -77.055314, zipCode: "23030"},
	{latitude: 37.655493, longitude: -76.681949, zipCode: "23032"},
	{latitude: 37.50014, longitude: -76.382103, zipCode: "23035"},
	{latitude: 37.753464, longitude: -78.148789, zipCode: "23038"},
	{latitude: 37.65288, longitude: -77.801578, zipCode: "23039"},
	{latitude: 37.520189, longitude: -78.252836, zipCode: "23040"},
	{latitude: 37.557588, longitude: -76.336434, zipCode: "23043"},
	{latitude: 37.425348, longitude: -76.268808, zipCode: "23045"},
	{latitude: 37.846207, longitude: -77.486531, zipCode: "23047"},
	{latitude: 37.487752, longitude: -76.419298, zipCode: "23050"},
	{latitude: 37.768214, longitude: -78.217011, zipCode: "23055"},
	{latitude: 37.394328, longitude: -76.383867, zipCode: "23056"},
	{latitude: 37.702252, longitude: -77.573995, zipCode: "23059"},
	{latitude: 37.659664, longitude: -77.533827, zipCode: "23060"},
	{latitude: 37.427731, longitude: -76.537584, zipCode: "23061"},
	{latitude: 37.262931, longitude: -76.507489, zipCode: "23062"},
	{latitude: 37.705919, longitude: -77.980663, zipCode: "23063"},
	{latitude: 37.805959, longitude: -77.932428, zipCode: "23065"},
	{latitude: 37.500146, longitude: -76.292613, zipCode: "23066"},
	{latitude: 37.48976, longitude: -76.333362, zipCode: "23068"},
	{latitude: 37.782686, longitude: -77.343002, zipCode: "23069"},
	{latitude: 37.55205, longitude: -76.383517, zipCode: "23070"},
	{latitude: 37.545377, longitude: -76.448792, zipCode: "23071"},
	{latitude: 37.290444, longitude: -76.44779, zipCode: "23072"},
	{latitude: 37.558215, longitude: -77.316877, zipCode: "23075"},
	{latitude: 37.472417, longitude: -76.306619, zipCode: "23076"},
	{latitude: 37.740275, longitude: -76.677511, zipCode: "23079"},
	{latitude: 37.19357, longitude: -76.752508, zipCode: "23081"},
	{latitude: 37.310661, longitude: -78.104834, zipCode: "23083"},
	{latitude: 37.884931, longitude: -78.115047, zipCode: "23084"},
	{latitude: 37.688954, longitude: -76.825645, zipCode: "23085"},
	{latitude: 37.668573, longitude: -77.054414, zipCode: "23086"},
	{latitude: 37.477156, longitude: -76.914756, zipCode: "23089"},
	{latitude: 37.656264, longitude: -76.802492, zipCode: "23091"},
	{latitude: 37.606769, longitude: -76.514748, zipCode: "23092"},
	{latitude: 38.004167, longitude: -78.040057, zipCode: "23093"},
	{latitude: 37.708119, longitude: -77.828654, zipCode: "23102"},
	{latitude: 37.648034, longitude: -77.72364, zipCode: "23103"},
	{latitude: 37.715627, longitude: -77.20717, zipCode: "23106"},
	{latitude: 37.656133, longitude: -76.720486, zipCode: "23108"},
	{latitude: 37.432779, longitude: -76.325576, zipCode: "23109"},
	{latitude: 37.570081, longitude: -76.770193, zipCode: "23110"},
	{latitude: 37.613551, longitude: -77.243019, zipCode: "23111"},
	{latitude: 37.432553, longitude: -77.655051, zipCode: "23112"},
	{latitude: 37.539809, longitude: -77.679743, zipCode: "23113"},
	{latitude: 37.48301, longitude: -77.660433, zipCode: "23114"},
	{latitude: 37.664436, longitude: -77.325493, zipCode: "23116"},
	{latitude: 37.981999, longitude: -77.873976, zipCode: "23117"},
	{latitude: 37.443765, longitude: -76.276751, zipCode: "23119"},
	{latitude: 37.42208, longitude: -77.78137, zipCode: "23120"},
	{latitude: 37.651834, longitude: -78.301142, zipCode: "23123"},
	{latitude: 37.55095, longitude: -77.044042, zipCode: "23124"},
	{latitude: 37.923461, longitude: -77.144316, zipCode: "23126"},
	{latitude: 37.447198, longitude: -76.429276, zipCode: "23128"},
	{latitude: 37.703624, longitude: -77.777648, zipCode: "23129"},
	{latitude: 37.387286, longitude: -76.259439, zipCode: "23130"},
	{latitude: 37.342651, longitude: -76.304324, zipCode: "23138"},
	{latitude: 37.545988, longitude: -77.936728, zipCode: "23139"},
	{latitude: 37.438755, longitude: -77.049272, zipCode: "23140"},
	{latitude: 37.535229, longitude: -77.15726, zipCode: "23141"},
	{latitude: 37.723428, longitude: -77.710398, zipCode: "23146"},
	{latitude: 37.369636, longitude: -77.039114, zipCode: "23147"},
	{latitude: 37.839647, longitude: -77.058668, zipCode: "23148"},
	{latitude: 37.575671, longitude: -76.609283, zipCode: "23149"},
	{latitude: 37.501141, longitude: -77.252581, zipCode: "23150"},
	{latitude: 37.779727, longitude: -77.955874, zipCode: "23153"},
	{latitude: 37.51922, longitude: -76.726031, zipCode: "23156"},
	{latitude: 37.629084, longitude: -77.840754, zipCode: "23160"},
	{latitude: 37.715706, longitude: -76.935229, zipCode: "23161"},
	{latitude: 37.357073, longitude: -76.297334, zipCode: "23163"},
	{latitude: 37.395757, longitude: -76.82511, zipCode: "23168"},
	{latitude: 37.603451, longitude: -76.457239, zipCode: "23169"},
	{latitude: 37.575763, longitude: -77.541537, zipCode: "23173"},
	{latitude: 37.654869, longitude: -76.624357, zipCode: "23175"},
	{latitude: 37.572941, longitude: -76.414976, zipCode: "23176"},
	{latitude: 37.729243, longitude: -77.006032, zipCode: "23177"},
	{latitude: 37.702428, longitude: -76.615265, zipCode: "23180"},
	{latitude: 37.597491, longitude: -76.88906, zipCode: "23181"},
	{latitude: 37.239658, longitude: -76.701436, zipCode: "23185"},
	{latitude: 37.341605, longitude: -76.747782, zipCode: "23188"},
	{latitude: 37.820363, longitude: -77.680814, zipCode: "23192"},
	{latitude: 37.540604, longitude: -77.434733, zipCode: "23219"},
	{latitude: 37.549349, longitude: -77.460606, zipCode: "23220"},
	{latitude: 37.549995, longitude: -77.490978, zipCode: "23221"},
	{latitude: 37.582968, longitude: -77.418907, zipCode: "23222"},
	{latitude: 37.557767, longitude: -77.378886, zipCode: "23223"},
	{latitude: 37.497603, longitude: -77.467429, zipCode: "23224"},
	{latitude: 37.519727, longitude: -77.510561, zipCode: "23225"},
	{latitude: 37.579356, longitude: -77.519937, zipCode: "23226"},
	{latitude: 37.611812, longitude: -77.435864, zipCode: "23227"},
	{latitude: 37.626617, longitude: -77.492834, zipCode: "23228"},
	{latitude: 37.588045, longitude: -77.570196, zipCode: "23229"},
	{latitude: 37.586639, longitude: -77.489122, zipCode: "23230"},
	{latitude: 37.442204, longitude: -77.314931, zipCode: "23231"},
	{latitude: 37.643812, longitude: -77.629395, zipCode: "23233"},
	{latitude: 37.452821, longitude: -77.466503, zipCode: "23234"},
	{latitude: 37.51457, longitude: -77.564758, zipCode: "23235"},
	{latitude: 37.47581, longitude: -77.585285, zipCode: "23236"},
	{latitude: 37.400683, longitude: -77.456626, zipCode: "23237"},
	{latitude: 37.592514, longitude: -77.641686, zipCode: "23238"},
	{latitude: 37.50466, longitude: -77.32086, zipCode: "23250"},
	{latitude: 37.549028, longitude: -77.451696, zipCode: "23284"},
	{latitude: 37.631281, longitude: -77.542534, zipCode: "23294"},
	{latitude: 37.541707, longitude: -77.42953, zipCode: "23298"},
	{latitude: 37.669851, longitude: -75.643661, zipCode: "23301"},
	{latitude: 37.854188, longitude: -75.517623, zipCode: "23302"},
	{latitude: 37.903366, longitude: -75.506367, zipCode: "23303"},
	{latitude: 36.996135, longitude: -76.572107, zipCode: "23304"},
	{latitude: 37.566478, longitude: -75.885459, zipCode: "23306"},
	{latitude: 37.432039, longitude: -75.871157, zipCode: "23307"},
	{latitude: 37.827651, longitude: -75.632377, zipCode: "23308"},
	{latitude: 37.258993, longitude: -75.963657, zipCode: "23310"},
	{latitude: 36.959049, longitude: -76.52428, zipCode: "23314"},
	{latitude: 36.73739, longitude: -76.840511, zipCode: "23315"},
	{latitude: 37.294354, longitude: -75.965519, zipCode: "23316"},
	{latitude: 36.751734, longitude: -76.217637, zipCode: "23320"},
	{latitude: 36.800398, longitude: -76.421152, zipCode: "23321"},
	{latitude: 36.625988, longitude: -76.267768, zipCode: "23322"},
	{latitude: 36.696975, longitude: -76.379925, zipCode: "23323"},
	{latitude: 36.799721, longitude: -76.273447, zipCode: "23324"},
	{latitude: 36.813625, longitude: -76.239857, zipCode: "23325"},
	{latitude: 37.949432, longitude: -75.338084, zipCode: "23336"},
	{latitude: 37.940106, longitude: -75.466025, zipCode: "23337"},
	{latitude: 37.356586, longitude: -75.978263, zipCode: "23347"},
	{latitude: 37.512705, longitude: -75.862593, zipCode: "23350"},
	{latitude: 37.479183, longitude: -75.902456, zipCode: "23354"},
	{latitude: 38.010003, longitude: -75.385368, zipCode: "23356"},
	{latitude: 37.755646, longitude: -75.676028, zipCode: "23357"},
	{latitude: 37.652659, longitude: -75.867953, zipCode: "23358"},
	{latitude: 37.887733, longitude: -75.603949, zipCode: "23359"},
	{latitude: 37.655265, longitude: -75.833086, zipCode: "23389"},
	{latitude: 37.969673, longitude: -75.448236, zipCode: "23395"},
	{latitude: 37.520559, longitude: -75.939732, zipCode: "23398"},
	{latitude: 37.627758, longitude: -75.778349, zipCode: "23401"},
	{latitude: 37.411724, longitude: -75.926597, zipCode: "23405"},
	{latitude: 37.847103, longitude: -75.554449, zipCode: "23407"},
	{latitude: 37.452913, longitude: -75.843474, zipCode: "23408"},
	{latitude: 37.887621, longitude: -75.662907, zipCode: "23409"},
	{latitude: 37.638996, longitude: -75.745065, zipCode: "23410"},
	{latitude: 37.46237, longitude: -75.8495, zipCode: "23413"},
	{latitude: 37.813754, longitude: -75.58691, zipCode: "23414"},
	{latitude: 37.975153, longitude: -75.535603, zipCode: "23415"},
	{latitude: 37.937326, longitude: -75.561481, zipCode: "23416"},
	{latitude: 37.732134, longitude: -75.761995, zipCode: "23417"},
	{latitude: 37.641378, longitude: -75.668394, zipCode: "23418"},
	{latitude: 37.59907, longitude: -75.817482, zipCode: "23420"},
	{latitude: 37.76478, longitude: -75.611768, zipCode: "23421"},
	{latitude: 37.635389, longitude: -75.822735, zipCode: "23422"},
	{latitude: 37.516077, longitude: -75.741043, zipCode: "23423"},
	{latitude: 37.925149, longitude: -75.6918, zipCode: "23426"},
	{latitude: 37.899775, longitude: -75.718243, zipCode: "23427"},
	{latitude: 36.999354, longitude: -76.660764, zipCode: "23430"},
	{latitude: 36.861812, longitude: -76.55156, zipCode: "23432"},
	{latitude: 36.916127, longitude: -76.484469, zipCode: "23433"},
	{latitude: 36.703003, longitude: -76.592105, zipCode: "23434"},
	{latitude: 36.848602, longitude: -76.472899, zipCode: "23435"},
	{latitude: 36.893756, longitude: -76.506917, zipCode: "23436"},
	{latitude: 36.627115, longitude: -76.803197, zipCode: "23437"},
	{latitude: 36.581192, longitude: -76.697189, zipCode: "23438"},
	{latitude: 37.825183, longitude: -75.99022, zipCode: "23440"},
	{latitude: 37.712102, longitude: -75.699413, zipCode: "23441"},
	{latitude: 37.894214, longitude: -75.559926, zipCode: "23442"},
	{latitude: 36.872358, longitude: -76.004319, zipCode: "23451"},
	{latitude: 36.845812, longitude: -76.091964, zipCode: "23452"},
	{latitude: 36.78349, longitude: -76.071207, zipCode: "23453"},
	{latitude: 36.864599, longitude: -76.055124, zipCode: "23454"},
	{latitude: 36.883814, longitude: -76.143237, zipCode: "23455"},
	{latitude: 36.736954, longitude: -76.035987, zipCode: "23456"},
	{latitude: 36.617383, longitude: -76.015339, zipCode: "23457"},
	{latitude: 36.91681, longitude: -76.171748, zipCode: "23459"},
	{latitude: 36.818488, longitude: -76.0316, zipCode: "23460"},
	{latitude: 36.770602, longitude: -75.963772, zipCode: "23461"},
	{latitude: 36.836242, longitude: -76.145948, zipCode: "23462"},
	{latitude: 36.796917, longitude: -76.187631, zipCode: "23464"},
	{latitude: 37.618681, longitude: -75.690659, zipCode: "23480"},
	{latitude: 37.517888, longitude: -75.806853, zipCode: "23486"},
	{latitude: 36.847368, longitude: -76.725048, zipCode: "23487"},
	{latitude: 37.947613, longitude: -75.598459, zipCode: "23488"},
	{latitude: 36.860941, longitude: -76.204908, zipCode: "23502"},
	{latitude: 36.948786, longitude: -76.266459, zipCode: "23503"},
	{latitude: 36.857302, longitude: -76.265612, zipCode: "23504"},
	{latitude: 36.912882, longitude: -76.299596, zipCode: "23505"},
	{latitude: 36.864564, longitude: -76.303218, zipCode: "23507"},
	{latitude: 36.884888, longitude: -76.31027, zipCode: "23508"},
	{latitude: 36.882413, longitude: -76.26396, zipCode: "23509"},
	{latitude: 36.852414, longitude: -76.29148, zipCode: "23510"},
	{latitude: 36.93724, longitude: -76.309215, zipCode: "23511"},
	{latitude: 36.889005, longitude: -76.237684, zipCode: "23513"},
	{latitude: 36.869238, longitude: -76.292055, zipCode: "23517"},
	{latitude: 36.916118, longitude: -76.215375, zipCode: "23518"},
	{latitude: 36.832389, longitude: -76.272082, zipCode: "23523"},
	{latitude: 36.923536, longitude: -76.292547, zipCode: "23551"},
	{latitude: 37.037855, longitude: -76.479809, zipCode: "23601"},
	{latitude: 37.113416, longitude: -76.517947, zipCode: "23602"},
	{latitude: 37.191057, longitude: -76.564535, zipCode: "23603"},
	{latitude: 37.119148, longitude: -76.593183, zipCode: "23604"},
	{latitude: 37.01867, longitude: -76.436702, zipCode: "23605"},
	{latitude: 37.065696, longitude: -76.516665, zipCode: "23606"},
	{latitude: 36.970515, longitude: -76.424339, zipCode: "23607"},
	{latitude: 37.147813, longitude: -76.543036, zipCode: "23608"},
	{latitude: 37.001772, longitude: -76.309871, zipCode: "23651"},
	{latitude: 37.000868, longitude: -76.369988, zipCode: "23661"},
	{latitude: 37.133319, longitude: -76.353027, zipCode: "23662"},
	{latitude: 37.015912, longitude: -76.322526, zipCode: "23663"},
	{latitude: 37.073116, longitude: -76.295078, zipCode: "23664"},
	{latitude: 37.083918, longitude: -76.360125, zipCode: "23665"},
	{latitude: 37.057009, longitude: -76.408083, zipCode: "23666"},
	{latitude: 37.02201, longitude: -76.329688, zipCode: "23667"},
	{latitude: 37.020264, longitude: -76.338549, zipCode: "23668"},
	{latitude: 37.050708, longitude: -76.339274, zipCode: "23669"},
	{latitude: 37.096813, longitude: -76.383535, zipCode: "23681"},
	{latitude: 37.220286, longitude: -76.518336, zipCode: "23690"},
	{latitude: 37.259622, longitude: -76.572884, zipCode: "23691"},
	{latitude: 37.212226, longitude: -76.421272, zipCode: "23692"},
	{latitude: 37.131179, longitude: -76.444819, zipCode: "23693"},
	{latitude: 37.189119, longitude: -76.421079, zipCode: "23696"},
	{latitude: 36.811975, longitude: -76.369378, zipCode: "23701"},
	{latitude: 36.803271, longitude: -76.326721, zipCode: "23702"},
	{latitude: 36.893339, longitude: -76.373292, zipCode: "23703"},
	{latitude: 36.826697, longitude: -76.312032, zipCode: "23704"},
	{latitude: 36.841794, longitude: -76.340331, zipCode: "23707"},
	{latitude: 36.847681, longitude: -76.304081, zipCode: "23708"},
	{latitude: 36.817203, longitude: -76.298856, zipCode: "23709"},
	{latitude: 37.23613, longitude: -77.335804, zipCode: "23801"},
	{latitude: 37.215079, longitude: -77.470382, zipCode: "23803"},
	{latitude: 37.132177, longitude: -77.39424, zipCode: "23805"},
	{latitude: 36.8832, longitude: -77.912944, zipCode: "23821"},
	{latitude: 37.088778, longitude: -77.958041, zipCode: "23824"},
	{latitude: 36.619049, longitude: -77.200186, zipCode: "23827"},
	{latitude: 36.580854, longitude: -77.266933, zipCode: "23828"},
	{latitude: 36.727211, longitude: -77.228224, zipCode: "23829"},
	{latitude: 37.024337, longitude: -77.397215, zipCode: "23830"},
	{latitude: 37.346253, longitude: -77.450566, zipCode: "23831"},
	{latitude: 37.38785, longitude: -77.592108, zipCode: "23832"},
	{latitude: 37.209429, longitude: -77.668917, zipCode: "23833"},
	{latitude: 37.290074, longitude: -77.40078, zipCode: "23834"},
	{latitude: 37.350997, longitude: -77.330501, zipCode: "23836"},
	{latitude: 36.757091, longitude: -77.09002, zipCode: "23837"},
	{latitude: 37.317516, longitude: -77.633869, zipCode: "23838"},
	{latitude: 37.085921, longitude: -76.918457, zipCode: "23839"},
	{latitude: 37.051048, longitude: -77.671109, zipCode: "23840"},
	{latitude: 37.061934, longitude: -77.550208, zipCode: "23841"},
	{latitude: 37.13718, longitude: -77.217028, zipCode: "23842"},
	{latitude: 36.850366, longitude: -77.811813, zipCode: "23843"},
	{latitude: 36.680032, longitude: -77.326525, zipCode: "23844"},
	{latitude: 36.578823, longitude: -77.977561, zipCode: "23845"},
	{latitude: 37.066603, longitude: -76.83402, zipCode: "23846"},
	{latitude: 36.664183, longitude: -77.55164, zipCode: "23847"},
	{latitude: 37.163011, longitude: -77.742547, zipCode: "23850"},
	{latitude: 36.652023, longitude: -76.957141, zipCode: "23851"},
	{latitude: 36.794321, longitude: -77.703117, zipCode: "23856"},
	{latitude: 36.584651, longitude: -77.883799, zipCode: "23857"},
	{latitude: 37.274749, longitude: -77.227407, zipCode: "23860"},
	{latitude: 36.923676, longitude: -76.862401, zipCode: "23866"},
	{latitude: 36.820372, longitude: -77.488594, zipCode: "23867"},
	{latitude: 36.709281, longitude: -77.795078, zipCode: "23868"},
	{latitude: 36.752475, longitude: -77.500167, zipCode: "23870"},
	{latitude: 36.982977, longitude: -77.726757, zipCode: "23872"},
	{latitude: 36.584733, longitude: -77.070769, zipCode: "23874"},
	{latitude: 37.229942, longitude: -77.255727, zipCode: "23875"},
	{latitude: 36.955753, longitude: -77.83361, zipCode: "23876"},
	{latitude: 36.824772, longitude: -77.025432, zipCode: "23878"},
	{latitude: 36.592275, longitude: -77.596839, zipCode: "23879"},
	{latitude: 37.180741, longitude: -76.977829, zipCode: "23881"},
	{latitude: 36.926173, longitude: -77.421687, zipCode: "23882"},
	{latitude: 37.135962, longitude: -76.735892, zipCode: "23883"},
	{latitude: 36.917319, longitude: -77.280089, zipCode: "23884"},
	{latitude: 37.178849, longitude: -77.572911, zipCode: "23885"},
	{latitude: 36.573362, longitude: -77.816917, zipCode: "23887"},
	{latitude: 36.9543, longitude: -76.979206, zipCode: "23888"},
	{latitude: 36.902933, longitude: -77.747246, zipCode: "23889"},
	{latitude: 37.009176, longitude: -77.118319, zipCode: "23890"},
	{latitude: 37.047636, longitude: -77.203719, zipCode: "23891"},
	{latitude: 36.624444, longitude: -77.936561, zipCode: "23893"},
	{latitude: 37.137093, longitude: -77.824648, zipCode: "23894"},
	{latitude: 36.83731, longitude: -77.284758, zipCode: "23897"},
	{latitude: 36.838263, longitude: -76.86187, zipCode: "23898"},
	{latitude: 37.234417, longitude: -76.974083, zipCode: "23899"},
	{latitude: 37.320056, longitude: -78.409678, zipCode: "23901"},
	{latitude: 37.298993, longitude: -78.396307, zipCode: "23909"},
	{latitude: 36.69314, longitude: -78.287418, zipCode: "23915"},
	{latitude: 36.625554, longitude: -78.344856, zipCode: "23917"},
	{latitude: 36.580366, longitude: -78.134851, zipCode: "23919"},
	{latitude: 36.724517, longitude: -77.975253, zipCode: "23920"},
	{latitude: 37.586321, longitude: -78.623535, zipCode: "23921"},
	{latitude: 37.174829, longitude: -78.221738, zipCode: "23922"},
	{latitude: 37.087148, longitude: -78.649692, zipCode: "23923"},
	{latitude: 36.822186, longitude: -78.425844, zipCode: "23924"},
	{latitude: 36.591659, longitude: -78.561035, zipCode: "23927"},
	{latitude: 37.171003, longitude: -78.099611, zipCode: "23930"},
	{latitude: 37.177152, longitude: -78.626705, zipCode: "23934"},
	{latitude: 37.524599, longitude: -78.476522, zipCode: "23936"},
	{latitude: 36.945141, longitude: -78.512503, zipCode: "23937"},
	{latitude: 36.904221, longitude: -78.006223, zipCode: "23938"},
	{latitude: 37.129727, longitude: -78.298581, zipCode: "23942"},
	{latitude: 37.24321, longitude: -78.462039, zipCode: "23943"},
	{latitude: 36.915939, longitude: -78.138611, zipCode: "23944"},
	{latitude: 37.017152, longitude: -78.447748, zipCode: "23947"},
	{latitude: 36.658762, longitude: -78.075874, zipCode: "23950"},
	{latitude: 36.917525, longitude: -78.279219, zipCode: "23952"},
	{latitude: 37.106333, longitude: -78.380108, zipCode: "23954"},
	{latitude: 37.112362, longitude: -78.069873, zipCode: "23955"},
	{latitude: 37.273961, longitude: -78.656941, zipCode: "23958"},
	{latitude: 37.105192, longitude: -78.794749, zipCode: "23959"},
	{latitude: 37.320962, longitude: -78.552064, zipCode: "23960"},
	{latitude: 36.950633, longitude: -78.692389, zipCode: "23962"},
	{latitude: 37.200973, longitude: -78.798547, zipCode: "23963"},
	{latitude: 36.782735, longitude: -78.633141, zipCode: "23964"},
	{latitude: 37.300262, longitude: -78.259116, zipCode: "23966"},
	{latitude: 36.922423, longitude: -78.630037, zipCode: "23967"},
	{latitude: 36.716868, longitude: -78.510232, zipCode: "23968"},
	{latitude: 36.73911, longitude: -78.182448, zipCode: "23970"},
	{latitude: 36.969138, longitude: -78.242447, zipCode: "23974"},
	{latitude: 36.855538, longitude: -78.5791, zipCode: "23976"},
	{latitude: 37.270198, longitude: -79.941523, zipCode: "24011"},
	{latitude: 37.31765, longitude: -79.896769, zipCode: "24012"},
	{latitude: 37.267389, longitude: -79.921908, zipCode: "24013"},
	{latitude: 37.22286, longitude: -79.915191, zipCode: "24014"},
	{latitude: 37.255203, longitude: -79.982124, zipCode: "24015"},
	{latitude: 37.272053, longitude: -79.954991, zipCode: "24016"},
	{latitude: 37.297642, longitude: -79.990973, zipCode: "24017"},
	{latitude: 37.213055, longitude: -80.041739, zipCode: "24018"},
	{latitude: 37.343945, longitude: -79.954546, zipCode: "24019"},
	{latitude: 37.35612, longitude: -79.943194, zipCode: "24020"},
	{latitude: 37.276862, longitude: -79.934277, zipCode: "24022"},
	{latitude: 36.607227, longitude: -80.534076, zipCode: "24053"},
	{latitude: 36.682046, longitude: -79.704568, zipCode: "24054"},
	{latitude: 36.762073, longitude: -80.000609, zipCode: "24055"},
	{latitude: 37.176663, longitude: -80.622252, zipCode: "24058"},
	{latitude: 37.155906, longitude: -80.131958, zipCode: "24059"},
	{latitude: 37.260526, longitude: -80.416722, zipCode: "24060"},
	{latitude: 37.22582, longitude: -80.426238, zipCode: "24061"},
	{latitude: 37.376311, longitude: -79.770395, zipCode: "24064"},
	{latitude: 37.117118, longitude: -79.997108, zipCode: "24065"},
	{latitude: 37.536095, longitude: -79.678578, zipCode: "24066"},
	{latitude: 37.032354, longitude: -80.06032, zipCode: "24067"},
	{latitude: 36.576893, longitude: -79.642569, zipCode: "24069"},
	{latitude: 37.372087, longitude: -80.232822, zipCode: "24070"},
	{latitude: 37.04199, longitude: -80.242381, zipCode: "24072"},
	{latitude: 37.13465, longitude: -80.423065, zipCode: "24073"},
	{latitude: 36.605192, longitude: -80.421039, zipCode: "24076"},
	{latitude: 37.36106, longitude: -79.907203, zipCode: "24077"},
	{latitude: 36.724265, longitude: -79.913147, zipCode: "24078"},
	{latitude: 37.053355, longitude: -80.150755, zipCode: "24079"},
	{latitude: 36.633271, longitude: -80.131234, zipCode: "24082"},
	{latitude: 37.405171, longitude: -79.920188, zipCode: "24083"},
	{latitude: 37.167585, longitude: -80.717608, zipCode: "24084"},
	{latitude: 37.68393, longitude: -79.857319, zipCode: "24085"},
	{latitude: 37.27558, longitude: -80.632246, zipCode: "24086"},
	{latitude: 37.221511, longitude: -80.23893, zipCode: "24087"},
	{latitude: 36.887904, longitude: -80.077927, zipCode: "24088"},
	{latitude: 36.703913, longitude: -79.960775, zipCode: "24089"},
	{latitude: 37.530182, longitude: -79.895504, zipCode: "24090"},
	{latitude: 36.919838, longitude: -80.321295, zipCode: "24091"},
	{latitude: 37.012031, longitude: -79.757851, zipCode: "24092"},
	{latitude: 37.385139, longitude: -80.868737, zipCode: "24093"},
	{latitude: 37.22321, longitude: -79.737268, zipCode: "24095"},
	{latitude: 37.173555, longitude: -79.796387, zipCode: "24101"},
	{latitude: 36.844404, longitude: -80.002271, zipCode: "24102"},
	{latitude: 37.133611, longitude: -79.488635, zipCode: "24104"},
	{latitude: 36.911577, longitude: -80.603687, zipCode: "24105"},
	{latitude: 36.72117, longitude: -79.846927, zipCode: "24112"},
	{latitude: 36.691636, longitude: -80.413917, zipCode: "24120"},
	{latitude: 37.168916, longitude: -79.647411, zipCode: "24121"},
	{latitude: 37.426197, longitude: -79.69213, zipCode: "24122"},
	{latitude: 37.305928, longitude: -80.866008, zipCode: "24124"},
	{latitude: 37.473603, longitude: -80.231051, zipCode: "24127"},
	{latitude: 37.339994, longitude: -80.482544, zipCode: "24128"},
	{latitude: 37.139573, longitude: -80.591735, zipCode: "24129"},
	{latitude: 37.570649, longitude: -80.23287, zipCode: "24131"},
	{latitude: 37.203776, longitude: -80.659247, zipCode: "24132"},
	{latitude: 36.675633, longitude: -80.127964, zipCode: "24133"},
	{latitude: 37.247923, longitude: -80.777561, zipCode: "24134"},
	{latitude: 37.316097, longitude: -80.612791, zipCode: "24136"},
	{latitude: 36.927552, longitude: -79.657504, zipCode: "24137"},
	{latitude: 37.051815, longitude: -80.31735, zipCode: "24138"},
	{latitude: 37.014888, longitude: -79.452893, zipCode: "24139"},
	{latitude: 37.107159, longitude: -80.561888, zipCode: "24141"},
	{latitude: 37.138226, longitude: -80.550786, zipCode: "24142"},
	{latitude: 37.40151, longitude: -80.823626, zipCode: "24147"},
	{latitude: 36.581493, longitude: -79.876803, zipCode: "24148"},
	{latitude: 37.018582, longitude: -80.433581, zipCode: "24149"},
	{latitude: 37.401049, longitude: -80.598275, zipCode: "24150"},
	{latitude: 36.961444, longitude: -79.866431, zipCode: "24151"},
	{latitude: 37.302506, longitude: -80.114325, zipCode: "24153"},
	{latitude: 36.978085, longitude: -79.529113, zipCode: "24161"},
	{latitude: 37.129449, longitude: -80.249715, zipCode: "24162"},
	{latitude: 36.594266, longitude: -80.060586, zipCode: "24165"},
	{latitude: 37.255259, longitude: -80.724536, zipCode: "24167"},
	{latitude: 36.736039, longitude: -79.948755, zipCode: "24168"},
	{latitude: 36.696639, longitude: -80.220825, zipCode: "24171"},
	{latitude: 37.34972, longitude: -79.661897, zipCode: "24174"},
	{latitude: 37.4577, longitude: -80.029375, zipCode: "24175"},
	{latitude: 37.005545, longitude: -79.692495, zipCode: "24176"},
	{latitude: 37.282958, longitude: -79.793851, zipCode: "24179"},
	{latitude: 37.087129, longitude: -79.773642, zipCode: "24184"},
	{latitude: 36.808689, longitude: -80.275573, zipCode: "24185"},
	{latitude: 36.611602, longitude: -82.168628, zipCode: "24201"},
	{latitude: 36.65631, longitude: -82.212219, zipCode: "24202"},
	{latitude: 36.768935, longitude: -82.027531, zipCode: "24210"},
	{latitude: 36.654088, longitude: -81.95507, zipCode: "24211"},
	{latitude: 36.943624, longitude: -82.782796, zipCode: "24216"},
	{latitude: 37.102112, longitude: -82.170381, zipCode: "24217"},
	{latitude: 36.83435, longitude: -82.749105, zipCode: "24219"},
	{latitude: 37.121879, longitude: -82.223096, zipCode: "24220"},
	{latitude: 36.625312, longitude: -82.99876, zipCode: "24221"},
	{latitude: 36.861431, longitude: -82.271754, zipCode: "24224"},
	{latitude: 36.991715, longitude: -82.141851, zipCode: "24225"},
	{latitude: 37.136902, longitude: -82.322989, zipCode: "24226"},
	{latitude: 37.166862, longitude: -82.456257, zipCode: "24228"},
	{latitude: 36.968669, longitude: -82.451369, zipCode: "24230"},
	{latitude: 36.638506, longitude: -81.7245, zipCode: "24236"},
	{latitude: 37.040636, longitude: -82.261885, zipCode: "24237"},
	{latitude: 37.113037, longitude: -82.147305, zipCode: "24239"},
	{latitude: 36.784268, longitude: -82.919771, zipCode: "24243"},
	{latitude: 36.707695, longitude: -82.803484, zipCode: "24244"},
	{latitude: 36.838011, longitude: -82.512802, zipCode: "24245"},
	{latitude: 36.866168, longitude: -82.743949, zipCode: "24246"},
	{latitude: 36.609636, longitude: -83.509791, zipCode: "24248"},
	{latitude: 36.756595, longitude: -82.587663, zipCode: "24250"},
	{latitude: 36.657147, longitude: -82.599414, zipCode: "24251"},
	{latitude: 37.223339, longitude: -82.283532, zipCode: "24256"},
	{latitude: 36.649232, longitude: -82.408651, zipCode: "24258"},
	{latitude: 37.021059, longitude: -82.001115, zipCode: "24260"},
	{latitude: 36.668225, longitude: -83.167128, zipCode: "24263"},
	{latitude: 36.832663, longitude: -82.946461, zipCode: "24265"},
	{latitude: 36.86802, longitude: -82.101925, zipCode: "24266"},
	{latitude: 37.072772, longitude: -82.380679, zipCode: "24269"},
	{latitude: 36.72961, longitude: -82.250673, zipCode: "24270"},
	{latitude: 36.748813, longitude: -82.417187, zipCode: "24271"},
	{latitude: 37.017789, longitude: -82.333362, zipCode: "24272"},
	{latitude: 36.964701, longitude: -82.66148, zipCode: "24273"},
	{latitude: 36.749458, longitude: -83.019532, zipCode: "24277"},
	{latitude: 37.077735, longitude: -82.627393, zipCode: "24279"},
	{latitude: 36.954813, longitude: -81.943423, zipCode: "24280"},
	{latitude: 36.637323, longitude: -83.340226, zipCode: "24281"},
	{latitude: 36.818761, longitude: -83.05431, zipCode: "24282"},
	{latitude: 36.939782, longitude: -82.332939, zipCode: "24283"},
	{latitude: 36.616105, longitude: -82.561337, zipCode: "24290"},
	{latitude: 36.624744, longitude: -81.595528, zipCode: "24292"},
	{latitude: 36.998037, longitude: -82.566165, zipCode: "24293"},
	{latitude: 37.06503, longitude: -80.818267, zipCode: "24301"},
	{latitude: 36.873143, longitude: -81.402872, zipCode: "24311"},
	{latitude: 36.833015, longitude: -80.887018, zipCode: "24312"},
	{latitude: 36.910809, longitude: -80.796966, zipCode: "24313"},
	{latitude: 37.157244, longitude: -81.210414, zipCode: "24314"},
	{latitude: 37.133631, longitude: -81.063229, zipCode: "24315"},
	{latitude: 36.955705, longitude: -81.668988, zipCode: "24316"},
	{latitude: 36.601166, longitude: -80.671347, zipCode: "24317"},
	{latitude: 36.995958, longitude: -81.387124, zipCode: "24318"},
	{latitude: 36.761741, longitude: -81.65692, zipCode: "24319"},
	{latitude: 36.799014, longitude: -81.113662, zipCode: "24322"},
	{latitude: 36.86577, longitude: -81.204727, zipCode: "24323"},
	{latitude: 36.974112, longitude: -80.776478, zipCode: "24324"},
	{latitude: 36.810406, longitude: -80.587633, zipCode: "24325"},
	{latitude: 36.722732, longitude: -81.190257, zipCode: "24326"},
	{latitude: 36.773635, longitude: -81.829717, zipCode: "24327"},
	{latitude: 36.645356, longitude: -80.700836, zipCode: "24328"},
	{latitude: 36.715854, longitude: -81.026821, zipCode: "24330"},
	{latitude: 36.639975, longitude: -80.934776, zipCode: "24333"},
	{latitude: 36.753252, longitude: -81.767904, zipCode: "24340"},
	{latitude: 36.762633, longitude: -80.698401, zipCode: "24343"},
	{latitude: 36.960871, longitude: -80.652471, zipCode: "24347"},
	{latitude: 36.652229, longitude: -81.215324, zipCode: "24348"},
	{latitude: 36.813301, longitude: -80.994182, zipCode: "24350"},
	{latitude: 36.579431, longitude: -80.765127, zipCode: "24351"},
	{latitude: 36.709011, longitude: -80.531193, zipCode: "24352"},
	{latitude: 36.821272, longitude: -81.540457, zipCode: "24354"},
	{latitude: 36.940631, longitude: -80.909054, zipCode: "24360"},
	{latitude: 36.767336, longitude: -81.84496, zipCode: "24361"},
	{latitude: 36.61663, longitude: -81.405048, zipCode: "24363"},
	{latitude: 37.264041, longitude: -81.104131, zipCode: "24366"},
	{latitude: 36.892877, longitude: -81.301358, zipCode: "24368"},
	{latitude: 36.911415, longitude: -81.689789, zipCode: "24370"},
	{latitude: 36.783782, longitude: -81.22036, zipCode: "24374"},
	{latitude: 36.771672, longitude: -81.392247, zipCode: "24375"},
	{latitude: 37.002115, longitude: -81.568654, zipCode: "24377"},
	{latitude: 36.694311, longitude: -81.419636, zipCode: "24378"},
	{latitude: 36.859701, longitude: -80.508764, zipCode: "24380"},
	{latitude: 36.722284, longitude: -80.833241, zipCode: "24381"},
	{latitude: 36.95331, longitude: -81.110754, zipCode: "24382"},
	{latitude: 38.134387, longitude: -79.085564, zipCode: "24401"},
	{latitude: 38.118186, longitude: -79.330583, zipCode: "24411"},
	{latitude: 38.053722, longitude: -79.818136, zipCode: "24412"},
	{latitude: 38.505863, longitude: -79.556099, zipCode: "24413"},
	{latitude: 37.908591, longitude: -79.307709, zipCode: "24415"},
	{latitude: 37.728717, longitude: -79.354893, zipCode: "24416"},
	{latitude: 38.244651, longitude: -79.245145, zipCode: "24421"},
	{latitude: 37.832856, longitude: -79.750441, zipCode: "24422"},
	{latitude: 37.759304, longitude: -80.049967, zipCode: "24426"},
	{latitude: 38.077977, longitude: -79.34309, zipCode: "24430"},
	{latitude: 38.170036, longitude: -78.842885, zipCode: "24431"},
	{latitude: 38.148062, longitude: -79.44336, zipCode: "24432"},
	{latitude: 38.424017, longitude: -79.424832, zipCode: "24433"},
	{latitude: 37.857657, longitude: -79.306951, zipCode: "24435"},
	{latitude: 38.21248, longitude: -78.933724, zipCode: "24437"},
	{latitude: 38.017124, longitude: -79.482011, zipCode: "24439"},
	{latitude: 37.999357, longitude: -79.165401, zipCode: "24440"},
	{latitude: 38.222233, longitude: -78.809436, zipCode: "24441"},
	{latitude: 38.37253, longitude: -79.384617, zipCode: "24442"},
	{latitude: 37.96003, longitude: -79.871803, zipCode: "24445"},
	{latitude: 37.79711, longitude: -79.788524, zipCode: "24448"},
	{latitude: 37.779751, longitude: -79.545136, zipCode: "24450"},
	{latitude: 37.795845, longitude: -79.865211, zipCode: "24457"},
	{latitude: 38.32643, longitude: -79.510303, zipCode: "24458"},
	{latitude: 38.039279, longitude: -79.279523, zipCode: "24459"},
	{latitude: 38.016947, longitude: -79.666635, zipCode: "24460"},
	{latitude: 37.860625, longitude: -79.109957, zipCode: "24464"},
	{latitude: 38.397583, longitude: -79.64689, zipCode: "24465"},
	{latitude: 38.254993, longitude: -78.971323, zipCode: "24467"},
	{latitude: 38.200975, longitude: -78.908253, zipCode: "24469"},
	{latitude: 38.31321, longitude: -78.78418, zipCode: "24471"},
	{latitude: 37.939731, longitude: -79.220606, zipCode: "24472"},
	{latitude: 37.934747, longitude: -79.415358, zipCode: "24473"},
	{latitude: 37.805299, longitude: -79.847564, zipCode: "24474"},
	{latitude: 37.974466, longitude: -79.227361, zipCode: "24476"},
	{latitude: 38.004878, longitude: -79.042232, zipCode: "24477"},
	{latitude: 38.163944, longitude: -79.239044, zipCode: "24479"},
	{latitude: 38.203307, longitude: -78.998125, zipCode: "24482"},
	{latitude: 37.812641, longitude: -79.235174, zipCode: "24483"},
	{latitude: 38.183081, longitude: -79.799492, zipCode: "24484"},
	{latitude: 38.270936, longitude: -79.32998, zipCode: "24485"},
	{latitude: 38.296885, longitude: -78.924533, zipCode: "24486"},
	{latitude: 38.199727, longitude: -79.588455, zipCode: "24487"},
	{latitude: 37.359741, longitude: -79.141272, zipCode: "24501"},
	{latitude: 37.355154, longitude: -79.21854, zipCode: "24502"},
	{latitude: 37.454259, longitude: -79.241531, zipCode: "24503"},
	{latitude: 37.365249, longitude: -79.048192, zipCode: "24504"},
	{latitude: 37.353195, longitude: -79.179018, zipCode: "24515"},
	{latitude: 37.141398, longitude: -79.237443, zipCode: "24517"},
	{latitude: 36.586589, longitude: -79.029748, zipCode: "24520"},
	{latitude: 37.633291, longitude: -79.10084, zipCode: "24521"},
	{latitude: 37.376791, longitude: -78.778773, zipCode: "24522"},
	{latitude: 37.336882, longitude: -79.525726, zipCode: "24523"},
	{latitude: 37.556164, longitude: -79.397558, zipCode: "24526"},
	{latitude: 36.732729, longitude: -79.345561, zipCode: "24527"},
	{latitude: 37.079686, longitude: -78.876229, zipCode: "24528"},
	{latitude: 36.617245, longitude: -78.629806, zipCode: "24529"},
	{latitude: 36.807067, longitude: -79.619501, zipCode: "24530"},
	{latitude: 36.840665, longitude: -79.438102, zipCode: "24531"},
	{latitude: 36.883302, longitude: -78.770379, zipCode: "24534"},
	{latitude: 37.490862, longitude: -79.32367, zipCode: "24536"},
	{latitude: 37.379013, longitude: -78.97006, zipCode: "24538"},
	{latitude: 36.840575, longitude: -78.931411, zipCode: "24539"},
	{latitude: 36.634508, longitude: -79.426758, zipCode: "24540"},
	{latitude: 36.588812, longitude: -79.515049, zipCode: "24541"},
	{latitude: 36.70666, longitude: -79.504475, zipCode: "24549"},
	{latitude: 37.238258, longitude: -79.272021, zipCode: "24550"},
	{latitude: 37.370068, longitude: -79.331364, zipCode: "24551"},
	{latitude: 37.545504, longitude: -78.81001, zipCode: "24553"},
	{latitude: 37.120045, longitude: -79.071619, zipCode: "24554"},
	{latitude: 37.666966, longitude: -79.468292, zipCode: "24555"},
	{latitude: 37.375825, longitude: -79.399305, zipCode: "24556"},
	{latitude: 36.969859, longitude: -79.306424, zipCode: "24557"},
	{latitude: 36.780645, longitude: -78.956102, zipCode: "24558"},
	{latitude: 37.703198, longitude: -78.617799, zipCode: "24562"},
	{latitude: 37.059343, longitude: -79.27262, zipCode: "24563"},
	{latitude: 36.85272, longitude: -79.189501, zipCode: "24565"},
	{latitude: 36.731828, longitude: -79.252639, zipCode: "24566"},
	{latitude: 37.042552, longitude: -79.125855, zipCode: "24569"},
	{latitude: 37.34859, longitude: -79.427169, zipCode: "24570"},
	{latitude: 37.132961, longitude: -79.372748, zipCode: "24571"},
	{latitude: 37.464909, longitude: -79.095265, zipCode: "24572"},
	{latitude: 37.576927, longitude: -79.245595, zipCode: "24574"},
	{latitude: 36.943933, longitude: -78.982674, zipCode: "24577"},
	{latitude: 37.659882, longitude: -79.56228, zipCode: "24578"},
	{latitude: 37.585803, longitude: -79.502698, zipCode: "24579"},
	{latitude: 36.579054, longitude: -78.673121, zipCode: "24580"},
	{latitude: 37.655216, longitude: -78.805765, zipCode: "24581"},
	{latitude: 36.607044, longitude: -79.276609, zipCode: "24586"},
	{latitude: 37.262152, longitude: -79.096234, zipCode: "24588"},
	{latitude: 36.757223, longitude: -78.753722, zipCode: "24589"},
	{latitude: 37.792821, longitude: -78.474856, zipCode: "24590"},
	{latitude: 36.681952, longitude: -78.9527, zipCode: "24592"},
	{latitude: 37.334225, longitude: -78.914279, zipCode: "24593"},
	{latitude: 36.635983, longitude: -79.186729, zipCode: "24594"},
	{latitude: 37.56071, longitude: -79.081021, zipCode: "24595"},
	{latitude: 36.782411, longitude: -79.110858, zipCode: "24597"},
	{latitude: 36.60467, longitude: -78.788004, zipCode: "24598"},
	{latitude: 37.623562, longitude: -78.72389, zipCode: "24599"},
	{latitude: 37.190597, longitude: -81.640566, zipCode: "24601"},
	{latitude: 37.183213, longitude: -81.65851, zipCode: "24602"},
	{latitude: 37.347416, longitude: -82.203222, zipCode: "24603"},
	{latitude: 37.211983, longitude: -81.539669, zipCode: "24604"},
	{latitude: 37.2351, longitude: -81.374905, zipCode: "24605"},
	{latitude: 37.289353, longitude: -81.391205, zipCode: "24606"},
	{latitude: 37.29548, longitude: -82.270862, zipCode: "24607"},
	{latitude: 37.016058, longitude: -81.791386, zipCode: "24609"},
	{latitude: 37.101469, longitude: -81.849825, zipCode: "24612"},
	{latitude: 37.265157, longitude: -81.336881, zipCode: "24613"},
	{latitude: 37.31307, longitude: -82.088914, zipCode: "24614"},
	{latitude: 37.431352, longitude: -82.011757, zipCode: "24620"},
	{latitude: 37.214674, longitude: -81.789853, zipCode: "24622"},
	{latitude: 37.176845, longitude: -81.475447, zipCode: "24630"},
	{latitude: 37.221713, longitude: -82.001735, zipCode: "24631"},
	{latitude: 37.267827, longitude: -81.902751, zipCode: "24634"},
	{latitude: 37.3079, longitude: -81.348777, zipCode: "24635"},
	{latitude: 37.061082, longitude: -81.713894, zipCode: "24637"},
	{latitude: 37.172836, longitude: -81.897958, zipCode: "24639"},
	{latitude: 37.111813, longitude: -81.802446, zipCode: "24641"},
	{latitude: 37.144821, longitude: -82.024209, zipCode: "24646"},
	{latitude: 37.090347, longitude: -81.921695, zipCode: "24649"},
	{latitude: 37.075529, longitude: -81.503095, zipCode: "24651"},
	{latitude: 37.175055, longitude: -82.124994, zipCode: "24656"},
	{latitude: 37.227814, longitude: -81.848776, zipCode: "24657"},
	{latitude: 37.305951, longitude: -81.204941, zipCode: "24701"},
	{latitude: 37.479645, longitude: -81.034708, zipCode: "24712"},
	{latitude: 37.46865, longitude: -81.178346, zipCode: "24714"},
	{latitude: 37.335227, longitude: -81.333435, zipCode: "24715"},
	{latitude: 37.533692, longitude: -81.376265, zipCode: "24716"},
	{latitude: 37.487241, longitude: -81.326586, zipCode: "24719"},
	{latitude: 37.337525, longitude: -81.317972, zipCode: "24724"},
	{latitude: 37.487564, longitude: -81.29968, zipCode: "24726"},
	{latitude: 37.449885, longitude: -81.260028, zipCode: "24729"},
	{latitude: 37.397354, longitude: -81.151631, zipCode: "24731"},
	{latitude: 37.450148, longitude: -81.201096, zipCode: "24733"},
	{latitude: 37.446778, longitude: -81.281225, zipCode: "24736"},
	{latitude: 37.352962, longitude: -81.26579, zipCode: "24737"},
	{latitude: 37.303215, longitude: -81.3201, zipCode: "24738"},
	{latitude: 37.373457, longitude: -81.004791, zipCode: "24739"},
	{latitude: 37.403448, longitude: -81.106456, zipCode: "24740"},
	{latitude: 37.411496, longitude: -81.21655, zipCode: "24747"},
	{latitude: 37.468587, longitude: -81.553126, zipCode: "24801"},
	{latitude: 37.330026, longitude: -81.422403, zipCode: "24808"},
	{latitude: 37.402181, longitude: -81.748496, zipCode: "24811"},
	{latitude: 37.357412, longitude: -81.720113, zipCode: "24813"},
	{latitude: 37.220042, longitude: -81.616211, zipCode: "24815"},
	{latitude: 37.461102, longitude: -81.705939, zipCode: "24816"},
	{latitude: 37.341159, longitude: -81.821588, zipCode: "24817"},
	{latitude: 37.624704, longitude: -81.620327, zipCode: "24818"},
	{latitude: 37.647027, longitude: -81.697268, zipCode: "24822"},
	{latitude: 37.67197, longitude: -81.751948, zipCode: "24823"},
	{latitude: 37.293267, longitude: -81.623592, zipCode: "24826"},
	{latitude: 37.768854, longitude: -81.636893, zipCode: "24827"},
	{latitude: 37.497807, longitude: -81.671316, zipCode: "24828"},
	{latitude: 37.333875, longitude: -81.558614, zipCode: "24830"},
	{latitude: 37.388942, longitude: -81.451138, zipCode: "24831"},
	{latitude: 37.523608, longitude: -81.686328, zipCode: "24834"},
	{latitude: 37.381162, longitude: -81.551174, zipCode: "24836"},
	{latitude: 37.578406, longitude: -81.757328, zipCode: "24839"},
	{latitude: 37.483784, longitude: -81.709229, zipCode: "24843"},
	{latitude: 37.489556, longitude: -81.792555, zipCode: "24844"},
	{latitude: 37.526411, longitude: -81.800383, zipCode: "24845"},
	{latitude: 37.525781, longitude: -81.886606, zipCode: "24846"},
	{latitude: 37.570719, longitude: -81.409406, zipCode: "24847"},
	{latitude: 37.280144, longitude: -81.434355, zipCode: "24848"},
	{latitude: 37.686953, longitude: -81.561622, zipCode: "24849"},
	{latitude: 37.378059, longitude: -81.885155, zipCode: "24850"},
	{latitude: 37.601371, longitude: -81.833685, zipCode: "24851"},
	{latitude: 37.448145, longitude: -81.530203, zipCode: "24853"},
	{latitude: 37.749562, longitude: -81.576507, zipCode: "24854"},
	{latitude: 37.408711, longitude: -81.425011, zipCode: "24855"},
	{latitude: 37.687392, longitude: -81.675978, zipCode: "24857"},
	{latitude: 37.662564, longitude: -81.589795, zipCode: "24860"},
	{latitude: 37.357282, longitude: -81.357337, zipCode: "24861"},
	{latitude: 37.478806, longitude: -81.963644, zipCode: "24862"},
	{latitude: 37.25006, longitude: -81.580509, zipCode: "24866"},
	{latitude: 37.605306, longitude: -81.457338, zipCode: "24867"},
	{latitude: 37.43845, longitude: -81.389063, zipCode: "24868"},
	{latitude: 37.553376, longitude: -81.824438, zipCode: "24869"},
	{latitude: 37.736479, longitude: -81.532083, zipCode: "24870"},
	{latitude: 37.323125, longitude: -81.506122, zipCode: "24871"},
	{latitude: 37.449367, longitude: -81.9205, zipCode: "24872"},
	{latitude: 37.351462, longitude: -81.880066, zipCode: "24873"},
	{latitude: 37.601764, longitude: -81.530968, zipCode: "24874"},
	{latitude: 37.439362, longitude: -81.625418, zipCode: "24878"},
	{latitude: 37.311992, longitude: -81.767655, zipCode: "24879"},
	{latitude: 37.649635, longitude: -81.532011, zipCode: "24880"},
	{latitude: 37.442844, longitude: -81.669945, zipCode: "24881"},
	{latitude: 37.625693, longitude: -81.765994, zipCode: "24882"},
	{latitude: 37.27312, longitude: -81.551387, zipCode: "24884"},
	{latitude: 37.378041, longitude: -81.386601, zipCode: "24887"},
	{latitude: 37.357632, longitude: -81.483351, zipCode: "24888"},
	{latitude: 37.316739, longitude: -81.696824, zipCode: "24892"},
	{latitude: 37.244303, longitude: -81.688138, zipCode: "24894"},
	{latitude: 37.3763, longitude: -81.582936, zipCode: "24895"},
	{latitude: 37.587407, longitude: -81.568403, zipCode: "24898"},
	{latitude: 37.853926, longitude: -80.484563, zipCode: "24901"},
	{latitude: 37.742663, longitude: -80.668419, zipCode: "24910"},
	{latitude: 38.469293, longitude: -79.786674, zipCode: "24915"},
	{latitude: 37.828615, longitude: -80.580988, zipCode: "24916"},
	{latitude: 37.52231, longitude: -80.745495, zipCode: "24918"},
	{latitude: 38.632836, longitude: -79.696155, zipCode: "24920"},
	{latitude: 38.201301, longitude: -80.164874, zipCode: "24924"},
	{latitude: 37.730837, longitude: -80.379891, zipCode: "24925"},
	{latitude: 38.340242, longitude: -80.037582, zipCode: "24927"},
	{latitude: 37.911583, longitude: -80.599696, zipCode: "24931"},
	{latitude: 38.333625, longitude: -79.891045, zipCode: "24934"},
	{latitude: 37.566111, longitude: -80.80565, zipCode: "24935"},
	{latitude: 37.932796, longitude: -80.370385, zipCode: "24938"},
	{latitude: 37.586293, longitude: -80.351035, zipCode: "24941"},
	{latitude: 37.838254, longitude: -80.733835, zipCode: "24943"},
	{latitude: 38.384639, longitude: -79.769328, zipCode: "24944"},
	{latitude: 37.516448, longitude: -80.70316, zipCode: "24945"},
	{latitude: 38.228574, longitude: -80.319783, zipCode: "24946"},
	{latitude: 37.49523, longitude: -80.624125, zipCode: "24951"},
	{latitude: 38.224907, longitude: -80.056049, zipCode: "24954"},
	{latitude: 37.896072, longitude: -80.396796, zipCode: "24957"},
	{latitude: 37.662404, longitude: -80.718357, zipCode: "24962"},
	{latitude: 37.436403, longitude: -80.758472, zipCode: "24963"},
	{latitude: 38.056828, longitude: -80.385133, zipCode: "24966"},
	{latitude: 37.728112, longitude: -80.474462, zipCode: "24970"},
	{latitude: 37.641769, longitude: -80.448356, zipCode: "24974"},
	{latitude: 37.656959, longitude: -80.51171, zipCode: "24976"},
	{latitude: 37.897887, longitude: -80.685023, zipCode: "24977"},
	{latitude: 37.632868, longitude: -80.735662, zipCode: "24981"},
	{latitude: 37.596051, longitude: -80.506452, zipCode: "24983"},
	{latitude: 37.493887, longitude: -80.407698, zipCode: "24984"},
	{latitude: 37.604171, longitude: -80.712621, zipCode: "24985"},
	{latitude: 37.92109, longitude: -80.145606, zipCode: "24986"},
	{latitude: 38.001294, longitude: -80.480567, zipCode: "24991"},
	{latitude: 37.660986, longitude: -80.635179, zipCode: "24993"},
	{latitude: 38.130879, longitude: -81.254724, zipCode: "25002"},
	{latitude: 38.248491, longitude: -81.784858, zipCode: "25003"},
	{latitude: 38.606199, longitude: -81.257079, zipCode: "25005"},
	{latitude: 37.82484, longitude: -81.430458, zipCode: "25007"},
	{latitude: 37.948787, longitude: -81.344599, zipCode: "25008"},
	{latitude: 38.202305, longitude: -81.684573, zipCode: "25009"},
	{latitude: 38.514838, longitude: -81.832739, zipCode: "25011"},
	{latitude: 38.246134, longitude: -81.497258, zipCode: "25015"},
	{latitude: 38.350663, longitude: -81.092215, zipCode: "25019"},
	{latitude: 37.917841, longitude: -81.683934, zipCode: "25021"},
	{latitude: 37.824584, longitude: -81.776158, zipCode: "25022"},
	{latitude: 38.159081, longitude: -81.623722, zipCode: "25024"},
	{latitude: 38.298414, longitude: -81.403141, zipCode: "25025"},
	{latitude: 37.947147, longitude: -81.725572, zipCode: "25028"},
	{latitude: 38.453317, longitude: -81.225847, zipCode: "25030"},
	{latitude: 38.148749, longitude: -81.27478, zipCode: "25031"},
	{latitude: 38.612061, longitude: -81.924382, zipCode: "25033"},
	{latitude: 38.165239, longitude: -81.52099, zipCode: "25035"},
	{latitude: 38.182341, longitude: -81.271114, zipCode: "25036"},
	{latitude: 38.234956, longitude: -81.363261, zipCode: "25039"},
	{latitude: 38.136354, longitude: -81.247095, zipCode: "25040"},
	{latitude: 38.425757, longitude: -81.003154, zipCode: "25043"},
	{latitude: 37.915805, longitude: -81.309364, zipCode: "25044"},
	{latitude: 38.447483, longitude: -81.329322, zipCode: "25045"},
	{latitude: 37.920527, longitude: -81.767625, zipCode: "25047"},
	{latitude: 37.947329, longitude: -81.430769, zipCode: "25048"},
	{latitude: 38.115286, longitude: -81.558258, zipCode: "25049"},
	{latitude: 38.156947, longitude: -81.709791, zipCode: "25051"},
	{latitude: 38.041333, longitude: -81.88156, zipCode: "25053"},
	{latitude: 38.120523, longitude: -81.451884, zipCode: "25054"},
	{latitude: 38.109263, longitude: -81.223428, zipCode: "25057"},
	{latitude: 38.244588, longitude: -81.223691, zipCode: "25059"},
	{latitude: 37.966455, longitude: -81.473963, zipCode: "25060"},
	{latitude: 38.174553, longitude: -81.439835, zipCode: "25061"},
	{latitude: 37.879656, longitude: -81.433199, zipCode: "25062"},
	{latitude: 38.576127, longitude: -80.922962, zipCode: "25063"},
	{latitude: 38.377039, longitude: -81.749365, zipCode: "25064"},
	{latitude: 38.213406, longitude: -81.444998, zipCode: "25067"},
	{latitude: 38.545893, longitude: -81.931647, zipCode: "25070"},
	{latitude: 38.477798, longitude: -81.466553, zipCode: "25071"},
	{latitude: 38.060148, longitude: -81.405123, zipCode: "25075"},
	{latitude: 37.868918, longitude: -81.937017, zipCode: "25076"},
	{latitude: 38.078076, longitude: -81.768414, zipCode: "25081"},
	{latitude: 38.604207, longitude: -82.031043, zipCode: "25082"},
	{latitude: 38.069872, longitude: -81.367702, zipCode: "25083"},
	{latitude: 38.161716, longitude: -81.163262, zipCode: "25085"},
	{latitude: 38.218276, longitude: -81.414583, zipCode: "25086"},
	{latitude: 38.398602, longitude: -81.208823, zipCode: "25088"},
	{latitude: 38.15499, longitude: -81.221063, zipCode: "25090"},
	{latitude: 37.951253, longitude: -81.632674, zipCode: "25093"},
	{latitude: 38.165711, longitude: -81.352056, zipCode: "25102"},
	{latitude: 38.180605, longitude: -81.380862, zipCode: "25103"},
	{latitude: 38.766038, longitude: -82.084836, zipCode: "25106"},
	{latitude: 38.237957, longitude: -81.676172, zipCode: "25107"},
	{latitude: 37.962356, longitude: -81.878069, zipCode: "25108"},
	{latitude: 38.531302, longitude: -81.860557, zipCode: "25109"},
	{latitude: 38.205084, longitude: -81.337228, zipCode: "25110"},
	{latitude: 38.350171, longitude: -81.123351, zipCode: "25111"},
	{latitude: 38.381037, longitude: -81.766295, zipCode: "25112"},
	{latitude: 38.553289, longitude: -81.046273, zipCode: "25113"},
	{latitude: 37.987318, longitude: -81.786336, zipCode: "25114"},
	{latitude: 38.122179, longitude: -81.182241, zipCode: "25115"},
	{latitude: 38.118868, longitude: -81.322966, zipCode: "25118"},
	{latitude: 38.014973, longitude: -81.262872, zipCode: "25119"},
	{latitude: 37.910177, longitude: -81.886743, zipCode: "25121"},
	{latitude: 38.730175, longitude: -81.900044, zipCode: "25123"},
	{latitude: 38.621093, longitude: -81.764505, zipCode: "25124"},
	{latitude: 38.311679, longitude: -81.21523, zipCode: "25125"},
	{latitude: 38.190554, longitude: -81.357264, zipCode: "25126"},
	{latitude: 38.032993, longitude: -81.752257, zipCode: "25130"},
	{latitude: 38.293275, longitude: -81.334405, zipCode: "25132"},
	{latitude: 38.474596, longitude: -81.101457, zipCode: "25133"},
	{latitude: 38.143244, longitude: -81.473035, zipCode: "25134"},
	{latitude: 38.154438, longitude: -81.314794, zipCode: "25136"},
	{latitude: 38.105991, longitude: -81.282831, zipCode: "25139"},
	{latitude: 37.839893, longitude: -81.498515, zipCode: "25140"},
	{latitude: 38.630175, longitude: -81.024705, zipCode: "25141"},
	{latitude: 38.143056, longitude: -81.737664, zipCode: "25142"},
	{latitude: 38.421336, longitude: -81.823619, zipCode: "25143"},
	{latitude: 38.025489, longitude: -81.525735, zipCode: "25148"},
	{latitude: 37.949155, longitude: -81.771997, zipCode: "25149"},
	{latitude: 38.03274, longitude: -81.24036, zipCode: "25152"},
	{latitude: 38.120576, longitude: -81.713325, zipCode: "25154"},
	{latitude: 38.411803, longitude: -81.482194, zipCode: "25156"},
	{latitude: 38.523611, longitude: -81.788899, zipCode: "25159"},
	{latitude: 38.324269, longitude: -81.268444, zipCode: "25160"},
	{latitude: 38.052784, longitude: -81.318726, zipCode: "25161"},
	{latitude: 38.207656, longitude: -81.382999, zipCode: "25162"},
	{latitude: 38.509184, longitude: -81.204946, zipCode: "25164"},
	{latitude: 38.155709, longitude: -81.653541, zipCode: "25165"},
	{latitude: 38.560349, longitude: -81.87985, zipCode: "25168"},
	{latitude: 38.129249, longitude: -81.763459, zipCode: "25169"},
	{latitude: 38.073781, longitude: -81.21041, zipCode: "25173"},
	{latitude: 37.865661, longitude: -81.410962, zipCode: "25174"},
	{latitude: 38.369399, longitude: -81.850811, zipCode: "25177"},
	{latitude: 37.79026, longitude: -81.436267, zipCode: "25180"},
	{latitude: 38.04929, longitude: -81.6412, zipCode: "25181"},
	{latitude: 37.891759, longitude: -81.799522, zipCode: "25183"},
	{latitude: 38.217189, longitude: -81.238013, zipCode: "25185"},
	{latitude: 38.173963, longitude: -81.29751, zipCode: "25186"},
	{latitude: 38.731665, longitude: -82.014332, zipCode: "25187"},
	{latitude: 38.016778, longitude: -81.550923, zipCode: "25193"},
	{latitude: 38.347703, longitude: -81.491336, zipCode: "25201"},
	{latitude: 38.316375, longitude: -81.859088, zipCode: "25202"},
	{latitude: 38.021238, longitude: -81.881629, zipCode: "25203"},
	{latitude: 37.927373, longitude: -81.638962, zipCode: "25204"},
	{latitude: 38.019214, longitude: -81.793394, zipCode: "25205"},
	{latitude: 37.956911, longitude: -81.682919, zipCode: "25206"},
	{latitude: 37.836513, longitude: -81.639765, zipCode: "25208"},
	{latitude: 37.959553, longitude: -81.522269, zipCode: "25209"},
	{latitude: 38.46869, longitude: -80.88841, zipCode: "25211"},
	{latitude: 38.512665, longitude: -81.913304, zipCode: "25213"},
	{latitude: 38.185826, longitude: -81.542914, zipCode: "25214"},
	{latitude: 38.613149, longitude: -81.594671, zipCode: "25231"},
	{latitude: 38.808318, longitude: -81.133343, zipCode: "25234"},
	{latitude: 38.669527, longitude: -81.0895, zipCode: "25235"},
	{latitude: 38.850285, longitude: -81.84485, zipCode: "25239"},
	{latitude: 38.775273, longitude: -81.800166, zipCode: "25241"},
	{latitude: 38.676181, longitude: -81.493644, zipCode: "25243"},
	{latitude: 38.763279, longitude: -81.539024, zipCode: "25244"},
	{latitude: 38.708806, longitude: -81.736288, zipCode: "25245"},
	{latitude: 39.001989, longitude: -81.989654, zipCode: "25247"},
	{latitude: 38.644268, longitude: -81.613862, zipCode: "25248"},
	{latitude: 38.621193, longitude: -81.231754, zipCode: "25251"},
	{latitude: 38.878578, longitude: -81.534861, zipCode: "25252"},
	{latitude: 38.921064, longitude: -81.96149, zipCode: "25253"},
	{latitude: 38.674671, longitude: -81.248355, zipCode: "25259"},
	{latitude: 39.021012, longitude: -82.019094, zipCode: "25260"},
	{latitude: 38.83338, longitude: -81.090148, zipCode: "25261"},
	{latitude: 38.909198, longitude: -81.836263, zipCode: "25262"},
	{latitude: 38.859864, longitude: -81.893884, zipCode: "25264"},
	{latitude: 38.973645, longitude: -81.982558, zipCode: "25265"},
	{latitude: 38.602239, longitude: -81.158898, zipCode: "25266"},
	{latitude: 38.85751, longitude: -80.951487, zipCode: "25267"},
	{latitude: 38.731224, longitude: -81.123509, zipCode: "25268"},
	{latitude: 38.877167, longitude: -81.457837, zipCode: "25270"},
	{latitude: 38.78851, longitude: -81.692539, zipCode: "25271"},
	{latitude: 38.926731, longitude: -81.619021, zipCode: "25275"},
	{latitude: 38.78208, longitude: -81.328692, zipCode: "25276"},
	{latitude: 38.539656, longitude: -81.101556, zipCode: "25285"},
	{latitude: 38.620979, longitude: -81.397287, zipCode: "25286"},
	{latitude: 38.960863, longitude: -82.051333, zipCode: "25287"},
	{latitude: 38.351327, longitude: -81.627994, zipCode: "25301"},
	{latitude: 38.393184, longitude: -81.59547, zipCode: "25302"},
	{latitude: 38.359561, longitude: -81.68613, zipCode: "25303"},
	{latitude: 38.301561, longitude: -81.596701, zipCode: "25304"},
	{latitude: 38.336819, longitude: -81.613139, zipCode: "25305"},
	{latitude: 38.312523, longitude: -81.49944, zipCode: "25306"},
	{latitude: 38.311899, longitude: -81.75717, zipCode: "25309"},
	{latitude: 38.366863, longitude: -81.557035, zipCode: "25311"},
	{latitude: 38.452523, longitude: -81.645726, zipCode: "25312"},
	{latitude: 38.408669, longitude: -81.743527, zipCode: "25313"},
	{latitude: 38.289161, longitude: -81.660153, zipCode: "25314"},
	{latitude: 38.21493, longitude: -81.584148, zipCode: "25315"},
	{latitude: 38.539791, longitude: -81.625733, zipCode: "25320"},
	{latitude: 38.384701, longitude: -81.670603, zipCode: "25387"},
	{latitude: 39.457168, longitude: -77.972428, zipCode: "25401"},
	{latitude: 39.47485, longitude: -78.01164, zipCode: "25403"},
	{latitude: 39.489919, longitude: -77.896363, zipCode: "25404"},
	{latitude: 39.40892, longitude: -77.962369, zipCode: "25405"},
	{latitude: 39.548748, longitude: -78.22147, zipCode: "25411"},
	{latitude: 39.310588, longitude: -78.055948, zipCode: "25413"},
	{latitude: 39.241794, longitude: -77.864849, zipCode: "25414"},
	{latitude: 39.58287, longitude: -77.885069, zipCode: "25419"},
	{latitude: 39.380952, longitude: -78.119679, zipCode: "25420"},
	{latitude: 39.378211, longitude: -78.163825, zipCode: "25421"},
	{latitude: 39.544988, longitude: -78.354289, zipCode: "25422"},
	{latitude: 39.306662, longitude: -77.773034, zipCode: "25425"},
	{latitude: 39.520419, longitude: -78.099121, zipCode: "25427"},
	{latitude: 39.380635, longitude: -78.02221, zipCode: "25428"},
	{latitude: 39.327184, longitude: -77.937916, zipCode: "25430"},
	{latitude: 39.483762, longitude: -78.574312, zipCode: "25431"},
	{latitude: 39.295447, longitude: -77.786803, zipCode: "25432"},
	{latitude: 39.439747, longitude: -78.461107, zipCode: "25434"},
	{latitude: 39.423666, longitude: -78.579298, zipCode: "25437"},
	{latitude: 39.323441, longitude: -77.864008, zipCode: "25438"},
	{latitude: 39.372847, longitude: -77.832749, zipCode: "25442"},
	{latitude: 39.438227, longitude: -77.814691, zipCode: "25443"},
	{latitude: 39.430835, longitude: -78.513474, zipCode: "25444"},
	{latitude: 39.240166, longitude: -77.957047, zipCode: "25446"},
	{latitude: 38.161533, longitude: -81.957254, zipCode: "25501"},
	{latitude: 38.665771, longitude: -82.116359, zipCode: "25502"},
	{latitude: 38.608945, longitude: -82.115017, zipCode: "25503"},
	{latitude: 38.37643, longitude: -82.270031, zipCode: "25504"},
	{latitude: 38.019684, longitude: -82.030755, zipCode: "25505"},
	{latitude: 38.209278, longitude: -82.198907, zipCode: "25506"},
	{latitude: 38.397073, longitude: -82.54935, zipCode: "25507"},
	{latitude: 37.962192, longitude: -82.006933, zipCode: "25508"},
	{latitude: 38.382296, longitude: -82.082999, zipCode: "25510"},
	{latitude: 38.051218, longitude: -82.33503, zipCode: "25511"},
	{latitude: 38.178424, longitude: -82.304109, zipCode: "25512"},
	{latitude: 38.07779, longitude: -82.525248, zipCode: "25514"},
	{latitude: 38.756623, longitude: -82.146875, zipCode: "25515"},
	{latitude: 38.109821, longitude: -82.453679, zipCode: "25517"},
	{latitude: 38.557515, longitude: -82.177897, zipCode: "25520"},
	{latitude: 38.226926, longitude: -81.982429, zipCode: "25521"},
	{latitude: 38.280064, longitude: -82.065304, zipCode: "25523"},
	{latitude: 38.021624, longitude: -82.117351, zipCode: "25524"},
	{latitude: 38.394449, longitude: -81.991307, zipCode: "25526"},
	{latitude: 38.159811, longitude: -81.818707, zipCode: "25529"},
	{latitude: 38.357487, longitude: -82.579994, zipCode: "25530"},
	{latitude: 38.08441, longitude: -82.275024, zipCode: "25534"},
	{latitude: 38.308556, longitude: -82.434844, zipCode: "25535"},
	{latitude: 38.533383, longitude: -82.281971, zipCode: "25537"},
	{latitude: 38.157054, longitude: -82.139109, zipCode: "25540"},
	{latitude: 38.480587, longitude: -82.130164, zipCode: "25541"},
	{latitude: 38.230777, longitude: -82.110192, zipCode: "25544"},
	{latitude: 38.459122, longitude: -82.227154, zipCode: "25545"},
	{latitude: 37.919787, longitude: -81.94458, zipCode: "25547"},
	{latitude: 38.863326, longitude: -82.067437, zipCode: "25550"},
	{latitude: 38.213895, longitude: -82.549634, zipCode: "25555"},
	{latitude: 38.100424, longitude: -82.153689, zipCode: "25557"},
	{latitude: 38.324948, longitude: -82.229139, zipCode: "25559"},
	{latitude: 38.446838, longitude: -81.898852, zipCode: "25560"},
	{latitude: 38.272173, longitude: -81.900988, zipCode: "25564"},
	{latitude: 38.121388, longitude: -81.973032, zipCode: "25565"},
	{latitude: 38.208254, longitude: -81.894447, zipCode: "25567"},
	{latitude: 38.227092, longitude: -82.402183, zipCode: "25570"},
	{latitude: 38.313487, longitude: -82.155816, zipCode: "25571"},
	{latitude: 38.232184, longitude: -81.941102, zipCode: "25573"},
	{latitude: 37.862762, longitude: -82.009263, zipCode: "25601"},
	{latitude: 37.764964, longitude: -81.820417, zipCode: "25606"},
	{latitude: 37.783698, longitude: -81.771867, zipCode: "25607"},
	{latitude: 37.575067, longitude: -81.89162, zipCode: "25608"},
	{latitude: 37.685046, longitude: -81.871695, zipCode: "25611"},
	{latitude: 37.763573, longitude: -81.979043, zipCode: "25612"},
	{latitude: 37.741457, longitude: -81.807343, zipCode: "25617"},
	{latitude: 37.649915, longitude: -81.88558, zipCode: "25621"},
	{latitude: 37.899691, longitude: -81.981116, zipCode: "25624"},
	{latitude: 37.819366, longitude: -82.081222, zipCode: "25625"},
	{latitude: 37.768114, longitude: -81.862466, zipCode: "25628"},
	{latitude: 37.78837, longitude: -81.693967, zipCode: "25630"},
	{latitude: 37.742027, longitude: -81.947597, zipCode: "25632"},
	{latitude: 37.727581, longitude: -81.81829, zipCode: "25634"},
	{latitude: 37.718937, longitude: -81.872401, zipCode: "25635"},
	{latitude: 37.851838, longitude: -82.026753, zipCode: "25637"},
	{latitude: 37.737547, longitude: -82.054367, zipCode: "25638"},
	{latitude: 37.881407, longitude: -81.955067, zipCode: "25639"},
	{latitude: 37.704742, longitude: -82.022096, zipCode: "25644"},
	{latitude: 37.847377, longitude: -81.884159, zipCode: "25646"},
	{latitude: 37.78681, longitude: -81.984432, zipCode: "25647"},
	{latitude: 37.875404, longitude: -82.09308, zipCode: "25649"},
	{latitude: 37.683569, longitude: -81.787746, zipCode: "25650"},
	{latitude: 37.585894, longitude: -81.956801, zipCode: "25651"},
	{latitude: 37.790733, longitude: -82.045124, zipCode: "25652"},
	{latitude: 37.823634, longitude: -81.991248, zipCode: "25653"},
	{latitude: 37.795626, longitude: -81.873719, zipCode: "25654"},
	{latitude: 37.710519, longitude: -82.253717, zipCode: "25661"},
	{latitude: 37.920311, longitude: -82.294292, zipCode: "25666"},
	{latitude: 37.706189, longitude: -82.273768, zipCode: "25667"},
	{latitude: 37.935498, longitude: -82.468453, zipCode: "25669"},
	{latitude: 37.738474, longitude: -82.1559, zipCode: "25670"},
	{latitude: 37.904067, longitude: -82.202798, zipCode: "25671"},
	{latitude: 37.570103, longitude: -82.100397, zipCode: "25672"},
	{latitude: 37.871079, longitude: -82.346703, zipCode: "25674"},
	{latitude: 37.837733, longitude: -82.193056, zipCode: "25676"},
	{latitude: 37.611952, longitude: -82.135327, zipCode: "25678"},
	{latitude: 37.795802, longitude: -82.314334, zipCode: "25685"},
	{latitude: 37.639548, longitude: -82.15821, zipCode: "25688"},
	{latitude: 37.691251, longitude: -82.155024, zipCode: "25690"},
	{latitude: 37.618039, longitude: -82.073753, zipCode: "25692"},
	{latitude: 37.6629, longitude: -82.055459, zipCode: "25696"},
	{latitude: 37.95875, longitude: -82.304028, zipCode: "25699"},
	{latitude: 38.365689, longitude: -82.40525, zipCode: "25701"},
	{latitude: 38.434723, longitude: -82.314416, zipCode: "25702"},
	{latitude: 38.422076, longitude: -82.418206, zipCode: "25703"},
	{latitude: 38.30761, longitude: -82.487363, zipCode: "25704"},
	{latitude: 38.406345, longitude: -82.362542, zipCode: "25705"},
	{latitude: 38.423592, longitude: -82.427923, zipCode: "25755"},
	{latitude: 37.840601, longitude: -81.270723, zipCode: "25801"},
	{latitude: 37.583494, longitude: -81.349947, zipCode: "25810"},
	{latitude: 37.590131, longitude: -81.29126, zipCode: "25811"},
	{latitude: 38.138983, longitude: -81.1262, zipCode: "25812"},
	{latitude: 37.784227, longitude: -81.099625, zipCode: "25813"},
	{latitude: 37.764458, longitude: -81.424855, zipCode: "25817"},
	{latitude: 37.86028, longitude: -81.191992, zipCode: "25818"},
	{latitude: 37.51519, longitude: -81.163849, zipCode: "25820"},
	{latitude: 37.666336, longitude: -81.182804, zipCode: "25823"},
	{latitude: 37.644279, longitude: -81.082973, zipCode: "25825"},
	{latitude: 37.573029, longitude: -81.370193, zipCode: "25826"},
	{latitude: 37.739101, longitude: -81.254768, zipCode: "25827"},
	{latitude: 37.959313, longitude: -80.930791, zipCode: "25831"},
	{latitude: 37.715324, longitude: -81.118819, zipCode: "25832"},
	{latitude: 37.785563, longitude: -81.28784, zipCode: "25836"},
	{latitude: 38.058226, longitude: -81.038259, zipCode: "25837"},
	{latitude: 37.778207, longitude: -81.387207, zipCode: "25839"},
	{latitude: 38.037842, longitude: -81.111641, zipCode: "25840"},
	{latitude: 37.560789, longitude: -81.092546, zipCode: "25841"},
	{latitude: 37.623873, longitude: -81.129885, zipCode: "25843"},
	{latitude: 37.807973, longitude: -81.358069, zipCode: "25844"},
	{latitude: 37.714972, longitude: -81.510784, zipCode: "25845"},
	{latitude: 37.926114, longitude: -81.168206, zipCode: "25846"},
	{latitude: 37.72151, longitude: -81.424697, zipCode: "25848"},
	{latitude: 37.731726, longitude: -81.278007, zipCode: "25849"},
	{latitude: 37.80255, longitude: -81.259402, zipCode: "25851"},
	{latitude: 37.644491, longitude: -81.282312, zipCode: "25853"},
	{latitude: 38.127547, longitude: -80.976434, zipCode: "25854"},
	{latitude: 37.940492, longitude: -81.153049, zipCode: "25855"},
	{latitude: 37.609736, longitude: -81.237553, zipCode: "25857"},
	{latitude: 38.088168, longitude: -81.040489, zipCode: "25862"},
	{latitude: 37.885488, longitude: -81.03777, zipCode: "25864"},
	{latitude: 37.71154, longitude: -81.362406, zipCode: "25865"},
	{latitude: 38.007772, longitude: -81.16148, zipCode: "25866"},
	{latitude: 38.070497, longitude: -80.967248, zipCode: "25868"},
	{latitude: 37.63824, longitude: -81.37296, zipCode: "25870"},
	{latitude: 37.76917, longitude: -81.211735, zipCode: "25871"},
	{latitude: 37.750066, longitude: -81.213823, zipCode: "25873"},
	{latitude: 37.665585, longitude: -81.451237, zipCode: "25875"},
	{latitude: 37.631101, longitude: -81.449139, zipCode: "25876"},
	{latitude: 37.705199, longitude: -81.222051, zipCode: "25878"},
	{latitude: 37.98226, longitude: -81.105668, zipCode: "25879"},
	{latitude: 37.879494, longitude: -81.199471, zipCode: "25880"},
	{latitude: 37.612277, longitude: -81.403666, zipCode: "25882"},
	{latitude: 37.939872, longitude: -81.102848, zipCode: "25901"},
	{latitude: 37.569827, longitude: -81.217459, zipCode: "25902"},
	{latitude: 37.915556, longitude: -81.245817, zipCode: "25904"},
	{latitude: 37.856018, longitude: -81.086217, zipCode: "25907"},
	{latitude: 37.667767, longitude: -81.244349, zipCode: "25908"},
	{latitude: 37.838952, longitude: -81.202842, zipCode: "25909"},
	{latitude: 37.751761, longitude: -81.180434, zipCode: "25911"},
	{latitude: 37.707827, longitude: -81.472973, zipCode: "25913"},
	{latitude: 37.61807, longitude: -81.291454, zipCode: "25915"},
	{latitude: 37.678176, longitude: -81.490278, zipCode: "25916"},
	{latitude: 37.983649, longitude: -81.252324, zipCode: "25917"},
	{latitude: 37.739998, longitude: -81.035597, zipCode: "25918"},
	{latitude: 37.686426, longitude: -81.336322, zipCode: "25920"},
	{latitude: 37.682817, longitude: -81.266249, zipCode: "25921"},
	{latitude: 37.471975, longitude: -81.102777, zipCode: "25922"},
	{latitude: 37.541308, longitude: -81.339851, zipCode: "25928"},
	{latitude: 37.752011, longitude: -81.299569, zipCode: "25932"},
	{latitude: 37.926383, longitude: -81.033813, zipCode: "25936"},
	{latitude: 38.162883, longitude: -81.030457, zipCode: "25938"},
	{latitude: 38.017861, longitude: -80.985353, zipCode: "25942"},
	{latitude: 37.596615, longitude: -81.349191, zipCode: "25943"},
	{latitude: 37.655201, longitude: -80.875502, zipCode: "25951"},
	{latitude: 38.004072, longitude: -80.766597, zipCode: "25958"},
	{latitude: 37.986095, longitude: -80.798917, zipCode: "25962"},
	{latitude: 37.805617, longitude: -80.756187, zipCode: "25966"},
	{latitude: 37.611155, longitude: -81.005215, zipCode: "25969"},
	{latitude: 37.477419, longitude: -80.977446, zipCode: "25971"},
	{latitude: 38.032122, longitude: -80.706902, zipCode: "25972"},
	{latitude: 37.840546, longitude: -80.866718, zipCode: "25976"},
	{latitude: 37.797837, longitude: -80.906563, zipCode: "25977"},
	{latitude: 37.633, longitude: -80.941205, zipCode: "25978"},
	{latitude: 37.510942, longitude: -80.913482, zipCode: "25979"},
	{latitude: 38.070912, longitude: -80.69787, zipCode: "25981"},
	{latitude: 38.015846, longitude: -80.607361, zipCode: "25984"},
	{latitude: 37.756694, longitude: -80.833257, zipCode: "25985"},
	{latitude: 37.899792, longitude: -80.823727, zipCode: "25986"},
	{latitude: 37.678587, longitude: -81.036977, zipCode: "25989"},
	{latitude: 40.069182, longitude: -80.650807, zipCode: "26003"},
	{latitude: 40.223251, longitude: -80.657935, zipCode: "26030"},
	{latitude: 39.999074, longitude: -80.706014, zipCode: "26031"},
	{latitude: 40.183687, longitude: -80.553234, zipCode: "26032"},
	{latitude: 39.828256, longitude: -80.579475, zipCode: "26033"},
	{latitude: 40.598473, longitude: -80.552334, zipCode: "26034"},
	{latitude: 40.3449, longitude: -80.553539, zipCode: "26035"},
	{latitude: 39.97772, longitude: -80.554299, zipCode: "26036"},
	{latitude: 40.335049, longitude: -80.585918, zipCode: "26037"},
	{latitude: 39.96459, longitude: -80.724103, zipCode: "26038"},
	{latitude: 39.799369, longitude: -80.674066, zipCode: "26039"},
	{latitude: 39.983582, longitude: -80.731377, zipCode: "26040"},
	{latitude: 39.887247, longitude: -80.705419, zipCode: "26041"},
	{latitude: 40.532075, longitude: -80.585773, zipCode: "26047"},
	{latitude: 40.608265, longitude: -80.620785, zipCode: "26050"},
	{latitude: 39.762275, longitude: -80.779828, zipCode: "26055"},
	{latitude: 40.516958, longitude: -80.570164, zipCode: "26056"},
	{latitude: 40.187138, longitude: -80.661917, zipCode: "26058"},
	{latitude: 40.057338, longitude: -80.605252, zipCode: "26059"},
	{latitude: 40.105481, longitude: -80.544835, zipCode: "26060"},
	{latitude: 40.421372, longitude: -80.557726, zipCode: "26062"},
	{latitude: 40.259827, longitude: -80.580029, zipCode: "26070"},
	{latitude: 40.166606, longitude: -80.595338, zipCode: "26074"},
	{latitude: 40.196426, longitude: -80.650027, zipCode: "26075"},
	{latitude: 39.225842, longitude: -81.584316, zipCode: "26101"},
	{latitude: 39.277972, longitude: -81.475975, zipCode: "26104"},
	{latitude: 39.329574, longitude: -81.514619, zipCode: "26105"},
	{latitude: 39.115389, longitude: -81.663161, zipCode: "26133"},
	{latitude: 39.381465, longitude: -81.273534, zipCode: "26134"},
	{latitude: 38.961365, longitude: -81.118094, zipCode: "26136"},
	{latitude: 38.995583, longitude: -81.0494, zipCode: "26137"},
	{latitude: 39.027944, longitude: -81.146269, zipCode: "26138"},
	{latitude: 38.933503, longitude: -81.246147, zipCode: "26141"},
	{latitude: 39.214984, longitude: -81.454579, zipCode: "26142"},
	{latitude: 39.056366, longitude: -81.372736, zipCode: "26143"},
	{latitude: 39.457065, longitude: -81.036844, zipCode: "26146"},
	{latitude: 38.913569, longitude: -81.076188, zipCode: "26147"},
	{latitude: 39.058499, longitude: -81.182808, zipCode: "26148"},
	{latitude: 39.472168, longitude: -80.88585, zipCode: "26149"},
	{latitude: 39.134707, longitude: -81.535127, zipCode: "26150"},
	{latitude: 38.894572, longitude: -81.182077, zipCode: "26151"},
	{latitude: 38.979139, longitude: -81.177705, zipCode: "26152"},
	{latitude: 39.630056, longitude: -80.763963, zipCode: "26155"},
	{latitude: 39.607357, longitude: -80.932238, zipCode: "26159"},
	{latitude: 38.981202, longitude: -81.412057, zipCode: "26160"},
	{latitude: 39.159713, longitude: -81.246033, zipCode: "26161"},
	{latitude: 39.568702, longitude: -80.766701, zipCode: "26162"},
	{latitude: 39.013355, longitude: -81.694848, zipCode: "26164"},
	{latitude: 39.532341, longitude: -80.722809, zipCode: "26167"},
	{latitude: 39.072207, longitude: -81.571589, zipCode: "26169"},
	{latitude: 39.362023, longitude: -81.177959, zipCode: "26170"},
	{latitude: 39.537188, longitude: -80.974755, zipCode: "26175"},
	{latitude: 39.062813, longitude: -81.027429, zipCode: "26178"},
	{latitude: 39.194913, longitude: -81.3663, zipCode: "26180"},
	{latitude: 39.209325, longitude: -81.664463, zipCode: "26181"},
	{latitude: 39.294993, longitude: -81.35272, zipCode: "26184"},
	{latitude: 39.366289, longitude: -81.446609, zipCode: "26187"},
	{latitude: 39.001947, longitude: -80.197409, zipCode: "26201"},
	{latitude: 38.231628, longitude: -80.622135, zipCode: "26202"},
	{latitude: 38.548476, longitude: -80.58844, zipCode: "26203"},
	{latitude: 38.321233, longitude: -80.646241, zipCode: "26205"},
	{latitude: 38.320088, longitude: -80.413219, zipCode: "26206"},
	{latitude: 38.349934, longitude: -80.583767, zipCode: "26208"},
	{latitude: 38.375593, longitude: -80.07997, zipCode: "26209"},
	{latitude: 38.903148, longitude: -80.277888, zipCode: "26210"},
	{latitude: 38.721568, longitude: -80.364888, zipCode: "26215"},
	{latitude: 38.611008, longitude: -80.446813, zipCode: "26217"},
	{latitude: 38.845023, longitude: -80.267943, zipCode: "26218"},
	{latitude: 38.660184, longitude: -80.343304, zipCode: "26222"},
	{latitude: 38.744844, longitude: -80.174726, zipCode: "26224"},
	{latitude: 38.754356, longitude: -80.370544, zipCode: "26228"},
	{latitude: 38.626966, longitude: -80.206432, zipCode: "26230"},
	{latitude: 38.766166, longitude: -80.308326, zipCode: "26234"},
	{latitude: 38.717581, longitude: -80.230872, zipCode: "26236"},
	{latitude: 38.847711, longitude: -80.151475, zipCode: "26237"},
	{latitude: 39.101783, longitude: -80.158407, zipCode: "26238"},
	{latitude: 38.916258, longitude: -79.792226, zipCode: "26241"},
	{latitude: 39.029586, longitude: -79.970377, zipCode: "26250"},
	{latitude: 38.806126, longitude: -79.843118, zipCode: "26253"},
	{latitude: 38.962179, longitude: -79.630146, zipCode: "26254"},
	{latitude: 38.92257, longitude: -80.012384, zipCode: "26257"},
	{latitude: 38.825363, longitude: -79.91532, zipCode: "26259"},
	{latitude: 39.082926, longitude: -79.412495, zipCode: "26260"},
	{latitude: 38.189002, longitude: -80.500577, zipCode: "26261"},
	{latitude: 38.957044, longitude: -79.43582, zipCode: "26263"},
	{latitude: 38.589152, longitude: -79.822853, zipCode: "26264"},
	{latitude: 38.437566, longitude: -80.497397, zipCode: "26266"},
	{latitude: 38.925527, longitude: -80.080708, zipCode: "26267"},
	{latitude: 38.714502, longitude: -79.822856, zipCode: "26268"},
	{latitude: 39.116684, longitude: -79.652235, zipCode: "26269"},
	{latitude: 38.812253, longitude: -79.600451, zipCode: "26270"},
	{latitude: 39.02377, longitude: -79.503895, zipCode: "26271"},
	{latitude: 38.634493, longitude: -79.961209, zipCode: "26273"},
	{latitude: 38.986933, longitude: -79.949988, zipCode: "26275"},
	{latitude: 39.033666, longitude: -79.732665, zipCode: "26276"},
	{latitude: 38.825708, longitude: -80.025489, zipCode: "26278"},
	{latitude: 38.717353, longitude: -80.03781, zipCode: "26280"},
	{latitude: 38.520163, longitude: -80.146217, zipCode: "26282"},
	{latitude: 39.05844, longitude: -79.823145, zipCode: "26283"},
	{latitude: 38.910179, longitude: -79.949448, zipCode: "26285"},
	{latitude: 39.166016, longitude: -79.687509, zipCode: "26287"},
	{latitude: 38.514958, longitude: -80.346597, zipCode: "26288"},
	{latitude: 38.989709, longitude: -79.512691, zipCode: "26289"},
	{latitude: 38.441098, longitude: -80.086311, zipCode: "26291"},
	{latitude: 39.141915, longitude: -79.533672, zipCode: "26292"},
	{latitude: 38.782355, longitude: -79.936517, zipCode: "26293"},
	{latitude: 38.534431, longitude: -80.023084, zipCode: "26294"},
	{latitude: 38.764498, longitude: -79.553568, zipCode: "26296"},
	{latitude: 38.456206, longitude: -80.286154, zipCode: "26298"},
	{latitude: 39.286434, longitude: -80.372691, zipCode: "26301"},
	{latitude: 39.404133, longitude: -80.812146, zipCode: "26320"},
	{latitude: 39.052245, longitude: -80.694146, zipCode: "26321"},
	{latitude: 39.262277, longitude: -80.290552, zipCode: "26323"},
	{latitude: 39.098947, longitude: -80.904803, zipCode: "26325"},
	{latitude: 39.122886, longitude: -80.94277, zipCode: "26327"},
	{latitude: 39.28879, longitude: -80.221567, zipCode: "26330"},
	{latitude: 38.843539, longitude: -80.684125, zipCode: "26335"},
	{latitude: 39.230232, longitude: -81.160993, zipCode: "26337"},
	{latitude: 39.091209, longitude: -80.628201, zipCode: "26338"},
	{latitude: 39.422207, longitude: -80.613976, zipCode: "26339"},
	{latitude: 39.033601, longitude: -80.85483, zipCode: "26342"},
	{latitude: 38.82222, longitude: -80.402438, zipCode: "26343"},
	{latitude: 39.289995, longitude: -81.060075, zipCode: "26346"},
	{latitude: 39.263696, longitude: -80.126108, zipCode: "26347"},
	{latitude: 39.462424, longitude: -80.545248, zipCode: "26348"},
	{latitude: 39.227475, longitude: -80.110826, zipCode: "26349"},
	{latitude: 38.926246, longitude: -80.837559, zipCode: "26351"},
	{latitude: 39.354044, longitude: -80.018749, zipCode: "26354"},
	{latitude: 39.365117, longitude: -80.31774, zipCode: "26361"},
	{latitude: 39.15538, longitude: -81.054218, zipCode: "26362"},
	{latitude: 39.382655, longitude: -80.334782, zipCode: "26366"},
	{latitude: 39.335246, longitude: -80.331184, zipCode: "26369"},
	{latitude: 38.959136, longitude: -80.346572, zipCode: "26372"},
	{latitude: 39.452313, longitude: -79.875295, zipCode: "26374"},
	{latitude: 38.775766, longitude: -80.468769, zipCode: "26376"},
	{latitude: 39.496261, longitude: -80.647037, zipCode: "26377"},
	{latitude: 39.123666, longitude: -80.447515, zipCode: "26378"},
	{latitude: 38.975373, longitude: -80.721954, zipCode: "26384"},
	{latitude: 39.159526, longitude: -80.35195, zipCode: "26385"},
	{latitude: 39.40247, longitude: -80.393241, zipCode: "26386"},
	{latitude: 39.335835, longitude: -80.306514, zipCode: "26404"},
	{latitude: 39.221652, longitude: -79.899756, zipCode: "26405"},
	{latitude: 39.196594, longitude: -80.306444, zipCode: "26408"},
	{latitude: 39.404974, longitude: -79.808333, zipCode: "26410"},
	{latitude: 39.177467, longitude: -80.723034, zipCode: "26411"},
	{latitude: 38.896606, longitude: -80.583001, zipCode: "26412"},
	{latitude: 39.288298, longitude: -80.939195, zipCode: "26415"},
	{latitude: 39.157139, longitude: -80.023725, zipCode: "26416"},
	{latitude: 39.59818, longitude: -80.635399, zipCode: "26419"},
	{latitude: 39.181913, longitude: -80.924246, zipCode: "26421"},
	{latitude: 39.297983, longitude: -80.433226, zipCode: "26422"},
	{latitude: 39.281141, longitude: -80.172683, zipCode: "26424"},
	{latitude: 39.299902, longitude: -79.691594, zipCode: "26425"},
	{latitude: 39.287048, longitude: -80.564406, zipCode: "26426"},
	{latitude: 38.895493, longitude: -80.758903, zipCode: "26430"},
	{latitude: 39.391461, longitude: -80.280868, zipCode: "26431"},
	{latitude: 39.267149, longitude: -80.085325, zipCode: "26435"},
	{latitude: 39.297278, longitude: -80.719427, zipCode: "26436"},
	{latitude: 39.525975, longitude: -80.50893, zipCode: "26437"},
	{latitude: 39.343177, longitude: -80.316362, zipCode: "26438"},
	{latitude: 39.321682, longitude: -79.918718, zipCode: "26440"},
	{latitude: 39.075943, longitude: -80.75316, zipCode: "26443"},
	{latitude: 39.375298, longitude: -79.770038, zipCode: "26444"},
	{latitude: 38.911775, longitude: -80.479076, zipCode: "26447"},
	{latitude: 39.403007, longitude: -80.491296, zipCode: "26448"},
	{latitude: 39.204406, longitude: -80.403427, zipCode: "26451"},
	{latitude: 39.037172, longitude: -80.5282, zipCode: "26452"},
	{latitude: 39.26294, longitude: -80.774047, zipCode: "26456"},
	{latitude: 39.437444, longitude: -80.346081, zipCode: "26463"},
	{latitude: 39.634091, longitude: -80.036839, zipCode: "26501"},
	{latitude: 39.653049, longitude: -79.945502, zipCode: "26505"},
	{latitude: 39.646781, longitude: -79.970152, zipCode: "26506"},
	{latitude: 39.601132, longitude: -79.902507, zipCode: "26508"},
	{latitude: 39.569166, longitude: -79.627057, zipCode: "26519"},
	{latitude: 39.501994, longitude: -79.824305, zipCode: "26520"},
	{latitude: 39.713283, longitude: -80.228143, zipCode: "26521"},
	{latitude: 39.540768, longitude: -79.807202, zipCode: "26524"},
	{latitude: 39.654026, longitude: -79.62581, zipCode: "26525"},
	{latitude: 39.648779, longitude: -79.999178, zipCode: "26534"},
	{latitude: 39.488133, longitude: -79.709884, zipCode: "26537"},
	{latitude: 39.695015, longitude: -80.125243, zipCode: "26541"},
	{latitude: 39.574651, longitude: -79.782809, zipCode: "26542"},
	{latitude: 39.664624, longitude: -79.999859, zipCode: "26543"},
	{latitude: 39.711623, longitude: -80.170951, zipCode: "26544"},
	{latitude: 39.662312, longitude: -80.023922, zipCode: "26546"},
	{latitude: 39.510714, longitude: -79.805798, zipCode: "26547"},
	{latitude: 39.465392, longitude: -80.109845, zipCode: "26554"},
	{latitude: 39.514926, longitude: -80.176661, zipCode: "26559"},
	{latitude: 39.540869, longitude: -80.143768, zipCode: "26560"},
	{latitude: 39.642435, longitude: -80.44378, zipCode: "26562"},
	{latitude: 39.481033, longitude: -80.273771, zipCode: "26563"},
	{latitude: 39.422153, longitude: -80.27807, zipCode: "26568"},
	{latitude: 39.636149, longitude: -80.243256, zipCode: "26570"},
	{latitude: 39.526563, longitude: -80.262027, zipCode: "26571"},
	{latitude: 39.486458, longitude: -80.316163, zipCode: "26572"},
	{latitude: 39.560818, longitude: -80.177899, zipCode: "26574"},
	{latitude: 39.692992, longitude: -80.424459, zipCode: "26575"},
	{latitude: 39.492501, longitude: -80.254748, zipCode: "26576"},
	{latitude: 39.670284, longitude: -80.574729, zipCode: "26581"},
	{latitude: 39.528793, longitude: -80.380088, zipCode: "26582"},
	{latitude: 39.600778, longitude: -80.435913, zipCode: "26585"},
	{latitude: 39.526547, longitude: -80.109518, zipCode: "26586"},
	{latitude: 39.516659, longitude: -80.299402, zipCode: "26587"},
	{latitude: 39.578871, longitude: -80.143989, zipCode: "26588"},
	{latitude: 39.669082, longitude: -80.312306, zipCode: "26590"},
	{latitude: 39.451607, longitude: -80.296284, zipCode: "26591"},
	{latitude: 38.617423, longitude: -80.622737, zipCode: "26601"},
	{latitude: 38.467568, longitude: -80.705302, zipCode: "26610"},
	{latitude: 38.853471, longitude: -80.823404, zipCode: "26611"},
	{latitude: 38.826274, longitude: -80.734588, zipCode: "26615"},
	{latitude: 38.515558, longitude: -80.840058, zipCode: "26617"},
	{latitude: 38.751656, longitude: -80.705437, zipCode: "26619"},
	{latitude: 38.690412, longitude: -80.506278, zipCode: "26621"},
	{latitude: 38.655505, longitude: -80.871607, zipCode: "26623"},
	{latitude: 38.720715, longitude: -80.819464, zipCode: "26624"},
	{latitude: 38.752473, longitude: -80.585064, zipCode: "26627"},
	{latitude: 38.56367, longitude: -80.704268, zipCode: "26629"},
	{latitude: 38.807313, longitude: -80.567864, zipCode: "26631"},
	{latitude: 38.743911, longitude: -80.95825, zipCode: "26636"},
	{latitude: 38.768006, longitude: -80.999466, zipCode: "26638"},
	{latitude: 38.265344, longitude: -80.993515, zipCode: "26651"},
	{latitude: 38.25017, longitude: -81.176887, zipCode: "26656"},
	{latitude: 38.356051, longitude: -80.7051, zipCode: "26660"},
	{latitude: 38.257951, longitude: -80.772136, zipCode: "26662"},
	{latitude: 38.244547, longitude: -80.999505, zipCode: "26667"},
	{latitude: 38.334344, longitude: -80.887038, zipCode: "26671"},
	{latitude: 38.160011, longitude: -80.647778, zipCode: "26676"},
	{latitude: 38.178882, longitude: -80.916062, zipCode: "26678"},
	{latitude: 38.173997, longitude: -80.805128, zipCode: "26679"},
	{latitude: 38.093333, longitude: -80.872973, zipCode: "26680"},
	{latitude: 38.233199, longitude: -80.731681, zipCode: "26681"},
	{latitude: 38.158218, longitude: -80.853278, zipCode: "26684"},
	{latitude: 38.175264, longitude: -81.089305, zipCode: "26690"},
	{latitude: 38.393664, longitude: -80.670852, zipCode: "26691"},
	{latitude: 39.286402, longitude: -78.601752, zipCode: "26704"},
	{latitude: 39.323023, longitude: -79.56777, zipCode: "26705"},
	{latitude: 39.2088, longitude: -79.402816, zipCode: "26707"},
	{latitude: 39.301755, longitude: -78.967602, zipCode: "26710"},
	{latitude: 39.322255, longitude: -78.450785, zipCode: "26711"},
	{latitude: 39.178491, longitude: -78.645187, zipCode: "26714"},
	{latitude: 39.25274, longitude: -79.529492, zipCode: "26716"},
	{latitude: 39.334155, longitude: -79.182508, zipCode: "26717"},
	{latitude: 39.497679, longitude: -78.767448, zipCode: "26719"},
	{latitude: 39.277648, longitude: -79.331243, zipCode: "26720"},
	{latitude: 39.496906, longitude: -78.630427, zipCode: "26722"},
	{latitude: 39.402846, longitude: -78.985446, zipCode: "26726"},
	{latitude: 39.149865, longitude: -79.064169, zipCode: "26731"},
	{latitude: 39.214649, longitude: -79.260285, zipCode: "26739"},
	{latitude: 39.293949, longitude: -79.075293, zipCode: "26743"},
	{latitude: 39.477082, longitude: -79.045448, zipCode: "26750"},
	{latitude: 39.561351, longitude: -78.767849, zipCode: "26753"},
	{latitude: 39.170356, longitude: -78.744478, zipCode: "26755"},
	{latitude: 39.320663, longitude: -78.767933, zipCode: "26757"},
	{latitude: 39.271397, longitude: -78.695001, zipCode: "26761"},
	{latitude: 39.473037, longitude: -78.699175, zipCode: "26763"},
	{latitude: 39.446512, longitude: -79.563923, zipCode: "26764"},
	{latitude: 39.615876, longitude: -78.756699, zipCode: "26767"},
	{latitude: 39.059339, longitude: -78.794453, zipCode: "26801"},
	{latitude: 38.65051, longitude: -79.191452, zipCode: "26802"},
	{latitude: 38.68187, longitude: -79.530284, zipCode: "26804"},
	{latitude: 38.638573, longitude: -79.354653, zipCode: "26807"},
	{latitude: 39.153475, longitude: -78.469265, zipCode: "26808"},
	{latitude: 38.99506, longitude: -78.754392, zipCode: "26810"},
	{latitude: 38.885776, longitude: -78.878013, zipCode: "26812"},
	{latitude: 38.781086, longitude: -79.475798, zipCode: "26814"},
	{latitude: 38.493516, longitude: -79.364475, zipCode: "26815"},
	{latitude: 39.373773, longitude: -78.401579, zipCode: "26817"},
	{latitude: 39.087625, longitude: -79.016499, zipCode: "26818"},
	{latitude: 39.128133, longitude: -78.495221, zipCode: "26823"},
	{latitude: 39.099326, longitude: -79.201694, zipCode: "26833"},
	{latitude: 38.99824, longitude: -78.975114, zipCode: "26836"},
	{latitude: 38.808793, longitude: -79.058414, zipCode: "26838"},
	{latitude: 39.164519, longitude: -78.964204, zipCode: "26845"},
	{latitude: 38.955186, longitude: -79.150889, zipCode: "26847"},
	{latitude: 39.047109, longitude: -78.611022, zipCode: "26851"},
	{latitude: 39.245363, longitude: -78.910599, zipCode: "26852"},
	{latitude: 38.975009, longitude: -79.220717, zipCode: "26855"},
	{latitude: 39.205938, longitude: -78.512135, zipCode: "26865"},
	{latitude: 38.80407, longitude: -79.24863, zipCode: "26866"},
	{latitude: 38.846405, longitude: -79.385291, zipCode: "26884"},
	{latitude: 38.904902, longitude: -79.395514, zipCode: "26886"},
	{latitude: 35.939004, longitude: -80.440264, zipCode: "27006"},
	{latitude: 36.38972, longitude: -80.588467, zipCode: "27007"},
	{latitude: 36.230922, longitude: -80.078651, zipCode: "27009"},
	{latitude: 36.220692, longitude: -80.692638, zipCode: "27011"},
	{latitude: 36.013155, longitude: -80.377628, zipCode: "27012"},
	{latitude: 35.750492, longitude: -80.700075, zipCode: "27013"},
	{latitude: 35.810049, longitude: -80.555744, zipCode: "27014"},
	{latitude: 36.447462, longitude: -80.218816, zipCode: "27016"},
	{latitude: 36.382016, longitude: -80.756218, zipCode: "27017"},
	{latitude: 36.197203, longitude: -80.518144, zipCode: "27018"},
	{latitude: 36.286886, longitude: -80.237725, zipCode: "27019"},
	{latitude: 36.112663, longitude: -80.811277, zipCode: "27020"},
	{latitude: 36.320319, longitude: -80.344721, zipCode: "27021"},
	{latitude: 36.512166, longitude: -80.215604, zipCode: "27022"},
	{latitude: 36.093825, longitude: -80.450175, zipCode: "27023"},
	{latitude: 36.519237, longitude: -80.841179, zipCode: "27024"},
	{latitude: 36.377985, longitude: -79.968222, zipCode: "27025"},
	{latitude: 36.441759, longitude: -79.988878, zipCode: "27027"},
	{latitude: 35.926999, longitude: -80.571086, zipCode: "27028"},
	{latitude: 36.489814, longitude: -80.629214, zipCode: "27030"},
	{latitude: 36.169312, longitude: -80.393045, zipCode: "27040"},
	{latitude: 36.415263, longitude: -80.484587, zipCode: "27041"},
	{latitude: 36.352819, longitude: -80.064203, zipCode: "27042"},
	{latitude: 36.338509, longitude: -80.448522, zipCode: "27043"},
	{latitude: 36.234398, longitude: -80.29607, zipCode: "27045"},
	{latitude: 36.502219, longitude: -80.078264, zipCode: "27046"},
	{latitude: 36.314134, longitude: -80.569112, zipCode: "27047"},
	{latitude: 36.482514, longitude: -79.917678, zipCode: "27048"},
	{latitude: 36.226442, longitude: -80.409707, zipCode: "27050"},
	{latitude: 36.195944, longitude: -80.153403, zipCode: "27051"},
	{latitude: 36.320755, longitude: -80.150453, zipCode: "27052"},
	{latitude: 36.475813, longitude: -80.3618, zipCode: "27053"},
	{latitude: 35.79254, longitude: -80.597888, zipCode: "27054"},
	{latitude: 36.113907, longitude: -80.631194, zipCode: "27055"},
	{latitude: 36.11085, longitude: -80.20043, zipCode: "27101"},
	{latitude: 36.05823, longitude: -80.321496, zipCode: "27103"},
	{latitude: 36.090721, longitude: -80.331545, zipCode: "27104"},
	{latitude: 36.161886, longitude: -80.23431, zipCode: "27105"},
	{latitude: 36.14391, longitude: -80.323157, zipCode: "27106"},
	{latitude: 36.013158, longitude: -80.175491, zipCode: "27107"},
	{latitude: 36.133731, longitude: -80.275408, zipCode: "27109"},
	{latitude: 36.089246, longitude: -80.224832, zipCode: "27110"},
	{latitude: 36.020708, longitude: -80.279817, zipCode: "27127"},
	{latitude: 36.034172, longitude: -79.484829, zipCode: "27201"},
	{latitude: 36.18558, longitude: -79.506028, zipCode: "27202"},
	{latitude: 35.728462, longitude: -79.78653, zipCode: "27203"},
	{latitude: 35.638259, longitude: -79.850866, zipCode: "27205"},
	{latitude: 35.607613, longitude: -79.390517, zipCode: "27207"},
	{latitude: 35.561896, longitude: -79.542943, zipCode: "27208"},
	{latitude: 35.342999, longitude: -79.753291, zipCode: "27209"},
	{latitude: 36.480967, longitude: -79.279856, zipCode: "27212"},
	{latitude: 35.649255, longitude: -79.40828, zipCode: "27213"},
	{latitude: 36.206055, longitude: -79.671126, zipCode: "27214"},
	{latitude: 36.019137, longitude: -79.5137, zipCode: "27215"},
	{latitude: 36.190945, longitude: -79.380185, zipCode: "27217"},
	{latitude: 35.239746, longitude: -79.791592, zipCode: "27229"},
	{latitude: 36.195182, longitude: -79.174669, zipCode: "27231"},
	{latitude: 35.892512, longitude: -79.70498, zipCode: "27233"},
	{latitude: 36.100372, longitude: -80.007443, zipCode: "27235"},
	{latitude: 35.601229, longitude: -80.09028, zipCode: "27239"},
	{latitude: 35.329657, longitude: -79.648955, zipCode: "27242"},
	{latitude: 36.060588, longitude: -79.201831, zipCode: "27243"},
	{latitude: 36.215823, longitude: -79.4866, zipCode: "27244"},
	{latitude: 35.460914, longitude: -79.787864, zipCode: "27247"},
	{latitude: 35.783296, longitude: -79.716331, zipCode: "27248"},
	{latitude: 36.162677, longitude: -79.582432, zipCode: "27249"},
	{latitude: 35.560325, longitude: -79.354984, zipCode: "27252"},
	{latitude: 35.966166, longitude: -79.347361, zipCode: "27253"},
	{latitude: 35.560484, longitude: -79.27462, zipCode: "27256"},
	{latitude: 36.083422, longitude: -79.351288, zipCode: "27258"},
	{latitude: 35.491435, longitude: -79.526237, zipCode: "27259"},
	{latitude: 35.954222, longitude: -79.987055, zipCode: "27260"},
	{latitude: 35.96313, longitude: -80.041667, zipCode: "27262"},
	{latitude: 35.911554, longitude: -79.936803, zipCode: "27263"},
	{latitude: 36.009229, longitude: -80.032388, zipCode: "27265"},
	{latitude: 35.971399, longitude: -79.994696, zipCode: "27268"},
	{latitude: 36.090195, longitude: -79.086929, zipCode: "27278"},
	{latitude: 35.187442, longitude: -79.625115, zipCode: "27281"},
	{latitude: 35.996346, longitude: -79.931842, zipCode: "27282"},
	{latitude: 35.951474, longitude: -79.635418, zipCode: "27283"},
	{latitude: 36.116121, longitude: -80.081865, zipCode: "27284"},
	{latitude: 36.497645, longitude: -79.750015, zipCode: "27288"},
	{latitude: 36.418316, longitude: -79.168428, zipCode: "27291"},
	{latitude: 35.738325, longitude: -80.207547, zipCode: "27292"},
	{latitude: 35.867644, longitude: -80.317083, zipCode: "27295"},
	{latitude: 35.885115, longitude: -79.567545, zipCode: "27298"},
	{latitude: 35.753068, longitude: -80.377451, zipCode: "27299"},
	{latitude: 36.11351, longitude: -79.665656, zipCode: "27301"},
	{latitude: 36.135763, longitude: -79.263348, zipCode: "27302"},
	{latitude: 36.51577, longitude: -79.216717, zipCode: "27305"},
	{latitude: 35.225229, longitude: -79.980186, zipCode: "27306"},
	{latitude: 36.174072, longitude: -79.992233, zipCode: "27310"},
	{latitude: 36.479651, longitude: -79.472563, zipCode: "27311"},
	{latitude: 35.756405, longitude: -79.209789, zipCode: "27312"},
	{latitude: 35.931448, longitude: -79.758055, zipCode: "27313"},
	{latitude: 36.31036, longitude: -79.19625, zipCode: "27314"},
	{latitude: 36.510175, longitude: -79.403503, zipCode: "27315"},
	{latitude: 35.697013, longitude: -79.625793, zipCode: "27316"},
	{latitude: 35.840737, longitude: -79.802499, zipCode: "27317"},
	{latitude: 36.341488, longitude: -79.658824, zipCode: "27320"},
	{latitude: 35.463598, longitude: -79.566313, zipCode: "27325"},
	{latitude: 36.464116, longitude: -79.556845, zipCode: "27326"},
	{latitude: 35.517067, longitude: -79.18321, zipCode: "27330"},
	{latitude: 35.372219, longitude: -79.148794, zipCode: "27332"},
	{latitude: 35.945481, longitude: -79.310108, zipCode: "27340"},
	{latitude: 35.523759, longitude: -79.695909, zipCode: "27341"},
	{latitude: 36.06636, longitude: -79.618597, zipCode: "27342"},
	{latitude: 36.504089, longitude: -79.075873, zipCode: "27343"},
	{latitude: 35.722316, longitude: -79.433692, zipCode: "27344"},
	{latitude: 35.895086, longitude: -79.418086, zipCode: "27349"},
	{latitude: 35.808253, longitude: -79.888213, zipCode: "27350"},
	{latitude: 35.659775, longitude: -80.267892, zipCode: "27351"},
	{latitude: 35.786858, longitude: -79.567836, zipCode: "27355"},
	{latitude: 35.436865, longitude: -79.804272, zipCode: "27356"},
	{latitude: 36.2666, longitude: -79.970625, zipCode: "27357"},
	{latitude: 36.228232, longitude: -79.880061, zipCode: "27358"},
	{latitude: 35.86058, longitude: -80.099288, zipCode: "27360"},
	{latitude: 35.809298, longitude: -79.97798, zipCode: "27370"},
	{latitude: 35.40575, longitude: -79.948344, zipCode: "27371"},
	{latitude: 35.91758, longitude: -80.257749, zipCode: "27374"},
	{latitude: 35.261099, longitude: -79.521811, zipCode: "27376"},
	{latitude: 36.038036, longitude: -79.599395, zipCode: "27377"},
	{latitude: 36.361127, longitude: -79.347762, zipCode: "27379"},
	{latitude: 36.070822, longitude: -79.767692, zipCode: "27401"},
	{latitude: 36.064078, longitude: -79.825413, zipCode: "27403"},
	{latitude: 36.115379, longitude: -79.734641, zipCode: "27405"},
	{latitude: 35.99795, longitude: -79.766734, zipCode: "27406"},
	{latitude: 36.013367, longitude: -79.871391, zipCode: "27407"},
	{latitude: 36.103667, longitude: -79.813712, zipCode: "27408"},
	{latitude: 36.071751, longitude: -79.964858, zipCode: "27409"},
	{latitude: 36.118615, longitude: -79.894851, zipCode: "27410"},
	{latitude: 36.076615, longitude: -79.771513, zipCode: "27411"},
	{latitude: 36.068277, longitude: -79.810061, zipCode: "27412"},
	{latitude: 36.187426, longitude: -79.81744, zipCode: "27455"},
	{latitude: 35.484941, longitude: -78.68457, zipCode: "27501"},
	{latitude: 35.719615, longitude: -78.907172, zipCode: "27502"},
	{latitude: 36.152977, longitude: -78.884452, zipCode: "27503"},
	{latitude: 35.400317, longitude: -78.518271, zipCode: "27504"},
	{latitude: 35.406599, longitude: -79.004422, zipCode: "27505"},
	{latitude: 35.408583, longitude: -78.739274, zipCode: "27506"},
	{latitude: 36.513808, longitude: -78.552466, zipCode: "27507"},
	{latitude: 35.959012, longitude: -78.25091, zipCode: "27508"},
	{latitude: 36.131468, longitude: -78.773709, zipCode: "27509"},
	{latitude: 35.909835, longitude: -79.081253, zipCode: "27510"},
	{latitude: 35.763313, longitude: -78.787832, zipCode: "27511"},
	{latitude: 35.80198, longitude: -78.802573, zipCode: "27513"},
	{latitude: 35.966756, longitude: -79.052136, zipCode: "27514"},
	{latitude: 35.906197, longitude: -79.154855, zipCode: "27516"},
	{latitude: 35.842663, longitude: -79.032015, zipCode: "27517"},
	{latitude: 35.731139, longitude: -78.773206, zipCode: "27518"},
	{latitude: 35.808392, longitude: -78.886546, zipCode: "27519"},
	{latitude: 35.611762, longitude: -78.47639, zipCode: "27520"},
	{latitude: 35.412121, longitude: -78.655865, zipCode: "27521"},
	{latitude: 36.105618, longitude: -78.675202, zipCode: "27522"},
	{latitude: 35.773618, longitude: -78.956926, zipCode: "27523"},
	{latitude: 35.391825, longitude: -78.378763, zipCode: "27524"},
	{latitude: 36.146064, longitude: -78.474143, zipCode: "27525"},
	{latitude: 35.543991, longitude: -78.829796, zipCode: "27526"},
	{latitude: 35.653948, longitude: -78.381715, zipCode: "27527"},
	{latitude: 35.64118, longitude: -78.584349, zipCode: "27529"},
	{latitude: 35.382697, longitude: -78.061142, zipCode: "27530"},
	{latitude: 35.343617, longitude: -77.967369, zipCode: "27531"},
	{latitude: 35.38746, longitude: -78.038964, zipCode: "27533"},
	{latitude: 35.369273, longitude: -77.886872, zipCode: "27534"},
	{latitude: 36.325908, longitude: -78.406772, zipCode: "27536"},
	{latitude: 36.358726, longitude: -78.398384, zipCode: "27537"},
	{latitude: 35.676533, longitude: -78.813447, zipCode: "27539"},
	{latitude: 35.620169, longitude: -78.865047, zipCode: "27540"},
	{latitude: 36.264862, longitude: -79.083217, zipCode: "27541"},
	{latitude: 35.618293, longitude: -78.136838, zipCode: "27542"},
	{latitude: 36.191235, longitude: -78.440476, zipCode: "27544"},
	{latitude: 35.783679, longitude: -78.483355, zipCode: "27545"},
	{latitude: 35.385317, longitude: -78.862107, zipCode: "27546"},
	{latitude: 36.099641, longitude: -78.251246, zipCode: "27549"},
	{latitude: 36.432843, longitude: -78.05017, zipCode: "27551"},
	{latitude: 36.486498, longitude: -78.302798, zipCode: "27553"},
	{latitude: 35.562379, longitude: -78.203517, zipCode: "27555"},
	{latitude: 36.396988, longitude: -78.309433, zipCode: "27556"},
	{latitude: 35.779135, longitude: -78.198512, zipCode: "27557"},
	{latitude: 35.628213, longitude: -79.102818, zipCode: "27559"},
	{latitude: 35.8576, longitude: -78.827114, zipCode: "27560"},
	{latitude: 35.641978, longitude: -78.975581, zipCode: "27562"},
	{latitude: 36.421449, longitude: -78.221394, zipCode: "27563"},
	{latitude: 36.361651, longitude: -78.648952, zipCode: "27565"},
	{latitude: 35.511274, longitude: -78.24291, zipCode: "27568"},
	{latitude: 35.429475, longitude: -78.172122, zipCode: "27569"},
	{latitude: 36.438631, longitude: -78.236608, zipCode: "27570"},
	{latitude: 35.9246, longitude: -78.461452, zipCode: "27571"},
	{latitude: 36.255106, longitude: -78.877494, zipCode: "27572"},
	{latitude: 36.399017, longitude: -78.97513, zipCode: "27573"},
	{latitude: 36.475265, longitude: -78.842101, zipCode: "27574"},
	{latitude: 35.577524, longitude: -78.261311, zipCode: "27576"},
	{latitude: 35.491935, longitude: -78.344614, zipCode: "27577"},
	{latitude: 36.203077, longitude: -78.736103, zipCode: "27581"},
	{latitude: 36.447352, longitude: -78.569089, zipCode: "27582"},
	{latitude: 36.309693, longitude: -78.930583, zipCode: "27583"},
	{latitude: 36.493383, longitude: -78.445998, zipCode: "27584"},
	{latitude: 35.980838, longitude: -78.55105, zipCode: "27587"},
	{latitude: 36.322541, longitude: -78.133484, zipCode: "27589"},
	{latitude: 35.78417, longitude: -78.388984, zipCode: "27591"},
	{latitude: 35.55738, longitude: -78.67227, zipCode: "27592"},
	{latitude: 35.585803, longitude: -78.362338, zipCode: "27593"},
	{latitude: 36.472959, longitude: -78.166215, zipCode: "27594"},
	{latitude: 35.99965, longitude: -78.434962, zipCode: "27596"},
	{latitude: 35.842593, longitude: -78.301044, zipCode: "27597"},
	{latitude: 35.904045, longitude: -79.052134, zipCode: "27599"},
	{latitude: 35.773661, longitude: -78.634563, zipCode: "27601"},
	{latitude: 35.664796, longitude: -78.653677, zipCode: "27603"},
	{latitude: 35.822932, longitude: -78.562057, zipCode: "27604"},
	{latitude: 35.790408, longitude: -78.654564, zipCode: "27605"},
	{latitude: 35.742323, longitude: -78.715478, zipCode: "27606"},
	{latitude: 35.817784, longitude: -78.727514, zipCode: "27607"},
	{latitude: 35.8103, longitude: -78.648658, zipCode: "27608"},
	{latitude: 35.843765, longitude: -78.633643, zipCode: "27609"},
	{latitude: 35.7444, longitude: -78.546278, zipCode: "27610"},
	{latitude: 35.850277, longitude: -78.703864, zipCode: "27612"},
	{latitude: 35.926102, longitude: -78.711414, zipCode: "27613"},
	{latitude: 35.94593, longitude: -78.619494, zipCode: "27614"},
	{latitude: 35.898462, longitude: -78.635558, zipCode: "27615"},
	{latitude: 35.866514, longitude: -78.541313, zipCode: "27616"},
	{latitude: 35.908845, longitude: -78.772396, zipCode: "27617"},
	{latitude: 35.785489, longitude: -78.67893, zipCode: "27695"},
	{latitude: 35.778803, longitude: -78.622967, zipCode: "27697"},
	{latitude: 35.99924, longitude: -78.897944, zipCode: "27701"},
	{latitude: 35.961286, longitude: -78.805228, zipCode: "27703"},
	{latitude: 36.042446, longitude: -78.828771, zipCode: "27704"},
	{latitude: 36.025112, longitude: -78.982067, zipCode: "27705"},
	{latitude: 35.954525, longitude: -78.953317, zipCode: "27707"},
	{latitude: 36.0001, longitude: -78.943102, zipCode: "27708"},
	{latitude: 35.920066, longitude: -78.867067, zipCode: "27709"},
	{latitude: 36.095484, longitude: -78.904075, zipCode: "27712"},
	{latitude: 35.883522, longitude: -78.935541, zipCode: "27713"},
	{latitude: 35.913446, longitude: -77.725699, zipCode: "27801"},
	{latitude: 35.891404, longitude: -77.84949, zipCode: "27803"},
	{latitude: 35.989169, longitude: -77.842709, zipCode: "27804"},
	{latitude: 36.208571, longitude: -77.088582, zipCode: "27805"},
	{latitude: 35.304999, longitude: -76.756067, zipCode: "27806"},
	{latitude: 35.811382, longitude: -78.085982, zipCode: "27807"},
	{latitude: 35.467089, longitude: -76.759752, zipCode: "27808"},
	{latitude: 36.016777, longitude: -77.74162, zipCode: "27809"},
	{latitude: 35.549649, longitude: -76.512464, zipCode: "27810"},
	{latitude: 35.805032, longitude: -77.376162, zipCode: "27812"},
	{latitude: 35.634739, longitude: -77.937516, zipCode: "27813"},
	{latitude: 35.380118, longitude: -76.932428, zipCode: "27814"},
	{latitude: 35.924689, longitude: -77.693027, zipCode: "27815"},
	{latitude: 36.108768, longitude: -78.072959, zipCode: "27816"},
	{latitude: 35.451821, longitude: -77.081218, zipCode: "27817"},
	{latitude: 36.499725, longitude: -77.018505, zipCode: "27818"},
	{latitude: 35.814154, longitude: -77.457328, zipCode: "27819"},
	{latitude: 36.421515, longitude: -77.241567, zipCode: "27820"},
	{latitude: 35.322066, longitude: -76.874087, zipCode: "27821"},
	{latitude: 35.807012, longitude: -77.840457, zipCode: "27822"},
	{latitude: 36.209653, longitude: -77.713733, zipCode: "27823"},
	{latitude: 35.551546, longitude: -76.005215, zipCode: "27824"},
	{latitude: 35.835279, longitude: -77.171481, zipCode: "27825"},
	{latitude: 35.59534, longitude: -76.243592, zipCode: "27826"},
	{latitude: 35.698211, longitude: -77.512233, zipCode: "27827"},
	{latitude: 35.585151, longitude: -77.577415, zipCode: "27828"},
	{latitude: 35.699996, longitude: -77.605652, zipCode: "27829"},
	{latitude: 35.564168, longitude: -77.950309, zipCode: "27830"},
	{latitude: 36.463087, longitude: -77.570553, zipCode: "27831"},
	{latitude: 36.519809, longitude: -77.740128, zipCode: "27832"},
	{latitude: 35.65677, longitude: -77.379527, zipCode: "27834"},
	{latitude: 35.513865, longitude: -77.2046, zipCode: "27837"},
	{latitude: 36.284912, longitude: -77.55688, zipCode: "27839"},
	{latitude: 35.929708, longitude: -77.238886, zipCode: "27840"},
	{latitude: 35.908579, longitude: -77.27636, zipCode: "27841"},
	{latitude: 36.526749, longitude: -77.851988, zipCode: "27842"},
	{latitude: 35.995534, longitude: -77.400001, zipCode: "27843"},
	{latitude: 36.242713, longitude: -77.946425, zipCode: "27844"},
	{latitude: 36.371267, longitude: -77.43999, zipCode: "27845"},
	{latitude: 35.758568, longitude: -76.897527, zipCode: "27846"},
	{latitude: 36.178202, longitude: -77.201851, zipCode: "27847"},
	{latitude: 36.077306, longitude: -77.215025, zipCode: "27849"},
	{latitude: 36.412391, longitude: -77.880191, zipCode: "27850"},
	{latitude: 35.640083, longitude: -78.030678, zipCode: "27851"},
	{latitude: 35.750459, longitude: -77.636931, zipCode: "27852"},
	{latitude: 36.515237, longitude: -77.307178, zipCode: "27853"},
	{latitude: 36.415557, longitude: -77.069339, zipCode: "27855"},
	{latitude: 36.01368, longitude: -77.980574, zipCode: "27856"},
	{latitude: 35.970826, longitude: -77.261068, zipCode: "27857"},
	{latitude: 35.532451, longitude: -77.282787, zipCode: "27858"},
	{latitude: 35.664163, longitude: -76.635302, zipCode: "27860"},
	{latitude: 35.817205, longitude: -77.319014, zipCode: "27861"},
	{latitude: 36.490175, longitude: -77.188291, zipCode: "27862"},
	{latitude: 35.488165, longitude: -77.971796, zipCode: "27863"},
	{latitude: 35.801141, longitude: -77.649403, zipCode: "27864"},
	{latitude: 35.664024, longitude: -76.899975, zipCode: "27865"},
	{latitude: 36.519869, longitude: -77.514565, zipCode: "27866"},
	{latitude: 36.267105, longitude: -77.313433, zipCode: "27869"},
	{latitude: 36.425216, longitude: -77.695139, zipCode: "27870"},
	{latitude: 35.802069, longitude: -77.260565, zipCode: "27871"},
	{latitude: 36.200954, longitude: -77.246027, zipCode: "27872"},
	{latitude: 35.653316, longitude: -77.776044, zipCode: "27873"},
	{latitude: 36.129011, longitude: -77.410692, zipCode: "27874"},
	{latitude: 35.455706, longitude: -76.504667, zipCode: "27875"},
	{latitude: 36.474414, longitude: -77.405107, zipCode: "27876"},
	{latitude: 36.51881, longitude: -77.192219, zipCode: "27877"},
	{latitude: 35.866118, longitude: -77.830602, zipCode: "27878"},
	{latitude: 35.574488, longitude: -77.279651, zipCode: "27879"},
	{latitude: 35.730736, longitude: -78.080589, zipCode: "27880"},
	{latitude: 35.97022, longitude: -77.442266, zipCode: "27881"},
	{latitude: 35.942383, longitude: -78.118404, zipCode: "27882"},
	{latitude: 35.592351, longitude: -77.804401, zipCode: "27883"},
	{latitude: 35.70352, longitude: -77.266635, zipCode: "27884"},
	{latitude: 35.420015, longitude: -76.247278, zipCode: "27885"},
	{latitude: 35.904662, longitude: -77.511738, zipCode: "27886"},
	{latitude: 35.595826, longitude: -77.702269, zipCode: "27888"},
	{latitude: 35.580432, longitude: -77.025019, zipCode: "27889"},
	{latitude: 36.41418, longitude: -77.587745, zipCode: "27890"},
	{latitude: 36.097735, longitude: -77.742789, zipCode: "27891"},
	{latitude: 35.819171, longitude: -77.063472, zipCode: "27892"},
	{latitude: 35.693766, longitude: -77.904137, zipCode: "27893"},
	{latitude: 35.791, longitude: -77.97639, zipCode: "27896"},
	{latitude: 36.324597, longitude: -77.20968, zipCode: "27897"},
	{latitude: 36.288605, longitude: -76.268734, zipCode: "27909"},
	{latitude: 36.281756, longitude: -76.993403, zipCode: "27910"},
	{latitude: 35.336654, longitude: -75.512063, zipCode: "27915"},
	{latitude: 36.329315, longitude: -75.920051, zipCode: "27916"},
	{latitude: 36.355136, longitude: -75.997463, zipCode: "27917"},
	{latitude: 36.308171, longitude: -76.504283, zipCode: "27919"},
	{latitude: 35.253294, longitude: -75.538313, zipCode: "27920"},
	{latitude: 36.3298, longitude: -76.147832, zipCode: "27921"},
	{latitude: 36.316755, longitude: -76.84221, zipCode: "27922"},
	{latitude: 36.368236, longitude: -75.930826, zipCode: "27923"},
	{latitude: 36.168447, longitude: -76.832521, zipCode: "27924"},
	{latitude: 35.841184, longitude: -76.173324, zipCode: "27925"},
	{latitude: 36.505115, longitude: -76.604925, zipCode: "27926"},
	{latitude: 36.331206, longitude: -75.851203, zipCode: "27927"},
	{latitude: 35.808824, longitude: -76.437748, zipCode: "27928"},
	{latitude: 36.439195, longitude: -76.016487, zipCode: "27929"},
	{latitude: 36.09419, longitude: -76.588045, zipCode: "27932"},
	{latitude: 36.431079, longitude: -76.868092, zipCode: "27935"},
	{latitude: 35.247856, longitude: -75.59873, zipCode: "27936"},
	{latitude: 36.512279, longitude: -76.773947, zipCode: "27937"},
	{latitude: 36.384296, longitude: -76.716, zipCode: "27938"},
	{latitude: 36.235206, longitude: -75.879817, zipCode: "27939"},
	{latitude: 36.092315, longitude: -75.822829, zipCode: "27941"},
	{latitude: 36.297139, longitude: -76.750854, zipCode: "27942"},
	{latitude: 35.207826, longitude: -75.717187, zipCode: "27943"},
	{latitude: 36.180897, longitude: -76.403244, zipCode: "27944"},
	{latitude: 36.364231, longitude: -76.590231, zipCode: "27946"},
	{latitude: 36.167137, longitude: -75.853521, zipCode: "27947"},
	{latitude: 36.019229, longitude: -75.692172, zipCode: "27948"},
	{latitude: 36.109859, longitude: -75.745433, zipCode: "27949"},
	{latitude: 36.500551, longitude: -75.973664, zipCode: "27950"},
	{latitude: 35.803985, longitude: -75.875404, zipCode: "27953"},
	{latitude: 35.91271, longitude: -75.685335, zipCode: "27954"},
	{latitude: 36.399985, longitude: -76.001115, zipCode: "27956"},
	{latitude: 36.066297, longitude: -76.759059, zipCode: "27957"},
	{latitude: 36.501198, longitude: -76.17333, zipCode: "27958"},
	{latitude: 35.891936, longitude: -75.603436, zipCode: "27959"},
	{latitude: 35.073231, longitude: -75.998527, zipCode: "27960"},
	{latitude: 35.762002, longitude: -76.776452, zipCode: "27962"},
	{latitude: 36.127053, longitude: -75.804257, zipCode: "27964"},
	{latitude: 36.292401, longitude: -75.932948, zipCode: "27965"},
	{latitude: 36.125626, longitude: -75.848248, zipCode: "27966"},
	{latitude: 36.222319, longitude: -76.908397, zipCode: "27967"},
	{latitude: 35.658631, longitude: -75.489189, zipCode: "27968"},
	{latitude: 36.463307, longitude: -76.802587, zipCode: "27969"},
	{latitude: 35.908747, longitude: -76.620202, zipCode: "27970"},
	{latitude: 35.552458, longitude: -75.471299, zipCode: "27972"},
	{latitude: 36.371209, longitude: -76.055514, zipCode: "27973"},
	{latitude: 36.243057, longitude: -75.989559, zipCode: "27974"},
	{latitude: 36.47861, longitude: -76.336271, zipCode: "27976"},
	{latitude: 35.672202, longitude: -75.773128, zipCode: "27978"},
	{latitude: 36.436768, longitude: -76.560679, zipCode: "27979"},
	{latitude: 36.245324, longitude: -76.619385, zipCode: "27980"},
	{latitude: 35.852855, longitude: -75.637424, zipCode: "27981"},
	{latitude: 35.5659, longitude: -75.465481, zipCode: "27982"},
	{latitude: 35.96203, longitude: -76.935766, zipCode: "27983"},
	{latitude: 36.218808, longitude: -76.473719, zipCode: "27985"},
	{latitude: 36.388979, longitude: -76.918295, zipCode: "27986"},
	{latitude: 35.338552, longitude: -80.208179, zipCode: "28001"},
	{latitude: 35.410436, longitude: -81.092004, zipCode: "28006"},
	{latitude: 35.121694, longitude: -80.130521, zipCode: "28007"},
	{latitude: 35.407846, longitude: -80.107195, zipCode: "28009"},
	{latitude: 35.211504, longitude: -81.037865, zipCode: "28012"},
	{latitude: 35.314932, longitude: -81.283292, zipCode: "28016"},
	{latitude: 35.247082, longitude: -81.670682, zipCode: "28017"},
	{latitude: 35.48898, longitude: -81.803385, zipCode: "28018"},
	{latitude: 35.283896, longitude: -81.793877, zipCode: "28019"},
	{latitude: 35.524091, longitude: -81.643245, zipCode: "28020"},
	{latitude: 35.400774, longitude: -81.403346, zipCode: "28021"},
	{latitude: 35.568585, longitude: -80.602542, zipCode: "28023"},
	{latitude: 35.24331, longitude: -81.770575, zipCode: "28024"},
	{latitude: 35.382565, longitude: -80.523443, zipCode: "28025"},
	{latitude: 35.413317, longitude: -80.67547, zipCode: "28027"},
	{latitude: 35.475193, longitude: -80.893685, zipCode: "28031"},
	{latitude: 35.233033, longitude: -81.079513, zipCode: "28032"},
	{latitude: 35.419107, longitude: -81.328427, zipCode: "28033"},
	{latitude: 35.353664, longitude: -81.178943, zipCode: "28034"},
	{latitude: 35.482087, longitude: -80.793351, zipCode: "28036"},
	{latitude: 35.495486, longitude: -81.018484, zipCode: "28037"},
	{latitude: 35.680389, longitude: -80.436238, zipCode: "28039"},
	{latitude: 35.389977, longitude: -81.752184, zipCode: "28040"},
	{latitude: 35.587422, longitude: -80.462535, zipCode: "28041"},
	{latitude: 35.422984, longitude: -81.507477, zipCode: "28042"},
	{latitude: 35.312387, longitude: -81.870315, zipCode: "28043"},
	{latitude: 35.227964, longitude: -81.231691, zipCode: "28052"},
	{latitude: 35.261363, longitude: -81.15015, zipCode: "28054"},
	{latitude: 35.216936, longitude: -81.125003, zipCode: "28056"},
	{latitude: 35.517515, longitude: -80.315893, zipCode: "28071"},
	{latitude: 35.613219, longitude: -80.442328, zipCode: "28072"},
	{latitude: 35.198574, longitude: -81.483785, zipCode: "28073"},
	{latitude: 35.239358, longitude: -81.878193, zipCode: "28074"},
	{latitude: 35.30191, longitude: -80.639269, zipCode: "28075"},
	{latitude: 35.256446, longitude: -81.77952, zipCode: "28076"},
	{latitude: 35.404802, longitude: -81.20277, zipCode: "28077"},
	{latitude: 35.404779, longitude: -80.866541, zipCode: "28078"},
	{latitude: 35.114666, longitude: -80.600597, zipCode: "28079"},
	{latitude: 35.456746, longitude: -81.105685, zipCode: "28080"},
	{latitude: 35.502816, longitude: -80.670173, zipCode: "28081"},
	{latitude: 35.491833, longitude: -80.582207, zipCode: "28083"},
	{latitude: 35.244164, longitude: -81.377912, zipCode: "28086"},
	{latitude: 35.544382, longitude: -80.61463, zipCode: "28088"},
	{latitude: 35.320272, longitude: -81.66069, zipCode: "28089"},
	{latitude: 35.454659, longitude: -81.559555, zipCode: "28090"},
	{latitude: 34.987392, longitude: -79.933002, zipCode: "28091"},
	{latitude: 35.483239, longitude: -81.253054, zipCode: "28092"},
	{latitude: 35.296733, longitude: -80.39332, zipCode: "28097"},
	{latitude: 35.271135, longitude: -81.100787, zipCode: "28098"},
	{latitude: 35.255802, longitude: -81.075416, zipCode: "28101"},
	{latitude: 34.814818, longitude: -79.976632, zipCode: "28102"},
	{latitude: 34.998974, longitude: -80.351455, zipCode: "28103"},
	{latitude: 35.057978, longitude: -80.697771, zipCode: "28104"},
	{latitude: 35.119788, longitude: -80.709693, zipCode: "28105"},
	{latitude: 35.252973, longitude: -80.51803, zipCode: "28107"},
	{latitude: 34.933185, longitude: -80.679471, zipCode: "28108"},
	{latitude: 35.48289, longitude: -80.279892, zipCode: "28109"},
	{latitude: 35.066044, longitude: -80.509089, zipCode: "28110"},
	{latitude: 34.889925, longitude: -80.549548, zipCode: "28112"},
	{latitude: 35.230441, longitude: -81.747702, zipCode: "28114"},
	{latitude: 35.575398, longitude: -80.774914, zipCode: "28115"},
	{latitude: 35.574965, longitude: -80.894155, zipCode: "28117"},
	{latitude: 34.847539, longitude: -80.013943, zipCode: "28119"},
	{latitude: 35.328586, longitude: -81.026424, zipCode: "28120"},
	{latitude: 35.400074, longitude: -80.408289, zipCode: "28124"},
	{latitude: 35.659025, longitude: -80.698613, zipCode: "28125"},
	{latitude: 35.450168, longitude: -80.167234, zipCode: "28127"},
	{latitude: 35.210699, longitude: -80.154524, zipCode: "28128"},
	{latitude: 35.232252, longitude: -80.327397, zipCode: "28129"},
	{latitude: 34.844926, longitude: -80.265681, zipCode: "28133"},
	{latitude: 35.084481, longitude: -80.891536, zipCode: "28134"},
	{latitude: 35.02977, longitude: -80.217264, zipCode: "28135"},
	{latitude: 35.420501, longitude: -81.642411, zipCode: "28136"},
	{latitude: 35.490898, longitude: -80.263638, zipCode: "28137"},
	{latitude: 35.514441, longitude: -80.437354, zipCode: "28138"},
	{latitude: 35.348363, longitude: -81.994737, zipCode: "28139"},
	{latitude: 35.715746, longitude: -80.458946, zipCode: "28144"},
	{latitude: 35.621144, longitude: -80.395596, zipCode: "28146"},
	{latitude: 35.681053, longitude: -80.562965, zipCode: "28147"},
	{latitude: 35.342703, longitude: -81.576248, zipCode: "28150"},
	{latitude: 35.240769, longitude: -81.591391, zipCode: "28152"},
	{latitude: 35.694166, longitude: -80.435209, zipCode: "28159"},
	{latitude: 35.360671, longitude: -81.922286, zipCode: "28160"},
	{latitude: 35.20702, longitude: -80.428498, zipCode: "28163"},
	{latitude: 35.389444, longitude: -81.037135, zipCode: "28164"},
	{latitude: 35.677222, longitude: -80.867951, zipCode: "28166"},
	{latitude: 35.506425, longitude: -81.959442, zipCode: "28167"},
	{latitude: 35.55236, longitude: -81.425071, zipCode: "28168"},
	{latitude: 35.358117, longitude: -81.430602, zipCode: "28169"},
	{latitude: 34.998714, longitude: -80.098784, zipCode: "28170"},
	{latitude: 34.919177, longitude: -80.730565, zipCode: "28173"},
	{latitude: 34.969035, longitude: -80.43958, zipCode: "28174"},
	{latitude: 35.227271, longitude: -80.844194, zipCode: "28202"},
	{latitude: 35.209006, longitude: -80.857571, zipCode: "28203"},
	{latitude: 35.2146, longitude: -80.826754, zipCode: "28204"},
	{latitude: 35.218774, longitude: -80.789616, zipCode: "28205"},
	{latitude: 35.255423, longitude: -80.821516, zipCode: "28206"},
	{latitude: 35.194148, longitude: -80.824585, zipCode: "28207"},
	{latitude: 35.232005, longitude: -80.904989, zipCode: "28208"},
	{latitude: 35.178714, longitude: -80.853985, zipCode: "28209"},
	{latitude: 35.130509, longitude: -80.856685, zipCode: "28210"},
	{latitude: 35.166342, longitude: -80.797285, zipCode: "28211"},
	{latitude: 35.187703, longitude: -80.744556, zipCode: "28212"},
	{latitude: 35.286229, longitude: -80.735231, zipCode: "28213"},
	{latitude: 35.272034, longitude: -80.968109, zipCode: "28214"},
	{latitude: 35.24557, longitude: -80.69893, zipCode: "28215"},
	{latitude: 35.31019, longitude: -80.889636, zipCode: "28216"},
	{latitude: 35.168745, longitude: -80.908364, zipCode: "28217"},
	{latitude: 35.308875, longitude: -80.732499, zipCode: "28223"},
	{latitude: 35.102697, longitude: -80.822129, zipCode: "28226"},
	{latitude: 35.189567, longitude: -80.645408, zipCode: "28227"},
	{latitude: 35.224605, longitude: -80.843094, zipCode: "28244"},
	{latitude: 35.322061, longitude: -80.739972, zipCode: "28262"},
	{latitude: 35.337313, longitude: -80.803472, zipCode: "28269"},
	{latitude: 35.112035, longitude: -80.764605, zipCode: "28270"},
	{latitude: 35.123659, longitude: -80.948455, zipCode: "28273"},
	{latitude: 35.189104, longitude: -80.83384, zipCode: "28274"},
	{latitude: 35.049856, longitude: -80.818019, zipCode: "28277"},
	{latitude: 35.133373, longitude: -81.008837, zipCode: "28278"},
	{latitude: 35.226332, longitude: -80.843091, zipCode: "28280"},
	{latitude: 35.224815, longitude: -80.845277, zipCode: "28282"},
	{latitude: 35.0748, longitude: -78.884257, zipCode: "28301"},
	{latitude: 35.085631, longitude: -78.959361, zipCode: "28303"},
	{latitude: 35.025528, longitude: -78.989604, zipCode: "28304"},
	{latitude: 35.052609, longitude: -78.907244, zipCode: "28305"},
	{latitude: 34.958495, longitude: -78.896848, zipCode: "28306"},
	{latitude: 35.139602, longitude: -78.993577, zipCode: "28307"},
	{latitude: 35.172036, longitude: -79.014963, zipCode: "28308"},
	{latitude: 35.124541, longitude: -79.04339, zipCode: "28310"},
	{latitude: 35.167769, longitude: -78.888697, zipCode: "28311"},
	{latitude: 34.947752, longitude: -78.738133, zipCode: "28312"},
	{latitude: 35.053208, longitude: -79.01547, zipCode: "28314"},
	{latitude: 35.115044, longitude: -79.448548, zipCode: "28315"},
	{latitude: 35.010768, longitude: -78.62373, zipCode: "28318"},
	{latitude: 34.558122, longitude: -78.772715, zipCode: "28320"},
	{latitude: 35.314791, longitude: -78.834376, zipCode: "28323"},
	{latitude: 35.15337, longitude: -78.10422, zipCode: "28325"},
	{latitude: 35.269044, longitude: -79.15469, zipCode: "28326"},
	{latitude: 35.358148, longitude: -79.422784, zipCode: "28327"},
	{latitude: 35.00513, longitude: -78.334419, zipCode: "28328"},
	{latitude: 34.910673, longitude: -79.823315, zipCode: "28330"},
	{latitude: 35.196432, longitude: -78.648539, zipCode: "28331"},
	{latitude: 34.655389, longitude: -78.699284, zipCode: "28332"},
	{latitude: 35.286131, longitude: -78.017373, zipCode: "28333"},
	{latitude: 35.272208, longitude: -78.568104, zipCode: "28334"},
	{latitude: 34.655856, longitude: -78.571951, zipCode: "28337"},
	{latitude: 35.098899, longitude: -79.744237, zipCode: "28338"},
	{latitude: 35.31054, longitude: -78.732747, zipCode: "28339"},
	{latitude: 34.471354, longitude: -79.134422, zipCode: "28340"},
	{latitude: 35.119876, longitude: -78.174437, zipCode: "28341"},
	{latitude: 35.18567, longitude: -78.651615, zipCode: "28342"},
	{latitude: 34.757362, longitude: -79.564875, zipCode: "28343"},
	{latitude: 35.167989, longitude: -78.622725, zipCode: "28344"},
	{latitude: 34.870288, longitude: -79.66564, zipCode: "28345"},
	{latitude: 35.052588, longitude: -79.558842, zipCode: "28347"},
	{latitude: 34.914973, longitude: -78.927021, zipCode: "28348"},
	{latitude: 34.981159, longitude: -77.927059, zipCode: "28349"},
	{latitude: 35.246712, longitude: -79.314996, zipCode: "28350"},
	{latitude: 34.848096, longitude: -79.576652, zipCode: "28351"},
	{latitude: 34.768395, longitude: -79.453559, zipCode: "28352"},
	{latitude: 35.390535, longitude: -79.19515, zipCode: "28355"},
	{latitude: 35.23702, longitude: -78.790396, zipCode: "28356"},
	{latitude: 34.902094, longitude: -79.083013, zipCode: "28357"},
	{latitude: 34.607857, longitude: -78.934772, zipCode: "28358"},
	{latitude: 34.58685, longitude: -79.054744, zipCode: "28359"},
	{latitude: 34.670328, longitude: -79.07494, zipCode: "28360"},
	{latitude: 34.369609, longitude: -79.128839, zipCode: "28362"},
	{latitude: 34.948668, longitude: -79.529934, zipCode: "28363"},
	{latitude: 34.707928, longitude: -79.322676, zipCode: "28364"},
	{latitude: 35.17233, longitude: -78.052287, zipCode: "28365"},
	{latitude: 35.230228, longitude: -78.362014, zipCode: "28366"},
	{latitude: 35.169804, longitude: -79.723229, zipCode: "28367"},
	{latitude: 35.369444, longitude: -79.113313, zipCode: "28368"},
	{latitude: 34.423373, longitude: -79.029416, zipCode: "28369"},
	{latitude: 34.91065, longitude: -78.978348, zipCode: "28371"},
	{latitude: 34.699915, longitude: -79.178622, zipCode: "28372"},
	{latitude: 35.09347, longitude: -79.485597, zipCode: "28373"},
	{latitude: 35.18928, longitude: -79.459071, zipCode: "28374"},
	{latitude: 34.477245, longitude: -79.043363, zipCode: "28375"},
	{latitude: 35.04681, longitude: -79.241396, zipCode: "28376"},
	{latitude: 34.836294, longitude: -79.210785, zipCode: "28377"},
	{latitude: 34.858772, longitude: -79.050603, zipCode: "28378"},
	{latitude: 34.929217, longitude: -79.786856, zipCode: "28379"},
	{latitude: 34.926629, longitude: -78.560381, zipCode: "28382"},
	{latitude: 34.577871, longitude: -79.26909, zipCode: "28383"},
	{latitude: 34.79156, longitude: -78.94141, zipCode: "28384"},
	{latitude: 35.048, longitude: -78.499648, zipCode: "28385"},
	{latitude: 34.862965, longitude: -79.128433, zipCode: "28386"},
	{latitude: 35.180486, longitude: -79.375695, zipCode: "28387"},
	{latitude: 35.220889, longitude: -78.953309, zipCode: "28390"},
	{latitude: 35.03417, longitude: -78.698638, zipCode: "28391"},
	{latitude: 34.734741, longitude: -78.795411, zipCode: "28392"},
	{latitude: 34.981087, longitude: -78.18948, zipCode: "28393"},
	{latitude: 35.217455, longitude: -79.241537, zipCode: "28394"},
	{latitude: 35.130166, longitude: -78.727024, zipCode: "28395"},
	{latitude: 34.955073, longitude: -79.413862, zipCode: "28396"},
	{latitude: 34.98978, longitude: -78.064173, zipCode: "28398"},
	{latitude: 34.769386, longitude: -78.719612, zipCode: "28399"},
	{latitude: 34.291985, longitude: -77.972365, zipCode: "28401"},
	{latitude: 34.221692, longitude: -77.880633, zipCode: "28403"},
	{latitude: 34.264179, longitude: -77.868315, zipCode: "28405"},
	{latitude: 34.150625, longitude: -77.86031, zipCode: "28409"},
	{latitude: 34.301829, longitude: -77.793066, zipCode: "28411"},
	{latitude: 34.136589, longitude: -77.920579, zipCode: "28412"},
	{latitude: 34.054572, longitude: -78.468698, zipCode: "28420"},
	{latitude: 34.505123, longitude: -78.167047, zipCode: "28421"},
	{latitude: 34.049233, longitude: -78.211058, zipCode: "28422"},
	{latitude: 34.270145, longitude: -78.404519, zipCode: "28423"},
	{latitude: 34.290349, longitude: -78.699676, zipCode: "28424"},
	{latitude: 34.560672, longitude: -77.900427, zipCode: "28425"},
	{latitude: 34.038615, longitude: -77.905263, zipCode: "28428"},
	{latitude: 34.341211, longitude: -77.900216, zipCode: "28429"},
	{latitude: 34.284007, longitude: -78.932772, zipCode: "28430"},
	{latitude: 34.317376, longitude: -78.844536, zipCode: "28431"},
	{latitude: 34.168033, longitude: -78.758709, zipCode: "28432"},
	{latitude: 34.488505, longitude: -78.616673, zipCode: "28433"},
	{latitude: 34.47245, longitude: -78.470809, zipCode: "28434"},
	{latitude: 34.405955, longitude: -78.109229, zipCode: "28435"},
	{latitude: 34.246636, longitude: -78.283897, zipCode: "28436"},
	{latitude: 34.420395, longitude: -78.91372, zipCode: "28438"},
	{latitude: 34.286816, longitude: -79.001382, zipCode: "28439"},
	{latitude: 34.811459, longitude: -78.427416, zipCode: "28441"},
	{latitude: 34.310175, longitude: -78.601091, zipCode: "28442"},
	{latitude: 34.481214, longitude: -77.692847, zipCode: "28443"},
	{latitude: 34.688313, longitude: -78.319957, zipCode: "28444"},
	{latitude: 34.528849, longitude: -77.535773, zipCode: "28445"},
	{latitude: 34.618651, longitude: -78.278106, zipCode: "28447"},
	{latitude: 34.518305, longitude: -78.359652, zipCode: "28448"},
	{latitude: 33.973269, longitude: -77.941888, zipCode: "28449"},
	{latitude: 34.309178, longitude: -78.496605, zipCode: "28450"},
	{latitude: 34.236096, longitude: -78.119072, zipCode: "28451"},
	{latitude: 34.005054, longitude: -78.555914, zipCode: "28452"},
	{latitude: 34.866406, longitude: -78.076448, zipCode: "28453"},
	{latitude: 34.670298, longitude: -77.653954, zipCode: "28454"},
	{latitude: 34.111657, longitude: -78.631796, zipCode: "28455"},
	{latitude: 34.374973, longitude: -78.287201, zipCode: "28456"},
	{latitude: 34.456198, longitude: -77.871525, zipCode: "28457"},
	{latitude: 34.819856, longitude: -78.081105, zipCode: "28458"},
	{latitude: 34.52332, longitude: -77.417074, zipCode: "28460"},
	{latitude: 33.966618, longitude: -78.057598, zipCode: "28461"},
	{latitude: 33.999721, longitude: -78.311045, zipCode: "28462"},
	{latitude: 34.109764, longitude: -78.776463, zipCode: "28463"},
	{latitude: 34.782225, longitude: -78.02108, zipCode: "28464"},
	{latitude: 33.912502, longitude: -78.100479, zipCode: "28465"},
	{latitude: 34.74077, longitude: -77.875702, zipCode: "28466"},
	{latitude: 33.917986, longitude: -78.592036, zipCode: "28467"},
	{latitude: 33.899652, longitude: -78.519214, zipCode: "28468"},
	{latitude: 33.93383, longitude: -78.473218, zipCode: "28469"},
	{latitude: 33.96697, longitude: -78.395923, zipCode: "28470"},
	{latitude: 34.299016, longitude: -78.691182, zipCode: "28472"},
	{latitude: 34.64284, longitude: -78.042039, zipCode: "28478"},
	{latitude: 34.100688, longitude: -78.016511, zipCode: "28479"},
	{latitude: 34.214753, longitude: -77.800506, zipCode: "28480"},
	{latitude: 35.239084, longitude: -77.527194, zipCode: "28501"},
	{latitude: 35.227366, longitude: -77.635888, zipCode: "28504"},
	{latitude: 35.099668, longitude: -77.824788, zipCode: "28508"},
	{latitude: 35.145759, longitude: -76.805927, zipCode: "28509"},
	{latitude: 34.987612, longitude: -76.804461, zipCode: "28510"},
	{latitude: 34.901116, longitude: -76.345763, zipCode: "28511"},
	{latitude: 34.701567, longitude: -76.806529, zipCode: "28512"},
	{latitude: 35.442763, longitude: -77.389638, zipCode: "28513"},
	{latitude: 35.191601, longitude: -76.689195, zipCode: "28515"},
	{latitude: 34.85641, longitude: -76.619618, zipCode: "28516"},
	{latitude: 34.894823, longitude: -77.747493, zipCode: "28518"},
	{latitude: 35.127835, longitude: -77.012553, zipCode: "28519"},
	{latitude: 34.99595, longitude: -76.466803, zipCode: "28520"},
	{latitude: 34.817902, longitude: -77.736618, zipCode: "28521"},
	{latitude: 35.208786, longitude: -77.299531, zipCode: "28523"},
	{latitude: 34.79445, longitude: -76.463848, zipCode: "28524"},
	{latitude: 35.132291, longitude: -77.686672, zipCode: "28525"},
	{latitude: 35.244705, longitude: -77.408429, zipCode: "28526"},
	{latitude: 35.258207, longitude: -77.017502, zipCode: "28527"},
	{latitude: 34.73211, longitude: -76.538617, zipCode: "28528"},
	{latitude: 35.103344, longitude: -76.878629, zipCode: "28529"},
	{latitude: 35.373976, longitude: -77.415522, zipCode: "28530"},
	{latitude: 34.698038, longitude: -76.555547, zipCode: "28531"},
	{latitude: 34.905022, longitude: -76.886672, zipCode: "28532"},
	{latitude: 34.901925, longitude: -76.900858, zipCode: "28533"},
	{latitude: 35.256616, longitude: -76.55864, zipCode: "28537"},
	{latitude: 35.416645, longitude: -77.565077, zipCode: "28538"},
	{latitude: 34.694212, longitude: -77.232818, zipCode: "28539"},
	{latitude: 34.744324, longitude: -77.499048, zipCode: "28540"},
	{latitude: 34.729932, longitude: -77.461943, zipCode: "28542"},
	{latitude: 34.734804, longitude: -77.39805, zipCode: "28543"},
	{latitude: 34.696042, longitude: -77.290709, zipCode: "28544"},
	{latitude: 34.827304, longitude: -77.391062, zipCode: "28546"},
	{latitude: 34.631368, longitude: -77.32856, zipCode: "28547"},
	{latitude: 35.321323, longitude: -77.780856, zipCode: "28551"},
	{latitude: 35.2982, longitude: -76.555928, zipCode: "28552"},
	{latitude: 34.739158, longitude: -76.510068, zipCode: "28553"},
	{latitude: 35.475702, longitude: -77.592596, zipCode: "28554"},
	{latitude: 34.867968, longitude: -77.248397, zipCode: "28555"},
	{latitude: 35.132807, longitude: -76.637805, zipCode: "28556"},
	{latitude: 34.737849, longitude: -76.750308, zipCode: "28557"},
	{latitude: 35.126695, longitude: -76.977628, zipCode: "28560"},
	{latitude: 35.080979, longitude: -77.125033, zipCode: "28562"},
	{latitude: 34.776989, longitude: -76.880505, zipCode: "28570"},
	{latitude: 35.046007, longitude: -76.703845, zipCode: "28571"},
	{latitude: 35.019918, longitude: -77.723833, zipCode: "28572"},
	{latitude: 34.979917, longitude: -77.197521, zipCode: "28573"},
	{latitude: 34.878886, longitude: -77.589666, zipCode: "28574"},
	{latitude: 34.688187, longitude: -76.880939, zipCode: "28575"},
	{latitude: 34.906777, longitude: -76.404402, zipCode: "28577"},
	{latitude: 35.196952, longitude: -77.856972, zipCode: "28578"},
	{latitude: 34.812858, longitude: -76.505058, zipCode: "28579"},
	{latitude: 35.453274, longitude: -77.679538, zipCode: "28580"},
	{latitude: 34.844258, longitude: -76.435553, zipCode: "28581"},
	{latitude: 34.748284, longitude: -77.150455, zipCode: "28582"},
	{latitude: 34.72401, longitude: -77.100543, zipCode: "28584"},
	{latitude: 35.049763, longitude: -77.41758, zipCode: "28585"},
	{latitude: 35.32415, longitude: -77.177411, zipCode: "28586"},
	{latitude: 35.186789, longitude: -76.666791, zipCode: "28587"},
	{latitude: 34.796151, longitude: -76.494019, zipCode: "28589"},
	{latitude: 35.520683, longitude: -77.416661, zipCode: "28590"},
	{latitude: 34.701105, longitude: -76.973912, zipCode: "28594"},
	{latitude: 35.772393, longitude: -81.326659, zipCode: "28601"},
	{latitude: 35.673522, longitude: -81.382627, zipCode: "28602"},
	{latitude: 36.179336, longitude: -81.850289, zipCode: "28604"},
	{latitude: 36.12962, longitude: -81.694915, zipCode: "28605"},
	{latitude: 36.051784, longitude: -81.306878, zipCode: "28606"},
	{latitude: 36.214937, longitude: -81.64675, zipCode: "28607"},
	{latitude: 35.678451, longitude: -81.057418, zipCode: "28609"},
	{latitude: 35.729217, longitude: -81.145335, zipCode: "28610"},
	{latitude: 36.00604, longitude: -81.736971, zipCode: "28611"},
	{latitude: 35.650391, longitude: -81.53756, zipCode: "28612"},
	{latitude: 35.737648, longitude: -81.207675, zipCode: "28613"},
	{latitude: 36.474894, longitude: -81.670259, zipCode: "28615"},
	{latitude: 36.021459, longitude: -81.930176, zipCode: "28616"},
	{latitude: 36.480702, longitude: -81.361979, zipCode: "28617"},
	{latitude: 36.213548, longitude: -81.516925, zipCode: "28618"},
	{latitude: 35.760135, longitude: -81.606807, zipCode: "28619"},
	{latitude: 36.275593, longitude: -80.785654, zipCode: "28621"},
	{latitude: 36.19496, longitude: -81.948911, zipCode: "28622"},
	{latitude: 36.529076, longitude: -80.973414, zipCode: "28623"},
	{latitude: 36.119696, longitude: -81.413245, zipCode: "28624"},
	{latitude: 35.869581, longitude: -80.889653, zipCode: "28625"},
	{latitude: 36.292577, longitude: -81.517632, zipCode: "28626"},
	{latitude: 36.453586, longitude: -81.014299, zipCode: "28627"},
	{latitude: 35.729174, longitude: -81.778268, zipCode: "28628"},
	{latitude: 36.347483, longitude: -81.359161, zipCode: "28629"},
	{latitude: 35.831975, longitude: -81.42017, zipCode: "28630"},
	{latitude: 36.547476, longitude: -81.410356, zipCode: "28631"},
	{latitude: 35.972783, longitude: -80.757232, zipCode: "28634"},
	{latitude: 36.332961, longitude: -81.126011, zipCode: "28635"},
	{latitude: 35.939426, longitude: -81.063433, zipCode: "28636"},
	{latitude: 35.717672, longitude: -81.419384, zipCode: "28637"},
	{latitude: 35.845093, longitude: -81.477938, zipCode: "28638"},
	{latitude: 36.404873, longitude: -81.399397, zipCode: "28640"},
	{latitude: 35.97546, longitude: -81.894868, zipCode: "28641"},
	{latitude: 36.225065, longitude: -80.815479, zipCode: "28642"},
	{latitude: 36.529059, longitude: -81.540879, zipCode: "28643"},
	{latitude: 36.426159, longitude: -81.277823, zipCode: "28644"},
	{latitude: 35.97883, longitude: -81.547779, zipCode: "28645"},
	{latitude: 36.073108, longitude: -81.846965, zipCode: "28646"},
	{latitude: 36.335979, longitude: -81.212043, zipCode: "28649"},
	{latitude: 35.571426, longitude: -81.15839, zipCode: "28650"},
	{latitude: 36.306214, longitude: -81.308783, zipCode: "28651"},
	{latitude: 36.089687, longitude: -81.986535, zipCode: "28652"},
	{latitude: 36.066028, longitude: -81.901025, zipCode: "28653"},
	{latitude: 36.058516, longitude: -81.159305, zipCode: "28654"},
	{latitude: 35.763266, longitude: -81.749862, zipCode: "28655"},
	{latitude: 36.021993, longitude: -81.942425, zipCode: "28657"},
	{latitude: 35.637616, longitude: -81.235853, zipCode: "28658"},
	{latitude: 36.215959, longitude: -81.148169, zipCode: "28659"},
	{latitude: 35.968615, longitude: -80.856248, zipCode: "28660"},
	{latitude: 36.02249, longitude: -81.891707, zipCode: "28662"},
	{latitude: 36.537202, longitude: -81.289931, zipCode: "28663"},
	{latitude: 36.04244, longitude: -82.005917, zipCode: "28664"},
	{latitude: 36.209682, longitude: -81.373878, zipCode: "28665"},
	{latitude: 35.718637, longitude: -81.470616, zipCode: "28666"},
	{latitude: 35.774539, longitude: -81.425089, zipCode: "28667"},
	{latitude: 36.435252, longitude: -80.982432, zipCode: "28668"},
	{latitude: 36.223445, longitude: -80.989818, zipCode: "28669"},
	{latitude: 36.194308, longitude: -80.912494, zipCode: "28670"},
	{latitude: 35.750203, longitude: -81.526631, zipCode: "28671"},
	{latitude: 36.483327, longitude: -81.32672, zipCode: "28672"},
	{latitude: 35.617717, longitude: -80.998758, zipCode: "28673"},
	{latitude: 36.506342, longitude: -81.136105, zipCode: "28675"},
	{latitude: 36.33186, longitude: -80.85534, zipCode: "28676"},
	{latitude: 35.738099, longitude: -80.926089, zipCode: "28677"},
	{latitude: 35.832604, longitude: -81.071143, zipCode: "28678"},
	{latitude: 36.260195, longitude: -81.840258, zipCode: "28679"},
	{latitude: 35.922678, longitude: -81.223058, zipCode: "28681"},
	{latitude: 35.588254, longitude: -80.965153, zipCode: "28682"},
	{latitude: 36.390439, longitude: -80.911007, zipCode: "28683"},
	{latitude: 36.33446, longitude: -81.606769, zipCode: "28684"},
	{latitude: 36.347273, longitude: -81.040959, zipCode: "28685"},
	{latitude: 36.043125, longitude: -80.933205, zipCode: "28689"},
	{latitude: 35.732735, longitude: -81.57423, zipCode: "28690"},
	{latitude: 36.314565, longitude: -81.837539, zipCode: "28692"},
	{latitude: 36.471773, longitude: -81.559009, zipCode: "28693"},
	{latitude: 36.366337, longitude: -81.468016, zipCode: "28694"},
	{latitude: 36.136665, longitude: -81.162721, zipCode: "28697"},
	{latitude: 36.314036, longitude: -81.760871, zipCode: "28698"},
	{latitude: 35.705098, longitude: -82.636467, zipCode: "28701"},
	{latitude: 35.379976, longitude: -83.641979, zipCode: "28702"},
	{latitude: 35.461429, longitude: -82.570815, zipCode: "28704"},
	{latitude: 36.035191, longitude: -82.15147, zipCode: "28705"},
	{latitude: 35.398716, longitude: -83.066194, zipCode: "28707"},
	{latitude: 35.271025, longitude: -82.859823, zipCode: "28708"},
	{latitude: 35.771399, longitude: -82.379244, zipCode: "28709"},
	{latitude: 35.456921, longitude: -82.279711, zipCode: "28710"},
	{latitude: 35.600604, longitude: -82.269687, zipCode: "28711"},
	{latitude: 35.173309, longitude: -82.770828, zipCode: "28712"},
	{latitude: 35.3657, longitude: -83.511664, zipCode: "28713"},
	{latitude: 35.878311, longitude: -82.304894, zipCode: "28714"},
	{latitude: 35.511966, longitude: -82.711701, zipCode: "28715"},
	{latitude: 35.439341, longitude: -82.847925, zipCode: "28716"},
	{latitude: 35.049543, longitude: -83.093326, zipCode: "28717"},
	{latitude: 35.159132, longitude: -82.634981, zipCode: "28718"},
	{latitude: 35.510891, longitude: -83.520266, zipCode: "28719"},
	{latitude: 35.447724, longitude: -82.262195, zipCode: "28720"},
	{latitude: 35.633768, longitude: -82.930786, zipCode: "28721"},
	{latitude: 35.208479, longitude: -82.061, zipCode: "28722"},
	{latitude: 35.314077, longitude: -83.013475, zipCode: "28723"},
	{latitude: 35.382223, longitude: -83.268112, zipCode: "28725"},
	{latitude: 35.280999, longitude: -82.416555, zipCode: "28726"},
	{latitude: 35.319556, longitude: -82.602883, zipCode: "28729"},
	{latitude: 35.5314, longitude: -82.359785, zipCode: "28730"},
	{latitude: 35.293544, longitude: -82.380326, zipCode: "28731"},
	{latitude: 35.451748, longitude: -82.445743, zipCode: "28732"},
	{latitude: 35.427397, longitude: -83.776971, zipCode: "28733"},
	{latitude: 35.179313, longitude: -83.436973, zipCode: "28734"},
	{latitude: 35.475405, longitude: -82.348507, zipCode: "28735"},
	{latitude: 35.175658, longitude: -83.086467, zipCode: "28736"},
	{latitude: 35.273876, longitude: -82.528602, zipCode: "28739"},
	{latitude: 36.025664, longitude: -82.305281, zipCode: "28740"},
	{latitude: 35.066425, longitude: -83.210613, zipCode: "28741"},
	{latitude: 35.38276, longitude: -82.651275, zipCode: "28742"},
	{latitude: 35.800283, longitude: -82.881224, zipCode: "28743"},
	{latitude: 35.522124, longitude: -82.973977, zipCode: "28745"},
	{latitude: 35.477191, longitude: -82.161693, zipCode: "28746"},
	{latitude: 35.145558, longitude: -82.917326, zipCode: "28747"},
	{latitude: 35.654923, longitude: -82.760267, zipCode: "28748"},
	{latitude: 35.841043, longitude: -82.097874, zipCode: "28749"},
	{latitude: 35.505476, longitude: -83.119563, zipCode: "28751"},
	{latitude: 35.709599, longitude: -82.035573, zipCode: "28752"},
	{latitude: 35.864206, longitude: -82.712631, zipCode: "28753"},
	{latitude: 35.876857, longitude: -82.514362, zipCode: "28754"},
	{latitude: 35.908203, longitude: -82.217538, zipCode: "28755"},
	{latitude: 35.355885, longitude: -82.208566, zipCode: "28756"},
	{latitude: 35.64804, longitude: -82.299164, zipCode: "28757"},
	{latitude: 35.374886, longitude: -82.495691, zipCode: "28758"},
	{latitude: 35.38135, longitude: -82.588899, zipCode: "28759"},
	{latitude: 35.690316, longitude: -81.906845, zipCode: "28761"},
	{latitude: 35.635334, longitude: -82.189772, zipCode: "28762"},
	{latitude: 35.028708, longitude: -83.465932, zipCode: "28763"},
	{latitude: 35.249443, longitude: -82.619253, zipCode: "28766"},
	{latitude: 35.325018, longitude: -82.707184, zipCode: "28768"},
	{latitude: 35.621106, longitude: -82.278167, zipCode: "28770"},
	{latitude: 35.342948, longitude: -83.854495, zipCode: "28771"},
	{latitude: 35.139069, longitude: -82.836919, zipCode: "28772"},
	{latitude: 35.261092, longitude: -82.29799, zipCode: "28773"},
	{latitude: 35.093536, longitude: -82.994977, zipCode: "28774"},
	{latitude: 35.019153, longitude: -83.321072, zipCode: "28775"},
	{latitude: 35.907661, longitude: -82.065327, zipCode: "28777"},
	{latitude: 35.614196, longitude: -82.39767, zipCode: "28778"},
	{latitude: 35.355752, longitude: -83.209682, zipCode: "28779"},
	{latitude: 35.224056, longitude: -83.635479, zipCode: "28781"},
	{latitude: 35.234277, longitude: -82.147383, zipCode: "28782"},
	{latitude: 35.242329, longitude: -83.021121, zipCode: "28783"},
	{latitude: 35.65327, longitude: -83.139633, zipCode: "28785"},
	{latitude: 35.462034, longitude: -82.988724, zipCode: "28786"},
	{latitude: 35.741533, longitude: -82.51564, zipCode: "28787"},
	{latitude: 35.35056, longitude: -83.22267, zipCode: "28788"},
	{latitude: 35.407057, longitude: -83.316777, zipCode: "28789"},
	{latitude: 35.198206, longitude: -82.495237, zipCode: "28790"},
	{latitude: 35.356985, longitude: -82.506995, zipCode: "28791"},
	{latitude: 35.394424, longitude: -82.368428, zipCode: "28792"},
	{latitude: 35.594539, longitude: -82.557972, zipCode: "28801"},
	{latitude: 35.531703, longitude: -82.523021, zipCode: "28803"},
	{latitude: 35.645008, longitude: -82.557723, zipCode: "28804"},
	{latitude: 35.613854, longitude: -82.479705, zipCode: "28805"},
	{latitude: 35.569645, longitude: -82.616787, zipCode: "28806"},
	{latitude: 35.201287, longitude: -83.805074, zipCode: "28901"},
	{latitude: 35.022948, longitude: -83.958294, zipCode: "28902"},
	{latitude: 35.06863, longitude: -83.733593, zipCode: "28904"},
	{latitude: 35.163064, longitude: -83.940936, zipCode: "28905"},
	{latitude: 35.087244, longitude: -84.136635, zipCode: "28906"},
	{latitude: 35.001138, longitude: -83.903263, zipCode: "28909"},
	{latitude: 33.786644, longitude: -80.174065, zipCode: "29001"},
	{latitude: 33.241221, longitude: -81.023815, zipCode: "29003"},
	{latitude: 33.869101, longitude: -81.551078, zipCode: "29006"},
	{latitude: 34.442983, longitude: -80.364579, zipCode: "29009"},
	{latitude: 34.223617, longitude: -80.272258, zipCode: "29010"},
	{latitude: 34.523382, longitude: -81.114513, zipCode: "29014"},
	{latitude: 34.44537, longitude: -81.337781, zipCode: "29015"},
	{latitude: 34.204016, longitude: -80.992765, zipCode: "29016"},
	{latitude: 33.348796, longitude: -80.639177, zipCode: "29018"},
	{latitude: 34.313938, longitude: -80.573806, zipCode: "29020"},
	{latitude: 33.576312, longitude: -80.647573, zipCode: "29030"},
	{latitude: 34.60681, longitude: -81.42897, zipCode: "29031"},
	{latitude: 34.337003, longitude: -80.444323, zipCode: "29032"},
	{latitude: 33.953093, longitude: -81.055204, zipCode: "29033"},
	{latitude: 34.134001, longitude: -81.339907, zipCode: "29036"},
	{latitude: 34.189935, longitude: -81.867694, zipCode: "29037"},
	{latitude: 33.38095, longitude: -80.981269, zipCode: "29038"},
	{latitude: 33.420441, longitude: -80.89215, zipCode: "29039"},
	{latitude: 34.060703, longitude: -80.437779, zipCode: "29040"},
	{latitude: 33.30556, longitude: -81.147853, zipCode: "29042"},
	{latitude: 33.92571, longitude: -80.695204, zipCode: "29044"},
	{latitude: 34.185078, longitude: -80.804984, zipCode: "29045"},
	{latitude: 34.112507, longitude: -80.161301, zipCode: "29046"},
	{latitude: 33.532178, longitude: -80.580231, zipCode: "29047"},
	{latitude: 33.390563, longitude: -80.295285, zipCode: "29048"},
	{latitude: 33.861626, longitude: -80.127828, zipCode: "29051"},
	{latitude: 33.798313, longitude: -80.741302, zipCode: "29052"},
	{latitude: 33.82754, longitude: -81.090895, zipCode: "29053"},
	{latitude: 33.947908, longitude: -81.382842, zipCode: "29054"},
	{latitude: 34.574244, longitude: -80.914866, zipCode: "29055"},
	{latitude: 33.608638, longitude: -80.005987, zipCode: "29056"},
	{latitude: 34.554283, longitude: -80.749684, zipCode: "29058"},
	{latitude: 33.329159, longitude: -80.419059, zipCode: "29059"},
	{latitude: 33.897493, longitude: -80.850604, zipCode: "29061"},
	{latitude: 34.004286, longitude: -80.605521, zipCode: "29062"},
	{latitude: 34.137162, longitude: -81.202313, zipCode: "29063"},
	{latitude: 34.298342, longitude: -81.317271, zipCode: "29065"},
	{latitude: 34.544166, longitude: -80.537094, zipCode: "29067"},
	{latitude: 34.180119, longitude: -80.094644, zipCode: "29069"},
	{latitude: 33.916667, longitude: -81.449233, zipCode: "29070"},
	{latitude: 33.998858, longitude: -81.272865, zipCode: "29072"},
	{latitude: 33.888025, longitude: -81.238637, zipCode: "29073"},
	{latitude: 34.441127, longitude: -80.818402, zipCode: "29074"},
	{latitude: 34.200659, longitude: -81.377808, zipCode: "29075"},
	{latitude: 34.181636, longitude: -80.699401, zipCode: "29078"},
	{latitude: 34.289086, longitude: -80.113167, zipCode: "29079"},
	{latitude: 34.012164, longitude: -80.085577, zipCode: "29080"},
	{latitude: 33.08908, longitude: -81.047373, zipCode: "29081"},
	{latitude: 33.038215, longitude: -80.939141, zipCode: "29082"},
	{latitude: 34.481778, longitude: -80.244607, zipCode: "29101"},
	{latitude: 33.654194, longitude: -80.202339, zipCode: "29102"},
	{latitude: 33.994689, longitude: -80.211764, zipCode: "29104"},
	{latitude: 33.806395, longitude: -81.593579, zipCode: "29105"},
	{latitude: 33.523387, longitude: -81.117556, zipCode: "29107"},
	{latitude: 34.305515, longitude: -81.627348, zipCode: "29108"},
	{latitude: 33.778496, longitude: -80.020582, zipCode: "29111"},
	{latitude: 33.625111, longitude: -81.08166, zipCode: "29112"},
	{latitude: 33.432442, longitude: -81.135333, zipCode: "29113"},
	{latitude: 33.954301, longitude: -79.932279, zipCode: "29114"},
	{latitude: 33.477717, longitude: -80.844199, zipCode: "29115"},
	{latitude: 33.498276, longitude: -80.850086, zipCode: "29117"},
	{latitude: 33.569394, longitude: -80.888558, zipCode: "29118"},
	{latitude: 34.238792, longitude: -81.328296, zipCode: "29122"},
	{latitude: 33.757417, longitude: -81.261261, zipCode: "29123"},
	{latitude: 33.695901, longitude: -80.474856, zipCode: "29125"},
	{latitude: 34.328933, longitude: -81.419482, zipCode: "29126"},
	{latitude: 34.153252, longitude: -81.512463, zipCode: "29127"},
	{latitude: 34.095702, longitude: -80.532082, zipCode: "29128"},
	{latitude: 33.789189, longitude: -81.672617, zipCode: "29129"},
	{latitude: 34.331606, longitude: -80.88041, zipCode: "29130"},
	{latitude: 33.358911, longitude: -80.803127, zipCode: "29133"},
	{latitude: 33.701789, longitude: -80.751062, zipCode: "29135"},
	{latitude: 33.596191, longitude: -81.321416, zipCode: "29137"},
	{latitude: 34.042392, longitude: -81.776152, zipCode: "29138"},
	{latitude: 33.465154, longitude: -80.522456, zipCode: "29142"},
	{latitude: 34.216762, longitude: -81.806759, zipCode: "29145"},
	{latitude: 33.508249, longitude: -81.295412, zipCode: "29146"},
	{latitude: 33.534926, longitude: -80.335816, zipCode: "29148"},
	{latitude: 33.818941, longitude: -80.316867, zipCode: "29150"},
	{latitude: 33.972657, longitude: -80.467319, zipCode: "29152"},
	{latitude: 33.970597, longitude: -80.305765, zipCode: "29153"},
	{latitude: 33.815261, longitude: -80.435274, zipCode: "29154"},
	{latitude: 33.746888, longitude: -81.035516, zipCode: "29160"},
	{latitude: 34.10365, longitude: -79.943426, zipCode: "29161"},
	{latitude: 33.889725, longitude: -80.008189, zipCode: "29162"},
	{latitude: 33.417579, longitude: -80.44645, zipCode: "29163"},
	{latitude: 33.658486, longitude: -81.430835, zipCode: "29164"},
	{latitude: 33.897095, longitude: -81.712255, zipCode: "29166"},
	{latitude: 33.848449, longitude: -80.540767, zipCode: "29168"},
	{latitude: 33.997503, longitude: -81.097406, zipCode: "29169"},
	{latitude: 33.939106, longitude: -81.144562, zipCode: "29170"},
	{latitude: 33.905765, longitude: -81.075502, zipCode: "29172"},
	{latitude: 34.442656, longitude: -80.606602, zipCode: "29175"},
	{latitude: 34.140505, longitude: -81.267506, zipCode: "29177"},
	{latitude: 34.495839, longitude: -81.589728, zipCode: "29178"},
	{latitude: 34.365471, longitude: -81.098531, zipCode: "29180"},
	{latitude: 33.982478, longitude: -81.028094, zipCode: "29201"},
	{latitude: 34.100612, longitude: -81.040698, zipCode: "29203"},
	{latitude: 34.029028, longitude: -80.999809, zipCode: "29204"},
	{latitude: 33.990015, longitude: -80.99663, zipCode: "29205"},
	{latitude: 34.028886, longitude: -80.960979, zipCode: "29206"},
	{latitude: 34.042447, longitude: -80.845986, zipCode: "29207"},
	{latitude: 33.997515, longitude: -81.026796, zipCode: "29208"},
	{latitude: 33.926751, longitude: -80.950364, zipCode: "29209"},
	{latitude: 34.045458, longitude: -81.110262, zipCode: "29210"},
	{latitude: 34.077553, longitude: -81.202336, zipCode: "29212"},
	{latitude: 34.092634, longitude: -80.919271, zipCode: "29223"},
	{latitude: 33.995404, longitude: -81.030051, zipCode: "29225"},
	{latitude: 34.145968, longitude: -80.894404, zipCode: "29229"},
	{latitude: 34.935234, longitude: -82.014769, zipCode: "29301"},
	{latitude: 34.884446, longitude: -81.8436, zipCode: "29302"},
	{latitude: 34.995296, longitude: -81.968368, zipCode: "29303"},
	{latitude: 34.892633, longitude: -81.921504, zipCode: "29306"},
	{latitude: 34.982716, longitude: -81.831555, zipCode: "29307"},
	{latitude: 35.043045, longitude: -81.975107, zipCode: "29316"},
	{latitude: 34.958567, longitude: -81.992583, zipCode: "29320"},
	{latitude: 34.714655, longitude: -81.741066, zipCode: "29321"},
	{latitude: 35.121094, longitude: -82.132432, zipCode: "29322"},
	{latitude: 35.126341, longitude: -81.907463, zipCode: "29323"},
	{latitude: 34.991295, longitude: -81.824132, zipCode: "29324"},
	{latitude: 34.468182, longitude: -81.845225, zipCode: "29325"},
	{latitude: 34.992453, longitude: -81.835984, zipCode: "29329"},
	{latitude: 35.052577, longitude: -81.80432, zipCode: "29330"},
	{latitude: 34.644241, longitude: -81.858033, zipCode: "29331"},
	{latitude: 34.278682, longitude: -81.970379, zipCode: "29332"},
	{latitude: 34.97095, longitude: -81.910754, zipCode: "29333"},
	{latitude: 34.905551, longitude: -82.124414, zipCode: "29334"},
	{latitude: 34.659271, longitude: -81.906657, zipCode: "29335"},
	{latitude: 35.134468, longitude: -82.001787, zipCode: "29338"},
	{latitude: 34.972313, longitude: -81.585917, zipCode: "29340"},
	{latitude: 35.119654, longitude: -81.712593, zipCode: "29341"},
	{latitude: 34.945184, longitude: -81.836208, zipCode: "29346"},
	{latitude: 35.067335, longitude: -82.068095, zipCode: "29349"},
	{latitude: 34.421036, longitude: -81.808045, zipCode: "29351"},
	{latitude: 34.830741, longitude: -81.648604, zipCode: "29353"},
	{latitude: 34.363443, longitude: -81.747608, zipCode: "29355"},
	{latitude: 35.146893, longitude: -82.260805, zipCode: "29356"},
	{latitude: 34.488003, longitude: -82.045762, zipCode: "29360"},
	{latitude: 34.788287, longitude: -81.462342, zipCode: "29364"},
	{latitude: 34.981062, longitude: -82.171924, zipCode: "29365"},
	{latitude: 35.082619, longitude: -81.862846, zipCode: "29368"},
	{latitude: 34.86562, longitude: -82.019463, zipCode: "29369"},
	{latitude: 34.373026, longitude: -81.977361, zipCode: "29370"},
	{latitude: 34.902124, longitude: -81.711842, zipCode: "29372"},
	{latitude: 34.92447, longitude: -81.743043, zipCode: "29373"},
	{latitude: 34.789284, longitude: -81.845267, zipCode: "29374"},
	{latitude: 34.860536, longitude: -82.113828, zipCode: "29375"},
	{latitude: 34.811382, longitude: -81.944219, zipCode: "29376"},
	{latitude: 34.930737, longitude: -82.096839, zipCode: "29377"},
	{latitude: 34.966792, longitude: -81.968697, zipCode: "29378"},
	{latitude: 34.687626, longitude: -81.601568, zipCode: "29379"},
	{latitude: 34.340252, longitude: -82.090181, zipCode: "29384"},
	{latitude: 34.972731, longitude: -82.101433, zipCode: "29385"},
	{latitude: 34.762969, longitude: -82.045689, zipCode: "29388"},
	{latitude: 32.77718, longitude: -79.932, zipCode: "29401"},
	{latitude: 32.803349, longitude: -79.945734, zipCode: "29403"},
	{latitude: 32.897841, longitude: -80.052445, zipCode: "29404"},
	{latitude: 32.852297, longitude: -79.980016, zipCode: "29405"},
	{latitude: 32.937294, longitude: -80.035355, zipCode: "29406"},
	{latitude: 32.79893, longitude: -79.997803, zipCode: "29407"},
	{latitude: 32.796655, longitude: -79.960706, zipCode: "29409"},
	{latitude: 32.938216, longitude: -80.014936, zipCode: "29410"},
	{latitude: 32.711961, longitude: -79.952187, zipCode: "29412"},
	{latitude: 32.842866, longitude: -80.104559, zipCode: "29414"},
	{latitude: 32.909495, longitude: -80.09566, zipCode: "29418"},
	{latitude: 32.932463, longitude: -80.100063, zipCode: "29420"},
	{latitude: 32.784887, longitude: -79.937725, zipCode: "29424"},
	{latitude: 32.78527, longitude: -79.948392, zipCode: "29425"},
	{latitude: 32.772145, longitude: -80.374085, zipCode: "29426"},
	{latitude: 32.988642, longitude: -79.696156, zipCode: "29429"},
	{latitude: 33.271438, longitude: -79.881694, zipCode: "29431"},
	{latitude: 33.228489, longitude: -80.813864, zipCode: "29432"},
	{latitude: 33.140888, longitude: -79.843725, zipCode: "29434"},
	{latitude: 32.953108, longitude: -80.466101, zipCode: "29435"},
	{latitude: 33.315358, longitude: -80.18725, zipCode: "29436"},
	{latitude: 33.135176, longitude: -80.417764, zipCode: "29437"},
	{latitude: 32.565933, longitude: -80.32173, zipCode: "29438"},
	{latitude: 32.652591, longitude: -79.95248, zipCode: "29439"},
	{latitude: 33.38111, longitude: -79.32976, zipCode: "29440"},
	{latitude: 32.997022, longitude: -79.966603, zipCode: "29445"},
	{latitude: 32.588889, longitude: -80.510785, zipCode: "29446"},
	{latitude: 33.255537, longitude: -80.452774, zipCode: "29448"},
	{latitude: 32.699809, longitude: -80.280925, zipCode: "29449"},
	{latitude: 33.087378, longitude: -79.771543, zipCode: "29450"},
	{latitude: 32.828009, longitude: -79.750481, zipCode: "29451"},
	{latitude: 32.717609, longitude: -80.470698, zipCode: "29452"},
	{latitude: 33.222884, longitude: -79.60735, zipCode: "29453"},
	{latitude: 32.684168, longitude: -80.091953, zipCode: "29455"},
	{latitude: 32.984201, longitude: -80.113273, zipCode: "29456"},
	{latitude: 33.119492, longitude: -79.486193, zipCode: "29458"},
	{latitude: 33.170981, longitude: -80.008578, zipCode: "29461"},
	{latitude: 32.819473, longitude: -79.857027, zipCode: "29464"},
	{latitude: 32.87555, longitude: -79.783739, zipCode: "29466"},
	{latitude: 33.427314, longitude: -80.079775, zipCode: "29468"},
	{latitude: 33.243672, longitude: -80.131055, zipCode: "29469"},
	{latitude: 32.814197, longitude: -80.210254, zipCode: "29470"},
	{latitude: 33.192654, longitude: -80.670479, zipCode: "29471"},
	{latitude: 33.054643, longitude: -80.325327, zipCode: "29472"},
	{latitude: 32.902724, longitude: -80.524038, zipCode: "29474"},
	{latitude: 32.966876, longitude: -80.819388, zipCode: "29475"},
	{latitude: 33.163169, longitude: -80.56521, zipCode: "29477"},
	{latitude: 33.380601, longitude: -79.890563, zipCode: "29479"},
	{latitude: 33.120379, longitude: -80.793077, zipCode: "29481"},
	{latitude: 32.770949, longitude: -79.831136, zipCode: "29482"},
	{latitude: 33.030187, longitude: -80.233763, zipCode: "29483"},
	{latitude: 32.933189, longitude: -80.207197, zipCode: "29485"},
	{latitude: 33.103211, longitude: -80.166653, zipCode: "29486"},
	{latitude: 32.657682, longitude: -80.183948, zipCode: "29487"},
	{latitude: 32.906763, longitude: -80.672836, zipCode: "29488"},
	{latitude: 32.909558, longitude: -79.879847, zipCode: "29492"},
	{latitude: 33.045319, longitude: -80.842769, zipCode: "29493"},
	{latitude: 34.204581, longitude: -79.82697, zipCode: "29501"},
	{latitude: 34.130066, longitude: -79.688893, zipCode: "29505"},
	{latitude: 34.210452, longitude: -79.649896, zipCode: "29506"},
	{latitude: 33.454312, longitude: -79.613121, zipCode: "29510"},
	{latitude: 33.975634, longitude: -79.122163, zipCode: "29511"},
	{latitude: 34.619033, longitude: -79.718041, zipCode: "29512"},
	{latitude: 34.442926, longitude: -79.614801, zipCode: "29516"},
	{latitude: 33.794814, longitude: -79.846611, zipCode: "29518"},
	{latitude: 34.016181, longitude: -79.360465, zipCode: "29519"},
	{latitude: 34.687998, longitude: -79.929523, zipCode: "29520"},
	{latitude: 34.563074, longitude: -79.515487, zipCode: "29525"},
	{latitude: 33.867767, longitude: -78.976526, zipCode: "29526"},
	{latitude: 33.785083, longitude: -79.140176, zipCode: "29527"},
	{latitude: 33.996055, longitude: -79.740075, zipCode: "29530"},
	{latitude: 34.282779, longitude: -79.850175, zipCode: "29532"},
	{latitude: 34.418937, longitude: -79.371955, zipCode: "29536"},
	{latitude: 34.383832, longitude: -79.850462, zipCode: "29540"},
	{latitude: 34.064949, longitude: -79.74075, zipCode: "29541"},
	{latitude: 34.2876, longitude: -79.265925, zipCode: "29543"},
	{latitude: 34.001578, longitude: -79.220764, zipCode: "29544"},
	{latitude: 34.16366, longitude: -78.966477, zipCode: "29545"},
	{latitude: 33.883845, longitude: -79.349409, zipCode: "29546"},
	{latitude: 34.488143, longitude: -79.32805, zipCode: "29547"},
	{latitude: 34.39327, longitude: -80.077031, zipCode: "29550"},
	{latitude: 33.70264, longitude: -79.392499, zipCode: "29554"},
	{latitude: 33.840906, longitude: -79.457601, zipCode: "29555"},
	{latitude: 33.668227, longitude: -79.763139, zipCode: "29556"},
	{latitude: 33.838702, longitude: -79.749876, zipCode: "29560"},
	{latitude: 34.35334, longitude: -79.208671, zipCode: "29563"},
	{latitude: 33.475902, longitude: -79.856124, zipCode: "29564"},
	{latitude: 34.358052, longitude: -79.499289, zipCode: "29565"},
	{latitude: 33.888951, longitude: -78.659429, zipCode: "29566"},
	{latitude: 34.560972, longitude: -79.432683, zipCode: "29567"},
	{latitude: 33.913616, longitude: -78.750292, zipCode: "29568"},
	{latitude: 34.03263, longitude: -78.911584, zipCode: "29569"},
	{latitude: 34.667866, longitude: -79.562707, zipCode: "29570"},
	{latitude: 34.135077, longitude: -79.425051, zipCode: "29571"},
	{latitude: 33.769526, longitude: -78.792285, zipCode: "29572"},
	{latitude: 34.165413, longitude: -79.260831, zipCode: "29574"},
	{latitude: 33.629139, longitude: -78.970404, zipCode: "29575"},
	{latitude: 33.569819, longitude: -79.053506, zipCode: "29576"},
	{latitude: 33.697638, longitude: -78.906484, zipCode: "29577"},
	{latitude: 33.76636, longitude: -78.913478, zipCode: "29579"},
	{latitude: 33.653101, longitude: -79.551975, zipCode: "29580"},
	{latitude: 34.194863, longitude: -79.114239, zipCode: "29581"},
	{latitude: 33.832049, longitude: -78.654824, zipCode: "29582"},
	{latitude: 33.977851, longitude: -79.571077, zipCode: "29583"},
	{latitude: 34.576481, longitude: -80.053649, zipCode: "29584"},
	{latitude: 33.556464, longitude: -79.088953, zipCode: "29585"},
	{latitude: 33.670794, longitude: -79.023125, zipCode: "29588"},
	{latitude: 34.100602, longitude: -79.320161, zipCode: "29589"},
	{latitude: 33.56892, longitude: -79.848696, zipCode: "29590"},
	{latitude: 33.937999, longitude: -79.76229, zipCode: "29591"},
	{latitude: 34.245086, longitude: -79.454441, zipCode: "29592"},
	{latitude: 34.493107, longitude: -79.856964, zipCode: "29593"},
	{latitude: 34.640233, longitude: -79.57736, zipCode: "29594"},
	{latitude: 34.736119, longitude: -79.851195, zipCode: "29596"},
	{latitude: 34.847311, longitude: -82.402121, zipCode: "29601"},
	{latitude: 34.778047, longitude: -82.380192, zipCode: "29605"},
	{latitude: 34.809603, longitude: -82.328865, zipCode: "29607"},
	{latitude: 34.912592, longitude: -82.38817, zipCode: "29609"},
	{latitude: 34.830934, longitude: -82.458497, zipCode: "29611"},
	{latitude: 34.924255, longitude: -82.440488, zipCode: "29613"},
	{latitude: 34.873714, longitude: -82.363011, zipCode: "29614"},
	{latitude: 34.856825, longitude: -82.296139, zipCode: "29615"},
	{latitude: 34.914439, longitude: -82.466074, zipCode: "29617"},
	{latitude: 34.182407, longitude: -82.425607, zipCode: "29620"},
	{latitude: 34.506294, longitude: -82.603598, zipCode: "29621"},
	{latitude: 34.435943, longitude: -82.624115, zipCode: "29624"},
	{latitude: 34.555519, longitude: -82.765621, zipCode: "29625"},
	{latitude: 34.460802, longitude: -82.756514, zipCode: "29626"},
	{latitude: 34.518591, longitude: -82.480284, zipCode: "29627"},
	{latitude: 34.08847, longitude: -82.564499, zipCode: "29628"},
	{latitude: 34.738673, longitude: -82.799161, zipCode: "29630"},
	{latitude: 34.681593, longitude: -82.817762, zipCode: "29631"},
	{latitude: 34.6756, longitude: -82.835245, zipCode: "29634"},
	{latitude: 35.07649, longitude: -82.634765, zipCode: "29635"},
	{latitude: 34.363867, longitude: -82.338204, zipCode: "29638"},
	{latitude: 34.303669, longitude: -82.428794, zipCode: "29639"},
	{latitude: 34.87771, longitude: -82.575421, zipCode: "29640"},
	{latitude: 34.776737, longitude: -82.563612, zipCode: "29642"},
	{latitude: 34.507566, longitude: -82.975315, zipCode: "29643"},
	{latitude: 34.666107, longitude: -82.195445, zipCode: "29644"},
	{latitude: 34.587727, longitude: -82.127358, zipCode: "29645"},
	{latitude: 34.136053, longitude: -82.14847, zipCode: "29646"},
	{latitude: 34.238919, longitude: -82.145019, zipCode: "29649"},
	{latitude: 34.897991, longitude: -82.258012, zipCode: "29650"},
	{latitude: 35.02383, longitude: -82.279564, zipCode: "29651"},
	{latitude: 34.306003, longitude: -82.232469, zipCode: "29653"},
	{latitude: 34.455541, longitude: -82.370225, zipCode: "29654"},
	{latitude: 34.26525, longitude: -82.632434, zipCode: "29655"},
	{latitude: 34.767066, longitude: -82.686594, zipCode: "29657"},
	{latitude: 34.771546, longitude: -83.303341, zipCode: "29658"},
	{latitude: 34.211685, longitude: -82.64531, zipCode: "29659"},
	{latitude: 35.057496, longitude: -82.554666, zipCode: "29661"},
	{latitude: 34.779208, longitude: -82.30308, zipCode: "29662"},
	{latitude: 34.860888, longitude: -83.157553, zipCode: "29664"},
	{latitude: 34.724825, longitude: -82.914632, zipCode: "29665"},
	{latitude: 34.113326, longitude: -81.979552, zipCode: "29666"},
	{latitude: 34.764327, longitude: -82.756529, zipCode: "29667"},
	{latitude: 34.638704, longitude: -82.412542, zipCode: "29669"},
	{latitude: 34.643979, longitude: -82.723237, zipCode: "29670"},
	{latitude: 34.943796, longitude: -82.72112, zipCode: "29671"},
	{latitude: 34.751685, longitude: -82.932051, zipCode: "29672"},
	{latitude: 34.720191, longitude: -82.455248, zipCode: "29673"},
	{latitude: 34.953666, longitude: -82.984956, zipCode: "29676"},
	{latitude: 34.63447, longitude: -82.937301, zipCode: "29678"},
	{latitude: 34.684858, longitude: -82.293791, zipCode: "29680"},
	{latitude: 34.767679, longitude: -82.225003, zipCode: "29681"},
	{latitude: 34.836191, longitude: -82.853397, zipCode: "29682"},
	{latitude: 35.030088, longitude: -82.492939, zipCode: "29683"},
	{latitude: 34.379432, longitude: -82.718704, zipCode: "29684"},
	{latitude: 34.983435, longitude: -82.844869, zipCode: "29685"},
	{latitude: 34.958205, longitude: -83.053088, zipCode: "29686"},
	{latitude: 34.986636, longitude: -82.327451, zipCode: "29687"},
	{latitude: 34.5367, longitude: -82.87594, zipCode: "29689"},
	{latitude: 35.059806, longitude: -82.417198, zipCode: "29690"},
	{latitude: 34.783623, longitude: -83.076927, zipCode: "29691"},
	{latitude: 34.411805, longitude: -82.219468, zipCode: "29692"},
	{latitude: 34.641751, longitude: -83.154596, zipCode: "29693"},
	{latitude: 34.781122, longitude: -83.008462, zipCode: "29696"},
	{latitude: 34.625593, longitude: -82.548639, zipCode: "29697"},
	{latitude: 35.116068, longitude: -81.473073, zipCode: "29702"},
	{latitude: 34.83212, longitude: -80.909792, zipCode: "29704"},
	{latitude: 34.715558, longitude: -81.23453, zipCode: "29706"},
	{latitude: 34.987552, longitude: -80.858188, zipCode: "29707"},
	{latitude: 35.048227, longitude: -80.986659, zipCode: "29708"},
	{latitude: 34.718509, longitude: -80.098942, zipCode: "29709"},
	{latitude: 35.106284, longitude: -81.221471, zipCode: "29710"},
	{latitude: 34.797966, longitude: -80.975531, zipCode: "29712"},
	{latitude: 34.711779, longitude: -80.913775, zipCode: "29714"},
	{latitude: 35.011342, longitude: -80.927133, zipCode: "29715"},
	{latitude: 34.95073, longitude: -81.448855, zipCode: "29717"},
	{latitude: 34.629803, longitude: -80.357614, zipCode: "29718"},
	{latitude: 34.745312, longitude: -80.743312, zipCode: "29720"},
	{latitude: 34.775005, longitude: -81.012539, zipCode: "29724"},
	{latitude: 34.860853, longitude: -81.236148, zipCode: "29726"},
	{latitude: 34.719265, longitude: -80.264601, zipCode: "29727"},
	{latitude: 34.764953, longitude: -80.400558, zipCode: "29728"},
	{latitude: 34.689134, longitude: -81.00676, zipCode: "29729"},
	{latitude: 34.884936, longitude: -81.02115, zipCode: "29730"},
	{latitude: 34.970716, longitude: -81.081364, zipCode: "29732"},
	{latitude: 34.939453, longitude: -81.031719, zipCode: "29733"},
	{latitude: 34.725288, longitude: -80.195526, zipCode: "29741"},
	{latitude: 34.869981, longitude: -81.392261, zipCode: "29742"},
	{latitude: 35.026136, longitude: -81.423067, zipCode: "29743"},
	{latitude: 34.990875, longitude: -81.214056, zipCode: "29745"},
	{latitude: 33.585864, longitude: -81.691726, zipCode: "29801"},
	{latitude: 33.453473, longitude: -81.701926, zipCode: "29803"},
	{latitude: 33.641872, longitude: -81.614053, zipCode: "29805"},
	{latitude: 33.416161, longitude: -81.689293, zipCode: "29809"},
	{latitude: 32.986368, longitude: -81.37811, zipCode: "29810"},
	{latitude: 33.214437, longitude: -81.383394, zipCode: "29812"},
	{latitude: 33.502122, longitude: -81.872627, zipCode: "29816"},
	{latitude: 33.36951, longitude: -81.270761, zipCode: "29817"},
	{latitude: 34.044227, longitude: -82.227378, zipCode: "29819"},
	{latitude: 33.636618, longitude: -82.115231, zipCode: "29821"},
	{latitude: 33.814545, longitude: -81.993868, zipCode: "29824"},
	{latitude: 33.385382, longitude: -81.359584, zipCode: "29826"},
	{latitude: 32.970088, longitude: -81.242285, zipCode: "29827"},
	{latitude: 33.523428, longitude: -81.82963, zipCode: "29828"},
	{latitude: 33.57472, longitude: -81.857718, zipCode: "29829"},
	{latitude: 33.338725, longitude: -81.864111, zipCode: "29831"},
	{latitude: 33.839003, longitude: -81.803671, zipCode: "29832"},
	{latitude: 33.517756, longitude: -81.843006, zipCode: "29834"},
	{latitude: 33.920581, longitude: -82.29452, zipCode: "29835"},
	{latitude: 33.085199, longitude: -81.496035, zipCode: "29836"},
	{latitude: 33.747491, longitude: -82.15107, zipCode: "29838"},
	{latitude: 33.996543, longitude: -82.542575, zipCode: "29840"},
	{latitude: 33.526407, longitude: -81.939337, zipCode: "29841"},
	{latitude: 33.457043, longitude: -81.86071, zipCode: "29842"},
	{latitude: 33.182069, longitude: -81.179772, zipCode: "29843"},
	{latitude: 33.785183, longitude: -82.214131, zipCode: "29844"},
	{latitude: 33.825859, longitude: -82.213392, zipCode: "29845"},
	{latitude: 33.704138, longitude: -81.847277, zipCode: "29847"},
	{latitude: 33.972317, longitude: -82.060396, zipCode: "29848"},
	{latitude: 33.103626, longitude: -81.238347, zipCode: "29849"},
	{latitude: 33.511092, longitude: -81.815851, zipCode: "29851"},
	{latitude: 33.399205, longitude: -81.431756, zipCode: "29853"},
	{latitude: 33.485256, longitude: -81.511831, zipCode: "29856"},
	{latitude: 33.614688, longitude: -81.984428, zipCode: "29860"},
	{latitude: 33.928023, longitude: -82.250602, zipCode: "29899"},
	{latitude: 32.317597, longitude: -80.682213, zipCode: "29902"},
	{latitude: 32.478848, longitude: -80.721951, zipCode: "29904"},
	{latitude: 32.355371, longitude: -80.700252, zipCode: "29905"},
	{latitude: 32.445112, longitude: -80.752875, zipCode: "29906"},
	{latitude: 32.474692, longitude: -80.599222, zipCode: "29907"},
	{latitude: 32.336992, longitude: -80.848074, zipCode: "29909"},
	{latitude: 32.217824, longitude: -80.894994, zipCode: "29910"},
	{latitude: 32.928833, longitude: -81.155189, zipCode: "29911"},
	{latitude: 32.584548, longitude: -80.928494, zipCode: "29912"},
	{latitude: 32.118706, longitude: -80.861178, zipCode: "29915"},
	{latitude: 32.728963, longitude: -80.958706, zipCode: "29916"},
	{latitude: 32.744462, longitude: -81.247365, zipCode: "29918"},
	{latitude: 32.36422, longitude: -80.55361, zipCode: "29920"},
	{latitude: 32.686639, longitude: -81.180144, zipCode: "29921"},
	{latitude: 32.588331, longitude: -81.280061, zipCode: "29922"},
	{latitude: 32.848248, longitude: -81.238594, zipCode: "29923"},
	{latitude: 32.876878, longitude: -81.111255, zipCode: "29924"},
	{latitude: 32.237257, longitude: -80.734276, zipCode: "29926"},
	{latitude: 32.24664, longitude: -81.057508, zipCode: "29927"},
	{latitude: 32.167062, longitude: -80.753591, zipCode: "29928"},
	{latitude: 32.955198, longitude: -80.923822, zipCode: "29929"},
	{latitude: 32.833497, longitude: -81.344326, zipCode: "29932"},
	{latitude: 32.595031, longitude: -81.112502, zipCode: "29934"},
	{latitude: 32.382648, longitude: -80.695597, zipCode: "29935"},
	{latitude: 32.483364, longitude: -80.966253, zipCode: "29936"},
	{latitude: 32.681259, longitude: -81.245338, zipCode: "29939"},
	{latitude: 32.552743, longitude: -80.677693, zipCode: "29940"},
	{latitude: 32.534804, longitude: -80.819692, zipCode: "29941"},
	{latitude: 32.491142, longitude: -81.146707, zipCode: "29943"},
	{latitude: 32.795699, longitude: -81.034521, zipCode: "29944"},
	{latitude: 32.681465, longitude: -80.795863, zipCode: "29945"},
	{latitude: 33.773335, longitude: -84.261667, zipCode: "30002"},
	{latitude: 34.145635, longitude: -84.291881, zipCode: "30004"},
	{latitude: 34.08905, longitude: -84.217019, zipCode: "30005"},
	{latitude: 33.900567, longitude: -84.590924, zipCode: "30008"},
	{latitude: 34.078136, longitude: -84.303424, zipCode: "30009"},
	{latitude: 34.019859, longitude: -83.837163, zipCode: "30011"},
	{latitude: 33.717806, longitude: -84.002515, zipCode: "30012"},
	{latitude: 33.642603, longitude: -83.971853, zipCode: "30013"},
	{latitude: 33.587193, longitude: -83.822173, zipCode: "30014"},
	{latitude: 33.517433, longitude: -83.928703, zipCode: "30016"},
	{latitude: 33.888722, longitude: -83.963687, zipCode: "30017"},
	{latitude: 33.71689, longitude: -83.804251, zipCode: "30018"},
	{latitude: 33.975634, longitude: -83.883769, zipCode: "30019"},
	{latitude: 33.807995, longitude: -84.238975, zipCode: "30021"},
	{latitude: 34.026085, longitude: -84.247433, zipCode: "30022"},
	{latitude: 34.063485, longitude: -84.0911, zipCode: "30024"},
	{latitude: 33.655983, longitude: -83.695765, zipCode: "30025"},
	{latitude: 34.293159, longitude: -84.175907, zipCode: "30028"},
	{latitude: 33.772712, longitude: -84.291005, zipCode: "30030"},
	{latitude: 33.740668, longitude: -84.264466, zipCode: "30032"},
	{latitude: 33.813689, longitude: -84.284377, zipCode: "30033"},
	{latitude: 33.690867, longitude: -84.248622, zipCode: "30034"},
	{latitude: 33.724452, longitude: -84.204244, zipCode: "30035"},
	{latitude: 33.66646, longitude: -84.139855, zipCode: "30038"},
	{latitude: 33.799244, longitude: -84.034408, zipCode: "30039"},
	{latitude: 34.223434, longitude: -84.17644, zipCode: "30040"},
	{latitude: 34.196222, longitude: -84.096676, zipCode: "30041"},
	{latitude: 34.010038, longitude: -84.005043, zipCode: "30043"},
	{latitude: 33.921502, longitude: -84.068873, zipCode: "30044"},
	{latitude: 33.936518, longitude: -83.927669, zipCode: "30045"},
	{latitude: 33.949702, longitude: -83.993451, zipCode: "30046"},
	{latitude: 33.870125, longitude: -84.112322, zipCode: "30047"},
	{latitude: 33.817047, longitude: -83.897315, zipCode: "30052"},
	{latitude: 33.678353, longitude: -83.88296, zipCode: "30054"},
	{latitude: 33.497411, longitude: -83.749784, zipCode: "30055"},
	{latitude: 33.491974, longitude: -83.650144, zipCode: "30056"},
	{latitude: 33.73835, longitude: -84.107376, zipCode: "30058"},
	{latitude: 33.920062, longitude: -84.5431, zipCode: "30060"},
	{latitude: 34.006497, longitude: -84.472974, zipCode: "30062"},
	{latitude: 33.940455, longitude: -84.609708, zipCode: "30064"},
	{latitude: 34.032388, longitude: -84.505154, zipCode: "30066"},
	{latitude: 33.934278, longitude: -84.462185, zipCode: "30067"},
	{latitude: 33.968116, longitude: -84.431704, zipCode: "30068"},
	{latitude: 33.573282, longitude: -83.895589, zipCode: "30070"},
	{latitude: 33.940652, longitude: -84.206734, zipCode: "30071"},
	{latitude: 33.79141, longitude: -84.204844, zipCode: "30072"},
	{latitude: 34.052688, longitude: -84.391587, zipCode: "30075"},
	{latitude: 34.028568, longitude: -84.311368, zipCode: "30076"},
	{latitude: 33.861019, longitude: -84.017993, zipCode: "30078"},
	{latitude: 33.791739, longitude: -84.260747, zipCode: "30079"},
	{latitude: 33.877818, longitude: -84.501703, zipCode: "30080"},
	{latitude: 33.854654, longitude: -84.536437, zipCode: "30082"},
	{latitude: 33.796597, longitude: -84.192603, zipCode: "30083"},
	{latitude: 33.854097, longitude: -84.216155, zipCode: "30084"},
	{latitude: 33.791402, longitude: -84.098277, zipCode: "30087"},
	{latitude: 33.756052, longitude: -84.182363, zipCode: "30088"},
	{latitude: 33.953141, longitude: -84.548159, zipCode: "30090"},
	{latitude: 33.972386, longitude: -84.231986, zipCode: "30092"},
	{latitude: 33.908911, longitude: -84.17924, zipCode: "30093"},
	{latitude: 33.612505, longitude: -84.051523, zipCode: "30094"},
	{latitude: 33.974302, longitude: -84.145384, zipCode: "30096"},
	{latitude: 34.026335, longitude: -84.145677, zipCode: "30097"},
	{latitude: 34.034515, longitude: -84.707349, zipCode: "30101"},
	{latitude: 34.101625, longitude: -84.645168, zipCode: "30102"},
	{latitude: 34.363242, longitude: -84.920192, zipCode: "30103"},
	{latitude: 34.088874, longitude: -85.070382, zipCode: "30104"},
	{latitude: 34.432548, longitude: -85.172442, zipCode: "30105"},
	{latitude: 33.83288, longitude: -84.628055, zipCode: "30106"},
	{latitude: 34.336098, longitude: -84.345464, zipCode: "30107"},
	{latitude: 33.5317, longitude: -85.262996, zipCode: "30108"},
	{latitude: 33.739243, longitude: -85.135434, zipCode: "30110"},
	{latitude: 33.831784, longitude: -84.65504, zipCode: "30111"},
	{latitude: 33.850746, longitude: -85.205308, zipCode: "30113"},
	{latitude: 34.243544, longitude: -84.535815, zipCode: "30114"},
	{latitude: 34.215519, longitude: -84.402426, zipCode: "30115"},
	{latitude: 33.539118, longitude: -84.99561, zipCode: "30116"},
	{latitude: 33.581851, longitude: -85.132439, zipCode: "30117"},
	{latitude: 33.572902, longitude: -85.099437, zipCode: "30118"},
	{latitude: 34.175229, longitude: -84.860036, zipCode: "30120"},
	{latitude: 34.211854, longitude: -84.77693, zipCode: "30121"},
	{latitude: 33.766909, longitude: -84.641356, zipCode: "30122"},
	{latitude: 34.133736, longitude: -85.345827, zipCode: "30124"},
	{latitude: 34.000642, longitude: -85.276805, zipCode: "30125"},
	{latitude: 33.815023, longitude: -84.553798, zipCode: "30126"},
	{latitude: 33.875084, longitude: -84.69934, zipCode: "30127"},
	{latitude: 33.977608, longitude: -84.851541, zipCode: "30132"},
	{latitude: 33.77752, longitude: -84.78139, zipCode: "30134"},
	{latitude: 33.672278, longitude: -84.733011, zipCode: "30135"},
	{latitude: 34.116557, longitude: -84.7657, zipCode: "30137"},
	{latitude: 34.432341, longitude: -84.705495, zipCode: "30139"},
	{latitude: 33.860444, longitude: -84.769846, zipCode: "30141"},
	{latitude: 34.462756, longitude: -84.425227, zipCode: "30143"},
	{latitude: 34.036845, longitude: -84.591033, zipCode: "30144"},
	{latitude: 34.24566, longitude: -84.985878, zipCode: "30145"},
	{latitude: 34.13494, longitude: -85.22206, zipCode: "30147"},
	{latitude: 34.46049, longitude: -84.265701, zipCode: "30148"},
	{latitude: 34.316789, longitude: -85.220289, zipCode: "30149"},
	{latitude: 33.991564, longitude: -84.64687, zipCode: "30152"},
	{latitude: 33.973822, longitude: -85.052856, zipCode: "30153"},
	{latitude: 33.884746, longitude: -84.871949, zipCode: "30157"},
	{latitude: 34.23623, longitude: -85.173993, zipCode: "30161"},
	{latitude: 34.315788, longitude: -85.295122, zipCode: "30165"},
	{latitude: 33.783542, longitude: -84.588106, zipCode: "30168"},
	{latitude: 33.432792, longitude: -85.164674, zipCode: "30170"},
	{latitude: 34.363353, longitude: -84.727435, zipCode: "30171"},
	{latitude: 34.133121, longitude: -85.153505, zipCode: "30173"},
	{latitude: 34.534915, longitude: -84.526711, zipCode: "30175"},
	{latitude: 33.771314, longitude: -85.30439, zipCode: "30176"},
	{latitude: 34.407132, longitude: -84.373997, zipCode: "30177"},
	{latitude: 34.095453, longitude: -84.971074, zipCode: "30178"},
	{latitude: 33.781553, longitude: -85.014782, zipCode: "30179"},
	{latitude: 33.718878, longitude: -84.918801, zipCode: "30180"},
	{latitude: 33.655819, longitude: -85.248832, zipCode: "30182"},
	{latitude: 34.338804, longitude: -84.602425, zipCode: "30183"},
	{latitude: 34.252062, longitude: -84.741056, zipCode: "30184"},
	{latitude: 33.519802, longitude: -84.918098, zipCode: "30185"},
	{latitude: 33.652761, longitude: -84.856249, zipCode: "30187"},
	{latitude: 34.123533, longitude: -84.457654, zipCode: "30188"},
	{latitude: 34.124224, longitude: -84.570679, zipCode: "30189"},
	{latitude: 33.056208, longitude: -84.121725, zipCode: "30204"},
	{latitude: 33.267427, longitude: -84.475116, zipCode: "30205"},
	{latitude: 33.096064, longitude: -84.458259, zipCode: "30206"},
	{latitude: 33.586742, longitude: -84.624426, zipCode: "30213"},
	{latitude: 33.490464, longitude: -84.485997, zipCode: "30214"},
	{latitude: 33.391613, longitude: -84.456432, zipCode: "30215"},
	{latitude: 33.228834, longitude: -83.884863, zipCode: "30216"},
	{latitude: 33.291347, longitude: -85.137886, zipCode: "30217"},
	{latitude: 33.120297, longitude: -84.587455, zipCode: "30218"},
	{latitude: 33.230839, longitude: -84.82486, zipCode: "30220"},
	{latitude: 33.037153, longitude: -84.73467, zipCode: "30222"},
	{latitude: 33.288794, longitude: -84.279919, zipCode: "30223"},
	{latitude: 33.204432, longitude: -84.239735, zipCode: "30224"},
	{latitude: 33.400608, longitude: -84.307728, zipCode: "30228"},
	{latitude: 33.227934, longitude: -84.570341, zipCode: "30229"},
	{latitude: 33.166737, longitude: -84.920497, zipCode: "30230"},
	{latitude: 33.294709, longitude: -83.975561, zipCode: "30233"},
	{latitude: 33.332567, longitude: -84.025298, zipCode: "30234"},
	{latitude: 33.519927, longitude: -84.325187, zipCode: "30236"},
	{latitude: 33.501846, longitude: -84.382236, zipCode: "30238"},
	{latitude: 33.036764, longitude: -85.120092, zipCode: "30240"},
	{latitude: 33.021712, longitude: -84.95623, zipCode: "30241"},
	{latitude: 33.35046, longitude: -84.109119, zipCode: "30248"},
	{latitude: 33.434846, longitude: -84.306078, zipCode: "30250"},
	{latitude: 33.198174, longitude: -84.698112, zipCode: "30251"},
	{latitude: 33.473512, longitude: -84.057165, zipCode: "30252"},
	{latitude: 33.453601, longitude: -84.187167, zipCode: "30253"},
	{latitude: 33.007484, longitude: -84.3099, zipCode: "30256"},
	{latitude: 33.143414, longitude: -84.18622, zipCode: "30257"},
	{latitude: 32.990854, longitude: -84.470041, zipCode: "30258"},
	{latitude: 33.265699, longitude: -84.741743, zipCode: "30259"},
	{latitude: 33.587165, longitude: -84.334307, zipCode: "30260"},
	{latitude: 33.388415, longitude: -84.858937, zipCode: "30263"},
	{latitude: 33.418686, longitude: -84.713164, zipCode: "30265"},
	{latitude: 33.54643, longitude: -84.722632, zipCode: "30268"},
	{latitude: 33.393891, longitude: -84.569301, zipCode: "30269"},
	{latitude: 33.623122, longitude: -84.501756, zipCode: "30272"},
	{latitude: 33.583798, longitude: -84.271027, zipCode: "30273"},
	{latitude: 33.557264, longitude: -84.400508, zipCode: "30274"},
	{latitude: 33.430654, longitude: -84.867864, zipCode: "30275"},
	{latitude: 33.263314, longitude: -84.583456, zipCode: "30276"},
	{latitude: 33.375308, longitude: -84.649196, zipCode: "30277"},
	{latitude: 33.556897, longitude: -84.195159, zipCode: "30281"},
	{latitude: 33.340288, longitude: -84.288571, zipCode: "30284"},
	{latitude: 32.987673, longitude: -84.258191, zipCode: "30285"},
	{latitude: 32.881118, longitude: -84.333168, zipCode: "30286"},
	{latitude: 33.652897, longitude: -84.327107, zipCode: "30288"},
	{latitude: 33.323596, longitude: -84.633698, zipCode: "30289"},
	{latitude: 33.476407, longitude: -84.589704, zipCode: "30290"},
	{latitude: 33.575883, longitude: -84.543771, zipCode: "30291"},
	{latitude: 33.168489, longitude: -84.393225, zipCode: "30292"},
	{latitude: 32.979078, longitude: -84.596302, zipCode: "30293"},
	{latitude: 33.636692, longitude: -84.264986, zipCode: "30294"},
	{latitude: 33.090342, longitude: -84.317583, zipCode: "30295"},
	{latitude: 33.56316, longitude: -84.441157, zipCode: "30296"},
	{latitude: 33.614988, longitude: -84.371672, zipCode: "30297"},
	{latitude: 33.752845, longitude: -84.390226, zipCode: "30303"},
	{latitude: 33.834063, longitude: -84.392063, zipCode: "30305"},
	{latitude: 33.78814, longitude: -84.352625, zipCode: "30306"},
	{latitude: 33.771079, longitude: -84.3336, zipCode: "30307"},
	{latitude: 33.770959, longitude: -84.377736, zipCode: "30308"},
	{latitude: 33.799851, longitude: -84.385837, zipCode: "30309"},
	{latitude: 33.726889, longitude: -84.428926, zipCode: "30310"},
	{latitude: 33.723262, longitude: -84.475994, zipCode: "30311"},
	{latitude: 33.744616, longitude: -84.375814, zipCode: "30312"},
	{latitude: 33.763613, longitude: -84.39767, zipCode: "30313"},
	{latitude: 33.757861, longitude: -84.431963, zipCode: "30314"},
	{latitude: 33.702741, longitude: -84.382853, zipCode: "30315"},
	{latitude: 33.716794, longitude: -84.331343, zipCode: "30316"},
	{latitude: 33.747989, longitude: -84.315586, zipCode: "30317"},
	{latitude: 33.79266, longitude: -84.44801, zipCode: "30318"},
	{latitude: 33.875954, longitude: -84.334763, zipCode: "30319"},
	{latitude: 33.794593, longitude: -84.325236, zipCode: "30322"},
	{latitude: 33.818405, longitude: -84.358175, zipCode: "30324"},
	{latitude: 33.849615, longitude: -84.363508, zipCode: "30326"},
	{latitude: 33.869694, longitude: -84.4173, zipCode: "30327"},
	{latitude: 33.933259, longitude: -84.386656, zipCode: "30328"},
	{latitude: 33.827245, longitude: -84.322148, zipCode: "30329"},
	{latitude: 33.705434, longitude: -84.544076, zipCode: "30331"},
	{latitude: 33.777618, longitude: -84.404348, zipCode: "30332"},
	{latitude: 33.748652, longitude: -84.387894, zipCode: "30334"},
	{latitude: 33.735444, longitude: -84.568978, zipCode: "30336"},
	{latitude: 33.640147, longitude: -84.450081, zipCode: "30337"},
	{latitude: 33.946218, longitude: -84.318119, zipCode: "30338"},
	{latitude: 33.86757, longitude: -84.4646, zipCode: "30339"},
	{latitude: 33.897873, longitude: -84.252804, zipCode: "30340"},
	{latitude: 33.888505, longitude: -84.289532, zipCode: "30341"},
	{latitude: 33.88435, longitude: -84.374964, zipCode: "30342"},
	{latitude: 33.676636, longitude: -84.461601, zipCode: "30344"},
	{latitude: 33.851652, longitude: -84.283685, zipCode: "30345"},
	{latitude: 33.924277, longitude: -84.338809, zipCode: "30346"},
	{latitude: 33.622488, longitude: -84.523908, zipCode: "30349"},
	{latitude: 33.980254, longitude: -84.330016, zipCode: "30350"},
	{latitude: 33.660361, longitude: -84.386502, zipCode: "30354"},
	{latitude: 33.933885, longitude: -84.272034, zipCode: "30360"},
	{latitude: 33.791478, longitude: -84.398892, zipCode: "30363"},
	{latitude: 32.60473, longitude: -82.347998, zipCode: "30401"},
	{latitude: 32.188589, longitude: -82.51347, zipCode: "30410"},
	{latitude: 32.124102, longitude: -82.793957, zipCode: "30411"},
	{latitude: 32.080344, longitude: -82.486087, zipCode: "30412"},
	{latitude: 32.884655, longitude: -82.513102, zipCode: "30413"},
	{latitude: 32.157001, longitude: -81.989953, zipCode: "30414"},
	{latitude: 32.324484, longitude: -81.603497, zipCode: "30415"},
	{latitude: 32.170468, longitude: -81.920992, zipCode: "30417"},
	{latitude: 32.306648, longitude: -82.130889, zipCode: "30420"},
	{latitude: 32.185377, longitude: -82.110912, zipCode: "30421"},
	{latitude: 32.146336, longitude: -81.824114, zipCode: "30423"},
	{latitude: 32.682022, longitude: -82.094572, zipCode: "30425"},
	{latitude: 33.0473, longitude: -81.648759, zipCode: "30426"},
	{latitude: 31.922905, longitude: -81.962446, zipCode: "30427"},
	{latitude: 32.224545, longitude: -82.71513, zipCode: "30428"},
	{latitude: 32.156771, longitude: -81.934857, zipCode: "30429"},
	{latitude: 33.02457, longitude: -82.366309, zipCode: "30434"},
	{latitude: 32.154894, longitude: -82.293036, zipCode: "30436"},
	{latitude: 32.416717, longitude: -82.070375, zipCode: "30439"},
	{latitude: 32.815558, longitude: -82.237151, zipCode: "30441"},
	{latitude: 32.816174, longitude: -81.969847, zipCode: "30442"},
	{latitude: 32.165598, longitude: -82.580717, zipCode: "30445"},
	{latitude: 32.563486, longitude: -81.463016, zipCode: "30446"},
	{latitude: 32.48824, longitude: -82.363384, zipCode: "30448"},
	{latitude: 32.525339, longitude: -81.534514, zipCode: "30449"},
	{latitude: 32.555993, longitude: -81.927363, zipCode: "30450"},
	{latitude: 32.393273, longitude: -81.956218, zipCode: "30451"},
	{latitude: 32.31611, longitude: -81.891855, zipCode: "30452"},
	{latitude: 32.026236, longitude: -82.111225, zipCode: "30453"},
	{latitude: 32.436077, longitude: -82.730358, zipCode: "30454"},
	{latitude: 32.69833, longitude: -81.809862, zipCode: "30455"},
	{latitude: 32.980717, longitude: -81.79614, zipCode: "30456"},
	{latitude: 32.399851, longitude: -82.56089, zipCode: "30457"},
	{latitude: 32.399447, longitude: -81.82894, zipCode: "30458"},
	{latitude: 32.427122, longitude: -81.783189, zipCode: "30460"},
	{latitude: 32.496955, longitude: -81.723101, zipCode: "30461"},
	{latitude: 32.437229, longitude: -82.224961, zipCode: "30464"},
	{latitude: 32.753793, longitude: -81.603534, zipCode: "30467"},
	{latitude: 32.358929, longitude: -82.497531, zipCode: "30470"},
	{latitude: 32.543263, longitude: -82.18091, zipCode: "30471"},
	{latitude: 32.015476, longitude: -82.473052, zipCode: "30473"},
	{latitude: 32.223996, longitude: -82.413685, zipCode: "30474"},
	{latitude: 32.862554, longitude: -82.400822, zipCode: "30477"},
	{latitude: 34.319043, longitude: -83.814422, zipCode: "30501"},
	{latitude: 34.266502, longitude: -83.888646, zipCode: "30504"},
	{latitude: 34.346938, longitude: -83.895627, zipCode: "30506"},
	{latitude: 34.264685, longitude: -83.769391, zipCode: "30507"},
	{latitude: 34.444872, longitude: -83.570707, zipCode: "30510"},
	{latitude: 34.449686, longitude: -83.486693, zipCode: "30511"},
	{latitude: 34.856753, longitude: -83.967998, zipCode: "30512"},
	{latitude: 34.821043, longitude: -84.322383, zipCode: "30513"},
	{latitude: 34.380913, longitude: -83.030697, zipCode: "30516"},
	{latitude: 34.130296, longitude: -83.797289, zipCode: "30517"},
	{latitude: 34.133822, longitude: -84.022599, zipCode: "30518"},
	{latitude: 34.08741, longitude: -83.945059, zipCode: "30519"},
	{latitude: 34.339924, longitude: -83.082702, zipCode: "30520"},
	{latitude: 34.362448, longitude: -83.292322, zipCode: "30521"},
	{latitude: 34.78368, longitude: -84.34373, zipCode: "30522"},
	{latitude: 34.713154, longitude: -83.551309, zipCode: "30523"},
	{latitude: 34.907223, longitude: -83.369347, zipCode: "30525"},
	{latitude: 34.480907, longitude: -83.781838, zipCode: "30527"},
	{latitude: 34.609088, longitude: -83.775997, zipCode: "30528"},
	{latitude: 34.217179, longitude: -83.482653, zipCode: "30529"},
	{latitude: 34.222921, longitude: -83.393799, zipCode: "30530"},
	{latitude: 34.512602, longitude: -83.592206, zipCode: "30531"},
	{latitude: 34.570958, longitude: -84.013764, zipCode: "30533"},
	{latitude: 34.4311, longitude: -84.144329, zipCode: "30534"},
	{latitude: 34.573655, longitude: -83.574769, zipCode: "30535"},
	{latitude: 34.652002, longitude: -84.359442, zipCode: "30536"},
	{latitude: 34.97907, longitude: -83.331499, zipCode: "30537"},
	{latitude: 34.500986, longitude: -83.263844, zipCode: "30538"},
	{latitude: 34.680607, longitude: -84.470127, zipCode: "30539"},
	{latitude: 34.728127, longitude: -84.543528, zipCode: "30540"},
	{latitude: 34.917258, longitude: -84.517744, zipCode: "30541"},
	{latitude: 34.179847, longitude: -83.910081, zipCode: "30542"},
	{latitude: 34.301301, longitude: -83.653747, zipCode: "30543"},
	{latitude: 34.762318, longitude: -83.762118, zipCode: "30545"},
	{latitude: 34.908448, longitude: -83.717767, zipCode: "30546"},
	{latitude: 34.362021, longitude: -83.455617, zipCode: "30547"},
	{latitude: 34.089396, longitude: -83.763599, zipCode: "30548"},
	{latitude: 34.107959, longitude: -83.577478, zipCode: "30549"},
	{latitude: 34.773831, longitude: -83.470714, zipCode: "30552"},
	{latitude: 34.443759, longitude: -83.084067, zipCode: "30553"},
	{latitude: 34.398088, longitude: -83.661905, zipCode: "30554"},
	{latitude: 34.969398, longitude: -84.419515, zipCode: "30555"},
	{latitude: 34.479752, longitude: -83.184905, zipCode: "30557"},
	{latitude: 34.264049, longitude: -83.566829, zipCode: "30558"},
	{latitude: 34.95786, longitude: -84.274335, zipCode: "30559"},
	{latitude: 34.876771, longitude: -84.204706, zipCode: "30560"},
	{latitude: 34.918996, longitude: -83.38368, zipCode: "30562"},
	{latitude: 34.55896, longitude: -83.461828, zipCode: "30563"},
	{latitude: 34.463965, longitude: -83.889312, zipCode: "30564"},
	{latitude: 34.086204, longitude: -83.411482, zipCode: "30565"},
	{latitude: 34.238652, longitude: -83.896845, zipCode: "30566"},
	{latitude: 34.180798, longitude: -83.676554, zipCode: "30567"},
	{latitude: 34.954844, longitude: -83.411111, zipCode: "30568"},
	{latitude: 34.71628, longitude: -83.68215, zipCode: "30571"},
	{latitude: 34.735793, longitude: -84.072507, zipCode: "30572"},
	{latitude: 34.741262, longitude: -83.393476, zipCode: "30573"},
	{latitude: 34.207594, longitude: -83.713441, zipCode: "30575"},
	{latitude: 34.834589, longitude: -83.452139, zipCode: "30576"},
	{latitude: 34.556243, longitude: -83.328905, zipCode: "30577"},
	{latitude: 34.796301, longitude: -83.424477, zipCode: "30581"},
	{latitude: 34.956451, longitude: -83.909789, zipCode: "30582"},
	{latitude: 34.527577, longitude: -83.984589, zipCode: "30597"},
	{latitude: 34.5966, longitude: -83.354162, zipCode: "30598"},
	{latitude: 33.999151, longitude: -83.346993, zipCode: "30601"},
	{latitude: 33.943919, longitude: -83.373855, zipCode: "30602"},
	{latitude: 33.904289, longitude: -83.325592, zipCode: "30605"},
	{latitude: 33.940299, longitude: -83.433583, zipCode: "30606"},
	{latitude: 34.017305, longitude: -83.447551, zipCode: "30607"},
	{latitude: 33.933102, longitude: -83.372322, zipCode: "30609"},
	{latitude: 33.855797, longitude: -83.244711, zipCode: "30619"},
	{latitude: 33.929472, longitude: -83.758457, zipCode: "30620"},
	{latitude: 33.796813, longitude: -83.471175, zipCode: "30621"},
	{latitude: 33.921282, longitude: -83.53083, zipCode: "30622"},
	{latitude: 33.728681, longitude: -83.513005, zipCode: "30623"},
	{latitude: 34.181024, longitude: -83.037941, zipCode: "30624"},
	{latitude: 33.520284, longitude: -83.33353, zipCode: "30625"},
	{latitude: 33.972176, longitude: -82.963748, zipCode: "30627"},
	{latitude: 34.032089, longitude: -83.215974, zipCode: "30628"},
	{latitude: 34.077983, longitude: -83.110367, zipCode: "30629"},
	{latitude: 33.90036, longitude: -83.145329, zipCode: "30630"},
	{latitude: 33.558262, longitude: -82.866495, zipCode: "30631"},
	{latitude: 34.180903, longitude: -83.249172, zipCode: "30633"},
	{latitude: 34.202177, longitude: -82.942226, zipCode: "30634"},
	{latitude: 34.105769, longitude: -82.793148, zipCode: "30635"},
	{latitude: 34.282737, longitude: -83.152397, zipCode: "30639"},
	{latitude: 33.769567, longitude: -83.56475, zipCode: "30641"},
	{latitude: 33.544289, longitude: -83.183971, zipCode: "30642"},
	{latitude: 34.365452, longitude: -82.911872, zipCode: "30643"},
	{latitude: 34.085682, longitude: -83.302513, zipCode: "30646"},
	{latitude: 33.870769, longitude: -83.038175, zipCode: "30648"},
	{latitude: 33.590645, longitude: -83.467554, zipCode: "30650"},
	{latitude: 33.780196, longitude: -83.692512, zipCode: "30655"},
	{latitude: 33.862137, longitude: -83.716175, zipCode: "30656"},
	{latitude: 33.782663, longitude: -82.931267, zipCode: "30660"},
	{latitude: 34.266966, longitude: -83.137564, zipCode: "30662"},
	{latitude: 33.615505, longitude: -83.600295, zipCode: "30663"},
	{latitude: 33.556259, longitude: -82.798319, zipCode: "30664"},
	{latitude: 33.537349, longitude: -83.073991, zipCode: "30665"},
	{latitude: 33.962087, longitude: -83.584602, zipCode: "30666"},
	{latitude: 33.796889, longitude: -83.175513, zipCode: "30667"},
	{latitude: 33.895747, longitude: -82.697208, zipCode: "30668"},
	{latitude: 33.676431, longitude: -83.118117, zipCode: "30669"},
	{latitude: 33.72809, longitude: -82.716244, zipCode: "30673"},
	{latitude: 33.783202, longitude: -83.373342, zipCode: "30677"},
	{latitude: 33.453017, longitude: -83.065918, zipCode: "30678"},
	{latitude: 33.995173, longitude: -83.697488, zipCode: "30680"},
	{latitude: 33.946841, longitude: -83.257109, zipCode: "30683"},
	{latitude: 34.494395, longitude: -84.954177, zipCode: "30701"},
	{latitude: 34.747542, longitude: -84.748401, zipCode: "30705"},
	{latitude: 34.778911, longitude: -85.3653, zipCode: "30707"},
	{latitude: 34.939899, longitude: -84.667565, zipCode: "30708"},
	{latitude: 34.938823, longitude: -84.917315, zipCode: "30710"},
	{latitude: 34.931766, longitude: -84.768643, zipCode: "30711"},
	{latitude: 34.733645, longitude: -85.000824, zipCode: "30720"},
	{latitude: 34.792977, longitude: -84.908686, zipCode: "30721"},
	{latitude: 34.825178, longitude: -84.757885, zipCode: "30724"},
	{latitude: 34.924777, longitude: -85.352675, zipCode: "30725"},
	{latitude: 34.974872, longitude: -85.141048, zipCode: "30726"},
	{latitude: 34.684875, longitude: -85.22579, zipCode: "30728"},
	{latitude: 34.36377, longitude: -85.416023, zipCode: "30730"},
	{latitude: 34.564178, longitude: -85.471885, zipCode: "30731"},
	{latitude: 34.414593, longitude: -85.050554, zipCode: "30733"},
	{latitude: 34.532804, longitude: -84.714916, zipCode: "30734"},
	{latitude: 34.602883, longitude: -84.882661, zipCode: "30735"},
	{latitude: 34.902401, longitude: -85.134056, zipCode: "30736"},
	{latitude: 34.796552, longitude: -85.480936, zipCode: "30738"},
	{latitude: 34.805906, longitude: -85.215958, zipCode: "30739"},
	{latitude: 34.757368, longitude: -85.072012, zipCode: "30740"},
	{latitude: 34.952695, longitude: -85.281295, zipCode: "30741"},
	{latitude: 34.935597, longitude: -85.244842, zipCode: "30742"},
	{latitude: 34.592095, longitude: -85.032872, zipCode: "30746"},
	{latitude: 34.504087, longitude: -85.292786, zipCode: "30747"},
	{latitude: 34.936814, longitude: -85.388204, zipCode: "30750"},
	{latitude: 34.984504, longitude: -84.73269, zipCode: "30751"},
	{latitude: 34.914982, longitude: -85.537961, zipCode: "30752"},
	{latitude: 34.58228, longitude: -85.2858, zipCode: "30753"},
	{latitude: 34.869366, longitude: -85.040664, zipCode: "30755"},
	{latitude: 34.901053, longitude: -84.97459, zipCode: "30756"},
	{latitude: 34.935801, longitude: -85.441516, zipCode: "30757"},
	{latitude: 33.601366, longitude: -82.299936, zipCode: "30802"},
	{latitude: 33.133462, longitude: -82.549151, zipCode: "30803"},
	{latitude: 33.279569, longitude: -82.191791, zipCode: "30805"},
	{latitude: 33.457408, longitude: -82.648621, zipCode: "30807"},
	{latitude: 33.384114, longitude: -82.391923, zipCode: "30808"},
	{latitude: 33.558527, longitude: -82.171185, zipCode: "30809"},
	{latitude: 33.232044, longitude: -82.587508, zipCode: "30810"},
	{latitude: 33.367452, longitude: -82.029067, zipCode: "30812"},
	{latitude: 33.466744, longitude: -82.228209, zipCode: "30813"},
	{latitude: 33.431091, longitude: -82.318849, zipCode: "30814"},
	{latitude: 33.284927, longitude: -82.072213, zipCode: "30815"},
	{latitude: 33.163379, longitude: -82.176208, zipCode: "30816"},
	{latitude: 33.778388, longitude: -82.430194, zipCode: "30817"},
	{latitude: 33.26956, longitude: -82.326081, zipCode: "30818"},
	{latitude: 33.222359, longitude: -82.724536, zipCode: "30820"},
	{latitude: 33.492107, longitude: -82.737994, zipCode: "30821"},
	{latitude: 32.919257, longitude: -81.944353, zipCode: "30822"},
	{latitude: 33.222042, longitude: -82.456142, zipCode: "30823"},
	{latitude: 33.512953, longitude: -82.514855, zipCode: "30824"},
	{latitude: 33.368038, longitude: -82.656312, zipCode: "30828"},
	{latitude: 33.085111, longitude: -81.978661, zipCode: "30830"},
	{latitude: 33.19125, longitude: -82.353132, zipCode: "30833"},
	{latitude: 33.437213, longitude: -81.956156, zipCode: "30901"},
	{latitude: 33.478289, longitude: -82.014102, zipCode: "30904"},
	{latitude: 33.411494, longitude: -82.149106, zipCode: "30905"},
	{latitude: 33.346732, longitude: -81.967703, zipCode: "30906"},
	{latitude: 33.523712, longitude: -82.085665, zipCode: "30907"},
	{latitude: 33.470481, longitude: -82.083045, zipCode: "30909"},
	{latitude: 33.4702, longitude: -81.987917, zipCode: "30912"},
	{latitude: 31.976256, longitude: -83.339665, zipCode: "31001"},
	{latitude: 32.554049, longitude: -82.595322, zipCode: "31002"},
	{latitude: 32.596881, longitude: -83.222928, zipCode: "31003"},
	{latitude: 32.951665, longitude: -83.805316, zipCode: "31004"},
	{latitude: 32.544622, longitude: -83.593676, zipCode: "31005"},
	{latitude: 32.58072, longitude: -84.250738, zipCode: "31006"},
	{latitude: 32.188514, longitude: -83.927321, zipCode: "31007"},
	{latitude: 32.665355, longitude: -83.786087, zipCode: "31008"},
	{latitude: 32.270181, longitude: -83.000516, zipCode: "31009"},
	{latitude: 32.130626, longitude: -83.05227, zipCode: "31011"},
	{latitude: 32.394344, longitude: -83.173715, zipCode: "31012"},
	{latitude: 32.415886, longitude: -83.349077, zipCode: "31014"},
	{latitude: 31.943011, longitude: -83.778608, zipCode: "31015"},
	{latitude: 32.829319, longitude: -84.091558, zipCode: "31016"},
	{latitude: 32.621094, longitude: -83.23222, zipCode: "31017"},
	{latitude: 32.97649, longitude: -82.616752, zipCode: "31018"},
	{latitude: 32.424244, longitude: -83.051342, zipCode: "31019"},
	{latitude: 32.654482, longitude: -83.498453, zipCode: "31020"},
	{latitude: 32.489539, longitude: -82.934985, zipCode: "31021"},
	{latitude: 32.51154, longitude: -83.116631, zipCode: "31022"},
	{latitude: 32.183174, longitude: -83.199476, zipCode: "31023"},
	{latitude: 33.322978, longitude: -83.370015, zipCode: "31024"},
	{latitude: 32.335397, longitude: -83.740875, zipCode: "31025"},
	{latitude: 32.571946, longitude: -82.822614, zipCode: "31027"},
	{latitude: 32.63284, longitude: -83.68393, zipCode: "31028"},
	{latitude: 33.043571, longitude: -83.93535, zipCode: "31029"},
	{latitude: 32.568973, longitude: -83.89188, zipCode: "31030"},
	{latitude: 32.866286, longitude: -83.32834, zipCode: "31031"},
	{latitude: 33.011996, longitude: -83.573289, zipCode: "31032"},
	{latitude: 33.079307, longitude: -83.434007, zipCode: "31033"},
	{latitude: 33.054623, longitude: -83.225513, zipCode: "31034"},
	{latitude: 32.854123, longitude: -82.707822, zipCode: "31035"},
	{latitude: 32.270061, longitude: -83.492626, zipCode: "31036"},
	{latitude: 32.121561, longitude: -82.93628, zipCode: "31037"},
	{latitude: 33.142498, longitude: -83.621399, zipCode: "31038"},
	{latitude: 32.604098, longitude: -84.379104, zipCode: "31039"},
	{latitude: 32.382361, longitude: -84.167801, zipCode: "31041"},
	{latitude: 32.743643, longitude: -83.189114, zipCode: "31042"},
	{latitude: 32.656173, longitude: -83.390884, zipCode: "31044"},
	{latitude: 33.282948, longitude: -82.794508, zipCode: "31045"},
	{latitude: 33.054336, longitude: -83.796088, zipCode: "31046"},
	{latitude: 32.466866, longitude: -83.580995, zipCode: "31047"},
	{latitude: 32.678289, longitude: -82.523385, zipCode: "31049"},
	{latitude: 32.721537, longitude: -83.937295, zipCode: "31050"},
	{latitude: 32.143126, longitude: -83.8794, zipCode: "31051"},
	{latitude: 32.789015, longitude: -83.840075, zipCode: "31052"},
	{latitude: 32.889861, longitude: -83.214067, zipCode: "31054"},
	{latitude: 31.985895, longitude: -82.883139, zipCode: "31055"},
	{latitude: 32.429781, longitude: -83.944276, zipCode: "31057"},
	{latitude: 32.488651, longitude: -84.410396, zipCode: "31058"},
	{latitude: 31.986445, longitude: -83.05635, zipCode: "31060"},
	{latitude: 33.059054, longitude: -83.226413, zipCode: "31061"},
	{latitude: 33.043372, longitude: -83.215801, zipCode: "31062"},
	{latitude: 32.288966, longitude: -83.96326, zipCode: "31063"},
	{latitude: 33.286454, longitude: -83.702299, zipCode: "31064"},
	{latitude: 32.564115, longitude: -83.188491, zipCode: "31065"},
	{latitude: 32.80241, longitude: -83.97815, zipCode: "31066"},
	{latitude: 32.852296, longitude: -82.961654, zipCode: "31067"},
	{latitude: 32.332742, longitude: -84.111143, zipCode: "31068"},
	{latitude: 32.440068, longitude: -83.748603, zipCode: "31069"},
	{latitude: 32.18658, longitude: -83.789771, zipCode: "31070"},
	{latitude: 32.129447, longitude: -83.540846, zipCode: "31071"},
	{latitude: 31.974465, longitude: -83.56684, zipCode: "31072"},
	{latitude: 32.360956, longitude: -82.954646, zipCode: "31075"},
	{latitude: 32.549678, longitude: -84.104974, zipCode: "31076"},
	{latitude: 31.942307, longitude: -83.169758, zipCode: "31077"},
	{latitude: 32.694814, longitude: -84.075661, zipCode: "31078"},
	{latitude: 31.942527, longitude: -83.46341, zipCode: "31079"},
	{latitude: 32.412399, longitude: -84.284047, zipCode: "31081"},
	{latitude: 32.996417, longitude: -82.903117, zipCode: "31082"},
	{latitude: 32.044134, longitude: -82.822001, zipCode: "31083"},
	{latitude: 31.96152, longitude: -83.602557, zipCode: "31084"},
	{latitude: 33.427349, longitude: -83.6277, zipCode: "31085"},
	{latitude: 33.251362, longitude: -83.001031, zipCode: "31087"},
	{latitude: 32.586171, longitude: -83.655082, zipCode: "31088"},
	{latitude: 32.850766, longitude: -82.860314, zipCode: "31089"},
	{latitude: 32.793582, longitude: -83.048446, zipCode: "31090"},
	{latitude: 32.237927, longitude: -83.704527, zipCode: "31091"},
	{latitude: 32.089661, longitude: -83.782665, zipCode: "31092"},
	{latitude: 32.647873, longitude: -83.657206, zipCode: "31093"},
	{latitude: 33.113614, longitude: -82.795759, zipCode: "31094"},
	{latitude: 32.723291, longitude: -82.707266, zipCode: "31096"},
	{latitude: 32.890411, longitude: -84.166895, zipCode: "31097"},
	{latitude: 32.614825, longitude: -83.578435, zipCode: "31098"},
	{latitude: 32.806321, longitude: -83.61786, zipCode: "31201"},
	{latitude: 32.848511, longitude: -83.674745, zipCode: "31204"},
	{latitude: 32.793366, longitude: -83.684573, zipCode: "31206"},
	{latitude: 32.828602, longitude: -83.649299, zipCode: "31207"},
	{latitude: 32.907633, longitude: -83.735052, zipCode: "31210"},
	{latitude: 32.904267, longitude: -83.598526, zipCode: "31211"},
	{latitude: 32.840081, longitude: -83.63935, zipCode: "31213"},
	{latitude: 32.732373, longitude: -83.671449, zipCode: "31216"},
	{latitude: 32.844147, longitude: -83.500044, zipCode: "31217"},
	{latitude: 32.884462, longitude: -83.818148, zipCode: "31220"},
	{latitude: 31.758253, longitude: -81.605053, zipCode: "31301"},
	{latitude: 32.125813, longitude: -81.340394, zipCode: "31302"},
	{latitude: 32.505422, longitude: -81.313427, zipCode: "31303"},
	{latitude: 31.367579, longitude: -81.378193, zipCode: "31305"},
	{latitude: 32.17641, longitude: -81.39761, zipCode: "31307"},
	{latitude: 32.180316, longitude: -81.47129, zipCode: "31308"},
	{latitude: 31.88045, longitude: -81.441172, zipCode: "31309"},
	{latitude: 32.318644, longitude: -81.400955, zipCode: "31312"},
	{latitude: 31.836598, longitude: -81.616774, zipCode: "31313"},
	{latitude: 31.906922, longitude: -81.617511, zipCode: "31314"},
	{latitude: 31.887679, longitude: -81.606009, zipCode: "31315"},
	{latitude: 31.719283, longitude: -81.733272, zipCode: "31316"},
	{latitude: 32.134838, longitude: -81.372263, zipCode: "31318"},
	{latitude: 31.765504, longitude: -81.368411, zipCode: "31320"},
	{latitude: 32.185062, longitude: -81.659794, zipCode: "31321"},
	{latitude: 32.101989, longitude: -81.255379, zipCode: "31322"},
	{latitude: 31.668385, longitude: -81.454187, zipCode: "31323"},
	{latitude: 31.850203, longitude: -81.264824, zipCode: "31324"},
	{latitude: 32.296613, longitude: -81.222653, zipCode: "31326"},
	{latitude: 31.423165, longitude: -81.290082, zipCode: "31327"},
	{latitude: 32.00433, longitude: -80.890787, zipCode: "31328"},
	{latitude: 32.427261, longitude: -81.350003, zipCode: "31329"},
	{latitude: 31.490245, longitude: -81.421511, zipCode: "31331"},
	{latitude: 31.767828, longitude: -81.627458, zipCode: "31333"},
	{latitude: 32.068296, longitude: -81.092835, zipCode: "31401"},
	{latitude: 32.05075, longitude: -81.052961, zipCode: "31404"},
	{latitude: 32.039402, longitude: -81.179112, zipCode: "31405"},
	{latitude: 31.980404, longitude: -81.085184, zipCode: "31406"},
	{latitude: 32.183218, longitude: -81.197935, zipCode: "31407"},
	{latitude: 32.103956, longitude: -81.180597, zipCode: "31408"},
	{latitude: 32.015561, longitude: -81.14483, zipCode: "31409"},
	{latitude: 32.014884, longitude: -80.988234, zipCode: "31410"},
	{latitude: 31.937542, longitude: -81.034252, zipCode: "31411"},
	{latitude: 32.08064, longitude: -81.127217, zipCode: "31415"},
	{latitude: 31.984562, longitude: -81.23695, zipCode: "31419"},
	{latitude: 32.086966, longitude: -81.078188, zipCode: "31421"},
	{latitude: 31.227596, longitude: -82.347944, zipCode: "31501"},
	{latitude: 31.176879, longitude: -82.401293, zipCode: "31503"},
	{latitude: 31.546546, longitude: -82.433529, zipCode: "31510"},
	{latitude: 31.545696, longitude: -83.018356, zipCode: "31512"},
	{latitude: 31.777421, longitude: -82.346004, zipCode: "31513"},
	{latitude: 31.309371, longitude: -82.25326, zipCode: "31516"},
	{latitude: 31.512607, longitude: -82.185093, zipCode: "31518"},
	{latitude: 31.668124, longitude: -82.869034, zipCode: "31519"},
	{latitude: 31.178078, longitude: -81.487563, zipCode: "31520"},
	{latitude: 31.257252, longitude: -81.342026, zipCode: "31522"},
	{latitude: 31.212645, longitude: -81.616811, zipCode: "31523"},
	{latitude: 31.240597, longitude: -81.473387, zipCode: "31524"},
	{latitude: 31.320999, longitude: -81.52644, zipCode: "31525"},
	{latitude: 31.075118, longitude: -81.416005, zipCode: "31527"},
	{latitude: 31.726415, longitude: -82.745735, zipCode: "31532"},
	{latitude: 31.562738, longitude: -82.82902, zipCode: "31533"},
	{latitude: 31.459541, longitude: -82.867327, zipCode: "31535"},
	{latitude: 30.926545, longitude: -82.176473, zipCode: "31537"},
	{latitude: 31.813918, longitude: -82.602233, zipCode: "31539"},
	{latitude: 31.142701, longitude: -82.119407, zipCode: "31542"},
	{latitude: 31.326469, longitude: -81.869695, zipCode: "31543"},
	{latitude: 31.840482, longitude: -82.963883, zipCode: "31544"},
	{latitude: 31.57256, longitude: -81.990861, zipCode: "31545"},
	{latitude: 31.530265, longitude: -81.794871, zipCode: "31546"},
	{latitude: 30.790347, longitude: -81.535288, zipCode: "31547"},
	{latitude: 30.79584, longitude: -81.712363, zipCode: "31548"},
	{latitude: 31.921441, longitude: -82.729933, zipCode: "31549"},
	{latitude: 31.14708, longitude: -82.595623, zipCode: "31550"},
	{latitude: 31.488281, longitude: -82.266768, zipCode: "31551"},
	{latitude: 31.289898, longitude: -82.599146, zipCode: "31552"},
	{latitude: 31.166379, longitude: -81.982418, zipCode: "31553"},
	{latitude: 31.485797, longitude: -82.620517, zipCode: "31554"},
	{latitude: 31.703058, longitude: -82.069441, zipCode: "31555"},
	{latitude: 31.40505, longitude: -82.115244, zipCode: "31556"},
	{latitude: 31.375274, longitude: -82.098837, zipCode: "31557"},
	{latitude: 30.802289, longitude: -81.507674, zipCode: "31558"},
	{latitude: 31.505609, longitude: -82.029604, zipCode: "31560"},
	{latitude: 31.199121, longitude: -81.328637, zipCode: "31561"},
	{latitude: 30.47534, longitude: -82.103897, zipCode: "31562"},
	{latitude: 31.738186, longitude: -82.177843, zipCode: "31563"},
	{latitude: 31.248716, longitude: -82.470948, zipCode: "31564"},
	{latitude: 31.070708, longitude: -81.643418, zipCode: "31565"},
	{latitude: 31.182755, longitude: -81.805594, zipCode: "31566"},
	{latitude: 31.630886, longitude: -82.706228, zipCode: "31567"},
	{latitude: 31.01339, longitude: -81.819102, zipCode: "31568"},
	{latitude: 30.93378, longitude: -81.737076, zipCode: "31569"},
	{latitude: 31.562748, longitude: -81.901734, zipCode: "31599"},
	{latitude: 30.775522, longitude: -83.3336, zipCode: "31601"},
	{latitude: 30.86816, longitude: -83.34235, zipCode: "31602"},
	{latitude: 30.932951, longitude: -83.221938, zipCode: "31605"},
	{latitude: 30.798662, longitude: -83.179881, zipCode: "31606"},
	{latitude: 31.117296, longitude: -83.434236, zipCode: "31620"},
	{latitude: 31.372347, longitude: -83.199344, zipCode: "31622"},
	{latitude: 31.071366, longitude: -82.64404, zipCode: "31623"},
	{latitude: 31.296189, longitude: -82.730338, zipCode: "31624"},
	{latitude: 30.989342, longitude: -83.52251, zipCode: "31625"},
	{latitude: 30.77561, longitude: -83.803086, zipCode: "31626"},
	{latitude: 31.039606, longitude: -83.392113, zipCode: "31627"},
	{latitude: 30.816844, longitude: -83.689394, zipCode: "31629"},
	{latitude: 30.924212, longitude: -82.860039, zipCode: "31630"},
	{latitude: 30.708217, longitude: -82.644358, zipCode: "31631"},
	{latitude: 30.98626, longitude: -83.3549, zipCode: "31632"},
	{latitude: 31.03051, longitude: -82.732846, zipCode: "31634"},
	{latitude: 31.096894, longitude: -83.048473, zipCode: "31635"},
	{latitude: 30.730986, longitude: -83.111372, zipCode: "31636"},
	{latitude: 31.295961, longitude: -83.440219, zipCode: "31637"},
	{latitude: 30.906417, longitude: -83.512186, zipCode: "31638"},
	{latitude: 31.213259, longitude: -83.190862, zipCode: "31639"},
	{latitude: 30.921011, longitude: -83.093576, zipCode: "31641"},
	{latitude: 31.262789, longitude: -82.883197, zipCode: "31642"},
	{latitude: 30.773089, longitude: -83.556898, zipCode: "31643"},
	{latitude: 31.060719, longitude: -83.218688, zipCode: "31645"},
	{latitude: 31.211229, longitude: -83.43169, zipCode: "31647"},
	{latitude: 30.708359, longitude: -83.00837, zipCode: "31648"},
	{latitude: 30.9405, longitude: -83.013889, zipCode: "31649"},
	{latitude: 31.370002, longitude: -83.018941, zipCode: "31650"},
	{latitude: 30.848992, longitude: -83.289627, zipCode: "31698"},
	{latitude: 30.969613, longitude: -83.194305, zipCode: "31699"},
	{latitude: 31.573054, longitude: -84.154783, zipCode: "31701"},
	{latitude: 31.549679, longitude: -84.055507, zipCode: "31704"},
	{latitude: 31.51689, longitude: -84.042942, zipCode: "31705"},
	{latitude: 31.586919, longitude: -84.206431, zipCode: "31707"},
	{latitude: 32.046416, longitude: -84.149578, zipCode: "31709"},
	{latitude: 32.187712, longitude: -84.122162, zipCode: "31711"},
	{latitude: 31.819358, longitude: -83.719208, zipCode: "31712"},
	{latitude: 31.708107, longitude: -83.678402, zipCode: "31714"},
	{latitude: 31.366109, longitude: -84.110732, zipCode: "31716"},
	{latitude: 32.090308, longitude: -84.310747, zipCode: "31719"},
	{latitude: 30.878154, longitude: -83.724572, zipCode: "31720"},
	{latitude: 31.52526, longitude: -84.301465, zipCode: "31721"},
	{latitude: 31.048275, longitude: -83.610855, zipCode: "31722"},
	{latitude: 31.417779, longitude: -83.387396, zipCode: "31727"},
	{latitude: 31.210871, longitude: -84.280647, zipCode: "31730"},
	{latitude: 31.59319, longitude: -83.489763, zipCode: "31733"},
	{latitude: 31.969555, longitude: -83.973306, zipCode: "31735"},
	{latitude: 31.00815, longitude: -83.879727, zipCode: "31738"},
	{latitude: 31.896489, longitude: -84.00748, zipCode: "31743"},
	{latitude: 31.340289, longitude: -83.903131, zipCode: "31744"},
	{latitude: 31.174618, longitude: -83.584923, zipCode: "31747"},
	{latitude: 31.420536, longitude: -83.33012, zipCode: "31749"},
	{latitude: 31.736465, longitude: -83.215037, zipCode: "31750"},
	{latitude: 31.200446, longitude: -83.872846, zipCode: "31753"},
	{latitude: 31.176891, longitude: -83.962264, zipCode: "31756"},
	{latitude: 30.871395, longitude: -83.903125, zipCode: "31757"},
	{latitude: 31.736137, longitude: -84.148186, zipCode: "31763"},
	{latitude: 31.976312, longitude: -84.088839, zipCode: "31764"},
	{latitude: 31.087551, longitude: -84.027154, zipCode: "31765"},
	{latitude: 31.189103, longitude: -83.841107, zipCode: "31768"},
	{latitude: 31.248142, longitude: -83.654152, zipCode: "31771"},
	{latitude: 31.724154, longitude: -83.961027, zipCode: "31772"},
	{latitude: 30.977698, longitude: -84.045849, zipCode: "31773"},
	{latitude: 31.565466, longitude: -83.263747, zipCode: "31774"},
	{latitude: 31.334299, longitude: -83.598864, zipCode: "31775"},
	{latitude: 30.974992, longitude: -83.700075, zipCode: "31778"},
	{latitude: 31.090181, longitude: -84.191684, zipCode: "31779"},
	{latitude: 32.027683, longitude: -84.391222, zipCode: "31780"},
	{latitude: 31.560566, longitude: -83.796317, zipCode: "31781"},
	{latitude: 31.758976, longitude: -83.470125, zipCode: "31783"},
	{latitude: 31.25245, longitude: -84.048457, zipCode: "31784"},
	{latitude: 31.895613, longitude: -84.248185, zipCode: "31787"},
	{latitude: 31.111533, longitude: -83.683892, zipCode: "31788"},
	{latitude: 31.483306, longitude: -83.733939, zipCode: "31789"},
	{latitude: 31.652476, longitude: -83.577968, zipCode: "31790"},
	{latitude: 31.528949, longitude: -83.894253, zipCode: "31791"},
	{latitude: 30.765554, longitude: -84.043154, zipCode: "31792"},
	{latitude: 31.461884, longitude: -83.58121, zipCode: "31793"},
	{latitude: 31.445872, longitude: -83.452667, zipCode: "31794"},
	{latitude: 31.434566, longitude: -83.639822, zipCode: "31795"},
	{latitude: 31.771231, longitude: -83.875011, zipCode: "31796"},
	{latitude: 31.644434, longitude: -83.047304, zipCode: "31798"},
	{latitude: 32.519338, longitude: -84.594755, zipCode: "31801"},
	{latitude: 32.307436, longitude: -84.517208, zipCode: "31803"},
	{latitude: 32.664402, longitude: -84.878409, zipCode: "31804"},
	{latitude: 32.263056, longitude: -84.739509, zipCode: "31805"},
	{latitude: 32.260168, longitude: -84.299506, zipCode: "31806"},
	{latitude: 32.644701, longitude: -84.804532, zipCode: "31807"},
	{latitude: 32.63161, longitude: -85.01055, zipCode: "31808"},
	{latitude: 32.568261, longitude: -84.524881, zipCode: "31810"},
	{latitude: 32.737356, longitude: -84.937436, zipCode: "31811"},
	{latitude: 32.634887, longitude: -84.42509, zipCode: "31812"},
	{latitude: 32.19736, longitude: -84.864974, zipCode: "31814"},
	{latitude: 32.041804, longitude: -84.856054, zipCode: "31815"},
	{latitude: 32.878917, longitude: -84.601311, zipCode: "31816"},
	{latitude: 32.575774, longitude: -84.829099, zipCode: "31820"},
	{latitude: 32.142468, longitude: -84.974694, zipCode: "31821"},
	{latitude: 32.864979, longitude: -84.910348, zipCode: "31822"},
	{latitude: 32.797846, longitude: -84.811418, zipCode: "31823"},
	{latitude: 32.05791, longitude: -84.520625, zipCode: "31824"},
	{latitude: 32.116195, longitude: -84.6709, zipCode: "31825"},
	{latitude: 32.800237, longitude: -84.703065, zipCode: "31826"},
	{latitude: 32.695522, longitude: -84.503751, zipCode: "31827"},
	{latitude: 32.566221, longitude: -84.725442, zipCode: "31829"},
	{latitude: 32.900708, longitude: -84.729063, zipCode: "31830"},
	{latitude: 32.666859, longitude: -84.704799, zipCode: "31831"},
	{latitude: 31.958169, longitude: -84.614896, zipCode: "31832"},
	{latitude: 32.8429, longitude: -85.091845, zipCode: "31833"},
	{latitude: 32.796402, longitude: -84.53509, zipCode: "31836"},
	{latitude: 32.46435, longitude: -84.980144, zipCode: "31901"},
	{latitude: 32.416037, longitude: -84.952525, zipCode: "31903"},
	{latitude: 32.55434, longitude: -85.009469, zipCode: "31904"},
	{latitude: 32.372789, longitude: -84.843361, zipCode: "31905"},
	{latitude: 32.467811, longitude: -84.950173, zipCode: "31906"},
	{latitude: 32.484404, longitude: -84.899945, zipCode: "31907"},
	{latitude: 32.548807, longitude: -84.924106, zipCode: "31909"},
	{latitude: 30.095584, longitude: -81.710162, zipCode: "32003"},
	{latitude: 29.90792, longitude: -82.897465, zipCode: "32008"},
	{latitude: 30.422502, longitude: -81.973824, zipCode: "32009"},
	{latitude: 30.569778, longitude: -81.8405, zipCode: "32011"},
	{latitude: 30.193666, longitude: -83.291204, zipCode: "32013"},
	{latitude: 30.091975, longitude: -82.73112, zipCode: "32024"},
	{latitude: 30.104026, longitude: -82.566168, zipCode: "32025"},
	{latitude: 30.059065, longitude: -82.190472, zipCode: "32026"},
	{latitude: 29.787856, longitude: -81.446744, zipCode: "32033"},
	{latitude: 30.601794, longitude: -81.481799, zipCode: "32034"},
	{latitude: 29.934936, longitude: -82.692234, zipCode: "32038"},
	{latitude: 30.247032, longitude: -82.23533, zipCode: "32040"},
	{latitude: 29.926711, longitude: -81.743631, zipCode: "32043"},
	{latitude: 29.857895, longitude: -82.178708, zipCode: "32044"},
	{latitude: 30.701152, longitude: -81.916587, zipCode: "32046"},
	{latitude: 30.49054, longitude: -82.944838, zipCode: "32052"},
	{latitude: 30.56884, longitude: -83.15187, zipCode: "32053"},
	{latitude: 30.033807, longitude: -82.394253, zipCode: "32054"},
	{latitude: 30.270103, longitude: -82.62413, zipCode: "32055"},
	{latitude: 30.107546, longitude: -82.106933, zipCode: "32058"},
	{latitude: 30.384943, longitude: -83.25597, zipCode: "32059"},
	{latitude: 30.274413, longitude: -83.036329, zipCode: "32060"},
	{latitude: 30.083085, longitude: -82.516655, zipCode: "32061"},
	{latitude: 30.137457, longitude: -82.978338, zipCode: "32062"},
	{latitude: 30.270901, longitude: -82.120398, zipCode: "32063"},
	{latitude: 30.293277, longitude: -82.991953, zipCode: "32064"},
	{latitude: 30.175071, longitude: -81.871668, zipCode: "32065"},
	{latitude: 30.039764, longitude: -83.205806, zipCode: "32066"},
	{latitude: 30.082951, longitude: -81.884022, zipCode: "32068"},
	{latitude: 30.058101, longitude: -82.955828, zipCode: "32071"},
	{latitude: 30.184037, longitude: -82.423425, zipCode: "32072"},
	{latitude: 30.167711, longitude: -81.739816, zipCode: "32073"},
	{latitude: 29.977993, longitude: -81.807021, zipCode: "32079"},
	{latitude: 29.816985, longitude: -81.265123, zipCode: "32080"},
	{latitude: 30.119606, longitude: -81.426613, zipCode: "32081"},
	{latitude: 30.111553, longitude: -81.371747, zipCode: "32082"},
	{latitude: 30.114597, longitude: -82.232781, zipCode: "32083"},
	{latitude: 29.913733, longitude: -81.365292, zipCode: "32084"},
	{latitude: 29.740948, longitude: -81.30016, zipCode: "32086"},
	{latitude: 30.364971, longitude: -82.298674, zipCode: "32087"},
	{latitude: 29.933025, longitude: -82.113403, zipCode: "32091"},
	{latitude: 29.929252, longitude: -81.521904, zipCode: "32092"},
	{latitude: 30.186943, longitude: -82.82182, zipCode: "32094"},
	{latitude: 30.027547, longitude: -81.436962, zipCode: "32095"},
	{latitude: 30.53438, longitude: -82.626965, zipCode: "32096"},
	{latitude: 30.662149, longitude: -81.648914, zipCode: "32097"},
	{latitude: 29.13842, longitude: -81.523078, zipCode: "32102"},
	{latitude: 29.444708, longitude: -81.352315, zipCode: "32110"},
	{latitude: 29.431746, longitude: -81.565573, zipCode: "32112"},
	{latitude: 29.409977, longitude: -82.089218, zipCode: "32113"},
	{latitude: 29.192391, longitude: -81.04676, zipCode: "32114"},
	{latitude: 29.232739, longitude: -81.068471, zipCode: "32117"},
	{latitude: 29.209639, longitude: -81.002321, zipCode: "32118"},
	{latitude: 29.160962, longitude: -81.025593, zipCode: "32119"},
	{latitude: 29.159517, longitude: -81.158803, zipCode: "32124"},
	{latitude: 29.108464, longitude: -80.975738, zipCode: "32127"},
	{latitude: 29.100739, longitude: -81.071829, zipCode: "32128"},
	{latitude: 29.136974, longitude: -81.023957, zipCode: "32129"},
	{latitude: 29.148342, longitude: -81.341043, zipCode: "32130"},
	{latitude: 29.677863, longitude: -81.563722, zipCode: "32131"},
	{latitude: 28.980108, longitude: -80.920894, zipCode: "32132"},
	{latitude: 29.01214, longitude: -81.914706, zipCode: "32133"},
	{latitude: 29.377165, longitude: -81.827585, zipCode: "32134"},
	{latitude: 29.463763, longitude: -81.147395, zipCode: "32136"},
	{latitude: 29.575688, longitude: -81.223832, zipCode: "32137"},
	{latitude: 29.377178, longitude: -81.613019, zipCode: "32139"},
	{latitude: 29.762604, longitude: -81.866657, zipCode: "32140"},
	{latitude: 28.928812, longitude: -80.919083, zipCode: "32141"},
	{latitude: 29.677558, longitude: -81.421332, zipCode: "32145"},
	{latitude: 29.634671, longitude: -81.787036, zipCode: "32147"},
	{latitude: 29.601157, longitude: -81.882919, zipCode: "32148"},
	{latitude: 29.461528, longitude: -81.587765, zipCode: "32157"},
	{latitude: 28.918072, longitude: -81.897655, zipCode: "32159"},
	{latitude: 28.922305, longitude: -81.999287, zipCode: "32162"},
	{latitude: 28.841403, longitude: -81.959215, zipCode: "32163"},
	{latitude: 29.480607, longitude: -81.217791, zipCode: "32164"},
	{latitude: 28.994646, longitude: -81.024439, zipCode: "32168"},
	{latitude: 28.975536, longitude: -80.867376, zipCode: "32169"},
	{latitude: 29.292191, longitude: -81.161446, zipCode: "32174"},
	{latitude: 29.33538, longitude: -81.064459, zipCode: "32176"},
	{latitude: 29.697699, longitude: -81.703239, zipCode: "32177"},
	{latitude: 29.095193, longitude: -81.899934, zipCode: "32179"},
	{latitude: 29.240997, longitude: -81.442807, zipCode: "32180"},
	{latitude: 29.507981, longitude: -81.601987, zipCode: "32181"},
	{latitude: 29.509246, longitude: -81.941044, zipCode: "32182"},
	{latitude: 29.578946, longitude: -81.550038, zipCode: "32187"},
	{latitude: 29.54984, longitude: -81.647696, zipCode: "32189"},
	{latitude: 29.337688, longitude: -81.492643, zipCode: "32190"},
	{latitude: 29.350183, longitude: -82.111066, zipCode: "32192"},
	{latitude: 29.477372, longitude: -81.663606, zipCode: "32193"},
	{latitude: 28.98808, longitude: -81.886336, zipCode: "32195"},
	{latitude: 30.32462, longitude: -81.647232, zipCode: "32202"},
	{latitude: 30.33702, longitude: -81.671293, zipCode: "32203"},
	{latitude: 30.317018, longitude: -81.680832, zipCode: "32204"},
	{latitude: 30.302454, longitude: -81.723043, zipCode: "32205"},
	{latitude: 30.351115, longitude: -81.63801, zipCode: "32206"},
	{latitude: 30.289637, longitude: -81.640966, zipCode: "32207"},
	{latitude: 30.39309, longitude: -81.682946, zipCode: "32208"},
	{latitude: 30.361015, longitude: -81.696254, zipCode: "32209"},
	{latitude: 30.265455, longitude: -81.748096, zipCode: "32210"},
	{latitude: 30.33152, longitude: -81.582697, zipCode: "32211"},
	{latitude: 30.223367, longitude: -81.672965, zipCode: "32212"},
	{latitude: 30.207684, longitude: -81.683226, zipCode: "32214"},
	{latitude: 30.281083, longitude: -81.581668, zipCode: "32216"},
	{latitude: 30.22918, longitude: -81.621158, zipCode: "32217"},
	{latitude: 30.487842, longitude: -81.667693, zipCode: "32218"},
	{latitude: 30.426654, longitude: -81.815005, zipCode: "32219"},
	{latitude: 30.335003, longitude: -81.855324, zipCode: "32220"},
	{latitude: 30.258573, longitude: -81.878191, zipCode: "32221"},
	{latitude: 30.208918, longitude: -81.831013, zipCode: "32222"},
	{latitude: 30.156574, longitude: -81.649405, zipCode: "32223"},
	{latitude: 30.269971, longitude: -81.460939, zipCode: "32224"},
	{latitude: 30.357756, longitude: -81.505829, zipCode: "32225"},
	{latitude: 30.481248, longitude: -81.506157, zipCode: "32226"},
	{latitude: 30.389189, longitude: -81.404408, zipCode: "32227"},
	{latitude: 30.39186, longitude: -81.400074, zipCode: "32228"},
	{latitude: 30.357338, longitude: -81.416992, zipCode: "32233"},
	{latitude: 30.226305, longitude: -82.004356, zipCode: "32234"},
	{latitude: 30.217099, longitude: -81.752604, zipCode: "32244"},
	{latitude: 30.291014, longitude: -81.512086, zipCode: "32246"},
	{latitude: 30.28049, longitude: -81.412038, zipCode: "32250"},
	{latitude: 30.340961, longitude: -81.734788, zipCode: "32254"},
	{latitude: 30.175327, longitude: -81.471951, zipCode: "32256"},
	{latitude: 30.189355, longitude: -81.612466, zipCode: "32257"},
	{latitude: 30.140712, longitude: -81.551712, zipCode: "32258"},
	{latitude: 30.074317, longitude: -81.585207, zipCode: "32259"},
	{latitude: 30.317386, longitude: -81.409066, zipCode: "32266"},
	{latitude: 30.37745, longitude: -81.593947, zipCode: "32277"},
	{latitude: 30.427618, longitude: -84.257753, zipCode: "32301"},
	{latitude: 30.512386, longitude: -84.344877, zipCode: "32303"},
	{latitude: 30.451981, longitude: -84.352682, zipCode: "32304"},
	{latitude: 30.349872, longitude: -84.300765, zipCode: "32305"},
	{latitude: 30.442319, longitude: -84.294482, zipCode: "32306"},
	{latitude: 30.42172, longitude: -84.286851, zipCode: "32307"},
	{latitude: 30.480911, longitude: -84.219746, zipCode: "32308"},
	{latitude: 30.58125, longitude: -84.104073, zipCode: "32309"},
	{latitude: 30.433038, longitude: -84.391845, zipCode: "32310"},
	{latitude: 30.39005, longitude: -84.181035, zipCode: "32311"},
	{latitude: 30.604278, longitude: -84.243286, zipCode: "32312"},
	{latitude: 30.443147, longitude: -84.300736, zipCode: "32313"},
	{latitude: 30.470481, longitude: -84.116694, zipCode: "32317"},
	{latitude: 29.731534, longitude: -85.054224, zipCode: "32320"},
	{latitude: 30.26275, longitude: -84.99775, zipCode: "32321"},
	{latitude: 29.864047, longitude: -84.631996, zipCode: "32322"},
	{latitude: 29.891511, longitude: -84.613733, zipCode: "32323"},
	{latitude: 30.654759, longitude: -84.783247, zipCode: "32324"},
	{latitude: 30.181534, longitude: -84.302685, zipCode: "32327"},
	{latitude: 29.80043, longitude: -84.896825, zipCode: "32328"},
	{latitude: 30.578634, longitude: -84.747993, zipCode: "32330"},
	{latitude: 30.454744, longitude: -83.577769, zipCode: "32331"},
	{latitude: 30.610125, longitude: -84.66511, zipCode: "32332"},
	{latitude: 30.607743, longitude: -84.417592, zipCode: "32333"},
	{latitude: 30.259321, longitude: -84.758416, zipCode: "32334"},
	{latitude: 30.263057, longitude: -83.865499, zipCode: "32336"},
	{latitude: 30.483148, longitude: -84.021121, zipCode: "32337"},
	{latitude: 30.448953, longitude: -83.434802, zipCode: "32340"},
	{latitude: 30.478331, longitude: -84.504689, zipCode: "32343"},
	{latitude: 30.444549, longitude: -83.925998, zipCode: "32344"},
	{latitude: 29.982274, longitude: -84.380578, zipCode: "32346"},
	{latitude: 30.150653, longitude: -83.640744, zipCode: "32347"},
	{latitude: 29.942417, longitude: -83.556926, zipCode: "32348"},
	{latitude: 30.574972, longitude: -83.325794, zipCode: "32350"},
	{latitude: 30.520125, longitude: -84.675081, zipCode: "32351"},
	{latitude: 30.662261, longitude: -84.591627, zipCode: "32352"},
	{latitude: 30.16907, longitude: -84.209318, zipCode: "32355"},
	{latitude: 29.859235, longitude: -83.415003, zipCode: "32356"},
	{latitude: 30.091044, longitude: -84.540694, zipCode: "32358"},
	{latitude: 29.650039, longitude: -83.376036, zipCode: "32359"},
	{latitude: 30.365008, longitude: -84.812364, zipCode: "32360"},
	{latitude: 30.357645, longitude: -83.98866, zipCode: "32361"},
	{latitude: 30.383268, longitude: -84.231203, zipCode: "32399"},
	{latitude: 30.158374, longitude: -85.637996, zipCode: "32401"},
	{latitude: 30.048562, longitude: -85.553231, zipCode: "32403"},
	{latitude: 30.185025, longitude: -85.507385, zipCode: "32404"},
	{latitude: 30.199378, longitude: -85.668605, zipCode: "32405"},
	{latitude: 30.198324, longitude: -85.790694, zipCode: "32407"},
	{latitude: 30.156773, longitude: -85.754657, zipCode: "32408"},
	{latitude: 30.34127, longitude: -85.712606, zipCode: "32409"},
	{latitude: 29.951994, longitude: -85.428336, zipCode: "32410"},
	{latitude: 30.305727, longitude: -85.886321, zipCode: "32413"},
	{latitude: 30.635631, longitude: -85.381706, zipCode: "32420"},
	{latitude: 30.521467, longitude: -85.205731, zipCode: "32421"},
	{latitude: 30.943777, longitude: -85.048909, zipCode: "32423"},
	{latitude: 30.390602, longitude: -85.081807, zipCode: "32424"},
	{latitude: 30.853184, longitude: -85.720492, zipCode: "32425"},
	{latitude: 30.953978, longitude: -85.367431, zipCode: "32426"},
	{latitude: 30.710487, longitude: -85.792309, zipCode: "32427"},
	{latitude: 30.61575, longitude: -85.572945, zipCode: "32428"},
	{latitude: 30.418696, longitude: -85.225596, zipCode: "32430"},
	{latitude: 30.787482, longitude: -85.410816, zipCode: "32431"},
	{latitude: 30.714114, longitude: -85.077237, zipCode: "32432"},
	{latitude: 30.851178, longitude: -86.200838, zipCode: "32433"},
	{latitude: 30.648635, longitude: -86.130197, zipCode: "32435"},
	{latitude: 30.436248, longitude: -85.909221, zipCode: "32437"},
	{latitude: 30.511651, longitude: -85.414931, zipCode: "32438"},
	{latitude: 30.483268, longitude: -86.130423, zipCode: "32439"},
	{latitude: 30.922426, longitude: -85.52619, zipCode: "32440"},
	{latitude: 30.686518, longitude: -85.008627, zipCode: "32442"},
	{latitude: 30.872521, longitude: -85.10942, zipCode: "32443"},
	{latitude: 30.238451, longitude: -85.651586, zipCode: "32444"},
	{latitude: 30.97052, longitude: -85.197338, zipCode: "32445"},
	{latitude: 30.84817, longitude: -85.231368, zipCode: "32446"},
	{latitude: 30.671012, longitude: -85.223312, zipCode: "32448"},
	{latitude: 30.268296, longitude: -85.218138, zipCode: "32449"},
	{latitude: 30.638626, longitude: -85.962701, zipCode: "32455"},
	{latitude: 29.814542, longitude: -85.306423, zipCode: "32456"},
	{latitude: 30.360577, longitude: -86.185591, zipCode: "32459"},
	{latitude: 30.764089, longitude: -84.954234, zipCode: "32460"},
	{latitude: 30.308898, longitude: -86.054011, zipCode: "32461"},
	{latitude: 30.561812, longitude: -85.82373, zipCode: "32462"},
	{latitude: 30.628041, longitude: -85.58734, zipCode: "32463"},
	{latitude: 30.881353, longitude: -85.955991, zipCode: "32464"},
	{latitude: 30.007868, longitude: -85.181889, zipCode: "32465"},
	{latitude: 30.388401, longitude: -85.465062, zipCode: "32466"},
	{latitude: 30.428781, longitude: -87.222515, zipCode: "32501"},
	{latitude: 30.408929, longitude: -87.226813, zipCode: "32502"},
	{latitude: 30.459667, longitude: -87.213643, zipCode: "32503"},
	{latitude: 30.473121, longitude: -87.186316, zipCode: "32504"},
	{latitude: 30.454624, longitude: -87.26065, zipCode: "32505"},
	{latitude: 30.391889, longitude: -87.371994, zipCode: "32506"},
	{latitude: 30.339189, longitude: -87.378231, zipCode: "32507"},
	{latitude: 30.350614, longitude: -87.316902, zipCode: "32508"},
	{latitude: 30.469744, longitude: -87.340363, zipCode: "32509"},
	{latitude: 30.40635, longitude: -87.291321, zipCode: "32511"},
	{latitude: 30.398995, longitude: -87.299246, zipCode: "32512"},
	{latitude: 30.53218, longitude: -87.222539, zipCode: "32514"},
	{latitude: 30.499226, longitude: -87.365573, zipCode: "32526"},
	{latitude: 30.597113, longitude: -87.020019, zipCode: "32530"},
	{latitude: 30.898091, longitude: -86.693071, zipCode: "32531"},
	{latitude: 30.612555, longitude: -87.324338, zipCode: "32533"},
	{latitude: 30.528304, longitude: -87.286197, zipCode: "32534"},
	{latitude: 30.963698, longitude: -87.346175, zipCode: "32535"},
	{latitude: 30.750432, longitude: -86.597598, zipCode: "32536"},
	{latitude: 30.778999, longitude: -86.477169, zipCode: "32539"},
	{latitude: 30.395159, longitude: -86.478784, zipCode: "32541"},
	{latitude: 30.507766, longitude: -86.541629, zipCode: "32542"},
	{latitude: 30.426372, longitude: -86.719287, zipCode: "32544"},
	{latitude: 30.466178, longitude: -86.652985, zipCode: "32547"},
	{latitude: 30.413213, longitude: -86.589259, zipCode: "32548"},
	{latitude: 30.381433, longitude: -86.345668, zipCode: "32550"},
	{latitude: 30.340667, longitude: -87.116593, zipCode: "32561"},
	{latitude: 30.390852, longitude: -87.024785, zipCode: "32563"},
	{latitude: 30.728808, longitude: -86.781915, zipCode: "32564"},
	{latitude: 30.89438, longitude: -87.145454, zipCode: "32565"},
	{latitude: 30.425302, longitude: -86.892346, zipCode: "32566"},
	{latitude: 30.921061, longitude: -86.431503, zipCode: "32567"},
	{latitude: 30.877835, longitude: -87.47486, zipCode: "32568"},
	{latitude: 30.41138, longitude: -86.728401, zipCode: "32569"},
	{latitude: 30.791549, longitude: -86.982974, zipCode: "32570"},
	{latitude: 30.673287, longitude: -87.19585, zipCode: "32571"},
	{latitude: 30.740981, longitude: -87.379367, zipCode: "32577"},
	{latitude: 30.507855, longitude: -86.448368, zipCode: "32578"},
	{latitude: 30.445672, longitude: -86.574308, zipCode: "32579"},
	{latitude: 30.506542, longitude: -86.51469, zipCode: "32580"},
	{latitude: 30.604882, longitude: -86.972179, zipCode: "32583"},
	{latitude: 29.6487, longitude: -82.325412, zipCode: "32601"},
	{latitude: 29.653082, longitude: -82.350386, zipCode: "32603"},
	{latitude: 29.67918, longitude: -82.372822, zipCode: "32605"},
	{latitude: 29.682064, longitude: -82.444115, zipCode: "32606"},
	{latitude: 29.647333, longitude: -82.419155, zipCode: "32607"},
	{latitude: 29.59023, longitude: -82.396199, zipCode: "32608"},
	{latitude: 29.772187, longitude: -82.283422, zipCode: "32609"},
	{latitude: 29.64076, longitude: -82.343353, zipCode: "32610"},
	{latitude: 29.64312, longitude: -82.353845, zipCode: "32612"},
	{latitude: 29.81917, longitude: -82.486164, zipCode: "32615"},
	{latitude: 29.782044, longitude: -82.499316, zipCode: "32616"},
	{latitude: 29.308388, longitude: -82.074366, zipCode: "32617"},
	{latitude: 29.539756, longitude: -82.521492, zipCode: "32618"},
	{latitude: 29.756031, longitude: -82.86825, zipCode: "32619"},
	{latitude: 29.428967, longitude: -82.630916, zipCode: "32621"},
	{latitude: 29.900067, longitude: -82.307034, zipCode: "32622"},
	{latitude: 29.214738, longitude: -82.995429, zipCode: "32625"},
	{latitude: 29.423864, longitude: -82.899323, zipCode: "32626"},
	{latitude: 29.653159, longitude: -83.162979, zipCode: "32628"},
	{latitude: 29.728502, longitude: -82.113414, zipCode: "32631"},
	{latitude: 29.36466, longitude: -82.254275, zipCode: "32634"},
	{latitude: 29.232242, longitude: -82.721668, zipCode: "32639"},
	{latitude: 29.557729, longitude: -82.070774, zipCode: "32640"},
	{latitude: 29.647501, longitude: -82.239482, zipCode: "32641"},
	{latitude: 29.817981, longitude: -82.636103, zipCode: "32643"},
	{latitude: 29.500642, longitude: -83.238615, zipCode: "32648"},
	{latitude: 29.743875, longitude: -82.402776, zipCode: "32653"},
	{latitude: 29.827107, longitude: -81.945168, zipCode: "32656"},
	{latitude: 29.851858, longitude: -82.416554, zipCode: "32658"},
	{latitude: 29.449347, longitude: -82.221854, zipCode: "32664"},
	{latitude: 29.714185, longitude: -81.992515, zipCode: "32666"},
	{latitude: 29.489515, longitude: -82.304316, zipCode: "32667"},
	{latitude: 29.257242, longitude: -82.474721, zipCode: "32668"},
	{latitude: 29.632156, longitude: -82.589919, zipCode: "32669"},
	{latitude: 29.574911, longitude: -83.074406, zipCode: "32680"},
	{latitude: 29.426939, longitude: -82.215214, zipCode: "32681"},
	{latitude: 29.274237, longitude: -82.769519, zipCode: "32683"},
	{latitude: 29.355545, longitude: -82.275017, zipCode: "32686"},
	{latitude: 29.329319, longitude: -83.172494, zipCode: "32692"},
	{latitude: 29.626485, longitude: -82.785375, zipCode: "32693"},
	{latitude: 29.808061, longitude: -82.144376, zipCode: "32694"},
	{latitude: 29.400068, longitude: -82.469276, zipCode: "32696"},
	{latitude: 29.92816, longitude: -82.425529, zipCode: "32697"},
	{latitude: 28.665624, longitude: -81.370356, zipCode: "32701"},
	{latitude: 29.044189, longitude: -81.627473, zipCode: "32702"},
	{latitude: 28.653636, longitude: -81.520744, zipCode: "32703"},
	{latitude: 28.664104, longitude: -81.314908, zipCode: "32707"},
	{latitude: 28.688064, longitude: -81.269962, zipCode: "32708"},
	{latitude: 28.502489, longitude: -80.95883, zipCode: "32709"},
	{latitude: 28.736081, longitude: -81.547624, zipCode: "32712"},
	{latitude: 28.881398, longitude: -81.32427, zipCode: "32713"},
	{latitude: 28.662487, longitude: -81.411607, zipCode: "32714"},
	{latitude: 29.033304, longitude: -81.374753, zipCode: "32720"},
	{latitude: 29.034536, longitude: -81.304522, zipCode: "32723"},
	{latitude: 29.057205, longitude: -81.225576, zipCode: "32724"},
	{latitude: 28.886189, longitude: -81.251417, zipCode: "32725"},
	{latitude: 28.854136, longitude: -81.679937, zipCode: "32726"},
	{latitude: 28.652057, longitude: -81.34348, zipCode: "32730"},
	{latitude: 28.751037, longitude: -81.102119, zipCode: "32732"},
	{latitude: 28.890423, longitude: -81.739039, zipCode: "32735"},
	{latitude: 28.916675, longitude: -81.489093, zipCode: "32736"},
	{latitude: 28.904744, longitude: -81.188232, zipCode: "32738"},
	{latitude: 28.985234, longitude: -81.219583, zipCode: "32744"},
	{latitude: 28.771373, longitude: -81.353086, zipCode: "32746"},
	{latitude: 28.70865, longitude: -81.350482, zipCode: "32750"},
	{latitude: 28.6304, longitude: -81.365046, zipCode: "32751"},
	{latitude: 28.687109, longitude: -80.919193, zipCode: "32754"},
	{latitude: 28.776422, longitude: -81.631228, zipCode: "32757"},
	{latitude: 28.825537, longitude: -80.902691, zipCode: "32759"},
	{latitude: 28.942166, longitude: -81.306122, zipCode: "32763"},
	{latitude: 28.836668, longitude: -81.063065, zipCode: "32764"},
	{latitude: 28.665112, longitude: -81.19629, zipCode: "32765"},
	{latitude: 28.647675, longitude: -81.044691, zipCode: "32766"},
	{latitude: 29.026591, longitude: -81.534474, zipCode: "32767"},
	{latitude: 28.695755, longitude: -81.557944, zipCode: "32768"},
	{latitude: 28.814656, longitude: -81.317583, zipCode: "32771"},
	{latitude: 28.755093, longitude: -81.247488, zipCode: "32773"},
	{latitude: 28.767044, longitude: -80.876699, zipCode: "32775"},
	{latitude: 28.802306, longitude: -81.483683, zipCode: "32776"},
	{latitude: 28.773431, longitude: -81.73023, zipCode: "32778"},
	{latitude: 28.726884, longitude: -81.414795, zipCode: "32779"},
	{latitude: 28.528169, longitude: -80.779838, zipCode: "32780"},
	{latitude: 28.974313, longitude: -81.716763, zipCode: "32784"},
	{latitude: 28.59917, longitude: -81.352238, zipCode: "32789"},
	{latitude: 28.608352, longitude: -81.300566, zipCode: "32792"},
	{latitude: 28.625289, longitude: -80.845855, zipCode: "32796"},
	{latitude: 28.723731, longitude: -81.58772, zipCode: "32798"},
	{latitude: 28.541769, longitude: -81.374357, zipCode: "32801"},
	{latitude: 28.555617, longitude: -81.348447, zipCode: "32803"},
	{latitude: 28.579038, longitude: -81.394201, zipCode: "32804"},
	{latitude: 28.529974, longitude: -81.406402, zipCode: "32805"},
	{latitude: 28.512357, longitude: -81.360269, zipCode: "32806"},
	{latitude: 28.553677, longitude: -81.300656, zipCode: "32807"},
	{latitude: 28.58011, longitude: -81.4443, zipCode: "32808"},
	{latitude: 28.462218, longitude: -81.385219, zipCode: "32809"},
	{latitude: 28.621595, longitude: -81.428706, zipCode: "32810"},
	{latitude: 28.521039, longitude: -81.447731, zipCode: "32811"},
	{latitude: 28.484039, longitude: -81.324313, zipCode: "32812"},
	{latitude: 28.56947, longitude: -81.326469, zipCode: "32814"},
	{latitude: 28.600084, longitude: -81.195983, zipCode: "32816"},
	{latitude: 28.587848, longitude: -81.244103, zipCode: "32817"},
	{latitude: 28.59006, longitude: -81.493848, zipCode: "32818"},
	{latitude: 28.453705, longitude: -81.472373, zipCode: "32819"},
	{latitude: 28.576926, longitude: -81.139837, zipCode: "32820"},
	{latitude: 28.381347, longitude: -81.480092, zipCode: "32821"},
	{latitude: 28.489898, longitude: -81.290154, zipCode: "32822"},
	{latitude: 28.388751, longitude: -81.344535, zipCode: "32824"},
	{latitude: 28.536725, longitude: -81.242932, zipCode: "32825"},
	{latitude: 28.579663, longitude: -81.18864, zipCode: "32826"},
	{latitude: 28.431169, longitude: -81.323825, zipCode: "32827"},
	{latitude: 28.530179, longitude: -81.169271, zipCode: "32828"},
	{latitude: 28.477164, longitude: -81.223883, zipCode: "32829"},
	{latitude: 28.391693, longitude: -81.567239, zipCode: "32830"},
	{latitude: 28.486537, longitude: -81.146405, zipCode: "32831"},
	{latitude: 28.400248, longitude: -81.176562, zipCode: "32832"},
	{latitude: 28.494294, longitude: -81.083265, zipCode: "32833"},
	{latitude: 28.521975, longitude: -81.485967, zipCode: "32835"},
	{latitude: 28.405681, longitude: -81.516437, zipCode: "32836"},
	{latitude: 28.38224, longitude: -81.41446, zipCode: "32837"},
	{latitude: 28.488069, longitude: -81.407208, zipCode: "32839"},
	{latitude: 28.570183, longitude: -80.627676, zipCode: "32899"},
	{latitude: 28.079378, longitude: -80.622302, zipCode: "32901"},
	{latitude: 28.102377, longitude: -80.58217, zipCode: "32903"},
	{latitude: 28.032879, longitude: -80.81605, zipCode: "32904"},
	{latitude: 28.031366, longitude: -80.600414, zipCode: "32905"},
	{latitude: 28.020947, longitude: -80.679785, zipCode: "32907"},
	{latitude: 27.956179, longitude: -80.698269, zipCode: "32908"},
	{latitude: 27.899533, longitude: -80.659568, zipCode: "32909"},
	{latitude: 28.441757, longitude: -80.587945, zipCode: "32920"},
	{latitude: 28.372888, longitude: -80.741608, zipCode: "32922"},
	{latitude: 28.25163, longitude: -80.610008, zipCode: "32925"},
	{latitude: 28.382901, longitude: -80.819312, zipCode: "32926"},
	{latitude: 28.463766, longitude: -80.806711, zipCode: "32927"},
	{latitude: 28.320489, longitude: -80.625805, zipCode: "32931"},
	{latitude: 28.134548, longitude: -80.713887, zipCode: "32934"},
	{latitude: 28.145532, longitude: -80.649962, zipCode: "32935"},
	{latitude: 28.175952, longitude: -80.605279, zipCode: "32937"},
	{latitude: 28.210691, longitude: -80.750596, zipCode: "32940"},
	{latitude: 27.759557, longitude: -80.629194, zipCode: "32948"},
	{latitude: 27.909779, longitude: -80.560567, zipCode: "32949"},
	{latitude: 27.980527, longitude: -80.569574, zipCode: "32950"},
	{latitude: 27.944027, longitude: -80.503427, zipCode: "32951"},
	{latitude: 28.320397, longitude: -80.659178, zipCode: "32952"},
	{latitude: 28.463513, longitude: -80.687731, zipCode: "32953"},
	{latitude: 28.288759, longitude: -80.791442, zipCode: "32955"},
	{latitude: 27.794384, longitude: -80.49146, zipCode: "32958"},
	{latitude: 28.445127, longitude: -80.763703, zipCode: "32959"},
	{latitude: 27.640722, longitude: -80.403592, zipCode: "32960"},
	{latitude: 27.583462, longitude: -80.383467, zipCode: "32962"},
	{latitude: 27.718239, longitude: -80.393276, zipCode: "32963"},
	{latitude: 27.686911, longitude: -80.715786, zipCode: "32966"},
	{latitude: 27.71549, longitude: -80.454289, zipCode: "32967"},
	{latitude: 27.593204, longitude: -80.508685, zipCode: "32968"},
	{latitude: 27.754026, longitude: -80.450493, zipCode: "32970"},
	{latitude: 27.846262, longitude: -80.554114, zipCode: "32976"},
	{latitude: 24.835499, longitude: -80.801568, zipCode: "33001"},
	{latitude: 26.061046, longitude: -80.135859, zipCode: "33004"},
	{latitude: 25.984406, longitude: -80.145605, zipCode: "33009"},
	{latitude: 25.833202, longitude: -80.27872, zipCode: "33010"},
	{latitude: 25.866106, longitude: -80.301115, zipCode: "33012"},
	{latitude: 25.86219, longitude: -80.269677, zipCode: "33013"},
	{latitude: 25.904642, longitude: -80.30208, zipCode: "33014"},
	{latitude: 25.940571, longitude: -80.317442, zipCode: "33015"},
	{latitude: 25.893769, longitude: -80.330087, zipCode: "33016"},
	{latitude: 25.912395, longitude: -80.393093, zipCode: "33018"},
	{latitude: 26.027614, longitude: -80.120197, zipCode: "33019"},
	{latitude: 26.019191, longitude: -80.152944, zipCode: "33020"},
	{latitude: 26.02313, longitude: -80.186539, zipCode: "33021"},
	{latitude: 26.013848, longitude: -80.144066, zipCode: "33022"},
	{latitude: 25.988484, longitude: -80.212462, zipCode: "33023"},
	{latitude: 26.026358, longitude: -80.244633, zipCode: "33024"},
	{latitude: 25.988183, longitude: -80.281394, zipCode: "33025"},
	{latitude: 26.026198, longitude: -80.295994, zipCode: "33026"},
	{latitude: 25.98811, longitude: -80.340442, zipCode: "33027"},
	{latitude: 26.014716, longitude: -80.340171, zipCode: "33028"},
	{latitude: 25.992293, longitude: -80.394197, zipCode: "33029"},
	{latitude: 25.485755, longitude: -80.508192, zipCode: "33030"},
	{latitude: 25.524991, longitude: -80.500876, zipCode: "33031"},
	{latitude: 25.531145, longitude: -80.391233, zipCode: "33032"},
	{latitude: 25.4789, longitude: -80.409102, zipCode: "33033"},
	{latitude: 25.472158, longitude: -80.773252, zipCode: "33034"},
	{latitude: 25.422344, longitude: -80.399874, zipCode: "33035"},
	{latitude: 24.901896, longitude: -80.698998, zipCode: "33036"},
	{latitude: 25.225842, longitude: -80.414337, zipCode: "33037"},
	{latitude: 25.497969, longitude: -80.398172, zipCode: "33039"},
	{latitude: 24.54461, longitude: -81.921737, zipCode: "33040"},
	{latitude: 24.665275, longitude: -81.503095, zipCode: "33042"},
	{latitude: 24.669652, longitude: -81.260781, zipCode: "33043"},
	{latitude: 24.749924, longitude: -80.986567, zipCode: "33050"},
	{latitude: 24.721256, longitude: -81.021325, zipCode: "33051"},
	{latitude: 25.907995, longitude: -80.257509, zipCode: "33054"},
	{latitude: 25.948468, longitude: -80.278855, zipCode: "33055"},
	{latitude: 25.948892, longitude: -80.243585, zipCode: "33056"},
	{latitude: 26.234723, longitude: -80.121087, zipCode: "33060"},
	{latitude: 26.241077, longitude: -80.091233, zipCode: "33062"},
	{latitude: 26.251909, longitude: -80.208929, zipCode: "33063"},
	{latitude: 26.277754, longitude: -80.115325, zipCode: "33064"},
	{latitude: 26.274498, longitude: -80.2604, zipCode: "33065"},
	{latitude: 26.252926, longitude: -80.17038, zipCode: "33066"},
	{latitude: 26.305853, longitude: -80.226402, zipCode: "33067"},
	{latitude: 26.216395, longitude: -80.213958, zipCode: "33068"},
	{latitude: 26.23583, longitude: -80.1571, zipCode: "33069"},
	{latitude: 25.01103, longitude: -80.529313, zipCode: "33070"},
	{latitude: 26.245781, longitude: -80.267366, zipCode: "33071"},
	{latitude: 26.298166, longitude: -80.182101, zipCode: "33073"},
	{latitude: 26.319325, longitude: -80.271793, zipCode: "33076"},
	{latitude: 25.779764, longitude: -80.198763, zipCode: "33101"},
	{latitude: 25.761184, longitude: -80.130777, zipCode: "33109"},
	{latitude: 25.805005, longitude: -80.315071, zipCode: "33122"},
	{latitude: 25.783834, longitude: -80.236333, zipCode: "33125"},
	{latitude: 25.779492, longitude: -80.299573, zipCode: "33126"},
	{latitude: 25.813529, longitude: -80.204358, zipCode: "33127"},
	{latitude: 25.776769, longitude: -80.203433, zipCode: "33128"},
	{latitude: 25.754889, longitude: -80.198479, zipCode: "33129"},
	{latitude: 25.768524, longitude: -80.203359, zipCode: "33130"},
	{latitude: 25.765524, longitude: -80.184349, zipCode: "33131"},
	{latitude: 25.776585, longitude: -80.173242, zipCode: "33132"},
	{latitude: 25.731479, longitude: -80.23384, zipCode: "33133"},
	{latitude: 25.753332, longitude: -80.270379, zipCode: "33134"},
	{latitude: 25.766612, longitude: -80.235036, zipCode: "33135"},
	{latitude: 25.787247, longitude: -80.205296, zipCode: "33136"},
	{latitude: 25.8109, longitude: -80.166944, zipCode: "33137"},
	{latitude: 25.853764, longitude: -80.178006, zipCode: "33138"},
	{latitude: 25.779391, longitude: -80.151566, zipCode: "33139"},
	{latitude: 25.816916, longitude: -80.13566, zipCode: "33140"},
	{latitude: 25.85098, longitude: -80.138556, zipCode: "33141"},
	{latitude: 25.80631, longitude: -80.255409, zipCode: "33142"},
	{latitude: 25.702492, longitude: -80.2986, zipCode: "33143"},
	{latitude: 25.763458, longitude: -80.312667, zipCode: "33144"},
	{latitude: 25.753247, longitude: -80.234512, zipCode: "33145"},
	{latitude: 25.72085, longitude: -80.272571, zipCode: "33146"},
	{latitude: 25.851208, longitude: -80.23885, zipCode: "33147"},
	{latitude: 25.714412, longitude: -80.163587, zipCode: "33149"},
	{latitude: 25.85219, longitude: -80.207174, zipCode: "33150"},
	{latitude: 25.885908, longitude: -80.132227, zipCode: "33154"},
	{latitude: 25.737789, longitude: -80.311193, zipCode: "33155"},
	{latitude: 25.668203, longitude: -80.29701, zipCode: "33156"},
	{latitude: 25.607529, longitude: -80.343349, zipCode: "33157"},
	{latitude: 25.63884, longitude: -80.311316, zipCode: "33158"},
	{latitude: 25.933455, longitude: -80.135342, zipCode: "33160"},
	{latitude: 25.893543, longitude: -80.183272, zipCode: "33161"},
	{latitude: 25.928618, longitude: -80.179211, zipCode: "33162"},
	{latitude: 25.734345, longitude: -80.358271, zipCode: "33165"},
	{latitude: 25.824907, longitude: -80.310665, zipCode: "33166"},
	{latitude: 25.884528, longitude: -80.236019, zipCode: "33167"},
	{latitude: 25.892856, longitude: -80.209124, zipCode: "33168"},
	{latitude: 25.943117, longitude: -80.214686, zipCode: "33169"},
	{latitude: 25.558264, longitude: -80.449634, zipCode: "33170"},
	{latitude: 25.786716, longitude: -80.364872, zipCode: "33172"},
	{latitude: 25.70232, longitude: -80.357503, zipCode: "33173"},
	{latitude: 25.762048, longitude: -80.3595, zipCode: "33174"},
	{latitude: 25.736612, longitude: -80.40683, zipCode: "33175"},
	{latitude: 25.658379, longitude: -80.360893, zipCode: "33176"},
	{latitude: 25.598817, longitude: -80.406503, zipCode: "33177"},
	{latitude: 25.835497, longitude: -80.422481, zipCode: "33178"},
	{latitude: 25.95762, longitude: -80.179998, zipCode: "33179"},
	{latitude: 25.962149, longitude: -80.140904, zipCode: "33180"},
	{latitude: 25.89766, longitude: -80.151112, zipCode: "33181"},
	{latitude: 25.782549, longitude: -80.401385, zipCode: "33182"},
	{latitude: 25.700602, longitude: -80.405729, zipCode: "33183"},
	{latitude: 25.759711, longitude: -80.406473, zipCode: "33184"},
	{latitude: 25.730485, longitude: -80.454283, zipCode: "33185"},
	{latitude: 25.65442, longitude: -80.412154, zipCode: "33186"},
	{latitude: 25.52743, longitude: -80.599826, zipCode: "33187"},
	{latitude: 25.573102, longitude: -80.338974, zipCode: "33189"},
	{latitude: 25.556885, longitude: -80.349914, zipCode: "33190"},
	{latitude: 25.705415, longitude: -80.470847, zipCode: "33193"},
	{latitude: 25.71406, longitude: -80.583523, zipCode: "33194"},
	{latitude: 25.648447, longitude: -80.55467, zipCode: "33196"},
	{latitude: 26.121323, longitude: -80.127909, zipCode: "33301"},
	{latitude: 26.14134, longitude: -80.119169, zipCode: "33304"},
	{latitude: 26.153446, longitude: -80.122627, zipCode: "33305"},
	{latitude: 26.165508, longitude: -80.114094, zipCode: "33306"},
	{latitude: 26.18851, longitude: -80.104988, zipCode: "33308"},
	{latitude: 26.18599, longitude: -80.172721, zipCode: "33309"},
	{latitude: 26.144208, longitude: -80.172785, zipCode: "33311"},
	{latitude: 26.08817, longitude: -80.181783, zipCode: "33312"},
	{latitude: 26.15152, longitude: -80.227397, zipCode: "33313"},
	{latitude: 26.06789, longitude: -80.22416, zipCode: "33314"},
	{latitude: 26.087005, longitude: -80.152976, zipCode: "33315"},
	{latitude: 26.101566, longitude: -80.120955, zipCode: "33316"},
	{latitude: 26.1126, longitude: -80.227875, zipCode: "33317"},
	{latitude: 26.181382, longitude: -80.225802, zipCode: "33319"},
	{latitude: 26.212501, longitude: -80.270158, zipCode: "33321"},
	{latitude: 26.149977, longitude: -80.275707, zipCode: "33322"},
	{latitude: 26.151759, longitude: -80.320939, zipCode: "33323"},
	{latitude: 26.112485, longitude: -80.27494, zipCode: "33324"},
	{latitude: 26.110546, longitude: -80.324093, zipCode: "33325"},
	{latitude: 26.110469, longitude: -80.374015, zipCode: "33326"},
	{latitude: 26.226029, longitude: -80.367607, zipCode: "33327"},
	{latitude: 26.070471, longitude: -80.272864, zipCode: "33328"},
	{latitude: 26.060566, longitude: -80.330284, zipCode: "33330"},
	{latitude: 26.060462, longitude: -80.369595, zipCode: "33331"},
	{latitude: 26.006605, longitude: -80.460176, zipCode: "33332"},
	{latitude: 26.183753, longitude: -80.134055, zipCode: "33334"},
	{latitude: 26.179581, longitude: -80.27504, zipCode: "33351"},
	{latitude: 26.117118, longitude: -80.254165, zipCode: "33388"},
	{latitude: 26.715793, longitude: -80.066777, zipCode: "33401"},
	{latitude: 26.803774, longitude: -80.076385, zipCode: "33403"},
	{latitude: 26.781099, longitude: -80.063244, zipCode: "33404"},
	{latitude: 26.669649, longitude: -80.058452, zipCode: "33405"},
	{latitude: 26.664083, longitude: -80.092373, zipCode: "33406"},
	{latitude: 26.757692, longitude: -80.091172, zipCode: "33407"},
	{latitude: 26.840895, longitude: -80.052098, zipCode: "33408"},
	{latitude: 26.715601, longitude: -80.095886, zipCode: "33409"},
	{latitude: 26.845662, longitude: -80.08735, zipCode: "33410"},
	{latitude: 26.715201, longitude: -80.203797, zipCode: "33411"},
	{latitude: 26.803302, longitude: -80.217319, zipCode: "33412"},
	{latitude: 26.663715, longitude: -80.151341, zipCode: "33413"},
	{latitude: 26.646721, longitude: -80.250311, zipCode: "33414"},
	{latitude: 26.660205, longitude: -80.126573, zipCode: "33415"},
	{latitude: 26.720445, longitude: -80.124016, zipCode: "33417"},
	{latitude: 26.872713, longitude: -80.165712, zipCode: "33418"},
	{latitude: 26.533736, longitude: -80.083152, zipCode: "33426"},
	{latitude: 26.351283, longitude: -80.229122, zipCode: "33428"},
	{latitude: 26.602653, longitude: -80.579271, zipCode: "33430"},
	{latitude: 26.37459, longitude: -80.10661, zipCode: "33431"},
	{latitude: 26.343798, longitude: -80.08221, zipCode: "33432"},
	{latitude: 26.347076, longitude: -80.159189, zipCode: "33433"},
	{latitude: 26.383168, longitude: -80.167688, zipCode: "33434"},
	{latitude: 26.525785, longitude: -80.061367, zipCode: "33435"},
	{latitude: 26.522954, longitude: -80.107263, zipCode: "33436"},
	{latitude: 26.509389, longitude: -80.148474, zipCode: "33437"},
	{latitude: 26.976388, longitude: -80.523513, zipCode: "33438"},
	{latitude: 26.537946, longitude: -81.044144, zipCode: "33440"},
	{latitude: 26.311098, longitude: -80.097874, zipCode: "33441"},
	{latitude: 26.309716, longitude: -80.144905, zipCode: "33442"},
	{latitude: 26.457074, longitude: -80.079598, zipCode: "33444"},
	{latitude: 26.455873, longitude: -80.107935, zipCode: "33445"},
	{latitude: 26.457431, longitude: -80.183425, zipCode: "33446"},
	{latitude: 26.592718, longitude: -80.233877, zipCode: "33449"},
	{latitude: 27.053677, longitude: -80.16232, zipCode: "33455"},
	{latitude: 26.935806, longitude: -80.128441, zipCode: "33458"},
	{latitude: 26.618426, longitude: -80.055762, zipCode: "33460"},
	{latitude: 26.618582, longitude: -80.093216, zipCode: "33461"},
	{latitude: 26.576183, longitude: -80.072313, zipCode: "33462"},
	{latitude: 26.593249, longitude: -80.1289, zipCode: "33463"},
	{latitude: 26.596362, longitude: -80.175602, zipCode: "33467"},
	{latitude: 26.989347, longitude: -80.108549, zipCode: "33469"},
	{latitude: 26.783861, longitude: -80.380968, zipCode: "33470"},
	{latitude: 26.917498, longitude: -81.198156, zipCode: "33471"},
	{latitude: 26.539753, longitude: -80.18903, zipCode: "33472"},
	{latitude: 26.503291, longitude: -80.192245, zipCode: "33473"},
	{latitude: 26.800973, longitude: -80.65306, zipCode: "33476"},
	{latitude: 26.918903, longitude: -80.07606, zipCode: "33477"},
	{latitude: 26.939847, longitude: -80.261144, zipCode: "33478"},
	{latitude: 26.68522, longitude: -80.037632, zipCode: "33480"},
	{latitude: 26.462605, longitude: -80.061762, zipCode: "33483"},
	{latitude: 26.454311, longitude: -80.134344, zipCode: "33484"},
	{latitude: 26.347543, longitude: -80.114805, zipCode: "33486"},
	{latitude: 26.411793, longitude: -80.089007, zipCode: "33487"},
	{latitude: 26.475636, longitude: -80.706002, zipCode: "33493"},
	{latitude: 26.409046, longitude: -80.166139, zipCode: "33496"},
	{latitude: 26.395299, longitude: -80.219811, zipCode: "33498"},
	{latitude: 27.763831, longitude: -82.276174, zipCode: "33503"},
	{latitude: 27.955306, longitude: -82.299483, zipCode: "33510"},
	{latitude: 27.909778, longitude: -82.295738, zipCode: "33511"},
	{latitude: 28.659903, longitude: -82.16917, zipCode: "33513"},
	{latitude: 28.660841, longitude: -81.99678, zipCode: "33514"},
	{latitude: 28.79792, longitude: -82.075174, zipCode: "33521"},
	{latitude: 28.421657, longitude: -82.265759, zipCode: "33523"},
	{latitude: 28.330809, longitude: -82.183329, zipCode: "33525"},
	{latitude: 27.973749, longitude: -82.212806, zipCode: "33527"},
	{latitude: 27.9077, longitude: -82.172352, zipCode: "33530"},
	{latitude: 27.817981, longitude: -82.372248, zipCode: "33534"},
	{latitude: 28.849949, longitude: -82.18511, zipCode: "33538"},
	{latitude: 28.261577, longitude: -82.133112, zipCode: "33540"},
	{latitude: 28.234002, longitude: -82.22136, zipCode: "33541"},
	{latitude: 28.23578, longitude: -82.177617, zipCode: "33542"},
	{latitude: 28.205247, longitude: -82.317108, zipCode: "33543"},
	{latitude: 28.248334, longitude: -82.370909, zipCode: "33544"},
	{latitude: 28.268104, longitude: -82.291232, zipCode: "33545"},
	{latitude: 27.789984, longitude: -82.135805, zipCode: "33547"},
	{latitude: 28.146279, longitude: -82.482303, zipCode: "33548"},
	{latitude: 28.140689, longitude: -82.44972, zipCode: "33549"},
	{latitude: 27.979504, longitude: -82.307012, zipCode: "33550"},
	{latitude: 28.165897, longitude: -82.601578, zipCode: "33556"},
	{latitude: 28.165344, longitude: -82.510685, zipCode: "33558"},
	{latitude: 28.160954, longitude: -82.414663, zipCode: "33559"},
	{latitude: 28.017072, longitude: -82.125766, zipCode: "33563"},
	{latitude: 28.085906, longitude: -82.148114, zipCode: "33565"},
	{latitude: 27.977964, longitude: -82.095709, zipCode: "33566"},
	{latitude: 27.920609, longitude: -82.116772, zipCode: "33567"},
	{latitude: 27.848771, longitude: -82.295848, zipCode: "33569"},
	{latitude: 27.693944, longitude: -82.458577, zipCode: "33570"},
	{latitude: 27.766168, longitude: -82.400285, zipCode: "33572"},
	{latitude: 27.720216, longitude: -82.361796, zipCode: "33573"},
	{latitude: 28.338027, longitude: -82.257098, zipCode: "33574"},
	{latitude: 28.302956, longitude: -82.30376, zipCode: "33576"},
	{latitude: 27.861107, longitude: -82.35506, zipCode: "33578"},
	{latitude: 27.798135, longitude: -82.280616, zipCode: "33579"},
	{latitude: 28.005455, longitude: -82.294337, zipCode: "33584"},
	{latitude: 28.727849, longitude: -82.032335, zipCode: "33585"},
	{latitude: 28.101871, longitude: -82.283236, zipCode: "33592"},
	{latitude: 27.940934, longitude: -82.242479, zipCode: "33594"},
	{latitude: 27.8872, longitude: -82.225852, zipCode: "33596"},
	{latitude: 28.528762, longitude: -82.085255, zipCode: "33597"},
	{latitude: 27.693423, longitude: -82.284659, zipCode: "33598"},
	{latitude: 27.953704, longitude: -82.45631, zipCode: "33602"},
	{latitude: 27.985618, longitude: -82.464439, zipCode: "33603"},
	{latitude: 28.017154, longitude: -82.45535, zipCode: "33604"},
	{latitude: 27.951052, longitude: -82.429635, zipCode: "33605"},
	{latitude: 27.944035, longitude: -82.473954, zipCode: "33606"},
	{latitude: 27.965234, longitude: -82.519051, zipCode: "33607"},
	{latitude: 27.942562, longitude: -82.515044, zipCode: "33609"},
	{latitude: 27.998214, longitude: -82.378016, zipCode: "33610"},
	{latitude: 27.891345, longitude: -82.505665, zipCode: "33611"},
	{latitude: 28.050827, longitude: -82.449573, zipCode: "33612"},
	{latitude: 28.083694, longitude: -82.447872, zipCode: "33613"},
	{latitude: 28.004691, longitude: -82.506135, zipCode: "33614"},
	{latitude: 28.001166, longitude: -82.582227, zipCode: "33615"},
	{latitude: 27.865139, longitude: -82.535398, zipCode: "33616"},
	{latitude: 28.039315, longitude: -82.392428, zipCode: "33617"},
	{latitude: 28.074825, longitude: -82.498432, zipCode: "33618"},
	{latitude: 27.935372, longitude: -82.37869, zipCode: "33619"},
	{latitude: 28.061368, longitude: -82.412801, zipCode: "33620"},
	{latitude: 27.849178, longitude: -82.510781, zipCode: "33621"},
	{latitude: 28.080255, longitude: -82.528248, zipCode: "33624"},
	{latitude: 28.066997, longitude: -82.557957, zipCode: "33625"},
	{latitude: 28.064407, longitude: -82.596741, zipCode: "33626"},
	{latitude: 27.921536, longitude: -82.509043, zipCode: "33629"},
	{latitude: 28.009826, longitude: -82.547789, zipCode: "33634"},
	{latitude: 28.02054, longitude: -82.617948, zipCode: "33635"},
	{latitude: 28.045249, longitude: -82.362861, zipCode: "33637"},
	{latitude: 28.151887, longitude: -82.318179, zipCode: "33647"},
	{latitude: 27.770793, longitude: -82.632806, zipCode: "33701"},
	{latitude: 27.846603, longitude: -82.63059, zipCode: "33702"},
	{latitude: 27.817056, longitude: -82.61975, zipCode: "33703"},
	{latitude: 27.796331, longitude: -82.628723, zipCode: "33704"},
	{latitude: 27.73397, longitude: -82.640314, zipCode: "33705"},
	{latitude: 27.741501, longitude: -82.752062, zipCode: "33706"},
	{latitude: 27.743466, longitude: -82.724374, zipCode: "33707"},
	{latitude: 27.815056, longitude: -82.79779, zipCode: "33708"},
	{latitude: 27.816844, longitude: -82.731621, zipCode: "33709"},
	{latitude: 27.788642, longitude: -82.729628, zipCode: "33710"},
	{latitude: 27.739365, longitude: -82.687234, zipCode: "33711"},
	{latitude: 27.735324, longitude: -82.667105, zipCode: "33712"},
	{latitude: 27.788266, longitude: -82.677461, zipCode: "33713"},
	{latitude: 27.815251, longitude: -82.677709, zipCode: "33714"},
	{latitude: 27.665801, longitude: -82.722722, zipCode: "33715"},
	{latitude: 27.879858, longitude: -82.652797, zipCode: "33716"},
	{latitude: 27.807285, longitude: -82.773051, zipCode: "33744"},
	{latitude: 27.979868, longitude: -82.782744, zipCode: "33755"},
	{latitude: 27.945591, longitude: -82.793988, zipCode: "33756"},
	{latitude: 27.975567, longitude: -82.712224, zipCode: "33759"},
	{latitude: 27.905787, longitude: -82.713989, zipCode: "33760"},
	{latitude: 28.033061, longitude: -82.724374, zipCode: "33761"},
	{latitude: 27.907509, longitude: -82.677494, zipCode: "33762"},
	{latitude: 28.0029, longitude: -82.743429, zipCode: "33763"},
	{latitude: 27.937207, longitude: -82.740585, zipCode: "33764"},
	{latitude: 27.974876, longitude: -82.745122, zipCode: "33765"},
	{latitude: 27.974748, longitude: -82.826777, zipCode: "33767"},
	{latitude: 27.915109, longitude: -82.804026, zipCode: "33770"},
	{latitude: 27.905836, longitude: -82.758774, zipCode: "33771"},
	{latitude: 27.843593, longitude: -82.796683, zipCode: "33772"},
	{latitude: 27.881507, longitude: -82.7553, zipCode: "33773"},
	{latitude: 27.883819, longitude: -82.827877, zipCode: "33774"},
	{latitude: 27.849564, longitude: -82.825827, zipCode: "33776"},
	{latitude: 27.853364, longitude: -82.760823, zipCode: "33777"},
	{latitude: 27.888621, longitude: -82.79854, zipCode: "33778"},
	{latitude: 27.838871, longitude: -82.714995, zipCode: "33781"},
	{latitude: 27.859631, longitude: -82.708145, zipCode: "33782"},
	{latitude: 27.891841, longitude: -82.849979, zipCode: "33785"},
	{latitude: 27.932667, longitude: -82.8339, zipCode: "33786"},
	{latitude: 28.035979, longitude: -81.899636, zipCode: "33801"},
	{latitude: 28.009928, longitude: -81.936496, zipCode: "33803"},
	{latitude: 28.095476, longitude: -81.917942, zipCode: "33805"},
	{latitude: 28.202419, longitude: -81.955621, zipCode: "33809"},
	{latitude: 28.130235, longitude: -82.038987, zipCode: "33810"},
	{latitude: 27.978876, longitude: -82.015892, zipCode: "33811"},
	{latitude: 27.971442, longitude: -81.894476, zipCode: "33812"},
	{latitude: 27.947248, longitude: -81.926108, zipCode: "33813"},
	{latitude: 28.041258, longitude: -81.99759, zipCode: "33815"},
	{latitude: 28.087193, longitude: -81.81294, zipCode: "33823"},
	{latitude: 27.620252, longitude: -81.458826, zipCode: "33825"},
	{latitude: 27.817937, longitude: -81.516767, zipCode: "33827"},
	{latitude: 27.883187, longitude: -81.810993, zipCode: "33830"},
	{latitude: 27.631303, longitude: -81.912581, zipCode: "33834"},
	{latitude: 27.788246, longitude: -81.972591, zipCode: "33835"},
	{latitude: 28.194263, longitude: -81.587805, zipCode: "33837"},
	{latitude: 28.016968, longitude: -81.610021, zipCode: "33838"},
	{latitude: 27.97427, longitude: -81.755395, zipCode: "33839"},
	{latitude: 28.009157, longitude: -81.905049, zipCode: "33840"},
	{latitude: 27.737973, longitude: -81.768094, zipCode: "33841"},
	{latitude: 27.731789, longitude: -81.5127, zipCode: "33843"},
	{latitude: 28.089578, longitude: -81.589383, zipCode: "33844"},
	{latitude: 27.802851, longitude: -81.822059, zipCode: "33847"},
	{latitude: 28.275975, longitude: -81.50822, zipCode: "33848"},
	{latitude: 28.226068, longitude: -82.068886, zipCode: "33849"},
	{latitude: 28.124707, longitude: -81.731098, zipCode: "33850"},
	{latitude: 28.04481, longitude: -81.626341, zipCode: "33851"},
	{latitude: 27.258703, longitude: -81.378113, zipCode: "33852"},
	{latitude: 27.900772, longitude: -81.58567, zipCode: "33853"},
	{latitude: 27.856274, longitude: -81.40993, zipCode: "33854"},
	{latitude: 27.791034, longitude: -81.359714, zipCode: "33855"},
	{latitude: 27.854747, longitude: -81.429024, zipCode: "33856"},
	{latitude: 27.35915, longitude: -81.166596, zipCode: "33857"},
	{latitude: 28.245093, longitude: -81.558313, zipCode: "33858"},
	{latitude: 27.885222, longitude: -81.628206, zipCode: "33859"},
	{latitude: 27.818504, longitude: -82.005783, zipCode: "33860"},
	{latitude: 27.433787, longitude: -81.963257, zipCode: "33865"},
	{latitude: 27.781088, longitude: -81.20204, zipCode: "33867"},
	{latitude: 28.267639, longitude: -81.853797, zipCode: "33868"},
	{latitude: 27.503346, longitude: -81.367739, zipCode: "33870"},
	{latitude: 27.505242, longitude: -81.546028, zipCode: "33872"},
	{latitude: 27.570917, longitude: -81.781173, zipCode: "33873"},
	{latitude: 27.423426, longitude: -81.500649, zipCode: "33875"},
	{latitude: 27.425161, longitude: -81.33921, zipCode: "33876"},
	{latitude: 27.984232, longitude: -81.618797, zipCode: "33877"},
	{latitude: 27.994018, longitude: -81.777505, zipCode: "33880"},
	{latitude: 28.050996, longitude: -81.702534, zipCode: "33881"},
	{latitude: 27.982788, longitude: -81.681745, zipCode: "33884"},
	{latitude: 27.455205, longitude: -81.724602, zipCode: "33890"},
	{latitude: 28.250175, longitude: -81.58077, zipCode: "33896"},
	{latitude: 28.285129, longitude: -81.691024, zipCode: "33897"},
	{latitude: 27.880552, longitude: -81.317534, zipCode: "33898"},
	{latitude: 26.623381, longitude: -81.883191, zipCode: "33901"},
	{latitude: 26.682165, longitude: -81.905776, zipCode: "33903"},
	{latitude: 26.575403, longitude: -81.94486, zipCode: "33904"},
	{latitude: 26.667732, longitude: -81.76395, zipCode: "33905"},
	{latitude: 26.563891, longitude: -81.870266, zipCode: "33907"},
	{latitude: 26.510332, longitude: -81.979563, zipCode: "33908"},
	{latitude: 26.720347, longitude: -81.965502, zipCode: "33909"},
	{latitude: 26.534987, longitude: -81.825763, zipCode: "33912"},
	{latitude: 26.514239, longitude: -81.696796, zipCode: "33913"},
	{latitude: 26.55944, longitude: -82.024218, zipCode: "33914"},
	{latitude: 26.639352, longitude: -81.841388, zipCode: "33916"},
	{latitude: 26.748095, longitude: -81.846151, zipCode: "33917"},
	{latitude: 26.555891, longitude: -81.902855, zipCode: "33919"},
	{latitude: 26.742298, longitude: -81.63605, zipCode: "33920"},
	{latitude: 26.772225, longitude: -82.252804, zipCode: "33921"},
	{latitude: 26.651679, longitude: -82.176814, zipCode: "33922"},
	{latitude: 26.541274, longitude: -82.19652, zipCode: "33924"},
	{latitude: 26.435629, longitude: -81.697137, zipCode: "33928"},
	{latitude: 26.567129, longitude: -81.406707, zipCode: "33930"},
	{latitude: 26.430176, longitude: -81.91404, zipCode: "33931"},
	{latitude: 26.756778, longitude: -81.417649, zipCode: "33935"},
	{latitude: 26.618028, longitude: -81.559247, zipCode: "33936"},
	{latitude: 26.955129, longitude: -81.450021, zipCode: "33944"},
	{latitude: 26.852415, longitude: -82.282045, zipCode: "33946"},
	{latitude: 26.88512, longitude: -82.277881, zipCode: "33947"},
	{latitude: 26.985889, longitude: -82.151742, zipCode: "33948"},
	{latitude: 26.915655, longitude: -82.044296, zipCode: "33950"},
	{latitude: 26.986134, longitude: -82.097042, zipCode: "33952"},
	{latitude: 26.999702, longitude: -82.212275, zipCode: "33953"},
	{latitude: 27.02495, longitude: -82.121451, zipCode: "33954"},
	{latitude: 26.816433, longitude: -81.993808, zipCode: "33955"},
	{latitude: 26.537104, longitude: -82.121707, zipCode: "33956"},
	{latitude: 26.460477, longitude: -82.100381, zipCode: "33957"},
	{latitude: 27.079063, longitude: -81.412221, zipCode: "33960"},
	{latitude: 26.46768, longitude: -81.763678, zipCode: "33965"},
	{latitude: 26.583269, longitude: -81.834152, zipCode: "33966"},
	{latitude: 26.471507, longitude: -81.81224, zipCode: "33967"},
	{latitude: 26.638858, longitude: -81.700343, zipCode: "33971"},
	{latitude: 26.649272, longitude: -81.61771, zipCode: "33972"},
	{latitude: 26.600661, longitude: -81.730435, zipCode: "33973"},
	{latitude: 26.558935, longitude: -81.601239, zipCode: "33974"},
	{latitude: 26.591277, longitude: -81.68613, zipCode: "33976"},
	{latitude: 26.977203, longitude: -82.049732, zipCode: "33980"},
	{latitude: 26.87617, longitude: -82.210575, zipCode: "33981"},
	{latitude: 26.85294, longitude: -81.669581, zipCode: "33982"},
	{latitude: 27.005904, longitude: -82.01337, zipCode: "33983"},
	{latitude: 26.62855, longitude: -81.948755, zipCode: "33990"},
	{latitude: 26.633654, longitude: -82.009842, zipCode: "33991"},
	{latitude: 26.67263, longitude: -82.051131, zipCode: "33993"},
	{latitude: 26.050185, longitude: -81.780621, zipCode: "34101"},
	{latitude: 26.137498, longitude: -81.799105, zipCode: "34102"},
	{latitude: 26.191352, longitude: -81.803331, zipCode: "34103"},
	{latitude: 26.150429, longitude: -81.740837, zipCode: "34104"},
	{latitude: 26.190046, longitude: -81.764777, zipCode: "34105"},
	{latitude: 26.246308, longitude: -81.808323, zipCode: "34108"},
	{latitude: 26.240842, longitude: -81.763823, zipCode: "34109"},
	{latitude: 26.303284, longitude: -81.788171, zipCode: "34110"},
	{latitude: 26.121422, longitude: -81.73903, zipCode: "34112"},
	{latitude: 26.043649, longitude: -81.732424, zipCode: "34113"},
	{latitude: 25.954218, longitude: -81.573387, zipCode: "34114"},
	{latitude: 26.189294, longitude: -81.710803, zipCode: "34116"},
	{latitude: 26.118102, longitude: -81.54893, zipCode: "34117"},
	{latitude: 26.263933, longitude: -81.717462, zipCode: "34119"},
	{latitude: 26.312992, longitude: -81.582396, zipCode: "34120"},
	{latitude: 26.361381, longitude: -81.831936, zipCode: "34134"},
	{latitude: 26.367713, longitude: -81.735659, zipCode: "34135"},
	{latitude: 26.040535, longitude: -81.391301, zipCode: "34137"},
	{latitude: 25.813921, longitude: -81.363212, zipCode: "34138"},
	{latitude: 25.849912, longitude: -81.389953, zipCode: "34139"},
	{latitude: 25.925119, longitude: -81.662613, zipCode: "34140"},
	{latitude: 26.022815, longitude: -81.081248, zipCode: "34141"},
	{latitude: 26.313791, longitude: -81.286265, zipCode: "34142"},
	{latitude: 25.909779, longitude: -81.695125, zipCode: "34145"},
	{latitude: 27.400662, longitude: -82.465793, zipCode: "34201"},
	{latitude: 27.402531, longitude: -82.394394, zipCode: "34202"},
	{latitude: 27.440337, longitude: -82.509384, zipCode: "34203"},
	{latitude: 27.484084, longitude: -82.581821, zipCode: "34205"},
	{latitude: 27.438677, longitude: -82.579674, zipCode: "34207"},
	{latitude: 27.487486, longitude: -82.515907, zipCode: "34208"},
	{latitude: 27.50473, longitude: -82.654669, zipCode: "34209"},
	{latitude: 27.437548, longitude: -82.630178, zipCode: "34210"},
	{latitude: 27.454365, longitude: -82.383383, zipCode: "34211"},
	{latitude: 27.493816, longitude: -82.406103, zipCode: "34212"},
	{latitude: 27.474427, longitude: -82.685724, zipCode: "34215"},
	{latitude: 27.534798, longitude: -82.735123, zipCode: "34216"},
	{latitude: 27.491178, longitude: -82.705018, zipCode: "34217"},
	{latitude: 27.576201, longitude: -82.263457, zipCode: "34219"},
	{latitude: 27.581152, longitude: -82.551825, zipCode: "34221"},
	{latitude: 27.538955, longitude: -82.507027, zipCode: "34222"},
	{latitude: 26.983677, longitude: -82.337666, zipCode: "34223"},
	{latitude: 26.922945, longitude: -82.308301, zipCode: "34224"},
	{latitude: 27.400457, longitude: -82.644175, zipCode: "34228"},
	{latitude: 27.191211, longitude: -82.484136, zipCode: "34229"},
	{latitude: 27.265984, longitude: -82.516583, zipCode: "34231"},
	{latitude: 27.324987, longitude: -82.471714, zipCode: "34232"},
	{latitude: 27.280018, longitude: -82.476632, zipCode: "34233"},
	{latitude: 27.368976, longitude: -82.556536, zipCode: "34234"},
	{latitude: 27.369416, longitude: -82.47682, zipCode: "34235"},
	{latitude: 27.325788, longitude: -82.559997, zipCode: "34236"},
	{latitude: 27.338802, longitude: -82.514742, zipCode: "34237"},
	{latitude: 27.235792, longitude: -82.470536, zipCode: "34238"},
	{latitude: 27.310893, longitude: -82.521168, zipCode: "34239"},
	{latitude: 27.344716, longitude: -82.354156, zipCode: "34240"},
	{latitude: 27.251039, longitude: -82.373785, zipCode: "34241"},
	{latitude: 27.26022, longitude: -82.540908, zipCode: "34242"},
	{latitude: 27.400992, longitude: -82.527943, zipCode: "34243"},
	{latitude: 27.569606, longitude: -82.585138, zipCode: "34250"},
	{latitude: 27.377729, longitude: -82.17154, zipCode: "34251"},
	{latitude: 27.202037, longitude: -81.802188, zipCode: "34266"},
	{latitude: 27.089306, longitude: -81.954297, zipCode: "34267"},
	{latitude: 27.132262, longitude: -81.927421, zipCode: "34268"},
	{latitude: 27.089943, longitude: -81.96148, zipCode: "34269"},
	{latitude: 27.151753, longitude: -82.422567, zipCode: "34275"},
	{latitude: 27.094564, longitude: -82.440895, zipCode: "34285"},
	{latitude: 27.107469, longitude: -82.10668, zipCode: "34286"},
	{latitude: 27.057797, longitude: -82.249277, zipCode: "34287"},
	{latitude: 27.0536, longitude: -82.113335, zipCode: "34288"},
	{latitude: 27.088812, longitude: -82.139203, zipCode: "34289"},
	{latitude: 27.11647, longitude: -82.215206, zipCode: "34291"},
	{latitude: 27.103873, longitude: -82.335474, zipCode: "34292"},
	{latitude: 27.037392, longitude: -82.358528, zipCode: "34293"},
	{latitude: 29.053114, longitude: -82.03873, zipCode: "34420"},
	{latitude: 28.956702, longitude: -82.647585, zipCode: "34428"},
	{latitude: 28.867281, longitude: -82.662032, zipCode: "34429"},
	{latitude: 29.140237, longitude: -82.530551, zipCode: "34431"},
	{latitude: 29.064898, longitude: -82.374559, zipCode: "34432"},
	{latitude: 28.992502, longitude: -82.528869, zipCode: "34433"},
	{latitude: 28.99503, longitude: -82.437972, zipCode: "34434"},
	{latitude: 28.733692, longitude: -82.293898, zipCode: "34436"},
	{latitude: 28.920909, longitude: -82.382325, zipCode: "34442"},
	{latitude: 28.971387, longitude: -82.42406, zipCode: "34445"},
	{latitude: 28.749019, longitude: -82.52346, zipCode: "34446"},
	{latitude: 28.767011, longitude: -82.625191, zipCode: "34448"},
	{latitude: 29.106972, longitude: -82.69054, zipCode: "34449"},
	{latitude: 28.830041, longitude: -82.266009, zipCode: "34450"},
	{latitude: 28.761187, longitude: -82.375709, zipCode: "34452"},
	{latitude: 28.895824, longitude: -82.329534, zipCode: "34453"},
	{latitude: 28.813818, longitude: -82.483064, zipCode: "34461"},
	{latitude: 28.928693, longitude: -82.490909, zipCode: "34465"},
	{latitude: 29.201008, longitude: -82.082072, zipCode: "34470"},
	{latitude: 29.161789, longitude: -82.126817, zipCode: "34471"},
	{latitude: 29.109474, longitude: -81.997364, zipCode: "34472"},
	{latitude: 28.99763, longitude: -82.18778, zipCode: "34473"},
	{latitude: 29.154472, longitude: -82.211485, zipCode: "34474"},
	{latitude: 29.25268, longitude: -82.161978, zipCode: "34475"},
	{latitude: 29.088605, longitude: -82.201598, zipCode: "34476"},
	{latitude: 29.255724, longitude: -82.109112, zipCode: "34479"},
	{latitude: 29.0942, longitude: -82.094208, zipCode: "34480"},
	{latitude: 29.125538, longitude: -82.309895, zipCode: "34481"},
	{latitude: 29.244387, longitude: -82.275029, zipCode: "34482"},
	{latitude: 28.945019, longitude: -82.097528, zipCode: "34484"},
	{latitude: 28.781477, longitude: -82.612719, zipCode: "34487"},
	{latitude: 29.219283, longitude: -81.941004, zipCode: "34488"},
	{latitude: 28.998477, longitude: -82.039708, zipCode: "34491"},
	{latitude: 29.031552, longitude: -82.736088, zipCode: "34498"},
	{latitude: 28.58845, longitude: -82.371337, zipCode: "34601"},
	{latitude: 28.502482, longitude: -82.282351, zipCode: "34602"},
	{latitude: 28.477875, longitude: -82.432707, zipCode: "34604"},
	{latitude: 28.468477, longitude: -82.595728, zipCode: "34606"},
	{latitude: 28.594975, longitude: -82.615947, zipCode: "34607"},
	{latitude: 28.482826, longitude: -82.552854, zipCode: "34608"},
	{latitude: 28.479476, longitude: -82.507906, zipCode: "34609"},
	{latitude: 28.369903, longitude: -82.510748, zipCode: "34610"},
	{latitude: 28.558922, longitude: -82.542335, zipCode: "34613"},
	{latitude: 28.646538, longitude: -82.51364, zipCode: "34614"},
	{latitude: 28.307711, longitude: -82.464832, zipCode: "34637"},
	{latitude: 28.274188, longitude: -82.529523, zipCode: "34638"},
	{latitude: 28.26543, longitude: -82.423239, zipCode: "34639"},
	{latitude: 28.240555, longitude: -82.744353, zipCode: "34652"},
	{latitude: 28.24408, longitude: -82.688476, zipCode: "34653"},
	{latitude: 28.297909, longitude: -82.629344, zipCode: "34654"},
	{latitude: 28.211353, longitude: -82.655286, zipCode: "34655"},
	{latitude: 28.620835, longitude: -82.261099, zipCode: "34661"},
	{latitude: 28.385867, longitude: -82.665627, zipCode: "34667"},
	{latitude: 28.293335, longitude: -82.710564, zipCode: "34668"},
	{latitude: 28.356025, longitude: -82.610167, zipCode: "34669"},
	{latitude: 28.044517, longitude: -82.674272, zipCode: "34677"},
	{latitude: 28.422752, longitude: -82.674162, zipCode: "34679"},
	{latitude: 28.090702, longitude: -82.777914, zipCode: "34681"},
	{latitude: 28.087706, longitude: -82.761134, zipCode: "34683"},
	{latitude: 28.080744, longitude: -82.726829, zipCode: "34684"},
	{latitude: 28.093255, longitude: -82.689402, zipCode: "34685"},
	{latitude: 28.143226, longitude: -82.683943, zipCode: "34688"},
	{latitude: 28.149526, longitude: -82.761831, zipCode: "34689"},
	{latitude: 28.193745, longitude: -82.725706, zipCode: "34690"},
	{latitude: 28.192577, longitude: -82.770421, zipCode: "34691"},
	{latitude: 28.008161, longitude: -82.688367, zipCode: "34695"},
	{latitude: 28.036272, longitude: -82.791427, zipCode: "34698"},
	{latitude: 28.693805, longitude: -81.710847, zipCode: "34705"},
	{latitude: 28.52078, longitude: -81.753851, zipCode: "34711"},
	{latitude: 28.399468, longitude: -81.812869, zipCode: "34714"},
	{latitude: 28.631041, longitude: -81.726074, zipCode: "34715"},
	{latitude: 28.617449, longitude: -81.700797, zipCode: "34729"},
	{latitude: 28.867636, longitude: -81.896543, zipCode: "34731"},
	{latitude: 28.537003, longitude: -81.515454, zipCode: "34734"},
	{latitude: 28.599487, longitude: -81.862203, zipCode: "34736"},
	{latitude: 28.697138, longitude: -81.803369, zipCode: "34737"},
	{latitude: 27.932962, longitude: -81.098849, zipCode: "34739"},
	{latitude: 28.306299, longitude: -81.426933, zipCode: "34741"},
	{latitude: 28.329321, longitude: -81.355148, zipCode: "34743"},
	{latitude: 28.295842, longitude: -81.348621, zipCode: "34744"},
	{latitude: 28.237471, longitude: -81.441898, zipCode: "34746"},
	{latitude: 28.308991, longitude: -81.591266, zipCode: "34747"},
	{latitude: 28.773001, longitude: -81.882622, zipCode: "34748"},
	{latitude: 28.604341, longitude: -81.905549, zipCode: "34753"},
	{latitude: 28.586734, longitude: -81.679482, zipCode: "34756"},
	{latitude: 28.192063, longitude: -81.48542, zipCode: "34758"},
	{latitude: 28.103895, longitude: -81.419373, zipCode: "34759"},
	{latitude: 28.557053, longitude: -81.62924, zipCode: "34760"},
	{latitude: 28.578577, longitude: -81.534116, zipCode: "34761"},
	{latitude: 28.757247, longitude: -81.920516, zipCode: "34762"},
	{latitude: 28.243666, longitude: -81.294191, zipCode: "34769"},
	{latitude: 28.268298, longitude: -81.128403, zipCode: "34771"},
	{latitude: 28.160499, longitude: -81.273639, zipCode: "34772"},
	{latitude: 28.160667, longitude: -80.998092, zipCode: "34773"},
	{latitude: 28.846318, longitude: -82.061648, zipCode: "34785"},
	{latitude: 28.482847, longitude: -81.538656, zipCode: "34786"},
	{latitude: 28.485174, longitude: -81.619415, zipCode: "34787"},
	{latitude: 28.880322, longitude: -81.790033, zipCode: "34788"},
	{latitude: 28.730468, longitude: -81.826624, zipCode: "34797"},
	{latitude: 27.457933, longitude: -80.551744, zipCode: "34945"},
	{latitude: 27.503294, longitude: -80.36048, zipCode: "34946"},
	{latitude: 27.453848, longitude: -80.373737, zipCode: "34947"},
	{latitude: 27.467258, longitude: -80.299808, zipCode: "34949"},
	{latitude: 27.443788, longitude: -80.330974, zipCode: "34950"},
	{latitude: 27.532134, longitude: -80.418954, zipCode: "34951"},
	{latitude: 27.297204, longitude: -80.29801, zipCode: "34952"},
	{latitude: 27.24968, longitude: -80.383236, zipCode: "34953"},
	{latitude: 27.098264, longitude: -80.499613, zipCode: "34956"},
	{latitude: 27.274577, longitude: -80.243022, zipCode: "34957"},
	{latitude: 27.477985, longitude: -80.908736, zipCode: "34972"},
	{latitude: 27.148849, longitude: -80.914061, zipCode: "34974"},
	{latitude: 27.393278, longitude: -80.372054, zipCode: "34981"},
	{latitude: 27.375286, longitude: -80.311514, zipCode: "34982"},
	{latitude: 27.323951, longitude: -80.352566, zipCode: "34983"},
	{latitude: 27.249005, longitude: -80.330553, zipCode: "34984"},
	{latitude: 27.327734, longitude: -80.401911, zipCode: "34986"},
	{latitude: 27.290285, longitude: -80.49712, zipCode: "34987"},
	{latitude: 27.147946, longitude: -80.362294, zipCode: "34990"},
	{latitude: 27.199535, longitude: -80.255952, zipCode: "34994"},
	{latitude: 27.188173, longitude: -80.1878, zipCode: "34996"},
	{latitude: 27.050481, longitude: -80.271203, zipCode: "34997"},
	{latitude: 33.603429, longitude: -86.493786, zipCode: "35004"},
	{latitude: 33.605877, longitude: -86.993731, zipCode: "35005"},
	{latitude: 33.422751, longitude: -87.209751, zipCode: "35006"},
	{latitude: 33.221352, longitude: -86.787652, zipCode: "35007"},
	{latitude: 32.916719, longitude: -85.940589, zipCode: "35010"},
	{latitude: 33.902164, longitude: -86.517774, zipCode: "35013"},
	{latitude: 33.355851, longitude: -86.261678, zipCode: "35014"},
	{latitude: 34.324867, longitude: -86.504289, zipCode: "35016"},
	{latitude: 34.302078, longitude: -86.634094, zipCode: "35019"},
	{latitude: 33.404832, longitude: -86.953972, zipCode: "35020"},
	{latitude: 33.331782, longitude: -86.959067, zipCode: "35022"},
	{latitude: 33.467843, longitude: -87.088194, zipCode: "35023"},
	{latitude: 34.097505, longitude: -86.555035, zipCode: "35031"},
	{latitude: 33.263126, longitude: -86.334052, zipCode: "35032"},
	{latitude: 33.921678, longitude: -87.010585, zipCode: "35033"},
	{latitude: 32.897994, longitude: -87.281501, zipCode: "35034"},
	{latitude: 33.043682, longitude: -86.968442, zipCode: "35035"},
	{latitude: 33.640529, longitude: -86.916404, zipCode: "35036"},
	{latitude: 33.102967, longitude: -86.728894, zipCode: "35040"},
	{latitude: 32.930993, longitude: -87.127915, zipCode: "35042"},
	{latitude: 33.310561, longitude: -86.662905, zipCode: "35043"},
	{latitude: 33.267244, longitude: -86.366505, zipCode: "35044"},
	{latitude: 32.811605, longitude: -86.673249, zipCode: "35045"},
	{latitude: 32.921346, longitude: -86.530466, zipCode: "35046"},
	{latitude: 33.961861, longitude: -86.612114, zipCode: "35049"},
	{latitude: 33.219388, longitude: -86.600824, zipCode: "35051"},
	{latitude: 33.590682, longitude: -86.409897, zipCode: "35052"},
	{latitude: 34.065536, longitude: -87.066361, zipCode: "35053"},
	{latitude: 33.502019, longitude: -86.337925, zipCode: "35054"},
	{latitude: 34.151162, longitude: -86.758748, zipCode: "35055"},
	{latitude: 34.18031, longitude: -86.93428, zipCode: "35057"},
	{latitude: 34.236831, longitude: -86.730183, zipCode: "35058"},
	{latitude: 33.557586, longitude: -86.928164, zipCode: "35060"},
	{latitude: 33.475321, longitude: -86.94678, zipCode: "35061"},
	{latitude: 33.719033, longitude: -87.017744, zipCode: "35062"},
	{latitude: 33.818224, longitude: -87.020631, zipCode: "35063"},
	{latitude: 33.474825, longitude: -86.919575, zipCode: "35064"},
	{latitude: 33.609343, longitude: -86.831744, zipCode: "35068"},
	{latitude: 34.01366, longitude: -86.742656, zipCode: "35070"},
	{latitude: 33.695133, longitude: -86.848962, zipCode: "35071"},
	{latitude: 33.093531, longitude: -86.06686, zipCode: "35072"},
	{latitude: 33.651263, longitude: -86.986993, zipCode: "35073"},
	{latitude: 33.22236, longitude: -87.124251, zipCode: "35074"},
	{latitude: 34.021165, longitude: -86.832668, zipCode: "35077"},
	{latitude: 33.32442, longitude: -86.444966, zipCode: "35078"},
	{latitude: 33.938494, longitude: -86.75701, zipCode: "35079"},
	{latitude: 33.249278, longitude: -86.914775, zipCode: "35080"},
	{latitude: 33.158024, longitude: -86.153863, zipCode: "35082"},
	{latitude: 34.203802, longitude: -86.59526, zipCode: "35083"},
	{latitude: 32.976498, longitude: -86.739181, zipCode: "35085"},
	{latitude: 34.306453, longitude: -86.584296, zipCode: "35087"},
	{latitude: 32.945782, longitude: -86.081832, zipCode: "35089"},
	{latitude: 33.783484, longitude: -86.789474, zipCode: "35091"},
	{latitude: 33.513549, longitude: -86.571182, zipCode: "35094"},
	{latitude: 33.620336, longitude: -86.107668, zipCode: "35096"},
	{latitude: 33.892188, longitude: -86.627133, zipCode: "35097"},
	{latitude: 34.139446, longitude: -87.03884, zipCode: "35098"},
	{latitude: 33.280948, longitude: -87.08981, zipCode: "35111"},
	{latitude: 33.694069, longitude: -86.474733, zipCode: "35112"},
	{latitude: 33.222515, longitude: -86.866567, zipCode: "35114"},
	{latitude: 33.142677, longitude: -86.891559, zipCode: "35115"},
	{latitude: 33.731298, longitude: -86.769981, zipCode: "35116"},
	{latitude: 33.667778, longitude: -86.892599, zipCode: "35117"},
	{latitude: 33.527311, longitude: -87.034644, zipCode: "35118"},
	{latitude: 33.638022, longitude: -86.778579, zipCode: "35119"},
	{latitude: 33.668642, longitude: -86.421654, zipCode: "35120"},
	{latitude: 33.941104, longitude: -86.45057, zipCode: "35121"},
	{latitude: 33.32183, longitude: -86.742197, zipCode: "35124"},
	{latitude: 33.633384, longitude: -86.288692, zipCode: "35125"},
	{latitude: 33.730926, longitude: -86.654972, zipCode: "35126"},
	{latitude: 33.491167, longitude: -86.975791, zipCode: "35127"},
	{latitude: 33.5524, longitude: -86.337819, zipCode: "35128"},
	{latitude: 33.633156, longitude: -87.136688, zipCode: "35130"},
	{latitude: 33.714269, longitude: -86.192523, zipCode: "35131"},
	{latitude: 33.828926, longitude: -86.595478, zipCode: "35133"},
	{latitude: 33.628093, longitude: -86.195731, zipCode: "35135"},
	{latitude: 32.879065, longitude: -86.287018, zipCode: "35136"},
	{latitude: 33.090189, longitude: -86.556119, zipCode: "35143"},
	{latitude: 33.79507, longitude: -86.453279, zipCode: "35146"},
	{latitude: 33.415884, longitude: -86.530992, zipCode: "35147"},
	{latitude: 33.756099, longitude: -87.04561, zipCode: "35148"},
	{latitude: 33.239597, longitude: -86.209926, zipCode: "35149"},
	{latitude: 33.190239, longitude: -86.244559, zipCode: "35150"},
	{latitude: 33.119817, longitude: -86.339033, zipCode: "35151"},
	{latitude: 33.363351, longitude: -86.110732, zipCode: "35160"},
	{latitude: 32.871411, longitude: -86.746309, zipCode: "35171"},
	{latitude: 33.845155, longitude: -86.695313, zipCode: "35172"},
	{latitude: 33.655233, longitude: -86.563239, zipCode: "35173"},
	{latitude: 34.435679, longitude: -86.511374, zipCode: "35175"},
	{latitude: 33.48179, longitude: -86.502441, zipCode: "35176"},
	{latitude: 33.415933, longitude: -86.411756, zipCode: "35178"},
	{latitude: 34.273307, longitude: -86.96897, zipCode: "35179"},
	{latitude: 33.832693, longitude: -86.84882, zipCode: "35180"},
	{latitude: 33.626438, longitude: -86.879972, zipCode: "35181"},
	{latitude: 33.006438, longitude: -86.357576, zipCode: "35183"},
	{latitude: 33.114345, longitude: -87.136714, zipCode: "35184"},
	{latitude: 33.348974, longitude: -86.538784, zipCode: "35185"},
	{latitude: 33.256733, longitude: -86.510291, zipCode: "35186"},
	{latitude: 33.170123, longitude: -87.172884, zipCode: "35188"},
	{latitude: 33.518381, longitude: -86.810067, zipCode: "35203"},
	{latitude: 33.522049, longitude: -86.840656, zipCode: "35204"},
	{latitude: 33.495139, longitude: -86.808245, zipCode: "35205"},
	{latitude: 33.570264, longitude: -86.71314, zipCode: "35206"},
	{latitude: 33.567685, longitude: -86.82502, zipCode: "35207"},
	{latitude: 33.497188, longitude: -86.879059, zipCode: "35208"},
	{latitude: 33.465306, longitude: -86.811022, zipCode: "35209"},
	{latitude: 33.541806, longitude: -86.669036, zipCode: "35210"},
	{latitude: 33.456481, longitude: -86.868488, zipCode: "35211"},
	{latitude: 33.54767, longitude: -86.75272, zipCode: "35212"},
	{latitude: 33.506674, longitude: -86.744347, zipCode: "35213"},
	{latitude: 33.570931, longitude: -86.892259, zipCode: "35214"},
	{latitude: 33.64986, longitude: -86.703762, zipCode: "35215"},
	{latitude: 33.419246, longitude: -86.789433, zipCode: "35216"},
	{latitude: 33.606723, longitude: -86.763005, zipCode: "35217"},
	{latitude: 33.507698, longitude: -86.893956, zipCode: "35218"},
	{latitude: 33.449719, longitude: -86.899014, zipCode: "35221"},
	{latitude: 33.523712, longitude: -86.770115, zipCode: "35222"},
	{latitude: 33.491488, longitude: -86.73447, zipCode: "35223"},
	{latitude: 33.52765, longitude: -86.945239, zipCode: "35224"},
	{latitude: 33.398798, longitude: -86.841794, zipCode: "35226"},
	{latitude: 33.456044, longitude: -86.921648, zipCode: "35228"},
	{latitude: 33.464365, longitude: -86.79398, zipCode: "35229"},
	{latitude: 33.509151, longitude: -86.801398, zipCode: "35233"},
	{latitude: 33.53999, longitude: -86.803793, zipCode: "35234"},
	{latitude: 33.615287, longitude: -86.645447, zipCode: "35235"},
	{latitude: 33.424611, longitude: -86.670867, zipCode: "35242"},
	{latitude: 33.440988, longitude: -86.741547, zipCode: "35243"},
	{latitude: 33.352918, longitude: -86.825333, zipCode: "35244"},
	{latitude: 33.506573, longitude: -86.80316, zipCode: "35249"},
	{latitude: 33.513705, longitude: -86.852874, zipCode: "35254"},
	{latitude: 33.179127, longitude: -87.615273, zipCode: "35401"},
	{latitude: 33.215157, longitude: -87.483406, zipCode: "35404"},
	{latitude: 33.120192, longitude: -87.539873, zipCode: "35405"},
	{latitude: 33.338842, longitude: -87.467554, zipCode: "35406"},
	{latitude: 32.857248, longitude: -87.731274, zipCode: "35441"},
	{latitude: 33.053605, longitude: -88.186094, zipCode: "35442"},
	{latitude: 32.76859, longitude: -88.011476, zipCode: "35443"},
	{latitude: 33.328718, longitude: -87.292882, zipCode: "35444"},
	{latitude: 33.209239, longitude: -87.749667, zipCode: "35446"},
	{latitude: 33.277932, longitude: -88.16795, zipCode: "35447"},
	{latitude: 33.280692, longitude: -87.689023, zipCode: "35452"},
	{latitude: 33.161675, longitude: -87.373572, zipCode: "35453"},
	{latitude: 33.063694, longitude: -87.41475, zipCode: "35456"},
	{latitude: 33.304137, longitude: -87.773144, zipCode: "35457"},
	{latitude: 33.402444, longitude: -87.809614, zipCode: "35458"},
	{latitude: 32.791636, longitude: -88.275851, zipCode: "35459"},
	{latitude: 32.721884, longitude: -88.195782, zipCode: "35460"},
	{latitude: 33.417374, longitude: -88.213613, zipCode: "35461"},
	{latitude: 32.931849, longitude: -87.938502, zipCode: "35462"},
	{latitude: 33.054133, longitude: -87.682064, zipCode: "35463"},
	{latitude: 32.784706, longitude: -88.144786, zipCode: "35464"},
	{latitude: 33.248527, longitude: -87.919484, zipCode: "35466"},
	{latitude: 33.004086, longitude: -87.798738, zipCode: "35469"},
	{latitude: 32.56175, longitude: -88.066558, zipCode: "35470"},
	{latitude: 33.269296, longitude: -87.585458, zipCode: "35473"},
	{latitude: 32.940566, longitude: -87.5912, zipCode: "35474"},
	{latitude: 33.438828, longitude: -87.561779, zipCode: "35475"},
	{latitude: 33.23778, longitude: -87.579808, zipCode: "35476"},
	{latitude: 32.956131, longitude: -88.240953, zipCode: "35477"},
	{latitude: 33.097877, longitude: -87.799147, zipCode: "35480"},
	{latitude: 33.404741, longitude: -88.046619, zipCode: "35481"},
	{latitude: 33.213827, longitude: -87.547643, zipCode: "35487"},
	{latitude: 33.218419, longitude: -87.238215, zipCode: "35490"},
	{latitude: 32.93662, longitude: -88.097773, zipCode: "35491"},
	{latitude: 33.821205, longitude: -87.296618, zipCode: "35501"},
	{latitude: 33.936688, longitude: -87.305301, zipCode: "35503"},
	{latitude: 33.894135, longitude: -87.170812, zipCode: "35504"},
	{latitude: 34.218557, longitude: -87.192847, zipCode: "35540"},
	{latitude: 34.081325, longitude: -87.174349, zipCode: "35541"},
	{latitude: 33.703711, longitude: -87.681105, zipCode: "35542"},
	{latitude: 34.211155, longitude: -87.758435, zipCode: "35543"},
	{latitude: 33.959985, longitude: -88.017137, zipCode: "35544"},
	{latitude: 33.642127, longitude: -87.93145, zipCode: "35545"},
	{latitude: 33.604963, longitude: -87.545388, zipCode: "35546"},
	{latitude: 34.056419, longitude: -87.750485, zipCode: "35548"},
	{latitude: 33.822862, longitude: -87.562989, zipCode: "35549"},
	{latitude: 33.745429, longitude: -87.160466, zipCode: "35550"},
	{latitude: 34.073094, longitude: -88.142693, zipCode: "35552"},
	{latitude: 34.126813, longitude: -87.383078, zipCode: "35553"},
	{latitude: 33.885727, longitude: -87.671678, zipCode: "35554"},
	{latitude: 33.670942, longitude: -87.822623, zipCode: "35555"},
	{latitude: 33.911636, longitude: -87.735336, zipCode: "35559"},
	{latitude: 33.996537, longitude: -87.906754, zipCode: "35563"},
	{latitude: 34.258833, longitude: -87.84526, zipCode: "35564"},
	{latitude: 34.23696, longitude: -87.563402, zipCode: "35565"},
	{latitude: 34.163756, longitude: -87.993159, zipCode: "35570"},
	{latitude: 34.368708, longitude: -87.944108, zipCode: "35571"},
	{latitude: 34.20127, longitude: -87.28258, zipCode: "35572"},
	{latitude: 33.901324, longitude: -87.549994, zipCode: "35573"},
	{latitude: 33.555441, longitude: -87.968983, zipCode: "35574"},
	{latitude: 34.036179, longitude: -87.588153, zipCode: "35575"},
	{latitude: 33.588397, longitude: -88.142987, zipCode: "35576"},
	{latitude: 34.091668, longitude: -87.599289, zipCode: "35577"},
	{latitude: 33.980483, longitude: -87.466391, zipCode: "35578"},
	{latitude: 33.625137, longitude: -87.331008, zipCode: "35579"},
	{latitude: 33.700754, longitude: -87.270788, zipCode: "35580"},
	{latitude: 34.359912, longitude: -87.713097, zipCode: "35581"},
	{latitude: 34.485744, longitude: -88.095035, zipCode: "35582"},
	{latitude: 33.819268, longitude: -87.087923, zipCode: "35584"},
	{latitude: 34.419353, longitude: -87.828938, zipCode: "35585"},
	{latitude: 33.874497, longitude: -88.140445, zipCode: "35586"},
	{latitude: 33.795541, longitude: -87.456888, zipCode: "35587"},
	{latitude: 33.742726, longitude: -88.09739, zipCode: "35592"},
	{latitude: 34.353682, longitude: -88.090198, zipCode: "35593"},
	{latitude: 33.920072, longitude: -87.77866, zipCode: "35594"},
	{latitude: 34.605711, longitude: -87.008324, zipCode: "35601"},
	{latitude: 34.535452, longitude: -86.971226, zipCode: "35603"},
	{latitude: 34.955261, longitude: -87.232018, zipCode: "35610"},
	{latitude: 34.773833, longitude: -87.084145, zipCode: "35611"},
	{latitude: 34.820163, longitude: -86.882161, zipCode: "35613"},
	{latitude: 34.858596, longitude: -87.080244, zipCode: "35614"},
	{latitude: 34.662322, longitude: -86.875974, zipCode: "35615"},
	{latitude: 34.739504, longitude: -88.019381, zipCode: "35616"},
	{latitude: 34.641403, longitude: -87.304983, zipCode: "35618"},
	{latitude: 34.382723, longitude: -87.152105, zipCode: "35619"},
	{latitude: 34.930812, longitude: -87.020185, zipCode: "35620"},
	{latitude: 34.335031, longitude: -86.727056, zipCode: "35621"},
	{latitude: 34.34659, longitude: -86.898961, zipCode: "35622"},
	{latitude: 34.825167, longitude: -87.659843, zipCode: "35630"},
	{latitude: 34.80768, longitude: -87.680572, zipCode: "35632"},
	{latitude: 34.875584, longitude: -87.796396, zipCode: "35633"},
	{latitude: 34.923665, longitude: -87.608101, zipCode: "35634"},
	{latitude: 34.440708, longitude: -86.943683, zipCode: "35640"},
	{latitude: 34.672577, longitude: -87.201931, zipCode: "35643"},
	{latitude: 34.894087, longitude: -87.489341, zipCode: "35645"},
	{latitude: 34.680476, longitude: -87.537602, zipCode: "35646"},
	{latitude: 34.967655, longitude: -87.117034, zipCode: "35647"},
	{latitude: 34.959239, longitude: -87.379419, zipCode: "35648"},
	{latitude: 34.625164, longitude: -86.871585, zipCode: "35649"},
	{latitude: 34.447233, longitude: -87.308593, zipCode: "35650"},
	{latitude: 34.460909, longitude: -87.480151, zipCode: "35651"},
	{latitude: 34.846744, longitude: -87.310208, zipCode: "35652"},
	{latitude: 34.533442, longitude: -87.863699, zipCode: "35653"},
	{latitude: 34.495391, longitude: -87.620541, zipCode: "35654"},
	{latitude: 34.758412, longitude: -87.696542, zipCode: "35660"},
	{latitude: 34.768526, longitude: -87.562802, zipCode: "35661"},
	{latitude: 34.463808, longitude: -86.729363, zipCode: "35670"},
	{latitude: 34.66899, longitude: -86.950044, zipCode: "35671"},
	{latitude: 34.648533, longitude: -87.406487, zipCode: "35672"},
	{latitude: 34.585942, longitude: -87.138453, zipCode: "35673"},
	{latitude: 34.670183, longitude: -87.758541, zipCode: "35674"},
	{latitude: 34.952582, longitude: -88.024682, zipCode: "35677"},
	{latitude: 34.968586, longitude: -86.791036, zipCode: "35739"},
	{latitude: 34.965286, longitude: -85.777448, zipCode: "35740"},
	{latitude: 34.729593, longitude: -86.463305, zipCode: "35741"},
	{latitude: 34.612686, longitude: -85.904504, zipCode: "35744"},
	{latitude: 34.943416, longitude: -86.180179, zipCode: "35745"},
	{latitude: 34.828619, longitude: -86.005957, zipCode: "35746"},
	{latitude: 34.494944, longitude: -86.298989, zipCode: "35747"},
	{latitude: 34.729465, longitude: -86.394951, zipCode: "35748"},
	{latitude: 34.822212, longitude: -86.757871, zipCode: "35749"},
	{latitude: 34.956179, longitude: -86.589482, zipCode: "35750"},
	{latitude: 34.811157, longitude: -86.27646, zipCode: "35751"},
	{latitude: 34.764114, longitude: -85.980056, zipCode: "35752"},
	{latitude: 34.514575, longitude: -86.615669, zipCode: "35754"},
	{latitude: 34.509624, longitude: -86.117547, zipCode: "35755"},
	{latitude: 34.643705, longitude: -86.818149, zipCode: "35756"},
	{latitude: 34.784545, longitude: -86.744299, zipCode: "35757"},
	{latitude: 34.71142, longitude: -86.746508, zipCode: "35758"},
	{latitude: 34.868419, longitude: -86.546114, zipCode: "35759"},
	{latitude: 34.543906, longitude: -86.383856, zipCode: "35760"},
	{latitude: 34.911819, longitude: -86.404827, zipCode: "35761"},
	{latitude: 34.611654, longitude: -86.472965, zipCode: "35763"},
	{latitude: 34.733206, longitude: -86.312664, zipCode: "35764"},
	{latitude: 34.695629, longitude: -85.819999, zipCode: "35765"},
	{latitude: 34.867485, longitude: -86.299884, zipCode: "35766"},
	{latitude: 34.78388, longitude: -86.102201, zipCode: "35768"},
	{latitude: 34.597638, longitude: -86.105106, zipCode: "35769"},
	{latitude: 34.538598, longitude: -86.005423, zipCode: "35771"},
	{latitude: 34.937034, longitude: -85.948355, zipCode: "35772"},
	{latitude: 34.891069, longitude: -86.707941, zipCode: "35773"},
	{latitude: 34.765246, longitude: -86.226525, zipCode: "35774"},
	{latitude: 34.542934, longitude: -86.705959, zipCode: "35775"},
	{latitude: 34.661195, longitude: -86.252272, zipCode: "35776"},
	{latitude: 34.724611, longitude: -86.565541, zipCode: "35801"},
	{latitude: 34.676464, longitude: -86.554568, zipCode: "35802"},
	{latitude: 34.563248, longitude: -86.519513, zipCode: "35803"},
	{latitude: 34.707121, longitude: -86.621685, zipCode: "35805"},
	{latitude: 34.766424, longitude: -86.683594, zipCode: "35806"},
	{latitude: 34.632929, longitude: -86.655842, zipCode: "35808"},
	{latitude: 34.80302, longitude: -86.60348, zipCode: "35810"},
	{latitude: 34.798812, longitude: -86.503887, zipCode: "35811"},
	{latitude: 34.738322, longitude: -86.631806, zipCode: "35816"},
	{latitude: 34.644929, longitude: -86.752061, zipCode: "35824"},
	{latitude: 34.75297, longitude: -86.651594, zipCode: "35896"},
	{latitude: 34.047586, longitude: -85.933265, zipCode: "35901"},
	{latitude: 34.025715, longitude: -85.845076, zipCode: "35903"},
	{latitude: 34.07056, longitude: -85.990098, zipCode: "35904"},
	{latitude: 33.9614, longitude: -85.922422, zipCode: "35905"},
	{latitude: 33.927149, longitude: -86.095113, zipCode: "35906"},
	{latitude: 33.890841, longitude: -86.01802, zipCode: "35907"},
	{latitude: 34.249767, longitude: -86.261112, zipCode: "35950"},
	{latitude: 34.346362, longitude: -86.146243, zipCode: "35951"},
	{latitude: 34.052385, longitude: -86.310738, zipCode: "35952"},
	{latitude: 33.809425, longitude: -86.173009, zipCode: "35953"},
	{latitude: 34.089754, longitude: -86.057449, zipCode: "35954"},
	{latitude: 34.146146, longitude: -86.146749, zipCode: "35956"},
	{latitude: 34.196159, longitude: -86.196898, zipCode: "35957"},
	{latitude: 34.916005, longitude: -85.645957, zipCode: "35958"},
	{latitude: 34.245174, longitude: -85.605509, zipCode: "35959"},
	{latitude: 34.128624, longitude: -85.566051, zipCode: "35960"},
	{latitude: 34.304305, longitude: -85.846009, zipCode: "35961"},
	{latitude: 34.299346, longitude: -86.036639, zipCode: "35962"},
	{latitude: 34.359787, longitude: -85.9272, zipCode: "35963"},
	{latitude: 34.794954, longitude: -85.70328, zipCode: "35966"},
	{latitude: 34.393786, longitude: -85.697972, zipCode: "35967"},
	{latitude: 34.476011, longitude: -85.790143, zipCode: "35968"},
	{latitude: 34.45603, longitude: -85.946629, zipCode: "35971"},
	{latitude: 33.991272, longitude: -86.224209, zipCode: "35972"},
	{latitude: 34.344016, longitude: -85.551513, zipCode: "35973"},
	{latitude: 34.362751, longitude: -86.010291, zipCode: "35974"},
	{latitude: 34.440412, longitude: -86.072425, zipCode: "35975"},
	{latitude: 34.341284, longitude: -86.322195, zipCode: "35976"},
	{latitude: 34.642798, longitude: -85.728979, zipCode: "35978"},
	{latitude: 34.829959, longitude: -85.612557, zipCode: "35979"},
	{latitude: 34.172088, longitude: -86.389617, zipCode: "35980"},
	{latitude: 34.722175, longitude: -85.640692, zipCode: "35981"},
	{latitude: 34.172844, longitude: -85.766155, zipCode: "35983"},
	{latitude: 34.518576, longitude: -85.557781, zipCode: "35984"},
	{latitude: 34.504775, longitude: -85.840326, zipCode: "35986"},
	{latitude: 33.91337, longitude: -86.242208, zipCode: "35987"},
	{latitude: 34.557889, longitude: -85.796884, zipCode: "35988"},
	{latitude: 34.598464, longitude: -85.635086, zipCode: "35989"},
	{latitude: 34.066728, longitude: -86.305243, zipCode: "35990"},
	{latitude: 32.444277, longitude: -86.730043, zipCode: "36003"},
	{latitude: 31.887588, longitude: -85.736039, zipCode: "36005"},
	{latitude: 32.641456, longitude: -86.727738, zipCode: "36006"},
	{latitude: 32.496116, longitude: -86.565398, zipCode: "36008"},
	{latitude: 31.597774, longitude: -86.328835, zipCode: "36009"},
	{latitude: 31.659895, longitude: -85.812896, zipCode: "36010"},
	{latitude: 32.296841, longitude: -85.979455, zipCode: "36013"},
	{latitude: 31.850223, longitude: -85.414706, zipCode: "36016"},
	{latitude: 31.679443, longitude: -85.55772, zipCode: "36017"},
	{latitude: 32.497937, longitude: -86.316387, zipCode: "36020"},
	{latitude: 32.623414, longitude: -86.405592, zipCode: "36022"},
	{latitude: 32.663489, longitude: -86.036358, zipCode: "36024"},
	{latitude: 32.545369, longitude: -86.340906, zipCode: "36025"},
	{latitude: 32.77548, longitude: -86.130993, zipCode: "36026"},
	{latitude: 31.977787, longitude: -85.183909, zipCode: "36027"},
	{latitude: 31.484716, longitude: -86.384837, zipCode: "36028"},
	{latitude: 32.181872, longitude: -85.933154, zipCode: "36029"},
	{latitude: 31.848788, longitude: -86.861639, zipCode: "36030"},
	{latitude: 32.247749, longitude: -85.769534, zipCode: "36031"},
	{latitude: 31.998301, longitude: -86.571084, zipCode: "36032"},
	{latitude: 31.671714, longitude: -86.770613, zipCode: "36033"},
	{latitude: 31.621045, longitude: -86.111209, zipCode: "36034"},
	{latitude: 31.788705, longitude: -86.126215, zipCode: "36035"},
	{latitude: 31.97394, longitude: -86.161676, zipCode: "36036"},
	{latitude: 31.76841, longitude: -86.617768, zipCode: "36037"},
	{latitude: 31.40789, longitude: -86.486923, zipCode: "36038"},
	{latitude: 32.285675, longitude: -85.821328, zipCode: "36039"},
	{latitude: 32.184019, longitude: -86.670033, zipCode: "36040"},
	{latitude: 31.931374, longitude: -86.327951, zipCode: "36041"},
	{latitude: 31.868619, longitude: -86.459989, zipCode: "36042"},
	{latitude: 32.185049, longitude: -86.43375, zipCode: "36043"},
	{latitude: 31.946468, longitude: -86.383706, zipCode: "36046"},
	{latitude: 32.086732, longitude: -86.519618, zipCode: "36047"},
	{latitude: 31.804214, longitude: -85.592024, zipCode: "36048"},
	{latitude: 31.768143, longitude: -86.277257, zipCode: "36049"},
	{latitude: 32.686698, longitude: -86.478759, zipCode: "36051"},
	{latitude: 32.182705, longitude: -86.042825, zipCode: "36052"},
	{latitude: 32.0425, longitude: -85.448435, zipCode: "36053"},
	{latitude: 32.466369, longitude: -86.359546, zipCode: "36054"},
	{latitude: 32.374662, longitude: -86.084602, zipCode: "36057"},
	{latitude: 32.304173, longitude: -86.089919, zipCode: "36064"},
	{latitude: 32.4797, longitude: -86.42401, zipCode: "36066"},
	{latitude: 32.516518, longitude: -86.561483, zipCode: "36067"},
	{latitude: 32.097979, longitude: -86.16545, zipCode: "36069"},
	{latitude: 31.706685, longitude: -86.397754, zipCode: "36071"},
	{latitude: 32.378389, longitude: -85.9237, zipCode: "36075"},
	{latitude: 32.546979, longitude: -85.942735, zipCode: "36078"},
	{latitude: 31.766709, longitude: -85.998035, zipCode: "36079"},
	{latitude: 32.711806, longitude: -86.284022, zipCode: "36080"},
	{latitude: 31.911215, longitude: -85.898114, zipCode: "36081"},
	{latitude: 31.802725, longitude: -85.955086, zipCode: "36082"},
	{latitude: 32.386729, longitude: -85.684361, zipCode: "36083"},
	{latitude: 32.419729, longitude: -85.717085, zipCode: "36088"},
	{latitude: 32.156702, longitude: -85.701532, zipCode: "36089"},
	{latitude: 32.749508, longitude: -86.519285, zipCode: "36091"},
	{latitude: 32.614454, longitude: -86.197243, zipCode: "36092"},
	{latitude: 32.494564, longitude: -86.133634, zipCode: "36093"},
	{latitude: 32.381774, longitude: -86.297649, zipCode: "36101"},
	{latitude: 32.401942, longitude: -86.318456, zipCode: "36104"},
	{latitude: 32.214802, longitude: -86.277339, zipCode: "36105"},
	{latitude: 32.353431, longitude: -86.256963, zipCode: "36106"},
	{latitude: 32.384322, longitude: -86.279489, zipCode: "36107"},
	{latitude: 32.3391, longitude: -86.401107, zipCode: "36108"},
	{latitude: 32.385846, longitude: -86.237918, zipCode: "36109"},
	{latitude: 32.446582, longitude: -86.255772, zipCode: "36110"},
	{latitude: 32.336579, longitude: -86.271324, zipCode: "36111"},
	{latitude: 32.380944, longitude: -86.363749, zipCode: "36112"},
	{latitude: 32.380166, longitude: -86.342025, zipCode: "36113"},
	{latitude: 32.404834, longitude: -86.245723, zipCode: "36114"},
	{latitude: 32.406859, longitude: -86.246748, zipCode: "36115"},
	{latitude: 32.271565, longitude: -86.21925, zipCode: "36116"},
	{latitude: 32.377903, longitude: -86.148907, zipCode: "36117"},
	{latitude: 32.377478, longitude: -86.299405, zipCode: "36130"},
	{latitude: 33.647488, longitude: -85.884129, zipCode: "36201"},
	{latitude: 33.583049, longitude: -85.834485, zipCode: "36203"},
	{latitude: 33.699568, longitude: -85.787428, zipCode: "36205"},
	{latitude: 33.732839, longitude: -85.817957, zipCode: "36206"},
	{latitude: 33.684121, longitude: -85.705195, zipCode: "36207"},
	{latitude: 33.764709, longitude: -85.893939, zipCode: "36250"},
	{latitude: 33.226513, longitude: -85.856735, zipCode: "36251"},
	{latitude: 33.182489, longitude: -85.737301, zipCode: "36255"},
	{latitude: 33.046308, longitude: -85.704818, zipCode: "36256"},
	{latitude: 33.477734, longitude: -85.717338, zipCode: "36258"},
	{latitude: 33.587905, longitude: -86.001061, zipCode: "36260"},
	{latitude: 33.793448, longitude: -85.468276, zipCode: "36262"},
	{latitude: 33.467247, longitude: -85.360162, zipCode: "36263"},
	{latitude: 33.596783, longitude: -85.551483, zipCode: "36264"},
	{latitude: 33.847655, longitude: -85.784916, zipCode: "36265"},
	{latitude: 33.35391, longitude: -85.749287, zipCode: "36266"},
	{latitude: 33.195092, longitude: -85.946532, zipCode: "36267"},
	{latitude: 33.494805, longitude: -85.929469, zipCode: "36268"},
	{latitude: 33.739797, longitude: -85.383876, zipCode: "36269"},
	{latitude: 33.78123, longitude: -86.01869, zipCode: "36271"},
	{latitude: 33.947203, longitude: -85.601118, zipCode: "36272"},
	{latitude: 33.545031, longitude: -85.378048, zipCode: "36273"},
	{latitude: 33.179317, longitude: -85.3603, zipCode: "36274"},
	{latitude: 33.125833, longitude: -85.574216, zipCode: "36276"},
	{latitude: 33.761331, longitude: -85.81521, zipCode: "36277"},
	{latitude: 33.311046, longitude: -85.52685, zipCode: "36278"},
	{latitude: 33.872332, longitude: -85.889664, zipCode: "36279"},
	{latitude: 33.386366, longitude: -85.392468, zipCode: "36280"},
	{latitude: 31.140459, longitude: -85.409097, zipCode: "36301"},
	{latitude: 31.264692, longitude: -85.388814, zipCode: "36303"},
	{latitude: 31.204572, longitude: -85.496004, zipCode: "36305"},
	{latitude: 31.595139, longitude: -85.208847, zipCode: "36310"},
	{latitude: 31.599512, longitude: -85.686806, zipCode: "36311"},
	{latitude: 31.162583, longitude: -85.235238, zipCode: "36312"},
	{latitude: 31.180207, longitude: -85.793716, zipCode: "36313"},
	{latitude: 31.028478, longitude: -85.776732, zipCode: "36314"},
	{latitude: 31.175691, longitude: -85.863975, zipCode: "36316"},
	{latitude: 31.634584, longitude: -85.389501, zipCode: "36317"},
	{latitude: 31.169524, longitude: -85.950531, zipCode: "36318"},
	{latitude: 31.331937, longitude: -85.148874, zipCode: "36319"},
	{latitude: 31.049898, longitude: -85.319224, zipCode: "36320"},
	{latitude: 31.210866, longitude: -85.299218, zipCode: "36321"},
	{latitude: 31.257605, longitude: -85.735807, zipCode: "36322"},
	{latitude: 31.428104, longitude: -86.072471, zipCode: "36323"},
	{latitude: 31.307863, longitude: -85.858011, zipCode: "36330"},
	{latitude: 31.056273, longitude: -85.894124, zipCode: "36340"},
	{latitude: 31.060704, longitude: -85.120765, zipCode: "36343"},
	{latitude: 31.101043, longitude: -85.710255, zipCode: "36344"},
	{latitude: 31.366306, longitude: -85.310372, zipCode: "36345"},
	{latitude: 31.55724, longitude: -85.939193, zipCode: "36346"},
	{latitude: 31.350214, longitude: -85.495521, zipCode: "36350"},
	{latitude: 31.425502, longitude: -85.9066, zipCode: "36351"},
	{latitude: 31.264999, longitude: -85.609699, zipCode: "36352"},
	{latitude: 31.47853, longitude: -85.348626, zipCode: "36353"},
	{latitude: 31.467469, longitude: -85.61703, zipCode: "36360"},
	{latitude: 31.350932, longitude: -85.679787, zipCode: "36362"},
	{latitude: 31.135275, longitude: -85.16173, zipCode: "36370"},
	{latitude: 31.329961, longitude: -85.546258, zipCode: "36371"},
	{latitude: 31.51865, longitude: -85.089056, zipCode: "36373"},
	{latitude: 31.572274, longitude: -85.489563, zipCode: "36374"},
	{latitude: 31.078473, longitude: -85.540441, zipCode: "36375"},
	{latitude: 31.250679, longitude: -85.269772, zipCode: "36376"},
	{latitude: 31.46897, longitude: -86.950426, zipCode: "36401"},
	{latitude: 31.177149, longitude: -86.551502, zipCode: "36420"},
	{latitude: 31.328718, longitude: -86.508335, zipCode: "36421"},
	{latitude: 31.76474, longitude: -87.195168, zipCode: "36425"},
	{latitude: 31.145173, longitude: -87.075489, zipCode: "36426"},
	{latitude: 31.301073, longitude: -87.037298, zipCode: "36432"},
	{latitude: 31.870102, longitude: -87.451564, zipCode: "36435"},
	{latitude: 31.766333, longitude: -87.661411, zipCode: "36436"},
	{latitude: 31.419287, longitude: -87.345189, zipCode: "36439"},
	{latitude: 31.039365, longitude: -87.255045, zipCode: "36441"},
	{latitude: 31.084458, longitude: -86.357041, zipCode: "36442"},
	{latitude: 31.662875, longitude: -87.463884, zipCode: "36444"},
	{latitude: 31.444585, longitude: -87.453392, zipCode: "36445"},
	{latitude: 31.791513, longitude: -87.724483, zipCode: "36446"},
	{latitude: 31.685521, longitude: -87.818624, zipCode: "36451"},
	{latitude: 31.198847, longitude: -86.132955, zipCode: "36453"},
	{latitude: 31.35295, longitude: -87.203008, zipCode: "36454"},
	{latitude: 31.012604, longitude: -86.353816, zipCode: "36455"},
	{latitude: 31.570731, longitude: -86.829905, zipCode: "36456"},
	{latitude: 31.525162, longitude: -87.308122, zipCode: "36460"},
	{latitude: 31.277952, longitude: -86.255483, zipCode: "36467"},
	{latitude: 31.515188, longitude: -87.565908, zipCode: "36470"},
	{latitude: 31.631915, longitude: -87.231792, zipCode: "36471"},
	{latitude: 31.320414, longitude: -87.225842, zipCode: "36473"},
	{latitude: 31.42969, longitude: -86.626037, zipCode: "36474"},
	{latitude: 31.389055, longitude: -87.219999, zipCode: "36475"},
	{latitude: 31.354575, longitude: -86.548397, zipCode: "36476"},
	{latitude: 31.087039, longitude: -86.067187, zipCode: "36477"},
	{latitude: 31.341999, longitude: -87.638416, zipCode: "36480"},
	{latitude: 31.771512, longitude: -87.382559, zipCode: "36481"},
	{latitude: 31.639318, longitude: -87.6369, zipCode: "36482"},
	{latitude: 31.047796, longitude: -86.674187, zipCode: "36483"},
	{latitude: 31.147733, longitude: -87.494821, zipCode: "36502"},
	{latitude: 31.156758, longitude: -87.465398, zipCode: "36503"},
	{latitude: 30.951186, longitude: -88.013469, zipCode: "36505"},
	{latitude: 30.858609, longitude: -87.742025, zipCode: "36507"},
	{latitude: 30.406338, longitude: -88.24881, zipCode: "36509"},
	{latitude: 30.305228, longitude: -87.742637, zipCode: "36511"},
	{latitude: 30.998808, longitude: -88.018409, zipCode: "36512"},
	{latitude: 31.154973, longitude: -87.991737, zipCode: "36513"},
	{latitude: 31.470773, longitude: -88.266741, zipCode: "36518"},
	{latitude: 30.967765, longitude: -88.186809, zipCode: "36521"},
	{latitude: 31.083982, longitude: -88.275016, zipCode: "36522"},
	{latitude: 30.370798, longitude: -88.190791, zipCode: "36523"},
	{latitude: 31.802618, longitude: -88.010863, zipCode: "36524"},
	{latitude: 30.89126, longitude: -88.021015, zipCode: "36525"},
	{latitude: 30.609941, longitude: -87.863812, zipCode: "36526"},
	{latitude: 30.702075, longitude: -87.881866, zipCode: "36527"},
	{latitude: 30.240424, longitude: -88.238319, zipCode: "36528"},
	{latitude: 31.277445, longitude: -88.278821, zipCode: "36529"},
	{latitude: 30.40821, longitude: -87.555585, zipCode: "36530"},
	{latitude: 30.480713, longitude: -87.861307, zipCode: "36532"},
	{latitude: 30.383999, longitude: -87.727359, zipCode: "36535"},
	{latitude: 31.657053, longitude: -88.143271, zipCode: "36538"},
	{latitude: 31.348624, longitude: -88.394107, zipCode: "36539"},
	{latitude: 31.448949, longitude: -87.675843, zipCode: "36540"},
	{latitude: 30.478285, longitude: -88.346047, zipCode: "36541"},
	{latitude: 30.26965, longitude: -87.750627, zipCode: "36542"},
	{latitude: 31.221534, longitude: -87.466165, zipCode: "36543"},
	{latitude: 30.474931, longitude: -88.239604, zipCode: "36544"},
	{latitude: 31.488511, longitude: -87.871704, zipCode: "36545"},
	{latitude: 30.252206, longitude: -87.654504, zipCode: "36547"},
	{latitude: 31.480372, longitude: -87.970958, zipCode: "36548"},
	{latitude: 30.411162, longitude: -87.451119, zipCode: "36549"},
	{latitude: 31.24943, longitude: -87.730436, zipCode: "36550"},
	{latitude: 30.636677, longitude: -87.74703, zipCode: "36551"},
	{latitude: 31.233904, longitude: -88.091567, zipCode: "36553"},
	{latitude: 30.399667, longitude: -87.773019, zipCode: "36555"},
	{latitude: 31.205186, longitude: -87.963791, zipCode: "36556"},
	{latitude: 31.621199, longitude: -88.327956, zipCode: "36558"},
	{latitude: 30.56904, longitude: -87.903933, zipCode: "36559"},
	{latitude: 31.085195, longitude: -88.049045, zipCode: "36560"},
	{latitude: 30.287622, longitude: -87.571267, zipCode: "36561"},
	{latitude: 31.037057, longitude: -87.670416, zipCode: "36562"},
	{latitude: 30.469787, longitude: -87.914526, zipCode: "36564"},
	{latitude: 30.623536, longitude: -87.55696, zipCode: "36567"},
	{latitude: 30.511814, longitude: -88.272373, zipCode: "36568"},
	{latitude: 31.547672, longitude: -88.088037, zipCode: "36569"},
	{latitude: 30.854566, longitude: -88.109842, zipCode: "36571"},
	{latitude: 30.85604, longitude: -88.059194, zipCode: "36572"},
	{latitude: 30.500977, longitude: -87.478432, zipCode: "36574"},
	{latitude: 30.76766, longitude: -88.272656, zipCode: "36575"},
	{latitude: 30.522939, longitude: -87.763842, zipCode: "36576"},
	{latitude: 30.737389, longitude: -87.771888, zipCode: "36578"},
	{latitude: 31.110476, longitude: -87.786871, zipCode: "36579"},
	{latitude: 30.478898, longitude: -87.703414, zipCode: "36580"},
	{latitude: 31.354632, longitude: -88.019104, zipCode: "36581"},
	{latitude: 30.51791, longitude: -88.17275, zipCode: "36582"},
	{latitude: 31.367319, longitude: -88.195002, zipCode: "36583"},
	{latitude: 31.199096, longitude: -88.388739, zipCode: "36584"},
	{latitude: 31.423523, longitude: -88.084456, zipCode: "36585"},
	{latitude: 30.836453, longitude: -88.349925, zipCode: "36587"},
	{latitude: 30.697537, longitude: -88.04028, zipCode: "36602"},
	{latitude: 30.684162, longitude: -88.052756, zipCode: "36603"},
	{latitude: 30.682299, longitude: -88.068365, zipCode: "36604"},
	{latitude: 30.602446, longitude: -88.091303, zipCode: "36605"},
	{latitude: 30.668786, longitude: -88.105874, zipCode: "36606"},
	{latitude: 30.69907, longitude: -88.105512, zipCode: "36607"},
	{latitude: 30.681503, longitude: -88.300213, zipCode: "36608"},
	{latitude: 30.660248, longitude: -88.163479, zipCode: "36609"},
	{latitude: 30.737085, longitude: -88.073172, zipCode: "36610"},
	{latitude: 30.769985, longitude: -88.076672, zipCode: "36611"},
	{latitude: 30.752882, longitude: -88.113135, zipCode: "36612"},
	{latitude: 30.809841, longitude: -88.187451, zipCode: "36613"},
	{latitude: 30.630196, longitude: -88.069943, zipCode: "36615"},
	{latitude: 30.715328, longitude: -88.094821, zipCode: "36617"},
	{latitude: 30.7369, longitude: -88.169796, zipCode: "36618"},
	{latitude: 30.596087, longitude: -88.196637, zipCode: "36619"},
	{latitude: 30.695925, longitude: -88.080342, zipCode: "36652"},
	{latitude: 30.736854, longitude: -88.108849, zipCode: "36663"},
	{latitude: 30.695541, longitude: -88.187867, zipCode: "36688"},
	{latitude: 30.62577, longitude: -88.149686, zipCode: "36693"},
	{latitude: 30.631258, longitude: -88.280593, zipCode: "36695"},
	{latitude: 32.496332, longitude: -87.057391, zipCode: "36701"},
	{latitude: 32.427349, longitude: -86.912858, zipCode: "36703"},
	{latitude: 32.164988, longitude: -87.346996, zipCode: "36720"},
	{latitude: 32.082439, longitude: -87.571494, zipCode: "36722"},
	{latitude: 32.068375, longitude: -87.294343, zipCode: "36723"},
	{latitude: 31.960453, longitude: -87.299484, zipCode: "36726"},
	{latitude: 31.910985, longitude: -88.03391, zipCode: "36727"},
	{latitude: 32.169012, longitude: -87.483394, zipCode: "36728"},
	{latitude: 32.417456, longitude: -87.892213, zipCode: "36732"},
	{latitude: 32.084706, longitude: -87.761188, zipCode: "36736"},
	{latitude: 32.42097, longitude: -87.624965, zipCode: "36738"},
	{latitude: 32.626783, longitude: -87.836571, zipCode: "36740"},
	{latitude: 32.030712, longitude: -87.012476, zipCode: "36741"},
	{latitude: 32.456156, longitude: -87.752226, zipCode: "36742"},
	{latitude: 32.684719, longitude: -87.598773, zipCode: "36744"},
	{latitude: 32.267275, longitude: -87.780901, zipCode: "36748"},
	{latitude: 32.550967, longitude: -86.849827, zipCode: "36749"},
	{latitude: 32.789616, longitude: -86.849377, zipCode: "36750"},
	{latitude: 31.803944, longitude: -87.576925, zipCode: "36751"},
	{latitude: 32.298669, longitude: -86.631995, zipCode: "36752"},
	{latitude: 31.836973, longitude: -87.107572, zipCode: "36753"},
	{latitude: 32.157313, longitude: -87.70238, zipCode: "36754"},
	{latitude: 32.692315, longitude: -87.299514, zipCode: "36756"},
	{latitude: 32.638892, longitude: -86.88991, zipCode: "36758"},
	{latitude: 32.404536, longitude: -87.290023, zipCode: "36759"},
	{latitude: 32.074107, longitude: -86.941109, zipCode: "36761"},
	{latitude: 32.276802, longitude: -87.980704, zipCode: "36763"},
	{latitude: 32.115184, longitude: -87.985264, zipCode: "36764"},
	{latitude: 32.588164, longitude: -87.522245, zipCode: "36765"},
	{latitude: 31.955047, longitude: -87.086673, zipCode: "36766"},
	{latitude: 32.245371, longitude: -87.204508, zipCode: "36767"},
	{latitude: 31.885394, longitude: -87.012859, zipCode: "36768"},
	{latitude: 31.992777, longitude: -87.551501, zipCode: "36769"},
	{latitude: 32.291124, longitude: -87.38522, zipCode: "36773"},
	{latitude: 32.19271, longitude: -86.954805, zipCode: "36775"},
	{latitude: 32.735606, longitude: -87.741416, zipCode: "36776"},
	{latitude: 32.10303, longitude: -87.913924, zipCode: "36782"},
	{latitude: 32.217047, longitude: -87.611655, zipCode: "36783"},
	{latitude: 31.919846, longitude: -87.834121, zipCode: "36784"},
	{latitude: 32.277617, longitude: -86.823481, zipCode: "36785"},
	{latitude: 32.430994, longitude: -87.482151, zipCode: "36786"},
	{latitude: 32.736428, longitude: -86.908481, zipCode: "36790"},
	{latitude: 32.918022, longitude: -86.91568, zipCode: "36792"},
	{latitude: 32.822856, longitude: -87.006438, zipCode: "36793"},
	{latitude: 32.702498, longitude: -85.385475, zipCode: "36801"},
	{latitude: 32.533973, longitude: -85.347519, zipCode: "36804"},
	{latitude: 32.534872, longitude: -85.493755, zipCode: "36830"},
	{latitude: 32.582936, longitude: -85.585938, zipCode: "36832"},
	{latitude: 32.59914, longitude: -85.48927, zipCode: "36849"},
	{latitude: 32.786919, longitude: -85.655757, zipCode: "36850"},
	{latitude: 32.150424, longitude: -85.080888, zipCode: "36851"},
	{latitude: 32.756611, longitude: -85.278908, zipCode: "36852"},
	{latitude: 32.804816, longitude: -85.768148, zipCode: "36853"},
	{latitude: 32.751613, longitude: -85.189484, zipCode: "36854"},
	{latitude: 33.028908, longitude: -85.308151, zipCode: "36855"},
	{latitude: 32.260957, longitude: -84.995401, zipCode: "36856"},
	{latitude: 32.323162, longitude: -85.307813, zipCode: "36858"},
	{latitude: 32.274537, longitude: -85.424439, zipCode: "36860"},
	{latitude: 32.890893, longitude: -85.849191, zipCode: "36861"},
	{latitude: 32.903965, longitude: -85.455157, zipCode: "36862"},
	{latitude: 32.889494, longitude: -85.26375, zipCode: "36863"},
	{latitude: 32.602618, longitude: -85.589334, zipCode: "36865"},
	{latitude: 32.565305, longitude: -85.712082, zipCode: "36866"},
	{latitude: 32.498054, longitude: -85.02359, zipCode: "36867"},
	{latitude: 32.419698, longitude: -85.06456, zipCode: "36869"},
	{latitude: 32.482781, longitude: -85.119859, zipCode: "36870"},
	{latitude: 32.158169, longitude: -85.156733, zipCode: "36871"},
	{latitude: 32.564252, longitude: -85.198549, zipCode: "36874"},
	{latitude: 32.31858, longitude: -85.167161, zipCode: "36875"},
	{latitude: 32.573153, longitude: -85.101369, zipCode: "36877"},
	{latitude: 32.731317, longitude: -85.544644, zipCode: "36879"},
	{latitude: 32.421636, longitude: -88.14733, zipCode: "36901"},
	{latitude: 32.100714, longitude: -88.260849, zipCode: "36904"},
	{latitude: 32.438096, longitude: -88.348591, zipCode: "36907"},
	{latitude: 31.872768, longitude: -88.237076, zipCode: "36908"},
	{latitude: 32.230807, longitude: -88.159321, zipCode: "36910"},
	{latitude: 32.242349, longitude: -88.305689, zipCode: "36912"},
	{latitude: 31.960734, longitude: -88.446676, zipCode: "36913"},
	{latitude: 31.958969, longitude: -88.360194, zipCode: "36915"},
	{latitude: 32.241505, longitude: -88.049184, zipCode: "36916"},
	{latitude: 31.769052, longitude: -88.282862, zipCode: "36919"},
	{latitude: 31.93843, longitude: -88.18278, zipCode: "36921"},
	{latitude: 32.297498, longitude: -88.149295, zipCode: "36922"},
	{latitude: 32.432254, longitude: -88.183591, zipCode: "36925"},
	{latitude: 36.571781, longitude: -87.108705, zipCode: "37010"},
	{latitude: 36.07111, longitude: -86.005649, zipCode: "37012"},
	{latitude: 36.051845, longitude: -86.630396, zipCode: "37013"},
	{latitude: 35.873015, longitude: -86.632923, zipCode: "37014"},
	{latitude: 36.297457, longitude: -87.091738, zipCode: "37015"},
	{latitude: 35.963556, longitude: -86.113618, zipCode: "37016"},
	{latitude: 35.6314, longitude: -86.210955, zipCode: "37018"},
	{latitude: 35.390902, longitude: -86.709331, zipCode: "37019"},
	{latitude: 35.634389, longitude: -86.407093, zipCode: "37020"},
	{latitude: 36.514582, longitude: -86.317038, zipCode: "37022"},
	{latitude: 36.592456, longitude: -87.782744, zipCode: "37023"},
	{latitude: 35.952659, longitude: -87.308467, zipCode: "37025"},
	{latitude: 35.69578, longitude: -86.107456, zipCode: "37026"},
	{latitude: 35.999288, longitude: -86.785062, zipCode: "37027"},
	{latitude: 36.628017, longitude: -87.852264, zipCode: "37028"},
	{latitude: 36.043084, longitude: -87.268786, zipCode: "37029"},
	{latitude: 36.271671, longitude: -85.942372, zipCode: "37030"},
	{latitude: 36.377463, longitude: -86.301172, zipCode: "37031"},
	{latitude: 36.525153, longitude: -87.016524, zipCode: "37032"},
	{latitude: 35.744615, longitude: -87.557839, zipCode: "37033"},
	{latitude: 35.637125, longitude: -86.694765, zipCode: "37034"},
	{latitude: 36.381108, longitude: -87.133983, zipCode: "37035"},
	{latitude: 36.234982, longitude: -87.261263, zipCode: "37036"},
	{latitude: 35.703083, longitude: -86.351238, zipCode: "37037"},
	{latitude: 36.522319, longitude: -87.327933, zipCode: "37040"},
	{latitude: 36.569339, longitude: -87.411845, zipCode: "37042"},
	{latitude: 36.490572, longitude: -87.240797, zipCode: "37043"},
	{latitude: 35.781852, longitude: -86.70075, zipCode: "37046"},
	{latitude: 35.312921, longitude: -86.831904, zipCode: "37047"},
	{latitude: 36.49042, longitude: -86.591085, zipCode: "37048"},
	{latitude: 36.561975, longitude: -86.682138, zipCode: "37049"},
	{latitude: 36.374244, longitude: -87.630844, zipCode: "37050"},
	{latitude: 36.304272, longitude: -87.414042, zipCode: "37051"},
	{latitude: 36.37258, longitude: -87.407441, zipCode: "37052"},
	{latitude: 36.077209, longitude: -87.442803, zipCode: "37055"},
	{latitude: 36.418109, longitude: -86.029944, zipCode: "37057"},
	{latitude: 36.444038, longitude: -87.876678, zipCode: "37058"},
	{latitude: 35.984992, longitude: -85.90307, zipCode: "37059"},
	{latitude: 35.749229, longitude: -86.634283, zipCode: "37060"},
	{latitude: 36.276545, longitude: -87.66302, zipCode: "37061"},
	{latitude: 35.990294, longitude: -87.135646, zipCode: "37062"},
	{latitude: 35.884862, longitude: -86.954138, zipCode: "37064"},
	{latitude: 36.398757, longitude: -86.45554, zipCode: "37066"},
	{latitude: 35.912204, longitude: -86.768843, zipCode: "37067"},
	{latitude: 35.984319, longitude: -86.902292, zipCode: "37069"},
	{latitude: 36.35214, longitude: -86.746651, zipCode: "37072"},
	{latitude: 36.432642, longitude: -86.817152, zipCode: "37073"},
	{latitude: 36.426041, longitude: -86.1529, zipCode: "37074"},
	{latitude: 36.339969, longitude: -86.607922, zipCode: "37075"},
	{latitude: 36.145176, longitude: -86.576636, zipCode: "37076"},
	{latitude: 35.903693, longitude: -87.774514, zipCode: "37078"},
	{latitude: 36.464769, longitude: -87.648093, zipCode: "37079"},
	{latitude: 36.332761, longitude: -86.916886, zipCode: "37080"},
	{latitude: 36.094872, longitude: -87.115266, zipCode: "37082"},
	{latitude: 36.537757, longitude: -86.00096, zipCode: "37083"},
	{latitude: 35.953078, longitude: -86.279107, zipCode: "37085"},
	{latitude: 36.022237, longitude: -86.559384, zipCode: "37086"},
	{latitude: 36.263852, longitude: -86.269244, zipCode: "37087"},
	{latitude: 36.115439, longitude: -86.297637, zipCode: "37090"},
	{latitude: 35.489244, longitude: -86.767472, zipCode: "37091"},
	{latitude: 35.965928, longitude: -85.961077, zipCode: "37095"},
	{latitude: 35.612943, longitude: -87.87101, zipCode: "37096"},
	{latitude: 35.779941, longitude: -87.823417, zipCode: "37097"},
	{latitude: 35.871459, longitude: -87.307846, zipCode: "37098"},
	{latitude: 36.078905, longitude: -87.62675, zipCode: "37101"},
	{latitude: 35.64509, longitude: -85.741252, zipCode: "37110"},
	{latitude: 36.255279, longitude: -86.698667, zipCode: "37115"},
	{latitude: 35.925673, longitude: -86.189014, zipCode: "37118"},
	{latitude: 36.178348, longitude: -86.48658, zipCode: "37122"},
	{latitude: 35.779478, longitude: -86.327234, zipCode: "37127"},
	{latitude: 35.803224, longitude: -86.474647, zipCode: "37128"},
	{latitude: 35.929834, longitude: -86.450822, zipCode: "37129"},
	{latitude: 35.881689, longitude: -86.318747, zipCode: "37130"},
	{latitude: 35.848732, longitude: -86.362829, zipCode: "37132"},
	{latitude: 35.992741, longitude: -87.948093, zipCode: "37134"},
	{latitude: 35.915789, longitude: -86.676587, zipCode: "37135"},
	{latitude: 35.884173, longitude: -87.505349, zipCode: "37137"},
	{latitude: 36.237708, longitude: -86.60703, zipCode: "37138"},
	{latitude: 35.863435, longitude: -87.652441, zipCode: "37140"},
	{latitude: 36.614476, longitude: -86.698935, zipCode: "37141"},
	{latitude: 36.410966, longitude: -87.509666, zipCode: "37142"},
	{latitude: 36.122529, longitude: -87.027133, zipCode: "37143"},
	{latitude: 35.305107, longitude: -86.655879, zipCode: "37144"},
	{latitude: 36.380909, longitude: -85.917012, zipCode: "37145"},
	{latitude: 36.404614, longitude: -87.032372, zipCode: "37146"},
	{latitude: 36.576658, longitude: -86.497813, zipCode: "37148"},
	{latitude: 35.808727, longitude: -86.193158, zipCode: "37149"},
	{latitude: 36.541483, longitude: -85.81041, zipCode: "37150"},
	{latitude: 36.309201, longitude: -86.04302, zipCode: "37151"},
	{latitude: 35.764477, longitude: -86.55531, zipCode: "37153"},
	{latitude: 35.455746, longitude: -86.48479, zipCode: "37160"},
	{latitude: 36.29113, longitude: -87.475439, zipCode: "37165"},
	{latitude: 35.917978, longitude: -85.786903, zipCode: "37166"},
	{latitude: 35.962828, longitude: -86.529684, zipCode: "37167"},
	{latitude: 36.369418, longitude: -87.310858, zipCode: "37171"},
	{latitude: 36.548385, longitude: -86.849451, zipCode: "37172"},
	{latitude: 35.719255, longitude: -86.898188, zipCode: "37174"},
	{latitude: 36.32426, longitude: -87.892851, zipCode: "37175"},
	{latitude: 36.323791, longitude: -87.790436, zipCode: "37178"},
	{latitude: 35.812608, longitude: -86.910077, zipCode: "37179"},
	{latitude: 35.621483, longitude: -86.575353, zipCode: "37180"},
	{latitude: 36.244378, longitude: -87.505893, zipCode: "37181"},
	{latitude: 35.505256, longitude: -86.287376, zipCode: "37183"},
	{latitude: 36.086788, longitude: -86.145212, zipCode: "37184"},
	{latitude: 36.062922, longitude: -87.865036, zipCode: "37185"},
	{latitude: 36.596738, longitude: -86.221711, zipCode: "37186"},
	{latitude: 36.144148, longitude: -87.197886, zipCode: "37187"},
	{latitude: 36.480492, longitude: -86.680971, zipCode: "37188"},
	{latitude: 36.278598, longitude: -86.831464, zipCode: "37189"},
	{latitude: 35.823091, longitude: -86.042159, zipCode: "37190"},
	{latitude: 36.505841, longitude: -87.530104, zipCode: "37191"},
	{latitude: 36.166986, longitude: -86.77832, zipCode: "37201"},
	{latitude: 36.149775, longitude: -86.789146, zipCode: "37203"},
	{latitude: 36.106676, longitude: -86.774575, zipCode: "37204"},
	{latitude: 36.113187, longitude: -86.870443, zipCode: "37205"},
	{latitude: 36.179319, longitude: -86.731083, zipCode: "37206"},
	{latitude: 36.232473, longitude: -86.777342, zipCode: "37207"},
	{latitude: 36.177546, longitude: -86.809518, zipCode: "37208"},
	{latitude: 36.150656, longitude: -86.959839, zipCode: "37209"},
	{latitude: 36.149248, longitude: -86.733016, zipCode: "37210"},
	{latitude: 36.067017, longitude: -86.723419, zipCode: "37211"},
	{latitude: 36.133773, longitude: -86.801688, zipCode: "37212"},
	{latitude: 36.167221, longitude: -86.768181, zipCode: "37213"},
	{latitude: 36.169038, longitude: -86.662565, zipCode: "37214"},
	{latitude: 36.081955, longitude: -86.834904, zipCode: "37215"},
	{latitude: 36.215017, longitude: -86.726911, zipCode: "37216"},
	{latitude: 36.108666, longitude: -86.656722, zipCode: "37217"},
	{latitude: 36.171353, longitude: -86.918161, zipCode: "37218"},
	{latitude: 36.166282, longitude: -86.782951, zipCode: "37219"},
	{latitude: 36.068447, longitude: -86.793127, zipCode: "37220"},
	{latitude: 36.059241, longitude: -86.972312, zipCode: "37221"},
	{latitude: 36.19693, longitude: -86.803373, zipCode: "37228"},
	{latitude: 36.141117, longitude: -86.802888, zipCode: "37232"},
	{latitude: 36.165697, longitude: -86.779462, zipCode: "37238"},
	{latitude: 35.417244, longitude: -85.795212, zipCode: "37301"},
	{latitude: 35.015703, longitude: -85.021748, zipCode: "37302"},
	{latitude: 35.442594, longitude: -84.639736, zipCode: "37303"},
	{latitude: 35.457963, longitude: -85.702038, zipCode: "37305"},
	{latitude: 35.096807, longitude: -86.191801, zipCode: "37306"},
	{latitude: 35.164958, longitude: -84.626256, zipCode: "37307"},
	{latitude: 35.342635, longitude: -84.993756, zipCode: "37308"},
	{latitude: 35.301834, longitude: -84.741456, zipCode: "37309"},
	{latitude: 35.252525, longitude: -84.710066, zipCode: "37310"},
	{latitude: 35.104016, longitude: -84.920828, zipCode: "37311"},
	{latitude: 35.226009, longitude: -84.874479, zipCode: "37312"},
	{latitude: 35.376389, longitude: -85.695496, zipCode: "37313"},
	{latitude: 35.047214, longitude: -85.051644, zipCode: "37315"},
	{latitude: 35.001661, longitude: -84.729673, zipCode: "37316"},
	{latitude: 35.031001, longitude: -84.443056, zipCode: "37317"},
	{latitude: 35.178719, longitude: -85.980014, zipCode: "37318"},
	{latitude: 35.502103, longitude: -85.008024, zipCode: "37321"},
	{latitude: 35.501959, longitude: -84.825071, zipCode: "37322"},
	{latitude: 35.096183, longitude: -84.816533, zipCode: "37323"},
	{latitude: 35.239754, longitude: -85.987165, zipCode: "37324"},
	{latitude: 35.253065, longitude: -84.604887, zipCode: "37325"},
	{latitude: 35.040252, longitude: -84.383286, zipCode: "37326"},
	{latitude: 35.416267, longitude: -85.394408, zipCode: "37327"},
	{latitude: 35.036942, longitude: -86.357374, zipCode: "37328"},
	{latitude: 35.391992, longitude: -84.460149, zipCode: "37329"},
	{latitude: 35.279637, longitude: -86.130603, zipCode: "37330"},
	{latitude: 35.31617, longitude: -84.535776, zipCode: "37331"},
	{latitude: 35.60059, longitude: -84.949548, zipCode: "37332"},
	{latitude: 35.148414, longitude: -84.35978, zipCode: "37333"},
	{latitude: 35.165417, longitude: -86.5796, zipCode: "37334"},
	{latitude: 35.068898, longitude: -86.403992, zipCode: "37335"},
	{latitude: 35.308843, longitude: -84.931512, zipCode: "37336"},
	{latitude: 35.787056, longitude: -84.850664, zipCode: "37337"},
	{latitude: 35.429787, longitude: -85.209519, zipCode: "37338"},
	{latitude: 35.373851, longitude: -85.632211, zipCode: "37339"},
	{latitude: 35.021988, longitude: -85.519895, zipCode: "37340"},
	{latitude: 35.219931, longitude: -85.073002, zipCode: "37341"},
	{latitude: 35.383549, longitude: -85.946858, zipCode: "37342"},
	{latitude: 35.166587, longitude: -85.212257, zipCode: "37343"},
	{latitude: 35.016829, longitude: -86.203041, zipCode: "37345"},
	{latitude: 35.063468, longitude: -85.613418, zipCode: "37347"},
	{latitude: 35.121827, longitude: -86.430786, zipCode: "37348"},
	{latitude: 34.994485, longitude: -85.351577, zipCode: "37350"},
	{latitude: 35.100285, longitude: -85.260363, zipCode: "37351"},
	{latitude: 35.27752, longitude: -86.353027, zipCode: "37352"},
	{latitude: 35.12286, longitude: -84.985492, zipCode: "37353"},
	{latitude: 35.506343, longitude: -84.347404, zipCode: "37354"},
	{latitude: 35.509287, longitude: -86.079287, zipCode: "37355"},
	{latitude: 35.228878, longitude: -85.823694, zipCode: "37356"},
	{latitude: 35.593909, longitude: -85.918342, zipCode: "37357"},
	{latitude: 35.204342, longitude: -86.403866, zipCode: "37359"},
	{latitude: 35.437578, longitude: -86.254328, zipCode: "37360"},
	{latitude: 35.087864, longitude: -84.656749, zipCode: "37361"},
	{latitude: 35.031284, longitude: -84.680857, zipCode: "37362"},
	{latitude: 35.121041, longitude: -85.0556, zipCode: "37363"},
	{latitude: 35.368696, longitude: -85.516325, zipCode: "37365"},
	{latitude: 35.338856, longitude: -85.830003, zipCode: "37366"},
	{latitude: 35.619509, longitude: -85.2263, zipCode: "37367"},
	{latitude: 35.205589, longitude: -84.464366, zipCode: "37369"},
	{latitude: 35.359048, longitude: -84.700606, zipCode: "37370"},
	{latitude: 35.407459, longitude: -85.092816, zipCode: "37373"},
	{latitude: 35.164983, longitude: -85.661818, zipCode: "37374"},
	{latitude: 35.150451, longitude: -85.890419, zipCode: "37375"},
	{latitude: 35.047392, longitude: -85.95836, zipCode: "37376"},
	{latitude: 35.212136, longitude: -85.362333, zipCode: "37377"},
	{latitude: 35.284581, longitude: -85.194872, zipCode: "37379"},
	{latitude: 35.070937, longitude: -85.754605, zipCode: "37380"},
	{latitude: 35.686974, longitude: -84.877368, zipCode: "37381"},
	{latitude: 35.211405, longitude: -85.913222, zipCode: "37383"},
	{latitude: 35.336793, longitude: -84.228286, zipCode: "37385"},
	{latitude: 35.27425, longitude: -85.720584, zipCode: "37387"},
	{latitude: 35.351487, longitude: -86.198185, zipCode: "37388"},
	{latitude: 35.093235, longitude: -84.35392, zipCode: "37391"},
	{latitude: 35.539186, longitude: -85.860646, zipCode: "37394"},
	{latitude: 35.001888, longitude: -85.503458, zipCode: "37396"},
	{latitude: 35.207609, longitude: -85.517909, zipCode: "37397"},
	{latitude: 35.181164, longitude: -86.13836, zipCode: "37398"},
	{latitude: 35.046805, longitude: -85.316289, zipCode: "37402"},
	{latitude: 35.048259, longitude: -85.294362, zipCode: "37403"},
	{latitude: 35.028234, longitude: -85.273258, zipCode: "37404"},
	{latitude: 35.129933, longitude: -85.382836, zipCode: "37405"},
	{latitude: 35.073134, longitude: -85.245571, zipCode: "37406"},
	{latitude: 35.001943, longitude: -85.289724, zipCode: "37407"},
	{latitude: 35.030816, longitude: -85.311638, zipCode: "37408"},
	{latitude: 35.010262, longitude: -85.335905, zipCode: "37409"},
	{latitude: 35.001899, longitude: -85.31422, zipCode: "37410"},
	{latitude: 35.026581, longitude: -85.230237, zipCode: "37411"},
	{latitude: 34.997437, longitude: -85.227199, zipCode: "37412"},
	{latitude: 35.124725, longitude: -85.281199, zipCode: "37415"},
	{latitude: 35.098865, longitude: -85.157005, zipCode: "37416"},
	{latitude: 35.051568, longitude: -85.421089, zipCode: "37419"},
	{latitude: 35.027473, longitude: -85.153604, zipCode: "37421"},
	{latitude: 35.048966, longitude: -85.312169, zipCode: "37450"},
	{latitude: 36.33825, longitude: -82.318084, zipCode: "37601"},
	{latitude: 36.299242, longitude: -82.383044, zipCode: "37604"},
	{latitude: 36.300978, longitude: -82.367398, zipCode: "37614"},
	{latitude: 36.398218, longitude: -82.452386, zipCode: "37615"},
	{latitude: 36.222507, longitude: -82.7516, zipCode: "37616"},
	{latitude: 36.52449, longitude: -82.365651, zipCode: "37617"},
	{latitude: 36.460339, longitude: -82.208744, zipCode: "37618"},
	{latitude: 36.549613, longitude: -82.09404, zipCode: "37620"},
	{latitude: 36.326824, longitude: -81.974686, zipCode: "37640"},
	{latitude: 36.199887, longitude: -82.663513, zipCode: "37641"},
	{latitude: 36.53133, longitude: -82.72694, zipCode: "37642"},
	{latitude: 36.370513, longitude: -82.122288, zipCode: "37643"},
	{latitude: 36.562766, longitude: -82.662455, zipCode: "37645"},
	{latitude: 36.087789, longitude: -82.468573, zipCode: "37650"},
	{latitude: 36.399971, longitude: -82.630786, zipCode: "37656"},
	{latitude: 36.017238, longitude: -82.560518, zipCode: "37657"},
	{latitude: 36.260442, longitude: -82.151596, zipCode: "37658"},
	{latitude: 36.279849, longitude: -82.506119, zipCode: "37659"},
	{latitude: 36.52686, longitude: -82.571968, zipCode: "37660"},
	{latitude: 36.463015, longitude: -82.488724, zipCode: "37663"},
	{latitude: 36.508113, longitude: -82.510198, zipCode: "37664"},
	{latitude: 36.579483, longitude: -82.573101, zipCode: "37665"},
	{latitude: 36.574631, longitude: -81.725538, zipCode: "37680"},
	{latitude: 36.258014, longitude: -82.6206, zipCode: "37681"},
	{latitude: 36.300321, longitude: -82.291672, zipCode: "37682"},
	{latitude: 36.453006, longitude: -81.822364, zipCode: "37683"},
	{latitude: 36.440209, longitude: -82.348811, zipCode: "37686"},
	{latitude: 36.178723, longitude: -82.097005, zipCode: "37687"},
	{latitude: 36.545444, longitude: -81.882879, zipCode: "37688"},
	{latitude: 36.25121, longitude: -82.559009, zipCode: "37690"},
	{latitude: 36.36791, longitude: -81.755247, zipCode: "37691"},
	{latitude: 36.184591, longitude: -82.30414, zipCode: "37692"},
	{latitude: 36.383788, longitude: -82.278722, zipCode: "37694"},
	{latitude: 35.791709, longitude: -83.987225, zipCode: "37701"},
	{latitude: 36.22688, longitude: -84.009714, zipCode: "37705"},
	{latitude: 36.330558, longitude: -83.317142, zipCode: "37708"},
	{latitude: 36.166501, longitude: -83.665, zipCode: "37709"},
	{latitude: 36.158033, longitude: -84.317712, zipCode: "37710"},
	{latitude: 36.281355, longitude: -83.045426, zipCode: "37711"},
	{latitude: 36.081457, longitude: -83.134285, zipCode: "37713"},
	{latitude: 36.230864, longitude: -84.254168, zipCode: "37714"},
	{latitude: 36.553208, longitude: -83.958942, zipCode: "37715"},
	{latitude: 36.100883, longitude: -84.168691, zipCode: "37716"},
	{latitude: 36.021862, longitude: -84.428085, zipCode: "37719"},
	{latitude: 36.130591, longitude: -83.814869, zipCode: "37721"},
	{latitude: 35.815156, longitude: -83.201096, zipCode: "37722"},
	{latitude: 35.97043, longitude: -84.813656, zipCode: "37723"},
	{latitude: 36.548535, longitude: -83.710257, zipCode: "37724"},
	{latitude: 35.997639, longitude: -83.393303, zipCode: "37725"},
	{latitude: 36.188121, longitude: -84.810269, zipCode: "37726"},
	{latitude: 35.885101, longitude: -83.022989, zipCode: "37727"},
	{latitude: 36.514122, longitude: -84.032754, zipCode: "37729"},
	{latitude: 36.56839, longitude: -83.973561, zipCode: "37730"},
	{latitude: 36.548667, longitude: -83.009063, zipCode: "37731"},
	{latitude: 36.327959, longitude: -84.628235, zipCode: "37732"},
	{latitude: 36.356736, longitude: -84.695035, zipCode: "37733"},
	{latitude: 35.761416, longitude: -84.120173, zipCode: "37737"},
	{latitude: 35.671291, longitude: -83.476474, zipCode: "37738"},
	{latitude: 35.675844, longitude: -84.179246, zipCode: "37742"},
	{latitude: 36.068327, longitude: -82.855697, zipCode: "37743"},
	{latitude: 36.276299, longitude: -82.831197, zipCode: "37745"},
	{latitude: 35.945373, longitude: -84.516366, zipCode: "37748"},
	{latitude: 36.575959, longitude: -83.547391, zipCode: "37752"},
	{latitude: 35.822812, longitude: -83.098, zipCode: "37753"},
	{latitude: 36.139099, longitude: -84.029801, zipCode: "37754"},
	{latitude: 36.422783, longitude: -84.532704, zipCode: "37755"},
	{latitude: 36.301631, longitude: -84.407038, zipCode: "37756"},
	{latitude: 36.261396, longitude: -84.105815, zipCode: "37757"},
	{latitude: 36.103764, longitude: -83.466311, zipCode: "37760"},
	{latitude: 36.576416, longitude: -84.119247, zipCode: "37762"},
	{latitude: 35.823448, longitude: -84.488177, zipCode: "37763"},
	{latitude: 35.970346, longitude: -83.616642, zipCode: "37764"},
	{latitude: 36.572344, longitude: -83.058614, zipCode: "37765"},
	{latitude: 36.398375, longitude: -84.073032, zipCode: "37766"},
	{latitude: 36.21785, longitude: -84.155468, zipCode: "37769"},
	{latitude: 36.152416, longitude: -84.641827, zipCode: "37770"},
	{latitude: 35.838499, longitude: -84.316382, zipCode: "37771"},
	{latitude: 35.793377, longitude: -84.212106, zipCode: "37772"},
	{latitude: 35.72913, longitude: -84.358961, zipCode: "37774"},
	{latitude: 35.822632, longitude: -84.052409, zipCode: "37777"},
	{latitude: 36.210942, longitude: -83.757913, zipCode: "37779"},
	{latitude: 35.671704, longitude: -84.080473, zipCode: "37801"},
	{latitude: 35.660842, longitude: -83.981389, zipCode: "37803"},
	{latitude: 35.792278, longitude: -83.893149, zipCode: "37804"},
	{latitude: 36.084693, longitude: -83.727986, zipCode: "37806"},
	{latitude: 36.259656, longitude: -83.82599, zipCode: "37807"},
	{latitude: 36.156693, longitude: -83.038092, zipCode: "37809"},
	{latitude: 36.177664, longitude: -83.10196, zipCode: "37810"},
	{latitude: 36.345957, longitude: -83.212268, zipCode: "37811"},
	{latitude: 36.175136, longitude: -83.257024, zipCode: "37813"},
	{latitude: 36.233341, longitude: -83.337912, zipCode: "37814"},
	{latitude: 36.185587, longitude: -82.973049, zipCode: "37818"},
	{latitude: 36.568255, longitude: -84.205753, zipCode: "37819"},
	{latitude: 36.085171, longitude: -83.566057, zipCode: "37820"},
	{latitude: 35.97508, longitude: -83.195503, zipCode: "37821"},
	{latitude: 36.415883, longitude: -83.679108, zipCode: "37825"},
	{latitude: 35.558226, longitude: -84.589828, zipCode: "37826"},
	{latitude: 36.197826, longitude: -84.06886, zipCode: "37828"},
	{latitude: 36.00102, longitude: -84.617381, zipCode: "37829"},
	{latitude: 35.966483, longitude: -84.290549, zipCode: "37830"},
	{latitude: 36.067831, longitude: -84.369634, zipCode: "37840"},
	{latitude: 36.50871, longitude: -84.543828, zipCode: "37841"},
	{latitude: 36.010713, longitude: -83.04836, zipCode: "37843"},
	{latitude: 36.103708, longitude: -84.450161, zipCode: "37845"},
	{latitude: 35.679251, longitude: -84.481817, zipCode: "37846"},
	{latitude: 36.431554, longitude: -84.273435, zipCode: "37847"},
	{latitude: 36.237034, longitude: -83.681748, zipCode: "37848"},
	{latitude: 36.05405, longitude: -84.048488, zipCode: "37849"},
	{latitude: 36.56559, longitude: -83.792466, zipCode: "37851"},
	{latitude: 36.324146, longitude: -84.579513, zipCode: "37852"},
	{latitude: 35.836766, longitude: -83.91081, zipCode: "37853"},
	{latitude: 35.886081, longitude: -84.709146, zipCode: "37854"},
	{latitude: 36.424289, longitude: -82.9414, zipCode: "37857"},
	{latitude: 36.240151, longitude: -83.179967, zipCode: "37860"},
	{latitude: 36.240743, longitude: -83.513713, zipCode: "37861"},
	{latitude: 35.736359, longitude: -83.672368, zipCode: "37862"},
	{latitude: 35.786377, longitude: -83.563687, zipCode: "37863"},
	{latitude: 35.851151, longitude: -83.734888, zipCode: "37865"},
	{latitude: 36.347281, longitude: -83.843304, zipCode: "37866"},
	{latitude: 36.514755, longitude: -83.244996, zipCode: "37869"},
	{latitude: 36.462895, longitude: -83.833801, zipCode: "37870"},
	{latitude: 36.046975, longitude: -83.681338, zipCode: "37871"},
	{latitude: 36.268699, longitude: -84.681205, zipCode: "37872"},
	{latitude: 36.52627, longitude: -82.843047, zipCode: "37873"},
	{latitude: 35.59833, longitude: -84.456484, zipCode: "37874"},
	{latitude: 35.865101, longitude: -83.47897, zipCode: "37876"},
	{latitude: 36.15113, longitude: -83.406846, zipCode: "37877"},
	{latitude: 35.596591, longitude: -83.981296, zipCode: "37878"},
	{latitude: 36.473348, longitude: -83.513314, zipCode: "37879"},
	{latitude: 35.700908, longitude: -84.660106, zipCode: "37880"},
	{latitude: 36.398144, longitude: -83.362463, zipCode: "37881"},
	{latitude: 35.604444, longitude: -83.819391, zipCode: "37882"},
	{latitude: 35.4989, longitude: -84.137703, zipCode: "37885"},
	{latitude: 35.741482, longitude: -83.807486, zipCode: "37886"},
	{latitude: 36.070712, longitude: -84.61413, zipCode: "37887"},
	{latitude: 36.329877, longitude: -83.579136, zipCode: "37888"},
	{latitude: 36.080387, longitude: -83.293078, zipCode: "37890"},
	{latitude: 36.292068, longitude: -83.138109, zipCode: "37891"},
	{latitude: 36.555696, longitude: -84.357644, zipCode: "37892"},
	{latitude: 35.962, longitude: -83.919622, zipCode: "37902"},
	{latitude: 35.947161, longitude: -84.022715, zipCode: "37909"},
	{latitude: 36.008487, longitude: -83.984687, zipCode: "37912"},
	{latitude: 35.983165, longitude: -83.795796, zipCode: "37914"},
	{latitude: 35.970813, longitude: -83.900432, zipCode: "37915"},
	{latitude: 35.953078, longitude: -83.932588, zipCode: "37916"},
	{latitude: 36.001581, longitude: -83.91265, zipCode: "37917"},
	{latitude: 36.056875, longitude: -83.917352, zipCode: "37918"},
	{latitude: 35.914748, longitude: -84.000169, zipCode: "37919"},
	{latitude: 35.911567, longitude: -83.857782, zipCode: "37920"},
	{latitude: 35.980971, longitude: -83.996905, zipCode: "37921"},
	{latitude: 35.860496, longitude: -84.100535, zipCode: "37922"},
	{latitude: 35.927527, longitude: -84.080687, zipCode: "37923"},
	{latitude: 36.02619, longitude: -83.811875, zipCode: "37924"},
	{latitude: 35.962404, longitude: -83.915969, zipCode: "37929"},
	{latitude: 35.976321, longitude: -84.126349, zipCode: "37931"},
	{latitude: 35.917299, longitude: -84.198787, zipCode: "37932"},
	{latitude: 35.87618, longitude: -84.180016, zipCode: "37934"},
	{latitude: 36.125008, longitude: -83.938175, zipCode: "37938"},
	{latitude: 35.816186, longitude: -89.149391, zipCode: "38001"},
	{latitude: 35.291995, longitude: -89.702318, zipCode: "38002"},
	{latitude: 35.412241, longitude: -89.759212, zipCode: "38004"},
	{latitude: 35.693723, longitude: -89.089657, zipCode: "38006"},
	{latitude: 36.164233, longitude: -89.439192, zipCode: "38007"},
	{latitude: 35.243353, longitude: -89.000379, zipCode: "38008"},
	{latitude: 35.473872, longitude: -89.714429, zipCode: "38011"},
	{latitude: 35.610021, longitude: -89.270574, zipCode: "38012"},
	{latitude: 35.559364, longitude: -89.824229, zipCode: "38015"},
	{latitude: 35.18086, longitude: -89.759838, zipCode: "38016"},
	{latitude: 35.066953, longitude: -89.654078, zipCode: "38017"},
	{latitude: 35.138613, longitude: -89.766424, zipCode: "38018"},
	{latitude: 35.561088, longitude: -89.631953, zipCode: "38019"},
	{latitude: 35.870442, longitude: -89.184078, zipCode: "38021"},
	{latitude: 35.474501, longitude: -89.964131, zipCode: "38023"},
	{latitude: 36.036919, longitude: -89.384751, zipCode: "38024"},
	{latitude: 35.183099, longitude: -89.6322, zipCode: "38028"},
	{latitude: 35.242098, longitude: -89.823522, zipCode: "38029"},
	{latitude: 35.985502, longitude: -89.61783, zipCode: "38030"},
	{latitude: 35.914103, longitude: -89.232081, zipCode: "38034"},
	{latitude: 35.327248, longitude: -89.619386, zipCode: "38036"},
	{latitude: 35.797359, longitude: -89.398895, zipCode: "38037"},
	{latitude: 35.0572, longitude: -89.186231, zipCode: "38039"},
	{latitude: 35.879109, longitude: -89.482941, zipCode: "38040"},
	{latitude: 35.652486, longitude: -89.675132, zipCode: "38041"},
	{latitude: 35.152981, longitude: -89.136662, zipCode: "38042"},
	{latitude: 35.215364, longitude: -88.807847, zipCode: "38044"},
	{latitude: 35.046002, longitude: -89.244092, zipCode: "38046"},
	{latitude: 36.065651, longitude: -89.547142, zipCode: "38047"},
	{latitude: 35.390333, longitude: -89.53821, zipCode: "38049"},
	{latitude: 35.816301, longitude: -89.227092, zipCode: "38050"},
	{latitude: 35.090253, longitude: -88.9178, zipCode: "38052"},
	{latitude: 35.328122, longitude: -89.940824, zipCode: "38053"},
	{latitude: 35.335804, longitude: -89.874415, zipCode: "38054"},
	{latitude: 35.052435, longitude: -89.358978, zipCode: "38057"},
	{latitude: 35.457857, longitude: -89.816192, zipCode: "38058"},
	{latitude: 36.115718, longitude: -89.239733, zipCode: "38059"},
	{latitude: 35.209347, longitude: -89.505468, zipCode: "38060"},
	{latitude: 35.06839, longitude: -88.770907, zipCode: "38061"},
	{latitude: 35.746419, longitude: -89.62939, zipCode: "38063"},
	{latitude: 35.076505, longitude: -89.534306, zipCode: "38066"},
	{latitude: 35.081609, longitude: -89.041788, zipCode: "38067"},
	{latitude: 35.25837, longitude: -89.335239, zipCode: "38068"},
	{latitude: 35.455618, longitude: -89.369888, zipCode: "38069"},
	{latitude: 35.940437, longitude: -89.249173, zipCode: "38070"},
	{latitude: 35.382302, longitude: -89.168055, zipCode: "38075"},
	{latitude: 35.123773, longitude: -89.466428, zipCode: "38076"},
	{latitude: 36.328517, longitude: -89.474404, zipCode: "38077"},
	{latitude: 36.413305, longitude: -89.469247, zipCode: "38079"},
	{latitude: 36.207086, longitude: -89.519208, zipCode: "38080"},
	{latitude: 35.156214, longitude: -90.055462, zipCode: "38103"},
	{latitude: 35.132559, longitude: -90.004289, zipCode: "38104"},
	{latitude: 35.151419, longitude: -90.035468, zipCode: "38105"},
	{latitude: 35.100623, longitude: -90.037407, zipCode: "38106"},
	{latitude: 35.170165, longitude: -90.023442, zipCode: "38107"},
	{latitude: 35.1769, longitude: -89.963974, zipCode: "38108"},
	{latitude: 35.037886, longitude: -90.154242, zipCode: "38109"},
	{latitude: 35.110521, longitude: -89.943501, zipCode: "38111"},
	{latitude: 35.147662, longitude: -89.976392, zipCode: "38112"},
	{latitude: 35.103782, longitude: -90.140824, zipCode: "38113"},
	{latitude: 35.09648, longitude: -89.985957, zipCode: "38114"},
	{latitude: 35.053175, longitude: -89.862635, zipCode: "38115"},
	{latitude: 35.032339, longitude: -90.008916, zipCode: "38116"},
	{latitude: 35.115008, longitude: -89.905517, zipCode: "38117"},
	{latitude: 35.035097, longitude: -89.927158, zipCode: "38118"},
	{latitude: 35.079511, longitude: -89.845385, zipCode: "38119"},
	{latitude: 35.123633, longitude: -89.852645, zipCode: "38120"},
	{latitude: 35.157102, longitude: -89.922444, zipCode: "38122"},
	{latitude: 35.025426, longitude: -89.78934, zipCode: "38125"},
	{latitude: 35.126977, longitude: -90.043696, zipCode: "38126"},
	{latitude: 35.242751, longitude: -90.02679, zipCode: "38127"},
	{latitude: 35.224574, longitude: -89.926689, zipCode: "38128"},
	{latitude: 35.066198, longitude: -89.992631, zipCode: "38131"},
	{latitude: 35.070634, longitude: -90.000393, zipCode: "38132"},
	{latitude: 35.21294, longitude: -89.794712, zipCode: "38133"},
	{latitude: 35.17454, longitude: -89.860777, zipCode: "38134"},
	{latitude: 35.238915, longitude: -89.848589, zipCode: "38135"},
	{latitude: 35.084786, longitude: -89.799272, zipCode: "38138"},
	{latitude: 35.082886, longitude: -89.754836, zipCode: "38139"},
	{latitude: 35.01603, longitude: -89.854765, zipCode: "38141"},
	{latitude: 35.118273, longitude: -89.93745, zipCode: "38152"},
	{latitude: 36.122634, longitude: -88.530194, zipCode: "38201"},
	{latitude: 35.993659, longitude: -88.670883, zipCode: "38220"},
	{latitude: 36.244904, longitude: -88.042202, zipCode: "38221"},
	{latitude: 36.445536, longitude: -88.157903, zipCode: "38222"},
	{latitude: 36.387007, longitude: -88.492179, zipCode: "38224"},
	{latitude: 36.31885, longitude: -88.67074, zipCode: "38225"},
	{latitude: 36.474282, longitude: -88.657034, zipCode: "38226"},
	{latitude: 36.219487, longitude: -88.610129, zipCode: "38229"},
	{latitude: 36.155177, longitude: -88.774665, zipCode: "38230"},
	{latitude: 36.213058, longitude: -88.426137, zipCode: "38231"},
	{latitude: 36.35901, longitude: -89.324181, zipCode: "38232"},
	{latitude: 36.203649, longitude: -89.043102, zipCode: "38233"},
	{latitude: 35.991104, longitude: -88.58126, zipCode: "38235"},
	{latitude: 36.178011, longitude: -88.275798, zipCode: "38236"},
	{latitude: 36.362563, longitude: -88.833542, zipCode: "38237"},
	{latitude: 36.245091, longitude: -89.318119, zipCode: "38240"},
	{latitude: 36.427772, longitude: -88.591844, zipCode: "38241"},
	{latitude: 36.306875, longitude: -88.337468, zipCode: "38242"},
	{latitude: 36.459872, longitude: -88.355856, zipCode: "38251"},
	{latitude: 36.308088, longitude: -89.059119, zipCode: "38253"},
	{latitude: 36.374442, longitude: -89.346612, zipCode: "38254"},
	{latitude: 36.240718, longitude: -88.85913, zipCode: "38255"},
	{latitude: 36.31754, longitude: -88.124676, zipCode: "38256"},
	{latitude: 36.463999, longitude: -88.886965, zipCode: "38257"},
	{latitude: 36.017941, longitude: -88.612596, zipCode: "38258"},
	{latitude: 36.202153, longitude: -89.18405, zipCode: "38259"},
	{latitude: 36.375274, longitude: -89.212672, zipCode: "38260"},
	{latitude: 36.42886, longitude: -89.113506, zipCode: "38261"},
	{latitude: 35.588164, longitude: -88.853395, zipCode: "38301"},
	{latitude: 35.701654, longitude: -88.768058, zipCode: "38305"},
	{latitude: 35.261655, longitude: -88.40859, zipCode: "38310"},
	{latitude: 35.435844, longitude: -88.097796, zipCode: "38311"},
	{latitude: 35.610339, longitude: -88.619827, zipCode: "38313"},
	{latitude: 35.268299, longitude: -88.615671, zipCode: "38315"},
	{latitude: 36.069868, longitude: -88.816869, zipCode: "38316"},
	{latitude: 36.057937, longitude: -88.26381, zipCode: "38317"},
	{latitude: 35.947414, longitude: -88.242002, zipCode: "38318"},
	{latitude: 36.045977, longitude: -88.109864, zipCode: "38320"},
	{latitude: 35.839302, longitude: -88.529314, zipCode: "38321"},
	{latitude: 35.045783, longitude: -88.278279, zipCode: "38326"},
	{latitude: 35.22405, longitude: -88.30206, zipCode: "38327"},
	{latitude: 35.668697, longitude: -88.208588, zipCode: "38328"},
	{latitude: 35.537576, longitude: -88.10779, zipCode: "38329"},
	{latitude: 36.070503, longitude: -89.034414, zipCode: "38330"},
	{latitude: 35.419764, longitude: -88.429352, zipCode: "38332"},
	{latitude: 36.128104, longitude: -87.95446, zipCode: "38333"},
	{latitude: 35.349837, longitude: -88.586353, zipCode: "38334"},
	{latitude: 35.778321, longitude: -89.028763, zipCode: "38337"},
	{latitude: 35.874646, longitude: -88.84878, zipCode: "38338"},
	{latitude: 35.052376, longitude: -88.514705, zipCode: "38339"},
	{latitude: 35.404805, longitude: -88.679411, zipCode: "38340"},
	{latitude: 35.877577, longitude: -88.083895, zipCode: "38341"},
	{latitude: 36.07174, longitude: -88.288871, zipCode: "38342"},
	{latitude: 35.82696, longitude: -88.928388, zipCode: "38343"},
	{latitude: 35.978284, longitude: -88.434633, zipCode: "38344"},
	{latitude: 35.595297, longitude: -88.507532, zipCode: "38345"},
	{latitude: 35.476299, longitude: -88.498514, zipCode: "38347"},
	{latitude: 35.872744, longitude: -88.650963, zipCode: "38348"},
	{latitude: 35.661918, longitude: -88.40479, zipCode: "38351"},
	{latitude: 35.529461, longitude: -88.533849, zipCode: "38352"},
	{latitude: 35.784009, longitude: -88.775061, zipCode: "38355"},
	{latitude: 35.438397, longitude: -88.893792, zipCode: "38356"},
	{latitude: 35.051488, longitude: -88.413921, zipCode: "38357"},
	{latitude: 35.916675, longitude: -88.758664, zipCode: "38358"},
	{latitude: 35.372232, longitude: -88.351119, zipCode: "38359"},
	{latitude: 35.311301, longitude: -88.299924, zipCode: "38361"},
	{latitude: 35.72694, longitude: -88.785157, zipCode: "38362"},
	{latitude: 35.687839, longitude: -88.110728, zipCode: "38363"},
	{latitude: 35.057656, longitude: -88.237619, zipCode: "38365"},
	{latitude: 35.479, longitude: -88.741273, zipCode: "38366"},
	{latitude: 35.053048, longitude: -88.623242, zipCode: "38367"},
	{latitude: 35.496063, longitude: -88.358896, zipCode: "38368"},
	{latitude: 36.131922, longitude: -88.942666, zipCode: "38369"},
	{latitude: 35.391477, longitude: -88.247962, zipCode: "38370"},
	{latitude: 35.423968, longitude: -88.295293, zipCode: "38371"},
	{latitude: 35.143564, longitude: -88.156874, zipCode: "38372"},
	{latitude: 35.52377, longitude: -88.238816, zipCode: "38374"},
	{latitude: 35.159956, longitude: -88.587028, zipCode: "38375"},
	{latitude: 35.130665, longitude: -88.347373, zipCode: "38376"},
	{latitude: 35.164694, longitude: -88.430564, zipCode: "38379"},
	{latitude: 35.779631, longitude: -88.039214, zipCode: "38380"},
	{latitude: 35.349277, longitude: -88.959499, zipCode: "38381"},
	{latitude: 35.968379, longitude: -88.984461, zipCode: "38382"},
	{latitude: 35.87292, longitude: -88.277462, zipCode: "38387"},
	{latitude: 35.781609, longitude: -88.309654, zipCode: "38388"},
	{latitude: 36.103118, longitude: -89.121406, zipCode: "38389"},
	{latitude: 35.838457, longitude: -88.377949, zipCode: "38390"},
	{latitude: 35.546571, longitude: -88.992177, zipCode: "38391"},
	{latitude: 35.457967, longitude: -89.028052, zipCode: "38392"},
	{latitude: 35.629164, longitude: -87.021526, zipCode: "38401"},
	{latitude: 35.416033, longitude: -87.969678, zipCode: "38425"},
	{latitude: 35.043063, longitude: -86.831074, zipCode: "38449"},
	{latitude: 35.203881, longitude: -87.669227, zipCode: "38450"},
	{latitude: 35.460534, longitude: -86.992239, zipCode: "38451"},
	{latitude: 35.078891, longitude: -87.792396, zipCode: "38452"},
	{latitude: 35.119204, longitude: -86.814744, zipCode: "38453"},
	{latitude: 35.728171, longitude: -87.345316, zipCode: "38454"},
	{latitude: 35.061981, longitude: -86.893642, zipCode: "38455"},
	{latitude: 35.33467, longitude: -87.265611, zipCode: "38456"},
	{latitude: 35.029667, longitude: -87.28484, zipCode: "38457"},
	{latitude: 35.191334, longitude: -86.802196, zipCode: "38459"},
	{latitude: 35.101157, longitude: -87.159372, zipCode: "38460"},
	{latitude: 35.595141, longitude: -87.339879, zipCode: "38461"},
	{latitude: 35.52745, longitude: -87.543456, zipCode: "38462"},
	{latitude: 35.070595, longitude: -87.645115, zipCode: "38463"},
	{latitude: 35.289918, longitude: -87.433746, zipCode: "38464"},
	{latitude: 35.135146, longitude: -87.291063, zipCode: "38468"},
	{latitude: 35.074631, longitude: -87.428316, zipCode: "38469"},
	{latitude: 35.083901, longitude: -87.932397, zipCode: "38471"},
	{latitude: 35.377324, longitude: -87.038742, zipCode: "38472"},
	{latitude: 35.021246, longitude: -87.171674, zipCode: "38473"},
	{latitude: 35.518299, longitude: -87.231551, zipCode: "38474"},
	{latitude: 35.262384, longitude: -88.023391, zipCode: "38475"},
	{latitude: 35.843364, longitude: -87.209904, zipCode: "38476"},
	{latitude: 35.059762, longitude: -87.018871, zipCode: "38477"},
	{latitude: 35.232879, longitude: -87.005872, zipCode: "38478"},
	{latitude: 35.028051, longitude: -87.488656, zipCode: "38481"},
	{latitude: 35.768825, longitude: -87.149896, zipCode: "38482"},
	{latitude: 35.440765, longitude: -87.333659, zipCode: "38483"},
	{latitude: 35.356672, longitude: -87.782248, zipCode: "38485"},
	{latitude: 35.173101, longitude: -87.540249, zipCode: "38486"},
	{latitude: 35.718387, longitude: -87.23346, zipCode: "38487"},
	{latitude: 35.028524, longitude: -86.686496, zipCode: "38488"},
	{latitude: 36.226045, longitude: -85.533285, zipCode: "38501"},
	{latitude: 36.16318, longitude: -85.505716, zipCode: "38503"},
	{latitude: 36.393141, longitude: -84.735299, zipCode: "38504"},
	{latitude: 36.17318, longitude: -85.505637, zipCode: "38505"},
	{latitude: 36.187036, longitude: -85.433518, zipCode: "38506"},
	{latitude: 36.538018, longitude: -85.335604, zipCode: "38541"},
	{latitude: 36.31578, longitude: -85.20116, zipCode: "38542"},
	{latitude: 36.350088, longitude: -85.150003, zipCode: "38543"},
	{latitude: 36.109883, longitude: -85.652321, zipCode: "38544"},
	{latitude: 36.231332, longitude: -85.659651, zipCode: "38545"},
	{latitude: 36.154655, longitude: -86.00947, zipCode: "38547"},
	{latitude: 36.174831, longitude: -85.791953, zipCode: "38548"},
	{latitude: 36.562107, longitude: -85.149059, zipCode: "38549"},
	{latitude: 35.7698, longitude: -85.632721, zipCode: "38550"},
	{latitude: 36.55805, longitude: -85.487178, zipCode: "38551"},
	{latitude: 36.217685, longitude: -85.813769, zipCode: "38552"},
	{latitude: 36.203218, longitude: -85.004427, zipCode: "38553"},
	{latitude: 36.251325, longitude: -85.160968, zipCode: "38554"},
	{latitude: 35.887881, longitude: -84.989016, zipCode: "38555"},
	{latitude: 36.40758, longitude: -84.927605, zipCode: "38556"},
	{latitude: 36.009851, longitude: -84.866557, zipCode: "38558"},
	{latitude: 35.808437, longitude: -85.508014, zipCode: "38559"},
	{latitude: 36.221926, longitude: -85.855639, zipCode: "38560"},
	{latitude: 36.351641, longitude: -85.659335, zipCode: "38562"},
	{latitude: 36.208803, longitude: -86.020716, zipCode: "38563"},
	{latitude: 36.256912, longitude: -85.754534, zipCode: "38564"},
	{latitude: 36.262955, longitude: -85.009854, zipCode: "38565"},
	{latitude: 36.132827, longitude: -85.925669, zipCode: "38567"},
	{latitude: 36.438492, longitude: -85.473057, zipCode: "38568"},
	{latitude: 36.118716, longitude: -85.859247, zipCode: "38569"},
	{latitude: 36.376292, longitude: -85.326413, zipCode: "38570"},
	{latitude: 36.051604, longitude: -85.012804, zipCode: "38571"},
	{latitude: 35.849059, longitude: -85.12896, zipCode: "38572"},
	{latitude: 36.493416, longitude: -85.223882, zipCode: "38573"},
	{latitude: 36.143643, longitude: -85.229288, zipCode: "38574"},
	{latitude: 36.553225, longitude: -85.629502, zipCode: "38575"},
	{latitude: 36.562156, longitude: -84.953088, zipCode: "38577"},
	{latitude: 35.980308, longitude: -85.196306, zipCode: "38578"},
	{latitude: 35.835867, longitude: -85.548177, zipCode: "38579"},
	{latitude: 36.277282, longitude: -85.295259, zipCode: "38580"},
	{latitude: 35.74104, longitude: -85.620827, zipCode: "38581"},
	{latitude: 36.099444, longitude: -85.764549, zipCode: "38582"},
	{latitude: 35.953136, longitude: -85.430418, zipCode: "38583"},
	{latitude: 35.688126, longitude: -85.414797, zipCode: "38585"},
	{latitude: 35.864761, longitude: -85.613998, zipCode: "38587"},
	{latitude: 36.446386, longitude: -85.730671, zipCode: "38588"},
	{latitude: 36.28549, longitude: -85.076214, zipCode: "38589"},
	{latitude: 34.478541, longitude: -89.451271, zipCode: "38601"},
	{latitude: 34.84841, longitude: -89.14226, zipCode: "38603"},
	{latitude: 34.303285, longitude: -89.95225, zipCode: "38606"},
	{latitude: 34.651787, longitude: -89.025549, zipCode: "38610"},
	{latitude: 34.856233, longitude: -89.67602, zipCode: "38611"},
	{latitude: 34.159567, longitude: -90.608353, zipCode: "38614"},
	{latitude: 34.36167, longitude: -90.490578, zipCode: "38617"},
	{latitude: 34.716282, longitude: -89.937848, zipCode: "38618"},
	{latitude: 34.51528, longitude: -89.823801, zipCode: "38619"},
	{latitude: 34.236735, longitude: -89.916136, zipCode: "38620"},
	{latitude: 34.447192, longitude: -90.170459, zipCode: "38621"},
	{latitude: 34.169492, longitude: -90.134577, zipCode: "38622"},
	{latitude: 34.354003, longitude: -90.309813, zipCode: "38623"},
	{latitude: 34.614317, longitude: -88.835904, zipCode: "38625"},
	{latitude: 34.51998, longitude: -90.433941, zipCode: "38626"},
	{latitude: 34.454558, longitude: -89.23804, zipCode: "38627"},
	{latitude: 34.868465, longitude: -88.990462, zipCode: "38629"},
	{latitude: 34.361, longitude: -90.625543, zipCode: "38631"},
	{latitude: 34.795914, longitude: -90.015068, zipCode: "38632"},
	{latitude: 34.647337, longitude: -89.194971, zipCode: "38633"},
	{latitude: 34.766919, longitude: -89.48633, zipCode: "38635"},
	{latitude: 34.950824, longitude: -90.048695, zipCode: "38637"},
	{latitude: 34.309083, longitude: -90.428534, zipCode: "38639"},
	{latitude: 34.91122, longitude: -90.169506, zipCode: "38641"},
	{latitude: 34.911338, longitude: -89.34139, zipCode: "38642"},
	{latitude: 34.148487, longitude: -90.260865, zipCode: "38643"},
	{latitude: 34.45889, longitude: -90.475777, zipCode: "38644"},
	{latitude: 34.247827, longitude: -90.47638, zipCode: "38645"},
	{latitude: 34.287169, longitude: -90.298573, zipCode: "38646"},
	{latitude: 34.963506, longitude: -89.224108, zipCode: "38647"},
	{latitude: 34.532061, longitude: -89.142466, zipCode: "38650"},
	{latitude: 34.893416, longitude: -90.002219, zipCode: "38651"},
	{latitude: 34.486088, longitude: -88.996739, zipCode: "38652"},
	{latitude: 34.933929, longitude: -89.81628, zipCode: "38654"},
	{latitude: 34.329772, longitude: -89.487118, zipCode: "38655"},
	{latitude: 34.188341, longitude: -89.900997, zipCode: "38658"},
	{latitude: 34.621505, longitude: -89.331511, zipCode: "38659"},
	{latitude: 34.882735, longitude: -89.556577, zipCode: "38661"},
	{latitude: 34.738947, longitude: -88.902893, zipCode: "38663"},
	{latitude: 34.811462, longitude: -90.329253, zipCode: "38664"},
	{latitude: 34.583273, longitude: -90.166012, zipCode: "38665"},
	{latitude: 34.418127, longitude: -89.928772, zipCode: "38666"},
	{latitude: 34.590668, longitude: -89.917818, zipCode: "38668"},
	{latitude: 34.433025, longitude: -90.28595, zipCode: "38670"},
	{latitude: 34.96626, longitude: -89.998152, zipCode: "38671"},
	{latitude: 34.9114, longitude: -89.927894, zipCode: "38672"},
	{latitude: 34.293443, longitude: -89.635806, zipCode: "38673"},
	{latitude: 34.86837, longitude: -88.892398, zipCode: "38674"},
	{latitude: 34.674208, longitude: -90.366823, zipCode: "38676"},
	{latitude: 34.361968, longitude: -89.538578, zipCode: "38677"},
	{latitude: 34.957753, longitude: -90.145108, zipCode: "38680"},
	{latitude: 34.935325, longitude: -88.854938, zipCode: "38683"},
	{latitude: 34.593072, longitude: -89.488486, zipCode: "38685"},
	{latitude: 33.302578, longitude: -91.028375, zipCode: "38701"},
	{latitude: 33.475662, longitude: -91.052834, zipCode: "38703"},
	{latitude: 34.108397, longitude: -90.788009, zipCode: "38720"},
	{latitude: 32.970368, longitude: -90.752127, zipCode: "38721"},
	{latitude: 33.260304, longitude: -90.883939, zipCode: "38722"},
	{latitude: 33.229676, longitude: -91.038462, zipCode: "38723"},
	{latitude: 33.635451, longitude: -91.060082, zipCode: "38725"},
	{latitude: 33.753407, longitude: -90.977334, zipCode: "38726"},
	{latitude: 33.680878, longitude: -90.804903, zipCode: "38730"},
	{latitude: 33.123508, longitude: -91.130809, zipCode: "38731"},
	{latitude: 33.758855, longitude: -90.725595, zipCode: "38732"},
	{latitude: 33.744626, longitude: -90.735133, zipCode: "38733"},
	{latitude: 33.647759, longitude: -90.543171, zipCode: "38736"},
	{latitude: 33.861002, longitude: -90.49643, zipCode: "38737"},
	{latitude: 33.93292, longitude: -90.534414, zipCode: "38738"},
	{latitude: 34.049925, longitude: -90.805229, zipCode: "38740"},
	{latitude: 33.029689, longitude: -91.051318, zipCode: "38744"},
	{latitude: 32.974211, longitude: -91.039242, zipCode: "38745"},
	{latitude: 33.961591, longitude: -90.954433, zipCode: "38746"},
	{latitude: 33.155088, longitude: -90.875976, zipCode: "38748"},
	{latitude: 33.453305, longitude: -90.674979, zipCode: "38751"},
	{latitude: 33.349405, longitude: -90.584439, zipCode: "38753"},
	{latitude: 33.249944, longitude: -90.622535, zipCode: "38754"},
	{latitude: 33.389575, longitude: -90.86025, zipCode: "38756"},
	{latitude: 33.829126, longitude: -90.737388, zipCode: "38759"},
	{latitude: 33.453544, longitude: -90.993986, zipCode: "38760"},
	{latitude: 33.429275, longitude: -90.469962, zipCode: "38761"},
	{latitude: 33.896439, longitude: -90.725653, zipCode: "38762"},
	{latitude: 33.799139, longitude: -90.88412, zipCode: "38764"},
	{latitude: 33.049293, longitude: -90.889553, zipCode: "38765"},
	{latitude: 34.128071, longitude: -90.805499, zipCode: "38767"},
	{latitude: 33.970883, longitude: -90.477488, zipCode: "38768"},
	{latitude: 33.84241, longitude: -90.978141, zipCode: "38769"},
	{latitude: 33.739586, longitude: -90.523058, zipCode: "38771"},
	{latitude: 33.562525, longitude: -91.087659, zipCode: "38772"},
	{latitude: 33.604991, longitude: -90.773389, zipCode: "38773"},
	{latitude: 33.965722, longitude: -90.767497, zipCode: "38774"},
	{latitude: 33.424672, longitude: -90.927454, zipCode: "38776"},
	{latitude: 33.55399, longitude: -90.514164, zipCode: "38778"},
	{latitude: 33.908716, longitude: -90.756821, zipCode: "38781"},
	{latitude: 34.222629, longitude: -88.774588, zipCode: "38801"},
	{latitude: 34.281378, longitude: -88.672873, zipCode: "38804"},
	{latitude: 33.973786, longitude: -88.437683, zipCode: "38821"},
	{latitude: 34.519299, longitude: -88.641004, zipCode: "38824"},
	{latitude: 34.302823, longitude: -88.848271, zipCode: "38826"},
	{latitude: 34.511227, longitude: -88.242369, zipCode: "38827"},
	{latitude: 34.423833, longitude: -88.864862, zipCode: "38828"},
	{latitude: 34.652493, longitude: -88.536769, zipCode: "38829"},
	{latitude: 34.833917, longitude: -88.342912, zipCode: "38833"},
	{latitude: 34.917816, longitude: -88.559666, zipCode: "38834"},
	{latitude: 34.546433, longitude: -88.255741, zipCode: "38838"},
	{latitude: 33.855572, longitude: -89.284062, zipCode: "38839"},
	{latitude: 34.348077, longitude: -89.021483, zipCode: "38841"},
	{latitude: 34.262054, longitude: -88.376344, zipCode: "38843"},
	{latitude: 33.83904, longitude: -88.255872, zipCode: "38844"},
	{latitude: 34.85172, longitude: -88.385389, zipCode: "38846"},
	{latitude: 34.41254, longitude: -88.23296, zipCode: "38847"},
	{latitude: 33.936684, longitude: -88.282673, zipCode: "38848"},
	{latitude: 34.460957, longitude: -88.696049, zipCode: "38849"},
	{latitude: 34.058891, longitude: -89.057937, zipCode: "38850"},
	{latitude: 33.910353, longitude: -88.967727, zipCode: "38851"},
	{latitude: 34.844249, longitude: -88.216912, zipCode: "38852"},
	{latitude: 34.323342, longitude: -88.485408, zipCode: "38855"},
	{latitude: 34.467115, longitude: -88.455149, zipCode: "38856"},
	{latitude: 34.281521, longitude: -88.574989, zipCode: "38857"},
	{latitude: 34.095829, longitude: -88.531015, zipCode: "38858"},
	{latitude: 34.515197, longitude: -88.368607, zipCode: "38859"},
	{latitude: 33.976696, longitude: -88.759696, zipCode: "38860"},
	{latitude: 34.168139, longitude: -88.623239, zipCode: "38862"},
	{latitude: 34.217021, longitude: -89.027469, zipCode: "38863"},
	{latitude: 34.157795, longitude: -89.207824, zipCode: "38864"},
	{latitude: 34.788031, longitude: -88.595406, zipCode: "38865"},
	{latitude: 34.36762, longitude: -88.680078, zipCode: "38866"},
	{latitude: 34.12565, longitude: -88.742951, zipCode: "38868"},
	{latitude: 34.356295, longitude: -88.83632, zipCode: "38869"},
	{latitude: 34.070018, longitude: -88.339264, zipCode: "38870"},
	{latitude: 34.33457, longitude: -89.206816, zipCode: "38871"},
	{latitude: 34.66487, longitude: -88.251348, zipCode: "38873"},
	{latitude: 34.21359, longitude: -88.230292, zipCode: "38876"},
	{latitude: 33.880346, longitude: -89.190093, zipCode: "38878"},
	{latitude: 34.195185, longitude: -88.712124, zipCode: "38879"},
	{latitude: 33.761689, longitude: -89.799115, zipCode: "38901"},
	{latitude: 34.138881, longitude: -89.384872, zipCode: "38913"},
	{latitude: 33.871651, longitude: -89.47047, zipCode: "38914"},
	{latitude: 34.03764, longitude: -89.388855, zipCode: "38915"},
	{latitude: 33.829912, longitude: -89.351138, zipCode: "38916"},
	{latitude: 33.538822, longitude: -89.981966, zipCode: "38917"},
	{latitude: 33.879609, longitude: -90.037111, zipCode: "38920"},
	{latitude: 33.982149, longitude: -90.144184, zipCode: "38921"},
	{latitude: 33.932659, longitude: -89.6509, zipCode: "38922"},
	{latitude: 33.377769, longitude: -89.992074, zipCode: "38923"},
	{latitude: 33.289691, longitude: -90.215676, zipCode: "38924"},
	{latitude: 33.630282, longitude: -89.618211, zipCode: "38925"},
	{latitude: 34.142879, longitude: -90.026843, zipCode: "38927"},
	{latitude: 33.869471, longitude: -90.291899, zipCode: "38928"},
	{latitude: 33.738853, longitude: -89.523096, zipCode: "38929"},
	{latitude: 33.527674, longitude: -90.138656, zipCode: "38930"},
	{latitude: 33.74686, longitude: -90.026036, zipCode: "38940"},
	{latitude: 33.484573, longitude: -90.358231, zipCode: "38941"},
	{latitude: 33.533304, longitude: -89.839447, zipCode: "38943"},
	{latitude: 33.765008, longitude: -90.341539, zipCode: "38944"},
	{latitude: 33.377039, longitude: -90.355874, zipCode: "38946"},
	{latitude: 33.517185, longitude: -89.921182, zipCode: "38947"},
	{latitude: 34.076148, longitude: -89.894126, zipCode: "38948"},
	{latitude: 34.179756, longitude: -89.428097, zipCode: "38949"},
	{latitude: 33.733058, longitude: -90.200045, zipCode: "38950"},
	{latitude: 33.973783, longitude: -89.29049, zipCode: "38951"},
	{latitude: 33.616802, longitude: -90.333902, zipCode: "38952"},
	{latitude: 33.919298, longitude: -89.932198, zipCode: "38953"},
	{latitude: 33.407969, longitude: -90.219581, zipCode: "38954"},
	{latitude: 33.987487, longitude: -90.372958, zipCode: "38957"},
	{latitude: 33.962265, longitude: -89.884233, zipCode: "38961"},
	{latitude: 33.872154, longitude: -90.185241, zipCode: "38962"},
	{latitude: 34.049355, longitude: -90.313518, zipCode: "38963"},
	{latitude: 34.112894, longitude: -90.387903, zipCode: "38964"},
	{latitude: 34.134989, longitude: -89.590925, zipCode: "38965"},
	{latitude: 33.920072, longitude: -90.317632, zipCode: "38966"},
	{latitude: 33.459525, longitude: -89.718167, zipCode: "38967"},
	{latitude: 33.201606, longitude: -90.474455, zipCode: "39038"},
	{latitude: 32.816556, longitude: -90.22886, zipCode: "39039"},
	{latitude: 32.629706, longitude: -90.417476, zipCode: "39040"},
	{latitude: 32.404769, longitude: -90.468863, zipCode: "39041"},
	{latitude: 32.197671, longitude: -89.894896, zipCode: "39042"},
	{latitude: 32.027132, longitude: -89.971611, zipCode: "39044"},
	{latitude: 32.819535, longitude: -89.784552, zipCode: "39045"},
	{latitude: 32.629563, longitude: -89.993488, zipCode: "39046"},
	{latitude: 32.452378, longitude: -89.949364, zipCode: "39047"},
	{latitude: 32.780342, longitude: -89.513458, zipCode: "39051"},
	{latitude: 32.798549, longitude: -90.935253, zipCode: "39054"},
	{latitude: 32.373552, longitude: -90.350592, zipCode: "39056"},
	{latitude: 32.490854, longitude: -89.284427, zipCode: "39057"},
	{latitude: 32.335712, longitude: -90.331549, zipCode: "39058"},
	{latitude: 31.982611, longitude: -90.350491, zipCode: "39059"},
	{latitude: 33.088405, longitude: -90.804386, zipCode: "39061"},
	{latitude: 31.984861, longitude: -89.898316, zipCode: "39062"},
	{latitude: 33.116674, longitude: -89.897489, zipCode: "39063"},
	{latitude: 32.296627, longitude: -90.606153, zipCode: "39066"},
	{latitude: 33.114946, longitude: -89.450465, zipCode: "39067"},
	{latitude: 31.68616, longitude: -91.043249, zipCode: "39069"},
	{latitude: 32.546094, longitude: -90.319187, zipCode: "39071"},
	{latitude: 32.102431, longitude: -90.135817, zipCode: "39073"},
	{latitude: 32.385998, longitude: -89.467337, zipCode: "39074"},
	{latitude: 31.878048, longitude: -90.188204, zipCode: "39078"},
	{latitude: 32.930988, longitude: -89.88886, zipCode: "39079"},
	{latitude: 31.941844, longitude: -90.120075, zipCode: "39082"},
	{latitude: 31.835325, longitude: -90.467283, zipCode: "39083"},
	{latitude: 31.962556, longitude: -90.763661, zipCode: "39086"},
	{latitude: 32.740748, longitude: -90.746856, zipCode: "39088"},
	{latitude: 33.012936, longitude: -89.543606, zipCode: "39090"},
	{latitude: 32.319465, longitude: -89.363441, zipCode: "39092"},
	{latitude: 32.575409, longitude: -89.675262, zipCode: "39094"},
	{latitude: 33.13191, longitude: -90.117243, zipCode: "39095"},
	{latitude: 31.817628, longitude: -91.120695, zipCode: "39096"},
	{latitude: 32.989067, longitude: -90.607666, zipCode: "39097"},
	{latitude: 33.162117, longitude: -89.327734, zipCode: "39108"},
	{latitude: 32.505332, longitude: -90.137593, zipCode: "39110"},
	{latitude: 31.896635, longitude: -89.718092, zipCode: "39111"},
	{latitude: 32.761006, longitude: -91.117861, zipCode: "39113"},
	{latitude: 31.951836, longitude: -89.824587, zipCode: "39114"},
	{latitude: 33.047869, longitude: -90.560389, zipCode: "39115"},
	{latitude: 31.896441, longitude: -89.538254, zipCode: "39116"},
	{latitude: 32.309634, longitude: -89.664992, zipCode: "39117"},
	{latitude: 31.751549, longitude: -89.670777, zipCode: "39119"},
	{latitude: 31.508801, longitude: -91.357371, zipCode: "39120"},
	{latitude: 31.749249, longitude: -90.020207, zipCode: "39140"},
	{latitude: 31.821213, longitude: -90.792161, zipCode: "39144"},
	{latitude: 32.344663, longitude: -89.794359, zipCode: "39145"},
	{latitude: 32.875897, longitude: -89.99491, zipCode: "39146"},
	{latitude: 32.061734, longitude: -89.995248, zipCode: "39148"},
	{latitude: 31.831965, longitude: -90.010649, zipCode: "39149"},
	{latitude: 31.987233, longitude: -90.985084, zipCode: "39150"},
	{latitude: 32.207475, longitude: -89.585474, zipCode: "39152"},
	{latitude: 32.045133, longitude: -89.490046, zipCode: "39153"},
	{latitude: 32.225111, longitude: -90.444671, zipCode: "39154"},
	{latitude: 32.546541, longitude: -90.77759, zipCode: "39156"},
	{latitude: 32.425376, longitude: -90.165592, zipCode: "39157"},
	{latitude: 32.822724, longitude: -90.911519, zipCode: "39159"},
	{latitude: 33.003124, longitude: -89.74716, zipCode: "39160"},
	{latitude: 32.618799, longitude: -90.624417, zipCode: "39162"},
	{latitude: 33.055718, longitude: -90.481544, zipCode: "39166"},
	{latitude: 32.09309, longitude: -90.040036, zipCode: "39167"},
	{latitude: 31.831878, longitude: -89.411823, zipCode: "39168"},
	{latitude: 33.215516, longitude: -90.245118, zipCode: "39169"},
	{latitude: 32.11177, longitude: -90.330653, zipCode: "39170"},
	{latitude: 32.742416, longitude: -90.472172, zipCode: "39173"},
	{latitude: 32.401019, longitude: -90.160257, zipCode: "39174"},
	{latitude: 32.089225, longitude: -90.622579, zipCode: "39175"},
	{latitude: 33.299719, longitude: -89.752909, zipCode: "39176"},
	{latitude: 32.606211, longitude: -90.823228, zipCode: "39177"},
	{latitude: 32.80244, longitude: -90.096935, zipCode: "39179"},
	{latitude: 32.23866, longitude: -90.893728, zipCode: "39180"},
	{latitude: 32.462618, longitude: -90.823208, zipCode: "39183"},
	{latitude: 32.608003, longitude: -89.400149, zipCode: "39189"},
	{latitude: 31.715655, longitude: -90.408869, zipCode: "39191"},
	{latitude: 33.194769, longitude: -89.746172, zipCode: "39192"},
	{latitude: 32.243461, longitude: -90.069892, zipCode: "39193"},
	{latitude: 32.842991, longitude: -90.515099, zipCode: "39194"},
	{latitude: 32.289606, longitude: -90.184324, zipCode: "39201"},
	{latitude: 32.311054, longitude: -90.16979, zipCode: "39202"},
	{latitude: 32.309223, longitude: -90.20038, zipCode: "39203"},
	{latitude: 32.283134, longitude: -90.236896, zipCode: "39204"},
	{latitude: 32.37234, longitude: -90.172688, zipCode: "39206"},
	{latitude: 32.25973, longitude: -90.091888, zipCode: "39208"},
	{latitude: 32.395519, longitude: -90.293173, zipCode: "39209"},
	{latitude: 32.32248, longitude: -90.179991, zipCode: "39210"},
	{latitude: 32.372138, longitude: -90.122743, zipCode: "39211"},
	{latitude: 32.246199, longitude: -90.259645, zipCode: "39212"},
	{latitude: 32.394736, longitude: -90.22496, zipCode: "39213"},
	{latitude: 32.335241, longitude: -90.161417, zipCode: "39216"},
	{latitude: 32.297555, longitude: -90.209639, zipCode: "39217"},
	{latitude: 32.226071, longitude: -90.156494, zipCode: "39218"},
	{latitude: 32.336997, longitude: -90.085883, zipCode: "39232"},
	{latitude: 32.30114, longitude: -90.188903, zipCode: "39269"},
	{latitude: 32.188422, longitude: -90.262445, zipCode: "39272"},
	{latitude: 32.276115, longitude: -88.592851, zipCode: "39301"},
	{latitude: 32.457923, longitude: -88.727353, zipCode: "39305"},
	{latitude: 32.335033, longitude: -88.789959, zipCode: "39307"},
	{latitude: 32.552243, longitude: -88.55089, zipCode: "39309"},
	{latitude: 32.564451, longitude: -88.748337, zipCode: "39320"},
	{latitude: 31.535554, longitude: -88.503823, zipCode: "39322"},
	{latitude: 32.352256, longitude: -88.923758, zipCode: "39323"},
	{latitude: 31.580375, longitude: -88.697438, zipCode: "39324"},
	{latitude: 32.561981, longitude: -88.877459, zipCode: "39325"},
	{latitude: 32.602744, longitude: -88.656165, zipCode: "39326"},
	{latitude: 32.445808, longitude: -89.122279, zipCode: "39327"},
	{latitude: 32.743509, longitude: -88.698611, zipCode: "39328"},
	{latitude: 32.189753, longitude: -88.857568, zipCode: "39330"},
	{latitude: 32.3043, longitude: -88.998857, zipCode: "39332"},
	{latitude: 32.533136, longitude: -88.49109, zipCode: "39335"},
	{latitude: 32.300601, longitude: -89.2622, zipCode: "39336"},
	{latitude: 32.51847, longitude: -88.996283, zipCode: "39337"},
	{latitude: 32.106743, longitude: -89.230936, zipCode: "39338"},
	{latitude: 33.111225, longitude: -89.008793, zipCode: "39339"},
	{latitude: 33.088581, longitude: -88.556133, zipCode: "39341"},
	{latitude: 32.424999, longitude: -88.64987, zipCode: "39342"},
	{latitude: 32.282232, longitude: -89.122113, zipCode: "39345"},
	{latitude: 32.965035, longitude: -89.095535, zipCode: "39346"},
	{latitude: 32.060443, longitude: -88.942004, zipCode: "39347"},
	{latitude: 31.990133, longitude: -89.041426, zipCode: "39348"},
	{latitude: 32.796469, longitude: -89.125241, zipCode: "39350"},
	{latitude: 32.674323, longitude: -88.481726, zipCode: "39352"},
	{latitude: 32.875326, longitude: -88.840419, zipCode: "39354"},
	{latitude: 32.05882, longitude: -88.61903, zipCode: "39355"},
	{latitude: 32.13298, longitude: -89.036385, zipCode: "39356"},
	{latitude: 32.831033, longitude: -88.453711, zipCode: "39358"},
	{latitude: 32.568531, longitude: -89.33207, zipCode: "39359"},
	{latitude: 31.864814, longitude: -88.754017, zipCode: "39360"},
	{latitude: 32.995472, longitude: -88.596378, zipCode: "39361"},
	{latitude: 31.39096, longitude: -88.541129, zipCode: "39362"},
	{latitude: 32.097188, longitude: -88.781868, zipCode: "39363"},
	{latitude: 32.405281, longitude: -88.486617, zipCode: "39364"},
	{latitude: 32.608635, longitude: -89.120184, zipCode: "39365"},
	{latitude: 31.941109, longitude: -88.900216, zipCode: "39366"},
	{latitude: 31.684373, longitude: -88.668296, zipCode: "39367"},
	{latitude: 31.234112, longitude: -89.265885, zipCode: "39401"},
	{latitude: 31.333168, longitude: -89.414072, zipCode: "39402"},
	{latitude: 31.328937, longitude: -89.335063, zipCode: "39406"},
	{latitude: 31.492362, longitude: -89.721243, zipCode: "39421"},
	{latitude: 31.955709, longitude: -89.265911, zipCode: "39422"},
	{latitude: 31.177551, longitude: -88.911995, zipCode: "39423"},
	{latitude: 31.069402, longitude: -89.060504, zipCode: "39425"},
	{latitude: 30.655773, longitude: -89.656862, zipCode: "39426"},
	{latitude: 31.489296, longitude: -89.82901, zipCode: "39427"},
	{latitude: 31.651051, longitude: -89.572839, zipCode: "39428"},
	{latitude: 31.24203, longitude: -89.779433, zipCode: "39429"},
	{latitude: 31.579926, longitude: -89.218571, zipCode: "39437"},
	{latitude: 31.863128, longitude: -89.001401, zipCode: "39439"},
	{latitude: 31.693183, longitude: -89.148281, zipCode: "39440"},
	{latitude: 31.716721, longitude: -89.078154, zipCode: "39443"},
	{latitude: 31.166512, longitude: -88.623419, zipCode: "39451"},
	{latitude: 30.886863, longitude: -88.592987, zipCode: "39452"},
	{latitude: 31.011564, longitude: -89.470558, zipCode: "39455"},
	{latitude: 31.030633, longitude: -88.827676, zipCode: "39456"},
	{latitude: 31.489786, longitude: -89.304575, zipCode: "39459"},
	{latitude: 31.20579, longitude: -88.732706, zipCode: "39461"},
	{latitude: 31.234012, longitude: -89.056621, zipCode: "39462"},
	{latitude: 31.492814, longitude: -89.061626, zipCode: "39464"},
	{latitude: 31.339572, longitude: -89.18817, zipCode: "39465"},
	{latitude: 30.53472, longitude: -89.617988, zipCode: "39466"},
	{latitude: 30.822939, longitude: -89.568872, zipCode: "39470"},
	{latitude: 31.621243, longitude: -89.854275, zipCode: "39474"},
	{latitude: 31.155087, longitude: -89.413507, zipCode: "39475"},
	{latitude: 31.386969, longitude: -88.866929, zipCode: "39476"},
	{latitude: 31.795734, longitude: -89.038321, zipCode: "39477"},
	{latitude: 31.054177, longitude: -89.85681, zipCode: "39478"},
	{latitude: 31.542107, longitude: -89.46421, zipCode: "39479"},
	{latitude: 31.704964, longitude: -89.316216, zipCode: "39480"},
	{latitude: 31.868389, longitude: -89.238201, zipCode: "39481"},
	{latitude: 31.372022, longitude: -89.579154, zipCode: "39482"},
	{latitude: 31.242774, longitude: -89.932348, zipCode: "39483"},
	{latitude: 30.387191, longitude: -89.099244, zipCode: "39501"},
	{latitude: 30.472894, longitude: -89.143324, zipCode: "39503"},
	{latitude: 30.400484, longitude: -89.034466, zipCode: "39507"},
	{latitude: 30.268896, longitude: -89.480787, zipCode: "39520"},
	{latitude: 30.381932, longitude: -89.370784, zipCode: "39525"},
	{latitude: 30.378163, longitude: -89.600625, zipCode: "39529"},
	{latitude: 30.401521, longitude: -88.89171, zipCode: "39530"},
	{latitude: 30.403994, longitude: -88.966133, zipCode: "39531"},
	{latitude: 30.501031, longitude: -88.975603, zipCode: "39532"},
	{latitude: 30.408572, longitude: -88.921273, zipCode: "39534"},
	{latitude: 30.442815, longitude: -88.897306, zipCode: "39540"},
	{latitude: 30.415444, longitude: -88.645744, zipCode: "39553"},
	{latitude: 30.441244, longitude: -89.427883, zipCode: "39556"},
	{latitude: 30.374244, longitude: -89.170776, zipCode: "39560"},
	{latitude: 30.707671, longitude: -89.154823, zipCode: "39561"},
	{latitude: 30.546682, longitude: -88.489452, zipCode: "39562"},
	{latitude: 30.425914, longitude: -88.532733, zipCode: "39563"},
	{latitude: 30.40477, longitude: -88.771229, zipCode: "39564"},
	{latitude: 30.611822, longitude: -88.761584, zipCode: "39565"},
	{latitude: 30.359723, longitude: -88.558539, zipCode: "39567"},
	{latitude: 30.420066, longitude: -89.275043, zipCode: "39571"},
	{latitude: 30.250826, longitude: -89.604803, zipCode: "39572"},
	{latitude: 30.730994, longitude: -89.093398, zipCode: "39573"},
	{latitude: 30.608496, longitude: -89.106093, zipCode: "39574"},
	{latitude: 30.28981, longitude: -89.383391, zipCode: "39576"},
	{latitude: 30.904729, longitude: -89.086488, zipCode: "39577"},
	{latitude: 30.350539, longitude: -88.493571, zipCode: "39581"},
	{latitude: 30.334078, longitude: -88.578536, zipCode: "39595"},
	{latitude: 31.570297, longitude: -90.475516, zipCode: "39601"},
	{latitude: 31.436637, longitude: -90.45685, zipCode: "39629"},
	{latitude: 31.459306, longitude: -90.84535, zipCode: "39630"},
	{latitude: 31.067208, longitude: -91.134006, zipCode: "39631"},
	{latitude: 31.292805, longitude: -91.167671, zipCode: "39633"},
	{latitude: 31.184305, longitude: -90.448574, zipCode: "39635"},
	{latitude: 31.225004, longitude: -90.995924, zipCode: "39638"},
	{latitude: 31.356403, longitude: -90.181851, zipCode: "39641"},
	{latitude: 31.246549, longitude: -90.014258, zipCode: "39643"},
	{latitude: 31.135943, longitude: -90.79399, zipCode: "39645"},
	{latitude: 31.526087, longitude: -90.711567, zipCode: "39647"},
	{latitude: 31.177896, longitude: -90.385212, zipCode: "39648"},
	{latitude: 31.111061, longitude: -90.465322, zipCode: "39652"},
	{latitude: 31.46121, longitude: -90.872092, zipCode: "39653"},
	{latitude: 31.54103, longitude: -90.148993, zipCode: "39654"},
	{latitude: 31.443901, longitude: -89.931798, zipCode: "39656"},
	{latitude: 31.040324, longitude: -90.506268, zipCode: "39657"},
	{latitude: 31.484447, longitude: -91.083468, zipCode: "39661"},
	{latitude: 31.386372, longitude: -90.285394, zipCode: "39662"},
	{latitude: 31.594893, longitude: -90.038161, zipCode: "39663"},
	{latitude: 31.32597, longitude: -90.674153, zipCode: "39664"},
	{latitude: 31.641253, longitude: -90.214036, zipCode: "39665"},
	{latitude: 31.287691, longitude: -90.494997, zipCode: "39666"},
	{latitude: 31.124737, longitude: -90.126164, zipCode: "39667"},
	{latitude: 31.691275, longitude: -90.791257, zipCode: "39668"},
	{latitude: 31.150673, longitude: -91.362519, zipCode: "39669"},
	{latitude: 33.393934, longitude: -88.50472, zipCode: "39701"},
	{latitude: 33.4317, longitude: -88.345532, zipCode: "39702"},
	{latitude: 33.587945, longitude: -88.441102, zipCode: "39705"},
	{latitude: 33.843635, longitude: -88.565615, zipCode: "39730"},
	{latitude: 33.344573, longitude: -89.186133, zipCode: "39735"},
	{latitude: 33.416861, longitude: -88.641787, zipCode: "39736"},
	{latitude: 33.650545, longitude: -89.336013, zipCode: "39737"},
	{latitude: 33.238369, longitude: -88.578745, zipCode: "39739"},
	{latitude: 33.715002, longitude: -88.315745, zipCode: "39740"},
	{latitude: 33.636028, longitude: -88.854675, zipCode: "39741"},
	{latitude: 33.336759, longitude: -88.61892, zipCode: "39743"},
	{latitude: 33.580161, longitude: -89.300323, zipCode: "39744"},
	{latitude: 33.298333, longitude: -89.46959, zipCode: "39745"},
	{latitude: 33.74904, longitude: -88.414965, zipCode: "39746"},
	{latitude: 33.424217, longitude: -89.535944, zipCode: "39747"},
	{latitude: 33.568951, longitude: -89.044787, zipCode: "39750"},
	{latitude: 33.695332, longitude: -89.093208, zipCode: "39751"},
	{latitude: 33.522607, longitude: -89.150748, zipCode: "39752"},
	{latitude: 33.48448, longitude: -88.630491, zipCode: "39753"},
	{latitude: 33.607825, longitude: -88.964378, zipCode: "39755"},
	{latitude: 33.756717, longitude: -88.741085, zipCode: "39756"},
	{latitude: 33.437924, longitude: -88.825142, zipCode: "39759"},
	{latitude: 33.446509, longitude: -88.795826, zipCode: "39762"},
	{latitude: 33.58657, longitude: -88.321105, zipCode: "39766"},
	{latitude: 33.467999, longitude: -89.452318, zipCode: "39767"},
	{latitude: 33.321925, longitude: -89.017837, zipCode: "39769"},
	{latitude: 33.606669, longitude: -89.279109, zipCode: "39771"},
	{latitude: 33.295108, longitude: -89.304307, zipCode: "39772"},
	{latitude: 33.639913, longitude: -88.672716, zipCode: "39773"},
	{latitude: 33.785325, longitude: -89.031378, zipCode: "39776"},
	{latitude: 31.423156, longitude: -84.684305, zipCode: "39813"},
	{latitude: 30.73885, longitude: -84.490698, zipCode: "39815"},
	{latitude: 30.947881, longitude: -84.593427, zipCode: "39817"},
	{latitude: 30.785401, longitude: -84.645153, zipCode: "39819"},
	{latitude: 31.333568, longitude: -84.947033, zipCode: "39823"},
	{latitude: 31.528358, longitude: -84.864795, zipCode: "39824"},
	{latitude: 30.930767, longitude: -84.740763, zipCode: "39825"},
	{latitude: 31.818496, longitude: -84.321144, zipCode: "39826"},
	{latitude: 30.947615, longitude: -84.210378, zipCode: "39827"},
	{latitude: 30.79859, longitude: -84.226723, zipCode: "39828"},
	{latitude: 31.19106, longitude: -85.027892, zipCode: "39832"},
	{latitude: 30.894291, longitude: -84.431155, zipCode: "39834"},
	{latitude: 31.666007, longitude: -84.868633, zipCode: "39836"},
	{latitude: 31.152104, longitude: -84.677706, zipCode: "39837"},
	{latitude: 31.7813, longitude: -84.764124, zipCode: "39840"},
	{latitude: 31.292866, longitude: -84.685044, zipCode: "39841"},
	{latitude: 31.754312, longitude: -84.435527, zipCode: "39842"},
	{latitude: 30.95145, longitude: -84.892724, zipCode: "39845"},
	{latitude: 31.568955, longitude: -84.745986, zipCode: "39846"},
	{latitude: 31.619831, longitude: -84.992583, zipCode: "39851"},
	{latitude: 31.869218, longitude: -85.052557, zipCode: "39854"},
	{latitude: 30.987638, longitude: -84.812759, zipCode: "39859"},
	{latitude: 31.13535, longitude: -84.987248, zipCode: "39861"},
	{latitude: 31.459371, longitude: -84.517381, zipCode: "39862"},
	{latitude: 31.56093, longitude: -84.603184, zipCode: "39866"},
	{latitude: 31.842843, longitude: -84.93075, zipCode: "39867"},
	{latitude: 31.322028, longitude: -84.414137, zipCode: "39870"},
	{latitude: 31.915159, longitude: -84.511576, zipCode: "39877"},
	{latitude: 31.721896, longitude: -84.343026, zipCode: "39885"},
	{latitude: 31.732531, longitude: -84.617228, zipCode: "39886"},
	{latitude: 30.911205, longitude: -84.329813, zipCode: "39897"},
	{latitude: 38.276697, longitude: -85.04382, zipCode: "40003"},
	{latitude: 37.802403, longitude: -85.469858, zipCode: "40004"},
	{latitude: 38.596216, longitude: -85.333041, zipCode: "40006"},
	{latitude: 38.458345, longitude: -84.99915, zipCode: "40007"},
	{latitude: 37.908948, longitude: -85.275003, zipCode: "40008"},
	{latitude: 37.48372, longitude: -85.086423, zipCode: "40009"},
	{latitude: 38.372519, longitude: -85.459505, zipCode: "40010"},
	{latitude: 38.527774, longitude: -85.170076, zipCode: "40011"},
	{latitude: 37.912954, longitude: -85.16734, zipCode: "40012"},
	{latitude: 37.94168, longitude: -85.482175, zipCode: "40013"},
	{latitude: 38.340798, longitude: -85.43293, zipCode: "40014"},
	{latitude: 38.372641, longitude: -85.175051, zipCode: "40019"},
	{latitude: 37.934784, longitude: -85.387416, zipCode: "40020"},
	{latitude: 38.151401, longitude: -85.342927, zipCode: "40022"},
	{latitude: 38.163984, longitude: -85.423936, zipCode: "40023"},
	{latitude: 38.301619, longitude: -85.652127, zipCode: "40025"},
	{latitude: 38.421287, longitude: -85.516845, zipCode: "40026"},
	{latitude: 38.429416, longitude: -85.400814, zipCode: "40031"},
	{latitude: 37.544245, longitude: -85.225566, zipCode: "40033"},
	{latitude: 38.415116, longitude: -84.953309, zipCode: "40036"},
	{latitude: 37.660639, longitude: -85.417518, zipCode: "40037"},
	{latitude: 37.777043, longitude: -85.069286, zipCode: "40040"},
	{latitude: 38.256127, longitude: -85.664767, zipCode: "40041"},
	{latitude: 38.693585, longitude: -85.354607, zipCode: "40045"},
	{latitude: 38.023931, longitude: -85.169791, zipCode: "40046"},
	{latitude: 38.035955, longitude: -85.555524, zipCode: "40047"},
	{latitude: 37.663524, longitude: -85.395025, zipCode: "40049"},
	{latitude: 38.455933, longitude: -85.177958, zipCode: "40050"},
	{latitude: 37.652226, longitude: -85.579583, zipCode: "40051"},
	{latitude: 37.604126, longitude: -85.487942, zipCode: "40052"},
	{latitude: 38.495202, longitude: -85.326025, zipCode: "40055"},
	{latitude: 38.308077, longitude: -85.487219, zipCode: "40056"},
	{latitude: 38.382361, longitude: -85.055394, zipCode: "40057"},
	{latitude: 38.432649, longitude: -85.170003, zipCode: "40058"},
	{latitude: 38.355611, longitude: -85.581963, zipCode: "40059"},
	{latitude: 37.542332, longitude: -85.466267, zipCode: "40060"},
	{latitude: 37.70974, longitude: -85.264093, zipCode: "40061"},
	{latitude: 37.607001, longitude: -85.441352, zipCode: "40062"},
	{latitude: 37.56954, longitude: -85.344634, zipCode: "40063"},
	{latitude: 38.212858, longitude: -85.208553, zipCode: "40065"},
	{latitude: 38.228416, longitude: -85.367465, zipCode: "40067"},
	{latitude: 38.388706, longitude: -85.286313, zipCode: "40068"},
	{latitude: 37.723853, longitude: -85.207683, zipCode: "40069"},
	{latitude: 38.472028, longitude: -85.235026, zipCode: "40070"},
	{latitude: 38.042869, longitude: -85.378753, zipCode: "40071"},
	{latitude: 38.568134, longitude: -85.11267, zipCode: "40075"},
	{latitude: 38.113729, longitude: -85.086124, zipCode: "40076"},
	{latitude: 38.495455, longitude: -85.444636, zipCode: "40077"},
	{latitude: 37.831112, longitude: -85.144771, zipCode: "40078"},
	{latitude: 38.085529, longitude: -86.33285, zipCode: "40104"},
	{latitude: 37.725327, longitude: -85.598827, zipCode: "40107"},
	{latitude: 37.96982, longitude: -86.17287, zipCode: "40108"},
	{latitude: 38.064495, longitude: -85.750373, zipCode: "40109"},
	{latitude: 37.94005, longitude: -85.651234, zipCode: "40110"},
	{latitude: 37.785507, longitude: -86.619578, zipCode: "40111"},
	{latitude: 37.750296, longitude: -86.230107, zipCode: "40115"},
	{latitude: 37.896583, longitude: -86.124289, zipCode: "40117"},
	{latitude: 38.096337, longitude: -85.757854, zipCode: "40118"},
	{latitude: 37.608155, longitude: -86.553108, zipCode: "40119"},
	{latitude: 37.926895, longitude: -85.95981, zipCode: "40121"},
	{latitude: 37.730408, longitude: -86.285434, zipCode: "40140"},
	{latitude: 37.893218, longitude: -86.204086, zipCode: "40142"},
	{latitude: 37.785077, longitude: -86.499925, zipCode: "40143"},
	{latitude: 37.757039, longitude: -86.384146, zipCode: "40144"},
	{latitude: 37.654153, longitude: -86.314307, zipCode: "40145"},
	{latitude: 37.836527, longitude: -86.30994, zipCode: "40146"},
	{latitude: 37.87859, longitude: -85.676516, zipCode: "40150"},
	{latitude: 37.601942, longitude: -86.448546, zipCode: "40152"},
	{latitude: 37.948316, longitude: -85.99416, zipCode: "40155"},
	{latitude: 38.023398, longitude: -86.375494, zipCode: "40157"},
	{latitude: 37.813011, longitude: -85.931679, zipCode: "40160"},
	{latitude: 38.008168, longitude: -86.401197, zipCode: "40161"},
	{latitude: 37.746355, longitude: -86.040685, zipCode: "40162"},
	{latitude: 37.992776, longitude: -85.695936, zipCode: "40165"},
	{latitude: 37.96655, longitude: -86.487398, zipCode: "40170"},
	{latitude: 37.972084, longitude: -86.436384, zipCode: "40171"},
	{latitude: 37.819002, longitude: -86.089891, zipCode: "40175"},
	{latitude: 37.907696, longitude: -86.351447, zipCode: "40176"},
	{latitude: 38.002446, longitude: -85.923439, zipCode: "40177"},
	{latitude: 37.681237, longitude: -86.422431, zipCode: "40178"},
	{latitude: 38.252811, longitude: -85.751946, zipCode: "40202"},
	{latitude: 38.252157, longitude: -85.765194, zipCode: "40203"},
	{latitude: 38.239517, longitude: -85.722286, zipCode: "40204"},
	{latitude: 38.223001, longitude: -85.683261, zipCode: "40205"},
	{latitude: 38.256501, longitude: -85.701621, zipCode: "40206"},
	{latitude: 38.263466, longitude: -85.656647, zipCode: "40207"},
	{latitude: 38.217471, longitude: -85.76585, zipCode: "40208"},
	{latitude: 38.182383, longitude: -85.735186, zipCode: "40209"},
	{latitude: 38.231567, longitude: -85.785981, zipCode: "40210"},
	{latitude: 38.235823, longitude: -85.824579, zipCode: "40211"},
	{latitude: 38.267967, longitude: -85.802494, zipCode: "40212"},
	{latitude: 38.176954, longitude: -85.717023, zipCode: "40213"},
	{latitude: 38.148491, longitude: -85.779843, zipCode: "40214"},
	{latitude: 38.190476, longitude: -85.785734, zipCode: "40215"},
	{latitude: 38.18614, longitude: -85.83878, zipCode: "40216"},
	{latitude: 38.216712, longitude: -85.737515, zipCode: "40217"},
	{latitude: 38.189532, longitude: -85.657624, zipCode: "40218"},
	{latitude: 38.137909, longitude: -85.692693, zipCode: "40219"},
	{latitude: 38.215375, longitude: -85.618675, zipCode: "40220"},
	{latitude: 38.186503, longitude: -85.753072, zipCode: "40221"},
	{latitude: 38.264716, longitude: -85.611811, zipCode: "40222"},
	{latitude: 38.258467, longitude: -85.539772, zipCode: "40223"},
	{latitude: 38.140048, longitude: -85.635813, zipCode: "40228"},
	{latitude: 38.08841, longitude: -85.660363, zipCode: "40229"},
	{latitude: 38.1955, longitude: -85.695227, zipCode: "40231"},
	{latitude: 38.299974, longitude: -85.575689, zipCode: "40241"},
	{latitude: 38.276223, longitude: -85.587103, zipCode: "40242"},
	{latitude: 38.240228, longitude: -85.532614, zipCode: "40243"},
	{latitude: 38.267561, longitude: -85.446172, zipCode: "40245"},
	{latitude: 38.148592, longitude: -85.871182, zipCode: "40258"},
	{latitude: 38.078458, longitude: -85.853119, zipCode: "40272"},
	{latitude: 38.247682, longitude: -85.686881, zipCode: "40280"},
	{latitude: 38.12607, longitude: -85.579552, zipCode: "40291"},
	{latitude: 38.151687, longitude: -85.517172, zipCode: "40299"},
	{latitude: 37.750044, longitude: -84.772759, zipCode: "40310"},
	{latitude: 38.332048, longitude: -83.997104, zipCode: "40311"},
	{latitude: 37.8443, longitude: -83.932894, zipCode: "40312"},
	{latitude: 38.137482, longitude: -83.402595, zipCode: "40313"},
	{latitude: 37.93661, longitude: -83.52052, zipCode: "40316"},
	{latitude: 37.935145, longitude: -83.633039, zipCode: "40322"},
	{latitude: 38.245796, longitude: -84.550626, zipCode: "40324"},
	{latitude: 37.581879, longitude: -85.012813, zipCode: "40328"},
	{latitude: 37.787723, longitude: -84.888056, zipCode: "40330"},
	{latitude: 38.025073, longitude: -83.745044, zipCode: "40334"},
	{latitude: 37.682978, longitude: -83.990952, zipCode: "40336"},
	{latitude: 37.942341, longitude: -83.854646, zipCode: "40337"},
	{latitude: 37.942173, longitude: -84.642798, zipCode: "40339"},
	{latitude: 38.005503, longitude: -84.967386, zipCode: "40342"},
	{latitude: 37.987118, longitude: -83.743879, zipCode: "40346"},
	{latitude: 38.158286, longitude: -84.728351, zipCode: "40347"},
	{latitude: 38.302972, longitude: -84.146105, zipCode: "40348"},
	{latitude: 38.304672, longitude: -83.873854, zipCode: "40350"},
	{latitude: 38.204266, longitude: -83.428079, zipCode: "40351"},
	{latitude: 38.065465, longitude: -83.948822, zipCode: "40353"},
	{latitude: 38.601903, longitude: -84.86816, zipCode: "40355"},
	{latitude: 37.873764, longitude: -84.565257, zipCode: "40356"},
	{latitude: 38.145187, longitude: -84.108414, zipCode: "40357"},
	{latitude: 38.057558, longitude: -83.684089, zipCode: "40358"},
	{latitude: 38.476322, longitude: -84.814863, zipCode: "40359"},
	{latitude: 38.167285, longitude: -83.767744, zipCode: "40360"},
	{latitude: 38.202562, longitude: -84.20986, zipCode: "40361"},
	{latitude: 38.514407, longitude: -85.009763, zipCode: "40363"},
	{latitude: 38.394547, longitude: -84.530175, zipCode: "40370"},
	{latitude: 38.082997, longitude: -83.595129, zipCode: "40371"},
	{latitude: 37.911756, longitude: -84.888114, zipCode: "40372"},
	{latitude: 38.214854, longitude: -83.902149, zipCode: "40374"},
	{latitude: 37.79294, longitude: -83.714047, zipCode: "40376"},
	{latitude: 38.308222, longitude: -84.690353, zipCode: "40379"},
	{latitude: 37.813728, longitude: -83.797132, zipCode: "40380"},
	{latitude: 38.006622, longitude: -84.739332, zipCode: "40383"},
	{latitude: 37.738202, longitude: -84.133667, zipCode: "40385"},
	{latitude: 37.940782, longitude: -83.487418, zipCode: "40387"},
	{latitude: 37.85776, longitude: -84.676831, zipCode: "40390"},
	{latitude: 37.970314, longitude: -84.145115, zipCode: "40391"},
	{latitude: 37.291039, longitude: -83.976505, zipCode: "40402"},
	{latitude: 37.570691, longitude: -84.263754, zipCode: "40403"},
	{latitude: 37.573157, longitude: -84.290332, zipCode: "40404"},
	{latitude: 37.369158, longitude: -84.434908, zipCode: "40409"},
	{latitude: 37.437328, longitude: -84.490234, zipCode: "40419"},
	{latitude: 37.644519, longitude: -84.806497, zipCode: "40422"},
	{latitude: 37.393495, longitude: -83.938776, zipCode: "40434"},
	{latitude: 37.457036, longitude: -84.844025, zipCode: "40437"},
	{latitude: 37.57921, longitude: -84.840423, zipCode: "40440"},
	{latitude: 37.338919, longitude: -84.741295, zipCode: "40442"},
	{latitude: 37.666248, longitude: -84.57803, zipCode: "40444"},
	{latitude: 37.320324, longitude: -84.214065, zipCode: "40445"},
	{latitude: 37.440508, longitude: -84.046726, zipCode: "40447"},
	{latitude: 37.457088, longitude: -84.757625, zipCode: "40448"},
	{latitude: 37.353471, longitude: -84.319508, zipCode: "40456"},
	{latitude: 37.391078, longitude: -84.248831, zipCode: "40460"},
	{latitude: 37.58253, longitude: -84.406, zipCode: "40461"},
	{latitude: 37.572274, longitude: -84.92506, zipCode: "40464"},
	{latitude: 37.651388, longitude: -84.982532, zipCode: "40468"},
	{latitude: 37.732128, longitude: -83.859667, zipCode: "40472"},
	{latitude: 37.76237, longitude: -84.304577, zipCode: "40475"},
	{latitude: 37.438559, longitude: -84.082757, zipCode: "40481"},
	{latitude: 37.52058, longitude: -84.673931, zipCode: "40484"},
	{latitude: 37.365328, longitude: -83.862799, zipCode: "40486"},
	{latitude: 37.360761, longitude: -84.649773, zipCode: "40489"},
	{latitude: 38.010953, longitude: -84.483191, zipCode: "40502"},
	{latitude: 38.005828, longitude: -84.533469, zipCode: "40503"},
	{latitude: 38.042413, longitude: -84.544016, zipCode: "40504"},
	{latitude: 38.060653, longitude: -84.457238, zipCode: "40505"},
	{latitude: 38.046898, longitude: -84.496262, zipCode: "40507"},
	{latitude: 38.060945, longitude: -84.511395, zipCode: "40508"},
	{latitude: 37.997347, longitude: -84.377477, zipCode: "40509"},
	{latitude: 38.071199, longitude: -84.583831, zipCode: "40510"},
	{latitude: 38.141016, longitude: -84.461618, zipCode: "40511"},
	{latitude: 38.016586, longitude: -84.605028, zipCode: "40513"},
	{latitude: 37.983376, longitude: -84.565224, zipCode: "40514"},
	{latitude: 37.915615, longitude: -84.412155, zipCode: "40515"},
	{latitude: 38.071804, longitude: -84.364002, zipCode: "40516"},
	{latitude: 37.982958, longitude: -84.489425, zipCode: "40517"},
	{latitude: 38.029736, longitude: -84.501378, zipCode: "40526"},
	{latitude: 38.029809, longitude: -84.507281, zipCode: "40536"},
	{latitude: 38.234919, longitude: -84.868786, zipCode: "40601"},
	{latitude: 36.913613, longitude: -84.168147, zipCode: "40701"},
	{latitude: 37.248042, longitude: -84.152709, zipCode: "40729"},
	{latitude: 36.929945, longitude: -83.975035, zipCode: "40734"},
	{latitude: 37.011038, longitude: -84.147979, zipCode: "40737"},
	{latitude: 37.020582, longitude: -84.037313, zipCode: "40740"},
	{latitude: 37.133373, longitude: -84.119167, zipCode: "40741"},
	{latitude: 37.03187, longitude: -84.109932, zipCode: "40744"},
	{latitude: 36.812972, longitude: -84.057124, zipCode: "40759"},
	{latitude: 36.689229, longitude: -83.925596, zipCode: "40763"},
	{latitude: 36.704354, longitude: -84.189734, zipCode: "40769"},
	{latitude: 36.862499, longitude: -84.037627, zipCode: "40771"},
	{latitude: 36.857008, longitude: -83.254924, zipCode: "40801"},
	{latitude: 37.045821, longitude: -83.422606, zipCode: "40803"},
	{latitude: 36.891225, longitude: -83.297006, zipCode: "40806"},
	{latitude: 36.96402, longitude: -82.955591, zipCode: "40807"},
	{latitude: 37.010211, longitude: -83.290841, zipCode: "40808"},
	{latitude: 36.936402, longitude: -83.280644, zipCode: "40810"},
	{latitude: 36.710199, longitude: -83.592254, zipCode: "40813"},
	{latitude: 36.79156, longitude: -83.232515, zipCode: "40815"},
	{latitude: 37.01327, longitude: -83.367212, zipCode: "40816"},
	{latitude: 36.821595, longitude: -83.243138, zipCode: "40818"},
	{latitude: 36.802316, longitude: -83.441646, zipCode: "40819"},
	{latitude: 36.797864, longitude: -83.158766, zipCode: "40820"},
	{latitude: 36.958653, longitude: -82.990992, zipCode: "40823"},
	{latitude: 36.825297, longitude: -83.375312, zipCode: "40824"},
	{latitude: 37.042414, longitude: -82.770095, zipCode: "40826"},
	{latitude: 37.037395, longitude: -83.512783, zipCode: "40827"},
	{latitude: 36.871821, longitude: -83.152587, zipCode: "40828"},
	{latitude: 36.796114, longitude: -83.302343, zipCode: "40829"},
	{latitude: 36.776364, longitude: -83.331299, zipCode: "40830"},
	{latitude: 36.760179, longitude: -83.328572, zipCode: "40831"},
	{latitude: 36.944631, longitude: -83.418974, zipCode: "40840"},
	{latitude: 36.894771, longitude: -82.931593, zipCode: "40843"},
	{latitude: 37.057797, longitude: -83.341426, zipCode: "40844"},
	{latitude: 36.784729, longitude: -83.503517, zipCode: "40845"},
	{latitude: 36.852435, longitude: -83.157059, zipCode: "40847"},
	{latitude: 36.899375, longitude: -83.135503, zipCode: "40849"},
	{latitude: 36.845327, longitude: -83.361744, zipCode: "40854"},
	{latitude: 36.929858, longitude: -82.885329, zipCode: "40855"},
	{latitude: 36.72316, longitude: -83.527522, zipCode: "40856"},
	{latitude: 37.012556, longitude: -83.409184, zipCode: "40858"},
	{latitude: 37.017552, longitude: -82.856157, zipCode: "40862"},
	{latitude: 36.727176, longitude: -83.430901, zipCode: "40863"},
	{latitude: 36.920061, longitude: -83.211161, zipCode: "40865"},
	{latitude: 37.094789, longitude: -83.426873, zipCode: "40868"},
	{latitude: 36.942023, longitude: -83.152968, zipCode: "40870"},
	{latitude: 36.810909, longitude: -83.400029, zipCode: "40873"},
	{latitude: 36.989139, longitude: -83.472521, zipCode: "40874"},
	{latitude: 36.861497, longitude: -83.602232, zipCode: "40902"},
	{latitude: 36.8029, longitude: -83.800941, zipCode: "40903"},
	{latitude: 36.889185, longitude: -83.875636, zipCode: "40906"},
	{latitude: 36.936127, longitude: -83.558902, zipCode: "40913"},
	{latitude: 37.092697, longitude: -83.572412, zipCode: "40914"},
	{latitude: 36.890409, longitude: -83.812553, zipCode: "40915"},
	{latitude: 36.791717, longitude: -83.90164, zipCode: "40921"},
	{latitude: 36.919323, longitude: -83.845446, zipCode: "40923"},
	{latitude: 36.900113, longitude: -83.012983, zipCode: "40927"},
	{latitude: 36.958171, longitude: -83.752819, zipCode: "40935"},
	{latitude: 36.810418, longitude: -83.720679, zipCode: "40939"},
	{latitude: 36.602546, longitude: -83.939044, zipCode: "40940"},
	{latitude: 37.130055, longitude: -83.745555, zipCode: "40941"},
	{latitude: 36.956284, longitude: -83.847458, zipCode: "40943"},
	{latitude: 36.957598, longitude: -83.824614, zipCode: "40946"},
	{latitude: 36.901144, longitude: -83.868345, zipCode: "40949"},
	{latitude: 36.937968, longitude: -83.801341, zipCode: "40953"},
	{latitude: 36.809878, longitude: -83.596918, zipCode: "40958"},
	{latitude: 37.164273, longitude: -83.715454, zipCode: "40962"},
	{latitude: 36.782637, longitude: -83.337726, zipCode: "40964"},
	{latitude: 36.619993, longitude: -83.731847, zipCode: "40965"},
	{latitude: 37.240525, longitude: -83.587899, zipCode: "40972"},
	{latitude: 36.724042, longitude: -83.729182, zipCode: "40977"},
	{latitude: 37.009382, longitude: -83.493067, zipCode: "40979"},
	{latitude: 37.277868, longitude: -83.485324, zipCode: "40981"},
	{latitude: 36.932952, longitude: -83.687166, zipCode: "40982"},
	{latitude: 37.347846, longitude: -83.731846, zipCode: "40983"},
	{latitude: 36.869781, longitude: -83.522693, zipCode: "40988"},
	{latitude: 36.75268, longitude: -83.823241, zipCode: "40995"},
	{latitude: 36.894542, longitude: -83.662034, zipCode: "40997"},
	{latitude: 38.910854, longitude: -84.400667, zipCode: "41001"},
	{latitude: 38.742052, longitude: -83.973538, zipCode: "41002"},
	{latitude: 38.52964, longitude: -84.390246, zipCode: "41003"},
	{latitude: 38.644125, longitude: -84.087983, zipCode: "41004"},
	{latitude: 39.006759, longitude: -84.760116, zipCode: "41005"},
	{latitude: 38.78443, longitude: -84.345639, zipCode: "41006"},
	{latitude: 38.892579, longitude: -84.308941, zipCode: "41007"},
	{latitude: 38.65112, longitude: -85.17622, zipCode: "41008"},
	{latitude: 38.499633, longitude: -84.608194, zipCode: "41010"},
	{latitude: 39.06713, longitude: -84.532674, zipCode: "41011"},
	{latitude: 39.064811, longitude: -84.504526, zipCode: "41014"},
	{latitude: 38.979711, longitude: -84.478594, zipCode: "41015"},
	{latitude: 39.088749, longitude: -84.548476, zipCode: "41016"},
	{latitude: 39.029195, longitude: -84.562134, zipCode: "41017"},
	{latitude: 39.014901, longitude: -84.602186, zipCode: "41018"},
	{latitude: 38.784431, longitude: -84.587261, zipCode: "41030"},
	{latitude: 38.413134, longitude: -84.297476, zipCode: "41031"},
	{latitude: 38.769157, longitude: -84.475477, zipCode: "41033"},
	{latitude: 38.687149, longitude: -83.900216, zipCode: "41034"},
	{latitude: 38.719224, longitude: -84.654354, zipCode: "41035"},
	{latitude: 38.414304, longitude: -83.871425, zipCode: "41039"},
	{latitude: 38.650767, longitude: -84.331159, zipCode: "41040"},
	{latitude: 38.405046, longitude: -83.723117, zipCode: "41041"},
	{latitude: 38.987219, longitude: -84.647016, zipCode: "41042"},
	{latitude: 38.761688, longitude: -84.20495, zipCode: "41043"},
	{latitude: 38.610431, longitude: -83.972434, zipCode: "41044"},
	{latitude: 38.721963, longitude: -85.021636, zipCode: "41045"},
	{latitude: 38.708767, longitude: -84.800539, zipCode: "41046"},
	{latitude: 39.087014, longitude: -84.703829, zipCode: "41048"},
	{latitude: 38.268275, longitude: -83.644163, zipCode: "41049"},
	{latitude: 38.93382, longitude: -84.540398, zipCode: "41051"},
	{latitude: 38.656441, longitude: -84.775333, zipCode: "41052"},
	{latitude: 38.525392, longitude: -83.866464, zipCode: "41055"},
	{latitude: 38.601939, longitude: -83.777402, zipCode: "41056"},
	{latitude: 38.998125, longitude: -84.345146, zipCode: "41059"},
	{latitude: 38.857033, longitude: -84.505345, zipCode: "41063"},
	{latitude: 38.513473, longitude: -84.06423, zipCode: "41064"},
	{latitude: 39.074304, longitude: -84.480561, zipCode: "41071"},
	{latitude: 39.101248, longitude: -84.477492, zipCode: "41073"},
	{latitude: 39.109225, longitude: -84.460305, zipCode: "41074"},
	{latitude: 39.082436, longitude: -84.457111, zipCode: "41075"},
	{latitude: 39.017527, longitude: -84.440935, zipCode: "41076"},
	{latitude: 39.054694, longitude: -84.824189, zipCode: "41080"},
	{latitude: 38.670772, longitude: -84.996374, zipCode: "41083"},
	{latitude: 39.034976, longitude: -84.393912, zipCode: "41085"},
	{latitude: 38.695366, longitude: -84.88102, zipCode: "41086"},
	{latitude: 38.910267, longitude: -84.736327, zipCode: "41091"},
	{latitude: 38.815614, longitude: -84.688213, zipCode: "41092"},
	{latitude: 38.378138, longitude: -83.55318, zipCode: "41093"},
	{latitude: 38.882754, longitude: -84.624108, zipCode: "41094"},
	{latitude: 38.773683, longitude: -84.747471, zipCode: "41095"},
	{latitude: 38.621179, longitude: -84.581444, zipCode: "41097"},
	{latitude: 38.597857, longitude: -84.987577, zipCode: "41098"},
	{latitude: 38.47752, longitude: -82.650548, zipCode: "41101"},
	{latitude: 38.427628, longitude: -82.730185, zipCode: "41102"},
	{latitude: 38.439405, longitude: -82.833674, zipCode: "41121"},
	{latitude: 38.019783, longitude: -82.858405, zipCode: "41124"},
	{latitude: 38.320926, longitude: -82.656257, zipCode: "41129"},
	{latitude: 38.256843, longitude: -82.823182, zipCode: "41132"},
	{latitude: 38.362748, longitude: -83.270796, zipCode: "41135"},
	{latitude: 38.508682, longitude: -82.734011, zipCode: "41139"},
	{latitude: 38.566748, longitude: -83.13882, zipCode: "41141"},
	{latitude: 38.27773, longitude: -83.073052, zipCode: "41142"},
	{latitude: 38.334937, longitude: -82.97524, zipCode: "41143"},
	{latitude: 38.518848, longitude: -82.929216, zipCode: "41144"},
	{latitude: 38.279178, longitude: -82.896024, zipCode: "41146"},
	{latitude: 38.099252, longitude: -83.076514, zipCode: "41149"},
	{latitude: 38.010413, longitude: -82.975065, zipCode: "41159"},
	{latitude: 38.288431, longitude: -83.157168, zipCode: "41164"},
	{latitude: 38.626388, longitude: -83.102358, zipCode: "41166"},
	{latitude: 38.307054, longitude: -82.75056, zipCode: "41168"},
	{latitude: 38.532644, longitude: -82.736651, zipCode: "41169"},
	{latitude: 38.045518, longitude: -83.178959, zipCode: "41171"},
	{latitude: 38.706272, longitude: -83.00708, zipCode: "41174"},
	{latitude: 38.648866, longitude: -82.972077, zipCode: "41175"},
	{latitude: 38.535047, longitude: -83.370143, zipCode: "41179"},
	{latitude: 38.150798, longitude: -82.884476, zipCode: "41180"},
	{latitude: 38.209151, longitude: -82.872923, zipCode: "41181"},
	{latitude: 38.55033, longitude: -82.736653, zipCode: "41183"},
	{latitude: 38.547639, longitude: -83.562888, zipCode: "41189"},
	{latitude: 38.062355, longitude: -82.762329, zipCode: "41201"},
	{latitude: 37.852415, longitude: -82.461108, zipCode: "41203"},
	{latitude: 37.806639, longitude: -82.671273, zipCode: "41204"},
	{latitude: 37.827633, longitude: -82.577239, zipCode: "41214"},
	{latitude: 37.732493, longitude: -82.803752, zipCode: "41216"},
	{latitude: 37.928718, longitude: -82.919913, zipCode: "41219"},
	{latitude: 37.755891, longitude: -82.858454, zipCode: "41222"},
	{latitude: 37.810746, longitude: -82.55249, zipCode: "41224"},
	{latitude: 37.98017, longitude: -82.954983, zipCode: "41226"},
	{latitude: 38.063274, longitude: -82.668441, zipCode: "41230"},
	{latitude: 37.792524, longitude: -82.379563, zipCode: "41231"},
	{latitude: 37.941018, longitude: -82.729719, zipCode: "41232"},
	{latitude: 37.802161, longitude: -82.746874, zipCode: "41234"},
	{latitude: 37.834882, longitude: -82.950158, zipCode: "41238"},
	{latitude: 37.82029, longitude: -82.783724, zipCode: "41240"},
	{latitude: 37.710799, longitude: -82.447931, zipCode: "41250"},
	{latitude: 37.887302, longitude: -82.715298, zipCode: "41254"},
	{latitude: 37.919982, longitude: -82.825799, zipCode: "41255"},
	{latitude: 37.820873, longitude: -82.876102, zipCode: "41256"},
	{latitude: 37.913105, longitude: -82.798879, zipCode: "41257"},
	{latitude: 37.825569, longitude: -82.752785, zipCode: "41260"},
	{latitude: 37.845096, longitude: -82.630093, zipCode: "41262"},
	{latitude: 37.856743, longitude: -82.764651, zipCode: "41263"},
	{latitude: 37.92491, longitude: -82.681454, zipCode: "41264"},
	{latitude: 37.759949, longitude: -82.718318, zipCode: "41265"},
	{latitude: 37.881714, longitude: -82.438842, zipCode: "41267"},
	{latitude: 37.788502, longitude: -82.785236, zipCode: "41268"},
	{latitude: 37.827751, longitude: -82.72454, zipCode: "41271"},
	{latitude: 37.86416, longitude: -82.810705, zipCode: "41274"},
	{latitude: 37.715351, longitude: -83.493392, zipCode: "41301"},
	{latitude: 37.640672, longitude: -83.251494, zipCode: "41310"},
	{latitude: 37.575085, longitude: -83.723171, zipCode: "41311"},
	{latitude: 37.439746, longitude: -83.637508, zipCode: "41314"},
	{latitude: 37.465638, longitude: -83.211598, zipCode: "41317"},
	{latitude: 37.807378, longitude: -83.367278, zipCode: "41332"},
	{latitude: 37.516193, longitude: -83.269114, zipCode: "41339"},
	{latitude: 37.409385, longitude: -83.301027, zipCode: "41348"},
	{latitude: 37.336072, longitude: -83.585919, zipCode: "41351"},
	{latitude: 37.826023, longitude: -83.336133, zipCode: "41352"},
	{latitude: 37.801375, longitude: -83.614634, zipCode: "41360"},
	{latitude: 37.36847, longitude: -83.660665, zipCode: "41364"},
	{latitude: 37.706784, longitude: -83.628873, zipCode: "41365"},
	{latitude: 37.624716, longitude: -83.21962, zipCode: "41366"},
	{latitude: 37.415011, longitude: -83.227816, zipCode: "41367"},
	{latitude: 37.663798, longitude: -83.314769, zipCode: "41385"},
	{latitude: 37.48224, longitude: -83.795846, zipCode: "41386"},
	{latitude: 37.410075, longitude: -83.380732, zipCode: "41390"},
	{latitude: 37.672915, longitude: -83.661539, zipCode: "41397"},
	{latitude: 37.802104, longitude: -83.287042, zipCode: "41408"},
	{latitude: 37.992048, longitude: -83.194702, zipCode: "41421"},
	{latitude: 37.887751, longitude: -83.417712, zipCode: "41425"},
	{latitude: 37.649636, longitude: -82.953298, zipCode: "41464"},
	{latitude: 37.729098, longitude: -83.09181, zipCode: "41465"},
	{latitude: 37.944009, longitude: -83.247331, zipCode: "41472"},
	{latitude: 37.507592, longitude: -82.518773, zipCode: "41501"},
	{latitude: 37.66069, longitude: -82.284318, zipCode: "41503"},
	{latitude: 37.252442, longitude: -82.477729, zipCode: "41512"},
	{latitude: 37.352867, longitude: -82.343306, zipCode: "41513"},
	{latitude: 37.66891, longitude: -82.318529, zipCode: "41514"},
	{latitude: 37.189643, longitude: -82.59976, zipCode: "41517"},
	{latitude: 37.583551, longitude: -82.323421, zipCode: "41519"},
	{latitude: 37.322137, longitude: -82.399515, zipCode: "41522"},
	{latitude: 37.433426, longitude: -82.234446, zipCode: "41524"},
	{latitude: 37.433707, longitude: -82.512566, zipCode: "41526"},
	{latitude: 37.631833, longitude: -82.293814, zipCode: "41527"},
	{latitude: 37.554537, longitude: -82.139854, zipCode: "41528"},
	{latitude: 37.595067, longitude: -82.231322, zipCode: "41531"},
	{latitude: 37.277479, longitude: -82.496509, zipCode: "41534"},
	{latitude: 37.593691, longitude: -82.284236, zipCode: "41535"},
	{latitude: 37.222622, longitude: -82.613738, zipCode: "41537"},
	{latitude: 37.303386, longitude: -82.588917, zipCode: "41538"},
	{latitude: 37.50263, longitude: -82.322298, zipCode: "41539"},
	{latitude: 37.394388, longitude: -82.342184, zipCode: "41540"},
	{latitude: 37.548341, longitude: -82.282267, zipCode: "41543"},
	{latitude: 37.595895, longitude: -82.173678, zipCode: "41544"},
	{latitude: 37.53812, longitude: -82.094543, zipCode: "41547"},
	{latitude: 37.374655, longitude: -82.250947, zipCode: "41548"},
	{latitude: 37.48058, longitude: -82.160286, zipCode: "41553"},
	{latitude: 37.430506, longitude: -82.335639, zipCode: "41554"},
	{latitude: 37.538125, longitude: -82.258921, zipCode: "41555"},
	{latitude: 37.486031, longitude: -82.410912, zipCode: "41557"},
	{latitude: 37.539514, longitude: -82.208198, zipCode: "41558"},
	{latitude: 37.380019, longitude: -82.390157, zipCode: "41559"},
	{latitude: 37.394197, longitude: -82.578727, zipCode: "41560"},
	{latitude: 37.401617, longitude: -82.473829, zipCode: "41562"},
	{latitude: 37.223732, longitude: -82.538565, zipCode: "41563"},
	{latitude: 37.604774, longitude: -82.366743, zipCode: "41564"},
	{latitude: 37.408303, longitude: -82.192085, zipCode: "41566"},
	{latitude: 37.56893, longitude: -82.286224, zipCode: "41567"},
	{latitude: 37.502786, longitude: -82.044361, zipCode: "41568"},
	{latitude: 37.626523, longitude: -82.44435, zipCode: "41571"},
	{latitude: 37.313398, longitude: -82.643705, zipCode: "41572"},
	{latitude: 37.608968, longitude: -82.718073, zipCode: "41601"},
	{latitude: 37.745936, longitude: -82.753242, zipCode: "41602"},
	{latitude: 37.571497, longitude: -82.704621, zipCode: "41603"},
	{latitude: 37.385069, longitude: -82.659826, zipCode: "41604"},
	{latitude: 37.555443, longitude: -82.630063, zipCode: "41605"},
	{latitude: 37.338065, longitude: -82.7347, zipCode: "41606"},
	{latitude: 37.625065, longitude: -82.856903, zipCode: "41607"},
	{latitude: 37.354529, longitude: -82.722194, zipCode: "41612"},
	{latitude: 37.537448, longitude: -82.692527, zipCode: "41615"},
	{latitude: 37.566331, longitude: -82.880016, zipCode: "41616"},
	{latitude: 37.479335, longitude: -82.733485, zipCode: "41619"},
	{latitude: 37.625962, longitude: -82.725484, zipCode: "41621"},
	{latitude: 37.51304, longitude: -82.809658, zipCode: "41622"},
	{latitude: 37.44794, longitude: -82.889072, zipCode: "41630"},
	{latitude: 37.468653, longitude: -82.653904, zipCode: "41631"},
	{latitude: 37.564932, longitude: -82.951718, zipCode: "41632"},
	{latitude: 37.4845, longitude: -82.626118, zipCode: "41635"},
	{latitude: 37.400794, longitude: -82.730609, zipCode: "41636"},
	{latitude: 37.472735, longitude: -82.91998, zipCode: "41640"},
	{latitude: 37.592239, longitude: -82.648393, zipCode: "41642"},
	{latitude: 37.434912, longitude: -82.844348, zipCode: "41643"},
	{latitude: 37.528243, longitude: -82.79783, zipCode: "41645"},
	{latitude: 37.432215, longitude: -82.726384, zipCode: "41647"},
	{latitude: 37.570994, longitude: -82.776686, zipCode: "41649"},
	{latitude: 37.341244, longitude: -82.675612, zipCode: "41650"},
	{latitude: 37.467118, longitude: -82.755289, zipCode: "41651"},
	{latitude: 37.657381, longitude: -82.758826, zipCode: "41653"},
	{latitude: 37.512878, longitude: -82.73637, zipCode: "41655"},
	{latitude: 37.587555, longitude: -82.591557, zipCode: "41659"},
	{latitude: 37.414099, longitude: -82.631726, zipCode: "41660"},
	{latitude: 37.567286, longitude: -82.663407, zipCode: "41663"},
	{latitude: 37.440783, longitude: -82.806291, zipCode: "41666"},
	{latitude: 37.310678, longitude: -82.692251, zipCode: "41667"},
	{latitude: 37.333771, longitude: -82.714703, zipCode: "41669"},
	{latitude: 37.295501, longitude: -83.182614, zipCode: "41701"},
	{latitude: 37.37172, longitude: -83.138426, zipCode: "41712"},
	{latitude: 37.22693, longitude: -83.278037, zipCode: "41713"},
	{latitude: 37.163187, longitude: -83.505763, zipCode: "41714"},
	{latitude: 37.296553, longitude: -83.247785, zipCode: "41719"},
	{latitude: 37.324942, longitude: -83.489843, zipCode: "41721"},
	{latitude: 37.408787, longitude: -83.09903, zipCode: "41722"},
	{latitude: 37.261705, longitude: -83.307253, zipCode: "41723"},
	{latitude: 37.317044, longitude: -83.033551, zipCode: "41725"},
	{latitude: 37.377305, longitude: -83.278803, zipCode: "41727"},
	{latitude: 37.269213, longitude: -83.215709, zipCode: "41729"},
	{latitude: 37.094134, longitude: -83.079389, zipCode: "41731"},
	{latitude: 37.01501, longitude: -83.1029, zipCode: "41735"},
	{latitude: 37.320611, longitude: -83.128075, zipCode: "41739"},
	{latitude: 37.356177, longitude: -83.047437, zipCode: "41740"},
	{latitude: 37.34021, longitude: -83.433003, zipCode: "41745"},
	{latitude: 37.213907, longitude: -83.0921, zipCode: "41746"},
	{latitude: 37.217226, longitude: -83.429208, zipCode: "41749"},
	{latitude: 37.218084, longitude: -83.128726, zipCode: "41751"},
	{latitude: 37.304449, longitude: -83.328175, zipCode: "41754"},
	{latitude: 37.231869, longitude: -83.026707, zipCode: "41759"},
	{latitude: 37.194137, longitude: -83.086065, zipCode: "41760"},
	{latitude: 37.207381, longitude: -83.500794, zipCode: "41762"},
	{latitude: 37.054631, longitude: -83.120677, zipCode: "41763"},
	{latitude: 37.119365, longitude: -83.254218, zipCode: "41764"},
	{latitude: 37.180487, longitude: -83.445314, zipCode: "41766"},
	{latitude: 37.407217, longitude: -83.014705, zipCode: "41772"},
	{latitude: 37.26099, longitude: -82.992024, zipCode: "41773"},
	{latitude: 37.15432, longitude: -83.145775, zipCode: "41774"},
	{latitude: 37.100896, longitude: -83.344953, zipCode: "41775"},
	{latitude: 37.185106, longitude: -83.308542, zipCode: "41776"},
	{latitude: 37.051473, longitude: -83.227916, zipCode: "41777"},
	{latitude: 37.266138, longitude: -83.322327, zipCode: "41778"},
	{latitude: 37.1515, longitude: -82.998642, zipCode: "41804"},
	{latitude: 37.184896, longitude: -82.672768, zipCode: "41810"},
	{latitude: 37.251153, longitude: -82.742744, zipCode: "41812"},
	{latitude: 37.153385, longitude: -82.802793, zipCode: "41815"},
	{latitude: 37.368632, longitude: -82.884173, zipCode: "41817"},
	{latitude: 36.987114, longitude: -83.107019, zipCode: "41819"},
	{latitude: 37.059106, longitude: -83.01476, zipCode: "41821"},
	{latitude: 37.31906, longitude: -82.982748, zipCode: "41822"},
	{latitude: 37.186158, longitude: -82.876558, zipCode: "41824"},
	{latitude: 37.231031, longitude: -82.705616, zipCode: "41825"},
	{latitude: 37.188067, longitude: -82.921645, zipCode: "41826"},
	{latitude: 37.288168, longitude: -82.787874, zipCode: "41828"},
	{latitude: 37.387165, longitude: -82.953376, zipCode: "41831"},
	{latitude: 37.169764, longitude: -82.96075, zipCode: "41832"},
	{latitude: 37.026016, longitude: -82.985811, zipCode: "41833"},
	{latitude: 37.252198, longitude: -82.955302, zipCode: "41834"},
	{latitude: 37.221077, longitude: -82.667983, zipCode: "41835"},
	{latitude: 37.258814, longitude: -82.9023, zipCode: "41836"},
	{latitude: 37.125158, longitude: -82.758338, zipCode: "41837"},
	{latitude: 37.203755, longitude: -82.756691, zipCode: "41838"},
	{latitude: 37.430792, longitude: -82.886539, zipCode: "41839"},
	{latitude: 37.198999, longitude: -82.720875, zipCode: "41840"},
	{latitude: 37.275262, longitude: -82.857834, zipCode: "41843"},
	{latitude: 37.314352, longitude: -82.872327, zipCode: "41844"},
	{latitude: 37.141394, longitude: -82.901327, zipCode: "41845"},
	{latitude: 37.202514, longitude: -82.96827, zipCode: "41847"},
	{latitude: 37.116337, longitude: -82.937277, zipCode: "41848"},
	{latitude: 37.173991, longitude: -82.732572, zipCode: "41849"},
	{latitude: 37.174972, longitude: -82.782632, zipCode: "41855"},
	{latitude: 37.136052, longitude: -82.854851, zipCode: "41858"},
	{latitude: 37.387909, longitude: -82.772025, zipCode: "41859"},
	{latitude: 37.38501, longitude: -82.818227, zipCode: "41861"},
	{latitude: 37.342734, longitude: -82.771495, zipCode: "41862"},
	{latitude: 37.030572, longitude: -88.7094, zipCode: "42001"},
	{latitude: 37.004848, longitude: -88.577941, zipCode: "42003"},
	{latitude: 36.686797, longitude: -88.286523, zipCode: "42020"},
	{latitude: 36.805684, longitude: -88.993595, zipCode: "42021"},
	{latitude: 37.144988, longitude: -88.944769, zipCode: "42022"},
	{latitude: 36.872349, longitude: -88.990733, zipCode: "42023"},
	{latitude: 37.077408, longitude: -89.050311, zipCode: "42024"},
	{latitude: 36.859674, longitude: -88.334676, zipCode: "42025"},
	{latitude: 36.919427, longitude: -88.635505, zipCode: "42027"},
	{latitude: 37.238913, longitude: -88.33685, zipCode: "42028"},
	{latitude: 37.012043, longitude: -88.384483, zipCode: "42029"},
	{latitude: 36.685748, longitude: -88.991397, zipCode: "42031"},
	{latitude: 36.742358, longitude: -89.110499, zipCode: "42032"},
	{latitude: 36.90774, longitude: -88.836453, zipCode: "42035"},
	{latitude: 36.712626, longitude: -88.234611, zipCode: "42036"},
	{latitude: 37.158305, longitude: -88.184495, zipCode: "42037"},
	{latitude: 37.047413, longitude: -88.027647, zipCode: "42038"},
	{latitude: 36.788709, longitude: -88.825434, zipCode: "42039"},
	{latitude: 36.607718, longitude: -88.501047, zipCode: "42040"},
	{latitude: 36.550373, longitude: -88.877352, zipCode: "42041"},
	{latitude: 36.957582, longitude: -88.263203, zipCode: "42044"},
	{latitude: 37.074727, longitude: -88.267863, zipCode: "42045"},
	{latitude: 37.29024, longitude: -88.399879, zipCode: "42047"},
	{latitude: 36.752393, longitude: -88.298921, zipCode: "42048"},
	{latitude: 36.517494, longitude: -88.319795, zipCode: "42049"},
	{latitude: 36.536643, longitude: -89.192952, zipCode: "42050"},
	{latitude: 36.859764, longitude: -88.644264, zipCode: "42051"},
	{latitude: 37.112648, longitude: -88.877823, zipCode: "42053"},
	{latitude: 36.722236, longitude: -88.432638, zipCode: "42054"},
	{latitude: 37.075222, longitude: -88.174049, zipCode: "42055"},
	{latitude: 37.107275, longitude: -88.986746, zipCode: "42056"},
	{latitude: 37.058337, longitude: -88.465707, zipCode: "42058"},
	{latitude: 36.965854, longitude: -88.82813, zipCode: "42060"},
	{latitude: 36.882838, longitude: -88.766366, zipCode: "42061"},
	{latitude: 37.344976, longitude: -88.095296, zipCode: "42064"},
	{latitude: 36.732532, longitude: -88.639882, zipCode: "42066"},
	{latitude: 36.918791, longitude: -88.756493, zipCode: "42069"},
	{latitude: 36.619748, longitude: -88.280465, zipCode: "42071"},
	{latitude: 36.571455, longitude: -88.106951, zipCode: "42076"},
	{latitude: 37.277501, longitude: -88.286608, zipCode: "42078"},
	{latitude: 36.541253, longitude: -88.576393, zipCode: "42079"},
	{latitude: 37.235071, longitude: -88.390523, zipCode: "42081"},
	{latitude: 36.917397, longitude: -88.495282, zipCode: "42082"},
	{latitude: 37.161785, longitude: -88.288333, zipCode: "42083"},
	{latitude: 36.569596, longitude: -88.800966, zipCode: "42085"},
	{latitude: 37.082764, longitude: -88.77271, zipCode: "42086"},
	{latitude: 36.973093, longitude: -88.994838, zipCode: "42087"},
	{latitude: 36.616199, longitude: -88.753917, zipCode: "42088"},
	{latitude: 37.058097, longitude: -86.463061, zipCode: "42101"},
	{latitude: 36.965139, longitude: -86.324599, zipCode: "42103"},
	{latitude: 36.876864, longitude: -86.45119, zipCode: "42104"},
	{latitude: 36.675927, longitude: -86.282585, zipCode: "42120"},
	{latitude: 36.85707, longitude: -86.353679, zipCode: "42122"},
	{latitude: 36.820844, longitude: -86.000351, zipCode: "42123"},
	{latitude: 36.86374, longitude: -85.652139, zipCode: "42124"},
	{latitude: 37.12258, longitude: -85.927046, zipCode: "42127"},
	{latitude: 36.989145, longitude: -85.585467, zipCode: "42129"},
	{latitude: 36.925865, longitude: -85.786306, zipCode: "42130"},
	{latitude: 36.829776, longitude: -85.909054, zipCode: "42131"},
	{latitude: 36.726047, longitude: -85.956233, zipCode: "42133"},
	{latitude: 36.726733, longitude: -86.563183, zipCode: "42134"},
	{latitude: 36.656541, longitude: -85.828893, zipCode: "42140"},
	{latitude: 36.939496, longitude: -85.903352, zipCode: "42141"},
	{latitude: 36.659928, longitude: -85.544839, zipCode: "42151"},
	{latitude: 36.688944, longitude: -86.046827, zipCode: "42153"},
	{latitude: 37.041978, longitude: -85.726199, zipCode: "42154"},
	{latitude: 36.86448, longitude: -86.047071, zipCode: "42156"},
	{latitude: 36.781385, longitude: -85.81974, zipCode: "42157"},
	{latitude: 36.996749, longitude: -86.249255, zipCode: "42159"},
	{latitude: 37.11053, longitude: -86.090455, zipCode: "42160"},
	{latitude: 37.079683, longitude: -86.134702, zipCode: "42163"},
	{latitude: 36.770089, longitude: -86.180721, zipCode: "42164"},
	{latitude: 36.873725, longitude: -85.726714, zipCode: "42166"},
	{latitude: 36.71845, longitude: -85.674728, zipCode: "42167"},
	{latitude: 36.856531, longitude: -86.569712, zipCode: "42170"},
	{latitude: 37.036369, longitude: -86.174441, zipCode: "42171"},
	{latitude: 36.682889, longitude: -86.844423, zipCode: "42202"},
	{latitude: 36.69589, longitude: -87.088999, zipCode: "42204"},
	{latitude: 36.886676, longitude: -86.743011, zipCode: "42206"},
	{latitude: 37.293995, longitude: -86.266888, zipCode: "42207"},
	{latitude: 37.214136, longitude: -86.308114, zipCode: "42210"},
	{latitude: 36.817748, longitude: -87.833699, zipCode: "42211"},
	{latitude: 37.127313, longitude: -85.700574, zipCode: "42214"},
	{latitude: 36.993627, longitude: -87.676432, zipCode: "42215"},
	{latitude: 37.046301, longitude: -87.472653, zipCode: "42217"},
	{latitude: 36.907339, longitude: -87.189361, zipCode: "42220"},
	{latitude: 36.639356, longitude: -87.473311, zipCode: "42223"},
	{latitude: 36.855418, longitude: -87.66123, zipCode: "42232"},
	{latitude: 36.721574, longitude: -87.178582, zipCode: "42234"},
	{latitude: 36.71208, longitude: -87.613526, zipCode: "42236"},
	{latitude: 36.880212, longitude: -87.46306, zipCode: "42240"},
	{latitude: 37.2321, longitude: -86.498984, zipCode: "42252"},
	{latitude: 36.661062, longitude: -87.643916, zipCode: "42254"},
	{latitude: 37.01173, longitude: -86.949626, zipCode: "42256"},
	{latitude: 37.229691, longitude: -86.164707, zipCode: "42259"},
	{latitude: 37.218484, longitude: -86.648351, zipCode: "42261"},
	{latitude: 36.690347, longitude: -87.448181, zipCode: "42262"},
	{latitude: 36.739223, longitude: -87.004306, zipCode: "42265"},
	{latitude: 36.744341, longitude: -87.357244, zipCode: "42266"},
	{latitude: 37.204585, longitude: -86.86165, zipCode: "42273"},
	{latitude: 36.950235, longitude: -86.598782, zipCode: "42274"},
	{latitude: 37.233183, longitude: -86.45416, zipCode: "42275"},
	{latitude: 36.859607, longitude: -86.881288, zipCode: "42276"},
	{latitude: 36.956981, longitude: -87.099164, zipCode: "42280"},
	{latitude: 37.264181, longitude: -86.297573, zipCode: "42285"},
	{latitude: 36.727261, longitude: -87.269899, zipCode: "42286"},
	{latitude: 37.735778, longitude: -87.249397, zipCode: "42301"},
	{latitude: 37.75704, longitude: -87.052531, zipCode: "42303"},
	{latitude: 37.332728, longitude: -86.867017, zipCode: "42320"},
	{latitude: 37.181837, longitude: -87.05036, zipCode: "42321"},
	{latitude: 37.62126, longitude: -87.387256, zipCode: "42322"},
	{latitude: 37.173258, longitude: -87.034023, zipCode: "42323"},
	{latitude: 37.141721, longitude: -87.013083, zipCode: "42324"},
	{latitude: 37.316867, longitude: -87.269397, zipCode: "42325"},
	{latitude: 37.235712, longitude: -87.010898, zipCode: "42326"},
	{latitude: 37.58053, longitude: -87.297311, zipCode: "42327"},
	{latitude: 37.431016, longitude: -87.04523, zipCode: "42328"},
	{latitude: 37.314737, longitude: -87.121852, zipCode: "42330"},
	{latitude: 37.250429, longitude: -87.088665, zipCode: "42332"},
	{latitude: 37.381485, longitude: -86.758958, zipCode: "42333"},
	{latitude: 37.200767, longitude: -86.949309, zipCode: "42337"},
	{latitude: 37.554573, longitude: -86.786988, zipCode: "42338"},
	{latitude: 37.121697, longitude: -86.96, zipCode: "42339"},
	{latitude: 37.652848, longitude: -86.693878, zipCode: "42343"},
	{latitude: 37.270857, longitude: -87.317209, zipCode: "42344"},
	{latitude: 37.144971, longitude: -87.199195, zipCode: "42345"},
	{latitude: 37.523846, longitude: -86.890108, zipCode: "42347"},
	{latitude: 37.830925, longitude: -86.774914, zipCode: "42348"},
	{latitude: 37.43031, longitude: -86.677192, zipCode: "42349"},
	{latitude: 37.455012, longitude: -87.186913, zipCode: "42350"},
	{latitude: 37.898577, longitude: -86.866633, zipCode: "42351"},
	{latitude: 37.514811, longitude: -87.0859, zipCode: "42352"},
	{latitude: 37.377978, longitude: -86.930568, zipCode: "42354"},
	{latitude: 37.85631, longitude: -86.993641, zipCode: "42355"},
	{latitude: 37.693614, longitude: -87.322974, zipCode: "42356"},
	{latitude: 37.50831, longitude: -86.70712, zipCode: "42361"},
	{latitude: 37.722748, longitude: -86.938515, zipCode: "42366"},
	{latitude: 37.244222, longitude: -87.158878, zipCode: "42367"},
	{latitude: 37.713326, longitude: -86.753064, zipCode: "42368"},
	{latitude: 37.337839, longitude: -86.988984, zipCode: "42369"},
	{latitude: 37.449977, longitude: -86.741129, zipCode: "42370"},
	{latitude: 37.510021, longitude: -87.289647, zipCode: "42371"},
	{latitude: 37.405167, longitude: -87.277591, zipCode: "42372"},
	{latitude: 37.332691, longitude: -87.139599, zipCode: "42374"},
	{latitude: 37.617755, longitude: -87.085957, zipCode: "42376"},
	{latitude: 37.6547, longitude: -86.874074, zipCode: "42378"},
	{latitude: 37.481982, longitude: -87.837134, zipCode: "42404"},
	{latitude: 37.705965, longitude: -87.689478, zipCode: "42406"},
	{latitude: 37.179537, longitude: -87.692699, zipCode: "42408"},
	{latitude: 37.538637, longitude: -87.692934, zipCode: "42409"},
	{latitude: 37.27637, longitude: -87.510675, zipCode: "42410"},
	{latitude: 37.198292, longitude: -88.010813, zipCode: "42411"},
	{latitude: 37.438482, longitude: -87.463909, zipCode: "42413"},
	{latitude: 37.799192, longitude: -87.516119, zipCode: "42420"},
	{latitude: 37.33757, longitude: -87.475195, zipCode: "42431"},
	{latitude: 37.416086, longitude: -87.564358, zipCode: "42436"},
	{latitude: 37.649263, longitude: -87.915228, zipCode: "42437"},
	{latitude: 37.238676, longitude: -87.470011, zipCode: "42440"},
	{latitude: 37.364757, longitude: -87.655014, zipCode: "42441"},
	{latitude: 37.180096, longitude: -87.484898, zipCode: "42442"},
	{latitude: 37.641551, longitude: -87.645554, zipCode: "42444"},
	{latitude: 37.128177, longitude: -87.857943, zipCode: "42445"},
	{latitude: 37.389214, longitude: -87.776989, zipCode: "42450"},
	{latitude: 37.884533, longitude: -87.390707, zipCode: "42451"},
	{latitude: 37.682717, longitude: -87.540273, zipCode: "42452"},
	{latitude: 37.157856, longitude: -87.590712, zipCode: "42453"},
	{latitude: 37.583729, longitude: -87.533404, zipCode: "42455"},
	{latitude: 37.508419, longitude: -87.510948, zipCode: "42456"},
	{latitude: 37.853465, longitude: -87.430617, zipCode: "42458"},
	{latitude: 37.566806, longitude: -88.019048, zipCode: "42459"},
	{latitude: 37.797397, longitude: -87.889216, zipCode: "42461"},
	{latitude: 37.738452, longitude: -87.792946, zipCode: "42462"},
	{latitude: 37.47262, longitude: -87.869452, zipCode: "42463"},
	{latitude: 37.181253, longitude: -87.356159, zipCode: "42464"},
	{latitude: 37.056193, longitude: -84.450902, zipCode: "42501"},
	{latitude: 37.158036, longitude: -84.519735, zipCode: "42503"},
	{latitude: 37.221659, longitude: -84.784892, zipCode: "42516"},
	{latitude: 36.905952, longitude: -84.639677, zipCode: "42518"},
	{latitude: 36.955307, longitude: -84.53504, zipCode: "42519"},
	{latitude: 37.181802, longitude: -85.023368, zipCode: "42528"},
	{latitude: 37.072195, longitude: -84.592355, zipCode: "42533"},
	{latitude: 37.309082, longitude: -84.950978, zipCode: "42539"},
	{latitude: 37.369065, longitude: -84.807993, zipCode: "42541"},
	{latitude: 37.029948, longitude: -84.815125, zipCode: "42544"},
	{latitude: 37.1803, longitude: -84.697071, zipCode: "42553"},
	{latitude: 37.141549, longitude: -84.906192, zipCode: "42565"},
	{latitude: 37.278532, longitude: -84.783172, zipCode: "42566"},
	{latitude: 37.265935, longitude: -84.604696, zipCode: "42567"},
	{latitude: 36.725956, longitude: -85.121543, zipCode: "42602"},
	{latitude: 36.783616, longitude: -85.014101, zipCode: "42603"},
	{latitude: 36.912644, longitude: -85.138072, zipCode: "42629"},
	{latitude: 36.740393, longitude: -84.480989, zipCode: "42631"},
	{latitude: 36.800772, longitude: -84.82659, zipCode: "42633"},
	{latitude: 36.89317, longitude: -84.422337, zipCode: "42634"},
	{latitude: 36.676247, longitude: -84.403232, zipCode: "42635"},
	{latitude: 36.674414, longitude: -84.469919, zipCode: "42638"},
	{latitude: 37.058681, longitude: -85.035151, zipCode: "42642"},
	{latitude: 36.681253, longitude: -84.627337, zipCode: "42647"},
	{latitude: 36.626483, longitude: -84.415013, zipCode: "42649"},
	{latitude: 36.782112, longitude: -84.454722, zipCode: "42653"},
	{latitude: 37.703239, longitude: -85.839548, zipCode: "42701"},
	{latitude: 37.55923, longitude: -86.157535, zipCode: "42712"},
	{latitude: 37.375146, longitude: -85.872711, zipCode: "42713"},
	{latitude: 36.939751, longitude: -85.405186, zipCode: "42715"},
	{latitude: 37.492233, longitude: -85.675203, zipCode: "42716"},
	{latitude: 36.782372, longitude: -85.388484, zipCode: "42717"},
	{latitude: 37.390302, longitude: -85.380067, zipCode: "42718"},
	{latitude: 37.42298, longitude: -86.499732, zipCode: "42721"},
	{latitude: 37.276586, longitude: -85.716132, zipCode: "42722"},
	{latitude: 37.675918, longitude: -86.070835, zipCode: "42724"},
	{latitude: 37.419922, longitude: -86.152108, zipCode: "42726"},
	{latitude: 37.117031, longitude: -85.29007, zipCode: "42728"},
	{latitude: 37.306467, longitude: -86.096497, zipCode: "42729"},
	{latitude: 37.613509, longitude: -86.101848, zipCode: "42732"},
	{latitude: 37.343603, longitude: -85.18643, zipCode: "42733"},
	{latitude: 37.586356, longitude: -85.948741, zipCode: "42740"},
	{latitude: 36.975229, longitude: -85.255401, zipCode: "42741"},
	{latitude: 37.076439, longitude: -85.473074, zipCode: "42742"},
	{latitude: 37.228307, longitude: -85.532268, zipCode: "42743"},
	{latitude: 37.212381, longitude: -85.732393, zipCode: "42746"},
	{latitude: 37.566719, longitude: -85.694069, zipCode: "42748"},
	{latitude: 37.183834, longitude: -85.877046, zipCode: "42749"},
	{latitude: 37.235091, longitude: -85.158997, zipCode: "42753"},
	{latitude: 37.478269, longitude: -86.314913, zipCode: "42754"},
	{latitude: 37.410389, longitude: -85.74099, zipCode: "42757"},
	{latitude: 37.374757, longitude: -85.201006, zipCode: "42758"},
	{latitude: 36.828162, longitude: -85.51444, zipCode: "42759"},
	{latitude: 37.455344, longitude: -86.39315, zipCode: "42762"},
	{latitude: 37.429579, longitude: -85.620541, zipCode: "42764"},
	{latitude: 37.306668, longitude: -85.934608, zipCode: "42765"},
	{latitude: 37.514506, longitude: -85.92709, zipCode: "42776"},
	{latitude: 37.336817, longitude: -85.643034, zipCode: "42782"},
	{latitude: 37.451992, longitude: -85.932394, zipCode: "42784"},
	{latitude: 37.529664, longitude: -86.027886, zipCode: "42788"},
	{latitude: 40.088034, longitude: -82.61393, zipCode: "43001"},
	{latitude: 40.057262, longitude: -83.168467, zipCode: "43002"},
	{latitude: 40.41354, longitude: -82.969322, zipCode: "43003"},
	{latitude: 40.015374, longitude: -82.804489, zipCode: "43004"},
	{latitude: 40.281612, longitude: -82.269007, zipCode: "43005"},
	{latitude: 40.459754, longitude: -82.149323, zipCode: "43006"},
	{latitude: 40.330881, longitude: -83.401894, zipCode: "43007"},
	{latitude: 39.933044, longitude: -82.483203, zipCode: "43008"},
	{latitude: 40.171458, longitude: -83.639322, zipCode: "43009"},
	{latitude: 39.999627, longitude: -83.621903, zipCode: "43010"},
	{latitude: 40.303039, longitude: -82.679642, zipCode: "43011"},
	{latitude: 40.230616, longitude: -82.68937, zipCode: "43013"},
	{latitude: 40.46989, longitude: -82.249623, zipCode: "43014"},
	{latitude: 40.297783, longitude: -83.060168, zipCode: "43015"},
	{latitude: 40.097462, longitude: -83.150204, zipCode: "43016"},
	{latitude: 40.11868, longitude: -83.133129, zipCode: "43017"},
	{latitude: 39.953801, longitude: -82.676136, zipCode: "43018"},
	{latitude: 40.501987, longitude: -82.593233, zipCode: "43019"},
	{latitude: 40.205042, longitude: -82.876523, zipCode: "43021"},
	{latitude: 40.345042, longitude: -82.339812, zipCode: "43022"},
	{latitude: 40.075537, longitude: -82.529776, zipCode: "43023"},
	{latitude: 39.970889, longitude: -82.517382, zipCode: "43025"},
	{latitude: 40.020519, longitude: -83.177502, zipCode: "43026"},
	{latitude: 40.253735, longitude: -82.522816, zipCode: "43027"},
	{latitude: 40.401712, longitude: -82.297266, zipCode: "43028"},
	{latitude: 40.097477, longitude: -83.440642, zipCode: "43029"},
	{latitude: 39.969139, longitude: -82.40575, zipCode: "43030"},
	{latitude: 40.162786, longitude: -82.663517, zipCode: "43031"},
	{latitude: 40.328595, longitude: -82.958098, zipCode: "43032"},
	{latitude: 39.961234, longitude: -82.599664, zipCode: "43033"},
	{latitude: 40.18597, longitude: -82.996343, zipCode: "43035"},
	{latitude: 40.352136, longitude: -83.263182, zipCode: "43036"},
	{latitude: 40.284431, longitude: -82.32319, zipCode: "43037"},
	{latitude: 40.261305, longitude: -83.358138, zipCode: "43040"},
	{latitude: 40.054878, longitude: -83.550817, zipCode: "43044"},
	{latitude: 40.168303, longitude: -83.458762, zipCode: "43045"},
	{latitude: 39.901754, longitude: -82.540412, zipCode: "43046"},
	{latitude: 40.207958, longitude: -83.637975, zipCode: "43047"},
	{latitude: 40.373976, longitude: -82.492721, zipCode: "43050"},
	{latitude: 40.070261, longitude: -82.802113, zipCode: "43054"},
	{latitude: 40.110508, longitude: -82.378699, zipCode: "43055"},
	{latitude: 40.009034, longitude: -82.331779, zipCode: "43056"},
	{latitude: 40.222358, longitude: -83.571509, zipCode: "43060"},
	{latitude: 40.289506, longitude: -83.203052, zipCode: "43061"},
	{latitude: 40.008131, longitude: -82.685267, zipCode: "43062"},
	{latitude: 40.098492, longitude: -83.288172, zipCode: "43064"},
	{latitude: 40.177567, longitude: -83.094156, zipCode: "43065"},
	{latitude: 40.402576, longitude: -83.172895, zipCode: "43066"},
	{latitude: 40.343049, longitude: -83.48386, zipCode: "43067"},
	{latitude: 39.956185, longitude: -82.784665, zipCode: "43068"},
	{latitude: 40.220585, longitude: -83.956489, zipCode: "43070"},
	{latitude: 40.180695, longitude: -82.359463, zipCode: "43071"},
	{latitude: 40.115162, longitude: -83.954818, zipCode: "43072"},
	{latitude: 40.269531, longitude: -82.850655, zipCode: "43074"},
	{latitude: 39.896193, longitude: -82.391194, zipCode: "43076"},
	{latitude: 40.13705, longitude: -83.341267, zipCode: "43077"},
	{latitude: 40.117636, longitude: -83.783785, zipCode: "43078"},
	{latitude: 40.240719, longitude: -82.429257, zipCode: "43080"},
	{latitude: 40.110443, longitude: -82.890646, zipCode: "43081"},
	{latitude: 40.155571, longitude: -82.885108, zipCode: "43082"},
	{latitude: 40.150137, longitude: -83.557264, zipCode: "43084"},
	{latitude: 40.100924, longitude: -83.013402, zipCode: "43085"},
	{latitude: 39.464497, longitude: -82.746107, zipCode: "43101"},
	{latitude: 39.64092, longitude: -82.764982, zipCode: "43102"},
	{latitude: 39.732375, longitude: -82.935708, zipCode: "43103"},
	{latitude: 39.86676, longitude: -82.612369, zipCode: "43105"},
	{latitude: 39.644806, longitude: -83.418536, zipCode: "43106"},
	{latitude: 39.692825, longitude: -82.414529, zipCode: "43107"},
	{latitude: 39.917249, longitude: -82.831886, zipCode: "43109"},
	{latitude: 39.826682, longitude: -82.800074, zipCode: "43110"},
	{latitude: 39.502067, longitude: -82.2427, zipCode: "43111"},
	{latitude: 39.79278, longitude: -82.698409, zipCode: "43112"},
	{latitude: 39.58659, longitude: -82.962245, zipCode: "43113"},
	{latitude: 39.489578, longitude: -83.168956, zipCode: "43115"},
	{latitude: 39.772755, longitude: -83.062758, zipCode: "43116"},
	{latitude: 39.768736, longitude: -83.206074, zipCode: "43117"},
	{latitude: 39.940928, longitude: -83.205719, zipCode: "43119"},
	{latitude: 39.866721, longitude: -83.115561, zipCode: "43123"},
	{latitude: 39.838052, longitude: -82.887941, zipCode: "43125"},
	{latitude: 39.810726, longitude: -83.168258, zipCode: "43126"},
	{latitude: 39.475597, longitude: -82.322298, zipCode: "43127"},
	{latitude: 39.659641, longitude: -83.593633, zipCode: "43128"},
	{latitude: 39.696463, longitude: -82.614717, zipCode: "43130"},
	{latitude: 39.472913, longitude: -82.713001, zipCode: "43135"},
	{latitude: 39.801631, longitude: -82.815387, zipCode: "43136"},
	{latitude: 39.8089, longitude: -82.987294, zipCode: "43137"},
	{latitude: 39.51834, longitude: -82.414097, zipCode: "43138"},
	{latitude: 39.880108, longitude: -83.428512, zipCode: "43140"},
	{latitude: 39.592923, longitude: -83.587788, zipCode: "43142"},
	{latitude: 39.712438, longitude: -83.288721, zipCode: "43143"},
	{latitude: 39.52988, longitude: -82.195044, zipCode: "43144"},
	{latitude: 39.56226, longitude: -83.260058, zipCode: "43145"},
	{latitude: 39.773521, longitude: -83.136925, zipCode: "43146"},
	{latitude: 39.897782, longitude: -82.744035, zipCode: "43147"},
	{latitude: 39.820316, longitude: -82.499992, zipCode: "43148"},
	{latitude: 39.542974, longitude: -82.577874, zipCode: "43149"},
	{latitude: 39.777096, longitude: -82.409813, zipCode: "43150"},
	{latitude: 39.733173, longitude: -83.476623, zipCode: "43151"},
	{latitude: 39.394275, longitude: -82.615932, zipCode: "43152"},
	{latitude: 39.743989, longitude: -83.556692, zipCode: "43153"},
	{latitude: 39.60391, longitude: -82.829648, zipCode: "43154"},
	{latitude: 39.627314, longitude: -82.535203, zipCode: "43155"},
	{latitude: 39.553806, longitude: -82.776293, zipCode: "43156"},
	{latitude: 39.843016, longitude: -82.546824, zipCode: "43157"},
	{latitude: 39.453047, longitude: -82.351192, zipCode: "43158"},
	{latitude: 39.52874, longitude: -83.439186, zipCode: "43160"},
	{latitude: 39.9494, longitude: -83.308423, zipCode: "43162"},
	{latitude: 39.583733, longitude: -83.119834, zipCode: "43164"},
	{latitude: 39.990881, longitude: -83.00116, zipCode: "43201"},
	{latitude: 40.019403, longitude: -83.014471, zipCode: "43202"},
	{latitude: 39.972934, longitude: -82.969167, zipCode: "43203"},
	{latitude: 39.960662, longitude: -83.082542, zipCode: "43204"},
	{latitude: 39.957342, longitude: -82.962019, zipCode: "43205"},
	{latitude: 39.942452, longitude: -82.974175, zipCode: "43206"},
	{latitude: 39.895414, longitude: -82.963853, zipCode: "43207"},
	{latitude: 39.953588, longitude: -82.929524, zipCode: "43209"},
	{latitude: 40.006425, longitude: -83.02212, zipCode: "43210"},
	{latitude: 40.011814, longitude: -82.970532, zipCode: "43211"},
	{latitude: 39.986564, longitude: -83.043198, zipCode: "43212"},
	{latitude: 39.971201, longitude: -82.863958, zipCode: "43213"},
	{latitude: 40.051734, longitude: -83.016269, zipCode: "43214"},
	{latitude: 39.966856, longitude: -83.01296, zipCode: "43215"},
	{latitude: 39.816946, longitude: -82.934089, zipCode: "43217"},
	{latitude: 40.017566, longitude: -82.923723, zipCode: "43218"},
	{latitude: 40.007373, longitude: -82.920654, zipCode: "43219"},
	{latitude: 40.048905, longitude: -83.072189, zipCode: "43220"},
	{latitude: 40.023112, longitude: -83.076127, zipCode: "43221"},
	{latitude: 39.961192, longitude: -83.035484, zipCode: "43222"},
	{latitude: 39.926237, longitude: -83.03305, zipCode: "43223"},
	{latitude: 40.042251, longitude: -82.964434, zipCode: "43224"},
	{latitude: 39.943213, longitude: -82.887646, zipCode: "43227"},
	{latitude: 39.964019, longitude: -83.127747, zipCode: "43228"},
	{latitude: 40.08587, longitude: -82.978197, zipCode: "43229"},
	{latitude: 40.038143, longitude: -82.872725, zipCode: "43230"},
	{latitude: 40.079392, longitude: -82.936858, zipCode: "43231"},
	{latitude: 39.920977, longitude: -82.870372, zipCode: "43232"},
	{latitude: 40.078722, longitude: -83.082473, zipCode: "43235"},
	{latitude: 40.145121, longitude: -82.98282, zipCode: "43240"},
	{latitude: 40.599237, longitude: -83.130298, zipCode: "43302"},
	{latitude: 40.532533, longitude: -83.779913, zipCode: "43310"},
	{latitude: 40.36441, longitude: -83.758426, zipCode: "43311"},
	{latitude: 40.647409, longitude: -82.961225, zipCode: "43314"},
	{latitude: 40.483968, longitude: -82.874359, zipCode: "43315"},
	{latitude: 40.951821, longitude: -83.384958, zipCode: "43316"},
	{latitude: 40.475145, longitude: -82.680931, zipCode: "43317"},
	{latitude: 40.309647, longitude: -83.913182, zipCode: "43318"},
	{latitude: 40.302682, longitude: -83.57512, zipCode: "43319"},
	{latitude: 40.588576, longitude: -82.899922, zipCode: "43320"},
	{latitude: 40.463053, longitude: -82.829033, zipCode: "43321"},
	{latitude: 40.532546, longitude: -83.208117, zipCode: "43322"},
	{latitude: 40.732133, longitude: -83.254734, zipCode: "43323"},
	{latitude: 40.444543, longitude: -83.813798, zipCode: "43324"},
	{latitude: 40.668063, longitude: -82.831265, zipCode: "43325"},
	{latitude: 40.64144, longitude: -83.614776, zipCode: "43326"},
	{latitude: 40.813493, longitude: -83.419408, zipCode: "43330"},
	{latitude: 40.517943, longitude: -83.921643, zipCode: "43331"},
	{latitude: 40.590903, longitude: -83.371544, zipCode: "43332"},
	{latitude: 40.432461, longitude: -83.923361, zipCode: "43333"},
	{latitude: 40.397947, longitude: -82.797304, zipCode: "43334"},
	{latitude: 40.283079, longitude: -83.576613, zipCode: "43336"},
	{latitude: 40.681312, longitude: -83.266637, zipCode: "43337"},
	{latitude: 40.564167, longitude: -82.760333, zipCode: "43338"},
	{latitude: 40.53031, longitude: -83.497923, zipCode: "43340"},
	{latitude: 40.595756, longitude: -83.315445, zipCode: "43341"},
	{latitude: 40.471236, longitude: -83.18398, zipCode: "43342"},
	{latitude: 40.305092, longitude: -83.995255, zipCode: "43343"},
	{latitude: 40.430934, longitude: -83.344615, zipCode: "43344"},
	{latitude: 40.508496, longitude: -83.586161, zipCode: "43345"},
	{latitude: 40.576822, longitude: -83.845209, zipCode: "43346"},
	{latitude: 40.477715, longitude: -83.658043, zipCode: "43347"},
	{latitude: 40.461198, longitude: -83.891401, zipCode: "43348"},
	{latitude: 40.395564, longitude: -82.699799, zipCode: "43350"},
	{latitude: 40.827604, longitude: -83.314046, zipCode: "43351"},
	{latitude: 40.45373, longitude: -83.041951, zipCode: "43356"},
	{latitude: 40.253523, longitude: -83.746774, zipCode: "43357"},
	{latitude: 40.41459, longitude: -83.537606, zipCode: "43358"},
	{latitude: 40.869767, longitude: -83.45318, zipCode: "43359"},
	{latitude: 40.333139, longitude: -83.643525, zipCode: "43360"},
	{latitude: 41.4097, longitude: -83.653452, zipCode: "43402"},
	{latitude: 41.377844, longitude: -83.636555, zipCode: "43403"},
	{latitude: 41.335764, longitude: -83.438605, zipCode: "43406"},
	{latitude: 41.277159, longitude: -83.249945, zipCode: "43407"},
	{latitude: 41.571834, longitude: -83.363479, zipCode: "43408"},
	{latitude: 41.31174, longitude: -82.95793, zipCode: "43410"},
	{latitude: 41.631291, longitude: -83.321889, zipCode: "43412"},
	{latitude: 41.247677, longitude: -83.660498, zipCode: "43413"},
	{latitude: 41.451403, longitude: -83.617162, zipCode: "43414"},
	{latitude: 41.483937, longitude: -83.267229, zipCode: "43416"},
	{latitude: 41.357927, longitude: -83.110237, zipCode: "43420"},
	{latitude: 41.524117, longitude: -83.375186, zipCode: "43430"},
	{latitude: 41.391809, longitude: -83.343718, zipCode: "43431"},
	{latitude: 41.563259, longitude: -83.252471, zipCode: "43432"},
	{latitude: 41.504621, longitude: -82.880336, zipCode: "43433"},
	{latitude: 41.693283, longitude: -83.444751, zipCode: "43434"},
	{latitude: 41.326332, longitude: -83.312052, zipCode: "43435"},
	{latitude: 41.713539, longitude: -82.826002, zipCode: "43436"},
	{latitude: 41.253957, longitude: -83.601779, zipCode: "43437"},
	{latitude: 41.602607, longitude: -82.706506, zipCode: "43438"},
	{latitude: 41.517587, longitude: -83.042752, zipCode: "43439"},
	{latitude: 41.515143, longitude: -82.771086, zipCode: "43440"},
	{latitude: 41.425041, longitude: -83.215825, zipCode: "43442"},
	{latitude: 41.461331, longitude: -83.470237, zipCode: "43443"},
	{latitude: 41.556616, longitude: -83.308937, zipCode: "43445"},
	{latitude: 41.673836, longitude: -82.80989, zipCode: "43446"},
	{latitude: 41.567683, longitude: -83.433162, zipCode: "43447"},
	{latitude: 41.534376, longitude: -83.13557, zipCode: "43449"},
	{latitude: 41.400871, longitude: -83.491254, zipCode: "43450"},
	{latitude: 41.318294, longitude: -83.618453, zipCode: "43451"},
	{latitude: 41.506464, longitude: -82.953329, zipCode: "43452"},
	{latitude: 41.650592, longitude: -82.826828, zipCode: "43456"},
	{latitude: 41.267878, longitude: -83.425065, zipCode: "43457"},
	{latitude: 41.530446, longitude: -83.213365, zipCode: "43458"},
	{latitude: 41.604817, longitude: -83.561032, zipCode: "43460"},
	{latitude: 41.283785, longitude: -83.722865, zipCode: "43462"},
	{latitude: 41.511458, longitude: -83.505088, zipCode: "43463"},
	{latitude: 41.415984, longitude: -82.922143, zipCode: "43464"},
	{latitude: 41.565472, longitude: -83.500302, zipCode: "43465"},
	{latitude: 41.29233, longitude: -83.513933, zipCode: "43466"},
	{latitude: 41.243263, longitude: -83.490878, zipCode: "43467"},
	{latitude: 41.599737, longitude: -83.34662, zipCode: "43468"},
	{latitude: 41.460369, longitude: -83.36455, zipCode: "43469"},
	{latitude: 41.672655, longitude: -84.461796, zipCode: "43501"},
	{latitude: 41.532649, longitude: -84.303124, zipCode: "43502"},
	{latitude: 41.697526, longitude: -83.834178, zipCode: "43504"},
	{latitude: 41.524228, longitude: -84.730909, zipCode: "43505"},
	{latitude: 41.466211, longitude: -84.557133, zipCode: "43506"},
	{latitude: 41.464838, longitude: -83.9471, zipCode: "43510"},
	{latitude: 41.2638, longitude: -83.823732, zipCode: "43511"},
	{latitude: 41.303378, longitude: -84.358279, zipCode: "43512"},
	{latitude: 41.586912, longitude: -84.010042, zipCode: "43515"},
	{latitude: 41.214903, longitude: -83.915242, zipCode: "43516"},
	{latitude: 41.433945, longitude: -84.72855, zipCode: "43517"},
	{latitude: 41.596488, longitude: -84.757748, zipCode: "43518"},
	{latitude: 41.419465, longitude: -84.406872, zipCode: "43519"},
	{latitude: 41.380047, longitude: -84.635669, zipCode: "43520"},
	{latitude: 41.660489, longitude: -84.297994, zipCode: "43521"},
	{latitude: 41.42031, longitude: -83.832402, zipCode: "43522"},
	{latitude: 41.338748, longitude: -83.998652, zipCode: "43523"},
	{latitude: 41.227059, longitude: -84.038511, zipCode: "43524"},
	{latitude: 41.466681, longitude: -83.701137, zipCode: "43525"},
	{latitude: 41.312072, longitude: -84.730847, zipCode: "43526"},
	{latitude: 41.246109, longitude: -84.157502, zipCode: "43527"},
	{latitude: 41.628855, longitude: -83.749575, zipCode: "43528"},
	{latitude: 41.1904, longitude: -83.78491, zipCode: "43529"},
	{latitude: 41.327921, longitude: -84.289447, zipCode: "43530"},
	{latitude: 41.635044, longitude: -84.495666, zipCode: "43531"},
	{latitude: 41.449074, longitude: -83.964344, zipCode: "43532"},
	{latitude: 41.691874, longitude: -84.081534, zipCode: "43533"},
	{latitude: 41.349611, longitude: -83.926981, zipCode: "43534"},
	{latitude: 41.305899, longitude: -84.024153, zipCode: "43535"},
	{latitude: 41.313445, longitude: -84.634004, zipCode: "43536"},
	{latitude: 41.574823, longitude: -83.686622, zipCode: "43537"},
	{latitude: 41.68871, longitude: -83.944492, zipCode: "43540"},
	{latitude: 41.300739, longitude: -83.829661, zipCode: "43541"},
	{latitude: 41.566408, longitude: -83.765308, zipCode: "43542"},
	{latitude: 41.606489, longitude: -84.645346, zipCode: "43543"},
	{latitude: 41.392249, longitude: -84.128288, zipCode: "43545"},
	{latitude: 41.49216, longitude: -83.876316, zipCode: "43547"},
	{latitude: 41.188665, longitude: -84.172959, zipCode: "43548"},
	{latitude: 41.369314, longitude: -84.531144, zipCode: "43549"},
	{latitude: 41.518596, longitude: -83.579118, zipCode: "43551"},
	{latitude: 41.530662, longitude: -84.217902, zipCode: "43553"},
	{latitude: 41.658421, longitude: -84.563491, zipCode: "43554"},
	{latitude: 41.437778, longitude: -84.25579, zipCode: "43555"},
	{latitude: 41.303304, longitude: -84.563729, zipCode: "43556"},
	{latitude: 41.487398, longitude: -84.392482, zipCode: "43557"},
	{latitude: 41.593972, longitude: -83.878653, zipCode: "43558"},
	{latitude: 41.700591, longitude: -83.738852, zipCode: "43560"},
	{latitude: 41.420445, longitude: -83.740106, zipCode: "43565"},
	{latitude: 41.491623, longitude: -83.756052, zipCode: "43566"},
	{latitude: 41.580345, longitude: -84.161065, zipCode: "43567"},
	{latitude: 41.349767, longitude: -83.789426, zipCode: "43569"},
	{latitude: 41.586056, longitude: -84.441036, zipCode: "43570"},
	{latitude: 41.514181, longitude: -83.813649, zipCode: "43571"},
	{latitude: 41.652389, longitude: -83.538897, zipCode: "43604"},
	{latitude: 41.648026, longitude: -83.506863, zipCode: "43605"},
	{latitude: 41.673136, longitude: -83.611414, zipCode: "43606"},
	{latitude: 41.648634, longitude: -83.604132, zipCode: "43607"},
	{latitude: 41.679872, longitude: -83.528913, zipCode: "43608"},
	{latitude: 41.628256, longitude: -83.57868, zipCode: "43609"},
	{latitude: 41.677783, longitude: -83.561453, zipCode: "43610"},
	{latitude: 41.703247, longitude: -83.486642, zipCode: "43611"},
	{latitude: 41.715635, longitude: -83.550946, zipCode: "43612"},
	{latitude: 41.706228, longitude: -83.603714, zipCode: "43613"},
	{latitude: 41.60446, longitude: -83.627515, zipCode: "43614"},
	{latitude: 41.649728, longitude: -83.671959, zipCode: "43615"},
	{latitude: 41.658372, longitude: -83.408909, zipCode: "43616"},
	{latitude: 41.664195, longitude: -83.728053, zipCode: "43617"},
	{latitude: 41.603711, longitude: -83.47049, zipCode: "43619"},
	{latitude: 41.665178, longitude: -83.554722, zipCode: "43620"},
	{latitude: 41.702956, longitude: -83.650077, zipCode: "43623"},
	{latitude: 39.963961, longitude: -81.995711, zipCode: "43701"},
	{latitude: 39.841865, longitude: -81.577973, zipCode: "43711"},
	{latitude: 39.989862, longitude: -81.173715, zipCode: "43713"},
	{latitude: 39.829224, longitude: -81.011909, zipCode: "43716"},
	{latitude: 39.789327, longitude: -81.554711, zipCode: "43717"},
	{latitude: 40.015007, longitude: -80.998096, zipCode: "43718"},
	{latitude: 39.99551, longitude: -81.09328, zipCode: "43719"},
	{latitude: 39.796093, longitude: -81.876456, zipCode: "43720"},
	{latitude: 39.949259, longitude: -82.260167, zipCode: "43721"},
	{latitude: 39.919311, longitude: -81.519962, zipCode: "43722"},
	{latitude: 39.960762, longitude: -81.541728, zipCode: "43723"},
	{latitude: 39.720894, longitude: -81.498682, zipCode: "43724"},
	{latitude: 40.039607, longitude: -81.601202, zipCode: "43725"},
	{latitude: 39.875689, longitude: -81.800241, zipCode: "43727"},
	{latitude: 39.480162, longitude: -81.895132, zipCode: "43728"},
	{latitude: 39.639168, longitude: -82.092226, zipCode: "43730"},
	{latitude: 39.733139, longitude: -82.058314, zipCode: "43731"},
	{latitude: 39.842747, longitude: -81.659465, zipCode: "43732"},
	{latitude: 39.927387, longitude: -81.538035, zipCode: "43733"},
	{latitude: 39.882489, longitude: -81.913656, zipCode: "43734"},
	{latitude: 39.857167, longitude: -82.114141, zipCode: "43735"},
	{latitude: 40.057683, longitude: -81.236607, zipCode: "43736"},
	{latitude: 39.855347, longitude: -82.140488, zipCode: "43738"},
	{latitude: 39.903136, longitude: -82.28631, zipCode: "43739"},
	{latitude: 39.951429, longitude: -82.217201, zipCode: "43740"},
	{latitude: 39.965416, longitude: -82.183858, zipCode: "43746"},
	{latitude: 39.860809, longitude: -81.124896, zipCode: "43747"},
	{latitude: 39.692383, longitude: -82.316294, zipCode: "43748"},
	{latitude: 40.155641, longitude: -81.555888, zipCode: "43749"},
	{latitude: 39.996873, longitude: -81.513176, zipCode: "43750"},
	{latitude: 39.753956, longitude: -81.243275, zipCode: "43754"},
	{latitude: 40.053294, longitude: -81.432363, zipCode: "43755"},
	{latitude: 39.691749, longitude: -81.795492, zipCode: "43756"},
	{latitude: 39.627856, longitude: -81.94544, zipCode: "43758"},
	{latitude: 40.063059, longitude: -81.070519, zipCode: "43759"},
	{latitude: 39.888265, longitude: -82.193224, zipCode: "43760"},
	{latitude: 39.66833, longitude: -82.148252, zipCode: "43761"},
	{latitude: 40.029956, longitude: -81.739992, zipCode: "43762"},
	{latitude: 39.713305, longitude: -82.180583, zipCode: "43764"},
	{latitude: 39.593199, longitude: -82.252838, zipCode: "43766"},
	{latitude: 40.014251, longitude: -81.80783, zipCode: "43767"},
	{latitude: 40.037761, longitude: -81.444709, zipCode: "43768"},
	{latitude: 39.835562, longitude: -81.945402, zipCode: "43771"},
	{latitude: 39.88606, longitude: -81.526625, zipCode: "43772"},
	{latitude: 39.982044, longitude: -81.298757, zipCode: "43773"},
	{latitude: 39.829116, longitude: -82.104103, zipCode: "43777"},
	{latitude: 40.010159, longitude: -81.368797, zipCode: "43778"},
	{latitude: 39.81957, longitude: -81.427766, zipCode: "43779"},
	{latitude: 39.908693, longitude: -81.437952, zipCode: "43780"},
	{latitude: 39.621795, longitude: -82.212467, zipCode: "43782"},
	{latitude: 39.80448, longitude: -82.278581, zipCode: "43783"},
	{latitude: 39.710868, longitude: -81.277046, zipCode: "43786"},
	{latitude: 39.523964, longitude: -81.801822, zipCode: "43787"},
	{latitude: 39.805526, longitude: -81.333948, zipCode: "43788"},
	{latitude: 39.877616, longitude: -82.08858, zipCode: "43791"},
	{latitude: 39.745149, longitude: -81.096102, zipCode: "43793"},
	{latitude: 40.091161, longitude: -81.852057, zipCode: "43802"},
	{latitude: 40.450845, longitude: -81.740914, zipCode: "43804"},
	{latitude: 40.389985, longitude: -81.971796, zipCode: "43805"},
	{latitude: 40.192157, longitude: -81.943009, zipCode: "43811"},
	{latitude: 40.263839, longitude: -81.880915, zipCode: "43812"},
	{latitude: 40.136752, longitude: -82.000512, zipCode: "43821"},
	{latitude: 40.181432, longitude: -82.158408, zipCode: "43822"},
	{latitude: 40.36391, longitude: -81.758449, zipCode: "43824"},
	{latitude: 40.06282, longitude: -82.137472, zipCode: "43830"},
	{latitude: 40.27335, longitude: -81.578178, zipCode: "43832"},
	{latitude: 40.208699, longitude: -81.718579, zipCode: "43836"},
	{latitude: 40.298402, longitude: -81.47793, zipCode: "43837"},
	{latitude: 40.403581, longitude: -81.603858, zipCode: "43840"},
	{latitude: 40.137444, longitude: -82.011816, zipCode: "43842"},
	{latitude: 40.349011, longitude: -82.17561, zipCode: "43843"},
	{latitude: 40.333655, longitude: -82.051059, zipCode: "43844"},
	{latitude: 40.264908, longitude: -81.731279, zipCode: "43845"},
	{latitude: 40.216723, longitude: -80.866199, zipCode: "43901"},
	{latitude: 39.88285, longitude: -80.951993, zipCode: "43902"},
	{latitude: 40.472134, longitude: -80.973297, zipCode: "43903"},
	{latitude: 40.11417, longitude: -80.833284, zipCode: "43905"},
	{latitude: 40.011095, longitude: -80.808694, zipCode: "43906"},
	{latitude: 40.248449, longitude: -81.019659, zipCode: "43907"},
	{latitude: 40.506269, longitude: -80.874019, zipCode: "43908"},
	{latitude: 40.067659, longitude: -80.814932, zipCode: "43909"},
	{latitude: 40.372682, longitude: -80.826332, zipCode: "43910"},
	{latitude: 40.090804, longitude: -80.79258, zipCode: "43912"},
	{latitude: 40.26823, longitude: -80.637584, zipCode: "43913"},
	{latitude: 39.775225, longitude: -80.952806, zipCode: "43914"},
	{latitude: 39.745732, longitude: -80.931569, zipCode: "43915"},
	{latitude: 40.215338, longitude: -80.792808, zipCode: "43917"},
	{latitude: 40.677227, longitude: -80.594299, zipCode: "43920"},
	{latitude: 40.445981, longitude: -80.862313, zipCode: "43925"},
	{latitude: 40.509103, longitude: -80.621739, zipCode: "43926"},
	{latitude: 40.013155, longitude: -80.882866, zipCode: "43928"},
	{latitude: 40.563211, longitude: -80.762834, zipCode: "43930"},
	{latitude: 39.672931, longitude: -80.872071, zipCode: "43931"},
	{latitude: 40.528597, longitude: -80.746253, zipCode: "43932"},
	{latitude: 39.935227, longitude: -80.8958, zipCode: "43933"},
	{latitude: 40.076093, longitude: -80.793756, zipCode: "43934"},
	{latitude: 40.120281, longitude: -80.739832, zipCode: "43935"},
	{latitude: 40.298534, longitude: -80.674994, zipCode: "43938"},
	{latitude: 40.16717, longitude: -80.80002, zipCode: "43939"},
	{latitude: 40.028974, longitude: -80.827299, zipCode: "43940"},
	{latitude: 39.861027, longitude: -80.854883, zipCode: "43942"},
	{latitude: 40.208931, longitude: -80.732289, zipCode: "43943"},
	{latitude: 40.436037, longitude: -80.771727, zipCode: "43944"},
	{latitude: 40.631988, longitude: -80.850107, zipCode: "43945"},
	{latitude: 39.648277, longitude: -80.974823, zipCode: "43946"},
	{latitude: 39.955025, longitude: -80.791533, zipCode: "43947"},
	{latitude: 40.268346, longitude: -80.779965, zipCode: "43948"},
	{latitude: 40.096948, longitude: -80.924704, zipCode: "43950"},
	{latitude: 40.114361, longitude: -81.011812, zipCode: "43951"},
	{latitude: 40.409127, longitude: -80.665297, zipCode: "43952"},
	{latitude: 40.352415, longitude: -80.702489, zipCode: "43953"},
	{latitude: 40.523512, longitude: -80.630385, zipCode: "43961"},
	{latitude: 40.673011, longitude: -80.889208, zipCode: "43962"},
	{latitude: 40.171369, longitude: -80.696907, zipCode: "43963"},
	{latitude: 40.480902, longitude: -80.662475, zipCode: "43964"},
	{latitude: 40.02653, longitude: -80.946227, zipCode: "43967"},
	{latitude: 40.632407, longitude: -80.683847, zipCode: "43968"},
	{latitude: 40.159103, longitude: -80.713361, zipCode: "43971"},
	{latitude: 40.101754, longitude: -80.973719, zipCode: "43972"},
	{latitude: 40.190266, longitude: -81.276328, zipCode: "43973"},
	{latitude: 40.182205, longitude: -80.887645, zipCode: "43974"},
	{latitude: 40.378004, longitude: -80.904709, zipCode: "43976"},
	{latitude: 40.148386, longitude: -81.114083, zipCode: "43977"},
	{latitude: 40.18442, longitude: -80.994283, zipCode: "43981"},
	{latitude: 40.137138, longitude: -81.199038, zipCode: "43983"},
	{latitude: 40.161779, longitude: -81.13209, zipCode: "43985"},
	{latitude: 40.383265, longitude: -80.990452, zipCode: "43986"},
	{latitude: 40.404394, longitude: -81.098229, zipCode: "43988"},
	{latitude: 41.366331, longitude: -82.259924, zipCode: "44001"},
	{latitude: 41.615736, longitude: -80.585942, zipCode: "44003"},
	{latitude: 41.855938, longitude: -80.790536, zipCode: "44004"},
	{latitude: 41.765276, longitude: -80.847485, zipCode: "44010"},
	{latitude: 41.4451, longitude: -82.005109, zipCode: "44011"},
	{latitude: 41.49727, longitude: -82.017462, zipCode: "44012"},
	{latitude: 41.370548, longitude: -81.861757, zipCode: "44017"},
	{latitude: 41.44326, longitude: -81.144465, zipCode: "44021"},
	{latitude: 41.446249, longitude: -81.402969, zipCode: "44022"},
	{latitude: 41.384807, longitude: -81.285741, zipCode: "44023"},
	{latitude: 41.578209, longitude: -81.19126, zipCode: "44024"},
	{latitude: 41.528147, longitude: -81.324706, zipCode: "44026"},
	{latitude: 41.300523, longitude: -81.937418, zipCode: "44028"},
	{latitude: 41.903318, longitude: -80.579879, zipCode: "44030"},
	{latitude: 41.669245, longitude: -80.67011, zipCode: "44032"},
	{latitude: 41.370046, longitude: -82.135976, zipCode: "44035"},
	{latitude: 41.383524, longitude: -82.020667, zipCode: "44039"},
	{latitude: 41.530992, longitude: -81.411153, zipCode: "44040"},
	{latitude: 41.7769, longitude: -80.949898, zipCode: "44041"},
	{latitude: 41.266284, longitude: -82.04179, zipCode: "44044"},
	{latitude: 41.743895, longitude: -81.286268, zipCode: "44045"},
	{latitude: 41.541516, longitude: -81.080263, zipCode: "44046"},
	{latitude: 41.727931, longitude: -80.735874, zipCode: "44047"},
	{latitude: 41.850255, longitude: -80.639309, zipCode: "44048"},
	{latitude: 41.266529, longitude: -82.305788, zipCode: "44049"},
	{latitude: 41.248733, longitude: -82.1282, zipCode: "44050"},
	{latitude: 41.460687, longitude: -82.167946, zipCode: "44052"},
	{latitude: 41.430125, longitude: -82.222205, zipCode: "44053"},
	{latitude: 41.4716, longitude: -82.090338, zipCode: "44054"},
	{latitude: 41.434058, longitude: -82.134095, zipCode: "44055"},
	{latitude: 41.314919, longitude: -81.501633, zipCode: "44056"},
	{latitude: 41.760117, longitude: -81.060305, zipCode: "44057"},
	{latitude: 41.67653, longitude: -81.328167, zipCode: "44060"},
	{latitude: 41.451056, longitude: -81.034637, zipCode: "44062"},
	{latitude: 41.598063, longitude: -81.032323, zipCode: "44064"},
	{latitude: 41.454775, longitude: -81.22918, zipCode: "44065"},
	{latitude: 41.313073, longitude: -81.543821, zipCode: "44067"},
	{latitude: 41.90621, longitude: -80.678814, zipCode: "44068"},
	{latitude: 41.414929, longitude: -81.919131, zipCode: "44070"},
	{latitude: 41.471152, longitude: -81.32492, zipCode: "44072"},
	{latitude: 41.289038, longitude: -82.235963, zipCode: "44074"},
	{latitude: 41.528147, longitude: -80.81475, zipCode: "44076"},
	{latitude: 41.697563, longitude: -81.20974, zipCode: "44077"},
	{latitude: 41.368405, longitude: -81.057809, zipCode: "44080"},
	{latitude: 41.763938, longitude: -81.143104, zipCode: "44081"},
	{latitude: 41.761873, longitude: -80.567535, zipCode: "44082"},
	{latitude: 41.671377, longitude: -80.899161, zipCode: "44084"},
	{latitude: 41.603272, longitude: -80.874544, zipCode: "44085"},
	{latitude: 41.675726, longitude: -81.058597, zipCode: "44086"},
	{latitude: 41.317111, longitude: -81.443521, zipCode: "44087"},
	{latitude: 41.392084, longitude: -82.377455, zipCode: "44089"},
	{latitude: 41.167482, longitude: -82.22838, zipCode: "44090"},
	{latitude: 41.596188, longitude: -81.470954, zipCode: "44092"},
	{latitude: 41.536156, longitude: -80.613263, zipCode: "44093"},
	{latitude: 41.610724, longitude: -81.379324, zipCode: "44094"},
	{latitude: 41.652326, longitude: -81.441721, zipCode: "44095"},
	{latitude: 41.549205, longitude: -80.983305, zipCode: "44099"},
	{latitude: 41.489929, longitude: -81.671842, zipCode: "44101"},
	{latitude: 41.479281, longitude: -81.736378, zipCode: "44102"},
	{latitude: 41.516323, longitude: -81.642908, zipCode: "44103"},
	{latitude: 41.482409, longitude: -81.627043, zipCode: "44104"},
	{latitude: 41.449858, longitude: -81.632098, zipCode: "44105"},
	{latitude: 41.505341, longitude: -81.605432, zipCode: "44106"},
	{latitude: 41.486189, longitude: -81.801047, zipCode: "44107"},
	{latitude: 41.54148, longitude: -81.612482, zipCode: "44108"},
	{latitude: 41.444141, longitude: -81.698357, zipCode: "44109"},
	{latitude: 41.568628, longitude: -81.566326, zipCode: "44110"},
	{latitude: 41.457782, longitude: -81.789892, zipCode: "44111"},
	{latitude: 41.535841, longitude: -81.574143, zipCode: "44112"},
	{latitude: 41.483241, longitude: -81.697166, zipCode: "44113"},
	{latitude: 41.52025, longitude: -81.678617, zipCode: "44114"},
	{latitude: 41.493539, longitude: -81.671188, zipCode: "44115"},
	{latitude: 41.473072, longitude: -81.853825, zipCode: "44116"},
	{latitude: 41.570651, longitude: -81.526412, zipCode: "44117"},
	{latitude: 41.502337, longitude: -81.556571, zipCode: "44118"},
	{latitude: 41.588256, longitude: -81.548746, zipCode: "44119"},
	{latitude: 41.473288, longitude: -81.58088, zipCode: "44120"},
	{latitude: 41.526243, longitude: -81.532433, zipCode: "44121"},
	{latitude: 41.469472, longitude: -81.512415, zipCode: "44122"},
	{latitude: 41.604699, longitude: -81.524123, zipCode: "44123"},
	{latitude: 41.49888, longitude: -81.470711, zipCode: "44124"},
	{latitude: 41.405777, longitude: -81.609715, zipCode: "44125"},
	{latitude: 41.442665, longitude: -81.852734, zipCode: "44126"},
	{latitude: 41.471013, longitude: -81.648805, zipCode: "44127"},
	{latitude: 41.439887, longitude: -81.538531, zipCode: "44128"},
	{latitude: 41.390459, longitude: -81.7353, zipCode: "44129"},
	{latitude: 41.376642, longitude: -81.787317, zipCode: "44130"},
	{latitude: 41.383276, longitude: -81.65469, zipCode: "44131"},
	{latitude: 41.606424, longitude: -81.497217, zipCode: "44132"},
	{latitude: 41.315857, longitude: -81.74301, zipCode: "44133"},
	{latitude: 41.38341, longitude: -81.702612, zipCode: "44134"},
	{latitude: 41.423588, longitude: -81.82593, zipCode: "44135"},
	{latitude: 41.312466, longitude: -81.809769, zipCode: "44136"},
	{latitude: 41.409027, longitude: -81.562762, zipCode: "44137"},
	{latitude: 41.373671, longitude: -81.922993, zipCode: "44138"},
	{latitude: 41.383262, longitude: -81.444249, zipCode: "44139"},
	{latitude: 41.487771, longitude: -81.928891, zipCode: "44140"},
	{latitude: 41.299668, longitude: -81.616506, zipCode: "44141"},
	{latitude: 41.398832, longitude: -81.828543, zipCode: "44142"},
	{latitude: 41.553196, longitude: -81.474346, zipCode: "44143"},
	{latitude: 41.437286, longitude: -81.739814, zipCode: "44144"},
	{latitude: 41.449531, longitude: -81.930162, zipCode: "44145"},
	{latitude: 41.381223, longitude: -81.529226, zipCode: "44146"},
	{latitude: 41.318156, longitude: -81.679262, zipCode: "44147"},
	{latitude: 41.311458, longitude: -81.853087, zipCode: "44149"},
	{latitude: 41.02866, longitude: -81.184283, zipCode: "44201"},
	{latitude: 41.316634, longitude: -81.342178, zipCode: "44202"},
	{latitude: 41.020135, longitude: -81.63068, zipCode: "44203"},
	{latitude: 41.244488, longitude: -81.828842, zipCode: "44212"},
	{latitude: 40.951923, longitude: -81.999753, zipCode: "44214"},
	{latitude: 41.071986, longitude: -81.89922, zipCode: "44215"},
	{latitude: 40.945779, longitude: -81.581744, zipCode: "44216"},
	{latitude: 40.952216, longitude: -81.913482, zipCode: "44217"},
	{latitude: 41.139266, longitude: -81.474873, zipCode: "44221"},
	{latitude: 41.169665, longitude: -81.531057, zipCode: "44223"},
	{latitude: 41.176261, longitude: -81.436523, zipCode: "44224"},
	{latitude: 40.962103, longitude: -81.690327, zipCode: "44230"},
	{latitude: 41.302963, longitude: -81.070474, zipCode: "44231"},
	{latitude: 40.932541, longitude: -81.463521, zipCode: "44232"},
	{latitude: 41.249087, longitude: -81.737867, zipCode: "44233"},
	{latitude: 41.325883, longitude: -81.152253, zipCode: "44234"},
	{latitude: 41.031108, longitude: -82.116704, zipCode: "44235"},
	{latitude: 41.246089, longitude: -81.44909, zipCode: "44236"},
	{latitude: 41.132956, longitude: -81.342432, zipCode: "44240"},
	{latitude: 41.242586, longitude: -81.347721, zipCode: "44241"},
	{latitude: 41.147842, longitude: -81.340464, zipCode: "44243"},
	{latitude: 41.020952, longitude: -81.433138, zipCode: "44250"},
	{latitude: 41.02819, longitude: -81.927641, zipCode: "44251"},
	{latitude: 41.162775, longitude: -82.033342, zipCode: "44253"},
	{latitude: 41.045359, longitude: -82.01245, zipCode: "44254"},
	{latitude: 41.286688, longitude: -81.229104, zipCode: "44255"},
	{latitude: 41.141354, longitude: -81.859522, zipCode: "44256"},
	{latitude: 41.035186, longitude: -81.338442, zipCode: "44260"},
	{latitude: 41.139576, longitude: -81.436058, zipCode: "44262"},
	{latitude: 41.229771, longitude: -81.540489, zipCode: "44264"},
	{latitude: 41.16897, longitude: -81.196962, zipCode: "44266"},
	{latitude: 40.960943, longitude: -81.774537, zipCode: "44270"},
	{latitude: 41.089759, longitude: -81.180523, zipCode: "44272"},
	{latitude: 41.027999, longitude: -81.877935, zipCode: "44273"},
	{latitude: 41.099446, longitude: -81.728537, zipCode: "44274"},
	{latitude: 41.097181, longitude: -82.105641, zipCode: "44275"},
	{latitude: 40.93973, longitude: -81.836018, zipCode: "44276"},
	{latitude: 41.092626, longitude: -81.42001, zipCode: "44278"},
	{latitude: 41.240261, longitude: -81.927779, zipCode: "44280"},
	{latitude: 41.0598, longitude: -81.740386, zipCode: "44281"},
	{latitude: 41.16264, longitude: -81.059593, zipCode: "44285"},
	{latitude: 41.233132, longitude: -81.643941, zipCode: "44286"},
	{latitude: 40.940215, longitude: -82.104486, zipCode: "44287"},
	{latitude: 41.239078, longitude: -81.076039, zipCode: "44288"},
	{latitude: 41.04349, longitude: -81.524017, zipCode: "44301"},
	{latitude: 41.08945, longitude: -81.538489, zipCode: "44302"},
	{latitude: 41.10447, longitude: -81.537358, zipCode: "44303"},
	{latitude: 41.085888, longitude: -81.509129, zipCode: "44304"},
	{latitude: 41.076637, longitude: -81.462675, zipCode: "44305"},
	{latitude: 41.039215, longitude: -81.483159, zipCode: "44306"},
	{latitude: 41.069221, longitude: -81.546525, zipCode: "44307"},
	{latitude: 41.081805, longitude: -81.517451, zipCode: "44308"},
	{latitude: 41.109156, longitude: -81.499983, zipCode: "44310"},
	{latitude: 41.064227, longitude: -81.520446, zipCode: "44311"},
	{latitude: 41.006513, longitude: -81.438547, zipCode: "44312"},
	{latitude: 41.130094, longitude: -81.573169, zipCode: "44313"},
	{latitude: 41.040756, longitude: -81.557908, zipCode: "44314"},
	{latitude: 40.986326, longitude: -81.531138, zipCode: "44319"},
	{latitude: 41.07314, longitude: -81.582414, zipCode: "44320"},
	{latitude: 41.100689, longitude: -81.647946, zipCode: "44321"},
	{latitude: 41.076124, longitude: -81.512285, zipCode: "44325"},
	{latitude: 41.164952, longitude: -81.633826, zipCode: "44333"},
	{latitude: 41.027176, longitude: -80.938225, zipCode: "44401"},
	{latitude: 41.397995, longitude: -80.852844, zipCode: "44402"},
	{latitude: 41.240521, longitude: -80.582656, zipCode: "44403"},
	{latitude: 41.324106, longitude: -80.556627, zipCode: "44404"},
	{latitude: 41.077984, longitude: -80.592359, zipCode: "44405"},
	{latitude: 41.01088, longitude: -80.770396, zipCode: "44406"},
	{latitude: 40.884987, longitude: -80.68794, zipCode: "44408"},
	{latitude: 41.346299, longitude: -80.727712, zipCode: "44410"},
	{latitude: 41.047698, longitude: -81.059303, zipCode: "44411"},
	{latitude: 41.09417, longitude: -81.023487, zipCode: "44412"},
	{latitude: 40.844757, longitude: -80.548689, zipCode: "44413"},
	{latitude: 40.770656, longitude: -80.704549, zipCode: "44415"},
	{latitude: 41.43316, longitude: -80.663287, zipCode: "44417"},
	{latitude: 41.306912, longitude: -80.60462, zipCode: "44418"},
	{latitude: 41.169929, longitude: -80.671496, zipCode: "44420"},
	{latitude: 40.74852, longitude: -80.895947, zipCode: "44423"},
	{latitude: 41.322011, longitude: -80.58324, zipCode: "44424"},
	{latitude: 41.168852, longitude: -80.578164, zipCode: "44425"},
	{latitude: 40.713736, longitude: -80.961667, zipCode: "44427"},
	{latitude: 41.430846, longitude: -80.585193, zipCode: "44428"},
	{latitude: 41.095128, longitude: -80.974159, zipCode: "44429"},
	{latitude: 41.237896, longitude: -80.910764, zipCode: "44430"},
	{latitude: 40.837609, longitude: -80.751064, zipCode: "44431"},
	{latitude: 40.749809, longitude: -80.749876, zipCode: "44432"},
	{latitude: 41.058684, longitude: -80.535073, zipCode: "44436"},
	{latitude: 41.15594, longitude: -80.729884, zipCode: "44437"},
	{latitude: 41.239654, longitude: -80.53066, zipCode: "44438"},
	{latitude: 41.454357, longitude: -80.955505, zipCode: "44439"},
	{latitude: 41.136876, longitude: -80.806286, zipCode: "44440"},
	{latitude: 40.755333, longitude: -80.548808, zipCode: "44441"},
	{latitude: 40.966173, longitude: -80.546111, zipCode: "44442"},
	{latitude: 40.921002, longitude: -80.590583, zipCode: "44443"},
	{latitude: 41.175636, longitude: -80.977665, zipCode: "44444"},
	{latitude: 40.84777, longitude: -80.617109, zipCode: "44445"},
	{latitude: 41.189531, longitude: -80.747534, zipCode: "44446"},
	{latitude: 40.974814, longitude: -81.022475, zipCode: "44449"},
	{latitude: 41.445707, longitude: -80.81962, zipCode: "44450"},
	{latitude: 41.079368, longitude: -80.868193, zipCode: "44451"},
	{latitude: 40.947372, longitude: -80.664007, zipCode: "44452"},
	{latitude: 40.923729, longitude: -80.545358, zipCode: "44454"},
	{latitude: 40.7723, longitude: -80.610166, zipCode: "44455"},
	{latitude: 40.902815, longitude: -80.868743, zipCode: "44460"},
	{latitude: 41.30031, longitude: -80.972747, zipCode: "44470"},
	{latitude: 41.051053, longitude: -80.590566, zipCode: "44471"},
	{latitude: 41.246554, longitude: -80.662661, zipCode: "44473"},
	{latitude: 41.176592, longitude: -80.902499, zipCode: "44481"},
	{latitude: 41.26359, longitude: -80.817044, zipCode: "44483"},
	{latitude: 41.235541, longitude: -80.74975, zipCode: "44484"},
	{latitude: 41.240379, longitude: -80.847801, zipCode: "44485"},
	{latitude: 40.900361, longitude: -80.768289, zipCode: "44490"},
	{latitude: 41.369969, longitude: -80.96365, zipCode: "44491"},
	{latitude: 40.82192, longitude: -80.888597, zipCode: "44493"},
	{latitude: 41.082788, longitude: -80.639487, zipCode: "44502"},
	{latitude: 41.099326, longitude: -80.649074, zipCode: "44503"},
	{latitude: 41.123512, longitude: -80.654651, zipCode: "44504"},
	{latitude: 41.12792, longitude: -80.617554, zipCode: "44505"},
	{latitude: 41.093003, longitude: -80.626397, zipCode: "44506"},
	{latitude: 41.074284, longitude: -80.655445, zipCode: "44507"},
	{latitude: 41.110908, longitude: -80.697158, zipCode: "44509"},
	{latitude: 41.122254, longitude: -80.673433, zipCode: "44510"},
	{latitude: 41.069085, longitude: -80.696703, zipCode: "44511"},
	{latitude: 41.025937, longitude: -80.666955, zipCode: "44512"},
	{latitude: 40.995891, longitude: -80.608267, zipCode: "44514"},
	{latitude: 41.102896, longitude: -80.761275, zipCode: "44515"},
	{latitude: 41.10726, longitude: -80.648749, zipCode: "44555"},
	{latitude: 40.918593, longitude: -81.128745, zipCode: "44601"},
	{latitude: 40.734706, longitude: -81.800147, zipCode: "44606"},
	{latitude: 40.695908, longitude: -81.026873, zipCode: "44607"},
	{latitude: 40.656404, longitude: -81.592795, zipCode: "44608"},
	{latitude: 40.912311, longitude: -80.989812, zipCode: "44609"},
	{latitude: 40.560865, longitude: -81.800867, zipCode: "44610"},
	{latitude: 40.610102, longitude: -82.074983, zipCode: "44611"},
	{latitude: 40.633088, longitude: -81.476036, zipCode: "44612"},
	{latitude: 40.711517, longitude: -81.607777, zipCode: "44613"},
	{latitude: 40.888221, longitude: -81.587803, zipCode: "44614"},
	{latitude: 40.565805, longitude: -81.078601, zipCode: "44615"},
	{latitude: 40.770383, longitude: -81.681101, zipCode: "44618"},
	{latitude: 40.905254, longitude: -80.954455, zipCode: "44619"},
	{latitude: 40.585628, longitude: -81.227832, zipCode: "44620"},
	{latitude: 40.432127, longitude: -81.288171, zipCode: "44621"},
	{latitude: 40.551338, longitude: -81.483514, zipCode: "44622"},
	{latitude: 40.622225, longitude: -81.659302, zipCode: "44624"},
	{latitude: 40.714017, longitude: -81.022561, zipCode: "44625"},
	{latitude: 40.693933, longitude: -81.389306, zipCode: "44626"},
	{latitude: 40.664104, longitude: -81.83643, zipCode: "44627"},
	{latitude: 40.544491, longitude: -82.148485, zipCode: "44628"},
	{latitude: 40.349496, longitude: -81.453226, zipCode: "44629"},
	{latitude: 40.92694, longitude: -81.404064, zipCode: "44630"},
	{latitude: 40.963037, longitude: -81.305397, zipCode: "44632"},
	{latitude: 40.629759, longitude: -81.935268, zipCode: "44633"},
	{latitude: 40.837991, longitude: -81.041397, zipCode: "44634"},
	{latitude: 40.480346, longitude: -82.029657, zipCode: "44637"},
	{latitude: 40.647134, longitude: -82.132629, zipCode: "44638"},
	{latitude: 40.451729, longitude: -81.20975, zipCode: "44639"},
	{latitude: 40.985543, longitude: -81.150705, zipCode: "44640"},
	{latitude: 40.858219, longitude: -81.245344, zipCode: "44641"},
	{latitude: 40.638346, longitude: -81.308871, zipCode: "44643"},
	{latitude: 40.687649, longitude: -81.178209, zipCode: "44644"},
	{latitude: 40.90974, longitude: -81.718168, zipCode: "44645"},
	{latitude: 40.814381, longitude: -81.497182, zipCode: "44646"},
	{latitude: 40.794393, longitude: -81.56498, zipCode: "44647"},
	{latitude: 40.621287, longitude: -80.96177, zipCode: "44651"},
	{latitude: 40.9008, longitude: -81.330166, zipCode: "44652"},
	{latitude: 40.437293, longitude: -81.371353, zipCode: "44653"},
	{latitude: 40.537937, longitude: -81.870867, zipCode: "44654"},
	{latitude: 40.568393, longitude: -81.327664, zipCode: "44656"},
	{latitude: 40.744724, longitude: -81.094903, zipCode: "44657"},
	{latitude: 40.6948, longitude: -81.701825, zipCode: "44659"},
	{latitude: 40.624212, longitude: -81.772588, zipCode: "44660"},
	{latitude: 40.585377, longitude: -82.117401, zipCode: "44661"},
	{latitude: 40.71099, longitude: -81.555052, zipCode: "44662"},
	{latitude: 40.46048, longitude: -81.44958, zipCode: "44663"},
	{latitude: 40.835554, longitude: -80.987051, zipCode: "44665"},
	{latitude: 40.841878, longitude: -81.639934, zipCode: "44666"},
	{latitude: 40.833793, longitude: -81.765184, zipCode: "44667"},
	{latitude: 40.788312, longitude: -81.150684, zipCode: "44669"},
	{latitude: 40.763006, longitude: -81.187916, zipCode: "44670"},
	{latitude: 40.645174, longitude: -81.36568, zipCode: "44671"},
	{latitude: 40.920003, longitude: -81.025268, zipCode: "44672"},
	{latitude: 40.503967, longitude: -81.250464, zipCode: "44675"},
	{latitude: 40.690775, longitude: -82.033802, zipCode: "44676"},
	{latitude: 40.874634, longitude: -81.858587, zipCode: "44677"},
	{latitude: 40.564797, longitude: -81.364165, zipCode: "44678"},
	{latitude: 40.598967, longitude: -81.544039, zipCode: "44680"},
	{latitude: 40.500822, longitude: -81.663619, zipCode: "44681"},
	{latitude: 40.397787, longitude: -81.403463, zipCode: "44682"},
	{latitude: 40.364772, longitude: -81.325071, zipCode: "44683"},
	{latitude: 40.955405, longitude: -81.424372, zipCode: "44685"},
	{latitude: 40.539465, longitude: -81.710347, zipCode: "44687"},
	{latitude: 40.683908, longitude: -81.255382, zipCode: "44688"},
	{latitude: 40.647624, longitude: -81.659231, zipCode: "44689"},
	{latitude: 40.613106, longitude: -81.693638, zipCode: "44690"},
	{latitude: 40.806029, longitude: -81.977588, zipCode: "44691"},
	{latitude: 40.297769, longitude: -81.188203, zipCode: "44693"},
	{latitude: 40.442011, longitude: -81.170166, zipCode: "44695"},
	{latitude: 40.60804, longitude: -81.407271, zipCode: "44697"},
	{latitude: 40.288793, longitude: -81.289004, zipCode: "44699"},
	{latitude: 40.799837, longitude: -81.376725, zipCode: "44702"},
	{latitude: 40.810948, longitude: -81.381347, zipCode: "44703"},
	{latitude: 40.800042, longitude: -81.343062, zipCode: "44704"},
	{latitude: 40.833287, longitude: -81.332021, zipCode: "44705"},
	{latitude: 40.753824, longitude: -81.419797, zipCode: "44706"},
	{latitude: 40.760321, longitude: -81.347116, zipCode: "44707"},
	{latitude: 40.816563, longitude: -81.435458, zipCode: "44708"},
	{latitude: 40.842205, longitude: -81.386331, zipCode: "44709"},
	{latitude: 40.789613, longitude: -81.426572, zipCode: "44710"},
	{latitude: 40.836317, longitude: -81.358279, zipCode: "44714"},
	{latitude: 40.8484, longitude: -81.450468, zipCode: "44718"},
	{latitude: 40.903454, longitude: -81.433282, zipCode: "44720"},
	{latitude: 40.893066, longitude: -81.318113, zipCode: "44721"},
	{latitude: 40.767017, longitude: -81.265784, zipCode: "44730"},
	{latitude: 41.045338, longitude: -83.41911, zipCode: "44802"},
	{latitude: 41.107053, longitude: -83.535376, zipCode: "44804"},
	{latitude: 40.87322, longitude: -82.322775, zipCode: "44805"},
	{latitude: 41.061226, longitude: -82.864336, zipCode: "44807"},
	{latitude: 41.132103, longitude: -83.293978, zipCode: "44809"},
	{latitude: 41.246317, longitude: -82.846599, zipCode: "44811"},
	{latitude: 40.599849, longitude: -82.529699, zipCode: "44813"},
	{latitude: 41.324106, longitude: -82.47216, zipCode: "44814"},
	{latitude: 41.24692, longitude: -83.23945, zipCode: "44815"},
	{latitude: 41.204134, longitude: -83.545038, zipCode: "44817"},
	{latitude: 40.998342, longitude: -82.989373, zipCode: "44818"},
	{latitude: 40.818352, longitude: -82.971722, zipCode: "44820"},
	{latitude: 40.556723, longitude: -82.398088, zipCode: "44822"},
	{latitude: 41.383272, longitude: -82.805888, zipCode: "44824"},
	{latitude: 40.954424, longitude: -82.941927, zipCode: "44825"},
	{latitude: 41.24234, longitude: -82.477643, zipCode: "44826"},
	{latitude: 40.822444, longitude: -82.75506, zipCode: "44827"},
	{latitude: 41.233963, longitude: -82.859475, zipCode: "44828"},
	{latitude: 41.167936, longitude: -83.398998, zipCode: "44830"},
	{latitude: 40.709559, longitude: -82.795815, zipCode: "44833"},
	{latitude: 41.237162, longitude: -83.062895, zipCode: "44836"},
	{latitude: 41.021221, longitude: -82.472683, zipCode: "44837"},
	{latitude: 40.771565, longitude: -82.256155, zipCode: "44838"},
	{latitude: 41.379796, longitude: -82.549616, zipCode: "44839"},
	{latitude: 40.791875, longitude: -82.173115, zipCode: "44840"},
	{latitude: 41.251253, longitude: -83.311346, zipCode: "44841"},
	{latitude: 40.649212, longitude: -82.224849, zipCode: "44842"},
	{latitude: 40.689497, longitude: -82.407602, zipCode: "44843"},
	{latitude: 40.992169, longitude: -83.258362, zipCode: "44844"},
	{latitude: 41.319407, longitude: -82.605833, zipCode: "44846"},
	{latitude: 41.230414, longitude: -82.712888, zipCode: "44847"},
	{latitude: 40.8086, longitude: -83.131857, zipCode: "44849"},
	{latitude: 41.035393, longitude: -82.684018, zipCode: "44850"},
	{latitude: 41.109176, longitude: -82.391571, zipCode: "44851"},
	{latitude: 41.055022, longitude: -83.303993, zipCode: "44853"},
	{latitude: 40.943647, longitude: -82.867843, zipCode: "44854"},
	{latitude: 41.108739, longitude: -82.574938, zipCode: "44855"},
	{latitude: 40.793984, longitude: -82.856263, zipCode: "44856"},
	{latitude: 41.216499, longitude: -82.577642, zipCode: "44857"},
	{latitude: 41.021963, longitude: -82.330724, zipCode: "44859"},
	{latitude: 41.24259, longitude: -83.14623, zipCode: "44861"},
	{latitude: 40.667625, longitude: -82.320628, zipCode: "44864"},
	{latitude: 40.991575, longitude: -82.689436, zipCode: "44865"},
	{latitude: 40.924, longitude: -82.197783, zipCode: "44866"},
	{latitude: 41.149035, longitude: -82.98222, zipCode: "44867"},
	{latitude: 41.41952, longitude: -82.713212, zipCode: "44870"},
	{latitude: 40.969379, longitude: -82.369629, zipCode: "44874"},
	{latitude: 40.889956, longitude: -82.651706, zipCode: "44875"},
	{latitude: 40.951007, longitude: -82.520651, zipCode: "44878"},
	{latitude: 41.033472, longitude: -82.218901, zipCode: "44880"},
	{latitude: 40.870304, longitude: -82.876744, zipCode: "44881"},
	{latitude: 40.946679, longitude: -83.14022, zipCode: "44882"},
	{latitude: 41.124173, longitude: -83.173639, zipCode: "44883"},
	{latitude: 40.916196, longitude: -82.790658, zipCode: "44887"},
	{latitude: 41.245882, longitude: -82.382433, zipCode: "44889"},
	{latitude: 41.085172, longitude: -82.710562, zipCode: "44890"},
	{latitude: 40.763934, longitude: -82.512509, zipCode: "44901"},
	{latitude: 40.758885, longitude: -82.510962, zipCode: "44902"},
	{latitude: 40.776223, longitude: -82.526824, zipCode: "44903"},
	{latitude: 40.663703, longitude: -82.614459, zipCode: "44904"},
	{latitude: 40.778593, longitude: -82.46738, zipCode: "44905"},
	{latitude: 40.764486, longitude: -82.574974, zipCode: "44906"},
	{latitude: 40.727662, longitude: -82.520406, zipCode: "44907"},
	{latitude: 39.138123, longitude: -84.712834, zipCode: "45001"},
	{latitude: 39.198531, longitude: -84.763113, zipCode: "45002"},
	{latitude: 39.586583, longitude: -84.78647, zipCode: "45003"},
	{latitude: 39.528928, longitude: -84.289524, zipCode: "45005"},
	{latitude: 39.426575, longitude: -84.497637, zipCode: "45011"},
	{latitude: 39.413632, longitude: -84.652679, zipCode: "45013"},
	{latitude: 39.325714, longitude: -84.55239, zipCode: "45014"},
	{latitude: 39.374319, longitude: -84.56116, zipCode: "45015"},
	{latitude: 39.257248, longitude: -84.763252, zipCode: "45030"},
	{latitude: 39.502575, longitude: -84.00757, zipCode: "45032"},
	{latitude: 39.179645, longitude: -84.764695, zipCode: "45033"},
	{latitude: 39.356535, longitude: -84.246301, zipCode: "45034"},
	{latitude: 39.443045, longitude: -84.216366, zipCode: "45036"},
	{latitude: 39.327409, longitude: -84.236695, zipCode: "45039"},
	{latitude: 39.350783, longitude: -84.313198, zipCode: "45040"},
	{latitude: 39.210865, longitude: -84.702469, zipCode: "45041"},
	{latitude: 39.556885, longitude: -84.420158, zipCode: "45042"},
	{latitude: 39.482404, longitude: -84.410243, zipCode: "45044"},
	{latitude: 39.444365, longitude: -84.365912, zipCode: "45050"},
	{latitude: 39.099843, longitude: -84.655127, zipCode: "45051"},
	{latitude: 39.144456, longitude: -84.779062, zipCode: "45052"},
	{latitude: 39.354057, longitude: -84.787824, zipCode: "45053"},
	{latitude: 39.438829, longitude: -84.085062, zipCode: "45054"},
	{latitude: 39.457057, longitude: -84.510899, zipCode: "45055"},
	{latitude: 39.487425, longitude: -84.737908, zipCode: "45056"},
	{latitude: 39.483783, longitude: -84.555516, zipCode: "45062"},
	{latitude: 39.570754, longitude: -84.610259, zipCode: "45064"},
	{latitude: 39.370555, longitude: -84.213952, zipCode: "45065"},
	{latitude: 39.547963, longitude: -84.217555, zipCode: "45066"},
	{latitude: 39.494819, longitude: -84.480997, zipCode: "45067"},
	{latitude: 39.529427, longitude: -84.040548, zipCode: "45068"},
	{latitude: 39.343522, longitude: -84.412333, zipCode: "45069"},
	{latitude: 39.589695, longitude: -84.558089, zipCode: "45070"},
	{latitude: 38.697011, longitude: -83.726747, zipCode: "45101"},
	{latitude: 39.01719, longitude: -84.204345, zipCode: "45102"},
	{latitude: 39.091617, longitude: -84.143715, zipCode: "45103"},
	{latitude: 38.750434, longitude: -83.612373, zipCode: "45105"},
	{latitude: 38.94502, longitude: -84.064915, zipCode: "45106"},
	{latitude: 39.287524, longitude: -83.97645, zipCode: "45107"},
	{latitude: 39.201067, longitude: -84.287019, zipCode: "45111"},
	{latitude: 38.794854, longitude: -84.140047, zipCode: "45112"},
	{latitude: 39.39992, longitude: -83.985441, zipCode: "45113"},
	{latitude: 38.808172, longitude: -83.707293, zipCode: "45115"},
	{latitude: 39.172764, longitude: -83.917149, zipCode: "45118"},
	{latitude: 38.878121, longitude: -83.999247, zipCode: "45119"},
	{latitude: 38.828417, longitude: -84.090026, zipCode: "45120"},
	{latitude: 38.866852, longitude: -83.908302, zipCode: "45121"},
	{latitude: 39.225626, longitude: -84.129368, zipCode: "45122"},
	{latitude: 39.348842, longitude: -83.388803, zipCode: "45123"},
	{latitude: 38.908827, longitude: -83.998997, zipCode: "45130"},
	{latitude: 38.789761, longitude: -83.966967, zipCode: "45131"},
	{latitude: 39.343936, longitude: -83.599545, zipCode: "45132"},
	{latitude: 39.159797, longitude: -83.571065, zipCode: "45133"},
	{latitude: 39.347615, longitude: -83.541912, zipCode: "45135"},
	{latitude: 39.254473, longitude: -84.24693, zipCode: "45140"},
	{latitude: 39.207984, longitude: -83.803451, zipCode: "45142"},
	{latitude: 38.704212, longitude: -83.615092, zipCode: "45144"},
	{latitude: 39.314119, longitude: -83.804201, zipCode: "45146"},
	{latitude: 39.210768, longitude: -84.302556, zipCode: "45147"},
	{latitude: 39.290218, longitude: -83.887266, zipCode: "45148"},
	{latitude: 39.166469, longitude: -84.230391, zipCode: "45150"},
	{latitude: 39.351487, longitude: -84.120225, zipCode: "45152"},
	{latitude: 38.862812, longitude: -84.189637, zipCode: "45153"},
	{latitude: 39.063432, longitude: -83.916821, zipCode: "45154"},
	{latitude: 39.042506, longitude: -83.752161, zipCode: "45155"},
	{latitude: 38.809892, longitude: -84.213589, zipCode: "45156"},
	{latitude: 38.953739, longitude: -84.23017, zipCode: "45157"},
	{latitude: 39.182278, longitude: -84.077712, zipCode: "45158"},
	{latitude: 39.336834, longitude: -83.691742, zipCode: "45159"},
	{latitude: 39.123667, longitude: -84.136971, zipCode: "45160"},
	{latitude: 39.269737, longitude: -84.074728, zipCode: "45162"},
	{latitude: 39.553223, longitude: -83.785515, zipCode: "45164"},
	{latitude: 39.476597, longitude: -83.687759, zipCode: "45166"},
	{latitude: 38.781664, longitude: -83.789733, zipCode: "45167"},
	{latitude: 38.849817, longitude: -83.770281, zipCode: "45168"},
	{latitude: 39.506479, longitude: -83.650882, zipCode: "45169"},
	{latitude: 39.013609, longitude: -83.796419, zipCode: "45171"},
	{latitude: 39.074472, longitude: -83.386883, zipCode: "45172"},
	{latitude: 39.15856, longitude: -84.312243, zipCode: "45174"},
	{latitude: 39.079262, longitude: -84.029729, zipCode: "45176"},
	{latitude: 39.46069, longitude: -83.844305, zipCode: "45177"},
	{latitude: 39.106735, longitude: -84.504181, zipCode: "45202"},
	{latitude: 39.105294, longitude: -84.533529, zipCode: "45203"},
	{latitude: 39.089567, longitude: -84.578107, zipCode: "45204"},
	{latitude: 39.110461, longitude: -84.575085, zipCode: "45205"},
	{latitude: 39.12732, longitude: -84.484568, zipCode: "45206"},
	{latitude: 39.141955, longitude: -84.471226, zipCode: "45207"},
	{latitude: 39.13523, longitude: -84.433911, zipCode: "45208"},
	{latitude: 39.153397, longitude: -84.42757, zipCode: "45209"},
	{latitude: 39.158667, longitude: -84.599661, zipCode: "45211"},
	{latitude: 39.164019, longitude: -84.451781, zipCode: "45212"},
	{latitude: 39.181181, longitude: -84.420046, zipCode: "45213"},
	{latitude: 39.115007, longitude: -84.534356, zipCode: "45214"},
	{latitude: 39.235399, longitude: -84.462189, zipCode: "45215"},
	{latitude: 39.200543, longitude: -84.4811, zipCode: "45216"},
	{latitude: 39.166056, longitude: -84.497807, zipCode: "45217"},
	{latitude: 39.266233, longitude: -84.519763, zipCode: "45218"},
	{latitude: 39.126624, longitude: -84.512017, zipCode: "45219"},
	{latitude: 39.146693, longitude: -84.520265, zipCode: "45220"},
	{latitude: 39.170996, longitude: -84.551359, zipCode: "45223"},
	{latitude: 39.20218, longitude: -84.53224, zipCode: "45224"},
	{latitude: 39.143842, longitude: -84.555469, zipCode: "45225"},
	{latitude: 39.111687, longitude: -84.421674, zipCode: "45226"},
	{latitude: 39.153237, longitude: -84.385795, zipCode: "45227"},
	{latitude: 39.153472, longitude: -84.486186, zipCode: "45229"},
	{latitude: 39.073748, longitude: -84.389281, zipCode: "45230"},
	{latitude: 39.247501, longitude: -84.534837, zipCode: "45231"},
	{latitude: 39.185714, longitude: -84.52027, zipCode: "45232"},
	{latitude: 39.116886, longitude: -84.672637, zipCode: "45233"},
	{latitude: 39.209678, longitude: -84.397222, zipCode: "45236"},
	{latitude: 39.19458, longitude: -84.451017, zipCode: "45237"},
	{latitude: 39.109225, longitude: -84.608841, zipCode: "45238"},
	{latitude: 39.203628, longitude: -84.580624, zipCode: "45239"},
	{latitude: 39.286209, longitude: -84.525958, zipCode: "45240"},
	{latitude: 39.276293, longitude: -84.399719, zipCode: "45241"},
	{latitude: 39.242803, longitude: -84.352996, zipCode: "45242"},
	{latitude: 39.188557, longitude: -84.336021, zipCode: "45243"},
	{latitude: 39.114121, longitude: -84.326346, zipCode: "45244"},
	{latitude: 39.059884, longitude: -84.280417, zipCode: "45245"},
	{latitude: 39.290213, longitude: -84.463826, zipCode: "45246"},
	{latitude: 39.216693, longitude: -84.661108, zipCode: "45247"},
	{latitude: 39.164388, longitude: -84.662306, zipCode: "45248"},
	{latitude: 39.275264, longitude: -84.326879, zipCode: "45249"},
	{latitude: 39.27464, longitude: -84.597838, zipCode: "45251"},
	{latitude: 39.270192, longitude: -84.628137, zipCode: "45252"},
	{latitude: 39.059554, longitude: -84.328333, zipCode: "45255"},
	{latitude: 39.138086, longitude: -84.503156, zipCode: "45267"},
	{latitude: 39.710981, longitude: -84.023014, zipCode: "45301"},
	{latitude: 40.40591, longitude: -84.20915, zipCode: "45302"},
	{latitude: 40.213971, longitude: -84.659636, zipCode: "45303"},
	{latitude: 39.98145, longitude: -84.525031, zipCode: "45304"},
	{latitude: 39.640797, longitude: -84.085678, zipCode: "45305"},
	{latitude: 40.459234, longitude: -84.187139, zipCode: "45306"},
	{latitude: 39.580612, longitude: -83.72337, zipCode: "45307"},
	{latitude: 40.129279, longitude: -84.457368, zipCode: "45308"},
	{latitude: 39.842677, longitude: -84.414725, zipCode: "45309"},
	{latitude: 40.352937, longitude: -84.642673, zipCode: "45310"},
	{latitude: 39.628776, longitude: -84.658891, zipCode: "45311"},
	{latitude: 40.052155, longitude: -84.093025, zipCode: "45312"},
	{latitude: 39.742042, longitude: -83.780962, zipCode: "45314"},
	{latitude: 39.857729, longitude: -84.338773, zipCode: "45315"},
	{latitude: 39.797545, longitude: -83.825228, zipCode: "45316"},
	{latitude: 40.178273, longitude: -84.018217, zipCode: "45317"},
	{latitude: 40.127006, longitude: -84.354408, zipCode: "45318"},
	{latitude: 39.91607, longitude: -83.943293, zipCode: "45319"},
	{latitude: 39.750658, longitude: -84.681309, zipCode: "45320"},
	{latitude: 39.865253, longitude: -84.686962, zipCode: "45321"},
	{latitude: 39.903342, longitude: -84.328333, zipCode: "45322"},
	{latitude: 39.851424, longitude: -83.92798, zipCode: "45323"},
	{latitude: 39.820725, longitude: -84.00029, zipCode: "45324"},
	{latitude: 39.692609, longitude: -84.430204, zipCode: "45325"},
	{latitude: 40.13991, longitude: -84.094457, zipCode: "45326"},
	{latitude: 39.62876, longitude: -84.403005, zipCode: "45327"},
	{latitude: 40.116253, longitude: -84.496755, zipCode: "45328"},
	{latitude: 39.648272, longitude: -84.528668, zipCode: "45330"},
	{latitude: 40.098606, longitude: -84.650546, zipCode: "45331"},
	{latitude: 40.000294, longitude: -84.775986, zipCode: "45332"},
	{latitude: 40.249464, longitude: -84.331774, zipCode: "45333"},
	{latitude: 40.443193, longitude: -84.047814, zipCode: "45334"},
	{latitude: 39.639814, longitude: -83.741198, zipCode: "45335"},
	{latitude: 40.442745, longitude: -84.257263, zipCode: "45336"},
	{latitude: 39.981474, longitude: -84.4226, zipCode: "45337"},
	{latitude: 39.85093, longitude: -84.543947, zipCode: "45338"},
	{latitude: 40.008963, longitude: -84.340718, zipCode: "45339"},
	{latitude: 40.368571, longitude: -84.052152, zipCode: "45340"},
	{latitude: 39.87286, longitude: -84.022857, zipCode: "45341"},
	{latitude: 39.629338, longitude: -84.275969, zipCode: "45342"},
	{latitude: 39.953828, longitude: -84.009674, zipCode: "45344"},
	{latitude: 39.739216, longitude: -84.399306, zipCode: "45345"},
	{latitude: 39.982115, longitude: -84.709956, zipCode: "45346"},
	{latitude: 39.88593, longitude: -84.763137, zipCode: "45347"},
	{latitude: 40.329969, longitude: -84.656386, zipCode: "45348"},
	{latitude: 39.989039, longitude: -83.940579, zipCode: "45349"},
	{latitude: 40.324724, longitude: -84.573602, zipCode: "45350"},
	{latitude: 40.339553, longitude: -84.495733, zipCode: "45351"},
	{latitude: 40.05012, longitude: -84.744314, zipCode: "45352"},
	{latitude: 40.292575, longitude: -84.042717, zipCode: "45353"},
	{latitude: 39.910374, longitude: -84.399831, zipCode: "45354"},
	{latitude: 40.16284, longitude: -84.22821, zipCode: "45356"},
	{latitude: 39.987264, longitude: -84.48778, zipCode: "45358"},
	{latitude: 40.04993, longitude: -84.350787, zipCode: "45359"},
	{latitude: 40.330159, longitude: -84.092195, zipCode: "45360"},
	{latitude: 39.963712, longitude: -84.414821, zipCode: "45361"},
	{latitude: 40.292481, longitude: -84.627731, zipCode: "45362"},
	{latitude: 40.239309, longitude: -84.400067, zipCode: "45363"},
	{latitude: 40.28258, longitude: -84.155811, zipCode: "45365"},
	{latitude: 39.844915, longitude: -83.647979, zipCode: "45368"},
	{latitude: 39.952673, longitude: -83.594162, zipCode: "45369"},
	{latitude: 39.608378, longitude: -84.033025, zipCode: "45370"},
	{latitude: 39.940927, longitude: -84.166021, zipCode: "45371"},
	{latitude: 40.013912, longitude: -83.83835, zipCode: "45372"},
	{latitude: 40.033003, longitude: -84.195649, zipCode: "45373"},
	{latitude: 39.904039, longitude: -84.218097, zipCode: "45377"},
	{latitude: 39.902945, longitude: -84.487797, zipCode: "45378"},
	{latitude: 40.241975, longitude: -84.508298, zipCode: "45380"},
	{latitude: 39.730685, longitude: -84.524354, zipCode: "45381"},
	{latitude: 39.901521, longitude: -84.611919, zipCode: "45382"},
	{latitude: 39.957266, longitude: -84.333053, zipCode: "45383"},
	{latitude: 39.715548, longitude: -83.889109, zipCode: "45384"},
	{latitude: 39.669076, longitude: -83.913955, zipCode: "45385"},
	{latitude: 39.799356, longitude: -83.887616, zipCode: "45387"},
	{latitude: 40.327999, longitude: -84.48022, zipCode: "45388"},
	{latitude: 40.056317, longitude: -84.025348, zipCode: "45389"},
	{latitude: 40.215198, longitude: -84.760541, zipCode: "45390"},
	{latitude: 39.760114, longitude: -84.207627, zipCode: "45402"},
	{latitude: 39.763363, longitude: -84.149693, zipCode: "45403"},
	{latitude: 39.788496, longitude: -84.152717, zipCode: "45404"},
	{latitude: 39.79216, longitude: -84.216028, zipCode: "45405"},
	{latitude: 39.785386, longitude: -84.242215, zipCode: "45406"},
	{latitude: 39.721305, longitude: -84.18797, zipCode: "45409"},
	{latitude: 39.747587, longitude: -84.159031, zipCode: "45410"},
	{latitude: 39.839394, longitude: -84.210719, zipCode: "45414"},
	{latitude: 39.835581, longitude: -84.257387, zipCode: "45415"},
	{latitude: 39.807522, longitude: -84.2578, zipCode: "45416"},
	{latitude: 39.733066, longitude: -84.283296, zipCode: "45417"},
	{latitude: 39.714311, longitude: -84.167597, zipCode: "45419"},
	{latitude: 39.718818, longitude: -84.129169, zipCode: "45420"},
	{latitude: 39.760399, longitude: -84.191116, zipCode: "45423"},
	{latitude: 39.851322, longitude: -84.102669, zipCode: "45424"},
	{latitude: 39.80079, longitude: -84.313501, zipCode: "45426"},
	{latitude: 39.742142, longitude: -84.259544, zipCode: "45428"},
	{latitude: 39.683588, longitude: -84.162737, zipCode: "45429"},
	{latitude: 39.718073, longitude: -84.077407, zipCode: "45430"},
	{latitude: 39.763835, longitude: -84.084204, zipCode: "45431"},
	{latitude: 39.739828, longitude: -84.086034, zipCode: "45432"},
	{latitude: 39.822834, longitude: -84.048504, zipCode: "45433"},
	{latitude: 39.721385, longitude: -84.031824, zipCode: "45434"},
	{latitude: 39.781088, longitude: -84.063113, zipCode: "45435"},
	{latitude: 39.698375, longitude: -84.229497, zipCode: "45439"},
	{latitude: 39.673379, longitude: -84.099895, zipCode: "45440"},
	{latitude: 39.664147, longitude: -84.237985, zipCode: "45449"},
	{latitude: 39.602396, longitude: -84.156357, zipCode: "45458"},
	{latitude: 39.646459, longitude: -84.166955, zipCode: "45459"},
	{latitude: 39.740327, longitude: -84.17911, zipCode: "45469"},
	{latitude: 39.926336, longitude: -83.807448, zipCode: "45501"},
	{latitude: 40.001239, longitude: -83.899983, zipCode: "45502"},
	{latitude: 39.965081, longitude: -83.767539, zipCode: "45503"},
	{latitude: 39.94697, longitude: -83.864897, zipCode: "45504"},
	{latitude: 39.901667, longitude: -83.757452, zipCode: "45505"},
	{latitude: 39.90802, longitude: -83.83667, zipCode: "45506"},
	{latitude: 39.31206, longitude: -82.957071, zipCode: "45601"},
	{latitude: 39.207005, longitude: -83.278842, zipCode: "45612"},
	{latitude: 39.034216, longitude: -82.8509, zipCode: "45613"},
	{latitude: 38.929682, longitude: -82.277557, zipCode: "45614"},
	{latitude: 38.73961, longitude: -83.286593, zipCode: "45616"},
	{latitude: 39.279642, longitude: -83.155586, zipCode: "45617"},
	{latitude: 38.885073, longitude: -83.616344, zipCode: "45618"},
	{latitude: 38.462544, longitude: -82.45018, zipCode: "45619"},
	{latitude: 38.955874, longitude: -82.147719, zipCode: "45620"},
	{latitude: 39.11137, longitude: -82.609935, zipCode: "45621"},
	{latitude: 39.352281, longitude: -82.487428, zipCode: "45622"},
	{latitude: 38.641686, longitude: -82.269786, zipCode: "45623"},
	{latitude: 39.173003, longitude: -83.348687, zipCode: "45624"},
	{latitude: 39.389145, longitude: -83.1938, zipCode: "45628"},
	{latitude: 38.657569, longitude: -82.815894, zipCode: "45629"},
	{latitude: 38.697389, longitude: -83.092891, zipCode: "45630"},
	{latitude: 38.821751, longitude: -82.264305, zipCode: "45631"},
	{latitude: 39.184362, longitude: -82.486496, zipCode: "45634"},
	{latitude: 38.581698, longitude: -82.816421, zipCode: "45636"},
	{latitude: 38.5558, longitude: -82.671915, zipCode: "45638"},
	{latitude: 39.019728, longitude: -82.656314, zipCode: "45640"},
	{latitude: 39.046916, longitude: -83.054118, zipCode: "45642"},
	{latitude: 39.447656, longitude: -82.834693, zipCode: "45644"},
	{latitude: 38.566362, longitude: -82.52701, zipCode: "45645"},
	{latitude: 39.07517, longitude: -83.322786, zipCode: "45646"},
	{latitude: 39.301238, longitude: -82.732413, zipCode: "45647"},
	{latitude: 38.918087, longitude: -83.007686, zipCode: "45648"},
	{latitude: 38.738293, longitude: -83.422328, zipCode: "45650"},
	{latitude: 39.28542, longitude: -82.483478, zipCode: "45651"},
	{latitude: 38.828197, longitude: -83.079769, zipCode: "45652"},
	{latitude: 38.889635, longitude: -82.821976, zipCode: "45653"},
	{latitude: 39.366468, longitude: -82.377742, zipCode: "45654"},
	{latitude: 38.876478, longitude: -82.591255, zipCode: "45656"},
	{latitude: 38.843669, longitude: -83.236462, zipCode: "45657"},
	{latitude: 38.762928, longitude: -82.411555, zipCode: "45658"},
	{latitude: 38.654972, longitude: -82.64113, zipCode: "45659"},
	{latitude: 38.988229, longitude: -83.356545, zipCode: "45660"},
	{latitude: 39.045633, longitude: -83.10822, zipCode: "45661"},
	{latitude: 38.792267, longitude: -82.911915, zipCode: "45662"},
	{latitude: 38.736204, longitude: -83.109841, zipCode: "45663"},
	{latitude: 38.498413, longitude: -82.358442, zipCode: "45669"},
	{latitude: 38.970254, longitude: -83.248012, zipCode: "45671"},
	{latitude: 39.221241, longitude: -82.665745, zipCode: "45672"},
	{latitude: 39.203982, longitude: -82.811523, zipCode: "45673"},
	{latitude: 38.877863, longitude: -82.380851, zipCode: "45674"},
	{latitude: 38.79599, longitude: -82.755727, zipCode: "45677"},
	{latitude: 38.610443, longitude: -82.379581, zipCode: "45678"},
	{latitude: 38.977837, longitude: -83.570978, zipCode: "45679"},
	{latitude: 38.467808, longitude: -82.546829, zipCode: "45680"},
	{latitude: 39.299738, longitude: -83.257847, zipCode: "45681"},
	{latitude: 38.806931, longitude: -82.694911, zipCode: "45682"},
	{latitude: 38.651433, longitude: -83.19063, zipCode: "45684"},
	{latitude: 38.931803, longitude: -82.456541, zipCode: "45685"},
	{latitude: 39.009319, longitude: -82.367941, zipCode: "45686"},
	{latitude: 38.73108, longitude: -82.54324, zipCode: "45688"},
	{latitude: 39.145655, longitude: -83.024997, zipCode: "45690"},
	{latitude: 39.115798, longitude: -82.542036, zipCode: "45692"},
	{latitude: 38.807713, longitude: -83.532368, zipCode: "45693"},
	{latitude: 38.743875, longitude: -82.780883, zipCode: "45694"},
	{latitude: 39.161543, longitude: -82.372654, zipCode: "45695"},
	{latitude: 38.601161, longitude: -82.452107, zipCode: "45696"},
	{latitude: 38.931585, longitude: -83.673684, zipCode: "45697"},
	{latitude: 39.294292, longitude: -82.400525, zipCode: "45698"},
	{latitude: 38.874131, longitude: -82.970465, zipCode: "45699"},
	{latitude: 39.311687, longitude: -82.073928, zipCode: "45701"},
	{latitude: 39.19594, longitude: -82.211062, zipCode: "45710"},
	{latitude: 39.428321, longitude: -81.931975, zipCode: "45711"},
	{latitude: 39.316984, longitude: -81.614345, zipCode: "45714"},
	{latitude: 39.610699, longitude: -81.627327, zipCode: "45715"},
	{latitude: 39.46297, longitude: -82.184187, zipCode: "45716"},
	{latitude: 39.400194, longitude: -82.128464, zipCode: "45719"},
	{latitude: 39.093601, longitude: -81.923103, zipCode: "45720"},
	{latitude: 39.565814, longitude: -81.58343, zipCode: "45721"},
	{latitude: 39.226262, longitude: -81.823473, zipCode: "45723"},
	{latitude: 39.383437, longitude: -81.800411, zipCode: "45724"},
	{latitude: 39.645643, longitude: -81.478612, zipCode: "45727"},
	{latitude: 39.418373, longitude: -81.604397, zipCode: "45729"},
	{latitude: 39.517019, longitude: -82.064004, zipCode: "45732"},
	{latitude: 39.641144, longitude: -81.195651, zipCode: "45734"},
	{latitude: 39.245036, longitude: -81.920415, zipCode: "45735"},
	{latitude: 39.19112, longitude: -81.746416, zipCode: "45739"},
	{latitude: 39.47703, longitude: -82.080095, zipCode: "45740"},
	{latitude: 39.075065, longitude: -82.247077, zipCode: "45741"},
	{latitude: 39.287076, longitude: -81.730393, zipCode: "45742"},
	{latitude: 39.075298, longitude: -81.834846, zipCode: "45743"},
	{latitude: 39.548772, longitude: -81.493979, zipCode: "45744"},
	{latitude: 39.606169, longitude: -81.329638, zipCode: "45745"},
	{latitude: 39.597408, longitude: -81.45095, zipCode: "45746"},
	{latitude: 39.429838, longitude: -81.432131, zipCode: "45750"},
	{latitude: 39.018527, longitude: -82.129729, zipCode: "45760"},
	{latitude: 39.425792, longitude: -82.075353, zipCode: "45761"},
	{latitude: 39.442492, longitude: -82.231499, zipCode: "45764"},
	{latitude: 39.319164, longitude: -82.262797, zipCode: "45766"},
	{latitude: 39.544917, longitude: -81.13838, zipCode: "45767"},
	{latitude: 39.415575, longitude: -81.25726, zipCode: "45768"},
	{latitude: 39.109284, longitude: -82.05104, zipCode: "45769"},
	{latitude: 38.98717, longitude: -81.807365, zipCode: "45770"},
	{latitude: 38.987538, longitude: -81.902514, zipCode: "45771"},
	{latitude: 39.148706, longitude: -81.819169, zipCode: "45772"},
	{latitude: 39.437542, longitude: -81.270726, zipCode: "45773"},
	{latitude: 39.087585, longitude: -82.155962, zipCode: "45775"},
	{latitude: 39.18141, longitude: -82.024066, zipCode: "45776"},
	{latitude: 39.353532, longitude: -81.893066, zipCode: "45778"},
	{latitude: 39.001232, longitude: -81.970716, zipCode: "45779"},
	{latitude: 39.370424, longitude: -82.130958, zipCode: "45780"},
	{latitude: 39.48587, longitude: -82.08165, zipCode: "45782"},
	{latitude: 39.175076, longitude: -81.839287, zipCode: "45783"},
	{latitude: 39.388308, longitude: -81.677855, zipCode: "45784"},
	{latitude: 39.502663, longitude: -81.655053, zipCode: "45786"},
	{latitude: 39.509259, longitude: -81.378514, zipCode: "45788"},
	{latitude: 39.551276, longitude: -81.255011, zipCode: "45789"},
	{latitude: 40.772863, longitude: -84.034098, zipCode: "45801"},
	{latitude: 40.709623, longitude: -84.061153, zipCode: "45804"},
	{latitude: 40.72777, longitude: -84.162772, zipCode: "45805"},
	{latitude: 40.67244, longitude: -84.126166, zipCode: "45806"},
	{latitude: 40.805996, longitude: -84.171653, zipCode: "45807"},
	{latitude: 40.831504, longitude: -83.973358, zipCode: "45808"},
	{latitude: 40.843049, longitude: -84.185096, zipCode: "45809"},
	{latitude: 40.784394, longitude: -83.813286, zipCode: "45810"},
	{latitude: 40.681862, longitude: -83.813832, zipCode: "45812"},
	{latitude: 41.192303, longitude: -84.733158, zipCode: "45813"},
	{latitude: 40.899099, longitude: -83.604267, zipCode: "45814"},
	{latitude: 41.156945, longitude: -83.942593, zipCode: "45815"},
	{latitude: 41.004537, longitude: -83.794005, zipCode: "45816"},
	{latitude: 40.878759, longitude: -83.883975, zipCode: "45817"},
	{latitude: 40.623958, longitude: -84.260391, zipCode: "45819"},
	{latitude: 40.830753, longitude: -84.084489, zipCode: "45820"},
	{latitude: 41.225764, longitude: -84.560946, zipCode: "45821"},
	{latitude: 40.555729, longitude: -84.598627, zipCode: "45822"},
	{latitude: 40.436217, longitude: -84.493693, zipCode: "45826"},
	{latitude: 40.998103, longitude: -84.298851, zipCode: "45827"},
	{latitude: 40.483642, longitude: -84.682889, zipCode: "45828"},
	{latitude: 40.90846, longitude: -84.095933, zipCode: "45830"},
	{latitude: 41.11376, longitude: -84.254987, zipCode: "45831"},
	{latitude: 40.926421, longitude: -84.730092, zipCode: "45832"},
	{latitude: 40.832782, longitude: -84.352902, zipCode: "45833"},
	{latitude: 40.754415, longitude: -83.699893, zipCode: "45835"},
	{latitude: 40.793407, longitude: -83.643202, zipCode: "45836"},
	{latitude: 40.740406, longitude: -84.477816, zipCode: "45838"},
	{latitude: 41.025894, longitude: -83.648894, zipCode: "45840"},
	{latitude: 40.881236, longitude: -83.731999, zipCode: "45841"},
	{latitude: 40.786339, longitude: -83.546244, zipCode: "45843"},
	{latitude: 40.913832, longitude: -84.287383, zipCode: "45844"},
	{latitude: 40.331338, longitude: -84.382544, zipCode: "45845"},
	{latitude: 40.412021, longitude: -84.74813, zipCode: "45846"},
	{latitude: 41.024493, longitude: -84.077088, zipCode: "45848"},
	{latitude: 41.013772, longitude: -84.446662, zipCode: "45849"},
	{latitude: 40.704713, longitude: -83.918297, zipCode: "45850"},
	{latitude: 41.032676, longitude: -84.602883, zipCode: "45851"},
	{latitude: 40.986494, longitude: -84.195186, zipCode: "45853"},
	{latitude: 40.760105, longitude: -83.952122, zipCode: "45854"},
	{latitude: 41.088192, longitude: -84.58349, zipCode: "45855"},
	{latitude: 41.111757, longitude: -83.999825, zipCode: "45856"},
	{latitude: 41.107169, longitude: -83.80211, zipCode: "45858"},
	{latitude: 40.693054, longitude: -83.785735, zipCode: "45859"},
	{latitude: 40.40033, longitude: -84.517378, zipCode: "45860"},
	{latitude: 41.085878, longitude: -84.425651, zipCode: "45861"},
	{latitude: 40.670523, longitude: -84.515236, zipCode: "45862"},
	{latitude: 40.910754, longitude: -84.452522, zipCode: "45863"},
	{latitude: 41.100214, longitude: -84.136736, zipCode: "45864"},
	{latitude: 40.394258, longitude: -84.372922, zipCode: "45865"},
	{latitude: 40.48878, longitude: -84.550493, zipCode: "45866"},
	{latitude: 40.895538, longitude: -83.538416, zipCode: "45867"},
	{latitude: 40.97097, longitude: -83.851238, zipCode: "45868"},
	{latitude: 40.456841, longitude: -84.390491, zipCode: "45869"},
	{latitude: 40.556255, longitude: -83.951444, zipCode: "45870"},
	{latitude: 40.489115, longitude: -84.294025, zipCode: "45871"},
	{latitude: 41.189167, longitude: -83.682423, zipCode: "45872"},
	{latitude: 41.117833, longitude: -84.390235, zipCode: "45873"},
	{latitude: 40.787757, longitude: -84.681229, zipCode: "45874"},
	{latitude: 41.01966, longitude: -84.058602, zipCode: "45875"},
	{latitude: 40.934176, longitude: -84.338664, zipCode: "45876"},
	{latitude: 40.959121, longitude: -83.933578, zipCode: "45877"},
	{latitude: 41.128322, longitude: -84.563105, zipCode: "45879"},
	{latitude: 41.067867, longitude: -84.741558, zipCode: "45880"},
	{latitude: 40.946247, longitude: -83.780047, zipCode: "45881"},
	{latitude: 40.683429, longitude: -84.677293, zipCode: "45882"},
	{latitude: 40.399494, longitude: -84.619593, zipCode: "45883"},
	{latitude: 40.546709, longitude: -84.084183, zipCode: "45884"},
	{latitude: 40.569863, longitude: -84.390644, zipCode: "45885"},
	{latitude: 40.9969, longitude: -84.620781, zipCode: "45886"},
	{latitude: 40.710853, longitude: -84.365128, zipCode: "45887"},
	{latitude: 40.602322, longitude: -84.085692, zipCode: "45888"},
	{latitude: 41.152611, longitude: -83.638601, zipCode: "45889"},
	{latitude: 40.979927, longitude: -83.494768, zipCode: "45890"},
	{latitude: 40.873982, longitude: -84.577107, zipCode: "45891"},
	{latitude: 40.881452, longitude: -84.145379, zipCode: "45893"},
	{latitude: 40.759426, longitude: -84.471117, zipCode: "45894"},
	{latitude: 40.563033, longitude: -84.145293, zipCode: "45895"},
	{latitude: 40.607869, longitude: -83.942108, zipCode: "45896"},
	{latitude: 40.84148, longitude: -83.656145, zipCode: "45897"},
	{latitude: 40.733947, longitude: -84.751052, zipCode: "45898"},
	{latitude: 40.802675, longitude: -84.772307, zipCode: "45899"},
	{latitude: 40.25141, longitude: -85.65806, zipCode: "46001"},
	{latitude: 40.130099, longitude: -85.760763, zipCode: "46011"},
	{latitude: 40.146095, longitude: -85.623061, zipCode: "46012"},
	{latitude: 40.051228, longitude: -85.685737, zipCode: "46013"},
	{latitude: 40.097821, longitude: -85.681485, zipCode: "46016"},
	{latitude: 40.069281, longitude: -85.607157, zipCode: "46017"},
	{latitude: 40.169794, longitude: -86.020635, zipCode: "46030"},
	{latitude: 40.203688, longitude: -86.009558, zipCode: "46031"},
	{latitude: 39.966569, longitude: -86.175131, zipCode: "46032"},
	{latitude: 39.979283, longitude: -86.085557, zipCode: "46033"},
	{latitude: 40.135385, longitude: -86.048472, zipCode: "46034"},
	{latitude: 40.18922, longitude: -86.679018, zipCode: "46035"},
	{latitude: 40.296875, longitude: -85.832902, zipCode: "46036"},
	{latitude: 39.962809, longitude: -85.943916, zipCode: "46037"},
	{latitude: 39.966704, longitude: -86.017173, zipCode: "46038"},
	{latitude: 40.370181, longitude: -86.311698, zipCode: "46039"},
	{latitude: 39.927284, longitude: -85.834273, zipCode: "46040"},
	{latitude: 40.295035, longitude: -86.493864, zipCode: "46041"},
	{latitude: 40.211777, longitude: -85.792705, zipCode: "46044"},
	{latitude: 40.28961, longitude: -86.148659, zipCode: "46045"},
	{latitude: 40.284181, longitude: -85.947381, zipCode: "46047"},
	{latitude: 39.954255, longitude: -85.807463, zipCode: "46048"},
	{latitude: 40.295013, longitude: -86.22179, zipCode: "46049"},
	{latitude: 40.205165, longitude: -86.346298, zipCode: "46050"},
	{latitude: 40.058556, longitude: -85.837927, zipCode: "46051"},
	{latitude: 40.044295, longitude: -86.460975, zipCode: "46052"},
	{latitude: 39.888415, longitude: -85.90221, zipCode: "46055"},
	{latitude: 39.97271, longitude: -85.606902, zipCode: "46056"},
	{latitude: 40.337951, longitude: -86.378006, zipCode: "46057"},
	{latitude: 40.359824, longitude: -86.647782, zipCode: "46058"},
	{latitude: 40.062575, longitude: -85.933198, zipCode: "46060"},
	{latitude: 40.06129, longitude: -86.055942, zipCode: "46062"},
	{latitude: 40.271794, longitude: -85.723961, zipCode: "46063"},
	{latitude: 39.981682, longitude: -85.738762, zipCode: "46064"},
	{latitude: 40.42133, longitude: -86.61002, zipCode: "46065"},
	{latitude: 40.375106, longitude: -86.11124, zipCode: "46068"},
	{latitude: 40.138748, longitude: -86.230316, zipCode: "46069"},
	{latitude: 40.346074, longitude: -85.66086, zipCode: "46070"},
	{latitude: 40.11046, longitude: -86.608042, zipCode: "46071"},
	{latitude: 40.28526, longitude: -86.068828, zipCode: "46072"},
	{latitude: 40.036077, longitude: -86.173657, zipCode: "46074"},
	{latitude: 40.020696, longitude: -86.3308, zipCode: "46075"},
	{latitude: 40.370669, longitude: -85.946072, zipCode: "46076"},
	{latitude: 39.98189, longitude: -86.278939, zipCode: "46077"},
	{latitude: 39.995552, longitude: -86.619922, zipCode: "46102"},
	{latitude: 39.689553, longitude: -86.61383, zipCode: "46103"},
	{latitude: 39.65847, longitude: -85.60183, zipCode: "46104"},
	{latitude: 39.764806, longitude: -86.813484, zipCode: "46105"},
	{latitude: 39.513173, longitude: -86.205119, zipCode: "46106"},
	{latitude: 39.716623, longitude: -86.091616, zipCode: "46107"},
	{latitude: 39.559379, longitude: -85.912843, zipCode: "46110"},
	{latitude: 39.541991, longitude: -86.367394, zipCode: "46111"},
	{latitude: 39.86391, longitude: -86.382425, zipCode: "46112"},
	{latitude: 39.636299, longitude: -86.302553, zipCode: "46113"},
	{latitude: 39.737677, longitude: -85.566877, zipCode: "46115"},
	{latitude: 39.814706, longitude: -85.615963, zipCode: "46117"},
	{latitude: 39.658691, longitude: -86.531342, zipCode: "46118"},
	{latitude: 39.511408, longitude: -86.789098, zipCode: "46120"},
	{latitude: 39.679042, longitude: -86.678783, zipCode: "46121"},
	{latitude: 39.773312, longitude: -86.551134, zipCode: "46122"},
	{latitude: 39.764437, longitude: -86.403388, zipCode: "46123"},
	{latitude: 39.371013, longitude: -85.947876, zipCode: "46124"},
	{latitude: 39.521866, longitude: -86.641816, zipCode: "46125"},
	{latitude: 39.618024, longitude: -85.88034, zipCode: "46126"},
	{latitude: 39.717665, longitude: -85.31583, zipCode: "46127"},
	{latitude: 39.658583, longitude: -86.742562, zipCode: "46128"},
	{latitude: 39.672748, longitude: -85.835022, zipCode: "46130"},
	{latitude: 39.473489, longitude: -86.043592, zipCode: "46131"},
	{latitude: 39.595055, longitude: -85.310832, zipCode: "46133"},
	{latitude: 39.654571, longitude: -86.893996, zipCode: "46135"},
	{latitude: 39.801973, longitude: -85.772071, zipCode: "46140"},
	{latitude: 39.62181, longitude: -86.175319, zipCode: "46142"},
	{latitude: 39.595658, longitude: -86.113954, zipCode: "46143"},
	{latitude: 39.665814, longitude: -85.647072, zipCode: "46144"},
	{latitude: 39.579642, longitude: -85.569555, zipCode: "46146"},
	{latitude: 39.971149, longitude: -86.622827, zipCode: "46147"},
	{latitude: 39.77989, longitude: -85.475163, zipCode: "46148"},
	{latitude: 39.883138, longitude: -86.553506, zipCode: "46149"},
	{latitude: 39.545464, longitude: -85.602908, zipCode: "46150"},
	{latitude: 39.46049, longitude: -86.418365, zipCode: "46151"},
	{latitude: 39.856378, longitude: -85.770398, zipCode: "46154"},
	{latitude: 39.743565, longitude: -85.429499, zipCode: "46155"},
	{latitude: 39.489361, longitude: -85.49445, zipCode: "46156"},
	{latitude: 39.55363, longitude: -86.528731, zipCode: "46157"},
	{latitude: 39.579356, longitude: -86.373602, zipCode: "46158"},
	{latitude: 39.351134, longitude: -86.268692, zipCode: "46160"},
	{latitude: 39.668477, longitude: -85.696892, zipCode: "46161"},
	{latitude: 39.555779, longitude: -85.953942, zipCode: "46162"},
	{latitude: 39.728582, longitude: -85.906051, zipCode: "46163"},
	{latitude: 39.302574, longitude: -86.121576, zipCode: "46164"},
	{latitude: 39.849817, longitude: -86.64015, zipCode: "46165"},
	{latitude: 39.4269, longitude: -86.590326, zipCode: "46166"},
	{latitude: 39.876973, longitude: -86.467801, zipCode: "46167"},
	{latitude: 39.68999, longitude: -86.392709, zipCode: "46168"},
	{latitude: 39.571624, longitude: -86.862651, zipCode: "46170"},
	{latitude: 39.525101, longitude: -86.960902, zipCode: "46171"},
	{latitude: 39.827835, longitude: -86.818464, zipCode: "46172"},
	{latitude: 39.600822, longitude: -85.4309, zipCode: "46173"},
	{latitude: 39.815724, longitude: -86.966359, zipCode: "46175"},
	{latitude: 39.526531, longitude: -85.773369, zipCode: "46176"},
	{latitude: 39.592189, longitude: -86.618525, zipCode: "46180"},
	{latitude: 39.372155, longitude: -86.171601, zipCode: "46181"},
	{latitude: 39.458578, longitude: -85.669424, zipCode: "46182"},
	{latitude: 39.653964, longitude: -86.28129, zipCode: "46183"},
	{latitude: 39.558123, longitude: -86.066946, zipCode: "46184"},
	{latitude: 39.894213, longitude: -85.644782, zipCode: "46186"},
	{latitude: 39.773669, longitude: -86.10956, zipCode: "46201"},
	{latitude: 39.7841, longitude: -86.161616, zipCode: "46202"},
	{latitude: 39.736808, longitude: -86.095848, zipCode: "46203"},
	{latitude: 39.770593, longitude: -86.157054, zipCode: "46204"},
	{latitude: 39.829437, longitude: -86.134381, zipCode: "46205"},
	{latitude: 39.760267, longitude: -86.161071, zipCode: "46206"},
	{latitude: 39.819071, longitude: -86.171083, zipCode: "46208"},
	{latitude: 39.792817, longitude: -86.291528, zipCode: "46214"},
	{latitude: 39.865958, longitude: -86.009333, zipCode: "46216"},
	{latitude: 39.674631, longitude: -86.191451, zipCode: "46217"},
	{latitude: 39.80713, longitude: -86.099554, zipCode: "46218"},
	{latitude: 39.78393, longitude: -86.044636, zipCode: "46219"},
	{latitude: 39.867114, longitude: -86.108848, zipCode: "46220"},
	{latitude: 39.695987, longitude: -86.238728, zipCode: "46221"},
	{latitude: 39.790968, longitude: -86.21526, zipCode: "46222"},
	{latitude: 39.795413, longitude: -86.25689, zipCode: "46224"},
	{latitude: 39.740314, longitude: -86.163255, zipCode: "46225"},
	{latitude: 39.839211, longitude: -86.048552, zipCode: "46226"},
	{latitude: 39.674763, longitude: -86.132676, zipCode: "46227"},
	{latitude: 39.848228, longitude: -86.200725, zipCode: "46228"},
	{latitude: 39.788237, longitude: -85.977015, zipCode: "46229"},
	{latitude: 39.723464, longitude: -86.330553, zipCode: "46231"},
	{latitude: 39.810574, longitude: -86.326281, zipCode: "46234"},
	{latitude: 39.836957, longitude: -85.974537, zipCode: "46235"},
	{latitude: 39.896639, longitude: -85.968156, zipCode: "46236"},
	{latitude: 39.67189, longitude: -86.074788, zipCode: "46237"},
	{latitude: 39.721517, longitude: -85.999033, zipCode: "46239"},
	{latitude: 39.906045, longitude: -86.124635, zipCode: "46240"},
	{latitude: 39.730422, longitude: -86.277774, zipCode: "46241"},
	{latitude: 39.900812, longitude: -86.063376, zipCode: "46250"},
	{latitude: 39.848326, longitude: -86.269687, zipCode: "46254"},
	{latitude: 39.908383, longitude: -86.013138, zipCode: "46256"},
	{latitude: 39.650921, longitude: -85.981462, zipCode: "46259"},
	{latitude: 39.896902, longitude: -86.178492, zipCode: "46260"},
	{latitude: 39.897442, longitude: -86.233327, zipCode: "46268"},
	{latitude: 39.892944, longitude: -86.298055, zipCode: "46278"},
	{latitude: 39.933838, longitude: -86.1352, zipCode: "46280"},
	{latitude: 39.93453, longitude: -86.161858, zipCode: "46290"},
	{latitude: 41.691837, longitude: -86.973274, zipCode: "46301"},
	{latitude: 41.364467, longitude: -87.123692, zipCode: "46302"},
	{latitude: 41.373627, longitude: -87.479284, zipCode: "46303"},
	{latitude: 41.614501, longitude: -87.050374, zipCode: "46304"},
	{latitude: 41.40206, longitude: -87.325371, zipCode: "46307"},
	{latitude: 41.179926, longitude: -87.2308, zipCode: "46310"},
	{latitude: 41.468381, longitude: -87.509184, zipCode: "46311"},
	{latitude: 41.650459, longitude: -87.451123, zipCode: "46312"},
	{latitude: 41.521837, longitude: -87.421897, zipCode: "46319"},
	{latitude: 41.608922, longitude: -87.497554, zipCode: "46320"},
	{latitude: 41.54751, longitude: -87.503268, zipCode: "46321"},
	{latitude: 41.546622, longitude: -87.457616, zipCode: "46322"},
	{latitude: 41.589434, longitude: -87.453729, zipCode: "46323"},
	{latitude: 41.583791, longitude: -87.501995, zipCode: "46324"},
	{latitude: 41.648591, longitude: -87.499255, zipCode: "46327"},
	{latitude: 41.382571, longitude: -86.770664, zipCode: "46340"},
	{latitude: 41.309672, longitude: -87.214561, zipCode: "46341"},
	{latitude: 41.518185, longitude: -87.244908, zipCode: "46342"},
	{latitude: 41.53036, longitude: -86.699931, zipCode: "46345"},
	{latitude: 41.478173, longitude: -86.69332, zipCode: "46346"},
	{latitude: 41.305001, longitude: -87.008573, zipCode: "46347"},
	{latitude: 41.309823, longitude: -86.862422, zipCode: "46348"},
	{latitude: 41.11198, longitude: -87.424918, zipCode: "46349"},
	{latitude: 41.607057, longitude: -86.721724, zipCode: "46350"},
	{latitude: 41.263745, longitude: -87.417152, zipCode: "46356"},
	{latitude: 41.689234, longitude: -86.86512, zipCode: "46360"},
	{latitude: 41.602287, longitude: -86.544223, zipCode: "46365"},
	{latitude: 41.21364, longitude: -86.768018, zipCode: "46366"},
	{latitude: 41.591612, longitude: -87.178939, zipCode: "46368"},
	{latitude: 41.678628, longitude: -86.593031, zipCode: "46371"},
	{latitude: 41.441816, longitude: -87.467779, zipCode: "46373"},
	{latitude: 41.205382, longitude: -86.899462, zipCode: "46374"},
	{latitude: 41.491453, longitude: -87.452867, zipCode: "46375"},
	{latitude: 41.184667, longitude: -87.47582, zipCode: "46376"},
	{latitude: 41.194282, longitude: -87.347795, zipCode: "46377"},
	{latitude: 41.156817, longitude: -87.430166, zipCode: "46379"},
	{latitude: 41.176323, longitude: -87.33446, zipCode: "46381"},
	{latitude: 41.461306, longitude: -86.761986, zipCode: "46382"},
	{latitude: 41.457895, longitude: -87.002008, zipCode: "46383"},
	{latitude: 41.464389, longitude: -87.124112, zipCode: "46385"},
	{latitude: 41.419466, longitude: -86.881518, zipCode: "46390"},
	{latitude: 41.554106, longitude: -86.918985, zipCode: "46391"},
	{latitude: 41.178499, longitude: -87.054975, zipCode: "46392"},
	{latitude: 41.513891, longitude: -87.168001, zipCode: "46393"},
	{latitude: 41.682008, longitude: -87.499922, zipCode: "46394"},
	{latitude: 41.61783, longitude: -87.337827, zipCode: "46402"},
	{latitude: 41.610527, longitude: -87.258152, zipCode: "46403"},
	{latitude: 41.584948, longitude: -87.374471, zipCode: "46404"},
	{latitude: 41.573828, longitude: -87.263247, zipCode: "46405"},
	{latitude: 41.606134, longitude: -87.408651, zipCode: "46406"},
	{latitude: 41.578413, longitude: -87.330432, zipCode: "46407"},
	{latitude: 41.545938, longitude: -87.368484, zipCode: "46408"},
	{latitude: 41.548258, longitude: -87.32423, zipCode: "46409"},
	{latitude: 41.482028, longitude: -87.334679, zipCode: "46410"},
	{latitude: 41.221645, longitude: -86.245803, zipCode: "46501"},
	{latitude: 41.261016, longitude: -85.976594, zipCode: "46502"},
	{latitude: 41.301217, longitude: -86.111595, zipCode: "46504"},
	{latitude: 41.462622, longitude: -86.1724, zipCode: "46506"},
	{latitude: 41.721042, longitude: -85.815643, zipCode: "46507"},
	{latitude: 41.155122, longitude: -85.969414, zipCode: "46508"},
	{latitude: 41.121661, longitude: -85.870707, zipCode: "46510"},
	{latitude: 41.220729, longitude: -86.428425, zipCode: "46511"},
	{latitude: 41.362876, longitude: -86.448101, zipCode: "46513"},
	{latitude: 41.722679, longitude: -85.974999, zipCode: "46514"},
	{latitude: 41.678907, longitude: -85.947966, zipCode: "46516"},
	{latitude: 41.62729, longitude: -85.997855, zipCode: "46517"},
	{latitude: 41.305641, longitude: -86.014116, zipCode: "46524"},
	{latitude: 41.558787, longitude: -85.87665, zipCode: "46526"},
	{latitude: 41.600525, longitude: -85.784864, zipCode: "46528"},
	{latitude: 41.740931, longitude: -86.126918, zipCode: "46530"},
	{latitude: 41.356985, longitude: -86.519382, zipCode: "46531"},
	{latitude: 41.411266, longitude: -86.630511, zipCode: "46532"},
	{latitude: 41.285345, longitude: -86.617818, zipCode: "46534"},
	{latitude: 41.514551, longitude: -86.298011, zipCode: "46536"},
	{latitude: 41.457313, longitude: -86.306584, zipCode: "46537"},
	{latitude: 41.330237, longitude: -85.823415, zipCode: "46538"},
	{latitude: 41.163149, longitude: -86.026511, zipCode: "46539"},
	{latitude: 41.671519, longitude: -85.696675, zipCode: "46540"},
	{latitude: 41.396628, longitude: -85.869691, zipCode: "46542"},
	{latitude: 41.533403, longitude: -85.671198, zipCode: "46543"},
	{latitude: 41.615165, longitude: -86.128266, zipCode: "46544"},
	{latitude: 41.693491, longitude: -86.146569, zipCode: "46545"},
	{latitude: 41.446944, longitude: -86.000648, zipCode: "46550"},
	{latitude: 41.706297, longitude: -86.481901, zipCode: "46552"},
	{latitude: 41.480038, longitude: -85.850932, zipCode: "46553"},
	{latitude: 41.557413, longitude: -86.416992, zipCode: "46554"},
	{latitude: 41.329174, longitude: -85.680113, zipCode: "46555"},
	{latitude: 41.701939, longitude: -86.244835, zipCode: "46556"},
	{latitude: 41.665758, longitude: -86.071515, zipCode: "46561"},
	{latitude: 41.208342, longitude: -85.684902, zipCode: "46562"},
	{latitude: 41.355358, longitude: -86.325957, zipCode: "46563"},
	{latitude: 41.691206, longitude: -85.583664, zipCode: "46565"},
	{latitude: 41.415775, longitude: -85.732336, zipCode: "46567"},
	{latitude: 41.207976, longitude: -86.120079, zipCode: "46570"},
	{latitude: 41.565539, longitude: -85.547025, zipCode: "46571"},
	{latitude: 41.40806, longitude: -86.393087, zipCode: "46572"},
	{latitude: 41.544364, longitude: -86.069668, zipCode: "46573"},
	{latitude: 41.481969, longitude: -86.486555, zipCode: "46574"},
	{latitude: 41.213832, longitude: -85.869547, zipCode: "46580"},
	{latitude: 41.279693, longitude: -85.851626, zipCode: "46582"},
	{latitude: 41.219142, longitude: -85.804586, zipCode: "46590"},
	{latitude: 41.526314, longitude: -86.163421, zipCode: "46595"},
	{latitude: 41.670274, longitude: -86.252661, zipCode: "46601"},
	{latitude: 41.65355, longitude: -86.266326, zipCode: "46613"},
	{latitude: 41.605661, longitude: -86.279601, zipCode: "46614"},
	{latitude: 41.673953, longitude: -86.21171, zipCode: "46615"},
	{latitude: 41.696914, longitude: -86.267, zipCode: "46616"},
	{latitude: 41.684553, longitude: -86.234758, zipCode: "46617"},
	{latitude: 41.660942, longitude: -86.353751, zipCode: "46619"},
	{latitude: 41.702511, longitude: -86.251633, zipCode: "46624"},
	{latitude: 41.720236, longitude: -86.32023, zipCode: "46628"},
	{latitude: 41.714357, longitude: -86.201743, zipCode: "46635"},
	{latitude: 41.733712, longitude: -86.242693, zipCode: "46637"},
	{latitude: 41.360791, longitude: -85.431238, zipCode: "46701"},
	{latitude: 40.820515, longitude: -85.622057, zipCode: "46702"},
	{latitude: 41.65494, longitude: -85.011464, zipCode: "46703"},
	{latitude: 41.10366, longitude: -85.292142, zipCode: "46704"},
	{latitude: 41.51869, longitude: -85.059603, zipCode: "46705"},
	{latitude: 41.341594, longitude: -85.032655, zipCode: "46706"},
	{latitude: 41.352187, longitude: -85.250487, zipCode: "46710"},
	{latitude: 40.661951, longitude: -84.926879, zipCode: "46711"},
	{latitude: 40.722652, longitude: -85.176526, zipCode: "46714"},
	{latitude: 41.423987, longitude: -84.872773, zipCode: "46721"},
	{latitude: 41.245823, longitude: -85.326752, zipCode: "46723"},
	{latitude: 41.153386, longitude: -85.47415, zipCode: "46725"},
	{latitude: 41.458442, longitude: -85.151597, zipCode: "46730"},
	{latitude: 40.793538, longitude: -85.099634, zipCode: "46731"},
	{latitude: 41.371989, longitude: -85.625681, zipCode: "46732"},
	{latitude: 40.83052, longitude: -84.939705, zipCode: "46733"},
	{latitude: 41.717155, longitude: -84.905186, zipCode: "46737"},
	{latitude: 41.318908, longitude: -85.148865, zipCode: "46738"},
	{latitude: 40.60428, longitude: -84.964762, zipCode: "46740"},
	{latitude: 41.216995, longitude: -84.951172, zipCode: "46741"},
	{latitude: 41.548594, longitude: -84.879092, zipCode: "46742"},
	{latitude: 41.215106, longitude: -84.853231, zipCode: "46743"},
	{latitude: 40.951938, longitude: -85.008819, zipCode: "46745"},
	{latitude: 41.725354, longitude: -85.344932, zipCode: "46746"},
	{latitude: 41.565693, longitude: -85.158031, zipCode: "46747"},
	{latitude: 41.248585, longitude: -85.161149, zipCode: "46748"},
	{latitude: 40.876437, longitude: -85.497441, zipCode: "46750"},
	{latitude: 41.449284, longitude: -85.275125, zipCode: "46755"},
	{latitude: 40.603927, longitude: -85.178498, zipCode: "46759"},
	{latitude: 41.345491, longitude: -85.552692, zipCode: "46760"},
	{latitude: 41.634364, longitude: -85.377512, zipCode: "46761"},
	{latitude: 41.297037, longitude: -85.249074, zipCode: "46763"},
	{latitude: 41.214362, longitude: -85.620243, zipCode: "46764"},
	{latitude: 41.233142, longitude: -85.0323, zipCode: "46765"},
	{latitude: 40.709035, longitude: -85.289416, zipCode: "46766"},
	{latitude: 41.46153, longitude: -85.574108, zipCode: "46767"},
	{latitude: 40.827045, longitude: -85.320637, zipCode: "46770"},
	{latitude: 41.694627, longitude: -85.296924, zipCode: "46771"},
	{latitude: 40.722143, longitude: -84.911604, zipCode: "46772"},
	{latitude: 40.984005, longitude: -84.875874, zipCode: "46773"},
	{latitude: 41.089207, longitude: -84.972367, zipCode: "46774"},
	{latitude: 41.728656, longitude: -85.166323, zipCode: "46776"},
	{latitude: 40.869136, longitude: -85.147577, zipCode: "46777"},
	{latitude: 40.603758, longitude: -85.162194, zipCode: "46778"},
	{latitude: 41.563988, longitude: -85.027468, zipCode: "46779"},
	{latitude: 40.63238, longitude: -85.26889, zipCode: "46781"},
	{latitude: 40.969261, longitude: -85.357314, zipCode: "46783"},
	{latitude: 41.500464, longitude: -85.388423, zipCode: "46784"},
	{latitude: 41.323197, longitude: -84.877756, zipCode: "46785"},
	{latitude: 41.531483, longitude: -85.272397, zipCode: "46786"},
	{latitude: 41.066251, longitude: -85.619724, zipCode: "46787"},
	{latitude: 41.272928, longitude: -84.908026, zipCode: "46788"},
	{latitude: 41.582671, longitude: -85.197403, zipCode: "46789"},
	{latitude: 40.83773, longitude: -85.251592, zipCode: "46791"},
	{latitude: 40.684316, longitude: -85.44333, zipCode: "46792"},
	{latitude: 41.452285, longitude: -85.01529, zipCode: "46793"},
	{latitude: 41.46219, longitude: -85.452201, zipCode: "46794"},
	{latitude: 41.558909, longitude: -85.327355, zipCode: "46795"},
	{latitude: 41.336656, longitude: -85.495795, zipCode: "46796"},
	{latitude: 41.128386, longitude: -84.858985, zipCode: "46797"},
	{latitude: 40.942551, longitude: -85.221115, zipCode: "46798"},
	{latitude: 40.915851, longitude: -85.279882, zipCode: "46799"},
	{latitude: 41.069304, longitude: -85.16516, zipCode: "46802"},
	{latitude: 41.07031, longitude: -85.086136, zipCode: "46803"},
	{latitude: 41.054212, longitude: -85.239056, zipCode: "46804"},
	{latitude: 41.10011, longitude: -85.117711, zipCode: "46805"},
	{latitude: 41.046472, longitude: -85.092193, zipCode: "46806"},
	{latitude: 41.044892, longitude: -85.147574, zipCode: "46807"},
	{latitude: 41.09775, longitude: -85.177129, zipCode: "46808"},
	{latitude: 40.999664, longitude: -85.218263, zipCode: "46809"},
	{latitude: 41.048682, longitude: -85.305902, zipCode: "46814"},
	{latitude: 41.10277, longitude: -85.058719, zipCode: "46815"},
	{latitude: 41.002832, longitude: -85.045897, zipCode: "46816"},
	{latitude: 41.142084, longitude: -85.25788, zipCode: "46818"},
	{latitude: 40.974587, longitude: -85.133593, zipCode: "46819"},
	{latitude: 41.15698, longitude: -85.128322, zipCode: "46825"},
	{latitude: 41.152961, longitude: -85.040749, zipCode: "46835"},
	{latitude: 41.212012, longitude: -85.108798, zipCode: "46845"},
	{latitude: 40.531266, longitude: -86.169537, zipCode: "46901"},
	{latitude: 40.437275, longitude: -86.094553, zipCode: "46902"},
	{latitude: 41.04384, longitude: -86.036345, zipCode: "46910"},
	{latitude: 40.62522, longitude: -85.942622, zipCode: "46911"},
	{latitude: 40.503634, longitude: -86.497425, zipCode: "46913"},
	{latitude: 40.628598, longitude: -86.094769, zipCode: "46914"},
	{latitude: 40.478325, longitude: -86.386373, zipCode: "46915"},
	{latitude: 40.626869, longitude: -86.475047, zipCode: "46917"},
	{latitude: 40.594162, longitude: -85.869637, zipCode: "46919"},
	{latitude: 40.464596, longitude: -86.495047, zipCode: "46920"},
	{latitude: 41.140995, longitude: -86.425631, zipCode: "46922"},
	{latitude: 40.607263, longitude: -86.642267, zipCode: "46923"},
	{latitude: 40.887938, longitude: -86.049986, zipCode: "46926"},
	{latitude: 40.405898, longitude: -85.682305, zipCode: "46928"},
	{latitude: 40.554748, longitude: -86.476387, zipCode: "46929"},
	{latitude: 40.40946, longitude: -85.572582, zipCode: "46930"},
	{latitude: 40.946907, longitude: -86.264552, zipCode: "46931"},
	{latitude: 40.595389, longitude: -86.268582, zipCode: "46932"},
	{latitude: 40.478849, longitude: -85.580823, zipCode: "46933"},
	{latitude: 40.484302, longitude: -85.927782, zipCode: "46936"},
	{latitude: 40.415348, longitude: -86.03693, zipCode: "46937"},
	{latitude: 40.430291, longitude: -85.593678, zipCode: "46938"},
	{latitude: 40.992876, longitude: -86.400545, zipCode: "46939"},
	{latitude: 40.690906, longitude: -85.689231, zipCode: "46940"},
	{latitude: 40.830123, longitude: -85.698576, zipCode: "46941"},
	{latitude: 40.972585, longitude: -85.839988, zipCode: "46943"},
	{latitude: 41.121096, longitude: -86.38516, zipCode: "46945"},
	{latitude: 41.039275, longitude: -85.730239, zipCode: "46946"},
	{latitude: 40.760974, longitude: -86.373099, zipCode: "46947"},
	{latitude: 40.888204, longitude: -86.364948, zipCode: "46950"},
	{latitude: 40.947573, longitude: -86.105392, zipCode: "46951"},
	{latitude: 40.599427, longitude: -85.629225, zipCode: "46952"},
	{latitude: 40.519407, longitude: -85.645053, zipCode: "46953"},
	{latitude: 40.388655, longitude: -85.499087, zipCode: "46957"},
	{latitude: 40.818129, longitude: -86.121097, zipCode: "46958"},
	{latitude: 40.612752, longitude: -86.112292, zipCode: "46959"},
	{latitude: 41.161035, longitude: -86.516056, zipCode: "46960"},
	{latitude: 40.767047, longitude: -86.192369, zipCode: "46961"},
	{latitude: 40.988512, longitude: -85.768369, zipCode: "46962"},
	{latitude: 40.415994, longitude: -86.101826, zipCode: "46965"},
	{latitude: 40.695045, longitude: -86.194834, zipCode: "46967"},
	{latitude: 41.170477, longitude: -86.551835, zipCode: "46968"},
	{latitude: 40.757804, longitude: -86.061165, zipCode: "46970"},
	{latitude: 40.66055, longitude: -86.161735, zipCode: "46971"},
	{latitude: 40.951908, longitude: -85.933516, zipCode: "46974"},
	{latitude: 41.075008, longitude: -86.251456, zipCode: "46975"},
	{latitude: 40.858704, longitude: -86.511134, zipCode: "46978"},
	{latitude: 40.429495, longitude: -86.275875, zipCode: "46979"},
	{latitude: 40.955958, longitude: -85.741521, zipCode: "46980"},
	{latitude: 41.051918, longitude: -85.877861, zipCode: "46982"},
	{latitude: 40.667737, longitude: -85.829789, zipCode: "46984"},
	{latitude: 40.959504, longitude: -86.580473, zipCode: "46985"},
	{latitude: 40.4948, longitude: -85.819273, zipCode: "46986"},
	{latitude: 40.568813, longitude: -85.765358, zipCode: "46987"},
	{latitude: 40.877117, longitude: -86.234743, zipCode: "46988"},
	{latitude: 40.448635, longitude: -85.481366, zipCode: "46989"},
	{latitude: 40.902353, longitude: -85.727646, zipCode: "46990"},
	{latitude: 40.628038, longitude: -85.51421, zipCode: "46991"},
	{latitude: 40.784517, longitude: -85.830509, zipCode: "46992"},
	{latitude: 40.667314, longitude: -86.254498, zipCode: "46994"},
	{latitude: 41.062011, longitude: -86.657974, zipCode: "46996"},
	{latitude: 40.575463, longitude: -86.350821, zipCode: "46998"},
	{latitude: 39.056506, longitude: -84.956061, zipCode: "47001"},
	{latitude: 39.559685, longitude: -84.834261, zipCode: "47003"},
	{latitude: 39.285873, longitude: -85.212713, zipCode: "47006"},
	{latitude: 39.501581, longitude: -84.848717, zipCode: "47010"},
	{latitude: 38.871078, longitude: -85.061603, zipCode: "47011"},
	{latitude: 39.427299, longitude: -84.983489, zipCode: "47012"},
	{latitude: 39.372219, longitude: -84.890262, zipCode: "47016"},
	{latitude: 38.940482, longitude: -85.181402, zipCode: "47017"},
	{latitude: 38.991857, longitude: -85.10417, zipCode: "47018"},
	{latitude: 38.817577, longitude: -84.923626, zipCode: "47020"},
	{latitude: 38.970151, longitude: -85.148555, zipCode: "47021"},
	{latitude: 39.207187, longitude: -84.950897, zipCode: "47022"},
	{latitude: 39.071078, longitude: -85.37685, zipCode: "47023"},
	{latitude: 39.476872, longitude: -85.199038, zipCode: "47024"},
	{latitude: 39.153346, longitude: -84.874001, zipCode: "47025"},
	{latitude: 39.424202, longitude: -85.132571, zipCode: "47030"},
	{latitude: 39.130564, longitude: -85.148398, zipCode: "47031"},
	{latitude: 39.080579, longitude: -85.055953, zipCode: "47032"},
	{latitude: 39.282202, longitude: -85.175385, zipCode: "47033"},
	{latitude: 39.204329, longitude: -85.327328, zipCode: "47034"},
	{latitude: 39.310128, longitude: -84.904094, zipCode: "47035"},
	{latitude: 39.385911, longitude: -85.230916, zipCode: "47036"},
	{latitude: 39.169482, longitude: -85.325656, zipCode: "47037"},
	{latitude: 38.850307, longitude: -84.849937, zipCode: "47038"},
	{latitude: 38.928447, longitude: -84.911343, zipCode: "47040"},
	{latitude: 39.234052, longitude: -85.083768, zipCode: "47041"},
	{latitude: 39.01944, longitude: -85.255113, zipCode: "47042"},
	{latitude: 38.792725, longitude: -85.103311, zipCode: "47043"},
	{latitude: 39.29202, longitude: -84.867911, zipCode: "47060"},
	{latitude: 38.755251, longitude: -85.797448, zipCode: "47102"},
	{latitude: 38.555878, longitude: -85.424338, zipCode: "47104"},
	{latitude: 38.4643, longitude: -85.89985, zipCode: "47106"},
	{latitude: 38.646188, longitude: -86.256953, zipCode: "47108"},
	{latitude: 38.095403, longitude: -86.207714, zipCode: "47110"},
	{latitude: 38.466285, longitude: -85.632485, zipCode: "47111"},
	{latitude: 38.186146, longitude: -86.145426, zipCode: "47112"},
	{latitude: 38.287515, longitude: -86.073418, zipCode: "47114"},
	{latitude: 38.344539, longitude: -86.216765, zipCode: "47115"},
	{latitude: 38.326573, longitude: -86.618248, zipCode: "47116"},
	{latitude: 38.106616, longitude: -85.967376, zipCode: "47117"},
	{latitude: 38.300022, longitude: -86.483135, zipCode: "47118"},
	{latitude: 38.371949, longitude: -85.881518, zipCode: "47119"},
	{latitude: 38.460364, longitude: -86.18872, zipCode: "47120"},
	{latitude: 38.30596, longitude: -85.984844, zipCode: "47122"},
	{latitude: 38.285576, longitude: -86.471806, zipCode: "47123"},
	{latitude: 38.365131, longitude: -86.004354, zipCode: "47124"},
	{latitude: 38.461689, longitude: -86.303872, zipCode: "47125"},
	{latitude: 38.547252, longitude: -85.776209, zipCode: "47126"},
	{latitude: 38.31313, longitude: -85.7686, zipCode: "47129"},
	{latitude: 38.332415, longitude: -85.696087, zipCode: "47130"},
	{latitude: 38.048816, longitude: -86.074351, zipCode: "47135"},
	{latitude: 38.234699, longitude: -85.965569, zipCode: "47136"},
	{latitude: 38.152529, longitude: -86.433713, zipCode: "47137"},
	{latitude: 38.682475, longitude: -85.584686, zipCode: "47138"},
	{latitude: 38.384299, longitude: -86.370695, zipCode: "47140"},
	{latitude: 38.547956, longitude: -85.612115, zipCode: "47141"},
	{latitude: 38.061628, longitude: -86.201419, zipCode: "47142"},
	{latitude: 38.468546, longitude: -85.775129, zipCode: "47143"},
	{latitude: 38.329877, longitude: -86.306583, zipCode: "47145"},
	{latitude: 38.599478, longitude: -85.533837, zipCode: "47147"},
	{latitude: 38.283804, longitude: -85.847721, zipCode: "47150"},
	{latitude: 38.157188, longitude: -86.05004, zipCode: "47160"},
	{latitude: 38.321795, longitude: -86.103592, zipCode: "47161"},
	{latitude: 38.548787, longitude: -85.491397, zipCode: "47162"},
	{latitude: 38.545232, longitude: -85.679349, zipCode: "47163"},
	{latitude: 38.413173, longitude: -86.095789, zipCode: "47164"},
	{latitude: 38.502214, longitude: -86.004786, zipCode: "47165"},
	{latitude: 38.306669, longitude: -86.169004, zipCode: "47166"},
	{latitude: 38.60699, longitude: -86.093464, zipCode: "47167"},
	{latitude: 38.685201, longitude: -85.844056, zipCode: "47170"},
	{latitude: 38.399505, longitude: -85.774266, zipCode: "47172"},
	{latitude: 38.227151, longitude: -86.481972, zipCode: "47174"},
	{latitude: 38.358221, longitude: -86.55472, zipCode: "47175"},
	{latitude: 38.589732, longitude: -85.79176, zipCode: "47177"},
	{latitude: 39.148221, longitude: -85.999407, zipCode: "47201"},
	{latitude: 39.230643, longitude: -85.83278, zipCode: "47203"},
	{latitude: 38.87789, longitude: -86.05498, zipCode: "47220"},
	{latitude: 39.055653, longitude: -85.479271, zipCode: "47223"},
	{latitude: 38.89044, longitude: -85.227992, zipCode: "47224"},
	{latitude: 39.435508, longitude: -85.344466, zipCode: "47225"},
	{latitude: 39.282638, longitude: -85.869051, zipCode: "47226"},
	{latitude: 38.868276, longitude: -85.655467, zipCode: "47227"},
	{latitude: 38.807113, longitude: -85.86484, zipCode: "47229"},
	{latitude: 38.79238, longitude: -85.625116, zipCode: "47230"},
	{latitude: 38.900912, longitude: -85.494614, zipCode: "47231"},
	{latitude: 39.110924, longitude: -85.790836, zipCode: "47232"},
	{latitude: 39.376089, longitude: -85.76842, zipCode: "47234"},
	{latitude: 39.01804, longitude: -86.138281, zipCode: "47235"},
	{latitude: 39.151743, longitude: -85.725277, zipCode: "47236"},
	{latitude: 39.306027, longitude: -85.478417, zipCode: "47240"},
	{latitude: 38.668701, longitude: -85.482235, zipCode: "47243"},
	{latitude: 39.217948, longitude: -85.70237, zipCode: "47244"},
	{latitude: 39.298183, longitude: -85.76413, zipCode: "47246"},
	{latitude: 39.05972, longitude: -85.88785, zipCode: "47247"},
	{latitude: 38.811483, longitude: -85.362137, zipCode: "47250"},
	{latitude: 38.836742, longitude: -86.197754, zipCode: "47260"},
	{latitude: 39.308848, longitude: -85.328478, zipCode: "47263"},
	{latitude: 38.979034, longitude: -86.250743, zipCode: "47264"},
	{latitude: 39.008555, longitude: -85.627378, zipCode: "47265"},
	{latitude: 38.839337, longitude: -85.722862, zipCode: "47270"},
	{latitude: 39.411013, longitude: -85.621407, zipCode: "47272"},
	{latitude: 39.07579, longitude: -85.736847, zipCode: "47273"},
	{latitude: 38.966218, longitude: -85.925728, zipCode: "47274"},
	{latitude: 39.296573, longitude: -85.950476, zipCode: "47280"},
	{latitude: 38.788527, longitude: -86.106342, zipCode: "47281"},
	{latitude: 38.985368, longitude: -85.610034, zipCode: "47282"},
	{latitude: 39.170024, longitude: -85.587896, zipCode: "47283"},
	{latitude: 40.129514, longitude: -85.37402, zipCode: "47302"},
	{latitude: 40.271643, longitude: -85.377772, zipCode: "47303"},
	{latitude: 40.236332, longitude: -85.459814, zipCode: "47304"},
	{latitude: 40.194099, longitude: -85.385939, zipCode: "47305"},
	{latitude: 40.20677, longitude: -85.407238, zipCode: "47306"},
	{latitude: 40.271703, longitude: -85.272799, zipCode: "47320"},
	{latitude: 39.741178, longitude: -84.851721, zipCode: "47324"},
	{latitude: 39.693573, longitude: -85.012798, zipCode: "47325"},
	{latitude: 40.54868, longitude: -84.967266, zipCode: "47326"},
	{latitude: 39.833542, longitude: -85.17883, zipCode: "47327"},
	{latitude: 39.786249, longitude: -85.022256, zipCode: "47330"},
	{latitude: 39.624084, longitude: -85.157338, zipCode: "47331"},
	{latitude: 40.10622, longitude: -85.523477, zipCode: "47334"},
	{latitude: 39.811527, longitude: -85.206596, zipCode: "47335"},
	{latitude: 40.397998, longitude: -85.212031, zipCode: "47336"},
	{latitude: 39.802754, longitude: -85.437285, zipCode: "47337"},
	{latitude: 40.342416, longitude: -85.337425, zipCode: "47338"},
	{latitude: 39.938777, longitude: -85.079129, zipCode: "47339"},
	{latitude: 40.180757, longitude: -85.133663, zipCode: "47340"},
	{latitude: 39.97232, longitude: -84.891734, zipCode: "47341"},
	{latitude: 40.32858, longitude: -85.507983, zipCode: "47342"},
	{latitude: 39.878548, longitude: -85.463972, zipCode: "47344"},
	{latitude: 39.893863, longitude: -85.06018, zipCode: "47345"},
	{latitude: 39.931638, longitude: -85.168192, zipCode: "47346"},
	{latitude: 40.466214, longitude: -85.340374, zipCode: "47348"},
	{latitude: 39.904488, longitude: -85.520037, zipCode: "47351"},
	{latitude: 39.795724, longitude: -85.367059, zipCode: "47352"},
	{latitude: 39.623094, longitude: -84.925145, zipCode: "47353"},
	{latitude: 40.040842, longitude: -85.192539, zipCode: "47354"},
	{latitude: 40.044174, longitude: -84.927052, zipCode: "47355"},
	{latitude: 40.031458, longitude: -85.507425, zipCode: "47356"},
	{latitude: 39.751359, longitude: -85.146917, zipCode: "47357"},
	{latitude: 40.057341, longitude: -85.112956, zipCode: "47358"},
	{latitude: 40.552162, longitude: -85.286474, zipCode: "47359"},
	{latitude: 40.011265, longitude: -85.258203, zipCode: "47360"},
	{latitude: 40.004184, longitude: -85.384931, zipCode: "47361"},
	{latitude: 39.932041, longitude: -85.369568, zipCode: "47362"},
	{latitude: 39.861237, longitude: -85.260631, zipCode: "47366"},
	{latitude: 40.083663, longitude: -85.392067, zipCode: "47367"},
	{latitude: 40.182196, longitude: -85.194189, zipCode: "47368"},
	{latitude: 40.507344, longitude: -85.142062, zipCode: "47369"},
	{latitude: 39.812813, longitude: -85.137775, zipCode: "47370"},
	{latitude: 40.419044, longitude: -84.962812, zipCode: "47371"},
	{latitude: 40.339583, longitude: -85.155806, zipCode: "47373"},
	{latitude: 39.833762, longitude: -84.889666, zipCode: "47374"},
	{latitude: 40.285442, longitude: -85.046074, zipCode: "47380"},
	{latitude: 40.386692, longitude: -84.864622, zipCode: "47381"},
	{latitude: 40.236883, longitude: -84.918433, zipCode: "47382"},
	{latitude: 40.156112, longitude: -85.267079, zipCode: "47383"},
	{latitude: 39.909241, longitude: -85.550892, zipCode: "47384"},
	{latitude: 39.832929, longitude: -85.446006, zipCode: "47385"},
	{latitude: 40.055073, longitude: -85.385654, zipCode: "47386"},
	{latitude: 39.838465, longitude: -85.298534, zipCode: "47387"},
	{latitude: 40.005637, longitude: -85.443437, zipCode: "47388"},
	{latitude: 40.205666, longitude: -84.846408, zipCode: "47390"},
	{latitude: 39.909974, longitude: -84.943965, zipCode: "47392"},
	{latitude: 39.962996, longitude: -85.003691, zipCode: "47393"},
	{latitude: 40.167974, longitude: -84.987308, zipCode: "47394"},
	{latitude: 40.189183, longitude: -85.517178, zipCode: "47396"},
	{latitude: 39.102302, longitude: -86.442812, zipCode: "47401"},
	{latitude: 39.085219, longitude: -86.61234, zipCode: "47403"},
	{latitude: 39.219531, longitude: -86.591137, zipCode: "47404"},
	{latitude: 39.167655, longitude: -86.521101, zipCode: "47405"},
	{latitude: 39.175146, longitude: -86.515136, zipCode: "47406"},
	{latitude: 39.229815, longitude: -86.467254, zipCode: "47408"},
	{latitude: 38.919213, longitude: -86.549412, zipCode: "47420"},
	{latitude: 38.863494, longitude: -86.45223, zipCode: "47421"},
	{latitude: 39.020048, longitude: -86.882428, zipCode: "47424"},
	{latitude: 39.236774, longitude: -87.007484, zipCode: "47427"},
	{latitude: 39.275805, longitude: -86.614456, zipCode: "47429"},
	{latitude: 39.245174, longitude: -86.915415, zipCode: "47431"},
	{latitude: 38.493129, longitude: -86.639282, zipCode: "47432"},
	{latitude: 39.355787, longitude: -86.641547, zipCode: "47433"},
	{latitude: 39.012767, longitude: -86.545689, zipCode: "47434"},
	{latitude: 39.265756, longitude: -86.295158, zipCode: "47435"},
	{latitude: 38.954149, longitude: -86.385977, zipCode: "47436"},
	{latitude: 38.713566, longitude: -86.676839, zipCode: "47437"},
	{latitude: 39.159701, longitude: -87.177259, zipCode: "47438"},
	{latitude: 39.039441, longitude: -87.174106, zipCode: "47441"},
	{latitude: 38.968443, longitude: -87.081567, zipCode: "47443"},
	{latitude: 39.112883, longitude: -87.189453, zipCode: "47445"},
	{latitude: 38.732679, longitude: -86.512274, zipCode: "47446"},
	{latitude: 39.17953, longitude: -86.235511, zipCode: "47448"},
	{latitude: 38.938277, longitude: -86.988463, zipCode: "47449"},
	{latitude: 38.891758, longitude: -86.525364, zipCode: "47451"},
	{latitude: 38.644139, longitude: -86.413677, zipCode: "47452"},
	{latitude: 38.939993, longitude: -86.771307, zipCode: "47453"},
	{latitude: 38.510025, longitude: -86.451821, zipCode: "47454"},
	{latitude: 39.312957, longitude: -86.957761, zipCode: "47455"},
	{latitude: 39.465239, longitude: -86.691333, zipCode: "47456"},
	{latitude: 38.911834, longitude: -86.904716, zipCode: "47457"},
	{latitude: 39.070233, longitude: -86.507118, zipCode: "47458"},
	{latitude: 39.113598, longitude: -86.750627, zipCode: "47459"},
	{latitude: 39.272063, longitude: -86.791838, zipCode: "47460"},
	{latitude: 38.958329, longitude: -86.647559, zipCode: "47462"},
	{latitude: 39.299786, longitude: -86.649954, zipCode: "47464"},
	{latitude: 39.061573, longitude: -87.037455, zipCode: "47465"},
	{latitude: 38.767697, longitude: -86.344469, zipCode: "47467"},
	{latitude: 39.285641, longitude: -86.392739, zipCode: "47468"},
	{latitude: 38.637574, longitude: -86.599158, zipCode: "47469"},
	{latitude: 38.816242, longitude: -86.644481, zipCode: "47470"},
	{latitude: 39.143295, longitude: -87.005175, zipCode: "47471"},
	{latitude: 38.66192, longitude: -87.174714, zipCode: "47501"},
	{latitude: 38.778961, longitude: -87.318902, zipCode: "47512"},
	{latitude: 38.304217, longitude: -86.714124, zipCode: "47513"},
	{latitude: 38.1486, longitude: -86.594016, zipCode: "47514"},
	{latitude: 38.183913, longitude: -86.703982, zipCode: "47515"},
	{latitude: 38.771006, longitude: -87.419265, zipCode: "47516"},
	{latitude: 38.590059, longitude: -86.983869, zipCode: "47519"},
	{latitude: 37.944948, longitude: -86.66826, zipCode: "47520"},
	{latitude: 38.408491, longitude: -86.721023, zipCode: "47521"},
	{latitude: 38.878527, longitude: -86.802044, zipCode: "47522"},
	{latitude: 38.178794, longitude: -87.025759, zipCode: "47523"},
	{latitude: 38.484092, longitude: -87.619006, zipCode: "47524"},
	{latitude: 38.020375, longitude: -86.559985, zipCode: "47525"},
	{latitude: 38.468858, longitude: -86.785924, zipCode: "47527"},
	{latitude: 38.8482, longitude: -87.258606, zipCode: "47528"},
	{latitude: 38.865509, longitude: -87.074515, zipCode: "47529"},
	{latitude: 38.03971, longitude: -86.851428, zipCode: "47531"},
	{latitude: 38.219608, longitude: -86.860182, zipCode: "47532"},
	{latitude: 38.862641, longitude: -87.312881, zipCode: "47535"},
	{latitude: 38.114468, longitude: -87.064713, zipCode: "47537"},
	{latitude: 38.235278, longitude: -87.046164, zipCode: "47541"},
	{latitude: 38.299143, longitude: -86.966966, zipCode: "47542"},
	{latitude: 38.418741, longitude: -86.9326, zipCode: "47546"},
	{latitude: 38.067184, longitude: -86.92131, zipCode: "47550"},
	{latitude: 38.114486, longitude: -86.569119, zipCode: "47551"},
	{latitude: 38.111636, longitude: -86.99415, zipCode: "47552"},
	{latitude: 38.655607, longitude: -86.91255, zipCode: "47553"},
	{latitude: 38.570098, longitude: -87.341805, zipCode: "47557"},
	{latitude: 38.638709, longitude: -87.049882, zipCode: "47558"},
	{latitude: 38.863906, longitude: -87.438312, zipCode: "47561"},
	{latitude: 38.833126, longitude: -86.97354, zipCode: "47562"},
	{latitude: 38.48226, longitude: -87.097762, zipCode: "47564"},
	{latitude: 38.462709, longitude: -87.305017, zipCode: "47567"},
	{latitude: 38.764086, longitude: -87.102429, zipCode: "47568"},
	{latitude: 38.744146, longitude: -87.322038, zipCode: "47573"},
	{latitude: 37.950036, longitude: -86.562127, zipCode: "47574"},
	{latitude: 38.325177, longitude: -86.80206, zipCode: "47575"},
	{latitude: 38.179382, longitude: -86.595169, zipCode: "47576"},
	{latitude: 38.149965, longitude: -86.808468, zipCode: "47577"},
	{latitude: 38.883346, longitude: -87.194338, zipCode: "47578"},
	{latitude: 38.11071, longitude: -86.913909, zipCode: "47579"},
	{latitude: 38.355203, longitude: -86.767156, zipCode: "47580"},
	{latitude: 38.66269, longitude: -86.758566, zipCode: "47581"},
	{latitude: 38.251069, longitude: -87.256759, zipCode: "47584"},
	{latitude: 38.266745, longitude: -87.161026, zipCode: "47585"},
	{latitude: 38.036352, longitude: -86.693738, zipCode: "47586"},
	{latitude: 38.02871, longitude: -86.804334, zipCode: "47588"},
	{latitude: 38.342723, longitude: -87.099028, zipCode: "47590"},
	{latitude: 38.628561, longitude: -87.501485, zipCode: "47591"},
	{latitude: 38.863159, longitude: -87.233824, zipCode: "47596"},
	{latitude: 38.641615, longitude: -87.295384, zipCode: "47597"},
	{latitude: 38.388004, longitude: -87.208264, zipCode: "47598"},
	{latitude: 38.051443, longitude: -87.254533, zipCode: "47601"},
	{latitude: 38.062125, longitude: -87.398791, zipCode: "47610"},
	{latitude: 38.027918, longitude: -87.057458, zipCode: "47611"},
	{latitude: 38.194602, longitude: -87.701537, zipCode: "47612"},
	{latitude: 38.150439, longitude: -87.424911, zipCode: "47613"},
	{latitude: 37.970761, longitude: -86.952275, zipCode: "47615"},
	{latitude: 38.233243, longitude: -87.909818, zipCode: "47616"},
	{latitude: 38.192445, longitude: -87.318429, zipCode: "47619"},
	{latitude: 37.931644, longitude: -87.904317, zipCode: "47620"},
	{latitude: 37.952897, longitude: -87.34647, zipCode: "47630"},
	{latitude: 38.109131, longitude: -87.901792, zipCode: "47631"},
	{latitude: 38.168768, longitude: -87.786342, zipCode: "47633"},
	{latitude: 37.939468, longitude: -87.182452, zipCode: "47634"},
	{latitude: 37.883458, longitude: -87.100911, zipCode: "47635"},
	{latitude: 38.141652, longitude: -87.15957, zipCode: "47637"},
	{latitude: 38.064381, longitude: -87.777636, zipCode: "47638"},
	{latitude: 38.188223, longitude: -87.566247, zipCode: "47639"},
	{latitude: 38.478176, longitude: -87.484032, zipCode: "47640"},
	{latitude: 38.248646, longitude: -87.550884, zipCode: "47648"},
	{latitude: 38.353484, longitude: -87.451209, zipCode: "47649"},
	{latitude: 38.252954, longitude: -87.39102, zipCode: "47654"},
	{latitude: 38.296067, longitude: -87.328127, zipCode: "47660"},
	{latitude: 38.283488, longitude: -87.734495, zipCode: "47665"},
	{latitude: 38.417797, longitude: -87.600469, zipCode: "47666"},
	{latitude: 38.346347, longitude: -87.57818, zipCode: "47670"},
	{latitude: 38.276844, longitude: -87.377333, zipCode: "47683"},
	{latitude: 37.974249, longitude: -87.574336, zipCode: "47708"},
	{latitude: 38.025505, longitude: -87.575809, zipCode: "47710"},
	{latitude: 38.015301, longitude: -87.536743, zipCode: "47711"},
	{latitude: 37.924358, longitude: -87.650203, zipCode: "47712"},
	{latitude: 37.948531, longitude: -87.555472, zipCode: "47713"},
	{latitude: 37.956614, longitude: -87.521291, zipCode: "47714"},
	{latitude: 37.972431, longitude: -87.479646, zipCode: "47715"},
	{latitude: 38.062897, longitude: -87.641573, zipCode: "47720"},
	{latitude: 38.103258, longitude: -87.527001, zipCode: "47725"},
	{latitude: 39.350856, longitude: -87.413513, zipCode: "47802"},
	{latitude: 39.466092, longitude: -87.307322, zipCode: "47803"},
	{latitude: 39.497817, longitude: -87.390537, zipCode: "47804"},
	{latitude: 39.546274, longitude: -87.326483, zipCode: "47805"},
	{latitude: 39.472935, longitude: -87.403423, zipCode: "47807"},
	{latitude: 39.471403, longitude: -87.409894, zipCode: "47809"},
	{latitude: 39.66549, longitude: -87.521766, zipCode: "47831"},
	{latitude: 39.880882, longitude: -87.258433, zipCode: "47832"},
	{latitude: 39.358615, longitude: -86.975261, zipCode: "47833"},
	{latitude: 39.523629, longitude: -87.123313, zipCode: "47834"},
	{latitude: 39.649184, longitude: -87.172324, zipCode: "47836"},
	{latitude: 39.627224, longitude: -87.114906, zipCode: "47837"},
	{latitude: 38.961773, longitude: -87.383679, zipCode: "47838"},
	{latitude: 39.407266, longitude: -87.061932, zipCode: "47840"},
	{latitude: 39.277102, longitude: -87.114659, zipCode: "47841"},
	{latitude: 39.678173, longitude: -87.453638, zipCode: "47842"},
	{latitude: 39.193666, longitude: -87.233879, zipCode: "47845"},
	{latitude: 39.367032, longitude: -87.19425, zipCode: "47846"},
	{latitude: 39.817433, longitude: -87.485619, zipCode: "47847"},
	{latitude: 39.042043, longitude: -87.261971, zipCode: "47848"},
	{latitude: 39.195304, longitude: -87.56374, zipCode: "47849"},
	{latitude: 39.237324, longitude: -87.435962, zipCode: "47850"},
	{latitude: 39.573584, longitude: -87.245956, zipCode: "47851"},
	{latitude: 39.537458, longitude: -87.072845, zipCode: "47853"},
	{latitude: 39.805799, longitude: -87.412455, zipCode: "47854"},
	{latitude: 39.188362, longitude: -87.302118, zipCode: "47855"},
	{latitude: 39.524034, longitude: -87.091112, zipCode: "47857"},
	{latitude: 39.262171, longitude: -87.224587, zipCode: "47858"},
	{latitude: 39.88184, longitude: -87.172196, zipCode: "47859"},
	{latitude: 39.722063, longitude: -87.330168, zipCode: "47860"},
	{latitude: 39.073459, longitude: -87.570355, zipCode: "47861"},
	{latitude: 39.776445, longitude: -87.34291, zipCode: "47862"},
	{latitude: 39.578471, longitude: -87.45925, zipCode: "47863"},
	{latitude: 39.019096, longitude: -87.392584, zipCode: "47865"},
	{latitude: 39.28303, longitude: -87.322142, zipCode: "47866"},
	{latitude: 39.411073, longitude: -86.902594, zipCode: "47868"},
	{latitude: 39.274994, longitude: -87.496599, zipCode: "47869"},
	{latitude: 39.370152, longitude: -87.473514, zipCode: "47870"},
	{latitude: 39.389709, longitude: -87.300219, zipCode: "47871"},
	{latitude: 39.749063, longitude: -87.165486, zipCode: "47872"},
	{latitude: 39.637492, longitude: -87.278534, zipCode: "47874"},
	{latitude: 39.71447, longitude: -87.522214, zipCode: "47875"},
	{latitude: 39.509958, longitude: -87.46107, zipCode: "47876"},
	{latitude: 39.19769, longitude: -87.378708, zipCode: "47879"},
	{latitude: 39.599748, longitude: -87.418844, zipCode: "47880"},
	{latitude: 39.487284, longitude: -87.190049, zipCode: "47881"},
	{latitude: 39.089471, longitude: -87.411406, zipCode: "47882"},
	{latitude: 39.623018, longitude: -87.453574, zipCode: "47884"},
	{latitude: 39.506258, longitude: -87.476142, zipCode: "47885"},
	{latitude: 40.417778, longitude: -86.889633, zipCode: "47901"},
	{latitude: 40.438641, longitude: -86.875329, zipCode: "47904"},
	{latitude: 40.421886, longitude: -86.763599, zipCode: "47905"},
	{latitude: 40.479649, longitude: -86.989218, zipCode: "47906"},
	{latitude: 40.422158, longitude: -86.913801, zipCode: "47907"},
	{latitude: 40.324563, longitude: -86.896302, zipCode: "47909"},
	{latitude: 39.983429, longitude: -87.055233, zipCode: "47916"},
	{latitude: 40.468803, longitude: -87.476284, zipCode: "47917"},
	{latitude: 40.298771, longitude: -87.209725, zipCode: "47918"},
	{latitude: 40.540759, longitude: -86.833864, zipCode: "47920"},
	{latitude: 40.478375, longitude: -87.374864, zipCode: "47921"},
	{latitude: 40.876948, longitude: -87.348911, zipCode: "47922"},
	{latitude: 40.610401, longitude: -86.940003, zipCode: "47923"},
	{latitude: 40.489843, longitude: -86.757837, zipCode: "47924"},
	{latitude: 40.882047, longitude: -86.744766, zipCode: "47925"},
	{latitude: 40.780384, longitude: -86.595516, zipCode: "47926"},
	{latitude: 39.921337, longitude: -87.472313, zipCode: "47928"},
	{latitude: 40.670159, longitude: -86.91276, zipCode: "47929"},
	{latitude: 40.237634, longitude: -86.741386, zipCode: "47930"},
	{latitude: 40.134328, longitude: -87.415242, zipCode: "47932"},
	{latitude: 40.037689, longitude: -86.902157, zipCode: "47933"},
	{latitude: 40.11748, longitude: -86.744711, zipCode: "47940"},
	{latitude: 40.375342, longitude: -86.771242, zipCode: "47941"},
	{latitude: 40.686285, longitude: -87.444303, zipCode: "47942"},
	{latitude: 41.064363, longitude: -87.28384, zipCode: "47943"},
	{latitude: 40.613739, longitude: -87.33619, zipCode: "47944"},
	{latitude: 40.984262, longitude: -86.87017, zipCode: "47946"},
	{latitude: 40.776379, longitude: -87.286788, zipCode: "47948"},
	{latitude: 40.06054, longitude: -87.137329, zipCode: "47949"},
	{latitude: 40.801719, longitude: -86.653534, zipCode: "47950"},
	{latitude: 40.790495, longitude: -87.443509, zipCode: "47951"},
	{latitude: 39.959649, longitude: -87.299119, zipCode: "47952"},
	{latitude: 39.899903, longitude: -86.818779, zipCode: "47954"},
	{latitude: 40.193474, longitude: -86.866696, zipCode: "47955"},
	{latitude: 41.089355, longitude: -86.877193, zipCode: "47957"},
	{latitude: 40.164523, longitude: -87.148628, zipCode: "47958"},
	{latitude: 40.862574, longitude: -86.900695, zipCode: "47959"},
	{latitude: 40.788336, longitude: -86.751934, zipCode: "47960"},
	{latitude: 40.968415, longitude: -87.439922, zipCode: "47963"},
	{latitude: 40.952296, longitude: -87.299596, zipCode: "47964"},
	{latitude: 39.954562, longitude: -86.919957, zipCode: "47965"},
	{latitude: 39.888495, longitude: -87.414389, zipCode: "47966"},
	{latitude: 40.194206, longitude: -86.998973, zipCode: "47967"},
	{latitude: 39.959518, longitude: -86.749957, zipCode: "47968"},
	{latitude: 40.203559, longitude: -87.148938, zipCode: "47969"},
	{latitude: 40.465457, longitude: -87.117975, zipCode: "47970"},
	{latitude: 40.525437, longitude: -87.235611, zipCode: "47971"},
	{latitude: 40.042921, longitude: -87.480363, zipCode: "47974"},
	{latitude: 40.444341, longitude: -87.253494, zipCode: "47975"},
	{latitude: 40.762554, longitude: -87.156605, zipCode: "47977"},
	{latitude: 40.986173, longitude: -87.112979, zipCode: "47978"},
	{latitude: 40.752294, longitude: -86.909348, zipCode: "47980"},
	{latitude: 40.251488, longitude: -86.944666, zipCode: "47981"},
	{latitude: 40.197262, longitude: -87.52689, zipCode: "47982"},
	{latitude: 40.286325, longitude: -86.77353, zipCode: "47983"},
	{latitude: 40.513775, longitude: -87.207189, zipCode: "47986"},
	{latitude: 40.121237, longitude: -87.234872, zipCode: "47987"},
	{latitude: 39.890345, longitude: -87.04904, zipCode: "47989"},
	{latitude: 40.061151, longitude: -87.059523, zipCode: "47990"},
	{latitude: 40.280136, longitude: -87.446185, zipCode: "47991"},
	{latitude: 40.319373, longitude: -87.048999, zipCode: "47992"},
	{latitude: 40.309014, longitude: -87.412707, zipCode: "47993"},
	{latitude: 40.182326, longitude: -87.0823, zipCode: "47994"},
	{latitude: 40.761189, longitude: -87.030109, zipCode: "47995"},
	{latitude: 40.667691, longitude: -86.723792, zipCode: "47997"},
	{latitude: 42.639195, longitude: -82.581664, zipCode: "48001"},
	{latitude: 42.935043, longitude: -82.916493, zipCode: "48002"},
	{latitude: 42.930235, longitude: -83.039992, zipCode: "48003"},
	{latitude: 42.855919, longitude: -82.912384, zipCode: "48005"},
	{latitude: 43.083019, longitude: -82.691035, zipCode: "48006"},
	{latitude: 42.544048, longitude: -83.217653, zipCode: "48009"},
	{latitude: 43.025719, longitude: -82.931553, zipCode: "48014"},
	{latitude: 42.480563, longitude: -83.027424, zipCode: "48015"},
	{latitude: 42.536689, longitude: -83.150389, zipCode: "48017"},
	{latitude: 42.46608, longitude: -82.946341, zipCode: "48021"},
	{latitude: 43.023396, longitude: -82.802404, zipCode: "48022"},
	{latitude: 42.704385, longitude: -82.658852, zipCode: "48023"},
	{latitude: 42.520357, longitude: -83.264824, zipCode: "48025"},
	{latitude: 42.537633, longitude: -82.946742, zipCode: "48026"},
	{latitude: 42.944289, longitude: -82.694041, zipCode: "48027"},
	{latitude: 42.580209, longitude: -82.61662, zipCode: "48028"},
	{latitude: 42.461926, longitude: -83.097693, zipCode: "48030"},
	{latitude: 43.133203, longitude: -82.596952, zipCode: "48032"},
	{latitude: 42.459322, longitude: -83.293205, zipCode: "48033"},
	{latitude: 42.49689, longitude: -83.291019, zipCode: "48034"},
	{latitude: 42.5566, longitude: -82.907589, zipCode: "48035"},
	{latitude: 42.597045, longitude: -82.913144, zipCode: "48036"},
	{latitude: 42.603611, longitude: -82.941283, zipCode: "48038"},
	{latitude: 42.692907, longitude: -82.546478, zipCode: "48039"},
	{latitude: 42.910313, longitude: -82.480603, zipCode: "48040"},
	{latitude: 42.937257, longitude: -82.809536, zipCode: "48041"},
	{latitude: 42.683619, longitude: -82.909486, zipCode: "48042"},
	{latitude: 42.598055, longitude: -82.881506, zipCode: "48043"},
	{latitude: 42.650226, longitude: -82.928905, zipCode: "48044"},
	{latitude: 42.587101, longitude: -82.817239, zipCode: "48045"},
	{latitude: 42.673906, longitude: -82.774086, zipCode: "48047"},
	{latitude: 42.738492, longitude: -82.798312, zipCode: "48048"},
	{latitude: 43.040497, longitude: -82.57108, zipCode: "48049"},
	{latitude: 42.787537, longitude: -82.799467, zipCode: "48050"},
	{latitude: 42.689938, longitude: -82.826099, zipCode: "48051"},
	{latitude: 42.770358, longitude: -82.528976, zipCode: "48054"},
	{latitude: 43.087276, longitude: -82.49449, zipCode: "48059"},
	{latitude: 42.983609, longitude: -82.458315, zipCode: "48060"},
	{latitude: 42.852495, longitude: -82.796471, zipCode: "48062"},
	{latitude: 42.859332, longitude: -82.683111, zipCode: "48063"},
	{latitude: 42.764617, longitude: -82.666777, zipCode: "48064"},
	{latitude: 42.845354, longitude: -83.039398, zipCode: "48065"},
	{latitude: 42.507586, longitude: -82.936582, zipCode: "48066"},
	{latitude: 42.490558, longitude: -83.137524, zipCode: "48067"},
	{latitude: 42.471516, longitude: -83.1445, zipCode: "48069"},
	{latitude: 42.481899, longitude: -83.168088, zipCode: "48070"},
	{latitude: 42.507259, longitude: -83.10337, zipCode: "48071"},
	{latitude: 42.497885, longitude: -83.185862, zipCode: "48072"},
	{latitude: 42.519181, longitude: -83.164361, zipCode: "48073"},
	{latitude: 42.950223, longitude: -82.561555, zipCode: "48074"},
	{latitude: 42.461778, longitude: -83.229942, zipCode: "48075"},
	{latitude: 42.497535, longitude: -83.230648, zipCode: "48076"},
	{latitude: 42.858849, longitude: -82.548404, zipCode: "48079"},
	{latitude: 42.465411, longitude: -82.897399, zipCode: "48080"},
	{latitude: 42.494669, longitude: -82.895039, zipCode: "48081"},
	{latitude: 42.528022, longitude: -82.887195, zipCode: "48082"},
	{latitude: 42.55701, longitude: -83.116767, zipCode: "48083"},
	{latitude: 42.560629, longitude: -83.175582, zipCode: "48084"},
	{latitude: 42.604032, longitude: -83.122111, zipCode: "48085"},
	{latitude: 42.515746, longitude: -82.982986, zipCode: "48088"},
	{latitude: 42.468075, longitude: -82.993829, zipCode: "48089"},
	{latitude: 42.46813, longitude: -83.058248, zipCode: "48091"},
	{latitude: 42.512535, longitude: -83.059507, zipCode: "48092"},
	{latitude: 42.51589, longitude: -83.016483, zipCode: "48093"},
	{latitude: 42.734556, longitude: -83.051541, zipCode: "48094"},
	{latitude: 42.778744, longitude: -83.048072, zipCode: "48095"},
	{latitude: 42.762151, longitude: -82.92047, zipCode: "48096"},
	{latitude: 43.121947, longitude: -82.827417, zipCode: "48097"},
	{latitude: 42.598433, longitude: -83.17845, zipCode: "48098"},
	{latitude: 42.259473, longitude: -83.21039, zipCode: "48101"},
	{latitude: 42.26135, longitude: -83.846458, zipCode: "48103"},
	{latitude: 42.262235, longitude: -83.716886, zipCode: "48104"},
	{latitude: 42.327634, longitude: -83.696444, zipCode: "48105"},
	{latitude: 42.221695, longitude: -83.732282, zipCode: "48108"},
	{latitude: 42.291245, longitude: -83.708195, zipCode: "48109"},
	{latitude: 42.183156, longitude: -83.489036, zipCode: "48111"},
	{latitude: 42.567939, longitude: -83.75629, zipCode: "48114"},
	{latitude: 42.505863, longitude: -83.781249, zipCode: "48116"},
	{latitude: 42.050259, longitude: -83.410188, zipCode: "48117"},
	{latitude: 42.312205, longitude: -84.0341, zipCode: "48118"},
	{latitude: 42.302912, longitude: -83.163229, zipCode: "48120"},
	{latitude: 42.278607, longitude: -83.182255, zipCode: "48122"},
	{latitude: 42.298036, longitude: -83.24761, zipCode: "48124"},
	{latitude: 42.277334, longitude: -83.264832, zipCode: "48125"},
	{latitude: 42.330326, longitude: -83.187133, zipCode: "48126"},
	{latitude: 42.335793, longitude: -83.283296, zipCode: "48127"},
	{latitude: 42.320411, longitude: -83.259969, zipCode: "48128"},
	{latitude: 42.364141, longitude: -83.90936, zipCode: "48130"},
	{latitude: 41.967241, longitude: -83.673201, zipCode: "48131"},
	{latitude: 41.782381, longitude: -83.486028, zipCode: "48133"},
	{latitude: 42.106695, longitude: -83.296148, zipCode: "48134"},
	{latitude: 42.324383, longitude: -83.341192, zipCode: "48135"},
	{latitude: 42.471975, longitude: -84.074565, zipCode: "48137"},
	{latitude: 42.131288, longitude: -83.155729, zipCode: "48138"},
	{latitude: 42.453024, longitude: -83.807909, zipCode: "48139"},
	{latitude: 41.882184, longitude: -83.580507, zipCode: "48140"},
	{latitude: 42.293945, longitude: -83.320298, zipCode: "48141"},
	{latitude: 42.457271, longitude: -83.852177, zipCode: "48143"},
	{latitude: 41.752485, longitude: -83.6287, zipCode: "48144"},
	{latitude: 41.844247, longitude: -83.45997, zipCode: "48145"},
	{latitude: 42.243291, longitude: -83.181258, zipCode: "48146"},
	{latitude: 42.371604, longitude: -83.376788, zipCode: "48150"},
	{latitude: 42.424935, longitude: -83.374264, zipCode: "48152"},
	{latitude: 42.398181, longitude: -83.372359, zipCode: "48154"},
	{latitude: 41.815464, longitude: -83.437467, zipCode: "48157"},
	{latitude: 42.155701, longitude: -84.029242, zipCode: "48158"},
	{latitude: 42.024779, longitude: -83.548907, zipCode: "48159"},
	{latitude: 42.082449, longitude: -83.679766, zipCode: "48160"},
	{latitude: 41.907183, longitude: -83.472489, zipCode: "48161"},
	{latitude: 41.960425, longitude: -83.429323, zipCode: "48162"},
	{latitude: 42.131745, longitude: -83.397257, zipCode: "48164"},
	{latitude: 42.497724, longitude: -83.618198, zipCode: "48165"},
	{latitude: 41.98821, longitude: -83.292421, zipCode: "48166"},
	{latitude: 42.432426, longitude: -83.52436, zipCode: "48167"},
	{latitude: 42.405262, longitude: -83.540498, zipCode: "48168"},
	{latitude: 42.461925, longitude: -83.947382, zipCode: "48169"},
	{latitude: 42.367689, longitude: -83.534306, zipCode: "48170"},
	{latitude: 42.073679, longitude: -83.212774, zipCode: "48173"},
	{latitude: 42.236686, longitude: -83.391656, zipCode: "48174"},
	{latitude: 42.136836, longitude: -83.82925, zipCode: "48176"},
	{latitude: 41.804838, longitude: -83.58159, zipCode: "48177"},
	{latitude: 42.443769, longitude: -83.656913, zipCode: "48178"},
	{latitude: 42.035095, longitude: -83.255444, zipCode: "48179"},
	{latitude: 42.225283, longitude: -83.267691, zipCode: "48180"},
	{latitude: 41.789858, longitude: -83.583849, zipCode: "48182"},
	{latitude: 42.131459, longitude: -83.217792, zipCode: "48183"},
	{latitude: 42.274474, longitude: -83.395633, zipCode: "48184"},
	{latitude: 42.333901, longitude: -83.384209, zipCode: "48185"},
	{latitude: 42.293764, longitude: -83.370739, zipCode: "48186"},
	{latitude: 42.325573, longitude: -83.483576, zipCode: "48187"},
	{latitude: 42.286081, longitude: -83.487072, zipCode: "48188"},
	{latitude: 42.40953, longitude: -83.782954, zipCode: "48189"},
	{latitude: 42.131617, longitude: -83.603327, zipCode: "48190"},
	{latitude: 42.121147, longitude: -83.565682, zipCode: "48191"},
	{latitude: 42.210674, longitude: -83.157208, zipCode: "48192"},
	{latitude: 42.174019, longitude: -83.21085, zipCode: "48193"},
	{latitude: 42.204707, longitude: -83.205685, zipCode: "48195"},
	{latitude: 42.194034, longitude: -83.639984, zipCode: "48197"},
	{latitude: 42.275075, longitude: -83.58634, zipCode: "48198"},
	{latitude: 42.34703, longitude: -83.060048, zipCode: "48201"},
	{latitude: 42.374597, longitude: -83.077652, zipCode: "48202"},
	{latitude: 42.421228, longitude: -83.105483, zipCode: "48203"},
	{latitude: 42.365825, longitude: -83.142801, zipCode: "48204"},
	{latitude: 42.433186, longitude: -82.981311, zipCode: "48205"},
	{latitude: 42.375168, longitude: -83.107831, zipCode: "48206"},
	{latitude: 42.348974, longitude: -83.012704, zipCode: "48207"},
	{latitude: 42.348486, longitude: -83.091781, zipCode: "48208"},
	{latitude: 42.306083, longitude: -83.117655, zipCode: "48209"},
	{latitude: 42.336418, longitude: -83.128741, zipCode: "48210"},
	{latitude: 42.381755, longitude: -83.046124, zipCode: "48211"},
	{latitude: 42.41, longitude: -83.055973, zipCode: "48212"},
	{latitude: 42.3979, longitude: -82.995397, zipCode: "48213"},
	{latitude: 42.365116, longitude: -82.987644, zipCode: "48214"},
	{latitude: 42.375217, longitude: -82.954301, zipCode: "48215"},
	{latitude: 42.326385, longitude: -83.07926, zipCode: "48216"},
	{latitude: 42.277651, longitude: -83.155078, zipCode: "48217"},
	{latitude: 42.270448, longitude: -83.131536, zipCode: "48218"},
	{latitude: 42.425328, longitude: -83.251428, zipCode: "48219"},
	{latitude: 42.458051, longitude: -83.135196, zipCode: "48220"},
	{latitude: 42.427187, longitude: -83.149528, zipCode: "48221"},
	{latitude: 42.393398, longitude: -83.246136, zipCode: "48223"},
	{latitude: 42.410699, longitude: -82.941456, zipCode: "48224"},
	{latitude: 42.438887, longitude: -82.929679, zipCode: "48225"},
	{latitude: 42.331815, longitude: -83.050354, zipCode: "48226"},
	{latitude: 42.387362, longitude: -83.192562, zipCode: "48227"},
	{latitude: 42.355145, longitude: -83.218208, zipCode: "48228"},
	{latitude: 42.249592, longitude: -83.140439, zipCode: "48229"},
	{latitude: 42.382395, longitude: -82.921842, zipCode: "48230"},
	{latitude: 42.323226, longitude: -83.063047, zipCode: "48233"},
	{latitude: 42.431184, longitude: -83.039509, zipCode: "48234"},
	{latitude: 42.427075, longitude: -83.19466, zipCode: "48235"},
	{latitude: 42.422583, longitude: -82.89285, zipCode: "48236"},
	{latitude: 42.464943, longitude: -83.18241, zipCode: "48237"},
	{latitude: 42.396277, longitude: -83.141316, zipCode: "48238"},
	{latitude: 42.376245, longitude: -83.286394, zipCode: "48239"},
	{latitude: 42.424483, longitude: -83.301271, zipCode: "48240"},
	{latitude: 42.213509, longitude: -83.356115, zipCode: "48242"},
	{latitude: 42.329948, longitude: -83.039531, zipCode: "48243"},
	{latitude: 42.5424, longitude: -83.282168, zipCode: "48301"},
	{latitude: 42.581851, longitude: -83.304703, zipCode: "48302"},
	{latitude: 42.58894, longitude: -83.233229, zipCode: "48304"},
	{latitude: 42.724326, longitude: -83.147312, zipCode: "48306"},
	{latitude: 42.659316, longitude: -83.122679, zipCode: "48307"},
	{latitude: 42.657213, longitude: -83.186577, zipCode: "48309"},
	{latitude: 42.566517, longitude: -83.066512, zipCode: "48310"},
	{latitude: 42.558228, longitude: -83.008926, zipCode: "48312"},
	{latitude: 42.599167, longitude: -82.997186, zipCode: "48313"},
	{latitude: 42.612074, longitude: -83.053489, zipCode: "48314"},
	{latitude: 42.671835, longitude: -82.997399, zipCode: "48315"},
	{latitude: 42.690184, longitude: -83.057237, zipCode: "48316"},
	{latitude: 42.644251, longitude: -83.051539, zipCode: "48317"},
	{latitude: 42.611935, longitude: -83.338092, zipCode: "48320"},
	{latitude: 42.537824, longitude: -83.384526, zipCode: "48322"},
	{latitude: 42.570804, longitude: -83.377909, zipCode: "48323"},
	{latitude: 42.594679, longitude: -83.393657, zipCode: "48324"},
	{latitude: 42.688246, longitude: -83.244075, zipCode: "48326"},
	{latitude: 42.6383, longitude: -83.410667, zipCode: "48327"},
	{latitude: 42.65016, longitude: -83.363182, zipCode: "48328"},
	{latitude: 42.687016, longitude: -83.388622, zipCode: "48329"},
	{latitude: 42.502486, longitude: -83.408936, zipCode: "48331"},
	{latitude: 42.504694, longitude: -83.349204, zipCode: "48334"},
	{latitude: 42.462863, longitude: -83.405029, zipCode: "48335"},
	{latitude: 42.463342, longitude: -83.348054, zipCode: "48336"},
	{latitude: 42.672141, longitude: -83.287938, zipCode: "48340"},
	{latitude: 42.626505, longitude: -83.291425, zipCode: "48341"},
	{latitude: 42.64441, longitude: -83.274561, zipCode: "48342"},
	{latitude: 42.717889, longitude: -83.428715, zipCode: "48346"},
	{latitude: 42.768579, longitude: -83.404438, zipCode: "48348"},
	{latitude: 42.739418, longitude: -83.531044, zipCode: "48350"},
	{latitude: 42.646151, longitude: -83.714412, zipCode: "48353"},
	{latitude: 42.654414, longitude: -83.592905, zipCode: "48356"},
	{latitude: 42.658574, longitude: -83.646557, zipCode: "48357"},
	{latitude: 42.720766, longitude: -83.275499, zipCode: "48359"},
	{latitude: 42.747358, longitude: -83.261, zipCode: "48360"},
	{latitude: 42.782351, longitude: -83.269423, zipCode: "48362"},
	{latitude: 42.772483, longitude: -83.162499, zipCode: "48363"},
	{latitude: 42.836232, longitude: -83.136902, zipCode: "48367"},
	{latitude: 42.840232, longitude: -83.191537, zipCode: "48370"},
	{latitude: 42.845874, longitude: -83.284608, zipCode: "48371"},
	{latitude: 42.473843, longitude: -83.519976, zipCode: "48374"},
	{latitude: 42.465798, longitude: -83.464035, zipCode: "48375"},
	{latitude: 42.505368, longitude: -83.473579, zipCode: "48377"},
	{latitude: 42.582994, longitude: -83.666836, zipCode: "48380"},
	{latitude: 42.558412, longitude: -83.591793, zipCode: "48381"},
	{latitude: 42.582368, longitude: -83.51143, zipCode: "48382"},
	{latitude: 42.65561, longitude: -83.525824, zipCode: "48383"},
	{latitude: 42.660183, longitude: -83.479969, zipCode: "48386"},
	{latitude: 42.550711, longitude: -83.474218, zipCode: "48390"},
	{latitude: 42.521838, longitude: -83.545088, zipCode: "48393"},
	{latitude: 43.347337, longitude: -82.661491, zipCode: "48401"},
	{latitude: 43.566289, longitude: -82.936223, zipCode: "48410"},
	{latitude: 42.934196, longitude: -83.529554, zipCode: "48411"},
	{latitude: 43.070433, longitude: -83.175549, zipCode: "48412"},
	{latitude: 43.802747, longitude: -82.996755, zipCode: "48413"},
	{latitude: 42.861694, longitude: -84.079847, zipCode: "48414"},
	{latitude: 43.271876, longitude: -83.808105, zipCode: "48415"},
	{latitude: 43.217071, longitude: -82.984954, zipCode: "48416"},
	{latitude: 43.261282, longitude: -83.939194, zipCode: "48417"},
	{latitude: 42.794295, longitude: -83.969891, zipCode: "48418"},
	{latitude: 43.435806, longitude: -82.660428, zipCode: "48419"},
	{latitude: 43.179103, longitude: -83.700508, zipCode: "48420"},
	{latitude: 43.155496, longitude: -83.393189, zipCode: "48421"},
	{latitude: 43.261472, longitude: -82.653704, zipCode: "48422"},
	{latitude: 43.04042, longitude: -83.51428, zipCode: "48423"},
	{latitude: 43.508618, longitude: -83.065131, zipCode: "48426"},
	{latitude: 43.535638, longitude: -82.725482, zipCode: "48427"},
	{latitude: 42.938195, longitude: -83.154454, zipCode: "48428"},
	{latitude: 42.902799, longitude: -84.004049, zipCode: "48429"},
	{latitude: 42.757217, longitude: -83.738279, zipCode: "48430"},
	{latitude: 43.901679, longitude: -82.974888, zipCode: "48432"},
	{latitude: 43.08221, longitude: -83.861039, zipCode: "48433"},
	{latitude: 43.659867, longitude: -82.612562, zipCode: "48434"},
	{latitude: 43.245379, longitude: -83.359338, zipCode: "48435"},
	{latitude: 42.87611, longitude: -83.879438, zipCode: "48436"},
	{latitude: 43.113049, longitude: -83.618412, zipCode: "48437"},
	{latitude: 42.913657, longitude: -83.484165, zipCode: "48438"},
	{latitude: 42.91714, longitude: -83.631336, zipCode: "48439"},
	{latitude: 42.955721, longitude: -83.407839, zipCode: "48440"},
	{latitude: 43.797892, longitude: -82.70863, zipCode: "48441"},
	{latitude: 42.798926, longitude: -83.595677, zipCode: "48442"},
	{latitude: 43.058491, longitude: -83.045197, zipCode: "48444"},
	{latitude: 43.950222, longitude: -82.990556, zipCode: "48445"},
	{latitude: 43.05374, longitude: -83.338704, zipCode: "48446"},
	{latitude: 42.991452, longitude: -83.944772, zipCode: "48449"},
	{latitude: 43.252306, longitude: -82.540855, zipCode: "48450"},
	{latitude: 42.805959, longitude: -83.815991, zipCode: "48451"},
	{latitude: 43.348144, longitude: -83.032572, zipCode: "48453"},
	{latitude: 43.195011, longitude: -82.812721, zipCode: "48454"},
	{latitude: 42.939796, longitude: -83.29103, zipCode: "48455"},
	{latitude: 43.666971, longitude: -82.762208, zipCode: "48456"},
	{latitude: 43.190051, longitude: -83.894218, zipCode: "48457"},
	{latitude: 43.1253, longitude: -83.68155, zipCode: "48458"},
	{latitude: 43.123857, longitude: -83.984481, zipCode: "48460"},
	{latitude: 43.193661, longitude: -83.206162, zipCode: "48461"},
	{latitude: 42.848445, longitude: -83.42125, zipCode: "48462"},
	{latitude: 43.165799, longitude: -83.519301, zipCode: "48463"},
	{latitude: 43.210907, longitude: -83.419044, zipCode: "48464"},
	{latitude: 43.615727, longitude: -82.705626, zipCode: "48465"},
	{latitude: 43.280507, longitude: -82.808877, zipCode: "48466"},
	{latitude: 44.015356, longitude: -82.982415, zipCode: "48467"},
	{latitude: 43.938412, longitude: -82.775181, zipCode: "48468"},
	{latitude: 43.441469, longitude: -82.558181, zipCode: "48469"},
	{latitude: 43.73618, longitude: -82.76352, zipCode: "48470"},
	{latitude: 43.41942, longitude: -82.852081, zipCode: "48471"},
	{latitude: 43.512323, longitude: -82.963212, zipCode: "48472"},
	{latitude: 42.937062, longitude: -83.820454, zipCode: "48473"},
	{latitude: 43.670124, longitude: -82.933407, zipCode: "48475"},
	{latitude: 42.938318, longitude: -84.03453, zipCode: "48476"},
	{latitude: 43.014645, longitude: -83.689159, zipCode: "48502"},
	{latitude: 42.998726, longitude: -83.710153, zipCode: "48503"},
	{latitude: 43.054725, longitude: -83.743745, zipCode: "48504"},
	{latitude: 43.06916, longitude: -83.692301, zipCode: "48505"},
	{latitude: 43.067472, longitude: -83.624392, zipCode: "48506"},
	{latitude: 42.966974, longitude: -83.71736, zipCode: "48507"},
	{latitude: 43.029903, longitude: -83.60068, zipCode: "48509"},
	{latitude: 42.981908, longitude: -83.604939, zipCode: "48519"},
	{latitude: 42.970338, longitude: -83.65859, zipCode: "48529"},
	{latitude: 43.011068, longitude: -83.794875, zipCode: "48532"},
	{latitude: 43.394997, longitude: -83.88928, zipCode: "48601"},
	{latitude: 43.420026, longitude: -83.974472, zipCode: "48602"},
	{latitude: 43.458786, longitude: -84.029471, zipCode: "48603"},
	{latitude: 43.499764, longitude: -83.969084, zipCode: "48604"},
	{latitude: 43.432412, longitude: -83.933828, zipCode: "48607"},
	{latitude: 43.389617, longitude: -84.08317, zipCode: "48609"},
	{latitude: 44.141691, longitude: -84.192191, zipCode: "48610"},
	{latitude: 43.634148, longitude: -84.097206, zipCode: "48611"},
	{latitude: 43.883024, longitude: -84.439434, zipCode: "48612"},
	{latitude: 43.947886, longitude: -84.155644, zipCode: "48613"},
	{latitude: 43.250991, longitude: -84.298042, zipCode: "48614"},
	{latitude: 43.422241, longitude: -84.474351, zipCode: "48615"},
	{latitude: 43.191074, longitude: -84.11346, zipCode: "48616"},
	{latitude: 43.851308, longitude: -84.717297, zipCode: "48617"},
	{latitude: 43.736779, longitude: -84.556707, zipCode: "48618"},
	{latitude: 44.819736, longitude: -84.020613, zipCode: "48619"},
	{latitude: 43.807628, longitude: -84.380417, zipCode: "48620"},
	{latitude: 44.721241, longitude: -84.012674, zipCode: "48621"},
	{latitude: 43.835257, longitude: -84.891309, zipCode: "48622"},
	{latitude: 43.519318, longitude: -84.133945, zipCode: "48623"},
	{latitude: 44.061093, longitude: -84.45737, zipCode: "48624"},
	{latitude: 44.052142, longitude: -84.837442, zipCode: "48625"},
	{latitude: 43.421368, longitude: -84.221438, zipCode: "48626"},
	{latitude: 44.461678, longitude: -84.748646, zipCode: "48627"},
	{latitude: 43.773578, longitude: -84.324041, zipCode: "48628"},
	{latitude: 44.312238, longitude: -84.784698, zipCode: "48629"},
	{latitude: 44.325497, longitude: -84.773269, zipCode: "48630"},
	{latitude: 43.688645, longitude: -83.995895, zipCode: "48631"},
	{latitude: 43.850411, longitude: -85.022782, zipCode: "48632"},
	{latitude: 43.948976, longitude: -84.93383, zipCode: "48633"},
	{latitude: 43.759621, longitude: -84.035837, zipCode: "48634"},
	{latitude: 44.387169, longitude: -83.99443, zipCode: "48635"},
	{latitude: 44.586019, longitude: -84.28586, zipCode: "48636"},
	{latitude: 43.413712, longitude: -84.33557, zipCode: "48637"},
	{latitude: 43.418925, longitude: -84.018242, zipCode: "48638"},
	{latitude: 43.582166, longitude: -84.337227, zipCode: "48640"},
	{latitude: 43.702468, longitude: -84.235252, zipCode: "48642"},
	{latitude: 44.650682, longitude: -84.086078, zipCode: "48647"},
	{latitude: 43.150461, longitude: -84.217431, zipCode: "48649"},
	{latitude: 43.849287, longitude: -84.013205, zipCode: "48650"},
	{latitude: 44.254775, longitude: -84.614813, zipCode: "48651"},
	{latitude: 43.848749, longitude: -84.216651, zipCode: "48652"},
	{latitude: 44.490344, longitude: -84.605805, zipCode: "48653"},
	{latitude: 44.476724, longitude: -84.11752, zipCode: "48654"},
	{latitude: 43.294547, longitude: -84.159596, zipCode: "48655"},
	{latitude: 44.354828, longitude: -84.461831, zipCode: "48656"},
	{latitude: 43.707391, longitude: -84.423081, zipCode: "48657"},
	{latitude: 43.975339, longitude: -83.936798, zipCode: "48658"},
	{latitude: 44.065548, longitude: -84.050783, zipCode: "48659"},
	{latitude: 44.318406, longitude: -84.236213, zipCode: "48661"},
	{latitude: 43.400996, longitude: -84.416668, zipCode: "48662"},
	{latitude: 43.586877, longitude: -83.557827, zipCode: "48701"},
	{latitude: 44.063642, longitude: -83.675914, zipCode: "48703"},
	{latitude: 44.695826, longitude: -83.656494, zipCode: "48705"},
	{latitude: 43.603805, longitude: -83.955411, zipCode: "48706"},
	{latitude: 43.55706, longitude: -83.84968, zipCode: "48708"},
	{latitude: 43.556513, longitude: -83.987669, zipCode: "48710"},
	{latitude: 43.831749, longitude: -83.393304, zipCode: "48720"},
	{latitude: 44.79677, longitude: -83.32924, zipCode: "48721"},
	{latitude: 43.346724, longitude: -83.844762, zipCode: "48722"},
	{latitude: 43.487517, longitude: -83.384739, zipCode: "48723"},
	{latitude: 43.457759, longitude: -83.926284, zipCode: "48724"},
	{latitude: 43.943956, longitude: -83.209593, zipCode: "48725"},
	{latitude: 43.607392, longitude: -83.168024, zipCode: "48726"},
	{latitude: 43.306497, longitude: -83.181103, zipCode: "48727"},
	{latitude: 44.743789, longitude: -83.822123, zipCode: "48728"},
	{latitude: 43.495939, longitude: -83.173779, zipCode: "48729"},
	{latitude: 44.349359, longitude: -83.487067, zipCode: "48730"},
	{latitude: 43.836579, longitude: -83.151845, zipCode: "48731"},
	{latitude: 43.612331, longitude: -83.789822, zipCode: "48732"},
	{latitude: 43.534102, longitude: -83.593113, zipCode: "48733"},
	{latitude: 43.357562, longitude: -83.74851, zipCode: "48734"},
	{latitude: 43.667593, longitude: -83.270883, zipCode: "48735"},
	{latitude: 44.549723, longitude: -83.712024, zipCode: "48737"},
	{latitude: 44.549602, longitude: -83.336162, zipCode: "48738"},
	{latitude: 44.370453, longitude: -83.844934, zipCode: "48739"},
	{latitude: 44.663889, longitude: -83.372024, zipCode: "48740"},
	{latitude: 43.402479, longitude: -83.179873, zipCode: "48741"},
	{latitude: 44.721621, longitude: -83.446145, zipCode: "48742"},
	{latitude: 44.441124, longitude: -83.863151, zipCode: "48743"},
	{latitude: 43.348042, longitude: -83.359255, zipCode: "48744"},
	{latitude: 44.5637, longitude: -83.484031, zipCode: "48745"},
	{latitude: 43.260277, longitude: -83.541049, zipCode: "48746"},
	{latitude: 43.518879, longitude: -83.764927, zipCode: "48747"},
	{latitude: 44.329236, longitude: -83.697641, zipCode: "48748"},
	{latitude: 44.053935, longitude: -83.900947, zipCode: "48749"},
	{latitude: 44.435199, longitude: -83.472879, zipCode: "48750"},
	{latitude: 43.733157, longitude: -83.242375, zipCode: "48754"},
	{latitude: 43.791501, longitude: -83.24562, zipCode: "48755"},
	{latitude: 44.233746, longitude: -83.973379, zipCode: "48756"},
	{latitude: 43.469859, longitude: -83.676311, zipCode: "48757"},
	{latitude: 43.730478, longitude: -83.385904, zipCode: "48759"},
	{latitude: 43.317658, longitude: -83.252582, zipCode: "48760"},
	{latitude: 44.510924, longitude: -83.89171, zipCode: "48761"},
	{latitude: 44.799958, longitude: -83.496914, zipCode: "48762"},
	{latitude: 44.246047, longitude: -83.613405, zipCode: "48763"},
	{latitude: 44.151556, longitude: -83.717952, zipCode: "48765"},
	{latitude: 44.110287, longitude: -83.856275, zipCode: "48766"},
	{latitude: 43.650518, longitude: -83.463154, zipCode: "48767"},
	{latitude: 43.370353, longitude: -83.576451, zipCode: "48768"},
	{latitude: 44.251183, longitude: -83.814184, zipCode: "48770"},
	{latitude: 43.378415, longitude: -84.676007, zipCode: "48801"},
	{latitude: 43.186204, longitude: -84.49531, zipCode: "48806"},
	{latitude: 43.150476, longitude: -84.402006, zipCode: "48807"},
	{latitude: 42.826809, longitude: -84.452707, zipCode: "48808"},
	{latitude: 43.075299, longitude: -85.257962, zipCode: "48809"},
	{latitude: 43.183922, longitude: -84.855988, zipCode: "48811"},
	{latitude: 42.573445, longitude: -84.845726, zipCode: "48813"},
	{latitude: 42.834371, longitude: -85.258585, zipCode: "48815"},
	{latitude: 42.765009, longitude: -83.955981, zipCode: "48816"},
	{latitude: 43.013949, longitude: -84.042884, zipCode: "48817"},
	{latitude: 43.272681, longitude: -84.897851, zipCode: "48818"},
	{latitude: 42.54423, longitude: -84.293281, zipCode: "48819"},
	{latitude: 42.863913, longitude: -84.592296, zipCode: "48820"},
	{latitude: 42.640388, longitude: -84.6513, zipCode: "48821"},
	{latitude: 42.835279, longitude: -84.763128, zipCode: "48822"},
	{latitude: 42.764057, longitude: -84.459921, zipCode: "48823"},
	{latitude: 42.721668, longitude: -84.475011, zipCode: "48824"},
	{latitude: 42.731621, longitude: -84.490348, zipCode: "48825"},
	{latitude: 42.508448, longitude: -84.661278, zipCode: "48827"},
	{latitude: 43.398205, longitude: -85.015378, zipCode: "48829"},
	{latitude: 43.096225, longitude: -84.372473, zipCode: "48831"},
	{latitude: 43.411883, longitude: -84.776582, zipCode: "48832"},
	{latitude: 43.128796, longitude: -85.031237, zipCode: "48834"},
	{latitude: 43.024903, longitude: -84.749057, zipCode: "48835"},
	{latitude: 42.669319, longitude: -84.076583, zipCode: "48836"},
	{latitude: 42.74701, longitude: -84.771033, zipCode: "48837"},
	{latitude: 43.182841, longitude: -85.258386, zipCode: "48838"},
	{latitude: 42.763138, longitude: -84.379361, zipCode: "48840"},
	{latitude: 43.112204, longitude: -84.239315, zipCode: "48841"},
	{latitude: 42.635311, longitude: -84.539319, zipCode: "48842"},
	{latitude: 42.575579, longitude: -83.923381, zipCode: "48843"},
	{latitude: 43.111475, longitude: -84.852555, zipCode: "48845"},
	{latitude: 42.986125, longitude: -85.057887, zipCode: "48846"},
	{latitude: 43.273232, longitude: -84.575579, zipCode: "48847"},
	{latitude: 42.881256, longitude: -84.356618, zipCode: "48848"},
	{latitude: 42.797413, longitude: -85.127567, zipCode: "48849"},
	{latitude: 43.435272, longitude: -85.246061, zipCode: "48850"},
	{latitude: 42.951055, longitude: -84.9364, zipCode: "48851"},
	{latitude: 43.352631, longitude: -85.046437, zipCode: "48852"},
	{latitude: 43.099962, longitude: -84.688515, zipCode: "48853"},
	{latitude: 42.581698, longitude: -84.451612, zipCode: "48854"},
	{latitude: 42.681274, longitude: -83.8972, zipCode: "48855"},
	{latitude: 43.19719, longitude: -84.733504, zipCode: "48856"},
	{latitude: 42.840946, longitude: -84.155615, zipCode: "48857"},
	{latitude: 43.616033, longitude: -84.795459, zipCode: "48858"},
	{latitude: 43.586849, longitude: -84.772768, zipCode: "48859"},
	{latitude: 43.052972, longitude: -84.92172, zipCode: "48860"},
	{latitude: 42.729428, longitude: -84.915601, zipCode: "48861"},
	{latitude: 43.249917, longitude: -84.548357, zipCode: "48862"},
	{latitude: 42.70417, longitude: -84.392139, zipCode: "48864"},
	{latitude: 43.090516, longitude: -85.104617, zipCode: "48865"},
	{latitude: 42.993433, longitude: -84.382609, zipCode: "48866"},
	{latitude: 43.005586, longitude: -84.187626, zipCode: "48867"},
	{latitude: 43.110264, longitude: -84.984823, zipCode: "48870"},
	{latitude: 43.156653, longitude: -84.673805, zipCode: "48871"},
	{latitude: 42.79865, longitude: -84.223099, zipCode: "48872"},
	{latitude: 43.007396, longitude: -84.828157, zipCode: "48873"},
	{latitude: 43.186449, longitude: -84.594937, zipCode: "48874"},
	{latitude: 42.860813, longitude: -84.941448, zipCode: "48875"},
	{latitude: 42.648423, longitude: -84.733104, zipCode: "48876"},
	{latitude: 43.403734, longitude: -84.840802, zipCode: "48877"},
	{latitude: 43.706095, longitude: -84.779858, zipCode: "48878"},
	{latitude: 43.006704, longitude: -84.579248, zipCode: "48879"},
	{latitude: 43.458864, longitude: -84.578359, zipCode: "48880"},
	{latitude: 42.932743, longitude: -85.204349, zipCode: "48881"},
	{latitude: 43.543727, longitude: -84.673886, zipCode: "48883"},
	{latitude: 43.209843, longitude: -85.049208, zipCode: "48884"},
	{latitude: 43.242912, longitude: -85.147939, zipCode: "48885"},
	{latitude: 43.415719, longitude: -85.159692, zipCode: "48886"},
	{latitude: 43.314815, longitude: -85.106068, zipCode: "48888"},
	{latitude: 43.285072, longitude: -84.8069, zipCode: "48889"},
	{latitude: 42.766873, longitude: -84.966363, zipCode: "48890"},
	{latitude: 43.395354, longitude: -84.911507, zipCode: "48891"},
	{latitude: 42.643735, longitude: -84.170035, zipCode: "48892"},
	{latitude: 43.686552, longitude: -84.989883, zipCode: "48893"},
	{latitude: 42.925563, longitude: -84.792902, zipCode: "48894"},
	{latitude: 42.688915, longitude: -84.279784, zipCode: "48895"},
	{latitude: 43.52125, longitude: -84.901015, zipCode: "48896"},
	{latitude: 42.703898, longitude: -85.130349, zipCode: "48897"},
	{latitude: 42.788027, longitude: -84.589435, zipCode: "48906"},
	{latitude: 42.697056, longitude: -84.52504, zipCode: "48910"},
	{latitude: 42.672858, longitude: -84.5734, zipCode: "48911"},
	{latitude: 42.738361, longitude: -84.524017, zipCode: "48912"},
	{latitude: 42.739959, longitude: -84.570887, zipCode: "48915"},
	{latitude: 42.725239, longitude: -84.639542, zipCode: "48917"},
	{latitude: 42.729373, longitude: -84.556242, zipCode: "48933"},
	{latitude: 42.264543, longitude: -85.561545, zipCode: "49001"},
	{latitude: 42.197202, longitude: -85.555543, zipCode: "49002"},
	{latitude: 42.349081, longitude: -85.558418, zipCode: "49004"},
	{latitude: 42.295385, longitude: -85.629061, zipCode: "49006"},
	{latitude: 42.302591, longitude: -85.588193, zipCode: "49007"},
	{latitude: 42.263643, longitude: -85.613247, zipCode: "49008"},
	{latitude: 42.303617, longitude: -85.698025, zipCode: "49009"},
	{latitude: 42.532965, longitude: -85.871134, zipCode: "49010"},
	{latitude: 42.099005, longitude: -85.225472, zipCode: "49011"},
	{latitude: 42.350751, longitude: -85.344597, zipCode: "49012"},
	{latitude: 42.302382, longitude: -86.100335, zipCode: "49013"},
	{latitude: 42.309994, longitude: -85.10697, zipCode: "49014"},
	{latitude: 42.278204, longitude: -85.229231, zipCode: "49015"},
	{latitude: 42.391685, longitude: -85.201106, zipCode: "49017"},
	{latitude: 42.460434, longitude: -85.054743, zipCode: "49021"},
	{latitude: 42.112289, longitude: -86.373776, zipCode: "49022"},
	{latitude: 42.206513, longitude: -85.617997, zipCode: "49024"},
	{latitude: 42.376862, longitude: -85.963435, zipCode: "49026"},
	{latitude: 42.350328, longitude: -86.067386, zipCode: "49027"},
	{latitude: 41.844036, longitude: -85.179964, zipCode: "49028"},
	{latitude: 42.140731, longitude: -85.089143, zipCode: "49029"},
	{latitude: 41.865948, longitude: -85.338879, zipCode: "49030"},
	{latitude: 41.903528, longitude: -85.976781, zipCode: "49031"},
	{latitude: 41.911488, longitude: -85.52287, zipCode: "49032"},
	{latitude: 42.227502, longitude: -85.088714, zipCode: "49033"},
	{latitude: 42.234273, longitude: -85.330521, zipCode: "49034"},
	{latitude: 41.90548, longitude: -85.031277, zipCode: "49036"},
	{latitude: 42.334561, longitude: -85.249172, zipCode: "49037"},
	{latitude: 42.202822, longitude: -86.320868, zipCode: "49038"},
	{latitude: 41.965926, longitude: -85.323248, zipCode: "49040"},
	{latitude: 41.859375, longitude: -85.669395, zipCode: "49042"},
	{latitude: 42.285992, longitude: -86.268503, zipCode: "49043"},
	{latitude: 42.102102, longitude: -85.999123, zipCode: "49045"},
	{latitude: 42.519178, longitude: -85.387984, zipCode: "49046"},
	{latitude: 42.004654, longitude: -86.120706, zipCode: "49047"},
	{latitude: 42.274268, longitude: -85.493081, zipCode: "49048"},
	{latitude: 42.504302, longitude: -85.245704, zipCode: "49050"},
	{latitude: 42.176669, longitude: -85.236608, zipCode: "49051"},
	{latitude: 42.121199, longitude: -85.315221, zipCode: "49052"},
	{latitude: 42.286125, longitude: -85.411396, zipCode: "49053"},
	{latitude: 42.368474, longitude: -85.857813, zipCode: "49055"},
	{latitude: 42.395243, longitude: -86.066041, zipCode: "49056"},
	{latitude: 42.184475, longitude: -86.18301, zipCode: "49057"},
	{latitude: 42.636422, longitude: -85.29969, zipCode: "49058"},
	{latitude: 42.424345, longitude: -85.40485, zipCode: "49060"},
	{latitude: 41.873542, longitude: -85.815243, zipCode: "49061"},
	{latitude: 42.409875, longitude: -86.139682, zipCode: "49063"},
	{latitude: 42.219819, longitude: -86.050128, zipCode: "49064"},
	{latitude: 42.138273, longitude: -85.842108, zipCode: "49065"},
	{latitude: 42.031263, longitude: -85.343516, zipCode: "49066"},
	{latitude: 42.024172, longitude: -85.798766, zipCode: "49067"},
	{latitude: 42.275694, longitude: -84.940816, zipCode: "49068"},
	{latitude: 42.53933, longitude: -85.62939, zipCode: "49070"},
	{latitude: 42.226439, longitude: -85.775518, zipCode: "49071"},
	{latitude: 42.012663, longitude: -85.457739, zipCode: "49072"},
	{latitude: 42.574928, longitude: -85.126192, zipCode: "49073"},
	{latitude: 42.320279, longitude: -85.540318, zipCode: "49074"},
	{latitude: 41.917513, longitude: -85.448603, zipCode: "49075"},
	{latitude: 42.428635, longitude: -84.88033, zipCode: "49076"},
	{latitude: 42.47251, longitude: -85.732849, zipCode: "49078"},
	{latitude: 42.240155, longitude: -85.908075, zipCode: "49079"},
	{latitude: 42.456426, longitude: -85.588337, zipCode: "49080"},
	{latitude: 41.940929, longitude: -84.87654, zipCode: "49082"},
	{latitude: 42.37811, longitude: -85.461128, zipCode: "49083"},
	{latitude: 42.180349, longitude: -86.385082, zipCode: "49084"},
	{latitude: 42.054277, longitude: -86.4626, zipCode: "49085"},
	{latitude: 42.12458, longitude: -85.676226, zipCode: "49087"},
	{latitude: 42.179736, longitude: -85.422562, zipCode: "49088"},
	{latitude: 42.001176, longitude: -85.234458, zipCode: "49089"},
	{latitude: 42.407427, longitude: -86.214544, zipCode: "49090"},
	{latitude: 41.828331, longitude: -85.447929, zipCode: "49091"},
	{latitude: 42.094284, longitude: -84.962983, zipCode: "49092"},
	{latitude: 41.975744, longitude: -85.64701, zipCode: "49093"},
	{latitude: 42.050304, longitude: -85.11357, zipCode: "49094"},
	{latitude: 41.906366, longitude: -85.885593, zipCode: "49095"},
	{latitude: 42.637069, longitude: -85.007259, zipCode: "49096"},
	{latitude: 42.121971, longitude: -85.487442, zipCode: "49097"},
	{latitude: 42.163885, longitude: -86.239986, zipCode: "49098"},
	{latitude: 41.793262, longitude: -85.671752, zipCode: "49099"},
	{latitude: 41.944405, longitude: -86.483407, zipCode: "49101"},
	{latitude: 41.949226, longitude: -86.252076, zipCode: "49102"},
	{latitude: 41.946299, longitude: -86.376474, zipCode: "49103"},
	{latitude: 41.963527, longitude: -86.358893, zipCode: "49104"},
	{latitude: 41.938283, longitude: -86.554765, zipCode: "49106"},
	{latitude: 41.844909, longitude: -86.417637, zipCode: "49107"},
	{latitude: 42.022699, longitude: -86.291132, zipCode: "49111"},
	{latitude: 41.796034, longitude: -86.03054, zipCode: "49112"},
	{latitude: 41.803262, longitude: -86.501365, zipCode: "49113"},
	{latitude: 41.875274, longitude: -86.633858, zipCode: "49115"},
	{latitude: 41.848108, longitude: -86.672121, zipCode: "49116"},
	{latitude: 41.781671, longitude: -86.726836, zipCode: "49117"},
	{latitude: 41.875946, longitude: -86.549234, zipCode: "49119"},
	{latitude: 41.831896, longitude: -86.230837, zipCode: "49120"},
	{latitude: 41.891897, longitude: -86.579415, zipCode: "49125"},
	{latitude: 42.032522, longitude: -86.371812, zipCode: "49126"},
	{latitude: 42.000088, longitude: -86.521741, zipCode: "49127"},
	{latitude: 41.820406, longitude: -86.600497, zipCode: "49128"},
	{latitude: 41.816107, longitude: -86.686432, zipCode: "49129"},
	{latitude: 41.774422, longitude: -85.860349, zipCode: "49130"},
	{latitude: 42.311764, longitude: -84.456172, zipCode: "49201"},
	{latitude: 42.267179, longitude: -84.41085, zipCode: "49202"},
	{latitude: 42.220698, longitude: -84.401859, zipCode: "49203"},
	{latitude: 41.998622, longitude: -84.337415, zipCode: "49220"},
	{latitude: 41.902354, longitude: -84.064698, zipCode: "49221"},
	{latitude: 42.279617, longitude: -84.746876, zipCode: "49224"},
	{latitude: 41.949776, longitude: -84.773057, zipCode: "49227"},
	{latitude: 41.809023, longitude: -83.875598, zipCode: "49228"},
	{latitude: 41.992104, longitude: -83.826482, zipCode: "49229"},
	{latitude: 42.090395, longitude: -84.2173, zipCode: "49230"},
	{latitude: 41.740366, longitude: -84.664597, zipCode: "49232"},
	{latitude: 42.061765, longitude: -84.367016, zipCode: "49233"},
	{latitude: 42.127037, longitude: -84.368713, zipCode: "49234"},
	{latitude: 41.863913, longitude: -84.20092, zipCode: "49235"},
	{latitude: 42.076033, longitude: -83.942349, zipCode: "49236"},
	{latitude: 42.173248, longitude: -84.648128, zipCode: "49237"},
	{latitude: 41.90223, longitude: -83.785596, zipCode: "49238"},
	{latitude: 42.271975, longitude: -84.179743, zipCode: "49240"},
	{latitude: 42.107843, longitude: -84.603335, zipCode: "49241"},
	{latitude: 41.886298, longitude: -84.622698, zipCode: "49242"},
	{latitude: 42.139366, longitude: -84.811584, zipCode: "49245"},
	{latitude: 42.122922, longitude: -84.503513, zipCode: "49246"},
	{latitude: 41.837616, longitude: -84.338261, zipCode: "49247"},
	{latitude: 41.756481, longitude: -84.011322, zipCode: "49248"},
	{latitude: 42.029334, longitude: -84.454755, zipCode: "49249"},
	{latitude: 42.021736, longitude: -84.626224, zipCode: "49250"},
	{latitude: 42.463459, longitude: -84.413194, zipCode: "49251"},
	{latitude: 42.035237, longitude: -84.765398, zipCode: "49252"},
	{latitude: 41.961543, longitude: -84.269284, zipCode: "49253"},
	{latitude: 42.225629, longitude: -84.323633, zipCode: "49254"},
	{latitude: 41.768259, longitude: -84.862872, zipCode: "49255"},
	{latitude: 41.746804, longitude: -84.214469, zipCode: "49256"},
	{latitude: 42.059989, longitude: -84.658414, zipCode: "49258"},
	{latitude: 42.382906, longitude: -84.255763, zipCode: "49259"},
	{latitude: 42.162684, longitude: -84.247, zipCode: "49261"},
	{latitude: 41.963612, longitude: -84.46829, zipCode: "49262"},
	{latitude: 42.158094, longitude: -84.182119, zipCode: "49263"},
	{latitude: 42.446232, longitude: -84.555061, zipCode: "49264"},
	{latitude: 42.006721, longitude: -84.175289, zipCode: "49265"},
	{latitude: 41.83975, longitude: -84.546789, zipCode: "49266"},
	{latitude: 41.757323, longitude: -83.719429, zipCode: "49267"},
	{latitude: 41.868704, longitude: -83.929015, zipCode: "49268"},
	{latitude: 42.288195, longitude: -84.580066, zipCode: "49269"},
	{latitude: 41.873675, longitude: -83.680783, zipCode: "49270"},
	{latitude: 41.831987, longitude: -84.453091, zipCode: "49271"},
	{latitude: 42.394756, longitude: -84.351271, zipCode: "49272"},
	{latitude: 41.846682, longitude: -84.759784, zipCode: "49274"},
	{latitude: 41.794583, longitude: -83.776666, zipCode: "49276"},
	{latitude: 42.388652, longitude: -84.469336, zipCode: "49277"},
	{latitude: 41.779648, longitude: -84.104066, zipCode: "49279"},
	{latitude: 42.03305, longitude: -84.400783, zipCode: "49282"},
	{latitude: 42.20139, longitude: -84.556087, zipCode: "49283"},
	{latitude: 42.382301, longitude: -84.690714, zipCode: "49284"},
	{latitude: 42.463242, longitude: -84.210049, zipCode: "49285"},
	{latitude: 42.009371, longitude: -83.930277, zipCode: "49286"},
	{latitude: 42.02303, longitude: -84.073485, zipCode: "49287"},
	{latitude: 41.733942, longitude: -84.429587, zipCode: "49288"},
	{latitude: 41.771403, longitude: -84.107074, zipCode: "49289"},
	{latitude: 42.973443, longitude: -85.476998, zipCode: "49301"},
	{latitude: 42.821539, longitude: -85.402238, zipCode: "49302"},
	{latitude: 43.271549, longitude: -85.860632, zipCode: "49303"},
	{latitude: 43.907821, longitude: -85.872637, zipCode: "49304"},
	{latitude: 43.752024, longitude: -85.151106, zipCode: "49305"},
	{latitude: 43.074829, longitude: -85.565582, zipCode: "49306"},
	{latitude: 43.697682, longitude: -85.482957, zipCode: "49307"},
	{latitude: 43.759789, longitude: -85.876901, zipCode: "49309"},
	{latitude: 43.513812, longitude: -85.04746, zipCode: "49310"},
	{latitude: 43.686004, longitude: -85.783517, zipCode: "49312"},
	{latitude: 42.80363, longitude: -85.741985, zipCode: "49315"},
	{latitude: 42.793767, longitude: -85.550467, zipCode: "49316"},
	{latitude: 43.227276, longitude: -85.820431, zipCode: "49318"},
	{latitude: 43.230087, longitude: -85.528037, zipCode: "49319"},
	{latitude: 43.755143, longitude: -85.287539, zipCode: "49320"},
	{latitude: 43.073608, longitude: -85.681875, zipCode: "49321"},
	{latitude: 43.355784, longitude: -85.351225, zipCode: "49322"},
	{latitude: 42.728548, longitude: -85.790642, zipCode: "49323"},
	{latitude: 42.760797, longitude: -85.301726, zipCode: "49325"},
	{latitude: 43.253633, longitude: -85.317352, zipCode: "49326"},
	{latitude: 43.323527, longitude: -85.845311, zipCode: "49327"},
	{latitude: 42.63368, longitude: -85.758168, zipCode: "49328"},
	{latitude: 43.4067, longitude: -85.494998, zipCode: "49329"},
	{latitude: 43.241536, longitude: -85.736679, zipCode: "49330"},
	{latitude: 42.950189, longitude: -85.351989, zipCode: "49331"},
	{latitude: 43.620936, longitude: -85.241642, zipCode: "49332"},
	{latitude: 42.695042, longitude: -85.463674, zipCode: "49333"},
	{latitude: 42.746252, longitude: -85.66907, zipCode: "49335"},
	{latitude: 43.508359, longitude: -85.435658, zipCode: "49336"},
	{latitude: 43.423029, longitude: -85.718784, zipCode: "49337"},
	{latitude: 43.771679, longitude: -85.602298, zipCode: "49338"},
	{latitude: 43.331355, longitude: -85.496459, zipCode: "49339"},
	{latitude: 43.61793, longitude: -85.110768, zipCode: "49340"},
	{latitude: 43.124135, longitude: -85.490928, zipCode: "49341"},
	{latitude: 43.690435, longitude: -85.315591, zipCode: "49342"},
	{latitude: 43.299716, longitude: -85.526074, zipCode: "49343"},
	{latitude: 42.589905, longitude: -85.591301, zipCode: "49344"},
	{latitude: 43.153922, longitude: -85.70523, zipCode: "49345"},
	{latitude: 43.594885, longitude: -85.421128, zipCode: "49346"},
	{latitude: 43.312299, longitude: -85.345236, zipCode: "49347"},
	{latitude: 42.685079, longitude: -85.610075, zipCode: "49348"},
	{latitude: 43.575337, longitude: -85.748732, zipCode: "49349"},
	{latitude: 42.977594, longitude: -85.958569, zipCode: "49401"},
	{latitude: 43.94098, longitude: -86.018022, zipCode: "49402"},
	{latitude: 43.140975, longitude: -85.857766, zipCode: "49403"},
	{latitude: 43.063438, longitude: -85.952185, zipCode: "49404"},
	{latitude: 43.907283, longitude: -86.189734, zipCode: "49405"},
	{latitude: 42.64076, longitude: -86.210618, zipCode: "49406"},
	{latitude: 42.571678, longitude: -86.116236, zipCode: "49408"},
	{latitude: 43.080755, longitude: -86.218375, zipCode: "49409"},
	{latitude: 44.027217, longitude: -86.148635, zipCode: "49410"},
	{latitude: 44.114566, longitude: -86.256829, zipCode: "49411"},
	{latitude: 43.470153, longitude: -85.946264, zipCode: "49412"},
	{latitude: 43.150964, longitude: -86.114307, zipCode: "49415"},
	{latitude: 43.009233, longitude: -86.152022, zipCode: "49417"},
	{latitude: 42.878123, longitude: -85.767925, zipCode: "49418"},
	{latitude: 42.676963, longitude: -85.979912, zipCode: "49419"},
	{latitude: 43.719985, longitude: -86.282313, zipCode: "49420"},
	{latitude: 43.599532, longitude: -86.069216, zipCode: "49421"},
	{latitude: 42.739168, longitude: -86.084793, zipCode: "49423"},
	{latitude: 42.832778, longitude: -86.130421, zipCode: "49424"},
	{latitude: 43.450442, longitude: -86.110575, zipCode: "49425"},
	{latitude: 42.863317, longitude: -85.887576, zipCode: "49426"},
	{latitude: 42.920207, longitude: -85.835883, zipCode: "49428"},
	{latitude: 43.012006, longitude: -85.900493, zipCode: "49430"},
	{latitude: 43.964611, longitude: -86.409251, zipCode: "49431"},
	{latitude: 42.770029, longitude: -86.20771, zipCode: "49434"},
	{latitude: 43.024453, longitude: -85.835175, zipCode: "49435"},
	{latitude: 43.687483, longitude: -86.46853, zipCode: "49436"},
	{latitude: 43.463596, longitude: -86.384631, zipCode: "49437"},
	{latitude: 43.23747, longitude: -86.253396, zipCode: "49440"},
	{latitude: 43.179962, longitude: -86.286824, zipCode: "49441"},
	{latitude: 43.241841, longitude: -86.135719, zipCode: "49442"},
	{latitude: 43.222007, longitude: -86.241585, zipCode: "49443"},
	{latitude: 43.177117, longitude: -86.199527, zipCode: "49444"},
	{latitude: 43.292537, longitude: -86.293883, zipCode: "49445"},
	{latitude: 43.539803, longitude: -86.378167, zipCode: "49446"},
	{latitude: 43.094383, longitude: -86.071643, zipCode: "49448"},
	{latitude: 43.793624, longitude: -86.388577, zipCode: "49449"},
	{latitude: 42.477969, longitude: -86.075032, zipCode: "49450"},
	{latitude: 43.213069, longitude: -85.967762, zipCode: "49451"},
	{latitude: 43.514595, longitude: -86.254788, zipCode: "49452"},
	{latitude: 42.661281, longitude: -86.178771, zipCode: "49453"},
	{latitude: 43.946348, longitude: -86.285058, zipCode: "49454"},
	{latitude: 43.610287, longitude: -86.378251, zipCode: "49455"},
	{latitude: 43.091147, longitude: -86.206467, zipCode: "49456"},
	{latitude: 43.351479, longitude: -86.145156, zipCode: "49457"},
	{latitude: 43.948125, longitude: -86.114888, zipCode: "49458"},
	{latitude: 43.736074, longitude: -86.095686, zipCode: "49459"},
	{latitude: 42.932375, longitude: -86.151244, zipCode: "49460"},
	{latitude: 43.37686, longitude: -86.331126, zipCode: "49461"},
	{latitude: 42.844796, longitude: -85.984799, zipCode: "49464"},
	{latitude: 42.96212, longitude: -85.659394, zipCode: "49503"},
	{latitude: 42.981579, longitude: -85.7107, zipCode: "49504"},
	{latitude: 42.996477, longitude: -85.637735, zipCode: "49505"},
	{latitude: 42.945748, longitude: -85.613839, zipCode: "49506"},
	{latitude: 42.930584, longitude: -85.655257, zipCode: "49507"},
	{latitude: 42.872994, longitude: -85.628015, zipCode: "49508"},
	{latitude: 42.897764, longitude: -85.693418, zipCode: "49509"},
	{latitude: 42.880121, longitude: -85.553203, zipCode: "49512"},
	{latitude: 42.895765, longitude: -85.71951, zipCode: "49519"},
	{latitude: 43.022168, longitude: -85.592115, zipCode: "49525"},
	{latitude: 42.964438, longitude: -85.787181, zipCode: "49534"},
	{latitude: 43.038932, longitude: -85.74657, zipCode: "49544"},
	{latitude: 42.93291, longitude: -85.535868, zipCode: "49546"},
	{latitude: 42.867826, longitude: -85.662972, zipCode: "49548"},
	{latitude: 44.242697, longitude: -85.539922, zipCode: "49601"},
	{latitude: 44.979986, longitude: -84.978744, zipCode: "49611"},
	{latitude: 44.863464, longitude: -85.224922, zipCode: "49612"},
	{latitude: 44.50612, longitude: -86.206892, zipCode: "49613"},
	{latitude: 44.445628, longitude: -86.111686, zipCode: "49614"},
	{latitude: 44.964551, longitude: -85.206629, zipCode: "49615"},
	{latitude: 44.579933, longitude: -86.090298, zipCode: "49616"},
	{latitude: 44.641515, longitude: -86.032239, zipCode: "49617"},
	{latitude: 44.302589, longitude: -85.590049, zipCode: "49618"},
	{latitude: 44.293058, longitude: -85.991212, zipCode: "49619"},
	{latitude: 44.516271, longitude: -85.686369, zipCode: "49620"},
	{latitude: 44.857781, longitude: -85.76991, zipCode: "49621"},
	{latitude: 45.075064, longitude: -85.267082, zipCode: "49622"},
	{latitude: 43.908889, longitude: -85.691816, zipCode: "49623"},
	{latitude: 44.43274, longitude: -85.858343, zipCode: "49625"},
	{latitude: 44.242823, longitude: -86.292548, zipCode: "49626"},
	{latitude: 45.112422, longitude: -85.358744, zipCode: "49627"},
	{latitude: 44.620998, longitude: -86.234097, zipCode: "49628"},
	{latitude: 44.918988, longitude: -85.390286, zipCode: "49629"},
	{latitude: 44.840524, longitude: -86.046492, zipCode: "49630"},
	{latitude: 43.916915, longitude: -85.259288, zipCode: "49631"},
	{latitude: 44.239007, longitude: -84.967889, zipCode: "49632"},
	{latitude: 44.562155, longitude: -85.185029, zipCode: "49633"},
	{latitude: 44.214721, longitude: -86.288768, zipCode: "49634"},
	{latitude: 44.625614, longitude: -86.193112, zipCode: "49635"},
	{latitude: 44.891248, longitude: -85.964596, zipCode: "49636"},
	{latitude: 44.636211, longitude: -85.71054, zipCode: "49637"},
	{latitude: 44.286973, longitude: -85.755675, zipCode: "49638"},
	{latitude: 43.84701, longitude: -85.408527, zipCode: "49639"},
	{latitude: 44.724471, longitude: -86.04736, zipCode: "49640"},
	{latitude: 43.904253, longitude: -85.783959, zipCode: "49642"},
	{latitude: 44.639738, longitude: -85.828944, zipCode: "49643"},
	{latitude: 44.111533, longitude: -85.917804, zipCode: "49644"},
	{latitude: 44.372119, longitude: -86.025418, zipCode: "49645"},
	{latitude: 44.719997, longitude: -85.036456, zipCode: "49646"},
	{latitude: 44.997686, longitude: -85.338808, zipCode: "49648"},
	{latitude: 44.563373, longitude: -85.525432, zipCode: "49649"},
	{latitude: 44.739836, longitude: -85.887151, zipCode: "49650"},
	{latitude: 44.398984, longitude: -85.106294, zipCode: "49651"},
	{latitude: 44.973631, longitude: -85.723949, zipCode: "49653"},
	{latitude: 45.009769, longitude: -86.136299, zipCode: "49654"},
	{latitude: 44.024932, longitude: -85.43205, zipCode: "49655"},
	{latitude: 44.055606, longitude: -85.697482, zipCode: "49656"},
	{latitude: 44.215267, longitude: -85.183625, zipCode: "49657"},
	{latitude: 44.905563, longitude: -85.034174, zipCode: "49659"},
	{latitude: 44.233751, longitude: -86.210649, zipCode: "49660"},
	{latitude: 44.42336, longitude: -85.393573, zipCode: "49663"},
	{latitude: 44.887981, longitude: -85.898639, zipCode: "49664"},
	{latitude: 44.100372, longitude: -85.132049, zipCode: "49665"},
	{latitude: 44.345956, longitude: -84.931109, zipCode: "49667"},
	{latitude: 44.40184, longitude: -85.713277, zipCode: "49668"},
	{latitude: 45.119283, longitude: -85.619724, zipCode: "49670"},
	{latitude: 45.047907, longitude: -85.591578, zipCode: "49674"},
	{latitude: 44.384164, longitude: -86.225376, zipCode: "49675"},
	{latitude: 44.843437, longitude: -85.294433, zipCode: "49676"},
	{latitude: 43.896335, longitude: -85.53535, zipCode: "49677"},
	{latitude: 43.878375, longitude: -85.158857, zipCode: "49679"},
	{latitude: 44.648125, longitude: -85.241466, zipCode: "49680"},
	{latitude: 44.988312, longitude: -85.647337, zipCode: "49682"},
	{latitude: 44.540521, longitude: -85.926894, zipCode: "49683"},
	{latitude: 44.762372, longitude: -85.685156, zipCode: "49684"},
	{latitude: 44.699632, longitude: -85.700684, zipCode: "49685"},
	{latitude: 44.849403, longitude: -85.542616, zipCode: "49686"},
	{latitude: 44.126516, longitude: -85.440211, zipCode: "49688"},
	{latitude: 44.211072, longitude: -85.910757, zipCode: "49689"},
	{latitude: 44.784314, longitude: -85.394673, zipCode: "49690"},
	{latitude: 44.674376, longitude: -85.487113, zipCode: "49696"},
	{latitude: 45.741789, longitude: -84.740904, zipCode: "49701"},
	{latitude: 45.348552, longitude: -84.463353, zipCode: "49705"},
	{latitude: 45.438274, longitude: -84.779397, zipCode: "49706"},
	{latitude: 45.094306, longitude: -83.476214, zipCode: "49707"},
	{latitude: 45.043661, longitude: -84.171382, zipCode: "49709"},
	{latitude: 46.276096, longitude: -84.176734, zipCode: "49710"},
	{latitude: 45.21121, longitude: -85.010975, zipCode: "49712"},
	{latitude: 45.220839, longitude: -84.845493, zipCode: "49713"},
	{latitude: 46.401119, longitude: -84.6925, zipCode: "49715"},
	{latitude: 45.509448, longitude: -84.749886, zipCode: "49716"},
	{latitude: 45.425791, longitude: -84.687367, zipCode: "49717"},
	{latitude: 45.716626, longitude: -84.843092, zipCode: "49718"},
	{latitude: 45.990618, longitude: -84.322321, zipCode: "49719"},
	{latitude: 45.280509, longitude: -85.245046, zipCode: "49720"},
	{latitude: 45.574179, longitude: -84.455446, zipCode: "49721"},
	{latitude: 45.420846, longitude: -84.865106, zipCode: "49722"},
	{latitude: 46.32764, longitude: -84.390112, zipCode: "49724"},
	{latitude: 45.985831, longitude: -83.989467, zipCode: "49725"},
	{latitude: 46.003738, longitude: -83.615219, zipCode: "49726"},
	{latitude: 45.137651, longitude: -85.108962, zipCode: "49727"},
	{latitude: 46.386704, longitude: -85.024763, zipCode: "49728"},
	{latitude: 45.163731, longitude: -85.292999, zipCode: "49729"},
	{latitude: 45.044034, longitude: -84.867529, zipCode: "49730"},
	{latitude: 44.794071, longitude: -84.730562, zipCode: "49733"},
	{latitude: 44.998087, longitude: -84.670164, zipCode: "49735"},
	{latitude: 46.087784, longitude: -84.138358, zipCode: "49736"},
	{latitude: 44.692289, longitude: -84.595113, zipCode: "49738"},
	{latitude: 45.521875, longitude: -84.990777, zipCode: "49740"},
	{latitude: 45.266958, longitude: -83.893364, zipCode: "49743"},
	{latitude: 44.993123, longitude: -83.657097, zipCode: "49744"},
	{latitude: 46.021302, longitude: -84.528114, zipCode: "49745"},
	{latitude: 45.062987, longitude: -83.951062, zipCode: "49746"},
	{latitude: 44.835571, longitude: -83.629725, zipCode: "49747"},
	{latitude: 46.372259, longitude: -85.16574, zipCode: "49748"},
	{latitude: 45.422081, longitude: -84.584616, zipCode: "49749"},
	{latitude: 44.982838, longitude: -84.430167, zipCode: "49751"},
	{latitude: 46.263933, longitude: -84.417596, zipCode: "49752"},
	{latitude: 45.017332, longitude: -83.777063, zipCode: "49753"},
	{latitude: 45.643275, longitude: -84.827509, zipCode: "49755"},
	{latitude: 44.814691, longitude: -84.275815, zipCode: "49756"},
	{latitude: 45.860184, longitude: -84.625834, zipCode: "49757"},
	{latitude: 45.417476, longitude: -84.096086, zipCode: "49759"},
	{latitude: 46.047891, longitude: -84.847153, zipCode: "49760"},
	{latitude: 46.116086, longitude: -85.28474, zipCode: "49762"},
	{latitude: 45.423537, longitude: -84.82824, zipCode: "49764"},
	{latitude: 45.338306, longitude: -84.25917, zipCode: "49765"},
	{latitude: 44.908297, longitude: -83.432043, zipCode: "49766"},
	{latitude: 46.639554, longitude: -85.118081, zipCode: "49768"},
	{latitude: 45.570812, longitude: -84.861754, zipCode: "49769"},
	{latitude: 45.331202, longitude: -84.897145, zipCode: "49770"},
	{latitude: 46.165835, longitude: -84.298661, zipCode: "49774"},
	{latitude: 45.770763, longitude: -84.477959, zipCode: "49775"},
	{latitude: 45.232202, longitude: -83.680373, zipCode: "49776"},
	{latitude: 45.285818, longitude: -83.479655, zipCode: "49777"},
	{latitude: 45.394382, longitude: -83.84441, zipCode: "49779"},
	{latitude: 46.209089, longitude: -84.686118, zipCode: "49780"},
	{latitude: 45.948137, longitude: -84.765522, zipCode: "49781"},
	{latitude: 45.618554, longitude: -85.543684, zipCode: "49782"},
	{latitude: 46.416901, longitude: -84.289054, zipCode: "49783"},
	{latitude: 46.257681, longitude: -84.454857, zipCode: "49784"},
	{latitude: 46.264324, longitude: -84.469852, zipCode: "49788"},
	{latitude: 45.481006, longitude: -84.599373, zipCode: "49791"},
	{latitude: 46.214575, longitude: -85.047394, zipCode: "49793"},
	{latitude: 45.160679, longitude: -84.579688, zipCode: "49795"},
	{latitude: 45.272213, longitude: -84.948286, zipCode: "49796"},
	{latitude: 45.262433, longitude: -84.580375, zipCode: "49799"},
	{latitude: 45.927171, longitude: -88.015827, zipCode: "49801"},
	{latitude: 45.800591, longitude: -88.082227, zipCode: "49802"},
	{latitude: 47.360611, longitude: -88.402488, zipCode: "49805"},
	{latitude: 46.452414, longitude: -86.901161, zipCode: "49806"},
	{latitude: 45.699232, longitude: -87.293231, zipCode: "49807"},
	{latitude: 46.823114, longitude: -87.85267, zipCode: "49808"},
	{latitude: 45.591632, longitude: -87.50312, zipCode: "49812"},
	{latitude: 46.568034, longitude: -87.953816, zipCode: "49814"},
	{latitude: 46.188604, longitude: -87.946546, zipCode: "49815"},
	{latitude: 46.278851, longitude: -86.909361, zipCode: "49816"},
	{latitude: 45.949333, longitude: -86.504514, zipCode: "49817"},
	{latitude: 45.982363, longitude: -87.349348, zipCode: "49818"},
	{latitude: 46.19009, longitude: -85.654163, zipCode: "49820"},
	{latitude: 45.520277, longitude: -87.619709, zipCode: "49821"},
	{latitude: 46.433521, longitude: -87.024586, zipCode: "49822"},
	{latitude: 46.357811, longitude: -86.972916, zipCode: "49825"},
	{latitude: 46.323306, longitude: -87.052838, zipCode: "49826"},
	{latitude: 46.180664, longitude: -85.530449, zipCode: "49827"},
	{latitude: 45.774101, longitude: -87.13629, zipCode: "49829"},
	{latitude: 46.056687, longitude: -87.729011, zipCode: "49831"},
	{latitude: 46.268336, longitude: -87.294628, zipCode: "49833"},
	{latitude: 45.969351, longitude: -87.705351, zipCode: "49834"},
	{latitude: 45.735776, longitude: -86.562598, zipCode: "49835"},
	{latitude: 46.201987, longitude: -85.903336, zipCode: "49836"},
	{latitude: 45.868679, longitude: -87.07914, zipCode: "49837"},
	{latitude: 46.031891, longitude: -85.737395, zipCode: "49838"},
	{latitude: 46.59846, longitude: -86.146442, zipCode: "49839"},
	{latitude: 46.174564, longitude: -86.054633, zipCode: "49840"},
	{latitude: 46.26711, longitude: -87.499647, zipCode: "49841"},
	{latitude: 45.704676, longitude: -87.636201, zipCode: "49847"},
	{latitude: 45.379216, longitude: -87.635741, zipCode: "49848"},
	{latitude: 46.42568, longitude: -87.771426, zipCode: "49849"},
	{latitude: 45.786249, longitude: -87.816777, zipCode: "49852"},
	{latitude: 46.368293, longitude: -85.750902, zipCode: "49853"},
	{latitude: 46.076172, longitude: -86.345803, zipCode: "49854"},
	{latitude: 46.561069, longitude: -87.451418, zipCode: "49855"},
	{latitude: 45.222109, longitude: -87.548174, zipCode: "49858"},
	{latitude: 46.568669, longitude: -88.1754, zipCode: "49861"},
	{latitude: 46.398696, longitude: -86.727185, zipCode: "49862"},
	{latitude: 45.61294, longitude: -87.551681, zipCode: "49863"},
	{latitude: 45.841522, longitude: -86.650397, zipCode: "49864"},
	{latitude: 46.415937, longitude: -87.584762, zipCode: "49866"},
	{latitude: 46.510988, longitude: -85.517552, zipCode: "49868"},
	{latitude: 45.867201, longitude: -87.878923, zipCode: "49870"},
	{latitude: 46.424714, longitude: -87.538923, zipCode: "49871"},
	{latitude: 46.018219, longitude: -87.082202, zipCode: "49872"},
	{latitude: 45.826699, longitude: -87.486662, zipCode: "49873"},
	{latitude: 45.753538, longitude: -87.468889, zipCode: "49874"},
	{latitude: 45.802164, longitude: -87.977383, zipCode: "49876"},
	{latitude: 46.158926, longitude: -87.712237, zipCode: "49877"},
	{latitude: 45.946742, longitude: -86.87753, zipCode: "49878"},
	{latitude: 46.340347, longitude: -88.064585, zipCode: "49879"},
	{latitude: 46.110993, longitude: -87.222358, zipCode: "49880"},
	{latitude: 46.07322, longitude: -88.047262, zipCode: "49881"},
	{latitude: 46.410608, longitude: -86.007868, zipCode: "49883"},
	{latitude: 46.41592, longitude: -86.356975, zipCode: "49884"},
	{latitude: 46.33805, longitude: -87.19832, zipCode: "49885"},
	{latitude: 45.6975, longitude: -87.494034, zipCode: "49886"},
	{latitude: 45.392007, longitude: -87.731587, zipCode: "49887"},
	{latitude: 46.217271, longitude: -87.015008, zipCode: "49891"},
	{latitude: 45.776825, longitude: -87.792363, zipCode: "49892"},
	{latitude: 45.297038, longitude: -87.605155, zipCode: "49893"},
	{latitude: 45.782901, longitude: -87.076346, zipCode: "49894"},
	{latitude: 46.171321, longitude: -86.661791, zipCode: "49895"},
	{latitude: 45.688568, longitude: -87.388865, zipCode: "49896"},
	{latitude: 47.292699, longitude: -88.392601, zipCode: "49901"},
	{latitude: 46.051168, longitude: -88.376162, zipCode: "49902"},
	{latitude: 46.337193, longitude: -88.482608, zipCode: "49903"},
	{latitude: 47.110078, longitude: -88.707654, zipCode: "49905"},
	{latitude: 46.758152, longitude: -88.563278, zipCode: "49908"},
	{latitude: 46.629711, longitude: -89.531354, zipCode: "49910"},
	{latitude: 46.498953, longitude: -90.057516, zipCode: "49911"},
	{latitude: 46.496716, longitude: -89.194224, zipCode: "49912"},
	{latitude: 47.238859, longitude: -88.451579, zipCode: "49913"},
	{latitude: 46.064638, longitude: -88.627095, zipCode: "49915"},
	{latitude: 46.984094, longitude: -88.586325, zipCode: "49916"},
	{latitude: 47.281359, longitude: -88.359132, zipCode: "49917"},
	{latitude: 47.439583, longitude: -87.829749, zipCode: "49918"},
	{latitude: 46.514745, longitude: -88.434927, zipCode: "49919"},
	{latitude: 46.138161, longitude: -88.30225, zipCode: "49920"},
	{latitude: 47.091218, longitude: -88.57971, zipCode: "49921"},
	{latitude: 47.124404, longitude: -88.489531, zipCode: "49922"},
	{latitude: 46.544419, longitude: -89.374684, zipCode: "49925"},
	{latitude: 46.03306, longitude: -88.547007, zipCode: "49927"},
	{latitude: 46.798725, longitude: -89.09564, zipCode: "49929"},
	{latitude: 47.202985, longitude: -88.576295, zipCode: "49930"},
	{latitude: 47.184793, longitude: -88.640831, zipCode: "49931"},
	{latitude: 47.165644, longitude: -88.439092, zipCode: "49934"},
	{latitude: 46.175587, longitude: -88.752156, zipCode: "49935"},
	{latitude: 46.525851, longitude: -90.130358, zipCode: "49938"},
	{latitude: 47.260732, longitude: -88.41655, zipCode: "49942"},
	{latitude: 47.148165, longitude: -88.337092, zipCode: "49945"},
	{latitude: 46.727817, longitude: -88.332527, zipCode: "49946"},
	{latitude: 46.412734, longitude: -89.606815, zipCode: "49947"},
	{latitude: 46.752182, longitude: -88.998266, zipCode: "49948"},
	{latitude: 47.386318, longitude: -88.133741, zipCode: "49950"},
	{latitude: 46.701362, longitude: -88.784945, zipCode: "49952"},
	{latitude: 46.785658, longitude: -89.42686, zipCode: "49953"},
	{latitude: 47.006294, longitude: -88.694822, zipCode: "49955"},
	{latitude: 46.815468, longitude: -88.662927, zipCode: "49958"},
	{latitude: 46.469886, longitude: -89.998077, zipCode: "49959"},
	{latitude: 46.711853, longitude: -89.258371, zipCode: "49960"},
	{latitude: 46.449553, longitude: -88.702147, zipCode: "49961"},
	{latitude: 46.839281, longitude: -88.131113, zipCode: "49962"},
	{latitude: 47.048942, longitude: -88.639995, zipCode: "49963"},
	{latitude: 46.955096, longitude: -88.859543, zipCode: "49965"},
	{latitude: 46.506508, longitude: -88.966362, zipCode: "49967"},
	{latitude: 46.502982, longitude: -89.889607, zipCode: "49968"},
	{latitude: 46.251655, longitude: -89.210723, zipCode: "49969"},
	{latitude: 46.522947, longitude: -88.600492, zipCode: "49970"},
	{latitude: 46.710437, longitude: -89.628683, zipCode: "49971"},
	{latitude: 41.353473, longitude: -93.438843, zipCode: "50001"},
	{latitude: 41.514826, longitude: -94.648337, zipCode: "50002"},
	{latitude: 41.611473, longitude: -94.043043, zipCode: "50003"},
	{latitude: 42.131988, longitude: -93.034602, zipCode: "50005"},
	{latitude: 42.508397, longitude: -93.401965, zipCode: "50006"},
	{latitude: 41.803653, longitude: -93.600427, zipCode: "50007"},
	{latitude: 40.672937, longitude: -93.386227, zipCode: "50008"},
	{latitude: 41.643423, longitude: -93.459703, zipCode: "50009"},
	{latitude: 42.030826, longitude: -93.5883, zipCode: "50010"},
	{latitude: 42.029706, longitude: -93.650496, zipCode: "50012"},
	{latitude: 42.014905, longitude: -93.657814, zipCode: "50013"},
	{latitude: 42.046724, longitude: -93.69462, zipCode: "50014"},
	{latitude: 41.426792, longitude: -94.749885, zipCode: "50020"},
	{latitude: 41.724292, longitude: -93.565308, zipCode: "50021"},
	{latitude: 41.416276, longitude: -95.006341, zipCode: "50022"},
	{latitude: 41.726385, longitude: -93.630506, zipCode: "50023"},
	{latitude: 41.756245, longitude: -94.920368, zipCode: "50025"},
	{latitude: 41.858226, longitude: -94.449565, zipCode: "50026"},
	{latitude: 41.482051, longitude: -92.469368, zipCode: "50027"},
	{latitude: 41.80691, longitude: -93.154284, zipCode: "50028"},
	{latitude: 41.828956, longitude: -94.555933, zipCode: "50029"},
	{latitude: 42.038807, longitude: -94.140029, zipCode: "50031"},
	{latitude: 41.665911, longitude: -93.542852, zipCode: "50032"},
	{latitude: 41.360409, longitude: -93.794244, zipCode: "50033"},
	{latitude: 42.543259, longitude: -93.646783, zipCode: "50034"},
	{latitude: 41.723893, longitude: -93.45792, zipCode: "50035"},
	{latitude: 42.085446, longitude: -93.849635, zipCode: "50036"},
	{latitude: 41.52484, longitude: -93.90306, zipCode: "50038"},
	{latitude: 41.829925, longitude: -94.014887, zipCode: "50039"},
	{latitude: 42.17363, longitude: -94.101233, zipCode: "50040"},
	{latitude: 42.634332, longitude: -93.246598, zipCode: "50041"},
	{latitude: 41.533745, longitude: -94.905323, zipCode: "50042"},
	{latitude: 41.203526, longitude: -92.905256, zipCode: "50044"},
	{latitude: 41.88822, longitude: -93.518498, zipCode: "50046"},
	{latitude: 41.475055, longitude: -93.467269, zipCode: "50047"},
	{latitude: 41.512457, longitude: -94.520058, zipCode: "50048"},
	{latitude: 41.034435, longitude: -93.295422, zipCode: "50049"},
	{latitude: 42.158973, longitude: -94.503593, zipCode: "50050"},
	{latitude: 42.135392, longitude: -93.141399, zipCode: "50051"},
	{latitude: 40.643287, longitude: -93.44438, zipCode: "50052"},
	{latitude: 41.688289, longitude: -93.231789, zipCode: "50054"},
	{latitude: 41.884636, longitude: -93.2801, zipCode: "50055"},
	{latitude: 42.020712, longitude: -93.296127, zipCode: "50056"},
	{latitude: 41.179914, longitude: -93.134256, zipCode: "50057"},
	{latitude: 41.855648, longitude: -94.691064, zipCode: "50058"},
	{latitude: 40.755224, longitude: -93.319639, zipCode: "50060"},
	{latitude: 41.475941, longitude: -93.79773, zipCode: "50061"},
	{latitude: 41.235464, longitude: -93.244965, zipCode: "50062"},
	{latitude: 41.695579, longitude: -93.940638, zipCode: "50063"},
	{latitude: 42.103878, longitude: -94.223102, zipCode: "50064"},
	{latitude: 40.611611, longitude: -93.774335, zipCode: "50065"},
	{latitude: 41.798081, longitude: -94.233396, zipCode: "50066"},
	{latitude: 40.692682, longitude: -93.882672, zipCode: "50067"},
	{latitude: 40.947993, longitude: -93.466251, zipCode: "50068"},
	{latitude: 41.521057, longitude: -94.02988, zipCode: "50069"},
	{latitude: 41.445537, longitude: -94.232632, zipCode: "50070"},
	{latitude: 42.64863, longitude: -93.504909, zipCode: "50071"},
	{latitude: 41.471219, longitude: -94.122983, zipCode: "50072"},
	{latitude: 41.792132, longitude: -93.523971, zipCode: "50073"},
	{latitude: 40.865931, longitude: -94.080133, zipCode: "50074"},
	{latitude: 42.325164, longitude: -93.564882, zipCode: "50075"},
	{latitude: 41.592177, longitude: -94.841945, zipCode: "50076"},
	{latitude: 41.938595, longitude: -92.862908, zipCode: "50078"},
	{latitude: 42.667138, longitude: -93.634216, zipCode: "50101"},
	{latitude: 42.245542, longitude: -93.400194, zipCode: "50102"},
	{latitude: 40.79512, longitude: -93.609144, zipCode: "50103"},
	{latitude: 41.494607, longitude: -92.388406, zipCode: "50104"},
	{latitude: 42.117512, longitude: -93.639111, zipCode: "50105"},
	{latitude: 41.881076, longitude: -92.805779, zipCode: "50106"},
	{latitude: 42.036497, longitude: -94.219014, zipCode: "50107"},
	{latitude: 40.824741, longitude: -93.965521, zipCode: "50108"},
	{latitude: 41.77088, longitude: -93.800631, zipCode: "50109"},
	{latitude: 41.844205, longitude: -94.986027, zipCode: "50110"},
	{latitude: 41.691918, longitude: -93.80184, zipCode: "50111"},
	{latitude: 41.7334, longitude: -92.712678, zipCode: "50112"},
	{latitude: 41.68324, longitude: -94.561556, zipCode: "50115"},
	{latitude: 41.178106, longitude: -92.982479, zipCode: "50116"},
	{latitude: 41.679966, longitude: -94.790568, zipCode: "50117"},
	{latitude: 41.459792, longitude: -93.378688, zipCode: "50118"},
	{latitude: 41.313063, longitude: -92.939705, zipCode: "50119"},
	{latitude: 41.933763, longitude: -92.982814, zipCode: "50120"},
	{latitude: 42.298821, longitude: -93.307758, zipCode: "50122"},
	{latitude: 40.833825, longitude: -93.513815, zipCode: "50123"},
	{latitude: 41.885132, longitude: -93.604447, zipCode: "50124"},
	{latitude: 41.335185, longitude: -93.584634, zipCode: "50125"},
	{latitude: 42.510523, longitude: -93.255315, zipCode: "50126"},
	{latitude: 41.781222, longitude: -93.211283, zipCode: "50127"},
	{latitude: 41.855589, longitude: -94.300236, zipCode: "50128"},
	{latitude: 42.013286, longitude: -94.376733, zipCode: "50129"},
	{latitude: 42.303386, longitude: -93.669228, zipCode: "50130"},
	{latitude: 41.696757, longitude: -93.720808, zipCode: "50131"},
	{latitude: 42.393932, longitude: -93.682569, zipCode: "50132"},
	{latitude: 40.718438, longitude: -94.081953, zipCode: "50133"},
	{latitude: 41.939909, longitude: -93.674312, zipCode: "50134"},
	{latitude: 41.745394, longitude: -92.896044, zipCode: "50135"},
	{latitude: 41.469501, longitude: -92.292764, zipCode: "50136"},
	{latitude: 41.609715, longitude: -92.921578, zipCode: "50137"},
	{latitude: 41.302889, longitude: -93.107738, zipCode: "50138"},
	{latitude: 41.194195, longitude: -93.358181, zipCode: "50139"},
	{latitude: 40.634105, longitude: -93.993343, zipCode: "50140"},
	{latitude: 41.867559, longitude: -92.949711, zipCode: "50141"},
	{latitude: 42.005555, longitude: -92.77582, zipCode: "50142"},
	{latitude: 41.341836, longitude: -92.804656, zipCode: "50143"},
	{latitude: 40.733083, longitude: -93.737338, zipCode: "50144"},
	{latitude: 41.203854, longitude: -93.485143, zipCode: "50145"},
	{latitude: 41.680415, longitude: -94.249054, zipCode: "50146"},
	{latitude: 40.617696, longitude: -93.54772, zipCode: "50147"},
	{latitude: 42.181458, longitude: -92.995665, zipCode: "50148"},
	{latitude: 41.149663, longitude: -94.095295, zipCode: "50149"},
	{latitude: 41.125508, longitude: -92.975192, zipCode: "50150"},
	{latitude: 41.078785, longitude: -93.488791, zipCode: "50151"},
	{latitude: 41.968734, longitude: -93.817643, zipCode: "50152"},
	{latitude: 41.573898, longitude: -92.80701, zipCode: "50153"},
	{latitude: 42.176263, longitude: -93.390727, zipCode: "50154"},
	{latitude: 41.211018, longitude: -94.199353, zipCode: "50155"},
	{latitude: 41.89831, longitude: -93.801235, zipCode: "50156"},
	{latitude: 41.740075, longitude: -92.563309, zipCode: "50157"},
	{latitude: 42.049806, longitude: -92.900739, zipCode: "50158"},
	{latitude: 41.373858, longitude: -93.738701, zipCode: "50160"},
	{latitude: 41.842298, longitude: -93.396907, zipCode: "50161"},
	{latitude: 41.928305, longitude: -93.062875, zipCode: "50162"},
	{latitude: 41.222284, longitude: -93.241041, zipCode: "50163"},
	{latitude: 41.499934, longitude: -94.433324, zipCode: "50164"},
	{latitude: 40.850789, longitude: -93.308499, zipCode: "50165"},
	{latitude: 41.282835, longitude: -93.431036, zipCode: "50166"},
	{latitude: 41.745257, longitude: -94.049388, zipCode: "50167"},
	{latitude: 41.788361, longitude: -93.278546, zipCode: "50168"},
	{latitude: 41.655261, longitude: -93.352352, zipCode: "50169"},
	{latitude: 41.529996, longitude: -93.116478, zipCode: "50170"},
	{latitude: 41.583936, longitude: -92.525423, zipCode: "50171"},
	{latitude: 41.964658, longitude: -92.713479, zipCode: "50173"},
	{latitude: 41.033707, longitude: -93.982304, zipCode: "50174"},
	{latitude: 42.033498, longitude: -93.444377, zipCode: "50201"},
	{latitude: 42.245665, longitude: -93.202336, zipCode: "50206"},
	{latitude: 41.431966, longitude: -92.63754, zipCode: "50207"},
	{latitude: 41.710803, longitude: -93.039775, zipCode: "50208"},
	{latitude: 41.195043, longitude: -93.698287, zipCode: "50210"},
	{latitude: 41.45531, longitude: -93.705269, zipCode: "50211"},
	{latitude: 42.036562, longitude: -94.066162, zipCode: "50212"},
	{latitude: 41.033119, longitude: -93.764729, zipCode: "50213"},
	{latitude: 41.469319, longitude: -93.055697, zipCode: "50214"},
	{latitude: 41.697256, longitude: -94.354853, zipCode: "50216"},
	{latitude: 42.183786, longitude: -94.251812, zipCode: "50217"},
	{latitude: 41.348429, longitude: -93.877528, zipCode: "50218"},
	{latitude: 41.420701, longitude: -92.896694, zipCode: "50219"},
	{latitude: 41.836423, longitude: -94.121285, zipCode: "50220"},
	{latitude: 41.215853, longitude: -93.959592, zipCode: "50222"},
	{latitude: 42.173721, longitude: -94.037815, zipCode: "50223"},
	{latitude: 41.381174, longitude: -93.274594, zipCode: "50225"},
	{latitude: 41.796243, longitude: -93.701973, zipCode: "50226"},
	{latitude: 42.593886, longitude: -93.428181, zipCode: "50227"},
	{latitude: 41.568861, longitude: -93.245318, zipCode: "50228"},
	{latitude: 41.379081, longitude: -93.775073, zipCode: "50229"},
	{latitude: 42.303248, longitude: -93.453417, zipCode: "50230"},
	{latitude: 42.237649, longitude: -93.602568, zipCode: "50231"},
	{latitude: 41.556725, longitude: -92.96972, zipCode: "50232"},
	{latitude: 41.612204, longitude: -94.210956, zipCode: "50233"},
	{latitude: 41.887962, longitude: -93.181347, zipCode: "50234"},
	{latitude: 41.931785, longitude: -94.224912, zipCode: "50235"},
	{latitude: 42.178399, longitude: -93.49039, zipCode: "50236"},
	{latitude: 41.545198, longitude: -93.370962, zipCode: "50237"},
	{latitude: 40.963784, longitude: -93.182607, zipCode: "50238"},
	{latitude: 42.142774, longitude: -93.202933, zipCode: "50239"},
	{latitude: 41.29709, longitude: -93.793028, zipCode: "50240"},
	{latitude: 41.307698, longitude: -93.733845, zipCode: "50241"},
	{latitude: 41.561039, longitude: -92.693756, zipCode: "50242"},
	{latitude: 41.864564, longitude: -93.696739, zipCode: "50243"},
	{latitude: 41.850801, longitude: -93.651217, zipCode: "50244"},
	{latitude: 42.288957, longitude: -93.781362, zipCode: "50246"},
	{latitude: 42.01809, longitude: -93.166386, zipCode: "50247"},
	{latitude: 42.191221, longitude: -93.614392, zipCode: "50248"},
	{latitude: 42.275278, longitude: -93.903159, zipCode: "50249"},
	{latitude: 41.482226, longitude: -94.347153, zipCode: "50250"},
	{latitude: 41.56694, longitude: -92.869363, zipCode: "50251"},
	{latitude: 41.456512, longitude: -93.319813, zipCode: "50252"},
	{latitude: 40.991218, longitude: -94.059403, zipCode: "50254"},
	{latitude: 41.455931, longitude: -92.332086, zipCode: "50255"},
	{latitude: 41.259706, longitude: -92.905272, zipCode: "50256"},
	{latitude: 41.183465, longitude: -93.862605, zipCode: "50257"},
	{latitude: 42.224136, longitude: -93.073131, zipCode: "50258"},
	{latitude: 41.474943, longitude: -93.921017, zipCode: "50261"},
	{latitude: 40.862401, longitude: -93.819055, zipCode: "50262"},
	{latitude: 41.604176, longitude: -93.879689, zipCode: "50263"},
	{latitude: 40.892216, longitude: -93.709914, zipCode: "50264"},
	{latitude: 41.542743, longitude: -93.751089, zipCode: "50265"},
	{latitude: 41.562678, longitude: -93.814626, zipCode: "50266"},
	{latitude: 41.406996, longitude: -92.361709, zipCode: "50268"},
	{latitude: 42.264454, longitude: -93.010632, zipCode: "50269"},
	{latitude: 42.484402, longitude: -93.544889, zipCode: "50271"},
	{latitude: 41.088096, longitude: -93.256842, zipCode: "50272"},
	{latitude: 41.326464, longitude: -94.049041, zipCode: "50273"},
	{latitude: 41.383348, longitude: -94.860975, zipCode: "50274"},
	{latitude: 41.043716, longitude: -93.588523, zipCode: "50275"},
	{latitude: 41.86006, longitude: -93.922601, zipCode: "50276"},
	{latitude: 41.779659, longitude: -94.341008, zipCode: "50277"},
	{latitude: 42.155575, longitude: -93.30116, zipCode: "50278"},
	{latitude: 41.584192, longitude: -93.621998, zipCode: "50309"},
	{latitude: 41.627582, longitude: -93.67266, zipCode: "50310"},
	{latitude: 41.60098, longitude: -93.673379, zipCode: "50311"},
	{latitude: 41.576832, longitude: -93.682094, zipCode: "50312"},
	{latitude: 41.656895, longitude: -93.62454, zipCode: "50313"},
	{latitude: 41.604964, longitude: -93.6312, zipCode: "50314"},
	{latitude: 41.545367, longitude: -93.619184, zipCode: "50315"},
	{latitude: 41.608629, longitude: -93.599579, zipCode: "50316"},
	{latitude: 41.618131, longitude: -93.549374, zipCode: "50317"},
	{latitude: 41.591141, longitude: -93.602996, zipCode: "50319"},
	{latitude: 41.531043, longitude: -93.572356, zipCode: "50320"},
	{latitude: 41.534555, longitude: -93.662444, zipCode: "50321"},
	{latitude: 41.634164, longitude: -93.73436, zipCode: "50322"},
	{latitude: 41.638924, longitude: -93.818482, zipCode: "50323"},
	{latitude: 41.604709, longitude: -93.712724, zipCode: "50324"},
	{latitude: 41.613586, longitude: -93.799124, zipCode: "50325"},
	{latitude: 41.586369, longitude: -93.487707, zipCode: "50327"},
	{latitude: 43.154082, longitude: -93.199836, zipCode: "50401"},
	{latitude: 42.806197, longitude: -93.45679, zipCode: "50420"},
	{latitude: 42.842924, longitude: -93.623061, zipCode: "50421"},
	{latitude: 43.118278, longitude: -93.814497, zipCode: "50423"},
	{latitude: 43.403289, longitude: -93.941777, zipCode: "50424"},
	{latitude: 43.415119, longitude: -93.017244, zipCode: "50426"},
	{latitude: 42.835528, longitude: -93.221176, zipCode: "50427"},
	{latitude: 43.14002, longitude: -93.390941, zipCode: "50428"},
	{latitude: 42.977287, longitude: -93.956552, zipCode: "50430"},
	{latitude: 42.736478, longitude: -93.369881, zipCode: "50431"},
	{latitude: 43.223981, longitude: -93.797899, zipCode: "50432"},
	{latitude: 42.928492, longitude: -93.047736, zipCode: "50433"},
	{latitude: 43.257488, longitude: -93.448583, zipCode: "50434"},
	{latitude: 43.157531, longitude: -92.76881, zipCode: "50435"},
	{latitude: 43.259173, longitude: -93.685778, zipCode: "50436"},
	{latitude: 43.111533, longitude: -93.618639, zipCode: "50438"},
	{latitude: 42.944717, longitude: -93.626339, zipCode: "50439"},
	{latitude: 43.332205, longitude: -93.068989, zipCode: "50440"},
	{latitude: 42.739747, longitude: -93.220628, zipCode: "50441"},
	{latitude: 43.297226, longitude: -93.38363, zipCode: "50444"},
	{latitude: 43.333798, longitude: -93.449821, zipCode: "50446"},
	{latitude: 42.931173, longitude: -93.798345, zipCode: "50447"},
	{latitude: 43.349938, longitude: -93.240534, zipCode: "50448"},
	{latitude: 43.002467, longitude: -93.547142, zipCode: "50449"},
	{latitude: 43.421201, longitude: -93.522214, zipCode: "50450"},
	{latitude: 43.377524, longitude: -94.091461, zipCode: "50451"},
	{latitude: 42.781073, longitude: -93.367342, zipCode: "50452"},
	{latitude: 43.363364, longitude: -93.64742, zipCode: "50453"},
	{latitude: 43.385059, longitude: -92.739334, zipCode: "50454"},
	{latitude: 43.456953, longitude: -92.649842, zipCode: "50455"},
	{latitude: 43.291262, longitude: -93.216049, zipCode: "50456"},
	{latitude: 42.914178, longitude: -93.492797, zipCode: "50457"},
	{latitude: 43.15877, longitude: -93.012028, zipCode: "50458"},
	{latitude: 43.457469, longitude: -93.250502, zipCode: "50459"},
	{latitude: 43.22594, longitude: -92.690218, zipCode: "50460"},
	{latitude: 43.306168, longitude: -92.818741, zipCode: "50461"},
	{latitude: 43.251357, longitude: -93.076507, zipCode: "50464"},
	{latitude: 43.487021, longitude: -93.910562, zipCode: "50465"},
	{latitude: 43.386853, longitude: -92.538439, zipCode: "50466"},
	{latitude: 43.209092, longitude: -93.066238, zipCode: "50467"},
	{latitude: 43.032234, longitude: -92.947134, zipCode: "50468"},
	{latitude: 42.999343, longitude: -93.197104, zipCode: "50469"},
	{latitude: 42.746528, longitude: -93.553756, zipCode: "50470"},
	{latitude: 43.164821, longitude: -92.878354, zipCode: "50471"},
	{latitude: 43.406746, longitude: -92.935316, zipCode: "50472"},
	{latitude: 43.465984, longitude: -93.666682, zipCode: "50473"},
	{latitude: 42.882208, longitude: -93.214017, zipCode: "50475"},
	{latitude: 43.446804, longitude: -92.764052, zipCode: "50476"},
	{latitude: 42.987396, longitude: -93.327325, zipCode: "50477"},
	{latitude: 43.407488, longitude: -93.783601, zipCode: "50478"},
	{latitude: 42.941186, longitude: -93.408191, zipCode: "50479"},
	{latitude: 43.250367, longitude: -94.058607, zipCode: "50480"},
	{latitude: 43.124799, longitude: -93.48465, zipCode: "50482"},
	{latitude: 43.111708, longitude: -93.997206, zipCode: "50483"},
	{latitude: 43.23826, longitude: -93.906492, zipCode: "50484"},
	{latitude: 42.49164, longitude: -94.187122, zipCode: "50501"},
	{latitude: 42.759296, longitude: -94.985603, zipCode: "50510"},
	{latitude: 43.07508, longitude: -94.219197, zipCode: "50511"},
	{latitude: 43.415156, longitude: -94.47241, zipCode: "50514"},
	{latitude: 43.017181, longitude: -94.886111, zipCode: "50515"},
	{latitude: 42.623973, longitude: -94.136853, zipCode: "50516"},
	{latitude: 43.305294, longitude: -94.234287, zipCode: "50517"},
	{latitude: 42.512127, longitude: -94.382397, zipCode: "50518"},
	{latitude: 42.903123, longitude: -94.265426, zipCode: "50519"},
	{latitude: 42.80919, longitude: -94.397929, zipCode: "50520"},
	{latitude: 42.346223, longitude: -94.102821, zipCode: "50521"},
	{latitude: 43.185624, longitude: -94.185759, zipCode: "50522"},
	{latitude: 42.367792, longitude: -94.293563, zipCode: "50523"},
	{latitude: 42.594247, longitude: -94.358612, zipCode: "50524"},
	{latitude: 42.736483, longitude: -93.747336, zipCode: "50525"},
	{latitude: 42.969211, longitude: -94.792264, zipCode: "50527"},
	{latitude: 43.137123, longitude: -94.540229, zipCode: "50528"},
	{latitude: 42.722185, longitude: -94.200983, zipCode: "50529"},
	{latitude: 42.270278, longitude: -94.039589, zipCode: "50530"},
	{latitude: 43.454085, longitude: -94.609753, zipCode: "50531"},
	{latitude: 42.460334, longitude: -94.000746, zipCode: "50532"},
	{latitude: 42.652495, longitude: -93.911097, zipCode: "50533"},
	{latitude: 42.452741, longitude: -95.164045, zipCode: "50535"},
	{latitude: 43.117413, longitude: -94.695427, zipCode: "50536"},
	{latitude: 42.283905, longitude: -94.431873, zipCode: "50538"},
	{latitude: 43.235365, longitude: -94.418488, zipCode: "50539"},
	{latitude: 42.59835, longitude: -94.839981, zipCode: "50540"},
	{latitude: 42.710228, longitude: -94.452387, zipCode: "50541"},
	{latitude: 42.782976, longitude: -93.942692, zipCode: "50542"},
	{latitude: 42.274325, longitude: -94.306668, zipCode: "50543"},
	{latitude: 42.253675, longitude: -94.170419, zipCode: "50544"},
	{latitude: 42.797396, longitude: -94.080863, zipCode: "50545"},
	{latitude: 42.843735, longitude: -94.708078, zipCode: "50546"},
	{latitude: 42.71368, longitude: -94.222776, zipCode: "50548"},
	{latitude: 42.482417, longitude: -94.754854, zipCode: "50551"},
	{latitude: 42.456379, longitude: -94.460691, zipCode: "50552"},
	{latitude: 42.845822, longitude: -94.83994, zipCode: "50554"},
	{latitude: 43.45522, longitude: -94.196646, zipCode: "50556"},
	{latitude: 42.360729, longitude: -94.043692, zipCode: "50557"},
	{latitude: 42.861277, longitude: -94.16377, zipCode: "50558"},
	{latitude: 43.209075, longitude: -94.324743, zipCode: "50559"},
	{latitude: 42.955794, longitude: -94.104296, zipCode: "50560"},
	{latitude: 42.417707, longitude: -94.850759, zipCode: "50561"},
	{latitude: 42.961574, longitude: -94.64173, zipCode: "50562"},
	{latitude: 42.527824, longitude: -94.525043, zipCode: "50563"},
	{latitude: 42.857398, longitude: -95.000842, zipCode: "50565"},
	{latitude: 42.43777, longitude: -94.323652, zipCode: "50566"},
	{latitude: 42.523753, longitude: -95.08084, zipCode: "50567"},
	{latitude: 42.632967, longitude: -95.003877, zipCode: "50568"},
	{latitude: 42.403518, longitude: -94.13447, zipCode: "50569"},
	{latitude: 42.895475, longitude: -94.367016, zipCode: "50570"},
	{latitude: 42.634857, longitude: -94.580585, zipCode: "50571"},
	{latitude: 42.876796, longitude: -94.622557, zipCode: "50573"},
	{latitude: 42.718938, longitude: -94.699661, zipCode: "50574"},
	{latitude: 42.558799, longitude: -94.70064, zipCode: "50575"},
	{latitude: 42.802696, longitude: -95.17725, zipCode: "50576"},
	{latitude: 42.857719, longitude: -93.979052, zipCode: "50577"},
	{latitude: 43.306502, longitude: -94.549559, zipCode: "50578"},
	{latitude: 42.393763, longitude: -94.634162, zipCode: "50579"},
	{latitude: 42.842996, longitude: -94.549778, zipCode: "50581"},
	{latitude: 42.792689, longitude: -94.294103, zipCode: "50582"},
	{latitude: 42.432773, longitude: -94.985626, zipCode: "50583"},
	{latitude: 42.91753, longitude: -95.141865, zipCode: "50585"},
	{latitude: 42.41186, longitude: -94.425556, zipCode: "50586"},
	{latitude: 42.654596, longitude: -95.161555, zipCode: "50588"},
	{latitude: 43.406735, longitude: -94.304136, zipCode: "50590"},
	{latitude: 42.676819, longitude: -94.054783, zipCode: "50591"},
	{latitude: 42.729257, longitude: -95.183407, zipCode: "50592"},
	{latitude: 42.657988, longitude: -94.898247, zipCode: "50593"},
	{latitude: 42.585595, longitude: -94.039503, zipCode: "50594"},
	{latitude: 42.461417, longitude: -93.822578, zipCode: "50595"},
	{latitude: 42.975304, longitude: -94.451718, zipCode: "50597"},
	{latitude: 43.080212, longitude: -94.418384, zipCode: "50598"},
	{latitude: 42.579322, longitude: -93.823217, zipCode: "50599"},
	{latitude: 42.567777, longitude: -93.060052, zipCode: "50601"},
	{latitude: 42.741575, longitude: -92.805699, zipCode: "50602"},
	{latitude: 43.188939, longitude: -92.458215, zipCode: "50603"},
	{latitude: 42.601831, longitude: -92.896477, zipCode: "50604"},
	{latitude: 42.835586, longitude: -93.032796, zipCode: "50605"},
	{latitude: 42.747329, longitude: -91.691214, zipCode: "50606"},
	{latitude: 42.602699, longitude: -91.749049, zipCode: "50607"},
	{latitude: 42.588138, longitude: -92.960793, zipCode: "50608"},
	{latitude: 42.228377, longitude: -92.809466, zipCode: "50609"},
	{latitude: 42.819267, longitude: -92.911112, zipCode: "50611"},
	{latitude: 42.285484, longitude: -92.386851, zipCode: "50612"},
	{latitude: 42.531411, longitude: -92.485861, zipCode: "50613"},
	{latitude: 43.080501, longitude: -92.658875, zipCode: "50616"},
	{latitude: 42.79933, longitude: -92.66324, zipCode: "50619"},
	{latitude: 43.157125, longitude: -92.591191, zipCode: "50620"},
	{latitude: 42.247399, longitude: -92.91904, zipCode: "50621"},
	{latitude: 42.673622, longitude: -92.333317, zipCode: "50622"},
	{latitude: 42.526923, longitude: -92.209673, zipCode: "50623"},
	{latitude: 42.468747, longitude: -92.658814, zipCode: "50624"},
	{latitude: 42.748399, longitude: -92.988397, zipCode: "50625"},
	{latitude: 42.584123, longitude: -92.17147, zipCode: "50626"},
	{latitude: 42.35043, longitude: -93.093942, zipCode: "50627"},
	{latitude: 43.27271, longitude: -92.394046, zipCode: "50628"},
	{latitude: 42.648669, longitude: -92.071537, zipCode: "50629"},
	{latitude: 42.95768, longitude: -92.213959, zipCode: "50630"},
	{latitude: 42.885148, longitude: -92.309518, zipCode: "50631"},
	{latitude: 42.075097, longitude: -92.696061, zipCode: "50632"},
	{latitude: 42.675296, longitude: -93.126684, zipCode: "50633"},
	{latitude: 42.418187, longitude: -92.214258, zipCode: "50634"},
	{latitude: 42.202425, longitude: -92.696618, zipCode: "50635"},
	{latitude: 42.893601, longitude: -92.808582, zipCode: "50636"},
	{latitude: 42.356537, longitude: -92.794961, zipCode: "50638"},
	{latitude: 42.591881, longitude: -91.919414, zipCode: "50641"},
	{latitude: 42.439542, longitude: -92.816432, zipCode: "50642"},
	{latitude: 42.357981, longitude: -92.464157, zipCode: "50643"},
	{latitude: 42.477485, longitude: -91.89775, zipCode: "50644"},
	{latitude: 43.019599, longitude: -92.443698, zipCode: "50645"},
	{latitude: 42.650931, longitude: -92.49703, zipCode: "50647"},
	{latitude: 42.453115, longitude: -92.09323, zipCode: "50648"},
	{latitude: 42.662488, longitude: -92.909726, zipCode: "50649"},
	{latitude: 42.618248, longitude: -91.65604, zipCode: "50650"},
	{latitude: 42.324572, longitude: -92.201653, zipCode: "50651"},
	{latitude: 42.264539, longitude: -92.692336, zipCode: "50652"},
	{latitude: 42.968576, longitude: -92.885635, zipCode: "50653"},
	{latitude: 42.407392, longitude: -91.574014, zipCode: "50654"},
	{latitude: 42.76561, longitude: -91.891729, zipCode: "50655"},
	{latitude: 42.344546, longitude: -92.674044, zipCode: "50657"},
	{latitude: 42.950432, longitude: -92.538095, zipCode: "50658"},
	{latitude: 43.095838, longitude: -92.329051, zipCode: "50659"},
	{latitude: 42.577494, longitude: -92.641802, zipCode: "50660"},
	{latitude: 42.688662, longitude: -91.937486, zipCode: "50662"},
	{latitude: 42.706961, longitude: -92.072256, zipCode: "50664"},
	{latitude: 42.580753, longitude: -92.771682, zipCode: "50665"},
	{latitude: 42.85442, longitude: -92.503231, zipCode: "50666"},
	{latitude: 42.470015, longitude: -92.225558, zipCode: "50667"},
	{latitude: 42.692925, longitude: -92.228888, zipCode: "50668"},
	{latitude: 42.339158, longitude: -92.614997, zipCode: "50669"},
	{latitude: 42.677637, longitude: -92.615911, zipCode: "50670"},
	{latitude: 42.650429, longitude: -91.795819, zipCode: "50671"},
	{latitude: 42.42604, longitude: -93.071556, zipCode: "50672"},
	{latitude: 42.52505, longitude: -92.711288, zipCode: "50673"},
	{latitude: 42.838035, longitude: -92.115326, zipCode: "50674"},
	{latitude: 42.19782, longitude: -92.512092, zipCode: "50675"},
	{latitude: 42.798888, longitude: -92.280177, zipCode: "50676"},
	{latitude: 42.766686, longitude: -92.43976, zipCode: "50677"},
	{latitude: 42.444591, longitude: -92.917174, zipCode: "50680"},
	{latitude: 42.777552, longitude: -92.009278, zipCode: "50681"},
	{latitude: 42.449804, longitude: -91.694685, zipCode: "50682"},
	{latitude: 42.417552, longitude: -92.344572, zipCode: "50701"},
	{latitude: 42.461502, longitude: -92.313745, zipCode: "50702"},
	{latitude: 42.548059, longitude: -92.286431, zipCode: "50703"},
	{latitude: 42.477381, longitude: -92.281742, zipCode: "50707"},
	{latitude: 41.078671, longitude: -94.401822, zipCode: "50801"},
	{latitude: 41.022578, longitude: -94.190473, zipCode: "50830"},
	{latitude: 40.950844, longitude: -94.222363, zipCode: "50831"},
	{latitude: 40.670545, longitude: -94.700888, zipCode: "50833"},
	{latitude: 40.706376, longitude: -94.375545, zipCode: "50835"},
	{latitude: 40.632285, longitude: -94.497484, zipCode: "50836"},
	{latitude: 41.219823, longitude: -94.691439, zipCode: "50837"},
	{latitude: 41.048763, longitude: -94.822031, zipCode: "50839"},
	{latitude: 40.79019, longitude: -94.482618, zipCode: "50840"},
	{latitude: 41.009059, longitude: -94.768784, zipCode: "50841"},
	{latitude: 41.039906, longitude: -94.461796, zipCode: "50842"},
	{latitude: 41.236842, longitude: -94.885233, zipCode: "50843"},
	{latitude: 40.815197, longitude: -94.352173, zipCode: "50845"},
	{latitude: 41.308993, longitude: -94.576117, zipCode: "50846"},
	{latitude: 41.141664, longitude: -94.983877, zipCode: "50847"},
	{latitude: 40.791748, longitude: -94.764282, zipCode: "50848"},
	{latitude: 41.294526, longitude: -94.405017, zipCode: "50849"},
	{latitude: 40.908584, longitude: -94.537032, zipCode: "50851"},
	{latitude: 41.236859, longitude: -94.767897, zipCode: "50853"},
	{latitude: 40.679375, longitude: -94.21277, zipCode: "50854"},
	{latitude: 40.934815, longitude: -94.879538, zipCode: "50857"},
	{latitude: 41.211108, longitude: -94.409596, zipCode: "50858"},
	{latitude: 41.067099, longitude: -94.597342, zipCode: "50859"},
	{latitude: 40.617459, longitude: -94.347944, zipCode: "50860"},
	{latitude: 40.908431, longitude: -94.255922, zipCode: "50861"},
	{latitude: 40.811604, longitude: -94.647731, zipCode: "50862"},
	{latitude: 40.847781, longitude: -94.175734, zipCode: "50863"},
	{latitude: 40.97037, longitude: -94.978111, zipCode: "50864"},
	{latitude: 42.840544, longitude: -96.546124, zipCode: "51001"},
	{latitude: 42.702052, longitude: -95.318922, zipCode: "51002"},
	{latitude: 42.989594, longitude: -95.985959, zipCode: "51003"},
	{latitude: 42.380122, longitude: -95.918206, zipCode: "51004"},
	{latitude: 42.723603, longitude: -95.418791, zipCode: "51005"},
	{latitude: 42.310269, longitude: -95.610728, zipCode: "51006"},
	{latitude: 42.40221, longitude: -96.18858, zipCode: "51007"},
	{latitude: 42.811254, longitude: -96.267221, zipCode: "51008"},
	{latitude: 42.945024, longitude: -95.551435, zipCode: "51009"},
	{latitude: 42.097526, longitude: -95.938189, zipCode: "51010"},
	{latitude: 42.915627, longitude: -96.513876, zipCode: "51011"},
	{latitude: 42.745429, longitude: -95.560413, zipCode: "51012"},
	{latitude: 42.78764, longitude: -95.707313, zipCode: "51014"},
	{latitude: 42.339375, longitude: -96.081085, zipCode: "51015"},
	{latitude: 42.471834, longitude: -95.805254, zipCode: "51016"},
	{latitude: 42.449661, longitude: -95.680771, zipCode: "51018"},
	{latitude: 42.276752, longitude: -95.71986, zipCode: "51019"},
	{latitude: 42.515038, longitude: -95.410213, zipCode: "51020"},
	{latitude: 42.978178, longitude: -95.847262, zipCode: "51022"},
	{latitude: 43.00674, longitude: -96.463475, zipCode: "51023"},
	{latitude: 42.626486, longitude: -96.262887, zipCode: "51024"},
	{latitude: 42.497628, longitude: -95.556141, zipCode: "51025"},
	{latitude: 42.25555, longitude: -96.068353, zipCode: "51026"},
	{latitude: 42.965721, longitude: -96.327908, zipCode: "51027"},
	{latitude: 42.596655, longitude: -95.98764, zipCode: "51028"},
	{latitude: 42.885476, longitude: -95.541381, zipCode: "51029"},
	{latitude: 42.497741, longitude: -96.188321, zipCode: "51030"},
	{latitude: 42.80324, longitude: -96.186428, zipCode: "51031"},
	{latitude: 42.919369, longitude: -95.252397, zipCode: "51033"},
	{latitude: 42.162829, longitude: -95.789595, zipCode: "51034"},
	{latitude: 42.780788, longitude: -95.800528, zipCode: "51035"},
	{latitude: 42.969703, longitude: -96.189514, zipCode: "51036"},
	{latitude: 42.825694, longitude: -95.642679, zipCode: "51037"},
	{latitude: 42.703191, longitude: -96.316537, zipCode: "51038"},
	{latitude: 42.449218, longitude: -96.04956, zipCode: "51039"},
	{latitude: 42.03254, longitude: -96.068714, zipCode: "51040"},
	{latitude: 43.024397, longitude: -96.077033, zipCode: "51041"},
	{latitude: 42.306174, longitude: -95.918122, zipCode: "51044"},
	{latitude: 42.820319, longitude: -96.057947, zipCode: "51045"},
	{latitude: 42.961834, longitude: -95.67802, zipCode: "51046"},
	{latitude: 42.942362, longitude: -95.36954, zipCode: "51047"},
	{latitude: 42.550952, longitude: -95.84131, zipCode: "51048"},
	{latitude: 42.630096, longitude: -95.644373, zipCode: "51049"},
	{latitude: 42.80044, longitude: -95.947854, zipCode: "51050"},
	{latitude: 42.206095, longitude: -95.964941, zipCode: "51051"},
	{latitude: 42.308234, longitude: -96.265987, zipCode: "51052"},
	{latitude: 42.495057, longitude: -95.286548, zipCode: "51053"},
	{latitude: 42.380877, longitude: -96.335045, zipCode: "51054"},
	{latitude: 42.208203, longitude: -96.260324, zipCode: "51055"},
	{latitude: 42.241293, longitude: -95.956396, zipCode: "51056"},
	{latitude: 42.989315, longitude: -95.476943, zipCode: "51058"},
	{latitude: 42.042386, longitude: -95.684387, zipCode: "51060"},
	{latitude: 42.579722, longitude: -95.725258, zipCode: "51061"},
	{latitude: 42.697865, longitude: -96.534926, zipCode: "51062"},
	{latitude: 42.132788, longitude: -96.190705, zipCode: "51063"},
	{latitude: 42.493688, longitude: -96.394184, zipCode: "51101"},
	{latitude: 42.519729, longitude: -96.449305, zipCode: "51103"},
	{latitude: 42.534533, longitude: -96.403167, zipCode: "51104"},
	{latitude: 42.507867, longitude: -96.366338, zipCode: "51105"},
	{latitude: 42.463175, longitude: -96.312909, zipCode: "51106"},
	{latitude: 42.565994, longitude: -96.352523, zipCode: "51108"},
	{latitude: 42.610335, longitude: -96.469977, zipCode: "51109"},
	{latitude: 42.4185, longitude: -96.393103, zipCode: "51111"},
	{latitude: 43.182369, longitude: -95.865607, zipCode: "51201"},
	{latitude: 43.356197, longitude: -96.322121, zipCode: "51230"},
	{latitude: 43.105665, longitude: -95.741308, zipCode: "51231"},
	{latitude: 43.29504, longitude: -95.809539, zipCode: "51232"},
	{latitude: 43.192205, longitude: -96.019335, zipCode: "51234"},
	{latitude: 43.294885, longitude: -96.215338, zipCode: "51235"},
	{latitude: 43.336066, longitude: -96.000764, zipCode: "51237"},
	{latitude: 43.074388, longitude: -95.892427, zipCode: "51238"},
	{latitude: 43.203917, longitude: -96.147481, zipCode: "51239"},
	{latitude: 43.309361, longitude: -96.458419, zipCode: "51240"},
	{latitude: 43.451232, longitude: -96.46175, zipCode: "51241"},
	{latitude: 43.432274, longitude: -96.338057, zipCode: "51242"},
	{latitude: 43.440585, longitude: -95.896644, zipCode: "51243"},
	{latitude: 43.244307, longitude: -95.934382, zipCode: "51244"},
	{latitude: 43.077819, longitude: -95.601445, zipCode: "51245"},
	{latitude: 43.428834, longitude: -96.164704, zipCode: "51246"},
	{latitude: 43.193752, longitude: -96.338127, zipCode: "51247"},
	{latitude: 43.204439, longitude: -95.659092, zipCode: "51248"},
	{latitude: 43.407372, longitude: -95.730959, zipCode: "51249"},
	{latitude: 43.090933, longitude: -96.205811, zipCode: "51250"},
	{latitude: 43.153092, longitude: -95.149793, zipCode: "51301"},
	{latitude: 43.35956, longitude: -95.13082, zipCode: "51331"},
	{latitude: 43.136582, longitude: -95.001158, zipCode: "51333"},
	{latitude: 43.403986, longitude: -94.803653, zipCode: "51334"},
	{latitude: 43.205366, longitude: -95.31875, zipCode: "51338"},
	{latitude: 43.240489, longitude: -95.156123, zipCode: "51340"},
	{latitude: 43.018185, longitude: -95.036, zipCode: "51341"},
	{latitude: 43.247877, longitude: -94.73688, zipCode: "51342"},
	{latitude: 43.017604, longitude: -95.127508, zipCode: "51343"},
	{latitude: 43.422367, longitude: -95.436696, zipCode: "51345"},
	{latitude: 43.191331, longitude: -95.453632, zipCode: "51346"},
	{latitude: 43.422425, longitude: -95.318451, zipCode: "51347"},
	{latitude: 43.307533, longitude: -95.598273, zipCode: "51350"},
	{latitude: 43.315064, longitude: -95.183646, zipCode: "51351"},
	{latitude: 43.412347, longitude: -95.543273, zipCode: "51354"},
	{latitude: 43.388681, longitude: -95.140456, zipCode: "51355"},
	{latitude: 43.062161, longitude: -95.287599, zipCode: "51357"},
	{latitude: 43.129897, longitude: -94.89371, zipCode: "51358"},
	{latitude: 43.43372, longitude: -95.0859, zipCode: "51360"},
	{latitude: 43.433676, longitude: -94.946745, zipCode: "51363"},
	{latitude: 43.29928, longitude: -94.962943, zipCode: "51364"},
	{latitude: 43.309619, longitude: -94.733283, zipCode: "51365"},
	{latitude: 42.960703, longitude: -95.002954, zipCode: "51366"},
	{latitude: 42.06492, longitude: -94.870021, zipCode: "51401"},
	{latitude: 42.0897, longitude: -95.026636, zipCode: "51430"},
	{latitude: 42.344418, longitude: -95.351451, zipCode: "51431"},
	{latitude: 41.910095, longitude: -95.137973, zipCode: "51432"},
	{latitude: 42.266648, longitude: -94.875329, zipCode: "51433"},
	{latitude: 42.189147, longitude: -95.004791, zipCode: "51436"},
	{latitude: 42.08284, longitude: -95.589058, zipCode: "51439"},
	{latitude: 41.911709, longitude: -94.81383, zipCode: "51440"},
	{latitude: 42.113316, longitude: -95.315424, zipCode: "51441"},
	{latitude: 42.03126, longitude: -95.356812, zipCode: "51442"},
	{latitude: 42.069895, longitude: -94.697048, zipCode: "51443"},
	{latitude: 42.012169, longitude: -94.973518, zipCode: "51444"},
	{latitude: 42.328061, longitude: -95.467708, zipCode: "51445"},
	{latitude: 41.776617, longitude: -95.192705, zipCode: "51446"},
	{latitude: 41.721032, longitude: -95.191945, zipCode: "51447"},
	{latitude: 42.205359, longitude: -95.309789, zipCode: "51448"},
	{latitude: 42.266, longitude: -94.734403, zipCode: "51449"},
	{latitude: 42.316392, longitude: -95.020732, zipCode: "51450"},
	{latitude: 42.185084, longitude: -94.695163, zipCode: "51451"},
	{latitude: 42.122421, longitude: -94.783642, zipCode: "51452"},
	{latitude: 42.26227, longitude: -94.545537, zipCode: "51453"},
	{latitude: 41.884818, longitude: -95.190586, zipCode: "51454"},
	{latitude: 41.907807, longitude: -95.056903, zipCode: "51455"},
	{latitude: 42.326375, longitude: -95.235308, zipCode: "51458"},
	{latitude: 42.044967, longitude: -94.637787, zipCode: "51459"},
	{latitude: 42.132415, longitude: -95.571034, zipCode: "51460"},
	{latitude: 42.17409, longitude: -95.474138, zipCode: "51461"},
	{latitude: 42.014403, longitude: -94.556511, zipCode: "51462"},
	{latitude: 41.907424, longitude: -94.912652, zipCode: "51463"},
	{latitude: 42.086931, longitude: -95.200635, zipCode: "51465"},
	{latitude: 42.247029, longitude: -95.099555, zipCode: "51466"},
	{latitude: 42.07192, longitude: -95.107346, zipCode: "51467"},
	{latitude: 41.227277, longitude: -95.878439, zipCode: "51501"},
	{latitude: 41.22322, longitude: -95.784481, zipCode: "51503"},
	{latitude: 41.288533, longitude: -95.916872, zipCode: "51510"},
	{latitude: 41.969195, longitude: -95.459299, zipCode: "51520"},
	{latitude: 41.485468, longitude: -95.344684, zipCode: "51521"},
	{latitude: 41.915211, longitude: -96.06771, zipCode: "51523"},
	{latitude: 41.234039, longitude: -95.420008, zipCode: "51525"},
	{latitude: 41.37389, longitude: -95.871784, zipCode: "51526"},
	{latitude: 41.837131, longitude: -95.337258, zipCode: "51527"},
	{latitude: 41.908916, longitude: -95.501172, zipCode: "51528"},
	{latitude: 41.860476, longitude: -95.643463, zipCode: "51529"},
	{latitude: 41.787456, longitude: -95.430482, zipCode: "51530"},
	{latitude: 41.5875, longitude: -95.088386, zipCode: "51531"},
	{latitude: 41.137981, longitude: -95.109116, zipCode: "51532"},
	{latitude: 41.039454, longitude: -95.378172, zipCode: "51533"},
	{latitude: 41.041259, longitude: -95.717828, zipCode: "51534"},
	{latitude: 41.226474, longitude: -95.126714, zipCode: "51535"},
	{latitude: 41.384331, longitude: -95.373214, zipCode: "51536"},
	{latitude: 41.650135, longitude: -95.297189, zipCode: "51537"},
	{latitude: 41.024322, longitude: -95.498233, zipCode: "51540"},
	{latitude: 41.139096, longitude: -95.41618, zipCode: "51541"},
	{latitude: 41.440364, longitude: -95.831845, zipCode: "51542"},
	{latitude: 41.657919, longitude: -95.073131, zipCode: "51543"},
	{latitude: 41.313903, longitude: -95.103393, zipCode: "51544"},
	{latitude: 41.850978, longitude: -96.014628, zipCode: "51545"},
	{latitude: 41.637077, longitude: -95.766074, zipCode: "51546"},
	{latitude: 41.317134, longitude: -95.635511, zipCode: "51548"},
	{latitude: 41.18583, longitude: -95.466707, zipCode: "51549"},
	{latitude: 41.692355, longitude: -95.873905, zipCode: "51550"},
	{latitude: 40.99082, longitude: -95.587501, zipCode: "51551"},
	{latitude: 41.486113, longitude: -95.114407, zipCode: "51552"},
	{latitude: 41.4146, longitude: -95.547066, zipCode: "51553"},
	{latitude: 41.145106, longitude: -95.681207, zipCode: "51554"},
	{latitude: 41.559503, longitude: -95.915257, zipCode: "51555"},
	{latitude: 41.64287, longitude: -96.040104, zipCode: "51556"},
	{latitude: 41.733644, longitude: -96.006365, zipCode: "51557"},
	{latitude: 41.915933, longitude: -95.880636, zipCode: "51558"},
	{latitude: 41.455393, longitude: -95.643037, zipCode: "51559"},
	{latitude: 41.313531, longitude: -95.400865, zipCode: "51560"},
	{latitude: 41.005051, longitude: -95.809552, zipCode: "51561"},
	{latitude: 41.725074, longitude: -95.493322, zipCode: "51562"},
	{latitude: 41.570675, longitude: -95.585585, zipCode: "51563"},
	{latitude: 41.80959, longitude: -95.922828, zipCode: "51564"},
	{latitude: 41.642892, longitude: -95.524498, zipCode: "51565"},
	{latitude: 41.015123, longitude: -95.229709, zipCode: "51566"},
	{latitude: 41.527724, longitude: -95.465477, zipCode: "51570"},
	{latitude: 41.14233, longitude: -95.616565, zipCode: "51571"},
	{latitude: 41.984413, longitude: -95.807762, zipCode: "51572"},
	{latitude: 40.979235, longitude: -95.094799, zipCode: "51573"},
	{latitude: 41.240315, longitude: -95.602468, zipCode: "51575"},
	{latitude: 41.382876, longitude: -95.714924, zipCode: "51576"},
	{latitude: 41.463613, longitude: -95.203768, zipCode: "51577"},
	{latitude: 41.720025, longitude: -95.39504, zipCode: "51578"},
	{latitude: 41.761721, longitude: -95.719127, zipCode: "51579"},
	{latitude: 40.735527, longitude: -95.346985, zipCode: "51601"},
	{latitude: 40.601029, longitude: -95.194274, zipCode: "51630"},
	{latitude: 40.591423, longitude: -95.009992, zipCode: "51631"},
	{latitude: 40.745841, longitude: -95.04658, zipCode: "51632"},
	{latitude: 40.667147, longitude: -95.235201, zipCode: "51636"},
	{latitude: 40.620441, longitude: -95.118338, zipCode: "51637"},
	{latitude: 40.837439, longitude: -95.266231, zipCode: "51638"},
	{latitude: 40.720105, longitude: -95.466467, zipCode: "51639"},
	{latitude: 40.626218, longitude: -95.661342, zipCode: "51640"},
	{latitude: 40.882063, longitude: -95.422875, zipCode: "51645"},
	{latitude: 40.747685, longitude: -94.874449, zipCode: "51646"},
	{latitude: 40.605426, longitude: -95.345104, zipCode: "51647"},
	{latitude: 40.739475, longitude: -95.800397, zipCode: "51648"},
	{latitude: 40.850406, longitude: -95.527041, zipCode: "51649"},
	{latitude: 40.678329, longitude: -95.550206, zipCode: "51650"},
	{latitude: 40.656567, longitude: -95.034694, zipCode: "51651"},
	{latitude: 40.772515, longitude: -95.623138, zipCode: "51652"},
	{latitude: 40.878432, longitude: -95.682322, zipCode: "51653"},
	{latitude: 40.834899, longitude: -95.78159, zipCode: "51654"},
	{latitude: 40.735257, longitude: -95.154337, zipCode: "51656"},
	{latitude: 42.548323, longitude: -90.69367, zipCode: "52001"},
	{latitude: 42.524766, longitude: -90.771903, zipCode: "52002"},
	{latitude: 42.430701, longitude: -90.669682, zipCode: "52003"},
	{latitude: 42.153194, longitude: -90.592277, zipCode: "52030"},
	{latitude: 42.252564, longitude: -90.486421, zipCode: "52031"},
	{latitude: 42.289646, longitude: -90.839534, zipCode: "52032"},
	{latitude: 42.279419, longitude: -91.001769, zipCode: "52033"},
	{latitude: 42.670738, longitude: -91.181213, zipCode: "52035"},
	{latitude: 42.472865, longitude: -91.340863, zipCode: "52036"},
	{latitude: 41.967208, longitude: -90.633725, zipCode: "52037"},
	{latitude: 42.586686, longitude: -91.561477, zipCode: "52038"},
	{latitude: 42.538097, longitude: -90.857411, zipCode: "52039"},
	{latitude: 42.503641, longitude: -91.14169, zipCode: "52040"},
	{latitude: 42.506068, longitude: -91.25505, zipCode: "52041"},
	{latitude: 42.678593, longitude: -91.36016, zipCode: "52042"},
	{latitude: 42.834004, longitude: -91.426289, zipCode: "52043"},
	{latitude: 42.768078, longitude: -91.323268, zipCode: "52044"},
	{latitude: 42.454871, longitude: -90.93113, zipCode: "52045"},
	{latitude: 42.440225, longitude: -91.011915, zipCode: "52046"},
	{latitude: 42.969286, longitude: -91.341757, zipCode: "52047"},
	{latitude: 42.737292, longitude: -91.255776, zipCode: "52048"},
	{latitude: 42.885565, longitude: -91.199378, zipCode: "52049"},
	{latitude: 42.610298, longitude: -91.326733, zipCode: "52050"},
	{latitude: 42.742443, longitude: -91.116701, zipCode: "52052"},
	{latitude: 42.606556, longitude: -90.963965, zipCode: "52053"},
	{latitude: 42.305599, longitude: -90.628157, zipCode: "52054"},
	{latitude: 42.604028, longitude: -91.072578, zipCode: "52056"},
	{latitude: 42.490877, longitude: -91.453192, zipCode: "52057"},
	{latitude: 42.101196, longitude: -90.680278, zipCode: "52060"},
	{latitude: 42.093574, longitude: -90.310826, zipCode: "52064"},
	{latitude: 42.583525, longitude: -91.102046, zipCode: "52065"},
	{latitude: 42.682033, longitude: -90.960141, zipCode: "52066"},
	{latitude: 42.423804, longitude: -90.811257, zipCode: "52068"},
	{latitude: 42.060515, longitude: -90.430017, zipCode: "52069"},
	{latitude: 42.084381, longitude: -90.208468, zipCode: "52070"},
	{latitude: 42.362383, longitude: -90.542345, zipCode: "52071"},
	{latitude: 42.92025, longitude: -91.380259, zipCode: "52072"},
	{latitude: 42.618868, longitude: -90.821643, zipCode: "52073"},
	{latitude: 42.110483, longitude: -90.462429, zipCode: "52074"},
	{latitude: 42.165739, longitude: -90.479174, zipCode: "52075"},
	{latitude: 42.697168, longitude: -91.510418, zipCode: "52076"},
	{latitude: 42.820999, longitude: -91.562518, zipCode: "52077"},
	{latitude: 42.395826, longitude: -91.115713, zipCode: "52078"},
	{latitude: 42.287754, longitude: -90.719099, zipCode: "52079"},
	{latitude: 43.366329, longitude: -91.772937, zipCode: "52101"},
	{latitude: 43.211162, longitude: -91.928137, zipCode: "52132"},
	{latitude: 43.108906, longitude: -91.660578, zipCode: "52133"},
	{latitude: 43.471562, longitude: -92.400791, zipCode: "52134"},
	{latitude: 43.02061, longitude: -91.662781, zipCode: "52135"},
	{latitude: 43.371394, longitude: -92.112398, zipCode: "52136"},
	{latitude: 43.445644, longitude: -91.534572, zipCode: "52140"},
	{latitude: 42.931188, longitude: -91.640929, zipCode: "52141"},
	{latitude: 42.825998, longitude: -91.7983, zipCode: "52142"},
	{latitude: 43.139611, longitude: -91.960711, zipCode: "52144"},
	{latitude: 43.175923, longitude: -91.206021, zipCode: "52146"},
	{latitude: 42.944781, longitude: -91.959293, zipCode: "52147"},
	{latitude: 43.346405, longitude: -91.264794, zipCode: "52151"},
	{latitude: 43.117985, longitude: -92.165058, zipCode: "52154"},
	{latitude: 43.412758, longitude: -92.295716, zipCode: "52155"},
	{latitude: 43.053113, longitude: -91.465349, zipCode: "52156"},
	{latitude: 43.021895, longitude: -91.240309, zipCode: "52157"},
	{latitude: 43.043301, longitude: -91.192423, zipCode: "52158"},
	{latitude: 43.078784, longitude: -91.365585, zipCode: "52159"},
	{latitude: 43.46905, longitude: -91.351235, zipCode: "52160"},
	{latitude: 43.127306, longitude: -91.756216, zipCode: "52161"},
	{latitude: 43.11063, longitude: -91.552658, zipCode: "52162"},
	{latitude: 43.219765, longitude: -92.103146, zipCode: "52163"},
	{latitude: 42.854706, longitude: -91.906611, zipCode: "52164"},
	{latitude: 43.308542, longitude: -91.994305, zipCode: "52165"},
	{latitude: 43.067951, longitude: -91.935511, zipCode: "52166"},
	{latitude: 43.203409, longitude: -91.953412, zipCode: "52168"},
	{latitude: 42.860708, longitude: -91.663069, zipCode: "52169"},
	{latitude: 43.228811, longitude: -91.304824, zipCode: "52170"},
	{latitude: 43.068229, longitude: -92.052943, zipCode: "52171"},
	{latitude: 43.271159, longitude: -91.48377, zipCode: "52172"},
	{latitude: 42.99367, longitude: -91.823872, zipCode: "52175"},
	{latitude: 41.32966, longitude: -91.547062, zipCode: "52201"},
	{latitude: 42.159208, longitude: -91.637843, zipCode: "52202"},
	{latitude: 41.813857, longitude: -91.89159, zipCode: "52203"},
	{latitude: 42.116219, longitude: -91.289568, zipCode: "52205"},
	{latitude: 41.991869, longitude: -91.887167, zipCode: "52206"},
	{latitude: 42.092794, longitude: -90.829985, zipCode: "52207"},
	{latitude: 41.876782, longitude: -92.25691, zipCode: "52208"},
	{latitude: 41.908972, longitude: -92.09756, zipCode: "52209"},
	{latitude: 42.334017, longitude: -91.992953, zipCode: "52210"},
	{latitude: 41.75389, longitude: -92.449753, zipCode: "52211"},
	{latitude: 42.108797, longitude: -91.090273, zipCode: "52212"},
	{latitude: 42.201851, longitude: -91.75965, zipCode: "52213"},
	{latitude: 42.187891, longitude: -91.509279, zipCode: "52214"},
	{latitude: 41.913536, longitude: -92.405305, zipCode: "52215"},
	{latitude: 41.883591, longitude: -91.024261, zipCode: "52216"},
	{latitude: 42.079487, longitude: -92.410663, zipCode: "52217"},
	{latitude: 42.295403, longitude: -91.538578, zipCode: "52218"},
	{latitude: 42.237527, longitude: -91.425728, zipCode: "52219"},
	{latitude: 41.726613, longitude: -91.998528, zipCode: "52220"},
	{latitude: 41.640929, longitude: -92.331699, zipCode: "52221"},
	{latitude: 41.576405, longitude: -92.34065, zipCode: "52222"},
	{latitude: 42.412626, longitude: -91.324396, zipCode: "52223"},
	{latitude: 42.157533, longitude: -92.293719, zipCode: "52224"},
	{latitude: 42.012913, longitude: -92.326336, zipCode: "52225"},
	{latitude: 41.894414, longitude: -91.57022, zipCode: "52227"},
	{latitude: 41.902291, longitude: -91.788703, zipCode: "52228"},
	{latitude: 42.144629, longitude: -92.169234, zipCode: "52229"},
	{latitude: 41.358831, longitude: -92.058629, zipCode: "52231"},
	{latitude: 41.804868, longitude: -92.347089, zipCode: "52232"},
	{latitude: 42.04922, longitude: -91.687328, zipCode: "52233"},
	{latitude: 41.578151, longitude: -91.531874, zipCode: "52235"},
	{latitude: 41.733399, longitude: -91.876355, zipCode: "52236"},
	{latitude: 42.340836, longitude: -91.251952, zipCode: "52237"},
	{latitude: 41.630753, longitude: -91.498781, zipCode: "52240"},
	{latitude: 41.697221, longitude: -91.597452, zipCode: "52241"},
	{latitude: 41.662939, longitude: -91.54355, zipCode: "52242"},
	{latitude: 41.672878, longitude: -91.512105, zipCode: "52245"},
	{latitude: 41.648386, longitude: -91.596793, zipCode: "52246"},
	{latitude: 41.518674, longitude: -91.72329, zipCode: "52247"},
	{latitude: 41.345772, longitude: -91.935346, zipCode: "52248"},
	{latitude: 42.012008, longitude: -92.205169, zipCode: "52249"},
	{latitude: 41.713615, longitude: -92.210074, zipCode: "52251"},
	{latitude: 42.197129, longitude: -91.227238, zipCode: "52252"},
	{latitude: 41.895347, longitude: -91.353373, zipCode: "52253"},
	{latitude: 41.952276, longitude: -90.805353, zipCode: "52254"},
	{latitude: 41.862918, longitude: -90.961266, zipCode: "52255"},
	{latitude: 41.914849, longitude: -92.172388, zipCode: "52257"},
	{latitude: 41.788471, longitude: -92.082542, zipCode: "52301"},
	{latitude: 42.065415, longitude: -91.560853, zipCode: "52302"},
	{latitude: 42.017962, longitude: -91.34211, zipCode: "52305"},
	{latitude: 41.887141, longitude: -91.254212, zipCode: "52306"},
	{latitude: 41.791523, longitude: -91.902448, zipCode: "52307"},
	{latitude: 41.573345, longitude: -92.159358, zipCode: "52308"},
	{latitude: 42.131684, longitude: -90.888642, zipCode: "52309"},
	{latitude: 42.220372, longitude: -91.195364, zipCode: "52310"},
	{latitude: 42.006168, longitude: -91.245263, zipCode: "52312"},
	{latitude: 42.257241, longitude: -92.096686, zipCode: "52313"},
	{latitude: 41.935711, longitude: -91.447883, zipCode: "52314"},
	{latitude: 42.001821, longitude: -91.969805, zipCode: "52315"},
	{latitude: 41.545573, longitude: -92.094822, zipCode: "52316"},
	{latitude: 41.767111, longitude: -91.634497, zipCode: "52317"},
	{latitude: 41.89006, longitude: -91.896534, zipCode: "52318"},
	{latitude: 41.999148, longitude: -91.14836, zipCode: "52320"},
	{latitude: 42.141694, longitude: -90.983093, zipCode: "52321"},
	{latitude: 41.692718, longitude: -91.762785, zipCode: "52322"},
	{latitude: 41.984429, longitude: -90.953333, zipCode: "52323"},
	{latitude: 42.06395, longitude: -91.806099, zipCode: "52324"},
	{latitude: 41.583907, longitude: -91.925884, zipCode: "52325"},
	{latitude: 42.394756, longitude: -91.75651, zipCode: "52326"},
	{latitude: 41.477136, longitude: -91.582989, zipCode: "52327"},
	{latitude: 42.071943, longitude: -91.666403, zipCode: "52328"},
	{latitude: 42.351929, longitude: -91.860116, zipCode: "52329"},
	{latitude: 42.352602, longitude: -91.50077, zipCode: "52330"},
	{latitude: 42.098053, longitude: -91.887293, zipCode: "52332"},
	{latitude: 41.80367, longitude: -91.487121, zipCode: "52333"},
	{latitude: 41.730797, longitude: -91.94354, zipCode: "52334"},
	{latitude: 41.467961, longitude: -92.053796, zipCode: "52335"},
	{latitude: 42.073784, longitude: -91.441835, zipCode: "52336"},
	{latitude: 41.88215, longitude: -91.142841, zipCode: "52337"},
	{latitude: 41.8179, longitude: -91.707501, zipCode: "52338"},
	{latitude: 41.927944, longitude: -92.587091, zipCode: "52339"},
	{latitude: 41.705564, longitude: -91.676678, zipCode: "52340"},
	{latitude: 42.102854, longitude: -91.726179, zipCode: "52341"},
	{latitude: 42.043495, longitude: -92.558548, zipCode: "52342"},
	{latitude: 42.292007, longitude: -91.683283, zipCode: "52344"},
	{latitude: 42.222424, longitude: -91.891704, zipCode: "52345"},
	{latitude: 42.012974, longitude: -92.081192, zipCode: "52346"},
	{latitude: 41.713462, longitude: -92.285895, zipCode: "52347"},
	{latitude: 41.9933, longitude: -92.385955, zipCode: "52348"},
	{latitude: 42.172181, longitude: -92.009259, zipCode: "52349"},
	{latitude: 41.874913, longitude: -91.835325, zipCode: "52351"},
	{latitude: 42.28913, longitude: -91.777966, zipCode: "52352"},
	{latitude: 41.298834, longitude: -91.715643, zipCode: "52353"},
	{latitude: 41.918839, longitude: -91.988595, zipCode: "52354"},
	{latitude: 41.460617, longitude: -92.191832, zipCode: "52355"},
	{latitude: 41.474907, longitude: -91.847686, zipCode: "52356"},
	{latitude: 41.6914, longitude: -91.318432, zipCode: "52358"},
	{latitude: 41.354312, longitude: -91.810076, zipCode: "52359"},
	{latitude: 41.640938, longitude: -92.015979, zipCode: "52361"},
	{latitude: 42.058411, longitude: -91.003185, zipCode: "52362"},
	{latitude: 41.975486, longitude: -91.659172, zipCode: "52401"},
	{latitude: 42.023172, longitude: -91.659613, zipCode: "52402"},
	{latitude: 41.965959, longitude: -91.566054, zipCode: "52403"},
	{latitude: 41.923046, longitude: -91.688501, zipCode: "52404"},
	{latitude: 41.983395, longitude: -91.746156, zipCode: "52405"},
	{latitude: 42.053819, longitude: -91.729266, zipCode: "52411"},
	{latitude: 41.044369, longitude: -92.42906, zipCode: "52501"},
	{latitude: 40.989078, longitude: -92.296206, zipCode: "52530"},
	{latitude: 41.025726, longitude: -92.819758, zipCode: "52531"},
	{latitude: 41.008386, longitude: -92.146425, zipCode: "52533"},
	{latitude: 41.273425, longitude: -92.680696, zipCode: "52534"},
	{latitude: 40.862943, longitude: -91.959573, zipCode: "52535"},
	{latitude: 40.957466, longitude: -92.60891, zipCode: "52536"},
	{latitude: 40.734974, longitude: -92.413637, zipCode: "52537"},
	{latitude: 41.15681, longitude: -91.823033, zipCode: "52540"},
	{latitude: 40.61985, longitude: -92.056237, zipCode: "52542"},
	{latitude: 41.201977, longitude: -92.512669, zipCode: "52543"},
	{latitude: 40.706642, longitude: -92.912272, zipCode: "52544"},
	{latitude: 41.08548, longitude: -92.53063, zipCode: "52548"},
	{latitude: 40.618642, longitude: -92.943113, zipCode: "52549"},
	{latitude: 41.313927, longitude: -92.356909, zipCode: "52550"},
	{latitude: 40.818884, longitude: -92.100387, zipCode: "52551"},
	{latitude: 40.827093, longitude: -92.548748, zipCode: "52552"},
	{latitude: 41.148432, longitude: -92.654929, zipCode: "52553"},
	{latitude: 40.92535, longitude: -92.235742, zipCode: "52554"},
	{latitude: 40.644315, longitude: -92.816227, zipCode: "52555"},
	{latitude: 41.024219, longitude: -91.927519, zipCode: "52556"},
	{latitude: 41.020067, longitude: -91.966364, zipCode: "52557"},
	{latitude: 40.854846, longitude: -92.249246, zipCode: "52560"},
	{latitude: 41.220289, longitude: -92.452261, zipCode: "52561"},
	{latitude: 41.265387, longitude: -92.248586, zipCode: "52562"},
	{latitude: 41.168346, longitude: -92.281811, zipCode: "52563"},
	{latitude: 40.742078, longitude: -91.934499, zipCode: "52565"},
	{latitude: 41.128897, longitude: -92.505496, zipCode: "52566"},
	{latitude: 40.93172, longitude: -92.072191, zipCode: "52567"},
	{latitude: 41.177898, longitude: -92.250771, zipCode: "52568"},
	{latitude: 40.980731, longitude: -93.049608, zipCode: "52569"},
	{latitude: 40.687408, longitude: -92.1462, zipCode: "52570"},
	{latitude: 40.873941, longitude: -92.832351, zipCode: "52571"},
	{latitude: 40.670536, longitude: -92.688414, zipCode: "52572"},
	{latitude: 40.627569, longitude: -91.928849, zipCode: "52573"},
	{latitude: 40.809808, longitude: -92.963294, zipCode: "52574"},
	{latitude: 41.215153, longitude: -92.109419, zipCode: "52576"},
	{latitude: 41.273342, longitude: -92.662306, zipCode: "52577"},
	{latitude: 41.105218, longitude: -92.090331, zipCode: "52580"},
	{latitude: 40.80122, longitude: -93.063949, zipCode: "52581"},
	{latitude: 40.804862, longitude: -93.146277, zipCode: "52583"},
	{latitude: 40.653474, longitude: -92.240759, zipCode: "52584"},
	{latitude: 41.189282, longitude: -91.986227, zipCode: "52585"},
	{latitude: 41.344968, longitude: -92.461377, zipCode: "52586"},
	{latitude: 40.857851, longitude: -92.160154, zipCode: "52588"},
	{latitude: 40.665711, longitude: -93.144643, zipCode: "52590"},
	{latitude: 41.316623, longitude: -92.191359, zipCode: "52591"},
	{latitude: 40.772293, longitude: -92.746226, zipCode: "52593"},
	{latitude: 40.836021, longitude: -92.652405, zipCode: "52594"},
	{latitude: 41.288471, longitude: -92.615052, zipCode: "52595"},
	{latitude: 40.85605, longitude: -91.129751, zipCode: "52601"},
	{latitude: 40.520145, longitude: -91.565172, zipCode: "52619"},
	{latitude: 40.702366, longitude: -91.796847, zipCode: "52620"},
	{latitude: 41.208542, longitude: -91.533755, zipCode: "52621"},
	{latitude: 40.849422, longitude: -91.337312, zipCode: "52623"},
	{latitude: 40.734794, longitude: -91.3361, zipCode: "52624"},
	{latitude: 40.660784, longitude: -91.574198, zipCode: "52625"},
	{latitude: 40.637455, longitude: -91.725591, zipCode: "52626"},
	{latitude: 40.658205, longitude: -91.356276, zipCode: "52627"},
	{latitude: 40.805616, longitude: -91.693581, zipCode: "52630"},
	{latitude: 40.783093, longitude: -91.612355, zipCode: "52631"},
	{latitude: 40.431939, longitude: -91.444612, zipCode: "52632"},
	{latitude: 40.991415, longitude: -91.761296, zipCode: "52635"},
	{latitude: 41.017547, longitude: -91.145888, zipCode: "52637"},
	{latitude: 40.801453, longitude: -91.242327, zipCode: "52638"},
	{latitude: 40.532566, longitude: -91.478323, zipCode: "52639"},
	{latitude: 41.101237, longitude: -91.280466, zipCode: "52640"},
	{latitude: 40.98772, longitude: -91.590166, zipCode: "52641"},
	{latitude: 41.039861, longitude: -91.41535, zipCode: "52644"},
	{latitude: 40.912281, longitude: -91.404298, zipCode: "52645"},
	{latitude: 41.069656, longitude: -91.016776, zipCode: "52646"},
	{latitude: 41.134185, longitude: -91.545051, zipCode: "52647"},
	{latitude: 40.83854, longitude: -91.612136, zipCode: "52649"},
	{latitude: 40.951205, longitude: -91.152034, zipCode: "52650"},
	{latitude: 40.864146, longitude: -91.81372, zipCode: "52651"},
	{latitude: 41.097935, longitude: -91.554226, zipCode: "52652"},
	{latitude: 41.16293, longitude: -91.153993, zipCode: "52653"},
	{latitude: 41.140693, longitude: -91.671749, zipCode: "52654"},
	{latitude: 40.847264, longitude: -91.213914, zipCode: "52655"},
	{latitude: 40.739814, longitude: -91.4555, zipCode: "52656"},
	{latitude: 40.767847, longitude: -91.516424, zipCode: "52657"},
	{latitude: 40.699404, longitude: -91.229654, zipCode: "52658"},
	{latitude: 41.132465, longitude: -91.436721, zipCode: "52659"},
	{latitude: 40.994596, longitude: -91.29937, zipCode: "52660"},
	{latitude: 41.981984, longitude: -90.249063, zipCode: "52701"},
	{latitude: 41.587724, longitude: -91.173386, zipCode: "52720"},
	{latitude: 41.755878, longitude: -90.963298, zipCode: "52721"},
	{latitude: 41.570155, longitude: -90.470122, zipCode: "52722"},
	{latitude: 41.496325, longitude: -90.780378, zipCode: "52726"},
	{latitude: 41.954788, longitude: -90.32594, zipCode: "52727"},
	{latitude: 41.458777, longitude: -90.72648, zipCode: "52728"},
	{latitude: 41.800183, longitude: -90.735691, zipCode: "52729"},
	{latitude: 41.775668, longitude: -90.335358, zipCode: "52730"},
	{latitude: 41.966353, longitude: -90.485523, zipCode: "52731"},
	{latitude: 41.891044, longitude: -90.253005, zipCode: "52732"},
	{latitude: 41.259178, longitude: -91.374738, zipCode: "52737"},
	{latitude: 41.273559, longitude: -91.377904, zipCode: "52738"},
	{latitude: 41.373418, longitude: -91.36748, zipCode: "52739"},
	{latitude: 41.838299, longitude: -90.519654, zipCode: "52742"},
	{latitude: 41.718602, longitude: -90.766483, zipCode: "52745"},
	{latitude: 41.716455, longitude: -90.670392, zipCode: "52746"},
	{latitude: 41.598084, longitude: -90.911712, zipCode: "52747"},
	{latitude: 41.665776, longitude: -90.554924, zipCode: "52748"},
	{latitude: 41.346036, longitude: -91.131145, zipCode: "52749"},
	{latitude: 41.937024, longitude: -90.397049, zipCode: "52750"},
	{latitude: 41.835204, longitude: -90.699664, zipCode: "52751"},
	{latitude: 41.274656, longitude: -91.191072, zipCode: "52752"},
	{latitude: 41.628548, longitude: -90.385928, zipCode: "52753"},
	{latitude: 41.338759, longitude: -91.24234, zipCode: "52754"},
	{latitude: 41.479344, longitude: -91.434347, zipCode: "52755"},
	{latitude: 41.729113, longitude: -90.54159, zipCode: "52756"},
	{latitude: 41.803385, longitude: -90.355187, zipCode: "52757"},
	{latitude: 41.742414, longitude: -90.437314, zipCode: "52758"},
	{latitude: 41.460439, longitude: -90.809851, zipCode: "52759"},
	{latitude: 41.552238, longitude: -91.068571, zipCode: "52760"},
	{latitude: 41.439931, longitude: -91.064259, zipCode: "52761"},
	{latitude: 41.720904, longitude: -90.869084, zipCode: "52765"},
	{latitude: 41.467543, longitude: -91.295421, zipCode: "52766"},
	{latitude: 41.573826, longitude: -90.424992, zipCode: "52767"},
	{latitude: 41.70068, longitude: -90.386997, zipCode: "52768"},
	{latitude: 41.605804, longitude: -90.847235, zipCode: "52769"},
	{latitude: 42.019615, longitude: -90.257053, zipCode: "52771"},
	{latitude: 41.741855, longitude: -91.154707, zipCode: "52772"},
	{latitude: 41.621544, longitude: -90.752751, zipCode: "52773"},
	{latitude: 41.907538, longitude: -90.595907, zipCode: "52774"},
	{latitude: 41.578835, longitude: -91.273449, zipCode: "52776"},
	{latitude: 41.847361, longitude: -90.855301, zipCode: "52777"},
	{latitude: 41.604586, longitude: -91.006403, zipCode: "52778"},
	{latitude: 41.521038, longitude: -90.574849, zipCode: "52801"},
	{latitude: 41.494514, longitude: -90.637187, zipCode: "52802"},
	{latitude: 41.538947, longitude: -90.555977, zipCode: "52803"},
	{latitude: 41.532477, longitude: -90.681245, zipCode: "52804"},
	{latitude: 41.589169, longitude: -90.625469, zipCode: "52806"},
	{latitude: 41.610645, longitude: -90.516298, zipCode: "52807"},
	{latitude: 43.607877, longitude: -88.055245, zipCode: "53001"},
	{latitude: 43.463972, longitude: -88.352057, zipCode: "53002"},
	{latitude: 43.212283, longitude: -88.519618, zipCode: "53003"},
	{latitude: 43.499517, longitude: -87.880511, zipCode: "53004"},
	{latitude: 43.062401, longitude: -88.098804, zipCode: "53005"},
	{latitude: 43.620098, longitude: -88.533555, zipCode: "53006"},
	{latitude: 43.108448, longitude: -88.072232, zipCode: "53007"},
	{latitude: 43.612534, longitude: -88.272017, zipCode: "53010"},
	{latitude: 43.659584, longitude: -88.091389, zipCode: "53011"},
	{latitude: 43.314732, longitude: -88.034639, zipCode: "53012"},
	{latitude: 43.565318, longitude: -87.846917, zipCode: "53013"},
	{latitude: 44.026076, longitude: -88.176312, zipCode: "53014"},
	{latitude: 43.907689, longitude: -87.785999, zipCode: "53015"},
	{latitude: 43.311063, longitude: -88.717679, zipCode: "53016"},
	{latitude: 43.201546, longitude: -88.252557, zipCode: "53017"},
	{latitude: 43.048156, longitude: -88.3905, zipCode: "53018"},
	{latitude: 43.696272, longitude: -88.302788, zipCode: "53019"},
	{latitude: 43.861777, longitude: -88.011415, zipCode: "53020"},
	{latitude: 43.49465, longitude: -88.009329, zipCode: "53021"},
	{latitude: 43.232179, longitude: -88.1158, zipCode: "53022"},
	{latitude: 43.780438, longitude: -88.10667, zipCode: "53023"},
	{latitude: 43.332043, longitude: -87.932727, zipCode: "53024"},
	{latitude: 43.316267, longitude: -88.371075, zipCode: "53027"},
	{latitude: 43.146603, longitude: -88.341, zipCode: "53029"},
	{latitude: 43.640401, longitude: -87.91782, zipCode: "53031"},
	{latitude: 43.446723, longitude: -88.623452, zipCode: "53032"},
	{latitude: 43.239613, longitude: -88.25398, zipCode: "53033"},
	{latitude: 43.330168, longitude: -88.618784, zipCode: "53034"},
	{latitude: 43.387774, longitude: -88.543422, zipCode: "53035"},
	{latitude: 43.179661, longitude: -88.575045, zipCode: "53036"},
	{latitude: 43.312701, longitude: -88.160386, zipCode: "53037"},
	{latitude: 43.084577, longitude: -88.790993, zipCode: "53038"},
	{latitude: 43.369786, longitude: -88.709466, zipCode: "53039"},
	{latitude: 43.526965, longitude: -88.191027, zipCode: "53040"},
	{latitude: 43.956873, longitude: -87.972329, zipCode: "53042"},
	{latitude: 43.738345, longitude: -87.783011, zipCode: "53044"},
	{latitude: 43.058889, longitude: -88.15252, zipCode: "53045"},
	{latitude: 43.15724, longitude: -88.158198, zipCode: "53046"},
	{latitude: 43.260504, longitude: -88.628678, zipCode: "53047"},
	{latitude: 43.573797, longitude: -88.457153, zipCode: "53048"},
	{latitude: 43.886116, longitude: -88.288605, zipCode: "53049"},
	{latitude: 43.510549, longitude: -88.547354, zipCode: "53050"},
	{latitude: 43.159411, longitude: -88.121548, zipCode: "53051"},
	{latitude: 43.794689, longitude: -88.236793, zipCode: "53057"},
	{latitude: 43.112352, longitude: -88.411579, zipCode: "53058"},
	{latitude: 43.288567, longitude: -88.525136, zipCode: "53059"},
	{latitude: 43.945427, longitude: -88.120654, zipCode: "53061"},
	{latitude: 43.9635, longitude: -87.802928, zipCode: "53063"},
	{latitude: 43.679772, longitude: -88.571625, zipCode: "53065"},
	{latitude: 43.113916, longitude: -88.489195, zipCode: "53066"},
	{latitude: 43.112665, longitude: -88.432986, zipCode: "53069"},
	{latitude: 43.620528, longitude: -87.806981, zipCode: "53070"},
	{latitude: 43.079955, longitude: -88.266595, zipCode: "53072"},
	{latitude: 43.751541, longitude: -87.996265, zipCode: "53073"},
	{latitude: 43.431139, longitude: -87.887322, zipCode: "53074"},
	{latitude: 43.564515, longitude: -87.990969, zipCode: "53075"},
	{latitude: 43.269659, longitude: -88.208999, zipCode: "53076"},
	{latitude: 43.317703, longitude: -88.466213, zipCode: "53078"},
	{latitude: 43.814532, longitude: -88.199468, zipCode: "53079"},
	{latitude: 43.405726, longitude: -87.986276, zipCode: "53080"},
	{latitude: 43.710329, longitude: -87.733076, zipCode: "53081"},
	{latitude: 43.815995, longitude: -87.764488, zipCode: "53083"},
	{latitude: 43.732838, longitude: -87.855452, zipCode: "53085"},
	{latitude: 43.329685, longitude: -88.273217, zipCode: "53086"},
	{latitude: 44.071746, longitude: -88.299592, zipCode: "53088"},
	{latitude: 43.144625, longitude: -88.233893, zipCode: "53089"},
	{latitude: 43.462267, longitude: -88.171686, zipCode: "53090"},
	{latitude: 43.499624, longitude: -88.434562, zipCode: "53091"},
	{latitude: 43.214964, longitude: -87.951218, zipCode: "53092"},
	{latitude: 43.650061, longitude: -87.93557, zipCode: "53093"},
	{latitude: 43.144017, longitude: -88.731994, zipCode: "53094"},
	{latitude: 43.387671, longitude: -88.158285, zipCode: "53095"},
	{latitude: 43.242049, longitude: -88.009823, zipCode: "53097"},
	{latitude: 43.253742, longitude: -88.710584, zipCode: "53098"},
	{latitude: 43.380446, longitude: -88.525704, zipCode: "53099"},
	{latitude: 42.880533, longitude: -88.217334, zipCode: "53103"},
	{latitude: 42.547457, longitude: -88.03928, zipCode: "53104"},
	{latitude: 42.6636, longitude: -88.280304, zipCode: "53105"},
	{latitude: 42.815148, longitude: -87.942986, zipCode: "53108"},
	{latitude: 42.950505, longitude: -87.860883, zipCode: "53110"},
	{latitude: 42.609352, longitude: -88.745886, zipCode: "53114"},
	{latitude: 42.656603, longitude: -88.668293, zipCode: "53115"},
	{latitude: 42.962495, longitude: -88.491539, zipCode: "53118"},
	{latitude: 42.89309, longitude: -88.485608, zipCode: "53119"},
	{latitude: 42.80275, longitude: -88.417448, zipCode: "53120"},
	{latitude: 42.719665, longitude: -88.534317, zipCode: "53121"},
	{latitude: 43.04752, longitude: -88.087029, zipCode: "53122"},
	{latitude: 42.545706, longitude: -88.561668, zipCode: "53125"},
	{latitude: 42.794482, longitude: -87.997744, zipCode: "53126"},
	{latitude: 42.956246, longitude: -88.380422, zipCode: "53127"},
	{latitude: 42.528607, longitude: -88.335232, zipCode: "53128"},
	{latitude: 42.939993, longitude: -88.000857, zipCode: "53129"},
	{latitude: 42.941231, longitude: -88.0495, zipCode: "53130"},
	{latitude: 42.886404, longitude: -88.012027, zipCode: "53132"},
	{latitude: 43.007883, longitude: -88.669078, zipCode: "53137"},
	{latitude: 42.685512, longitude: -88.120797, zipCode: "53139"},
	{latitude: 42.620224, longitude: -87.828347, zipCode: "53140"},
	{latitude: 42.561695, longitude: -87.891757, zipCode: "53142"},
	{latitude: 42.561565, longitude: -87.828956, zipCode: "53143"},
	{latitude: 42.622122, longitude: -87.920178, zipCode: "53144"},
	{latitude: 42.973705, longitude: -88.155385, zipCode: "53146"},
	{latitude: 42.558204, longitude: -88.451878, zipCode: "53147"},
	{latitude: 42.875371, longitude: -88.341581, zipCode: "53149"},
	{latitude: 42.871356, longitude: -88.13432, zipCode: "53150"},
	{latitude: 42.97393, longitude: -88.095566, zipCode: "53151"},
	{latitude: 42.940891, longitude: -88.402108, zipCode: "53153"},
	{latitude: 42.878693, longitude: -87.898676, zipCode: "53154"},
	{latitude: 42.889875, longitude: -88.588845, zipCode: "53156"},
	{latitude: 42.537694, longitude: -88.368555, zipCode: "53157"},
	{latitude: 42.52459, longitude: -87.885754, zipCode: "53158"},
	{latitude: 42.730069, longitude: -88.225404, zipCode: "53167"},
	{latitude: 42.575473, longitude: -88.124355, zipCode: "53168"},
	{latitude: 42.555548, longitude: -88.156246, zipCode: "53170"},
	{latitude: 42.911998, longitude: -87.859664, zipCode: "53172"},
	{latitude: 42.643045, longitude: -88.414458, zipCode: "53176"},
	{latitude: 42.704395, longitude: -87.933959, zipCode: "53177"},
	{latitude: 43.029545, longitude: -88.596788, zipCode: "53178"},
	{latitude: 42.513801, longitude: -88.130588, zipCode: "53179"},
	{latitude: 42.519845, longitude: -88.240997, zipCode: "53181"},
	{latitude: 42.697723, longitude: -88.040516, zipCode: "53182"},
	{latitude: 43.005837, longitude: -88.370357, zipCode: "53183"},
	{latitude: 42.520486, longitude: -88.613199, zipCode: "53184"},
	{latitude: 42.798205, longitude: -88.194451, zipCode: "53185"},
	{latitude: 43.022155, longitude: -88.204676, zipCode: "53186"},
	{latitude: 43.020011, longitude: -88.294411, zipCode: "53188"},
	{latitude: 42.944064, longitude: -88.290974, zipCode: "53189"},
	{latitude: 42.807774, longitude: -88.736204, zipCode: "53190"},
	{latitude: 42.577543, longitude: -88.545964, zipCode: "53191"},
	{latitude: 42.516451, longitude: -88.184286, zipCode: "53192"},
	{latitude: 42.513955, longitude: -88.490108, zipCode: "53195"},
	{latitude: 43.049673, longitude: -87.892384, zipCode: "53202"},
	{latitude: 43.03807, longitude: -87.914533, zipCode: "53203"},
	{latitude: 43.018587, longitude: -87.926053, zipCode: "53204"},
	{latitude: 43.053576, longitude: -87.933531, zipCode: "53205"},
	{latitude: 43.07523, longitude: -87.933521, zipCode: "53206"},
	{latitude: 42.975097, longitude: -87.8989, zipCode: "53207"},
	{latitude: 43.043979, longitude: -87.968096, zipCode: "53208"},
	{latitude: 43.127801, longitude: -87.949434, zipCode: "53209"},
	{latitude: 43.068886, longitude: -87.973815, zipCode: "53210"},
	{latitude: 43.083056, longitude: -87.883305, zipCode: "53211"},
	{latitude: 43.073857, longitude: -87.908604, zipCode: "53212"},
	{latitude: 43.048801, longitude: -88.001825, zipCode: "53213"},
	{latitude: 43.020328, longitude: -88.012866, zipCode: "53214"},
	{latitude: 42.999273, longitude: -87.943018, zipCode: "53215"},
	{latitude: 43.086333, longitude: -87.976321, zipCode: "53216"},
	{latitude: 43.158225, longitude: -87.913231, zipCode: "53217"},
	{latitude: 43.11372, longitude: -87.99298, zipCode: "53218"},
	{latitude: 42.995181, longitude: -87.993688, zipCode: "53219"},
	{latitude: 42.965172, longitude: -87.991328, zipCode: "53220"},
	{latitude: 42.953909, longitude: -87.943306, zipCode: "53221"},
	{latitude: 43.081401, longitude: -88.034966, zipCode: "53222"},
	{latitude: 43.161027, longitude: -87.988111, zipCode: "53223"},
	{latitude: 43.16333, longitude: -88.039699, zipCode: "53224"},
	{latitude: 43.114266, longitude: -88.042947, zipCode: "53225"},
	{latitude: 43.050631, longitude: -88.043161, zipCode: "53226"},
	{latitude: 42.995814, longitude: -88.042017, zipCode: "53227"},
	{latitude: 42.969046, longitude: -88.043224, zipCode: "53228"},
	{latitude: 43.036914, longitude: -87.933508, zipCode: "53233"},
	{latitude: 42.973107, longitude: -87.867895, zipCode: "53235"},
	{latitude: 43.02227, longitude: -87.976617, zipCode: "53295"},
	{latitude: 42.795888, longitude: -87.816532, zipCode: "53402"},
	{latitude: 42.684171, longitude: -87.815533, zipCode: "53403"},
	{latitude: 42.760834, longitude: -87.815278, zipCode: "53404"},
	{latitude: 42.726918, longitude: -87.830175, zipCode: "53405"},
	{latitude: 42.734536, longitude: -87.864893, zipCode: "53406"},
	{latitude: 42.603546, longitude: -89.075661, zipCode: "53501"},
	{latitude: 42.730406, longitude: -89.438069, zipCode: "53502"},
	{latitude: 43.142976, longitude: -89.957507, zipCode: "53503"},
	{latitude: 42.717524, longitude: -89.850503, zipCode: "53504"},
	{latitude: 42.661074, longitude: -88.820897, zipCode: "53505"},
	{latitude: 43.164816, longitude: -90.286348, zipCode: "53506"},
	{latitude: 42.984266, longitude: -89.914026, zipCode: "53507"},
	{latitude: 42.866188, longitude: -89.575582, zipCode: "53508"},
	{latitude: 42.742361, longitude: -90.322636, zipCode: "53510"},
	{latitude: 42.546428, longitude: -89.106715, zipCode: "53511"},
	{latitude: 43.115004, longitude: -89.739338, zipCode: "53515"},
	{latitude: 42.79685, longitude: -89.873566, zipCode: "53516"},
	{latitude: 43.056983, longitude: -89.832329, zipCode: "53517"},
	{latitude: 43.240989, longitude: -90.59513, zipCode: "53518"},
	{latitude: 42.600902, longitude: -89.361163, zipCode: "53520"},
	{latitude: 42.838105, longitude: -89.400302, zipCode: "53521"},
	{latitude: 42.556909, longitude: -89.78349, zipCode: "53522"},
	{latitude: 42.984409, longitude: -89.028839, zipCode: "53523"},
	{latitude: 42.544032, longitude: -88.846623, zipCode: "53525"},
	{latitude: 42.974318, longitude: -90.344407, zipCode: "53526"},
	{latitude: 43.051357, longitude: -89.192257, zipCode: "53527"},
	{latitude: 43.1183, longitude: -89.638014, zipCode: "53528"},
	{latitude: 43.243074, longitude: -89.531098, zipCode: "53529"},
	{latitude: 42.693554, longitude: -90.111658, zipCode: "53530"},
	{latitude: 43.05689, longitude: -89.099536, zipCode: "53531"},
	{latitude: 43.249682, longitude: -89.326691, zipCode: "53532"},
	{latitude: 42.99682, longitude: -90.162324, zipCode: "53533"},
	{latitude: 42.860823, longitude: -89.09331, zipCode: "53534"},
	{latitude: 42.761233, longitude: -89.267862, zipCode: "53536"},
	{latitude: 42.672679, longitude: -89.210601, zipCode: "53537"},
	{latitude: 42.908935, longitude: -88.871563, zipCode: "53538"},
	{latitude: 43.221829, longitude: -90.29361, zipCode: "53540"},
	{latitude: 42.569708, longitude: -90.029663, zipCode: "53541"},
	{latitude: 43.057568, longitude: -90.34546, zipCode: "53543"},
	{latitude: 42.87756, longitude: -89.905663, zipCode: "53544"},
	{latitude: 42.738735, longitude: -89.040224, zipCode: "53545"},
	{latitude: 42.652244, longitude: -88.948166, zipCode: "53546"},
	{latitude: 42.689322, longitude: -89.131287, zipCode: "53548"},
	{latitude: 42.984485, longitude: -88.765584, zipCode: "53549"},
	{latitude: 42.552842, longitude: -89.49393, zipCode: "53550"},
	{latitude: 43.079479, longitude: -88.916098, zipCode: "53551"},
	{latitude: 42.912324, longitude: -90.296958, zipCode: "53553"},
	{latitude: 42.908694, longitude: -90.440667, zipCode: "53554"},
	{latitude: 43.320154, longitude: -89.555784, zipCode: "53555"},
	{latitude: 43.238115, longitude: -90.245515, zipCode: "53556"},
	{latitude: 43.338949, longitude: -88.797661, zipCode: "53557"},
	{latitude: 42.997128, longitude: -89.275481, zipCode: "53558"},
	{latitude: 43.165573, longitude: -89.081901, zipCode: "53559"},
	{latitude: 43.192255, longitude: -89.737666, zipCode: "53560"},
	{latitude: 43.399407, longitude: -89.651691, zipCode: "53561"},
	{latitude: 43.115952, longitude: -89.543473, zipCode: "53562"},
	{latitude: 42.780889, longitude: -88.933538, zipCode: "53563"},
	{latitude: 42.837485, longitude: -90.166593, zipCode: "53565"},
	{latitude: 42.609146, longitude: -89.649429, zipCode: "53566"},
	{latitude: 42.988991, longitude: -90.438937, zipCode: "53569"},
	{latitude: 42.731054, longitude: -89.61958, zipCode: "53570"},
	{latitude: 43.277906, longitude: -89.353641, zipCode: "53571"},
	{latitude: 42.960808, longitude: -89.750087, zipCode: "53572"},
	{latitude: 43.180877, longitude: -90.459279, zipCode: "53573"},
	{latitude: 42.818517, longitude: -89.651825, zipCode: "53574"},
	{latitude: 42.931609, longitude: -89.387082, zipCode: "53575"},
	{latitude: 42.625738, longitude: -89.233212, zipCode: "53576"},
	{latitude: 43.311608, longitude: -90.064349, zipCode: "53577"},
	{latitude: 43.311092, longitude: -89.768881, zipCode: "53578"},
	{latitude: 43.295981, longitude: -88.867735, zipCode: "53579"},
	{latitude: 42.859439, longitude: -90.381301, zipCode: "53580"},
	{latitude: 43.376738, longitude: -90.419474, zipCode: "53581"},
	{latitude: 43.018374, longitude: -89.978797, zipCode: "53582"},
	{latitude: 43.251453, longitude: -89.813919, zipCode: "53583"},
	{latitude: 43.279367, longitude: -90.294675, zipCode: "53584"},
	{latitude: 42.530454, longitude: -88.720641, zipCode: "53585"},
	{latitude: 42.572731, longitude: -90.227024, zipCode: "53586"},
	{latitude: 42.580307, longitude: -89.904145, zipCode: "53587"},
	{latitude: 43.189163, longitude: -90.080481, zipCode: "53588"},
	{latitude: 42.928255, longitude: -89.215237, zipCode: "53589"},
	{latitude: 43.19989, longitude: -89.207148, zipCode: "53590"},
	{latitude: 42.985198, longitude: -89.579337, zipCode: "53593"},
	{latitude: 43.183316, longitude: -88.971228, zipCode: "53594"},
	{latitude: 43.18141, longitude: -89.457373, zipCode: "53597"},
	{latitude: 43.209737, longitude: -89.336532, zipCode: "53598"},
	{latitude: 42.647308, longitude: -89.857206, zipCode: "53599"},
	{latitude: 43.079607, longitude: -89.378423, zipCode: "53703"},
	{latitude: 43.135832, longitude: -89.34748, zipCode: "53704"},
	{latitude: 43.074014, longitude: -89.460509, zipCode: "53705"},
	{latitude: 43.075022, longitude: -89.409969, zipCode: "53706"},
	{latitude: 43.011281, longitude: -89.40931, zipCode: "53711"},
	{latitude: 43.036981, longitude: -89.393248, zipCode: "53713"},
	{latitude: 43.100348, longitude: -89.311966, zipCode: "53714"},
	{latitude: 43.05561, longitude: -89.387808, zipCode: "53715"},
	{latitude: 43.065149, longitude: -89.315015, zipCode: "53716"},
	{latitude: 43.067686, longitude: -89.521056, zipCode: "53717"},
	{latitude: 43.097019, longitude: -89.274603, zipCode: "53718"},
	{latitude: 43.047533, longitude: -89.506263, zipCode: "53719"},
	{latitude: 43.070699, longitude: -89.421896, zipCode: "53726"},
	{latitude: 43.077166, longitude: -89.430412, zipCode: "53792"},
	{latitude: 42.924833, longitude: -91.07456, zipCode: "53801"},
	{latitude: 42.805457, longitude: -90.893602, zipCode: "53802"},
	{latitude: 42.559139, longitude: -90.355078, zipCode: "53803"},
	{latitude: 42.876456, longitude: -90.907982, zipCode: "53804"},
	{latitude: 43.142976, longitude: -90.683004, zipCode: "53805"},
	{latitude: 42.729852, longitude: -90.919845, zipCode: "53806"},
	{latitude: 42.616835, longitude: -90.472701, zipCode: "53807"},
	{latitude: 42.637432, longitude: -90.59206, zipCode: "53808"},
	{latitude: 42.993622, longitude: -90.633235, zipCode: "53809"},
	{latitude: 42.819851, longitude: -90.997889, zipCode: "53810"},
	{latitude: 42.541192, longitude: -90.525122, zipCode: "53811"},
	{latitude: 42.580805, longitude: -90.602421, zipCode: "53812"},
	{latitude: 42.843965, longitude: -90.716982, zipCode: "53813"},
	{latitude: 42.977951, longitude: -90.857728, zipCode: "53816"},
	{latitude: 42.940288, longitude: -90.972343, zipCode: "53817"},
	{latitude: 42.7457, longitude: -90.492321, zipCode: "53818"},
	{latitude: 42.699772, longitude: -90.701468, zipCode: "53820"},
	{latitude: 43.049076, longitude: -91.057334, zipCode: "53821"},
	{latitude: 42.91834, longitude: -90.588504, zipCode: "53825"},
	{latitude: 43.113736, longitude: -90.924658, zipCode: "53826"},
	{latitude: 43.045865, longitude: -90.820473, zipCode: "53827"},
	{latitude: 43.560907, longitude: -89.483147, zipCode: "53901"},
	{latitude: 43.886678, longitude: -89.807748, zipCode: "53910"},
	{latitude: 43.325818, longitude: -89.367503, zipCode: "53911"},
	{latitude: 43.492773, longitude: -89.72873, zipCode: "53913"},
	{latitude: 43.455976, longitude: -88.848515, zipCode: "53916"},
	{latitude: 43.733861, longitude: -88.776014, zipCode: "53919"},
	{latitude: 43.670723, longitude: -89.604598, zipCode: "53920"},
	{latitude: 43.52093, longitude: -88.715147, zipCode: "53922"},
	{latitude: 43.574139, longitude: -89.138363, zipCode: "53923"},
	{latitude: 43.483411, longitude: -90.261729, zipCode: "53924"},
	{latitude: 43.326703, longitude: -89.057148, zipCode: "53925"},
	{latitude: 43.666882, longitude: -89.222839, zipCode: "53926"},
	{latitude: 43.418098, longitude: -89.155032, zipCode: "53928"},
	{latitude: 43.75661, longitude: -90.290996, zipCode: "53929"},
	{latitude: 43.685212, longitude: -89.498144, zipCode: "53930"},
	{latitude: 43.742542, longitude: -88.866939, zipCode: "53931"},
	{latitude: 43.422362, longitude: -89.055328, zipCode: "53932"},
	{latitude: 43.584046, longitude: -88.875214, zipCode: "53933"},
	{latitude: 43.979489, longitude: -89.823633, zipCode: "53934"},
	{latitude: 43.587632, longitude: -89.06555, zipCode: "53935"},
	{latitude: 43.868893, longitude: -89.707306, zipCode: "53936"},
	{latitude: 43.386885, longitude: -90.145424, zipCode: "53937"},
	{latitude: 43.690381, longitude: -89.138839, zipCode: "53939"},
	{latitude: 43.591278, longitude: -89.794431, zipCode: "53940"},
	{latitude: 43.580149, longitude: -90.135776, zipCode: "53941"},
	{latitude: 43.463955, longitude: -90.165331, zipCode: "53942"},
	{latitude: 43.386819, longitude: -90.035254, zipCode: "53943"},
	{latitude: 43.688416, longitude: -89.930181, zipCode: "53944"},
	{latitude: 43.719381, longitude: -89.021662, zipCode: "53946"},
	{latitude: 43.746768, longitude: -89.137671, zipCode: "53947"},
	{latitude: 43.776559, longitude: -90.040249, zipCode: "53948"},
	{latitude: 43.782615, longitude: -89.339644, zipCode: "53949"},
	{latitude: 43.901156, longitude: -90.137798, zipCode: "53950"},
	{latitude: 43.38793, longitude: -89.876247, zipCode: "53951"},
	{latitude: 43.784927, longitude: -89.58969, zipCode: "53952"},
	{latitude: 43.766096, longitude: -89.457916, zipCode: "53953"},
	{latitude: 43.55154, longitude: -89.313911, zipCode: "53954"},
	{latitude: 43.399878, longitude: -89.39869, zipCode: "53955"},
	{latitude: 43.542019, longitude: -89.017566, zipCode: "53956"},
	{latitude: 43.533502, longitude: -89.997349, zipCode: "53959"},
	{latitude: 43.426085, longitude: -89.235375, zipCode: "53960"},
	{latitude: 43.463686, longitude: -89.944341, zipCode: "53961"},
	{latitude: 43.68353, longitude: -90.264986, zipCode: "53962"},
	{latitude: 43.634671, longitude: -88.737589, zipCode: "53963"},
	{latitude: 43.912582, longitude: -89.512502, zipCode: "53964"},
	{latitude: 43.671422, longitude: -89.748134, zipCode: "53965"},
	{latitude: 43.635315, longitude: -90.235854, zipCode: "53968"},
	{latitude: 43.495662, longitude: -89.300282, zipCode: "53969"},
	{latitude: 45.33433, longitude: -92.386607, zipCode: "54001"},
	{latitude: 44.959627, longitude: -92.371857, zipCode: "54002"},
	{latitude: 44.783102, longitude: -92.446405, zipCode: "54003"},
	{latitude: 45.30222, longitude: -92.125177, zipCode: "54004"},
	{latitude: 45.228602, longitude: -92.230101, zipCode: "54005"},
	{latitude: 45.590505, longitude: -92.646687, zipCode: "54006"},
	{latitude: 45.190329, longitude: -92.35294, zipCode: "54007"},
	{latitude: 45.352521, longitude: -92.577819, zipCode: "54009"},
	{latitude: 44.718292, longitude: -92.464706, zipCode: "54011"},
	{latitude: 45.08295, longitude: -92.228166, zipCode: "54013"},
	{latitude: 44.648704, longitude: -92.572769, zipCode: "54014"},
	{latitude: 44.970815, longitude: -92.453163, zipCode: "54015"},
	{latitude: 44.981214, longitude: -92.694785, zipCode: "54016"},
	{latitude: 45.120019, longitude: -92.51979, zipCode: "54017"},
	{latitude: 45.27476, longitude: -92.662039, zipCode: "54020"},
	{latitude: 44.734183, longitude: -92.706307, zipCode: "54021"},
	{latitude: 44.846857, longitude: -92.611118, zipCode: "54022"},
	{latitude: 44.974227, longitude: -92.546904, zipCode: "54023"},
	{latitude: 45.5017, longitude: -92.640733, zipCode: "54024"},
	{latitude: 45.143751, longitude: -92.680358, zipCode: "54025"},
	{latitude: 45.227579, longitude: -92.53474, zipCode: "54026"},
	{latitude: 44.924026, longitude: -92.199858, zipCode: "54027"},
	{latitude: 44.954968, longitude: -92.283413, zipCode: "54028"},
	{latitude: 45.069563, longitude: -92.7511, zipCode: "54082"},
	{latitude: 44.785785, longitude: -88.075082, zipCode: "54101"},
	{latitude: 45.498029, longitude: -88.014598, zipCode: "54102"},
	{latitude: 45.63688, longitude: -88.516296, zipCode: "54103"},
	{latitude: 45.4344, longitude: -88.271131, zipCode: "54104"},
	{latitude: 44.485462, longitude: -88.456588, zipCode: "54106"},
	{latitude: 44.700663, longitude: -88.456942, zipCode: "54107"},
	{latitude: 44.184885, longitude: -88.0819, zipCode: "54110"},
	{latitude: 44.812102, longitude: -88.380018, zipCode: "54111"},
	{latitude: 45.054691, longitude: -88.05484, zipCode: "54112"},
	{latitude: 44.261995, longitude: -88.309534, zipCode: "54113"},
	{latitude: 45.245414, longitude: -88.108864, zipCode: "54114"},
	{latitude: 44.405368, longitude: -88.08935, zipCode: "54115"},
	{latitude: 45.61198, longitude: -88.196344, zipCode: "54119"},
	{latitude: 45.803454, longitude: -88.569329, zipCode: "54120"},
	{latitude: 45.865638, longitude: -88.326971, zipCode: "54121"},
	{latitude: 44.208779, longitude: -88.155184, zipCode: "54123"},
	{latitude: 44.908023, longitude: -88.345233, zipCode: "54124"},
	{latitude: 45.579138, longitude: -88.401622, zipCode: "54125"},
	{latitude: 44.288933, longitude: -88.032469, zipCode: "54126"},
	{latitude: 44.796922, longitude: -88.268174, zipCode: "54127"},
	{latitude: 44.864838, longitude: -88.808757, zipCode: "54128"},
	{latitude: 44.125112, longitude: -88.199405, zipCode: "54129"},
	{latitude: 44.311017, longitude: -88.244079, zipCode: "54130"},
	{latitude: 44.907717, longitude: -88.626589, zipCode: "54135"},
	{latitude: 44.268219, longitude: -88.338535, zipCode: "54136"},
	{latitude: 44.75843, longitude: -88.256104, zipCode: "54137"},
	{latitude: 45.333606, longitude: -88.409689, zipCode: "54138"},
	{latitude: 44.956844, longitude: -88.075505, zipCode: "54139"},
	{latitude: 44.288433, longitude: -88.310552, zipCode: "54140"},
	{latitude: 44.728584, longitude: -87.998318, zipCode: "54141"},
	{latitude: 45.076169, longitude: -87.698833, zipCode: "54143"},
	{latitude: 45.211171, longitude: -88.443513, zipCode: "54149"},
	{latitude: 44.969198, longitude: -88.788175, zipCode: "54150"},
	{latitude: 45.725441, longitude: -87.988217, zipCode: "54151"},
	{latitude: 44.566466, longitude: -88.470528, zipCode: "54152"},
	{latitude: 44.898069, longitude: -87.91574, zipCode: "54153"},
	{latitude: 44.87369, longitude: -88.182068, zipCode: "54154"},
	{latitude: 44.527495, longitude: -88.200548, zipCode: "54155"},
	{latitude: 45.610359, longitude: -87.944169, zipCode: "54156"},
	{latitude: 45.048231, longitude: -87.8112, zipCode: "54157"},
	{latitude: 45.193355, longitude: -87.838849, zipCode: "54159"},
	{latitude: 44.118481, longitude: -88.101704, zipCode: "54160"},
	{latitude: 45.135186, longitude: -88.150639, zipCode: "54161"},
	{latitude: 44.66254, longitude: -88.270904, zipCode: "54162"},
	{latitude: 44.521215, longitude: -88.316334, zipCode: "54165"},
	{latitude: 44.778797, longitude: -88.634549, zipCode: "54166"},
	{latitude: 44.176686, longitude: -88.280739, zipCode: "54169"},
	{latitude: 44.536096, longitude: -88.57693, zipCode: "54170"},
	{latitude: 44.715231, longitude: -88.096472, zipCode: "54171"},
	{latitude: 44.640749, longitude: -88.014886, zipCode: "54173"},
	{latitude: 45.03449, longitude: -88.437377, zipCode: "54174"},
	{latitude: 45.304391, longitude: -88.62536, zipCode: "54175"},
	{latitude: 45.366107, longitude: -87.913138, zipCode: "54177"},
	{latitude: 44.330219, longitude: -88.178304, zipCode: "54180"},
	{latitude: 44.626823, longitude: -87.486614, zipCode: "54201"},
	{latitude: 45.087307, longitude: -87.140061, zipCode: "54202"},
	{latitude: 44.755474, longitude: -87.605318, zipCode: "54204"},
	{latitude: 44.61075, longitude: -87.632443, zipCode: "54205"},
	{latitude: 44.080075, longitude: -87.982256, zipCode: "54207"},
	{latitude: 44.363565, longitude: -87.799595, zipCode: "54208"},
	{latitude: 45.017946, longitude: -87.270394, zipCode: "54209"},
	{latitude: 45.246247, longitude: -87.035915, zipCode: "54210"},
	{latitude: 45.160359, longitude: -87.166369, zipCode: "54211"},
	{latitude: 45.109775, longitude: -87.209029, zipCode: "54212"},
	{latitude: 44.695162, longitude: -87.528228, zipCode: "54213"},
	{latitude: 44.19893, longitude: -87.714444, zipCode: "54214"},
	{latitude: 44.226774, longitude: -87.803316, zipCode: "54215"},
	{latitude: 44.438598, longitude: -87.569911, zipCode: "54216"},
	{latitude: 44.548118, longitude: -87.717899, zipCode: "54217"},
	{latitude: 44.091177, longitude: -87.724511, zipCode: "54220"},
	{latitude: 44.284115, longitude: -87.804799, zipCode: "54227"},
	{latitude: 44.278161, longitude: -87.650284, zipCode: "54228"},
	{latitude: 44.560061, longitude: -87.815517, zipCode: "54229"},
	{latitude: 44.159839, longitude: -87.91504, zipCode: "54230"},
	{latitude: 44.007345, longitude: -87.924787, zipCode: "54232"},
	{latitude: 45.181963, longitude: -87.10151, zipCode: "54234"},
	{latitude: 44.843613, longitude: -87.37279, zipCode: "54235"},
	{latitude: 44.215871, longitude: -87.594706, zipCode: "54241"},
	{latitude: 44.030924, longitude: -87.898061, zipCode: "54245"},
	{latitude: 45.373209, longitude: -86.899374, zipCode: "54246"},
	{latitude: 44.199973, longitude: -87.790095, zipCode: "54247"},
	{latitude: 44.480778, longitude: -88.016063, zipCode: "54301"},
	{latitude: 44.506657, longitude: -87.976285, zipCode: "54302"},
	{latitude: 44.543349, longitude: -88.047176, zipCode: "54303"},
	{latitude: 44.49279, longitude: -88.067301, zipCode: "54304"},
	{latitude: 44.470458, longitude: -88.036594, zipCode: "54307"},
	{latitude: 44.480319, longitude: -87.892082, zipCode: "54311"},
	{latitude: 44.583773, longitude: -88.115037, zipCode: "54313"},
	{latitude: 44.966821, longitude: -89.709421, zipCode: "54401"},
	{latitude: 45.018505, longitude: -89.525117, zipCode: "54403"},
	{latitude: 44.96753, longitude: -90.282268, zipCode: "54405"},
	{latitude: 44.412217, longitude: -89.30493, zipCode: "54406"},
	{latitude: 44.532194, longitude: -89.281769, zipCode: "54407"},
	{latitude: 45.025581, longitude: -89.323997, zipCode: "54408"},
	{latitude: 45.122181, longitude: -89.170872, zipCode: "54409"},
	{latitude: 44.542291, longitude: -90.030599, zipCode: "54410"},
	{latitude: 45.06256, longitude: -90.033418, zipCode: "54411"},
	{latitude: 44.663265, longitude: -89.974225, zipCode: "54412"},
	{latitude: 44.291454, longitude: -90.148197, zipCode: "54413"},
	{latitude: 44.959673, longitude: -89.176857, zipCode: "54414"},
	{latitude: 44.884118, longitude: -88.94842, zipCode: "54416"},
	{latitude: 45.027187, longitude: -89.654128, zipCode: "54417"},
	{latitude: 45.211795, longitude: -88.983032, zipCode: "54418"},
	{latitude: 44.610077, longitude: -90.350683, zipCode: "54420"},
	{latitude: 44.895191, longitude: -90.288689, zipCode: "54421"},
	{latitude: 44.977342, longitude: -90.445395, zipCode: "54422"},
	{latitude: 44.578892, longitude: -89.425918, zipCode: "54423"},
	{latitude: 45.286662, longitude: -89.192334, zipCode: "54424"},
	{latitude: 45.006782, longitude: -90.341083, zipCode: "54425"},
	{latitude: 44.911498, longitude: -89.996824, zipCode: "54426"},
	{latitude: 44.824616, longitude: -89.257133, zipCode: "54427"},
	{latitude: 45.443489, longitude: -89.161449, zipCode: "54428"},
	{latitude: 45.13608, longitude: -88.885701, zipCode: "54430"},
	{latitude: 45.226674, longitude: -90.812628, zipCode: "54433"},
	{latitude: 45.360468, longitude: -89.438197, zipCode: "54435"},
	{latitude: 44.534563, longitude: -90.427162, zipCode: "54436"},
	{latitude: 44.777208, longitude: -90.629136, zipCode: "54437"},
	{latitude: 44.807094, longitude: -89.390975, zipCode: "54440"},
	{latitude: 44.642011, longitude: -90.10604, zipCode: "54441"},
	{latitude: 45.358854, longitude: -89.65898, zipCode: "54442"},
	{latitude: 44.608922, longitude: -89.751725, zipCode: "54443"},
	{latitude: 44.747785, longitude: -90.466411, zipCode: "54446"},
	{latitude: 45.106647, longitude: -90.72209, zipCode: "54447"},
	{latitude: 44.933287, longitude: -89.830213, zipCode: "54448"},
	{latitude: 44.637705, longitude: -90.196801, zipCode: "54449"},
	{latitude: 45.003663, longitude: -89.041732, zipCode: "54450"},
	{latitude: 45.19739, longitude: -90.407651, zipCode: "54451"},
	{latitude: 45.217729, longitude: -89.793643, zipCode: "54452"},
	{latitude: 44.601983, longitude: -89.883974, zipCode: "54454"},
	{latitude: 44.766821, longitude: -89.684474, zipCode: "54455"},
	{latitude: 44.544396, longitude: -90.632781, zipCode: "54456"},
	{latitude: 44.229258, longitude: -89.897501, zipCode: "54457"},
	{latitude: 44.497118, longitude: -89.306142, zipCode: "54458"},
	{latitude: 45.412588, longitude: -90.237432, zipCode: "54459"},
	{latitude: 44.953422, longitude: -90.532895, zipCode: "54460"},
	{latitude: 45.392002, longitude: -89.007393, zipCode: "54462"},
	{latitude: 45.498652, longitude: -89.232818, zipCode: "54463"},
	{latitude: 45.054774, longitude: -89.01748, zipCode: "54464"},
	{latitude: 45.396263, longitude: -88.875744, zipCode: "54465"},
	{latitude: 44.385459, longitude: -90.327046, zipCode: "54466"},
	{latitude: 44.413919, longitude: -89.538901, zipCode: "54467"},
	{latitude: 44.345149, longitude: -89.874087, zipCode: "54469"},
	{latitude: 45.291895, longitude: -90.136696, zipCode: "54470"},
	{latitude: 44.930719, longitude: -89.424316, zipCode: "54471"},
	{latitude: 44.650645, longitude: -89.352368, zipCode: "54473"},
	{latitude: 44.873714, longitude: -89.61975, zipCode: "54474"},
	{latitude: 44.484609, longitude: -89.793126, zipCode: "54475"},
	{latitude: 44.88996, longitude: -89.520725, zipCode: "54476"},
	{latitude: 44.750611, longitude: -90.331989, zipCode: "54479"},
	{latitude: 45.05431, longitude: -90.269289, zipCode: "54480"},
	{latitude: 44.522502, longitude: -89.646137, zipCode: "54481"},
	{latitude: 44.550416, longitude: -89.509449, zipCode: "54482"},
	{latitude: 44.800275, longitude: -90.081667, zipCode: "54484"},
	{latitude: 45.393438, longitude: -89.220286, zipCode: "54485"},
	{latitude: 44.718048, longitude: -89.045227, zipCode: "54486"},
	{latitude: 45.510949, longitude: -89.744184, zipCode: "54487"},
	{latitude: 44.843146, longitude: -90.346204, zipCode: "54488"},
	{latitude: 44.460777, longitude: -90.003987, zipCode: "54489"},
	{latitude: 45.32538, longitude: -90.408626, zipCode: "54490"},
	{latitude: 45.227481, longitude: -88.749006, zipCode: "54491"},
	{latitude: 44.69676, longitude: -90.81072, zipCode: "54493"},
	{latitude: 44.340807, longitude: -89.735011, zipCode: "54494"},
	{latitude: 44.391655, longitude: -89.958705, zipCode: "54495"},
	{latitude: 45.035112, longitude: -90.629022, zipCode: "54498"},
	{latitude: 44.789831, longitude: -89.176405, zipCode: "54499"},
	{latitude: 45.657615, longitude: -89.349239, zipCode: "54501"},
	{latitude: 45.737476, longitude: -88.823136, zipCode: "54511"},
	{latitude: 46.091151, longitude: -89.659025, zipCode: "54512"},
	{latitude: 45.578372, longitude: -90.141255, zipCode: "54513"},
	{latitude: 46.032511, longitude: -90.495824, zipCode: "54514"},
	{latitude: 45.512603, longitude: -90.491098, zipCode: "54515"},
	{latitude: 46.121051, longitude: -90.934042, zipCode: "54517"},
	{latitude: 46.054749, longitude: -89.263568, zipCode: "54519"},
	{latitude: 45.54124, longitude: -88.9329, zipCode: "54520"},
	{latitude: 45.916231, longitude: -89.254165, zipCode: "54521"},
	{latitude: 45.828177, longitude: -90.392415, zipCode: "54524"},
	{latitude: 46.434358, longitude: -90.225507, zipCode: "54525"},
	{latitude: 45.529271, longitude: -90.85718, zipCode: "54526"},
	{latitude: 46.126172, longitude: -90.640325, zipCode: "54527"},
	{latitude: 45.677711, longitude: -89.667165, zipCode: "54529"},
	{latitude: 45.529066, longitude: -90.715445, zipCode: "54530"},
	{latitude: 45.752823, longitude: -89.774652, zipCode: "54531"},
	{latitude: 46.352058, longitude: -90.22372, zipCode: "54534"},
	{latitude: 46.347698, longitude: -90.338071, zipCode: "54536"},
	{latitude: 45.533815, longitude: -90.602234, zipCode: "54537"},
	{latitude: 45.979627, longitude: -89.926581, zipCode: "54538"},
	{latitude: 45.809175, longitude: -89.577404, zipCode: "54539"},
	{latitude: 46.154791, longitude: -89.372738, zipCode: "54540"},
	{latitude: 45.553631, longitude: -88.662073, zipCode: "54541"},
	{latitude: 45.922608, longitude: -88.745342, zipCode: "54542"},
	{latitude: 46.133995, longitude: -89.854518, zipCode: "54545"},
	{latitude: 46.287313, longitude: -90.748955, zipCode: "54546"},
	{latitude: 46.21159, longitude: -90.10617, zipCode: "54547"},
	{latitude: 45.866014, longitude: -89.831735, zipCode: "54548"},
	{latitude: 46.347845, longitude: -90.259437, zipCode: "54550"},
	{latitude: 45.929782, longitude: -90.292821, zipCode: "54552"},
	{latitude: 46.060032, longitude: -89.064052, zipCode: "54554"},
	{latitude: 45.768047, longitude: -90.330476, zipCode: "54555"},
	{latitude: 45.53473, longitude: -90.317067, zipCode: "54556"},
	{latitude: 46.211775, longitude: -89.747843, zipCode: "54557"},
	{latitude: 45.925125, longitude: -89.499382, zipCode: "54558"},
	{latitude: 46.495208, longitude: -90.49123, zipCode: "54559"},
	{latitude: 46.036677, longitude: -89.552305, zipCode: "54560"},
	{latitude: 46.06915, longitude: -89.426527, zipCode: "54561"},
	{latitude: 45.802655, longitude: -89.079344, zipCode: "54562"},
	{latitude: 45.460162, longitude: -90.970548, zipCode: "54563"},
	{latitude: 45.604132, longitude: -89.961429, zipCode: "54564"},
	{latitude: 46.284828, longitude: -90.465513, zipCode: "54565"},
	{latitude: 45.428346, longitude: -88.630658, zipCode: "54566"},
	{latitude: 45.960879, longitude: -89.668172, zipCode: "54568"},
	{latitude: 43.806243, longitude: -91.140632, zipCode: "54601"},
	{latitude: 43.857742, longitude: -91.251775, zipCode: "54603"},
	{latitude: 44.365117, longitude: -91.831714, zipCode: "54610"},
	{latitude: 44.446225, longitude: -90.951582, zipCode: "54611"},
	{latitude: 44.249594, longitude: -91.501513, zipCode: "54612"},
	{latitude: 44.075585, longitude: -89.923744, zipCode: "54613"},
	{latitude: 43.904941, longitude: -90.952842, zipCode: "54614"},
	{latitude: 44.266352, longitude: -90.784217, zipCode: "54615"},
	{latitude: 44.311465, longitude: -91.213854, zipCode: "54616"},
	{latitude: 43.963629, longitude: -90.285854, zipCode: "54618"},
	{latitude: 43.7575, longitude: -90.774231, zipCode: "54619"},
	{latitude: 43.646508, longitude: -91.082311, zipCode: "54621"},
	{latitude: 44.273429, longitude: -91.773972, zipCode: "54622"},
	{latitude: 43.72924, longitude: -91.027212, zipCode: "54623"},
	{latitude: 43.461506, longitude: -91.131141, zipCode: "54624"},
	{latitude: 44.132865, longitude: -91.525052, zipCode: "54625"},
	{latitude: 43.219662, longitude: -91.054076, zipCode: "54626"},
	{latitude: 44.171454, longitude: -91.226764, zipCode: "54627"},
	{latitude: 43.372994, longitude: -91.026177, zipCode: "54628"},
	{latitude: 44.174054, longitude: -91.63934, zipCode: "54629"},
	{latitude: 44.122749, longitude: -91.373028, zipCode: "54630"},
	{latitude: 43.288563, longitude: -90.833269, zipCode: "54631"},
	{latitude: 43.561085, longitude: -91.146932, zipCode: "54632"},
	{latitude: 43.581681, longitude: -90.422391, zipCode: "54634"},
	{latitude: 44.402904, longitude: -91.045552, zipCode: "54635"},
	{latitude: 44.003164, longitude: -91.223953, zipCode: "54636"},
	{latitude: 43.878745, longitude: -90.270778, zipCode: "54637"},
	{latitude: 43.785642, longitude: -90.405061, zipCode: "54638"},
	{latitude: 43.610809, longitude: -90.599646, zipCode: "54639"},
	{latitude: 44.152076, longitude: -91.044944, zipCode: "54642"},
	{latitude: 44.18818, longitude: -90.63237, zipCode: "54643"},
	{latitude: 44.036084, longitude: -91.063408, zipCode: "54644"},
	{latitude: 43.320799, longitude: -90.929039, zipCode: "54645"},
	{latitude: 44.082822, longitude: -90.092968, zipCode: "54646"},
	{latitude: 43.836918, longitude: -90.6345, zipCode: "54648"},
	{latitude: 43.909405, longitude: -91.224829, zipCode: "54650"},
	{latitude: 43.72304, longitude: -90.566398, zipCode: "54651"},
	{latitude: 43.475689, longitude: -90.772333, zipCode: "54652"},
	{latitude: 43.821366, longitude: -90.926922, zipCode: "54653"},
	{latitude: 43.268539, longitude: -90.954198, zipCode: "54654"},
	{latitude: 43.370959, longitude: -90.759061, zipCode: "54655"},
	{latitude: 43.990087, longitude: -90.80319, zipCode: "54656"},
	{latitude: 43.199351, longitude: -90.909688, zipCode: "54657"},
	{latitude: 43.682596, longitude: -91.187099, zipCode: "54658"},
	{latitude: 44.28706, longitude: -91.096274, zipCode: "54659"},
	{latitude: 43.985009, longitude: -90.505437, zipCode: "54660"},
	{latitude: 44.057603, longitude: -91.467828, zipCode: "54661"},
	{latitude: 43.48833, longitude: -90.642904, zipCode: "54664"},
	{latitude: 43.531978, longitude: -90.928599, zipCode: "54665"},
	{latitude: 44.170133, longitude: -90.449668, zipCode: "54666"},
	{latitude: 43.666366, longitude: -90.864779, zipCode: "54667"},
	{latitude: 43.911169, longitude: -91.083953, zipCode: "54669"},
	{latitude: 43.837992, longitude: -90.482723, zipCode: "54670"},
	{latitude: 44.751984, longitude: -91.514998, zipCode: "54701"},
	{latitude: 44.834611, longitude: -91.520448, zipCode: "54703"},
	{latitude: 44.805042, longitude: -91.437019, zipCode: "54720"},
	{latitude: 44.633744, longitude: -92.082731, zipCode: "54721"},
	{latitude: 44.711812, longitude: -91.096003, zipCode: "54722"},
	{latitude: 44.610586, longitude: -92.419425, zipCode: "54723"},
	{latitude: 45.107813, longitude: -91.484409, zipCode: "54724"},
	{latitude: 45.073194, longitude: -91.990915, zipCode: "54725"},
	{latitude: 44.937235, longitude: -91.025904, zipCode: "54726"},
	{latitude: 44.953953, longitude: -91.159107, zipCode: "54727"},
	{latitude: 45.30795, longitude: -91.637762, zipCode: "54728"},
	{latitude: 44.946356, longitude: -91.394524, zipCode: "54729"},
	{latitude: 45.018518, longitude: -91.719608, zipCode: "54730"},
	{latitude: 45.349837, longitude: -91.090398, zipCode: "54731"},
	{latitude: 45.14463, longitude: -91.170488, zipCode: "54732"},
	{latitude: 45.27866, longitude: -91.850182, zipCode: "54733"},
	{latitude: 45.093283, longitude: -92.114129, zipCode: "54734"},
	{latitude: 44.612868, longitude: -91.924613, zipCode: "54736"},
	{latitude: 44.71841, longitude: -91.981626, zipCode: "54737"},
	{latitude: 44.588292, longitude: -91.488813, zipCode: "54738"},
	{latitude: 44.859783, longitude: -91.701162, zipCode: "54739"},
	{latitude: 44.751437, longitude: -92.166807, zipCode: "54740"},
	{latitude: 44.610234, longitude: -90.980075, zipCode: "54741"},
	{latitude: 44.762783, longitude: -91.272041, zipCode: "54742"},
	{latitude: 45.251132, longitude: -91.123401, zipCode: "54745"},
	{latitude: 44.547856, longitude: -90.86441, zipCode: "54746"},
	{latitude: 44.394958, longitude: -91.500452, zipCode: "54747"},
	{latitude: 45.075083, longitude: -91.26302, zipCode: "54748"},
	{latitude: 44.951199, longitude: -92.0991, zipCode: "54749"},
	{latitude: 44.635286, longitude: -92.284954, zipCode: "54750"},
	{latitude: 44.85063, longitude: -91.933502, zipCode: "54751"},
	{latitude: 44.426436, longitude: -90.773969, zipCode: "54754"},
	{latitude: 44.575754, longitude: -91.681293, zipCode: "54755"},
	{latitude: 44.468071, longitude: -91.939604, zipCode: "54756"},
	{latitude: 45.237197, longitude: -91.522349, zipCode: "54757"},
	{latitude: 44.557919, longitude: -91.216522, zipCode: "54758"},
	{latitude: 44.497878, longitude: -92.12093, zipCode: "54759"},
	{latitude: 44.424142, longitude: -91.206127, zipCode: "54760"},
	{latitude: 44.623236, longitude: -92.1783, zipCode: "54761"},
	{latitude: 45.256474, longitude: -91.996707, zipCode: "54762"},
	{latitude: 45.180248, longitude: -91.875269, zipCode: "54763"},
	{latitude: 45.340738, longitude: -90.857989, zipCode: "54766"},
	{latitude: 44.8299, longitude: -92.255237, zipCode: "54767"},
	{latitude: 44.96712, longitude: -90.927085, zipCode: "54768"},
	{latitude: 44.509543, longitude: -92.227439, zipCode: "54769"},
	{latitude: 44.55195, longitude: -91.373065, zipCode: "54770"},
	{latitude: 44.92394, longitude: -90.80674, zipCode: "54771"},
	{latitude: 45.081011, longitude: -91.887397, zipCode: "54772"},
	{latitude: 44.390016, longitude: -91.2964, zipCode: "54773"},
	{latitude: 45.863919, longitude: -91.937464, zipCode: "54801"},
	{latitude: 45.412059, longitude: -92.023537, zipCode: "54805"},
	{latitude: 46.551759, longitude: -90.845656, zipCode: "54806"},
	{latitude: 45.457218, longitude: -92.388369, zipCode: "54810"},
	{latitude: 45.392467, longitude: -91.882622, zipCode: "54812"},
	{latitude: 45.657437, longitude: -92.022462, zipCode: "54813"},
	{latitude: 46.86331, longitude: -90.901205, zipCode: "54814"},
	{latitude: 45.676433, longitude: -91.532367, zipCode: "54817"},
	{latitude: 45.475775, longitude: -91.302138, zipCode: "54819"},
	{latitude: 46.556557, longitude: -91.562013, zipCode: "54820"},
	{latitude: 46.216971, longitude: -91.166187, zipCode: "54821"},
	{latitude: 45.400937, longitude: -91.694413, zipCode: "54822"},
	{latitude: 45.484715, longitude: -92.539887, zipCode: "54824"},
	{latitude: 45.496504, longitude: -92.176817, zipCode: "54826"},
	{latitude: 46.801174, longitude: -91.111594, zipCode: "54827"},
	{latitude: 45.840978, longitude: -91.294249, zipCode: "54828"},
	{latitude: 45.566779, longitude: -92.075066, zipCode: "54829"},
	{latitude: 46.106014, longitude: -92.225683, zipCode: "54830"},
	{latitude: 46.319287, longitude: -91.343441, zipCode: "54832"},
	{latitude: 45.679838, longitude: -91.249937, zipCode: "54835"},
	{latitude: 46.454438, longitude: -92.195041, zipCode: "54836"},
	{latitude: 45.674284, longitude: -92.357709, zipCode: "54837"},
	{latitude: 46.249103, longitude: -91.7944, zipCode: "54838"},
	{latitude: 46.356374, longitude: -91.150715, zipCode: "54839"},
	{latitude: 45.751262, longitude: -92.692383, zipCode: "54840"},
	{latitude: 45.607593, longitude: -91.778588, zipCode: "54841"},
	{latitude: 46.490793, longitude: -91.871965, zipCode: "54842"},
	{latitude: 46.023604, longitude: -91.293027, zipCode: "54843"},
	{latitude: 46.781465, longitude: -91.215472, zipCode: "54844"},
	{latitude: 45.803543, longitude: -92.144504, zipCode: "54845"},
	{latitude: 46.378316, longitude: -90.720238, zipCode: "54846"},
	{latitude: 46.579326, longitude: -91.39985, zipCode: "54847"},
	{latitude: 45.516039, longitude: -91.103822, zipCode: "54848"},
	{latitude: 46.482245, longitude: -91.71671, zipCode: "54849"},
	{latitude: 46.940645, longitude: -90.57083, zipCode: "54850"},
	{latitude: 45.576404, longitude: -92.458671, zipCode: "54853"},
	{latitude: 46.65038, longitude: -91.667167, zipCode: "54854"},
	{latitude: 46.382631, longitude: -90.82752, zipCode: "54855"},
	{latitude: 46.432392, longitude: -91.109905, zipCode: "54856"},
	{latitude: 45.593407, longitude: -91.599298, zipCode: "54857"},
	{latitude: 45.519382, longitude: -92.457844, zipCode: "54858"},
	{latitude: 46.126055, longitude: -91.848834, zipCode: "54859"},
	{latitude: 46.598934, longitude: -90.643311, zipCode: "54861"},
	{latitude: 45.807575, longitude: -91.118161, zipCode: "54862"},
	{latitude: 46.602234, longitude: -91.801441, zipCode: "54864"},
	{latitude: 46.756, longitude: -91.374842, zipCode: "54865"},
	{latitude: 45.821701, longitude: -91.203121, zipCode: "54867"},
	{latitude: 45.527349, longitude: -91.716991, zipCode: "54868"},
	{latitude: 45.70926, longitude: -91.775273, zipCode: "54870"},
	{latitude: 45.749634, longitude: -92.002023, zipCode: "54871"},
	{latitude: 45.77231, longitude: -92.391514, zipCode: "54872"},
	{latitude: 46.346345, longitude: -91.702229, zipCode: "54873"},
	{latitude: 46.571798, longitude: -91.938747, zipCode: "54874"},
	{latitude: 45.973507, longitude: -91.693054, zipCode: "54875"},
	{latitude: 45.827511, longitude: -91.501096, zipCode: "54876"},
	{latitude: 46.590874, longitude: -92.12831, zipCode: "54880"},
	{latitude: 45.974807, longitude: -91.866892, zipCode: "54888"},
	{latitude: 45.415113, longitude: -92.170107, zipCode: "54889"},
	{latitude: 46.697766, longitude: -90.971168, zipCode: "54891"},
	{latitude: 45.878684, longitude: -92.325978, zipCode: "54893"},
	{latitude: 45.449432, longitude: -91.46076, zipCode: "54895"},
	{latitude: 45.806223, longitude: -90.883546, zipCode: "54896"},
	{latitude: 44.062759, longitude: -88.535773, zipCode: "54901"},
	{latitude: 43.946895, longitude: -88.540285, zipCode: "54902"},
	{latitude: 44.0234, longitude: -88.623839, zipCode: "54904"},
	{latitude: 44.280704, longitude: -89.367404, zipCode: "54909"},
	{latitude: 44.281533, longitude: -88.381003, zipCode: "54911"},
	{latitude: 44.335996, longitude: -88.39997, zipCode: "54913"},
	{latitude: 44.267634, longitude: -88.499046, zipCode: "54914"},
	{latitude: 44.242088, longitude: -88.361084, zipCode: "54915"},
	{latitude: 44.295741, longitude: -89.579301, zipCode: "54921"},
	{latitude: 44.548638, longitude: -88.742749, zipCode: "54922"},
	{latitude: 43.99772, longitude: -88.9668, zipCode: "54923"},
	{latitude: 44.615607, longitude: -89.020239, zipCode: "54926"},
	{latitude: 44.103128, longitude: -88.654369, zipCode: "54927"},
	{latitude: 44.752702, longitude: -88.879209, zipCode: "54928"},
	{latitude: 44.640938, longitude: -88.739982, zipCode: "54929"},
	{latitude: 44.03264, longitude: -89.554799, zipCode: "54930"},
	{latitude: 44.270225, longitude: -88.668244, zipCode: "54931"},
	{latitude: 43.840339, longitude: -88.61944, zipCode: "54932"},
	{latitude: 44.658611, longitude: -88.71404, zipCode: "54933"},
	{latitude: 43.775457, longitude: -88.432601, zipCode: "54935"},
	{latitude: 43.724107, longitude: -88.398163, zipCode: "54937"},
	{latitude: 44.22037, longitude: -88.842561, zipCode: "54940"},
	{latitude: 43.844703, longitude: -88.992882, zipCode: "54941"},
	{latitude: 44.299013, longitude: -88.542674, zipCode: "54942"},
	{latitude: 44.122562, longitude: -89.572665, zipCode: "54943"},
	{latitude: 44.324687, longitude: -88.626956, zipCode: "54944"},
	{latitude: 44.568636, longitude: -89.136826, zipCode: "54945"},
	{latitude: 44.337097, longitude: -89.145114, zipCode: "54946"},
	{latitude: 44.203594, longitude: -88.698749, zipCode: "54947"},
	{latitude: 44.776062, longitude: -88.885647, zipCode: "54948"},
	{latitude: 44.494046, longitude: -88.91261, zipCode: "54949"},
	{latitude: 44.657215, longitude: -88.918084, zipCode: "54950"},
	{latitude: 44.202041, longitude: -88.339418, zipCode: "54952"},
	{latitude: 44.182337, longitude: -88.527647, zipCode: "54956"},
	{latitude: 43.941733, longitude: -89.218999, zipCode: "54960"},
	{latitude: 44.410204, longitude: -88.761688, zipCode: "54961"},
	{latitude: 44.486188, longitude: -89.030971, zipCode: "54962"},
	{latitude: 44.045644, longitude: -88.76728, zipCode: "54963"},
	{latitude: 43.915614, longitude: -88.741945, zipCode: "54964"},
	{latitude: 44.167973, longitude: -89.043887, zipCode: "54965"},
	{latitude: 44.210102, longitude: -89.50478, zipCode: "54966"},
	{latitude: 44.12674, longitude: -88.975792, zipCode: "54967"},
	{latitude: 43.84693, longitude: -89.136064, zipCode: "54968"},
	{latitude: 44.072763, longitude: -89.098173, zipCode: "54970"},
	{latitude: 43.855118, longitude: -88.833583, zipCode: "54971"},
	{latitude: 43.781841, longitude: -88.652136, zipCode: "54974"},
	{latitude: 44.461176, longitude: -89.171211, zipCode: "54977"},
	{latitude: 44.794239, longitude: -88.914001, zipCode: "54978"},
	{latitude: 43.872535, longitude: -88.536399, zipCode: "54979"},
	{latitude: 43.986125, longitude: -88.765941, zipCode: "54980"},
	{latitude: 44.322675, longitude: -89.131481, zipCode: "54981"},
	{latitude: 44.069753, longitude: -89.292831, zipCode: "54982"},
	{latitude: 44.316862, longitude: -88.935165, zipCode: "54983"},
	{latitude: 44.190566, longitude: -89.223457, zipCode: "54984"},
	{latitude: 44.075638, longitude: -88.519717, zipCode: "54985"},
	{latitude: 44.117492, longitude: -88.747427, zipCode: "54986"},
	{latitude: 44.898776, longitude: -92.819108, zipCode: "55001"},
	{latitude: 45.007476, longitude: -92.779064, zipCode: "55003"},
	{latitude: 45.396107, longitude: -93.218464, zipCode: "55005"},
	{latitude: 45.738266, longitude: -93.198939, zipCode: "55006"},
	{latitude: 45.971293, longitude: -93.117849, zipCode: "55007"},
	{latitude: 45.569488, longitude: -93.276632, zipCode: "55008"},
	{latitude: 44.480564, longitude: -92.871085, zipCode: "55009"},
	{latitude: 45.344423, longitude: -93.252018, zipCode: "55011"},
	{latitude: 45.437981, longitude: -92.788354, zipCode: "55012"},
	{latitude: 45.351756, longitude: -92.8875, zipCode: "55013"},
	{latitude: 45.168349, longitude: -93.123752, zipCode: "55014"},
	{latitude: 44.816735, longitude: -92.93373, zipCode: "55016"},
	{latitude: 45.671279, longitude: -93.437683, zipCode: "55017"},
	{latitude: 44.414834, longitude: -92.984184, zipCode: "55018"},
	{latitude: 44.411004, longitude: -93.250262, zipCode: "55019"},
	{latitude: 44.578265, longitude: -93.374344, zipCode: "55020"},
	{latitude: 44.297513, longitude: -93.273914, zipCode: "55021"},
	{latitude: 44.628726, longitude: -93.135594, zipCode: "55024"},
	{latitude: 45.260352, longitude: -93.016529, zipCode: "55025"},
	{latitude: 44.521428, longitude: -92.336214, zipCode: "55026"},
	{latitude: 44.406342, longitude: -92.609391, zipCode: "55027"},
	{latitude: 45.635165, longitude: -93.20119, zipCode: "55029"},
	{latitude: 45.848274, longitude: -93.120168, zipCode: "55030"},
	{latitude: 44.612517, longitude: -92.962163, zipCode: "55031"},
	{latitude: 45.590313, longitude: -92.993037, zipCode: "55032"},
	{latitude: 44.711007, longitude: -92.858662, zipCode: "55033"},
	{latitude: 45.871291, longitude: -93.119702, zipCode: "55036"},
	{latitude: 46.007713, longitude: -92.79298, zipCode: "55037"},
	{latitude: 45.17339, longitude: -92.981181, zipCode: "55038"},
	{latitude: 45.468531, longitude: -93.277925, zipCode: "55040"},
	{latitude: 44.392813, longitude: -92.333885, zipCode: "55041"},
	{latitude: 44.9883, longitude: -92.892415, zipCode: "55042"},
	{latitude: 44.940575, longitude: -92.768851, zipCode: "55043"},
	{latitude: 44.642146, longitude: -93.276446, zipCode: "55044"},
	{latitude: 45.395738, longitude: -92.828189, zipCode: "55045"},
	{latitude: 44.442784, longitude: -93.410436, zipCode: "55046"},
	{latitude: 45.200429, longitude: -92.820729, zipCode: "55047"},
	{latitude: 44.17663, longitude: -93.238, zipCode: "55049"},
	{latitude: 45.936644, longitude: -93.339442, zipCode: "55051"},
	{latitude: 44.233327, longitude: -93.440444, zipCode: "55052"},
	{latitude: 44.349416, longitude: -93.065445, zipCode: "55053"},
	{latitude: 44.571531, longitude: -93.350679, zipCode: "55054"},
	{latitude: 44.874399, longitude: -92.998647, zipCode: "55055"},
	{latitude: 45.509277, longitude: -92.960967, zipCode: "55056"},
	{latitude: 44.472652, longitude: -93.179125, zipCode: "55057"},
	{latitude: 44.059703, longitude: -93.227333, zipCode: "55060"},
	{latitude: 45.838574, longitude: -92.902898, zipCode: "55063"},
	{latitude: 44.550722, longitude: -93.024936, zipCode: "55065"},
	{latitude: 44.511927, longitude: -92.539225, zipCode: "55066"},
	{latitude: 44.735478, longitude: -93.062774, zipCode: "55068"},
	{latitude: 45.700335, longitude: -92.972173, zipCode: "55069"},
	{latitude: 45.406224, longitude: -93.303323, zipCode: "55070"},
	{latitude: 44.821362, longitude: -92.99838, zipCode: "55071"},
	{latitude: 46.142704, longitude: -92.584273, zipCode: "55072"},
	{latitude: 45.276262, longitude: -92.830353, zipCode: "55073"},
	{latitude: 45.387778, longitude: -92.720049, zipCode: "55074"},
	{latitude: 44.88799, longitude: -93.041731, zipCode: "55075"},
	{latitude: 44.832866, longitude: -93.033244, zipCode: "55076"},
	{latitude: 44.81792, longitude: -93.077035, zipCode: "55077"},
	{latitude: 45.419467, longitude: -93.035393, zipCode: "55079"},
	{latitude: 45.66642, longitude: -93.222225, zipCode: "55080"},
	{latitude: 45.07442, longitude: -92.841577, zipCode: "55082"},
	{latitude: 45.462591, longitude: -92.716225, zipCode: "55084"},
	{latitude: 44.669691, longitude: -92.963426, zipCode: "55085"},
	{latitude: 44.236212, longitude: -93.409214, zipCode: "55087"},
	{latitude: 44.523586, longitude: -93.383455, zipCode: "55088"},
	{latitude: 44.59325, longitude: -92.709064, zipCode: "55089"},
	{latitude: 45.053731, longitude: -92.957987, zipCode: "55090"},
	{latitude: 45.334054, longitude: -93.099884, zipCode: "55092"},
	{latitude: 44.950764, longitude: -93.087571, zipCode: "55101"},
	{latitude: 44.931901, longitude: -93.121291, zipCode: "55102"},
	{latitude: 44.964258, longitude: -93.122627, zipCode: "55103"},
	{latitude: 44.953894, longitude: -93.164446, zipCode: "55104"},
	{latitude: 44.934707, longitude: -93.165751, zipCode: "55105"},
	{latitude: 44.957236, longitude: -93.047129, zipCode: "55106"},
	{latitude: 44.93107, longitude: -93.079172, zipCode: "55107"},
	{latitude: 44.982322, longitude: -93.174657, zipCode: "55108"},
	{latitude: 45.016634, longitude: -93.026716, zipCode: "55109"},
	{latitude: 45.089937, longitude: -93.004046, zipCode: "55110"},
	{latitude: 44.890094, longitude: -93.190164, zipCode: "55111"},
	{latitude: 45.080514, longitude: -93.19206, zipCode: "55112"},
	{latitude: 45.012196, longitude: -93.151186, zipCode: "55113"},
	{latitude: 44.96669, longitude: -93.195072, zipCode: "55114"},
	{latitude: 45.066802, longitude: -92.949458, zipCode: "55115"},
	{latitude: 44.910719, longitude: -93.169581, zipCode: "55116"},
	{latitude: 45.003562, longitude: -93.09128, zipCode: "55117"},
	{latitude: 44.894315, longitude: -93.100947, zipCode: "55118"},
	{latitude: 44.939808, longitude: -93.006254, zipCode: "55119"},
	{latitude: 44.875979, longitude: -93.150649, zipCode: "55120"},
	{latitude: 44.845873, longitude: -93.159565, zipCode: "55121"},
	{latitude: 44.800972, longitude: -93.195445, zipCode: "55122"},
	{latitude: 44.80239, longitude: -93.139126, zipCode: "55123"},
	{latitude: 44.745301, longitude: -93.199413, zipCode: "55124"},
	{latitude: 44.918031, longitude: -92.938277, zipCode: "55125"},
	{latitude: 45.082989, longitude: -93.140492, zipCode: "55126"},
	{latitude: 45.081572, longitude: -93.076928, zipCode: "55127"},
	{latitude: 44.987546, longitude: -92.963553, zipCode: "55128"},
	{latitude: 44.883474, longitude: -92.892768, zipCode: "55129"},
	{latitude: 44.973302, longitude: -93.08241, zipCode: "55130"},
	{latitude: 44.887411, longitude: -93.161007, zipCode: "55150"},
	{latitude: 44.951919, longitude: -93.099363, zipCode: "55155"},
	{latitude: 45.244364, longitude: -93.661168, zipCode: "55301"},
	{latitude: 45.246516, longitude: -94.117125, zipCode: "55302"},
	{latitude: 45.288412, longitude: -93.431529, zipCode: "55303"},
	{latitude: 45.245585, longitude: -93.259952, zipCode: "55304"},
	{latitude: 44.956346, longitude: -93.428092, zipCode: "55305"},
	{latitude: 44.730944, longitude: -93.291381, zipCode: "55306"},
	{latitude: 44.599706, longitude: -94.111216, zipCode: "55307"},
	{latitude: 45.413703, longitude: -93.835316, zipCode: "55308"},
	{latitude: 45.379166, longitude: -93.732936, zipCode: "55309"},
	{latitude: 44.755904, longitude: -94.876309, zipCode: "55310"},
	{latitude: 45.106403, longitude: -93.497029, zipCode: "55311"},
	{latitude: 44.70634, longitude: -94.35129, zipCode: "55312"},
	{latitude: 45.17409, longitude: -93.848952, zipCode: "55313"},
	{latitude: 44.776384, longitude: -94.607125, zipCode: "55314"},
	{latitude: 44.71469, longitude: -93.691863, zipCode: "55315"},
	{latitude: 45.172932, longitude: -93.386829, zipCode: "55316"},
	{latitude: 44.85671, longitude: -93.550567, zipCode: "55317"},
	{latitude: 44.810787, longitude: -93.636252, zipCode: "55318"},
	{latitude: 45.471792, longitude: -93.95229, zipCode: "55319"},
	{latitude: 45.396331, longitude: -94.086052, zipCode: "55320"},
	{latitude: 45.087568, longitude: -94.19314, zipCode: "55321"},
	{latitude: 44.771886, longitude: -93.787124, zipCode: "55322"},
	{latitude: 45.053776, longitude: -94.433007, zipCode: "55324"},
	{latitude: 45.084868, longitude: -94.316956, zipCode: "55325"},
	{latitude: 45.204315, longitude: -93.468761, zipCode: "55327"},
	{latitude: 45.033416, longitude: -93.813687, zipCode: "55328"},
	{latitude: 45.304542, longitude: -94.558937, zipCode: "55329"},
	{latitude: 45.332212, longitude: -93.566528, zipCode: "55330"},
	{latitude: 44.901254, longitude: -93.609953, zipCode: "55331"},
	{latitude: 44.520417, longitude: -94.699494, zipCode: "55332"},
	{latitude: 44.562955, longitude: -94.860631, zipCode: "55333"},
	{latitude: 44.535651, longitude: -94.188415, zipCode: "55334"},
	{latitude: 44.5311, longitude: -94.553136, zipCode: "55335"},
	{latitude: 44.765194, longitude: -94.190404, zipCode: "55336"},
	{latitude: 44.778472, longitude: -93.272269, zipCode: "55337"},
	{latitude: 44.674788, longitude: -94.000669, zipCode: "55338"},
	{latitude: 44.723428, longitude: -93.95696, zipCode: "55339"},
	{latitude: 45.078263, longitude: -93.572118, zipCode: "55340"},
	{latitude: 45.157755, longitude: -93.66275, zipCode: "55341"},
	{latitude: 44.74292, longitude: -94.739002, zipCode: "55342"},
	{latitude: 44.914372, longitude: -93.416254, zipCode: "55343"},
	{latitude: 44.863838, longitude: -93.430008, zipCode: "55344"},
	{latitude: 44.915442, longitude: -93.48443, zipCode: "55345"},
	{latitude: 44.878622, longitude: -93.481287, zipCode: "55346"},
	{latitude: 44.827986, longitude: -93.462382, zipCode: "55347"},
	{latitude: 45.06225, longitude: -94.074566, zipCode: "55349"},
	{latitude: 44.897378, longitude: -94.392695, zipCode: "55350"},
	{latitude: 44.651475, longitude: -93.592608, zipCode: "55352"},
	{latitude: 45.329098, longitude: -94.324629, zipCode: "55353"},
	{latitude: 44.886898, longitude: -94.064307, zipCode: "55354"},
	{latitude: 45.090934, longitude: -94.559835, zipCode: "55355"},
	{latitude: 44.995899, longitude: -93.585463, zipCode: "55356"},
	{latitude: 45.103722, longitude: -93.674218, zipCode: "55357"},
	{latitude: 45.244875, longitude: -93.997675, zipCode: "55358"},
	{latitude: 45.007347, longitude: -93.692613, zipCode: "55359"},
	{latitude: 44.91596, longitude: -93.919645, zipCode: "55360"},
	{latitude: 45.300334, longitude: -93.847481, zipCode: "55362"},
	{latitude: 45.03782, longitude: -93.91814, zipCode: "55363"},
	{latitude: 44.939325, longitude: -93.675835, zipCode: "55364"},
	{latitude: 44.67235, longitude: -94.234581, zipCode: "55366"},
	{latitude: 44.895242, longitude: -93.976028, zipCode: "55367"},
	{latitude: 44.740063, longitude: -93.899747, zipCode: "55368"},
	{latitude: 45.127366, longitude: -93.4492, zipCode: "55369"},
	{latitude: 44.78412, longitude: -94.0346, zipCode: "55370"},
	{latitude: 45.575584, longitude: -93.589164, zipCode: "55371"},
	{latitude: 44.682387, longitude: -93.413588, zipCode: "55372"},
	{latitude: 45.088208, longitude: -93.72159, zipCode: "55373"},
	{latitude: 45.175547, longitude: -93.575532, zipCode: "55374"},
	{latitude: 44.894592, longitude: -93.726038, zipCode: "55375"},
	{latitude: 45.210325, longitude: -93.682824, zipCode: "55376"},
	{latitude: 44.759057, longitude: -93.364213, zipCode: "55378"},
	{latitude: 44.743442, longitude: -93.510032, zipCode: "55379"},
	{latitude: 44.930963, longitude: -94.199979, zipCode: "55381"},
	{latitude: 45.300423, longitude: -94.199089, zipCode: "55382"},
	{latitude: 44.935926, longitude: -93.63011, zipCode: "55384"},
	{latitude: 44.736135, longitude: -94.486465, zipCode: "55385"},
	{latitude: 44.856644, longitude: -93.664138, zipCode: "55386"},
	{latitude: 44.861222, longitude: -93.784034, zipCode: "55387"},
	{latitude: 44.954831, longitude: -93.848665, zipCode: "55388"},
	{latitude: 45.297919, longitude: -94.442626, zipCode: "55389"},
	{latitude: 45.067871, longitude: -93.979016, zipCode: "55390"},
	{latitude: 44.964243, longitude: -93.543436, zipCode: "55391"},
	{latitude: 44.957797, longitude: -94.063408, zipCode: "55395"},
	{latitude: 44.544829, longitude: -94.3669, zipCode: "55396"},
	{latitude: 44.823801, longitude: -93.937964, zipCode: "55397"},
	{latitude: 45.458747, longitude: -93.589494, zipCode: "55398"},
	{latitude: 44.985367, longitude: -93.270208, zipCode: "55401"},
	{latitude: 44.976069, longitude: -93.271161, zipCode: "55402"},
	{latitude: 44.969923, longitude: -93.284253, zipCode: "55403"},
	{latitude: 44.961913, longitude: -93.261349, zipCode: "55404"},
	{latitude: 44.972674, longitude: -93.301878, zipCode: "55405"},
	{latitude: 44.938689, longitude: -93.221042, zipCode: "55406"},
	{latitude: 44.935036, longitude: -93.253029, zipCode: "55407"},
	{latitude: 44.943815, longitude: -93.293867, zipCode: "55408"},
	{latitude: 44.930178, longitude: -93.281459, zipCode: "55409"},
	{latitude: 44.913345, longitude: -93.316942, zipCode: "55410"},
	{latitude: 44.999752, longitude: -93.29798, zipCode: "55411"},
	{latitude: 45.027324, longitude: -93.303612, zipCode: "55412"},
	{latitude: 44.997814, longitude: -93.243546, zipCode: "55413"},
	{latitude: 44.978381, longitude: -93.222449, zipCode: "55414"},
	{latitude: 44.974644, longitude: -93.257815, zipCode: "55415"},
	{latitude: 44.949373, longitude: -93.340994, zipCode: "55416"},
	{latitude: 44.906117, longitude: -93.235387, zipCode: "55417"},
	{latitude: 45.021318, longitude: -93.242853, zipCode: "55418"},
	{latitude: 44.905799, longitude: -93.287653, zipCode: "55419"},
	{latitude: 44.835969, longitude: -93.277176, zipCode: "55420"},
	{latitude: 45.050574, longitude: -93.249937, zipCode: "55421"},
	{latitude: 45.009275, longitude: -93.341105, zipCode: "55422"},
	{latitude: 44.877193, longitude: -93.281359, zipCode: "55423"},
	{latitude: 44.905024, longitude: -93.344865, zipCode: "55424"},
	{latitude: 44.851169, longitude: -93.22608, zipCode: "55425"},
	{latitude: 44.956566, longitude: -93.381654, zipCode: "55426"},
	{latitude: 45.004595, longitude: -93.381351, zipCode: "55427"},
	{latitude: 45.064039, longitude: -93.383509, zipCode: "55428"},
	{latitude: 45.063659, longitude: -93.341175, zipCode: "55429"},
	{latitude: 45.063152, longitude: -93.299246, zipCode: "55430"},
	{latitude: 44.824534, longitude: -93.312602, zipCode: "55431"},
	{latitude: 45.096702, longitude: -93.253726, zipCode: "55432"},
	{latitude: 45.160411, longitude: -93.314839, zipCode: "55433"},
	{latitude: 45.163672, longitude: -93.250445, zipCode: "55434"},
	{latitude: 44.873981, longitude: -93.335255, zipCode: "55435"},
	{latitude: 44.907173, longitude: -93.375086, zipCode: "55436"},
	{latitude: 44.824117, longitude: -93.342225, zipCode: "55437"},
	{latitude: 44.823123, longitude: -93.386125, zipCode: "55438"},
	{latitude: 44.873551, longitude: -93.373048, zipCode: "55439"},
	{latitude: 45.008425, longitude: -93.430718, zipCode: "55441"},
	{latitude: 45.04892, longitude: -93.426212, zipCode: "55442"},
	{latitude: 45.119451, longitude: -93.338969, zipCode: "55443"},
	{latitude: 45.107682, longitude: -93.303205, zipCode: "55444"},
	{latitude: 45.122441, longitude: -93.379184, zipCode: "55445"},
	{latitude: 45.03914, longitude: -93.489701, zipCode: "55446"},
	{latitude: 45.001096, longitude: -93.489561, zipCode: "55447"},
	{latitude: 45.193603, longitude: -93.300445, zipCode: "55448"},
	{latitude: 45.175978, longitude: -93.184149, zipCode: "55449"},
	{latitude: 44.872084, longitude: -93.228993, zipCode: "55450"},
	{latitude: 44.973346, longitude: -93.243708, zipCode: "55454"},
	{latitude: 44.975487, longitude: -93.235337, zipCode: "55455"},
	{latitude: 47.235637, longitude: -91.365953, zipCode: "55601"},
	{latitude: 47.316208, longitude: -91.931835, zipCode: "55602"},
	{latitude: 47.550775, longitude: -91.206393, zipCode: "55603"},
	{latitude: 47.979098, longitude: -90.599609, zipCode: "55604"},
	{latitude: 47.952837, longitude: -89.660012, zipCode: "55605"},
	{latitude: 47.95979, longitude: -90.016378, zipCode: "55606"},
	{latitude: 47.621962, longitude: -91.500786, zipCode: "55607"},
	{latitude: 46.966501, longitude: -91.78876, zipCode: "55609"},
	{latitude: 47.787856, longitude: -90.705596, zipCode: "55612"},
	{latitude: 47.573365, longitude: -90.986359, zipCode: "55613"},
	{latitude: 47.345735, longitude: -91.382386, zipCode: "55614"},
	{latitude: 47.746544, longitude: -90.85118, zipCode: "55615"},
	{latitude: 47.251537, longitude: -91.691986, zipCode: "55616"},
	{latitude: 46.957364, longitude: -92.637569, zipCode: "55702"},
	{latitude: 47.73101, longitude: -92.750157, zipCode: "55703"},
	{latitude: 46.219085, longitude: -92.751667, zipCode: "55704"},
	{latitude: 47.47068, longitude: -92.267326, zipCode: "55705"},
	{latitude: 47.667051, longitude: -91.985485, zipCode: "55706"},
	{latitude: 46.551745, longitude: -92.636106, zipCode: "55707"},
	{latitude: 47.540312, longitude: -92.342174, zipCode: "55708"},
	{latitude: 47.402584, longitude: -93.372855, zipCode: "55709"},
	{latitude: 47.64984, longitude: -92.697364, zipCode: "55710"},
	{latitude: 46.818752, longitude: -92.690769, zipCode: "55711"},
	{latitude: 46.28175, longitude: -92.52058, zipCode: "55712"},
	{latitude: 47.487942, longitude: -92.76443, zipCode: "55713"},
	{latitude: 47.321718, longitude: -93.26434, zipCode: "55716"},
	{latitude: 47.068751, longitude: -92.387215, zipCode: "55717"},
	{latitude: 46.620931, longitude: -92.486403, zipCode: "55718"},
	{latitude: 47.541039, longitude: -92.86219, zipCode: "55719"},
	{latitude: 46.750977, longitude: -92.533373, zipCode: "55720"},
	{latitude: 47.22127, longitude: -93.683167, zipCode: "55721"},
	{latitude: 47.283261, longitude: -93.434435, zipCode: "55722"},
	{latitude: 47.840484, longitude: -92.857179, zipCode: "55723"},
	{latitude: 47.167253, longitude: -92.369033, zipCode: "55724"},
	{latitude: 48.350302, longitude: -92.530677, zipCode: "55725"},
	{latitude: 46.649595, longitude: -92.827156, zipCode: "55726"},
	{latitude: 47.996882, longitude: -91.753982, zipCode: "55731"},
	{latitude: 47.68792, longitude: -92.250653, zipCode: "55732"},
	{latitude: 46.713831, longitude: -92.360792, zipCode: "55733"},
	{latitude: 47.388596, longitude: -92.496846, zipCode: "55734"},
	{latitude: 46.210293, longitude: -93.010212, zipCode: "55735"},
	{latitude: 46.95686, longitude: -92.920606, zipCode: "55736"},
	{latitude: 47.26433, longitude: -92.660348, zipCode: "55738"},
	{latitude: 47.457221, longitude: -92.384961, zipCode: "55741"},
	{latitude: 47.171024, longitude: -93.144843, zipCode: "55742"},
	{latitude: 47.233766, longitude: -93.5126, zipCode: "55744"},
	{latitude: 47.335183, longitude: -92.954287, zipCode: "55746"},
	{latitude: 46.993656, longitude: -93.596353, zipCode: "55748"},
	{latitude: 46.439558, longitude: -92.443315, zipCode: "55749"},
	{latitude: 47.531432, longitude: -91.963808, zipCode: "55750"},
	{latitude: 47.389419, longitude: -92.67316, zipCode: "55751"},
	{latitude: 46.952155, longitude: -93.252996, zipCode: "55752"},
	{latitude: 47.396738, longitude: -93.080468, zipCode: "55753"},
	{latitude: 46.375388, longitude: -92.601355, zipCode: "55756"},
	{latitude: 46.526354, longitude: -92.947611, zipCode: "55757"},
	{latitude: 47.506036, longitude: -92.720175, zipCode: "55758"},
	{latitude: 46.642586, longitude: -93.257419, zipCode: "55760"},
	{latitude: 47.330498, longitude: -92.232393, zipCode: "55763"},
	{latitude: 47.332601, longitude: -93.291449, zipCode: "55764"},
	{latitude: 47.098901, longitude: -92.736191, zipCode: "55765"},
	{latitude: 46.448584, longitude: -92.773754, zipCode: "55767"},
	{latitude: 47.537842, longitude: -92.660603, zipCode: "55768"},
	{latitude: 47.479989, longitude: -93.18386, zipCode: "55769"},
	{latitude: 48.116756, longitude: -92.904198, zipCode: "55771"},
	{latitude: 48.110172, longitude: -93.100224, zipCode: "55772"},
	{latitude: 47.298619, longitude: -93.160503, zipCode: "55775"},
	{latitude: 46.908739, longitude: -92.449133, zipCode: "55779"},
	{latitude: 46.67613, longitude: -92.677707, zipCode: "55780"},
	{latitude: 47.680868, longitude: -93.065077, zipCode: "55781"},
	{latitude: 47.832953, longitude: -92.206005, zipCode: "55782"},
	{latitude: 46.412058, longitude: -92.917963, zipCode: "55783"},
	{latitude: 47.058391, longitude: -93.165281, zipCode: "55784"},
	{latitude: 46.921584, longitude: -93.731217, zipCode: "55785"},
	{latitude: 47.317896, longitude: -93.382848, zipCode: "55786"},
	{latitude: 46.721756, longitude: -93.108496, zipCode: "55787"},
	{latitude: 47.825233, longitude: -92.376129, zipCode: "55790"},
	{latitude: 47.597028, longitude: -92.479684, zipCode: "55792"},
	{latitude: 47.116305, longitude: -93.28397, zipCode: "55793"},
	{latitude: 46.298208, longitude: -92.882889, zipCode: "55795"},
	{latitude: 47.930019, longitude: -91.795004, zipCode: "55796"},
	{latitude: 46.5665, longitude: -92.362116, zipCode: "55797"},
	{latitude: 46.723206, longitude: -92.967784, zipCode: "55798"},
	{latitude: 46.764173, longitude: -92.082422, zipCode: "55802"},
	{latitude: 47.034891, longitude: -92.072208, zipCode: "55803"},
	{latitude: 46.931351, longitude: -91.936344, zipCode: "55804"},
	{latitude: 46.799685, longitude: -92.092366, zipCode: "55805"},
	{latitude: 46.768139, longitude: -92.127642, zipCode: "55806"},
	{latitude: 46.735485, longitude: -92.16794, zipCode: "55807"},
	{latitude: 46.678736, longitude: -92.237362, zipCode: "55808"},
	{latitude: 46.756745, longitude: -92.269437, zipCode: "55810"},
	{latitude: 46.83855, longitude: -92.222193, zipCode: "55811"},
	{latitude: 46.809362, longitude: -92.071786, zipCode: "55812"},
	{latitude: 46.835186, longitude: -92.197125, zipCode: "55814"},
	{latitude: 44.077657, longitude: -92.497063, zipCode: "55901"},
	{latitude: 43.971301, longitude: -92.507871, zipCode: "55902"},
	{latitude: 44.024073, longitude: -92.436529, zipCode: "55903"},
	{latitude: 43.95331, longitude: -92.401387, zipCode: "55904"},
	{latitude: 44.055948, longitude: -92.525906, zipCode: "55905"},
	{latitude: 44.099898, longitude: -92.408166, zipCode: "55906"},
	{latitude: 43.563125, longitude: -92.734624, zipCode: "55909"},
	{latitude: 44.12866, longitude: -91.971828, zipCode: "55910"},
	{latitude: 43.682607, longitude: -92.98845, zipCode: "55912"},
	{latitude: 43.88482, longitude: -93.075481, zipCode: "55917"},
	{latitude: 43.742365, longitude: -92.8467, zipCode: "55918"},
	{latitude: 43.620065, longitude: -91.299476, zipCode: "55919"},
	{latitude: 44.01242, longitude: -92.621101, zipCode: "55920"},
	{latitude: 43.620369, longitude: -91.475848, zipCode: "55921"},
	{latitude: 43.562105, longitude: -91.895571, zipCode: "55922"},
	{latitude: 43.849408, longitude: -92.183193, zipCode: "55923"},
	{latitude: 44.035571, longitude: -93.016111, zipCode: "55924"},
	{latitude: 43.912092, longitude: -91.458412, zipCode: "55925"},
	{latitude: 43.742744, longitude: -92.706901, zipCode: "55926"},
	{latitude: 44.033768, longitude: -92.873344, zipCode: "55927"},
	{latitude: 43.977606, longitude: -92.144093, zipCode: "55929"},
	{latitude: 43.508662, longitude: -91.385229, zipCode: "55931"},
	{latitude: 44.152205, longitude: -92.298336, zipCode: "55932"},
	{latitude: 43.647848, longitude: -92.685059, zipCode: "55933"},
	{latitude: 44.003839, longitude: -92.265751, zipCode: "55934"},
	{latitude: 43.747374, longitude: -92.127439, zipCode: "55935"},
	{latitude: 43.706444, longitude: -92.584793, zipCode: "55936"},
	{latitude: 43.546844, longitude: -92.08147, zipCode: "55939"},
	{latitude: 43.898614, longitude: -92.809489, zipCode: "55940"},
	{latitude: 43.724845, longitude: -91.349148, zipCode: "55941"},
	{latitude: 43.782961, longitude: -91.582681, zipCode: "55943"},
	{latitude: 43.989517, longitude: -92.718008, zipCode: "55944"},
	{latitude: 44.266873, longitude: -92.052504, zipCode: "55945"},
	{latitude: 44.262107, longitude: -92.950147, zipCode: "55946"},
	{latitude: 43.82289, longitude: -91.36636, zipCode: "55947"},
	{latitude: 43.702965, longitude: -91.929692, zipCode: "55949"},
	{latitude: 43.743455, longitude: -92.966283, zipCode: "55950"},
	{latitude: 43.529233, longitude: -92.482488, zipCode: "55951"},
	{latitude: 43.94393, longitude: -91.843354, zipCode: "55952"},
	{latitude: 43.527759, longitude: -92.965198, zipCode: "55953"},
	{latitude: 43.557061, longitude: -91.780106, zipCode: "55954"},
	{latitude: 44.08732, longitude: -92.74087, zipCode: "55955"},
	{latitude: 44.286019, longitude: -92.527603, zipCode: "55956"},
	{latitude: 44.253073, longitude: -92.296925, zipCode: "55957"},
	{latitude: 44.117944, longitude: -91.799533, zipCode: "55959"},
	{latitude: 44.140242, longitude: -92.535165, zipCode: "55960"},
	{latitude: 43.605396, longitude: -92.475415, zipCode: "55961"},
	{latitude: 43.762187, longitude: -91.845128, zipCode: "55962"},
	{latitude: 44.180829, longitude: -92.671135, zipCode: "55963"},
	{latitude: 44.160247, longitude: -92.158081, zipCode: "55964"},
	{latitude: 43.623194, longitude: -92.128187, zipCode: "55965"},
	{latitude: 43.793424, longitude: -92.513508, zipCode: "55967"},
	{latitude: 44.397037, longitude: -92.089941, zipCode: "55968"},
	{latitude: 44.082639, longitude: -91.876555, zipCode: "55969"},
	{latitude: 43.596762, longitude: -92.831223, zipCode: "55970"},
	{latitude: 43.819894, longitude: -91.75781, zipCode: "55971"},
	{latitude: 43.993925, longitude: -92.050909, zipCode: "55972"},
	{latitude: 43.807937, longitude: -92.771966, zipCode: "55973"},
	{latitude: 43.577137, longitude: -91.647987, zipCode: "55974"},
	{latitude: 43.677869, longitude: -92.364434, zipCode: "55975"},
	{latitude: 43.863814, longitude: -92.48404, zipCode: "55976"},
	{latitude: 43.553707, longitude: -92.647928, zipCode: "55977"},
	{latitude: 43.923054, longitude: -91.951337, zipCode: "55979"},
	{latitude: 44.35761, longitude: -92.049231, zipCode: "55981"},
	{latitude: 43.815788, longitude: -92.885837, zipCode: "55982"},
	{latitude: 44.291403, longitude: -92.806985, zipCode: "55983"},
	{latitude: 44.15822, longitude: -92.904395, zipCode: "55985"},
	{latitude: 43.994246, longitude: -91.625441, zipCode: "55987"},
	{latitude: 43.728463, longitude: -92.259118, zipCode: "55990"},
	{latitude: 44.261215, longitude: -92.418566, zipCode: "55991"},
	{latitude: 44.3228, longitude: -92.682884, zipCode: "55992"},
	{latitude: 44.128169, longitude: -93.978991, zipCode: "56001"},
	{latitude: 44.219273, longitude: -94.090329, zipCode: "56003"},
	{latitude: 43.660887, longitude: -93.324563, zipCode: "56007"},
	{latitude: 43.661833, longitude: -93.546405, zipCode: "56009"},
	{latitude: 43.886163, longitude: -94.179807, zipCode: "56010"},
	{latitude: 44.602461, longitude: -93.772619, zipCode: "56011"},
	{latitude: 43.62019, longitude: -94.103794, zipCode: "56013"},
	{latitude: 43.583158, longitude: -93.817898, zipCode: "56014"},
	{latitude: 43.762065, longitude: -93.343107, zipCode: "56016"},
	{latitude: 44.30535, longitude: -93.819535, zipCode: "56017"},
	{latitude: 44.115873, longitude: -94.888968, zipCode: "56019"},
	{latitude: 43.61458, longitude: -93.530173, zipCode: "56020"},
	{latitude: 44.289313, longitude: -94.316025, zipCode: "56021"},
	{latitude: 44.052625, longitude: -94.83861, zipCode: "56022"},
	{latitude: 43.771611, longitude: -94.012902, zipCode: "56023"},
	{latitude: 44.143552, longitude: -93.838858, zipCode: "56024"},
	{latitude: 43.753245, longitude: -93.917351, zipCode: "56025"},
	{latitude: 43.885765, longitude: -93.290995, zipCode: "56026"},
	{latitude: 43.516714, longitude: -94.116644, zipCode: "56027"},
	{latitude: 44.226257, longitude: -93.71742, zipCode: "56028"},
	{latitude: 43.517762, longitude: -93.536143, zipCode: "56029"},
	{latitude: 44.324821, longitude: -94.604835, zipCode: "56030"},
	{latitude: 43.626846, longitude: -94.462324, zipCode: "56031"},
	{latitude: 43.777124, longitude: -93.568742, zipCode: "56032"},
	{latitude: 43.56506, longitude: -93.938149, zipCode: "56033"},
	{latitude: 44.028598, longitude: -94.181962, zipCode: "56034"},
	{latitude: 43.819522, longitude: -93.272612, zipCode: "56035"},
	{latitude: 43.551882, longitude: -93.221023, zipCode: "56036"},
	{latitude: 44.011156, longitude: -94.038634, zipCode: "56037"},
	{latitude: 43.663418, longitude: -94.323586, zipCode: "56039"},
	{latitude: 44.161305, longitude: -94.533087, zipCode: "56041"},
	{latitude: 43.804093, longitude: -93.483859, zipCode: "56042"},
	{latitude: 43.649395, longitude: -93.222428, zipCode: "56043"},
	{latitude: 44.564707, longitude: -93.956838, zipCode: "56044"},
	{latitude: 43.759614, longitude: -93.209611, zipCode: "56045"},
	{latitude: 43.967814, longitude: -93.268871, zipCode: "56046"},
	{latitude: 44.094768, longitude: -93.713152, zipCode: "56048"},
	{latitude: 44.259143, longitude: -93.947348, zipCode: "56050"},
	{latitude: 43.536437, longitude: -93.70847, zipCode: "56051"},
	{latitude: 44.327066, longitude: -93.5587, zipCode: "56052"},
	{latitude: 44.435729, longitude: -94.370394, zipCode: "56054"},
	{latitude: 44.128492, longitude: -94.235283, zipCode: "56055"},
	{latitude: 44.07278, longitude: -94.579472, zipCode: "56056"},
	{latitude: 44.391251, longitude: -93.7221, zipCode: "56057"},
	{latitude: 44.44185, longitude: -93.914029, zipCode: "56058"},
	{latitude: 43.928735, longitude: -94.441065, zipCode: "56060"},
	{latitude: 44.048235, longitude: -94.413855, zipCode: "56062"},
	{latitude: 44.214892, longitude: -93.820513, zipCode: "56063"},
	{latitude: 43.93149, longitude: -93.908396, zipCode: "56065"},
	{latitude: 43.865306, longitude: -93.806142, zipCode: "56068"},
	{latitude: 44.412099, longitude: -93.542436, zipCode: "56069"},
	{latitude: 44.534963, longitude: -93.57945, zipCode: "56071"},
	{latitude: 43.899229, longitude: -93.517081, zipCode: "56072"},
	{latitude: 44.306837, longitude: -94.469485, zipCode: "56073"},
	{latitude: 44.326877, longitude: -94.196936, zipCode: "56074"},
	{latitude: 43.735161, longitude: -94.436621, zipCode: "56075"},
	{latitude: 44.005921, longitude: -93.760875, zipCode: "56078"},
	{latitude: 44.081722, longitude: -93.857876, zipCode: "56080"},
	{latitude: 43.992871, longitude: -94.634824, zipCode: "56081"},
	{latitude: 44.357287, longitude: -94.036965, zipCode: "56082"},
	{latitude: 44.208007, longitude: -95.134292, zipCode: "56083"},
	{latitude: 44.291376, longitude: -94.733572, zipCode: "56085"},
	{latitude: 44.240509, longitude: -94.975248, zipCode: "56087"},
	{latitude: 43.825427, longitude: -94.458131, zipCode: "56088"},
	{latitude: 43.567993, longitude: -93.457463, zipCode: "56089"},
	{latitude: 43.951314, longitude: -94.226965, zipCode: "56090"},
	{latitude: 43.914434, longitude: -93.686188, zipCode: "56091"},
	{latitude: 44.062566, longitude: -93.519762, zipCode: "56093"},
	{latitude: 44.244889, longitude: -93.603466, zipCode: "56096"},
	{latitude: 43.720968, longitude: -93.713282, zipCode: "56097"},
	{latitude: 43.793016, longitude: -94.181766, zipCode: "56098"},
	{latitude: 43.879726, longitude: -95.133825, zipCode: "56101"},
	{latitude: 43.622221, longitude: -95.945051, zipCode: "56110"},
	{latitude: 43.642681, longitude: -94.887167, zipCode: "56111"},
	{latitude: 44.404363, longitude: -96.1511, zipCode: "56113"},
	{latitude: 43.985864, longitude: -95.592498, zipCode: "56114"},
	{latitude: 44.209457, longitude: -95.905771, zipCode: "56115"},
	{latitude: 43.62379, longitude: -96.377012, zipCode: "56116"},
	{latitude: 43.523116, longitude: -95.687376, zipCode: "56117"},
	{latitude: 43.936028, longitude: -95.029368, zipCode: "56118"},
	{latitude: 43.713721, longitude: -95.462996, zipCode: "56119"},
	{latitude: 43.960117, longitude: -94.791747, zipCode: "56120"},
	{latitude: 43.534615, longitude: -94.584233, zipCode: "56121"},
	{latitude: 43.898445, longitude: -95.956, zipCode: "56122"},
	{latitude: 44.086937, longitude: -95.592414, zipCode: "56123"},
	{latitude: 44.054516, longitude: -95.547738, zipCode: "56125"},
	{latitude: 43.547487, longitude: -94.764716, zipCode: "56127"},
	{latitude: 43.881542, longitude: -96.110303, zipCode: "56128"},
	{latitude: 43.529203, longitude: -96.041896, zipCode: "56129"},
	{latitude: 43.855847, longitude: -95.572539, zipCode: "56131"},
	{latitude: 44.203451, longitude: -95.764941, zipCode: "56132"},
	{latitude: 43.789546, longitude: -96.231234, zipCode: "56134"},
	{latitude: 44.495189, longitude: -96.414125, zipCode: "56136"},
	{latitude: 43.822584, longitude: -95.30966, zipCode: "56137"},
	{latitude: 43.537043, longitude: -96.380224, zipCode: "56138"},
	{latitude: 44.103929, longitude: -96.179427, zipCode: "56139"},
	{latitude: 43.909432, longitude: -96.367168, zipCode: "56140"},
	{latitude: 43.886293, longitude: -95.803384, zipCode: "56141"},
	{latitude: 44.46764, longitude: -96.231042, zipCode: "56142"},
	{latitude: 43.627684, longitude: -95.002409, zipCode: "56143"},
	{latitude: 43.838593, longitude: -96.386803, zipCode: "56144"},
	{latitude: 44.055796, longitude: -95.173869, zipCode: "56145"},
	{latitude: 43.580062, longitude: -96.103432, zipCode: "56146"},
	{latitude: 43.757406, longitude: -96.057684, zipCode: "56147"},
	{latitude: 44.304157, longitude: -96.305564, zipCode: "56149"},
	{latitude: 43.628218, longitude: -95.197159, zipCode: "56150"},
	{latitude: 44.031451, longitude: -95.929422, zipCode: "56151"},
	{latitude: 44.227155, longitude: -95.281359, zipCode: "56152"},
	{latitude: 43.826383, longitude: -96.024232, zipCode: "56153"},
	{latitude: 43.765868, longitude: -95.946055, zipCode: "56155"},
	{latitude: 43.66151, longitude: -96.227229, zipCode: "56156"},
	{latitude: 44.404175, longitude: -95.948377, zipCode: "56157"},
	{latitude: 43.633815, longitude: -96.083931, zipCode: "56158"},
	{latitude: 43.943175, longitude: -94.937433, zipCode: "56159"},
	{latitude: 43.829148, longitude: -94.805151, zipCode: "56160"},
	{latitude: 43.707031, longitude: -95.324827, zipCode: "56161"},
	{latitude: 43.840329, longitude: -94.66735, zipCode: "56162"},
	{latitude: 44.053834, longitude: -96.333008, zipCode: "56164"},
	{latitude: 43.725343, longitude: -95.703057, zipCode: "56165"},
	{latitude: 44.203001, longitude: -95.380986, zipCode: "56166"},
	{latitude: 43.553927, longitude: -95.408728, zipCode: "56167"},
	{latitude: 43.609252, longitude: -95.799591, zipCode: "56168"},
	{latitude: 44.332055, longitude: -96.011097, zipCode: "56169"},
	{latitude: 44.16455, longitude: -96.095184, zipCode: "56170"},
	{latitude: 43.671285, longitude: -94.761789, zipCode: "56171"},
	{latitude: 44.014163, longitude: -95.775513, zipCode: "56172"},
	{latitude: 43.52506, longitude: -96.241864, zipCode: "56173"},
	{latitude: 44.016336, longitude: -95.305092, zipCode: "56174"},
	{latitude: 44.260701, longitude: -95.638506, zipCode: "56175"},
	{latitude: 43.783287, longitude: -94.720493, zipCode: "56176"},
	{latitude: 44.285664, longitude: -96.128091, zipCode: "56178"},
	{latitude: 44.246204, longitude: -95.459592, zipCode: "56180"},
	{latitude: 43.677795, longitude: -94.626602, zipCode: "56181"},
	{latitude: 44.029019, longitude: -95.431132, zipCode: "56183"},
	{latitude: 43.798534, longitude: -95.824238, zipCode: "56185"},
	{latitude: 44.025279, longitude: -96.077471, zipCode: "56186"},
	{latitude: 43.622795, longitude: -95.583202, zipCode: "56187"},
	{latitude: 45.102185, longitude: -95.041816, zipCode: "56201"},
	{latitude: 45.505918, longitude: -96.066571, zipCode: "56207"},
	{latitude: 45.246719, longitude: -96.00477, zipCode: "56208"},
	{latitude: 45.12725, longitude: -94.811967, zipCode: "56209"},
	{latitude: 45.557351, longitude: -96.559578, zipCode: "56210"},
	{latitude: 45.593133, longitude: -96.687979, zipCode: "56211"},
	{latitude: 45.130873, longitude: -96.323833, zipCode: "56212"},
	{latitude: 44.610477, longitude: -95.324368, zipCode: "56214"},
	{latitude: 45.317715, longitude: -95.571953, zipCode: "56215"},
	{latitude: 44.942501, longitude: -95.059379, zipCode: "56216"},
	{latitude: 44.826953, longitude: -95.940466, zipCode: "56218"},
	{latitude: 45.59729, longitude: -96.817874, zipCode: "56219"},
	{latitude: 44.726335, longitude: -96.291347, zipCode: "56220"},
	{latitude: 45.560013, longitude: -96.187081, zipCode: "56221"},
	{latitude: 44.985399, longitude: -95.356764, zipCode: "56222"},
	{latitude: 44.762144, longitude: -95.818773, zipCode: "56223"},
	{latitude: 44.375843, longitude: -95.068124, zipCode: "56224"},
	{latitude: 45.440973, longitude: -96.442682, zipCode: "56225"},
	{latitude: 45.40733, longitude: -95.678281, zipCode: "56226"},
	{latitude: 45.290841, longitude: -96.168828, zipCode: "56227"},
	{latitude: 44.951235, longitude: -94.685178, zipCode: "56228"},
	{latitude: 44.594036, longitude: -95.716384, zipCode: "56229"},
	{latitude: 44.792114, longitude: -95.088916, zipCode: "56230"},
	{latitude: 45.27951, longitude: -95.763212, zipCode: "56231"},
	{latitude: 44.92716, longitude: -96.03801, zipCode: "56232"},
	{latitude: 45.709727, longitude: -96.016509, zipCode: "56235"},
	{latitude: 45.664953, longitude: -96.396115, zipCode: "56236"},
	{latitude: 44.623589, longitude: -95.43109, zipCode: "56237"},
	{latitude: 44.504141, longitude: -95.910813, zipCode: "56239"},
	{latitude: 45.562563, longitude: -96.48365, zipCode: "56240"},
	{latitude: 44.797886, longitude: -95.572946, zipCode: "56241"},
	{latitude: 45.170236, longitude: -94.686485, zipCode: "56243"},
	{latitude: 45.477723, longitude: -95.780133, zipCode: "56244"},
	{latitude: 44.684045, longitude: -95.677295, zipCode: "56245"},
	{latitude: 45.78621, longitude: -96.135808, zipCode: "56248"},
	{latitude: 45.318731, longitude: -95.901564, zipCode: "56249"},
	{latitude: 45.143722, longitude: -94.920176, zipCode: "56251"},
	{latitude: 45.178253, longitude: -95.310123, zipCode: "56252"},
	{latitude: 44.95818, longitude: -94.866193, zipCode: "56253"},
	{latitude: 44.389516, longitude: -95.420958, zipCode: "56255"},
	{latitude: 45.016942, longitude: -96.212703, zipCode: "56256"},
	{latitude: 44.969258, longitude: -96.402151, zipCode: "56257"},
	{latitude: 44.436606, longitude: -95.746316, zipCode: "56258"},
	{latitude: 44.963275, longitude: -95.482997, zipCode: "56260"},
	{latitude: 45.115071, longitude: -95.869707, zipCode: "56262"},
	{latitude: 44.42149, longitude: -95.532478, zipCode: "56263"},
	{latitude: 44.586807, longitude: -95.981253, zipCode: "56264"},
	{latitude: 44.999823, longitude: -95.706482, zipCode: "56265"},
	{latitude: 44.421061, longitude: -94.914515, zipCode: "56266"},
	{latitude: 45.582463, longitude: -95.928793, zipCode: "56267"},
	{latitude: 44.559922, longitude: -94.965267, zipCode: "56270"},
	{latitude: 45.241158, longitude: -95.414434, zipCode: "56271"},
	{latitude: 45.317879, longitude: -94.979933, zipCode: "56273"},
	{latitude: 45.897893, longitude: -96.244534, zipCode: "56274"},
	{latitude: 45.240992, longitude: -96.307336, zipCode: "56276"},
	{latitude: 44.767422, longitude: -94.993117, zipCode: "56277"},
	{latitude: 45.348003, longitude: -96.379955, zipCode: "56278"},
	{latitude: 45.218831, longitude: -95.169843, zipCode: "56279"},
	{latitude: 44.632751, longitude: -96.165617, zipCode: "56280"},
	{latitude: 44.948323, longitude: -95.164811, zipCode: "56281"},
	{latitude: 45.02953, longitude: -95.223518, zipCode: "56282"},
	{latitude: 44.528006, longitude: -95.138872, zipCode: "56283"},
	{latitude: 44.773488, longitude: -95.212128, zipCode: "56284"},
	{latitude: 44.788327, longitude: -95.345197, zipCode: "56285"},
	{latitude: 44.475752, longitude: -95.326447, zipCode: "56287"},
	{latitude: 45.243115, longitude: -94.943643, zipCode: "56288"},
	{latitude: 45.333188, longitude: -95.224451, zipCode: "56289"},
	{latitude: 44.622612, longitude: -96.062063, zipCode: "56291"},
	{latitude: 44.502071, longitude: -95.456501, zipCode: "56292"},
	{latitude: 44.416511, longitude: -95.259903, zipCode: "56293"},
	{latitude: 44.314874, longitude: -95.213085, zipCode: "56294"},
	{latitude: 45.043139, longitude: -95.848874, zipCode: "56295"},
	{latitude: 45.82561, longitude: -96.477229, zipCode: "56296"},
	{latitude: 44.636795, longitude: -95.553607, zipCode: "56297"},
	{latitude: 45.487084, longitude: -94.242777, zipCode: "56301"},
	{latitude: 45.574017, longitude: -94.215271, zipCode: "56303"},
	{latitude: 45.53037, longitude: -94.057203, zipCode: "56304"},
	{latitude: 45.62681, longitude: -94.591029, zipCode: "56307"},
	{latitude: 45.867614, longitude: -95.394257, zipCode: "56308"},
	{latitude: 46.07926, longitude: -95.805971, zipCode: "56309"},
	{latitude: 45.633476, longitude: -94.451336, zipCode: "56310"},
	{latitude: 45.897453, longitude: -95.895579, zipCode: "56311"},
	{latitude: 45.4566, longitude: -94.969177, zipCode: "56312"},
	{latitude: 45.784022, longitude: -93.552745, zipCode: "56313"},
	{latitude: 45.814701, longitude: -94.432957, zipCode: "56314"},
	{latitude: 45.980142, longitude: -95.593376, zipCode: "56315"},
	{latitude: 45.505682, longitude: -95.142779, zipCode: "56316"},
	{latitude: 45.853038, longitude: -94.67453, zipCode: "56318"},
	{latitude: 46.010823, longitude: -95.215688, zipCode: "56319"},
	{latitude: 45.466777, longitude: -94.402803, zipCode: "56320"},
	{latitude: 45.579722, longitude: -94.391868, zipCode: "56321"},
	{latitude: 45.629632, longitude: -95.723921, zipCode: "56323"},
	{latitude: 46.16896, longitude: -95.904066, zipCode: "56324"},
	{latitude: 45.562902, longitude: -94.947199, zipCode: "56325"},
	{latitude: 46.039558, longitude: -95.670809, zipCode: "56326"},
	{latitude: 45.754477, longitude: -95.608226, zipCode: "56327"},
	{latitude: 45.951903, longitude: -94.521467, zipCode: "56328"},
	{latitude: 45.709067, longitude: -93.914815, zipCode: "56329"},
	{latitude: 45.753241, longitude: -93.756384, zipCode: "56330"},
	{latitude: 45.678998, longitude: -94.671722, zipCode: "56331"},
	{latitude: 45.967416, longitude: -95.506404, zipCode: "56332"},
	{latitude: 45.574381, longitude: -95.33678, zipCode: "56334"},
	{latitude: 45.60137, longitude: -94.859334, zipCode: "56335"},
	{latitude: 45.820725, longitude: -94.762557, zipCode: "56336"},
	{latitude: 46.002981, longitude: -93.834143, zipCode: "56338"},
	{latitude: 45.824978, longitude: -95.821158, zipCode: "56339"},
	{latitude: 45.759467, longitude: -94.429019, zipCode: "56340"},
	{latitude: 46.18381, longitude: -93.368969, zipCode: "56342"},
	{latitude: 45.796066, longitude: -95.713065, zipCode: "56343"},
	{latitude: 46.009996, longitude: -94.383791, zipCode: "56345"},
	{latitude: 45.970711, longitude: -94.860558, zipCode: "56347"},
	{latitude: 45.71822, longitude: -95.52014, zipCode: "56349"},
	{latitude: 46.305029, longitude: -93.230407, zipCode: "56350"},
	{latitude: 45.6418, longitude: -94.806359, zipCode: "56352"},
	{latitude: 45.803664, longitude: -93.629079, zipCode: "56353"},
	{latitude: 46.064044, longitude: -95.281737, zipCode: "56354"},
	{latitude: 45.941442, longitude: -95.245716, zipCode: "56355"},
	{latitude: 45.6336, longitude: -94.72762, zipCode: "56356"},
	{latitude: 45.691525, longitude: -93.798163, zipCode: "56357"},
	{latitude: 45.83798, longitude: -93.446139, zipCode: "56358"},
	{latitude: 46.099612, longitude: -93.66533, zipCode: "56359"},
	{latitude: 45.88734, longitude: -95.103613, zipCode: "56360"},
	{latitude: 46.160236, longitude: -95.332488, zipCode: "56361"},
	{latitude: 45.402031, longitude: -94.712237, zipCode: "56362"},
	{latitude: 45.69715, longitude: -93.652212, zipCode: "56363"},
	{latitude: 46.018654, longitude: -94.071943, zipCode: "56364"},
	{latitude: 45.763756, longitude: -94.182051, zipCode: "56367"},
	{latitude: 45.464267, longitude: -94.56116, zipCode: "56368"},
	{latitude: 45.469038, longitude: -94.335834, zipCode: "56369"},
	{latitude: 45.433334, longitude: -94.637167, zipCode: "56371"},
	{latitude: 45.85974, longitude: -94.230562, zipCode: "56373"},
	{latitude: 45.565877, longitude: -94.316571, zipCode: "56374"},
	{latitude: 45.701222, longitude: -94.274581, zipCode: "56375"},
	{latitude: 45.502461, longitude: -94.667468, zipCode: "56376"},
	{latitude: 45.64023, longitude: -94.226839, zipCode: "56377"},
	{latitude: 45.725034, longitude: -94.987283, zipCode: "56378"},
	{latitude: 45.639682, longitude: -94.082677, zipCode: "56379"},
	{latitude: 45.556817, longitude: -95.537775, zipCode: "56381"},
	{latitude: 45.907863, longitude: -94.598327, zipCode: "56382"},
	{latitude: 45.809944, longitude: -94.567527, zipCode: "56384"},
	{latitude: 45.68966, longitude: -95.21375, zipCode: "56385"},
	{latitude: 46.114439, longitude: -93.519502, zipCode: "56386"},
	{latitude: 45.520868, longitude: -94.242982, zipCode: "56387"},
	{latitude: 45.801276, longitude: -95.086381, zipCode: "56389"},
	{latitude: 46.322919, longitude: -94.12521, zipCode: "56401"},
	{latitude: 46.343935, longitude: -94.276015, zipCode: "56425"},
	{latitude: 46.49109, longitude: -93.635569, zipCode: "56431"},
	{latitude: 46.950669, longitude: -94.688275, zipCode: "56433"},
	{latitude: 46.337962, longitude: -94.934379, zipCode: "56434"},
	{latitude: 46.805864, longitude: -94.531858, zipCode: "56435"},
	{latitude: 46.245745, longitude: -95.058493, zipCode: "56437"},
	{latitude: 46.1386, longitude: -94.819179, zipCode: "56438"},
	{latitude: 46.141976, longitude: -94.955089, zipCode: "56440"},
	{latitude: 46.577763, longitude: -93.98148, zipCode: "56441"},
	{latitude: 46.678212, longitude: -94.102014, zipCode: "56442"},
	{latitude: 46.174026, longitude: -94.579512, zipCode: "56443"},
	{latitude: 46.435784, longitude: -93.888944, zipCode: "56444"},
	{latitude: 46.128838, longitude: -95.09749, zipCode: "56446"},
	{latitude: 46.731923, longitude: -93.883667, zipCode: "56447"},
	{latitude: 46.78812, longitude: -94.082154, zipCode: "56448"},
	{latitude: 46.178548, longitude: -94.261423, zipCode: "56449"},
	{latitude: 46.236874, longitude: -93.843027, zipCode: "56450"},
	{latitude: 46.957022, longitude: -94.448224, zipCode: "56452"},
	{latitude: 46.316002, longitude: -95.175374, zipCode: "56453"},
	{latitude: 46.440986, longitude: -94.000623, zipCode: "56455"},
	{latitude: 47.195795, longitude: -95.002022, zipCode: "56458"},
	{latitude: 47.232073, longitude: -94.853912, zipCode: "56461"},
	{latitude: 46.768824, longitude: -95.10417, zipCode: "56464"},
	{latitude: 46.537322, longitude: -94.11443, zipCode: "56465"},
	{latitude: 46.436002, longitude: -94.624955, zipCode: "56466"},
	{latitude: 46.974819, longitude: -94.830974, zipCode: "56467"},
	{latitude: 46.493254, longitude: -94.311291, zipCode: "56468"},
	{latitude: 46.781236, longitude: -93.566778, zipCode: "56469"},
	{latitude: 47.026894, longitude: -95.091886, zipCode: "56470"},
	{latitude: 46.582338, longitude: -94.340826, zipCode: "56472"},
	{latitude: 46.358254, longitude: -94.469836, zipCode: "56473"},
	{latitude: 46.723289, longitude: -94.396599, zipCode: "56474"},
	{latitude: 46.079719, longitude: -94.545992, zipCode: "56475"},
	{latitude: 46.644616, longitude: -95.000175, zipCode: "56477"},
	{latitude: 46.434608, longitude: -94.794542, zipCode: "56479"},
	{latitude: 46.463002, longitude: -94.93624, zipCode: "56481"},
	{latitude: 46.464284, longitude: -95.147694, zipCode: "56482"},
	{latitude: 47.111209, longitude: -94.482388, zipCode: "56484"},
	{latitude: 46.840111, longitude: -95.827361, zipCode: "56501"},
	{latitude: 47.35881, longitude: -96.524868, zipCode: "56510"},
	{latitude: 46.842476, longitude: -96.016346, zipCode: "56511"},
	{latitude: 46.64577, longitude: -96.431434, zipCode: "56514"},
	{latitude: 46.307322, longitude: -95.690808, zipCode: "56515"},
	{latitude: 47.444667, longitude: -95.971019, zipCode: "56516"},
	{latitude: 47.550084, longitude: -96.559409, zipCode: "56517"},
	{latitude: 46.490076, longitude: -95.250361, zipCode: "56518"},
	{latitude: 47.161941, longitude: -96.478978, zipCode: "56519"},
	{latitude: 46.28975, longitude: -96.497076, zipCode: "56520"},
	{latitude: 46.999299, longitude: -95.901808, zipCode: "56521"},
	{latitude: 46.134508, longitude: -96.389964, zipCode: "56522"},
	{latitude: 47.638349, longitude: -96.80865, zipCode: "56523"},
	{latitude: 46.216786, longitude: -95.615481, zipCode: "56524"},
	{latitude: 46.671573, longitude: -96.751935, zipCode: "56525"},
	{latitude: 46.372621, longitude: -95.314968, zipCode: "56527"},
	{latitude: 46.521354, longitude: -95.823814, zipCode: "56528"},
	{latitude: 46.878919, longitude: -96.696187, zipCode: "56529"},
	{latitude: 45.991594, longitude: -95.981567, zipCode: "56531"},
	{latitude: 46.398688, longitude: -96.1421, zipCode: "56533"},
	{latitude: 46.471585, longitude: -96.011412, zipCode: "56534"},
	{latitude: 47.677541, longitude: -96.041655, zipCode: "56535"},
	{latitude: 47.041467, longitude: -96.532866, zipCode: "56536"},
	{latitude: 46.276711, longitude: -96.091938, zipCode: "56537"},
	{latitude: 47.540347, longitude: -96.274227, zipCode: "56540"},
	{latitude: 47.17327, longitude: -96.141377, zipCode: "56541"},
	{latitude: 47.568077, longitude: -95.727783, zipCode: "56542"},
	{latitude: 46.285406, longitude: -96.336533, zipCode: "56543"},
	{latitude: 46.757786, longitude: -95.605437, zipCode: "56544"},
	{latitude: 47.397374, longitude: -96.243251, zipCode: "56545"},
	{latitude: 47.104877, longitude: -96.720074, zipCode: "56546"},
	{latitude: 46.889472, longitude: -96.553728, zipCode: "56547"},
	{latitude: 47.368272, longitude: -96.745362, zipCode: "56548"},
	{latitude: 46.847746, longitude: -96.312245, zipCode: "56549"},
	{latitude: 47.273933, longitude: -96.74306, zipCode: "56550"},
	{latitude: 46.318939, longitude: -95.454375, zipCode: "56551"},
	{latitude: 46.985199, longitude: -96.234112, zipCode: "56552"},
	{latitude: 46.429417, longitude: -96.617555, zipCode: "56553"},
	{latitude: 46.885536, longitude: -96.116088, zipCode: "56554"},
	{latitude: 47.662952, longitude: -95.879974, zipCode: "56556"},
	{latitude: 47.303709, longitude: -95.835947, zipCode: "56557"},
	{latitude: 46.835221, longitude: -96.734828, zipCode: "56560"},
	{latitude: 46.864506, longitude: -96.770166, zipCode: "56562"},
	{latitude: 46.866814, longitude: -96.755342, zipCode: "56563"},
	{latitude: 46.048626, longitude: -96.315132, zipCode: "56565"},
	{latitude: 47.245965, longitude: -95.604853, zipCode: "56566"},
	{latitude: 46.549791, longitude: -95.374425, zipCode: "56567"},
	{latitude: 47.535231, longitude: -96.783178, zipCode: "56568"},
	{latitude: 47.078388, longitude: -95.827042, zipCode: "56569"},
	{latitude: 46.917929, longitude: -95.367136, zipCode: "56570"},
	{latitude: 46.433809, longitude: -95.542709, zipCode: "56571"},
	{latitude: 46.605236, longitude: -96.084155, zipCode: "56572"},
	{latitude: 46.610235, longitude: -95.542244, zipCode: "56573"},
	{latitude: 47.198997, longitude: -96.705549, zipCode: "56574"},
	{latitude: 47.042407, longitude: -95.434841, zipCode: "56575"},
	{latitude: 46.475948, longitude: -95.739557, zipCode: "56576"},
	{latitude: 46.940894, longitude: -95.641496, zipCode: "56578"},
	{latitude: 46.463532, longitude: -96.338658, zipCode: "56579"},
	{latitude: 46.736062, longitude: -96.604014, zipCode: "56580"},
	{latitude: 47.462333, longitude: -96.761828, zipCode: "56581"},
	{latitude: 46.003328, longitude: -96.406589, zipCode: "56583"},
	{latitude: 47.223664, longitude: -96.237407, zipCode: "56584"},
	{latitude: 47.088342, longitude: -96.246308, zipCode: "56585"},
	{latitude: 46.318269, longitude: -95.8309, zipCode: "56586"},
	{latitude: 46.636631, longitude: -95.856523, zipCode: "56587"},
	{latitude: 46.209179, longitude: -95.496981, zipCode: "56588"},
	{latitude: 47.21196, longitude: -95.727525, zipCode: "56589"},
	{latitude: 46.028103, longitude: -96.152761, zipCode: "56590"},
	{latitude: 47.101357, longitude: -95.857318, zipCode: "56591"},
	{latitude: 47.531609, longitude: -96.007823, zipCode: "56592"},
	{latitude: 46.549021, longitude: -96.644934, zipCode: "56594"},
	{latitude: 47.510829, longitude: -94.82092, zipCode: "56601"},
	{latitude: 47.397973, longitude: -95.414062, zipCode: "56621"},
	{latitude: 48.596877, longitude: -94.600709, zipCode: "56623"},
	{latitude: 47.376691, longitude: -94.149463, zipCode: "56626"},
	{latitude: 48.194406, longitude: -94.047949, zipCode: "56627"},
	{latitude: 47.702088, longitude: -93.533741, zipCode: "56628"},
	{latitude: 48.512189, longitude: -94.165466, zipCode: "56629"},
	{latitude: 47.738959, longitude: -94.510324, zipCode: "56630"},
	{latitude: 47.318784, longitude: -94.50118, zipCode: "56633"},
	{latitude: 47.680725, longitude: -95.39813, zipCode: "56634"},
	{latitude: 47.39047, longitude: -93.84871, zipCode: "56636"},
	{latitude: 47.618205, longitude: -93.843619, zipCode: "56637"},
	{latitude: 47.918539, longitude: -93.536233, zipCode: "56639"},
	{latitude: 47.201891, longitude: -94.260143, zipCode: "56641"},
	{latitude: 47.778021, longitude: -95.512732, zipCode: "56644"},
	{latitude: 47.783068, longitude: -95.633256, zipCode: "56646"},
	{latitude: 47.689935, longitude: -94.618731, zipCode: "56647"},
	{latitude: 48.533133, longitude: -93.163134, zipCode: "56649"},
	{latitude: 47.998056, longitude: -94.557478, zipCode: "56650"},
	{latitude: 47.454496, longitude: -95.616723, zipCode: "56651"},
	{latitude: 47.708135, longitude: -95.22766, zipCode: "56652"},
	{latitude: 48.283385, longitude: -93.574938, zipCode: "56653"},
	{latitude: 48.431201, longitude: -93.964932, zipCode: "56654"},
	{latitude: 46.980791, longitude: -94.231476, zipCode: "56655"},
	{latitude: 47.57291, longitude: -93.63673, zipCode: "56657"},
	{latitude: 48.112487, longitude: -93.932981, zipCode: "56658"},
	{latitude: 47.551229, longitude: -94.048817, zipCode: "56659"},
	{latitude: 47.992586, longitude: -94.192822, zipCode: "56660"},
	{latitude: 47.835047, longitude: -94.125028, zipCode: "56661"},
	{latitude: 46.862207, longitude: -93.853011, zipCode: "56662"},
	{latitude: 47.505946, longitude: -94.44265, zipCode: "56663"},
	{latitude: 48.033237, longitude: -94.821241, zipCode: "56666"},
	{latitude: 47.722113, longitude: -94.968993, zipCode: "56667"},
	{latitude: 48.61505, longitude: -93.345358, zipCode: "56668"},
	{latitude: 48.371352, longitude: -93.210038, zipCode: "56669"},
	{latitude: 47.828897, longitude: -94.865169, zipCode: "56670"},
	{latitude: 47.943399, longitude: -95.338714, zipCode: "56671"},
	{latitude: 47.072504, longitude: -94.00184, zipCode: "56672"},
	{latitude: 48.774487, longitude: -95.149609, zipCode: "56673"},
	{latitude: 47.522354, longitude: -95.201072, zipCode: "56676"},
	{latitude: 47.43646, longitude: -95.115242, zipCode: "56678"},
	{latitude: 47.659009, longitude: -93.954669, zipCode: "56680"},
	{latitude: 47.553943, longitude: -94.264157, zipCode: "56681"},
	{latitude: 47.736271, longitude: -94.7509, zipCode: "56683"},
	{latitude: 47.870168, longitude: -95.699374, zipCode: "56684"},
	{latitude: 48.238688, longitude: -94.531271, zipCode: "56685"},
	{latitude: 48.784049, longitude: -94.911697, zipCode: "56686"},
	{latitude: 47.805467, longitude: -93.839563, zipCode: "56688"},
	{latitude: 48.120464, longitude: -96.200014, zipCode: "56701"},
	{latitude: 48.218086, longitude: -96.997682, zipCode: "56710"},
	{latitude: 48.984208, longitude: -95.058739, zipCode: "56711"},
	{latitude: 48.351138, longitude: -96.814456, zipCode: "56713"},
	{latitude: 48.788391, longitude: -96.005467, zipCode: "56714"},
	{latitude: 47.806422, longitude: -95.949087, zipCode: "56715"},
	{latitude: 47.743247, longitude: -96.555408, zipCode: "56716"},
	{latitude: 48.563465, longitude: -96.904542, zipCode: "56720"},
	{latitude: 47.970648, longitude: -96.949756, zipCode: "56721"},
	{latitude: 47.984776, longitude: -96.650566, zipCode: "56722"},
	{latitude: 47.804902, longitude: -96.805928, zipCode: "56723"},
	{latitude: 48.445299, longitude: -95.734759, zipCode: "56724"},
	{latitude: 48.143921, longitude: -95.711952, zipCode: "56725"},
	{latitude: 48.724517, longitude: -96.217151, zipCode: "56726"},
	{latitude: 48.34688, longitude: -95.389489, zipCode: "56727"},
	{latitude: 48.784278, longitude: -96.977461, zipCode: "56728"},
	{latitude: 48.669163, longitude: -96.593584, zipCode: "56729"},
	{latitude: 48.913017, longitude: -97.072561, zipCode: "56731"},
	{latitude: 48.584211, longitude: -96.46733, zipCode: "56732"},
	{latitude: 48.638028, longitude: -96.895705, zipCode: "56733"},
	{latitude: 48.769619, longitude: -96.603622, zipCode: "56734"},
	{latitude: 48.910175, longitude: -96.635315, zipCode: "56735"},
	{latitude: 47.706473, longitude: -96.211901, zipCode: "56736"},
	{latitude: 48.406856, longitude: -96.009116, zipCode: "56737"},
	{latitude: 48.347178, longitude: -96.337148, zipCode: "56738"},
	{latitude: 47.9306, longitude: -95.827932, zipCode: "56742"},
	{latitude: 48.232575, longitude: -97.117183, zipCode: "56744"},
	{latitude: 47.920629, longitude: -96.007445, zipCode: "56748"},
	{latitude: 47.88585, longitude: -96.297316, zipCode: "56750"},
	{latitude: 48.811428, longitude: -95.757474, zipCode: "56751"},
	{latitude: 47.983396, longitude: -96.187004, zipCode: "56754"},
	{latitude: 48.969716, longitude: -97.117923, zipCode: "56755"},
	{latitude: 48.900935, longitude: -95.560277, zipCode: "56756"},
	{latitude: 48.478088, longitude: -96.898134, zipCode: "56757"},
	{latitude: 48.470412, longitude: -96.54114, zipCode: "56758"},
	{latitude: 48.550867, longitude: -96.081018, zipCode: "56759"},
	{latitude: 48.240377, longitude: -96.445796, zipCode: "56760"},
	{latitude: 48.608619, longitude: -95.697081, zipCode: "56761"},
	{latitude: 48.168765, longitude: -96.74148, zipCode: "56762"},
	{latitude: 48.807304, longitude: -95.341393, zipCode: "56763"},
	{latitude: 43.004185, longitude: -96.657255, zipCode: "57001"},
	{latitude: 44.287826, longitude: -96.677529, zipCode: "57002"},
	{latitude: 43.746275, longitude: -96.755397, zipCode: "57003"},
	{latitude: 43.075775, longitude: -96.792476, zipCode: "57004"},
	{latitude: 43.593654, longitude: -96.587013, zipCode: "57005"},
	{latitude: 44.316217, longitude: -96.79436, zipCode: "57006"},
	{latitude: 44.319236, longitude: -96.783974, zipCode: "57007"},
	{latitude: 42.811905, longitude: -96.816221, zipCode: "57010"},
	{latitude: 43.593944, longitude: -97.278094, zipCode: "57012"},
	{latitude: 43.283105, longitude: -96.624962, zipCode: "57013"},
	{latitude: 43.111915, longitude: -96.95616, zipCode: "57014"},
	{latitude: 43.425185, longitude: -96.97361, zipCode: "57015"},
	{latitude: 43.878074, longitude: -96.957571, zipCode: "57016"},
	{latitude: 44.033164, longitude: -96.819173, zipCode: "57017"},
	{latitude: 43.797893, longitude: -96.981006, zipCode: "57018"},
	{latitude: 43.681278, longitude: -96.820156, zipCode: "57020"},
	{latitude: 43.274875, longitude: -96.980559, zipCode: "57021"},
	{latitude: 43.856284, longitude: -96.736592, zipCode: "57022"},
	{latitude: 43.985418, longitude: -96.681515, zipCode: "57024"},
	{latitude: 42.715058, longitude: -96.69453, zipCode: "57025"},
	{latitude: 44.247673, longitude: -96.495922, zipCode: "57026"},
	{latitude: 43.192125, longitude: -96.519558, zipCode: "57027"},
	{latitude: 44.061654, longitude: -96.599779, zipCode: "57028"},
	{latitude: 43.334745, longitude: -97.470784, zipCode: "57029"},
	{latitude: 43.735725, longitude: -96.54371, zipCode: "57030"},
	{latitude: 42.875524, longitude: -97.185859, zipCode: "57031"},
	{latitude: 43.424196, longitude: -96.678113, zipCode: "57032"},
	{latitude: 43.612706, longitude: -96.958466, zipCode: "57033"},
	{latitude: 43.120164, longitude: -96.538284, zipCode: "57034"},
	{latitude: 43.646374, longitude: -97.079759, zipCode: "57035"},
	{latitude: 43.266729, longitude: -97.145938, zipCode: "57036"},
	{latitude: 43.104465, longitude: -97.24922, zipCode: "57037"},
	{latitude: 42.577928, longitude: -96.576599, zipCode: "57038"},
	{latitude: 43.337838, longitude: -96.874855, zipCode: "57039"},
	{latitude: 43.061032, longitude: -97.58735, zipCode: "57040"},
	{latitude: 43.721837, longitude: -96.884158, zipCode: "57041"},
	{latitude: 43.971319, longitude: -97.144098, zipCode: "57042"},
	{latitude: 43.422611, longitude: -97.310061, zipCode: "57043"},
	{latitude: 43.205008, longitude: -97.528384, zipCode: "57045"},
	{latitude: 42.957636, longitude: -97.306648, zipCode: "57046"},
	{latitude: 43.517551, longitude: -97.215251, zipCode: "57047"},
	{latitude: 43.741984, longitude: -97.200336, zipCode: "57048"},
	{latitude: 42.523328, longitude: -96.509711, zipCode: "57049"},
	{latitude: 44.156906, longitude: -97.0201, zipCode: "57050"},
	{latitude: 44.217416, longitude: -97.350687, zipCode: "57051"},
	{latitude: 43.292356, longitude: -97.707966, zipCode: "57052"},
	{latitude: 43.409594, longitude: -97.1372, zipCode: "57053"},
	{latitude: 44.11673, longitude: -97.259525, zipCode: "57054"},
	{latitude: 43.66685, longitude: -96.743898, zipCode: "57055"},
	{latitude: 44.102232, longitude: -96.95925, zipCode: "57057"},
	{latitude: 43.743878, longitude: -97.401911, zipCode: "57058"},
	{latitude: 43.119205, longitude: -97.764306, zipCode: "57059"},
	{latitude: 44.244494, longitude: -97.044031, zipCode: "57061"},
	{latitude: 42.846199, longitude: -97.945247, zipCode: "57062"},
	{latitude: 42.930268, longitude: -97.684565, zipCode: "57063"},
	{latitude: 43.455629, longitude: -96.874889, zipCode: "57064"},
	{latitude: 43.900434, longitude: -96.606295, zipCode: "57065"},
	{latitude: 42.988653, longitude: -97.867208, zipCode: "57066"},
	{latitude: 43.045592, longitude: -97.469006, zipCode: "57067"},
	{latitude: 43.572833, longitude: -96.499455, zipCode: "57068"},
	{latitude: 42.844619, longitude: -96.986388, zipCode: "57069"},
	{latitude: 43.182034, longitude: -97.170165, zipCode: "57070"},
	{latitude: 44.272319, longitude: -96.962925, zipCode: "57071"},
	{latitude: 42.988979, longitude: -97.205028, zipCode: "57072"},
	{latitude: 43.005958, longitude: -97.055057, zipCode: "57073"},
	{latitude: 43.99616, longitude: -96.967302, zipCode: "57075"},
	{latitude: 43.971525, longitude: -97.359727, zipCode: "57076"},
	{latitude: 43.308778, longitude: -96.752405, zipCode: "57077"},
	{latitude: 42.915648, longitude: -97.446689, zipCode: "57078"},
	{latitude: 43.538037, longitude: -96.69203, zipCode: "57103"},
	{latitude: 43.603016, longitude: -96.704273, zipCode: "57104"},
	{latitude: 43.520296, longitude: -96.735607, zipCode: "57105"},
	{latitude: 43.510502, longitude: -96.834884, zipCode: "57106"},
	{latitude: 43.598741, longitude: -96.816958, zipCode: "57107"},
	{latitude: 43.477461, longitude: -96.704757, zipCode: "57108"},
	{latitude: 43.544679, longitude: -96.638642, zipCode: "57110"},
	{latitude: 43.531243, longitude: -96.754971, zipCode: "57117"},
	{latitude: 43.524551, longitude: -96.73861, zipCode: "57197"},
	{latitude: 44.936722, longitude: -97.092757, zipCode: "57201"},
	{latitude: 44.37853, longitude: -97.139459, zipCode: "57212"},
	{latitude: 44.57012, longitude: -96.536883, zipCode: "57213"},
	{latitude: 44.480491, longitude: -97.195146, zipCode: "57214"},
	{latitude: 45.30324, longitude: -96.529779, zipCode: "57216"},
	{latitude: 45.086733, longitude: -97.665461, zipCode: "57217"},
	{latitude: 44.652379, longitude: -96.592103, zipCode: "57218"},
	{latitude: 45.290641, longitude: -97.796148, zipCode: "57219"},
	{latitude: 44.45976, longitude: -96.917332, zipCode: "57220"},
	{latitude: 44.608917, longitude: -97.441378, zipCode: "57221"},
	{latitude: 44.712299, longitude: -96.994353, zipCode: "57223"},
	{latitude: 45.811883, longitude: -97.155275, zipCode: "57224"},
	{latitude: 44.883544, longitude: -97.728093, zipCode: "57225"},
	{latitude: 44.788077, longitude: -96.730646, zipCode: "57226"},
	{latitude: 45.375905, longitude: -96.728121, zipCode: "57227"},
	{latitude: 44.375816, longitude: -97.582277, zipCode: "57231"},
	{latitude: 45.623412, longitude: -97.399926, zipCode: "57232"},
	{latitude: 44.509922, longitude: -97.399324, zipCode: "57233"},
	{latitude: 44.610285, longitude: -96.894291, zipCode: "57234"},
	{latitude: 45.071967, longitude: -97.320432, zipCode: "57235"},
	{latitude: 44.985548, longitude: -97.585622, zipCode: "57236"},
	{latitude: 44.797699, longitude: -96.500497, zipCode: "57237"},
	{latitude: 44.873359, longitude: -96.860868, zipCode: "57238"},
	{latitude: 45.510884, longitude: -97.31312, zipCode: "57239"},
	{latitude: 44.695552, longitude: -97.216193, zipCode: "57241"},
	{latitude: 44.774131, longitude: -97.357985, zipCode: "57242"},
	{latitude: 44.912943, longitude: -97.44237, zipCode: "57243"},
	{latitude: 44.893654, longitude: -96.918338, zipCode: "57245"},
	{latitude: 45.054911, longitude: -96.66698, zipCode: "57246"},
	{latitude: 45.712201, longitude: -97.463181, zipCode: "57247"},
	{latitude: 44.583522, longitude: -97.18799, zipCode: "57248"},
	{latitude: 44.383398, longitude: -97.370502, zipCode: "57249"},
	{latitude: 45.276515, longitude: -96.934706, zipCode: "57251"},
	{latitude: 45.200406, longitude: -96.612559, zipCode: "57252"},
	{latitude: 45.851107, longitude: -96.947957, zipCode: "57255"},
	{latitude: 45.249962, longitude: -97.196982, zipCode: "57256"},
	{latitude: 45.49661, longitude: -97.030948, zipCode: "57257"},
	{latitude: 44.882722, longitude: -97.927448, zipCode: "57258"},
	{latitude: 45.002493, longitude: -96.563489, zipCode: "57259"},
	{latitude: 45.841733, longitude: -96.719612, zipCode: "57260"},
	{latitude: 45.545582, longitude: -97.49956, zipCode: "57261"},
	{latitude: 45.668703, longitude: -97.073638, zipCode: "57262"},
	{latitude: 45.140528, longitude: -97.00799, zipCode: "57263"},
	{latitude: 45.107946, longitude: -96.81089, zipCode: "57264"},
	{latitude: 44.992127, longitude: -96.781509, zipCode: "57265"},
	{latitude: 45.28982, longitude: -97.092722, zipCode: "57266"},
	{latitude: 44.567964, longitude: -96.689414, zipCode: "57268"},
	{latitude: 45.224257, longitude: -96.800243, zipCode: "57269"},
	{latitude: 45.848663, longitude: -97.330977, zipCode: "57270"},
	{latitude: 44.75847, longitude: -97.538215, zipCode: "57271"},
	{latitude: 45.108232, longitude: -97.4837, zipCode: "57272"},
	{latitude: 45.333152, longitude: -97.275195, zipCode: "57273"},
	{latitude: 45.31018, longitude: -97.555747, zipCode: "57274"},
	{latitude: 44.435942, longitude: -96.617911, zipCode: "57276"},
	{latitude: 44.648834, longitude: -97.681153, zipCode: "57278"},
	{latitude: 45.41575, longitude: -96.869201, zipCode: "57279"},
	{latitude: 43.710912, longitude: -98.040449, zipCode: "57301"},
	{latitude: 43.650512, longitude: -97.764648, zipCode: "57311"},
	{latitude: 44.193181, longitude: -98.38401, zipCode: "57312"},
	{latitude: 43.304789, longitude: -98.354755, zipCode: "57313"},
	{latitude: 44.046091, longitude: -97.981288, zipCode: "57314"},
	{latitude: 42.988388, longitude: -98.040294, zipCode: "57315"},
	{latitude: 43.143555, longitude: -98.966447, zipCode: "57317"},
	{latitude: 43.533018, longitude: -97.480025, zipCode: "57319"},
	{latitude: 43.870552, longitude: -97.54036, zipCode: "57321"},
	{latitude: 44.637971, longitude: -97.961977, zipCode: "57322"},
	{latitude: 44.1648, longitude: -97.72028, zipCode: "57323"},
	{latitude: 44.301451, longitude: -98.045408, zipCode: "57324"},
	{latitude: 43.834901, longitude: -99.31203, zipCode: "57325"},
	{latitude: 43.437622, longitude: -98.439006, zipCode: "57328"},
	{latitude: 42.980663, longitude: -98.145755, zipCode: "57329"},
	{latitude: 43.264542, longitude: -98.157694, zipCode: "57330"},
	{latitude: 43.474415, longitude: -98.044848, zipCode: "57331"},
	{latitude: 43.5505, longitude: -97.656776, zipCode: "57332"},
	{latitude: 43.535165, longitude: -97.996338, zipCode: "57334"},
	{latitude: 43.065138, longitude: -98.743972, zipCode: "57335"},
	{latitude: 44.00208, longitude: -97.781101, zipCode: "57337"},
	{latitude: 44.126676, longitude: -99.463922, zipCode: "57339"},
	{latitude: 43.792912, longitude: -97.852171, zipCode: "57340"},
	{latitude: 44.094667, longitude: -99.117978, zipCode: "57341"},
	{latitude: 43.244625, longitude: -98.701247, zipCode: "57342"},
	{latitude: 43.449198, longitude: -98.618863, zipCode: "57344"},
	{latitude: 44.578381, longitude: -99.444401, zipCode: "57345"},
	{latitude: 44.249138, longitude: -99.456674, zipCode: "57346"},
	{latitude: 44.625569, longitude: -98.37222, zipCode: "57348"},
	{latitude: 44.030097, longitude: -97.5808, zipCode: "57349"},
	{latitude: 44.392466, longitude: -98.201934, zipCode: "57350"},
	{latitude: 44.369382, longitude: -97.834605, zipCode: "57353"},
	{latitude: 43.193624, longitude: -97.844102, zipCode: "57354"},
	{latitude: 43.783607, longitude: -98.931444, zipCode: "57355"},
	{latitude: 43.145225, longitude: -98.513188, zipCode: "57356"},
	{latitude: 44.075188, longitude: -98.422024, zipCode: "57358"},
	{latitude: 43.902463, longitude: -98.154388, zipCode: "57359"},
	{latitude: 42.994574, longitude: -98.434602, zipCode: "57361"},
	{latitude: 44.469468, longitude: -99.071406, zipCode: "57362"},
	{latitude: 43.680487, longitude: -98.25552, zipCode: "57363"},
	{latitude: 43.434393, longitude: -98.617803, zipCode: "57364"},
	{latitude: 43.811327, longitude: -99.416428, zipCode: "57365"},
	{latitude: 43.391569, longitude: -97.946598, zipCode: "57366"},
	{latitude: 43.065703, longitude: -98.503, zipCode: "57367"},
	{latitude: 43.749509, longitude: -98.472097, zipCode: "57368"},
	{latitude: 43.420751, longitude: -98.953012, zipCode: "57369"},
	{latitude: 43.816777, longitude: -99.16819, zipCode: "57370"},
	{latitude: 44.437149, longitude: -99.221494, zipCode: "57371"},
	{latitude: 44.530648, longitude: -98.878286, zipCode: "57373"},
	{latitude: 43.753348, longitude: -97.599126, zipCode: "57374"},
	{latitude: 43.556414, longitude: -98.479919, zipCode: "57375"},
	{latitude: 43.219877, longitude: -97.944868, zipCode: "57376"},
	{latitude: 44.270105, longitude: -98.52918, zipCode: "57379"},
	{latitude: 43.030618, longitude: -98.29855, zipCode: "57380"},
	{latitude: 44.388019, longitude: -98.730903, zipCode: "57381"},
	{latitude: 44.055549, longitude: -98.679659, zipCode: "57382"},
	{latitude: 43.71733, longitude: -98.712725, zipCode: "57383"},
	{latitude: 44.416836, longitude: -98.485357, zipCode: "57384"},
	{latitude: 44.046746, longitude: -98.30107, zipCode: "57385"},
	{latitude: 44.506667, longitude: -97.996974, zipCode: "57386"},
	{latitude: 45.482335, longitude: -98.53254, zipCode: "57401"},
	{latitude: 45.281852, longitude: -100.142521, zipCode: "57420"},
	{latitude: 45.755478, longitude: -97.907063, zipCode: "57421"},
	{latitude: 45.424957, longitude: -97.927804, zipCode: "57422"},
	{latitude: 45.020492, longitude: -98.523182, zipCode: "57424"},
	{latitude: 45.73484, longitude: -98.493636, zipCode: "57426"},
	{latitude: 45.480547, longitude: -98.318576, zipCode: "57427"},
	{latitude: 45.452705, longitude: -99.67822, zipCode: "57428"},
	{latitude: 45.161167, longitude: -98.300506, zipCode: "57429"},
	{latitude: 45.834926, longitude: -97.719666, zipCode: "57430"},
	{latitude: 45.682867, longitude: -98.031046, zipCode: "57432"},
	{latitude: 45.638241, longitude: -98.320765, zipCode: "57433"},
	{latitude: 45.167107, longitude: -98.071969, zipCode: "57434"},
	{latitude: 45.153847, longitude: -98.914249, zipCode: "57435"},
	{latitude: 44.835547, longitude: -98.076968, zipCode: "57436"},
	{latitude: 45.789053, longitude: -99.624988, zipCode: "57437"},
	{latitude: 45.065476, longitude: -99.153564, zipCode: "57438"},
	{latitude: 45.33465, longitude: -98.100066, zipCode: "57439"},
	{latitude: 44.853426, longitude: -98.256687, zipCode: "57440"},
	{latitude: 45.826421, longitude: -98.513499, zipCode: "57441"},
	{latitude: 45.06175, longitude: -100.158139, zipCode: "57442"},
	{latitude: 45.465537, longitude: -98.114967, zipCode: "57445"},
	{latitude: 45.870828, longitude: -98.15267, zipCode: "57446"},
	{latitude: 45.612128, longitude: -99.433272, zipCode: "57448"},
	{latitude: 45.726515, longitude: -98.176162, zipCode: "57449"},
	{latitude: 45.20995, longitude: -99.82746, zipCode: "57450"},
	{latitude: 45.437923, longitude: -98.936584, zipCode: "57451"},
	{latitude: 45.529397, longitude: -99.862852, zipCode: "57452"},
	{latitude: 45.611554, longitude: -97.76148, zipCode: "57454"},
	{latitude: 45.00698, longitude: -99.683039, zipCode: "57455"},
	{latitude: 45.721001, longitude: -98.999797, zipCode: "57456"},
	{latitude: 45.877277, longitude: -99.154299, zipCode: "57457"},
	{latitude: 45.279575, longitude: -98.670681, zipCode: "57460"},
	{latitude: 45.164689, longitude: -98.442109, zipCode: "57461"},
	{latitude: 45.150666, longitude: -98.666504, zipCode: "57465"},
	{latitude: 45.196976, longitude: -99.458493, zipCode: "57466"},
	{latitude: 44.836772, longitude: -99.180338, zipCode: "57467"},
	{latitude: 45.49498, longitude: -97.743213, zipCode: "57468"},
	{latitude: 44.857384, longitude: -98.571778, zipCode: "57469"},
	{latitude: 44.883901, longitude: -98.894286, zipCode: "57470"},
	{latitude: 45.404536, longitude: -99.318265, zipCode: "57471"},
	{latitude: 45.427652, longitude: -100.110208, zipCode: "57472"},
	{latitude: 44.991451, longitude: -99.471014, zipCode: "57473"},
	{latitude: 45.292014, longitude: -98.278733, zipCode: "57474"},
	{latitude: 45.223553, longitude: -99.624634, zipCode: "57475"},
	{latitude: 44.706167, longitude: -98.615249, zipCode: "57476"},
	{latitude: 45.03382, longitude: -98.144343, zipCode: "57477"},
	{latitude: 45.304151, longitude: -98.43819, zipCode: "57479"},
	{latitude: 45.662592, longitude: -98.682958, zipCode: "57481"},
	{latitude: 44.527632, longitude: -100.309123, zipCode: "57501"},
	{latitude: 44.852798, longitude: -100.193658, zipCode: "57520"},
	{latitude: 43.792089, longitude: -101.182802, zipCode: "57521"},
	{latitude: 44.510544, longitude: -99.963858, zipCode: "57522"},
	{latitude: 43.247619, longitude: -99.23661, zipCode: "57523"},
	{latitude: 43.177211, longitude: -99.760346, zipCode: "57528"},
	{latitude: 43.230602, longitude: -99.563794, zipCode: "57529"},
	{latitude: 43.973671, longitude: -100.485912, zipCode: "57531"},
	{latitude: 44.364929, longitude: -100.565604, zipCode: "57532"},
	{latitude: 43.350367, longitude: -99.420272, zipCode: "57533"},
	{latitude: 43.561204, longitude: -99.651941, zipCode: "57534"},
	{latitude: 44.417429, longitude: -99.774218, zipCode: "57536"},
	{latitude: 44.578086, longitude: -101.067304, zipCode: "57537"},
	{latitude: 43.121162, longitude: -99.156625, zipCode: "57538"},
	{latitude: 44.461425, longitude: -99.607861, zipCode: "57540"},
	{latitude: 43.597722, longitude: -99.911895, zipCode: "57541"},
	{latitude: 43.747765, longitude: -101.521076, zipCode: "57543"},
	{latitude: 43.883449, longitude: -99.843986, zipCode: "57544"},
	{latitude: 43.489599, longitude: -101.423692, zipCode: "57547"},
	{latitude: 44.168374, longitude: -99.588372, zipCode: "57548"},
	{latitude: 43.191962, longitude: -101.708294, zipCode: "57551"},
	{latitude: 44.262437, longitude: -101.217154, zipCode: "57552"},
	{latitude: 44.474594, longitude: -101.645588, zipCode: "57553"},
	{latitude: 43.285887, longitude: -100.666559, zipCode: "57555"},
	{latitude: 43.931283, longitude: -100.722674, zipCode: "57559"},
	{latitude: 43.437137, longitude: -101.209565, zipCode: "57560"},
	{latitude: 43.897568, longitude: -100.958429, zipCode: "57562"},
	{latitude: 43.349949, longitude: -100.421261, zipCode: "57563"},
	{latitude: 44.740369, longitude: -100.014646, zipCode: "57564"},
	{latitude: 43.3087, longitude: -101.089361, zipCode: "57566"},
	{latitude: 44.133584, longitude: -101.707853, zipCode: "57567"},
	{latitude: 43.876471, longitude: -100.096054, zipCode: "57568"},
	{latitude: 43.859159, longitude: -99.554062, zipCode: "57569"},
	{latitude: 43.252173, longitude: -100.885154, zipCode: "57570"},
	{latitude: 43.041253, longitude: -99.090095, zipCode: "57571"},
	{latitude: 43.137209, longitude: -101.100455, zipCode: "57572"},
	{latitude: 43.125763, longitude: -101.385805, zipCode: "57574"},
	{latitude: 43.906086, longitude: -100.292316, zipCode: "57576"},
	{latitude: 43.523767, longitude: -101.655861, zipCode: "57577"},
	{latitude: 43.576177, longitude: -100.788909, zipCode: "57579"},
	{latitude: 43.289423, longitude: -100.090188, zipCode: "57580"},
	{latitude: 43.490154, longitude: -100.061799, zipCode: "57584"},
	{latitude: 43.558006, longitude: -100.385405, zipCode: "57585"},
	{latitude: 45.338976, longitude: -100.364733, zipCode: "57601"},
	{latitude: 45.377258, longitude: -102.540212, zipCode: "57620"},
	{latitude: 45.772788, longitude: -101.081389, zipCode: "57621"},
	{latitude: 44.682659, longitude: -101.566327, zipCode: "57622"},
	{latitude: 45.015928, longitude: -101.652998, zipCode: "57623"},
	{latitude: 44.979017, longitude: -101.025137, zipCode: "57625"},
	{latitude: 44.996783, longitude: -102.174187, zipCode: "57626"},
	{latitude: 45.333778, longitude: -100.844046, zipCode: "57630"},
	{latitude: 45.589095, longitude: -100.296128, zipCode: "57631"},
	{latitude: 45.833456, longitude: -100.044213, zipCode: "57632"},
	{latitude: 45.441592, longitude: -101.532383, zipCode: "57633"},
	{latitude: 45.773244, longitude: -101.865567, zipCode: "57634"},
	{latitude: 45.032464, longitude: -101.438087, zipCode: "57636"},
	{latitude: 45.869861, longitude: -102.23206, zipCode: "57638"},
	{latitude: 45.678661, longitude: -100.807036, zipCode: "57639"},
	{latitude: 45.768832, longitude: -102.704525, zipCode: "57640"},
	{latitude: 45.896186, longitude: -101.311106, zipCode: "57641"},
	{latitude: 45.790775, longitude: -100.71484, zipCode: "57642"},
	{latitude: 45.477538, longitude: -102.093672, zipCode: "57644"},
	{latitude: 45.907315, longitude: -101.716409, zipCode: "57645"},
	{latitude: 45.680479, longitude: -100.05824, zipCode: "57646"},
	{latitude: 45.929783, longitude: -100.319922, zipCode: "57648"},
	{latitude: 45.488704, longitude: -102.848892, zipCode: "57649"},
	{latitude: 45.830929, longitude: -103.034682, zipCode: "57650"},
	{latitude: 45.476863, longitude: -103.03443, zipCode: "57651"},
	{latitude: 45.136216, longitude: -100.683015, zipCode: "57652"},
	{latitude: 45.423044, longitude: -101.106687, zipCode: "57656"},
	{latitude: 45.518884, longitude: -100.792161, zipCode: "57657"},
	{latitude: 45.669439, longitude: -100.562316, zipCode: "57658"},
	{latitude: 45.890896, longitude: -101.094218, zipCode: "57659"},
	{latitude: 45.83209, longitude: -101.530815, zipCode: "57660"},
	{latitude: 45.26651, longitude: -100.977565, zipCode: "57661"},
	{latitude: 44.121777, longitude: -103.201222, zipCode: "57701"},
	{latitude: 44.032012, longitude: -103.415359, zipCode: "57702"},
	{latitude: 44.004564, longitude: -103.042628, zipCode: "57703"},
	{latitude: 44.158789, longitude: -103.101868, zipCode: "57706"},
	{latitude: 43.329195, longitude: -101.914725, zipCode: "57714"},
	{latitude: 43.091167, longitude: -102.196028, zipCode: "57716"},
	{latitude: 44.933879, longitude: -103.895745, zipCode: "57717"},
	{latitude: 44.170504, longitude: -103.369156, zipCode: "57718"},
	{latitude: 44.231201, longitude: -102.994377, zipCode: "57719"},
	{latitude: 45.537168, longitude: -103.586067, zipCode: "57720"},
	{latitude: 43.443214, longitude: -102.987369, zipCode: "57722"},
	{latitude: 45.573791, longitude: -104.001141, zipCode: "57724"},
	{latitude: 43.932032, longitude: -102.7919, zipCode: "57725"},
	{latitude: 43.726576, longitude: -103.648599, zipCode: "57730"},
	{latitude: 44.329012, longitude: -103.689034, zipCode: "57732"},
	{latitude: 43.287937, longitude: -103.85355, zipCode: "57735"},
	{latitude: 44.555435, longitude: -102.517339, zipCode: "57737"},
	{latitude: 43.684853, longitude: -103.158718, zipCode: "57738"},
	{latitude: 44.410498, longitude: -103.472882, zipCode: "57741"},
	{latitude: 43.793475, longitude: -103.033857, zipCode: "57744"},
	{latitude: 44.013264, longitude: -103.785659, zipCode: "57745"},
	{latitude: 43.37376, longitude: -103.519145, zipCode: "57747"},
	{latitude: 44.585172, longitude: -102.010628, zipCode: "57748"},
	{latitude: 43.5929, longitude: -101.945128, zipCode: "57750"},
	{latitude: 43.869135, longitude: -103.426932, zipCode: "57751"},
	{latitude: 43.429333, longitude: -102.201331, zipCode: "57752"},
	{latitude: 44.255434, longitude: -103.887569, zipCode: "57754"},
	{latitude: 45.868326, longitude: -103.347178, zipCode: "57755"},
	{latitude: 43.237288, longitude: -102.475437, zipCode: "57756"},
	{latitude: 44.994621, longitude: -102.737081, zipCode: "57758"},
	{latitude: 44.227818, longitude: -103.556798, zipCode: "57759"},
	{latitude: 44.894658, longitude: -103.334676, zipCode: "57760"},
	{latitude: 44.227284, longitude: -102.785434, zipCode: "57761"},
	{latitude: 44.753351, longitude: -103.619692, zipCode: "57762"},
	{latitude: 43.123511, longitude: -103.251157, zipCode: "57763"},
	{latitude: 43.257861, longitude: -102.771135, zipCode: "57764"},
	{latitude: 43.356096, longitude: -103.177535, zipCode: "57766"},
	{latitude: 44.136748, longitude: -102.571112, zipCode: "57767"},
	{latitude: 44.245301, longitude: -103.288634, zipCode: "57769"},
	{latitude: 43.084365, longitude: -102.654251, zipCode: "57770"},
	{latitude: 43.477183, longitude: -102.527554, zipCode: "57772"},
	{latitude: 43.594435, longitude: -103.592474, zipCode: "57773"},
	{latitude: 44.093016, longitude: -102.001651, zipCode: "57775"},
	{latitude: 45.236414, longitude: -103.507964, zipCode: "57776"},
	{latitude: 44.568767, longitude: -103.760725, zipCode: "57779"},
	{latitude: 43.767506, longitude: -102.479503, zipCode: "57780"},
	{latitude: 43.249431, longitude: -103.180503, zipCode: "57782"},
	{latitude: 44.456339, longitude: -103.925266, zipCode: "57783"},
	{latitude: 44.402472, longitude: -103.188819, zipCode: "57785"},
	{latitude: 44.667994, longitude: -102.657839, zipCode: "57787"},
	{latitude: 44.616964, longitude: -103.204941, zipCode: "57788"},
	{latitude: 44.104078, longitude: -102.200877, zipCode: "57790"},
	{latitude: 44.247026, longitude: -102.457512, zipCode: "57791"},
	{latitude: 44.59754, longitude: -102.367975, zipCode: "57792"},
	{latitude: 44.525287, longitude: -103.578811, zipCode: "57793"},
	{latitude: 43.120679, longitude: -102.407006, zipCode: "57794"},
	{latitude: 44.498152, longitude: -103.872505, zipCode: "57799"},
	{latitude: 46.448366, longitude: -96.740812, zipCode: "58001"},
	{latitude: 46.97109, longitude: -97.397242, zipCode: "58002"},
	{latitude: 47.026475, longitude: -97.270723, zipCode: "58004"},
	{latitude: 47.052614, longitude: -96.950996, zipCode: "58005"},
	{latitude: 47.096824, longitude: -97.203601, zipCode: "58006"},
	{latitude: 47.025192, longitude: -97.457242, zipCode: "58007"},
	{latitude: 46.308142, longitude: -97.002379, zipCode: "58008"},
	{latitude: 47.32658, longitude: -97.244403, zipCode: "58009"},
	{latitude: 46.927793, longitude: -97.544021, zipCode: "58011"},
	{latitude: 46.941686, longitude: -97.168331, zipCode: "58012"},
	{latitude: 46.057851, longitude: -97.353266, zipCode: "58013"},
	{latitude: 46.592204, longitude: -96.815431, zipCode: "58015"},
	{latitude: 47.366113, longitude: -97.462952, zipCode: "58016"},
	{latitude: 46.043361, longitude: -97.851471, zipCode: "58017"},
	{latitude: 46.434565, longitude: -96.888742, zipCode: "58018"},
	{latitude: 46.736154, longitude: -97.08213, zipCode: "58021"},
	{latitude: 46.633177, longitude: -97.630735, zipCode: "58027"},
	{latitude: 47.119024, longitude: -97.394066, zipCode: "58029"},
	{latitude: 46.030334, longitude: -96.668186, zipCode: "58030"},
	{latitude: 46.767684, longitude: -97.671491, zipCode: "58031"},
	{latitude: 46.07644, longitude: -97.654761, zipCode: "58032"},
	{latitude: 46.4347, longitude: -97.91956, zipCode: "58033"},
	{latitude: 47.253416, longitude: -97.430516, zipCode: "58035"},
	{latitude: 47.15463, longitude: -96.963263, zipCode: "58036"},
	{latitude: 47.245071, longitude: -96.994784, zipCode: "58038"},
	{latitude: 46.189495, longitude: -97.685, zipCode: "58040"},
	{latitude: 46.036544, longitude: -96.906425, zipCode: "58041"},
	{latitude: 46.970891, longitude: -96.956035, zipCode: "58042"},
	{latitude: 45.970214, longitude: -97.537917, zipCode: "58043"},
	{latitude: 47.388882, longitude: -97.016318, zipCode: "58045"},
	{latitude: 47.322086, longitude: -97.727721, zipCode: "58046"},
	{latitude: 46.713528, longitude: -96.872858, zipCode: "58047"},
	{latitude: 47.198821, longitude: -97.223655, zipCode: "58048"},
	{latitude: 46.670689, longitude: -97.991465, zipCode: "58049"},
	{latitude: 46.636833, longitude: -97.021501, zipCode: "58051"},
	{latitude: 46.63462, longitude: -97.279569, zipCode: "58052"},
	{latitude: 46.052505, longitude: -97.18054, zipCode: "58053"},
	{latitude: 46.416881, longitude: -97.668722, zipCode: "58054"},
	{latitude: 47.236875, longitude: -97.929491, zipCode: "58056"},
	{latitude: 46.425466, longitude: -97.267553, zipCode: "58057"},
	{latitude: 46.180584, longitude: -96.971607, zipCode: "58058"},
	{latitude: 46.832371, longitude: -97.143569, zipCode: "58059"},
	{latitude: 46.267715, longitude: -97.426686, zipCode: "58060"},
	{latitude: 46.274595, longitude: -96.894203, zipCode: "58061"},
	{latitude: 46.637537, longitude: -97.839516, zipCode: "58062"},
	{latitude: 46.959034, longitude: -97.797584, zipCode: "58063"},
	{latitude: 47.139612, longitude: -97.632489, zipCode: "58064"},
	{latitude: 47.200352, longitude: -97.766154, zipCode: "58065"},
	{latitude: 46.072878, longitude: -97.49869, zipCode: "58067"},
	{latitude: 46.550685, longitude: -97.416545, zipCode: "58068"},
	{latitude: 46.243089, longitude: -97.839604, zipCode: "58069"},
	{latitude: 46.941278, longitude: -97.682736, zipCode: "58071"},
	{latitude: 46.927034, longitude: -98.008336, zipCode: "58072"},
	{latitude: 46.218057, longitude: -96.87601, zipCode: "58074"},
	{latitude: 46.275536, longitude: -96.730394, zipCode: "58075"},
	{latitude: 46.274056, longitude: -96.607449, zipCode: "58076"},
	{latitude: 46.524583, longitude: -96.976013, zipCode: "58077"},
	{latitude: 46.867244, longitude: -96.942162, zipCode: "58078"},
	{latitude: 46.86844, longitude: -97.366681, zipCode: "58079"},
	{latitude: 46.313857, longitude: -97.143829, zipCode: "58081"},
	{latitude: 46.92721, longitude: -96.834819, zipCode: "58102"},
	{latitude: 46.856347, longitude: -96.82246, zipCode: "58103"},
	{latitude: 46.793179, longitude: -96.838434, zipCode: "58104"},
	{latitude: 46.895884, longitude: -96.803639, zipCode: "58105"},
	{latitude: 47.866638, longitude: -97.141144, zipCode: "58201"},
	{latitude: 47.92146, longitude: -97.075139, zipCode: "58202"},
	{latitude: 47.970477, longitude: -97.16549, zipCode: "58203"},
	{latitude: 47.960469, longitude: -97.370795, zipCode: "58204"},
	{latitude: 47.95475, longitude: -97.395254, zipCode: "58205"},
	{latitude: 48.405691, longitude: -98.111589, zipCode: "58210"},
	{latitude: 47.695166, longitude: -97.997378, zipCode: "58212"},
	{latitude: 47.926891, longitude: -97.4975, zipCode: "58214"},
	{latitude: 48.879383, longitude: -97.442869, zipCode: "58216"},
	{latitude: 47.592361, longitude: -97.059216, zipCode: "58218"},
	{latitude: 47.476068, longitude: -96.878197, zipCode: "58219"},
	{latitude: 48.797612, longitude: -97.742027, zipCode: "58220"},
	{latitude: 48.618537, longitude: -97.682198, zipCode: "58222"},
	{latitude: 47.519634, longitude: -97.016444, zipCode: "58223"},
	{latitude: 48.167781, longitude: -97.943082, zipCode: "58224"},
	{latitude: 48.611238, longitude: -97.222779, zipCode: "58225"},
	{latitude: 48.549096, longitude: -97.892709, zipCode: "58227"},
	{latitude: 47.868067, longitude: -97.345202, zipCode: "58228"},
	{latitude: 48.514016, longitude: -98.242608, zipCode: "58229"},
	{latitude: 47.492526, longitude: -97.821541, zipCode: "58230"},
	{latitude: 48.202238, longitude: -97.829398, zipCode: "58231"},
	{latitude: 48.201661, longitude: -97.546065, zipCode: "58233"},
	{latitude: 48.090058, longitude: -97.483082, zipCode: "58235"},
	{latitude: 48.718443, longitude: -97.504782, zipCode: "58236"},
	{latitude: 48.415203, longitude: -97.400931, zipCode: "58237"},
	{latitude: 48.779537, longitude: -97.397941, zipCode: "58238"},
	{latitude: 48.919596, longitude: -98.684906, zipCode: "58239"},
	{latitude: 47.635966, longitude: -97.48458, zipCode: "58240"},
	{latitude: 48.709462, longitude: -97.699433, zipCode: "58241"},
	{latitude: 48.514235, longitude: -97.679526, zipCode: "58243"},
	{latitude: 48.11034, longitude: -97.657525, zipCode: "58244"},
	{latitude: 48.822796, longitude: -98.326795, zipCode: "58249"},
	{latitude: 48.274343, longitude: -98.022295, zipCode: "58250"},
	{latitude: 47.954354, longitude: -97.699884, zipCode: "58251"},
	{latitude: 47.774007, longitude: -98.15, zipCode: "58254"},
	{latitude: 48.996767, longitude: -98.353905, zipCode: "58255"},
	{latitude: 48.084038, longitude: -97.200214, zipCode: "58256"},
	{latitude: 47.490249, longitude: -97.285041, zipCode: "58257"},
	{latitude: 48.011374, longitude: -97.3383, zipCode: "58258"},
	{latitude: 48.020465, longitude: -98.120838, zipCode: "58259"},
	{latitude: 48.637094, longitude: -98.048609, zipCode: "58260"},
	{latitude: 48.242266, longitude: -97.319417, zipCode: "58261"},
	{latitude: 48.681, longitude: -97.877567, zipCode: "58262"},
	{latitude: 48.956126, longitude: -97.590287, zipCode: "58265"},
	{latitude: 48.000939, longitude: -97.849975, zipCode: "58266"},
	{latitude: 47.755019, longitude: -97.629119, zipCode: "58267"},
	{latitude: 48.698177, longitude: -98.157538, zipCode: "58269"},
	{latitude: 48.394753, longitude: -97.791155, zipCode: "58270"},
	{latitude: 48.912895, longitude: -97.289694, zipCode: "58271"},
	{latitude: 48.007621, longitude: -97.984915, zipCode: "58272"},
	{latitude: 48.297483, longitude: -97.662764, zipCode: "58273"},
	{latitude: 47.491898, longitude: -97.488544, zipCode: "58274"},
	{latitude: 47.690471, longitude: -97.105092, zipCode: "58275"},
	{latitude: 48.624014, longitude: -97.456036, zipCode: "58276"},
	{latitude: 47.608381, longitude: -97.82331, zipCode: "58277"},
	{latitude: 47.781229, longitude: -97.121467, zipCode: "58278"},
	{latitude: 48.855839, longitude: -98.616489, zipCode: "58281"},
	{latitude: 48.898141, longitude: -97.94185, zipCode: "58282"},
	{latitude: 48.141836, longitude: -98.893438, zipCode: "58301"},
	{latitude: 48.566713, longitude: -99.553099, zipCode: "58310"},
	{latitude: 48.645227, longitude: -98.557163, zipCode: "58311"},
	{latitude: 48.166169, longitude: -100.036646, zipCode: "58313"},
	{latitude: 48.844632, longitude: -99.80279, zipCode: "58316"},
	{latitude: 48.626244, longitude: -99.403518, zipCode: "58317"},
	{latitude: 48.858083, longitude: -100.39742, zipCode: "58318"},
	{latitude: 48.202445, longitude: -98.314422, zipCode: "58321"},
	{latitude: 48.841487, longitude: -98.923128, zipCode: "58323"},
	{latitude: 48.479405, longitude: -99.220932, zipCode: "58324"},
	{latitude: 48.28948, longitude: -99.118266, zipCode: "58325"},
	{latitude: 48.104377, longitude: -98.568244, zipCode: "58327"},
	{latitude: 48.862438, longitude: -100.0721, zipCode: "58329"},
	{latitude: 48.43375, longitude: -98.478781, zipCode: "58330"},
	{latitude: 48.638515, longitude: -99.090041, zipCode: "58331"},
	{latitude: 48.044165, longitude: -99.772982, zipCode: "58332"},
	{latitude: 47.970499, longitude: -99.016593, zipCode: "58335"},
	{latitude: 48.526201, longitude: -98.627124, zipCode: "58338"},
	{latitude: 48.972517, longitude: -99.324612, zipCode: "58339"},
	{latitude: 47.767635, longitude: -99.876653, zipCode: "58341"},
	{latitude: 48.295858, longitude: -99.728761, zipCode: "58343"},
	{latitude: 48.016523, longitude: -98.343004, zipCode: "58344"},
	{latitude: 48.306617, longitude: -98.375144, zipCode: "58345"},
	{latitude: 48.289988, longitude: -99.427829, zipCode: "58346"},
	{latitude: 47.95344, longitude: -99.521094, zipCode: "58348"},
	{latitude: 48.09797, longitude: -99.314636, zipCode: "58351"},
	{latitude: 48.695589, longitude: -98.857768, zipCode: "58352"},
	{latitude: 48.64021, longitude: -99.59009, zipCode: "58353"},
	{latitude: 48.578444, longitude: -98.39595, zipCode: "58355"},
	{latitude: 47.671392, longitude: -99.072079, zipCode: "58356"},
	{latitude: 47.939783, longitude: -99.216787, zipCode: "58357"},
	{latitude: 47.752047, longitude: -98.375596, zipCode: "58361"},
	{latitude: 48.230989, longitude: -99.059859, zipCode: "58362"},
	{latitude: 48.769264, longitude: -99.465532, zipCode: "58363"},
	{latitude: 48.841341, longitude: -99.231706, zipCode: "58365"},
	{latitude: 48.653521, longitude: -99.874028, zipCode: "58366"},
	{latitude: 48.881881, longitude: -99.556133, zipCode: "58367"},
	{latitude: 48.239469, longitude: -100.031162, zipCode: "58368"},
	{latitude: 48.95891, longitude: -99.813735, zipCode: "58369"},
	{latitude: 47.985147, longitude: -98.86648, zipCode: "58370"},
	{latitude: 48.942452, longitude: -99.012007, zipCode: "58372"},
	{latitude: 47.824576, longitude: -99.095041, zipCode: "58374"},
	{latitude: 48.471646, longitude: -98.872965, zipCode: "58377"},
	{latitude: 47.896628, longitude: -98.821626, zipCode: "58379"},
	{latitude: 47.825153, longitude: -98.501854, zipCode: "58380"},
	{latitude: 47.825765, longitude: -98.748768, zipCode: "58381"},
	{latitude: 48.318001, longitude: -98.799048, zipCode: "58382"},
	{latitude: 48.595867, longitude: -100.26556, zipCode: "58384"},
	{latitude: 48.472729, longitude: -99.645585, zipCode: "58385"},
	{latitude: 48.318725, longitude: -99.598751, zipCode: "58386"},
	{latitude: 46.884312, longitude: -98.766742, zipCode: "58401"},
	{latitude: 46.881949, longitude: -98.687913, zipCode: "58402"},
	{latitude: 46.914592, longitude: -98.696736, zipCode: "58405"},
	{latitude: 45.985451, longitude: -99.332346, zipCode: "58413"},
	{latitude: 46.361573, longitude: -98.485453, zipCode: "58415"},
	{latitude: 47.551001, longitude: -98.361428, zipCode: "58416"},
	{latitude: 47.421423, longitude: -99.638932, zipCode: "58418"},
	{latitude: 47.061018, longitude: -98.85844, zipCode: "58420"},
	{latitude: 47.440419, longitude: -99.076593, zipCode: "58421"},
	{latitude: 47.593516, longitude: -99.405537, zipCode: "58422"},
	{latitude: 47.428453, longitude: -99.830534, zipCode: "58423"},
	{latitude: 46.878988, longitude: -99.094314, zipCode: "58424"},
	{latitude: 47.453704, longitude: -98.118284, zipCode: "58425"},
	{latitude: 47.218226, longitude: -98.579339, zipCode: "58426"},
	{latitude: 46.807934, longitude: -99.749171, zipCode: "58428"},
	{latitude: 47.204691, longitude: -98.17407, zipCode: "58429"},
	{latitude: 47.508825, longitude: -100.256876, zipCode: "58430"},
	{latitude: 46.508341, longitude: -98.503912, zipCode: "58431"},
	{latitude: 46.366412, longitude: -98.721914, zipCode: "58433"},
	{latitude: 46.076099, longitude: -98.579913, zipCode: "58436"},
	{latitude: 47.667399, longitude: -99.568378, zipCode: "58438"},
	{latitude: 45.968075, longitude: -98.830849, zipCode: "58439"},
	{latitude: 46.278297, longitude: -99.162138, zipCode: "58440"},
	{latitude: 46.175288, longitude: -98.368493, zipCode: "58441"},
	{latitude: 46.553679, longitude: -99.179594, zipCode: "58442"},
	{latitude: 47.464343, longitude: -98.681191, zipCode: "58443"},
	{latitude: 47.484024, longitude: -100.140744, zipCode: "58444"},
	{latitude: 47.557233, longitude: -98.78318, zipCode: "58445"},
	{latitude: 47.305788, longitude: -98.204932, zipCode: "58448"},
	{latitude: 47.450264, longitude: -99.971738, zipCode: "58451"},
	{latitude: 47.54019, longitude: -98.229147, zipCode: "58452"},
	{latitude: 46.584322, longitude: -98.951788, zipCode: "58454"},
	{latitude: 47.302513, longitude: -98.728706, zipCode: "58455"},
	{latitude: 46.251934, longitude: -98.953218, zipCode: "58456"},
	{latitude: 46.367944, longitude: -98.293849, zipCode: "58458"},
	{latitude: 46.309722, longitude: -99.32202, zipCode: "58460"},
	{latitude: 46.649509, longitude: -98.191611, zipCode: "58461"},
	{latitude: 47.491985, longitude: -100.473402, zipCode: "58463"},
	{latitude: 47.61293, longitude: -98.608539, zipCode: "58464"},
	{latitude: 46.609213, longitude: -98.369436, zipCode: "58466"},
	{latitude: 46.874843, longitude: -99.330452, zipCode: "58467"},
	{latitude: 46.638569, longitude: -98.650249, zipCode: "58472"},
	{latitude: 46.102707, longitude: -98.142267, zipCode: "58474"},
	{latitude: 47.146989, longitude: -99.534225, zipCode: "58475"},
	{latitude: 47.19385, longitude: -99.004507, zipCode: "58476"},
	{latitude: 47.220955, longitude: -100.520799, zipCode: "58477"},
	{latitude: 47.161778, longitude: -99.745442, zipCode: "58478"},
	{latitude: 47.088795, longitude: -98.20338, zipCode: "58479"},
	{latitude: 46.945029, longitude: -98.259337, zipCode: "58480"},
	{latitude: 46.934507, longitude: -98.423607, zipCode: "58481"},
	{latitude: 46.868757, longitude: -99.900895, zipCode: "58482"},
	{latitude: 46.668133, longitude: -99.432794, zipCode: "58483"},
	{latitude: 47.368583, longitude: -98.464276, zipCode: "58484"},
	{latitude: 47.396329, longitude: -99.3902, zipCode: "58486"},
	{latitude: 46.856829, longitude: -99.605753, zipCode: "58487"},
	{latitude: 47.17896, longitude: -100.025613, zipCode: "58488"},
	{latitude: 46.384285, longitude: -98.065943, zipCode: "58490"},
	{latitude: 47.161982, longitude: -98.424957, zipCode: "58492"},
	{latitude: 47.184899, longitude: -100.262087, zipCode: "58494"},
	{latitude: 46.271778, longitude: -99.601054, zipCode: "58495"},
	{latitude: 47.140886, longitude: -99.309026, zipCode: "58496"},
	{latitude: 46.768141, longitude: -98.508625, zipCode: "58497"},
	{latitude: 46.814932, longitude: -100.698195, zipCode: "58501"},
	{latitude: 46.906399, longitude: -100.752189, zipCode: "58503"},
	{latitude: 46.743148, longitude: -100.678745, zipCode: "58504"},
	{latitude: 46.820285, longitude: -100.781059, zipCode: "58505"},
	{latitude: 46.674232, longitude: -101.567958, zipCode: "58520"},
	{latitude: 47.027958, longitude: -100.673956, zipCode: "58521"},
	{latitude: 47.265005, longitude: -101.781567, zipCode: "58523"},
	{latitude: 46.622101, longitude: -100.073951, zipCode: "58524"},
	{latitude: 46.323996, longitude: -100.595218, zipCode: "58528"},
	{latitude: 46.304871, longitude: -101.580815, zipCode: "58529"},
	{latitude: 47.131904, longitude: -101.164206, zipCode: "58530"},
	{latitude: 47.578741, longitude: -101.235189, zipCode: "58531"},
	{latitude: 46.847398, longitude: -100.109279, zipCode: "58532"},
	{latitude: 46.394577, longitude: -101.796661, zipCode: "58533"},
	{latitude: 46.482107, longitude: -101.24899, zipCode: "58535"},
	{latitude: 46.081101, longitude: -100.659187, zipCode: "58538"},
	{latitude: 47.621778, longitude: -101.726395, zipCode: "58540"},
	{latitude: 47.339697, longitude: -102.044491, zipCode: "58541"},
	{latitude: 46.044646, longitude: -99.993945, zipCode: "58542"},
	{latitude: 46.512746, longitude: -100.372475, zipCode: "58544"},
	{latitude: 47.349585, longitude: -101.600309, zipCode: "58545"},
	{latitude: 46.463443, longitude: -99.937496, zipCode: "58549"},
	{latitude: 46.244129, longitude: -100.287175, zipCode: "58552"},
	{latitude: 46.755661, longitude: -100.948661, zipCode: "58554"},
	{latitude: 46.819776, longitude: -100.526091, zipCode: "58558"},
	{latitude: 47.471719, longitude: -100.694959, zipCode: "58559"},
	{latitude: 46.654902, longitude: -100.312936, zipCode: "58560"},
	{latitude: 46.492146, longitude: -99.663496, zipCode: "58561"},
	{latitude: 46.361322, longitude: -102.008606, zipCode: "58562"},
	{latitude: 46.898984, longitude: -101.439257, zipCode: "58563"},
	{latitude: 46.266095, longitude: -101.344234, zipCode: "58564"},
	{latitude: 47.496387, longitude: -101.371365, zipCode: "58565"},
	{latitude: 46.578368, longitude: -100.906689, zipCode: "58566"},
	{latitude: 46.097736, longitude: -100.998835, zipCode: "58568"},
	{latitude: 46.268099, longitude: -101.19937, zipCode: "58569"},
	{latitude: 46.375643, longitude: -100.872005, zipCode: "58570"},
	{latitude: 47.29236, longitude: -101.406757, zipCode: "58571"},
	{latitude: 46.868336, longitude: -100.339849, zipCode: "58572"},
	{latitude: 46.123803, longitude: -100.201889, zipCode: "58573"},
	{latitude: 47.551926, longitude: -100.89414, zipCode: "58575"},
	{latitude: 47.436851, longitude: -101.214174, zipCode: "58576"},
	{latitude: 47.317785, longitude: -101.052327, zipCode: "58577"},
	{latitude: 47.179421, longitude: -100.738366, zipCode: "58579"},
	{latitude: 47.267551, longitude: -101.943298, zipCode: "58580"},
	{latitude: 46.049857, longitude: -99.805692, zipCode: "58581"},
	{latitude: 46.908157, longitude: -102.826564, zipCode: "58601"},
	{latitude: 46.505983, longitude: -103.338434, zipCode: "58620"},
	{latitude: 47.121085, longitude: -103.944725, zipCode: "58621"},
	{latitude: 46.880016, longitude: -103.251986, zipCode: "58622"},
	{latitude: 46.160795, longitude: -103.423595, zipCode: "58623"},
	{latitude: 47.279664, longitude: -102.186143, zipCode: "58625"},
	{latitude: 47.377693, longitude: -102.602385, zipCode: "58626"},
	{latitude: 47.234399, longitude: -103.27265, zipCode: "58627"},
	{latitude: 46.926684, longitude: -102.57024, zipCode: "58630"},
	{latitude: 46.798625, longitude: -101.837035, zipCode: "58631"},
	{latitude: 46.672998, longitude: -103.965468, zipCode: "58632"},
	{latitude: 47.444717, longitude: -103.364216, zipCode: "58634"},
	{latitude: 47.393879, longitude: -102.345737, zipCode: "58636"},
	{latitude: 46.918358, longitude: -102.037572, zipCode: "58638"},
	{latitude: 46.066735, longitude: -102.650509, zipCode: "58639"},
	{latitude: 47.453697, longitude: -102.905423, zipCode: "58640"},
	{latitude: 46.66713, longitude: -102.486272, zipCode: "58641"},
	{latitude: 47.201319, longitude: -102.834401, zipCode: "58642"},
	{latitude: 46.216746, longitude: -103.959692, zipCode: "58643"},
	{latitude: 47.13237, longitude: -102.348714, zipCode: "58644"},
	{latitude: 46.951005, longitude: -103.549653, zipCode: "58645"},
	{latitude: 46.392913, longitude: -102.287643, zipCode: "58646"},
	{latitude: 46.518902, longitude: -102.936294, zipCode: "58647"},
	{latitude: 46.140068, longitude: -102.901957, zipCode: "58649"},
	{latitude: 46.437003, longitude: -102.620071, zipCode: "58650"},
	{latitude: 46.259288, longitude: -103.74136, zipCode: "58651"},
	{latitude: 46.905856, longitude: -102.244208, zipCode: "58652"},
	{latitude: 46.090443, longitude: -103.130019, zipCode: "58653"},
	{latitude: 46.784161, longitude: -103.823613, zipCode: "58654"},
	{latitude: 46.770815, longitude: -103.030896, zipCode: "58655"},
	{latitude: 46.955764, longitude: -102.464501, zipCode: "58656"},
	{latitude: 48.144826, longitude: -101.326016, zipCode: "58701"},
	{latitude: 48.321334, longitude: -101.304721, zipCode: "58703"},
	{latitude: 48.414329, longitude: -101.31841, zipCode: "58704"},
	{latitude: 48.416121, longitude: -101.351834, zipCode: "58705"},
	{latitude: 48.247706, longitude: -101.301268, zipCode: "58707"},
	{latitude: 47.868061, longitude: -100.231091, zipCode: "58710"},
	{latitude: 48.929604, longitude: -101.26715, zipCode: "58711"},
	{latitude: 47.970808, longitude: -100.553132, zipCode: "58712"},
	{latitude: 48.523646, longitude: -100.614056, zipCode: "58713"},
	{latitude: 47.804326, longitude: -101.067845, zipCode: "58716"},
	{latitude: 48.290906, longitude: -101.80355, zipCode: "58718"},
	{latitude: 48.821687, longitude: -102.254263, zipCode: "58721"},
	{latitude: 48.250002, longitude: -101.501006, zipCode: "58722"},
	{latitude: 47.745881, longitude: -100.596154, zipCode: "58723"},
	{latitude: 48.481063, longitude: -101.679896, zipCode: "58725"},
	{latitude: 48.857569, longitude: -102.804499, zipCode: "58727"},
	{latitude: 48.860175, longitude: -103.308172, zipCode: "58730"},
	{latitude: 48.448859, longitude: -100.983225, zipCode: "58731"},
	{latitude: 48.175009, longitude: -101.587881, zipCode: "58733"},
	{latitude: 48.51285, longitude: -101.957356, zipCode: "58734"},
	{latitude: 47.884246, longitude: -101.475674, zipCode: "58735"},
	{latitude: 47.930855, longitude: -100.396493, zipCode: "58736"},
	{latitude: 48.908015, longitude: -102.401421, zipCode: "58737"},
	{latitude: 48.466399, longitude: -101.222222, zipCode: "58740"},
	{latitude: 48.27684, longitude: -100.830768, zipCode: "58741"},
	{latitude: 48.120173, longitude: -100.591065, zipCode: "58744"},
	{latitude: 48.713443, longitude: -102.094053, zipCode: "58746"},
	{latitude: 48.66158, longitude: -100.635644, zipCode: "58748"},
	{latitude: 48.610954, longitude: -101.397529, zipCode: "58750"},
	{latitude: 48.821296, longitude: -102.598817, zipCode: "58752"},
	{latitude: 48.625964, longitude: -102.95095, zipCode: "58755"},
	{latitude: 47.970731, longitude: -101.814768, zipCode: "58756"},
	{latitude: 47.686282, longitude: -102.555817, zipCode: "58757"},
	{latitude: 47.768017, longitude: -100.090345, zipCode: "58758"},
	{latitude: 47.844676, longitude: -101.249698, zipCode: "58759"},
	{latitude: 48.697459, longitude: -101.135896, zipCode: "58760"},
	{latitude: 48.785989, longitude: -101.524267, zipCode: "58761"},
	{latitude: 48.673209, longitude: -100.940087, zipCode: "58762"},
	{latitude: 47.983958, longitude: -102.583404, zipCode: "58763"},
	{latitude: 48.873295, longitude: -103.042817, zipCode: "58765"},
	{latitude: 48.252046, longitude: -101.0238, zipCode: "58768"},
	{latitude: 48.305501, longitude: -102.235314, zipCode: "58769"},
	{latitude: 47.869389, longitude: -102.161202, zipCode: "58770"},
	{latitude: 48.088309, longitude: -101.976214, zipCode: "58771"},
	{latitude: 48.959575, longitude: -102.63371, zipCode: "58772"},
	{latitude: 48.58258, longitude: -102.657837, zipCode: "58773"},
	{latitude: 47.710059, longitude: -101.861201, zipCode: "58775"},
	{latitude: 48.247805, longitude: -102.623232, zipCode: "58776"},
	{latitude: 47.78649, longitude: -100.913005, zipCode: "58778"},
	{latitude: 47.85496, longitude: -101.753854, zipCode: "58779"},
	{latitude: 48.030114, longitude: -101.133973, zipCode: "58781"},
	{latitude: 48.944346, longitude: -101.70467, zipCode: "58782"},
	{latitude: 48.87979, longitude: -100.760464, zipCode: "58783"},
	{latitude: 48.359814, longitude: -102.423996, zipCode: "58784"},
	{latitude: 48.307069, longitude: -101.102285, zipCode: "58785"},
	{latitude: 48.756322, longitude: -101.813659, zipCode: "58787"},
	{latitude: 48.325232, longitude: -100.477506, zipCode: "58788"},
	{latitude: 48.553182, longitude: -100.808895, zipCode: "58789"},
	{latitude: 48.029571, longitude: -100.947714, zipCode: "58790"},
	{latitude: 47.971228, longitude: -100.746785, zipCode: "58792"},
	{latitude: 48.869225, longitude: -101.051028, zipCode: "58793"},
	{latitude: 48.269343, longitude: -102.791266, zipCode: "58794"},
	{latitude: 48.640339, longitude: -103.156669, zipCode: "58795"},
	{latitude: 48.238486, longitude: -103.769649, zipCode: "58801"},
	{latitude: 48.59734, longitude: -103.461192, zipCode: "58830"},
	{latitude: 47.854223, longitude: -103.652015, zipCode: "58831"},
	{latitude: 48.887153, longitude: -103.520333, zipCode: "58833"},
	{latitude: 47.736663, longitude: -103.449132, zipCode: "58835"},
	{latitude: 47.769991, longitude: -103.900837, zipCode: "58838"},
	{latitude: 48.23386, longitude: -103.37749, zipCode: "58843"},
	{latitude: 48.887526, longitude: -103.749952, zipCode: "58844"},
	{latitude: 48.641039, longitude: -103.917535, zipCode: "58845"},
	{latitude: 47.888875, longitude: -102.906185, zipCode: "58847"},
	{latitude: 48.307903, longitude: -103.163231, zipCode: "58849"},
	{latitude: 48.381568, longitude: -102.931933, zipCode: "58852"},
	{latitude: 48.061128, longitude: -103.72747, zipCode: "58853"},
	{latitude: 47.828372, longitude: -103.153255, zipCode: "58854"},
	{latitude: 48.595028, longitude: -103.732864, zipCode: "58856"},
	{latitude: 45.530159, longitude: -109.539994, zipCode: "59001"},
	{latitude: 46.02272, longitude: -108.66561, zipCode: "59002"},
	{latitude: 45.491982, longitude: -106.200861, zipCode: "59003"},
	{latitude: 45.92083, longitude: -108.08888, zipCode: "59006"},
	{latitude: 45.170774, longitude: -109.194932, zipCode: "59007"},
	{latitude: 45.079746, longitude: -109.07594, zipCode: "59008"},
	{latitude: 45.965205, longitude: -107.299311, zipCode: "59010"},
	{latitude: 45.924721, longitude: -109.948625, zipCode: "59011"},
	{latitude: 45.323605, longitude: -106.627201, zipCode: "59012"},
	{latitude: 45.461319, longitude: -109.080551, zipCode: "59013"},
	{latitude: 45.155169, longitude: -108.667654, zipCode: "59014"},
	{latitude: 46.085105, longitude: -108.828741, zipCode: "59015"},
	{latitude: 45.457702, longitude: -106.947988, zipCode: "59016"},
	{latitude: 45.850287, longitude: -110.623921, zipCode: "59018"},
	{latitude: 45.628692, longitude: -109.240732, zipCode: "59019"},
	{latitude: 45.108043, longitude: -110.10062, zipCode: "59020"},
	{latitude: 45.570481, longitude: -107.261652, zipCode: "59022"},
	{latitude: 46.278426, longitude: -107.807114, zipCode: "59024"},
	{latitude: 45.085978, longitude: -106.704232, zipCode: "59025"},
	{latitude: 45.48538, longitude: -108.784438, zipCode: "59026"},
	{latitude: 45.259227, longitude: -110.904444, zipCode: "59027"},
	{latitude: 45.30247, longitude: -109.713736, zipCode: "59028"},
	{latitude: 45.397384, longitude: -108.802501, zipCode: "59029"},
	{latitude: 45.086943, longitude: -110.596164, zipCode: "59030"},
	{latitude: 45.498166, longitude: -107.374537, zipCode: "59031"},
	{latitude: 47.024576, longitude: -108.70277, zipCode: "59032"},
	{latitude: 45.724181, longitude: -109.73519, zipCode: "59033"},
	{latitude: 45.700376, longitude: -107.783469, zipCode: "59034"},
	{latitude: 45.304795, longitude: -107.94896, zipCode: "59035"},
	{latitude: 46.459994, longitude: -109.88342, zipCode: "59036"},
	{latitude: 45.864795, longitude: -108.210799, zipCode: "59037"},
	{latitude: 46.221886, longitude: -107.208456, zipCode: "59038"},
	{latitude: 46.686774, longitude: -107.291122, zipCode: "59039"},
	{latitude: 45.501093, longitude: -108.968152, zipCode: "59041"},
	{latitude: 45.550795, longitude: -106.676407, zipCode: "59043"},
	{latitude: 45.643813, longitude: -108.732471, zipCode: "59044"},
	{latitude: 46.361298, longitude: -108.950437, zipCode: "59046"},
	{latitude: 45.577413, longitude: -110.55021, zipCode: "59047"},
	{latitude: 45.259182, longitude: -107.476204, zipCode: "59050"},
	{latitude: 45.465798, longitude: -110.145521, zipCode: "59052"},
	{latitude: 46.44153, longitude: -110.432354, zipCode: "59053"},
	{latitude: 46.668547, longitude: -107.772165, zipCode: "59054"},
	{latitude: 46.108998, longitude: -109.809893, zipCode: "59055"},
	{latitude: 45.853605, longitude: -108.953837, zipCode: "59057"},
	{latitude: 46.987662, longitude: -107.760242, zipCode: "59058"},
	{latitude: 46.511035, longitude: -108.073924, zipCode: "59059"},
	{latitude: 45.424609, longitude: -109.857145, zipCode: "59061"},
	{latitude: 45.151007, longitude: -106.218145, zipCode: "59062"},
	{latitude: 45.652232, longitude: -108.965717, zipCode: "59063"},
	{latitude: 45.879402, longitude: -107.871378, zipCode: "59064"},
	{latitude: 45.271112, longitude: -110.673496, zipCode: "59065"},
	{latitude: 45.335877, longitude: -108.510261, zipCode: "59066"},
	{latitude: 45.970297, longitude: -109.223835, zipCode: "59067"},
	{latitude: 45.087055, longitude: -109.558392, zipCode: "59068"},
	{latitude: 45.726695, longitude: -109.582747, zipCode: "59069"},
	{latitude: 45.364899, longitude: -109.181751, zipCode: "59070"},
	{latitude: 45.2462, longitude: -109.629564, zipCode: "59071"},
	{latitude: 46.525989, longitude: -108.553737, zipCode: "59072"},
	{latitude: 46.364496, longitude: -109.298528, zipCode: "59074"},
	{latitude: 45.406314, longitude: -107.848049, zipCode: "59075"},
	{latitude: 46.282518, longitude: -107.068474, zipCode: "59076"},
	{latitude: 47.244813, longitude: -107.592797, zipCode: "59077"},
	{latitude: 46.42268, longitude: -109.489288, zipCode: "59078"},
	{latitude: 46.062118, longitude: -108.363307, zipCode: "59079"},
	{latitude: 45.019784, longitude: -109.982939, zipCode: "59081"},
	{latitude: 45.849173, longitude: -110.290444, zipCode: "59082"},
	{latitude: 46.563108, longitude: -107.559493, zipCode: "59083"},
	{latitude: 46.323085, longitude: -110.187893, zipCode: "59085"},
	{latitude: 46.061326, longitude: -110.662681, zipCode: "59086"},
	{latitude: 47.141917, longitude: -108.226575, zipCode: "59087"},
	{latitude: 46.130285, longitude: -108.055419, zipCode: "59088"},
	{latitude: 45.091886, longitude: -107.315133, zipCode: "59089"},
	{latitude: 45.607108, longitude: -108.341517, zipCode: "59101"},
	{latitude: 45.774768, longitude: -108.581321, zipCode: "59102"},
	{latitude: 45.892879, longitude: -108.497876, zipCode: "59105"},
	{latitude: 45.813757, longitude: -108.7098, zipCode: "59106"},
	{latitude: 48.141944, longitude: -105.626831, zipCode: "59201"},
	{latitude: 48.698644, longitude: -104.349863, zipCode: "59211"},
	{latitude: 48.222386, longitude: -104.143067, zipCode: "59212"},
	{latitude: 48.188882, longitude: -104.886575, zipCode: "59213"},
	{latitude: 47.299772, longitude: -106.038986, zipCode: "59214"},
	{latitude: 47.516636, longitude: -105.769079, zipCode: "59215"},
	{latitude: 47.579432, longitude: -104.238602, zipCode: "59217"},
	{latitude: 48.075949, longitude: -104.491631, zipCode: "59218"},
	{latitude: 48.571433, longitude: -104.157731, zipCode: "59219"},
	{latitude: 47.899749, longitude: -104.164477, zipCode: "59221"},
	{latitude: 48.700902, longitude: -105.158539, zipCode: "59222"},
	{latitude: 47.912807, longitude: -106.206866, zipCode: "59223"},
	{latitude: 48.26964, longitude: -105.967526, zipCode: "59225"},
	{latitude: 48.347861, longitude: -104.530464, zipCode: "59226"},
	{latitude: 48.131171, longitude: -106.798317, zipCode: "59230"},
	{latitude: 48.403916, longitude: -106.544215, zipCode: "59231"},
	{latitude: 48.842241, longitude: -106.240509, zipCode: "59240"},
	{latitude: 48.458162, longitude: -107.050557, zipCode: "59241"},
	{latitude: 48.411173, longitude: -104.443382, zipCode: "59242"},
	{latitude: 47.757547, longitude: -104.679209, zipCode: "59243"},
	{latitude: 48.619958, longitude: -106.304401, zipCode: "59244"},
	{latitude: 48.516207, longitude: -104.523515, zipCode: "59247"},
	{latitude: 48.245397, longitude: -106.249241, zipCode: "59248"},
	{latitude: 48.844643, longitude: -106.623677, zipCode: "59250"},
	{latitude: 48.94671, longitude: -104.837622, zipCode: "59252"},
	{latitude: 48.736375, longitude: -105.858224, zipCode: "59253"},
	{latitude: 48.751679, longitude: -104.640564, zipCode: "59254"},
	{latitude: 48.236464, longitude: -105.158947, zipCode: "59255"},
	{latitude: 48.907561, longitude: -104.5528, zipCode: "59256"},
	{latitude: 48.753635, longitude: -104.942304, zipCode: "59257"},
	{latitude: 48.583548, longitude: -104.655046, zipCode: "59258"},
	{latitude: 47.680959, longitude: -105.099852, zipCode: "59259"},
	{latitude: 48.856867, longitude: -106.119737, zipCode: "59260"},
	{latitude: 48.529143, longitude: -107.422067, zipCode: "59261"},
	{latitude: 47.489669, longitude: -104.497165, zipCode: "59262"},
	{latitude: 48.791171, longitude: -105.514255, zipCode: "59263"},
	{latitude: 47.609447, longitude: -104.086114, zipCode: "59270"},
	{latitude: 48.306968, longitude: -106.942674, zipCode: "59273"},
	{latitude: 47.846151, longitude: -105.418762, zipCode: "59274"},
	{latitude: 48.877437, longitude: -104.156433, zipCode: "59275"},
	{latitude: 48.965743, longitude: -105.214128, zipCode: "59276"},
	{latitude: 46.360165, longitude: -105.867454, zipCode: "59301"},
	{latitude: 45.162865, longitude: -104.549317, zipCode: "59311"},
	{latitude: 47.037569, longitude: -106.263943, zipCode: "59312"},
	{latitude: 46.257762, longitude: -104.225586, zipCode: "59313"},
	{latitude: 45.067734, longitude: -105.433561, zipCode: "59314"},
	{latitude: 47.4523, longitude: -104.868428, zipCode: "59315"},
	{latitude: 45.347046, longitude: -105.071917, zipCode: "59316"},
	{latitude: 45.337829, longitude: -105.519039, zipCode: "59317"},
	{latitude: 47.501154, longitude: -107.51385, zipCode: "59318"},
	{latitude: 45.411852, longitude: -104.165377, zipCode: "59319"},
	{latitude: 47.050884, longitude: -106.688672, zipCode: "59322"},
	{latitude: 45.916835, longitude: -106.722011, zipCode: "59323"},
	{latitude: 45.768923, longitude: -104.56924, zipCode: "59324"},
	{latitude: 46.770357, longitude: -104.859403, zipCode: "59326"},
	{latitude: 46.236772, longitude: -106.682233, zipCode: "59327"},
	{latitude: 47.12112, longitude: -104.763541, zipCode: "59330"},
	{latitude: 45.389696, longitude: -104.698552, zipCode: "59332"},
	{latitude: 46.277146, longitude: -106.194636, zipCode: "59333"},
	{latitude: 46.348832, longitude: -104.954101, zipCode: "59336"},
	{latitude: 47.40652, longitude: -106.816654, zipCode: "59337"},
	{latitude: 46.640459, longitude: -105.640492, zipCode: "59338"},
	{latitude: 47.225333, longitude: -105.271248, zipCode: "59339"},
	{latitude: 45.662478, longitude: -105.426431, zipCode: "59343"},
	{latitude: 46.356859, longitude: -104.597278, zipCode: "59344"},
	{latitude: 45.860143, longitude: -105.212007, zipCode: "59345"},
	{latitude: 46.420675, longitude: -106.365853, zipCode: "59347"},
	{latitude: 46.812384, longitude: -105.503994, zipCode: "59349"},
	{latitude: 45.759265, longitude: -105.711281, zipCode: "59351"},
	{latitude: 46.962966, longitude: -104.274518, zipCode: "59353"},
	{latitude: 46.177031, longitude: -104.420639, zipCode: "59354"},
	{latitude: 47.511227, longitude: -111.27724, zipCode: "59401"},
	{latitude: 47.631766, longitude: -111.32887, zipCode: "59404"},
	{latitude: 47.288991, longitude: -111.300456, zipCode: "59405"},
	{latitude: 47.503548, longitude: -112.6469, zipCode: "59410"},
	{latitude: 48.892066, longitude: -113.398244, zipCode: "59411"},
	{latitude: 47.303324, longitude: -110.86368, zipCode: "59412"},
	{latitude: 47.527748, longitude: -111.259956, zipCode: "59414"},
	{latitude: 48.037972, longitude: -111.526826, zipCode: "59416"},
	{latitude: 48.552149, longitude: -113.001927, zipCode: "59417"},
	{latitude: 46.803651, longitude: -109.855632, zipCode: "59418"},
	{latitude: 47.966391, longitude: -112.316989, zipCode: "59419"},
	{latitude: 47.877548, longitude: -111.003871, zipCode: "59420"},
	{latitude: 47.074847, longitude: -111.688368, zipCode: "59421"},
	{latitude: 47.834255, longitude: -112.488851, zipCode: "59422"},
	{latitude: 47.353027, longitude: -110.108208, zipCode: "59424"},
	{latitude: 48.187163, longitude: -111.898576, zipCode: "59425"},
	{latitude: 48.740743, longitude: -112.666436, zipCode: "59427"},
	{latitude: 47.430977, longitude: -109.78019, zipCode: "59430"},
	{latitude: 48.156207, longitude: -112.546145, zipCode: "59432"},
	{latitude: 47.883621, longitude: -111.69203, zipCode: "59433"},
	{latitude: 48.698205, longitude: -113.507207, zipCode: "59434"},
	{latitude: 48.576987, longitude: -112.039245, zipCode: "59435"},
	{latitude: 47.616272, longitude: -112.11985, zipCode: "59436"},
	{latitude: 47.715998, longitude: -111.158569, zipCode: "59440"},
	{latitude: 46.827529, longitude: -109.069119, zipCode: "59441"},
	{latitude: 47.928944, longitude: -110.651689, zipCode: "59442"},
	{latitude: 47.563934, longitude: -111.828933, zipCode: "59443"},
	{latitude: 48.650917, longitude: -111.380414, zipCode: "59444"},
	{latitude: 47.65059, longitude: -110.152437, zipCode: "59446"},
	{latitude: 47.286249, longitude: -110.450024, zipCode: "59447"},
	{latitude: 48.263152, longitude: -112.849472, zipCode: "59448"},
	{latitude: 47.552457, longitude: -110.742814, zipCode: "59450"},
	{latitude: 47.390215, longitude: -109.390087, zipCode: "59451"},
	{latitude: 46.892567, longitude: -110.061256, zipCode: "59452"},
	{latitude: 46.648823, longitude: -109.681412, zipCode: "59453"},
	{latitude: 48.76895, longitude: -111.985097, zipCode: "59454"},
	{latitude: 48.27015, longitude: -111.36167, zipCode: "59456"},
	{latitude: 47.139731, longitude: -109.310887, zipCode: "59457"},
	{latitude: 48.074045, longitude: -110.506987, zipCode: "59460"},
	{latitude: 48.471199, longitude: -111.211268, zipCode: "59461"},
	{latitude: 47.105394, longitude: -109.893333, zipCode: "59462"},
	{latitude: 47.048919, longitude: -110.86667, zipCode: "59463"},
	{latitude: 46.83955, longitude: -109.616825, zipCode: "59464"},
	{latitude: 46.93772, longitude: -110.707881, zipCode: "59465"},
	{latitude: 48.718414, longitude: -111.774954, zipCode: "59466"},
	{latitude: 48.069718, longitude: -112.402314, zipCode: "59467"},
	{latitude: 47.727591, longitude: -111.634236, zipCode: "59468"},
	{latitude: 47.190808, longitude: -110.683742, zipCode: "59469"},
	{latitude: 47.426655, longitude: -108.772077, zipCode: "59471"},
	{latitude: 47.338846, longitude: -111.207122, zipCode: "59472"},
	{latitude: 48.529477, longitude: -111.690738, zipCode: "59474"},
	{latitude: 47.44284, longitude: -111.974484, zipCode: "59477"},
	{latitude: 47.032558, longitude: -110.30532, zipCode: "59479"},
	{latitude: 47.213659, longitude: -111.074636, zipCode: "59480"},
	{latitude: 48.863808, longitude: -111.851602, zipCode: "59482"},
	{latitude: 47.464924, longitude: -111.732896, zipCode: "59483"},
	{latitude: 48.959575, longitude: -111.538713, zipCode: "59484"},
	{latitude: 47.432255, longitude: -111.568631, zipCode: "59485"},
	{latitude: 48.340494, longitude: -112.377361, zipCode: "59486"},
	{latitude: 47.587366, longitude: -111.638996, zipCode: "59487"},
	{latitude: 47.624666, longitude: -109.230405, zipCode: "59489"},
	{latitude: 48.689867, longitude: -109.781085, zipCode: "59501"},
	{latitude: 48.038123, longitude: -109.931777, zipCode: "59520"},
	{latitude: 48.305039, longitude: -109.951289, zipCode: "59521"},
	{latitude: 48.556788, longitude: -110.976831, zipCode: "59522"},
	{latitude: 48.526003, longitude: -109.198574, zipCode: "59523"},
	{latitude: 48.138817, longitude: -108.402446, zipCode: "59524"},
	{latitude: 48.641724, longitude: -110.261511, zipCode: "59525"},
	{latitude: 48.452755, longitude: -108.701335, zipCode: "59526"},
	{latitude: 48.04166, longitude: -108.791566, zipCode: "59527"},
	{latitude: 48.617209, longitude: -110.43386, zipCode: "59528"},
	{latitude: 48.797048, longitude: -108.678763, zipCode: "59529"},
	{latitude: 48.600914, longitude: -110.695944, zipCode: "59530"},
	{latitude: 48.776638, longitude: -110.794634, zipCode: "59531"},
	{latitude: 48.593531, longitude: -110.080727, zipCode: "59532"},
	{latitude: 47.941002, longitude: -109.252867, zipCode: "59535"},
	{latitude: 48.843205, longitude: -107.988067, zipCode: "59537"},
	{latitude: 48.026235, longitude: -107.840361, zipCode: "59538"},
	{latitude: 48.604919, longitude: -110.563581, zipCode: "59540"},
	{latitude: 48.821862, longitude: -108.33575, zipCode: "59542"},
	{latitude: 48.803387, longitude: -107.555214, zipCode: "59544"},
	{latitude: 48.939152, longitude: -111.21972, zipCode: "59545"},
	{latitude: 47.899298, longitude: -108.539285, zipCode: "59546"},
	{latitude: 48.703466, longitude: -108.98602, zipCode: "59547"},
	{latitude: 46.53069, longitude: -112.199398, zipCode: "59601"},
	{latitude: 46.726915, longitude: -111.908265, zipCode: "59602"},
	{latitude: 46.589915, longitude: -112.040677, zipCode: "59623"},
	{latitude: 46.601439, longitude: -112.038815, zipCode: "59625"},
	{latitude: 46.290605, longitude: -112.388976, zipCode: "59631"},
	{latitude: 46.169453, longitude: -112.004042, zipCode: "59632"},
	{latitude: 46.818847, longitude: -112.342931, zipCode: "59633"},
	{latitude: 46.444934, longitude: -112.031499, zipCode: "59634"},
	{latitude: 46.555315, longitude: -111.814234, zipCode: "59635"},
	{latitude: 46.61614, longitude: -112.114018, zipCode: "59636"},
	{latitude: 46.357903, longitude: -112.027699, zipCode: "59638"},
	{latitude: 47.192032, longitude: -112.74729, zipCode: "59639"},
	{latitude: 46.768033, longitude: -112.367823, zipCode: "59640"},
	{latitude: 46.265344, longitude: -110.757381, zipCode: "59642"},
	{latitude: 46.134746, longitude: -111.579908, zipCode: "59643"},
	{latitude: 46.416678, longitude: -111.442641, zipCode: "59644"},
	{latitude: 46.687672, longitude: -111.054662, zipCode: "59645"},
	{latitude: 46.462548, longitude: -111.65395, zipCode: "59647"},
	{latitude: 47.077021, longitude: -112.195965, zipCode: "59648"},
	{latitude: 46.032709, longitude: -112.464159, zipCode: "59701"},
	{latitude: 45.054132, longitude: -112.081261, zipCode: "59710"},
	{latitude: 46.094672, longitude: -113.141624, zipCode: "59711"},
	{latitude: 46.659451, longitude: -112.61914, zipCode: "59713"},
	{latitude: 45.972611, longitude: -111.133999, zipCode: "59714"},
	{latitude: 45.693722, longitude: -110.906093, zipCode: "59715"},
	{latitude: 45.287116, longitude: -111.38874, zipCode: "59716"},
	{latitude: 45.639468, longitude: -111.171171, zipCode: "59718"},
	{latitude: 44.939235, longitude: -111.58923, zipCode: "59720"},
	{latitude: 45.852021, longitude: -111.863151, zipCode: "59721"},
	{latitude: 46.352854, longitude: -112.752885, zipCode: "59722"},
	{latitude: 44.661167, longitude: -112.804654, zipCode: "59724"},
	{latitude: 45.073828, longitude: -112.834673, zipCode: "59725"},
	{latitude: 45.799384, longitude: -112.830888, zipCode: "59727"},
	{latitude: 46.508912, longitude: -112.434837, zipCode: "59728"},
	{latitude: 45.324524, longitude: -111.651181, zipCode: "59729"},
	{latitude: 45.208226, longitude: -111.128685, zipCode: "59730"},
	{latitude: 46.579108, longitude: -112.761137, zipCode: "59731"},
	{latitude: 45.500048, longitude: -112.692418, zipCode: "59732"},
	{latitude: 46.544949, longitude: -112.958189, zipCode: "59733"},
	{latitude: 45.649764, longitude: -111.849852, zipCode: "59735"},
	{latitude: 45.344184, longitude: -113.342623, zipCode: "59736"},
	{latitude: 44.660246, longitude: -112.199789, zipCode: "59739"},
	{latitude: 45.503611, longitude: -111.814148, zipCode: "59740"},
	{latitude: 45.769824, longitude: -111.368344, zipCode: "59741"},
	{latitude: 45.601215, longitude: -112.764722, zipCode: "59743"},
	{latitude: 45.595195, longitude: -111.614802, zipCode: "59745"},
	{latitude: 45.384931, longitude: -113.059097, zipCode: "59746"},
	{latitude: 45.696862, longitude: -111.961765, zipCode: "59747"},
	{latitude: 46.046104, longitude: -112.696033, zipCode: "59748"},
	{latitude: 45.454116, longitude: -112.072389, zipCode: "59749"},
	{latitude: 45.916938, longitude: -112.684982, zipCode: "59750"},
	{latitude: 45.689965, longitude: -112.297556, zipCode: "59751"},
	{latitude: 45.916184, longitude: -111.526184, zipCode: "59752"},
	{latitude: 45.519283, longitude: -112.356711, zipCode: "59754"},
	{latitude: 45.270967, longitude: -111.943619, zipCode: "59755"},
	{latitude: 46.174143, longitude: -112.834586, zipCode: "59756"},
	{latitude: 44.799883, longitude: -111.19038, zipCode: "59758"},
	{latitude: 45.845245, longitude: -112.165946, zipCode: "59759"},
	{latitude: 45.755936, longitude: -111.592435, zipCode: "59760"},
	{latitude: 45.575969, longitude: -113.556204, zipCode: "59761"},
	{latitude: 45.948586, longitude: -113.333599, zipCode: "59762"},
	{latitude: 46.854689, longitude: -114.010437, zipCode: "59801"},
	{latitude: 46.903959, longitude: -113.91929, zipCode: "59802"},
	{latitude: 46.784478, longitude: -113.946271, zipCode: "59803"},
	{latitude: 46.878743, longitude: -114.264888, zipCode: "59804"},
	{latitude: 46.961731, longitude: -114.091961, zipCode: "59808"},
	{latitude: 46.861226, longitude: -113.984509, zipCode: "59812"},
	{latitude: 46.897452, longitude: -114.609123, zipCode: "59820"},
	{latitude: 47.2091, longitude: -114.022396, zipCode: "59821"},
	{latitude: 46.967846, longitude: -113.604667, zipCode: "59823"},
	{latitude: 47.413466, longitude: -114.207383, zipCode: "59824"},
	{latitude: 46.61446, longitude: -113.648701, zipCode: "59825"},
	{latitude: 47.462573, longitude: -113.704157, zipCode: "59826"},
	{latitude: 45.892583, longitude: -114.088429, zipCode: "59827"},
	{latitude: 46.326969, longitude: -113.958867, zipCode: "59828"},
	{latitude: 45.750336, longitude: -114.177836, zipCode: "59829"},
	{latitude: 47.434619, longitude: -115.45719, zipCode: "59830"},
	{latitude: 47.26012, longitude: -114.368312, zipCode: "59831"},
	{latitude: 46.688956, longitude: -113.252857, zipCode: "59832"},
	{latitude: 46.648016, longitude: -114.074173, zipCode: "59833"},
	{latitude: 47.079584, longitude: -114.221812, zipCode: "59834"},
	{latitude: 46.535047, longitude: -113.283235, zipCode: "59837"},
	{latitude: 46.166692, longitude: -114.105097, zipCode: "59840"},
	{latitude: 46.334211, longitude: -114.222998, zipCode: "59841"},
	{latitude: 47.33015, longitude: -115.43801, zipCode: "59842"},
	{latitude: 46.853556, longitude: -113.013789, zipCode: "59843"},
	{latitude: 48.000416, longitude: -115.939432, zipCode: "59844"},
	{latitude: 47.690256, longitude: -114.554685, zipCode: "59845"},
	{latitude: 47.151793, longitude: -114.513188, zipCode: "59846"},
	{latitude: 46.715693, longitude: -114.370083, zipCode: "59847"},
	{latitude: 47.710009, longitude: -114.689787, zipCode: "59848"},
	{latitude: 46.871545, longitude: -113.876577, zipCode: "59851"},
	{latitude: 48.042017, longitude: -115.790724, zipCode: "59853"},
	{latitude: 47.275618, longitude: -113.234187, zipCode: "59854"},
	{latitude: 47.600266, longitude: -114.117887, zipCode: "59855"},
	{latitude: 47.379863, longitude: -114.737432, zipCode: "59856"},
	{latitude: 46.285772, longitude: -113.467406, zipCode: "59858"},
	{latitude: 47.519781, longitude: -114.795216, zipCode: "59859"},
	{latitude: 47.715801, longitude: -114.18621, zipCode: "59860"},
	{latitude: 47.300207, longitude: -114.182582, zipCode: "59863"},
	{latitude: 47.531668, longitude: -114.119557, zipCode: "59864"},
	{latitude: 47.332182, longitude: -113.998307, zipCode: "59865"},
	{latitude: 47.342903, longitude: -115.120402, zipCode: "59866"},
	{latitude: 47.390653, longitude: -115.562589, zipCode: "59867"},
	{latitude: 47.221041, longitude: -113.54046, zipCode: "59868"},
	{latitude: 46.516308, longitude: -114.06345, zipCode: "59870"},
	{latitude: 45.89215, longitude: -113.816683, zipCode: "59871"},
	{latitude: 47.104767, longitude: -114.908845, zipCode: "59872"},
	{latitude: 47.662662, longitude: -115.273133, zipCode: "59873"},
	{latitude: 47.807052, longitude: -115.600358, zipCode: "59874"},
	{latitude: 46.420377, longitude: -114.315601, zipCode: "59875"},
	{latitude: 48.230565, longitude: -114.403313, zipCode: "59901"},
	{latitude: 47.820761, longitude: -114.235507, zipCode: "59910"},
	{latitude: 47.864278, longitude: -113.884598, zipCode: "59911"},
	{latitude: 48.436986, longitude: -114.168722, zipCode: "59912"},
	{latitude: 48.433503, longitude: -113.959142, zipCode: "59913"},
	{latitude: 47.876133, longitude: -114.303177, zipCode: "59914"},
	{latitude: 47.820334, longitude: -114.411263, zipCode: "59915"},
	{latitude: 48.306528, longitude: -113.657165, zipCode: "59916"},
	{latitude: 48.934642, longitude: -114.876703, zipCode: "59917"},
	{latitude: 48.781333, longitude: -114.758611, zipCode: "59918"},
	{latitude: 48.377418, longitude: -114.056424, zipCode: "59919"},
	{latitude: 48.002764, longitude: -114.608268, zipCode: "59920"},
	{latitude: 48.003043, longitude: -114.222136, zipCode: "59922"},
	{latitude: 48.270626, longitude: -115.313164, zipCode: "59923"},
	{latitude: 48.044436, longitude: -114.832615, zipCode: "59925"},
	{latitude: 48.352184, longitude: -113.995596, zipCode: "59926"},
	{latitude: 48.559744, longitude: -114.712763, zipCode: "59927"},
	{latitude: 48.797334, longitude: -114.292454, zipCode: "59928"},
	{latitude: 47.915932, longitude: -114.405183, zipCode: "59929"},
	{latitude: 48.866728, longitude: -115.133187, zipCode: "59930"},
	{latitude: 47.922117, longitude: -114.254551, zipCode: "59931"},
	{latitude: 48.05217, longitude: -114.242598, zipCode: "59932"},
	{latitude: 48.71944, longitude: -114.768386, zipCode: "59933"},
	{latitude: 48.636606, longitude: -114.931778, zipCode: "59934"},
	{latitude: 48.649762, longitude: -115.834835, zipCode: "59935"},
	{latitude: 48.189819, longitude: -113.473635, zipCode: "59936"},
	{latitude: 48.436974, longitude: -114.499016, zipCode: "59937"},
	{latitude: 42.467143, longitude: -88.094339, zipCode: "60002"},
	{latitude: 42.11278, longitude: -87.979542, zipCode: "60004"},
	{latitude: 42.064472, longitude: -87.98669, zipCode: "60005"},
	{latitude: 42.023977, longitude: -87.999779, zipCode: "60007"},
	{latitude: 42.070412, longitude: -88.022247, zipCode: "60008"},
	{latitude: 42.146498, longitude: -88.164654, zipCode: "60010"},
	{latitude: 42.272621, longitude: -88.31157, zipCode: "60012"},
	{latitude: 42.223514, longitude: -88.235896, zipCode: "60013"},
	{latitude: 42.230279, longitude: -88.329654, zipCode: "60014"},
	{latitude: 42.173681, longitude: -87.878362, zipCode: "60015"},
	{latitude: 42.052719, longitude: -87.892061, zipCode: "60016"},
	{latitude: 41.978872, longitude: -87.894342, zipCode: "60018"},
	{latitude: 42.394859, longitude: -88.174127, zipCode: "60020"},
	{latitude: 42.194762, longitude: -88.217181, zipCode: "60021"},
	{latitude: 42.136137, longitude: -87.760474, zipCode: "60022"},
	{latitude: 42.074632, longitude: -87.811581, zipCode: "60025"},
	{latitude: 42.092205, longitude: -87.837913, zipCode: "60026"},
	{latitude: 42.058549, longitude: -87.784941, zipCode: "60029"},
	{latitude: 42.335635, longitude: -88.046782, zipCode: "60030"},
	{latitude: 42.373189, longitude: -87.939664, zipCode: "60031"},
	{latitude: 42.423429, longitude: -88.610465, zipCode: "60033"},
	{latitude: 42.458664, longitude: -88.426643, zipCode: "60034"},
	{latitude: 42.185968, longitude: -87.805939, zipCode: "60035"},
	{latitude: 42.206525, longitude: -87.812818, zipCode: "60040"},
	{latitude: 42.36958, longitude: -88.153504, zipCode: "60041"},
	{latitude: 42.278327, longitude: -88.195495, zipCode: "60042"},
	{latitude: 42.088818, longitude: -87.714497, zipCode: "60043"},
	{latitude: 42.288124, longitude: -87.860081, zipCode: "60044"},
	{latitude: 42.235997, longitude: -87.864777, zipCode: "60045"},
	{latitude: 42.415962, longitude: -88.065351, zipCode: "60046"},
	{latitude: 42.201564, longitude: -88.046295, zipCode: "60047"},
	{latitude: 42.292606, longitude: -87.945317, zipCode: "60048"},
	{latitude: 42.329613, longitude: -88.293245, zipCode: "60050"},
	{latitude: 42.350262, longitude: -88.221054, zipCode: "60051"},
	{latitude: 42.042239, longitude: -87.788928, zipCode: "60053"},
	{latitude: 42.065378, longitude: -87.93471, zipCode: "60056"},
	{latitude: 42.270125, longitude: -88.039327, zipCode: "60060"},
	{latitude: 42.232851, longitude: -87.95893, zipCode: "60061"},
	{latitude: 42.126279, longitude: -87.845088, zipCode: "60062"},
	{latitude: 42.324058, longitude: -87.86157, zipCode: "60064"},
	{latitude: 42.108134, longitude: -88.064335, zipCode: "60067"},
	{latitude: 42.013588, longitude: -87.844655, zipCode: "60068"},
	{latitude: 42.196149, longitude: -87.923458, zipCode: "60069"},
	{latitude: 42.103663, longitude: -87.928972, zipCode: "60070"},
	{latitude: 42.46845, longitude: -88.315807, zipCode: "60071"},
	{latitude: 42.406458, longitude: -88.306351, zipCode: "60072"},
	{latitude: 42.354458, longitude: -88.102421, zipCode: "60073"},
	{latitude: 42.131462, longitude: -88.026329, zipCode: "60074"},
	{latitude: 42.492576, longitude: -87.908663, zipCode: "60075"},
	{latitude: 42.034133, longitude: -87.729445, zipCode: "60076"},
	{latitude: 42.036154, longitude: -87.756328, zipCode: "60077"},
	{latitude: 42.449091, longitude: -88.226705, zipCode: "60081"},
	{latitude: 42.436841, longitude: -87.949952, zipCode: "60083"},
	{latitude: 42.269002, longitude: -88.139094, zipCode: "60084"},
	{latitude: 42.354935, longitude: -87.860761, zipCode: "60085"},
	{latitude: 42.403838, longitude: -87.853589, zipCode: "60087"},
	{latitude: 42.309632, longitude: -87.844061, zipCode: "60088"},
	{latitude: 42.167231, longitude: -87.964063, zipCode: "60089"},
	{latitude: 42.125878, longitude: -87.911954, zipCode: "60090"},
	{latitude: 42.077788, longitude: -87.72057, zipCode: "60091"},
	{latitude: 42.104067, longitude: -87.753933, zipCode: "60093"},
	{latitude: 42.480614, longitude: -87.829557, zipCode: "60096"},
	{latitude: 42.396975, longitude: -88.372444, zipCode: "60097"},
	{latitude: 42.324645, longitude: -88.455561, zipCode: "60098"},
	{latitude: 42.459988, longitude: -87.865021, zipCode: "60099"},
	{latitude: 41.932119, longitude: -88.010781, zipCode: "60101"},
	{latitude: 42.163134, longitude: -88.310793, zipCode: "60102"},
	{latitude: 41.976545, longitude: -88.205689, zipCode: "60103"},
	{latitude: 41.882563, longitude: -87.876444, zipCode: "60104"},
	{latitude: 41.964374, longitude: -87.944129, zipCode: "60106"},
	{latitude: 42.020586, longitude: -88.177771, zipCode: "60107"},
	{latitude: 41.950304, longitude: -88.095497, zipCode: "60108"},
	{latitude: 42.054489, longitude: -88.540912, zipCode: "60109"},
	{latitude: 42.113432, longitude: -88.292251, zipCode: "60110"},
	{latitude: 42.00986, longitude: -88.835543, zipCode: "60111"},
	{latitude: 41.923732, longitude: -88.690217, zipCode: "60112"},
	{latitude: 41.931309, longitude: -88.96413, zipCode: "60113"},
	{latitude: 41.901263, longitude: -88.76155, zipCode: "60115"},
	{latitude: 42.106259, longitude: -88.304383, zipCode: "60118"},
	{latitude: 41.851532, longitude: -88.476451, zipCode: "60119"},
	{latitude: 42.032566, longitude: -88.240438, zipCode: "60120"},
	{latitude: 42.040644, longitude: -88.311296, zipCode: "60123"},
	{latitude: 42.020169, longitude: -88.400686, zipCode: "60124"},
	{latitude: 41.896843, longitude: -87.94124, zipCode: "60126"},
	{latitude: 42.008161, longitude: -88.974996, zipCode: "60129"},
	{latitude: 41.867031, longitude: -87.817934, zipCode: "60130"},
	{latitude: 41.937783, longitude: -87.883025, zipCode: "60131"},
	{latitude: 41.977842, longitude: -88.143199, zipCode: "60133"},
	{latitude: 41.878343, longitude: -88.342072, zipCode: "60134"},
	{latitude: 42.112505, longitude: -88.670815, zipCode: "60135"},
	{latitude: 42.105188, longitude: -88.380424, zipCode: "60136"},
	{latitude: 41.865206, longitude: -88.062783, zipCode: "60137"},
	{latitude: 41.918877, longitude: -88.078095, zipCode: "60139"},
	{latitude: 42.079051, longitude: -88.513098, zipCode: "60140"},
	{latitude: 41.858344, longitude: -87.83791, zipCode: "60141"},
	{latitude: 42.173915, longitude: -88.444659, zipCode: "60142"},
	{latitude: 41.973971, longitude: -88.021084, zipCode: "60143"},
	{latitude: 41.835076, longitude: -88.51986, zipCode: "60144"},
	{latitude: 42.084569, longitude: -88.77382, zipCode: "60145"},
	{latitude: 42.099982, longitude: -88.874061, zipCode: "60146"},
	{latitude: 41.876257, longitude: -88.018754, zipCode: "60148"},
	{latitude: 41.9144, longitude: -88.895775, zipCode: "60150"},
	{latitude: 41.918006, longitude: -88.577108, zipCode: "60151"},
	{latitude: 42.239365, longitude: -88.627304, zipCode: "60152"},
	{latitude: 41.879817, longitude: -87.84387, zipCode: "60153"},
	{latitude: 41.849234, longitude: -87.889849, zipCode: "60154"},
	{latitude: 41.857785, longitude: -87.856316, zipCode: "60155"},
	{latitude: 42.203063, longitude: -88.320474, zipCode: "60156"},
	{latitude: 41.975298, longitude: -88.056215, zipCode: "60157"},
	{latitude: 41.903491, longitude: -87.86177, zipCode: "60160"},
	{latitude: 41.867548, longitude: -87.901946, zipCode: "60162"},
	{latitude: 41.88793, longitude: -87.909467, zipCode: "60163"},
	{latitude: 41.91669, longitude: -87.901182, zipCode: "60164"},
	{latitude: 41.903234, longitude: -87.880732, zipCode: "60165"},
	{latitude: 42.05087, longitude: -88.116615, zipCode: "60169"},
	{latitude: 41.925059, longitude: -87.838334, zipCode: "60171"},
	{latitude: 41.980253, longitude: -88.088918, zipCode: "60172"},
	{latitude: 42.051556, longitude: -88.055522, zipCode: "60173"},
	{latitude: 41.930614, longitude: -88.298678, zipCode: "60174"},
	{latitude: 41.948188, longitude: -88.388701, zipCode: "60175"},
	{latitude: 41.958324, longitude: -87.869655, zipCode: "60176"},
	{latitude: 41.988481, longitude: -88.310167, zipCode: "60177"},
	{latitude: 42.008161, longitude: -88.660729, zipCode: "60178"},
	{latitude: 42.223377, longitude: -88.520207, zipCode: "60180"},
	{latitude: 41.876897, longitude: -87.976328, zipCode: "60181"},
	{latitude: 41.940234, longitude: -88.409141, zipCode: "60183"},
	{latitude: 41.951502, longitude: -88.25317, zipCode: "60184"},
	{latitude: 41.893111, longitude: -88.214998, zipCode: "60185"},
	{latitude: 41.87272, longitude: -88.112442, zipCode: "60187"},
	{latitude: 41.915737, longitude: -88.129434, zipCode: "60188"},
	{latitude: 41.840645, longitude: -88.122055, zipCode: "60189"},
	{latitude: 41.873621, longitude: -88.156243, zipCode: "60190"},
	{latitude: 41.964839, longitude: -87.981107, zipCode: "60191"},
	{latitude: 42.063272, longitude: -88.203662, zipCode: "60192"},
	{latitude: 42.009371, longitude: -88.096725, zipCode: "60193"},
	{latitude: 42.033424, longitude: -88.113562, zipCode: "60194"},
	{latitude: 42.065367, longitude: -88.086587, zipCode: "60195"},
	{latitude: 42.054745, longitude: -87.692382, zipCode: "60201"},
	{latitude: 42.02932, longitude: -87.68539, zipCode: "60202"},
	{latitude: 42.049035, longitude: -87.717455, zipCode: "60203"},
	{latitude: 42.053554, longitude: -87.670259, zipCode: "60208"},
	{latitude: 41.888654, longitude: -87.796538, zipCode: "60301"},
	{latitude: 41.894772, longitude: -87.789825, zipCode: "60302"},
	{latitude: 41.872468, longitude: -87.789331, zipCode: "60304"},
	{latitude: 41.894875, longitude: -87.819064, zipCode: "60305"},
	{latitude: 41.347143, longitude: -87.61413, zipCode: "60401"},
	{latitude: 41.83288, longitude: -87.790063, zipCode: "60402"},
	{latitude: 41.56819, longitude: -88.118732, zipCode: "60403"},
	{latitude: 41.513454, longitude: -88.224146, zipCode: "60404"},
	{latitude: 41.654945, longitude: -87.681952, zipCode: "60406"},
	{latitude: 41.234907, longitude: -88.261302, zipCode: "60407"},
	{latitude: 41.266034, longitude: -88.218537, zipCode: "60408"},
	{latitude: 41.613641, longitude: -87.552122, zipCode: "60409"},
	{latitude: 41.428379, longitude: -88.213202, zipCode: "60410"},
	{latitude: 41.507305, longitude: -87.589701, zipCode: "60411"},
	{latitude: 41.703438, longitude: -87.779542, zipCode: "60415"},
	{latitude: 41.293908, longitude: -88.283101, zipCode: "60416"},
	{latitude: 41.427585, longitude: -87.586149, zipCode: "60417"},
	{latitude: 41.645598, longitude: -87.740078, zipCode: "60418"},
	{latitude: 41.627271, longitude: -87.59839, zipCode: "60419"},
	{latitude: 41.087649, longitude: -88.420894, zipCode: "60420"},
	{latitude: 41.414206, longitude: -88.083462, zipCode: "60421"},
	{latitude: 41.538608, longitude: -87.684404, zipCode: "60422"},
	{latitude: 41.474458, longitude: -87.841151, zipCode: "60423"},
	{latitude: 41.164148, longitude: -88.327716, zipCode: "60424"},
	{latitude: 41.543427, longitude: -87.610754, zipCode: "60425"},
	{latitude: 41.610251, longitude: -87.653038, zipCode: "60426"},
	{latitude: 41.59981, longitude: -87.690617, zipCode: "60428"},
	{latitude: 41.574029, longitude: -87.683706, zipCode: "60429"},
	{latitude: 41.558114, longitude: -87.662807, zipCode: "60430"},
	{latitude: 41.509986, longitude: -88.190357, zipCode: "60431"},
	{latitude: 41.542255, longitude: -88.033845, zipCode: "60432"},
	{latitude: 41.496599, longitude: -88.042341, zipCode: "60433"},
	{latitude: 41.548642, longitude: -88.128501, zipCode: "60435"},
	{latitude: 41.48967, longitude: -88.12381, zipCode: "60436"},
	{latitude: 41.156902, longitude: -88.556083, zipCode: "60437"},
	{latitude: 41.566242, longitude: -87.550204, zipCode: "60438"},
	{latitude: 41.676605, longitude: -87.97709, zipCode: "60439"},
	{latitude: 41.7006, longitude: -88.075006, zipCode: "60440"},
	{latitude: 41.593049, longitude: -88.051567, zipCode: "60441"},
	{latitude: 41.391844, longitude: -87.960615, zipCode: "60442"},
	{latitude: 41.502887, longitude: -87.749571, zipCode: "60443"},
	{latitude: 41.243115, longitude: -88.402732, zipCode: "60444"},
	{latitude: 41.628327, longitude: -87.73537, zipCode: "60445"},
	{latitude: 41.633263, longitude: -88.099305, zipCode: "60446"},
	{latitude: 41.487239, longitude: -88.322166, zipCode: "60447"},
	{latitude: 41.53778, longitude: -87.892564, zipCode: "60448"},
	{latitude: 41.417643, longitude: -87.778586, zipCode: "60449"},
	{latitude: 41.370016, longitude: -88.434265, zipCode: "60450"},
	{latitude: 41.508158, longitude: -87.960979, zipCode: "60451"},
	{latitude: 41.608977, longitude: -87.754159, zipCode: "60452"},
	{latitude: 41.714116, longitude: -87.752771, zipCode: "60453"},
	{latitude: 41.740982, longitude: -87.806769, zipCode: "60455"},
	{latitude: 41.731248, longitude: -87.731134, zipCode: "60456"},
	{latitude: 41.723789, longitude: -87.829619, zipCode: "60457"},
	{latitude: 41.753401, longitude: -87.826941, zipCode: "60458"},
	{latitude: 41.74439, longitude: -87.768598, zipCode: "60459"},
	{latitude: 40.998574, longitude: -88.5366, zipCode: "60460"},
	{latitude: 41.521399, longitude: -87.686878, zipCode: "60461"},
	{latitude: 41.626852, longitude: -87.840615, zipCode: "60462"},
	{latitude: 41.662446, longitude: -87.789969, zipCode: "60463"},
	{latitude: 41.669034, longitude: -87.864299, zipCode: "60464"},
	{latitude: 41.700681, longitude: -87.825297, zipCode: "60465"},
	{latitude: 41.479162, longitude: -87.684598, zipCode: "60466"},
	{latitude: 41.597736, longitude: -87.886496, zipCode: "60467"},
	{latitude: 41.335283, longitude: -87.810291, zipCode: "60468"},
	{latitude: 41.628697, longitude: -87.686312, zipCode: "60469"},
	{latitude: 41.165888, longitude: -88.638723, zipCode: "60470"},
	{latitude: 41.479363, longitude: -87.729215, zipCode: "60471"},
	{latitude: 41.643075, longitude: -87.708082, zipCode: "60472"},
	{latitude: 41.597352, longitude: -87.601333, zipCode: "60473"},
	{latitude: 41.185566, longitude: -88.275234, zipCode: "60474"},
	{latitude: 41.471976, longitude: -87.628251, zipCode: "60475"},
	{latitude: 41.569483, longitude: -87.601709, zipCode: "60476"},
	{latitude: 41.573605, longitude: -87.786711, zipCode: "60477"},
	{latitude: 41.554179, longitude: -87.735525, zipCode: "60478"},
	{latitude: 41.232384, longitude: -88.526396, zipCode: "60479"},
	{latitude: 41.720306, longitude: -87.881345, zipCode: "60480"},
	{latitude: 41.285531, longitude: -88.110561, zipCode: "60481"},
	{latitude: 41.688211, longitude: -87.792577, zipCode: "60482"},
	{latitude: 41.443025, longitude: -87.710477, zipCode: "60484"},
	{latitude: 41.563837, longitude: -87.830349, zipCode: "60487"},
	{latitude: 41.676202, longitude: -88.140588, zipCode: "60490"},
	{latitude: 41.602698, longitude: -87.962605, zipCode: "60491"},
	{latitude: 41.779245, longitude: -87.823379, zipCode: "60501"},
	{latitude: 41.787522, longitude: -88.260017, zipCode: "60502"},
	{latitude: 41.707727, longitude: -88.257677, zipCode: "60503"},
	{latitude: 41.745439, longitude: -88.241209, zipCode: "60504"},
	{latitude: 41.765459, longitude: -88.296136, zipCode: "60505"},
	{latitude: 41.758878, longitude: -88.371865, zipCode: "60506"},
	{latitude: 41.843037, longitude: -88.311055, zipCode: "60510"},
	{latitude: 41.758993, longitude: -88.551993, zipCode: "60511"},
	{latitude: 41.701365, longitude: -88.43991, zipCode: "60512"},
	{latitude: 41.825059, longitude: -87.847546, zipCode: "60513"},
	{latitude: 41.795756, longitude: -87.96154, zipCode: "60514"},
	{latitude: 41.81107, longitude: -88.024525, zipCode: "60515"},
	{latitude: 41.761297, longitude: -88.013393, zipCode: "60516"},
	{latitude: 41.738621, longitude: -88.042518, zipCode: "60517"},
	{latitude: 41.598842, longitude: -88.919741, zipCode: "60518"},
	{latitude: 41.77748, longitude: -88.242553, zipCode: "60519"},
	{latitude: 41.787267, longitude: -88.667548, zipCode: "60520"},
	{latitude: 41.800683, longitude: -87.928172, zipCode: "60521"},
	{latitude: 41.836395, longitude: -87.953173, zipCode: "60523"},
	{latitude: 41.783546, longitude: -87.865993, zipCode: "60525"},
	{latitude: 41.831402, longitude: -87.8838, zipCode: "60526"},
	{latitude: 41.743074, longitude: -87.929214, zipCode: "60527"},
	{latitude: 41.79708, longitude: -88.952366, zipCode: "60530"},
	{latitude: 41.619207, longitude: -88.787498, zipCode: "60531"},
	{latitude: 41.789443, longitude: -88.083984, zipCode: "60532"},
	{latitude: 41.81239, longitude: -87.820036, zipCode: "60534"},
	{latitude: 41.598542, longitude: -88.552061, zipCode: "60536"},
	{latitude: 41.560477, longitude: -88.604103, zipCode: "60537"},
	{latitude: 41.727502, longitude: -88.336942, zipCode: "60538"},
	{latitude: 41.824632, longitude: -88.336198, zipCode: "60539"},
	{latitude: 41.764585, longitude: -88.144165, zipCode: "60540"},
	{latitude: 41.513344, longitude: -88.532398, zipCode: "60541"},
	{latitude: 41.808697, longitude: -88.34841, zipCode: "60542"},
	{latitude: 41.668264, longitude: -88.322786, zipCode: "60543"},
	{latitude: 41.613913, longitude: -88.218684, zipCode: "60544"},
	{latitude: 41.680353, longitude: -88.536308, zipCode: "60545"},
	{latitude: 41.837923, longitude: -87.821859, zipCode: "60546"},
	{latitude: 41.648667, longitude: -88.634691, zipCode: "60548"},
	{latitude: 41.502537, longitude: -88.739635, zipCode: "60549"},
	{latitude: 41.782197, longitude: -88.881393, zipCode: "60550"},
	{latitude: 41.525322, longitude: -88.687663, zipCode: "60551"},
	{latitude: 41.666888, longitude: -88.706507, zipCode: "60552"},
	{latitude: 41.826254, longitude: -89.040056, zipCode: "60553"},
	{latitude: 41.776668, longitude: -88.457048, zipCode: "60554"},
	{latitude: 41.823293, longitude: -88.188633, zipCode: "60555"},
	{latitude: 41.762915, longitude: -88.771125, zipCode: "60556"},
	{latitude: 41.445845, longitude: -88.767989, zipCode: "60557"},
	{latitude: 41.802072, longitude: -87.900752, zipCode: "60558"},
	{latitude: 41.794371, longitude: -87.97286, zipCode: "60559"},
	{latitude: 41.607605, longitude: -88.431708, zipCode: "60560"},
	{latitude: 41.745663, longitude: -87.981494, zipCode: "60561"},
	{latitude: 41.790064, longitude: -88.205593, zipCode: "60563"},
	{latitude: 41.706234, longitude: -88.195104, zipCode: "60564"},
	{latitude: 41.728864, longitude: -88.125668, zipCode: "60565"},
	{latitude: 41.657058, longitude: -88.225243, zipCode: "60585"},
	{latitude: 41.578191, longitude: -88.234492, zipCode: "60586"},
	{latitude: 41.885155, longitude: -87.621512, zipCode: "60601"},
	{latitude: 41.883041, longitude: -87.629079, zipCode: "60602"},
	{latitude: 41.880262, longitude: -87.627396, zipCode: "60603"},
	{latitude: 41.878113, longitude: -87.629071, zipCode: "60604"},
	{latitude: 41.867566, longitude: -87.617228, zipCode: "60605"},
	{latitude: 41.881259, longitude: -87.637452, zipCode: "60606"},
	{latitude: 41.874806, longitude: -87.654543, zipCode: "60607"},
	{latitude: 41.84688, longitude: -87.670664, zipCode: "60608"},
	{latitude: 41.81268, longitude: -87.656935, zipCode: "60609"},
	{latitude: 41.904718, longitude: -87.635891, zipCode: "60610"},
	{latitude: 41.894742, longitude: -87.615073, zipCode: "60611"},
	{latitude: 41.880511, longitude: -87.687867, zipCode: "60612"},
	{latitude: 41.956153, longitude: -87.652994, zipCode: "60613"},
	{latitude: 41.922747, longitude: -87.647153, zipCode: "60614"},
	{latitude: 41.803261, longitude: -87.597934, zipCode: "60615"},
	{latitude: 41.844883, longitude: -87.624032, zipCode: "60616"},
	{latitude: 41.714417, longitude: -87.555761, zipCode: "60617"},
	{latitude: 41.947568, longitude: -87.702647, zipCode: "60618"},
	{latitude: 41.743439, longitude: -87.605318, zipCode: "60619"},
	{latitude: 41.740497, longitude: -87.652558, zipCode: "60620"},
	{latitude: 41.776232, longitude: -87.639527, zipCode: "60621"},
	{latitude: 41.902172, longitude: -87.683337, zipCode: "60622"},
	{latitude: 41.847101, longitude: -87.717611, zipCode: "60623"},
	{latitude: 41.880504, longitude: -87.724444, zipCode: "60624"},
	{latitude: 41.973081, longitude: -87.699751, zipCode: "60625"},
	{latitude: 42.0088, longitude: -87.66705, zipCode: "60626"},
	{latitude: 41.691478, longitude: -87.618593, zipCode: "60628"},
	{latitude: 41.775414, longitude: -87.711487, zipCode: "60629"},
	{latitude: 41.972071, longitude: -87.756569, zipCode: "60630"},
	{latitude: 41.994769, longitude: -87.813104, zipCode: "60631"},
	{latitude: 41.810166, longitude: -87.713252, zipCode: "60632"},
	{latitude: 41.663783, longitude: -87.561314, zipCode: "60633"},
	{latitude: 41.946189, longitude: -87.806117, zipCode: "60634"},
	{latitude: 41.775789, longitude: -87.669071, zipCode: "60636"},
	{latitude: 41.782504, longitude: -87.602734, zipCode: "60637"},
	{latitude: 41.782468, longitude: -87.770753, zipCode: "60638"},
	{latitude: 41.920598, longitude: -87.755942, zipCode: "60639"},
	{latitude: 41.972872, longitude: -87.662604, zipCode: "60640"},
	{latitude: 41.946718, longitude: -87.746887, zipCode: "60641"},
	{latitude: 41.902042, longitude: -87.658544, zipCode: "60642"},
	{latitude: 41.700273, longitude: -87.663267, zipCode: "60643"},
	{latitude: 41.880084, longitude: -87.756373, zipCode: "60644"},
	{latitude: 42.008603, longitude: -87.694579, zipCode: "60645"},
	{latitude: 41.99365, longitude: -87.761325, zipCode: "60646"},
	{latitude: 41.921042, longitude: -87.701971, zipCode: "60647"},
	{latitude: 41.75934, longitude: -87.557025, zipCode: "60649"},
	{latitude: 41.902006, longitude: -87.741016, zipCode: "60651"},
	{latitude: 41.747432, longitude: -87.714403, zipCode: "60652"},
	{latitude: 41.819965, longitude: -87.605965, zipCode: "60653"},
	{latitude: 41.892158, longitude: -87.636324, zipCode: "60654"},
	{latitude: 41.694771, longitude: -87.703761, zipCode: "60655"},
	{latitude: 41.974257, longitude: -87.827052, zipCode: "60656"},
	{latitude: 41.939411, longitude: -87.650704, zipCode: "60657"},
	{latitude: 41.989578, longitude: -87.706572, zipCode: "60659"},
	{latitude: 41.99111, longitude: -87.663076, zipCode: "60660"},
	{latitude: 41.882997, longitude: -87.644114, zipCode: "60661"},
	{latitude: 41.963885, longitude: -87.816788, zipCode: "60706"},
	{latitude: 41.921752, longitude: -87.807437, zipCode: "60707"},
	{latitude: 42.00543, longitude: -87.732959, zipCode: "60712"},
	{latitude: 42.028351, longitude: -87.811304, zipCode: "60714"},
	{latitude: 41.674064, longitude: -87.736222, zipCode: "60803"},
	{latitude: 41.840164, longitude: -87.759916, zipCode: "60804"},
	{latitude: 41.722001, longitude: -87.702446, zipCode: "60805"},
	{latitude: 41.649434, longitude: -87.633572, zipCode: "60827"},
	{latitude: 41.109438, longitude: -87.902817, zipCode: "60901"},
	{latitude: 41.066399, longitude: -87.799584, zipCode: "60910"},
	{latitude: 40.880243, longitude: -87.976584, zipCode: "60911"},
	{latitude: 40.973619, longitude: -87.603476, zipCode: "60912"},
	{latitude: 41.148353, longitude: -88.062041, zipCode: "60913"},
	{latitude: 41.183001, longitude: -87.86121, zipCode: "60914"},
	{latitude: 41.146331, longitude: -87.860709, zipCode: "60915"},
	{latitude: 41.047059, longitude: -88.197386, zipCode: "60917"},
	{latitude: 40.594969, longitude: -88.025655, zipCode: "60918"},
	{latitude: 40.987876, longitude: -88.237518, zipCode: "60919"},
	{latitude: 41.030214, longitude: -88.30379, zipCode: "60920"},
	{latitude: 40.731543, longitude: -88.297211, zipCode: "60921"},
	{latitude: 41.010925, longitude: -87.91929, zipCode: "60922"},
	{latitude: 40.560659, longitude: -87.885621, zipCode: "60924"},
	{latitude: 40.569394, longitude: -87.812079, zipCode: "60926"},
	{latitude: 40.938489, longitude: -87.972776, zipCode: "60927"},
	{latitude: 40.745685, longitude: -87.85077, zipCode: "60928"},
	{latitude: 40.876008, longitude: -88.292405, zipCode: "60929"},
	{latitude: 40.831736, longitude: -88.006266, zipCode: "60930"},
	{latitude: 40.889327, longitude: -87.588141, zipCode: "60931"},
	{latitude: 40.465104, longitude: -87.800118, zipCode: "60932"},
	{latitude: 40.462578, longitude: -88.273801, zipCode: "60933"},
	{latitude: 40.957093, longitude: -88.345104, zipCode: "60934"},
	{latitude: 41.170462, longitude: -88.175879, zipCode: "60935"},
	{latitude: 40.46469, longitude: -88.36306, zipCode: "60936"},
	{latitude: 40.772258, longitude: -87.984392, zipCode: "60938"},
	{latitude: 40.567001, longitude: -87.777093, zipCode: "60939"},
	{latitude: 41.249777, longitude: -87.642858, zipCode: "60940"},
	{latitude: 41.038658, longitude: -88.085869, zipCode: "60941"},
	{latitude: 40.466569, longitude: -87.661605, zipCode: "60942"},
	{latitude: 40.835123, longitude: -87.596704, zipCode: "60945"},
	{latitude: 40.913073, longitude: -88.200712, zipCode: "60946"},
	{latitude: 40.52356, longitude: -88.07735, zipCode: "60948"},
	{latitude: 40.378337, longitude: -88.105151, zipCode: "60949"},
	{latitude: 41.252848, longitude: -87.883586, zipCode: "60950"},
	{latitude: 40.919046, longitude: -87.740751, zipCode: "60951"},
	{latitude: 40.562795, longitude: -88.250366, zipCode: "60952"},
	{latitude: 40.629003, longitude: -87.6902, zipCode: "60953"},
	{latitude: 41.147708, longitude: -87.622516, zipCode: "60954"},
	{latitude: 40.708464, longitude: -87.982949, zipCode: "60955"},
	{latitude: 40.967685, longitude: -87.716245, zipCode: "60956"},
	{latitude: 40.442962, longitude: -88.136204, zipCode: "60957"},
	{latitude: 41.065203, longitude: -87.591599, zipCode: "60958"},
	{latitude: 40.789482, longitude: -88.183175, zipCode: "60959"},
	{latitude: 40.438957, longitude: -87.890723, zipCode: "60960"},
	{latitude: 41.109567, longitude: -88.221504, zipCode: "60961"},
	{latitude: 40.626141, longitude: -88.187143, zipCode: "60962"},
	{latitude: 40.368495, longitude: -87.65107, zipCode: "60963"},
	{latitude: 41.027818, longitude: -87.742256, zipCode: "60964"},
	{latitude: 40.751413, longitude: -87.583242, zipCode: "60966"},
	{latitude: 40.624091, longitude: -87.581334, zipCode: "60967"},
	{latitude: 40.675461, longitude: -88.119734, zipCode: "60968"},
	{latitude: 41.110968, longitude: -88.149713, zipCode: "60969"},
	{latitude: 40.793923, longitude: -87.741344, zipCode: "60970"},
	{latitude: 40.53654, longitude: -87.662626, zipCode: "60973"},
	{latitude: 40.717984, longitude: -87.735903, zipCode: "60974"},
	{latitude: 42.465816, longitude: -90.110232, zipCode: "61001"},
	{latitude: 41.861762, longitude: -89.202281, zipCode: "61006"},
	{latitude: 42.199421, longitude: -89.591073, zipCode: "61007"},
	{latitude: 42.245929, longitude: -88.839859, zipCode: "61008"},
	{latitude: 42.134545, longitude: -89.272646, zipCode: "61010"},
	{latitude: 42.392262, longitude: -88.897124, zipCode: "61011"},
	{latitude: 42.401445, longitude: -88.764558, zipCode: "61012"},
	{latitude: 42.375397, longitude: -89.636374, zipCode: "61013"},
	{latitude: 41.974996, longitude: -89.886487, zipCode: "61014"},
	{latitude: 41.996752, longitude: -89.197077, zipCode: "61015"},
	{latitude: 42.1973, longitude: -88.947729, zipCode: "61016"},
	{latitude: 42.415969, longitude: -89.555551, zipCode: "61018"},
	{latitude: 42.439175, longitude: -89.410156, zipCode: "61019"},
	{latitude: 42.11034, longitude: -89.098088, zipCode: "61020"},
	{latitude: 41.829597, longitude: -89.477284, zipCode: "61021"},
	{latitude: 42.436415, longitude: -89.297114, zipCode: "61024"},
	{latitude: 42.471378, longitude: -90.555965, zipCode: "61025"},
	{latitude: 42.333273, longitude: -89.755547, zipCode: "61027"},
	{latitude: 42.298465, longitude: -90.175881, zipCode: "61028"},
	{latitude: 42.119648, longitude: -89.591622, zipCode: "61030"},
	{latitude: 41.829964, longitude: -89.321271, zipCode: "61031"},
	{latitude: 42.318865, longitude: -89.636977, zipCode: "61032"},
	{latitude: 42.413689, longitude: -90.375811, zipCode: "61036"},
	{latitude: 41.788389, longitude: -89.759781, zipCode: "61037"},
	{latitude: 42.265818, longitude: -88.737964, zipCode: "61038"},
	{latitude: 42.208034, longitude: -89.469314, zipCode: "61039"},
	{latitude: 42.273156, longitude: -90.311245, zipCode: "61041"},
	{latitude: 41.694051, longitude: -89.558745, zipCode: "61042"},
	{latitude: 42.066754, longitude: -89.104946, zipCode: "61043"},
	{latitude: 42.3234, longitude: -89.909937, zipCode: "61044"},
	{latitude: 42.104436, longitude: -89.812795, zipCode: "61046"},
	{latitude: 42.153044, longitude: -89.396012, zipCode: "61047"},
	{latitude: 42.382634, longitude: -89.841177, zipCode: "61048"},
	{latitude: 42.049626, longitude: -89.008914, zipCode: "61049"},
	{latitude: 42.439332, longitude: -89.7319, zipCode: "61050"},
	{latitude: 41.982849, longitude: -89.761848, zipCode: "61051"},
	{latitude: 42.111451, longitude: -89.000102, zipCode: "61052"},
	{latitude: 42.120819, longitude: -89.983297, zipCode: "61053"},
	{latitude: 42.055737, longitude: -89.445461, zipCode: "61054"},
	{latitude: 41.833568, longitude: -89.383952, zipCode: "61057"},
	{latitude: 42.454648, longitude: -89.940823, zipCode: "61059"},
	{latitude: 42.478784, longitude: -89.618152, zipCode: "61060"},
	{latitude: 41.997917, longitude: -89.341656, zipCode: "61061"},
	{latitude: 42.250675, longitude: -89.835758, zipCode: "61062"},
	{latitude: 42.319457, longitude: -89.340712, zipCode: "61063"},
	{latitude: 41.989306, longitude: -89.587249, zipCode: "61064"},
	{latitude: 42.400588, longitude: -88.836952, zipCode: "61065"},
	{latitude: 42.305472, longitude: -89.472083, zipCode: "61067"},
	{latitude: 41.950028, longitude: -89.06559, zipCode: "61068"},
	{latitude: 42.415681, longitude: -89.471727, zipCode: "61070"},
	{latitude: 41.724694, longitude: -89.698885, zipCode: "61071"},
	{latitude: 42.442354, longitude: -89.138457, zipCode: "61072"},
	{latitude: 42.423752, longitude: -88.993888, zipCode: "61073"},
	{latitude: 42.124371, longitude: -90.122436, zipCode: "61074"},
	{latitude: 42.46545, longitude: -90.264383, zipCode: "61075"},
	{latitude: 42.240128, longitude: -89.348043, zipCode: "61077"},
	{latitude: 42.167276, longitude: -89.730603, zipCode: "61078"},
	{latitude: 42.452934, longitude: -89.215121, zipCode: "61079"},
	{latitude: 42.480913, longitude: -88.983829, zipCode: "61080"},
	{latitude: 41.850006, longitude: -89.739377, zipCode: "61081"},
	{latitude: 42.123586, longitude: -89.190074, zipCode: "61084"},
	{latitude: 42.342651, longitude: -90.030768, zipCode: "61085"},
	{latitude: 42.480502, longitude: -89.993491, zipCode: "61087"},
	{latitude: 42.268117, longitude: -89.262717, zipCode: "61088"},
	{latitude: 42.473543, longitude: -89.823358, zipCode: "61089"},
	{latitude: 41.908183, longitude: -89.552363, zipCode: "61091"},
	{latitude: 42.346079, longitude: -89.146087, zipCode: "61101"},
	{latitude: 42.228769, longitude: -89.162524, zipCode: "61102"},
	{latitude: 42.340195, longitude: -89.086635, zipCode: "61103"},
	{latitude: 42.25172, longitude: -89.079945, zipCode: "61104"},
	{latitude: 42.285464, longitude: -89.001649, zipCode: "61107"},
	{latitude: 42.255157, longitude: -89.002313, zipCode: "61108"},
	{latitude: 42.191304, longitude: -89.055756, zipCode: "61109"},
	{latitude: 42.335505, longitude: -89.002749, zipCode: "61111"},
	{latitude: 42.24197, longitude: -88.975223, zipCode: "61112"},
	{latitude: 42.306605, longitude: -88.990894, zipCode: "61114"},
	{latitude: 42.362592, longitude: -89.024156, zipCode: "61115"},
	{latitude: 41.477559, longitude: -90.575628, zipCode: "61201"},
	{latitude: 41.734071, longitude: -90.212923, zipCode: "61230"},
	{latitude: 41.210699, longitude: -90.717768, zipCode: "61231"},
	{latitude: 41.431676, longitude: -90.727388, zipCode: "61232"},
	{latitude: 41.294676, longitude: -90.291318, zipCode: "61233"},
	{latitude: 41.417969, longitude: -89.914344, zipCode: "61234"},
	{latitude: 41.401291, longitude: -90.023568, zipCode: "61235"},
	{latitude: 41.514428, longitude: -90.355576, zipCode: "61236"},
	{latitude: 41.341768, longitude: -90.862181, zipCode: "61237"},
	{latitude: 41.282275, longitude: -90.172422, zipCode: "61238"},
	{latitude: 41.49477, longitude: -90.389711, zipCode: "61239"},
	{latitude: 41.423286, longitude: -90.43697, zipCode: "61240"},
	{latitude: 41.487328, longitude: -90.320356, zipCode: "61241"},
	{latitude: 41.708009, longitude: -90.285148, zipCode: "61242"},
	{latitude: 41.618468, longitude: -89.678408, zipCode: "61243"},
	{latitude: 41.522559, longitude: -90.390822, zipCode: "61244"},
	{latitude: 41.655923, longitude: -90.112354, zipCode: "61250"},
	{latitude: 41.729093, longitude: -90.072903, zipCode: "61251"},
	{latitude: 41.83685, longitude: -90.12531, zipCode: "61252"},
	{latitude: 41.467229, longitude: -90.149632, zipCode: "61254"},
	{latitude: 41.561084, longitude: -90.400585, zipCode: "61256"},
	{latitude: 41.593358, longitude: -90.221845, zipCode: "61257"},
	{latitude: 41.521849, longitude: -89.913968, zipCode: "61258"},
	{latitude: 41.385591, longitude: -90.924481, zipCode: "61259"},
	{latitude: 41.237949, longitude: -90.887362, zipCode: "61260"},
	{latitude: 41.736022, longitude: -89.910033, zipCode: "61261"},
	{latitude: 41.276069, longitude: -90.348286, zipCode: "61262"},
	{latitude: 41.266237, longitude: -90.596023, zipCode: "61263"},
	{latitude: 41.402799, longitude: -90.595864, zipCode: "61264"},
	{latitude: 41.480697, longitude: -90.487167, zipCode: "61265"},
	{latitude: 41.826598, longitude: -89.963451, zipCode: "61270"},
	{latitude: 41.251085, longitude: -91.011405, zipCode: "61272"},
	{latitude: 41.364242, longitude: -90.399093, zipCode: "61273"},
	{latitude: 41.370314, longitude: -90.276626, zipCode: "61274"},
	{latitude: 41.605575, longitude: -90.307048, zipCode: "61275"},
	{latitude: 41.316601, longitude: -90.576297, zipCode: "61276"},
	{latitude: 41.613582, longitude: -89.930578, zipCode: "61277"},
	{latitude: 41.579333, longitude: -90.334162, zipCode: "61278"},
	{latitude: 41.325731, longitude: -90.728612, zipCode: "61279"},
	{latitude: 41.29978, longitude: -90.520144, zipCode: "61281"},
	{latitude: 41.496716, longitude: -90.411691, zipCode: "61282"},
	{latitude: 41.590912, longitude: -89.788559, zipCode: "61283"},
	{latitude: 41.395104, longitude: -90.750033, zipCode: "61284"},
	{latitude: 41.993904, longitude: -90.082298, zipCode: "61285"},
	{latitude: 41.397423, longitude: -89.084264, zipCode: "61301"},
	{latitude: 41.702419, longitude: -89.35451, zipCode: "61310"},
	{latitude: 41.040152, longitude: -88.862201, zipCode: "61311"},
	{latitude: 41.434042, longitude: -89.225397, zipCode: "61312"},
	{latitude: 41.07237, longitude: -88.669963, zipCode: "61313"},
	{latitude: 41.300261, longitude: -89.676578, zipCode: "61314"},
	{latitude: 41.285326, longitude: -89.366593, zipCode: "61315"},
	{latitude: 41.257789, longitude: -89.127667, zipCode: "61316"},
	{latitude: 41.431965, longitude: -89.208181, zipCode: "61317"},
	{latitude: 41.711285, longitude: -89.078271, zipCode: "61318"},
	{latitude: 41.015375, longitude: -88.739908, zipCode: "61319"},
	{latitude: 41.355293, longitude: -89.170438, zipCode: "61320"},
	{latitude: 40.967587, longitude: -88.976213, zipCode: "61321"},
	{latitude: 41.312267, longitude: -89.307047, zipCode: "61322"},
	{latitude: 41.435484, longitude: -89.396058, zipCode: "61323"},
	{latitude: 41.769935, longitude: -89.413597, zipCode: "61324"},
	{latitude: 41.234953, longitude: -88.809633, zipCode: "61325"},
	{latitude: 41.246249, longitude: -89.229711, zipCode: "61326"},
	{latitude: 41.230466, longitude: -89.299146, zipCode: "61327"},
	{latitude: 41.509284, longitude: -89.458993, zipCode: "61328"},
	{latitude: 41.377047, longitude: -89.207846, zipCode: "61329"},
	{latitude: 41.535103, longitude: -89.277313, zipCode: "61330"},
	{latitude: 41.747703, longitude: -89.277542, zipCode: "61331"},
	{latitude: 41.188817, longitude: -88.984548, zipCode: "61332"},
	{latitude: 40.988451, longitude: -88.889294, zipCode: "61333"},
	{latitude: 41.147315, longitude: -89.099338, zipCode: "61334"},
	{latitude: 41.159239, longitude: -89.233918, zipCode: "61335"},
	{latitude: 41.111479, longitude: -89.212267, zipCode: "61336"},
	{latitude: 41.450551, longitude: -89.297789, zipCode: "61337"},
	{latitude: 41.470139, longitude: -89.66514, zipCode: "61338"},
	{latitude: 41.264025, longitude: -89.251281, zipCode: "61340"},
	{latitude: 41.352165, longitude: -88.693197, zipCode: "61341"},
	{latitude: 41.543677, longitude: -89.084088, zipCode: "61342"},
	{latitude: 41.412366, longitude: -89.834404, zipCode: "61344"},
	{latitude: 41.276085, longitude: -89.785366, zipCode: "61345"},
	{latitude: 41.506666, longitude: -89.719954, zipCode: "61346"},
	{latitude: 41.285723, longitude: -89.035197, zipCode: "61348"},
	{latitude: 41.548675, longitude: -89.437073, zipCode: "61349"},
	{latitude: 41.372095, longitude: -88.865127, zipCode: "61350"},
	{latitude: 41.704317, longitude: -88.991048, zipCode: "61353"},
	{latitude: 41.334165, longitude: -89.142245, zipCode: "61354"},
	{latitude: 41.389301, longitude: -89.439863, zipCode: "61356"},
	{latitude: 40.981674, longitude: -89.042991, zipCode: "61358"},
	{latitude: 41.362452, longitude: -89.271459, zipCode: "61359"},
	{latitude: 41.332589, longitude: -88.600301, zipCode: "61360"},
	{latitude: 41.412278, longitude: -89.773775, zipCode: "61361"},
	{latitude: 41.351426, longitude: -89.223736, zipCode: "61362"},
	{latitude: 41.254249, longitude: -89.182485, zipCode: "61363"},
	{latitude: 41.128479, longitude: -88.849898, zipCode: "61364"},
	{latitude: 41.621412, longitude: -89.259488, zipCode: "61367"},
	{latitude: 41.262126, longitude: -89.517493, zipCode: "61368"},
	{latitude: 40.98269, longitude: -89.153993, zipCode: "61369"},
	{latitude: 41.210728, longitude: -89.038814, zipCode: "61370"},
	{latitude: 41.499321, longitude: -89.019862, zipCode: "61371"},
	{latitude: 41.465822, longitude: -89.082396, zipCode: "61372"},
	{latitude: 41.407228, longitude: -88.995936, zipCode: "61373"},
	{latitude: 41.547302, longitude: -89.357589, zipCode: "61374"},
	{latitude: 41.035173, longitude: -89.243431, zipCode: "61375"},
	{latitude: 41.538404, longitude: -89.6018, zipCode: "61376"},
	{latitude: 41.065403, longitude: -89.042089, zipCode: "61377"},
	{latitude: 41.722671, longitude: -89.155402, zipCode: "61378"},
	{latitude: 41.380255, longitude: -89.606301, zipCode: "61379"},
	{latitude: 40.945179, longitude: -90.386567, zipCode: "61401"},
	{latitude: 40.939903, longitude: -90.406354, zipCode: "61402"},
	{latitude: 40.79488, longitude: -90.422478, zipCode: "61410"},
	{latitude: 40.400982, longitude: -90.502128, zipCode: "61411"},
	{latitude: 41.078398, longitude: -90.579715, zipCode: "61412"},
	{latitude: 41.186577, longitude: -90.3704, zipCode: "61413"},
	{latitude: 41.125934, longitude: -90.154913, zipCode: "61414"},
	{latitude: 40.649983, longitude: -90.418763, zipCode: "61415"},
	{latitude: 40.495917, longitude: -90.563071, zipCode: "61416"},
	{latitude: 40.772229, longitude: -90.54413, zipCode: "61417"},
	{latitude: 40.847173, longitude: -90.869221, zipCode: "61418"},
	{latitude: 41.199807, longitude: -90.117518, zipCode: "61419"},
	{latitude: 40.548785, longitude: -90.867256, zipCode: "61420"},
	{latitude: 41.187535, longitude: -89.647909, zipCode: "61421"},
	{latitude: 40.551405, longitude: -90.532911, zipCode: "61422"},
	{latitude: 40.885484, longitude: -90.502322, zipCode: "61423"},
	{latitude: 41.076634, longitude: -89.635092, zipCode: "61424"},
	{latitude: 40.759246, longitude: -91.040136, zipCode: "61425"},
	{latitude: 41.119635, longitude: -89.706009, zipCode: "61426"},
	{latitude: 40.497616, longitude: -90.194498, zipCode: "61427"},
	{latitude: 40.933329, longitude: -90.113563, zipCode: "61428"},
	{latitude: 40.943799, longitude: -90.311977, zipCode: "61430"},
	{latitude: 40.600024, longitude: -90.28854, zipCode: "61431"},
	{latitude: 40.648385, longitude: -90.153167, zipCode: "61432"},
	{latitude: 40.554375, longitude: -90.168989, zipCode: "61433"},
	{latitude: 41.172707, longitude: -90.03619, zipCode: "61434"},
	{latitude: 40.99561, longitude: -90.568639, zipCode: "61435"},
	{latitude: 40.860149, longitude: -90.223178, zipCode: "61436"},
	{latitude: 40.846983, longitude: -90.992111, zipCode: "61437"},
	{latitude: 40.585404, longitude: -90.649451, zipCode: "61438"},
	{latitude: 41.029203, longitude: -90.350207, zipCode: "61439"},
	{latitude: 40.316101, longitude: -90.592759, zipCode: "61440"},
	{latitude: 40.330188, longitude: -90.28171, zipCode: "61441"},
	{latitude: 41.103273, longitude: -90.923968, zipCode: "61442"},
	{latitude: 41.26369, longitude: -89.944924, zipCode: "61443"},
	{latitude: 40.8703, longitude: -90.763381, zipCode: "61447"},
	{latitude: 40.933819, longitude: -90.243765, zipCode: "61448"},
	{latitude: 41.095042, longitude: -89.975133, zipCode: "61449"},
	{latitude: 40.571726, longitude: -90.984257, zipCode: "61450"},
	{latitude: 40.938697, longitude: -89.939478, zipCode: "61451"},
	{latitude: 40.23836, longitude: -90.660225, zipCode: "61452"},
	{latitude: 41.013477, longitude: -90.76321, zipCode: "61453"},
	{latitude: 40.68193, longitude: -91.039891, zipCode: "61454"},
	{latitude: 40.440441, longitude: -90.637941, zipCode: "61455"},
	{latitude: 40.782213, longitude: -90.183159, zipCode: "61458"},
	{latitude: 40.510245, longitude: -90.419564, zipCode: "61459"},
	{latitude: 40.721616, longitude: -90.827733, zipCode: "61460"},
	{latitude: 40.909888, longitude: -90.651678, zipCode: "61462"},
	{latitude: 41.210673, longitude: -90.479667, zipCode: "61465"},
	{latitude: 41.111017, longitude: -90.482208, zipCode: "61466"},
	{latitude: 41.069432, longitude: -90.242389, zipCode: "61467"},
	{latitude: 41.256502, longitude: -90.387431, zipCode: "61468"},
	{latitude: 40.955983, longitude: -90.908233, zipCode: "61469"},
	{latitude: 40.606179, longitude: -90.506187, zipCode: "61470"},
	{latitude: 40.695781, longitude: -90.825065, zipCode: "61471"},
	{latitude: 41.103259, longitude: -90.383436, zipCode: "61472"},
	{latitude: 40.709145, longitude: -90.649735, zipCode: "61473"},
	{latitude: 40.742124, longitude: -90.394094, zipCode: "61474"},
	{latitude: 40.596886, longitude: -90.752701, zipCode: "61475"},
	{latitude: 41.089796, longitude: -90.829029, zipCode: "61476"},
	{latitude: 40.495994, longitude: -90.310552, zipCode: "61477"},
	{latitude: 40.756317, longitude: -90.760382, zipCode: "61478"},
	{latitude: 41.001916, longitude: -89.639391, zipCode: "61479"},
	{latitude: 40.727402, longitude: -90.913343, zipCode: "61480"},
	{latitude: 40.389845, longitude: -90.403943, zipCode: "61482"},
	{latitude: 41.081231, longitude: -89.873285, zipCode: "61483"},
	{latitude: 40.297674, longitude: -90.443219, zipCode: "61484"},
	{latitude: 41.022346, longitude: -90.0706, zipCode: "61485"},
	{latitude: 41.194794, longitude: -90.576436, zipCode: "61486"},
	{latitude: 41.030032, longitude: -90.320773, zipCode: "61488"},
	{latitude: 40.930759, longitude: -90.033093, zipCode: "61489"},
	{latitude: 41.185531, longitude: -90.258712, zipCode: "61490"},
	{latitude: 41.053825, longitude: -89.72078, zipCode: "61491"},
	{latitude: 40.228366, longitude: -90.311915, zipCode: "61501"},
	{latitude: 40.855551, longitude: -89.131967, zipCode: "61516"},
	{latitude: 40.818955, longitude: -89.846915, zipCode: "61517"},
	{latitude: 40.464644, longitude: -90.093078, zipCode: "61519"},
	{latitude: 40.543564, longitude: -90.024844, zipCode: "61520"},
	{latitude: 40.911951, longitude: -89.537757, zipCode: "61523"},
	{latitude: 40.491238, longitude: -90.034652, zipCode: "61524"},
	{latitude: 40.847755, longitude: -89.670466, zipCode: "61525"},
	{latitude: 40.922901, longitude: -89.619957, zipCode: "61526"},
	{latitude: 40.776901, longitude: -89.718271, zipCode: "61528"},
	{latitude: 40.782018, longitude: -89.937608, zipCode: "61529"},
	{latitude: 40.708694, longitude: -89.264946, zipCode: "61530"},
	{latitude: 40.682505, longitude: -90.036794, zipCode: "61531"},
	{latitude: 40.348491, longitude: -89.806913, zipCode: "61532"},
	{latitude: 40.586374, longitude: -89.842499, zipCode: "61533"},
	{latitude: 40.414961, longitude: -89.664661, zipCode: "61534"},
	{latitude: 40.578864, longitude: -89.516514, zipCode: "61535"},
	{latitude: 40.690822, longitude: -89.790062, zipCode: "61536"},
	{latitude: 41.119166, longitude: -89.471442, zipCode: "61537"},
	{latitude: 40.556359, longitude: -89.762845, zipCode: "61539"},
	{latitude: 41.0146, longitude: -89.366597, zipCode: "61540"},
	{latitude: 40.980378, longitude: -89.234952, zipCode: "61541"},
	{latitude: 40.376696, longitude: -90.131662, zipCode: "61542"},
	{latitude: 40.417859, longitude: -89.977542, zipCode: "61543"},
	{latitude: 40.700737, longitude: -90.234071, zipCode: "61544"},
	{latitude: 40.868374, longitude: -89.352495, zipCode: "61545"},
	{latitude: 40.448337, longitude: -89.809588, zipCode: "61546"},
	{latitude: 40.570008, longitude: -89.721846, zipCode: "61547"},
	{latitude: 40.814049, longitude: -89.420028, zipCode: "61548"},
	{latitude: 40.611423, longitude: -89.436378, zipCode: "61550"},
	{latitude: 40.821419, longitude: -89.564892, zipCode: "61552"},
	{latitude: 40.621388, longitude: -90.026814, zipCode: "61553"},
	{latitude: 40.543623, longitude: -89.620621, zipCode: "61554"},
	{latitude: 40.919017, longitude: -89.779205, zipCode: "61559"},
	{latitude: 41.187262, longitude: -89.41979, zipCode: "61560"},
	{latitude: 40.805582, longitude: -89.206921, zipCode: "61561"},
	{latitude: 40.880988, longitude: -89.501449, zipCode: "61562"},
	{latitude: 40.49317, longitude: -90.051134, zipCode: "61563"},
	{latitude: 40.498515, longitude: -89.656218, zipCode: "61564"},
	{latitude: 41.038714, longitude: -89.505036, zipCode: "61565"},
	{latitude: 40.373179, longitude: -89.901049, zipCode: "61567"},
	{latitude: 40.510242, longitude: -89.481372, zipCode: "61568"},
	{latitude: 40.683232, longitude: -89.900619, zipCode: "61569"},
	{latitude: 40.914177, longitude: -89.323379, zipCode: "61570"},
	{latitude: 40.704506, longitude: -89.424629, zipCode: "61571"},
	{latitude: 40.797187, longitude: -90.039721, zipCode: "61572"},
	{latitude: 40.679205, longitude: -89.605574, zipCode: "61602"},
	{latitude: 40.709838, longitude: -89.575458, zipCode: "61603"},
	{latitude: 40.705247, longitude: -89.652921, zipCode: "61604"},
	{latitude: 40.676526, longitude: -89.633135, zipCode: "61605"},
	{latitude: 40.70007, longitude: -89.61187, zipCode: "61606"},
	{latitude: 40.627132, longitude: -89.68513, zipCode: "61607"},
	{latitude: 40.642116, longitude: -89.59956, zipCode: "61610"},
	{latitude: 40.713327, longitude: -89.535743, zipCode: "61611"},
	{latitude: 40.759136, longitude: -89.604123, zipCode: "61614"},
	{latitude: 40.748929, longitude: -89.672275, zipCode: "61615"},
	{latitude: 40.746726, longitude: -89.571641, zipCode: "61616"},
	{latitude: 40.697698, longitude: -89.614572, zipCode: "61625"},
	{latitude: 40.475855, longitude: -88.99267, zipCode: "61701"},
	{latitude: 40.470052, longitude: -88.94152, zipCode: "61704"},
	{latitude: 40.523132, longitude: -89.07201, zipCode: "61705"},
	{latitude: 40.5503, longitude: -88.502122, zipCode: "61720"},
	{latitude: 40.350776, longitude: -89.323505, zipCode: "61721"},
	{latitude: 40.423434, longitude: -88.626319, zipCode: "61722"},
	{latitude: 40.264664, longitude: -89.276542, zipCode: "61723"},
	{latitude: 40.333787, longitude: -88.525202, zipCode: "61724"},
	{latitude: 40.616502, longitude: -89.121678, zipCode: "61725"},
	{latitude: 40.733399, longitude: -88.707734, zipCode: "61726"},
	{latitude: 40.146752, longitude: -88.953188, zipCode: "61727"},
	{latitude: 40.580622, longitude: -88.622264, zipCode: "61728"},
	{latitude: 40.628659, longitude: -89.221294, zipCode: "61729"},
	{latitude: 40.532622, longitude: -88.734397, zipCode: "61730"},
	{latitude: 40.612579, longitude: -88.483885, zipCode: "61731"},
	{latitude: 40.529638, longitude: -89.208063, zipCode: "61732"},
	{latitude: 40.623632, longitude: -89.330193, zipCode: "61733"},
	{latitude: 40.376442, longitude: -89.526224, zipCode: "61734"},
	{latitude: 40.205139, longitude: -88.80563, zipCode: "61735"},
	{latitude: 40.402284, longitude: -88.840374, zipCode: "61736"},
	{latitude: 40.455719, longitude: -88.733223, zipCode: "61737"},
	{latitude: 40.745101, longitude: -89.030052, zipCode: "61738"},
	{latitude: 40.727827, longitude: -88.521355, zipCode: "61739"},
	{latitude: 40.891933, longitude: -88.862761, zipCode: "61740"},
	{latitude: 40.762563, longitude: -88.396715, zipCode: "61741"},
	{latitude: 40.634613, longitude: -89.27252, zipCode: "61742"},
	{latitude: 40.878048, longitude: -88.781428, zipCode: "61743"},
	{latitude: 40.743776, longitude: -88.881206, zipCode: "61744"},
	{latitude: 40.320623, longitude: -88.967428, zipCode: "61745"},
	{latitude: 40.416122, longitude: -89.435257, zipCode: "61747"},
	{latitude: 40.622397, longitude: -88.996779, zipCode: "61748"},
	{latitude: 40.092849, longitude: -89.116222, zipCode: "61749"},
	{latitude: 40.1225, longitude: -88.855673, zipCode: "61750"},
	{latitude: 40.214556, longitude: -89.299252, zipCode: "61751"},
	{latitude: 40.337063, longitude: -88.76166, zipCode: "61752"},
	{latitude: 40.629744, longitude: -88.787974, zipCode: "61753"},
	{latitude: 40.328141, longitude: -89.161373, zipCode: "61754"},
	{latitude: 40.52609, longitude: -89.341761, zipCode: "61755"},
	{latitude: 40.033122, longitude: -88.963689, zipCode: "61756"},
	{latitude: 40.437316, longitude: -89.336053, zipCode: "61759"},
	{latitude: 40.885892, longitude: -89.032115, zipCode: "61760"},
	{latitude: 40.528032, longitude: -88.947633, zipCode: "61761"},
	{latitude: 40.883301, longitude: -88.625907, zipCode: "61764"},
	{latitude: 40.885492, longitude: -88.399452, zipCode: "61769"},
	{latitude: 40.439518, longitude: -88.51077, zipCode: "61770"},
	{latitude: 40.713112, longitude: -89.147976, zipCode: "61771"},
	{latitude: 40.38598, longitude: -89.063578, zipCode: "61772"},
	{latitude: 40.566429, longitude: -88.373678, zipCode: "61773"},
	{latitude: 40.432998, longitude: -89.195528, zipCode: "61774"},
	{latitude: 40.653696, longitude: -88.375962, zipCode: "61775"},
	{latitude: 40.572114, longitude: -88.870139, zipCode: "61776"},
	{latitude: 40.244063, longitude: -88.945663, zipCode: "61777"},
	{latitude: 40.225767, longitude: -89.091051, zipCode: "61778"},
	{latitude: 40.10952, longitude: -88.211031, zipCode: "61801"},
	{latitude: 40.123553, longitude: -88.153254, zipCode: "61802"},
	{latitude: 39.910131, longitude: -87.918408, zipCode: "61810"},
	{latitude: 40.295327, longitude: -87.621656, zipCode: "61811"},
	{latitude: 40.209777, longitude: -87.926661, zipCode: "61812"},
	{latitude: 39.910777, longitude: -88.565693, zipCode: "61813"},
	{latitude: 40.231924, longitude: -87.570194, zipCode: "61814"},
	{latitude: 40.110725, longitude: -88.367954, zipCode: "61815"},
	{latitude: 39.924446, longitude: -88.003968, zipCode: "61816"},
	{latitude: 40.035275, longitude: -87.716283, zipCode: "61817"},
	{latitude: 39.886702, longitude: -88.716473, zipCode: "61818"},
	{latitude: 40.11212, longitude: -88.244315, zipCode: "61820"},
	{latitude: 40.111099, longitude: -88.275533, zipCode: "61821"},
	{latitude: 40.133683, longitude: -88.302921, zipCode: "61822"},
	{latitude: 40.011061, longitude: -88.73034, zipCode: "61830"},
	{latitude: 40.226196, longitude: -87.797019, zipCode: "61831"},
	{latitude: 40.13236, longitude: -87.632069, zipCode: "61832"},
	{latitude: 40.099196, longitude: -87.64723, zipCode: "61833"},
	{latitude: 40.152546, longitude: -87.673298, zipCode: "61834"},
	{latitude: 40.131851, longitude: -88.636941, zipCode: "61839"},
	{latitude: 40.298873, longitude: -88.300263, zipCode: "61840"},
	{latitude: 40.034151, longitude: -87.817937, zipCode: "61841"},
	{latitude: 40.238774, longitude: -88.66522, zipCode: "61842"},
	{latitude: 40.317241, longitude: -88.373903, zipCode: "61843"},
	{latitude: 40.142967, longitude: -87.869207, zipCode: "61844"},
	{latitude: 40.373621, longitude: -88.396814, zipCode: "61845"},
	{latitude: 39.977252, longitude: -87.607538, zipCode: "61846"},
	{latitude: 40.30559, longitude: -88.009824, zipCode: "61847"},
	{latitude: 40.306942, longitude: -87.700962, zipCode: "61848"},
	{latitude: 40.014226, longitude: -87.96737, zipCode: "61849"},
	{latitude: 39.930087, longitude: -87.743452, zipCode: "61850"},
	{latitude: 39.96504, longitude: -88.435463, zipCode: "61851"},
	{latitude: 39.899498, longitude: -88.072753, zipCode: "61852"},
	{latitude: 40.229857, longitude: -88.421002, zipCode: "61853"},
	{latitude: 40.199872, longitude: -88.529328, zipCode: "61854"},
	{latitude: 39.924365, longitude: -88.660552, zipCode: "61855"},
	{latitude: 40.029305, longitude: -88.583656, zipCode: "61856"},
	{latitude: 40.11862, longitude: -87.843405, zipCode: "61857"},
	{latitude: 40.134258, longitude: -87.761877, zipCode: "61858"},
	{latitude: 40.150428, longitude: -87.962905, zipCode: "61859"},
	{latitude: 40.306027, longitude: -87.958606, zipCode: "61862"},
	{latitude: 39.904888, longitude: -88.281899, zipCode: "61863"},
	{latitude: 39.975064, longitude: -88.152723, zipCode: "61864"},
	{latitude: 40.302705, longitude: -87.802055, zipCode: "61865"},
	{latitude: 40.311624, longitude: -88.157981, zipCode: "61866"},
	{latitude: 39.903218, longitude: -87.615621, zipCode: "61870"},
	{latitude: 40.191174, longitude: -87.979219, zipCode: "61871"},
	{latitude: 39.93044, longitude: -88.376961, zipCode: "61872"},
	{latitude: 40.133896, longitude: -88.039122, zipCode: "61873"},
	{latitude: 40.054258, longitude: -88.266563, zipCode: "61874"},
	{latitude: 40.103467, longitude: -88.416253, zipCode: "61875"},
	{latitude: 39.901327, longitude: -87.836174, zipCode: "61876"},
	{latitude: 39.992886, longitude: -88.075895, zipCode: "61877"},
	{latitude: 40.242651, longitude: -88.145453, zipCode: "61878"},
	{latitude: 39.973795, longitude: -88.248906, zipCode: "61880"},
	{latitude: 40.10623, longitude: -88.754729, zipCode: "61882"},
	{latitude: 40.034937, longitude: -87.639918, zipCode: "61883"},
	{latitude: 40.090615, longitude: -88.49107, zipCode: "61884"},
	{latitude: 39.671146, longitude: -88.297107, zipCode: "61910"},
	{latitude: 39.700925, longitude: -88.469319, zipCode: "61911"},
	{latitude: 39.522381, longitude: -88.040843, zipCode: "61912"},
	{latitude: 39.826572, longitude: -88.471563, zipCode: "61913"},
	{latitude: 39.630343, longitude: -88.762449, zipCode: "61914"},
	{latitude: 39.717434, longitude: -87.894093, zipCode: "61917"},
	{latitude: 39.784439, longitude: -88.13777, zipCode: "61919"},
	{latitude: 39.511992, longitude: -88.154169, zipCode: "61920"},
	{latitude: 39.786183, longitude: -87.663252, zipCode: "61924"},
	{latitude: 39.719105, longitude: -88.840528, zipCode: "61925"},
	{latitude: 39.455959, longitude: -88.498197, zipCode: "61928"},
	{latitude: 39.824789, longitude: -88.625797, zipCode: "61929"},
	{latitude: 39.679328, longitude: -88.129, zipCode: "61930"},
	{latitude: 39.586143, longitude: -88.368055, zipCode: "61931"},
	{latitude: 39.799669, longitude: -87.874909, zipCode: "61932"},
	{latitude: 39.548469, longitude: -87.896313, zipCode: "61933"},
	{latitude: 39.803188, longitude: -88.726844, zipCode: "61936"},
	{latitude: 39.733828, longitude: -88.657314, zipCode: "61937"},
	{latitude: 39.479932, longitude: -88.37585, zipCode: "61938"},
	{latitude: 39.803938, longitude: -87.812475, zipCode: "61940"},
	{latitude: 39.804583, longitude: -88.075037, zipCode: "61941"},
	{latitude: 39.814412, longitude: -88.003205, zipCode: "61942"},
	{latitude: 39.661522, longitude: -88.023744, zipCode: "61943"},
	{latitude: 39.599796, longitude: -87.702066, zipCode: "61944"},
	{latitude: 39.657567, longitude: -87.858415, zipCode: "61949"},
	{latitude: 39.595821, longitude: -88.590378, zipCode: "61951"},
	{latitude: 39.797652, longitude: -88.290629, zipCode: "61953"},
	{latitude: 39.581626, longitude: -87.587505, zipCode: "61955"},
	{latitude: 39.85819, longitude: -88.135009, zipCode: "61956"},
	{latitude: 39.441804, longitude: -88.611557, zipCode: "61957"},
	{latitude: 38.881431, longitude: -89.745011, zipCode: "62001"},
	{latitude: 38.939234, longitude: -90.134948, zipCode: "62002"},
	{latitude: 39.064316, longitude: -90.678987, zipCode: "62006"},
	{latitude: 39.093581, longitude: -89.798129, zipCode: "62009"},
	{latitude: 38.919081, longitude: -90.048335, zipCode: "62010"},
	{latitude: 39.128667, longitude: -89.217556, zipCode: "62011"},
	{latitude: 39.044764, longitude: -90.148254, zipCode: "62012"},
	{latitude: 38.956374, longitude: -90.57358, zipCode: "62013"},
	{latitude: 39.043373, longitude: -89.950356, zipCode: "62014"},
	{latitude: 39.212268, longitude: -89.544222, zipCode: "62015"},
	{latitude: 39.29683, longitude: -90.415521, zipCode: "62016"},
	{latitude: 39.065108, longitude: -89.368607, zipCode: "62017"},
	{latitude: 38.913876, longitude: -90.081863, zipCode: "62018"},
	{latitude: 39.018669, longitude: -89.441465, zipCode: "62019"},
	{latitude: 38.983648, longitude: -89.976563, zipCode: "62021"},
	{latitude: 39.003161, longitude: -90.32618, zipCode: "62022"},
	{latitude: 39.108699, longitude: -89.779025, zipCode: "62023"},
	{latitude: 38.883132, longitude: -90.08995, zipCode: "62024"},
	{latitude: 38.829908, longitude: -89.931046, zipCode: "62025"},
	{latitude: 38.789441, longitude: -90.003005, zipCode: "62026"},
	{latitude: 39.243387, longitude: -90.557406, zipCode: "62027"},
	{latitude: 38.958193, longitude: -90.354834, zipCode: "62028"},
	{latitude: 39.154609, longitude: -90.164457, zipCode: "62030"},
	{latitude: 39.111496, longitude: -90.53693, zipCode: "62031"},
	{latitude: 39.115814, longitude: -89.288315, zipCode: "62032"},
	{latitude: 39.136563, longitude: -89.839916, zipCode: "62033"},
	{latitude: 38.754873, longitude: -89.972216, zipCode: "62034"},
	{latitude: 38.958664, longitude: -90.242706, zipCode: "62035"},
	{latitude: 38.918311, longitude: -90.589735, zipCode: "62036"},
	{latitude: 39.010943, longitude: -90.465066, zipCode: "62037"},
	{latitude: 38.714928, longitude: -90.09893, zipCode: "62040"},
	{latitude: 39.365787, longitude: -90.202032, zipCode: "62044"},
	{latitude: 39.264194, longitude: -90.690137, zipCode: "62045"},
	{latitude: 38.892463, longitude: -89.838728, zipCode: "62046"},
	{latitude: 39.123135, longitude: -90.626568, zipCode: "62047"},
	{latitude: 38.772852, longitude: -90.13716, zipCode: "62048"},
	{latitude: 39.141039, longitude: -89.47951, zipCode: "62049"},
	{latitude: 39.442243, longitude: -90.573308, zipCode: "62050"},
	{latitude: 39.20165, longitude: -89.403275, zipCode: "62051"},
	{latitude: 39.112832, longitude: -90.319526, zipCode: "62052"},
	{latitude: 39.319254, longitude: -90.66542, zipCode: "62053"},
	{latitude: 39.207474, longitude: -90.350632, zipCode: "62054"},
	{latitude: 39.175595, longitude: -89.674359, zipCode: "62056"},
	{latitude: 38.968437, longitude: -89.764607, zipCode: "62058"},
	{latitude: 38.653126, longitude: -90.173352, zipCode: "62059"},
	{latitude: 38.681805, longitude: -90.142416, zipCode: "62060"},
	{latitude: 38.790684, longitude: -89.776379, zipCode: "62061"},
	{latitude: 38.725658, longitude: -89.963126, zipCode: "62062"},
	{latitude: 39.198525, longitude: -90.149861, zipCode: "62063"},
	{latitude: 38.929024, longitude: -89.983292, zipCode: "62067"},
	{latitude: 39.089106, longitude: -89.739298, zipCode: "62069"},
	{latitude: 38.955001, longitude: -89.689739, zipCode: "62074"},
	{latitude: 39.302086, longitude: -89.295837, zipCode: "62075"},
	{latitude: 39.341875, longitude: -89.233793, zipCode: "62076"},
	{latitude: 39.031822, longitude: -89.511541, zipCode: "62077"},
	{latitude: 39.47985, longitude: -90.486842, zipCode: "62078"},
	{latitude: 39.108389, longitude: -90.131819, zipCode: "62079"},
	{latitude: 39.141918, longitude: -89.1031, zipCode: "62080"},
	{latitude: 39.264927, longitude: -90.225, zipCode: "62081"},
	{latitude: 39.489989, longitude: -90.317508, zipCode: "62082"},
	{latitude: 39.351192, longitude: -89.198408, zipCode: "62083"},
	{latitude: 38.840697, longitude: -90.063424, zipCode: "62084"},
	{latitude: 39.0819, longitude: -89.804504, zipCode: "62085"},
	{latitude: 38.989931, longitude: -89.589606, zipCode: "62086"},
	{latitude: 38.814811, longitude: -90.071029, zipCode: "62087"},
	{latitude: 39.014677, longitude: -89.798527, zipCode: "62088"},
	{latitude: 39.117829, longitude: -89.486654, zipCode: "62089"},
	{latitude: 38.667097, longitude: -90.166525, zipCode: "62090"},
	{latitude: 39.056246, longitude: -89.610567, zipCode: "62091"},
	{latitude: 39.418572, longitude: -90.431226, zipCode: "62092"},
	{latitude: 39.064746, longitude: -89.841993, zipCode: "62093"},
	{latitude: 39.238645, longitude: -89.349827, zipCode: "62094"},
	{latitude: 38.862573, longitude: -90.074295, zipCode: "62095"},
	{latitude: 38.936676, longitude: -89.852687, zipCode: "62097"},
	{latitude: 39.383756, longitude: -90.29943, zipCode: "62098"},
	{latitude: 38.635131, longitude: -90.140711, zipCode: "62201"},
	{latitude: 38.601691, longitude: -90.075971, zipCode: "62203"},
	{latitude: 38.629486, longitude: -90.095925, zipCode: "62204"},
	{latitude: 38.602768, longitude: -90.118561, zipCode: "62205"},
	{latitude: 38.56793, longitude: -90.16933, zipCode: "62206"},
	{latitude: 38.582893, longitude: -90.125415, zipCode: "62207"},
	{latitude: 38.595341, longitude: -90.004735, zipCode: "62208"},
	{latitude: 38.376365, longitude: -89.603686, zipCode: "62214"},
	{latitude: 38.507849, longitude: -89.600785, zipCode: "62215"},
	{latitude: 38.632462, longitude: -89.602359, zipCode: "62216"},
	{latitude: 38.173836, longitude: -89.838573, zipCode: "62217"},
	{latitude: 38.522194, longitude: -89.473978, zipCode: "62218"},
	{latitude: 38.603613, longitude: -89.437167, zipCode: "62219"},
	{latitude: 38.478277, longitude: -89.998032, zipCode: "62220"},
	{latitude: 38.511713, longitude: -89.902416, zipCode: "62221"},
	{latitude: 38.535923, longitude: -90.059477, zipCode: "62223"},
	{latitude: 38.542974, longitude: -89.852127, zipCode: "62225"},
	{latitude: 38.536763, longitude: -90.000344, zipCode: "62226"},
	{latitude: 38.635005, longitude: -89.530995, zipCode: "62230"},
	{latitude: 38.653115, longitude: -89.326853, zipCode: "62231"},
	{latitude: 38.632444, longitude: -90.00545, zipCode: "62232"},
	{latitude: 37.942252, longitude: -89.788551, zipCode: "62233"},
	{latitude: 38.682181, longitude: -89.980621, zipCode: "62234"},
	{latitude: 38.433901, longitude: -90.211941, zipCode: "62236"},
	{latitude: 38.197835, longitude: -89.569294, zipCode: "62237"},
	{latitude: 38.052604, longitude: -89.543786, zipCode: "62238"},
	{latitude: 38.524614, longitude: -90.156695, zipCode: "62239"},
	{latitude: 38.501857, longitude: -90.176557, zipCode: "62240"},
	{latitude: 38.011185, longitude: -89.893884, zipCode: "62241"},
	{latitude: 38.098949, longitude: -89.937731, zipCode: "62242"},
	{latitude: 38.419053, longitude: -89.8894, zipCode: "62243"},
	{latitude: 38.195693, longitude: -90.198775, zipCode: "62244"},
	{latitude: 38.531041, longitude: -89.575162, zipCode: "62245"},
	{latitude: 38.890366, longitude: -89.427577, zipCode: "62246"},
	{latitude: 38.30589, longitude: -89.992855, zipCode: "62248"},
	{latitude: 38.755333, longitude: -89.667323, zipCode: "62249"},
	{latitude: 38.540493, longitude: -89.264342, zipCode: "62250"},
	{latitude: 38.777732, longitude: -89.300085, zipCode: "62253"},
	{latitude: 38.609368, longitude: -89.822871, zipCode: "62254"},
	{latitude: 38.313204, longitude: -89.783121, zipCode: "62255"},
	{latitude: 38.226376, longitude: -90.231493, zipCode: "62256"},
	{latitude: 38.284874, longitude: -89.728709, zipCode: "62257"},
	{latitude: 38.460897, longitude: -89.782081, zipCode: "62258"},
	{latitude: 38.460929, longitude: -90.098634, zipCode: "62260"},
	{latitude: 38.006752, longitude: -89.997271, zipCode: "62261"},
	{latitude: 38.939413, longitude: -89.263839, zipCode: "62262"},
	{latitude: 38.318988, longitude: -89.401159, zipCode: "62263"},
	{latitude: 38.315878, longitude: -89.899945, zipCode: "62264"},
	{latitude: 38.506152, longitude: -89.680732, zipCode: "62265"},
	{latitude: 38.484804, longitude: -89.6573, zipCode: "62266"},
	{latitude: 38.276295, longitude: -89.539572, zipCode: "62268"},
	{latitude: 38.600006, longitude: -89.915402, zipCode: "62269"},
	{latitude: 38.440109, longitude: -89.520464, zipCode: "62271"},
	{latitude: 37.996234, longitude: -89.616433, zipCode: "62272"},
	{latitude: 38.78684, longitude: -89.583768, zipCode: "62273"},
	{latitude: 38.091163, longitude: -89.40027, zipCode: "62274"},
	{latitude: 38.814556, longitude: -89.547072, zipCode: "62275"},
	{latitude: 38.096972, longitude: -90.112198, zipCode: "62277"},
	{latitude: 38.20377, longitude: -89.991304, zipCode: "62278"},
	{latitude: 38.133836, longitude: -90.135803, zipCode: "62279"},
	{latitude: 37.846621, longitude: -89.648009, zipCode: "62280"},
	{latitude: 38.70688, longitude: -89.779355, zipCode: "62281"},
	{latitude: 38.356433, longitude: -89.71286, zipCode: "62282"},
	{latitude: 38.864144, longitude: -89.310441, zipCode: "62284"},
	{latitude: 38.392279, longitude: -90.008393, zipCode: "62285"},
	{latitude: 38.116958, longitude: -89.717653, zipCode: "62286"},
	{latitude: 37.993373, longitude: -89.68961, zipCode: "62288"},
	{latitude: 38.597636, longitude: -89.751278, zipCode: "62289"},
	{latitude: 38.213149, longitude: -89.681962, zipCode: "62292"},
	{latitude: 38.616002, longitude: -89.695483, zipCode: "62293"},
	{latitude: 38.702562, longitude: -89.867485, zipCode: "62294"},
	{latitude: 38.271875, longitude: -90.310764, zipCode: "62295"},
	{latitude: 38.052121, longitude: -89.806332, zipCode: "62297"},
	{latitude: 38.308842, longitude: -90.149413, zipCode: "62298"},
	{latitude: 39.931155, longitude: -91.388714, zipCode: "62301"},
	{latitude: 39.928192, longitude: -91.343933, zipCode: "62305"},
	{latitude: 40.222576, longitude: -90.935227, zipCode: "62311"},
	{latitude: 39.707275, longitude: -91.024203, zipCode: "62312"},
	{latitude: 40.317726, longitude: -91.228289, zipCode: "62313"},
	{latitude: 39.767988, longitude: -90.891698, zipCode: "62314"},
	{latitude: 40.235474, longitude: -91.053025, zipCode: "62316"},
	{latitude: 40.15292, longitude: -90.73001, zipCode: "62319"},
	{latitude: 40.030454, longitude: -91.082935, zipCode: "62320"},
	{latitude: 40.409716, longitude: -91.095432, zipCode: "62321"},
	{latitude: 39.800419, longitude: -90.674455, zipCode: "62323"},
	{latitude: 39.998277, longitude: -90.966328, zipCode: "62324"},
	{latitude: 40.045624, longitude: -91.162046, zipCode: "62325"},
	{latitude: 40.422789, longitude: -90.813562, zipCode: "62326"},
	{latitude: 40.571437, longitude: -91.125784, zipCode: "62330"},
	{latitude: 40.395595, longitude: -91.248792, zipCode: "62334"},
	{latitude: 40.467551, longitude: -91.173341, zipCode: "62336"},
	{latitude: 39.998692, longitude: -91.237201, zipCode: "62338"},
	{latitude: 40.128504, longitude: -91.0381, zipCode: "62339"},
	{latitude: 39.734718, longitude: -90.728238, zipCode: "62340"},
	{latitude: 40.43343, longitude: -91.300936, zipCode: "62341"},
	{latitude: 39.696603, longitude: -91.245013, zipCode: "62343"},
	{latitude: 40.179891, longitude: -90.824965, zipCode: "62344"},
	{latitude: 39.697299, longitude: -91.112564, zipCode: "62345"},
	{latitude: 40.139846, longitude: -90.936403, zipCode: "62346"},
	{latitude: 39.870093, longitude: -91.088955, zipCode: "62347"},
	{latitude: 40.188341, longitude: -91.371244, zipCode: "62348"},
	{latitude: 40.170546, longitude: -91.191859, zipCode: "62349"},
	{latitude: 40.113608, longitude: -91.268867, zipCode: "62351"},
	{latitude: 39.568163, longitude: -90.654562, zipCode: "62352"},
	{latitude: 39.973028, longitude: -90.757546, zipCode: "62353"},
	{latitude: 40.517396, longitude: -91.342092, zipCode: "62354"},
	{latitude: 39.412432, longitude: -90.778724, zipCode: "62355"},
	{latitude: 39.588873, longitude: -91.07967, zipCode: "62356"},
	{latitude: 39.68582, longitude: -90.867067, zipCode: "62357"},
	{latitude: 40.587804, longitude: -91.263164, zipCode: "62358"},
	{latitude: 40.037373, longitude: -91.212083, zipCode: "62359"},
	{latitude: 39.817324, longitude: -91.261413, zipCode: "62360"},
	{latitude: 39.492118, longitude: -90.621524, zipCode: "62361"},
	{latitude: 39.776193, longitude: -90.764319, zipCode: "62362"},
	{latitude: 39.590496, longitude: -90.769665, zipCode: "62363"},
	{latitude: 39.796408, longitude: -91.165114, zipCode: "62365"},
	{latitude: 39.472086, longitude: -90.901821, zipCode: "62366"},
	{latitude: 40.299887, longitude: -90.865155, zipCode: "62367"},
	{latitude: 39.517293, longitude: -91.007228, zipCode: "62370"},
	{latitude: 40.242748, longitude: -91.364953, zipCode: "62373"},
	{latitude: 40.407678, longitude: -90.910538, zipCode: "62374"},
	{latitude: 40.002127, longitude: -90.857282, zipCode: "62375"},
	{latitude: 40.113632, longitude: -91.407737, zipCode: "62376"},
	{latitude: 39.905859, longitude: -90.634784, zipCode: "62378"},
	{latitude: 40.269272, longitude: -91.403368, zipCode: "62379"},
	{latitude: 40.23823, longitude: -91.190901, zipCode: "62380"},
	{latitude: 39.118715, longitude: -88.562384, zipCode: "62401"},
	{latitude: 38.5388, longitude: -87.733213, zipCode: "62410"},
	{latitude: 39.071265, longitude: -88.733641, zipCode: "62411"},
	{latitude: 39.138821, longitude: -87.837837, zipCode: "62413"},
	{latitude: 39.174446, longitude: -88.826372, zipCode: "62414"},
	{latitude: 38.703351, longitude: -87.765689, zipCode: "62417"},
	{latitude: 39.022272, longitude: -88.973758, zipCode: "62418"},
	{latitude: 38.626535, longitude: -87.980115, zipCode: "62419"},
	{latitude: 39.287028, longitude: -87.998551, zipCode: "62420"},
	{latitude: 38.752142, longitude: -87.943453, zipCode: "62421"},
	{latitude: 39.233282, longitude: -88.888586, zipCode: "62422"},
	{latitude: 39.462687, longitude: -87.57989, zipCode: "62423"},
	{latitude: 38.988265, longitude: -88.428594, zipCode: "62424"},
	{latitude: 38.836831, longitude: -88.098691, zipCode: "62425"},
	{latitude: 38.892329, longitude: -88.664014, zipCode: "62426"},
	{latitude: 38.873127, longitude: -87.649864, zipCode: "62427"},
	{latitude: 39.262654, longitude: -88.136713, zipCode: "62428"},
	{latitude: 39.225294, longitude: -88.996541, zipCode: "62431"},
	{latitude: 39.133517, longitude: -88.141763, zipCode: "62432"},
	{latitude: 39.106742, longitude: -87.715756, zipCode: "62433"},
	{latitude: 38.842047, longitude: -88.321795, zipCode: "62434"},
	{latitude: 39.182449, longitude: -88.256092, zipCode: "62436"},
	{latitude: 39.325541, longitude: -88.870056, zipCode: "62438"},
	{latitude: 38.744062, longitude: -87.639699, zipCode: "62439"},
	{latitude: 39.39431, longitude: -88.26325, zipCode: "62440"},
	{latitude: 39.394116, longitude: -87.698696, zipCode: "62441"},
	{latitude: 39.303946, longitude: -87.853807, zipCode: "62442"},
	{latitude: 38.955761, longitude: -88.631112, zipCode: "62443"},
	{latitude: 39.278519, longitude: -88.734717, zipCode: "62444"},
	{latitude: 39.165285, longitude: -88.330174, zipCode: "62445"},
	{latitude: 38.489913, longitude: -88.206714, zipCode: "62446"},
	{latitude: 39.313232, longitude: -88.444715, zipCode: "62447"},
	{latitude: 38.968356, longitude: -88.179181, zipCode: "62448"},
	{latitude: 39.004119, longitude: -87.91063, zipCode: "62449"},
	{latitude: 38.713171, longitude: -88.089293, zipCode: "62450"},
	{latitude: 38.971206, longitude: -87.611114, zipCode: "62451"},
	{latitude: 38.590061, longitude: -88.014703, zipCode: "62452"},
	{latitude: 38.992348, longitude: -87.764978, zipCode: "62454"},
	{latitude: 39.025807, longitude: -88.854507, zipCode: "62458"},
	{latitude: 38.929977, longitude: -88.027782, zipCode: "62459"},
	{latitude: 38.601695, longitude: -87.727208, zipCode: "62460"},
	{latitude: 39.194169, longitude: -88.684349, zipCode: "62461"},
	{latitude: 39.238766, longitude: -88.468514, zipCode: "62462"},
	{latitude: 39.280639, longitude: -88.59123, zipCode: "62463"},
	{latitude: 38.997956, longitude: -87.82379, zipCode: "62464"},
	{latitude: 39.346135, longitude: -88.638265, zipCode: "62465"},
	{latitude: 38.728414, longitude: -87.852631, zipCode: "62466"},
	{latitude: 39.128079, longitude: -88.435402, zipCode: "62467"},
	{latitude: 39.276946, longitude: -88.270309, zipCode: "62468"},
	{latitude: 39.351236, longitude: -88.333827, zipCode: "62469"},
	{latitude: 38.958929, longitude: -89.130198, zipCode: "62471"},
	{latitude: 39.021484, longitude: -88.567904, zipCode: "62473"},
	{latitude: 39.456917, longitude: -88.035856, zipCode: "62474"},
	{latitude: 38.881919, longitude: -88.048614, zipCode: "62475"},
	{latitude: 38.532205, longitude: -88.015447, zipCode: "62476"},
	{latitude: 39.233553, longitude: -87.643438, zipCode: "62477"},
	{latitude: 39.179972, longitude: -87.743616, zipCode: "62478"},
	{latitude: 39.037041, longitude: -88.306897, zipCode: "62479"},
	{latitude: 38.997392, longitude: -87.999575, zipCode: "62480"},
	{latitude: 39.126601, longitude: -88.022953, zipCode: "62481"},
	{latitude: 39.926572, longitude: -88.814353, zipCode: "62501"},
	{latitude: 39.530665, longitude: -89.028916, zipCode: "62510"},
	{latitude: 40.143468, longitude: -89.210602, zipCode: "62512"},
	{latitude: 39.721278, longitude: -89.150274, zipCode: "62513"},
	{latitude: 39.767497, longitude: -89.045007, zipCode: "62514"},
	{latitude: 39.860176, longitude: -89.365875, zipCode: "62515"},
	{latitude: 39.59421, longitude: -89.433251, zipCode: "62517"},
	{latitude: 40.051402, longitude: -89.203009, zipCode: "62518"},
	{latitude: 39.928801, longitude: -89.395313, zipCode: "62519"},
	{latitude: 39.824552, longitude: -89.456341, zipCode: "62520"},
	{latitude: 39.81017, longitude: -88.927784, zipCode: "62521"},
	{latitude: 39.827583, longitude: -89.0478, zipCode: "62522"},
	{latitude: 39.843721, longitude: -88.952944, zipCode: "62523"},
	{latitude: 39.901709, longitude: -88.989191, zipCode: "62526"},
	{latitude: 39.560144, longitude: -89.656576, zipCode: "62530"},
	{latitude: 39.674378, longitude: -89.37756, zipCode: "62531"},
	{latitude: 39.776207, longitude: -88.977597, zipCode: "62532"},
	{latitude: 39.44586, longitude: -89.629816, zipCode: "62533"},
	{latitude: 39.529684, longitude: -88.800594, zipCode: "62534"},
	{latitude: 39.925511, longitude: -88.96604, zipCode: "62535"},
	{latitude: 39.629019, longitude: -89.652265, zipCode: "62536"},
	{latitude: 39.852606, longitude: -89.09325, zipCode: "62537"},
	{latitude: 39.356306, longitude: -89.520465, zipCode: "62538"},
	{latitude: 39.866801, longitude: -89.249159, zipCode: "62539"},
	{latitude: 39.584873, longitude: -89.419855, zipCode: "62540"},
	{latitude: 39.956556, longitude: -89.370868, zipCode: "62541"},
	{latitude: 39.970816, longitude: -89.151833, zipCode: "62543"},
	{latitude: 39.70855, longitude: -88.973742, zipCode: "62544"},
	{latitude: 39.767754, longitude: -89.384972, zipCode: "62545"},
	{latitude: 39.431455, longitude: -89.445923, zipCode: "62546"},
	{latitude: 39.770482, longitude: -89.242253, zipCode: "62547"},
	{latitude: 39.981254, longitude: -89.283539, zipCode: "62548"},
	{latitude: 39.771029, longitude: -88.867155, zipCode: "62549"},
	{latitude: 39.617876, longitude: -88.989628, zipCode: "62550"},
	{latitude: 39.854146, longitude: -89.155878, zipCode: "62551"},
	{latitude: 39.271818, longitude: -89.105098, zipCode: "62553"},
	{latitude: 39.944482, longitude: -88.882274, zipCode: "62554"},
	{latitude: 39.46298, longitude: -89.210466, zipCode: "62555"},
	{latitude: 39.474974, longitude: -89.375213, zipCode: "62556"},
	{latitude: 39.401766, longitude: -89.105904, zipCode: "62557"},
	{latitude: 39.586712, longitude: -89.549853, zipCode: "62558"},
	{latitude: 39.29863, longitude: -89.602818, zipCode: "62560"},
	{latitude: 39.871116, longitude: -89.506726, zipCode: "62561"},
	{latitude: 39.719259, longitude: -89.496252, zipCode: "62563"},
	{latitude: 39.416775, longitude: -88.803851, zipCode: "62565"},
	{latitude: 39.646674, longitude: -89.190804, zipCode: "62567"},
	{latitude: 39.559732, longitude: -89.287031, zipCode: "62568"},
	{latitude: 39.578559, longitude: -89.451718, zipCode: "62570"},
	{latitude: 39.381008, longitude: -88.960225, zipCode: "62571"},
	{latitude: 39.359076, longitude: -89.697767, zipCode: "62572"},
	{latitude: 39.945515, longitude: -89.072649, zipCode: "62573"},
	{latitude: 39.750251, longitude: -90.039027, zipCode: "62601"},
	{latitude: 39.538883, longitude: -90.446226, zipCode: "62610"},
	{latitude: 39.884116, longitude: -90.398177, zipCode: "62611"},
	{latitude: 39.896048, longitude: -90.063158, zipCode: "62612"},
	{latitude: 39.98262, longitude: -89.665216, zipCode: "62613"},
	{latitude: 39.585684, longitude: -89.754068, zipCode: "62615"},
	{latitude: 40.156822, longitude: -90.16989, zipCode: "62617"},
	{latitude: 40.008414, longitude: -90.397111, zipCode: "62618"},
	{latitude: 39.72672, longitude: -90.52058, zipCode: "62621"},
	{latitude: 39.977864, longitude: -90.34305, zipCode: "62622"},
	{latitude: 40.15929, longitude: -90.346784, zipCode: "62624"},
	{latitude: 39.901203, longitude: -89.701769, zipCode: "62625"},
	{latitude: 39.28461, longitude: -89.883614, zipCode: "62626"},
	{latitude: 40.059128, longitude: -90.111077, zipCode: "62627"},
	{latitude: 39.7794, longitude: -90.402013, zipCode: "62628"},
	{latitude: 39.677477, longitude: -89.716922, zipCode: "62629"},
	{latitude: 39.27113, longitude: -90.086348, zipCode: "62630"},
	{latitude: 39.827417, longitude: -90.354199, zipCode: "62631"},
	{latitude: 40.22354, longitude: -89.870793, zipCode: "62633"},
	{latitude: 40.013776, longitude: -89.451557, zipCode: "62634"},
	{latitude: 40.296538, longitude: -89.465729, zipCode: "62635"},
	{latitude: 39.609301, longitude: -90.081236, zipCode: "62638"},
	{latitude: 40.03767, longitude: -90.486012, zipCode: "62639"},
	{latitude: 39.428269, longitude: -89.80498, zipCode: "62640"},
	{latitude: 40.093598, longitude: -89.724853, zipCode: "62642"},
	{latitude: 40.241528, longitude: -89.457767, zipCode: "62643"},
	{latitude: 40.285418, longitude: -90.061898, zipCode: "62644"},
	{latitude: 39.357549, longitude: -90.060042, zipCode: "62649"},
	{latitude: 39.738301, longitude: -90.220141, zipCode: "62650"},
	{latitude: 40.169931, longitude: -89.986537, zipCode: "62655"},
	{latitude: 40.139677, longitude: -89.366192, zipCode: "62656"},
	{latitude: 39.668004, longitude: -89.826974, zipCode: "62661"},
	{latitude: 39.541057, longitude: -90.327474, zipCode: "62663"},
	{latitude: 40.212562, longitude: -89.724144, zipCode: "62664"},
	{latitude: 39.816949, longitude: -90.529555, zipCode: "62665"},
	{latitude: 40.082439, longitude: -89.539596, zipCode: "62666"},
	{latitude: 39.487765, longitude: -89.992297, zipCode: "62667"},
	{latitude: 39.565929, longitude: -90.240289, zipCode: "62668"},
	{latitude: 39.743479, longitude: -89.898578, zipCode: "62670"},
	{latitude: 40.172249, longitude: -89.549117, zipCode: "62671"},
	{latitude: 39.398009, longitude: -89.804455, zipCode: "62672"},
	{latitude: 40.097105, longitude: -89.9688, zipCode: "62673"},
	{latitude: 39.441118, longitude: -90.023313, zipCode: "62674"},
	{latitude: 40.026477, longitude: -89.867144, zipCode: "62675"},
	{latitude: 39.852668, longitude: -89.879391, zipCode: "62677"},
	{latitude: 40.136241, longitude: -90.542754, zipCode: "62681"},
	{latitude: 40.298725, longitude: -89.630971, zipCode: "62682"},
	{latitude: 39.909142, longitude: -89.586759, zipCode: "62684"},
	{latitude: 39.139898, longitude: -89.991836, zipCode: "62685"},
	{latitude: 39.944993, longitude: -89.924125, zipCode: "62688"},
	{latitude: 39.534044, longitude: -89.765721, zipCode: "62689"},
	{latitude: 39.505643, longitude: -89.766163, zipCode: "62690"},
	{latitude: 39.958491, longitude: -90.228819, zipCode: "62691"},
	{latitude: 39.583553, longitude: -89.939164, zipCode: "62692"},
	{latitude: 39.958048, longitude: -89.516172, zipCode: "62693"},
	{latitude: 39.62278, longitude: -90.477118, zipCode: "62694"},
	{latitude: 39.619374, longitude: -90.224197, zipCode: "62695"},
	{latitude: 39.800544, longitude: -89.648894, zipCode: "62701"},
	{latitude: 39.824222, longitude: -89.64273, zipCode: "62702"},
	{latitude: 39.763035, longitude: -89.631099, zipCode: "62703"},
	{latitude: 39.773498, longitude: -89.68384, zipCode: "62704"},
	{latitude: 39.797886, longitude: -89.655398, zipCode: "62706"},
	{latitude: 39.855571, longitude: -89.650079, zipCode: "62707"},
	{latitude: 39.766136, longitude: -89.732756, zipCode: "62711"},
	{latitude: 39.748105, longitude: -89.581559, zipCode: "62712"},
	{latitude: 38.511022, longitude: -89.140448, zipCode: "62801"},
	{latitude: 38.453336, longitude: -89.294973, zipCode: "62803"},
	{latitude: 38.387363, longitude: -88.086971, zipCode: "62806"},
	{latitude: 38.751557, longitude: -88.929233, zipCode: "62807"},
	{latitude: 38.30618, longitude: -89.188699, zipCode: "62808"},
	{latitude: 38.278964, longitude: -88.337811, zipCode: "62809"},
	{latitude: 38.205899, longitude: -88.743061, zipCode: "62810"},
	{latitude: 38.387361, longitude: -87.903604, zipCode: "62811"},
	{latitude: 38.006432, longitude: -88.92252, zipCode: "62812"},
	{latitude: 38.378397, longitude: -88.731915, zipCode: "62814"},
	{latitude: 38.458352, longitude: -87.966441, zipCode: "62815"},
	{latitude: 38.189787, longitude: -88.922971, zipCode: "62816"},
	{latitude: 37.954474, longitude: -88.476107, zipCode: "62817"},
	{latitude: 38.377679, longitude: -87.967518, zipCode: "62818"},
	{latitude: 37.974472, longitude: -89.004769, zipCode: "62819"},
	{latitude: 38.248629, longitude: -88.235358, zipCode: "62820"},
	{latitude: 38.071022, longitude: -88.129673, zipCode: "62821"},
	{latitude: 37.973972, longitude: -89.054102, zipCode: "62822"},
	{latitude: 38.517707, longitude: -88.437321, zipCode: "62823"},
	{latitude: 38.669458, longitude: -88.341676, zipCode: "62824"},
	{latitude: 37.998152, longitude: -89.05902, zipCode: "62825"},
	{latitude: 38.170656, longitude: -88.047697, zipCode: "62827"},
	{latitude: 38.194532, longitude: -88.601576, zipCode: "62828"},
	{latitude: 38.003336, longitude: -88.490961, zipCode: "62829"},
	{latitude: 38.443409, longitude: -88.967632, zipCode: "62830"},
	{latitude: 38.23495, longitude: -89.222731, zipCode: "62831"},
	{latitude: 38.00859, longitude: -89.252759, zipCode: "62832"},
	{latitude: 38.340234, longitude: -88.167646, zipCode: "62833"},
	{latitude: 38.106046, longitude: -88.321669, zipCode: "62835"},
	{latitude: 38.094569, longitude: -88.805455, zipCode: "62836"},
	{latitude: 38.36518, longitude: -88.357724, zipCode: "62837"},
	{latitude: 38.864237, longitude: -88.745462, zipCode: "62838"},
	{latitude: 38.660786, longitude: -88.49381, zipCode: "62839"},
	{latitude: 37.858686, longitude: -88.996437, zipCode: "62841"},
	{latitude: 38.467921, longitude: -88.389471, zipCode: "62842"},
	{latitude: 38.38221, longitude: -88.199061, zipCode: "62843"},
	{latitude: 38.251442, longitude: -88.048528, zipCode: "62844"},
	{latitude: 38.150884, longitude: -88.855063, zipCode: "62846"},
	{latitude: 38.434045, longitude: -89.166391, zipCode: "62848"},
	{latitude: 38.58494, longitude: -88.773698, zipCode: "62849"},
	{latitude: 38.494727, longitude: -88.59482, zipCode: "62850"},
	{latitude: 38.427545, longitude: -88.659954, zipCode: "62851"},
	{latitude: 38.349942, longitude: -87.866757, zipCode: "62852"},
	{latitude: 38.516107, longitude: -88.912193, zipCode: "62853"},
	{latitude: 38.76451, longitude: -88.824003, zipCode: "62854"},
	{latitude: 37.952271, longitude: -88.838385, zipCode: "62856"},
	{latitude: 38.811813, longitude: -88.49295, zipCode: "62858"},
	{latitude: 38.066004, longitude: -88.539666, zipCode: "62859"},
	{latitude: 38.041367, longitude: -88.731934, zipCode: "62860"},
	{latitude: 38.035177, longitude: -88.045277, zipCode: "62861"},
	{latitude: 38.219114, longitude: -88.299337, zipCode: "62862"},
	{latitude: 38.415849, longitude: -87.85988, zipCode: "62863"},
	{latitude: 38.330513, longitude: -88.902367, zipCode: "62864"},
	{latitude: 37.974942, longitude: -89.082497, zipCode: "62865"},
	{latitude: 37.912235, longitude: -88.098929, zipCode: "62867"},
	{latitude: 38.67771, longitude: -88.228125, zipCode: "62868"},
	{latitude: 37.9566, longitude: -88.277773, zipCode: "62869"},
	{latitude: 38.635153, longitude: -89.053057, zipCode: "62870"},
	{latitude: 37.882055, longitude: -88.269243, zipCode: "62871"},
	{latitude: 38.271585, longitude: -88.800672, zipCode: "62872"},
	{latitude: 37.917551, longitude: -88.978331, zipCode: "62874"},
	{latitude: 38.755451, longitude: -89.121169, zipCode: "62875"},
	{latitude: 38.277107, longitude: -89.194829, zipCode: "62876"},
	{latitude: 38.392467, longitude: -89.19963, zipCode: "62877"},
	{latitude: 38.575036, longitude: -88.4966, zipCode: "62878"},
	{latitude: 38.765021, longitude: -88.359032, zipCode: "62879"},
	{latitude: 38.867518, longitude: -88.885942, zipCode: "62880"},
	{latitude: 38.638551, longitude: -88.927259, zipCode: "62881"},
	{latitude: 38.636254, longitude: -89.118646, zipCode: "62882"},
	{latitude: 38.168038, longitude: -89.104683, zipCode: "62883"},
	{latitude: 38.085286, longitude: -89.042349, zipCode: "62884"},
	{latitude: 38.860481, longitude: -89.064583, zipCode: "62885"},
	{latitude: 38.378661, longitude: -88.534706, zipCode: "62886"},
	{latitude: 38.186329, longitude: -88.369035, zipCode: "62887"},
	{latitude: 38.127458, longitude: -89.228979, zipCode: "62888"},
	{latitude: 38.456539, longitude: -88.823598, zipCode: "62889"},
	{latitude: 37.906205, longitude: -88.737966, zipCode: "62890"},
	{latitude: 38.016005, longitude: -89.041198, zipCode: "62891"},
	{latitude: 38.808565, longitude: -89.07566, zipCode: "62892"},
	{latitude: 38.465816, longitude: -89.030176, zipCode: "62893"},
	{latitude: 38.209538, longitude: -89.039635, zipCode: "62894"},
	{latitude: 38.305545, longitude: -88.555138, zipCode: "62895"},
	{latitude: 37.892664, longitude: -88.912076, zipCode: "62896"},
	{latitude: 38.093889, longitude: -88.911821, zipCode: "62897"},
	{latitude: 38.350379, longitude: -89.060166, zipCode: "62898"},
	{latitude: 38.618912, longitude: -88.642448, zipCode: "62899"},
	{latitude: 37.740824, longitude: -89.208976, zipCode: "62901"},
	{latitude: 37.674381, longitude: -89.112452, zipCode: "62902"},
	{latitude: 37.674118, longitude: -89.276234, zipCode: "62903"},
	{latitude: 37.55771, longitude: -89.367362, zipCode: "62905"},
	{latitude: 37.462889, longitude: -89.177551, zipCode: "62906"},
	{latitude: 37.863159, longitude: -89.495706, zipCode: "62907"},
	{latitude: 37.310226, longitude: -88.864901, zipCode: "62908"},
	{latitude: 37.153109, longitude: -88.534459, zipCode: "62910"},
	{latitude: 37.476199, longitude: -89.032362, zipCode: "62912"},
	{latitude: 37.040124, longitude: -89.204316, zipCode: "62914"},
	{latitude: 37.784449, longitude: -89.122134, zipCode: "62915"},
	{latitude: 37.943305, longitude: -89.578759, zipCode: "62916"},
	{latitude: 37.693416, longitude: -88.651628, zipCode: "62917"},
	{latitude: 37.786372, longitude: -89.086175, zipCode: "62918"},
	{latitude: 37.524023, longitude: -88.138368, zipCode: "62919"},
	{latitude: 37.553584, longitude: -89.232109, zipCode: "62920"},
	{latitude: 37.798612, longitude: -89.082777, zipCode: "62921"},
	{latitude: 37.620754, longitude: -88.814807, zipCode: "62922"},
	{latitude: 37.342143, longitude: -89.035402, zipCode: "62923"},
	{latitude: 37.815148, longitude: -89.195549, zipCode: "62924"},
	{latitude: 37.366678, longitude: -89.131409, zipCode: "62926"},
	{latitude: 37.943544, longitude: -89.251992, zipCode: "62927"},
	{latitude: 37.475405, longitude: -88.567239, zipCode: "62928"},
	{latitude: 37.847199, longitude: -88.441577, zipCode: "62930"},
	{latitude: 37.523685, longitude: -88.275056, zipCode: "62931"},
	{latitude: 37.892867, longitude: -89.213656, zipCode: "62932"},
	{latitude: 37.772369, longitude: -89.01965, zipCode: "62933"},
	{latitude: 37.707239, longitude: -88.358633, zipCode: "62934"},
	{latitude: 37.844774, longitude: -88.638638, zipCode: "62935"},
	{latitude: 37.384128, longitude: -88.545966, zipCode: "62938"},
	{latitude: 37.546199, longitude: -88.976711, zipCode: "62939"},
	{latitude: 37.731566, longitude: -89.460578, zipCode: "62940"},
	{latitude: 37.244528, longitude: -88.970223, zipCode: "62941"},
	{latitude: 37.635784, longitude: -89.473569, zipCode: "62942"},
	{latitude: 37.356264, longitude: -88.746097, zipCode: "62943"},
	{latitude: 37.700824, longitude: -88.520323, zipCode: "62946"},
	{latitude: 37.528691, longitude: -88.453991, zipCode: "62947"},
	{latitude: 37.819917, longitude: -89.02596, zipCode: "62948"},
	{latitude: 37.827923, longitude: -89.144607, zipCode: "62949"},
	{latitude: 37.746447, longitude: -89.560711, zipCode: "62950"},
	{latitude: 37.825691, longitude: -88.927343, zipCode: "62951"},
	{latitude: 37.417455, longitude: -89.34345, zipCode: "62952"},
	{latitude: 37.209221, longitude: -88.847034, zipCode: "62953"},
	{latitude: 37.696253, longitude: -88.273377, zipCode: "62954"},
	{latitude: 37.279978, longitude: -88.931355, zipCode: "62956"},
	{latitude: 37.312496, longitude: -89.426131, zipCode: "62957"},
	{latitude: 37.610662, longitude: -89.142212, zipCode: "62958"},
	{latitude: 37.711169, longitude: -88.914627, zipCode: "62959"},
	{latitude: 37.233516, longitude: -88.724007, zipCode: "62960"},
	{latitude: 37.340904, longitude: -89.253148, zipCode: "62961"},
	{latitude: 37.07536, longitude: -89.335889, zipCode: "62962"},
	{latitude: 37.094559, longitude: -89.169921, zipCode: "62963"},
	{latitude: 37.130321, longitude: -89.226576, zipCode: "62964"},
	{latitude: 37.767341, longitude: -88.518311, zipCode: "62965"},
	{latitude: 37.771161, longitude: -89.353317, zipCode: "62966"},
	{latitude: 37.584276, longitude: -88.735808, zipCode: "62967"},
	{latitude: 37.139667, longitude: -89.333679, zipCode: "62969"},
	{latitude: 37.221461, longitude: -89.108584, zipCode: "62970"},
	{latitude: 37.540668, longitude: -88.816118, zipCode: "62972"},
	{latitude: 37.779479, longitude: -88.796733, zipCode: "62974"},
	{latitude: 37.640592, longitude: -89.371224, zipCode: "62975"},
	{latitude: 37.21984, longitude: -89.2127, zipCode: "62976"},
	{latitude: 37.853186, longitude: -88.541876, zipCode: "62977"},
	{latitude: 37.811478, longitude: -88.233629, zipCode: "62979"},
	{latitude: 37.424825, longitude: -88.365319, zipCode: "62982"},
	{latitude: 37.89931, longitude: -89.104813, zipCode: "62983"},
	{latitude: 37.754002, longitude: -88.146249, zipCode: "62984"},
	{latitude: 37.467521, longitude: -88.685895, zipCode: "62985"},
	{latitude: 37.618725, longitude: -88.660351, zipCode: "62987"},
	{latitude: 37.226148, longitude: -89.294708, zipCode: "62988"},
	{latitude: 37.228582, longitude: -89.398842, zipCode: "62990"},
	{latitude: 37.276105, longitude: -89.141909, zipCode: "62992"},
	{latitude: 37.906286, longitude: -89.347378, zipCode: "62994"},
	{latitude: 37.419276, longitude: -88.879938, zipCode: "62995"},
	{latitude: 37.17514, longitude: -89.155786, zipCode: "62996"},
	{latitude: 37.983572, longitude: -89.590535, zipCode: "62997"},
	{latitude: 37.512071, longitude: -89.458147, zipCode: "62998"},
	{latitude: 37.869529, longitude: -89.056407, zipCode: "62999"},
	{latitude: 38.644214, longitude: -90.651407, zipCode: "63005"},
	{latitude: 38.430371, longitude: -90.3921, zipCode: "63010"},
	{latitude: 38.605173, longitude: -90.557679, zipCode: "63011"},
	{latitude: 38.347377, longitude: -90.453537, zipCode: "63012"},
	{latitude: 38.405246, longitude: -91.152562, zipCode: "63013"},
	{latitude: 38.651805, longitude: -91.313445, zipCode: "63014"},
	{latitude: 38.39591, longitude: -90.751976, zipCode: "63015"},
	{latitude: 38.35655, longitude: -90.650978, zipCode: "63016"},
	{latitude: 38.656285, longitude: -90.541867, zipCode: "63017"},
	{latitude: 38.231258, longitude: -90.37501, zipCode: "63019"},
	{latitude: 38.10715, longitude: -90.571596, zipCode: "63020"},
	{latitude: 38.56984, longitude: -90.545689, zipCode: "63021"},
	{latitude: 38.26863, longitude: -90.698824, zipCode: "63023"},
	{latitude: 38.490877, longitude: -90.615713, zipCode: "63025"},
	{latitude: 38.50272, longitude: -90.458936, zipCode: "63026"},
	{latitude: 38.142859, longitude: -90.39212, zipCode: "63028"},
	{latitude: 38.143218, longitude: -90.752184, zipCode: "63030"},
	{latitude: 38.812046, longitude: -90.35362, zipCode: "63031"},
	{latitude: 38.798443, longitude: -90.274206, zipCode: "63033"},
	{latitude: 38.845391, longitude: -90.288495, zipCode: "63034"},
	{latitude: 37.976284, longitude: -90.372096, zipCode: "63036"},
	{latitude: 38.422437, longitude: -91.319529, zipCode: "63037"},
	{latitude: 38.577073, longitude: -90.667807, zipCode: "63038"},
	{latitude: 38.491673, longitude: -90.843735, zipCode: "63039"},
	{latitude: 38.573668, longitude: -90.634357, zipCode: "63040"},
	{latitude: 38.285959, longitude: -90.773624, zipCode: "63041"},
	{latitude: 38.781326, longitude: -90.37908, zipCode: "63042"},
	{latitude: 38.729228, longitude: -90.45499, zipCode: "63043"},
	{latitude: 38.772903, longitude: -90.429784, zipCode: "63044"},
	{latitude: 38.769628, longitude: -90.464026, zipCode: "63045"},
	{latitude: 38.195501, longitude: -90.487417, zipCode: "63047"},
	{latitude: 38.260366, longitude: -90.392425, zipCode: "63048"},
	{latitude: 38.477962, longitude: -90.528642, zipCode: "63049"},
	{latitude: 38.258066, longitude: -90.57979, zipCode: "63050"},
	{latitude: 38.377777, longitude: -90.576635, zipCode: "63051"},
	{latitude: 38.393026, longitude: -90.432576, zipCode: "63052"},
	{latitude: 38.366493, longitude: -90.363972, zipCode: "63053"},
	{latitude: 38.529619, longitude: -90.835038, zipCode: "63055"},
	{latitude: 38.399617, longitude: -91.215, zipCode: "63056"},
	{latitude: 38.260585, longitude: -90.875984, zipCode: "63060"},
	{latitude: 38.273403, longitude: -90.810499, zipCode: "63061"},
	{latitude: 38.565884, longitude: -91.244062, zipCode: "63068"},
	{latitude: 38.463537, longitude: -90.709983, zipCode: "63069"},
	{latitude: 38.287631, longitude: -90.421202, zipCode: "63070"},
	{latitude: 38.150005, longitude: -90.82112, zipCode: "63071"},
	{latitude: 38.369499, longitude: -90.817766, zipCode: "63072"},
	{latitude: 38.584692, longitude: -90.769496, zipCode: "63073"},
	{latitude: 38.726366, longitude: -90.38892, zipCode: "63074"},
	{latitude: 38.329039, longitude: -90.984466, zipCode: "63077"},
	{latitude: 38.266315, longitude: -91.100066, zipCode: "63079"},
	{latitude: 38.214096, longitude: -91.078109, zipCode: "63080"},
	{latitude: 38.4219, longitude: -91.019289, zipCode: "63084"},
	{latitude: 38.005068, longitude: -90.463566, zipCode: "63087"},
	{latitude: 38.548627, longitude: -90.501705, zipCode: "63088"},
	{latitude: 38.460533, longitude: -90.878903, zipCode: "63089"},
	{latitude: 38.535474, longitude: -91.048733, zipCode: "63090"},
	{latitude: 38.362896, longitude: -91.387251, zipCode: "63091"},
	{latitude: 38.631397, longitude: -90.192753, zipCode: "63101"},
	{latitude: 38.634489, longitude: -90.186258, zipCode: "63102"},
	{latitude: 38.629824, longitude: -90.217052, zipCode: "63103"},
	{latitude: 38.611128, longitude: -90.214087, zipCode: "63104"},
	{latitude: 38.644292, longitude: -90.32807, zipCode: "63105"},
	{latitude: 38.644424, longitude: -90.207972, zipCode: "63106"},
	{latitude: 38.663685, longitude: -90.211992, zipCode: "63107"},
	{latitude: 38.64485, longitude: -90.253405, zipCode: "63108"},
	{latitude: 38.584601, longitude: -90.294432, zipCode: "63109"},
	{latitude: 38.625369, longitude: -90.266467, zipCode: "63110"},
	{latitude: 38.558084, longitude: -90.250008, zipCode: "63111"},
	{latitude: 38.659923, longitude: -90.283324, zipCode: "63112"},
	{latitude: 38.65802, longitude: -90.2476, zipCode: "63113"},
	{latitude: 38.702256, longitude: -90.363674, zipCode: "63114"},
	{latitude: 38.681855, longitude: -90.24017, zipCode: "63115"},
	{latitude: 38.579976, longitude: -90.265142, zipCode: "63116"},
	{latitude: 38.630811, longitude: -90.330406, zipCode: "63117"},
	{latitude: 38.592292, longitude: -90.225035, zipCode: "63118"},
	{latitude: 38.590054, longitude: -90.34987, zipCode: "63119"},
	{latitude: 38.690583, longitude: -90.262119, zipCode: "63120"},
	{latitude: 38.708063, longitude: -90.300626, zipCode: "63121"},
	{latitude: 38.579356, longitude: -90.420881, zipCode: "63122"},
	{latitude: 38.547432, longitude: -90.328109, zipCode: "63123"},
	{latitude: 38.636558, longitude: -90.374651, zipCode: "63124"},
	{latitude: 38.519437, longitude: -90.292738, zipCode: "63125"},
	{latitude: 38.549585, longitude: -90.378685, zipCode: "63126"},
	{latitude: 38.534493, longitude: -90.417387, zipCode: "63127"},
	{latitude: 38.491966, longitude: -90.386567, zipCode: "63128"},
	{latitude: 38.457809, longitude: -90.31914, zipCode: "63129"},
	{latitude: 38.664917, longitude: -90.324897, zipCode: "63130"},
	{latitude: 38.616725, longitude: -90.439561, zipCode: "63131"},
	{latitude: 38.676067, longitude: -90.377441, zipCode: "63132"},
	{latitude: 38.682271, longitude: -90.306694, zipCode: "63133"},
	{latitude: 38.740211, longitude: -90.345825, zipCode: "63134"},
	{latitude: 38.751048, longitude: -90.299537, zipCode: "63135"},
	{latitude: 38.744761, longitude: -90.259924, zipCode: "63136"},
	{latitude: 38.749684, longitude: -90.212956, zipCode: "63137"},
	{latitude: 38.801066, longitude: -90.192381, zipCode: "63138"},
	{latitude: 38.610351, longitude: -90.291845, zipCode: "63139"},
	{latitude: 38.739364, longitude: -90.324019, zipCode: "63140"},
	{latitude: 38.657953, longitude: -90.455394, zipCode: "63141"},
	{latitude: 38.611729, longitude: -90.321432, zipCode: "63143"},
	{latitude: 38.619444, longitude: -90.348189, zipCode: "63144"},
	{latitude: 38.700996, longitude: -90.480257, zipCode: "63146"},
	{latitude: 38.693802, longitude: -90.215331, zipCode: "63147"},
	{latitude: 38.627871, longitude: -90.205457, zipCode: "63155"},
	{latitude: 38.858035, longitude: -90.463316, zipCode: "63301"},
	{latitude: 38.739794, longitude: -90.543692, zipCode: "63303"},
	{latitude: 38.706113, longitude: -90.68602, zipCode: "63304"},
	{latitude: 39.259958, longitude: -90.790393, zipCode: "63330"},
	{latitude: 38.596875, longitude: -90.894031, zipCode: "63332"},
	{latitude: 39.03528, longitude: -91.320376, zipCode: "63333"},
	{latitude: 39.308441, longitude: -91.191569, zipCode: "63334"},
	{latitude: 39.331804, longitude: -90.934034, zipCode: "63336"},
	{latitude: 39.330048, longitude: -91.354785, zipCode: "63339"},
	{latitude: 38.660633, longitude: -90.806786, zipCode: "63341"},
	{latitude: 39.154813, longitude: -90.828184, zipCode: "63343"},
	{latitude: 39.22941, longitude: -91.009113, zipCode: "63344"},
	{latitude: 39.276876, longitude: -91.57269, zipCode: "63345"},
	{latitude: 39.065452, longitude: -90.769248, zipCode: "63347"},
	{latitude: 38.785381, longitude: -90.945242, zipCode: "63348"},
	{latitude: 38.983714, longitude: -91.150553, zipCode: "63349"},
	{latitude: 38.904226, longitude: -91.351355, zipCode: "63350"},
	{latitude: 38.858217, longitude: -91.31052, zipCode: "63351"},
	{latitude: 39.247787, longitude: -91.658947, zipCode: "63352"},
	{latitude: 39.454043, longitude: -91.113045, zipCode: "63353"},
	{latitude: 38.647778, longitude: -91.103758, zipCode: "63357"},
	{latitude: 39.159605, longitude: -91.343919, zipCode: "63359"},
	{latitude: 38.955583, longitude: -91.560141, zipCode: "63361"},
	{latitude: 38.93583, longitude: -90.895533, zipCode: "63362"},
	{latitude: 38.885437, longitude: -91.464668, zipCode: "63363"},
	{latitude: 38.709576, longitude: -90.873384, zipCode: "63365"},
	{latitude: 38.857457, longitude: -90.725305, zipCode: "63366"},
	{latitude: 38.774189, longitude: -90.795053, zipCode: "63367"},
	{latitude: 38.751545, longitude: -90.728422, zipCode: "63368"},
	{latitude: 38.938392, longitude: -90.776429, zipCode: "63369"},
	{latitude: 38.929549, longitude: -90.38534, zipCode: "63373"},
	{latitude: 38.800744, longitude: -90.61215, zipCode: "63376"},
	{latitude: 39.107191, longitude: -91.096214, zipCode: "63377"},
	{latitude: 39.001027, longitude: -90.995571, zipCode: "63379"},
	{latitude: 38.813115, longitude: -91.12213, zipCode: "63380"},
	{latitude: 38.986402, longitude: -91.253305, zipCode: "63381"},
	{latitude: 39.311299, longitude: -91.490493, zipCode: "63382"},
	{latitude: 38.804365, longitude: -91.193705, zipCode: "63383"},
	{latitude: 39.092528, longitude: -91.553592, zipCode: "63384"},
	{latitude: 38.796985, longitude: -90.857245, zipCode: "63385"},
	{latitude: 38.870205, longitude: -90.219539, zipCode: "63386"},
	{latitude: 39.183555, longitude: -91.016734, zipCode: "63387"},
	{latitude: 38.875118, longitude: -91.757125, zipCode: "63388"},
	{latitude: 39.000511, longitude: -90.772845, zipCode: "63389"},
	{latitude: 38.803397, longitude: -91.040068, zipCode: "63390"},
	{latitude: 39.689938, longitude: -91.466239, zipCode: "63401"},
	{latitude: 40.388088, longitude: -91.529125, zipCode: "63430"},
	{latitude: 39.747308, longitude: -92.312248, zipCode: "63431"},
	{latitude: 40.497239, longitude: -91.993928, zipCode: "63432"},
	{latitude: 39.511304, longitude: -91.116943, zipCode: "63433"},
	{latitude: 39.909786, longitude: -91.947237, zipCode: "63434"},
	{latitude: 40.199137, longitude: -91.58368, zipCode: "63435"},
	{latitude: 39.500689, longitude: -91.539951, zipCode: "63436"},
	{latitude: 39.723901, longitude: -92.236527, zipCode: "63437"},
	{latitude: 39.968514, longitude: -91.687967, zipCode: "63438"},
	{latitude: 39.803818, longitude: -91.859595, zipCode: "63439"},
	{latitude: 39.96857, longitude: -91.741425, zipCode: "63440"},
	{latitude: 39.482617, longitude: -91.321165, zipCode: "63441"},
	{latitude: 39.66058, longitude: -91.869458, zipCode: "63443"},
	{latitude: 40.395028, longitude: -91.723151, zipCode: "63445"},
	{latitude: 40.140034, longitude: -92.002285, zipCode: "63446"},
	{latitude: 40.094404, longitude: -91.908896, zipCode: "63447"},
	{latitude: 40.015964, longitude: -91.542113, zipCode: "63448"},
	{latitude: 39.668909, longitude: -92.15026, zipCode: "63450"},
	{latitude: 39.917465, longitude: -92.206036, zipCode: "63451"},
	{latitude: 40.092903, longitude: -91.780556, zipCode: "63452"},
	{latitude: 40.49419, longitude: -91.864421, zipCode: "63453"},
	{latitude: 39.922981, longitude: -91.650377, zipCode: "63454"},
	{latitude: 39.65385, longitude: -91.726569, zipCode: "63456"},
	{latitude: 40.16585, longitude: -91.711889, zipCode: "63457"},
	{latitude: 39.992451, longitude: -92.019907, zipCode: "63458"},
	{latitude: 39.584494, longitude: -91.382316, zipCode: "63459"},
	{latitude: 40.005142, longitude: -92.209328, zipCode: "63460"},
	{latitude: 39.80218, longitude: -91.555549, zipCode: "63461"},
	{latitude: 39.419207, longitude: -91.70113, zipCode: "63462"},
	{latitude: 39.851458, longitude: -91.781062, zipCode: "63463"},
	{latitude: 40.527638, longitude: -91.679549, zipCode: "63465"},
	{latitude: 40.2662, longitude: -91.618556, zipCode: "63466"},
	{latitude: 39.648489, longitude: -91.267284, zipCode: "63467"},
	{latitude: 39.67971, longitude: -92.018554, zipCode: "63468"},
	{latitude: 39.825851, longitude: -92.041218, zipCode: "63469"},
	{latitude: 39.926333, longitude: -91.496752, zipCode: "63471"},
	{latitude: 40.405293, longitude: -91.577311, zipCode: "63472"},
	{latitude: 40.251733, longitude: -91.76878, zipCode: "63473"},
	{latitude: 40.349765, longitude: -91.893573, zipCode: "63474"},
	{latitude: 40.166289, longitude: -92.59121, zipCode: "63501"},
	{latitude: 39.906219, longitude: -92.443723, zipCode: "63530"},
	{latitude: 40.300263, longitude: -92.241036, zipCode: "63531"},
	{latitude: 39.766564, longitude: -92.571886, zipCode: "63532"},
	{latitude: 40.199773, longitude: -92.392226, zipCode: "63533"},
	{latitude: 39.737309, longitude: -92.648407, zipCode: "63534"},
	{latitude: 40.580678, longitude: -92.674502, zipCode: "63535"},
	{latitude: 40.485291, longitude: -92.355927, zipCode: "63536"},
	{latitude: 40.144472, longitude: -92.157091, zipCode: "63537"},
	{latitude: 39.953937, longitude: -92.679871, zipCode: "63538"},
	{latitude: 39.925596, longitude: -92.768903, zipCode: "63539"},
	{latitude: 40.098346, longitude: -92.397896, zipCode: "63540"},
	{latitude: 40.50417, longitude: -92.627042, zipCode: "63541"},
	{latitude: 40.35242, longitude: -92.014125, zipCode: "63543"},
	{latitude: 40.243351, longitude: -92.850174, zipCode: "63544"},
	{latitude: 40.26475, longitude: -92.967999, zipCode: "63545"},
	{latitude: 40.337948, longitude: -92.502527, zipCode: "63546"},
	{latitude: 40.135832, longitude: -92.297714, zipCode: "63547"},
	{latitude: 40.536364, longitude: -92.489163, zipCode: "63548"},
	{latitude: 40.017249, longitude: -92.490999, zipCode: "63549"},
	{latitude: 40.499174, longitude: -92.742526, zipCode: "63551"},
	{latitude: 39.753192, longitude: -92.450313, zipCode: "63552"},
	{latitude: 40.486404, longitude: -92.193179, zipCode: "63555"},
	{latitude: 40.176075, longitude: -93.11234, zipCode: "63556"},
	{latitude: 39.982807, longitude: -92.878405, zipCode: "63557"},
	{latitude: 39.748773, longitude: -92.751379, zipCode: "63558"},
	{latitude: 40.242723, longitude: -92.76685, zipCode: "63559"},
	{latitude: 40.367197, longitude: -93.115387, zipCode: "63560"},
	{latitude: 40.410574, longitude: -92.534729, zipCode: "63561"},
	{latitude: 40.304993, longitude: -92.072933, zipCode: "63563"},
	{latitude: 40.48062, longitude: -92.947276, zipCode: "63565"},
	{latitude: 40.029563, longitude: -92.952336, zipCode: "63566"},
	{latitude: 40.413304, longitude: -92.704585, zipCode: "63567"},
	{latitude: 37.821084, longitude: -90.543883, zipCode: "63601"},
	{latitude: 37.38173, longitude: -90.651947, zipCode: "63620"},
	{latitude: 37.474997, longitude: -90.565976, zipCode: "63621"},
	{latitude: 37.790992, longitude: -90.907431, zipCode: "63622"},
	{latitude: 37.679325, longitude: -90.906361, zipCode: "63623"},
	{latitude: 37.755635, longitude: -90.633945, zipCode: "63624"},
	{latitude: 37.563413, longitude: -90.997619, zipCode: "63625"},
	{latitude: 38.072732, longitude: -90.709843, zipCode: "63626"},
	{latitude: 38.038291, longitude: -90.252161, zipCode: "63627"},
	{latitude: 37.93458, longitude: -90.534178, zipCode: "63628"},
	{latitude: 37.38061, longitude: -91.239387, zipCode: "63629"},
	{latitude: 38.027933, longitude: -90.719351, zipCode: "63630"},
	{latitude: 37.754571, longitude: -90.788037, zipCode: "63631"},
	{latitude: 37.442584, longitude: -91.020136, zipCode: "63633"},
	{latitude: 37.293641, longitude: -90.575534, zipCode: "63636"},
	{latitude: 37.712286, longitude: -90.518346, zipCode: "63637"},
	{latitude: 37.214858, longitude: -91.019115, zipCode: "63638"},
	{latitude: 37.755067, longitude: -90.369022, zipCode: "63640"},
	{latitude: 37.543368, longitude: -90.325994, zipCode: "63645"},
	{latitude: 37.815283, longitude: -90.701003, zipCode: "63648"},
	{latitude: 37.61105, longitude: -90.630518, zipCode: "63650"},
	{latitude: 37.861318, longitude: -90.587878, zipCode: "63653"},
	{latitude: 37.487151, longitude: -90.857524, zipCode: "63654"},
	{latitude: 37.388946, longitude: -90.187025, zipCode: "63655"},
	{latitude: 37.596555, longitude: -90.792841, zipCode: "63656"},
	{latitude: 37.915773, longitude: -90.706399, zipCode: "63660"},
	{latitude: 37.538433, longitude: -90.05031, zipCode: "63662"},
	{latitude: 37.625409, longitude: -90.647867, zipCode: "63663"},
	{latitude: 37.917196, longitude: -90.9142, zipCode: "63664"},
	{latitude: 37.29295, longitude: -90.843941, zipCode: "63665"},
	{latitude: 37.904016, longitude: -90.164527, zipCode: "63670"},
	{latitude: 37.841831, longitude: -89.991058, zipCode: "63673"},
	{latitude: 38.036719, longitude: -90.659176, zipCode: "63674"},
	{latitude: 37.314474, longitude: -90.694768, zipCode: "63675"},
	{latitude: 37.332339, longitude: -89.585745, zipCode: "63701"},
	{latitude: 37.274934, longitude: -89.540005, zipCode: "63703"},
	{latitude: 37.092999, longitude: -89.92192, zipCode: "63730"},
	{latitude: 37.594135, longitude: -89.57593, zipCode: "63732"},
	{latitude: 36.979123, longitude: -89.785206, zipCode: "63735"},
	{latitude: 37.083813, longitude: -89.519853, zipCode: "63736"},
	{latitude: 37.08984, longitude: -89.95723, zipCode: "63738"},
	{latitude: 37.35855, longitude: -89.800259, zipCode: "63739"},
	{latitude: 37.175369, longitude: -89.680721, zipCode: "63740"},
	{latitude: 37.160012, longitude: -89.446952, zipCode: "63742"},
	{latitude: 37.519108, longitude: -89.823448, zipCode: "63743"},
	{latitude: 37.193333, longitude: -89.718347, zipCode: "63744"},
	{latitude: 37.255887, longitude: -89.659456, zipCode: "63745"},
	{latitude: 37.707841, longitude: -89.694828, zipCode: "63746"},
	{latitude: 37.555807, longitude: -89.817465, zipCode: "63747"},
	{latitude: 37.662219, longitude: -89.610066, zipCode: "63748"},
	{latitude: 37.150162, longitude: -90.163028, zipCode: "63750"},
	{latitude: 37.275815, longitude: -90.128182, zipCode: "63751"},
	{latitude: 37.439458, longitude: -89.6408, zipCode: "63755"},
	{latitude: 37.192135, longitude: -89.550526, zipCode: "63758"},
	{latitude: 37.257417, longitude: -89.899164, zipCode: "63760"},
	{latitude: 37.064063, longitude: -90.173309, zipCode: "63763"},
	{latitude: 37.32863, longitude: -89.969568, zipCode: "63764"},
	{latitude: 37.44193, longitude: -89.85009, zipCode: "63766"},
	{latitude: 37.043581, longitude: -89.610378, zipCode: "63767"},
	{latitude: 37.527609, longitude: -89.752643, zipCode: "63769"},
	{latitude: 37.594553, longitude: -89.700436, zipCode: "63770"},
	{latitude: 37.075599, longitude: -89.71477, zipCode: "63771"},
	{latitude: 37.09425, longitude: -89.774723, zipCode: "63774"},
	{latitude: 37.711303, longitude: -89.877458, zipCode: "63775"},
	{latitude: 37.176379, longitude: -89.495855, zipCode: "63780"},
	{latitude: 37.527511, longitude: -89.926219, zipCode: "63781"},
	{latitude: 37.122136, longitude: -90.035305, zipCode: "63782"},
	{latitude: 37.609495, longitude: -89.682287, zipCode: "63783"},
	{latitude: 36.994979, longitude: -89.696024, zipCode: "63784"},
	{latitude: 37.266837, longitude: -89.822215, zipCode: "63785"},
	{latitude: 37.121154, longitude: -90.132881, zipCode: "63787"},
	{latitude: 36.904917, longitude: -89.618496, zipCode: "63801"},
	{latitude: 36.824291, longitude: -89.325641, zipCode: "63820"},
	{latitude: 36.049343, longitude: -90.238906, zipCode: "63821"},
	{latitude: 36.660487, longitude: -89.99888, zipCode: "63822"},
	{latitude: 36.885642, longitude: -89.445022, zipCode: "63823"},
	{latitude: 37.004943, longitude: -89.526454, zipCode: "63824"},
	{latitude: 36.93141, longitude: -89.945758, zipCode: "63825"},
	{latitude: 36.187094, longitude: -89.848981, zipCode: "63826"},
	{latitude: 36.261189, longitude: -89.895723, zipCode: "63827"},
	{latitude: 36.750369, longitude: -89.691841, zipCode: "63828"},
	{latitude: 36.03551, longitude: -90.308867, zipCode: "63829"},
	{latitude: 36.13535, longitude: -89.690139, zipCode: "63830"},
	{latitude: 36.650344, longitude: -89.730765, zipCode: "63833"},
	{latitude: 36.914293, longitude: -89.274233, zipCode: "63834"},
	{latitude: 36.45029, longitude: -89.986451, zipCode: "63837"},
	{latitude: 36.047538, longitude: -89.809631, zipCode: "63839"},
	{latitude: 36.191637, longitude: -89.884209, zipCode: "63840"},
	{latitude: 36.771316, longitude: -89.984451, zipCode: "63841"},
	{latitude: 36.711758, longitude: -89.314713, zipCode: "63845"},
	{latitude: 36.826259, longitude: -89.799347, zipCode: "63846"},
	{latitude: 36.442711, longitude: -90.034724, zipCode: "63847"},
	{latitude: 36.437047, longitude: -89.888407, zipCode: "63848"},
	{latitude: 36.13968, longitude: -89.991152, zipCode: "63849"},
	{latitude: 36.822362, longitude: -89.782141, zipCode: "63850"},
	{latitude: 36.259153, longitude: -89.717229, zipCode: "63851"},
	{latitude: 36.367334, longitude: -90.015154, zipCode: "63852"},
	{latitude: 36.058277, longitude: -89.867412, zipCode: "63853"},
	{latitude: 36.069107, longitude: -90.056809, zipCode: "63855"},
	{latitude: 36.224266, longitude: -90.04576, zipCode: "63857"},
	{latitude: 36.678151, longitude: -89.592139, zipCode: "63860"},
	{latitude: 36.590133, longitude: -89.67786, zipCode: "63862"},
	{latitude: 36.563034, longitude: -89.97695, zipCode: "63863"},
	{latitude: 36.528537, longitude: -89.605643, zipCode: "63866"},
	{latitude: 36.750147, longitude: -89.551598, zipCode: "63867"},
	{latitude: 36.845769, longitude: -89.689692, zipCode: "63868"},
	{latitude: 36.607532, longitude: -89.495216, zipCode: "63869"},
	{latitude: 36.621298, longitude: -89.844043, zipCode: "63870"},
	{latitude: 36.430734, longitude: -89.661517, zipCode: "63873"},
	{latitude: 36.561686, longitude: -89.813713, zipCode: "63874"},
	{latitude: 36.132299, longitude: -90.164943, zipCode: "63876"},
	{latitude: 36.079496, longitude: -89.849151, zipCode: "63877"},
	{latitude: 36.503021, longitude: -89.822358, zipCode: "63878"},
	{latitude: 36.359516, longitude: -89.812721, zipCode: "63879"},
	{latitude: 36.329979, longitude: -90.025872, zipCode: "63880"},
	{latitude: 36.91639, longitude: -89.219749, zipCode: "63882"},
	{latitude: 36.771042, longitude: -90.451548, zipCode: "63901"},
	{latitude: 36.687148, longitude: -90.244962, zipCode: "63932"},
	{latitude: 36.519181, longitude: -90.111928, zipCode: "63933"},
	{latitude: 37.198552, longitude: -90.368661, zipCode: "63934"},
	{latitude: 36.675213, longitude: -90.92147, zipCode: "63935"},
	{latitude: 36.808925, longitude: -90.129028, zipCode: "63936"},
	{latitude: 36.953817, longitude: -90.753404, zipCode: "63937"},
	{latitude: 36.68408, longitude: -90.689336, zipCode: "63939"},
	{latitude: 36.768003, longitude: -90.227674, zipCode: "63940"},
	{latitude: 36.880602, longitude: -91.177143, zipCode: "63941"},
	{latitude: 36.554015, longitude: -91.074164, zipCode: "63942"},
	{latitude: 36.834861, longitude: -90.801209, zipCode: "63943"},
	{latitude: 37.113156, longitude: -90.359498, zipCode: "63944"},
	{latitude: 36.638612, longitude: -90.54944, zipCode: "63945"},
	{latitude: 37.262925, longitude: -90.414877, zipCode: "63950"},
	{latitude: 37.114896, longitude: -90.257851, zipCode: "63951"},
	{latitude: 37.009877, longitude: -90.64274, zipCode: "63952"},
	{latitude: 36.587245, longitude: -90.637706, zipCode: "63953"},
	{latitude: 36.567418, longitude: -90.470263, zipCode: "63954"},
	{latitude: 36.602079, longitude: -90.678656, zipCode: "63955"},
	{latitude: 37.213951, longitude: -90.521728, zipCode: "63956"},
	{latitude: 37.141905, longitude: -90.69231, zipCode: "63957"},
	{latitude: 36.95694, longitude: -90.131465, zipCode: "63960"},
	{latitude: 36.567208, longitude: -90.277076, zipCode: "63961"},
	{latitude: 36.844028, longitude: -90.281507, zipCode: "63962"},
	{latitude: 37.239691, longitude: -90.40274, zipCode: "63964"},
	{latitude: 36.965172, longitude: -91.0213, zipCode: "63965"},
	{latitude: 36.970682, longitude: -90.261525, zipCode: "63966"},
	{latitude: 36.940696, longitude: -90.485325, zipCode: "63967"},
	{latitude: 39.101185, longitude: -93.536551, zipCode: "64001"},
	{latitude: 38.959756, longitude: -94.067926, zipCode: "64011"},
	{latitude: 38.788391, longitude: -94.551918, zipCode: "64012"},
	{latitude: 39.00941, longitude: -94.256518, zipCode: "64014"},
	{latitude: 39.020721, longitude: -94.301688, zipCode: "64015"},
	{latitude: 39.112922, longitude: -94.214765, zipCode: "64016"},
	{latitude: 39.199706, longitude: -94.032505, zipCode: "64017"},
	{latitude: 39.454719, longitude: -94.721261, zipCode: "64018"},
	{latitude: 38.777542, longitude: -93.865281, zipCode: "64019"},
	{latitude: 38.967425, longitude: -93.599784, zipCode: "64020"},
	{latitude: 39.112583, longitude: -93.625724, zipCode: "64021"},
	{latitude: 39.201655, longitude: -93.672247, zipCode: "64022"},
	{latitude: 39.327214, longitude: -94.227268, zipCode: "64024"},
	{latitude: 39.285202, longitude: -94.832349, zipCode: "64028"},
	{latitude: 39.005904, longitude: -94.216393, zipCode: "64029"},
	{latitude: 38.881359, longitude: -94.522744, zipCode: "64030"},
	{latitude: 38.858572, longitude: -94.297554, zipCode: "64034"},
	{latitude: 39.290513, longitude: -93.812219, zipCode: "64035"},
	{latitude: 39.200558, longitude: -93.935126, zipCode: "64036"},
	{latitude: 39.04663, longitude: -93.733848, zipCode: "64037"},
	{latitude: 38.719336, longitude: -93.987754, zipCode: "64040"},
	{latitude: 39.437785, longitude: -94.363578, zipCode: "64048"},
	{latitude: 39.115787, longitude: -94.410569, zipCode: "64050"},
	{latitude: 39.073616, longitude: -94.450866, zipCode: "64052"},
	{latitude: 39.107561, longitude: -94.464692, zipCode: "64053"},
	{latitude: 39.117502, longitude: -94.440932, zipCode: "64054"},
	{latitude: 39.050847, longitude: -94.397998, zipCode: "64055"},
	{latitude: 39.113129, longitude: -94.314919, zipCode: "64056"},
	{latitude: 39.071957, longitude: -94.319308, zipCode: "64057"},
	{latitude: 39.173856, longitude: -94.31356, zipCode: "64058"},
	{latitude: 39.370952, longitude: -94.368787, zipCode: "64060"},
	{latitude: 38.786719, longitude: -94.084421, zipCode: "64061"},
	{latitude: 39.456489, longitude: -94.177881, zipCode: "64062"},
	{latitude: 38.912225, longitude: -94.353417, zipCode: "64063"},
	{latitude: 38.98297, longitude: -94.343194, zipCode: "64064"},
	{latitude: 38.952177, longitude: -94.404397, zipCode: "64065"},
	{latitude: 39.139616, longitude: -94.138543, zipCode: "64066"},
	{latitude: 39.154284, longitude: -93.836041, zipCode: "64067"},
	{latitude: 39.258315, longitude: -94.389653, zipCode: "64068"},
	{latitude: 38.886611, longitude: -94.148894, zipCode: "64070"},
	{latitude: 39.027828, longitude: -93.831717, zipCode: "64071"},
	{latitude: 39.240552, longitude: -94.299635, zipCode: "64072"},
	{latitude: 39.083651, longitude: -94.071296, zipCode: "64074"},
	{latitude: 38.9996, longitude: -94.145262, zipCode: "64075"},
	{latitude: 38.983162, longitude: -93.948056, zipCode: "64076"},
	{latitude: 39.224642, longitude: -94.139091, zipCode: "64077"},
	{latitude: 38.708429, longitude: -94.471589, zipCode: "64078"},
	{latitude: 39.3638, longitude: -94.793302, zipCode: "64079"},
	{latitude: 38.764216, longitude: -94.260423, zipCode: "64080"},
	{latitude: 38.910627, longitude: -94.411428, zipCode: "64081"},
	{latitude: 38.862188, longitude: -94.408306, zipCode: "64082"},
	{latitude: 38.801995, longitude: -94.448846, zipCode: "64083"},
	{latitude: 39.385681, longitude: -94.069434, zipCode: "64084"},
	{latitude: 39.343321, longitude: -93.955431, zipCode: "64085"},
	{latitude: 38.899171, longitude: -94.272385, zipCode: "64086"},
	{latitude: 39.167487, longitude: -94.183165, zipCode: "64088"},
	{latitude: 39.389996, longitude: -94.564952, zipCode: "64089"},
	{latitude: 38.760043, longitude: -94.163889, zipCode: "64090"},
	{latitude: 39.217578, longitude: -94.811119, zipCode: "64092"},
	{latitude: 38.792552, longitude: -93.730877, zipCode: "64093"},
	{latitude: 39.196804, longitude: -93.557939, zipCode: "64096"},
	{latitude: 39.113702, longitude: -93.990407, zipCode: "64097"},
	{latitude: 39.468076, longitude: -94.891475, zipCode: "64098"},
	{latitude: 39.103468, longitude: -94.60061, zipCode: "64101"},
	{latitude: 39.094311, longitude: -94.603204, zipCode: "64102"},
	{latitude: 39.106182, longitude: -94.590621, zipCode: "64105"},
	{latitude: 39.105274, longitude: -94.570088, zipCode: "64106"},
	{latitude: 39.084895, longitude: -94.58647, zipCode: "64108"},
	{latitude: 39.06583, longitude: -94.567597, zipCode: "64109"},
	{latitude: 39.034455, longitude: -94.572637, zipCode: "64110"},
	{latitude: 39.05708, longitude: -94.593899, zipCode: "64111"},
	{latitude: 39.036004, longitude: -94.59525, zipCode: "64112"},
	{latitude: 39.01414, longitude: -94.595493, zipCode: "64113"},
	{latitude: 38.957802, longitude: -94.598599, zipCode: "64114"},
	{latitude: 39.148811, longitude: -94.575242, zipCode: "64116"},
	{latitude: 39.164449, longitude: -94.522657, zipCode: "64117"},
	{latitude: 39.212864, longitude: -94.573951, zipCode: "64118"},
	{latitude: 39.209967, longitude: -94.514665, zipCode: "64119"},
	{latitude: 39.135007, longitude: -94.514957, zipCode: "64120"},
	{latitude: 39.114403, longitude: -94.521806, zipCode: "64123"},
	{latitude: 39.107143, longitude: -94.539062, zipCode: "64124"},
	{latitude: 39.106463, longitude: -94.494153, zipCode: "64125"},
	{latitude: 39.091093, longitude: -94.495978, zipCode: "64126"},
	{latitude: 39.089069, longitude: -94.538388, zipCode: "64127"},
	{latitude: 39.065482, longitude: -94.534791, zipCode: "64128"},
	{latitude: 39.049005, longitude: -94.497409, zipCode: "64129"},
	{latitude: 39.034782, longitude: -94.542049, zipCode: "64130"},
	{latitude: 38.959809, longitude: -94.571951, zipCode: "64131"},
	{latitude: 38.979726, longitude: -94.541547, zipCode: "64132"},
	{latitude: 39.013884, longitude: -94.45772, zipCode: "64133"},
	{latitude: 38.930972, longitude: -94.487154, zipCode: "64134"},
	{latitude: 39.012793, longitude: -94.405733, zipCode: "64136"},
	{latitude: 38.931621, longitude: -94.542913, zipCode: "64137"},
	{latitude: 38.967254, longitude: -94.470948, zipCode: "64138"},
	{latitude: 38.967134, longitude: -94.40466, zipCode: "64139"},
	{latitude: 38.87803, longitude: -94.596188, zipCode: "64145"},
	{latitude: 38.882998, longitude: -94.571894, zipCode: "64146"},
	{latitude: 38.851782, longitude: -94.554647, zipCode: "64147"},
	{latitude: 38.865459, longitude: -94.471606, zipCode: "64149"},
	{latitude: 39.172175, longitude: -94.632499, zipCode: "64150"},
	{latitude: 39.215355, longitude: -94.630084, zipCode: "64151"},
	{latitude: 39.219065, longitude: -94.724719, zipCode: "64152"},
	{latitude: 39.274581, longitude: -94.726643, zipCode: "64153"},
	{latitude: 39.281279, longitude: -94.626948, zipCode: "64154"},
	{latitude: 39.281154, longitude: -94.567841, zipCode: "64155"},
	{latitude: 39.273669, longitude: -94.517765, zipCode: "64156"},
	{latitude: 39.276856, longitude: -94.470557, zipCode: "64157"},
	{latitude: 39.23438, longitude: -94.482916, zipCode: "64158"},
	{latitude: 39.158585, longitude: -94.450909, zipCode: "64161"},
	{latitude: 39.344487, longitude: -94.688231, zipCode: "64163"},
	{latitude: 39.329353, longitude: -94.627065, zipCode: "64164"},
	{latitude: 39.320814, longitude: -94.577513, zipCode: "64165"},
	{latitude: 39.32215, longitude: -94.523009, zipCode: "64166"},
	{latitude: 39.319092, longitude: -94.482038, zipCode: "64167"},
	{latitude: 39.633389, longitude: -94.715455, zipCode: "64401"},
	{latitude: 40.251742, longitude: -94.323106, zipCode: "64402"},
	{latitude: 40.486462, longitude: -94.289201, zipCode: "64420"},
	{latitude: 39.90836, longitude: -94.927604, zipCode: "64421"},
	{latitude: 39.894787, longitude: -94.493961, zipCode: "64422"},
	{latitude: 40.192871, longitude: -94.851656, zipCode: "64423"},
	{latitude: 40.264687, longitude: -94.032246, zipCode: "64424"},
	{latitude: 40.516654, longitude: -93.861005, zipCode: "64426"},
	{latitude: 40.108578, longitude: -94.846324, zipCode: "64427"},
	{latitude: 40.423083, longitude: -95.088263, zipCode: "64428"},
	{latitude: 39.736744, longitude: -94.225807, zipCode: "64429"},
	{latitude: 39.83899, longitude: -94.565539, zipCode: "64430"},
	{latitude: 40.528193, longitude: -94.996257, zipCode: "64431"},
	{latitude: 40.264012, longitude: -94.661169, zipCode: "64432"},
	{latitude: 40.238844, longitude: -94.680901, zipCode: "64433"},
	{latitude: 40.258404, longitude: -94.736154, zipCode: "64434"},
	{latitude: 39.851704, longitude: -94.691249, zipCode: "64436"},
	{latitude: 40.139951, longitude: -95.363369, zipCode: "64437"},
	{latitude: 40.158583, longitude: -94.413766, zipCode: "64438"},
	{latitude: 39.524407, longitude: -94.753446, zipCode: "64439"},
	{latitude: 39.585134, longitude: -94.910338, zipCode: "64440"},
	{latitude: 40.392917, longitude: -94.2713, zipCode: "64441"},
	{latitude: 40.497186, longitude: -94.005525, zipCode: "64442"},
	{latitude: 39.742886, longitude: -94.653163, zipCode: "64443"},
	{latitude: 39.485425, longitude: -94.648031, zipCode: "64444"},
	{latitude: 40.540243, longitude: -95.149108, zipCode: "64445"},
	{latitude: 40.323896, longitude: -95.399116, zipCode: "64446"},
	{latitude: 39.603918, longitude: -94.827258, zipCode: "64448"},
	{latitude: 40.0412, longitude: -94.978475, zipCode: "64449"},
	{latitude: 39.994021, longitude: -95.212592, zipCode: "64451"},
	{latitude: 40.344716, longitude: -94.469469, zipCode: "64453"},
	{latitude: 39.60785, longitude: -94.593794, zipCode: "64454"},
	{latitude: 40.180658, longitude: -95.005056, zipCode: "64455"},
	{latitude: 40.49313, longitude: -94.391085, zipCode: "64456"},
	{latitude: 40.162713, longitude: -94.682355, zipCode: "64457"},
	{latitude: 40.514955, longitude: -94.145198, zipCode: "64458"},
	{latitude: 39.928729, longitude: -94.645016, zipCode: "64459"},
	{latitude: 40.514354, longitude: -94.807278, zipCode: "64461"},
	{latitude: 40.062598, longitude: -94.482585, zipCode: "64463"},
	{latitude: 39.550388, longitude: -94.288483, zipCode: "64465"},
	{latitude: 40.16622, longitude: -95.106267, zipCode: "64466"},
	{latitude: 40.391688, longitude: -94.176662, zipCode: "64467"},
	{latitude: 40.342732, longitude: -94.881155, zipCode: "64468"},
	{latitude: 39.932801, longitude: -94.357064, zipCode: "64469"},
	{latitude: 40.173356, longitude: -95.226671, zipCode: "64470"},
	{latitude: 40.265744, longitude: -94.193607, zipCode: "64471"},
	{latitude: 39.979261, longitude: -95.076209, zipCode: "64473"},
	{latitude: 39.754427, longitude: -94.379661, zipCode: "64474"},
	{latitude: 40.436447, longitude: -94.629368, zipCode: "64475"},
	{latitude: 40.452961, longitude: -94.830608, zipCode: "64476"},
	{latitude: 39.575462, longitude: -94.452859, zipCode: "64477"},
	{latitude: 40.338127, longitude: -94.670899, zipCode: "64479"},
	{latitude: 40.054115, longitude: -94.705477, zipCode: "64480"},
	{latitude: 40.353947, longitude: -93.887733, zipCode: "64481"},
	{latitude: 40.447478, longitude: -95.556291, zipCode: "64482"},
	{latitude: 40.038869, longitude: -94.839127, zipCode: "64483"},
	{latitude: 39.5612, longitude: -95.017149, zipCode: "64484"},
	{latitude: 39.95417, longitude: -94.816319, zipCode: "64485"},
	{latitude: 40.537477, longitude: -94.613008, zipCode: "64486"},
	{latitude: 40.300935, longitude: -95.095911, zipCode: "64487"},
	{latitude: 40.234191, longitude: -94.548227, zipCode: "64489"},
	{latitude: 39.742047, longitude: -94.514843, zipCode: "64490"},
	{latitude: 40.446045, longitude: -95.358054, zipCode: "64491"},
	{latitude: 39.481857, longitude: -94.544417, zipCode: "64492"},
	{latitude: 39.630595, longitude: -94.304498, zipCode: "64493"},
	{latitude: 39.990658, longitude: -94.609645, zipCode: "64494"},
	{latitude: 40.49814, longitude: -95.622045, zipCode: "64496"},
	{latitude: 39.929538, longitude: -94.219874, zipCode: "64497"},
	{latitude: 40.540253, longitude: -95.312458, zipCode: "64498"},
	{latitude: 40.397091, longitude: -94.415275, zipCode: "64499"},
	{latitude: 39.766248, longitude: -94.844438, zipCode: "64501"},
	{latitude: 39.749277, longitude: -94.845718, zipCode: "64503"},
	{latitude: 39.691247, longitude: -94.905924, zipCode: "64504"},
	{latitude: 39.846744, longitude: -94.823879, zipCode: "64505"},
	{latitude: 39.789915, longitude: -94.803312, zipCode: "64506"},
	{latitude: 39.736854, longitude: -94.740379, zipCode: "64507"},
	{latitude: 39.805381, longitude: -93.579097, zipCode: "64601"},
	{latitude: 39.894531, longitude: -94.089951, zipCode: "64620"},
	{latitude: 39.49255, longitude: -93.542717, zipCode: "64622"},
	{latitude: 39.469802, longitude: -93.333256, zipCode: "64623"},
	{latitude: 39.575736, longitude: -93.782858, zipCode: "64624"},
	{latitude: 39.744043, longitude: -93.802883, zipCode: "64625"},
	{latitude: 39.793634, longitude: -93.047921, zipCode: "64628"},
	{latitude: 40.037666, longitude: -93.156885, zipCode: "64630"},
	{latitude: 39.837305, longitude: -92.88282, zipCode: "64631"},
	{latitude: 40.468986, longitude: -93.766788, zipCode: "64632"},
	{latitude: 39.359951, longitude: -93.48192, zipCode: "64633"},
	{latitude: 39.952801, longitude: -93.433857, zipCode: "64635"},
	{latitude: 40.128144, longitude: -93.982727, zipCode: "64636"},
	{latitude: 39.575717, longitude: -93.917412, zipCode: "64637"},
	{latitude: 39.617643, longitude: -93.620136, zipCode: "64638"},
	{latitude: 39.400435, longitude: -93.215667, zipCode: "64639"},
	{latitude: 39.898207, longitude: -93.935966, zipCode: "64640"},
	{latitude: 40.164488, longitude: -93.402039, zipCode: "64641"},
	{latitude: 40.170792, longitude: -93.836449, zipCode: "64642"},
	{latitude: 39.613045, longitude: -93.369158, zipCode: "64643"},
	{latitude: 39.739066, longitude: -93.970808, zipCode: "64644"},
	{latitude: 40.30007, longitude: -93.32232, zipCode: "64645"},
	{latitude: 40.098954, longitude: -93.30898, zipCode: "64646"},
	{latitude: 40.026443, longitude: -93.967299, zipCode: "64647"},
	{latitude: 39.989188, longitude: -93.79024, zipCode: "64648"},
	{latitude: 39.773347, longitude: -94.08639, zipCode: "64649"},
	{latitude: 39.637875, longitude: -94.053727, zipCode: "64650"},
	{latitude: 39.776866, longitude: -93.181934, zipCode: "64651"},
	{latitude: 40.021508, longitude: -93.418192, zipCode: "64652"},
	{latitude: 39.895133, longitude: -93.194089, zipCode: "64653"},
	{latitude: 39.848589, longitude: -93.775568, zipCode: "64654"},
	{latitude: 40.44653, longitude: -93.257493, zipCode: "64655"},
	{latitude: 39.67691, longitude: -93.692789, zipCode: "64656"},
	{latitude: 40.1323, longitude: -94.207594, zipCode: "64657"},
	{latitude: 39.662965, longitude: -92.917299, zipCode: "64658"},
	{latitude: 39.781505, longitude: -93.28978, zipCode: "64659"},
	{latitude: 39.576684, longitude: -93.097156, zipCode: "64660"},
	{latitude: 40.536056, longitude: -93.517477, zipCode: "64661"},
	{latitude: 39.749704, longitude: -93.651286, zipCode: "64664"},
	{latitude: 40.400409, longitude: -93.34476, zipCode: "64667"},
	{latitude: 39.355068, longitude: -93.704415, zipCode: "64668"},
	{latitude: 40.052618, longitude: -94.140261, zipCode: "64670"},
	{latitude: 39.531881, longitude: -94.050775, zipCode: "64671"},
	{latitude: 40.538926, longitude: -93.249737, zipCode: "64672"},
	{latitude: 40.374309, longitude: -93.586901, zipCode: "64673"},
	{latitude: 39.967615, longitude: -93.156958, zipCode: "64674"},
	{latitude: 39.646173, longitude: -93.030107, zipCode: "64676"},
	{latitude: 40.23723, longitude: -93.580806, zipCode: "64679"},
	{latitude: 39.649257, longitude: -93.213258, zipCode: "64681"},
	{latitude: 39.549491, longitude: -93.495161, zipCode: "64682"},
	{latitude: 40.073782, longitude: -93.603525, zipCode: "64683"},
	{latitude: 39.748701, longitude: -93.623824, zipCode: "64686"},
	{latitude: 39.812391, longitude: -93.369305, zipCode: "64688"},
	{latitude: 39.875177, longitude: -94.151152, zipCode: "64689"},
	{latitude: 38.624518, longitude: -94.329252, zipCode: "64701"},
	{latitude: 38.404278, longitude: -94.335778, zipCode: "64720"},
	{latitude: 38.276462, longitude: -94.554491, zipCode: "64722"},
	{latitude: 38.361639, longitude: -94.559592, zipCode: "64723"},
	{latitude: 38.15321, longitude: -94.006638, zipCode: "64724"},
	{latitude: 38.490658, longitude: -94.372655, zipCode: "64725"},
	{latitude: 38.527153, longitude: -93.921941, zipCode: "64726"},
	{latitude: 37.698899, longitude: -94.517846, zipCode: "64728"},
	{latitude: 38.255968, longitude: -94.283819, zipCode: "64730"},
	{latitude: 38.584554, longitude: -93.82749, zipCode: "64733"},
	{latitude: 38.654734, longitude: -94.559334, zipCode: "64734"},
	{latitude: 38.372423, longitude: -93.727337, zipCode: "64735"},
	{latitude: 37.901664, longitude: -93.651689, zipCode: "64738"},
	{latitude: 38.51128, longitude: -94.096624, zipCode: "64739"},
	{latitude: 38.227505, longitude: -93.702953, zipCode: "64740"},
	{latitude: 37.818234, longitude: -94.562345, zipCode: "64741"},
	{latitude: 38.508095, longitude: -94.541195, zipCode: "64742"},
	{latitude: 38.663695, longitude: -94.213956, zipCode: "64743"},
	{latitude: 37.848197, longitude: -93.978926, zipCode: "64744"},
	{latitude: 38.15126, longitude: -94.507744, zipCode: "64745"},
	{latitude: 38.602146, longitude: -94.496724, zipCode: "64746"},
	{latitude: 38.576924, longitude: -94.17142, zipCode: "64747"},
	{latitude: 37.364764, longitude: -94.102264, zipCode: "64748"},
	{latitude: 37.921459, longitude: -94.113212, zipCode: "64750"},
	{latitude: 38.065018, longitude: -94.563468, zipCode: "64752"},
	{latitude: 37.337416, longitude: -94.308691, zipCode: "64755"},
	{latitude: 37.61903, longitude: -94.015167, zipCode: "64756"},
	{latitude: 37.521844, longitude: -94.267108, zipCode: "64759"},
	{latitude: 38.608025, longitude: -93.675642, zipCode: "64761"},
	{latitude: 37.546659, longitude: -94.501483, zipCode: "64762"},
	{latitude: 38.144005, longitude: -93.7115, zipCode: "64763"},
	{latitude: 37.99678, longitude: -94.442531, zipCode: "64765"},
	{latitude: 37.737357, longitude: -94.201452, zipCode: "64767"},
	{latitude: 37.49318, longitude: -94.548201, zipCode: "64769"},
	{latitude: 38.281451, longitude: -93.989507, zipCode: "64770"},
	{latitude: 37.772695, longitude: -94.463438, zipCode: "64771"},
	{latitude: 37.825103, longitude: -94.333493, zipCode: "64772"},
	{latitude: 38.042283, longitude: -93.675435, zipCode: "64776"},
	{latitude: 37.919372, longitude: -94.47919, zipCode: "64778"},
	{latitude: 38.092018, longitude: -94.396212, zipCode: "64779"},
	{latitude: 38.059202, longitude: -94.046961, zipCode: "64780"},
	{latitude: 37.976643, longitude: -93.817473, zipCode: "64781"},
	{latitude: 37.999796, longitude: -94.105267, zipCode: "64783"},
	{latitude: 37.664651, longitude: -94.234169, zipCode: "64784"},
	{latitude: 38.423906, longitude: -94.019548, zipCode: "64788"},
	{latitude: 37.919348, longitude: -94.222169, zipCode: "64790"},
	{latitude: 37.106902, longitude: -94.497647, zipCode: "64801"},
	{latitude: 37.019944, longitude: -94.510905, zipCode: "64804"},
	{latitude: 37.23082, longitude: -94.410561, zipCode: "64830"},
	{latitude: 36.659128, longitude: -94.456762, zipCode: "64831"},
	{latitude: 37.340209, longitude: -94.573598, zipCode: "64832"},
	{latitude: 37.19381, longitude: -94.129842, zipCode: "64833"},
	{latitude: 37.1857, longitude: -94.572848, zipCode: "64834"},
	{latitude: 37.147517, longitude: -94.438287, zipCode: "64835"},
	{latitude: 37.189255, longitude: -94.279255, zipCode: "64836"},
	{latitude: 37.020059, longitude: -94.342858, zipCode: "64840"},
	{latitude: 37.078712, longitude: -94.411027, zipCode: "64841"},
	{latitude: 36.789007, longitude: -94.105994, zipCode: "64842"},
	{latitude: 36.733479, longitude: -94.45317, zipCode: "64843"},
	{latitude: 36.910917, longitude: -94.239554, zipCode: "64844"},
	{latitude: 36.592151, longitude: -94.438637, zipCode: "64847"},
	{latitude: 37.185314, longitude: -93.995267, zipCode: "64848"},
	{latitude: 37.254396, longitude: -94.445292, zipCode: "64849"},
	{latitude: 36.860533, longitude: -94.401675, zipCode: "64850"},
	{latitude: 36.549701, longitude: -94.437596, zipCode: "64854"},
	{latitude: 37.336855, longitude: -94.513011, zipCode: "64855"},
	{latitude: 36.549263, longitude: -94.252192, zipCode: "64856"},
	{latitude: 37.245867, longitude: -94.438623, zipCode: "64857"},
	{latitude: 36.900862, longitude: -94.530534, zipCode: "64858"},
	{latitude: 37.164209, longitude: -94.113408, zipCode: "64859"},
	{latitude: 36.695352, longitude: -94.154072, zipCode: "64861"},
	{latitude: 37.100665, longitude: -94.119523, zipCode: "64862"},
	{latitude: 36.559367, longitude: -94.58334, zipCode: "64863"},
	{latitude: 36.842651, longitude: -94.581313, zipCode: "64865"},
	{latitude: 36.869577, longitude: -94.156423, zipCode: "64866"},
	{latitude: 36.724466, longitude: -94.23472, zipCode: "64867"},
	{latitude: 36.687541, longitude: -94.61285, zipCode: "64868"},
	{latitude: 37.182917, longitude: -94.477775, zipCode: "64870"},
	{latitude: 37.010659, longitude: -94.053847, zipCode: "64873"},
	{latitude: 36.752144, longitude: -94.052997, zipCode: "64874"},
	{latitude: 38.27761, longitude: -92.004757, zipCode: "65001"},
	{latitude: 38.78781, longitude: -92.241975, zipCode: "65010"},
	{latitude: 38.402263, longitude: -92.71027, zipCode: "65011"},
	{latitude: 38.269917, longitude: -91.752129, zipCode: "65013"},
	{latitude: 38.322804, longitude: -91.624649, zipCode: "65014"},
	{latitude: 38.560706, longitude: -91.919121, zipCode: "65016"},
	{latitude: 38.092307, longitude: -92.494503, zipCode: "65017"},
	{latitude: 38.620078, longitude: -92.564068, zipCode: "65018"},
	{latitude: 38.006239, longitude: -92.780644, zipCode: "65020"},
	{latitude: 38.653901, longitude: -92.396296, zipCode: "65023"},
	{latitude: 38.629998, longitude: -91.774725, zipCode: "65024"},
	{latitude: 38.667143, longitude: -92.69929, zipCode: "65025"},
	{latitude: 38.324361, longitude: -92.564791, zipCode: "65026"},
	{latitude: 38.347628, longitude: -92.383086, zipCode: "65032"},
	{latitude: 38.559677, longitude: -92.818105, zipCode: "65034"},
	{latitude: 38.363815, longitude: -91.946963, zipCode: "65035"},
	{latitude: 38.243419, longitude: -92.849146, zipCode: "65037"},
	{latitude: 38.711076, longitude: -92.296636, zipCode: "65039"},
	{latitude: 38.348268, longitude: -92.284585, zipCode: "65040"},
	{latitude: 38.63302, longitude: -91.489758, zipCode: "65041"},
	{latitude: 38.623012, longitude: -92.102287, zipCode: "65043"},
	{latitude: 38.775851, longitude: -92.477586, zipCode: "65046"},
	{latitude: 38.150894, longitude: -92.578446, zipCode: "65047"},
	{latitude: 38.37312, longitude: -92.040605, zipCode: "65048"},
	{latitude: 38.199958, longitude: -92.67452, zipCode: "65049"},
	{latitude: 38.521827, longitude: -92.686401, zipCode: "65050"},
	{latitude: 38.462526, longitude: -91.797971, zipCode: "65051"},
	{latitude: 38.076413, longitude: -92.70546, zipCode: "65052"},
	{latitude: 38.541217, longitude: -92.36959, zipCode: "65053"},
	{latitude: 38.483962, longitude: -91.946925, zipCode: "65054"},
	{latitude: 38.269515, longitude: -92.130674, zipCode: "65058"},
	{latitude: 38.701317, longitude: -91.888159, zipCode: "65059"},
	{latitude: 38.594245, longitude: -91.634877, zipCode: "65061"},
	{latitude: 38.50121, longitude: -91.659734, zipCode: "65062"},
	{latitude: 38.725915, longitude: -92.087514, zipCode: "65063"},
	{latitude: 38.402845, longitude: -92.471423, zipCode: "65064"},
	{latitude: 38.132046, longitude: -92.669926, zipCode: "65065"},
	{latitude: 38.345418, longitude: -91.490472, zipCode: "65066"},
	{latitude: 38.744622, longitude: -91.728111, zipCode: "65067"},
	{latitude: 38.828625, longitude: -92.598054, zipCode: "65068"},
	{latitude: 38.757504, longitude: -91.608296, zipCode: "65069"},
	{latitude: 38.266773, longitude: -92.72935, zipCode: "65072"},
	{latitude: 38.484301, longitude: -92.468144, zipCode: "65074"},
	{latitude: 38.237326, longitude: -92.250624, zipCode: "65075"},
	{latitude: 38.379486, longitude: -92.194056, zipCode: "65076"},
	{latitude: 38.769625, longitude: -91.818502, zipCode: "65077"},
	{latitude: 38.381135, longitude: -93.035985, zipCode: "65078"},
	{latitude: 38.155851, longitude: -92.751986, zipCode: "65079"},
	{latitude: 38.636261, longitude: -91.978927, zipCode: "65080"},
	{latitude: 38.633981, longitude: -92.779889, zipCode: "65081"},
	{latitude: 38.219313, longitude: -92.421422, zipCode: "65082"},
	{latitude: 38.1475, longitude: -92.443614, zipCode: "65083"},
	{latitude: 38.438023, longitude: -92.855365, zipCode: "65084"},
	{latitude: 38.405742, longitude: -92.083782, zipCode: "65085"},
	{latitude: 38.49743, longitude: -92.133196, zipCode: "65101"},
	{latitude: 38.573482, longitude: -92.276821, zipCode: "65109"},
	{latitude: 38.900945, longitude: -92.238427, zipCode: "65201"},
	{latitude: 39.01716, longitude: -92.297814, zipCode: "65202"},
	{latitude: 38.895872, longitude: -92.395843, zipCode: "65203"},
	{latitude: 38.951687, longitude: -92.320389, zipCode: "65215"},
	{latitude: 38.958171, longitude: -92.326298, zipCode: "65216"},
	{latitude: 39.29055, longitude: -92.681308, zipCode: "65230"},
	{latitude: 39.017843, longitude: -91.907822, zipCode: "65231"},
	{latitude: 39.160695, longitude: -91.756, zipCode: "65232"},
	{latitude: 38.910695, longitude: -92.723915, zipCode: "65233"},
	{latitude: 39.448439, longitude: -93.088686, zipCode: "65236"},
	{latitude: 38.767567, longitude: -92.794578, zipCode: "65237"},
	{latitude: 39.533015, longitude: -92.420798, zipCode: "65239"},
	{latitude: 39.220047, longitude: -92.131098, zipCode: "65240"},
	{latitude: 39.275217, longitude: -92.35057, zipCode: "65243"},
	{latitude: 39.4848, longitude: -92.659485, zipCode: "65244"},
	{latitude: 39.370188, longitude: -92.993886, zipCode: "65246"},
	{latitude: 39.635081, longitude: -92.470867, zipCode: "65247"},
	{latitude: 39.138606, longitude: -92.660133, zipCode: "65248"},
	{latitude: 39.0301, longitude: -92.840101, zipCode: "65250"},
	{latitude: 38.849998, longitude: -91.978774, zipCode: "65251"},
	{latitude: 39.211594, longitude: -92.846129, zipCode: "65254"},
	{latitude: 39.102418, longitude: -92.218415, zipCode: "65255"},
	{latitude: 39.121584, longitude: -92.455552, zipCode: "65256"},
	{latitude: 39.287086, longitude: -92.533811, zipCode: "65257"},
	{latitude: 39.526093, longitude: -92.138242, zipCode: "65258"},
	{latitude: 39.475322, longitude: -92.563651, zipCode: "65259"},
	{latitude: 39.593302, longitude: -92.400522, zipCode: "65260"},
	{latitude: 39.462382, longitude: -92.916036, zipCode: "65261"},
	{latitude: 38.960143, longitude: -91.955313, zipCode: "65262"},
	{latitude: 39.448143, longitude: -92.218052, zipCode: "65263"},
	{latitude: 39.088314, longitude: -91.68564, zipCode: "65264"},
	{latitude: 39.203373, longitude: -91.885371, zipCode: "65265"},
	{latitude: 39.413639, longitude: -92.39495, zipCode: "65270"},
	{latitude: 39.015477, longitude: -92.685507, zipCode: "65274"},
	{latitude: 39.453066, longitude: -91.994965, zipCode: "65275"},
	{latitude: 38.852749, longitude: -92.957912, zipCode: "65276"},
	{latitude: 39.342148, longitude: -92.412206, zipCode: "65278"},
	{latitude: 38.994375, longitude: -92.545027, zipCode: "65279"},
	{latitude: 39.233565, longitude: -91.754613, zipCode: "65280"},
	{latitude: 39.466112, longitude: -92.788003, zipCode: "65281"},
	{latitude: 39.382472, longitude: -91.839756, zipCode: "65282"},
	{latitude: 39.509271, longitude: -91.827525, zipCode: "65283"},
	{latitude: 39.186823, longitude: -92.30416, zipCode: "65284"},
	{latitude: 39.271734, longitude: -91.999838, zipCode: "65285"},
	{latitude: 39.507751, longitude: -93.220276, zipCode: "65286"},
	{latitude: 38.883655, longitude: -92.52232, zipCode: "65287"},
	{latitude: 38.699117, longitude: -93.221345, zipCode: "65301"},
	{latitude: 38.733116, longitude: -93.553158, zipCode: "65305"},
	{latitude: 39.066396, longitude: -92.945955, zipCode: "65320"},
	{latitude: 39.075267, longitude: -93.447502, zipCode: "65321"},
	{latitude: 38.969776, longitude: -92.966365, zipCode: "65322"},
	{latitude: 38.453529, longitude: -93.616304, zipCode: "65323"},
	{latitude: 38.097093, longitude: -93.009748, zipCode: "65324"},
	{latitude: 38.439546, longitude: -93.1888, zipCode: "65325"},
	{latitude: 38.16584, longitude: -93.13917, zipCode: "65326"},
	{latitude: 38.976539, longitude: -93.484089, zipCode: "65327"},
	{latitude: 38.602086, longitude: -92.957038, zipCode: "65329"},
	{latitude: 39.222686, longitude: -92.950634, zipCode: "65330"},
	{latitude: 38.619836, longitude: -93.413505, zipCode: "65332"},
	{latitude: 38.900768, longitude: -93.319941, zipCode: "65333"},
	{latitude: 38.838248, longitude: -93.246143, zipCode: "65334"},
	{latitude: 38.513709, longitude: -93.322812, zipCode: "65335"},
	{latitude: 38.785151, longitude: -93.557492, zipCode: "65336"},
	{latitude: 38.794493, longitude: -93.431379, zipCode: "65337"},
	{latitude: 38.357194, longitude: -93.305888, zipCode: "65338"},
	{latitude: 39.188819, longitude: -93.371603, zipCode: "65339"},
	{latitude: 39.088482, longitude: -93.175934, zipCode: "65340"},
	{latitude: 39.318962, longitude: -93.204192, zipCode: "65344"},
	{latitude: 38.537892, longitude: -93.126897, zipCode: "65345"},
	{latitude: 38.98104, longitude: -93.046305, zipCode: "65347"},
	{latitude: 38.712425, longitude: -92.989911, zipCode: "65348"},
	{latitude: 39.233822, longitude: -93.053552, zipCode: "65349"},
	{latitude: 38.654785, longitude: -93.10301, zipCode: "65350"},
	{latitude: 38.994519, longitude: -93.381385, zipCode: "65351"},
	{latitude: 38.643566, longitude: -92.885997, zipCode: "65354"},
	{latitude: 38.219531, longitude: -93.366959, zipCode: "65355"},
	{latitude: 38.52016, longitude: -93.514635, zipCode: "65360"},
	{latitude: 37.899248, longitude: -91.768537, zipCode: "65401"},
	{latitude: 37.950967, longitude: -91.77954, zipCode: "65409"},
	{latitude: 37.623801, longitude: -91.96361, zipCode: "65436"},
	{latitude: 36.931336, longitude: -91.501101, zipCode: "65438"},
	{latitude: 37.664882, longitude: -91.094227, zipCode: "65439"},
	{latitude: 37.599217, longitude: -91.190612, zipCode: "65440"},
	{latitude: 38.096433, longitude: -91.159018, zipCode: "65441"},
	{latitude: 38.131713, longitude: -92.094612, zipCode: "65443"},
	{latitude: 37.37778, longitude: -92.053848, zipCode: "65444"},
	{latitude: 37.810903, longitude: -91.269805, zipCode: "65446"},
	{latitude: 37.852033, longitude: -91.494674, zipCode: "65449"},
	{latitude: 37.967139, longitude: -92.266342, zipCode: "65452"},
	{latitude: 38.107481, longitude: -91.427005, zipCode: "65453"},
	{latitude: 37.784607, longitude: -91.18978, zipCode: "65456"},
	{latitude: 37.823799, longitude: -92.051019, zipCode: "65457"},
	{latitude: 38.02972, longitude: -92.096732, zipCode: "65459"},
	{latitude: 37.687102, longitude: -92.02376, zipCode: "65461"},
	{latitude: 37.684681, longitude: -91.864589, zipCode: "65462"},
	{latitude: 37.847675, longitude: -92.783349, zipCode: "65463"},
	{latitude: 37.186578, longitude: -91.899782, zipCode: "65464"},
	{latitude: 37.211312, longitude: -91.386372, zipCode: "65466"},
	{latitude: 37.254359, longitude: -91.798258, zipCode: "65468"},
	{latitude: 37.54739, longitude: -92.401779, zipCode: "65470"},
	{latitude: 37.709893, longitude: -92.147911, zipCode: "65473"},
	{latitude: 37.342026, longitude: -91.636139, zipCode: "65479"},
	{latitude: 37.298969, longitude: -91.955093, zipCode: "65483"},
	{latitude: 37.356223, longitude: -92.227015, zipCode: "65484"},
	{latitude: 38.103192, longitude: -92.306674, zipCode: "65486"},
	{latitude: 37.45461, longitude: -91.541535, zipCode: "65501"},
	{latitude: 37.929116, longitude: -91.989143, zipCode: "65529"},
	{latitude: 37.70079, longitude: -92.260406, zipCode: "65534"},
	{latitude: 38.070003, longitude: -91.274154, zipCode: "65535"},
	{latitude: 37.691691, longitude: -92.647854, zipCode: "65536"},
	{latitude: 37.643728, longitude: -91.758724, zipCode: "65541"},
	{latitude: 37.49571, longitude: -91.883741, zipCode: "65542"},
	{latitude: 37.453945, longitude: -92.290231, zipCode: "65543"},
	{latitude: 37.005701, longitude: -91.712048, zipCode: "65548"},
	{latitude: 37.84413, longitude: -91.943747, zipCode: "65550"},
	{latitude: 37.498044, longitude: -92.172291, zipCode: "65552"},
	{latitude: 37.362235, longitude: -91.778188, zipCode: "65555"},
	{latitude: 37.835355, longitude: -92.403661, zipCode: "65556"},
	{latitude: 37.516799, longitude: -92.144602, zipCode: "65557"},
	{latitude: 38.016857, longitude: -91.614457, zipCode: "65559"},
	{latitude: 37.59661, longitude: -91.503867, zipCode: "65560"},
	{latitude: 37.232593, longitude: -91.956395, zipCode: "65564"},
	{latitude: 37.896485, longitude: -91.239276, zipCode: "65565"},
	{latitude: 37.808607, longitude: -91.161474, zipCode: "65566"},
	{latitude: 37.849482, longitude: -92.54003, zipCode: "65567"},
	{latitude: 37.452634, longitude: -92.094798, zipCode: "65570"},
	{latitude: 37.186576, longitude: -91.644761, zipCode: "65571"},
	{latitude: 38.116506, longitude: -91.789901, zipCode: "65580"},
	{latitude: 38.204152, longitude: -91.926434, zipCode: "65582"},
	{latitude: 37.82843, longitude: -92.257625, zipCode: "65583"},
	{latitude: 37.828894, longitude: -92.131217, zipCode: "65584"},
	{latitude: 37.864779, longitude: -91.432955, zipCode: "65586"},
	{latitude: 36.966274, longitude: -91.29365, zipCode: "65588"},
	{latitude: 37.237297, longitude: -91.827444, zipCode: "65589"},
	{latitude: 37.636585, longitude: -92.975332, zipCode: "65590"},
	{latitude: 37.97938, longitude: -92.590769, zipCode: "65591"},
	{latitude: 37.530032, longitude: -93.58573, zipCode: "65601"},
	{latitude: 37.533454, longitude: -93.856535, zipCode: "65603"},
	{latitude: 37.269914, longitude: -93.598471, zipCode: "65604"},
	{latitude: 36.989679, longitude: -93.73973, zipCode: "65605"},
	{latitude: 36.697248, longitude: -91.359401, zipCode: "65606"},
	{latitude: 36.902123, longitude: -92.667292, zipCode: "65608"},
	{latitude: 36.526793, longitude: -92.176983, zipCode: "65609"},
	{latitude: 37.042315, longitude: -93.525315, zipCode: "65610"},
	{latitude: 36.549823, longitude: -93.372126, zipCode: "65611"},
	{latitude: 37.213797, longitude: -93.538811, zipCode: "65612"},
	{latitude: 37.640914, longitude: -93.387351, zipCode: "65613"},
	{latitude: 36.733727, longitude: -92.89709, zipCode: "65614"},
	{latitude: 36.670737, longitude: -93.2488, zipCode: "65616"},
	{latitude: 37.437614, longitude: -93.334662, zipCode: "65617"},
	{latitude: 36.759505, longitude: -92.367121, zipCode: "65618"},
	{latitude: 37.122459, longitude: -93.386624, zipCode: "65619"},
	{latitude: 37.017469, longitude: -92.955719, zipCode: "65620"},
	{latitude: 37.635235, longitude: -93.110239, zipCode: "65622"},
	{latitude: 36.748889, longitude: -93.906575, zipCode: "65623"},
	{latitude: 36.731636, longitude: -93.561154, zipCode: "65624"},
	{latitude: 36.674069, longitude: -93.816497, zipCode: "65625"},
	{latitude: 36.587772, longitude: -92.113109, zipCode: "65626"},
	{latitude: 36.566955, longitude: -93.032874, zipCode: "65627"},
	{latitude: 36.891538, longitude: -93.000516, zipCode: "65629"},
	{latitude: 36.838159, longitude: -93.226303, zipCode: "65630"},
	{latitude: 37.000699, longitude: -93.423999, zipCode: "65631"},
	{latitude: 37.499015, longitude: -92.815963, zipCode: "65632"},
	{latitude: 36.899134, longitude: -93.535971, zipCode: "65633"},
	{latitude: 38.015397, longitude: -93.194616, zipCode: "65634"},
	{latitude: 37.531741, longitude: -93.717143, zipCode: "65635"},
	{latitude: 36.776353, longitude: -92.190751, zipCode: "65637"},
	{latitude: 36.833134, longitude: -92.330273, zipCode: "65638"},
	{latitude: 37.698467, longitude: -93.566848, zipCode: "65640"},
	{latitude: 36.544001, longitude: -93.771047, zipCode: "65641"},
	{latitude: 37.510802, longitude: -93.037975, zipCode: "65644"},
	{latitude: 37.333448, longitude: -93.703246, zipCode: "65646"},
	{latitude: 36.69296, longitude: -94.007064, zipCode: "65647"},
	{latitude: 37.408778, longitude: -93.166512, zipCode: "65648"},
	{latitude: 37.617274, longitude: -93.623215, zipCode: "65649"},
	{latitude: 37.813994, longitude: -93.441928, zipCode: "65650"},
	{latitude: 37.133087, longitude: -92.949997, zipCode: "65652"},
	{latitude: 36.840868, longitude: -93.140301, zipCode: "65653"},
	{latitude: 37.021684, longitude: -93.897529, zipCode: "65654"},
	{latitude: 36.603082, longitude: -92.419582, zipCode: "65655"},
	{latitude: 36.793766, longitude: -93.46783, zipCode: "65656"},
	{latitude: 36.839394, longitude: -93.018217, zipCode: "65657"},
	{latitude: 36.546676, longitude: -93.638135, zipCode: "65658"},
	{latitude: 37.323909, longitude: -92.270747, zipCode: "65660"},
	{latitude: 37.45627, longitude: -93.829081, zipCode: "65661"},
	{latitude: 37.419061, longitude: -92.572331, zipCode: "65662"},
	{latitude: 37.630156, longitude: -93.246009, zipCode: "65663"},
	{latitude: 37.198054, longitude: -93.615176, zipCode: "65664"},
	{latitude: 36.57483, longitude: -92.360456, zipCode: "65666"},
	{latitude: 37.308459, longitude: -92.514047, zipCode: "65667"},
	{latitude: 37.930666, longitude: -93.288163, zipCode: "65668"},
	{latitude: 36.915521, longitude: -93.295633, zipCode: "65669"},
	{latitude: 36.560353, longitude: -93.219489, zipCode: "65672"},
	{latitude: 37.791219, longitude: -93.608207, zipCode: "65674"},
	{latitude: 36.93093, longitude: -93.492387, zipCode: "65675"},
	{latitude: 36.578048, longitude: -92.599028, zipCode: "65676"},
	{latitude: 36.574234, longitude: -93.094922, zipCode: "65679"},
	{latitude: 36.641983, longitude: -92.981749, zipCode: "65680"},
	{latitude: 36.545551, longitude: -93.468709, zipCode: "65681"},
	{latitude: 37.416231, longitude: -93.979593, zipCode: "65682"},
	{latitude: 37.753739, longitude: -93.151133, zipCode: "65685"},
	{latitude: 36.626859, longitude: -93.443782, zipCode: "65686"},
	{latitude: 36.649279, longitude: -91.693279, zipCode: "65688"},
	{latitude: 37.135825, longitude: -92.090842, zipCode: "65689"},
	{latitude: 36.592338, longitude: -91.264476, zipCode: "65690"},
	{latitude: 36.62145, longitude: -91.650328, zipCode: "65692"},
	{latitude: 37.053256, longitude: -92.485409, zipCode: "65702"},
	{latitude: 37.12762, longitude: -92.586107, zipCode: "65704"},
	{latitude: 37.092357, longitude: -93.623318, zipCode: "65705"},
	{latitude: 37.322817, longitude: -92.898613, zipCode: "65706"},
	{latitude: 37.233674, longitude: -93.843315, zipCode: "65707"},
	{latitude: 36.910924, longitude: -93.908754, zipCode: "65708"},
	{latitude: 37.485555, longitude: -93.421088, zipCode: "65710"},
	{latitude: 37.176382, longitude: -92.275679, zipCode: "65711"},
	{latitude: 37.110815, longitude: -93.806027, zipCode: "65712"},
	{latitude: 37.405733, longitude: -92.739217, zipCode: "65713"},
	{latitude: 37.040971, longitude: -93.320743, zipCode: "65714"},
	{latitude: 36.731375, longitude: -92.574001, zipCode: "65715"},
	{latitude: 37.06044, longitude: -92.416048, zipCode: "65717"},
	{latitude: 36.947556, longitude: -92.962543, zipCode: "65720"},
	{latitude: 36.980529, longitude: -93.209387, zipCode: "65721"},
	{latitude: 37.589022, longitude: -92.807729, zipCode: "65722"},
	{latitude: 36.953147, longitude: -94.048372, zipCode: "65723"},
	{latitude: 37.840458, longitude: -93.316158, zipCode: "65724"},
	{latitude: 37.429655, longitude: -93.264141, zipCode: "65725"},
	{latitude: 36.619595, longitude: -93.242859, zipCode: "65726"},
	{latitude: 37.765849, longitude: -93.295111, zipCode: "65727"},
	{latitude: 36.877092, longitude: -93.3353, zipCode: "65728"},
	{latitude: 36.538889, longitude: -92.563512, zipCode: "65729"},
	{latitude: 36.623583, longitude: -94.224668, zipCode: "65730"},
	{latitude: 36.653457, longitude: -93.125924, zipCode: "65731"},
	{latitude: 37.9348, longitude: -93.177165, zipCode: "65732"},
	{latitude: 36.533097, longitude: -92.851245, zipCode: "65733"},
	{latitude: 36.796585, longitude: -93.953748, zipCode: "65734"},
	{latitude: 38.039886, longitude: -93.486638, zipCode: "65735"},
	{latitude: 36.712204, longitude: -93.350973, zipCode: "65737"},
	{latitude: 37.137273, longitude: -93.490452, zipCode: "65738"},
	{latitude: 36.510963, longitude: -93.274118, zipCode: "65739"},
	{latitude: 36.711926, longitude: -93.163213, zipCode: "65740"},
	{latitude: 36.795809, longitude: -92.404237, zipCode: "65741"},
	{latitude: 37.125343, longitude: -93.088984, zipCode: "65742"},
	{latitude: 36.624477, longitude: -92.893379, zipCode: "65744"},
	{latitude: 36.522672, longitude: -93.983629, zipCode: "65745"},
	{latitude: 37.143195, longitude: -92.7877, zipCode: "65746"},
	{latitude: 36.600861, longitude: -93.580084, zipCode: "65747"},
	{latitude: 37.332402, longitude: -93.838349, zipCode: "65752"},
	{latitude: 36.994555, longitude: -93.056207, zipCode: "65753"},
	{latitude: 36.838441, longitude: -93.282222, zipCode: "65754"},
	{latitude: 36.801592, longitude: -92.632683, zipCode: "65755"},
	{latitude: 37.096307, longitude: -93.960331, zipCode: "65756"},
	{latitude: 37.282852, longitude: -93.098839, zipCode: "65757"},
	{latitude: 36.767553, longitude: -93.048394, zipCode: "65759"},
	{latitude: 36.616251, longitude: -92.276774, zipCode: "65760"},
	{latitude: 36.5412, longitude: -92.721785, zipCode: "65761"},
	{latitude: 36.680587, longitude: -92.66287, zipCode: "65762"},
	{latitude: 37.81452, longitude: -92.948674, zipCode: "65764"},
	{latitude: 36.536052, longitude: -92.268646, zipCode: "65766"},
	{latitude: 37.852251, longitude: -93.155937, zipCode: "65767"},
	{latitude: 36.919435, longitude: -92.270035, zipCode: "65768"},
	{latitude: 36.919096, longitude: -93.799405, zipCode: "65769"},
	{latitude: 37.422627, longitude: -93.548226, zipCode: "65770"},
	{latitude: 36.774833, longitude: -93.204208, zipCode: "65771"},
	{latitude: 36.59795, longitude: -94.024935, zipCode: "65772"},
	{latitude: 36.750977, longitude: -92.516888, zipCode: "65773"},
	{latitude: 37.930273, longitude: -93.489344, zipCode: "65774"},
	{latitude: 36.701055, longitude: -91.856335, zipCode: "65775"},
	{latitude: 36.517396, longitude: -91.980986, zipCode: "65777"},
	{latitude: 36.530448, longitude: -91.271025, zipCode: "65778"},
	{latitude: 37.981959, longitude: -93.384659, zipCode: "65779"},
	{latitude: 37.355539, longitude: -93.417572, zipCode: "65781"},
	{latitude: 37.710693, longitude: -92.903269, zipCode: "65783"},
	{latitude: 36.730108, longitude: -92.297404, zipCode: "65784"},
	{latitude: 37.708475, longitude: -93.825054, zipCode: "65785"},
	{latitude: 37.967389, longitude: -92.963419, zipCode: "65786"},
	{latitude: 38.067687, longitude: -92.898872, zipCode: "65787"},
	{latitude: 36.806689, longitude: -91.694945, zipCode: "65788"},
	{latitude: 36.857229, longitude: -91.889823, zipCode: "65789"},
	{latitude: 36.702937, longitude: -92.109573, zipCode: "65790"},
	{latitude: 36.579155, longitude: -91.49723, zipCode: "65791"},
	{latitude: 36.99172, longitude: -91.980005, zipCode: "65793"},
	{latitude: 37.21421, longitude: -93.295218, zipCode: "65801"},
	{latitude: 37.210537, longitude: -93.35169, zipCode: "65802"},
	{latitude: 37.283159, longitude: -93.289905, zipCode: "65803"},
	{latitude: 37.148205, longitude: -93.252457, zipCode: "65804"},
	{latitude: 37.205661, longitude: -93.298579, zipCode: "65806"},
	{latitude: 37.167524, longitude: -93.324808, zipCode: "65807"},
	{latitude: 37.173467, longitude: -93.189943, zipCode: "65809"},
	{latitude: 37.119734, longitude: -93.309724, zipCode: "65810"},
	{latitude: 37.199857, longitude: -93.280538, zipCode: "65897"},
	{latitude: 39.55166, longitude: -95.140354, zipCode: "66002"},
	{latitude: 38.788836, longitude: -95.238103, zipCode: "66006"},
	{latitude: 39.13603, longitude: -94.944555, zipCode: "66007"},
	{latitude: 39.718181, longitude: -95.176388, zipCode: "66008"},
	{latitude: 38.091956, longitude: -94.996041, zipCode: "66010"},
	{latitude: 39.066626, longitude: -94.92058, zipCode: "66012"},
	{latitude: 38.730666, longitude: -94.695551, zipCode: "66013"},
	{latitude: 38.228845, longitude: -94.987105, zipCode: "66014"},
	{latitude: 38.075724, longitude: -95.400167, zipCode: "66015"},
	{latitude: 39.467255, longitude: -95.222694, zipCode: "66016"},
	{latitude: 39.703716, longitude: -95.273961, zipCode: "66017"},
	{latitude: 38.96405, longitude: -94.974413, zipCode: "66018"},
	{latitude: 39.339359, longitude: -95.111443, zipCode: "66020"},
	{latitude: 38.75661, longitude: -95.005098, zipCode: "66021"},
	{latitude: 39.512268, longitude: -95.392384, zipCode: "66023"},
	{latitude: 39.762082, longitude: -94.889502, zipCode: "66024"},
	{latitude: 38.891903, longitude: -95.08334, zipCode: "66025"},
	{latitude: 38.395871, longitude: -94.866312, zipCode: "66026"},
	{latitude: 39.361406, longitude: -94.916526, zipCode: "66027"},
	{latitude: 38.815123, longitude: -94.940262, zipCode: "66030"},
	{latitude: 38.831962, longitude: -94.890083, zipCode: "66031"},
	{latitude: 38.277458, longitude: -95.276974, zipCode: "66032"},
	{latitude: 38.358776, longitude: -95.127585, zipCode: "66033"},
	{latitude: 39.870959, longitude: -95.253634, zipCode: "66035"},
	{latitude: 38.104411, longitude: -95.164855, zipCode: "66039"},
	{latitude: 38.356875, longitude: -94.738049, zipCode: "66040"},
	{latitude: 39.600303, longitude: -95.307974, zipCode: "66041"},
	{latitude: 38.422764, longitude: -95.079878, zipCode: "66042"},
	{latitude: 39.248453, longitude: -94.905575, zipCode: "66043"},
	{latitude: 39.032931, longitude: -95.209092, zipCode: "66044"},
	{latitude: 38.954328, longitude: -95.261527, zipCode: "66045"},
	{latitude: 38.904524, longitude: -95.209753, zipCode: "66046"},
	{latitude: 38.893051, longitude: -95.34616, zipCode: "66047"},
	{latitude: 39.272723, longitude: -94.980405, zipCode: "66048"},
	{latitude: 38.979249, longitude: -95.345686, zipCode: "66049"},
	{latitude: 39.025738, longitude: -95.430057, zipCode: "66050"},
	{latitude: 39.008348, longitude: -95.04558, zipCode: "66052"},
	{latitude: 38.581078, longitude: -94.668065, zipCode: "66053"},
	{latitude: 39.20622, longitude: -95.198329, zipCode: "66054"},
	{latitude: 38.151453, longitude: -94.844282, zipCode: "66056"},
	{latitude: 39.541062, longitude: -95.520379, zipCode: "66058"},
	{latitude: 39.418672, longitude: -95.331986, zipCode: "66060"},
	{latitude: 38.893755, longitude: -94.879968, zipCode: "66061"},
	{latitude: 38.838296, longitude: -94.784409, zipCode: "66062"},
	{latitude: 38.480714, longitude: -94.988936, zipCode: "66064"},
	{latitude: 39.203002, longitude: -95.330593, zipCode: "66066"},
	{latitude: 38.619765, longitude: -95.284211, zipCode: "66067"},
	{latitude: 39.211355, longitude: -95.453339, zipCode: "66070"},
	{latitude: 38.574468, longitude: -94.8667, zipCode: "66071"},
	{latitude: 38.31847, longitude: -94.974751, zipCode: "66072"},
	{latitude: 39.092072, longitude: -95.380998, zipCode: "66073"},
	{latitude: 38.200962, longitude: -94.684056, zipCode: "66075"},
	{latitude: 38.632455, longitude: -95.443013, zipCode: "66076"},
	{latitude: 38.48454, longitude: -95.25801, zipCode: "66078"},
	{latitude: 38.542059, longitude: -95.124399, zipCode: "66079"},
	{latitude: 38.412899, longitude: -95.254945, zipCode: "66080"},
	{latitude: 38.732299, longitude: -94.830141, zipCode: "66083"},
	{latitude: 38.806276, longitude: -94.645167, zipCode: "66085"},
	{latitude: 39.114913, longitude: -95.081394, zipCode: "66086"},
	{latitude: 39.808933, longitude: -95.139757, zipCode: "66087"},
	{latitude: 39.341326, longitude: -95.456454, zipCode: "66088"},
	{latitude: 39.787949, longitude: -94.973367, zipCode: "66090"},
	{latitude: 38.17207, longitude: -95.327652, zipCode: "66091"},
	{latitude: 38.688813, longitude: -95.10642, zipCode: "66092"},
	{latitude: 38.20781, longitude: -95.494138, zipCode: "66093"},
	{latitude: 39.959183, longitude: -95.341745, zipCode: "66094"},
	{latitude: 38.441094, longitude: -95.438861, zipCode: "66095"},
	{latitude: 39.328599, longitude: -95.246083, zipCode: "66097"},
	{latitude: 39.118743, longitude: -94.625905, zipCode: "66101"},
	{latitude: 39.108964, longitude: -94.692539, zipCode: "66102"},
	{latitude: 39.060329, longitude: -94.626783, zipCode: "66103"},
	{latitude: 39.150159, longitude: -94.689533, zipCode: "66104"},
	{latitude: 39.086225, longitude: -94.636591, zipCode: "66105"},
	{latitude: 39.069454, longitude: -94.702613, zipCode: "66106"},
	{latitude: 39.165351, longitude: -94.829341, zipCode: "66109"},
	{latitude: 39.084029, longitude: -94.789887, zipCode: "66111"},
	{latitude: 39.114955, longitude: -94.770108, zipCode: "66112"},
	{latitude: 39.148258, longitude: -94.605084, zipCode: "66115"},
	{latitude: 39.100904, longitude: -94.613056, zipCode: "66118"},
	{latitude: 39.055816, longitude: -94.610055, zipCode: "66160"},
	{latitude: 39.02315, longitude: -94.669466, zipCode: "66202"},
	{latitude: 39.020426, longitude: -94.70585, zipCode: "66203"},
	{latitude: 38.990381, longitude: -94.678214, zipCode: "66204"},
	{latitude: 39.031229, longitude: -94.630732, zipCode: "66205"},
	{latitude: 38.958711, longitude: -94.618649, zipCode: "66206"},
	{latitude: 38.955644, longitude: -94.643247, zipCode: "66207"},
	{latitude: 38.997529, longitude: -94.628369, zipCode: "66208"},
	{latitude: 38.900851, longitude: -94.634114, zipCode: "66209"},
	{latitude: 38.926531, longitude: -94.704907, zipCode: "66210"},
	{latitude: 38.92512, longitude: -94.635624, zipCode: "66211"},
	{latitude: 38.955565, longitude: -94.679875, zipCode: "66212"},
	{latitude: 38.899195, longitude: -94.703706, zipCode: "66213"},
	{latitude: 38.963579, longitude: -94.713965, zipCode: "66214"},
	{latitude: 38.9558, longitude: -94.743054, zipCode: "66215"},
	{latitude: 39.013565, longitude: -94.741563, zipCode: "66216"},
	{latitude: 39.013669, longitude: -94.775402, zipCode: "66217"},
	{latitude: 39.012931, longitude: -94.817887, zipCode: "66218"},
	{latitude: 38.953198, longitude: -94.778381, zipCode: "66219"},
	{latitude: 38.959691, longitude: -94.819014, zipCode: "66220"},
	{latitude: 38.846811, longitude: -94.713671, zipCode: "66221"},
	{latitude: 38.861597, longitude: -94.668768, zipCode: "66223"},
	{latitude: 38.862095, longitude: -94.623627, zipCode: "66224"},
	{latitude: 39.030956, longitude: -94.861176, zipCode: "66226"},
	{latitude: 38.972669, longitude: -94.874836, zipCode: "66227"},
	{latitude: 38.917163, longitude: -94.658844, zipCode: "66251"},
	{latitude: 38.932367, longitude: -96.309652, zipCode: "66401"},
	{latitude: 38.914, longitude: -95.843271, zipCode: "66402"},
	{latitude: 39.890613, longitude: -96.270122, zipCode: "66403"},
	{latitude: 39.863837, longitude: -96.172821, zipCode: "66404"},
	{latitude: 39.894106, longitude: -96.420729, zipCode: "66406"},
	{latitude: 39.227879, longitude: -96.185867, zipCode: "66407"},
	{latitude: 39.949593, longitude: -95.975154, zipCode: "66408"},
	{latitude: 38.9192, longitude: -95.555145, zipCode: "66409"},
	{latitude: 39.641998, longitude: -96.643774, zipCode: "66411"},
	{latitude: 39.9078, longitude: -96.777424, zipCode: "66412"},
	{latitude: 38.768875, longitude: -95.880725, zipCode: "66413"},
	{latitude: 38.830778, longitude: -95.685485, zipCode: "66414"},
	{latitude: 39.689412, longitude: -96.137602, zipCode: "66415"},
	{latitude: 39.525706, longitude: -95.86324, zipCode: "66416"},
	{latitude: 39.656551, longitude: -96.037085, zipCode: "66417"},
	{latitude: 39.279496, longitude: -95.933586, zipCode: "66418"},
	{latitude: 39.360679, longitude: -95.598882, zipCode: "66419"},
	{latitude: 39.333975, longitude: -96.083967, zipCode: "66422"},
	{latitude: 38.853961, longitude: -96.1222, zipCode: "66423"},
	{latitude: 39.674689, longitude: -95.404063, zipCode: "66424"},
	{latitude: 39.806734, longitude: -95.721284, zipCode: "66425"},
	{latitude: 39.661283, longitude: -96.438829, zipCode: "66427"},
	{latitude: 39.676116, longitude: -95.931358, zipCode: "66428"},
	{latitude: 39.09377, longitude: -95.534186, zipCode: "66429"},
	{latitude: 38.80593, longitude: -96.002771, zipCode: "66431"},
	{latitude: 39.491388, longitude: -96.077576, zipCode: "66432"},
	{latitude: 39.86304, longitude: -95.554804, zipCode: "66434"},
	{latitude: 39.468312, longitude: -95.70384, zipCode: "66436"},
	{latitude: 39.835044, longitude: -96.510931, zipCode: "66438"},
	{latitude: 39.656331, longitude: -95.540086, zipCode: "66439"},
	{latitude: 39.268181, longitude: -95.676822, zipCode: "66440"},
	{latitude: 38.973521, longitude: -96.803091, zipCode: "66441"},
	{latitude: 39.09167, longitude: -96.790143, zipCode: "66442"},
	{latitude: 39.390138, longitude: -96.856465, zipCode: "66449"},
	{latitude: 38.60326, longitude: -95.673215, zipCode: "66451"},
	{latitude: 39.052914, longitude: -96.236894, zipCode: "66501"},
	{latitude: 39.139945, longitude: -96.528718, zipCode: "66502"},
	{latitude: 39.265014, longitude: -96.69527, zipCode: "66503"},
	{latitude: 39.195974, longitude: -96.581468, zipCode: "66506"},
	{latitude: 39.051133, longitude: -96.030609, zipCode: "66507"},
	{latitude: 39.858566, longitude: -96.644306, zipCode: "66508"},
	{latitude: 39.325448, longitude: -95.78492, zipCode: "66509"},
	{latitude: 38.499626, longitude: -95.610199, zipCode: "66510"},
	{latitude: 39.208241, longitude: -95.541486, zipCode: "66512"},
	{latitude: 39.126231, longitude: -96.875602, zipCode: "66514"},
	{latitude: 39.936249, longitude: -95.703739, zipCode: "66515"},
	{latitude: 39.616501, longitude: -95.720797, zipCode: "66516"},
	{latitude: 39.11092, longitude: -96.702353, zipCode: "66517"},
	{latitude: 39.965606, longitude: -96.550756, zipCode: "66518"},
	{latitude: 39.411721, longitude: -96.611487, zipCode: "66520"},
	{latitude: 39.470744, longitude: -96.229434, zipCode: "66521"},
	{latitude: 39.866131, longitude: -95.938164, zipCode: "66522"},
	{latitude: 38.615774, longitude: -95.833994, zipCode: "66523"},
	{latitude: 38.790939, longitude: -95.506078, zipCode: "66524"},
	{latitude: 39.064612, longitude: -96.162615, zipCode: "66526"},
	{latitude: 39.74697, longitude: -95.68097, zipCode: "66527"},
	{latitude: 38.60287, longitude: -95.537804, zipCode: "66528"},
	{latitude: 39.312395, longitude: -96.838498, zipCode: "66531"},
	{latitude: 39.821429, longitude: -95.377943, zipCode: "66532"},
	{latitude: 39.163187, longitude: -95.947495, zipCode: "66533"},
	{latitude: 39.876714, longitude: -95.836775, zipCode: "66534"},
	{latitude: 39.26703, longitude: -96.433702, zipCode: "66535"},
	{latitude: 39.215441, longitude: -96.0695, zipCode: "66536"},
	{latitude: 38.751242, longitude: -95.719623, zipCode: "66537"},
	{latitude: 39.850557, longitude: -96.044691, zipCode: "66538"},
	{latitude: 39.141967, longitude: -95.84039, zipCode: "66539"},
	{latitude: 39.484787, longitude: -95.970371, zipCode: "66540"},
	{latitude: 39.978561, longitude: -96.343592, zipCode: "66541"},
	{latitude: 39.010744, longitude: -95.544771, zipCode: "66542"},
	{latitude: 38.63957, longitude: -95.599414, zipCode: "66543"},
	{latitude: 39.688855, longitude: -96.273293, zipCode: "66544"},
	{latitude: 38.893955, longitude: -95.72967, zipCode: "66546"},
	{latitude: 39.231558, longitude: -96.295611, zipCode: "66547"},
	{latitude: 39.670854, longitude: -96.762724, zipCode: "66548"},
	{latitude: 39.420581, longitude: -96.435724, zipCode: "66549"},
	{latitude: 39.655442, longitude: -95.820665, zipCode: "66550"},
	{latitude: 39.587682, longitude: -95.629855, zipCode: "66552"},
	{latitude: 39.494814, longitude: -96.790528, zipCode: "66554"},
	{latitude: 39.057532, longitude: -95.674747, zipCode: "66603"},
	{latitude: 39.039022, longitude: -95.726188, zipCode: "66604"},
	{latitude: 39.010828, longitude: -95.638819, zipCode: "66605"},
	{latitude: 39.061436, longitude: -95.719951, zipCode: "66606"},
	{latitude: 39.041205, longitude: -95.635345, zipCode: "66607"},
	{latitude: 39.080837, longitude: -95.663859, zipCode: "66608"},
	{latitude: 38.978222, longitude: -95.660742, zipCode: "66609"},
	{latitude: 38.976726, longitude: -95.844565, zipCode: "66610"},
	{latitude: 39.018756, longitude: -95.696387, zipCode: "66611"},
	{latitude: 39.040224, longitude: -95.680334, zipCode: "66612"},
	{latitude: 39.009926, longitude: -95.837954, zipCode: "66614"},
	{latitude: 39.050826, longitude: -95.897866, zipCode: "66615"},
	{latitude: 39.069817, longitude: -95.623043, zipCode: "66616"},
	{latitude: 39.134832, longitude: -95.623239, zipCode: "66617"},
	{latitude: 39.139508, longitude: -95.748267, zipCode: "66618"},
	{latitude: 38.947079, longitude: -95.690198, zipCode: "66619"},
	{latitude: 39.03312, longitude: -95.70238, zipCode: "66621"},
	{latitude: 39.026315, longitude: -95.722224, zipCode: "66622"},
	{latitude: 37.829814, longitude: -94.758937, zipCode: "66701"},
	{latitude: 37.551598, longitude: -95.624499, zipCode: "66710"},
	{latitude: 37.637096, longitude: -94.685001, zipCode: "66711"},
	{latitude: 37.564876, longitude: -94.714519, zipCode: "66712"},
	{latitude: 37.040492, longitude: -94.785294, zipCode: "66713"},
	{latitude: 37.636602, longitude: -95.698621, zipCode: "66714"},
	{latitude: 37.938576, longitude: -95.038044, zipCode: "66716"},
	{latitude: 37.699465, longitude: -95.681038, zipCode: "66717"},
	{latitude: 37.648326, longitude: -95.457198, zipCode: "66720"},
	{latitude: 37.346978, longitude: -94.856724, zipCode: "66724"},
	{latitude: 37.156583, longitude: -94.895319, zipCode: "66725"},
	{latitude: 37.804211, longitude: -95.192631, zipCode: "66732"},
	{latitude: 37.590295, longitude: -95.254947, zipCode: "66733"},
	{latitude: 37.613816, longitude: -94.819509, zipCode: "66734"},
	{latitude: 37.517994, longitude: -94.715108, zipCode: "66735"},
	{latitude: 37.551477, longitude: -95.861095, zipCode: "66736"},
	{latitude: 38.013752, longitude: -94.723418, zipCode: "66738"},
	{latitude: 37.12578, longitude: -94.655261, zipCode: "66739"},
	{latitude: 37.465762, longitude: -95.371007, zipCode: "66740"},
	{latitude: 37.720033, longitude: -94.663918, zipCode: "66741"},
	{latitude: 37.919584, longitude: -95.348902, zipCode: "66742"},
	{latitude: 37.508867, longitude: -94.879644, zipCode: "66743"},
	{latitude: 37.667983, longitude: -94.952979, zipCode: "66746"},
	{latitude: 37.799844, longitude: -95.436048, zipCode: "66748"},
	{latitude: 37.933761, longitude: -95.415032, zipCode: "66749"},
	{latitude: 37.9296, longitude: -95.289751, zipCode: "66751"},
	{latitude: 37.363794, longitude: -95.016159, zipCode: "66753"},
	{latitude: 38.004856, longitude: -94.885625, zipCode: "66754"},
	{latitude: 37.91177, longitude: -95.168695, zipCode: "66755"},
	{latitude: 37.547537, longitude: -94.641053, zipCode: "66756"},
	{latitude: 37.414353, longitude: -95.698279, zipCode: "66757"},
	{latitude: 38.015934, longitude: -95.586909, zipCode: "66758"},
	{latitude: 37.348316, longitude: -94.624352, zipCode: "66760"},
	{latitude: 37.911039, longitude: -95.570175, zipCode: "66761"},
	{latitude: 37.393878, longitude: -94.710478, zipCode: "66762"},
	{latitude: 37.463236, longitude: -94.697764, zipCode: "66763"},
	{latitude: 38.078779, longitude: -94.709796, zipCode: "66767"},
	{latitude: 37.856527, longitude: -94.889957, zipCode: "66769"},
	{latitude: 37.080735, longitude: -94.715342, zipCode: "66770"},
	{latitude: 37.494851, longitude: -95.145787, zipCode: "66771"},
	{latitude: 37.740201, longitude: -95.187512, zipCode: "66772"},
	{latitude: 37.2594, longitude: -94.80545, zipCode: "66773"},
	{latitude: 37.701935, longitude: -95.148771, zipCode: "66775"},
	{latitude: 37.467579, longitude: -95.497665, zipCode: "66776"},
	{latitude: 37.787226, longitude: -95.975742, zipCode: "66777"},
	{latitude: 37.00045, longitude: -94.83891, zipCode: "66778"},
	{latitude: 37.829395, longitude: -94.965819, zipCode: "66779"},
	{latitude: 37.610325, longitude: -95.055722, zipCode: "66780"},
	{latitude: 37.288583, longitude: -94.733491, zipCode: "66781"},
	{latitude: 37.284955, longitude: -94.926099, zipCode: "66782"},
	{latitude: 37.876775, longitude: -95.733175, zipCode: "66783"},
	{latitude: 38.411743, longitude: -96.223507, zipCode: "66801"},
	{latitude: 38.596073, longitude: -96.091023, zipCode: "66830"},
	{latitude: 38.695591, longitude: -96.195012, zipCode: "66833"},
	{latitude: 38.862121, longitude: -96.455358, zipCode: "66834"},
	{latitude: 38.526914, longitude: -96.266195, zipCode: "66835"},
	{latitude: 38.555437, longitude: -96.800671, zipCode: "66838"},
	{latitude: 38.217399, longitude: -95.748127, zipCode: "66839"},
	{latitude: 38.076924, longitude: -96.876735, zipCode: "66840"},
	{latitude: 38.024842, longitude: -96.657047, zipCode: "66842"},
	{latitude: 38.222479, longitude: -96.740487, zipCode: "66843"},
	{latitude: 38.298731, longitude: -96.508392, zipCode: "66845"},
	{latitude: 38.64016, longitude: -96.473816, zipCode: "66846"},
	{latitude: 38.877505, longitude: -96.603227, zipCode: "66849"},
	{latitude: 38.407683, longitude: -96.712994, zipCode: "66850"},
	{latitude: 38.217863, longitude: -96.918638, zipCode: "66851"},
	{latitude: 38.071642, longitude: -95.895277, zipCode: "66852"},
	{latitude: 38.001895, longitude: -96.235303, zipCode: "66853"},
	{latitude: 38.252132, longitude: -95.988479, zipCode: "66854"},
	{latitude: 38.397806, longitude: -95.811788, zipCode: "66856"},
	{latitude: 38.103409, longitude: -95.633361, zipCode: "66857"},
	{latitude: 38.472506, longitude: -96.92242, zipCode: "66858"},
	{latitude: 38.556888, longitude: -96.964887, zipCode: "66859"},
	{latitude: 38.12728, longitude: -96.165629, zipCode: "66860"},
	{latitude: 38.364105, longitude: -97.006364, zipCode: "66861"},
	{latitude: 38.154999, longitude: -96.556128, zipCode: "66862"},
	{latitude: 37.833687, longitude: -96.076297, zipCode: "66863"},
	{latitude: 38.354887, longitude: -95.981495, zipCode: "66864"},
	{latitude: 38.208888, longitude: -96.274533, zipCode: "66865"},
	{latitude: 38.174049, longitude: -97.103198, zipCode: "66866"},
	{latitude: 38.544773, longitude: -95.979419, zipCode: "66868"},
	{latitude: 38.46881, longitude: -96.515162, zipCode: "66869"},
	{latitude: 37.932517, longitude: -95.976085, zipCode: "66870"},
	{latitude: 38.362643, longitude: -95.610225, zipCode: "66871"},
	{latitude: 38.798958, longitude: -96.763708, zipCode: "66872"},
	{latitude: 38.612773, longitude: -96.666147, zipCode: "66873"},
	{latitude: 39.560035, longitude: -97.63895, zipCode: "66901"},
	{latitude: 39.704618, longitude: -97.490688, zipCode: "66930"},
	{latitude: 39.779487, longitude: -98.930885, zipCode: "66932"},
	{latitude: 39.675419, longitude: -96.872319, zipCode: "66933"},
	{latitude: 39.826457, longitude: -97.635469, zipCode: "66935"},
	{latitude: 39.918731, longitude: -98.301928, zipCode: "66936"},
	{latitude: 39.599827, longitude: -97.26481, zipCode: "66937"},
	{latitude: 39.589651, longitude: -97.444346, zipCode: "66938"},
	{latitude: 39.85286, longitude: -97.90429, zipCode: "66939"},
	{latitude: 39.802418, longitude: -97.453186, zipCode: "66940"},
	{latitude: 39.830775, longitude: -98.447992, zipCode: "66941"},
	{latitude: 39.7908, longitude: -97.997936, zipCode: "66942"},
	{latitude: 39.671811, longitude: -96.966281, zipCode: "66943"},
	{latitude: 39.840229, longitude: -97.293099, zipCode: "66944"},
	{latitude: 39.88701, longitude: -96.878386, zipCode: "66945"},
	{latitude: 39.967939, longitude: -96.998839, zipCode: "66946"},
	{latitude: 39.59825, longitude: -97.852349, zipCode: "66948"},
	{latitude: 39.644677, longitude: -98.205176, zipCode: "66949"},
	{latitude: 39.864211, longitude: -99.037761, zipCode: "66951"},
	{latitude: 39.835805, longitude: -98.604785, zipCode: "66952"},
	{latitude: 39.679524, longitude: -97.117817, zipCode: "66953"},
	{latitude: 39.980007, longitude: -97.32062, zipCode: "66955"},
	{latitude: 39.796509, longitude: -98.215629, zipCode: "66956"},
	{latitude: 39.889446, longitude: -97.177186, zipCode: "66958"},
	{latitude: 39.922959, longitude: -97.529682, zipCode: "66959"},
	{latitude: 39.935732, longitude: -97.42164, zipCode: "66960"},
	{latitude: 39.693369, longitude: -97.7756, zipCode: "66961"},
	{latitude: 39.599322, longitude: -97.114331, zipCode: "66962"},
	{latitude: 39.641884, longitude: -98.014288, zipCode: "66963"},
	{latitude: 39.934388, longitude: -97.793764, zipCode: "66964"},
	{latitude: 39.783664, longitude: -97.762232, zipCode: "66966"},
	{latitude: 39.819725, longitude: -98.794227, zipCode: "66967"},
	{latitude: 39.835272, longitude: -97.063088, zipCode: "66968"},
	{latitude: 39.950165, longitude: -98.016856, zipCode: "66970"},
	{latitude: 37.757001, longitude: -97.635437, zipCode: "67001"},
	{latitude: 37.700824, longitude: -97.106918, zipCode: "67002"},
	{latitude: 37.104737, longitude: -98.05679, zipCode: "67003"},
	{latitude: 37.281587, longitude: -97.762919, zipCode: "67004"},
	{latitude: 37.068836, longitude: -96.995926, zipCode: "67005"},
	{latitude: 37.488584, longitude: -96.752899, zipCode: "67008"},
	{latitude: 37.225287, longitude: -98.268794, zipCode: "67009"},
	{latitude: 37.655436, longitude: -96.976049, zipCode: "67010"},
	{latitude: 37.657681, longitude: -96.558732, zipCode: "67012"},
	{latitude: 37.381869, longitude: -97.26822, zipCode: "67013"},
	{latitude: 37.888815, longitude: -97.518173, zipCode: "67016"},
	{latitude: 37.827762, longitude: -97.111646, zipCode: "67017"},
	{latitude: 37.071269, longitude: -97.853695, zipCode: "67018"},
	{latitude: 37.314492, longitude: -96.784487, zipCode: "67019"},
	{latitude: 38.007932, longitude: -97.675907, zipCode: "67020"},
	{latitude: 37.776118, longitude: -98.910519, zipCode: "67021"},
	{latitude: 37.076932, longitude: -97.626852, zipCode: "67022"},
	{latitude: 37.346062, longitude: -96.592276, zipCode: "67023"},
	{latitude: 37.11281, longitude: -96.492173, zipCode: "67024"},
	{latitude: 37.642355, longitude: -97.78496, zipCode: "67025"},
	{latitude: 37.511055, longitude: -97.491983, zipCode: "67026"},
	{latitude: 37.476462, longitude: -98.97979, zipCode: "67028"},
	{latitude: 37.171432, longitude: -99.279961, zipCode: "67029"},
	{latitude: 37.785616, longitude: -97.546808, zipCode: "67030"},
	{latitude: 37.410484, longitude: -97.63512, zipCode: "67031"},
	{latitude: 37.648184, longitude: -98.394963, zipCode: "67035"},
	{latitude: 37.289848, longitude: -97.882621, zipCode: "67036"},
	{latitude: 37.571832, longitude: -97.217235, zipCode: "67037"},
	{latitude: 37.120165, longitude: -96.680273, zipCode: "67038"},
	{latitude: 37.527668, longitude: -96.97429, zipCode: "67039"},
	{latitude: 38.054094, longitude: -97.127536, zipCode: "67041"},
	{latitude: 37.857264, longitude: -96.780718, zipCode: "67042"},
	{latitude: 37.854518, longitude: -96.322318, zipCode: "67045"},
	{latitude: 37.639619, longitude: -96.0503, zipCode: "67047"},
	{latitude: 37.170582, longitude: -97.846447, zipCode: "67049"},
	{latitude: 37.674517, longitude: -97.67745, zipCode: "67050"},
	{latitude: 37.089302, longitude: -97.196565, zipCode: "67051"},
	{latitude: 37.657727, longitude: -97.574242, zipCode: "67052"},
	{latitude: 38.247072, longitude: -97.345742, zipCode: "67053"},
	{latitude: 37.57504, longitude: -99.316072, zipCode: "67054"},
	{latitude: 37.783374, longitude: -97.203149, zipCode: "67055"},
	{latitude: 38.019344, longitude: -97.523472, zipCode: "67056"},
	{latitude: 37.07054, longitude: -98.780083, zipCode: "67057"},
	{latitude: 37.317916, longitude: -98.061352, zipCode: "67058"},
	{latitude: 37.658966, longitude: -99.097013, zipCode: "67059"},
	{latitude: 37.542973, longitude: -97.363698, zipCode: "67060"},
	{latitude: 37.082321, longitude: -98.377237, zipCode: "67061"},
	{latitude: 38.143105, longitude: -97.432958, zipCode: "67062"},
	{latitude: 38.340735, longitude: -97.233749, zipCode: "67063"},
	{latitude: 37.478079, longitude: -98.528675, zipCode: "67065"},
	{latitude: 37.773726, longitude: -98.757991, zipCode: "67066"},
	{latitude: 37.794756, longitude: -97.26646, zipCode: "67067"},
	{latitude: 37.621851, longitude: -98.119542, zipCode: "67068"},
	{latitude: 37.072489, longitude: -98.553014, zipCode: "67070"},
	{latitude: 37.208622, longitude: -98.889662, zipCode: "67071"},
	{latitude: 37.535234, longitude: -96.627995, zipCode: "67072"},
	{latitude: 38.389068, longitude: -97.320426, zipCode: "67073"},
	{latitude: 37.670184, longitude: -96.736046, zipCode: "67074"},
	{latitude: 37.785277, longitude: -97.464182, zipCode: "67101"},
	{latitude: 37.027431, longitude: -96.799812, zipCode: "67102"},
	{latitude: 37.266039, longitude: -97.555077, zipCode: "67103"},
	{latitude: 37.265078, longitude: -98.664433, zipCode: "67104"},
	{latitude: 37.219989, longitude: -97.682944, zipCode: "67105"},
	{latitude: 37.463347, longitude: -97.758054, zipCode: "67106"},
	{latitude: 38.200195, longitude: -97.542678, zipCode: "67107"},
	{latitude: 37.816344, longitude: -97.690681, zipCode: "67108"},
	{latitude: 37.561275, longitude: -99.485817, zipCode: "67109"},
	{latitude: 37.469649, longitude: -97.223627, zipCode: "67110"},
	{latitude: 37.615011, longitude: -97.937844, zipCode: "67111"},
	{latitude: 37.452778, longitude: -98.418891, zipCode: "67112"},
	{latitude: 38.067626, longitude: -97.291649, zipCode: "67114"},
	{latitude: 38.074214, longitude: -97.346242, zipCode: "67117"},
	{latitude: 37.481665, longitude: -97.854212, zipCode: "67118"},
	{latitude: 37.23869, longitude: -97.176546, zipCode: "67119"},
	{latitude: 37.468085, longitude: -97.37377, zipCode: "67120"},
	{latitude: 37.619001, longitude: -96.428116, zipCode: "67122"},
	{latitude: 37.948316, longitude: -96.996293, zipCode: "67123"},
	{latitude: 37.634439, longitude: -98.773642, zipCode: "67124"},
	{latitude: 37.16482, longitude: -99.487114, zipCode: "67127"},
	{latitude: 37.425145, longitude: -96.9469, zipCode: "67131"},
	{latitude: 37.850881, longitude: -96.584695, zipCode: "67132"},
	{latitude: 37.577615, longitude: -97.107094, zipCode: "67133"},
	{latitude: 37.476985, longitude: -98.67207, zipCode: "67134"},
	{latitude: 37.914249, longitude: -97.453602, zipCode: "67135"},
	{latitude: 37.631501, longitude: -96.227605, zipCode: "67137"},
	{latitude: 37.260232, longitude: -98.419614, zipCode: "67138"},
	{latitude: 37.079884, longitude: -97.357466, zipCode: "67140"},
	{latitude: 37.452585, longitude: -98.11362, zipCode: "67142"},
	{latitude: 37.319166, longitude: -98.974517, zipCode: "67143"},
	{latitude: 37.81917, longitude: -97.003301, zipCode: "67144"},
	{latitude: 37.384475, longitude: -97.104616, zipCode: "67146"},
	{latitude: 37.865125, longitude: -97.305039, zipCode: "67147"},
	{latitude: 37.539732, longitude: -97.625937, zipCode: "67149"},
	{latitude: 37.028169, longitude: -98.197912, zipCode: "67150"},
	{latitude: 38.142146, longitude: -97.257065, zipCode: "67151"},
	{latitude: 37.263003, longitude: -97.411479, zipCode: "67152"},
	{latitude: 37.963538, longitude: -97.122174, zipCode: "67154"},
	{latitude: 37.274948, longitude: -99.137561, zipCode: "67155"},
	{latitude: 37.248449, longitude: -96.963204, zipCode: "67156"},
	{latitude: 37.427657, longitude: -98.309416, zipCode: "67159"},
	{latitude: 37.686984, longitude: -97.33492, zipCode: "67202"},
	{latitude: 37.698891, longitude: -97.364641, zipCode: "67203"},
	{latitude: 37.762497, longitude: -97.358547, zipCode: "67204"},
	{latitude: 37.749466, longitude: -97.423567, zipCode: "67205"},
	{latitude: 37.703734, longitude: -97.224505, zipCode: "67206"},
	{latitude: 37.665927, longitude: -97.230236, zipCode: "67207"},
	{latitude: 37.703847, longitude: -97.279266, zipCode: "67208"},
	{latitude: 37.651448, longitude: -97.42819, zipCode: "67209"},
	{latitude: 37.642884, longitude: -97.210096, zipCode: "67210"},
	{latitude: 37.667063, longitude: -97.317043, zipCode: "67211"},
	{latitude: 37.702404, longitude: -97.438899, zipCode: "67212"},
	{latitude: 37.667472, longitude: -97.364129, zipCode: "67213"},
	{latitude: 37.707178, longitude: -97.31775, zipCode: "67214"},
	{latitude: 37.61587, longitude: -97.435032, zipCode: "67215"},
	{latitude: 37.611241, longitude: -97.312617, zipCode: "67216"},
	{latitude: 37.617773, longitude: -97.362058, zipCode: "67217"},
	{latitude: 37.668511, longitude: -97.280361, zipCode: "67218"},
	{latitude: 37.769251, longitude: -97.316284, zipCode: "67219"},
	{latitude: 37.751156, longitude: -97.278361, zipCode: "67220"},
	{latitude: 37.635429, longitude: -97.258223, zipCode: "67221"},
	{latitude: 37.742442, longitude: -97.495566, zipCode: "67223"},
	{latitude: 37.768695, longitude: -97.220336, zipCode: "67226"},
	{latitude: 37.626915, longitude: -97.501132, zipCode: "67227"},
	{latitude: 37.777903, longitude: -97.180625, zipCode: "67228"},
	{latitude: 37.681467, longitude: -97.167833, zipCode: "67230"},
	{latitude: 37.643807, longitude: -97.162328, zipCode: "67232"},
	{latitude: 37.688993, longitude: -97.500046, zipCode: "67235"},
	{latitude: 37.719325, longitude: -97.293383, zipCode: "67260"},
	{latitude: 37.20823, longitude: -95.756081, zipCode: "67301"},
	{latitude: 37.158055, longitude: -95.309148, zipCode: "67330"},
	{latitude: 37.043368, longitude: -95.236567, zipCode: "67332"},
	{latitude: 37.043544, longitude: -95.865924, zipCode: "67333"},
	{latitude: 37.023897, longitude: -96.177144, zipCode: "67334"},
	{latitude: 37.274582, longitude: -95.526745, zipCode: "67335"},
	{latitude: 37.069267, longitude: -95.068849, zipCode: "67336"},
	{latitude: 37.053665, longitude: -95.598198, zipCode: "67337"},
	{latitude: 37.059513, longitude: -95.712198, zipCode: "67340"},
	{latitude: 37.34437, longitude: -95.428356, zipCode: "67341"},
	{latitude: 37.060121, longitude: -95.355286, zipCode: "67342"},
	{latitude: 37.287469, longitude: -95.953898, zipCode: "67344"},
	{latitude: 37.370251, longitude: -96.20041, zipCode: "67345"},
	{latitude: 37.321407, longitude: -96.448189, zipCode: "67346"},
	{latitude: 37.154402, longitude: -95.951547, zipCode: "67347"},
	{latitude: 37.495978, longitude: -96.280486, zipCode: "67349"},
	{latitude: 37.143556, longitude: -95.568937, zipCode: "67351"},
	{latitude: 37.333195, longitude: -96.090692, zipCode: "67352"},
	{latitude: 37.308381, longitude: -96.317193, zipCode: "67353"},
	{latitude: 37.206453, longitude: -95.411526, zipCode: "67354"},
	{latitude: 37.03167, longitude: -96.00584, zipCode: "67355"},
	{latitude: 37.176257, longitude: -95.145772, zipCode: "67356"},
	{latitude: 37.338103, longitude: -95.253064, zipCode: "67357"},
	{latitude: 37.038024, longitude: -96.106561, zipCode: "67360"},
	{latitude: 37.137644, longitude: -96.217607, zipCode: "67361"},
	{latitude: 37.332888, longitude: -95.719728, zipCode: "67363"},
	{latitude: 37.038569, longitude: -95.824277, zipCode: "67364"},
	{latitude: 38.831832, longitude: -97.65455, zipCode: "67401"},
	{latitude: 38.956476, longitude: -97.21909, zipCode: "67410"},
	{latitude: 38.670931, longitude: -97.564987, zipCode: "67416"},
	{latitude: 39.433969, longitude: -97.545581, zipCode: "67417"},
	{latitude: 39.18491, longitude: -98.051868, zipCode: "67418"},
	{latitude: 39.400935, longitude: -98.085468, zipCode: "67420"},
	{latitude: 39.032152, longitude: -97.59933, zipCode: "67422"},
	{latitude: 38.96562, longitude: -97.974945, zipCode: "67423"},
	{latitude: 38.782025, longitude: -97.907988, zipCode: "67425"},
	{latitude: 38.498463, longitude: -98.399346, zipCode: "67427"},
	{latitude: 38.380425, longitude: -97.417317, zipCode: "67428"},
	{latitude: 39.575518, longitude: -98.442139, zipCode: "67430"},
	{latitude: 38.951046, longitude: -97.012425, zipCode: "67431"},
	{latitude: 39.330815, longitude: -97.181145, zipCode: "67432"},
	{latitude: 39.280781, longitude: -97.73046, zipCode: "67436"},
	{latitude: 39.506757, longitude: -98.557352, zipCode: "67437"},
	{latitude: 38.501985, longitude: -97.272112, zipCode: "67438"},
	{latitude: 38.759762, longitude: -98.221114, zipCode: "67439"},
	{latitude: 38.878591, longitude: -97.102832, zipCode: "67441"},
	{latitude: 38.646058, longitude: -97.775762, zipCode: "67442"},
	{latitude: 38.380609, longitude: -97.525769, zipCode: "67443"},
	{latitude: 38.551066, longitude: -98.11842, zipCode: "67444"},
	{latitude: 39.371384, longitude: -97.824151, zipCode: "67445"},
	{latitude: 39.461302, longitude: -98.325251, zipCode: "67446"},
	{latitude: 39.474587, longitude: -96.968674, zipCode: "67447"},
	{latitude: 38.664369, longitude: -97.402645, zipCode: "67448"},
	{latitude: 38.682946, longitude: -96.896947, zipCode: "67449"},
	{latitude: 38.626311, longitude: -98.457346, zipCode: "67450"},
	{latitude: 38.700938, longitude: -97.132001, zipCode: "67451"},
	{latitude: 39.241286, longitude: -98.368356, zipCode: "67452"},
	{latitude: 38.69884, longitude: -98.112105, zipCode: "67454"},
	{latitude: 39.028528, longitude: -98.162663, zipCode: "67455"},
	{latitude: 38.59624, longitude: -97.680439, zipCode: "67456"},
	{latitude: 38.38547, longitude: -97.987741, zipCode: "67457"},
	{latitude: 39.167764, longitude: -97.311485, zipCode: "67458"},
	{latitude: 38.603854, longitude: -98.345792, zipCode: "67459"},
	{latitude: 38.382552, longitude: -97.699453, zipCode: "67460"},
	{latitude: 38.55862, longitude: -97.897299, zipCode: "67464"},
	{latitude: 39.327815, longitude: -97.472357, zipCode: "67466"},
	{latitude: 39.142355, longitude: -97.69582, zipCode: "67467"},
	{latitude: 39.459362, longitude: -97.25749, zipCode: "67468"},
	{latitude: 38.922721, longitude: -97.507633, zipCode: "67470"},
	{latitude: 39.378657, longitude: -98.740571, zipCode: "67473"},
	{latitude: 39.595779, longitude: -98.701985, zipCode: "67474"},
	{latitude: 38.589659, longitude: -97.055151, zipCode: "67475"},
	{latitude: 38.544278, longitude: -97.436217, zipCode: "67476"},
	{latitude: 39.385696, longitude: -97.949921, zipCode: "67478"},
	{latitude: 38.965807, longitude: -97.400502, zipCode: "67480"},
	{latitude: 39.012909, longitude: -98.388594, zipCode: "67481"},
	{latitude: 39.026891, longitude: -97.259713, zipCode: "67482"},
	{latitude: 38.558165, longitude: -97.198369, zipCode: "67483"},
	{latitude: 38.995746, longitude: -97.850499, zipCode: "67484"},
	{latitude: 39.315729, longitude: -98.497175, zipCode: "67485"},
	{latitude: 39.172892, longitude: -97.064316, zipCode: "67487"},
	{latitude: 38.803346, longitude: -98.444885, zipCode: "67490"},
	{latitude: 38.383354, longitude: -97.901877, zipCode: "67491"},
	{latitude: 38.819721, longitude: -96.959508, zipCode: "67492"},
	{latitude: 37.977493, longitude: -97.926452, zipCode: "67501"},
	{latitude: 38.126714, longitude: -97.929805, zipCode: "67502"},
	{latitude: 38.031355, longitude: -97.9458, zipCode: "67505"},
	{latitude: 37.992123, longitude: -98.206508, zipCode: "67510"},
	{latitude: 38.433638, longitude: -99.050582, zipCode: "67511"},
	{latitude: 38.239061, longitude: -98.3351, zipCode: "67512"},
	{latitude: 38.445112, longitude: -99.538302, zipCode: "67513"},
	{latitude: 37.861953, longitude: -98.202381, zipCode: "67514"},
	{latitude: 38.599313, longitude: -100.065329, zipCode: "67515"},
	{latitude: 38.392958, longitude: -99.686778, zipCode: "67516"},
	{latitude: 38.403991, longitude: -100.17337, zipCode: "67518"},
	{latitude: 37.935674, longitude: -99.102922, zipCode: "67519"},
	{latitude: 38.589677, longitude: -99.187712, zipCode: "67520"},
	{latitude: 38.628167, longitude: -99.712902, zipCode: "67521"},
	{latitude: 38.122196, longitude: -97.747612, zipCode: "67522"},
	{latitude: 38.193103, longitude: -99.565606, zipCode: "67523"},
	{latitude: 38.384234, longitude: -98.378211, zipCode: "67524"},
	{latitude: 38.572533, longitude: -98.563033, zipCode: "67525"},
	{latitude: 38.297376, longitude: -98.544467, zipCode: "67526"},
	{latitude: 38.076237, longitude: -99.254408, zipCode: "67529"},
	{latitude: 38.352915, longitude: -98.798117, zipCode: "67530"},
	{latitude: 37.890194, longitude: -97.8023, zipCode: "67543"},
	{latitude: 38.569827, longitude: -98.750563, zipCode: "67544"},
	{latitude: 38.180598, longitude: -98.647621, zipCode: "67545"},
	{latitude: 38.224507, longitude: -97.817285, zipCode: "67546"},
	{latitude: 37.938109, longitude: -99.472318, zipCode: "67547"},
	{latitude: 38.591491, longitude: -99.333997, zipCode: "67548"},
	{latitude: 38.179706, longitude: -99.126865, zipCode: "67550"},
	{latitude: 37.860545, longitude: -99.236466, zipCode: "67552"},
	{latitude: 38.654479, longitude: -99.320027, zipCode: "67553"},
	{latitude: 38.371059, longitude: -98.184884, zipCode: "67554"},
	{latitude: 38.604619, longitude: -99.520035, zipCode: "67556"},
	{latitude: 37.919958, longitude: -98.958293, zipCode: "67557"},
	{latitude: 38.372351, longitude: -99.458927, zipCode: "67559"},
	{latitude: 38.399335, longitude: -99.939209, zipCode: "67560"},
	{latitude: 38.109017, longitude: -98.096853, zipCode: "67561"},
	{latitude: 37.848214, longitude: -99.555359, zipCode: "67563"},
	{latitude: 38.529013, longitude: -98.935649, zipCode: "67564"},
	{latitude: 38.600591, longitude: -99.044062, zipCode: "67565"},
	{latitude: 37.948658, longitude: -98.095317, zipCode: "67566"},
	{latitude: 38.264735, longitude: -98.971998, zipCode: "67567"},
	{latitude: 38.020901, longitude: -98.316736, zipCode: "67568"},
	{latitude: 37.787407, longitude: -97.964176, zipCode: "67570"},
	{latitude: 38.689472, longitude: -99.917014, zipCode: "67572"},
	{latitude: 38.264522, longitude: -98.412196, zipCode: "67573"},
	{latitude: 38.226164, longitude: -99.396759, zipCode: "67574"},
	{latitude: 38.401956, longitude: -99.256489, zipCode: "67575"},
	{latitude: 38.035977, longitude: -98.795217, zipCode: "67576"},
	{latitude: 37.969088, longitude: -98.586679, zipCode: "67578"},
	{latitude: 38.188579, longitude: -98.235601, zipCode: "67579"},
	{latitude: 37.991713, longitude: -98.408385, zipCode: "67581"},
	{latitude: 37.799311, longitude: -98.474937, zipCode: "67583"},
	{latitude: 38.681972, longitude: -100.163736, zipCode: "67584"},
	{latitude: 38.873553, longitude: -99.34086, zipCode: "67601"},
	{latitude: 39.827807, longitude: -99.128505, zipCode: "67621"},
	{latitude: 39.904321, longitude: -99.726987, zipCode: "67622"},
	{latitude: 39.444471, longitude: -98.954264, zipCode: "67623"},
	{latitude: 39.389004, longitude: -99.685595, zipCode: "67625"},
	{latitude: 38.88157, longitude: -98.716946, zipCode: "67626"},
	{latitude: 38.922235, longitude: -99.213448, zipCode: "67627"},
	{latitude: 39.627384, longitude: -99.001622, zipCode: "67628"},
	{latitude: 39.699951, longitude: -100.199353, zipCode: "67629"},
	{latitude: 39.009136, longitude: -100.081392, zipCode: "67631"},
	{latitude: 39.329657, longitude: -99.581063, zipCode: "67632"},
	{latitude: 38.823292, longitude: -98.604391, zipCode: "67634"},
	{latitude: 39.600342, longitude: -100.451227, zipCode: "67635"},
	{latitude: 38.94012, longitude: -99.589967, zipCode: "67637"},
	{latitude: 39.632689, longitude: -98.847107, zipCode: "67638"},
	{latitude: 39.62452, longitude: -99.318496, zipCode: "67639"},
	{latitude: 38.896752, longitude: -99.046483, zipCode: "67640"},
	{latitude: 39.368629, longitude: -99.83097, zipCode: "67642"},
	{latitude: 39.642956, longitude: -100.288609, zipCode: "67643"},
	{latitude: 39.640667, longitude: -99.146689, zipCode: "67644"},
	{latitude: 39.60201, longitude: -99.894163, zipCode: "67645"},
	{latitude: 39.617594, longitude: -99.594174, zipCode: "67646"},
	{latitude: 39.936787, longitude: -99.526067, zipCode: "67647"},
	{latitude: 39.087245, longitude: -98.58037, zipCode: "67648"},
	{latitude: 39.140977, longitude: -98.688549, zipCode: "67649"},
	{latitude: 39.37267, longitude: -100.102673, zipCode: "67650"},
	{latitude: 39.205783, longitude: -99.03991, zipCode: "67651"},
	{latitude: 39.9067, longitude: -100.208054, zipCode: "67653"},
	{latitude: 39.835348, longitude: -99.954228, zipCode: "67654"},
	{latitude: 38.980998, longitude: -99.754596, zipCode: "67656"},
	{latitude: 39.212621, longitude: -99.609557, zipCode: "67657"},
	{latitude: 39.115239, longitude: -98.934375, zipCode: "67658"},
	{latitude: 39.322801, longitude: -100.005362, zipCode: "67659"},
	{latitude: 38.717542, longitude: -99.148098, zipCode: "67660"},
	{latitude: 39.820462, longitude: -99.33761, zipCode: "67661"},
	{latitude: 39.212155, longitude: -99.334632, zipCode: "67663"},
	{latitude: 39.818077, longitude: -99.583111, zipCode: "67664"},
	{latitude: 38.849085, longitude: -98.88253, zipCode: "67665"},
	{latitude: 38.712895, longitude: -99.332255, zipCode: "67667"},
	{latitude: 39.458229, longitude: -99.34415, zipCode: "67669"},
	{latitude: 38.874863, longitude: -99.141414, zipCode: "67671"},
	{latitude: 38.993852, longitude: -99.905192, zipCode: "67672"},
	{latitude: 39.16978, longitude: -98.814055, zipCode: "67673"},
	{latitude: 38.888967, longitude: -99.096496, zipCode: "67674"},
	{latitude: 39.409508, longitude: -99.089312, zipCode: "67675"},
	{latitude: 39.394217, longitude: -101.050067, zipCode: "67701"},
	{latitude: 39.808975, longitude: -101.111247, zipCode: "67730"},
	{latitude: 39.765932, longitude: -101.537318, zipCode: "67731"},
	{latitude: 39.3744, longitude: -101.351567, zipCode: "67732"},
	{latitude: 39.329761, longitude: -101.506468, zipCode: "67733"},
	{latitude: 39.542948, longitude: -100.88884, zipCode: "67734"},
	{latitude: 39.346293, longitude: -101.773854, zipCode: "67735"},
	{latitude: 38.846256, longitude: -100.448783, zipCode: "67736"},
	{latitude: 39.088682, longitude: -100.493179, zipCode: "67737"},
	{latitude: 38.999341, longitude: -100.650512, zipCode: "67738"},
	{latitude: 39.878452, longitude: -100.812808, zipCode: "67739"},
	{latitude: 39.356281, longitude: -100.364317, zipCode: "67740"},
	{latitude: 39.38688, longitude: -102.0048, zipCode: "67741"},
	{latitude: 39.388082, longitude: -101.241257, zipCode: "67743"},
	{latitude: 39.860825, longitude: -100.941108, zipCode: "67744"},
	{latitude: 39.807589, longitude: -101.345391, zipCode: "67745"},
	{latitude: 39.042059, longitude: -101.061021, zipCode: "67747"},
	{latitude: 39.070273, longitude: -100.859845, zipCode: "67748"},
	{latitude: 39.834858, longitude: -100.562519, zipCode: "67749"},
	{latitude: 39.117291, longitude: -100.35627, zipCode: "67751"},
	{latitude: 38.99481, longitude: -100.232863, zipCode: "67752"},
	{latitude: 39.432606, longitude: -100.747899, zipCode: "67753"},
	{latitude: 39.797204, longitude: -101.836735, zipCode: "67756"},
	{latitude: 39.552908, longitude: -100.617249, zipCode: "67757"},
	{latitude: 38.897753, longitude: -101.778701, zipCode: "67758"},
	{latitude: 38.951098, longitude: -101.526474, zipCode: "67761"},
	{latitude: 38.841393, longitude: -102.000884, zipCode: "67762"},
	{latitude: 38.870091, longitude: -101.157571, zipCode: "67764"},
	{latitude: 37.730309, longitude: -100.060028, zipCode: "67801"},
	{latitude: 37.185621, longitude: -99.79598, zipCode: "67831"},
	{latitude: 37.534619, longitude: -99.620072, zipCode: "67834"},
	{latitude: 37.986646, longitude: -100.309167, zipCode: "67835"},
	{latitude: 38.03961, longitude: -101.994562, zipCode: "67836"},
	{latitude: 37.554824, longitude: -100.675137, zipCode: "67837"},
	{latitude: 38.058041, longitude: -101.133767, zipCode: "67838"},
	{latitude: 38.470501, longitude: -100.441624, zipCode: "67839"},
	{latitude: 37.052337, longitude: -99.952272, zipCode: "67840"},
	{latitude: 37.628481, longitude: -100.225933, zipCode: "67841"},
	{latitude: 37.547883, longitude: -99.765317, zipCode: "67842"},
	{latitude: 37.730253, longitude: -99.93724, zipCode: "67843"},
	{latitude: 37.376942, longitude: -100.197149, zipCode: "67844"},
	{latitude: 38.026003, longitude: -100.778949, zipCode: "67846"},
	{latitude: 38.160097, longitude: -99.72895, zipCode: "67849"},
	{latitude: 38.631411, longitude: -100.638969, zipCode: "67850"},
	{latitude: 38.089153, longitude: -101.015668, zipCode: "67851"},
	{latitude: 37.885578, longitude: -100.528171, zipCode: "67853"},
	{latitude: 38.100966, longitude: -100.001318, zipCode: "67854"},
	{latitude: 37.540963, longitude: -101.698775, zipCode: "67855"},
	{latitude: 37.993894, longitude: -101.547801, zipCode: "67857"},
	{latitude: 37.235142, longitude: -100.770135, zipCode: "67859"},
	{latitude: 38.022313, longitude: -101.299594, zipCode: "67860"},
	{latitude: 38.473999, longitude: -101.390429, zipCode: "67861"},
	{latitude: 37.488076, longitude: -101.952538, zipCode: "67862"},
	{latitude: 38.565208, longitude: -101.208173, zipCode: "67863"},
	{latitude: 37.176517, longitude: -100.31727, zipCode: "67864"},
	{latitude: 37.435511, longitude: -99.96837, zipCode: "67865"},
	{latitude: 37.56612, longitude: -100.455678, zipCode: "67867"},
	{latitude: 37.820265, longitude: -100.710263, zipCode: "67868"},
	{latitude: 37.249646, longitude: -100.56255, zipCode: "67869"},
	{latitude: 37.529415, longitude: -101.021561, zipCode: "67870"},
	{latitude: 38.492375, longitude: -100.916966, zipCode: "67871"},
	{latitude: 37.862844, longitude: -99.743464, zipCode: "67876"},
	{latitude: 37.554925, longitude: -100.843926, zipCode: "67877"},
	{latitude: 38.0048, longitude: -101.810208, zipCode: "67878"},
	{latitude: 38.470775, longitude: -101.794586, zipCode: "67879"},
	{latitude: 37.598434, longitude: -101.322308, zipCode: "67880"},
	{latitude: 37.804181, longitude: -99.879885, zipCode: "67882"},
	{latitude: 37.097866, longitude: -100.895222, zipCode: "67901"},
	{latitude: 37.062774, longitude: -101.867684, zipCode: "67950"},
	{latitude: 37.161192, longitude: -101.328612, zipCode: "67951"},
	{latitude: 37.293085, longitude: -101.122238, zipCode: "67952"},
	{latitude: 37.221665, longitude: -101.85485, zipCode: "67953"},
	{latitude: 37.178005, longitude: -101.619073, zipCode: "67954"},
	{latitude: 41.33371, longitude: -96.954903, zipCode: "68001"},
	{latitude: 41.499022, longitude: -96.337716, zipCode: "68002"},
	{latitude: 41.069795, longitude: -96.38148, zipCode: "68003"},
	{latitude: 42.009736, longitude: -96.623048, zipCode: "68004"},
	{latitude: 41.141142, longitude: -95.894004, zipCode: "68005"},
	{latitude: 41.37344, longitude: -96.184378, zipCode: "68007"},
	{latitude: 41.540632, longitude: -96.169877, zipCode: "68008"},
	{latitude: 41.257232, longitude: -96.133503, zipCode: "68010"},
	{latitude: 41.282697, longitude: -96.963094, zipCode: "68014"},
	{latitude: 41.386614, longitude: -96.649718, zipCode: "68015"},
	{latitude: 41.021841, longitude: -96.10172, zipCode: "68016"},
	{latitude: 41.057884, longitude: -96.662056, zipCode: "68017"},
	{latitude: 41.302097, longitude: -96.615195, zipCode: "68018"},
	{latitude: 41.781019, longitude: -96.375352, zipCode: "68019"},
	{latitude: 41.971179, longitude: -96.264175, zipCode: "68020"},
	{latitude: 41.277405, longitude: -96.246406, zipCode: "68022"},
	{latitude: 41.470121, longitude: -96.022357, zipCode: "68023"},
	{latitude: 41.437899, longitude: -96.491294, zipCode: "68025"},
	{latitude: 41.106888, longitude: -96.261771, zipCode: "68028"},
	{latitude: 41.657003, longitude: -96.273537, zipCode: "68029"},
	{latitude: 42.319604, longitude: -96.461936, zipCode: "68030"},
	{latitude: 41.63955, longitude: -96.533211, zipCode: "68031"},
	{latitude: 41.129405, longitude: -96.524848, zipCode: "68033"},
	{latitude: 41.451684, longitude: -96.227542, zipCode: "68034"},
	{latitude: 41.387375, longitude: -96.952345, zipCode: "68036"},
	{latitude: 40.983506, longitude: -96.130047, zipCode: "68037"},
	{latitude: 41.948591, longitude: -96.434638, zipCode: "68038"},
	{latitude: 42.113604, longitude: -96.340497, zipCode: "68039"},
	{latitude: 41.287287, longitude: -96.740022, zipCode: "68040"},
	{latitude: 41.241423, longitude: -96.493389, zipCode: "68041"},
	{latitude: 41.093728, longitude: -96.436419, zipCode: "68042"},
	{latitude: 41.547591, longitude: -96.448311, zipCode: "68044"},
	{latitude: 41.825554, longitude: -96.492473, zipCode: "68045"},
	{latitude: 41.121818, longitude: -96.052772, zipCode: "68046"},
	{latitude: 42.111188, longitude: -96.733089, zipCode: "68047"},
	{latitude: 40.98408, longitude: -95.942013, zipCode: "68048"},
	{latitude: 41.300053, longitude: -96.842321, zipCode: "68050"},
	{latitude: 42.054161, longitude: -96.47219, zipCode: "68055"},
	{latitude: 41.141242, longitude: -95.904413, zipCode: "68056"},
	{latitude: 41.66187, longitude: -96.723527, zipCode: "68057"},
	{latitude: 41.003954, longitude: -96.244019, zipCode: "68058"},
	{latitude: 41.06445, longitude: -96.158755, zipCode: "68059"},
	{latitude: 41.80659, longitude: -96.219182, zipCode: "68061"},
	{latitude: 42.198845, longitude: -96.67751, zipCode: "68062"},
	{latitude: 41.723443, longitude: -96.496909, zipCode: "68063"},
	{latitude: 41.345285, longitude: -96.353772, zipCode: "68064"},
	{latitude: 41.077914, longitude: -96.8463, zipCode: "68065"},
	{latitude: 41.184632, longitude: -96.618892, zipCode: "68066"},
	{latitude: 42.156883, longitude: -96.488163, zipCode: "68067"},
	{latitude: 41.397626, longitude: -96.207454, zipCode: "68068"},
	{latitude: 41.241065, longitude: -96.315939, zipCode: "68069"},
	{latitude: 41.196681, longitude: -96.800948, zipCode: "68070"},
	{latitude: 42.237863, longitude: -96.475436, zipCode: "68071"},
	{latitude: 41.610125, longitude: -96.504695, zipCode: "68072"},
	{latitude: 41.239178, longitude: -96.416488, zipCode: "68073"},
	{latitude: 41.261537, longitude: -95.932558, zipCode: "68102"},
	{latitude: 41.295431, longitude: -96.002289, zipCode: "68104"},
	{latitude: 41.240735, longitude: -95.964919, zipCode: "68105"},
	{latitude: 41.238785, longitude: -96.002274, zipCode: "68106"},
	{latitude: 41.209065, longitude: -95.951808, zipCode: "68107"},
	{latitude: 41.237556, longitude: -95.931392, zipCode: "68108"},
	{latitude: 41.302425, longitude: -95.892763, zipCode: "68110"},
	{latitude: 41.295458, longitude: -95.964916, zipCode: "68111"},
	{latitude: 41.384177, longitude: -95.966427, zipCode: "68112"},
	{latitude: 41.117881, longitude: -95.906546, zipCode: "68113"},
	{latitude: 41.263139, longitude: -96.05204, zipCode: "68114"},
	{latitude: 41.298493, longitude: -96.165988, zipCode: "68116"},
	{latitude: 41.206571, longitude: -96.003104, zipCode: "68117"},
	{latitude: 41.263023, longitude: -96.178105, zipCode: "68118"},
	{latitude: 41.363392, longitude: -96.051876, zipCode: "68122"},
	{latitude: 41.102245, longitude: -95.947634, zipCode: "68123"},
	{latitude: 41.235443, longitude: -96.052175, zipCode: "68124"},
	{latitude: 41.206275, longitude: -96.0539, zipCode: "68127"},
	{latitude: 41.178452, longitude: -96.07087, zipCode: "68128"},
	{latitude: 41.233443, longitude: -96.197982, zipCode: "68130"},
	{latitude: 41.26432, longitude: -95.964744, zipCode: "68131"},
	{latitude: 41.265016, longitude: -96.002958, zipCode: "68132"},
	{latitude: 41.112304, longitude: -95.997685, zipCode: "68133"},
	{latitude: 41.296304, longitude: -96.050687, zipCode: "68134"},
	{latitude: 41.205868, longitude: -96.193319, zipCode: "68135"},
	{latitude: 41.168052, longitude: -96.184662, zipCode: "68136"},
	{latitude: 41.205468, longitude: -96.118756, zipCode: "68137"},
	{latitude: 41.157433, longitude: -96.131116, zipCode: "68138"},
	{latitude: 41.369212, longitude: -96.101872, zipCode: "68142"},
	{latitude: 41.234983, longitude: -96.11845, zipCode: "68144"},
	{latitude: 41.17653, longitude: -95.956793, zipCode: "68147"},
	{latitude: 41.367418, longitude: -95.996625, zipCode: "68152"},
	{latitude: 41.265517, longitude: -96.114976, zipCode: "68154"},
	{latitude: 41.178739, longitude: -95.993774, zipCode: "68157"},
	{latitude: 41.297022, longitude: -96.105868, zipCode: "68164"},
	{latitude: 41.265361, longitude: -95.945394, zipCode: "68178"},
	{latitude: 41.257745, longitude: -96.010674, zipCode: "68182"},
	{latitude: 41.257093, longitude: -95.939786, zipCode: "68183"},
	{latitude: 40.470495, longitude: -96.524982, zipCode: "68301"},
	{latitude: 40.262348, longitude: -97.415341, zipCode: "68303"},
	{latitude: 40.892599, longitude: -96.379841, zipCode: "68304"},
	{latitude: 40.370253, longitude: -95.864948, zipCode: "68305"},
	{latitude: 40.79224, longitude: -96.148576, zipCode: "68307"},
	{latitude: 40.049051, longitude: -96.572866, zipCode: "68309"},
	{latitude: 40.249821, longitude: -96.74949, zipCode: "68310"},
	{latitude: 40.783701, longitude: -97.271337, zipCode: "68313"},
	{latitude: 41.016148, longitude: -97.022214, zipCode: "68314"},
	{latitude: 40.243334, longitude: -97.536515, zipCode: "68315"},
	{latitude: 41.013204, longitude: -97.613575, zipCode: "68316"},
	{latitude: 40.673244, longitude: -96.504507, zipCode: "68317"},
	{latitude: 40.164921, longitude: -96.648576, zipCode: "68318"},
	{latitude: 40.937755, longitude: -97.75971, zipCode: "68319"},
	{latitude: 40.479563, longitude: -95.946634, zipCode: "68320"},
	{latitude: 40.400056, longitude: -95.696669, zipCode: "68321"},
	{latitude: 40.329898, longitude: -97.54779, zipCode: "68322"},
	{latitude: 40.128391, longitude: -96.372773, zipCode: "68323"},
	{latitude: 40.554076, longitude: -96.290145, zipCode: "68324"},
	{latitude: 40.010667, longitude: -97.755297, zipCode: "68325"},
	{latitude: 40.283769, longitude: -97.684106, zipCode: "68326"},
	{latitude: 40.012886, longitude: -97.621764, zipCode: "68327"},
	{latitude: 40.474387, longitude: -96.840805, zipCode: "68328"},
	{latitude: 40.497921, longitude: -96.169239, zipCode: "68329"},
	{latitude: 40.707409, longitude: -97.341196, zipCode: "68330"},
	{latitude: 40.472889, longitude: -96.714812, zipCode: "68331"},
	{latitude: 40.327928, longitude: -96.410319, zipCode: "68332"},
	{latitude: 40.620687, longitude: -96.950188, zipCode: "68333"},
	{latitude: 40.296696, longitude: -97.810381, zipCode: "68335"},
	{latitude: 40.965855, longitude: -96.690917, zipCode: "68336"},
	{latitude: 40.098408, longitude: -95.831894, zipCode: "68337"},
	{latitude: 40.313729, longitude: -97.246006, zipCode: "68338"},
	{latitude: 40.732053, longitude: -96.870103, zipCode: "68339"},
	{latitude: 40.126412, longitude: -97.738518, zipCode: "68340"},
	{latitude: 40.38729, longitude: -96.920794, zipCode: "68341"},
	{latitude: 40.103982, longitude: -96.947486, zipCode: "68342"},
	{latitude: 40.618234, longitude: -97.132103, zipCode: "68343"},
	{latitude: 40.566742, longitude: -96.399847, zipCode: "68344"},
	{latitude: 40.036455, longitude: -96.019176, zipCode: "68345"},
	{latitude: 40.660639, longitude: -96.048762, zipCode: "68346"},
	{latitude: 40.805502, longitude: -96.416644, zipCode: "68347"},
	{latitude: 40.298805, longitude: -96.115779, zipCode: "68348"},
	{latitude: 40.83163, longitude: -96.296896, zipCode: "68349"},
	{latitude: 40.030491, longitude: -97.094664, zipCode: "68350"},
	{latitude: 40.650778, longitude: -97.434866, zipCode: "68351"},
	{latitude: 40.152084, longitude: -97.22602, zipCode: "68352"},
	{latitude: 40.638995, longitude: -97.579833, zipCode: "68354"},
	{latitude: 40.123524, longitude: -95.567952, zipCode: "68355"},
	{latitude: 40.312925, longitude: -96.547756, zipCode: "68357"},
	{latitude: 40.532318, longitude: -96.609426, zipCode: "68358"},
	{latitude: 40.631292, longitude: -97.284829, zipCode: "68359"},
	{latitude: 40.950556, longitude: -96.956412, zipCode: "68360"},
	{latitude: 40.501353, longitude: -97.603498, zipCode: "68361"},
	{latitude: 40.154197, longitude: -97.420811, zipCode: "68362"},
	{latitude: 40.836794, longitude: -97.230387, zipCode: "68364"},
	{latitude: 40.619345, longitude: -97.735015, zipCode: "68365"},
	{latitude: 40.981597, longitude: -96.434617, zipCode: "68366"},
	{latitude: 41.039521, longitude: -97.39684, zipCode: "68367"},
	{latitude: 40.55433, longitude: -96.813122, zipCode: "68368"},
	{latitude: 40.14428, longitude: -97.556231, zipCode: "68370"},
	{latitude: 40.763634, longitude: -97.783303, zipCode: "68371"},
	{latitude: 40.602526, longitude: -96.60045, zipCode: "68372"},
	{latitude: 40.037018, longitude: -97.492568, zipCode: "68375"},
	{latitude: 40.170222, longitude: -95.938382, zipCode: "68376"},
	{latitude: 40.217009, longitude: -97.019327, zipCode: "68377"},
	{latitude: 40.398308, longitude: -96.020851, zipCode: "68378"},
	{latitude: 40.529115, longitude: -95.869802, zipCode: "68379"},
	{latitude: 40.242606, longitude: -96.405599, zipCode: "68380"},
	{latitude: 40.076766, longitude: -96.480787, zipCode: "68381"},
	{latitude: 40.59722, longitude: -96.024177, zipCode: "68382"},
	{latitude: 40.750809, longitude: -97.582698, zipCode: "68401"},
	{latitude: 40.908276, longitude: -96.857101, zipCode: "68402"},
	{latitude: 40.920566, longitude: -96.166772, zipCode: "68403"},
	{latitude: 40.621919, longitude: -96.755233, zipCode: "68404"},
	{latitude: 40.76205, longitude: -97.073498, zipCode: "68405"},
	{latitude: 40.503459, longitude: -97.381738, zipCode: "68406"},
	{latitude: 40.922907, longitude: -96.263729, zipCode: "68407"},
	{latitude: 40.907713, longitude: -95.95418, zipCode: "68409"},
	{latitude: 40.635824, longitude: -95.883774, zipCode: "68410"},
	{latitude: 40.846119, longitude: -96.026323, zipCode: "68413"},
	{latitude: 40.317601, longitude: -95.697886, zipCode: "68414"},
	{latitude: 40.061922, longitude: -96.821283, zipCode: "68415"},
	{latitude: 40.410391, longitude: -97.459709, zipCode: "68416"},
	{latitude: 40.733016, longitude: -96.094497, zipCode: "68417"},
	{latitude: 40.69358, longitude: -96.379013, zipCode: "68418"},
	{latitude: 40.602959, longitude: -96.511279, zipCode: "68419"},
	{latitude: 40.07901, longitude: -96.189143, zipCode: "68420"},
	{latitude: 40.493981, longitude: -95.743549, zipCode: "68421"},
	{latitude: 40.388735, longitude: -96.694709, zipCode: "68422"},
	{latitude: 40.807477, longitude: -96.95745, zipCode: "68423"},
	{latitude: 40.299195, longitude: -97.016261, zipCode: "68424"},
	{latitude: 40.966093, longitude: -96.805216, zipCode: "68428"},
	{latitude: 40.062369, longitude: -97.373402, zipCode: "68429"},
	{latitude: 40.679616, longitude: -96.667679, zipCode: "68430"},
	{latitude: 40.067562, longitude: -95.438183, zipCode: "68431"},
	{latitude: 40.053054, longitude: -95.738208, zipCode: "68433"},
	{latitude: 40.909586, longitude: -97.116949, zipCode: "68434"},
	{latitude: 40.433561, longitude: -97.744246, zipCode: "68436"},
	{latitude: 40.252896, longitude: -95.638479, zipCode: "68437"},
	{latitude: 40.626563, longitude: -96.744708, zipCode: "68438"},
	{latitude: 40.995946, longitude: -97.206667, zipCode: "68439"},
	{latitude: 40.0539, longitude: -97.012623, zipCode: "68440"},
	{latitude: 40.224693, longitude: -96.249812, zipCode: "68441"},
	{latitude: 40.239003, longitude: -95.772913, zipCode: "68442"},
	{latitude: 40.454775, longitude: -96.361203, zipCode: "68443"},
	{latitude: 40.41117, longitude: -97.584712, zipCode: "68444"},
	{latitude: 40.401474, longitude: -97.086612, zipCode: "68445"},
	{latitude: 40.647219, longitude: -96.166654, zipCode: "68446"},
	{latitude: 40.195353, longitude: -96.073041, zipCode: "68447"},
	{latitude: 40.545229, longitude: -96.038302, zipCode: "68448"},
	{latitude: 40.355234, longitude: -96.226201, zipCode: "68450"},
	{latitude: 40.401587, longitude: -97.852541, zipCode: "68452"},
	{latitude: 40.402246, longitude: -97.339834, zipCode: "68453"},
	{latitude: 40.686895, longitude: -96.28738, zipCode: "68454"},
	{latitude: 40.819689, longitude: -95.897413, zipCode: "68455"},
	{latitude: 40.909062, longitude: -97.301709, zipCode: "68456"},
	{latitude: 40.157565, longitude: -95.712063, zipCode: "68457"},
	{latitude: 40.22581, longitude: -96.492404, zipCode: "68458"},
	{latitude: 40.896327, longitude: -97.428385, zipCode: "68460"},
	{latitude: 40.762334, longitude: -96.511432, zipCode: "68461"},
	{latitude: 40.952129, longitude: -96.519659, zipCode: "68462"},
	{latitude: 40.875803, longitude: -96.129031, zipCode: "68463"},
	{latitude: 40.413364, longitude: -97.18959, zipCode: "68464"},
	{latitude: 40.493152, longitude: -97.020707, zipCode: "68465"},
	{latitude: 40.066854, longitude: -96.642831, zipCode: "68466"},
	{latitude: 40.865457, longitude: -97.598134, zipCode: "68467"},
	{latitude: 40.78401, longitude: -96.700404, zipCode: "68502"},
	{latitude: 40.824473, longitude: -96.673893, zipCode: "68503"},
	{latitude: 40.854478, longitude: -96.660184, zipCode: "68504"},
	{latitude: 40.824398, longitude: -96.615664, zipCode: "68505"},
	{latitude: 40.783832, longitude: -96.639581, zipCode: "68506"},
	{latitude: 40.859487, longitude: -96.61172, zipCode: "68507"},
	{latitude: 40.818065, longitude: -96.705504, zipCode: "68508"},
	{latitude: 40.807097, longitude: -96.648326, zipCode: "68510"},
	{latitude: 40.73746, longitude: -96.705659, zipCode: "68512"},
	{latitude: 40.934063, longitude: -96.650743, zipCode: "68514"},
	{latitude: 40.735276, longitude: -96.64279, zipCode: "68516"},
	{latitude: 40.932207, longitude: -96.603253, zipCode: "68517"},
	{latitude: 40.805895, longitude: -96.572561, zipCode: "68520"},
	{latitude: 40.861818, longitude: -96.714783, zipCode: "68521"},
	{latitude: 40.791284, longitude: -96.760102, zipCode: "68522"},
	{latitude: 40.7315, longitude: -96.755301, zipCode: "68523"},
	{latitude: 40.906992, longitude: -96.787368, zipCode: "68524"},
	{latitude: 40.729979, longitude: -96.582051, zipCode: "68526"},
	{latitude: 40.845382, longitude: -96.511331, zipCode: "68527"},
	{latitude: 40.824999, longitude: -96.824998, zipCode: "68528"},
	{latitude: 40.908026, longitude: -96.710468, zipCode: "68531"},
	{latitude: 40.795421, longitude: -96.843344, zipCode: "68532"},
	{latitude: 40.824921, longitude: -96.696429, zipCode: "68588"},
	{latitude: 41.454145, longitude: -97.398786, zipCode: "68601"},
	{latitude: 41.716659, longitude: -98.051161, zipCode: "68620"},
	{latitude: 41.492162, longitude: -96.639488, zipCode: "68621"},
	{latitude: 41.922575, longitude: -98.520853, zipCode: "68622"},
	{latitude: 41.436243, longitude: -98.102119, zipCode: "68623"},
	{latitude: 41.345613, longitude: -97.229358, zipCode: "68624"},
	{latitude: 41.169497, longitude: -96.992008, zipCode: "68626"},
	{latitude: 41.547605, longitude: -98.165686, zipCode: "68627"},
	{latitude: 41.216215, longitude: -97.843634, zipCode: "68628"},
	{latitude: 41.707272, longitude: -97.131094, zipCode: "68629"},
	{latitude: 41.68998, longitude: -97.355634, zipCode: "68631"},
	{latitude: 41.243105, longitude: -97.133872, zipCode: "68632"},
	{latitude: 41.706202, longitude: -96.896404, zipCode: "68633"},
	{latitude: 41.390732, longitude: -97.506636, zipCode: "68634"},
	{latitude: 41.084576, longitude: -97.022886, zipCode: "68635"},
	{latitude: 42.009224, longitude: -98.152606, zipCode: "68636"},
	{latitude: 41.815455, longitude: -98.644164, zipCode: "68637"},
	{latitude: 41.377775, longitude: -98.007171, zipCode: "68638"},
	{latitude: 41.45877, longitude: -97.766341, zipCode: "68640"},
	{latitude: 41.73567, longitude: -97.015265, zipCode: "68641"},
	{latitude: 41.689215, longitude: -97.513932, zipCode: "68642"},
	{latitude: 41.705976, longitude: -97.263334, zipCode: "68643"},
	{latitude: 41.704257, longitude: -97.670015, zipCode: "68644"},
	{latitude: 41.518774, longitude: -97.62834, zipCode: "68647"},
	{latitude: 41.401381, longitude: -96.780226, zipCode: "68648"},
	{latitude: 41.505048, longitude: -96.785365, zipCode: "68649"},
	{latitude: 41.228224, longitude: -97.570129, zipCode: "68651"},
	{latitude: 41.861112, longitude: -98.090096, zipCode: "68652"},
	{latitude: 41.562834, longitude: -97.495748, zipCode: "68653"},
	{latitude: 41.067034, longitude: -97.779138, zipCode: "68654"},
	{latitude: 41.648119, longitude: -98.261209, zipCode: "68655"},
	{latitude: 41.199533, longitude: -97.292671, zipCode: "68658"},
	{latitude: 41.545943, longitude: -96.935406, zipCode: "68659"},
	{latitude: 41.574116, longitude: -97.867733, zipCode: "68660"},
	{latitude: 41.492505, longitude: -97.088623, zipCode: "68661"},
	{latitude: 41.210704, longitude: -97.417564, zipCode: "68662"},
	{latitude: 41.311214, longitude: -97.701007, zipCode: "68663"},
	{latitude: 41.704573, longitude: -96.786835, zipCode: "68664"},
	{latitude: 41.71387, longitude: -98.418973, zipCode: "68665"},
	{latitude: 41.110595, longitude: -97.611211, zipCode: "68666"},
	{latitude: 41.115455, longitude: -97.306937, zipCode: "68667"},
	{latitude: 41.079231, longitude: -97.236898, zipCode: "68669"},
	{latitude: 42.019825, longitude: -97.442556, zipCode: "68701"},
	{latitude: 42.448281, longitude: -96.849399, zipCode: "68710"},
	{latitude: 42.194967, longitude: -99.039009, zipCode: "68711"},
	{latitude: 42.540294, longitude: -98.973236, zipCode: "68713"},
	{latitude: 42.368746, longitude: -99.5033, zipCode: "68714"},
	{latitude: 41.971271, longitude: -97.605926, zipCode: "68715"},
	{latitude: 41.927041, longitude: -96.822664, zipCode: "68716"},
	{latitude: 42.38335, longitude: -97.222448, zipCode: "68717"},
	{latitude: 42.669933, longitude: -97.692085, zipCode: "68718"},
	{latitude: 42.969389, longitude: -98.559565, zipCode: "68719"},
	{latitude: 42.344997, longitude: -98.01466, zipCode: "68720"},
	{latitude: 42.929935, longitude: -98.869016, zipCode: "68722"},
	{latitude: 42.285585, longitude: -97.218555, zipCode: "68723"},
	{latitude: 42.627619, longitude: -97.884932, zipCode: "68724"},
	{latitude: 42.197414, longitude: -98.777902, zipCode: "68725"},
	{latitude: 42.184662, longitude: -98.210112, zipCode: "68726"},
	{latitude: 42.515296, longitude: -97.202478, zipCode: "68727"},
	{latitude: 42.387427, longitude: -96.930001, zipCode: "68728"},
	{latitude: 42.477415, longitude: -97.907316, zipCode: "68729"},
	{latitude: 42.767263, longitude: -97.534632, zipCode: "68730"},
	{latitude: 42.407821, longitude: -96.492072, zipCode: "68731"},
	{latitude: 42.488576, longitude: -96.97166, zipCode: "68732"},
	{latitude: 42.28292, longitude: -96.722184, zipCode: "68733"},
	{latitude: 42.429051, longitude: -98.798338, zipCode: "68734"},
	{latitude: 42.146817, longitude: -98.446612, zipCode: "68735"},
	{latitude: 42.744837, longitude: -97.371385, zipCode: "68736"},
	{latitude: 42.627643, longitude: -97.26157, zipCode: "68739"},
	{latitude: 42.146637, longitude: -97.301618, zipCode: "68740"},
	{latitude: 42.36491, longitude: -96.594532, zipCode: "68741"},
	{latitude: 42.350211, longitude: -98.499895, zipCode: "68742"},
	{latitude: 42.472448, longitude: -96.624763, zipCode: "68743"},
	{latitude: 42.456197, longitude: -97.083883, zipCode: "68745"},
	{latitude: 42.809636, longitude: -98.437442, zipCode: "68746"},
	{latitude: 42.391145, longitude: -97.496125, zipCode: "68747"},
	{latitude: 41.836996, longitude: -97.474624, zipCode: "68748"},
	{latitude: 42.460326, longitude: -97.475323, zipCode: "68749"},
	{latitude: 42.69043, longitude: -96.981426, zipCode: "68751"},
	{latitude: 42.020231, longitude: -97.711668, zipCode: "68752"},
	{latitude: 42.854067, longitude: -99.485011, zipCode: "68753"},
	{latitude: 42.926512, longitude: -99.130016, zipCode: "68755"},
	{latitude: 42.183568, longitude: -97.980286, zipCode: "68756"},
	{latitude: 42.65328, longitude: -96.927927, zipCode: "68757"},
	{latitude: 41.778406, longitude: -97.783026, zipCode: "68758"},
	{latitude: 42.751759, longitude: -99.346454, zipCode: "68759"},
	{latitude: 42.708842, longitude: -97.874979, zipCode: "68760"},
	{latitude: 42.021589, longitude: -97.956375, zipCode: "68761"},
	{latitude: 42.515901, longitude: -98.588982, zipCode: "68763"},
	{latitude: 42.404882, longitude: -98.23167, zipCode: "68764"},
	{latitude: 42.3423, longitude: -97.613711, zipCode: "68765"},
	{latitude: 42.42839, longitude: -98.40925, zipCode: "68766"},
	{latitude: 42.197018, longitude: -97.536586, zipCode: "68767"},
	{latitude: 41.999989, longitude: -97.070194, zipCode: "68768"},
	{latitude: 42.309481, longitude: -97.824652, zipCode: "68769"},
	{latitude: 42.561623, longitude: -96.764827, zipCode: "68770"},
	{latitude: 42.395738, longitude: -97.360096, zipCode: "68771"},
	{latitude: 42.362211, longitude: -98.119232, zipCode: "68773"},
	{latitude: 42.812222, longitude: -97.274892, zipCode: "68774"},
	{latitude: 42.457187, longitude: -96.451047, zipCode: "68776"},
	{latitude: 42.860734, longitude: -98.687868, zipCode: "68777"},
	{latitude: 42.879535, longitude: -99.80724, zipCode: "68778"},
	{latitude: 41.952592, longitude: -97.209291, zipCode: "68779"},
	{latitude: 42.632961, longitude: -99.12819, zipCode: "68780"},
	{latitude: 42.027562, longitude: -97.839951, zipCode: "68781"},
	{latitude: 42.636497, longitude: -98.139394, zipCode: "68783"},
	{latitude: 42.257167, longitude: -96.891367, zipCode: "68784"},
	{latitude: 42.427966, longitude: -96.722393, zipCode: "68785"},
	{latitude: 42.50156, longitude: -97.557442, zipCode: "68786"},
	{latitude: 42.213826, longitude: -97.030949, zipCode: "68787"},
	{latitude: 41.835484, longitude: -96.725466, zipCode: "68788"},
	{latitude: 42.535428, longitude: -97.983803, zipCode: "68789"},
	{latitude: 42.168389, longitude: -97.186038, zipCode: "68790"},
	{latitude: 41.99758, longitude: -96.93982, zipCode: "68791"},
	{latitude: 42.727629, longitude: -97.100144, zipCode: "68792"},
	{latitude: 40.949883, longitude: -98.297321, zipCode: "68801"},
	{latitude: 40.956158, longitude: -98.414569, zipCode: "68803"},
	{latitude: 40.863259, longitude: -98.467445, zipCode: "68810"},
	{latitude: 40.909086, longitude: -99.280446, zipCode: "68812"},
	{latitude: 41.699277, longitude: -99.856993, zipCode: "68813"},
	{latitude: 41.308527, longitude: -99.41025, zipCode: "68814"},
	{latitude: 41.428903, longitude: -99.155996, zipCode: "68815"},
	{latitude: 41.174853, longitude: -98.133715, zipCode: "68816"},
	{latitude: 41.26748, longitude: -98.793822, zipCode: "68817"},
	{latitude: 40.822012, longitude: -98.006221, zipCode: "68818"},
	{latitude: 41.112899, longitude: -98.701416, zipCode: "68820"},
	{latitude: 42.007491, longitude: -99.762704, zipCode: "68821"},
	{latitude: 41.388461, longitude: -99.631356, zipCode: "68822"},
	{latitude: 41.956564, longitude: -99.155956, zipCode: "68823"},
	{latitude: 41.007094, longitude: -98.618145, zipCode: "68824"},
	{latitude: 41.264062, longitude: -99.968062, zipCode: "68825"},
	{latitude: 41.163588, longitude: -98.016399, zipCode: "68826"},
	{latitude: 41.042659, longitude: -98.181454, zipCode: "68827"},
	{latitude: 41.558194, longitude: -99.248739, zipCode: "68828"},
	{latitude: 41.106685, longitude: -98.544036, zipCode: "68831"},
	{latitude: 40.770258, longitude: -98.366824, zipCode: "68832"},
	{latitude: 41.785968, longitude: -100.076596, zipCode: "68833"},
	{latitude: 41.014915, longitude: -99.657783, zipCode: "68834"},
	{latitude: 41.331899, longitude: -98.61724, zipCode: "68835"},
	{latitude: 40.705215, longitude: -99.37727, zipCode: "68836"},
	{latitude: 41.669828, longitude: -99.07315, zipCode: "68837"},
	{latitude: 41.212162, longitude: -98.662361, zipCode: "68838"},
	{latitude: 40.759666, longitude: -98.877542, zipCode: "68840"},
	{latitude: 40.762203, longitude: -98.158229, zipCode: "68841"},
	{latitude: 41.580675, longitude: -98.5345, zipCode: "68842"},
	{latitude: 40.905842, longitude: -97.878615, zipCode: "68843"},
	{latitude: 41.053759, longitude: -99.063394, zipCode: "68844"},
	{latitude: 40.718088, longitude: -99.170857, zipCode: "68845"},
	{latitude: 41.062699, longitude: -97.883831, zipCode: "68846"},
	{latitude: 40.738535, longitude: -99.020515, zipCode: "68847"},
	{latitude: 40.701456, longitude: -99.100884, zipCode: "68849"},
	{latitude: 40.826405, longitude: -99.739779, zipCode: "68850"},
	{latitude: 41.168335, longitude: -99.144321, zipCode: "68852"},
	{latitude: 41.287717, longitude: -98.980747, zipCode: "68853"},
	{latitude: 41.009922, longitude: -98.002428, zipCode: "68854"},
	{latitude: 41.169337, longitude: -99.325453, zipCode: "68855"},
	{latitude: 41.503044, longitude: -99.817591, zipCode: "68856"},
	{latitude: 40.965845, longitude: -99.37754, zipCode: "68858"},
	{latitude: 41.458741, longitude: -98.82218, zipCode: "68859"},
	{latitude: 41.133983, longitude: -99.795816, zipCode: "68860"},
	{latitude: 40.698946, longitude: -99.263886, zipCode: "68861"},
	{latitude: 41.604066, longitude: -98.93836, zipCode: "68862"},
	{latitude: 40.766301, longitude: -99.532137, zipCode: "68863"},
	{latitude: 41.255217, longitude: -98.238229, zipCode: "68864"},
	{latitude: 40.896549, longitude: -98.178659, zipCode: "68865"},
	{latitude: 40.968939, longitude: -99.129182, zipCode: "68866"},
	{latitude: 40.996135, longitude: -98.884197, zipCode: "68869"},
	{latitude: 40.8453, longitude: -99.158364, zipCode: "68870"},
	{latitude: 41.140917, longitude: -98.852753, zipCode: "68871"},
	{latitude: 41.092874, longitude: -98.332167, zipCode: "68872"},
	{latitude: 41.240069, longitude: -98.453879, zipCode: "68873"},
	{latitude: 41.634546, longitude: -99.44441, zipCode: "68874"},
	{latitude: 41.523113, longitude: -98.685407, zipCode: "68875"},
	{latitude: 40.82518, longitude: -98.749999, zipCode: "68876"},
	{latitude: 40.981333, longitude: -99.506834, zipCode: "68878"},
	{latitude: 41.903183, longitude: -99.509852, zipCode: "68879"},
	{latitude: 41.434669, longitude: -99.359595, zipCode: "68881"},
	{latitude: 41.435696, longitude: -98.391066, zipCode: "68882"},
	{latitude: 40.820652, longitude: -98.577851, zipCode: "68883"},
	{latitude: 40.590946, longitude: -98.366104, zipCode: "68901"},
	{latitude: 40.154543, longitude: -99.339914, zipCode: "68920"},
	{latitude: 40.336428, longitude: -99.899773, zipCode: "68922"},
	{latitude: 40.368328, longitude: -99.473203, zipCode: "68923"},
	{latitude: 40.495504, longitude: -99.124672, zipCode: "68924"},
	{latitude: 40.428761, longitude: -98.430108, zipCode: "68925"},
	{latitude: 40.105983, longitude: -99.840291, zipCode: "68926"},
	{latitude: 40.499975, longitude: -99.633924, zipCode: "68927"},
	{latitude: 40.284345, longitude: -98.60684, zipCode: "68928"},
	{latitude: 40.154009, longitude: -99.094314, zipCode: "68929"},
	{latitude: 40.284768, longitude: -98.415566, zipCode: "68930"},
	{latitude: 40.303716, longitude: -98.739803, zipCode: "68932"},
	{latitude: 40.518328, longitude: -98.040619, zipCode: "68933"},
	{latitude: 40.341976, longitude: -98.150717, zipCode: "68934"},
	{latitude: 40.378358, longitude: -97.962002, zipCode: "68935"},
	{latitude: 40.314966, longitude: -99.775367, zipCode: "68936"},
	{latitude: 40.569279, longitude: -99.858924, zipCode: "68937"},
	{latitude: 40.415596, longitude: -98.117141, zipCode: "68938"},
	{latitude: 40.10974, longitude: -98.961742, zipCode: "68939"},
	{latitude: 40.538385, longitude: -99.25176, zipCode: "68940"},
	{latitude: 40.468824, longitude: -98.267895, zipCode: "68941"},
	{latitude: 40.096586, longitude: -98.320788, zipCode: "68942"},
	{latitude: 40.031109, longitude: -97.887306, zipCode: "68943"},
	{latitude: 40.637829, longitude: -98.094112, zipCode: "68944"},
	{latitude: 40.560983, longitude: -98.773268, zipCode: "68945"},
	{latitude: 40.106742, longitude: -99.990473, zipCode: "68946"},
	{latitude: 40.301383, longitude: -99.051893, zipCode: "68947"},
	{latitude: 40.342314, longitude: -100.027727, zipCode: "68948"},
	{latitude: 40.432746, longitude: -99.393472, zipCode: "68949"},
	{latitude: 40.455849, longitude: -98.66413, zipCode: "68950"},
	{latitude: 40.081713, longitude: -98.679112, zipCode: "68952"},
	{latitude: 40.614718, longitude: -98.233726, zipCode: "68954"},
	{latitude: 40.591991, longitude: -98.540295, zipCode: "68955"},
	{latitude: 40.631616, longitude: -98.68814, zipCode: "68956"},
	{latitude: 40.273952, longitude: -98.246364, zipCode: "68957"},
	{latitude: 40.530287, longitude: -99.507393, zipCode: "68958"},
	{latitude: 40.48554, longitude: -98.923098, zipCode: "68959"},
	{latitude: 40.083803, longitude: -99.138983, zipCode: "68960"},
	{latitude: 40.208997, longitude: -98.043802, zipCode: "68961"},
	{latitude: 40.235195, longitude: -97.902786, zipCode: "68964"},
	{latitude: 40.159905, longitude: -99.467272, zipCode: "68966"},
	{latitude: 40.253917, longitude: -99.63162, zipCode: "68967"},
	{latitude: 40.10798, longitude: -98.529779, zipCode: "68970"},
	{latitude: 40.124355, longitude: -99.250192, zipCode: "68971"},
	{latitude: 40.101604, longitude: -98.803401, zipCode: "68972"},
	{latitude: 40.444547, longitude: -98.562912, zipCode: "68973"},
	{latitude: 40.125862, longitude: -97.867653, zipCode: "68974"},
	{latitude: 40.61881, longitude: -97.948619, zipCode: "68975"},
	{latitude: 40.602317, longitude: -99.709135, zipCode: "68976"},
	{latitude: 40.066948, longitude: -99.630525, zipCode: "68977"},
	{latitude: 40.06666, longitude: -98.0975, zipCode: "68978"},
	{latitude: 40.59828, longitude: -97.853549, zipCode: "68979"},
	{latitude: 40.676614, longitude: -98.250701, zipCode: "68980"},
	{latitude: 40.286007, longitude: -98.892394, zipCode: "68981"},
	{latitude: 40.330764, longitude: -99.19517, zipCode: "68982"},
	{latitude: 40.250669, longitude: -100.661196, zipCode: "69001"},
	{latitude: 40.153957, longitude: -100.26232, zipCode: "69020"},
	{latitude: 40.14161, longitude: -101.540343, zipCode: "69021"},
	{latitude: 40.341844, longitude: -100.187143, zipCode: "69022"},
	{latitude: 40.481826, longitude: -101.89873, zipCode: "69023"},
	{latitude: 40.240961, longitude: -100.863894, zipCode: "69024"},
	{latitude: 40.620868, longitude: -100.50948, zipCode: "69025"},
	{latitude: 40.025388, longitude: -100.410645, zipCode: "69026"},
	{latitude: 40.502503, longitude: -101.524265, zipCode: "69027"},
	{latitude: 40.635927, longitude: -100.054271, zipCode: "69028"},
	{latitude: 40.677788, longitude: -100.242829, zipCode: "69029"},
	{latitude: 40.18343, longitude: -101.916354, zipCode: "69030"},
	{latitude: 40.579352, longitude: -101.063208, zipCode: "69032"},
	{latitude: 40.552146, longitude: -101.694374, zipCode: "69033"},
	{latitude: 40.250769, longitude: -100.433692, zipCode: "69034"},
	{latitude: 40.027907, longitude: -100.298018, zipCode: "69036"},
	{latitude: 40.184984, longitude: -101.405741, zipCode: "69037"},
	{latitude: 40.61081, longitude: -100.739407, zipCode: "69038"},
	{latitude: 40.706065, longitude: -100.387701, zipCode: "69039"},
	{latitude: 40.354996, longitude: -101.119758, zipCode: "69040"},
	{latitude: 40.177036, longitude: -101.720375, zipCode: "69041"},
	{latitude: 40.475456, longitude: -100.379004, zipCode: "69042"},
	{latitude: 40.128711, longitude: -101.255201, zipCode: "69043"},
	{latitude: 40.130145, longitude: -101.016489, zipCode: "69044"},
	{latitude: 40.465392, longitude: -101.369178, zipCode: "69045"},
	{latitude: 40.06821, longitude: -100.104099, zipCode: "69046"},
	{latitude: 41.150189, longitude: -100.805308, zipCode: "69101"},
	{latitude: 41.432195, longitude: -100.242481, zipCode: "69120"},
	{latitude: 41.543566, longitude: -101.773562, zipCode: "69121"},
	{latitude: 41.099788, longitude: -102.086763, zipCode: "69122"},
	{latitude: 41.044466, longitude: -100.370866, zipCode: "69123"},
	{latitude: 41.592506, longitude: -102.855514, zipCode: "69125"},
	{latitude: 41.105836, longitude: -101.918791, zipCode: "69127"},
	{latitude: 41.230119, longitude: -103.907963, zipCode: "69128"},
	{latitude: 41.134977, longitude: -102.380427, zipCode: "69129"},
	{latitude: 40.897081, longitude: -99.961723, zipCode: "69130"},
	{latitude: 41.416551, longitude: -102.997395, zipCode: "69131"},
	{latitude: 40.814912, longitude: -101.006611, zipCode: "69132"},
	{latitude: 41.219563, longitude: -103.471892, zipCode: "69133"},
	{latitude: 40.806617, longitude: -101.369279, zipCode: "69134"},
	{latitude: 42.144777, longitude: -100.266113, zipCode: "69135"},
	{latitude: 40.995816, longitude: -100.176551, zipCode: "69138"},
	{latitude: 40.840299, longitude: -101.77312, zipCode: "69140"},
	{latitude: 41.304157, longitude: -102.936025, zipCode: "69141"},
	{latitude: 41.913938, longitude: -100.304871, zipCode: "69142"},
	{latitude: 41.19271, longitude: -101.019374, zipCode: "69143"},
	{latitude: 41.342468, longitude: -101.62135, zipCode: "69144"},
	{latitude: 41.251546, longitude: -103.68349, zipCode: "69145"},
	{latitude: 41.34836, longitude: -101.869141, zipCode: "69146"},
	{latitude: 41.574086, longitude: -102.095212, zipCode: "69147"},
	{latitude: 41.688782, longitude: -102.610804, zipCode: "69148"},
	{latitude: 41.221745, longitude: -102.677147, zipCode: "69149"},
	{latitude: 40.821947, longitude: -101.541332, zipCode: "69150"},
	{latitude: 41.090203, longitude: -100.495252, zipCode: "69151"},
	{latitude: 42.065406, longitude: -101.149729, zipCode: "69152"},
	{latitude: 41.075658, longitude: -101.670275, zipCode: "69153"},
	{latitude: 41.460919, longitude: -102.353212, zipCode: "69154"},
	{latitude: 41.188361, longitude: -101.389426, zipCode: "69155"},
	{latitude: 41.276565, longitude: -103.345817, zipCode: "69156"},
	{latitude: 41.966773, longitude: -100.158443, zipCode: "69157"},
	{latitude: 42.156506, longitude: -100.781305, zipCode: "69161"},
	{latitude: 41.129189, longitude: -103.016494, zipCode: "69162"},
	{latitude: 41.569639, longitude: -100.541642, zipCode: "69163"},
	{latitude: 41.152671, longitude: -101.170763, zipCode: "69165"},
	{latitude: 42.030913, longitude: -100.618323, zipCode: "69166"},
	{latitude: 41.596232, longitude: -101.006031, zipCode: "69167"},
	{latitude: 40.748165, longitude: -101.981251, zipCode: "69168"},
	{latitude: 40.821043, longitude: -101.179886, zipCode: "69169"},
	{latitude: 40.79581, longitude: -100.744272, zipCode: "69170"},
	{latitude: 40.890659, longitude: -100.068161, zipCode: "69171"},
	{latitude: 42.692363, longitude: -100.614583, zipCode: "69201"},
	{latitude: 42.390453, longitude: -99.856953, zipCode: "69210"},
	{latitude: 42.836333, longitude: -101.316348, zipCode: "69211"},
	{latitude: 42.979813, longitude: -100.803245, zipCode: "69212"},
	{latitude: 42.448252, longitude: -100.100493, zipCode: "69214"},
	{latitude: 42.951708, longitude: -101.008059, zipCode: "69216"},
	{latitude: 42.26594, longitude: -99.716152, zipCode: "69217"},
	{latitude: 42.774795, longitude: -101.612778, zipCode: "69218"},
	{latitude: 42.713708, longitude: -101.025102, zipCode: "69219"},
	{latitude: 42.918531, longitude: -100.168594, zipCode: "69220"},
	{latitude: 42.641545, longitude: -100.310434, zipCode: "69221"},
	{latitude: 42.126924, longitude: -102.889507, zipCode: "69301"},
	{latitude: 41.897596, longitude: -102.991053, zipCode: "69331"},
	{latitude: 42.050947, longitude: -101.966635, zipCode: "69333"},
	{latitude: 41.821808, longitude: -103.29016, zipCode: "69334"},
	{latitude: 42.020655, longitude: -102.142622, zipCode: "69335"},
	{latitude: 41.628549, longitude: -103.166962, zipCode: "69336"},
	{latitude: 42.876447, longitude: -102.983, zipCode: "69337"},
	{latitude: 42.583427, longitude: -103.442759, zipCode: "69339"},
	{latitude: 42.149843, longitude: -102.196795, zipCode: "69340"},
	{latitude: 41.739079, longitude: -103.679013, zipCode: "69341"},
	{latitude: 42.665533, longitude: -101.910901, zipCode: "69343"},
	{latitude: 41.59419, longitude: -103.710493, zipCode: "69345"},
	{latitude: 42.625227, longitude: -103.792979, zipCode: "69346"},
	{latitude: 42.594852, longitude: -102.683817, zipCode: "69347"},
	{latitude: 42.331438, longitude: -103.207858, zipCode: "69348"},
	{latitude: 41.961247, longitude: -101.764942, zipCode: "69350"},
	{latitude: 42.115127, longitude: -102.413129, zipCode: "69351"},
	{latitude: 41.803986, longitude: -103.973548, zipCode: "69352"},
	{latitude: 41.74705, longitude: -103.418918, zipCode: "69353"},
	{latitude: 42.381413, longitude: -103.381836, zipCode: "69354"},
	{latitude: 41.781972, longitude: -103.516935, zipCode: "69355"},
	{latitude: 41.890922, longitude: -103.451135, zipCode: "69356"},
	{latitude: 42.110818, longitude: -103.767984, zipCode: "69357"},
	{latitude: 42.076823, longitude: -103.979768, zipCode: "69358"},
	{latitude: 42.738551, longitude: -102.486558, zipCode: "69360"},
	{latitude: 42.102096, longitude: -103.566082, zipCode: "69361"},
	{latitude: 42.947495, longitude: -102.460981, zipCode: "69365"},
	{latitude: 42.067934, longitude: -101.581195, zipCode: "69366"},
	{latitude: 42.814352, longitude: -103.316932, zipCode: "69367"},
	{latitude: 29.982705, longitude: -90.169068, zipCode: "70001"},
	{latitude: 30.010189, longitude: -90.162617, zipCode: "70002"},
	{latitude: 30.001164, longitude: -90.209724, zipCode: "70003"},
	{latitude: 29.999416, longitude: -90.134065, zipCode: "70005"},
	{latitude: 30.014032, longitude: -90.191345, zipCode: "70006"},
	{latitude: 29.819197, longitude: -90.43971, zipCode: "70030"},
	{latitude: 29.943492, longitude: -90.295565, zipCode: "70031"},
	{latitude: 29.95038, longitude: -89.996001, zipCode: "70032"},
	{latitude: 29.684562, longitude: -90.131823, zipCode: "70036"},
	{latitude: 29.715894, longitude: -90.035359, zipCode: "70037"},
	{latitude: 29.331064, longitude: -89.400699, zipCode: "70038"},
	{latitude: 29.877209, longitude: -90.377249, zipCode: "70039"},
	{latitude: 29.740211, longitude: -89.922596, zipCode: "70040"},
	{latitude: 29.333868, longitude: -89.467526, zipCode: "70041"},
	{latitude: 29.956176, longitude: -89.964417, zipCode: "70043"},
	{latitude: 29.972092, longitude: -90.354156, zipCode: "70047"},
	{latitude: 30.030039, longitude: -90.562201, zipCode: "70049"},
	{latitude: 29.38336, longitude: -89.595672, zipCode: "70050"},
	{latitude: 30.073542, longitude: -90.642768, zipCode: "70051"},
	{latitude: 30.070157, longitude: -90.687348, zipCode: "70052"},
	{latitude: 29.917069, longitude: -90.052204, zipCode: "70053"},
	{latitude: 29.887441, longitude: -90.027304, zipCode: "70056"},
	{latitude: 29.962198, longitude: -90.464199, zipCode: "70057"},
	{latitude: 29.868874, longitude: -90.064872, zipCode: "70058"},
	{latitude: 29.991657, longitude: -90.258116, zipCode: "70062"},
	{latitude: 30.026672, longitude: -90.25345, zipCode: "70065"},
	{latitude: 29.710879, longitude: -90.096784, zipCode: "70067"},
	{latitude: 30.156347, longitude: -90.436783, zipCode: "70068"},
	{latitude: 29.829842, longitude: -90.310627, zipCode: "70070"},
	{latitude: 30.05192, longitude: -90.703341, zipCode: "70071"},
	{latitude: 29.809263, longitude: -90.138308, zipCode: "70072"},
	{latitude: 29.927459, longitude: -89.91921, zipCode: "70075"},
	{latitude: 30.054467, longitude: -90.64973, zipCode: "70076"},
	{latitude: 30.002153, longitude: -90.412317, zipCode: "70079"},
	{latitude: 29.871092, longitude: -90.421812, zipCode: "70080"},
	{latitude: 29.571513, longitude: -89.752824, zipCode: "70082"},
	{latitude: 29.511914, longitude: -89.843736, zipCode: "70083"},
	{latitude: 30.072724, longitude: -90.573197, zipCode: "70084"},
	{latitude: 29.818077, longitude: -89.759469, zipCode: "70085"},
	{latitude: 30.036193, longitude: -90.865406, zipCode: "70086"},
	{latitude: 29.994018, longitude: -90.320832, zipCode: "70087"},
	{latitude: 29.969253, longitude: -90.701136, zipCode: "70090"},
	{latitude: 29.16749, longitude: -89.216283, zipCode: "70091"},
	{latitude: 29.899112, longitude: -89.895936, zipCode: "70092"},
	{latitude: 29.915778, longitude: -90.207605, zipCode: "70094"},
	{latitude: 29.95609, longitude: -90.07735, zipCode: "70112"},
	{latitude: 29.942292, longitude: -90.083732, zipCode: "70113"},
	{latitude: 29.937445, longitude: -90.032322, zipCode: "70114"},
	{latitude: 29.92393, longitude: -90.102484, zipCode: "70115"},
	{latitude: 29.96744, longitude: -90.064783, zipCode: "70116"},
	{latitude: 29.968157, longitude: -90.029962, zipCode: "70117"},
	{latitude: 29.944708, longitude: -90.125674, zipCode: "70118"},
	{latitude: 29.975752, longitude: -90.087845, zipCode: "70119"},
	{latitude: 29.961736, longitude: -90.159183, zipCode: "70121"},
	{latitude: 30.008505, longitude: -90.064718, zipCode: "70122"},
	{latitude: 29.949989, longitude: -90.205559, zipCode: "70123"},
	{latitude: 30.007379, longitude: -90.103919, zipCode: "70124"},
	{latitude: 29.951928, longitude: -90.10335, zipCode: "70125"},
	{latitude: 30.019485, longitude: -90.017565, zipCode: "70126"},
	{latitude: 30.028404, longitude: -89.9742, zipCode: "70127"},
	{latitude: 30.044717, longitude: -89.951375, zipCode: "70128"},
	{latitude: 30.080851, longitude: -89.813366, zipCode: "70129"},
	{latitude: 29.936406, longitude: -90.069932, zipCode: "70130"},
	{latitude: 29.906333, longitude: -89.95834, zipCode: "70131"},
	{latitude: 29.95023, longitude: -90.070995, zipCode: "70139"},
	{latitude: 30.02732, longitude: -90.067597, zipCode: "70148"},
	{latitude: 29.949981, longitude: -90.075462, zipCode: "70163"},
	{latitude: 29.808328, longitude: -90.750267, zipCode: "70301"},
	{latitude: 29.790543, longitude: -90.802982, zipCode: "70310"},
	{latitude: 29.904627, longitude: -91.184723, zipCode: "70339"},
	{latitude: 29.669742, longitude: -91.104288, zipCode: "70340"},
	{latitude: 30.02649, longitude: -91.083333, zipCode: "70341"},
	{latitude: 29.693147, longitude: -91.23834, zipCode: "70342"},
	{latitude: 29.548398, longitude: -90.557239, zipCode: "70343"},
	{latitude: 29.401184, longitude: -90.633055, zipCode: "70344"},
	{latitude: 29.501844, longitude: -90.23789, zipCode: "70345"},
	{latitude: 30.10609, longitude: -91.017988, zipCode: "70346"},
	{latitude: 29.684424, longitude: -90.940194, zipCode: "70352"},
	{latitude: 29.358143, longitude: -90.700118, zipCode: "70353"},
	{latitude: 29.43028, longitude: -90.342585, zipCode: "70354"},
	{latitude: 29.687187, longitude: -90.43547, zipCode: "70355"},
	{latitude: 29.631566, longitude: -90.970879, zipCode: "70356"},
	{latitude: 29.257799, longitude: -90.192019, zipCode: "70357"},
	{latitude: 29.214732, longitude: -90.027546, zipCode: "70358"},
	{latitude: 29.689847, longitude: -90.777822, zipCode: "70359"},
	{latitude: 29.578671, longitude: -90.805402, zipCode: "70360"},
	{latitude: 29.506722, longitude: -90.700777, zipCode: "70363"},
	{latitude: 29.635994, longitude: -90.679678, zipCode: "70364"},
	{latitude: 29.782462, longitude: -90.988904, zipCode: "70372"},
	{latitude: 29.597594, longitude: -90.311472, zipCode: "70373"},
	{latitude: 29.604507, longitude: -90.501988, zipCode: "70374"},
	{latitude: 29.695664, longitude: -90.531568, zipCode: "70375"},
	{latitude: 29.459272, longitude: -90.532111, zipCode: "70377"},
	{latitude: 29.752074, longitude: -91.103597, zipCode: "70380"},
	{latitude: 29.890288, longitude: -91.03187, zipCode: "70390"},
	{latitude: 29.995146, longitude: -91.060326, zipCode: "70391"},
	{latitude: 29.699424, longitude: -91.31847, zipCode: "70392"},
	{latitude: 29.995924, longitude: -90.99897, zipCode: "70393"},
	{latitude: 29.692681, longitude: -90.61612, zipCode: "70394"},
	{latitude: 29.697436, longitude: -90.86173, zipCode: "70395"},
	{latitude: 29.388473, longitude: -90.830994, zipCode: "70397"},
	{latitude: 30.533553, longitude: -90.455443, zipCode: "70401"},
	{latitude: 30.516501, longitude: -90.46926, zipCode: "70402"},
	{latitude: 30.485555, longitude: -90.487688, zipCode: "70403"},
	{latitude: 30.498753, longitude: -89.944416, zipCode: "70420"},
	{latitude: 30.735737, longitude: -90.491513, zipCode: "70422"},
	{latitude: 30.926497, longitude: -89.858614, zipCode: "70426"},
	{latitude: 30.744198, longitude: -89.912079, zipCode: "70427"},
	{latitude: 30.616752, longitude: -89.967363, zipCode: "70431"},
	{latitude: 30.462717, longitude: -90.140504, zipCode: "70433"},
	{latitude: 30.556992, longitude: -90.106639, zipCode: "70435"},
	{latitude: 30.834554, longitude: -90.533106, zipCode: "70436"},
	{latitude: 30.619454, longitude: -90.209393, zipCode: "70437"},
	{latitude: 30.843721, longitude: -90.101029, zipCode: "70438"},
	{latitude: 30.859967, longitude: -90.749066, zipCode: "70441"},
	{latitude: 30.703049, longitude: -90.333198, zipCode: "70442"},
	{latitude: 30.630554, longitude: -90.542882, zipCode: "70443"},
	{latitude: 30.897005, longitude: -90.489375, zipCode: "70444"},
	{latitude: 30.371531, longitude: -89.892306, zipCode: "70445"},
	{latitude: 30.634711, longitude: -90.358474, zipCode: "70446"},
	{latitude: 30.422656, longitude: -90.205855, zipCode: "70447"},
	{latitude: 30.36218, longitude: -90.039451, zipCode: "70448"},
	{latitude: 30.273961, longitude: -90.670145, zipCode: "70449"},
	{latitude: 30.942521, longitude: -90.261736, zipCode: "70450"},
	{latitude: 30.549613, longitude: -90.481067, zipCode: "70451"},
	{latitude: 30.416894, longitude: -89.75483, zipCode: "70452"},
	{latitude: 30.685545, longitude: -90.782221, zipCode: "70453"},
	{latitude: 30.362846, longitude: -90.358594, zipCode: "70454"},
	{latitude: 30.529855, longitude: -90.323981, zipCode: "70455"},
	{latitude: 30.787059, longitude: -90.50462, zipCode: "70456"},
	{latitude: 30.527464, longitude: -90.113355, zipCode: "70457"},
	{latitude: 30.262075, longitude: -89.794105, zipCode: "70458"},
	{latitude: 30.299875, longitude: -89.835997, zipCode: "70460"},
	{latitude: 30.234038, longitude: -89.709634, zipCode: "70461"},
	{latitude: 30.376279, longitude: -90.580741, zipCode: "70462"},
	{latitude: 30.65807, longitude: -89.903817, zipCode: "70463"},
	{latitude: 30.541322, longitude: -89.890516, zipCode: "70464"},
	{latitude: 30.875781, longitude: -90.51384, zipCode: "70465"},
	{latitude: 30.568714, longitude: -90.500761, zipCode: "70466"},
	{latitude: 30.404287, longitude: -90.061307, zipCode: "70471"},
	{latitude: 30.239007, longitude: -91.991284, zipCode: "70501"},
	{latitude: 30.171005, longitude: -92.054782, zipCode: "70503"},
	{latitude: 30.195834, longitude: -92.080781, zipCode: "70506"},
	{latitude: 30.278324, longitude: -92.028037, zipCode: "70507"},
	{latitude: 30.155238, longitude: -92.028225, zipCode: "70508"},
	{latitude: 29.894612, longitude: -92.193173, zipCode: "70510"},
	{latitude: 30.415729, longitude: -91.920497, zipCode: "70512"},
	{latitude: 29.899977, longitude: -91.903269, zipCode: "70513"},
	{latitude: 29.845424, longitude: -91.557762, zipCode: "70514"},
	{latitude: 30.452467, longitude: -92.577743, zipCode: "70515"},
	{latitude: 30.370902, longitude: -92.303577, zipCode: "70516"},
	{latitude: 30.290824, longitude: -91.786378, zipCode: "70517"},
	{latitude: 30.134691, longitude: -91.927341, zipCode: "70518"},
	{latitude: 30.081749, longitude: -91.906967, zipCode: "70519"},
	{latitude: 30.329615, longitude: -92.033349, zipCode: "70520"},
	{latitude: 29.748439, longitude: -91.435682, zipCode: "70522"},
	{latitude: 29.872128, longitude: -91.530498, zipCode: "70523"},
	{latitude: 30.557334, longitude: -92.318572, zipCode: "70524"},
	{latitude: 30.408131, longitude: -92.217328, zipCode: "70525"},
	{latitude: 30.210529, longitude: -92.378876, zipCode: "70526"},
	{latitude: 29.884157, longitude: -91.959577, zipCode: "70528"},
	{latitude: 30.202279, longitude: -92.16158, zipCode: "70529"},
	{latitude: 30.231157, longitude: -92.500746, zipCode: "70531"},
	{latitude: 30.4709, longitude: -92.697075, zipCode: "70532"},
	{latitude: 29.901326, longitude: -92.031146, zipCode: "70533"},
	{latitude: 30.165634, longitude: -92.454959, zipCode: "70534"},
	{latitude: 30.471149, longitude: -92.419836, zipCode: "70535"},
	{latitude: 30.251631, longitude: -92.570201, zipCode: "70537"},
	{latitude: 29.80709, longitude: -91.487512, zipCode: "70538"},
	{latitude: 30.420629, longitude: -92.042561, zipCode: "70541"},
	{latitude: 30.009678, longitude: -92.584774, zipCode: "70542"},
	{latitude: 30.335404, longitude: -92.501138, zipCode: "70543"},
	{latitude: 29.905016, longitude: -91.656399, zipCode: "70544"},
	{latitude: 30.266368, longitude: -92.673552, zipCode: "70546"},
	{latitude: 29.790545, longitude: -92.420528, zipCode: "70548"},
	{latitude: 30.046794, longitude: -92.832958, zipCode: "70549"},
	{latitude: 30.515714, longitude: -92.189654, zipCode: "70550"},
	{latitude: 30.461914, longitude: -91.983452, zipCode: "70551"},
	{latitude: 30.042453, longitude: -91.659967, zipCode: "70552"},
	{latitude: 30.629478, longitude: -92.490928, zipCode: "70554"},
	{latitude: 30.086857, longitude: -92.143336, zipCode: "70555"},
	{latitude: 30.190636, longitude: -92.583797, zipCode: "70556"},
	{latitude: 30.100525, longitude: -92.075759, zipCode: "70558"},
	{latitude: 30.139347, longitude: -92.508109, zipCode: "70559"},
	{latitude: 29.937141, longitude: -91.864508, zipCode: "70560"},
	{latitude: 30.029251, longitude: -91.731591, zipCode: "70563"},
	{latitude: 30.531941, longitude: -92.106967, zipCode: "70570"},
	{latitude: 29.948922, longitude: -92.158095, zipCode: "70575"},
	{latitude: 30.780968, longitude: -92.418253, zipCode: "70576"},
	{latitude: 30.549507, longitude: -91.918752, zipCode: "70577"},
	{latitude: 30.230073, longitude: -92.263673, zipCode: "70578"},
	{latitude: 30.675428, longitude: -92.428438, zipCode: "70580"},
	{latitude: 30.258119, longitude: -92.737129, zipCode: "70581"},
	{latitude: 30.163505, longitude: -91.775338, zipCode: "70582"},
	{latitude: 30.261419, longitude: -92.124012, zipCode: "70583"},
	{latitude: 30.388058, longitude: -92.113453, zipCode: "70584"},
	{latitude: 30.878323, longitude: -92.407716, zipCode: "70585"},
	{latitude: 30.727263, longitude: -92.356045, zipCode: "70586"},
	{latitude: 30.684908, longitude: -92.044194, zipCode: "70589"},
	{latitude: 30.257744, longitude: -92.832802, zipCode: "70591"},
	{latitude: 30.077154, longitude: -92.017724, zipCode: "70592"},
	{latitude: 30.226792, longitude: -93.215443, zipCode: "70601"},
	{latitude: 30.129276, longitude: -93.273527, zipCode: "70605"},
	{latitude: 30.038451, longitude: -93.190093, zipCode: "70607"},
	{latitude: 30.179071, longitude: -93.21482, zipCode: "70609"},
	{latitude: 30.348118, longitude: -93.2036, zipCode: "70611"},
	{latitude: 30.254009, longitude: -93.117713, zipCode: "70615"},
	{latitude: 30.231071, longitude: -93.21864, zipCode: "70629"},
	{latitude: 30.03117, longitude: -93.059283, zipCode: "70630"},
	{latitude: 29.853375, longitude: -93.568288, zipCode: "70631"},
	{latitude: 29.883232, longitude: -92.876911, zipCode: "70632"},
	{latitude: 30.426005, longitude: -93.39129, zipCode: "70633"},
	{latitude: 30.799649, longitude: -93.243292, zipCode: "70634"},
	{latitude: 30.707711, longitude: -92.974144, zipCode: "70637"},
	{latitude: 30.842711, longitude: -92.77199, zipCode: "70638"},
	{latitude: 30.983355, longitude: -93.497879, zipCode: "70639"},
	{latitude: 30.365879, longitude: -92.916564, zipCode: "70640"},
	{latitude: 29.737191, longitude: -92.779067, zipCode: "70643"},
	{latitude: 30.792345, longitude: -92.945596, zipCode: "70644"},
	{latitude: 29.928002, longitude: -93.473885, zipCode: "70645"},
	{latitude: 30.105014, longitude: -92.932777, zipCode: "70646"},
	{latitude: 30.247243, longitude: -93.015323, zipCode: "70647"},
	{latitude: 30.505073, longitude: -92.889361, zipCode: "70648"},
	{latitude: 30.233755, longitude: -92.920679, zipCode: "70650"},
	{latitude: 30.571997, longitude: -92.960871, zipCode: "70651"},
	{latitude: 30.613738, longitude: -93.247407, zipCode: "70652"},
	{latitude: 30.667604, longitude: -93.558664, zipCode: "70653"},
	{latitude: 30.6922, longitude: -92.87442, zipCode: "70654"},
	{latitude: 30.625103, longitude: -92.717084, zipCode: "70655"},
	{latitude: 30.914161, longitude: -92.926727, zipCode: "70656"},
	{latitude: 30.500463, longitude: -93.140462, zipCode: "70657"},
	{latitude: 30.521695, longitude: -93.036234, zipCode: "70658"},
	{latitude: 30.921932, longitude: -93.284285, zipCode: "70659"},
	{latitude: 30.57264, longitude: -93.460816, zipCode: "70660"},
	{latitude: 30.353644, longitude: -93.653664, zipCode: "70661"},
	{latitude: 30.798103, longitude: -93.005982, zipCode: "70662"},
	{latitude: 30.278305, longitude: -93.40559, zipCode: "70663"},
	{latitude: 30.121881, longitude: -93.451224, zipCode: "70665"},
	{latitude: 30.175044, longitude: -93.605585, zipCode: "70668"},
	{latitude: 30.242348, longitude: -93.272318, zipCode: "70669"},
	{latitude: 30.607238, longitude: -90.907361, zipCode: "70706"},
	{latitude: 30.358232, longitude: -91.268832, zipCode: "70710"},
	{latitude: 30.524507, longitude: -90.592006, zipCode: "70711"},
	{latitude: 30.969575, longitude: -91.596702, zipCode: "70712"},
	{latitude: 30.587189, longitude: -91.127633, zipCode: "70714"},
	{latitude: 30.814542, longitude: -91.657426, zipCode: "70715"},
	{latitude: 30.363175, longitude: -91.299555, zipCode: "70719"},
	{latitude: 30.223391, longitude: -91.079505, zipCode: "70721"},
	{latitude: 30.854787, longitude: -90.945939, zipCode: "70722"},
	{latitude: 30.059602, longitude: -90.840237, zipCode: "70723"},
	{latitude: 30.132614, longitude: -90.975919, zipCode: "70725"},
	{latitude: 30.433416, longitude: -90.90231, zipCode: "70726"},
	{latitude: 30.587693, longitude: -91.344857, zipCode: "70729"},
	{latitude: 30.809925, longitude: -91.094653, zipCode: "70730"},
	{latitude: 30.635912, longitude: -91.585224, zipCode: "70732"},
	{latitude: 30.289843, longitude: -90.800199, zipCode: "70733"},
	{latitude: 30.206556, longitude: -90.996999, zipCode: "70734"},
	{latitude: 30.633975, longitude: -91.338448, zipCode: "70736"},
	{latitude: 30.226092, longitude: -90.92498, zipCode: "70737"},
	{latitude: 30.601066, longitude: -90.965337, zipCode: "70739"},
	{latitude: 30.310056, longitude: -91.439765, zipCode: "70740"},
	{latitude: 30.025982, longitude: -90.770238, zipCode: "70743"},
	{latitude: 30.55941, longitude: -90.667123, zipCode: "70744"},
	{latitude: 30.87426, longitude: -91.672236, zipCode: "70747"},
	{latitude: 30.815023, longitude: -91.206165, zipCode: "70748"},
	{latitude: 30.629553, longitude: -91.450662, zipCode: "70749"},
	{latitude: 30.46394, longitude: -91.787529, zipCode: "70750"},
	{latitude: 30.595551, longitude: -91.41723, zipCode: "70752"},
	{latitude: 30.638578, longitude: -91.683835, zipCode: "70753"},
	{latitude: 30.40741, longitude: -90.75046, zipCode: "70754"},
	{latitude: 30.594669, longitude: -91.529819, zipCode: "70755"},
	{latitude: 30.523718, longitude: -91.650273, zipCode: "70756"},
	{latitude: 30.368748, longitude: -91.531236, zipCode: "70757"},
	{latitude: 30.712455, longitude: -91.586374, zipCode: "70759"},
	{latitude: 30.699747, longitude: -91.482689, zipCode: "70760"},
	{latitude: 30.974954, longitude: -91.038279, zipCode: "70761"},
	{latitude: 30.57834, longitude: -91.464653, zipCode: "70762"},
	{latitude: 30.048701, longitude: -90.736504, zipCode: "70763"},
	{latitude: 30.234516, longitude: -91.307982, zipCode: "70764"},
	{latitude: 30.481128, longitude: -91.330712, zipCode: "70767"},
	{latitude: 30.30743, longitude: -90.940643, zipCode: "70769"},
	{latitude: 30.651321, longitude: -90.993161, zipCode: "70770"},
	{latitude: 30.437481, longitude: -91.464796, zipCode: "70772"},
	{latitude: 30.596783, longitude: -91.373333, zipCode: "70773"},
	{latitude: 30.214554, longitude: -90.761904, zipCode: "70774"},
	{latitude: 30.858253, longitude: -91.369, zipCode: "70775"},
	{latitude: 30.260016, longitude: -91.091026, zipCode: "70776"},
	{latitude: 30.73968, longitude: -91.07711, zipCode: "70777"},
	{latitude: 30.162048, longitude: -90.818385, zipCode: "70778"},
	{latitude: 30.298735, longitude: -91.185292, zipCode: "70780"},
	{latitude: 30.951919, longitude: -91.511804, zipCode: "70782"},
	{latitude: 30.669405, longitude: -91.416824, zipCode: "70783"},
	{latitude: 30.557101, longitude: -90.819236, zipCode: "70785"},
	{latitude: 30.582437, longitude: -90.954355, zipCode: "70786"},
	{latitude: 30.961632, longitude: -91.44071, zipCode: "70787"},
	{latitude: 30.132093, longitude: -91.183796, zipCode: "70788"},
	{latitude: 30.940581, longitude: -91.082957, zipCode: "70789"},
	{latitude: 30.649627, longitude: -91.157635, zipCode: "70791"},
	{latitude: 30.449581, longitude: -91.185971, zipCode: "70801"},
	{latitude: 30.445471, longitude: -91.17527, zipCode: "70802"},
	{latitude: 30.410008, longitude: -91.174013, zipCode: "70803"},
	{latitude: 30.488923, longitude: -91.158261, zipCode: "70805"},
	{latitude: 30.448797, longitude: -91.124704, zipCode: "70806"},
	{latitude: 30.555634, longitude: -91.208201, zipCode: "70807"},
	{latitude: 30.404146, longitude: -91.139137, zipCode: "70808"},
	{latitude: 30.394049, longitude: -91.07058, zipCode: "70809"},
	{latitude: 30.345423, longitude: -91.094293, zipCode: "70810"},
	{latitude: 30.537682, longitude: -91.134907, zipCode: "70811"},
	{latitude: 30.500175, longitude: -91.110497, zipCode: "70812"},
	{latitude: 30.527861, longitude: -91.197563, zipCode: "70813"},
	{latitude: 30.485018, longitude: -91.061669, zipCode: "70814"},
	{latitude: 30.455363, longitude: -91.066491, zipCode: "70815"},
	{latitude: 30.426892, longitude: -91.027834, zipCode: "70816"},
	{latitude: 30.375109, longitude: -90.980419, zipCode: "70817"},
	{latitude: 30.542313, longitude: -91.050102, zipCode: "70818"},
	{latitude: 30.47174, longitude: -91.007785, zipCode: "70819"},
	{latitude: 30.365415, longitude: -91.202669, zipCode: "70820"},
	{latitude: 30.451972, longitude: -91.187756, zipCode: "70825"},
	{latitude: 30.387574, longitude: -91.083564, zipCode: "70836"},
	{latitude: 32.591884, longitude: -92.911426, zipCode: "71001"},
	{latitude: 32.120379, longitude: -93.11254, zipCode: "71002"},
	{latitude: 32.629546, longitude: -93.030824, zipCode: "71003"},
	{latitude: 32.741443, longitude: -93.860079, zipCode: "71004"},
	{latitude: 32.729183, longitude: -93.656132, zipCode: "71006"},
	{latitude: 32.362527, longitude: -94.006751, zipCode: "71007"},
	{latitude: 32.347123, longitude: -92.968651, zipCode: "71008"},
	{latitude: 32.578008, longitude: -93.891836, zipCode: "71009"},
	{latitude: 32.224441, longitude: -93.116314, zipCode: "71016"},
	{latitude: 32.821036, longitude: -93.432601, zipCode: "71018"},
	{latitude: 32.038461, longitude: -93.306638, zipCode: "71019"},
	{latitude: 32.967708, longitude: -93.446542, zipCode: "71021"},
	{latitude: 32.464514, longitude: -93.40458, zipCode: "71023"},
	{latitude: 32.48892, longitude: -93.219881, zipCode: "71024"},
	{latitude: 32.246949, longitude: -93.686859, zipCode: "71027"},
	{latitude: 32.486338, longitude: -93.076046, zipCode: "71028"},
	{latitude: 32.836168, longitude: -93.833718, zipCode: "71029"},
	{latitude: 32.183671, longitude: -93.793639, zipCode: "71030"},
	{latitude: 32.007616, longitude: -92.897035, zipCode: "71031"},
	{latitude: 32.094865, longitude: -93.795369, zipCode: "71032"},
	{latitude: 32.439845, longitude: -94.00382, zipCode: "71033"},
	{latitude: 32.184222, longitude: -93.304168, zipCode: "71034"},
	{latitude: 32.475082, longitude: -93.48762, zipCode: "71037"},
	{latitude: 32.943192, longitude: -93.072935, zipCode: "71038"},
	{latitude: 32.430294, longitude: -93.2897, zipCode: "71039"},
	{latitude: 32.783708, longitude: -92.998142, zipCode: "71040"},
	{latitude: 32.886012, longitude: -93.887836, zipCode: "71043"},
	{latitude: 32.954178, longitude: -93.882456, zipCode: "71044"},
	{latitude: 32.335904, longitude: -93.162819, zipCode: "71045"},
	{latitude: 32.172426, longitude: -93.935005, zipCode: "71046"},
	{latitude: 32.302147, longitude: -93.905099, zipCode: "71047"},
	{latitude: 32.835964, longitude: -92.850708, zipCode: "71048"},
	{latitude: 32.014274, longitude: -93.971246, zipCode: "71049"},
	{latitude: 32.319454, longitude: -93.477953, zipCode: "71051"},
	{latitude: 32.015449, longitude: -93.656448, zipCode: "71052"},
	{latitude: 32.676883, longitude: -93.293312, zipCode: "71055"},
	{latitude: 32.64095, longitude: -93.98931, zipCode: "71060"},
	{latitude: 32.753997, longitude: -93.976297, zipCode: "71061"},
	{latitude: 31.911513, longitude: -93.514993, zipCode: "71063"},
	{latitude: 32.911171, longitude: -93.688336, zipCode: "71064"},
	{latitude: 31.803198, longitude: -93.502679, zipCode: "71065"},
	{latitude: 31.874781, longitude: -93.198262, zipCode: "71066"},
	{latitude: 32.61081, longitude: -93.463765, zipCode: "71067"},
	{latitude: 32.276729, longitude: -93.303455, zipCode: "71068"},
	{latitude: 32.971292, longitude: -93.988152, zipCode: "71069"},
	{latitude: 32.084929, longitude: -92.98803, zipCode: "71070"},
	{latitude: 32.918355, longitude: -93.449941, zipCode: "71071"},
	{latitude: 32.938004, longitude: -93.312011, zipCode: "71072"},
	{latitude: 32.51414, longitude: -93.311486, zipCode: "71073"},
	{latitude: 32.991404, longitude: -93.479899, zipCode: "71075"},
	{latitude: 32.278203, longitude: -93.797767, zipCode: "71078"},
	{latitude: 32.95697, longitude: -92.808435, zipCode: "71079"},
	{latitude: 32.842181, longitude: -93.978352, zipCode: "71082"},
	{latitude: 32.505424, longitude: -93.748748, zipCode: "71101"},
	{latitude: 32.491546, longitude: -93.771694, zipCode: "71103"},
	{latitude: 32.483775, longitude: -93.731646, zipCode: "71104"},
	{latitude: 32.455146, longitude: -93.709919, zipCode: "71105"},
	{latitude: 32.384654, longitude: -93.736285, zipCode: "71106"},
	{latitude: 32.589392, longitude: -93.861725, zipCode: "71107"},
	{latitude: 32.443173, longitude: -93.787826, zipCode: "71108"},
	{latitude: 32.467911, longitude: -93.813872, zipCode: "71109"},
	{latitude: 32.500371, longitude: -93.630892, zipCode: "71110"},
	{latitude: 32.572361, longitude: -93.696339, zipCode: "71111"},
	{latitude: 32.448826, longitude: -93.645111, zipCode: "71112"},
	{latitude: 32.264471, longitude: -93.56293, zipCode: "71115"},
	{latitude: 32.393679, longitude: -93.804064, zipCode: "71118"},
	{latitude: 32.490669, longitude: -93.925462, zipCode: "71119"},
	{latitude: 32.393039, longitude: -93.926716, zipCode: "71129"},
	{latitude: 32.539557, longitude: -92.106941, zipCode: "71201"},
	{latitude: 32.399906, longitude: -92.05655, zipCode: "71202"},
	{latitude: 32.581991, longitude: -92.018572, zipCode: "71203"},
	{latitude: 32.531362, longitude: -92.070982, zipCode: "71209"},
	{latitude: 32.526084, longitude: -92.074299, zipCode: "71212"},
	{latitude: 32.350797, longitude: -91.787398, zipCode: "71218"},
	{latitude: 32.325706, longitude: -91.69322, zipCode: "71219"},
	{latitude: 32.864539, longitude: -91.908541, zipCode: "71220"},
	{latitude: 32.82922, longitude: -92.660475, zipCode: "71222"},
	{latitude: 32.89102, longitude: -91.67854, zipCode: "71223"},
	{latitude: 32.50693, longitude: -92.350471, zipCode: "71225"},
	{latitude: 32.250749, longitude: -92.437982, zipCode: "71226"},
	{latitude: 32.529605, longitude: -92.474256, zipCode: "71227"},
	{latitude: 32.631923, longitude: -91.896808, zipCode: "71229"},
	{latitude: 32.240305, longitude: -91.592824, zipCode: "71230"},
	{latitude: 32.414389, longitude: -91.496652, zipCode: "71232"},
	{latitude: 32.325145, longitude: -90.931697, zipCode: "71233"},
	{latitude: 32.64523, longitude: -92.330531, zipCode: "71234"},
	{latitude: 32.690015, longitude: -92.673804, zipCode: "71235"},
	{latitude: 32.588352, longitude: -91.481055, zipCode: "71237"},
	{latitude: 32.363057, longitude: -92.375235, zipCode: "71238"},
	{latitude: 32.779372, longitude: -92.352043, zipCode: "71241"},
	{latitude: 32.792233, longitude: -91.412918, zipCode: "71242"},
	{latitude: 31.95173, longitude: -91.816093, zipCode: "71243"},
	{latitude: 32.526936, longitude: -92.71556, zipCode: "71245"},
	{latitude: 32.271289, longitude: -92.720338, zipCode: "71247"},
	{latitude: 32.958209, longitude: -91.572997, zipCode: "71250"},
	{latitude: 32.220631, longitude: -92.67689, zipCode: "71251"},
	{latitude: 32.996085, longitude: -91.315728, zipCode: "71253"},
	{latitude: 32.807682, longitude: -91.235149, zipCode: "71254"},
	{latitude: 32.947741, longitude: -92.70947, zipCode: "71256"},
	{latitude: 32.259266, longitude: -91.848657, zipCode: "71259"},
	{latitude: 32.907919, longitude: -92.234963, zipCode: "71260"},
	{latitude: 32.778105, longitude: -91.694108, zipCode: "71261"},
	{latitude: 32.871845, longitude: -91.425837, zipCode: "71263"},
	{latitude: 32.601281, longitude: -91.772528, zipCode: "71264"},
	{latitude: 32.711035, longitude: -91.477007, zipCode: "71266"},
	{latitude: 32.346067, longitude: -92.73702, zipCode: "71268"},
	{latitude: 32.440635, longitude: -91.793784, zipCode: "71269"},
	{latitude: 32.489856, longitude: -92.643874, zipCode: "71270"},
	{latitude: 32.529187, longitude: -92.651631, zipCode: "71272"},
	{latitude: 32.511899, longitude: -92.816833, zipCode: "71275"},
	{latitude: 32.581774, longitude: -91.159545, zipCode: "71276"},
	{latitude: 32.943343, longitude: -92.550972, zipCode: "71277"},
	{latitude: 32.486719, longitude: -91.856879, zipCode: "71279"},
	{latitude: 32.717701, longitude: -92.101204, zipCode: "71280"},
	{latitude: 32.346878, longitude: -91.231932, zipCode: "71282"},
	{latitude: 32.64739, longitude: -91.23319, zipCode: "71286"},
	{latitude: 32.565188, longitude: -92.173211, zipCode: "71291"},
	{latitude: 32.396681, longitude: -92.214761, zipCode: "71292"},
	{latitude: 32.145309, longitude: -91.708966, zipCode: "71295"},
	{latitude: 31.274156, longitude: -92.467107, zipCode: "71301"},
	{latitude: 31.197018, longitude: -92.37022, zipCode: "71302"},
	{latitude: 31.281321, longitude: -92.547344, zipCode: "71303"},
	{latitude: 31.288968, longitude: -91.755272, zipCode: "71316"},
	{latitude: 31.113686, longitude: -91.776, zipCode: "71320"},
	{latitude: 30.871128, longitude: -92.175542, zipCode: "71322"},
	{latitude: 31.26656, longitude: -92.210375, zipCode: "71323"},
	{latitude: 31.016772, longitude: -92.336244, zipCode: "71325"},
	{latitude: 31.772181, longitude: -91.585739, zipCode: "71326"},
	{latitude: 30.976902, longitude: -92.034859, zipCode: "71327"},
	{latitude: 31.357684, longitude: -92.188903, zipCode: "71328"},
	{latitude: 31.110023, longitude: -92.241499, zipCode: "71330"},
	{latitude: 31.252072, longitude: -92.065984, zipCode: "71331"},
	{latitude: 30.909986, longitude: -92.07187, zipCode: "71333"},
	{latitude: 31.668507, longitude: -91.581596, zipCode: "71334"},
	{latitude: 32.027122, longitude: -91.591595, zipCode: "71336"},
	{latitude: 31.005742, longitude: -91.936359, zipCode: "71339"},
	{latitude: 31.759812, longitude: -91.80805, zipCode: "71340"},
	{latitude: 31.056111, longitude: -92.164117, zipCode: "71341"},
	{latitude: 31.593716, longitude: -92.137601, zipCode: "71342"},
	{latitude: 31.520809, longitude: -91.899171, zipCode: "71343"},
	{latitude: 30.728356, longitude: -91.975451, zipCode: "71345"},
	{latitude: 31.118246, longitude: -92.374928, zipCode: "71346"},
	{latitude: 31.066509, longitude: -92.060267, zipCode: "71350"},
	{latitude: 31.184246, longitude: -91.960829, zipCode: "71351"},
	{latitude: 30.612978, longitude: -91.767213, zipCode: "71353"},
	{latitude: 31.404457, longitude: -91.750053, zipCode: "71354"},
	{latitude: 31.058397, longitude: -91.841215, zipCode: "71355"},
	{latitude: 30.835335, longitude: -92.02834, zipCode: "71356"},
	{latitude: 32.118991, longitude: -91.299302, zipCode: "71357"},
	{latitude: 30.716673, longitude: -91.84786, zipCode: "71358"},
	{latitude: 31.324701, longitude: -92.426319, zipCode: "71359"},
	{latitude: 31.324271, longitude: -92.354146, zipCode: "71360"},
	{latitude: 30.899117, longitude: -91.961057, zipCode: "71362"},
	{latitude: 31.950193, longitude: -91.352398, zipCode: "71366"},
	{latitude: 30.875518, longitude: -92.288288, zipCode: "71367"},
	{latitude: 31.879707, longitude: -91.681882, zipCode: "71368"},
	{latitude: 30.936543, longitude: -91.854161, zipCode: "71369"},
	{latitude: 31.674053, longitude: -92.249684, zipCode: "71371"},
	{latitude: 31.375868, longitude: -91.585156, zipCode: "71373"},
	{latitude: 31.842335, longitude: -91.468245, zipCode: "71375"},
	{latitude: 31.942365, longitude: -91.708659, zipCode: "71378"},
	{latitude: 31.794781, longitude: -91.944613, zipCode: "71401"},
	{latitude: 31.210416, longitude: -93.445374, zipCode: "71403"},
	{latitude: 31.752115, longitude: -92.746016, zipCode: "71404"},
	{latitude: 31.409505, longitude: -92.40173, zipCode: "71405"},
	{latitude: 31.734226, longitude: -93.505319, zipCode: "71406"},
	{latitude: 31.517243, longitude: -92.48646, zipCode: "71407"},
	{latitude: 31.310753, longitude: -92.689221, zipCode: "71409"},
	{latitude: 31.964224, longitude: -92.771219, zipCode: "71410"},
	{latitude: 31.905455, longitude: -93.090534, zipCode: "71411"},
	{latitude: 31.825687, longitude: -93.024517, zipCode: "71414"},
	{latitude: 32.025394, longitude: -92.142355, zipCode: "71415"},
	{latitude: 31.540434, longitude: -92.896951, zipCode: "71416"},
	{latitude: 31.508204, longitude: -92.649646, zipCode: "71417"},
	{latitude: 32.137308, longitude: -92.048469, zipCode: "71418"},
	{latitude: 31.797619, longitude: -93.728964, zipCode: "71419"},
	{latitude: 32.076589, longitude: -92.657982, zipCode: "71422"},
	{latitude: 31.603431, longitude: -92.553342, zipCode: "71423"},
	{latitude: 31.1795, longitude: -92.696673, zipCode: "71424"},
	{latitude: 31.885091, longitude: -91.870687, zipCode: "71425"},
	{latitude: 31.491792, longitude: -93.461665, zipCode: "71426"},
	{latitude: 31.384968, longitude: -92.891088, zipCode: "71427"},
	{latitude: 31.609205, longitude: -93.088533, zipCode: "71428"},
	{latitude: 31.400435, longitude: -93.426223, zipCode: "71429"},
	{latitude: 31.067683, longitude: -92.527582, zipCode: "71430"},
	{latitude: 31.749577, longitude: -92.46771, zipCode: "71432"},
	{latitude: 31.00118, longitude: -92.639215, zipCode: "71433"},
	{latitude: 31.442012, longitude: -92.942844, zipCode: "71434"},
	{latitude: 32.025951, longitude: -92.152261, zipCode: "71435"},
	{latitude: 31.1018, longitude: -92.880251, zipCode: "71438"},
	{latitude: 31.339524, longitude: -93.367906, zipCode: "71439"},
	{latitude: 31.951551, longitude: -92.15343, zipCode: "71441"},
	{latitude: 31.157403, longitude: -93.173885, zipCode: "71446"},
	{latitude: 31.411719, longitude: -92.81051, zipCode: "71447"},
	{latitude: 31.521833, longitude: -93.54044, zipCode: "71449"},
	{latitude: 31.779008, longitude: -93.398733, zipCode: "71450"},
	{latitude: 31.582642, longitude: -92.945043, zipCode: "71452"},
	{latitude: 31.69142, longitude: -92.858382, zipCode: "71454"},
	{latitude: 31.403448, longitude: -92.9895, zipCode: "71455"},
	{latitude: 31.619037, longitude: -92.961449, zipCode: "71456"},
	{latitude: 31.739201, longitude: -93.091426, zipCode: "71457"},
	{latitude: 31.077352, longitude: -93.22042, zipCode: "71459"},
	{latitude: 31.12101, longitude: -93.297735, zipCode: "71461"},
	{latitude: 31.669175, longitude: -93.736203, zipCode: "71462"},
	{latitude: 30.808963, longitude: -92.647273, zipCode: "71463"},
	{latitude: 31.871987, longitude: -92.203887, zipCode: "71465"},
	{latitude: 31.215909, longitude: -92.748853, zipCode: "71466"},
	{latitude: 31.563329, longitude: -92.387104, zipCode: "71467"},
	{latitude: 31.475874, longitude: -93.138491, zipCode: "71468"},
	{latitude: 31.694472, longitude: -93.270631, zipCode: "71469"},
	{latitude: 31.168851, longitude: -92.787163, zipCode: "71472"},
	{latitude: 32.068969, longitude: -92.429741, zipCode: "71473"},
	{latitude: 31.277182, longitude: -93.014278, zipCode: "71474"},
	{latitude: 31.201296, longitude: -93.1246, zipCode: "71475"},
	{latitude: 31.857738, longitude: -92.353053, zipCode: "71479"},
	{latitude: 31.870004, longitude: -92.279, zipCode: "71480"},
	{latitude: 31.892384, longitude: -92.657875, zipCode: "71483"},
	{latitude: 31.173893, longitude: -92.520418, zipCode: "71485"},
	{latitude: 31.627595, longitude: -93.658762, zipCode: "71486"},
	{latitude: 31.749019, longitude: -93.097965, zipCode: "71497"},
	{latitude: 34.17938, longitude: -91.892483, zipCode: "71601"},
	{latitude: 34.27466, longitude: -92.132158, zipCode: "71602"},
	{latitude: 34.121075, longitude: -92.088127, zipCode: "71603"},
	{latitude: 33.61235, longitude: -91.233515, zipCode: "71630"},
	{latitude: 33.581916, longitude: -92.264171, zipCode: "71631"},
	{latitude: 33.13246, longitude: -92.004317, zipCode: "71635"},
	{latitude: 33.520936, longitude: -91.484668, zipCode: "71638"},
	{latitude: 33.886641, longitude: -91.536646, zipCode: "71639"},
	{latitude: 33.121283, longitude: -91.247331, zipCode: "71640"},
	{latitude: 33.388539, longitude: -91.876108, zipCode: "71642"},
	{latitude: 34.016891, longitude: -91.576497, zipCode: "71643"},
	{latitude: 34.09986, longitude: -91.715419, zipCode: "71644"},
	{latitude: 33.207451, longitude: -91.775994, zipCode: "71646"},
	{latitude: 33.36436, longitude: -92.114035, zipCode: "71647"},
	{latitude: 33.326924, longitude: -92.295767, zipCode: "71651"},
	{latitude: 33.89324, longitude: -92.322752, zipCode: "71652"},
	{latitude: 33.371542, longitude: -91.284111, zipCode: "71653"},
	{latitude: 33.621105, longitude: -91.37108, zipCode: "71654"},
	{latitude: 33.628015, longitude: -91.73055, zipCode: "71655"},
	{latitude: 33.325314, longitude: -91.573421, zipCode: "71658"},
	{latitude: 34.135067, longitude: -91.780736, zipCode: "71659"},
	{latitude: 33.76064, longitude: -92.19826, zipCode: "71660"},
	{latitude: 33.146196, longitude: -91.511986, zipCode: "71661"},
	{latitude: 33.81164, longitude: -91.511885, zipCode: "71662"},
	{latitude: 33.226443, longitude: -91.429483, zipCode: "71663"},
	{latitude: 33.93287, longitude: -92.135251, zipCode: "71665"},
	{latitude: 33.744892, longitude: -91.261168, zipCode: "71666"},
	{latitude: 33.93556, longitude: -91.844415, zipCode: "71667"},
	{latitude: 33.745277, longitude: -91.400895, zipCode: "71670"},
	{latitude: 33.602006, longitude: -92.096018, zipCode: "71671"},
	{latitude: 33.826243, longitude: -91.181163, zipCode: "71674"},
	{latitude: 33.606476, longitude: -91.94164, zipCode: "71675"},
	{latitude: 33.051964, longitude: -91.555111, zipCode: "71676"},
	{latitude: 33.76185, longitude: -91.490645, zipCode: "71677"},
	{latitude: 34.024635, longitude: -91.827185, zipCode: "71678"},
	{latitude: 33.579188, longitude: -92.817453, zipCode: "71701"},
	{latitude: 33.775378, longitude: -92.638313, zipCode: "71720"},
	{latitude: 33.890889, longitude: -93.206447, zipCode: "71721"},
	{latitude: 33.721791, longitude: -93.174681, zipCode: "71722"},
	{latitude: 33.334924, longitude: -92.534802, zipCode: "71724"},
	{latitude: 34.039987, longitude: -92.535234, zipCode: "71725"},
	{latitude: 33.682267, longitude: -92.99141, zipCode: "71726"},
	{latitude: 34.008912, longitude: -93.105276, zipCode: "71728"},
	{latitude: 33.201125, longitude: -92.633528, zipCode: "71730"},
	{latitude: 33.076871, longitude: -93.167354, zipCode: "71740"},
	{latitude: 33.827537, longitude: -92.45334, zipCode: "71742"},
	{latitude: 33.889333, longitude: -93.097793, zipCode: "71743"},
	{latitude: 33.496803, longitude: -92.480163, zipCode: "71744"},
	{latitude: 33.499739, longitude: -92.394977, zipCode: "71745"},
	{latitude: 33.053051, longitude: -92.22566, zipCode: "71747"},
	{latitude: 33.902984, longitude: -92.433246, zipCode: "71748"},
	{latitude: 33.071881, longitude: -92.818579, zipCode: "71749"},
	{latitude: 33.406805, longitude: -92.770625, zipCode: "71751"},
	{latitude: 33.391416, longitude: -93.191768, zipCode: "71752"},
	{latitude: 33.225678, longitude: -93.184674, zipCode: "71753"},
	{latitude: 33.309289, longitude: -92.914948, zipCode: "71758"},
	{latitude: 33.31508, longitude: -92.659656, zipCode: "71759"},
	{latitude: 33.342917, longitude: -92.768357, zipCode: "71762"},
	{latitude: 33.905227, longitude: -92.803797, zipCode: "71763"},
	{latitude: 33.431882, longitude: -93.048947, zipCode: "71764"},
	{latitude: 33.141882, longitude: -92.331795, zipCode: "71765"},
	{latitude: 33.734007, longitude: -92.496273, zipCode: "71766"},
	{latitude: 33.390938, longitude: -93.309963, zipCode: "71770"},
	{latitude: 33.833785, longitude: -93.126452, zipCode: "71772"},
	{latitude: 33.623006, longitude: -93.611656, zipCode: "71801"},
	{latitude: 33.785683, longitude: -94.24202, zipCode: "71820"},
	{latitude: 33.670153, longitude: -94.147931, zipCode: "71822"},
	{latitude: 33.829113, longitude: -94.123319, zipCode: "71823"},
	{latitude: 33.877866, longitude: -93.556441, zipCode: "71825"},
	{latitude: 33.09254, longitude: -93.723219, zipCode: "71826"},
	{latitude: 33.389945, longitude: -93.409857, zipCode: "71827"},
	{latitude: 33.771695, longitude: -93.851461, zipCode: "71831"},
	{latitude: 34.041462, longitude: -94.357126, zipCode: "71832"},
	{latitude: 34.134854, longitude: -94.023014, zipCode: "71833"},
	{latitude: 33.114843, longitude: -93.957845, zipCode: "71834"},
	{latitude: 33.675464, longitude: -93.451161, zipCode: "71835"},
	{latitude: 33.713096, longitude: -94.394164, zipCode: "71836"},
	{latitude: 33.254898, longitude: -93.845308, zipCode: "71837"},
	{latitude: 33.653606, longitude: -93.793448, zipCode: "71838"},
	{latitude: 33.292126, longitude: -93.743268, zipCode: "71839"},
	{latitude: 34.161997, longitude: -94.339515, zipCode: "71841"},
	{latitude: 33.893347, longitude: -94.292923, zipCode: "71842"},
	{latitude: 33.315589, longitude: -93.643958, zipCode: "71845"},
	{latitude: 33.924063, longitude: -94.124186, zipCode: "71846"},
	{latitude: 33.921878, longitude: -93.630167, zipCode: "71847"},
	{latitude: 33.856659, longitude: -93.942522, zipCode: "71851"},
	{latitude: 33.985571, longitude: -93.85297, zipCode: "71852"},
	{latitude: 33.593799, longitude: -93.938035, zipCode: "71853"},
	{latitude: 33.444906, longitude: -93.894766, zipCode: "71854"},
	{latitude: 33.863124, longitude: -93.740145, zipCode: "71855"},
	{latitude: 33.801479, longitude: -93.337654, zipCode: "71857"},
	{latitude: 33.585124, longitude: -93.26375, zipCode: "71858"},
	{latitude: 33.722244, longitude: -93.923077, zipCode: "71859"},
	{latitude: 33.309324, longitude: -93.492407, zipCode: "71860"},
	{latitude: 33.100688, longitude: -93.499923, zipCode: "71861"},
	{latitude: 33.779653, longitude: -93.728054, zipCode: "71862"},
	{latitude: 33.508168, longitude: -93.289815, zipCode: "71864"},
	{latitude: 33.741705, longitude: -94.145956, zipCode: "71865"},
	{latitude: 33.868442, longitude: -94.380289, zipCode: "71866"},
	{latitude: 34.52645, longitude: -92.97407, zipCode: "71901"},
	{latitude: 34.642151, longitude: -92.989927, zipCode: "71909"},
	{latitude: 34.446001, longitude: -93.096602, zipCode: "71913"},
	{latitude: 34.250818, longitude: -93.405281, zipCode: "71921"},
	{latitude: 34.023241, longitude: -93.413401, zipCode: "71922"},
	{latitude: 34.088935, longitude: -93.055583, zipCode: "71923"},
	{latitude: 34.303737, longitude: -93.159556, zipCode: "71929"},
	{latitude: 34.413747, longitude: -93.355793, zipCode: "71933"},
	{latitude: 34.394967, longitude: -93.751325, zipCode: "71935"},
	{latitude: 34.398539, longitude: -94.381645, zipCode: "71937"},
	{latitude: 34.01347, longitude: -93.50325, zipCode: "71940"},
	{latitude: 34.249093, longitude: -92.955284, zipCode: "71941"},
	{latitude: 34.221234, longitude: -92.994236, zipCode: "71942"},
	{latitude: 34.332363, longitude: -93.586172, zipCode: "71943"},
	{latitude: 34.240165, longitude: -94.321995, zipCode: "71944"},
	{latitude: 34.500308, longitude: -94.384724, zipCode: "71945"},
	{latitude: 34.687439, longitude: -93.213413, zipCode: "71949"},
	{latitude: 34.256158, longitude: -93.718063, zipCode: "71950"},
	{latitude: 34.276513, longitude: -93.821435, zipCode: "71952"},
	{latitude: 34.616046, longitude: -94.210584, zipCode: "71953"},
	{latitude: 34.610493, longitude: -93.133011, zipCode: "71956"},
	{latitude: 34.563818, longitude: -93.595537, zipCode: "71957"},
	{latitude: 34.112359, longitude: -93.65565, zipCode: "71958"},
	{latitude: 34.2499, longitude: -93.928716, zipCode: "71959"},
	{latitude: 34.472746, longitude: -93.693273, zipCode: "71960"},
	{latitude: 34.600315, longitude: -93.83836, zipCode: "71961"},
	{latitude: 34.04887, longitude: -93.361722, zipCode: "71962"},
	{latitude: 34.424134, longitude: -93.240731, zipCode: "71964"},
	{latitude: 34.654748, longitude: -93.737364, zipCode: "71965"},
	{latitude: 34.52417, longitude: -93.290493, zipCode: "71968"},
	{latitude: 34.669242, longitude: -93.62921, zipCode: "71969"},
	{latitude: 34.668934, longitude: -93.489154, zipCode: "71970"},
	{latitude: 34.303846, longitude: -94.034364, zipCode: "71971"},
	{latitude: 34.389092, longitude: -94.242016, zipCode: "71972"},
	{latitude: 34.285151, longitude: -94.302667, zipCode: "71973"},
	{latitude: 34.12618, longitude: -93.052783, zipCode: "71998"},
	{latitude: 34.127255, longitude: -93.059309, zipCode: "71999"},
	{latitude: 35.058317, longitude: -92.885863, zipCode: "72001"},
	{latitude: 34.665968, longitude: -92.540194, zipCode: "72002"},
	{latitude: 34.38013, longitude: -91.405022, zipCode: "72003"},
	{latitude: 34.394188, longitude: -91.86177, zipCode: "72004"},
	{latitude: 35.537189, longitude: -91.070427, zipCode: "72005"},
	{latitude: 35.243873, longitude: -91.359583, zipCode: "72006"},
	{latitude: 34.999311, longitude: -91.979237, zipCode: "72007"},
	{latitude: 35.312605, longitude: -91.524084, zipCode: "72010"},
	{latitude: 34.500623, longitude: -92.465885, zipCode: "72011"},
	{latitude: 35.099105, longitude: -91.930254, zipCode: "72012"},
	{latitude: 35.455344, longitude: -92.368472, zipCode: "72013"},
	{latitude: 35.430262, longitude: -91.112129, zipCode: "72014"},
	{latitude: 34.485985, longitude: -92.585077, zipCode: "72015"},
	{latitude: 34.9834, longitude: -92.631977, zipCode: "72016"},
	{latitude: 34.887328, longitude: -91.387903, zipCode: "72017"},
	{latitude: 34.633802, longitude: -92.699001, zipCode: "72019"},
	{latitude: 35.472466, longitude: -91.469203, zipCode: "72020"},
	{latitude: 34.856494, longitude: -91.216285, zipCode: "72021"},
	{latitude: 34.606423, longitude: -92.493912, zipCode: "72022"},
	{latitude: 34.964339, longitude: -92.063643, zipCode: "72023"},
	{latitude: 34.752292, longitude: -91.74381, zipCode: "72024"},
	{latitude: 35.052328, longitude: -92.999375, zipCode: "72025"},
	{latitude: 34.517311, longitude: -91.317798, zipCode: "72026"},
	{latitude: 35.369809, longitude: -92.57902, zipCode: "72027"},
	{latitude: 35.543456, longitude: -92.431432, zipCode: "72028"},
	{latitude: 34.710347, longitude: -91.278025, zipCode: "72029"},
	{latitude: 35.427088, longitude: -92.675908, zipCode: "72030"},
	{latitude: 35.603748, longitude: -92.520925, zipCode: "72031"},
	{latitude: 35.065558, longitude: -92.362771, zipCode: "72032"},
	{latitude: 35.053787, longitude: -92.47871, zipCode: "72034"},
	{latitude: 35.078887, longitude: -92.458269, zipCode: "72035"},
	{latitude: 35.011116, longitude: -91.273098, zipCode: "72036"},
	{latitude: 34.452648, longitude: -91.238203, zipCode: "72038"},
	{latitude: 35.356086, longitude: -92.397345, zipCode: "72039"},
	{latitude: 34.96967, longitude: -91.532797, zipCode: "72040"},
	{latitude: 34.733825, longitude: -91.47115, zipCode: "72041"},
	{latitude: 34.274591, longitude: -91.323299, zipCode: "72042"},
	{latitude: 35.693152, longitude: -92.169769, zipCode: "72044"},
	{latitude: 35.093088, longitude: -92.077385, zipCode: "72045"},
	{latitude: 34.559321, longitude: -91.941489, zipCode: "72046"},
	{latitude: 35.228094, longitude: -92.210596, zipCode: "72047"},
	{latitude: 34.225236, longitude: -91.111548, zipCode: "72048"},
	{latitude: 35.794736, longitude: -92.315027, zipCode: "72051"},
	{latitude: 35.142496, longitude: -91.779184, zipCode: "72052"},
	{latitude: 34.700535, longitude: -92.228432, zipCode: "72053"},
	{latitude: 34.098693, longitude: -91.35419, zipCode: "72055"},
	{latitude: 34.131153, longitude: -92.326497, zipCode: "72057"},
	{latitude: 35.245865, longitude: -92.374241, zipCode: "72058"},
	{latitude: 35.154708, longitude: -91.342138, zipCode: "72059"},
	{latitude: 35.077141, longitude: -91.570454, zipCode: "72060"},
	{latitude: 35.324341, longitude: -92.289884, zipCode: "72061"},
	{latitude: 35.318066, longitude: -92.790118, zipCode: "72063"},
	{latitude: 34.796673, longitude: -91.618453, zipCode: "72064"},
	{latitude: 34.534624, longitude: -92.286377, zipCode: "72065"},
	{latitude: 34.978458, longitude: -91.71668, zipCode: "72066"},
	{latitude: 35.562102, longitude: -92.16594, zipCode: "72067"},
	{latitude: 35.143961, longitude: -91.721213, zipCode: "72068"},
	{latitude: 34.53214, longitude: -91.137845, zipCode: "72069"},
	{latitude: 35.014877, longitude: -92.700586, zipCode: "72070"},
	{latitude: 34.516622, longitude: -91.765041, zipCode: "72072"},
	{latitude: 34.368496, longitude: -91.653968, zipCode: "72073"},
	{latitude: 35.065658, longitude: -91.1054, zipCode: "72074"},
	{latitude: 35.640856, longitude: -91.31073, zipCode: "72075"},
	{latitude: 34.912086, longitude: -92.137672, zipCode: "72076"},
	{latitude: 34.389235, longitude: -92.194478, zipCode: "72079"},
	{latitude: 35.426935, longitude: -92.844394, zipCode: "72080"},
	{latitude: 35.381616, longitude: -91.670102, zipCode: "72081"},
	{latitude: 35.238631, longitude: -91.66935, zipCode: "72082"},
	{latitude: 34.605783, longitude: -92.01177, zipCode: "72083"},
	{latitude: 34.137096, longitude: -92.6895, zipCode: "72084"},
	{latitude: 35.366681, longitude: -91.827731, zipCode: "72085"},
	{latitude: 34.806999, longitude: -91.907634, zipCode: "72086"},
	{latitude: 34.59673, longitude: -92.826193, zipCode: "72087"},
	{latitude: 35.602275, longitude: -92.261009, zipCode: "72088"},
	{latitude: 34.900648, longitude: -92.154384, zipCode: "72099"},
	{latitude: 35.228342, longitude: -91.150668, zipCode: "72101"},
	{latitude: 35.141903, longitude: -91.829167, zipCode: "72102"},
	{latitude: 34.590777, longitude: -92.381266, zipCode: "72103"},
	{latitude: 34.346385, longitude: -92.815479, zipCode: "72104"},
	{latitude: 34.973928, longitude: -92.525787, zipCode: "72106"},
	{latitude: 35.141743, longitude: -92.536054, zipCode: "72107"},
	{latitude: 34.728038, longitude: -91.103615, zipCode: "72108"},
	{latitude: 35.154561, longitude: -92.772448, zipCode: "72110"},
	{latitude: 35.238551, longitude: -92.131305, zipCode: "72111"},
	{latitude: 35.580917, longitude: -91.229715, zipCode: "72112"},
	{latitude: 34.859573, longitude: -92.397599, zipCode: "72113"},
	{latitude: 34.764902, longitude: -92.260348, zipCode: "72114"},
	{latitude: 34.800258, longitude: -92.245317, zipCode: "72116"},
	{latitude: 34.780634, longitude: -92.140428, zipCode: "72117"},
	{latitude: 34.840468, longitude: -92.326035, zipCode: "72118"},
	{latitude: 34.836907, longitude: -92.295044, zipCode: "72119"},
	{latitude: 34.902228, longitude: -92.240817, zipCode: "72120"},
	{latitude: 35.455643, longitude: -91.785232, zipCode: "72121"},
	{latitude: 34.76976, longitude: -92.78031, zipCode: "72122"},
	{latitude: 35.257764, longitude: -91.236698, zipCode: "72123"},
	{latitude: 35.066215, longitude: -92.790635, zipCode: "72125"},
	{latitude: 34.943121, longitude: -92.908561, zipCode: "72126"},
	{latitude: 35.17311, longitude: -92.606047, zipCode: "72127"},
	{latitude: 34.313396, longitude: -92.63536, zipCode: "72128"},
	{latitude: 34.311744, longitude: -92.554155, zipCode: "72129"},
	{latitude: 35.661399, longitude: -92.056438, zipCode: "72130"},
	{latitude: 35.40412, longitude: -92.19102, zipCode: "72131"},
	{latitude: 34.439337, longitude: -92.188484, zipCode: "72132"},
	{latitude: 34.096022, longitude: -91.483353, zipCode: "72133"},
	{latitude: 34.61777, longitude: -91.350611, zipCode: "72134"},
	{latitude: 34.879554, longitude: -92.588996, zipCode: "72135"},
	{latitude: 35.240238, longitude: -92.03032, zipCode: "72136"},
	{latitude: 35.336271, longitude: -92.042179, zipCode: "72137"},
	{latitude: 35.362156, longitude: -91.509915, zipCode: "72139"},
	{latitude: 34.380707, longitude: -91.186485, zipCode: "72140"},
	{latitude: 35.521886, longitude: -92.695392, zipCode: "72141"},
	{latitude: 34.673729, longitude: -92.073923, zipCode: "72142"},
	{latitude: 35.226714, longitude: -91.736844, zipCode: "72143"},
	{latitude: 34.310188, longitude: -92.397419, zipCode: "72150"},
	{latitude: 34.356114, longitude: -91.979332, zipCode: "72152"},
	{latitude: 35.65606, longitude: -92.321852, zipCode: "72153"},
	{latitude: 35.287278, longitude: -92.682972, zipCode: "72156"},
	{latitude: 35.273655, longitude: -92.565675, zipCode: "72157"},
	{latitude: 34.442806, longitude: -91.529738, zipCode: "72160"},
	{latitude: 35.576596, longitude: -91.4665, zipCode: "72165"},
	{latitude: 34.069097, longitude: -91.221874, zipCode: "72166"},
	{latitude: 34.425121, longitude: -92.650983, zipCode: "72167"},
	{latitude: 34.434521, longitude: -91.96555, zipCode: "72168"},
	{latitude: 35.394537, longitude: -91.226888, zipCode: "72169"},
	{latitude: 34.574355, longitude: -91.421539, zipCode: "72170"},
	{latitude: 35.100101, longitude: -92.211144, zipCode: "72173"},
	{latitude: 34.33704, longitude: -91.757363, zipCode: "72175"},
	{latitude: 34.992756, longitude: -91.851804, zipCode: "72176"},
	{latitude: 35.203821, longitude: -91.612682, zipCode: "72178"},
	{latitude: 35.497725, longitude: -91.865641, zipCode: "72179"},
	{latitude: 34.531162, longitude: -92.214438, zipCode: "72180"},
	{latitude: 35.162396, longitude: -92.445162, zipCode: "72181"},
	{latitude: 34.431083, longitude: -92.092137, zipCode: "72182"},
	{latitude: 34.6015, longitude: -92.192268, zipCode: "72183"},
	{latitude: 34.828288, longitude: -92.290419, zipCode: "72199"},
	{latitude: 34.747026, longitude: -92.280938, zipCode: "72201"},
	{latitude: 34.73813, longitude: -92.247251, zipCode: "72202"},
	{latitude: 34.717572, longitude: -92.358332, zipCode: "72204"},
	{latitude: 34.749493, longitude: -92.35144, zipCode: "72205"},
	{latitude: 34.633113, longitude: -92.253224, zipCode: "72206"},
	{latitude: 34.775843, longitude: -92.342614, zipCode: "72207"},
	{latitude: 34.682543, longitude: -92.341564, zipCode: "72209"},
	{latitude: 34.7139, longitude: -92.50845, zipCode: "72210"},
	{latitude: 34.746283, longitude: -92.417157, zipCode: "72211"},
	{latitude: 34.785888, longitude: -92.415028, zipCode: "72212"},
	{latitude: 34.790813, longitude: -92.495154, zipCode: "72223"},
	{latitude: 34.77685, longitude: -92.373303, zipCode: "72227"},
	{latitude: 35.13432, longitude: -90.179292, zipCode: "72301"},
	{latitude: 34.720727, longitude: -90.896838, zipCode: "72311"},
	{latitude: 35.530795, longitude: -90.13947, zipCode: "72313"},
	{latitude: 35.899435, longitude: -89.899259, zipCode: "72315"},
	{latitude: 34.81107, longitude: -90.542405, zipCode: "72320"},
	{latitude: 35.068836, longitude: -90.80794, zipCode: "72322"},
	{latitude: 35.390418, longitude: -90.786566, zipCode: "72324"},
	{latitude: 35.33068, longitude: -90.25394, zipCode: "72325"},
	{latitude: 35.116264, longitude: -90.907852, zipCode: "72326"},
	{latitude: 35.243418, longitude: -90.333854, zipCode: "72327"},
	{latitude: 34.013236, longitude: -91.108775, zipCode: "72328"},
	{latitude: 35.623914, longitude: -89.971523, zipCode: "72329"},
	{latitude: 35.591372, longitude: -90.201839, zipCode: "72330"},
	{latitude: 35.281026, longitude: -90.466302, zipCode: "72331"},
	{latitude: 35.101162, longitude: -90.302348, zipCode: "72332"},
	{latitude: 34.286699, longitude: -90.881176, zipCode: "72333"},
	{latitude: 34.99439, longitude: -90.746054, zipCode: "72335"},
	{latitude: 35.432015, longitude: -90.14224, zipCode: "72338"},
	{latitude: 35.416034, longitude: -90.264922, zipCode: "72339"},
	{latitude: 34.958544, longitude: -91.038698, zipCode: "72340"},
	{latitude: 34.87839, longitude: -90.769095, zipCode: "72341"},
	{latitude: 34.447871, longitude: -90.674778, zipCode: "72342"},
	{latitude: 35.082326, longitude: -90.48449, zipCode: "72346"},
	{latitude: 35.381764, longitude: -90.992507, zipCode: "72347"},
	{latitude: 34.941764, longitude: -90.417889, zipCode: "72348"},
	{latitude: 35.499779, longitude: -90.12918, zipCode: "72350"},
	{latitude: 35.675945, longitude: -90.097725, zipCode: "72351"},
	{latitude: 34.655967, longitude: -90.734283, zipCode: "72352"},
	{latitude: 34.322777, longitude: -90.996365, zipCode: "72353"},
	{latitude: 35.642289, longitude: -90.312623, zipCode: "72354"},
	{latitude: 34.464893, longitude: -90.852339, zipCode: "72355"},
	{latitude: 35.792094, longitude: -89.892431, zipCode: "72358"},
	{latitude: 35.018514, longitude: -90.71833, zipCode: "72359"},
	{latitude: 34.750303, longitude: -90.780769, zipCode: "72360"},
	{latitude: 35.214002, longitude: -90.189576, zipCode: "72364"},
	{latitude: 35.563201, longitude: -90.420076, zipCode: "72365"},
	{latitude: 34.525653, longitude: -90.951772, zipCode: "72366"},
	{latitude: 34.184851, longitude: -91.015236, zipCode: "72367"},
	{latitude: 34.810402, longitude: -91.023371, zipCode: "72368"},
	{latitude: 34.455236, longitude: -90.789531, zipCode: "72369"},
	{latitude: 35.672875, longitude: -90.05801, zipCode: "72370"},
	{latitude: 34.96759, longitude: -90.950562, zipCode: "72372"},
	{latitude: 35.285814, longitude: -90.606692, zipCode: "72373"},
	{latitude: 34.563424, longitude: -90.838469, zipCode: "72374"},
	{latitude: 35.088428, longitude: -90.308, zipCode: "72376"},
	{latitude: 35.675299, longitude: -90.340104, zipCode: "72377"},
	{latitude: 34.069046, longitude: -91.048826, zipCode: "72379"},
	{latitude: 34.491175, longitude: -91.039601, zipCode: "72383"},
	{latitude: 35.375795, longitude: -90.219489, zipCode: "72384"},
	{latitude: 35.461832, longitude: -90.3694, zipCode: "72386"},
	{latitude: 35.315679, longitude: -90.772679, zipCode: "72387"},
	{latitude: 34.372315, longitude: -90.883883, zipCode: "72389"},
	{latitude: 34.551084, longitude: -90.679239, zipCode: "72390"},
	{latitude: 35.676512, longitude: -90.269545, zipCode: "72391"},
	{latitude: 34.965598, longitude: -91.100606, zipCode: "72392"},
	{latitude: 35.058646, longitude: -90.609059, zipCode: "72394"},
	{latitude: 35.591618, longitude: -90.024744, zipCode: "72395"},
	{latitude: 35.229655, longitude: -90.831944, zipCode: "72396"},
	{latitude: 35.830646, longitude: -90.578121, zipCode: "72401"},
	{latitude: 35.775228, longitude: -90.785018, zipCode: "72404"},
	{latitude: 35.90269, longitude: -90.650204, zipCode: "72405"},
	{latitude: 35.947477, longitude: -91.097648, zipCode: "72410"},
	{latitude: 35.745029, longitude: -90.584413, zipCode: "72411"},
	{latitude: 36.125736, longitude: -90.691725, zipCode: "72412"},
	{latitude: 36.303813, longitude: -90.815443, zipCode: "72413"},
	{latitude: 35.835097, longitude: -90.370007, zipCode: "72414"},
	{latitude: 36.126668, longitude: -91.175013, zipCode: "72415"},
	{latitude: 35.960914, longitude: -90.783329, zipCode: "72416"},
	{latitude: 35.91569, longitude: -90.532225, zipCode: "72417"},
	{latitude: 35.737271, longitude: -90.353441, zipCode: "72419"},
	{latitude: 35.778842, longitude: -90.97891, zipCode: "72421"},
	{latitude: 36.424558, longitude: -90.519488, zipCode: "72422"},
	{latitude: 36.391856, longitude: -90.728726, zipCode: "72424"},
	{latitude: 36.228844, longitude: -90.745296, zipCode: "72425"},
	{latitude: 35.856098, longitude: -90.041596, zipCode: "72426"},
	{latitude: 35.868766, longitude: -90.948959, zipCode: "72427"},
	{latitude: 35.741421, longitude: -90.216729, zipCode: "72428"},
	{latitude: 35.492674, longitude: -90.941747, zipCode: "72429"},
	{latitude: 36.335981, longitude: -90.173615, zipCode: "72430"},
	{latitude: 35.632571, longitude: -91.079409, zipCode: "72431"},
	{latitude: 35.55307, longitude: -90.725948, zipCode: "72432"},
	{latitude: 36.025347, longitude: -91.038162, zipCode: "72433"},
	{latitude: 36.217536, longitude: -91.156352, zipCode: "72434"},
	{latitude: 36.313068, longitude: -90.552888, zipCode: "72435"},
	{latitude: 36.239729, longitude: -90.47999, zipCode: "72436"},
	{latitude: 35.830482, longitude: -90.456102, zipCode: "72437"},
	{latitude: 35.941708, longitude: -90.226926, zipCode: "72438"},
	{latitude: 35.992386, longitude: -91.264695, zipCode: "72440"},
	{latitude: 36.435603, longitude: -90.389364, zipCode: "72441"},
	{latitude: 35.825848, longitude: -90.186546, zipCode: "72442"},
	{latitude: 36.180201, longitude: -90.402926, zipCode: "72443"},
	{latitude: 36.428649, longitude: -90.852789, zipCode: "72444"},
	{latitude: 35.978197, longitude: -91.026586, zipCode: "72445"},
	{latitude: 35.908785, longitude: -90.328361, zipCode: "72447"},
	{latitude: 36.166607, longitude: -90.817171, zipCode: "72449"},
	{latitude: 36.079547, longitude: -90.51644, zipCode: "72450"},
	{latitude: 36.306385, longitude: -90.667004, zipCode: "72453"},
	{latitude: 36.406778, longitude: -90.202659, zipCode: "72454"},
	{latitude: 36.321334, longitude: -91.048884, zipCode: "72455"},
	{latitude: 36.448592, longitude: -90.325407, zipCode: "72456"},
	{latitude: 36.085439, longitude: -91.068234, zipCode: "72457"},
	{latitude: 36.068281, longitude: -91.155588, zipCode: "72458"},
	{latitude: 36.271449, longitude: -91.297478, zipCode: "72459"},
	{latitude: 36.347012, longitude: -91.153514, zipCode: "72460"},
	{latitude: 36.266319, longitude: -90.2742, zipCode: "72461"},
	{latitude: 36.389232, longitude: -90.759315, zipCode: "72462"},
	{latitude: 36.453208, longitude: -90.145504, zipCode: "72464"},
	{latitude: 36.041989, longitude: -91.329589, zipCode: "72466"},
	{latitude: 35.842221, longitude: -90.677602, zipCode: "72467"},
	{latitude: 35.951825, longitude: -91.289073, zipCode: "72469"},
	{latitude: 36.474682, longitude: -90.692281, zipCode: "72470"},
	{latitude: 35.824776, longitude: -91.121353, zipCode: "72471"},
	{latitude: 35.594379, longitude: -90.547553, zipCode: "72472"},
	{latitude: 35.738276, longitude: -91.171315, zipCode: "72473"},
	{latitude: 36.04611, longitude: -90.916792, zipCode: "72476"},
	{latitude: 36.465214, longitude: -91.053688, zipCode: "72478"},
	{latitude: 35.631671, longitude: -90.898939, zipCode: "72479"},
	{latitude: 36.268749, longitude: -91.358891, zipCode: "72482"},
	{latitude: 35.79118, longitude: -91.6534, zipCode: "72501"},
	{latitude: 36.222377, longitude: -91.750663, zipCode: "72512"},
	{latitude: 36.231448, longitude: -91.647677, zipCode: "72513"},
	{latitude: 36.27639, longitude: -92.056388, zipCode: "72515"},
	{latitude: 36.146299, longitude: -91.95408, zipCode: "72517"},
	{latitude: 36.134123, longitude: -92.180309, zipCode: "72519"},
	{latitude: 36.39809, longitude: -91.72473, zipCode: "72520"},
	{latitude: 35.949672, longitude: -91.541117, zipCode: "72521"},
	{latitude: 35.80471, longitude: -91.466823, zipCode: "72522"},
	{latitude: 35.627793, longitude: -91.844656, zipCode: "72523"},
	{latitude: 35.840215, longitude: -91.311438, zipCode: "72524"},
	{latitude: 35.877612, longitude: -91.766207, zipCode: "72526"},
	{latitude: 35.740376, longitude: -91.68317, zipCode: "72527"},
	{latitude: 36.238176, longitude: -92.114983, zipCode: "72528"},
	{latitude: 36.289134, longitude: -91.567606, zipCode: "72529"},
	{latitude: 35.616447, longitude: -91.967098, zipCode: "72530"},
	{latitude: 36.313953, longitude: -92.147815, zipCode: "72531"},
	{latitude: 36.086297, longitude: -91.588552, zipCode: "72532"},
	{latitude: 35.977941, longitude: -92.245263, zipCode: "72533"},
	{latitude: 35.590036, longitude: -91.73681, zipCode: "72534"},
	{latitude: 36.143874, longitude: -91.768166, zipCode: "72536"},
	{latitude: 36.448984, longitude: -92.234056, zipCode: "72537"},
	{latitude: 36.445567, longitude: -92.100292, zipCode: "72538"},
	{latitude: 36.330106, longitude: -91.722581, zipCode: "72539"},
	{latitude: 35.919431, longitude: -91.910055, zipCode: "72540"},
	{latitude: 36.300396, longitude: -91.465971, zipCode: "72542"},
	{latitude: 35.459891, longitude: -91.99849, zipCode: "72543"},
	{latitude: 36.403646, longitude: -92.189682, zipCode: "72544"},
	{latitude: 35.577135, longitude: -91.944853, zipCode: "72546"},
	{latitude: 35.710135, longitude: -91.783873, zipCode: "72550"},
	{latitude: 35.7009, longitude: -91.482101, zipCode: "72553"},
	{latitude: 36.443532, longitude: -91.555087, zipCode: "72554"},
	{latitude: 35.768733, longitude: -91.871509, zipCode: "72555"},
	{latitude: 36.013879, longitude: -91.97001, zipCode: "72556"},
	{latitude: 35.840811, longitude: -92.079416, zipCode: "72560"},
	{latitude: 35.932392, longitude: -91.813154, zipCode: "72561"},
	{latitude: 35.730615, longitude: -91.394453, zipCode: "72562"},
	{latitude: 35.607044, longitude: -91.447244, zipCode: "72564"},
	{latitude: 36.21916, longitude: -91.908365, zipCode: "72565"},
	{latitude: 36.207916, longitude: -92.064676, zipCode: "72566"},
	{latitude: 35.817453, longitude: -91.919775, zipCode: "72567"},
	{latitude: 35.566789, longitude: -91.621471, zipCode: "72568"},
	{latitude: 36.081124, longitude: -91.458219, zipCode: "72569"},
	{latitude: 35.641673, longitude: -91.55121, zipCode: "72571"},
	{latitude: 35.897608, longitude: -91.29177, zipCode: "72572"},
	{latitude: 36.076165, longitude: -91.797364, zipCode: "72573"},
	{latitude: 36.348507, longitude: -91.856063, zipCode: "72576"},
	{latitude: 36.033447, longitude: -91.711961, zipCode: "72577"},
	{latitude: 36.474086, longitude: -91.859442, zipCode: "72578"},
	{latitude: 35.842624, longitude: -91.44487, zipCode: "72579"},
	{latitude: 35.547053, longitude: -91.96746, zipCode: "72581"},
	{latitude: 36.414782, longitude: -91.994123, zipCode: "72583"},
	{latitude: 36.147922, longitude: -91.847342, zipCode: "72584"},
	{latitude: 36.178603, longitude: -92.014578, zipCode: "72585"},
	{latitude: 36.219736, longitude: -91.817286, zipCode: "72587"},
	{latitude: 36.238038, longitude: -93.085958, zipCode: "72601"},
	{latitude: 36.246858, longitude: -93.316997, zipCode: "72611"},
	{latitude: 36.315419, longitude: -93.01203, zipCode: "72615"},
	{latitude: 36.348327, longitude: -93.550846, zipCode: "72616"},
	{latitude: 36.019997, longitude: -92.386927, zipCode: "72617"},
	{latitude: 36.37357, longitude: -92.591504, zipCode: "72619"},
	{latitude: 36.459148, longitude: -92.292698, zipCode: "72623"},
	{latitude: 36.082078, longitude: -93.363694, zipCode: "72624"},
	{latitude: 36.322178, longitude: -92.551534, zipCode: "72626"},
	{latitude: 35.839875, longitude: -93.281762, zipCode: "72628"},
	{latitude: 35.738502, longitude: -92.566795, zipCode: "72629"},
	{latitude: 36.461353, longitude: -92.92079, zipCode: "72630"},
	{latitude: 36.469075, longitude: -93.728427, zipCode: "72631"},
	{latitude: 36.373296, longitude: -93.743854, zipCode: "72632"},
	{latitude: 36.140942, longitude: -92.870884, zipCode: "72633"},
	{latitude: 36.233065, longitude: -92.551825, zipCode: "72634"},
	{latitude: 36.317361, longitude: -92.483979, zipCode: "72635"},
	{latitude: 35.990981, longitude: -92.716181, zipCode: "72636"},
	{latitude: 36.328182, longitude: -93.402386, zipCode: "72638"},
	{latitude: 36.021866, longitude: -92.496667, zipCode: "72639"},
	{latitude: 36.002794, longitude: -93.05943, zipCode: "72640"},
	{latitude: 35.997041, longitude: -93.212373, zipCode: "72641"},
	{latitude: 36.370544, longitude: -92.544307, zipCode: "72642"},
	{latitude: 36.414606, longitude: -92.967758, zipCode: "72644"},
	{latitude: 35.804129, longitude: -92.575389, zipCode: "72645"},
	{latitude: 36.087809, longitude: -93.162053, zipCode: "72648"},
	{latitude: 35.862684, longitude: -92.690813, zipCode: "72650"},
	{latitude: 36.388162, longitude: -92.475143, zipCode: "72651"},
	{latitude: 36.342381, longitude: -92.38543, zipCode: "72653"},
	{latitude: 35.909895, longitude: -93.035855, zipCode: "72655"},
	{latitude: 36.15042, longitude: -92.321486, zipCode: "72658"},
	{latitude: 36.478646, longitude: -93.377872, zipCode: "72660"},
	{latitude: 36.430373, longitude: -92.597275, zipCode: "72661"},
	{latitude: 36.426384, longitude: -93.184571, zipCode: "72662"},
	{latitude: 35.938718, longitude: -92.332714, zipCode: "72663"},
	{latitude: 35.949391, longitude: -93.286821, zipCode: "72666"},
	{latitude: 36.418465, longitude: -92.772377, zipCode: "72668"},
	{latitude: 36.06479, longitude: -92.873627, zipCode: "72669"},
	{latitude: 35.981012, longitude: -93.371111, zipCode: "72670"},
	{latitude: 36.287895, longitude: -92.844306, zipCode: "72672"},
	{latitude: 36.000216, longitude: -92.800216, zipCode: "72675"},
	{latitude: 36.264412, longitude: -92.700636, zipCode: "72677"},
	{latitude: 35.735176, longitude: -92.801028, zipCode: "72679"},
	{latitude: 35.880674, longitude: -92.330799, zipCode: "72680"},
	{latitude: 36.135623, longitude: -92.732034, zipCode: "72682"},
	{latitude: 35.932007, longitude: -93.115062, zipCode: "72683"},
	{latitude: 36.064122, longitude: -92.98553, zipCode: "72685"},
	{latitude: 35.599437, longitude: -92.962006, zipCode: "72686"},
	{latitude: 36.285164, longitude: -92.653934, zipCode: "72687"},
	{latitude: 35.992387, longitude: -94.085584, zipCode: "72701"},
	{latitude: 36.118051, longitude: -94.043538, zipCode: "72703"},
	{latitude: 36.105647, longitude: -94.2965, zipCode: "72704"},
	{latitude: 36.398394, longitude: -94.22235, zipCode: "72712"},
	{latitude: 36.297956, longitude: -94.282784, zipCode: "72713"},
	{latitude: 36.46735, longitude: -94.222151, zipCode: "72714"},
	{latitude: 36.467146, longitude: -94.304915, zipCode: "72715"},
	{latitude: 35.835094, longitude: -94.408423, zipCode: "72717"},
	{latitude: 36.264638, longitude: -94.220694, zipCode: "72718"},
	{latitude: 36.367161, longitude: -94.295087, zipCode: "72719"},
	{latitude: 35.780737, longitude: -93.77853, zipCode: "72721"},
	{latitude: 36.343752, longitude: -94.469914, zipCode: "72722"},
	{latitude: 35.913471, longitude: -93.934029, zipCode: "72727"},
	{latitude: 35.788441, longitude: -94.473128, zipCode: "72729"},
	{latitude: 36.030478, longitude: -94.268678, zipCode: "72730"},
	{latitude: 36.432453, longitude: -93.959439, zipCode: "72732"},
	{latitude: 36.27025, longitude: -94.451058, zipCode: "72734"},
	{latitude: 36.413792, longitude: -94.465005, zipCode: "72736"},
	{latitude: 36.162486, longitude: -93.886698, zipCode: "72738"},
	{latitude: 36.426746, longitude: -94.328797, zipCode: "72739"},
	{latitude: 36.104648, longitude: -93.655394, zipCode: "72740"},
	{latitude: 35.996323, longitude: -93.51538, zipCode: "72742"},
	{latitude: 35.962601, longitude: -94.435558, zipCode: "72744"},
	{latitude: 36.246524, longitude: -94.102609, zipCode: "72745"},
	{latitude: 36.370358, longitude: -94.580852, zipCode: "72747"},
	{latitude: 35.868416, longitude: -94.437597, zipCode: "72749"},
	{latitude: 36.463315, longitude: -94.119873, zipCode: "72751"},
	{latitude: 35.834982, longitude: -93.556963, zipCode: "72752"},
	{latitude: 35.933889, longitude: -94.331072, zipCode: "72753"},
	{latitude: 36.321106, longitude: -93.990073, zipCode: "72756"},
	{latitude: 36.299426, longitude: -94.140989, zipCode: "72758"},
	{latitude: 35.84052, longitude: -93.702256, zipCode: "72760"},
	{latitude: 36.165256, longitude: -94.456061, zipCode: "72761"},
	{latitude: 36.18126, longitude: -94.225296, zipCode: "72762"},
	{latitude: 36.175681, longitude: -94.060222, zipCode: "72764"},
	{latitude: 36.471983, longitude: -94.490056, zipCode: "72768"},
	{latitude: 36.035804, longitude: -94.500097, zipCode: "72769"},
	{latitude: 36.022252, longitude: -93.835973, zipCode: "72773"},
	{latitude: 35.930543, longitude: -94.144646, zipCode: "72774"},
	{latitude: 35.912982, longitude: -93.666899, zipCode: "72776"},
	{latitude: 35.280583, longitude: -93.141596, zipCode: "72801"},
	{latitude: 35.31894, longitude: -93.081593, zipCode: "72802"},
	{latitude: 35.45843, longitude: -93.713907, zipCode: "72821"},
	{latitude: 35.25257, longitude: -92.894541, zipCode: "72823"},
	{latitude: 35.126107, longitude: -93.415332, zipCode: "72824"},
	{latitude: 35.129532, longitude: -93.710153, zipCode: "72826"},
	{latitude: 34.871709, longitude: -93.625647, zipCode: "72827"},
	{latitude: 34.930718, longitude: -93.534996, zipCode: "72828"},
	{latitude: 35.103569, longitude: -93.175277, zipCode: "72829"},
	{latitude: 35.517899, longitude: -93.510271, zipCode: "72830"},
	{latitude: 35.428288, longitude: -93.668646, zipCode: "72832"},
	{latitude: 35.01485, longitude: -93.50123, zipCode: "72833"},
	{latitude: 35.170653, longitude: -93.201502, zipCode: "72834"},
	{latitude: 35.272957, longitude: -93.347922, zipCode: "72835"},
	{latitude: 35.524976, longitude: -93.104222, zipCode: "72837"},
	{latitude: 34.888199, longitude: -93.68034, zipCode: "72838"},
	{latitude: 35.629712, longitude: -93.244286, zipCode: "72839"},
	{latitude: 35.45809, longitude: -93.615427, zipCode: "72840"},
	{latitude: 34.865078, longitude: -93.777084, zipCode: "72841"},
	{latitude: 35.089887, longitude: -93.584537, zipCode: "72842"},
	{latitude: 35.579441, longitude: -92.902292, zipCode: "72843"},
	{latitude: 35.36165, longitude: -93.377876, zipCode: "72845"},
	{latitude: 35.4839, longitude: -93.326796, zipCode: "72846"},
	{latitude: 35.371377, longitude: -93.267294, zipCode: "72847"},
	{latitude: 35.255427, longitude: -93.438111, zipCode: "72851"},
	{latitude: 35.668768, longitude: -93.553758, zipCode: "72852"},
	{latitude: 35.013464, longitude: -93.22872, zipCode: "72853"},
	{latitude: 35.696685, longitude: -93.428617, zipCode: "72854"},
	{latitude: 35.245001, longitude: -93.667573, zipCode: "72855"},
	{latitude: 35.768992, longitude: -93.057382, zipCode: "72856"},
	{latitude: 34.862186, longitude: -93.32409, zipCode: "72857"},
	{latitude: 35.213311, longitude: -93.043996, zipCode: "72858"},
	{latitude: 34.965767, longitude: -93.44586, zipCode: "72860"},
	{latitude: 35.369401, longitude: -93.511253, zipCode: "72863"},
	{latitude: 35.306339, longitude: -93.550736, zipCode: "72865"},
	{latitude: 35.365188, longitude: -94.414866, zipCode: "72901"},
	{latitude: 35.354958, longitude: -94.360725, zipCode: "72903"},
	{latitude: 35.415224, longitude: -94.382518, zipCode: "72904"},
	{latitude: 35.263063, longitude: -94.242068, zipCode: "72905"},
	{latitude: 35.309004, longitude: -94.407401, zipCode: "72908"},
	{latitude: 35.271983, longitude: -94.372193, zipCode: "72916"},
	{latitude: 35.463135, longitude: -94.1915, zipCode: "72921"},
	{latitude: 35.308712, longitude: -94.289369, zipCode: "72923"},
	{latitude: 34.710472, longitude: -94.024727, zipCode: "72926"},
	{latitude: 35.080085, longitude: -93.939782, zipCode: "72927"},
	{latitude: 35.291342, longitude: -93.930652, zipCode: "72928"},
	{latitude: 35.434713, longitude: -94.127067, zipCode: "72930"},
	{latitude: 35.604794, longitude: -94.376843, zipCode: "72932"},
	{latitude: 35.328642, longitude: -94.013024, zipCode: "72933"},
	{latitude: 35.686804, longitude: -94.284335, zipCode: "72934"},
	{latitude: 35.494338, longitude: -94.132753, zipCode: "72935"},
	{latitude: 35.189722, longitude: -94.218475, zipCode: "72936"},
	{latitude: 35.134729, longitude: -94.391456, zipCode: "72937"},
	{latitude: 35.004129, longitude: -94.377828, zipCode: "72938"},
	{latitude: 35.122069, longitude: -94.259293, zipCode: "72940"},
	{latitude: 35.347955, longitude: -94.164694, zipCode: "72941"},
	{latitude: 35.180144, longitude: -93.786949, zipCode: "72943"},
	{latitude: 35.042497, longitude: -94.237592, zipCode: "72944"},
	{latitude: 35.099425, longitude: -94.362627, zipCode: "72945"},
	{latitude: 35.652068, longitude: -94.135842, zipCode: "72946"},
	{latitude: 35.553358, longitude: -94.036777, zipCode: "72947"},
	{latitude: 35.702664, longitude: -94.392935, zipCode: "72948"},
	{latitude: 35.562791, longitude: -93.807178, zipCode: "72949"},
	{latitude: 34.793332, longitude: -93.903086, zipCode: "72950"},
	{latitude: 35.3005, longitude: -93.889348, zipCode: "72951"},
	{latitude: 35.568598, longitude: -94.301384, zipCode: "72952"},
	{latitude: 35.614629, longitude: -94.437681, zipCode: "72955"},
	{latitude: 35.468947, longitude: -94.348179, zipCode: "72956"},
	{latitude: 34.9057, longitude: -94.150994, zipCode: "72958"},
	{latitude: 35.778528, longitude: -94.062195, zipCode: "72959"},
	{latitude: 35.226206, longitude: -98.416515, zipCode: "73001"},
	{latitude: 34.96417, longitude: -97.741182, zipCode: "73002"},
	{latitude: 35.668905, longitude: -97.49738, zipCode: "73003"},
	{latitude: 35.169807, longitude: -97.796972, zipCode: "73004"},
	{latitude: 35.051308, longitude: -98.240141, zipCode: "73005"},
	{latitude: 34.906219, longitude: -98.400221, zipCode: "73006"},
	{latitude: 35.685355, longitude: -97.327459, zipCode: "73007"},
	{latitude: 35.510852, longitude: -97.642541, zipCode: "73008"},
	{latitude: 35.286345, longitude: -98.372834, zipCode: "73009"},
	{latitude: 35.100739, longitude: -97.673404, zipCode: "73010"},
	{latitude: 34.847842, longitude: -97.746577, zipCode: "73011"},
	{latitude: 35.673673, longitude: -97.592444, zipCode: "73012"},
	{latitude: 35.61699, longitude: -97.500777, zipCode: "73013"},
	{latitude: 35.587534, longitude: -98.145189, zipCode: "73014"},
	{latitude: 35.070873, longitude: -98.595544, zipCode: "73015"},
	{latitude: 35.794599, longitude: -97.689364, zipCode: "73016"},
	{latitude: 34.925443, longitude: -98.095442, zipCode: "73017"},
	{latitude: 35.048478, longitude: -97.938657, zipCode: "73018"},
	{latitude: 35.205661, longitude: -97.442738, zipCode: "73019"},
	{latitude: 35.46191, longitude: -97.262598, zipCode: "73020"},
	{latitude: 35.32409, longitude: -98.670405, zipCode: "73021"},
	{latitude: 35.616939, longitude: -97.992248, zipCode: "73022"},
	{latitude: 35.370939, longitude: -98.80878, zipCode: "73024"},
	{latitude: 35.729682, longitude: -97.569841, zipCode: "73025"},
	{latitude: 35.24046, longitude: -97.275732, zipCode: "73026"},
	{latitude: 35.96752, longitude: -97.24276, zipCode: "73027"},
	{latitude: 35.977961, longitude: -97.631503, zipCode: "73028"},
	{latitude: 34.906458, longitude: -98.208171, zipCode: "73029"},
	{latitude: 34.472693, longitude: -97.188731, zipCode: "73030"},
	{latitude: 35.03381, longitude: -97.631399, zipCode: "73031"},
	{latitude: 34.400463, longitude: -97.050581, zipCode: "73032"},
	{latitude: 35.306975, longitude: -98.555087, zipCode: "73033"},
	{latitude: 35.703333, longitude: -97.433595, zipCode: "73034"},
	{latitude: 35.506223, longitude: -97.958645, zipCode: "73036"},
	{latitude: 35.124525, longitude: -98.451329, zipCode: "73038"},
	{latitude: 35.645563, longitude: -98.375225, zipCode: "73040"},
	{latitude: 35.028147, longitude: -98.875792, zipCode: "73041"},
	{latitude: 35.222848, longitude: -98.247001, zipCode: "73042"},
	{latitude: 35.734241, longitude: -98.403655, zipCode: "73043"},
	{latitude: 35.867906, longitude: -97.434361, zipCode: "73044"},
	{latitude: 35.511632, longitude: -97.141539, zipCode: "73045"},
	{latitude: 35.456802, longitude: -98.315149, zipCode: "73047"},
	{latitude: 35.49658, longitude: -98.546273, zipCode: "73048"},
	{latitude: 35.58214, longitude: -97.28939, zipCode: "73049"},
	{latitude: 35.938315, longitude: -97.256014, zipCode: "73050"},
	{latitude: 35.031833, longitude: -97.246443, zipCode: "73051"},
	{latitude: 34.833332, longitude: -97.596182, zipCode: "73052"},
	{latitude: 35.355908, longitude: -98.412145, zipCode: "73053"},
	{latitude: 35.675227, longitude: -97.203883, zipCode: "73054"},
	{latitude: 34.645249, longitude: -97.92308, zipCode: "73055"},
	{latitude: 36.169358, longitude: -97.61278, zipCode: "73056"},
	{latitude: 34.831027, longitude: -97.428642, zipCode: "73057"},
	{latitude: 35.819993, longitude: -97.225807, zipCode: "73058"},
	{latitude: 35.328975, longitude: -98.050724, zipCode: "73059"},
	{latitude: 36.351116, longitude: -97.022355, zipCode: "73061"},
	{latitude: 35.022301, longitude: -98.735171, zipCode: "73062"},
	{latitude: 36.060733, longitude: -97.425731, zipCode: "73063"},
	{latitude: 35.375491, longitude: -97.753526, zipCode: "73064"},
	{latitude: 35.255255, longitude: -97.610137, zipCode: "73065"},
	{latitude: 35.501707, longitude: -97.328229, zipCode: "73066"},
	{latitude: 34.911077, longitude: -97.907103, zipCode: "73067"},
	{latitude: 35.138752, longitude: -97.282243, zipCode: "73068"},
	{latitude: 35.252521, longitude: -97.464398, zipCode: "73069"},
	{latitude: 35.227641, longitude: -97.42718, zipCode: "73070"},
	{latitude: 35.237099, longitude: -97.407945, zipCode: "73071"},
	{latitude: 35.211565, longitude: -97.501018, zipCode: "73072"},
	{latitude: 36.161933, longitude: -97.394581, zipCode: "73073"},
	{latitude: 34.833426, longitude: -97.274183, zipCode: "73074"},
	{latitude: 34.748844, longitude: -97.2324, zipCode: "73075"},
	{latitude: 36.303005, longitude: -97.268141, zipCode: "73077"},
	{latitude: 35.674845, longitude: -97.753508, zipCode: "73078"},
	{latitude: 35.210934, longitude: -98.012001, zipCode: "73079"},
	{latitude: 35.016436, longitude: -97.449803, zipCode: "73080"},
	{latitude: 34.776197, longitude: -97.90211, zipCode: "73082"},
	{latitude: 35.525673, longitude: -97.346456, zipCode: "73084"},
	{latitude: 34.485294, longitude: -96.971843, zipCode: "73086"},
	{latitude: 35.268848, longitude: -97.798843, zipCode: "73089"},
	{latitude: 35.397531, longitude: -97.962446, zipCode: "73090"},
	{latitude: 35.131203, longitude: -98.073831, zipCode: "73092"},
	{latitude: 35.108417, longitude: -97.506471, zipCode: "73093"},
	{latitude: 34.907688, longitude: -97.285181, zipCode: "73095"},
	{latitude: 35.51281, longitude: -98.722072, zipCode: "73096"},
	{latitude: 35.398931, longitude: -97.645011, zipCode: "73097"},
	{latitude: 34.627041, longitude: -97.164925, zipCode: "73098"},
	{latitude: 35.518409, longitude: -97.766172, zipCode: "73099"},
	{latitude: 35.470652, longitude: -97.51909, zipCode: "73102"},
	{latitude: 35.489035, longitude: -97.518988, zipCode: "73103"},
	{latitude: 35.475123, longitude: -97.5038, zipCode: "73104"},
	{latitude: 35.519523, longitude: -97.503225, zipCode: "73105"},
	{latitude: 35.482265, longitude: -97.536656, zipCode: "73106"},
	{latitude: 35.481939, longitude: -97.574484, zipCode: "73107"},
	{latitude: 35.450299, longitude: -97.567681, zipCode: "73108"},
	{latitude: 35.432796, longitude: -97.524518, zipCode: "73109"},
	{latitude: 35.448057, longitude: -97.39451, zipCode: "73110"},
	{latitude: 35.519496, longitude: -97.477422, zipCode: "73111"},
	{latitude: 35.518006, longitude: -97.572972, zipCode: "73112"},
	{latitude: 35.578131, longitude: -97.517635, zipCode: "73114"},
	{latitude: 35.442311, longitude: -97.441535, zipCode: "73115"},
	{latitude: 35.546701, longitude: -97.564592, zipCode: "73116"},
	{latitude: 35.474862, longitude: -97.462502, zipCode: "73117"},
	{latitude: 35.518242, longitude: -97.530937, zipCode: "73118"},
	{latitude: 35.421495, longitude: -97.568962, zipCode: "73119"},
	{latitude: 35.581316, longitude: -97.573674, zipCode: "73120"},
	{latitude: 35.519732, longitude: -97.444896, zipCode: "73121"},
	{latitude: 35.520484, longitude: -97.61344, zipCode: "73122"},
	{latitude: 35.477618, longitude: -97.648683, zipCode: "73127"},
	{latitude: 35.442497, longitude: -97.662583, zipCode: "73128"},
	{latitude: 35.431646, longitude: -97.484321, zipCode: "73129"},
	{latitude: 35.458769, longitude: -97.345832, zipCode: "73130"},
	{latitude: 35.576458, longitude: -97.469294, zipCode: "73131"},
	{latitude: 35.543835, longitude: -97.63894, zipCode: "73132"},
	{latitude: 35.6151, longitude: -97.569399, zipCode: "73134"},
	{latitude: 35.400268, longitude: -97.433076, zipCode: "73135"},
	{latitude: 35.386377, longitude: -97.525689, zipCode: "73139"},
	{latitude: 35.519962, longitude: -97.394475, zipCode: "73141"},
	{latitude: 35.609765, longitude: -97.646818, zipCode: "73142"},
	{latitude: 35.424279, longitude: -97.414037, zipCode: "73145"},
	{latitude: 35.389204, longitude: -97.489087, zipCode: "73149"},
	{latitude: 35.401238, longitude: -97.329876, zipCode: "73150"},
	{latitude: 35.571686, longitude: -97.416539, zipCode: "73151"},
	{latitude: 35.387855, longitude: -97.578835, zipCode: "73159"},
	{latitude: 35.336297, longitude: -97.475078, zipCode: "73160"},
	{latitude: 35.582011, longitude: -97.640928, zipCode: "73162"},
	{latitude: 35.358755, longitude: -97.37304, zipCode: "73165"},
	{latitude: 35.380573, longitude: -97.648406, zipCode: "73169"},
	{latitude: 35.325285, longitude: -97.552201, zipCode: "73170"},
	{latitude: 35.340805, longitude: -97.627491, zipCode: "73173"},
	{latitude: 35.425259, longitude: -97.649483, zipCode: "73179"},
	{latitude: 34.207795, longitude: -97.106823, zipCode: "73401"},
	{latitude: 34.462092, longitude: -97.570239, zipCode: "73425"},
	{latitude: 33.898323, longitude: -97.361382, zipCode: "73430"},
	{latitude: 34.252177, longitude: -96.422675, zipCode: "73432"},
	{latitude: 34.64052, longitude: -97.420324, zipCode: "73433"},
	{latitude: 34.618434, longitude: -97.578939, zipCode: "73434"},
	{latitude: 34.357864, longitude: -97.495, zipCode: "73435"},
	{latitude: 34.285608, longitude: -97.040507, zipCode: "73436"},
	{latitude: 34.367943, longitude: -97.412376, zipCode: "73437"},
	{latitude: 34.281559, longitude: -97.503211, zipCode: "73438"},
	{latitude: 33.937901, longitude: -96.744112, zipCode: "73439"},
	{latitude: 33.961721, longitude: -96.889375, zipCode: "73440"},
	{latitude: 33.867323, longitude: -97.440147, zipCode: "73441"},
	{latitude: 34.323029, longitude: -97.689263, zipCode: "73442"},
	{latitude: 34.161956, longitude: -97.297727, zipCode: "73443"},
	{latitude: 34.4745, longitude: -97.388925, zipCode: "73444"},
	{latitude: 34.088832, longitude: -96.778025, zipCode: "73446"},
	{latitude: 34.247252, longitude: -96.85345, zipCode: "73447"},
	{latitude: 33.948312, longitude: -97.106144, zipCode: "73448"},
	{latitude: 33.991024, longitude: -96.543615, zipCode: "73449"},
	{latitude: 34.267306, longitude: -96.538942, zipCode: "73450"},
	{latitude: 34.030828, longitude: -97.27098, zipCode: "73453"},
	{latitude: 34.229981, longitude: -96.760353, zipCode: "73455"},
	{latitude: 34.109825, longitude: -97.623006, zipCode: "73456"},
	{latitude: 34.349739, longitude: -97.313891, zipCode: "73458"},
	{latitude: 33.773242, longitude: -97.139314, zipCode: "73459"},
	{latitude: 34.297279, longitude: -96.667517, zipCode: "73460"},
	{latitude: 34.356085, longitude: -96.433061, zipCode: "73461"},
	{latitude: 34.123762, longitude: -97.434885, zipCode: "73463"},
	{latitude: 34.427014, longitude: -97.527411, zipCode: "73481"},
	{latitude: 34.469502, longitude: -97.442062, zipCode: "73487"},
	{latitude: 34.493463, longitude: -97.555045, zipCode: "73488"},
	{latitude: 34.467572, longitude: -97.65959, zipCode: "73491"},
	{latitude: 34.557943, longitude: -98.290972, zipCode: "73501"},
	{latitude: 34.682971, longitude: -98.49182, zipCode: "73503"},
	{latitude: 34.572847, longitude: -98.487889, zipCode: "73505"},
	{latitude: 34.728258, longitude: -98.474582, zipCode: "73507"},
	{latitude: 34.268706, longitude: -97.977189, zipCode: "73520"},
	{latitude: 34.634457, longitude: -99.315307, zipCode: "73521"},
	{latitude: 34.655169, longitude: -99.285311, zipCode: "73523"},
	{latitude: 34.792879, longitude: -99.265561, zipCode: "73526"},
	{latitude: 34.578229, longitude: -98.622288, zipCode: "73527"},
	{latitude: 34.398748, longitude: -98.653325, zipCode: "73528"},
	{latitude: 34.337674, longitude: -97.952942, zipCode: "73529"},
	{latitude: 34.254868, longitude: -99.051492, zipCode: "73530"},
	{latitude: 34.195431, longitude: -98.528173, zipCode: "73531"},
	{latitude: 34.629571, longitude: -99.588932, zipCode: "73532"},
	{latitude: 34.504963, longitude: -97.866672, zipCode: "73533"},
	{latitude: 34.468361, longitude: -99.679178, zipCode: "73537"},
	{latitude: 34.775838, longitude: -98.383602, zipCode: "73538"},
	{latitude: 34.457248, longitude: -99.280735, zipCode: "73539"},
	{latitude: 34.467092, longitude: -98.560889, zipCode: "73540"},
	{latitude: 34.788728, longitude: -98.180721, zipCode: "73541"},
	{latitude: 34.398024, longitude: -98.935928, zipCode: "73542"},
	{latitude: 34.470568, longitude: -98.391798, zipCode: "73543"},
	{latitude: 34.660295, longitude: -99.754445, zipCode: "73544"},
	{latitude: 34.222944, longitude: -98.723483, zipCode: "73546"},
	{latitude: 34.973872, longitude: -99.404615, zipCode: "73547"},
	{latitude: 34.211349, longitude: -98.131958, zipCode: "73548"},
	{latitude: 34.644812, longitude: -99.152829, zipCode: "73549"},
	{latitude: 34.703473, longitude: -99.901034, zipCode: "73550"},
	{latitude: 34.307588, longitude: -98.880594, zipCode: "73551"},
	{latitude: 34.628741, longitude: -98.760163, zipCode: "73552"},
	{latitude: 34.329576, longitude: -98.701219, zipCode: "73553"},
	{latitude: 34.84157, longitude: -99.608195, zipCode: "73554"},
	{latitude: 34.501511, longitude: -98.965923, zipCode: "73555"},
	{latitude: 34.725983, longitude: -99.386657, zipCode: "73556"},
	{latitude: 34.731024, longitude: -98.500784, zipCode: "73557"},
	{latitude: 34.730027, longitude: -98.940492, zipCode: "73559"},
	{latitude: 34.546578, longitude: -99.448356, zipCode: "73560"},
	{latitude: 34.190541, longitude: -98.39712, zipCode: "73562"},
	{latitude: 34.848463, longitude: -98.999632, zipCode: "73564"},
	{latitude: 34.019909, longitude: -97.893975, zipCode: "73565"},
	{latitude: 34.615969, longitude: -98.931114, zipCode: "73566"},
	{latitude: 34.747384, longitude: -98.17093, zipCode: "73567"},
	{latitude: 34.263389, longitude: -98.219291, zipCode: "73568"},
	{latitude: 33.936131, longitude: -97.782013, zipCode: "73569"},
	{latitude: 34.51463, longitude: -99.114772, zipCode: "73570"},
	{latitude: 34.914039, longitude: -99.928091, zipCode: "73571"},
	{latitude: 34.372115, longitude: -98.3269, zipCode: "73572"},
	{latitude: 34.144062, longitude: -97.961663, zipCode: "73573"},
	{latitude: 35.487491, longitude: -98.950794, zipCode: "73601"},
	{latitude: 35.62233, longitude: -99.025127, zipCode: "73620"},
	{latitude: 35.400168, longitude: -98.997714, zipCode: "73622"},
	{latitude: 35.359659, longitude: -99.171886, zipCode: "73624"},
	{latitude: 35.692338, longitude: -99.190277, zipCode: "73625"},
	{latitude: 35.43872, longitude: -99.285133, zipCode: "73626"},
	{latitude: 35.197014, longitude: -99.452704, zipCode: "73627"},
	{latitude: 35.630462, longitude: -99.69984, zipCode: "73628"},
	{latitude: 35.269165, longitude: -98.914828, zipCode: "73632"},
	{latitude: 35.849095, longitude: -99.795626, zipCode: "73638"},
	{latitude: 35.739089, longitude: -98.96062, zipCode: "73639"},
	{latitude: 35.269648, longitude: -99.19678, zipCode: "73641"},
	{latitude: 35.822727, longitude: -99.909944, zipCode: "73642"},
	{latitude: 35.405055, longitude: -99.426566, zipCode: "73644"},
	{latitude: 35.172186, longitude: -99.868655, zipCode: "73645"},
	{latitude: 35.819443, longitude: -98.62326, zipCode: "73646"},
	{latitude: 35.43551, longitude: -99.151277, zipCode: "73647"},
	{latitude: 35.644545, longitude: -99.4224, zipCode: "73650"},
	{latitude: 35.025165, longitude: -99.069729, zipCode: "73651"},
	{latitude: 35.85851, longitude: -99.374149, zipCode: "73654"},
	{latitude: 34.980189, longitude: -99.24916, zipCode: "73655"},
	{latitude: 35.948227, longitude: -98.718781, zipCode: "73658"},
	{latitude: 35.87748, longitude: -98.940126, zipCode: "73659"},
	{latitude: 35.631691, longitude: -99.91387, zipCode: "73660"},
	{latitude: 35.17474, longitude: -99.021184, zipCode: "73661"},
	{latitude: 35.31797, longitude: -99.661062, zipCode: "73662"},
	{latitude: 36.102944, longitude: -98.894969, zipCode: "73663"},
	{latitude: 35.17451, longitude: -99.235923, zipCode: "73664"},
	{latitude: 35.415025, longitude: -99.920299, zipCode: "73666"},
	{latitude: 36.005902, longitude: -99.0399, zipCode: "73667"},
	{latitude: 35.224604, longitude: -99.976077, zipCode: "73668"},
	{latitude: 35.750492, longitude: -98.743216, zipCode: "73669"},
	{latitude: 35.052195, longitude: -99.637624, zipCode: "73673"},
	{latitude: 36.426744, longitude: -97.791989, zipCode: "73701"},
	{latitude: 36.440704, longitude: -97.966838, zipCode: "73703"},
	{latitude: 36.339188, longitude: -97.902082, zipCode: "73705"},
	{latitude: 36.485133, longitude: -98.551474, zipCode: "73716"},
	{latitude: 36.83916, longitude: -98.783763, zipCode: "73717"},
	{latitude: 36.210239, longitude: -98.166753, zipCode: "73718"},
	{latitude: 36.964281, longitude: -98.263874, zipCode: "73719"},
	{latitude: 36.194635, longitude: -97.870054, zipCode: "73720"},
	{latitude: 36.911995, longitude: -98.350361, zipCode: "73722"},
	{latitude: 36.029768, longitude: -98.690945, zipCode: "73724"},
	{latitude: 36.587429, longitude: -98.508281, zipCode: "73726"},
	{latitude: 36.481719, longitude: -98.042668, zipCode: "73727"},
	{latitude: 36.729158, longitude: -98.4042, zipCode: "73728"},
	{latitude: 36.392827, longitude: -98.454409, zipCode: "73729"},
	{latitude: 36.311725, longitude: -97.541166, zipCode: "73730"},
	{latitude: 36.644994, longitude: -98.603442, zipCode: "73731"},
	{latitude: 36.254662, longitude: -97.68794, zipCode: "73733"},
	{latitude: 35.983368, longitude: -97.852175, zipCode: "73734"},
	{latitude: 36.280361, longitude: -98.062871, zipCode: "73735"},
	{latitude: 36.34469, longitude: -97.680689, zipCode: "73736"},
	{latitude: 36.296299, longitude: -98.576856, zipCode: "73737"},
	{latitude: 36.439255, longitude: -97.558448, zipCode: "73738"},
	{latitude: 36.514647, longitude: -98.134637, zipCode: "73739"},
	{latitude: 36.55371, longitude: -98.270121, zipCode: "73741"},
	{latitude: 36.096292, longitude: -97.921951, zipCode: "73742"},
	{latitude: 36.56325, longitude: -97.992664, zipCode: "73743"},
	{latitude: 35.950038, longitude: -98.290872, zipCode: "73744"},
	{latitude: 36.68818, longitude: -98.662014, zipCode: "73746"},
	{latitude: 36.233568, longitude: -98.33966, zipCode: "73747"},
	{latitude: 36.702659, longitude: -98.155153, zipCode: "73749"},
	{latitude: 35.853124, longitude: -97.94355, zipCode: "73750"},
	{latitude: 36.555251, longitude: -97.863739, zipCode: "73753"},
	{latitude: 36.382547, longitude: -98.096765, zipCode: "73754"},
	{latitude: 36.14368, longitude: -98.598056, zipCode: "73755"},
	{latitude: 35.978568, longitude: -98.111886, zipCode: "73756"},
	{latitude: 36.280109, longitude: -97.446871, zipCode: "73757"},
	{latitude: 36.934851, longitude: -98.085735, zipCode: "73758"},
	{latitude: 36.861861, longitude: -97.706196, zipCode: "73759"},
	{latitude: 36.383293, longitude: -98.166938, zipCode: "73760"},
	{latitude: 36.684967, longitude: -98.031302, zipCode: "73761"},
	{latitude: 35.713641, longitude: -97.975292, zipCode: "73762"},
	{latitude: 36.102881, longitude: -98.325303, zipCode: "73763"},
	{latitude: 35.854734, longitude: -98.201695, zipCode: "73764"},
	{latitude: 36.64506, longitude: -97.83673, zipCode: "73766"},
	{latitude: 36.369201, longitude: -98.274804, zipCode: "73768"},
	{latitude: 36.054081, longitude: -98.497896, zipCode: "73770"},
	{latitude: 36.876317, longitude: -97.958292, zipCode: "73771"},
	{latitude: 35.864523, longitude: -98.421152, zipCode: "73772"},
	{latitude: 36.253126, longitude: -97.920025, zipCode: "73773"},
	{latitude: 36.485433, longitude: -99.412748, zipCode: "73801"},
	{latitude: 36.049393, longitude: -99.689049, zipCode: "73832"},
	{latitude: 36.851782, longitude: -99.57098, zipCode: "73834"},
	{latitude: 35.968551, longitude: -99.218586, zipCode: "73835"},
	{latitude: 36.238724, longitude: -98.876324, zipCode: "73838"},
	{latitude: 36.402432, longitude: -99.643115, zipCode: "73840"},
	{latitude: 36.548017, longitude: -99.619271, zipCode: "73841"},
	{latitude: 36.821781, longitude: -99.216173, zipCode: "73842"},
	{latitude: 36.398856, longitude: -99.829637, zipCode: "73843"},
	{latitude: 36.89909, longitude: -100.16007, zipCode: "73844"},
	{latitude: 36.633991, longitude: -100.039852, zipCode: "73848"},
	{latitude: 36.573932, longitude: -99.781545, zipCode: "73851"},
	{latitude: 36.47926, longitude: -99.120104, zipCode: "73852"},
	{latitude: 36.239248, longitude: -99.159683, zipCode: "73853"},
	{latitude: 36.874731, longitude: -99.853036, zipCode: "73855"},
	{latitude: 36.270809, longitude: -99.332734, zipCode: "73857"},
	{latitude: 36.168196, longitude: -99.926684, zipCode: "73858"},
	{latitude: 36.137333, longitude: -99.285943, zipCode: "73859"},
	{latitude: 36.542527, longitude: -98.842859, zipCode: "73860"},
	{latitude: 36.754581, longitude: -101.075164, zipCode: "73901"},
	{latitude: 36.587331, longitude: -100.801469, zipCode: "73931"},
	{latitude: 36.690185, longitude: -100.513912, zipCode: "73932"},
	{latitude: 36.740858, longitude: -102.579737, zipCode: "73933"},
	{latitude: 36.566611, longitude: -102.759935, zipCode: "73937"},
	{latitude: 36.932374, longitude: -100.578761, zipCode: "73938"},
	{latitude: 36.776778, longitude: -101.789896, zipCode: "73939"},
	{latitude: 36.74404, longitude: -101.496362, zipCode: "73942"},
	{latitude: 36.581573, longitude: -101.103438, zipCode: "73944"},
	{latitude: 36.826905, longitude: -101.232916, zipCode: "73945"},
	{latitude: 36.935479, longitude: -102.80408, zipCode: "73946"},
	{latitude: 36.841931, longitude: -102.166856, zipCode: "73947"},
	{latitude: 36.596924, longitude: -101.987742, zipCode: "73949"},
	{latitude: 36.854789, longitude: -100.89497, zipCode: "73950"},
	{latitude: 36.942874, longitude: -101.053915, zipCode: "73951"},
	{latitude: 36.491667, longitude: -101.792613, zipCode: "73960"},
	{latitude: 36.488677, longitude: -96.062405, zipCode: "74001"},
	{latitude: 36.561392, longitude: -96.144457, zipCode: "74002"},
	{latitude: 36.728268, longitude: -96.067573, zipCode: "74003"},
	{latitude: 36.712522, longitude: -95.895054, zipCode: "74006"},
	{latitude: 35.924499, longitude: -95.877707, zipCode: "74008"},
	{latitude: 35.808125, longitude: -96.362114, zipCode: "74010"},
	{latitude: 35.981812, longitude: -95.809191, zipCode: "74011"},
	{latitude: 36.051418, longitude: -95.805731, zipCode: "74012"},
	{latitude: 36.052965, longitude: -95.663961, zipCode: "74014"},
	{latitude: 36.182181, longitude: -95.718832, zipCode: "74015"},
	{latitude: 36.545156, longitude: -95.45126, zipCode: "74016"},
	{latitude: 36.390927, longitude: -95.587689, zipCode: "74017"},
	{latitude: 36.284765, longitude: -95.603194, zipCode: "74019"},
	{latitude: 36.246194, longitude: -96.42075, zipCode: "74020"},
	{latitude: 36.378774, longitude: -95.845826, zipCode: "74021"},
	{latitude: 36.925468, longitude: -95.978443, zipCode: "74022"},
	{latitude: 35.957327, longitude: -96.751877, zipCode: "74023"},
	{latitude: 35.716909, longitude: -96.77966, zipCode: "74026"},
	{latitude: 36.81342, longitude: -95.607171, zipCode: "74027"},
	{latitude: 35.794437, longitude: -96.519656, zipCode: "74028"},
	{latitude: 36.822884, longitude: -95.889907, zipCode: "74029"},
	{latitude: 35.984509, longitude: -96.56502, zipCode: "74030"},
	{latitude: 36.20693, longitude: -96.898778, zipCode: "74032"},
	{latitude: 35.947098, longitude: -96.006341, zipCode: "74033"},
	{latitude: 36.232619, longitude: -96.562884, zipCode: "74034"},
	{latitude: 36.404491, longitude: -96.377862, zipCode: "74035"},
	{latitude: 36.158187, longitude: -95.5066, zipCode: "74036"},
	{latitude: 35.998551, longitude: -95.97989, zipCode: "74037"},
	{latitude: 36.156887, longitude: -96.559215, zipCode: "74038"},
	{latitude: 35.901719, longitude: -96.236557, zipCode: "74039"},
	{latitude: 35.937851, longitude: -96.058945, zipCode: "74041"},
	{latitude: 36.880785, longitude: -95.551221, zipCode: "74042"},
	{latitude: 36.083465, longitude: -96.380671, zipCode: "74044"},
	{latitude: 36.221475, longitude: -96.699649, zipCode: "74045"},
	{latitude: 35.753208, longitude: -96.572917, zipCode: "74046"},
	{latitude: 35.850773, longitude: -96.027278, zipCode: "74047"},
	{latitude: 36.691792, longitude: -95.650961, zipCode: "74048"},
	{latitude: 36.072262, longitude: -96.06655, zipCode: "74050"},
	{latitude: 36.61401, longitude: -95.955767, zipCode: "74051"},
	{latitude: 36.066692, longitude: -96.570042, zipCode: "74052"},
	{latitude: 36.430484, longitude: -95.731944, zipCode: "74053"},
	{latitude: 36.280388, longitude: -96.329327, zipCode: "74054"},
	{latitude: 36.276728, longitude: -95.818917, zipCode: "74055"},
	{latitude: 36.795306, longitude: -96.330856, zipCode: "74056"},
	{latitude: 36.362625, longitude: -96.758594, zipCode: "74058"},
	{latitude: 35.971732, longitude: -97.067586, zipCode: "74059"},
	{latitude: 36.25735, longitude: -96.251151, zipCode: "74060"},
	{latitude: 36.533125, longitude: -95.892635, zipCode: "74061"},
	{latitude: 36.037717, longitude: -96.90095, zipCode: "74062"},
	{latitude: 36.150767, longitude: -96.19959, zipCode: "74063"},
	{latitude: 35.994351, longitude: -96.170162, zipCode: "74066"},
	{latitude: 35.907256, longitude: -96.577887, zipCode: "74068"},
	{latitude: 36.400791, longitude: -96.087994, zipCode: "74070"},
	{latitude: 35.778762, longitude: -96.264795, zipCode: "74071"},
	{latitude: 36.95996, longitude: -95.616134, zipCode: "74072"},
	{latitude: 36.29874, longitude: -96.01023, zipCode: "74073"},
	{latitude: 36.078654, longitude: -97.064146, zipCode: "74074"},
	{latitude: 36.169107, longitude: -97.083365, zipCode: "74075"},
	{latitude: 36.124004, longitude: -97.069021, zipCode: "74077"},
	{latitude: 36.125273, longitude: -97.079718, zipCode: "74078"},
	{latitude: 35.743102, longitude: -96.656155, zipCode: "74079"},
	{latitude: 36.542107, longitude: -95.749012, zipCode: "74080"},
	{latitude: 36.181667, longitude: -96.460082, zipCode: "74081"},
	{latitude: 36.448822, longitude: -95.880889, zipCode: "74082"},
	{latitude: 36.87921, longitude: -95.753383, zipCode: "74083"},
	{latitude: 36.528019, longitude: -96.333939, zipCode: "74084"},
	{latitude: 36.110793, longitude: -96.722608, zipCode: "74085"},
	{latitude: 36.156146, longitude: -95.995692, zipCode: "74103"},
	{latitude: 36.14624, longitude: -95.954196, zipCode: "74104"},
	{latitude: 36.097442, longitude: -95.961087, zipCode: "74105"},
	{latitude: 36.191942, longitude: -95.98515, zipCode: "74106"},
	{latitude: 36.11488, longitude: -96.032371, zipCode: "74107"},
	{latitude: 36.140188, longitude: -95.789544, zipCode: "74108"},
	{latitude: 36.188283, longitude: -95.953083, zipCode: "74110"},
	{latitude: 36.147036, longitude: -95.902328, zipCode: "74112"},
	{latitude: 36.126943, longitude: -95.946695, zipCode: "74114"},
	{latitude: 36.199308, longitude: -95.90889, zipCode: "74115"},
	{latitude: 36.188756, longitude: -95.842573, zipCode: "74116"},
	{latitude: 36.246042, longitude: -95.901615, zipCode: "74117"},
	{latitude: 36.142062, longitude: -95.98933, zipCode: "74119"},
	{latitude: 36.150734, longitude: -95.97737, zipCode: "74120"},
	{latitude: 36.237483, longitude: -96.017974, zipCode: "74126"},
	{latitude: 36.173134, longitude: -96.046424, zipCode: "74127"},
	{latitude: 36.147408, longitude: -95.850349, zipCode: "74128"},
	{latitude: 36.12585, longitude: -95.871575, zipCode: "74129"},
	{latitude: 36.241454, longitude: -95.956565, zipCode: "74130"},
	{latitude: 36.050812, longitude: -96.073378, zipCode: "74131"},
	{latitude: 36.048782, longitude: -96.016734, zipCode: "74132"},
	{latitude: 36.039275, longitude: -95.877463, zipCode: "74133"},
	{latitude: 36.10981, longitude: -95.797189, zipCode: "74134"},
	{latitude: 36.097276, longitude: -95.923171, zipCode: "74135"},
	{latitude: 36.0624, longitude: -95.941457, zipCode: "74136"},
	{latitude: 36.022431, longitude: -95.930573, zipCode: "74137"},
	{latitude: 36.097138, longitude: -95.890482, zipCode: "74145"},
	{latitude: 36.099209, longitude: -95.855543, zipCode: "74146"},
	{latitude: 36.050615, longitude: -95.952561, zipCode: "74171"},
	{latitude: 36.677521, longitude: -95.242565, zipCode: "74301"},
	{latitude: 36.444528, longitude: -95.261977, zipCode: "74330"},
	{latitude: 36.647697, longitude: -94.951834, zipCode: "74331"},
	{latitude: 36.547699, longitude: -95.315622, zipCode: "74332"},
	{latitude: 36.793909, longitude: -95.082138, zipCode: "74333"},
	{latitude: 36.140741, longitude: -95.336936, zipCode: "74337"},
	{latitude: 36.255961, longitude: -94.712719, zipCode: "74338"},
	{latitude: 36.934026, longitude: -94.87974, zipCode: "74339"},
	{latitude: 36.474142, longitude: -95.017845, zipCode: "74340"},
	{latitude: 36.427344, longitude: -94.944658, zipCode: "74342"},
	{latitude: 36.736252, longitude: -94.82092, zipCode: "74343"},
	{latitude: 36.595479, longitude: -94.740655, zipCode: "74344"},
	{latitude: 36.439428, longitude: -94.754142, zipCode: "74346"},
	{latitude: 36.199164, longitude: -94.78827, zipCode: "74347"},
	{latitude: 36.512542, longitude: -95.028058, zipCode: "74349"},
	{latitude: 36.466226, longitude: -95.047763, zipCode: "74350"},
	{latitude: 36.135062, longitude: -95.193613, zipCode: "74352"},
	{latitude: 36.889474, longitude: -94.880953, zipCode: "74354"},
	{latitude: 36.917964, longitude: -94.880518, zipCode: "74358"},
	{latitude: 36.168484, longitude: -94.852172, zipCode: "74359"},
	{latitude: 36.979996, longitude: -94.836481, zipCode: "74360"},
	{latitude: 36.308236, longitude: -95.306846, zipCode: "74361"},
	{latitude: 36.959977, longitude: -94.711216, zipCode: "74363"},
	{latitude: 36.196316, longitude: -94.948524, zipCode: "74364"},
	{latitude: 36.287874, longitude: -95.059289, zipCode: "74365"},
	{latitude: 36.377954, longitude: -95.028918, zipCode: "74366"},
	{latitude: 36.416088, longitude: -95.112888, zipCode: "74367"},
	{latitude: 36.207257, longitude: -94.848247, zipCode: "74368"},
	{latitude: 36.92462, longitude: -95.202002, zipCode: "74369"},
	{latitude: 36.749784, longitude: -94.680364, zipCode: "74370"},
	{latitude: 35.723651, longitude: -95.458203, zipCode: "74401"},
	{latitude: 35.681993, longitude: -95.307488, zipCode: "74403"},
	{latitude: 35.762714, longitude: -96.042025, zipCode: "74421"},
	{latitude: 35.643184, longitude: -95.690292, zipCode: "74422"},
	{latitude: 35.642284, longitude: -95.219757, zipCode: "74423"},
	{latitude: 35.171244, longitude: -95.622996, zipCode: "74425"},
	{latitude: 35.445471, longitude: -95.52932, zipCode: "74426"},
	{latitude: 35.677081, longitude: -94.886897, zipCode: "74427"},
	{latitude: 35.548672, longitude: -95.648126, zipCode: "74428"},
	{latitude: 35.948792, longitude: -95.632985, zipCode: "74429"},
	{latitude: 35.129363, longitude: -95.646448, zipCode: "74430"},
	{latitude: 35.457795, longitude: -95.939104, zipCode: "74431"},
	{latitude: 35.282171, longitude: -95.650568, zipCode: "74432"},
	{latitude: 35.782597, longitude: -95.194809, zipCode: "74434"},
	{latitude: 35.593287, longitude: -95.094694, zipCode: "74435"},
	{latitude: 35.805572, longitude: -95.704649, zipCode: "74436"},
	{latitude: 35.436505, longitude: -95.927179, zipCode: "74437"},
	{latitude: 35.513228, longitude: -95.762052, zipCode: "74438"},
	{latitude: 35.976851, longitude: -95.145009, zipCode: "74441"},
	{latitude: 35.123823, longitude: -95.816571, zipCode: "74442"},
	{latitude: 35.631467, longitude: -95.828602, zipCode: "74445"},
	{latitude: 35.851152, longitude: -95.322222, zipCode: "74446"},
	{latitude: 35.649678, longitude: -96.012211, zipCode: "74447"},
	{latitude: 35.599554, longitude: -95.472748, zipCode: "74450"},
	{latitude: 35.723862, longitude: -95.011345, zipCode: "74451"},
	{latitude: 36.121366, longitude: -95.068301, zipCode: "74452"},
	{latitude: 35.842528, longitude: -95.50907, zipCode: "74454"},
	{latitude: 35.361613, longitude: -95.268894, zipCode: "74455"},
	{latitude: 35.710845, longitude: -95.9926, zipCode: "74456"},
	{latitude: 36.005973, longitude: -94.841439, zipCode: "74457"},
	{latitude: 35.88232, longitude: -95.58786, zipCode: "74458"},
	{latitude: 35.518777, longitude: -95.492126, zipCode: "74459"},
	{latitude: 35.504767, longitude: -95.964493, zipCode: "74460"},
	{latitude: 35.279649, longitude: -95.146719, zipCode: "74462"},
	{latitude: 35.770005, longitude: -95.551033, zipCode: "74463"},
	{latitude: 35.944081, longitude: -94.974083, zipCode: "74464"},
	{latitude: 35.967372, longitude: -95.393018, zipCode: "74467"},
	{latitude: 35.617263, longitude: -95.59935, zipCode: "74468"},
	{latitude: 35.51191, longitude: -95.321491, zipCode: "74469"},
	{latitude: 35.476717, longitude: -95.166878, zipCode: "74470"},
	{latitude: 35.834204, longitude: -94.871883, zipCode: "74471"},
	{latitude: 35.250048, longitude: -95.259719, zipCode: "74472"},
	{latitude: 36.003882, longitude: -95.266587, zipCode: "74477"},
	{latitude: 34.984203, longitude: -95.786716, zipCode: "74501"},
	{latitude: 34.662141, longitude: -95.099369, zipCode: "74521"},
	{latitude: 34.882542, longitude: -95.676716, zipCode: "74522"},
	{latitude: 34.251959, longitude: -95.602314, zipCode: "74523"},
	{latitude: 34.332767, longitude: -96.055576, zipCode: "74525"},
	{latitude: 34.702267, longitude: -95.727506, zipCode: "74528"},
	{latitude: 34.429746, longitude: -96.536907, zipCode: "74530"},
	{latitude: 34.881495, longitude: -96.228053, zipCode: "74531"},
	{latitude: 34.208012, longitude: -96.197011, zipCode: "74533"},
	{latitude: 34.62394, longitude: -96.360742, zipCode: "74534"},
	{latitude: 34.484118, longitude: -96.444883, zipCode: "74535"},
	{latitude: 34.582807, longitude: -95.42299, zipCode: "74536"},
	{latitude: 34.5694, longitude: -96.24928, zipCode: "74538"},
	{latitude: 34.571036, longitude: -95.708133, zipCode: "74540"},
	{latitude: 34.359386, longitude: -95.381336, zipCode: "74543"},
	{latitude: 34.852076, longitude: -95.580117, zipCode: "74546"},
	{latitude: 34.81008, longitude: -95.598826, zipCode: "74547"},
	{latitude: 34.562468, longitude: -94.894185, zipCode: "74549"},
	{latitude: 35.113371, longitude: -95.248923, zipCode: "74552"},
	{latitude: 34.71336, longitude: -95.925331, zipCode: "74553"},
	{latitude: 34.925675, longitude: -95.719977, zipCode: "74554"},
	{latitude: 34.249162, longitude: -95.964544, zipCode: "74555"},
	{latitude: 34.458207, longitude: -96.201347, zipCode: "74556"},
	{latitude: 34.387277, longitude: -95.698212, zipCode: "74557"},
	{latitude: 34.50478, longitude: -95.165928, zipCode: "74558"},
	{latitude: 34.648749, longitude: -95.760132, zipCode: "74560"},
	{latitude: 35.130293, longitude: -95.415199, zipCode: "74561"},
	{latitude: 34.306972, longitude: -95.312687, zipCode: "74562"},
	{latitude: 34.969955, longitude: -95.072632, zipCode: "74563"},
	{latitude: 34.81983, longitude: -95.822508, zipCode: "74565"},
	{latitude: 34.450336, longitude: -95.336203, zipCode: "74567"},
	{latitude: 34.477288, longitude: -95.924577, zipCode: "74569"},
	{latitude: 34.862799, longitude: -96.100965, zipCode: "74570"},
	{latitude: 34.719633, longitude: -95.049044, zipCode: "74571"},
	{latitude: 34.625926, longitude: -96.430569, zipCode: "74572"},
	{latitude: 34.676744, longitude: -95.261965, zipCode: "74574"},
	{latitude: 34.668289, longitude: -96.042682, zipCode: "74576"},
	{latitude: 34.733498, longitude: -94.86177, zipCode: "74577"},
	{latitude: 34.906147, longitude: -95.341662, zipCode: "74578"},
	{latitude: 36.702158, longitude: -97.148862, zipCode: "74601"},
	{latitude: 36.701928, longitude: -96.982361, zipCode: "74604"},
	{latitude: 36.499408, longitude: -97.435455, zipCode: "74630"},
	{latitude: 36.79918, longitude: -97.303867, zipCode: "74631"},
	{latitude: 36.940722, longitude: -97.341297, zipCode: "74632"},
	{latitude: 36.710214, longitude: -96.776634, zipCode: "74633"},
	{latitude: 36.822566, longitude: -97.522218, zipCode: "74636"},
	{latitude: 36.602785, longitude: -96.643875, zipCode: "74637"},
	{latitude: 36.57102, longitude: -97.649023, zipCode: "74640"},
	{latitude: 36.807356, longitude: -96.860722, zipCode: "74641"},
	{latitude: 36.655037, longitude: -97.575123, zipCode: "74643"},
	{latitude: 36.5597, longitude: -97.095983, zipCode: "74644"},
	{latitude: 36.821978, longitude: -97.442056, zipCode: "74646"},
	{latitude: 36.9181, longitude: -96.994561, zipCode: "74647"},
	{latitude: 36.480421, longitude: -96.720016, zipCode: "74650"},
	{latitude: 36.47096, longitude: -97.16449, zipCode: "74651"},
	{latitude: 36.859145, longitude: -96.644047, zipCode: "74652"},
	{latitude: 36.648616, longitude: -97.340668, zipCode: "74653"},
	{latitude: 34.009926, longitude: -96.377439, zipCode: "74701"},
	{latitude: 33.831478, longitude: -96.371632, zipCode: "74720"},
	{latitude: 33.879785, longitude: -96.162468, zipCode: "74721"},
	{latitude: 34.379582, longitude: -94.898278, zipCode: "74722"},
	{latitude: 33.992941, longitude: -96.017164, zipCode: "74723"},
	{latitude: 34.342044, longitude: -94.861546, zipCode: "74724"},
	{latitude: 33.941237, longitude: -96.174339, zipCode: "74726"},
	{latitude: 34.018703, longitude: -95.858735, zipCode: "74727"},
	{latitude: 34.157141, longitude: -94.820188, zipCode: "74728"},
	{latitude: 34.146808, longitude: -96.254066, zipCode: "74729"},
	{latitude: 33.912288, longitude: -96.4311, zipCode: "74730"},
	{latitude: 33.870143, longitude: -96.577163, zipCode: "74731"},
	{latitude: 33.822346, longitude: -96.481884, zipCode: "74733"},
	{latitude: 34.090553, longitude: -94.538276, zipCode: "74734"},
	{latitude: 34.096722, longitude: -95.261523, zipCode: "74735"},
	{latitude: 33.914485, longitude: -94.99277, zipCode: "74736"},
	{latitude: 34.030741, longitude: -94.902967, zipCode: "74737"},
	{latitude: 33.895691, longitude: -95.443392, zipCode: "74738"},
	{latitude: 33.767393, longitude: -94.597149, zipCode: "74740"},
	{latitude: 33.795005, longitude: -96.287607, zipCode: "74741"},
	{latitude: 34.006722, longitude: -95.510994, zipCode: "74743"},
	{latitude: 33.859098, longitude: -94.816353, zipCode: "74745"},
	{latitude: 33.758123, longitude: -96.315483, zipCode: "74747"},
	{latitude: 34.130818, longitude: -96.493039, zipCode: "74748"},
	{latitude: 33.985488, longitude: -95.011517, zipCode: "74750"},
	{latitude: 33.907851, longitude: -96.533959, zipCode: "74753"},
	{latitude: 34.191987, longitude: -95.119525, zipCode: "74754"},
	{latitude: 34.119409, longitude: -95.142024, zipCode: "74755"},
	{latitude: 34.08451, longitude: -95.374012, zipCode: "74756"},
	{latitude: 34.056297, longitude: -95.719572, zipCode: "74759"},
	{latitude: 34.163146, longitude: -95.355768, zipCode: "74760"},
	{latitude: 34.005089, longitude: -95.200867, zipCode: "74761"},
	{latitude: 34.090935, longitude: -95.057481, zipCode: "74764"},
	{latitude: 34.086021, longitude: -94.9739, zipCode: "74766"},
	{latitude: 35.315129, longitude: -96.969671, zipCode: "74801"},
	{latitude: 35.391465, longitude: -96.921469, zipCode: "74804"},
	{latitude: 34.8044, longitude: -96.715374, zipCode: "74820"},
	{latitude: 35.888506, longitude: -96.871285, zipCode: "74824"},
	{latitude: 34.822522, longitude: -96.391992, zipCode: "74825"},
	{latitude: 35.021029, longitude: -96.887141, zipCode: "74826"},
	{latitude: 34.939754, longitude: -96.343384, zipCode: "74827"},
	{latitude: 35.504916, longitude: -96.466379, zipCode: "74829"},
	{latitude: 35.144519, longitude: -96.664618, zipCode: "74830"},
	{latitude: 34.894175, longitude: -97.034356, zipCode: "74831"},
	{latitude: 35.828769, longitude: -97.006776, zipCode: "74832"},
	{latitude: 35.583036, longitude: -96.413893, zipCode: "74833"},
	{latitude: 35.69671, longitude: -96.878659, zipCode: "74834"},
	{latitude: 34.442069, longitude: -96.629676, zipCode: "74836"},
	{latitude: 35.34312, longitude: -96.456133, zipCode: "74837"},
	{latitude: 35.241439, longitude: -96.045582, zipCode: "74839"},
	{latitude: 35.308627, longitude: -96.769722, zipCode: "74840"},
	{latitude: 34.618599, longitude: -96.615173, zipCode: "74842"},
	{latitude: 34.65702, longitude: -96.728623, zipCode: "74843"},
	{latitude: 34.875738, longitude: -96.589142, zipCode: "74844"},
	{latitude: 35.253247, longitude: -95.879832, zipCode: "74845"},
	{latitude: 35.079874, longitude: -96.343719, zipCode: "74848"},
	{latitude: 34.978391, longitude: -96.735519, zipCode: "74849"},
	{latitude: 35.109686, longitude: -96.10715, zipCode: "74850"},
	{latitude: 35.426238, longitude: -97.081953, zipCode: "74851"},
	{latitude: 35.12394, longitude: -97.008872, zipCode: "74852"},
	{latitude: 35.110667, longitude: -96.765976, zipCode: "74854"},
	{latitude: 35.513888, longitude: -96.906423, zipCode: "74855"},
	{latitude: 34.396638, longitude: -96.816268, zipCode: "74856"},
	{latitude: 35.34024, longitude: -97.200546, zipCode: "74857"},
	{latitude: 35.456479, longitude: -96.321622, zipCode: "74859"},
	{latitude: 35.531903, longitude: -96.569823, zipCode: "74860"},
	{latitude: 35.498742, longitude: -96.707028, zipCode: "74864"},
	{latitude: 34.613477, longitude: -96.818238, zipCode: "74865"},
	{latitude: 35.066457, longitude: -96.846905, zipCode: "74866"},
	{latitude: 34.965048, longitude: -96.537532, zipCode: "74867"},
	{latitude: 35.278531, longitude: -96.644613, zipCode: "74868"},
	{latitude: 35.590368, longitude: -96.751152, zipCode: "74869"},
	{latitude: 34.596505, longitude: -96.55163, zipCode: "74871"},
	{latitude: 34.771696, longitude: -96.988016, zipCode: "74872"},
	{latitude: 35.232856, longitude: -96.977226, zipCode: "74873"},
	{latitude: 35.872583, longitude: -96.958564, zipCode: "74875"},
	{latitude: 35.004771, longitude: -97.069829, zipCode: "74878"},
	{latitude: 35.379751, longitude: -96.120506, zipCode: "74880"},
	{latitude: 35.714361, longitude: -97.079617, zipCode: "74881"},
	{latitude: 35.219868, longitude: -96.253013, zipCode: "74883"},
	{latitude: 35.184918, longitude: -96.515465, zipCode: "74884"},
	{latitude: 35.299141, longitude: -94.50136, zipCode: "74901"},
	{latitude: 35.251531, longitude: -94.467919, zipCode: "74902"},
	{latitude: 35.184955, longitude: -94.775052, zipCode: "74930"},
	{latitude: 35.686198, longitude: -94.7867, zipCode: "74931"},
	{latitude: 35.145602, longitude: -94.535377, zipCode: "74932"},
	{latitude: 34.961951, longitude: -94.911181, zipCode: "74935"},
	{latitude: 35.395424, longitude: -94.727941, zipCode: "74936"},
	{latitude: 34.817524, longitude: -94.587454, zipCode: "74937"},
	{latitude: 34.729805, longitude: -94.632473, zipCode: "74939"},
	{latitude: 34.949481, longitude: -94.631817, zipCode: "74940"},
	{latitude: 35.267539, longitude: -94.876146, zipCode: "74941"},
	{latitude: 34.894594, longitude: -94.978025, zipCode: "74942"},
	{latitude: 35.099974, longitude: -95.099411, zipCode: "74943"},
	{latitude: 35.130606, longitude: -95.005635, zipCode: "74944"},
	{latitude: 35.583882, longitude: -94.817396, zipCode: "74945"},
	{latitude: 35.396251, longitude: -94.452432, zipCode: "74946"},
	{latitude: 34.99147, longitude: -94.515856, zipCode: "74947"},
	{latitude: 35.467998, longitude: -94.568927, zipCode: "74948"},
	{latitude: 34.683524, longitude: -94.772087, zipCode: "74949"},
	{latitude: 35.171573, longitude: -94.663636, zipCode: "74951"},
	{latitude: 35.0381, longitude: -94.571497, zipCode: "74953"},
	{latitude: 35.412586, longitude: -94.490555, zipCode: "74954"},
	{latitude: 35.501381, longitude: -94.757501, zipCode: "74955"},
	{latitude: 35.096432, longitude: -94.774368, zipCode: "74956"},
	{latitude: 34.523791, longitude: -94.655141, zipCode: "74957"},
	{latitude: 35.270912, longitude: -94.623306, zipCode: "74959"},
	{latitude: 35.810426, longitude: -94.650548, zipCode: "74960"},
	{latitude: 35.544019, longitude: -94.940814, zipCode: "74962"},
	{latitude: 34.412238, longitude: -94.563365, zipCode: "74963"},
	{latitude: 36.111948, longitude: -94.658801, zipCode: "74964"},
	{latitude: 35.99654, longitude: -94.631092, zipCode: "74965"},
	{latitude: 34.894289, longitude: -94.870567, zipCode: "74966"},
	{latitude: 32.960047, longitude: -96.83724, zipCode: "75001"},
	{latitude: 33.089854, longitude: -96.6086, zipCode: "75002"},
	{latitude: 32.962141, longitude: -96.898585, zipCode: "75006"},
	{latitude: 33.005262, longitude: -96.896742, zipCode: "75007"},
	{latitude: 33.338899, longitude: -96.752977, zipCode: "75009"},
	{latitude: 33.04078, longitude: -96.875462, zipCode: "75010"},
	{latitude: 33.114327, longitude: -96.693964, zipCode: "75013"},
	{latitude: 32.961253, longitude: -96.985817, zipCode: "75019"},
	{latitude: 33.772722, longitude: -96.606962, zipCode: "75020"},
	{latitude: 33.727988, longitude: -96.478369, zipCode: "75021"},
	{latitude: 33.024484, longitude: -97.119504, zipCode: "75022"},
	{latitude: 33.056789, longitude: -96.730859, zipCode: "75023"},
	{latitude: 33.077854, longitude: -96.811028, zipCode: "75024"},
	{latitude: 33.090085, longitude: -96.740008, zipCode: "75025"},
	{latitude: 33.032998, longitude: -97.064618, zipCode: "75028"},
	{latitude: 32.857112, longitude: -96.43121, zipCode: "75032"},
	{latitude: 33.184818, longitude: -96.844308, zipCode: "75033"},
	{latitude: 33.121286, longitude: -96.838536, zipCode: "75034"},
	{latitude: 33.166306, longitude: -96.775635, zipCode: "75035"},
	{latitude: 33.133351, longitude: -96.914663, zipCode: "75036"},
	{latitude: 32.87226, longitude: -96.97881, zipCode: "75038"},
	{latitude: 32.884959, longitude: -96.937297, zipCode: "75039"},
	{latitude: 32.928902, longitude: -96.619782, zipCode: "75040"},
	{latitude: 32.880196, longitude: -96.651555, zipCode: "75041"},
	{latitude: 32.914495, longitude: -96.674995, zipCode: "75042"},
	{latitude: 32.855772, longitude: -96.578214, zipCode: "75043"},
	{latitude: 32.964609, longitude: -96.649688, zipCode: "75044"},
	{latitude: 32.969015, longitude: -96.579523, zipCode: "75048"},
	{latitude: 32.773069, longitude: -97.005227, zipCode: "75050"},
	{latitude: 32.725591, longitude: -96.992072, zipCode: "75051"},
	{latitude: 32.664684, longitude: -97.029478, zipCode: "75052"},
	{latitude: 32.613848, longitude: -97.025353, zipCode: "75054"},
	{latitude: 33.075699, longitude: -96.908088, zipCode: "75056"},
	{latitude: 33.050725, longitude: -96.974623, zipCode: "75057"},
	{latitude: 33.449112, longitude: -96.737121, zipCode: "75058"},
	{latitude: 32.798049, longitude: -96.952563, zipCode: "75060"},
	{latitude: 32.825907, longitude: -96.966915, zipCode: "75061"},
	{latitude: 32.84737, longitude: -96.957871, zipCode: "75062"},
	{latitude: 32.910627, longitude: -97.000481, zipCode: "75063"},
	{latitude: 33.11034, longitude: -97.00545, zipCode: "75065"},
	{latitude: 33.016102, longitude: -96.998962, zipCode: "75067"},
	{latitude: 33.177497, longitude: -96.952388, zipCode: "75068"},
	{latitude: 33.164093, longitude: -96.59544, zipCode: "75069"},
	{latitude: 33.154678, longitude: -96.696669, zipCode: "75070"},
	{latitude: 33.245816, longitude: -96.63072, zipCode: "75071"},
	{latitude: 33.187899, longitude: -96.699951, zipCode: "75072"},
	{latitude: 33.033284, longitude: -96.672758, zipCode: "75074"},
	{latitude: 33.021268, longitude: -96.741558, zipCode: "75075"},
	{latitude: 33.800692, longitude: -96.726011, zipCode: "75076"},
	{latitude: 33.081178, longitude: -97.060925, zipCode: "75077"},
	{latitude: 33.241332, longitude: -96.812388, zipCode: "75078"},
	{latitude: 32.976063, longitude: -96.74208, zipCode: "75080"},
	{latitude: 32.949566, longitude: -96.70742, zipCode: "75081"},
	{latitude: 32.994599, longitude: -96.666758, zipCode: "75082"},
	{latitude: 32.948579, longitude: -96.442091, zipCode: "75087"},
	{latitude: 32.891629, longitude: -96.541754, zipCode: "75088"},
	{latitude: 32.93758, longitude: -96.544176, zipCode: "75089"},
	{latitude: 33.604131, longitude: -96.550405, zipCode: "75090"},
	{latitude: 33.652066, longitude: -96.700263, zipCode: "75092"},
	{latitude: 33.034224, longitude: -96.811608, zipCode: "75093"},
	{latitude: 33.01983, longitude: -96.613463, zipCode: "75094"},
	{latitude: 33.014492, longitude: -96.534832, zipCode: "75098"},
	{latitude: 32.270024, longitude: -96.702449, zipCode: "75101"},
	{latitude: 32.086623, longitude: -96.635777, zipCode: "75102"},
	{latitude: 32.51693, longitude: -95.883547, zipCode: "75103"},
	{latitude: 32.584814, longitude: -96.972312, zipCode: "75104"},
	{latitude: 32.267126, longitude: -96.367988, zipCode: "75105"},
	{latitude: 32.042784, longitude: -96.343754, zipCode: "75109"},
	{latitude: 32.079637, longitude: -96.520474, zipCode: "75110"},
	{latitude: 32.616552, longitude: -96.443365, zipCode: "75114"},
	{latitude: 32.599427, longitude: -96.864075, zipCode: "75115"},
	{latitude: 32.66066, longitude: -96.911882, zipCode: "75116"},
	{latitude: 32.711103, longitude: -95.858365, zipCode: "75117"},
	{latitude: 32.323939, longitude: -96.590285, zipCode: "75119"},
	{latitude: 32.318568, longitude: -95.970223, zipCode: "75124"},
	{latitude: 32.53675, longitude: -96.621707, zipCode: "75125"},
	{latitude: 32.742601, longitude: -96.452883, zipCode: "75126"},
	{latitude: 32.699397, longitude: -95.791444, zipCode: "75127"},
	{latitude: 32.938662, longitude: -96.380424, zipCode: "75132"},
	{latitude: 32.622839, longitude: -96.779517, zipCode: "75134"},
	{latitude: 33.067548, longitude: -96.224353, zipCode: "75135"},
	{latitude: 32.634831, longitude: -96.91341, zipCode: "75137"},
	{latitude: 32.635479, longitude: -95.686402, zipCode: "75140"},
	{latitude: 32.639977, longitude: -96.693284, zipCode: "75141"},
	{latitude: 32.568163, longitude: -96.250214, zipCode: "75142"},
	{latitude: 32.364579, longitude: -96.244987, zipCode: "75143"},
	{latitude: 32.108388, longitude: -96.207955, zipCode: "75144"},
	{latitude: 32.566313, longitude: -96.748636, zipCode: "75146"},
	{latitude: 32.426069, longitude: -96.06456, zipCode: "75147"},
	{latitude: 32.124647, longitude: -96.02579, zipCode: "75148"},
	{latitude: 32.773369, longitude: -96.618811, zipCode: "75149"},
	{latitude: 32.813863, longitude: -96.63098, zipCode: "75150"},
	{latitude: 32.439871, longitude: -96.678051, zipCode: "75152"},
	{latitude: 32.166522, longitude: -96.358838, zipCode: "75153"},
	{latitude: 32.524259, longitude: -96.809069, zipCode: "75154"},
	{latitude: 32.229113, longitude: -96.475509, zipCode: "75155"},
	{latitude: 32.277923, longitude: -96.105287, zipCode: "75156"},
	{latitude: 32.463433, longitude: -96.449255, zipCode: "75157"},
	{latitude: 32.465314, longitude: -96.401101, zipCode: "75158"},
	{latitude: 32.604328, longitude: -96.5406, zipCode: "75159"},
	{latitude: 32.764343, longitude: -96.293313, zipCode: "75160"},
	{latitude: 32.738794, longitude: -96.163857, zipCode: "75161"},
	{latitude: 32.166777, longitude: -96.118531, zipCode: "75163"},
	{latitude: 33.075574, longitude: -96.303404, zipCode: "75164"},
	{latitude: 32.371654, longitude: -96.80303, zipCode: "75165"},
	{latitude: 33.014721, longitude: -96.449374, zipCode: "75166"},
	{latitude: 32.360829, longitude: -96.915523, zipCode: "75167"},
	{latitude: 32.703269, longitude: -95.994666, zipCode: "75169"},
	{latitude: 32.605321, longitude: -96.674073, zipCode: "75172"},
	{latitude: 33.061737, longitude: -96.395825, zipCode: "75173"},
	{latitude: 32.719382, longitude: -96.618565, zipCode: "75180"},
	{latitude: 32.723894, longitude: -96.555946, zipCode: "75181"},
	{latitude: 32.800525, longitude: -96.547577, zipCode: "75182"},
	{latitude: 32.94205, longitude: -96.311669, zipCode: "75189"},
	{latitude: 32.788309, longitude: -96.799572, zipCode: "75201"},
	{latitude: 32.778463, longitude: -96.803734, zipCode: "75202"},
	{latitude: 32.744319, longitude: -96.801867, zipCode: "75203"},
	{latitude: 32.80219, longitude: -96.78889, zipCode: "75204"},
	{latitude: 32.837129, longitude: -96.797155, zipCode: "75205"},
	{latitude: 32.831279, longitude: -96.771191, zipCode: "75206"},
	{latitude: 32.7877, longitude: -96.820656, zipCode: "75207"},
	{latitude: 32.754622, longitude: -96.839633, zipCode: "75208"},
	{latitude: 32.84831, longitude: -96.825474, zipCode: "75209"},
	{latitude: 32.771314, longitude: -96.747148, zipCode: "75210"},
	{latitude: 32.736827, longitude: -96.907506, zipCode: "75211"},
	{latitude: 32.78225, longitude: -96.878833, zipCode: "75212"},
	{latitude: 32.828956, longitude: -96.742915, zipCode: "75214"},
	{latitude: 32.752897, longitude: -96.761, zipCode: "75215"},
	{latitude: 32.711324, longitude: -96.781215, zipCode: "75216"},
	{latitude: 32.712583, longitude: -96.682045, zipCode: "75217"},
	{latitude: 32.840254, longitude: -96.704041, zipCode: "75218"},
	{latitude: 32.810894, longitude: -96.813193, zipCode: "75219"},
	{latitude: 32.868529, longitude: -96.876118, zipCode: "75220"},
	{latitude: 32.792565, longitude: -96.745584, zipCode: "75223"},
	{latitude: 32.711405, longitude: -96.838591, zipCode: "75224"},
	{latitude: 32.864887, longitude: -96.790359, zipCode: "75225"},
	{latitude: 32.783102, longitude: -96.776627, zipCode: "75226"},
	{latitude: 32.76681, longitude: -96.6869, zipCode: "75227"},
	{latitude: 32.824924, longitude: -96.679438, zipCode: "75228"},
	{latitude: 32.893614, longitude: -96.867317, zipCode: "75229"},
	{latitude: 32.902679, longitude: -96.793393, zipCode: "75230"},
	{latitude: 32.873494, longitude: -96.747555, zipCode: "75231"},
	{latitude: 32.658999, longitude: -96.839593, zipCode: "75232"},
	{latitude: 32.703913, longitude: -96.871889, zipCode: "75233"},
	{latitude: 32.924853, longitude: -96.888139, zipCode: "75234"},
	{latitude: 32.832614, longitude: -96.849082, zipCode: "75235"},
	{latitude: 32.688844, longitude: -96.93571, zipCode: "75236"},
	{latitude: 32.664916, longitude: -96.872401, zipCode: "75237"},
	{latitude: 32.878511, longitude: -96.707852, zipCode: "75238"},
	{latitude: 32.930154, longitude: -96.792727, zipCode: "75240"},
	{latitude: 32.666747, longitude: -96.758878, zipCode: "75241"},
	{latitude: 32.912633, longitude: -96.736637, zipCode: "75243"},
	{latitude: 32.925367, longitude: -96.836334, zipCode: "75244"},
	{latitude: 32.792496, longitude: -96.772763, zipCode: "75246"},
	{latitude: 32.814402, longitude: -96.878295, zipCode: "75247"},
	{latitude: 32.969699, longitude: -96.797334, zipCode: "75248"},
	{latitude: 32.636053, longitude: -96.964574, zipCode: "75249"},
	{latitude: 32.919435, longitude: -96.77478, zipCode: "75251"},
	{latitude: 32.996776, longitude: -96.788479, zipCode: "75252"},
	{latitude: 32.674426, longitude: -96.609228, zipCode: "75253"},
	{latitude: 32.944116, longitude: -96.80009, zipCode: "75254"},
	{latitude: 32.903206, longitude: -97.05313, zipCode: "75261"},
	{latitude: 32.78126, longitude: -96.801922, zipCode: "75270"},
	{latitude: 32.999309, longitude: -96.841688, zipCode: "75287"},
	{latitude: 32.813992, longitude: -96.842808, zipCode: "75390"},
	{latitude: 33.189002, longitude: -96.108829, zipCode: "75401"},
	{latitude: 33.067866, longitude: -96.08797, zipCode: "75402"},
	{latitude: 33.155792, longitude: -96.48041, zipCode: "75407"},
	{latitude: 33.3555, longitude: -96.516313, zipCode: "75409"},
	{latitude: 32.79246, longitude: -95.637012, zipCode: "75410"},
	{latitude: 33.868296, longitude: -95.637752, zipCode: "75411"},
	{latitude: 33.800848, longitude: -95.13475, zipCode: "75412"},
	{latitude: 33.435954, longitude: -96.164755, zipCode: "75413"},
	{latitude: 33.623644, longitude: -96.427868, zipCode: "75414"},
	{latitude: 33.455683, longitude: -95.723798, zipCode: "75415"},
	{latitude: 33.598091, longitude: -95.32831, zipCode: "75416"},
	{latitude: 33.439658, longitude: -95.140371, zipCode: "75417"},
	{latitude: 33.571922, longitude: -96.170962, zipCode: "75418"},
	{latitude: 33.065239, longitude: -95.71835, zipCode: "75420"},
	{latitude: 33.66136, longitude: -95.731245, zipCode: "75421"},
	{latitude: 33.139105, longitude: -95.92361, zipCode: "75422"},
	{latitude: 33.291919, longitude: -96.199842, zipCode: "75423"},
	{latitude: 33.305372, longitude: -96.389293, zipCode: "75424"},
	{latitude: 33.648255, longitude: -95.011561, zipCode: "75426"},
	{latitude: 33.274704, longitude: -95.917561, zipCode: "75428"},
	{latitude: 33.278511, longitude: -95.892297, zipCode: "75429"},
	{latitude: 33.01419, longitude: -95.460935, zipCode: "75431"},
	{latitude: 33.385933, longitude: -95.67335, zipCode: "75432"},
	{latitude: 33.098096, longitude: -95.803234, zipCode: "75433"},
	{latitude: 33.416263, longitude: -95.344129, zipCode: "75434"},
	{latitude: 33.500508, longitude: -95.290328, zipCode: "75435"},
	{latitude: 33.742203, longitude: -95.25565, zipCode: "75436"},
	{latitude: 33.257166, longitude: -95.464741, zipCode: "75437"},
	{latitude: 33.615571, longitude: -96.072162, zipCode: "75438"},
	{latitude: 33.55352, longitude: -96.282897, zipCode: "75439"},
	{latitude: 32.872902, longitude: -95.753991, zipCode: "75440"},
	{latitude: 33.427656, longitude: -95.642911, zipCode: "75441"},
	{latitude: 33.176073, longitude: -96.346433, zipCode: "75442"},
	{latitude: 33.615093, longitude: -95.900092, zipCode: "75446"},
	{latitude: 33.772396, longitude: -96.124356, zipCode: "75447"},
	{latitude: 33.316269, longitude: -95.788968, zipCode: "75448"},
	{latitude: 33.423386, longitude: -95.949483, zipCode: "75449"},
	{latitude: 33.397908, longitude: -95.461615, zipCode: "75450"},
	{latitude: 32.9593, longitude: -95.12521, zipCode: "75451"},
	{latitude: 33.402226, longitude: -96.23857, zipCode: "75452"},
	{latitude: 33.009868, longitude: -95.93267, zipCode: "75453"},
	{latitude: 33.282924, longitude: -96.561165, zipCode: "75454"},
	{latitude: 33.217422, longitude: -94.983011, zipCode: "75455"},
	{latitude: 33.176424, longitude: -95.209861, zipCode: "75457"},
	{latitude: 33.528758, longitude: -96.682333, zipCode: "75459"},
	{latitude: 33.662129, longitude: -95.604973, zipCode: "75460"},
	{latitude: 33.624342, longitude: -95.491739, zipCode: "75462"},
	{latitude: 33.507381, longitude: -95.403599, zipCode: "75468"},
	{latitude: 33.399609, longitude: -95.82251, zipCode: "75469"},
	{latitude: 33.587176, longitude: -95.802008, zipCode: "75470"},
	{latitude: 33.056806, longitude: -95.373518, zipCode: "75471"},
	{latitude: 32.884423, longitude: -95.869602, zipCode: "75472"},
	{latitude: 33.815579, longitude: -95.488005, zipCode: "75473"},
	{latitude: 32.924005, longitude: -96.109433, zipCode: "75474"},
	{latitude: 33.485946, longitude: -96.257579, zipCode: "75475"},
	{latitude: 33.708311, longitude: -96.240654, zipCode: "75476"},
	{latitude: 33.529909, longitude: -95.738073, zipCode: "75477"},
	{latitude: 33.173205, longitude: -95.348615, zipCode: "75478"},
	{latitude: 33.630179, longitude: -96.330285, zipCode: "75479"},
	{latitude: 33.015266, longitude: -95.195886, zipCode: "75480"},
	{latitude: 33.320029, longitude: -95.373504, zipCode: "75481"},
	{latitude: 33.167968, longitude: -95.608224, zipCode: "75482"},
	{latitude: 33.745235, longitude: -95.744327, zipCode: "75486"},
	{latitude: 33.33936, longitude: -95.160193, zipCode: "75487"},
	{latitude: 33.778767, longitude: -95.996778, zipCode: "75488"},
	{latitude: 33.520308, longitude: -96.485194, zipCode: "75489"},
	{latitude: 33.438031, longitude: -96.30828, zipCode: "75490"},
	{latitude: 33.486324, longitude: -96.39593, zipCode: "75491"},
	{latitude: 33.597043, longitude: -95.991995, zipCode: "75492"},
	{latitude: 33.142229, longitude: -95.114425, zipCode: "75493"},
	{latitude: 32.917192, longitude: -95.25986, zipCode: "75494"},
	{latitude: 33.439195, longitude: -96.546841, zipCode: "75495"},
	{latitude: 33.363703, longitude: -96.065379, zipCode: "75496"},
	{latitude: 32.921857, longitude: -95.576169, zipCode: "75497"},
	{latitude: 33.371831, longitude: -94.139813, zipCode: "75501"},
	{latitude: 33.51791, longitude: -94.13711, zipCode: "75503"},
	{latitude: 33.498221, longitude: -94.890845, zipCode: "75550"},
	{latitude: 33.119332, longitude: -94.220491, zipCode: "75551"},
	{latitude: 33.57901, longitude: -94.779754, zipCode: "75554"},
	{latitude: 32.939166, longitude: -94.142316, zipCode: "75555"},
	{latitude: 33.154789, longitude: -94.062407, zipCode: "75556"},
	{latitude: 33.255524, longitude: -94.849719, zipCode: "75558"},
	{latitude: 33.50355, longitude: -94.649921, zipCode: "75559"},
	{latitude: 33.202156, longitude: -94.36614, zipCode: "75560"},
	{latitude: 33.516756, longitude: -94.281559, zipCode: "75561"},
	{latitude: 32.935893, longitude: -94.253046, zipCode: "75562"},
	{latitude: 33.027773, longitude: -94.393045, zipCode: "75563"},
	{latitude: 32.887825, longitude: -94.249636, zipCode: "75564"},
	{latitude: 32.950209, longitude: -94.073953, zipCode: "75565"},
	{latitude: 33.152559, longitude: -94.490158, zipCode: "75566"},
	{latitude: 33.31607, longitude: -94.320839, zipCode: "75567"},
	{latitude: 33.193211, longitude: -94.587279, zipCode: "75568"},
	{latitude: 33.4412, longitude: -94.122717, zipCode: "75569"},
	{latitude: 33.463886, longitude: -94.437383, zipCode: "75570"},
	{latitude: 33.207019, longitude: -94.77363, zipCode: "75571"},
	{latitude: 33.225893, longitude: -94.127695, zipCode: "75572"},
	{latitude: 33.348093, longitude: -94.229102, zipCode: "75573"},
	{latitude: 33.325004, longitude: -94.550659, zipCode: "75574"},
	{latitude: 32.508595, longitude: -94.724189, zipCode: "75601"},
	{latitude: 32.4368, longitude: -94.643472, zipCode: "75602"},
	{latitude: 32.40305, longitude: -94.711177, zipCode: "75603"},
	{latitude: 32.513584, longitude: -94.828048, zipCode: "75604"},
	{latitude: 32.585123, longitude: -94.710328, zipCode: "75605"},
	{latitude: 32.853106, longitude: -94.572524, zipCode: "75630"},
	{latitude: 32.261589, longitude: -94.449852, zipCode: "75631"},
	{latitude: 32.138496, longitude: -94.274156, zipCode: "75633"},
	{latitude: 33.020475, longitude: -94.729699, zipCode: "75638"},
	{latitude: 32.278637, longitude: -94.168956, zipCode: "75639"},
	{latitude: 32.708417, longitude: -94.683181, zipCode: "75640"},
	{latitude: 32.384546, longitude: -94.575669, zipCode: "75641"},
	{latitude: 32.358198, longitude: -94.1782, zipCode: "75642"},
	{latitude: 32.005834, longitude: -94.361607, zipCode: "75643"},
	{latitude: 32.791213, longitude: -94.999846, zipCode: "75644"},
	{latitude: 32.67875, longitude: -94.879548, zipCode: "75645"},
	{latitude: 32.516559, longitude: -94.963086, zipCode: "75647"},
	{latitude: 32.513277, longitude: -94.548846, zipCode: "75650"},
	{latitude: 32.669181, longitude: -94.524475, zipCode: "75651"},
	{latitude: 32.239409, longitude: -94.728328, zipCode: "75652"},
	{latitude: 32.091227, longitude: -94.834457, zipCode: "75654"},
	{latitude: 33.011304, longitude: -94.572161, zipCode: "75656"},
	{latitude: 32.794973, longitude: -94.310306, zipCode: "75657"},
	{latitude: 32.172179, longitude: -94.899076, zipCode: "75658"},
	{latitude: 32.507254, longitude: -94.092462, zipCode: "75659"},
	{latitude: 32.638008, longitude: -94.153019, zipCode: "75661"},
	{latitude: 32.38033, longitude: -94.8741, zipCode: "75662"},
	{latitude: 31.988175, longitude: -94.844274, zipCode: "75667"},
	{latitude: 32.907075, longitude: -94.678637, zipCode: "75668"},
	{latitude: 32.032182, longitude: -94.554822, zipCode: "75669"},
	{latitude: 32.55933, longitude: -94.422306, zipCode: "75670"},
	{latitude: 32.489068, longitude: -94.314972, zipCode: "75671"},
	{latitude: 32.468303, longitude: -94.299079, zipCode: "75672"},
	{latitude: 32.010317, longitude: -94.718133, zipCode: "75680"},
	{latitude: 31.954241, longitude: -94.683616, zipCode: "75681"},
	{latitude: 32.261589, longitude: -94.953623, zipCode: "75682"},
	{latitude: 32.826816, longitude: -94.737224, zipCode: "75683"},
	{latitude: 32.277021, longitude: -94.937156, zipCode: "75684"},
	{latitude: 32.967111, longitude: -94.944207, zipCode: "75686"},
	{latitude: 32.105834, longitude: -94.963425, zipCode: "75687"},
	{latitude: 32.187066, longitude: -94.961598, zipCode: "75689"},
	{latitude: 32.311917, longitude: -94.551752, zipCode: "75691"},
	{latitude: 32.470841, longitude: -94.118036, zipCode: "75692"},
	{latitude: 32.53378, longitude: -94.86066, zipCode: "75693"},
	{latitude: 32.638328, longitude: -94.344401, zipCode: "75694"},
	{latitude: 32.322885, longitude: -95.296265, zipCode: "75701"},
	{latitude: 32.363991, longitude: -95.311744, zipCode: "75702"},
	{latitude: 32.235111, longitude: -95.320764, zipCode: "75703"},
	{latitude: 32.401635, longitude: -95.448727, zipCode: "75704"},
	{latitude: 32.375415, longitude: -95.076528, zipCode: "75705"},
	{latitude: 32.472409, longitude: -95.294022, zipCode: "75706"},
	{latitude: 32.303774, longitude: -95.168767, zipCode: "75707"},
	{latitude: 32.413219, longitude: -95.208673, zipCode: "75708"},
	{latitude: 32.312406, longitude: -95.385525, zipCode: "75709"},
	{latitude: 32.336975, longitude: -95.283818, zipCode: "75711"},
	{latitude: 32.26754, longitude: -95.077715, zipCode: "75750"},
	{latitude: 32.069812, longitude: -95.920717, zipCode: "75751"},
	{latitude: 32.251937, longitude: -95.799861, zipCode: "75752"},
	{latitude: 32.412949, longitude: -95.64943, zipCode: "75754"},
	{latitude: 32.641695, longitude: -95.075624, zipCode: "75755"},
	{latitude: 32.293485, longitude: -95.578644, zipCode: "75756"},
	{latitude: 32.11, longitude: -95.348317, zipCode: "75757"},
	{latitude: 32.271436, longitude: -95.521442, zipCode: "75758"},
	{latitude: 32.036141, longitude: -95.415783, zipCode: "75759"},
	{latitude: 31.809907, longitude: -94.863217, zipCode: "75760"},
	{latitude: 32.218341, longitude: -95.413445, zipCode: "75762"},
	{latitude: 32.042754, longitude: -95.523388, zipCode: "75763"},
	{latitude: 31.895231, longitude: -95.14368, zipCode: "75764"},
	{latitude: 32.642043, longitude: -95.234859, zipCode: "75765"},
	{latitude: 31.9463, longitude: -95.25187, zipCode: "75766"},
	{latitude: 32.158557, longitude: -95.643712, zipCode: "75770"},
	{latitude: 32.532678, longitude: -95.427268, zipCode: "75771"},
	{latitude: 32.681453, longitude: -95.447702, zipCode: "75773"},
	{latitude: 32.300926, longitude: -95.709837, zipCode: "75778"},
	{latitude: 31.863226, longitude: -95.484612, zipCode: "75779"},
	{latitude: 31.989107, longitude: -95.091218, zipCode: "75780"},
	{latitude: 32.068956, longitude: -95.588366, zipCode: "75782"},
	{latitude: 32.816459, longitude: -95.424768, zipCode: "75783"},
	{latitude: 31.871756, longitude: -94.965633, zipCode: "75784"},
	{latitude: 31.75786, longitude: -95.176209, zipCode: "75785"},
	{latitude: 31.822539, longitude: -94.916146, zipCode: "75788"},
	{latitude: 32.109512, longitude: -95.094194, zipCode: "75789"},
	{latitude: 32.526985, longitude: -95.643165, zipCode: "75790"},
	{latitude: 32.221677, longitude: -95.220676, zipCode: "75791"},
	{latitude: 32.46911, longitude: -95.106318, zipCode: "75792"},
	{latitude: 31.741893, longitude: -95.551175, zipCode: "75801"},
	{latitude: 31.881777, longitude: -95.679494, zipCode: "75803"},
	{latitude: 31.473667, longitude: -96.009365, zipCode: "75831"},
	{latitude: 31.268635, longitude: -95.843907, zipCode: "75833"},
	{latitude: 31.284891, longitude: -95.490625, zipCode: "75835"},
	{latitude: 31.4789, longitude: -96.229757, zipCode: "75838"},
	{latitude: 31.618014, longitude: -95.559002, zipCode: "75839"},
	{latitude: 31.782559, longitude: -96.093621, zipCode: "75840"},
	{latitude: 31.525333, longitude: -95.422927, zipCode: "75844"},
	{latitude: 31.087576, longitude: -95.076959, zipCode: "75845"},
	{latitude: 31.347499, longitude: -96.179086, zipCode: "75846"},
	{latitude: 31.342009, longitude: -95.119727, zipCode: "75847"},
	{latitude: 31.772741, longitude: -96.334029, zipCode: "75848"},
	{latitude: 31.396569, longitude: -95.476161, zipCode: "75849"},
	{latitude: 31.135677, longitude: -95.935045, zipCode: "75850"},
	{latitude: 31.092367, longitude: -95.499937, zipCode: "75851"},
	{latitude: 30.999541, longitude: -95.699976, zipCode: "75852"},
	{latitude: 31.946872, longitude: -95.814466, zipCode: "75853"},
	{latitude: 31.557331, longitude: -95.863641, zipCode: "75855"},
	{latitude: 31.201358, longitude: -95.25369, zipCode: "75856"},
	{latitude: 31.380811, longitude: -95.136741, zipCode: "75858"},
	{latitude: 31.887924, longitude: -96.254291, zipCode: "75859"},
	{latitude: 31.622647, longitude: -96.245354, zipCode: "75860"},
	{latitude: 31.851579, longitude: -95.888459, zipCode: "75861"},
	{latitude: 30.964728, longitude: -95.325895, zipCode: "75862"},
	{latitude: 31.058171, longitude: -94.96239, zipCode: "75865"},
	{latitude: 31.789238, longitude: -95.892617, zipCode: "75884"},
	{latitude: 31.777759, longitude: -95.916435, zipCode: "75886"},
	{latitude: 31.301675, longitude: -94.641408, zipCode: "75901"},
	{latitude: 31.374077, longitude: -94.748009, zipCode: "75903"},
	{latitude: 31.32705, longitude: -94.839618, zipCode: "75904"},
	{latitude: 31.614855, longitude: -95.062493, zipCode: "75925"},
	{latitude: 31.244111, longitude: -94.958914, zipCode: "75926"},
	{latitude: 30.671395, longitude: -93.71134, zipCode: "75928"},
	{latitude: 31.226678, longitude: -94.191712, zipCode: "75929"},
	{latitude: 31.33998, longitude: -94.035231, zipCode: "75930"},
	{latitude: 31.095522, longitude: -93.994918, zipCode: "75931"},
	{latitude: 31.056147, longitude: -93.615655, zipCode: "75932"},
	{latitude: 30.553812, longitude: -93.812696, zipCode: "75933"},
	{latitude: 30.895058, longitude: -94.759121, zipCode: "75934"},
	{latitude: 31.719778, longitude: -94.199007, zipCode: "75935"},
	{latitude: 30.951446, longitude: -94.58884, zipCode: "75936"},
	{latitude: 31.481989, longitude: -94.395878, zipCode: "75937"},
	{latitude: 30.940517, longitude: -94.35364, zipCode: "75938"},
	{latitude: 31.017256, longitude: -94.801457, zipCode: "75939"},
	{latitude: 31.187072, longitude: -94.758803, zipCode: "75941"},
	{latitude: 30.825788, longitude: -94.416471, zipCode: "75942"},
	{latitude: 31.658422, longitude: -94.894177, zipCode: "75943"},
	{latitude: 31.354095, longitude: -94.38768, zipCode: "75944"},
	{latitude: 31.814449, longitude: -94.51503, zipCode: "75946"},
	{latitude: 31.274876, longitude: -93.721735, zipCode: "75948"},
	{latitude: 31.229843, longitude: -94.509183, zipCode: "75949"},
	{latitude: 30.934425, longitude: -94.059578, zipCode: "75951"},
	{latitude: 31.904341, longitude: -94.048094, zipCode: "75954"},
	{latitude: 30.676367, longitude: -93.98312, zipCode: "75956"},
	{latitude: 31.512269, longitude: -93.839109, zipCode: "75959"},
	{latitude: 30.910921, longitude: -94.854674, zipCode: "75960"},
	{latitude: 31.558362, longitude: -94.504771, zipCode: "75961"},
	{latitude: 31.619839, longitude: -94.648445, zipCode: "75962"},
	{latitude: 31.62953, longitude: -94.77679, zipCode: "75964"},
	{latitude: 31.728994, longitude: -94.626523, zipCode: "75965"},
	{latitude: 30.827971, longitude: -93.714454, zipCode: "75966"},
	{latitude: 31.252156, longitude: -93.954016, zipCode: "75968"},
	{latitude: 31.445715, longitude: -94.88759, zipCode: "75969"},
	{latitude: 31.502076, longitude: -94.121587, zipCode: "75972"},
	{latitude: 31.711317, longitude: -93.942853, zipCode: "75973"},
	{latitude: 31.958364, longitude: -94.239838, zipCode: "75974"},
	{latitude: 31.890537, longitude: -94.418619, zipCode: "75975"},
	{latitude: 31.519361, longitude: -94.950427, zipCode: "75976"},
	{latitude: 31.065187, longitude: -93.786673, zipCode: "75977"},
	{latitude: 30.748559, longitude: -94.38912, zipCode: "75979"},
	{latitude: 31.094487, longitude: -94.350197, zipCode: "75980"},
	{latitude: 32.631081, longitude: -97.155866, zipCode: "76001"},
	{latitude: 32.621896, longitude: -97.093509, zipCode: "76002"},
	{latitude: 32.801114, longitude: -97.075615, zipCode: "76005"},
	{latitude: 32.787079, longitude: -97.102578, zipCode: "76006"},
	{latitude: 32.682396, longitude: -97.626635, zipCode: "76008"},
	{latitude: 32.41621, longitude: -97.202071, zipCode: "76009"},
	{latitude: 32.72271, longitude: -97.080659, zipCode: "76010"},
	{latitude: 32.75203, longitude: -97.082615, zipCode: "76011"},
	{latitude: 32.75513, longitude: -97.139242, zipCode: "76012"},
	{latitude: 32.719328, longitude: -97.155027, zipCode: "76013"},
	{latitude: 32.692105, longitude: -97.086517, zipCode: "76014"},
	{latitude: 32.691638, longitude: -97.135959, zipCode: "76015"},
	{latitude: 32.689222, longitude: -97.18913, zipCode: "76016"},
	{latitude: 32.662513, longitude: -97.164024, zipCode: "76017"},
	{latitude: 32.662289, longitude: -97.090382, zipCode: "76018"},
	{latitude: 32.900196, longitude: -97.561179, zipCode: "76020"},
	{latitude: 32.852971, longitude: -97.130443, zipCode: "76021"},
	{latitude: 32.830632, longitude: -97.145045, zipCode: "76022"},
	{latitude: 33.041952, longitude: -97.597065, zipCode: "76023"},
	{latitude: 32.532677, longitude: -97.306547, zipCode: "76028"},
	{latitude: 32.346162, longitude: -97.328078, zipCode: "76031"},
	{latitude: 32.291735, longitude: -97.516796, zipCode: "76033"},
	{latitude: 32.891478, longitude: -97.149747, zipCode: "76034"},
	{latitude: 32.565628, longitude: -97.646152, zipCode: "76035"},
	{latitude: 32.570974, longitude: -97.421275, zipCode: "76036"},
	{latitude: 32.860285, longitude: -97.086028, zipCode: "76039"},
	{latitude: 32.819908, longitude: -97.102794, zipCode: "76040"},
	{latitude: 32.237638, longitude: -96.84175, zipCode: "76041"},
	{latitude: 32.198144, longitude: -97.76065, zipCode: "76043"},
	{latitude: 32.425936, longitude: -97.539874, zipCode: "76044"},
	{latitude: 32.408186, longitude: -97.801312, zipCode: "76048"},
	{latitude: 32.459823, longitude: -97.706972, zipCode: "76049"},
	{latitude: 32.266777, longitude: -97.155824, zipCode: "76050"},
	{latitude: 32.942026, longitude: -97.07993, zipCode: "76051"},
	{latitude: 32.979054, longitude: -97.371308, zipCode: "76052"},
	{latitude: 32.81645, longitude: -97.180683, zipCode: "76053"},
	{latitude: 32.857279, longitude: -97.178552, zipCode: "76054"},
	{latitude: 32.159658, longitude: -97.148471, zipCode: "76055"},
	{latitude: 32.471765, longitude: -97.426814, zipCode: "76058"},
	{latitude: 32.393655, longitude: -97.330665, zipCode: "76059"},
	{latitude: 32.639649, longitude: -97.217476, zipCode: "76060"},
	{latitude: 32.502595, longitude: -97.198145, zipCode: "76061"},
	{latitude: 32.57074, longitude: -97.144837, zipCode: "76063"},
	{latitude: 32.309287, longitude: -97.03736, zipCode: "76064"},
	{latitude: 32.469436, longitude: -96.99067, zipCode: "76065"},
	{latitude: 32.69621, longitude: -97.999217, zipCode: "76066"},
	{latitude: 32.802422, longitude: -98.142547, zipCode: "76067"},
	{latitude: 32.21662, longitude: -97.659129, zipCode: "76070"},
	{latitude: 33.011335, longitude: -97.474362, zipCode: "76071"},
	{latitude: 33.095616, longitude: -97.721202, zipCode: "76073"},
	{latitude: 32.281995, longitude: -97.713766, zipCode: "76077"},
	{latitude: 33.093271, longitude: -97.462843, zipCode: "76078"},
	{latitude: 32.970044, longitude: -97.733748, zipCode: "76082"},
	{latitude: 32.431715, longitude: -97.088354, zipCode: "76084"},
	{latitude: 32.861116, longitude: -97.692165, zipCode: "76085"},
	{latitude: 32.752204, longitude: -97.78927, zipCode: "76086"},
	{latitude: 32.66842, longitude: -97.791086, zipCode: "76087"},
	{latitude: 32.850685, longitude: -97.902855, zipCode: "76088"},
	{latitude: 32.954493, longitude: -97.148867, zipCode: "76092"},
	{latitude: 32.210022, longitude: -97.405815, zipCode: "76093"},
	{latitude: 32.757779, longitude: -97.328872, zipCode: "76102"},
	{latitude: 32.748947, longitude: -97.272638, zipCode: "76103"},
	{latitude: 32.728391, longitude: -97.317852, zipCode: "76104"},
	{latitude: 32.723287, longitude: -97.270008, zipCode: "76105"},
	{latitude: 32.816685, longitude: -97.359631, zipCode: "76106"},
	{latitude: 32.742058, longitude: -97.38173, zipCode: "76107"},
	{latitude: 32.788074, longitude: -97.523247, zipCode: "76108"},
	{latitude: 32.70065, longitude: -97.386965, zipCode: "76109"},
	{latitude: 32.707831, longitude: -97.338265, zipCode: "76110"},
	{latitude: 32.780469, longitude: -97.300587, zipCode: "76111"},
	{latitude: 32.747426, longitude: -97.217658, zipCode: "76112"},
	{latitude: 32.774573, longitude: -97.407427, zipCode: "76114"},
	{latitude: 32.6785, longitude: -97.330511, zipCode: "76115"},
	{latitude: 32.720723, longitude: -97.447033, zipCode: "76116"},
	{latitude: 32.803436, longitude: -97.267376, zipCode: "76117"},
	{latitude: 32.793694, longitude: -97.194034, zipCode: "76118"},
	{latitude: 32.691143, longitude: -97.261538, zipCode: "76119"},
	{latitude: 32.766511, longitude: -97.180715, zipCode: "76120"},
	{latitude: 32.623041, longitude: -97.403392, zipCode: "76123"},
	{latitude: 32.648847, longitude: -97.514799, zipCode: "76126"},
	{latitude: 32.776618, longitude: -97.430502, zipCode: "76127"},
	{latitude: 32.709324, longitude: -97.36269, zipCode: "76129"},
	{latitude: 32.881821, longitude: -97.345943, zipCode: "76131"},
	{latitude: 32.668193, longitude: -97.415906, zipCode: "76132"},
	{latitude: 32.652918, longitude: -97.379538, zipCode: "76133"},
	{latitude: 32.643558, longitude: -97.330104, zipCode: "76134"},
	{latitude: 32.836325, longitude: -97.469908, zipCode: "76135"},
	{latitude: 32.859685, longitude: -97.291523, zipCode: "76137"},
	{latitude: 32.619935, longitude: -97.278061, zipCode: "76140"},
	{latitude: 32.868421, longitude: -97.252132, zipCode: "76148"},
	{latitude: 32.824042, longitude: -97.049138, zipCode: "76155"},
	{latitude: 32.780688, longitude: -97.353863, zipCode: "76164"},
	{latitude: 32.966541, longitude: -97.303778, zipCode: "76177"},
	{latitude: 32.909919, longitude: -97.434281, zipCode: "76179"},
	{latitude: 32.840721, longitude: -97.224241, zipCode: "76180"},
	{latitude: 32.886498, longitude: -97.208097, zipCode: "76182"},
	{latitude: 33.220974, longitude: -97.146038, zipCode: "76201"},
	{latitude: 33.212131, longitude: -97.151813, zipCode: "76203"},
	{latitude: 33.192632, longitude: -97.129266, zipCode: "76205"},
	{latitude: 33.222016, longitude: -97.176122, zipCode: "76207"},
	{latitude: 33.208706, longitude: -97.05541, zipCode: "76208"},
	{latitude: 33.2315, longitude: -97.109986, zipCode: "76209"},
	{latitude: 33.149467, longitude: -97.096016, zipCode: "76210"},
	{latitude: 33.374041, longitude: -97.667809, zipCode: "76225"},
	{latitude: 33.105493, longitude: -97.17752, zipCode: "76226"},
	{latitude: 33.273393, longitude: -96.990395, zipCode: "76227"},
	{latitude: 33.61791, longitude: -98.062664, zipCode: "76228"},
	{latitude: 33.52374, longitude: -97.931932, zipCode: "76230"},
	{latitude: 33.542725, longitude: -96.894849, zipCode: "76233"},
	{latitude: 33.276319, longitude: -97.514178, zipCode: "76234"},
	{latitude: 33.479016, longitude: -97.351845, zipCode: "76238"},
	{latitude: 33.512179, longitude: -97.576642, zipCode: "76239"},
	{latitude: 33.661602, longitude: -97.176369, zipCode: "76240"},
	{latitude: 33.645414, longitude: -97.176257, zipCode: "76241"},
	{latitude: 32.927979, longitude: -97.276559, zipCode: "76244"},
	{latitude: 33.823803, longitude: -96.863133, zipCode: "76245"},
	{latitude: 33.101183, longitude: -97.347457, zipCode: "76247"},
	{latitude: 32.929111, longitude: -97.226206, zipCode: "76248"},
	{latitude: 33.278823, longitude: -97.30231, zipCode: "76249"},
	{latitude: 33.630059, longitude: -97.242452, zipCode: "76250"},
	{latitude: 33.658977, longitude: -97.685455, zipCode: "76251"},
	{latitude: 33.670696, longitude: -97.377989, zipCode: "76252"},
	{latitude: 33.625251, longitude: -97.313579, zipCode: "76253"},
	{latitude: 33.811378, longitude: -97.718678, zipCode: "76255"},
	{latitude: 33.371085, longitude: -96.932195, zipCode: "76258"},
	{latitude: 33.198695, longitude: -97.312, zipCode: "76259"},
	{latitude: 33.793074, longitude: -97.963802, zipCode: "76261"},
	{latitude: 33.009665, longitude: -97.219981, zipCode: "76262"},
	{latitude: 33.504346, longitude: -97.437358, zipCode: "76263"},
	{latitude: 33.748511, longitude: -96.836828, zipCode: "76264"},
	{latitude: 33.719876, longitude: -97.5408, zipCode: "76265"},
	{latitude: 33.372827, longitude: -97.213258, zipCode: "76266"},
	{latitude: 33.369335, longitude: -97.397099, zipCode: "76267"},
	{latitude: 33.62779, longitude: -96.765256, zipCode: "76268"},
	{latitude: 33.461447, longitude: -97.758976, zipCode: "76270"},
	{latitude: 33.468882, longitude: -96.913865, zipCode: "76271"},
	{latitude: 33.455973, longitude: -97.127134, zipCode: "76272"},
	{latitude: 33.743893, longitude: -96.936121, zipCode: "76273"},
	{latitude: 33.905072, longitude: -98.480433, zipCode: "76301"},
	{latitude: 33.866113, longitude: -98.484843, zipCode: "76302"},
	{latitude: 34.006193, longitude: -98.387687, zipCode: "76305"},
	{latitude: 33.949681, longitude: -98.527158, zipCode: "76306"},
	{latitude: 33.854833, longitude: -98.5373, zipCode: "76308"},
	{latitude: 33.895517, longitude: -98.543996, zipCode: "76309"},
	{latitude: 33.805189, longitude: -98.528479, zipCode: "76310"},
	{latitude: 33.973277, longitude: -98.508262, zipCode: "76311"},
	{latitude: 33.558025, longitude: -98.695427, zipCode: "76351"},
	{latitude: 34.090195, longitude: -98.625379, zipCode: "76354"},
	{latitude: 34.07876, longitude: -98.184843, zipCode: "76357"},
	{latitude: 33.882756, longitude: -98.88271, zipCode: "76360"},
	{latitude: 33.513208, longitude: -99.523946, zipCode: "76363"},
	{latitude: 34.143303, longitude: -99.011419, zipCode: "76364"},
	{latitude: 33.757451, longitude: -98.208383, zipCode: "76365"},
	{latitude: 33.714631, longitude: -98.774879, zipCode: "76366"},
	{latitude: 33.971461, longitude: -98.714841, zipCode: "76367"},
	{latitude: 33.45617, longitude: -98.89571, zipCode: "76370"},
	{latitude: 33.482721, longitude: -99.654925, zipCode: "76371"},
	{latitude: 33.236008, longitude: -98.932538, zipCode: "76372"},
	{latitude: 34.158677, longitude: -99.127698, zipCode: "76373"},
	{latitude: 33.343882, longitude: -98.763147, zipCode: "76374"},
	{latitude: 34.008318, longitude: -98.258589, zipCode: "76377"},
	{latitude: 33.668239, longitude: -98.489118, zipCode: "76379"},
	{latitude: 33.603022, longitude: -99.213584, zipCode: "76380"},
	{latitude: 34.141323, longitude: -99.31882, zipCode: "76384"},
	{latitude: 33.322836, longitude: -99.642126, zipCode: "76388"},
	{latitude: 33.512837, longitude: -98.481235, zipCode: "76389"},
	{latitude: 32.271207, longitude: -98.205511, zipCode: "76401"},
	{latitude: 32.216519, longitude: -98.215848, zipCode: "76402"},
	{latitude: 32.801213, longitude: -98.88698, zipCode: "76424"},
	{latitude: 33.16006, longitude: -97.840034, zipCode: "76426"},
	{latitude: 33.130596, longitude: -98.396473, zipCode: "76427"},
	{latitude: 32.759373, longitude: -98.666449, zipCode: "76429"},
	{latitude: 32.804487, longitude: -99.22307, zipCode: "76430"},
	{latitude: 33.302787, longitude: -97.868426, zipCode: "76431"},
	{latitude: 31.836985, longitude: -98.784332, zipCode: "76432"},
	{latitude: 32.305725, longitude: -98.009624, zipCode: "76433"},
	{latitude: 32.224529, longitude: -98.855993, zipCode: "76435"},
	{latitude: 31.868174, longitude: -98.218408, zipCode: "76436"},
	{latitude: 32.38227, longitude: -99.023871, zipCode: "76437"},
	{latitude: 32.617963, longitude: -97.926819, zipCode: "76439"},
	{latitude: 31.890896, longitude: -98.613096, zipCode: "76442"},
	{latitude: 32.113392, longitude: -99.211458, zipCode: "76443"},
	{latitude: 32.123666, longitude: -98.577422, zipCode: "76444"},
	{latitude: 32.305404, longitude: -98.542165, zipCode: "76445"},
	{latitude: 32.074747, longitude: -98.359696, zipCode: "76446"},
	{latitude: 32.392615, longitude: -98.790658, zipCode: "76448"},
	{latitude: 32.901592, longitude: -98.315542, zipCode: "76449"},
	{latitude: 33.060398, longitude: -98.652502, zipCode: "76450"},
	{latitude: 31.760412, longitude: -98.397118, zipCode: "76452"},
	{latitude: 32.584194, longitude: -98.335694, zipCode: "76453"},
	{latitude: 32.226539, longitude: -98.700377, zipCode: "76454"},
	{latitude: 31.821442, longitude: -98.366808, zipCode: "76455"},
	{latitude: 31.944662, longitude: -98.027972, zipCode: "76457"},
	{latitude: 33.215811, longitude: -98.191865, zipCode: "76458"},
	{latitude: 33.275311, longitude: -98.390215, zipCode: "76459"},
	{latitude: 33.306476, longitude: -98.500269, zipCode: "76460"},
	{latitude: 32.520718, longitude: -98.029665, zipCode: "76462"},
	{latitude: 32.455408, longitude: -98.418168, zipCode: "76463"},
	{latitude: 32.579207, longitude: -99.156392, zipCode: "76464"},
	{latitude: 32.428875, longitude: -98.733107, zipCode: "76466"},
	{latitude: 32.336117, longitude: -99.202392, zipCode: "76469"},
	{latitude: 32.503071, longitude: -98.674946, zipCode: "76470"},
	{latitude: 32.107292, longitude: -98.982886, zipCode: "76471"},
	{latitude: 32.607745, longitude: -98.179771, zipCode: "76472"},
	{latitude: 31.958671, longitude: -98.775052, zipCode: "76474"},
	{latitude: 32.673266, longitude: -98.485899, zipCode: "76475"},
	{latitude: 32.362649, longitude: -97.924354, zipCode: "76476"},
	{latitude: 32.996252, longitude: -98.698362, zipCode: "76481"},
	{latitude: 33.160117, longitude: -99.282788, zipCode: "76483"},
	{latitude: 32.740311, longitude: -98.302489, zipCode: "76484"},
	{latitude: 33.014639, longitude: -98.05319, zipCode: "76486"},
	{latitude: 32.972482, longitude: -97.904879, zipCode: "76487"},
	{latitude: 32.961949, longitude: -98.044304, zipCode: "76490"},
	{latitude: 33.057138, longitude: -99.038826, zipCode: "76491"},
	{latitude: 31.075303, longitude: -97.252433, zipCode: "76501"},
	{latitude: 31.104022, longitude: -97.412045, zipCode: "76502"},
	{latitude: 31.142333, longitude: -97.374637, zipCode: "76504"},
	{latitude: 30.798077, longitude: -97.419451, zipCode: "76511"},
	{latitude: 31.057023, longitude: -97.503, zipCode: "76513"},
	{latitude: 30.852029, longitude: -97.137995, zipCode: "76518"},
	{latitude: 30.994797, longitude: -97.033363, zipCode: "76519"},
	{latitude: 30.872601, longitude: -96.917318, zipCode: "76520"},
	{latitude: 31.219045, longitude: -97.968224, zipCode: "76522"},
	{latitude: 30.802735, longitude: -97.272414, zipCode: "76523"},
	{latitude: 31.268644, longitude: -97.202495, zipCode: "76524"},
	{latitude: 31.473539, longitude: -98.182044, zipCode: "76525"},
	{latitude: 30.836156, longitude: -97.795604, zipCode: "76527"},
	{latitude: 31.441046, longitude: -97.774565, zipCode: "76528"},
	{latitude: 30.71223, longitude: -97.424823, zipCode: "76530"},
	{latitude: 31.686812, longitude: -98.18703, zipCode: "76531"},
	{latitude: 30.884371, longitude: -97.378453, zipCode: "76534"},
	{latitude: 30.817309, longitude: -97.602978, zipCode: "76537"},
	{latitude: 31.628871, longitude: -97.922097, zipCode: "76538"},
	{latitude: 31.090618, longitude: -97.995741, zipCode: "76539"},
	{latitude: 31.114384, longitude: -97.729347, zipCode: "76541"},
	{latitude: 30.989388, longitude: -97.702738, zipCode: "76542"},
	{latitude: 31.124743, longitude: -97.643542, zipCode: "76543"},
	{latitude: 31.330254, longitude: -97.689632, zipCode: "76544"},
	{latitude: 31.054577, longitude: -97.641727, zipCode: "76548"},
	{latitude: 31.017077, longitude: -97.833092, zipCode: "76549"},
	{latitude: 31.102626, longitude: -98.259776, zipCode: "76550"},
	{latitude: 30.967883, longitude: -97.376957, zipCode: "76554"},
	{latitude: 30.696609, longitude: -96.844814, zipCode: "76556"},
	{latitude: 31.293783, longitude: -97.40019, zipCode: "76557"},
	{latitude: 31.083202, longitude: -97.614056, zipCode: "76559"},
	{latitude: 31.428851, longitude: -97.542938, zipCode: "76561"},
	{latitude: 31.645074, longitude: -98.349211, zipCode: "76565"},
	{latitude: 31.514088, longitude: -98.000163, zipCode: "76566"},
	{latitude: 30.631487, longitude: -97.028281, zipCode: "76567"},
	{latitude: 30.939337, longitude: -97.217459, zipCode: "76569"},
	{latitude: 31.082267, longitude: -96.953514, zipCode: "76570"},
	{latitude: 30.949256, longitude: -97.604438, zipCode: "76571"},
	{latitude: 30.818652, longitude: -97.502432, zipCode: "76573"},
	{latitude: 30.579326, longitude: -97.388618, zipCode: "76574"},
	{latitude: 30.613397, longitude: -97.183669, zipCode: "76577"},
	{latitude: 30.535409, longitude: -97.23717, zipCode: "76578"},
	{latitude: 31.197289, longitude: -97.241907, zipCode: "76579"},
	{latitude: 31.476271, longitude: -97.726962, zipCode: "76596"},
	{latitude: 31.484459, longitude: -97.709839, zipCode: "76597"},
	{latitude: 31.475478, longitude: -97.734902, zipCode: "76598"},
	{latitude: 31.464153, longitude: -97.737501, zipCode: "76599"},
	{latitude: 31.873806, longitude: -97.087391, zipCode: "76621"},
	{latitude: 31.816191, longitude: -97.243292, zipCode: "76622"},
	{latitude: 32.226783, longitude: -96.800447, zipCode: "76623"},
	{latitude: 31.661047, longitude: -96.955129, zipCode: "76624"},
	{latitude: 32.122997, longitude: -96.723082, zipCode: "76626"},
	{latitude: 32.102216, longitude: -97.389311, zipCode: "76627"},
	{latitude: 32.037791, longitude: -96.969837, zipCode: "76628"},
	{latitude: 31.14975, longitude: -96.651785, zipCode: "76629"},
	{latitude: 31.349603, longitude: -97.222639, zipCode: "76630"},
	{latitude: 31.986553, longitude: -96.96585, zipCode: "76631"},
	{latitude: 31.30653, longitude: -97.065653, zipCode: "76632"},
	{latitude: 31.695107, longitude: -97.328851, zipCode: "76633"},
	{latitude: 31.805316, longitude: -97.570542, zipCode: "76634"},
	{latitude: 31.71629, longitude: -96.661428, zipCode: "76635"},
	{latitude: 32.170232, longitude: -97.290211, zipCode: "76636"},
	{latitude: 31.774623, longitude: -97.756011, zipCode: "76637"},
	{latitude: 31.558396, longitude: -97.444501, zipCode: "76638"},
	{latitude: 31.866953, longitude: -96.655804, zipCode: "76639"},
	{latitude: 31.696619, longitude: -97.073707, zipCode: "76640"},
	{latitude: 32.044707, longitude: -96.786919, zipCode: "76641"},
	{latitude: 31.498209, longitude: -96.525475, zipCode: "76642"},
	{latitude: 31.449362, longitude: -97.197582, zipCode: "76643"},
	{latitude: 32.012383, longitude: -97.127012, zipCode: "76645"},
	{latitude: 31.848596, longitude: -96.807361, zipCode: "76648"},
	{latitude: 32.001589, longitude: -97.882698, zipCode: "76649"},
	{latitude: 31.980079, longitude: -96.863626, zipCode: "76650"},
	{latitude: 32.184674, longitude: -96.868299, zipCode: "76651"},
	{latitude: 32.117538, longitude: -97.575601, zipCode: "76652"},
	{latitude: 31.295398, longitude: -96.607195, zipCode: "76653"},
	{latitude: 31.72749, longitude: -97.013346, zipCode: "76654"},
	{latitude: 31.392436, longitude: -97.174161, zipCode: "76655"},
	{latitude: 31.177841, longitude: -97.063678, zipCode: "76656"},
	{latitude: 31.438276, longitude: -97.399681, zipCode: "76657"},
	{latitude: 31.93561, longitude: -96.892676, zipCode: "76660"},
	{latitude: 31.307238, longitude: -96.84033, zipCode: "76661"},
	{latitude: 31.560686, longitude: -96.802657, zipCode: "76664"},
	{latitude: 31.925006, longitude: -97.715407, zipCode: "76665"},
	{latitude: 32.040254, longitude: -96.894989, zipCode: "76666"},
	{latitude: 31.657082, longitude: -96.465417, zipCode: "76667"},
	{latitude: 32.136018, longitude: -96.988963, zipCode: "76670"},
	{latitude: 31.988449, longitude: -97.531227, zipCode: "76671"},
	{latitude: 31.751025, longitude: -96.891756, zipCode: "76673"},
	{latitude: 31.876385, longitude: -96.959031, zipCode: "76676"},
	{latitude: 31.651121, longitude: -96.749192, zipCode: "76678"},
	{latitude: 31.955435, longitude: -96.57674, zipCode: "76679"},
	{latitude: 31.190743, longitude: -96.801353, zipCode: "76680"},
	{latitude: 31.915937, longitude: -96.436057, zipCode: "76681"},
	{latitude: 31.45345, longitude: -96.913489, zipCode: "76682"},
	{latitude: 31.339417, longitude: -97.008179, zipCode: "76685"},
	{latitude: 31.743177, longitude: -96.540199, zipCode: "76686"},
	{latitude: 31.369198, longitude: -96.486256, zipCode: "76687"},
	{latitude: 31.662159, longitude: -97.506079, zipCode: "76689"},
	{latitude: 32.104295, longitude: -97.794848, zipCode: "76690"},
	{latitude: 31.769851, longitude: -97.098169, zipCode: "76691"},
	{latitude: 31.959701, longitude: -97.330421, zipCode: "76692"},
	{latitude: 31.81195, longitude: -96.44386, zipCode: "76693"},
	{latitude: 31.552011, longitude: -97.1382, zipCode: "76701"},
	{latitude: 31.575445, longitude: -97.124247, zipCode: "76704"},
	{latitude: 31.626707, longitude: -97.084474, zipCode: "76705"},
	{latitude: 31.456322, longitude: -97.084442, zipCode: "76706"},
	{latitude: 31.553876, longitude: -97.159082, zipCode: "76707"},
	{latitude: 31.627123, longitude: -97.215893, zipCode: "76708"},
	{latitude: 31.539723, longitude: -97.195009, zipCode: "76710"},
	{latitude: 31.513756, longitude: -97.152983, zipCode: "76711"},
	{latitude: 31.526506, longitude: -97.260888, zipCode: "76712"},
	{latitude: 31.546107, longitude: -97.120959, zipCode: "76798"},
	{latitude: 31.744154, longitude: -99.056447, zipCode: "76801"},
	{latitude: 31.715632, longitude: -98.894643, zipCode: "76802"},
	{latitude: 30.784257, longitude: -99.041286, zipCode: "76820"},
	{latitude: 31.738169, longitude: -99.950504, zipCode: "76821"},
	{latitude: 31.687276, longitude: -99.168269, zipCode: "76823"},
	{latitude: 30.936768, longitude: -98.467066, zipCode: "76824"},
	{latitude: 31.09852, longitude: -99.393909, zipCode: "76825"},
	{latitude: 31.527193, longitude: -99.136616, zipCode: "76827"},
	{latitude: 32.006167, longitude: -99.292204, zipCode: "76828"},
	{latitude: 30.757565, longitude: -98.957787, zipCode: "76831"},
	{latitude: 30.962958, longitude: -98.746925, zipCode: "76832"},
	{latitude: 31.881956, longitude: -99.480043, zipCode: "76834"},
	{latitude: 31.422466, longitude: -99.587642, zipCode: "76836"},
	{latitude: 31.20719, longitude: -99.892672, zipCode: "76837"},
	{latitude: 30.854076, longitude: -100.137309, zipCode: "76841"},
	{latitude: 30.92318, longitude: -99.10172, zipCode: "76842"},
	{latitude: 31.450936, longitude: -98.502117, zipCode: "76844"},
	{latitude: 31.538066, longitude: -99.488185, zipCode: "76845"},
	{latitude: 30.839235, longitude: -99.563748, zipCode: "76848"},
	{latitude: 30.472352, longitude: -99.821506, zipCode: "76849"},
	{latitude: 31.35766, longitude: -99.457261, zipCode: "76852"},
	{latitude: 31.267613, longitude: -98.371832, zipCode: "76853"},
	{latitude: 30.555872, longitude: -99.528236, zipCode: "76854"},
	{latitude: 30.691484, longitude: -99.273312, zipCode: "76856"},
	{latitude: 31.931233, longitude: -98.927404, zipCode: "76857"},
	{latitude: 31.207138, longitude: -99.614549, zipCode: "76858"},
	{latitude: 30.885268, longitude: -99.858861, zipCode: "76859"},
	{latitude: 31.595587, longitude: -100.201635, zipCode: "76861"},
	{latitude: 31.41481, longitude: -99.705265, zipCode: "76862"},
	{latitude: 31.558415, longitude: -98.748856, zipCode: "76864"},
	{latitude: 31.849653, longitude: -100.153554, zipCode: "76865"},
	{latitude: 31.43985, longitude: -99.928032, zipCode: "76866"},
	{latitude: 30.909651, longitude: -98.975666, zipCode: "76869"},
	{latitude: 31.604321, longitude: -98.529648, zipCode: "76870"},
	{latitude: 31.270261, longitude: -98.98578, zipCode: "76871"},
	{latitude: 31.335813, longitude: -99.148158, zipCode: "76872"},
	{latitude: 31.505779, longitude: -99.3701, zipCode: "76873"},
	{latitude: 30.476915, longitude: -100.122717, zipCode: "76874"},
	{latitude: 31.601451, longitude: -100.015619, zipCode: "76875"},
	{latitude: 31.157059, longitude: -98.764007, zipCode: "76877"},
	{latitude: 31.657754, longitude: -99.292932, zipCode: "76878"},
	{latitude: 31.444902, longitude: -98.315136, zipCode: "76880"},
	{latitude: 31.800285, longitude: -99.71096, zipCode: "76882"},
	{latitude: 31.690741, longitude: -99.569051, zipCode: "76884"},
	{latitude: 30.858957, longitude: -98.861313, zipCode: "76885"},
	{latitude: 31.031568, longitude: -99.158464, zipCode: "76887"},
	{latitude: 31.609899, longitude: -99.64049, zipCode: "76888"},
	{latitude: 31.694189, longitude: -98.766342, zipCode: "76890"},
	{latitude: 31.508725, longitude: -100.648748, zipCode: "76901"},
	{latitude: 31.544368, longitude: -100.724514, zipCode: "76902"},
	{latitude: 31.481519, longitude: -100.437903, zipCode: "76903"},
	{latitude: 31.287019, longitude: -100.423597, zipCode: "76904"},
	{latitude: 31.49983, longitude: -100.315802, zipCode: "76905"},
	{latitude: 31.432248, longitude: -100.403167, zipCode: "76908"},
	{latitude: 31.441425, longitude: -100.466262, zipCode: "76909"},
	{latitude: 31.110531, longitude: -101.119614, zipCode: "76930"},
	{latitude: 31.192457, longitude: -101.563757, zipCode: "76932"},
	{latitude: 31.88084, longitude: -100.283968, zipCode: "76933"},
	{latitude: 31.61489, longitude: -100.676674, zipCode: "76934"},
	{latitude: 31.072297, longitude: -100.424831, zipCode: "76935"},
	{latitude: 30.891813, longitude: -100.655315, zipCode: "76936"},
	{latitude: 31.3911, longitude: -100.150274, zipCode: "76937"},
	{latitude: 31.268563, longitude: -100.590295, zipCode: "76939"},
	{latitude: 31.436812, longitude: -100.126944, zipCode: "76940"},
	{latitude: 31.245277, longitude: -100.861245, zipCode: "76941"},
	{latitude: 30.470373, longitude: -101.321635, zipCode: "76943"},
	{latitude: 31.87304, longitude: -100.604685, zipCode: "76945"},
	{latitude: 30.43594, longitude: -100.564774, zipCode: "76950"},
	{latitude: 31.802748, longitude: -101.055625, zipCode: "76951"},
	{latitude: 31.727528, longitude: -100.31996, zipCode: "76953"},
	{latitude: 31.299186, longitude: -100.138886, zipCode: "76955"},
	{latitude: 31.372301, longitude: -100.305145, zipCode: "76957"},
	{latitude: 31.64468, longitude: -100.75515, zipCode: "76958"},
	{latitude: 29.756845, longitude: -95.365652, zipCode: "77002"},
	{latitude: 29.750624, longitude: -95.345812, zipCode: "77003"},
	{latitude: 29.724272, longitude: -95.364242, zipCode: "77004"},
	{latitude: 29.718435, longitude: -95.423555, zipCode: "77005"},
	{latitude: 29.740861, longitude: -95.391303, zipCode: "77006"},
	{latitude: 29.771579, longitude: -95.40893, zipCode: "77007"},
	{latitude: 29.798249, longitude: -95.416933, zipCode: "77008"},
	{latitude: 29.795344, longitude: -95.36759, zipCode: "77009"},
	{latitude: 29.753615, longitude: -95.359737, zipCode: "77010"},
	{latitude: 29.742988, longitude: -95.307114, zipCode: "77011"},
	{latitude: 29.718525, longitude: -95.274137, zipCode: "77012"},
	{latitude: 29.795268, longitude: -95.233977, zipCode: "77013"},
	{latitude: 29.985844, longitude: -95.465176, zipCode: "77014"},
	{latitude: 29.764825, longitude: -95.171416, zipCode: "77015"},
	{latitude: 29.862447, longitude: -95.297733, zipCode: "77016"},
	{latitude: 29.691984, longitude: -95.250232, zipCode: "77017"},
	{latitude: 29.827404, longitude: -95.426101, zipCode: "77018"},
	{latitude: 29.753344, longitude: -95.412855, zipCode: "77019"},
	{latitude: 29.768297, longitude: -95.308971, zipCode: "77020"},
	{latitude: 29.69843, longitude: -95.3569, zipCode: "77021"},
	{latitude: 29.83159, longitude: -95.379554, zipCode: "77022"},
	{latitude: 29.721825, longitude: -95.318275, zipCode: "77023"},
	{latitude: 29.767148, longitude: -95.505627, zipCode: "77024"},
	{latitude: 29.685706, longitude: -95.434764, zipCode: "77025"},
	{latitude: 29.800187, longitude: -95.328888, zipCode: "77026"},
	{latitude: 29.739314, longitude: -95.446424, zipCode: "77027"},
	{latitude: 29.825122, longitude: -95.28636, zipCode: "77028"},
	{latitude: 29.759365, longitude: -95.256626, zipCode: "77029"},
	{latitude: 29.705557, longitude: -95.401754, zipCode: "77030"},
	{latitude: 29.652205, longitude: -95.54623, zipCode: "77031"},
	{latitude: 29.985874, longitude: -95.345795, zipCode: "77032"},
	{latitude: 29.668308, longitude: -95.338574, zipCode: "77033"},
	{latitude: 29.614699, longitude: -95.19318, zipCode: "77034"},
	{latitude: 29.655503, longitude: -95.471663, zipCode: "77035"},
	{latitude: 29.699048, longitude: -95.536507, zipCode: "77036"},
	{latitude: 29.891373, longitude: -95.390561, zipCode: "77037"},
	{latitude: 29.919329, longitude: -95.4412, zipCode: "77038"},
	{latitude: 29.908394, longitude: -95.349795, zipCode: "77039"},
	{latitude: 29.876546, longitude: -95.532244, zipCode: "77040"},
	{latitude: 29.857781, longitude: -95.585719, zipCode: "77041"},
	{latitude: 29.740605, longitude: -95.560257, zipCode: "77042"},
	{latitude: 29.81093, longitude: -95.584639, zipCode: "77043"},
	{latitude: 29.906312, longitude: -95.180561, zipCode: "77044"},
	{latitude: 29.647339, longitude: -95.431435, zipCode: "77045"},
	{latitude: 29.734428, longitude: -95.433099, zipCode: "77046"},
	{latitude: 29.61065, longitude: -95.386863, zipCode: "77047"},
	{latitude: 29.623228, longitude: -95.327644, zipCode: "77048"},
	{latitude: 29.832928, longitude: -95.14904, zipCode: "77049"},
	{latitude: 29.902887, longitude: -95.269296, zipCode: "77050"},
	{latitude: 29.656113, longitude: -95.378696, zipCode: "77051"},
	{latitude: 29.585681, longitude: -95.461267, zipCode: "77053"},
	{latitude: 29.68025, longitude: -95.404913, zipCode: "77054"},
	{latitude: 29.796897, longitude: -95.49271, zipCode: "77055"},
	{latitude: 29.748202, longitude: -95.468948, zipCode: "77056"},
	{latitude: 29.744068, longitude: -95.48921, zipCode: "77057"},
	{latitude: 29.561579, longitude: -95.099626, zipCode: "77058"},
	{latitude: 29.613528, longitude: -95.118667, zipCode: "77059"},
	{latitude: 29.935512, longitude: -95.397624, zipCode: "77060"},
	{latitude: 29.647155, longitude: -95.276204, zipCode: "77061"},
	{latitude: 29.575781, longitude: -95.134334, zipCode: "77062"},
	{latitude: 29.735679, longitude: -95.521587, zipCode: "77063"},
	{latitude: 29.924186, longitude: -95.541691, zipCode: "77064"},
	{latitude: 29.92647, longitude: -95.603801, zipCode: "77065"},
	{latitude: 29.957224, longitude: -95.503319, zipCode: "77066"},
	{latitude: 29.952629, longitude: -95.446609, zipCode: "77067"},
	{latitude: 30.007063, longitude: -95.488362, zipCode: "77068"},
	{latitude: 29.987811, longitude: -95.523217, zipCode: "77069"},
	{latitude: 29.979455, longitude: -95.573317, zipCode: "77070"},
	{latitude: 29.651785, longitude: -95.519929, zipCode: "77071"},
	{latitude: 29.700441, longitude: -95.585023, zipCode: "77072"},
	{latitude: 30.000147, longitude: -95.400069, zipCode: "77073"},
	{latitude: 29.687947, longitude: -95.515725, zipCode: "77074"},
	{latitude: 29.621466, longitude: -95.268968, zipCode: "77075"},
	{latitude: 29.860021, longitude: -95.382184, zipCode: "77076"},
	{latitude: 29.753633, longitude: -95.642004, zipCode: "77077"},
	{latitude: 29.854057, longitude: -95.244813, zipCode: "77078"},
	{latitude: 29.777978, longitude: -95.603102, zipCode: "77079"},
	{latitude: 29.81835, longitude: -95.522439, zipCode: "77080"},
	{latitude: 29.712829, longitude: -95.478516, zipCode: "77081"},
	{latitude: 29.72449, longitude: -95.640002, zipCode: "77082"},
	{latitude: 29.693991, longitude: -95.64783, zipCode: "77083"},
	{latitude: 29.827486, longitude: -95.65992, zipCode: "77084"},
	{latitude: 29.620509, longitude: -95.485333, zipCode: "77085"},
	{latitude: 29.919035, longitude: -95.491606, zipCode: "77086"},
	{latitude: 29.686519, longitude: -95.303917, zipCode: "77087"},
	{latitude: 29.881487, longitude: -95.452619, zipCode: "77088"},
	{latitude: 29.586959, longitude: -95.225601, zipCode: "77089"},
	{latitude: 30.009427, longitude: -95.439603, zipCode: "77090"},
	{latitude: 29.853067, longitude: -95.435966, zipCode: "77091"},
	{latitude: 29.831681, longitude: -95.474905, zipCode: "77092"},
	{latitude: 29.863156, longitude: -95.341927, zipCode: "77093"},
	{latitude: 29.770918, longitude: -95.698677, zipCode: "77094"},
	{latitude: 29.910049, longitude: -95.65884, zipCode: "77095"},
	{latitude: 29.675339, longitude: -95.479372, zipCode: "77096"},
	{latitude: 29.734813, longitude: -95.416098, zipCode: "77098"},
	{latitude: 29.670869, longitude: -95.58599, zipCode: "77099"},
	{latitude: 29.720884, longitude: -95.343537, zipCode: "77204"},
	{latitude: 30.309853, longitude: -95.43128, zipCode: "77301"},
	{latitude: 30.222119, longitude: -95.344934, zipCode: "77302"},
	{latitude: 30.377952, longitude: -95.37714, zipCode: "77303"},
	{latitude: 30.32776, longitude: -95.516045, zipCode: "77304"},
	{latitude: 30.28584, longitude: -95.312397, zipCode: "77306"},
	{latitude: 30.306163, longitude: -95.68639, zipCode: "77316"},
	{latitude: 30.444908, longitude: -95.551907, zipCode: "77318"},
	{latitude: 30.807729, longitude: -95.558036, zipCode: "77320"},
	{latitude: 30.503708, longitude: -94.81848, zipCode: "77326"},
	{latitude: 30.347992, longitude: -94.930738, zipCode: "77327"},
	{latitude: 30.405402, longitude: -95.192082, zipCode: "77328"},
	{latitude: 30.617698, longitude: -95.184377, zipCode: "77331"},
	{latitude: 30.781836, longitude: -95.373439, zipCode: "77334"},
	{latitude: 30.591653, longitude: -94.920713, zipCode: "77335"},
	{latitude: 30.069576, longitude: -95.106699, zipCode: "77336"},
	{latitude: 30.014356, longitude: -95.282995, zipCode: "77338"},
	{latitude: 30.046777, longitude: -95.221022, zipCode: "77339"},
	{latitude: 30.656507, longitude: -95.530744, zipCode: "77340"},
	{latitude: 30.69825, longitude: -95.55896, zipCode: "77341"},
	{latitude: 30.738781, longitude: -95.559212, zipCode: "77342"},
	{latitude: 30.053105, longitude: -95.157943, zipCode: "77345"},
	{latitude: 29.991995, longitude: -95.177268, zipCode: "77346"},
	{latitude: 30.808479, longitude: -94.912079, zipCode: "77350"},
	{latitude: 30.717792, longitude: -94.817231, zipCode: "77351"},
	{latitude: 30.207853, longitude: -95.642971, zipCode: "77354"},
	{latitude: 30.156257, longitude: -95.74926, zipCode: "77355"},
	{latitude: 30.465662, longitude: -95.729888, zipCode: "77356"},
	{latitude: 30.156882, longitude: -95.181121, zipCode: "77357"},
	{latitude: 30.543328, longitude: -95.443351, zipCode: "77358"},
	{latitude: 30.773832, longitude: -95.299653, zipCode: "77359"},
	{latitude: 30.846249, longitude: -95.117513, zipCode: "77360"},
	{latitude: 30.156589, longitude: -95.671465, zipCode: "77362"},
	{latitude: 30.316241, longitude: -95.849802, zipCode: "77363"},
	{latitude: 30.772997, longitude: -95.214951, zipCode: "77364"},
	{latitude: 30.11348, longitude: -95.274545, zipCode: "77365"},
	{latitude: 30.848145, longitude: -95.390115, zipCode: "77367"},
	{latitude: 30.451225, longitude: -94.840633, zipCode: "77368"},
	{latitude: 30.421815, longitude: -94.73533, zipCode: "77369"},
	{latitude: 30.49399, longitude: -94.98118, zipCode: "77371"},
	{latitude: 30.2677, longitude: -95.201009, zipCode: "77372"},
	{latitude: 30.062169, longitude: -95.383966, zipCode: "77373"},
	{latitude: 30.392213, longitude: -94.644093, zipCode: "77374"},
	{latitude: 30.094665, longitude: -95.586187, zipCode: "77375"},
	{latitude: 30.443987, longitude: -94.675487, zipCode: "77376"},
	{latitude: 30.059863, longitude: -95.672207, zipCode: "77377"},
	{latitude: 30.478056, longitude: -95.384905, zipCode: "77378"},
	{latitude: 30.039457, longitude: -95.534383, zipCode: "77379"},
	{latitude: 30.136972, longitude: -95.46836, zipCode: "77380"},
	{latitude: 30.177126, longitude: -95.502489, zipCode: "77381"},
	{latitude: 30.195137, longitude: -95.547917, zipCode: "77382"},
	{latitude: 30.233846, longitude: -95.49577, zipCode: "77384"},
	{latitude: 30.186682, longitude: -95.429005, zipCode: "77385"},
	{latitude: 30.100262, longitude: -95.356488, zipCode: "77386"},
	{latitude: 30.061932, longitude: -95.465877, zipCode: "77388"},
	{latitude: 30.123469, longitude: -95.517064, zipCode: "77389"},
	{latitude: 29.943359, longitude: -95.262498, zipCode: "77396"},
	{latitude: 29.704019, longitude: -95.460905, zipCode: "77401"},
	{latitude: 29.64609, longitude: -95.794526, zipCode: "77406"},
	{latitude: 29.670004, longitude: -95.708089, zipCode: "77407"},
	{latitude: 29.560824, longitude: -96.458267, zipCode: "77412"},
	{latitude: 28.904769, longitude: -95.887564, zipCode: "77414"},
	{latitude: 28.936977, longitude: -95.706673, zipCode: "77415"},
	{latitude: 29.460956, longitude: -95.956007, zipCode: "77417"},
	{latitude: 29.987117, longitude: -96.252942, zipCode: "77418"},
	{latitude: 28.87215, longitude: -96.251935, zipCode: "77419"},
	{latitude: 29.233389, longitude: -95.918796, zipCode: "77420"},
	{latitude: 28.96955, longitude: -95.582181, zipCode: "77422"},
	{latitude: 29.833142, longitude: -95.986919, zipCode: "77423"},
	{latitude: 30.1223, longitude: -96.258442, zipCode: "77426"},
	{latitude: 28.672888, longitude: -96.156984, zipCode: "77428"},
	{latitude: 29.995758, longitude: -95.665088, zipCode: "77429"},
	{latitude: 29.259878, longitude: -95.667662, zipCode: "77430"},
	{latitude: 29.132598, longitude: -95.818919, zipCode: "77431"},
	{latitude: 29.035751, longitude: -96.228247, zipCode: "77432"},
	{latitude: 29.945301, longitude: -95.741369, zipCode: "77433"},
	{latitude: 29.523643, longitude: -96.318161, zipCode: "77434"},
	{latitude: 29.515694, longitude: -96.121026, zipCode: "77435"},
	{latitude: 29.365048, longitude: -96.252644, zipCode: "77436"},
	{latitude: 29.204365, longitude: -96.272726, zipCode: "77437"},
	{latitude: 28.89016, longitude: -96.145464, zipCode: "77440"},
	{latitude: 29.697428, longitude: -95.909559, zipCode: "77441"},
	{latitude: 29.437903, longitude: -96.495003, zipCode: "77442"},
	{latitude: 29.359204, longitude: -96.18075, zipCode: "77443"},
	{latitude: 29.286377, longitude: -95.777811, zipCode: "77444"},
	{latitude: 30.097218, longitude: -96.064246, zipCode: "77445"},
	{latitude: 30.087117, longitude: -95.989558, zipCode: "77446"},
	{latitude: 30.055282, longitude: -95.824783, zipCode: "77447"},
	{latitude: 29.416447, longitude: -96.085418, zipCode: "77448"},
	{latitude: 29.837611, longitude: -95.73374, zipCode: "77449"},
	{latitude: 29.748169, longitude: -95.736154, zipCode: "77450"},
	{latitude: 29.463216, longitude: -96.025392, zipCode: "77451"},
	{latitude: 29.231938, longitude: -96.019246, zipCode: "77453"},
	{latitude: 29.54538, longitude: -96.238523, zipCode: "77454"},
	{latitude: 29.13158, longitude: -96.445375, zipCode: "77455"},
	{latitude: 28.927379, longitude: -96.08966, zipCode: "77456"},
	{latitude: 28.700403, longitude: -95.945058, zipCode: "77457"},
	{latitude: 28.96317, longitude: -96.2498, zipCode: "77458"},
	{latitude: 29.526393, longitude: -95.533799, zipCode: "77459"},
	{latitude: 29.397434, longitude: -96.384512, zipCode: "77460"},
	{latitude: 29.393325, longitude: -95.774833, zipCode: "77461"},
	{latitude: 29.620932, longitude: -95.939254, zipCode: "77464"},
	{latitude: 28.711934, longitude: -96.226079, zipCode: "77465"},
	{latitude: 29.792687, longitude: -96.012215, zipCode: "77466"},
	{latitude: 29.234129, longitude: -96.182424, zipCode: "77467"},
	{latitude: 29.13719, longitude: -95.851225, zipCode: "77468"},
	{latitude: 29.491375, longitude: -95.702129, zipCode: "77469"},
	{latitude: 29.533816, longitude: -96.532663, zipCode: "77470"},
	{latitude: 29.54852, longitude: -95.86204, zipCode: "77471"},
	{latitude: 29.794594, longitude: -96.103745, zipCode: "77473"},
	{latitude: 29.77406, longitude: -96.190029, zipCode: "77474"},
	{latitude: 29.51133, longitude: -96.672979, zipCode: "77475"},
	{latitude: 29.676603, longitude: -95.973393, zipCode: "77476"},
	{latitude: 29.624274, longitude: -95.568008, zipCode: "77477"},
	{latitude: 29.617523, longitude: -95.60829, zipCode: "77478"},
	{latitude: 29.56504, longitude: -95.635059, zipCode: "77479"},
	{latitude: 29.091495, longitude: -95.770074, zipCode: "77480"},
	{latitude: 29.471484, longitude: -95.570843, zipCode: "77481"},
	{latitude: 29.092714, longitude: -95.903493, zipCode: "77482"},
	{latitude: 28.780687, longitude: -95.871565, zipCode: "77483"},
	{latitude: 30.08563, longitude: -95.92719, zipCode: "77484"},
	{latitude: 29.640656, longitude: -96.06303, zipCode: "77485"},
	{latitude: 29.160441, longitude: -95.691989, zipCode: "77486"},
	{latitude: 29.295708, longitude: -96.095122, zipCode: "77488"},
	{latitude: 29.600498, longitude: -95.515537, zipCode: "77489"},
	{latitude: 29.853217, longitude: -95.831457, zipCode: "77493"},
	{latitude: 29.744657, longitude: -95.815536, zipCode: "77494"},
	{latitude: 29.640975, longitude: -95.655784, zipCode: "77498"},
	{latitude: 29.679854, longitude: -95.199591, zipCode: "77502"},
	{latitude: 29.690596, longitude: -95.157031, zipCode: "77503"},
	{latitude: 29.647916, longitude: -95.18978, zipCode: "77504"},
	{latitude: 29.648337, longitude: -95.141499, zipCode: "77505"},
	{latitude: 29.718387, longitude: -95.191126, zipCode: "77506"},
	{latitude: 29.624371, longitude: -95.063465, zipCode: "77507"},
	{latitude: 29.348871, longitude: -95.091618, zipCode: "77510"},
	{latitude: 29.380763, longitude: -95.245193, zipCode: "77511"},
	{latitude: 29.688046, longitude: -94.625902, zipCode: "77514"},
	{latitude: 29.173439, longitude: -95.451545, zipCode: "77515"},
	{latitude: 29.359526, longitude: -95.14081, zipCode: "77517"},
	{latitude: 29.505183, longitude: -94.986711, zipCode: "77518"},
	{latitude: 30.218323, longitude: -94.575058, zipCode: "77519"},
	{latitude: 29.736716, longitude: -95.000847, zipCode: "77520"},
	{latitude: 29.811924, longitude: -94.963306, zipCode: "77521"},
	{latitude: 29.766303, longitude: -94.865759, zipCode: "77523"},
	{latitude: 29.789356, longitude: -95.113987, zipCode: "77530"},
	{latitude: 29.038855, longitude: -95.401937, zipCode: "77531"},
	{latitude: 29.932894, longitude: -95.057219, zipCode: "77532"},
	{latitude: 30.087172, longitude: -94.599557, zipCode: "77533"},
	{latitude: 29.215163, longitude: -95.311193, zipCode: "77534"},
	{latitude: 30.054844, longitude: -94.922316, zipCode: "77535"},
	{latitude: 29.691659, longitude: -95.116531, zipCode: "77536"},
	{latitude: 29.974167, longitude: -94.522942, zipCode: "77538"},
	{latitude: 29.452997, longitude: -95.02847, zipCode: "77539"},
	{latitude: 29.016114, longitude: -95.325708, zipCode: "77541"},
	{latitude: 29.537323, longitude: -95.473042, zipCode: "77545"},
	{latitude: 29.511132, longitude: -95.193008, zipCode: "77546"},
	{latitude: 29.740123, longitude: -95.239114, zipCode: "77547"},
	{latitude: 29.307853, longitude: -94.769334, zipCode: "77550"},
	{latitude: 29.279462, longitude: -94.833527, zipCode: "77551"},
	{latitude: 29.23501, longitude: -94.924489, zipCode: "77554"},
	{latitude: 29.310761, longitude: -94.776472, zipCode: "77555"},
	{latitude: 29.866863, longitude: -94.587444, zipCode: "77560"},
	{latitude: 30.134736, longitude: -94.735504, zipCode: "77561"},
	{latitude: 29.829761, longitude: -95.05494, zipCode: "77562"},
	{latitude: 29.303625, longitude: -95.032385, zipCode: "77563"},
	{latitude: 30.232134, longitude: -94.655967, zipCode: "77564"},
	{latitude: 29.538069, longitude: -95.03292, zipCode: "77565"},
	{latitude: 29.052739, longitude: -95.474351, zipCode: "77566"},
	{latitude: 29.365831, longitude: -94.988044, zipCode: "77568"},
	{latitude: 29.688455, longitude: -95.05738, zipCode: "77571"},
	{latitude: 29.503785, longitude: -95.0873, zipCode: "77573"},
	{latitude: 30.081073, longitude: -94.752565, zipCode: "77575"},
	{latitude: 29.259741, longitude: -95.264579, zipCode: "77577"},
	{latitude: 29.485422, longitude: -95.362322, zipCode: "77578"},
	{latitude: 29.85752, longitude: -94.874131, zipCode: "77580"},
	{latitude: 29.561597, longitude: -95.279759, zipCode: "77581"},
	{latitude: 30.013779, longitude: -94.662106, zipCode: "77582"},
	{latitude: 29.374205, longitude: -95.453756, zipCode: "77583"},
	{latitude: 29.545041, longitude: -95.350692, zipCode: "77584"},
	{latitude: 30.330645, longitude: -94.534176, zipCode: "77585"},
	{latitude: 29.581848, longitude: -95.038739, zipCode: "77586"},
	{latitude: 29.661024, longitude: -95.228361, zipCode: "77587"},
	{latitude: 29.378627, longitude: -94.916651, zipCode: "77590"},
	{latitude: 29.399363, longitude: -94.999115, zipCode: "77591"},
	{latitude: 29.828398, longitude: -94.696298, zipCode: "77597"},
	{latitude: 29.53942, longitude: -95.134993, zipCode: "77598"},
	{latitude: 30.014439, longitude: -93.828243, zipCode: "77611"},
	{latitude: 30.401923, longitude: -93.935124, zipCode: "77612"},
	{latitude: 30.027694, longitude: -94.358782, zipCode: "77613"},
	{latitude: 30.306015, longitude: -93.747906, zipCode: "77614"},
	{latitude: 30.357102, longitude: -94.065681, zipCode: "77615"},
	{latitude: 30.566807, longitude: -94.209042, zipCode: "77616"},
	{latitude: 29.530784, longitude: -94.469858, zipCode: "77617"},
	{latitude: 29.945971, longitude: -93.916656, zipCode: "77619"},
	{latitude: 29.862397, longitude: -94.286046, zipCode: "77622"},
	{latitude: 29.561256, longitude: -94.414021, zipCode: "77623"},
	{latitude: 30.678969, longitude: -94.271465, zipCode: "77624"},
	{latitude: 30.367544, longitude: -94.391402, zipCode: "77625"},
	{latitude: 29.990409, longitude: -94.006974, zipCode: "77627"},
	{latitude: 29.977461, longitude: -94.43306, zipCode: "77629"},
	{latitude: 30.066254, longitude: -93.870098, zipCode: "77630"},
	{latitude: 30.201515, longitude: -93.804648, zipCode: "77632"},
	{latitude: 29.880511, longitude: -93.941151, zipCode: "77640"},
	{latitude: 29.95802, longitude: -93.891921, zipCode: "77642"},
	{latitude: 29.438515, longitude: -94.663658, zipCode: "77650"},
	{latitude: 29.98055, longitude: -93.948021, zipCode: "77651"},
	{latitude: 29.709922, longitude: -93.939365, zipCode: "77655"},
	{latitude: 30.424433, longitude: -94.169063, zipCode: "77656"},
	{latitude: 30.226788, longitude: -94.188851, zipCode: "77657"},
	{latitude: 30.171444, longitude: -94.432275, zipCode: "77659"},
	{latitude: 30.645369, longitude: -94.139641, zipCode: "77660"},
	{latitude: 29.77996, longitude: -94.368614, zipCode: "77661"},
	{latitude: 30.177924, longitude: -94.018171, zipCode: "77662"},
	{latitude: 30.512662, longitude: -94.406722, zipCode: "77663"},
	{latitude: 30.584852, longitude: -94.396222, zipCode: "77664"},
	{latitude: 29.789888, longitude: -94.406324, zipCode: "77665"},
	{latitude: 30.073053, longitude: -94.105486, zipCode: "77701"},
	{latitude: 30.086285, longitude: -94.128289, zipCode: "77702"},
	{latitude: 30.114058, longitude: -94.109271, zipCode: "77703"},
	{latitude: 29.908454, longitude: -94.175243, zipCode: "77705"},
	{latitude: 30.100359, longitude: -94.166937, zipCode: "77706"},
	{latitude: 30.053142, longitude: -94.166056, zipCode: "77707"},
	{latitude: 30.150453, longitude: -94.153426, zipCode: "77708"},
	{latitude: 30.068794, longitude: -94.259663, zipCode: "77713"},
	{latitude: 30.639285, longitude: -96.362454, zipCode: "77801"},
	{latitude: 30.660911, longitude: -96.322289, zipCode: "77802"},
	{latitude: 30.678262, longitude: -96.387152, zipCode: "77803"},
	{latitude: 30.677687, longitude: -96.483601, zipCode: "77807"},
	{latitude: 30.797736, longitude: -96.312084, zipCode: "77808"},
	{latitude: 30.532425, longitude: -95.998153, zipCode: "77830"},
	{latitude: 30.731755, longitude: -95.907465, zipCode: "77831"},
	{latitude: 30.205898, longitude: -96.410067, zipCode: "77833"},
	{latitude: 30.193586, longitude: -96.588051, zipCode: "77835"},
	{latitude: 30.5375, longitude: -96.680513, zipCode: "77836"},
	{latitude: 31.014211, longitude: -96.677701, zipCode: "77837"},
	{latitude: 30.611527, longitude: -96.323267, zipCode: "77840"},
	{latitude: 30.615329, longitude: -96.340234, zipCode: "77843"},
	{latitude: 30.5611, longitude: -96.275139, zipCode: "77845"},
	{latitude: 30.428385, longitude: -96.757581, zipCode: "77852"},
	{latitude: 30.354551, longitude: -96.840806, zipCode: "77853"},
	{latitude: 31.154981, longitude: -96.103067, zipCode: "77855"},
	{latitude: 31.092107, longitude: -96.4122, zipCode: "77856"},
	{latitude: 30.791279, longitude: -96.755021, zipCode: "77857"},
	{latitude: 30.879524, longitude: -96.516335, zipCode: "77859"},
	{latitude: 30.721716, longitude: -96.109298, zipCode: "77861"},
	{latitude: 30.390769, longitude: -96.558881, zipCode: "77863"},
	{latitude: 30.960288, longitude: -95.894142, zipCode: "77864"},
	{latitude: 31.211668, longitude: -96.203161, zipCode: "77865"},
	{latitude: 30.494875, longitude: -96.214829, zipCode: "77866"},
	{latitude: 30.742731, longitude: -96.570727, zipCode: "77867"},
	{latitude: 30.365776, longitude: -96.04642, zipCode: "77868"},
	{latitude: 31.0572, longitude: -96.110997, zipCode: "77871"},
	{latitude: 30.884964, longitude: -96.089548, zipCode: "77872"},
	{latitude: 30.582632, longitude: -95.800538, zipCode: "77873"},
	{latitude: 30.578914, longitude: -95.941674, zipCode: "77875"},
	{latitude: 30.597562, longitude: -95.856922, zipCode: "77876"},
	{latitude: 30.491249, longitude: -96.434382, zipCode: "77878"},
	{latitude: 30.416515, longitude: -96.465717, zipCode: "77879"},
	{latitude: 30.286936, longitude: -96.189365, zipCode: "77880"},
	{latitude: 30.911384, longitude: -96.3791, zipCode: "77882"},
	{latitude: 28.806249, longitude: -96.985438, zipCode: "77901"},
	{latitude: 28.917567, longitude: -97.011864, zipCode: "77904"},
	{latitude: 28.737418, longitude: -97.060318, zipCode: "77905"},
	{latitude: 28.246142, longitude: -96.8974, zipCode: "77950"},
	{latitude: 28.635946, longitude: -96.911444, zipCode: "77951"},
	{latitude: 29.096488, longitude: -97.295716, zipCode: "77954"},
	{latitude: 29.035875, longitude: -96.716211, zipCode: "77957"},
	{latitude: 28.653515, longitude: -97.252385, zipCode: "77960"},
	{latitude: 28.846143, longitude: -96.377036, zipCode: "77961"},
	{latitude: 29.030815, longitude: -96.503389, zipCode: "77962"},
	{latitude: 28.652457, longitude: -97.405775, zipCode: "77963"},
	{latitude: 29.378848, longitude: -96.834606, zipCode: "77964"},
	{latitude: 28.916065, longitude: -96.854022, zipCode: "77968"},
	{latitude: 28.782649, longitude: -96.653994, zipCode: "77969"},
	{latitude: 28.846341, longitude: -96.434149, zipCode: "77970"},
	{latitude: 28.833262, longitude: -96.521612, zipCode: "77971"},
	{latitude: 28.532467, longitude: -96.9642, zipCode: "77973"},
	{latitude: 28.897853, longitude: -97.288877, zipCode: "77974"},
	{latitude: 29.575684, longitude: -97.081976, zipCode: "77975"},
	{latitude: 28.940564, longitude: -97.13418, zipCode: "77976"},
	{latitude: 28.680828, longitude: -96.801866, zipCode: "77977"},
	{latitude: 28.690358, longitude: -96.57791, zipCode: "77978"},
	{latitude: 28.60965, longitude: -96.61898, zipCode: "77979"},
	{latitude: 28.444644, longitude: -96.491788, zipCode: "77982"},
	{latitude: 28.376402, longitude: -96.696758, zipCode: "77983"},
	{latitude: 29.465685, longitude: -97.193893, zipCode: "77984"},
	{latitude: 29.476607, longitude: -96.796078, zipCode: "77986"},
	{latitude: 29.345578, longitude: -97.080203, zipCode: "77987"},
	{latitude: 28.836933, longitude: -96.884758, zipCode: "77988"},
	{latitude: 28.484888, longitude: -96.924886, zipCode: "77990"},
	{latitude: 28.785362, longitude: -96.596011, zipCode: "77991"},
	{latitude: 28.855654, longitude: -97.419639, zipCode: "77993"},
	{latitude: 29.178194, longitude: -97.47155, zipCode: "77994"},
	{latitude: 29.200412, longitude: -97.091881, zipCode: "77995"},
	{latitude: 28.231577, longitude: -99.221995, zipCode: "78001"},
	{latitude: 29.28375, longitude: -98.737693, zipCode: "78002"},
	{latitude: 29.70798, longitude: -99.103894, zipCode: "78003"},
	{latitude: 29.857679, longitude: -98.537702, zipCode: "78004"},
	{latitude: 28.935722, longitude: -98.832941, zipCode: "78005"},
	{latitude: 29.85855, longitude: -98.703353, zipCode: "78006"},
	{latitude: 28.476072, longitude: -98.408487, zipCode: "78007"},
	{latitude: 28.727589, longitude: -98.25982, zipCode: "78008"},
	{latitude: 29.367585, longitude: -98.889807, zipCode: "78009"},
	{latitude: 29.910753, longitude: -99.04681, zipCode: "78010"},
	{latitude: 28.813097, longitude: -98.726115, zipCode: "78011"},
	{latitude: 28.771756, longitude: -98.453507, zipCode: "78012"},
	{latitude: 29.977171, longitude: -98.904977, zipCode: "78013"},
	{latitude: 28.395098, longitude: -99.113861, zipCode: "78014"},
	{latitude: 29.742896, longitude: -98.651458, zipCode: "78015"},
	{latitude: 29.181395, longitude: -98.951719, zipCode: "78016"},
	{latitude: 28.741551, longitude: -99.208039, zipCode: "78017"},
	{latitude: 28.024355, longitude: -99.270844, zipCode: "78019"},
	{latitude: 28.437456, longitude: -98.83137, zipCode: "78021"},
	{latitude: 28.238271, longitude: -98.168594, zipCode: "78022"},
	{latitude: 29.621586, longitude: -98.756372, zipCode: "78023"},
	{latitude: 29.995463, longitude: -99.485565, zipCode: "78024"},
	{latitude: 30.07779, longitude: -99.285401, zipCode: "78025"},
	{latitude: 28.747304, longitude: -98.559149, zipCode: "78026"},
	{latitude: 29.980917, longitude: -98.529304, zipCode: "78027"},
	{latitude: 30.03651, longitude: -99.163044, zipCode: "78028"},
	{latitude: 29.899539, longitude: -99.186974, zipCode: "78029"},
	{latitude: 29.31215, longitude: -98.834792, zipCode: "78039"},
	{latitude: 27.513375, longitude: -99.50214, zipCode: "78040"},
	{latitude: 27.559571, longitude: -99.462957, zipCode: "78041"},
	{latitude: 27.570451, longitude: -99.304188, zipCode: "78043"},
	{latitude: 27.73253, longitude: -99.229736, zipCode: "78044"},
	{latitude: 27.833815, longitude: -99.681424, zipCode: "78045"},
	{latitude: 27.385525, longitude: -99.389132, zipCode: "78046"},
	{latitude: 29.089151, longitude: -98.502462, zipCode: "78050"},
	{latitude: 29.210468, longitude: -98.775324, zipCode: "78052"},
	{latitude: 29.830348, longitude: -99.331352, zipCode: "78055"},
	{latitude: 29.540929, longitude: -98.942615, zipCode: "78056"},
	{latitude: 29.043063, longitude: -99.016422, zipCode: "78057"},
	{latitude: 30.105919, longitude: -99.600886, zipCode: "78058"},
	{latitude: 29.189493, longitude: -98.841011, zipCode: "78059"},
	{latitude: 28.901927, longitude: -99.126644, zipCode: "78061"},
	{latitude: 28.749449, longitude: -98.202784, zipCode: "78062"},
	{latitude: 29.689692, longitude: -98.911216, zipCode: "78063"},
	{latitude: 28.924564, longitude: -98.429083, zipCode: "78064"},
	{latitude: 29.079818, longitude: -98.642303, zipCode: "78065"},
	{latitude: 29.493028, longitude: -98.891867, zipCode: "78066"},
	{latitude: 27.103631, longitude: -99.363349, zipCode: "78067"},
	{latitude: 29.188365, longitude: -98.671845, zipCode: "78069"},
	{latitude: 29.892301, longitude: -98.408273, zipCode: "78070"},
	{latitude: 28.474444, longitude: -98.199054, zipCode: "78071"},
	{latitude: 28.32531, longitude: -98.586392, zipCode: "78072"},
	{latitude: 29.243915, longitude: -98.622442, zipCode: "78073"},
	{latitude: 29.942285, longitude: -98.792226, zipCode: "78074"},
	{latitude: 28.589505, longitude: -98.270578, zipCode: "78075"},
	{latitude: 26.934109, longitude: -99.131024, zipCode: "78076"},
	{latitude: 29.353553, longitude: -98.238308, zipCode: "78101"},
	{latitude: 28.415399, longitude: -97.71444, zipCode: "78102"},
	{latitude: 28.523624, longitude: -97.762016, zipCode: "78104"},
	{latitude: 28.568278, longitude: -97.579237, zipCode: "78107"},
	{latitude: 29.570142, longitude: -98.217797, zipCode: "78108"},
	{latitude: 29.477983, longitude: -98.293406, zipCode: "78109"},
	{latitude: 29.034959, longitude: -97.73698, zipCode: "78111"},
	{latitude: 29.186904, longitude: -98.392789, zipCode: "78112"},
	{latitude: 28.945213, longitude: -98.132466, zipCode: "78113"},
	{latitude: 29.126771, longitude: -98.198371, zipCode: "78114"},
	{latitude: 29.102255, longitude: -97.743964, zipCode: "78116"},
	{latitude: 28.938338, longitude: -97.923572, zipCode: "78117"},
	{latitude: 28.874787, longitude: -97.937367, zipCode: "78118"},
	{latitude: 28.72143, longitude: -97.913782, zipCode: "78119"},
	{latitude: 29.351052, longitude: -98.088866, zipCode: "78121"},
	{latitude: 29.457249, longitude: -97.707606, zipCode: "78122"},
	{latitude: 29.605116, longitude: -98.039135, zipCode: "78123"},
	{latitude: 29.557271, longitude: -98.146892, zipCode: "78124"},
	{latitude: 28.531848, longitude: -97.966545, zipCode: "78125"},
	{latitude: 29.693649, longitude: -98.068676, zipCode: "78130"},
	{latitude: 29.758817, longitude: -98.191068, zipCode: "78132"},
	{latitude: 29.883877, longitude: -98.252229, zipCode: "78133"},
	{latitude: 29.306802, longitude: -97.763362, zipCode: "78140"},
	{latitude: 28.908121, longitude: -97.618884, zipCode: "78141"},
	{latitude: 28.536291, longitude: -97.812957, zipCode: "78142"},
	{latitude: 29.25871, longitude: -97.851241, zipCode: "78143"},
	{latitude: 28.951209, longitude: -97.892578, zipCode: "78144"},
	{latitude: 28.645392, longitude: -97.992487, zipCode: "78145"},
	{latitude: 28.603767, longitude: -97.839917, zipCode: "78146"},
	{latitude: 29.073446, longitude: -98.080406, zipCode: "78147"},
	{latitude: 29.543251, longitude: -98.296478, zipCode: "78148"},
	{latitude: 29.538151, longitude: -98.281563, zipCode: "78150"},
	{latitude: 28.869945, longitude: -97.713456, zipCode: "78151"},
	{latitude: 29.429237, longitude: -98.204532, zipCode: "78152"},
	{latitude: 29.547147, longitude: -98.262605, zipCode: "78154"},
	{latitude: 29.542968, longitude: -97.937698, zipCode: "78155"},
	{latitude: 29.225941, longitude: -97.585019, zipCode: "78159"},
	{latitude: 29.245074, longitude: -97.908914, zipCode: "78160"},
	{latitude: 29.286151, longitude: -98.050314, zipCode: "78161"},
	{latitude: 28.578178, longitude: -97.794476, zipCode: "78162"},
	{latitude: 29.768727, longitude: -98.44052, zipCode: "78163"},
	{latitude: 28.979611, longitude: -97.520952, zipCode: "78164"},
	{latitude: 29.468413, longitude: -98.528889, zipCode: "78201"},
	{latitude: 29.428224, longitude: -98.460787, zipCode: "78202"},
	{latitude: 29.415125, longitude: -98.459335, zipCode: "78203"},
	{latitude: 29.404404, longitude: -98.505028, zipCode: "78204"},
	{latitude: 29.423945, longitude: -98.48642, zipCode: "78205"},
	{latitude: 29.422124, longitude: -98.525978, zipCode: "78207"},
	{latitude: 29.439875, longitude: -98.458105, zipCode: "78208"},
	{latitude: 29.488906, longitude: -98.456235, zipCode: "78209"},
	{latitude: 29.395776, longitude: -98.464401, zipCode: "78210"},
	{latitude: 29.34255, longitude: -98.570024, zipCode: "78211"},
	{latitude: 29.464611, longitude: -98.493653, zipCode: "78212"},
	{latitude: 29.516395, longitude: -98.523017, zipCode: "78213"},
	{latitude: 29.34976, longitude: -98.476736, zipCode: "78214"},
	{latitude: 29.440446, longitude: -98.480293, zipCode: "78215"},
	{latitude: 29.538739, longitude: -98.491258, zipCode: "78216"},
	{latitude: 29.539974, longitude: -98.42005, zipCode: "78217"},
	{latitude: 29.494749, longitude: -98.396559, zipCode: "78218"},
	{latitude: 29.439928, longitude: -98.384478, zipCode: "78219"},
	{latitude: 29.412433, longitude: -98.398385, zipCode: "78220"},
	{latitude: 29.29864, longitude: -98.483115, zipCode: "78221"},
	{latitude: 29.355761, longitude: -98.386375, zipCode: "78222"},
	{latitude: 29.310488, longitude: -98.387514, zipCode: "78223"},
	{latitude: 29.310237, longitude: -98.546151, zipCode: "78224"},
	{latitude: 29.387967, longitude: -98.525846, zipCode: "78225"},
	{latitude: 29.383778, longitude: -98.56994, zipCode: "78226"},
	{latitude: 29.389895, longitude: -98.639378, zipCode: "78227"},
	{latitude: 29.460646, longitude: -98.571279, zipCode: "78228"},
	{latitude: 29.505502, longitude: -98.577033, zipCode: "78229"},
	{latitude: 29.546515, longitude: -98.557207, zipCode: "78230"},
	{latitude: 29.581019, longitude: -98.542526, zipCode: "78231"},
	{latitude: 29.590145, longitude: -98.476253, zipCode: "78232"},
	{latitude: 29.555319, longitude: -98.364027, zipCode: "78233"},
	{latitude: 29.460896, longitude: -98.43834, zipCode: "78234"},
	{latitude: 29.344242, longitude: -98.438991, zipCode: "78235"},
	{latitude: 29.385897, longitude: -98.622521, zipCode: "78236"},
	{latitude: 29.420924, longitude: -98.566465, zipCode: "78237"},
	{latitude: 29.471786, longitude: -98.617678, zipCode: "78238"},
	{latitude: 29.516184, longitude: -98.361849, zipCode: "78239"},
	{latitude: 29.525291, longitude: -98.604382, zipCode: "78240"},
	{latitude: 29.3504, longitude: -98.607054, zipCode: "78242"},
	{latitude: 29.371037, longitude: -98.592987, zipCode: "78243"},
	{latitude: 29.477668, longitude: -98.351082, zipCode: "78244"},
	{latitude: 29.39317, longitude: -98.74144, zipCode: "78245"},
	{latitude: 29.581433, longitude: -98.408776, zipCode: "78247"},
	{latitude: 29.590028, longitude: -98.525262, zipCode: "78248"},
	{latitude: 29.568476, longitude: -98.614271, zipCode: "78249"},
	{latitude: 29.502729, longitude: -98.664897, zipCode: "78250"},
	{latitude: 29.461683, longitude: -98.676727, zipCode: "78251"},
	{latitude: 29.335952, longitude: -98.690709, zipCode: "78252"},
	{latitude: 29.470229, longitude: -98.787591, zipCode: "78253"},
	{latitude: 29.530564, longitude: -98.72718, zipCode: "78254"},
	{latitude: 29.649165, longitude: -98.666344, zipCode: "78255"},
	{latitude: 29.622289, longitude: -98.626164, zipCode: "78256"},
	{latitude: 29.647103, longitude: -98.581072, zipCode: "78257"},
	{latitude: 29.634209, longitude: -98.489808, zipCode: "78258"},
	{latitude: 29.625165, longitude: -98.421852, zipCode: "78259"},
	{latitude: 29.698541, longitude: -98.492729, zipCode: "78260"},
	{latitude: 29.68958, longitude: -98.402411, zipCode: "78261"},
	{latitude: 29.32891, longitude: -98.312898, zipCode: "78263"},
	{latitude: 29.201192, longitude: -98.508966, zipCode: "78264"},
	{latitude: 29.650495, longitude: -98.338431, zipCode: "78266"},
	{latitude: 27.755818, longitude: -97.925974, zipCode: "78330"},
	{latitude: 27.724313, longitude: -98.134487, zipCode: "78332"},
	{latitude: 27.927892, longitude: -97.171277, zipCode: "78336"},
	{latitude: 26.856162, longitude: -97.732223, zipCode: "78338"},
	{latitude: 27.800017, longitude: -97.796112, zipCode: "78339"},
	{latitude: 28.1207, longitude: -97.19689, zipCode: "78340"},
	{latitude: 27.575018, longitude: -98.471129, zipCode: "78341"},
	{latitude: 27.660163, longitude: -98.075692, zipCode: "78342"},
	{latitude: 27.626037, longitude: -97.766234, zipCode: "78343"},
	{latitude: 27.559603, longitude: -98.854901, zipCode: "78344"},
	{latitude: 27.573374, longitude: -97.486404, zipCode: "78347"},
	{latitude: 27.373485, longitude: -98.285472, zipCode: "78349"},
	{latitude: 27.667801, longitude: -97.75258, zipCode: "78351"},
	{latitude: 27.98154, longitude: -97.689782, zipCode: "78352"},
	{latitude: 26.882961, longitude: -98.240519, zipCode: "78353"},
	{latitude: 27.18888, longitude: -98.206411, zipCode: "78355"},
	{latitude: 27.933768, longitude: -98.567347, zipCode: "78357"},
	{latitude: 28.067949, longitude: -97.042232, zipCode: "78358"},
	{latitude: 27.93022, longitude: -97.300538, zipCode: "78359"},
	{latitude: 26.860504, longitude: -98.832055, zipCode: "78360"},
	{latitude: 27.053231, longitude: -98.747572, zipCode: "78361"},
	{latitude: 27.853993, longitude: -97.196496, zipCode: "78362"},
	{latitude: 27.436813, longitude: -97.853828, zipCode: "78363"},
	{latitude: 28.113668, longitude: -97.801551, zipCode: "78368"},
	{latitude: 27.42933, longitude: -99.08597, zipCode: "78369"},
	{latitude: 27.935487, longitude: -97.605556, zipCode: "78370"},
	{latitude: 27.512602, longitude: -99.073571, zipCode: "78371"},
	{latitude: 28.009554, longitude: -98.098803, zipCode: "78372"},
	{latitude: 27.776251, longitude: -97.130398, zipCode: "78373"},
	{latitude: 27.885392, longitude: -97.300174, zipCode: "78374"},
	{latitude: 27.39631, longitude: -98.171285, zipCode: "78375"},
	{latitude: 27.367472, longitude: -98.483299, zipCode: "78376"},
	{latitude: 28.322116, longitude: -97.162472, zipCode: "78377"},
	{latitude: 27.306614, longitude: -97.780427, zipCode: "78379"},
	{latitude: 27.776069, longitude: -97.741915, zipCode: "78380"},
	{latitude: 28.11318, longitude: -97.046273, zipCode: "78382"},
	{latitude: 28.072822, longitude: -97.944062, zipCode: "78383"},
	{latitude: 27.734594, longitude: -98.471677, zipCode: "78384"},
	{latitude: 27.121601, longitude: -97.836132, zipCode: "78385"},
	{latitude: 28.081035, longitude: -97.548899, zipCode: "78387"},
	{latitude: 28.246302, longitude: -97.650811, zipCode: "78389"},
	{latitude: 28.007673, longitude: -97.349938, zipCode: "78390"},
	{latitude: 28.179576, longitude: -97.708393, zipCode: "78391"},
	{latitude: 28.185211, longitude: -97.354437, zipCode: "78393"},
	{latitude: 27.796812, longitude: -97.399092, zipCode: "78401"},
	{latitude: 27.851846, longitude: -97.439165, zipCode: "78402"},
	{latitude: 27.767919, longitude: -97.399444, zipCode: "78404"},
	{latitude: 27.774039, longitude: -97.438731, zipCode: "78405"},
	{latitude: 27.771962, longitude: -97.51545, zipCode: "78406"},
	{latitude: 27.807622, longitude: -97.447745, zipCode: "78407"},
	{latitude: 27.799635, longitude: -97.443688, zipCode: "78408"},
	{latitude: 27.827786, longitude: -97.509559, zipCode: "78409"},
	{latitude: 27.837656, longitude: -97.593129, zipCode: "78410"},
	{latitude: 27.729177, longitude: -97.385089, zipCode: "78411"},
	{latitude: 27.703573, longitude: -97.345045, zipCode: "78412"},
	{latitude: 27.685823, longitude: -97.406015, zipCode: "78413"},
	{latitude: 27.662421, longitude: -97.371535, zipCode: "78414"},
	{latitude: 27.658984, longitude: -97.495147, zipCode: "78415"},
	{latitude: 27.74933, longitude: -97.437585, zipCode: "78416"},
	{latitude: 27.73753, longitude: -97.467701, zipCode: "78417"},
	{latitude: 27.389078, longitude: -97.329145, zipCode: "78418"},
	{latitude: 27.69696, longitude: -97.269235, zipCode: "78419"},
	{latitude: 26.215817, longitude: -98.239572, zipCode: "78501"},
	{latitude: 26.162332, longitude: -98.248702, zipCode: "78503"},
	{latitude: 26.28279, longitude: -98.236933, zipCode: "78504"},
	{latitude: 26.144539, longitude: -98.121312, zipCode: "78516"},
	{latitude: 25.960875, longitude: -97.548607, zipCode: "78520"},
	{latitude: 25.943923, longitude: -97.317944, zipCode: "78521"},
	{latitude: 25.995689, longitude: -97.450848, zipCode: "78526"},
	{latitude: 26.24961, longitude: -97.731281, zipCode: "78535"},
	{latitude: 26.643234, longitude: -98.443726, zipCode: "78536"},
	{latitude: 26.173186, longitude: -98.057967, zipCode: "78537"},
	{latitude: 26.367213, longitude: -97.974038, zipCode: "78538"},
	{latitude: 26.280689, longitude: -98.183295, zipCode: "78539"},
	{latitude: 26.549081, longitude: -97.991646, zipCode: "78540"},
	{latitude: 26.452135, longitude: -98.276811, zipCode: "78541"},
	{latitude: 26.432513, longitude: -98.089884, zipCode: "78542"},
	{latitude: 26.301157, longitude: -98.005073, zipCode: "78543"},
	{latitude: 26.585644, longitude: -99.134651, zipCode: "78545"},
	{latitude: 26.304187, longitude: -98.696403, zipCode: "78547"},
	{latitude: 26.276549, longitude: -98.655268, zipCode: "78548"},
	{latitude: 26.489349, longitude: -98.029688, zipCode: "78549"},
	{latitude: 26.255724, longitude: -97.6635, zipCode: "78550"},
	{latitude: 26.193782, longitude: -97.75069, zipCode: "78552"},
	{latitude: 26.109464, longitude: -98.253463, zipCode: "78557"},
	{latitude: 26.292747, longitude: -98.023689, zipCode: "78558"},
	{latitude: 26.145744, longitude: -97.82572, zipCode: "78559"},
	{latitude: 26.265326, longitude: -98.485759, zipCode: "78560"},
	{latitude: 26.468912, longitude: -97.915781, zipCode: "78561"},
	{latitude: 26.283802, longitude: -97.891227, zipCode: "78562"},
	{latitude: 26.672311, longitude: -98.254972, zipCode: "78563"},
	{latitude: 26.671224, longitude: -99.131115, zipCode: "78564"},
	{latitude: 26.244459, longitude: -98.561327, zipCode: "78565"},
	{latitude: 26.137185, longitude: -97.388567, zipCode: "78566"},
	{latitude: 26.044155, longitude: -97.738421, zipCode: "78567"},
	{latitude: 26.378857, longitude: -97.746805, zipCode: "78569"},
	{latitude: 26.171539, longitude: -97.911049, zipCode: "78570"},
	{latitude: 26.201484, longitude: -98.357159, zipCode: "78572"},
	{latitude: 26.296191, longitude: -98.299959, zipCode: "78573"},
	{latitude: 26.31117, longitude: -98.372914, zipCode: "78574"},
	{latitude: 26.033306, longitude: -97.550231, zipCode: "78575"},
	{latitude: 26.317103, longitude: -98.463699, zipCode: "78576"},
	{latitude: 26.163436, longitude: -98.19389, zipCode: "78577"},
	{latitude: 26.043955, longitude: -97.306593, zipCode: "78578"},
	{latitude: 26.08921, longitude: -97.946286, zipCode: "78579"},
	{latitude: 26.500598, longitude: -97.747802, zipCode: "78580"},
	{latitude: 26.530903, longitude: -98.740235, zipCode: "78582"},
	{latitude: 26.285511, longitude: -97.471595, zipCode: "78583"},
	{latitude: 26.569737, longitude: -98.99195, zipCode: "78584"},
	{latitude: 26.484168, longitude: -99.080648, zipCode: "78585"},
	{latitude: 26.109941, longitude: -97.645414, zipCode: "78586"},
	{latitude: 26.73968, longitude: -98.39852, zipCode: "78588"},
	{latitude: 26.151151, longitude: -98.159307, zipCode: "78589"},
	{latitude: 26.501875, longitude: -97.635063, zipCode: "78590"},
	{latitude: 26.639132, longitude: -98.551887, zipCode: "78591"},
	{latitude: 26.062006, longitude: -97.846971, zipCode: "78592"},
	{latitude: 26.275747, longitude: -97.823524, zipCode: "78593"},
	{latitude: 26.34637, longitude: -97.806243, zipCode: "78594"},
	{latitude: 26.314551, longitude: -98.540404, zipCode: "78595"},
	{latitude: 26.097256, longitude: -97.999651, zipCode: "78596"},
	{latitude: 26.273207, longitude: -97.27637, zipCode: "78597"},
	{latitude: 26.541967, longitude: -97.419907, zipCode: "78598"},
	{latitude: 26.217004, longitude: -97.987952, zipCode: "78599"},
	{latitude: 30.132505, longitude: -97.329527, zipCode: "78602"},
	{latitude: 30.773295, longitude: -98.04518, zipCode: "78605"},
	{latitude: 30.086444, longitude: -98.457963, zipCode: "78606"},
	{latitude: 30.819379, longitude: -98.488114, zipCode: "78607"},
	{latitude: 30.931006, longitude: -97.936974, zipCode: "78608"},
	{latitude: 30.746922, longitude: -98.451367, zipCode: "78609"},
	{latitude: 30.079459, longitude: -97.841011, zipCode: "78610"},
	{latitude: 30.801051, longitude: -98.28349, zipCode: "78611"},
	{latitude: 30.094071, longitude: -97.495094, zipCode: "78612"},
	{latitude: 30.507079, longitude: -97.819464, zipCode: "78613"},
	{latitude: 29.410411, longitude: -97.587167, zipCode: "78614"},
	{latitude: 30.463118, longitude: -97.392857, zipCode: "78615"},
	{latitude: 29.926602, longitude: -97.553539, zipCode: "78616"},
	{latitude: 30.147803, longitude: -97.603558, zipCode: "78617"},
	{latitude: 30.488233, longitude: -99.173583, zipCode: "78618"},
	{latitude: 30.112156, longitude: -98.031043, zipCode: "78619"},
	{latitude: 30.225607, longitude: -98.136297, zipCode: "78620"},
	{latitude: 30.337023, longitude: -97.36042, zipCode: "78621"},
	{latitude: 29.782595, longitude: -97.779435, zipCode: "78622"},
	{latitude: 29.955285, longitude: -98.216849, zipCode: "78623"},
	{latitude: 30.291786, longitude: -98.870915, zipCode: "78624"},
	{latitude: 30.657895, longitude: -97.617843, zipCode: "78626"},
	{latitude: 30.664543, longitude: -97.778616, zipCode: "78628"},
	{latitude: 29.476842, longitude: -97.44894, zipCode: "78629"},
	{latitude: 30.33977, longitude: -99.30658, zipCode: "78631"},
	{latitude: 29.685544, longitude: -97.471489, zipCode: "78632"},
	{latitude: 30.735166, longitude: -97.75545, zipCode: "78633"},
	{latitude: 30.55985, longitude: -97.544081, zipCode: "78634"},
	{latitude: 30.202352, longitude: -98.541681, zipCode: "78635"},
	{latitude: 30.316898, longitude: -98.424992, zipCode: "78636"},
	{latitude: 29.659588, longitude: -97.785196, zipCode: "78638"},
	{latitude: 30.656288, longitude: -98.455537, zipCode: "78639"},
	{latitude: 29.994866, longitude: -97.821239, zipCode: "78640"},
	{latitude: 30.557918, longitude: -97.913672, zipCode: "78641"},
	{latitude: 30.695665, longitude: -97.939508, zipCode: "78642"},
	{latitude: 30.679688, longitude: -98.714851, zipCode: "78643"},
	{latitude: 29.872592, longitude: -97.674363, zipCode: "78644"},
	{latitude: 30.450041, longitude: -97.971978, zipCode: "78645"},
	{latitude: 29.694265, longitude: -97.638111, zipCode: "78648"},
	{latitude: 30.303885, longitude: -97.218899, zipCode: "78650"},
	{latitude: 30.134626, longitude: -97.860639, zipCode: "78652"},
	{latitude: 30.338229, longitude: -97.52073, zipCode: "78653"},
	{latitude: 30.571849, longitude: -98.210498, zipCode: "78654"},
	{latitude: 29.816062, longitude: -97.835053, zipCode: "78655"},
	{latitude: 29.904511, longitude: -97.807308, zipCode: "78656"},
	{latitude: 30.533379, longitude: -98.378353, zipCode: "78657"},
	{latitude: 29.595952, longitude: -97.622539, zipCode: "78658"},
	{latitude: 30.207417, longitude: -97.125389, zipCode: "78659"},
	{latitude: 30.43963, longitude: -97.594687, zipCode: "78660"},
	{latitude: 29.721948, longitude: -97.758944, zipCode: "78661"},
	{latitude: 29.944801, longitude: -97.443564, zipCode: "78662"},
	{latitude: 30.415519, longitude: -98.311699, zipCode: "78663"},
	{latitude: 30.505414, longitude: -97.647284, zipCode: "78664"},
	{latitude: 30.548874, longitude: -97.636201, zipCode: "78665"},
	{latitude: 29.881662, longitude: -97.984794, zipCode: "78666"},
	{latitude: 30.442478, longitude: -98.123381, zipCode: "78669"},
	{latitude: 29.7648, longitude: -97.848529, zipCode: "78670"},
	{latitude: 30.218956, longitude: -98.629901, zipCode: "78671"},
	{latitude: 30.882248, longitude: -98.489135, zipCode: "78672"},
	{latitude: 30.670574, longitude: -97.598481, zipCode: "78674"},
	{latitude: 30.434638, longitude: -98.707142, zipCode: "78675"},
	{latitude: 30.03875, longitude: -98.159591, zipCode: "78676"},
	{latitude: 29.347767, longitude: -97.59018, zipCode: "78677"},
	{latitude: 30.541758, longitude: -97.716638, zipCode: "78681"},
	{latitude: 30.270569, longitude: -97.742589, zipCode: "78701"},
	{latitude: 30.263378, longitude: -97.714483, zipCode: "78702"},
	{latitude: 30.293268, longitude: -97.76605, zipCode: "78703"},
	{latitude: 30.243032, longitude: -97.765081, zipCode: "78704"},
	{latitude: 30.294713, longitude: -97.738978, zipCode: "78705"},
	{latitude: 30.283916, longitude: -97.732937, zipCode: "78712"},
	{latitude: 30.489544, longitude: -97.753842, zipCode: "78717"},
	{latitude: 30.139718, longitude: -97.672615, zipCode: "78719"},
	{latitude: 30.271276, longitude: -97.683455, zipCode: "78721"},
	{latitude: 30.290085, longitude: -97.714582, zipCode: "78722"},
	{latitude: 30.304238, longitude: -97.68575, zipCode: "78723"},
	{latitude: 30.292709, longitude: -97.617945, zipCode: "78724"},
	{latitude: 30.230011, longitude: -97.608576, zipCode: "78725"},
	{latitude: 30.4308, longitude: -97.840085, zipCode: "78726"},
	{latitude: 30.428984, longitude: -97.717708, zipCode: "78727"},
	{latitude: 30.453202, longitude: -97.688674, zipCode: "78728"},
	{latitude: 30.457246, longitude: -97.756419, zipCode: "78729"},
	{latitude: 30.363577, longitude: -97.839838, zipCode: "78730"},
	{latitude: 30.348244, longitude: -97.768139, zipCode: "78731"},
	{latitude: 30.380104, longitude: -97.891857, zipCode: "78732"},
	{latitude: 30.321401, longitude: -97.884739, zipCode: "78733"},
	{latitude: 30.380059, longitude: -97.94781, zipCode: "78734"},
	{latitude: 30.269121, longitude: -97.868816, zipCode: "78735"},
	{latitude: 30.24836, longitude: -97.949745, zipCode: "78736"},
	{latitude: 30.177042, longitude: -97.956662, zipCode: "78737"},
	{latitude: 30.307015, longitude: -97.988911, zipCode: "78738"},
	{latitude: 30.175967, longitude: -97.890894, zipCode: "78739"},
	{latitude: 30.229681, longitude: -97.713687, zipCode: "78741"},
	{latitude: 30.242354, longitude: -97.6592, zipCode: "78742"},
	{latitude: 30.177791, longitude: -97.725147, zipCode: "78744"},
	{latitude: 30.207424, longitude: -97.798244, zipCode: "78745"},
	{latitude: 30.29686, longitude: -97.809566, zipCode: "78746"},
	{latitude: 30.124749, longitude: -97.741214, zipCode: "78747"},
	{latitude: 30.160024, longitude: -97.823302, zipCode: "78748"},
	{latitude: 30.216175, longitude: -97.85583, zipCode: "78749"},
	{latitude: 30.441084, longitude: -97.786667, zipCode: "78750"},
	{latitude: 30.310756, longitude: -97.722767, zipCode: "78751"},
	{latitude: 30.331428, longitude: -97.703643, zipCode: "78752"},
	{latitude: 30.383591, longitude: -97.673349, zipCode: "78753"},
	{latitude: 30.356711, longitude: -97.639281, zipCode: "78754"},
	{latitude: 30.322255, longitude: -97.740163, zipCode: "78756"},
	{latitude: 30.35167, longitude: -97.732666, zipCode: "78757"},
	{latitude: 30.387697, longitude: -97.706655, zipCode: "78758"},
	{latitude: 30.402705, longitude: -97.760938, zipCode: "78759"},
	{latitude: 29.362904, longitude: -99.898132, zipCode: "78801"},
	{latitude: 29.146243, longitude: -99.921055, zipCode: "78802"},
	{latitude: 28.430942, longitude: -99.723746, zipCode: "78827"},
	{latitude: 29.844256, longitude: -100.009166, zipCode: "78828"},
	{latitude: 28.890559, longitude: -99.583662, zipCode: "78829"},
	{latitude: 28.51949, longitude: -99.477126, zipCode: "78830"},
	{latitude: 29.409706, longitude: -100.391405, zipCode: "78832"},
	{latitude: 29.694926, longitude: -99.971776, zipCode: "78833"},
	{latitude: 28.544594, longitude: -99.868351, zipCode: "78834"},
	{latitude: 28.31626, longitude: -99.633712, zipCode: "78836"},
	{latitude: 29.930781, longitude: -101.284923, zipCode: "78837"},
	{latitude: 29.534647, longitude: -99.706512, zipCode: "78838"},
	{latitude: 28.714473, longitude: -99.739675, zipCode: "78839"},
	{latitude: 29.729784, longitude: -100.926008, zipCode: "78840"},
	{latitude: 29.843444, longitude: -101.473569, zipCode: "78841"},
	{latitude: 29.639773, longitude: -101.005136, zipCode: "78842"},
	{latitude: 29.343574, longitude: -100.78914, zipCode: "78843"},
	{latitude: 29.285285, longitude: -99.329182, zipCode: "78850"},
	{latitude: 30.21642, longitude: -101.92463, zipCode: "78851"},
	{latitude: 28.943749, longitude: -100.227139, zipCode: "78852"},
	{latitude: 28.401006, longitude: -100.230598, zipCode: "78860"},
	{latitude: 29.414591, longitude: -99.155756, zipCode: "78861"},
	{latitude: 29.273522, longitude: -99.608011, zipCode: "78870"},
	{latitude: 29.900867, longitude: -101.620307, zipCode: "78871"},
	{latitude: 28.907223, longitude: -99.881037, zipCode: "78872"},
	{latitude: 29.815376, longitude: -99.727302, zipCode: "78873"},
	{latitude: 28.953437, longitude: -100.60675, zipCode: "78877"},
	{latitude: 29.647104, longitude: -99.711307, zipCode: "78879"},
	{latitude: 29.951024, longitude: -100.275549, zipCode: "78880"},
	{latitude: 29.382382, longitude: -99.496031, zipCode: "78881"},
	{latitude: 29.673791, longitude: -99.317226, zipCode: "78883"},
	{latitude: 29.636597, longitude: -99.506534, zipCode: "78884"},
	{latitude: 29.835326, longitude: -99.557796, zipCode: "78885"},
	{latitude: 29.155307, longitude: -99.18621, zipCode: "78886"},
	{latitude: 30.038364, longitude: -96.44144, zipCode: "78931"},
	{latitude: 30.164115, longitude: -96.718195, zipCode: "78932"},
	{latitude: 29.801467, longitude: -96.368317, zipCode: "78933"},
	{latitude: 29.702699, longitude: -96.567123, zipCode: "78934"},
	{latitude: 29.695493, longitude: -96.424208, zipCode: "78935"},
	{latitude: 29.813771, longitude: -96.708409, zipCode: "78938"},
	{latitude: 29.940125, longitude: -96.658827, zipCode: "78940"},
	{latitude: 29.727617, longitude: -97.15845, zipCode: "78941"},
	{latitude: 30.155484, longitude: -96.938267, zipCode: "78942"},
	{latitude: 29.698796, longitude: -96.595534, zipCode: "78943"},
	{latitude: 30.001358, longitude: -96.486785, zipCode: "78944"},
	{latitude: 29.916659, longitude: -96.888985, zipCode: "78945"},
	{latitude: 30.18837, longitude: -96.779058, zipCode: "78946"},
	{latitude: 30.443442, longitude: -97.063655, zipCode: "78947"},
	{latitude: 30.321498, longitude: -96.976823, zipCode: "78948"},
	{latitude: 29.847787, longitude: -97.08019, zipCode: "78949"},
	{latitude: 29.904948, longitude: -96.482617, zipCode: "78950"},
	{latitude: 29.620516, longitude: -96.836597, zipCode: "78951"},
	{latitude: 29.844028, longitude: -97.358297, zipCode: "78953"},
	{latitude: 30.043016, longitude: -96.699188, zipCode: "78954"},
	{latitude: 29.686382, longitude: -96.933775, zipCode: "78956"},
	{latitude: 30.013881, longitude: -97.182098, zipCode: "78957"},
	{latitude: 29.700745, longitude: -97.301328, zipCode: "78959"},
	{latitude: 29.674439, longitude: -96.7345, zipCode: "78962"},
	{latitude: 29.91692, longitude: -97.028763, zipCode: "78963"},
	{latitude: 35.290798, longitude: -102.805404, zipCode: "79001"},
	{latitude: 35.204414, longitude: -100.770299, zipCode: "79002"},
	{latitude: 35.639808, longitude: -100.082662, zipCode: "79003"},
	{latitude: 36.386253, longitude: -100.516052, zipCode: "79005"},
	{latitude: 35.668323, longitude: -101.412247, zipCode: "79007"},
	{latitude: 35.698383, longitude: -101.423838, zipCode: "79008"},
	{latitude: 34.529103, longitude: -102.883363, zipCode: "79009"},
	{latitude: 35.451665, longitude: -102.140733, zipCode: "79010"},
	{latitude: 35.63542, longitude: -100.20705, zipCode: "79011"},
	{latitude: 35.117246, longitude: -102.107254, zipCode: "79012"},
	{latitude: 36.040954, longitude: -101.996154, zipCode: "79013"},
	{latitude: 35.923984, longitude: -100.332663, zipCode: "79014"},
	{latitude: 34.947109, longitude: -101.900712, zipCode: "79015"},
	{latitude: 34.983383, longitude: -101.914804, zipCode: "79016"},
	{latitude: 35.705732, longitude: -102.343965, zipCode: "79018"},
	{latitude: 34.979959, longitude: -101.388558, zipCode: "79019"},
	{latitude: 33.983664, longitude: -101.993014, zipCode: "79021"},
	{latitude: 36.098125, longitude: -102.60691, zipCode: "79022"},
	{latitude: 36.439773, longitude: -100.369084, zipCode: "79024"},
	{latitude: 34.949401, longitude: -102.201165, zipCode: "79025"},
	{latitude: 34.497573, longitude: -102.337979, zipCode: "79027"},
	{latitude: 35.901966, longitude: -102.025897, zipCode: "79029"},
	{latitude: 34.244322, longitude: -102.449905, zipCode: "79031"},
	{latitude: 34.287801, longitude: -101.894919, zipCode: "79032"},
	{latitude: 36.306844, longitude: -100.976612, zipCode: "79033"},
	{latitude: 36.380058, longitude: -100.164764, zipCode: "79034"},
	{latitude: 34.68165, longitude: -102.781898, zipCode: "79035"},
	{latitude: 35.59276, longitude: -101.544363, zipCode: "79036"},
	{latitude: 35.239266, longitude: -101.132823, zipCode: "79039"},
	{latitude: 36.29648, longitude: -101.58718, zipCode: "79040"},
	{latitude: 34.043255, longitude: -101.931966, zipCode: "79041"},
	{latitude: 34.741342, longitude: -101.893567, zipCode: "79042"},
	{latitude: 34.368347, longitude: -102.134819, zipCode: "79043"},
	{latitude: 35.863315, longitude: -102.367488, zipCode: "79044"},
	{latitude: 34.899855, longitude: -102.514844, zipCode: "79045"},
	{latitude: 36.13903, longitude: -100.107146, zipCode: "79046"},
	{latitude: 36.444349, longitude: -102.224098, zipCode: "79051"},
	{latitude: 34.358439, longitude: -101.764658, zipCode: "79052"},
	{latitude: 34.408017, longitude: -102.579402, zipCode: "79053"},
	{latitude: 35.429618, longitude: -100.783905, zipCode: "79054"},
	{latitude: 36.217254, longitude: -100.221361, zipCode: "79056"},
	{latitude: 35.198478, longitude: -100.621274, zipCode: "79057"},
	{latitude: 35.594731, longitude: -101.951347, zipCode: "79058"},
	{latitude: 35.799469, longitude: -100.73389, zipCode: "79059"},
	{latitude: 35.524283, longitude: -100.452264, zipCode: "79061"},
	{latitude: 35.988501, longitude: -101.530407, zipCode: "79062"},
	{latitude: 34.517009, longitude: -102.115148, zipCode: "79063"},
	{latitude: 34.180943, longitude: -102.119262, zipCode: "79064"},
	{latitude: 35.51732, longitude: -100.899954, zipCode: "79065"},
	{latitude: 35.499266, longitude: -101.03425, zipCode: "79066"},
	{latitude: 35.3285, longitude: -101.444138, zipCode: "79068"},
	{latitude: 36.236595, longitude: -100.786511, zipCode: "79070"},
	{latitude: 34.171907, longitude: -101.744828, zipCode: "79072"},
	{latitude: 33.956084, longitude: -101.949248, zipCode: "79073"},
	{latitude: 35.698705, longitude: -101.550344, zipCode: "79078"},
	{latitude: 35.206557, longitude: -100.248529, zipCode: "79079"},
	{latitude: 35.701544, longitude: -101.212706, zipCode: "79080"},
	{latitude: 36.218996, longitude: -101.204732, zipCode: "79081"},
	{latitude: 34.218791, longitude: -102.286186, zipCode: "79082"},
	{latitude: 35.869815, longitude: -101.512425, zipCode: "79083"},
	{latitude: 36.259687, longitude: -102.02658, zipCode: "79084"},
	{latitude: 34.739899, longitude: -102.509167, zipCode: "79085"},
	{latitude: 36.125218, longitude: -101.784188, zipCode: "79086"},
	{latitude: 36.320843, longitude: -102.917155, zipCode: "79087"},
	{latitude: 34.575464, longitude: -101.691958, zipCode: "79088"},
	{latitude: 34.870604, longitude: -102.100172, zipCode: "79091"},
	{latitude: 35.313659, longitude: -102.510275, zipCode: "79092"},
	{latitude: 36.266369, longitude: -101.029969, zipCode: "79093"},
	{latitude: 34.840077, longitude: -101.5097, zipCode: "79094"},
	{latitude: 34.884163, longitude: -100.196165, zipCode: "79095"},
	{latitude: 35.458033, longitude: -100.199727, zipCode: "79096"},
	{latitude: 35.430575, longitude: -101.173894, zipCode: "79097"},
	{latitude: 35.152354, longitude: -102.215756, zipCode: "79098"},
	{latitude: 35.206072, longitude: -101.8395, zipCode: "79101"},
	{latitude: 35.201263, longitude: -101.849182, zipCode: "79102"},
	{latitude: 35.18215, longitude: -101.800373, zipCode: "79103"},
	{latitude: 35.201147, longitude: -101.789954, zipCode: "79104"},
	{latitude: 35.203051, longitude: -101.891662, zipCode: "79106"},
	{latitude: 35.231515, longitude: -101.794239, zipCode: "79107"},
	{latitude: 35.301258, longitude: -101.675725, zipCode: "79108"},
	{latitude: 35.166416, longitude: -101.886423, zipCode: "79109"},
	{latitude: 35.150993, longitude: -101.869766, zipCode: "79110"},
	{latitude: 35.220443, longitude: -101.708078, zipCode: "79111"},
	{latitude: 35.106367, longitude: -101.739911, zipCode: "79118"},
	{latitude: 35.109084, longitude: -102.017887, zipCode: "79119"},
	{latitude: 35.174461, longitude: -101.929219, zipCode: "79121"},
	{latitude: 35.259324, longitude: -102.047444, zipCode: "79124"},
	{latitude: 34.404718, longitude: -100.341146, zipCode: "79201"},
	{latitude: 33.766958, longitude: -100.764319, zipCode: "79220"},
	{latitude: 34.231669, longitude: -100.480873, zipCode: "79223"},
	{latitude: 34.249634, longitude: -99.507466, zipCode: "79225"},
	{latitude: 34.918336, longitude: -100.997087, zipCode: "79226"},
	{latitude: 33.871603, longitude: -99.747306, zipCode: "79227"},
	{latitude: 33.666927, longitude: -100.744262, zipCode: "79229"},
	{latitude: 34.710122, longitude: -100.053583, zipCode: "79230"},
	{latitude: 33.941637, longitude: -101.065843, zipCode: "79231"},
	{latitude: 34.498476, longitude: -100.494137, zipCode: "79233"},
	{latitude: 34.216998, longitude: -100.926434, zipCode: "79234"},
	{latitude: 33.942142, longitude: -101.26349, zipCode: "79235"},
	{latitude: 33.603167, longitude: -100.29976, zipCode: "79236"},
	{latitude: 34.857952, longitude: -100.673364, zipCode: "79237"},
	{latitude: 34.655623, longitude: -100.763893, zipCode: "79239"},
	{latitude: 34.883937, longitude: -100.760664, zipCode: "79240"},
	{latitude: 34.179633, longitude: -101.387813, zipCode: "79241"},
	{latitude: 33.767731, longitude: -101.025326, zipCode: "79243"},
	{latitude: 34.057899, longitude: -100.793152, zipCode: "79244"},
	{latitude: 34.680965, longitude: -100.476496, zipCode: "79245"},
	{latitude: 34.396883, longitude: -99.380703, zipCode: "79247"},
	{latitude: 33.943094, longitude: -100.22369, zipCode: "79248"},
	{latitude: 33.894281, longitude: -101.598542, zipCode: "79250"},
	{latitude: 34.943421, longitude: -100.451566, zipCode: "79251"},
	{latitude: 34.306674, longitude: -99.797428, zipCode: "79252"},
	{latitude: 34.349913, longitude: -101.050762, zipCode: "79255"},
	{latitude: 33.881975, longitude: -100.83115, zipCode: "79256"},
	{latitude: 34.446931, longitude: -101.298874, zipCode: "79257"},
	{latitude: 34.243411, longitude: -101.296434, zipCode: "79258"},
	{latitude: 34.358303, longitude: -100.454434, zipCode: "79259"},
	{latitude: 34.449799, longitude: -100.872884, zipCode: "79261"},
	{latitude: 33.857882, longitude: -101.881538, zipCode: "79311"},
	{latitude: 34.022954, longitude: -102.307259, zipCode: "79312"},
	{latitude: 33.812561, longitude: -102.16092, zipCode: "79313"},
	{latitude: 33.630178, longitude: -103.03047, zipCode: "79314"},
	{latitude: 33.134142, longitude: -102.3048, zipCode: "79316"},
	{latitude: 33.661255, longitude: -101.209231, zipCode: "79322"},
	{latitude: 32.933054, longitude: -102.904556, zipCode: "79323"},
	{latitude: 33.887607, longitude: -102.735385, zipCode: "79324"},
	{latitude: 34.414294, longitude: -102.934273, zipCode: "79325"},
	{latitude: 34.099124, longitude: -102.214321, zipCode: "79326"},
	{latitude: 33.716447, longitude: -101.691654, zipCode: "79329"},
	{latitude: 33.018178, longitude: -101.262086, zipCode: "79330"},
	{latitude: 32.671095, longitude: -101.972672, zipCode: "79331"},
	{latitude: 33.59973, longitude: -102.40023, zipCode: "79336"},
	{latitude: 33.886628, longitude: -102.354854, zipCode: "79339"},
	{latitude: 32.903137, longitude: -102.331883, zipCode: "79342"},
	{latitude: 33.645727, longitude: -101.552578, zipCode: "79343"},
	{latitude: 33.856787, longitude: -103.014315, zipCode: "79344"},
	{latitude: 33.337542, longitude: -102.33135, zipCode: "79345"},
	{latitude: 33.645304, longitude: -102.825853, zipCode: "79346"},
	{latitude: 34.18618, longitude: -102.797922, zipCode: "79347"},
	{latitude: 33.731452, longitude: -101.825896, zipCode: "79350"},
	{latitude: 32.938226, longitude: -101.777029, zipCode: "79351"},
	{latitude: 33.783578, longitude: -102.591413, zipCode: "79353"},
	{latitude: 33.166024, longitude: -102.894785, zipCode: "79355"},
	{latitude: 33.28459, longitude: -101.342082, zipCode: "79356"},
	{latitude: 33.646471, longitude: -101.405761, zipCode: "79357"},
	{latitude: 33.421975, longitude: -102.190949, zipCode: "79358"},
	{latitude: 32.923636, longitude: -102.544132, zipCode: "79359"},
	{latitude: 32.689865, longitude: -102.724434, zipCode: "79360"},
	{latitude: 33.707731, longitude: -102.045021, zipCode: "79363"},
	{latitude: 33.445487, longitude: -101.63203, zipCode: "79364"},
	{latitude: 33.530315, longitude: -101.685343, zipCode: "79366"},
	{latitude: 33.589372, longitude: -102.168049, zipCode: "79367"},
	{latitude: 33.943198, longitude: -102.159111, zipCode: "79369"},
	{latitude: 33.444643, longitude: -100.929803, zipCode: "79370"},
	{latitude: 33.979698, longitude: -102.69717, zipCode: "79371"},
	{latitude: 33.424804, longitude: -102.501112, zipCode: "79372"},
	{latitude: 33.158706, longitude: -101.839339, zipCode: "79373"},
	{latitude: 33.247697, longitude: -102.637249, zipCode: "79376"},
	{latitude: 32.929648, longitude: -102.133506, zipCode: "79377"},
	{latitude: 33.02766, longitude: -102.446749, zipCode: "79378"},
	{latitude: 33.495033, longitude: -102.653489, zipCode: "79379"},
	{latitude: 33.739154, longitude: -102.327823, zipCode: "79380"},
	{latitude: 33.312912, longitude: -101.770523, zipCode: "79381"},
	{latitude: 33.468919, longitude: -102.01762, zipCode: "79382"},
	{latitude: 33.32435, longitude: -101.90802, zipCode: "79383"},
	{latitude: 33.586823, longitude: -101.851832, zipCode: "79401"},
	{latitude: 33.581238, longitude: -101.842013, zipCode: "79402"},
	{latitude: 33.643534, longitude: -101.758167, zipCode: "79403"},
	{latitude: 33.527702, longitude: -101.793717, zipCode: "79404"},
	{latitude: 33.585914, longitude: -101.878393, zipCode: "79406"},
	{latitude: 33.56146, longitude: -102.085741, zipCode: "79407"},
	{latitude: 33.570071, longitude: -101.891011, zipCode: "79410"},
	{latitude: 33.570115, longitude: -101.857732, zipCode: "79411"},
	{latitude: 33.546383, longitude: -101.856516, zipCode: "79412"},
	{latitude: 33.545748, longitude: -101.889936, zipCode: "79413"},
	{latitude: 33.547794, longitude: -101.92023, zipCode: "79414"},
	{latitude: 33.700795, longitude: -101.896988, zipCode: "79415"},
	{latitude: 33.600114, longitude: -101.982775, zipCode: "79416"},
	{latitude: 33.439985, longitude: -101.855477, zipCode: "79423"},
	{latitude: 33.465175, longitude: -101.935271, zipCode: "79424"},
	{latitude: 32.740591, longitude: -99.890392, zipCode: "79501"},
	{latitude: 33.182154, longitude: -100.263878, zipCode: "79502"},
	{latitude: 32.887469, longitude: -99.696382, zipCode: "79503"},
	{latitude: 32.310489, longitude: -99.353711, zipCode: "79504"},
	{latitude: 33.55955, longitude: -99.844136, zipCode: "79505"},
	{latitude: 32.117436, longitude: -100.330036, zipCode: "79506"},
	{latitude: 32.269967, longitude: -99.830323, zipCode: "79508"},
	{latitude: 32.266413, longitude: -99.518894, zipCode: "79510"},
	{latitude: 32.434136, longitude: -101.26903, zipCode: "79511"},
	{latitude: 32.323175, longitude: -100.931991, zipCode: "79512"},
	{latitude: 32.879511, longitude: -101.246784, zipCode: "79517"},
	{latitude: 33.30818, longitude: -100.745809, zipCode: "79518"},
	{latitude: 32.044159, longitude: -99.700329, zipCode: "79519"},
	{latitude: 32.876994, longitude: -100.128483, zipCode: "79520"},
	{latitude: 33.120371, longitude: -99.631772, zipCode: "79521"},
	{latitude: 32.660278, longitude: -99.826967, zipCode: "79525"},
	{latitude: 32.650421, longitude: -100.756399, zipCode: "79526"},
	{latitude: 32.595492, longitude: -101.099466, zipCode: "79527"},
	{latitude: 33.164602, longitude: -100.578188, zipCode: "79528"},
	{latitude: 33.435018, longitude: -99.8295, zipCode: "79529"},
	{latitude: 32.103908, longitude: -99.779744, zipCode: "79530"},
	{latitude: 32.408016, longitude: -100.714264, zipCode: "79532"},
	{latitude: 32.848659, longitude: -99.547268, zipCode: "79533"},
	{latitude: 32.753255, longitude: -100.224591, zipCode: "79534"},
	{latitude: 32.223438, longitude: -100.479835, zipCode: "79535"},
	{latitude: 32.474124, longitude: -100.024523, zipCode: "79536"},
	{latitude: 32.27586, longitude: -100.219861, zipCode: "79537"},
	{latitude: 31.976237, longitude: -99.702377, zipCode: "79538"},
	{latitude: 33.359951, longitude: -99.889262, zipCode: "79539"},
	{latitude: 33.150657, longitude: -100.07114, zipCode: "79540"},
	{latitude: 32.147788, longitude: -99.810223, zipCode: "79541"},
	{latitude: 32.720841, longitude: -100.380112, zipCode: "79543"},
	{latitude: 33.293231, longitude: -99.858473, zipCode: "79544"},
	{latitude: 32.415212, longitude: -100.573539, zipCode: "79545"},
	{latitude: 32.880122, longitude: -100.488267, zipCode: "79546"},
	{latitude: 33.197744, longitude: -99.905443, zipCode: "79547"},
	{latitude: 33.071167, longitude: -99.912835, zipCode: "79548"},
	{latitude: 32.89857, longitude: -100.93202, zipCode: "79549"},
	{latitude: 32.940917, longitude: -99.850965, zipCode: "79553"},
	{latitude: 32.485191, longitude: -100.392114, zipCode: "79556"},
	{latitude: 32.665235, longitude: -100.213046, zipCode: "79560"},
	{latitude: 32.512808, longitude: -100.176641, zipCode: "79561"},
	{latitude: 32.236251, longitude: -99.866451, zipCode: "79562"},
	{latitude: 32.415823, longitude: -99.90623, zipCode: "79563"},
	{latitude: 32.378574, longitude: -101.102996, zipCode: "79565"},
	{latitude: 32.156601, longitude: -100.111723, zipCode: "79566"},
	{latitude: 31.988595, longitude: -99.97707, zipCode: "79567"},
	{latitude: 32.572842, longitude: -99.642121, zipCode: "79601"},
	{latitude: 32.335677, longitude: -99.667488, zipCode: "79602"},
	{latitude: 32.333551, longitude: -99.927724, zipCode: "79603"},
	{latitude: 32.434599, longitude: -99.781827, zipCode: "79605"},
	{latitude: 32.362767, longitude: -99.817334, zipCode: "79606"},
	{latitude: 32.423188, longitude: -99.838681, zipCode: "79607"},
	{latitude: 32.46219, longitude: -99.714942, zipCode: "79699"},
	{latitude: 31.992446, longitude: -102.080985, zipCode: "79701"},
	{latitude: 31.98001, longitude: -102.131551, zipCode: "79703"},
	{latitude: 32.104144, longitude: -102.059752, zipCode: "79705"},
	{latitude: 31.821861, longitude: -101.954609, zipCode: "79706"},
	{latitude: 32.037424, longitude: -102.195228, zipCode: "79707"},
	{latitude: 32.496157, longitude: -101.747536, zipCode: "79713"},
	{latitude: 32.312258, longitude: -102.640206, zipCode: "79714"},
	{latitude: 30.99421, longitude: -103.734733, zipCode: "79718"},
	{latitude: 31.460655, longitude: -103.404137, zipCode: "79719"},
	{latitude: 32.199486, longitude: -101.504783, zipCode: "79720"},
	{latitude: 32.224895, longitude: -101.527363, zipCode: "79721"},
	{latitude: 31.192539, longitude: -103.052043, zipCode: "79730"},
	{latitude: 31.435696, longitude: -102.581722, zipCode: "79731"},
	{latitude: 32.110338, longitude: -101.366796, zipCode: "79733"},
	{latitude: 30.68216, longitude: -103.980976, zipCode: "79734"},
	{latitude: 30.786235, longitude: -102.851189, zipCode: "79735"},
	{latitude: 32.751934, longitude: -101.474837, zipCode: "79738"},
	{latitude: 31.769325, longitude: -101.506391, zipCode: "79739"},
	{latitude: 32.007678, longitude: -102.647611, zipCode: "79741"},
	{latitude: 31.360283, longitude: -102.852658, zipCode: "79742"},
	{latitude: 31.236626, longitude: -102.789065, zipCode: "79743"},
	{latitude: 30.890855, longitude: -101.912948, zipCode: "79744"},
	{latitude: 31.832862, longitude: -103.054925, zipCode: "79745"},
	{latitude: 32.389997, longitude: -101.663975, zipCode: "79748"},
	{latitude: 32.255488, longitude: -101.834148, zipCode: "79749"},
	{latitude: 31.113084, longitude: -102.191259, zipCode: "79752"},
	{latitude: 31.820092, longitude: -103.488035, zipCode: "79754"},
	{latitude: 31.525261, longitude: -101.997544, zipCode: "79755"},
	{latitude: 31.534766, longitude: -102.877408, zipCode: "79756"},
	{latitude: 32.013098, longitude: -102.327298, zipCode: "79758"},
	{latitude: 31.799217, longitude: -102.633009, zipCode: "79759"},
	{latitude: 31.855583, longitude: -102.348437, zipCode: "79761"},
	{latitude: 31.925286, longitude: -102.357886, zipCode: "79762"},
	{latitude: 31.81292, longitude: -102.454612, zipCode: "79763"},
	{latitude: 31.876762, longitude: -102.473341, zipCode: "79764"},
	{latitude: 31.896642, longitude: -102.262922, zipCode: "79765"},
	{latitude: 31.71573, longitude: -102.375828, zipCode: "79766"},
	{latitude: 31.682772, longitude: -102.625287, zipCode: "79769"},
	{latitude: 31.854982, longitude: -103.912233, zipCode: "79770"},
	{latitude: 31.400312, longitude: -103.584082, zipCode: "79772"},
	{latitude: 31.77515, longitude: -102.555724, zipCode: "79776"},
	{latitude: 31.484586, longitude: -103.161046, zipCode: "79777"},
	{latitude: 31.258503, longitude: -101.973541, zipCode: "79778"},
	{latitude: 31.039416, longitude: -103.65751, zipCode: "79780"},
	{latitude: 30.61472, longitude: -101.76562, zipCode: "79781"},
	{latitude: 32.189391, longitude: -101.882857, zipCode: "79782"},
	{latitude: 32.332045, longitude: -102.050176, zipCode: "79783"},
	{latitude: 31.321361, longitude: -103.940052, zipCode: "79785"},
	{latitude: 31.603154, longitude: -102.983513, zipCode: "79788"},
	{latitude: 31.774778, longitude: -103.270419, zipCode: "79789"},
	{latitude: 31.976815, longitude: -106.599316, zipCode: "79821"},
	{latitude: 30.211507, longitude: -103.626765, zipCode: "79830"},
	{latitude: 30.36324, longitude: -103.649247, zipCode: "79832"},
	{latitude: 29.182737, longitude: -103.262462, zipCode: "79834"},
	{latitude: 31.935653, longitude: -106.596089, zipCode: "79835"},
	{latitude: 31.57011, longitude: -106.211608, zipCode: "79836"},
	{latitude: 32.095261, longitude: -105.162407, zipCode: "79837"},
	{latitude: 31.486292, longitude: -106.16111, zipCode: "79838"},
	{latitude: 31.42078, longitude: -105.64224, zipCode: "79839"},
	{latitude: 30.013027, longitude: -103.027525, zipCode: "79842"},
	{latitude: 29.869824, longitude: -104.412332, zipCode: "79843"},
	{latitude: 29.653344, longitude: -104.397247, zipCode: "79845"},
	{latitude: 29.387969, longitude: -103.990395, zipCode: "79846"},
	{latitude: 31.747031, longitude: -105.175314, zipCode: "79847"},
	{latitude: 30.11968, longitude: -102.397964, zipCode: "79848"},
	{latitude: 31.56691, longitude: -106.258375, zipCode: "79849"},
	{latitude: 31.279537, longitude: -105.257914, zipCode: "79851"},
	{latitude: 29.364936, longitude: -103.615479, zipCode: "79852"},
	{latitude: 31.434058, longitude: -106.063238, zipCode: "79853"},
	{latitude: 30.625445, longitude: -104.61006, zipCode: "79854"},
	{latitude: 30.930866, longitude: -104.860712, zipCode: "79855"},
	{latitude: 31.759397, longitude: -106.480128, zipCode: "79901"},
	{latitude: 31.784855, longitude: -106.496881, zipCode: "79902"},
	{latitude: 31.786231, longitude: -106.441911, zipCode: "79903"},
	{latitude: 31.849199, longitude: -106.464792, zipCode: "79904"},
	{latitude: 31.766054, longitude: -106.426202, zipCode: "79905"},
	{latitude: 31.809024, longitude: -106.419547, zipCode: "79906"},
	{latitude: 31.707231, longitude: -106.326563, zipCode: "79907"},
	{latitude: 31.833114, longitude: -106.364975, zipCode: "79908"},
	{latitude: 31.936069, longitude: -106.535685, zipCode: "79911"},
	{latitude: 31.848583, longitude: -106.534316, zipCode: "79912"},
	{latitude: 31.746041, longitude: -106.371426, zipCode: "79915"},
	{latitude: 31.866612, longitude: -106.387217, zipCode: "79916"},
	{latitude: 31.846889, longitude: -106.36942, zipCode: "79918"},
	{latitude: 31.822773, longitude: -106.459822, zipCode: "79920"},
	{latitude: 31.810369, longitude: -106.555964, zipCode: "79922"},
	{latitude: 31.902236, longitude: -106.413548, zipCode: "79924"},
	{latitude: 31.805328, longitude: -106.368845, zipCode: "79925"},
	{latitude: 31.645424, longitude: -106.274864, zipCode: "79927"},
	{latitude: 31.678635, longitude: -106.115148, zipCode: "79928"},
	{latitude: 31.810423, longitude: -106.468958, zipCode: "79930"},
	{latitude: 31.874971, longitude: -106.607981, zipCode: "79932"},
	{latitude: 31.950669, longitude: -106.433518, zipCode: "79934"},
	{latitude: 31.768054, longitude: -106.330277, zipCode: "79935"},
	{latitude: 31.773676, longitude: -106.295415, zipCode: "79936"},
	{latitude: 31.845514, longitude: -106.019659, zipCode: "79938"},
	{latitude: 39.793716, longitude: -105.108382, zipCode: "80002"},
	{latitude: 39.825413, longitude: -105.064435, zipCode: "80003"},
	{latitude: 39.81378, longitude: -105.122182, zipCode: "80004"},
	{latitude: 39.85165, longitude: -105.131418, zipCode: "80005"},
	{latitude: 39.867038, longitude: -105.197733, zipCode: "80007"},
	{latitude: 39.73925, longitude: -104.86171, zipCode: "80010"},
	{latitude: 39.744033, longitude: -104.790013, zipCode: "80011"},
	{latitude: 39.699766, longitude: -104.837834, zipCode: "80012"},
	{latitude: 39.661462, longitude: -104.765764, zipCode: "80013"},
	{latitude: 39.663552, longitude: -104.837771, zipCode: "80014"},
	{latitude: 39.628055, longitude: -104.793254, zipCode: "80015"},
	{latitude: 39.593026, longitude: -104.7224, zipCode: "80016"},
	{latitude: 39.699437, longitude: -104.775296, zipCode: "80017"},
	{latitude: 39.7019, longitude: -104.686962, zipCode: "80018"},
	{latitude: 39.778586, longitude: -104.684754, zipCode: "80019"},
	{latitude: 39.930782, longitude: -105.074287, zipCode: "80020"},
	{latitude: 39.890663, longitude: -105.113724, zipCode: "80021"},
	{latitude: 39.861115, longitude: -104.788777, zipCode: "80022"},
	{latitude: 39.975844, longitude: -105.009381, zipCode: "80023"},
	{latitude: 39.844106, longitude: -104.918414, zipCode: "80024"},
	{latitude: 39.925508, longitude: -105.299486, zipCode: "80025"},
	{latitude: 40.012151, longitude: -105.09991, zipCode: "80026"},
	{latitude: 39.951506, longitude: -105.15813, zipCode: "80027"},
	{latitude: 39.830822, longitude: -105.03747, zipCode: "80030"},
	{latitude: 39.873936, longitude: -105.038792, zipCode: "80031"},
	{latitude: 39.772675, longitude: -105.104801, zipCode: "80033"},
	{latitude: 39.746661, longitude: -104.837224, zipCode: "80045"},
	{latitude: 39.406936, longitude: -103.944435, zipCode: "80101"},
	{latitude: 39.734017, longitude: -104.438524, zipCode: "80102"},
	{latitude: 39.7912, longitude: -104.093839, zipCode: "80103"},
	{latitude: 39.302112, longitude: -104.819674, zipCode: "80104"},
	{latitude: 39.636719, longitude: -103.966232, zipCode: "80105"},
	{latitude: 39.186147, longitude: -104.515915, zipCode: "80106"},
	{latitude: 39.411894, longitude: -104.572151, zipCode: "80107"},
	{latitude: 39.444376, longitude: -104.853179, zipCode: "80108"},
	{latitude: 39.372344, longitude: -104.912085, zipCode: "80109"},
	{latitude: 39.645936, longitude: -105.011368, zipCode: "80110"},
	{latitude: 39.612004, longitude: -104.882787, zipCode: "80111"},
	{latitude: 39.573236, longitude: -104.86489, zipCode: "80112"},
	{latitude: 39.644445, longitude: -104.965111, zipCode: "80113"},
	{latitude: 39.320019, longitude: -104.709712, zipCode: "80116"},
	{latitude: 39.395522, longitude: -104.389585, zipCode: "80117"},
	{latitude: 39.18964, longitude: -104.881926, zipCode: "80118"},
	{latitude: 39.593836, longitude: -105.01146, zipCode: "80120"},
	{latitude: 39.610385, longitude: -104.95381, zipCode: "80121"},
	{latitude: 39.580309, longitude: -104.954917, zipCode: "80122"},
	{latitude: 39.61601, longitude: -105.069449, zipCode: "80123"},
	{latitude: 39.532798, longitude: -104.891219, zipCode: "80124"},
	{latitude: 39.485382, longitude: -105.062779, zipCode: "80125"},
	{latitude: 39.542161, longitude: -104.963124, zipCode: "80126"},
	{latitude: 39.530726, longitude: -105.164344, zipCode: "80127"},
	{latitude: 39.560922, longitude: -105.079925, zipCode: "80128"},
	{latitude: 39.546879, longitude: -105.011599, zipCode: "80129"},
	{latitude: 39.528342, longitude: -104.923869, zipCode: "80130"},
	{latitude: 39.476265, longitude: -105.00904, zipCode: "80131"},
	{latitude: 39.086552, longitude: -104.85834, zipCode: "80132"},
	{latitude: 39.113465, longitude: -104.899806, zipCode: "80133"},
	{latitude: 39.482825, longitude: -104.77999, zipCode: "80134"},
	{latitude: 39.254744, longitude: -105.156973, zipCode: "80135"},
	{latitude: 39.791978, longitude: -104.279929, zipCode: "80136"},
	{latitude: 39.712176, longitude: -104.594097, zipCode: "80137"},
	{latitude: 39.517532, longitude: -104.670911, zipCode: "80138"},
	{latitude: 39.751526, longitude: -104.997673, zipCode: "80202"},
	{latitude: 39.731419, longitude: -104.982764, zipCode: "80203"},
	{latitude: 39.734757, longitude: -105.020678, zipCode: "80204"},
	{latitude: 39.758861, longitude: -104.964699, zipCode: "80205"},
	{latitude: 39.730369, longitude: -104.952511, zipCode: "80206"},
	{latitude: 39.761385, longitude: -104.916696, zipCode: "80207"},
	{latitude: 39.706581, longitude: -104.96575, zipCode: "80209"},
	{latitude: 39.676626, longitude: -104.962315, zipCode: "80210"},
	{latitude: 39.767444, longitude: -105.019736, zipCode: "80211"},
	{latitude: 39.772047, longitude: -105.048027, zipCode: "80212"},
	{latitude: 39.743312, longitude: -105.069025, zipCode: "80214"},
	{latitude: 39.744773, longitude: -105.115977, zipCode: "80215"},
	{latitude: 39.787707, longitude: -104.961139, zipCode: "80216"},
	{latitude: 39.731168, longitude: -104.970715, zipCode: "80218"},
	{latitude: 39.695293, longitude: -105.035601, zipCode: "80219"},
	{latitude: 39.733782, longitude: -104.916465, zipCode: "80220"},
	{latitude: 39.81602, longitude: -105.011552, zipCode: "80221"},
	{latitude: 39.671314, longitude: -104.92823, zipCode: "80222"},
	{latitude: 39.695794, longitude: -105.003843, zipCode: "80223"},
	{latitude: 39.688065, longitude: -104.911575, zipCode: "80224"},
	{latitude: 39.710131, longitude: -105.092127, zipCode: "80226"},
	{latitude: 39.665458, longitude: -105.102196, zipCode: "80227"},
	{latitude: 39.694935, longitude: -105.173187, zipCode: "80228"},
	{latitude: 39.85425, longitude: -104.957598, zipCode: "80229"},
	{latitude: 39.720338, longitude: -104.889308, zipCode: "80230"},
	{latitude: 39.671225, longitude: -104.887861, zipCode: "80231"},
	{latitude: 39.688198, longitude: -105.089484, zipCode: "80232"},
	{latitude: 39.897536, longitude: -104.943922, zipCode: "80233"},
	{latitude: 39.909992, longitude: -105.002643, zipCode: "80234"},
	{latitude: 39.647607, longitude: -105.089482, zipCode: "80235"},
	{latitude: 39.651633, longitude: -105.039699, zipCode: "80236"},
	{latitude: 39.639871, longitude: -104.901711, zipCode: "80237"},
	{latitude: 39.77741, longitude: -104.879652, zipCode: "80238"},
	{latitude: 39.789197, longitude: -104.833616, zipCode: "80239"},
	{latitude: 39.929079, longitude: -104.954277, zipCode: "80241"},
	{latitude: 39.705173, longitude: -104.930718, zipCode: "80246"},
	{latitude: 39.697809, longitude: -104.878897, zipCode: "80247"},
	{latitude: 39.854746, longitude: -104.695804, zipCode: "80249"},
	{latitude: 39.866989, longitude: -105.001354, zipCode: "80260"},
	{latitude: 39.742477, longitude: -104.985482, zipCode: "80264"},
	{latitude: 39.790007, longitude: -104.900321, zipCode: "80266"},
	{latitude: 39.744092, longitude: -104.986754, zipCode: "80290"},
	{latitude: 39.746079, longitude: -104.98972, zipCode: "80293"},
	{latitude: 39.749794, longitude: -104.989712, zipCode: "80294"},
	{latitude: 40.04569, longitude: -105.20089, zipCode: "80301"},
	{latitude: 40.025056, longitude: -105.348664, zipCode: "80302"},
	{latitude: 39.973222, longitude: -105.209276, zipCode: "80303"},
	{latitude: 40.044727, longitude: -105.288351, zipCode: "80304"},
	{latitude: 39.976873, longitude: -105.248683, zipCode: "80305"},
	{latitude: 40.003986, longitude: -105.261368, zipCode: "80310"},
	{latitude: 39.718872, longitude: -105.235342, zipCode: "80401"},
	{latitude: 39.828768, longitude: -105.316923, zipCode: "80403"},
	{latitude: 39.728414, longitude: -105.202468, zipCode: "80419"},
	{latitude: 39.324401, longitude: -106.11611, zipCode: "80420"},
	{latitude: 39.460932, longitude: -105.501199, zipCode: "80421"},
	{latitude: 39.861574, longitude: -105.539757, zipCode: "80422"},
	{latitude: 39.845901, longitude: -106.554702, zipCode: "80423"},
	{latitude: 39.473285, longitude: -106.00426, zipCode: "80424"},
	{latitude: 39.350155, longitude: -105.205591, zipCode: "80425"},
	{latitude: 39.875477, longitude: -106.990946, zipCode: "80426"},
	{latitude: 39.793654, longitude: -105.524087, zipCode: "80427"},
	{latitude: 40.843694, longitude: -106.925249, zipCode: "80428"},
	{latitude: 40.519317, longitude: -106.41632, zipCode: "80430"},
	{latitude: 39.230346, longitude: -105.837507, zipCode: "80432"},
	{latitude: 39.498109, longitude: -105.303437, zipCode: "80433"},
	{latitude: 40.903319, longitude: -106.284985, zipCode: "80434"},
	{latitude: 39.573898, longitude: -105.934721, zipCode: "80435"},
	{latitude: 39.781044, longitude: -105.640202, zipCode: "80436"},
	{latitude: 39.744312, longitude: -105.796262, zipCode: "80438"},
	{latitude: 39.634422, longitude: -105.440245, zipCode: "80439"},
	{latitude: 39.132289, longitude: -106.055005, zipCode: "80440"},
	{latitude: 39.92467, longitude: -105.887899, zipCode: "80442"},
	{latitude: 39.503377, longitude: -106.174069, zipCode: "80443"},
	{latitude: 39.632198, longitude: -105.734642, zipCode: "80444"},
	{latitude: 40.163892, longitude: -105.934574, zipCode: "80446"},
	{latitude: 40.29556, longitude: -105.822108, zipCode: "80447"},
	{latitude: 39.477204, longitude: -105.834433, zipCode: "80448"},
	{latitude: 38.929671, longitude: -105.790096, zipCode: "80449"},
	{latitude: 40.040045, longitude: -106.04454, zipCode: "80451"},
	{latitude: 39.712014, longitude: -105.597334, zipCode: "80452"},
	{latitude: 39.664805, longitude: -105.24284, zipCode: "80453"},
	{latitude: 39.629881, longitude: -105.247461, zipCode: "80454"},
	{latitude: 40.094966, longitude: -105.397702, zipCode: "80455"},
	{latitude: 39.310412, longitude: -105.689471, zipCode: "80456"},
	{latitude: 39.65097, longitude: -105.299732, zipCode: "80457"},
	{latitude: 40.1547, longitude: -106.414187, zipCode: "80459"},
	{latitude: 39.242242, longitude: -106.287866, zipCode: "80461"},
	{latitude: 39.982326, longitude: -106.758075, zipCode: "80463"},
	{latitude: 39.609094, longitude: -105.205535, zipCode: "80465"},
	{latitude: 39.988037, longitude: -105.544054, zipCode: "80466"},
	{latitude: 40.207634, longitude: -106.825857, zipCode: "80467"},
	{latitude: 39.914883, longitude: -106.110896, zipCode: "80468"},
	{latitude: 40.214461, longitude: -106.967989, zipCode: "80469"},
	{latitude: 39.379796, longitude: -105.324404, zipCode: "80470"},
	{latitude: 39.935049, longitude: -105.420534, zipCode: "80471"},
	{latitude: 40.449427, longitude: -106.160134, zipCode: "80473"},
	{latitude: 39.923207, longitude: -105.569046, zipCode: "80474"},
	{latitude: 39.410988, longitude: -105.608182, zipCode: "80475"},
	{latitude: 39.670628, longitude: -105.834343, zipCode: "80476"},
	{latitude: 40.022149, longitude: -105.856936, zipCode: "80478"},
	{latitude: 39.992645, longitude: -106.847261, zipCode: "80479"},
	{latitude: 40.672888, longitude: -106.341566, zipCode: "80480"},
	{latitude: 40.101411, longitude: -105.576764, zipCode: "80481"},
	{latitude: 39.901527, longitude: -105.785677, zipCode: "80482"},
	{latitude: 40.091798, longitude: -106.93598, zipCode: "80483"},
	{latitude: 40.581425, longitude: -106.885954, zipCode: "80487"},
	{latitude: 39.746631, longitude: -106.27053, zipCode: "80498"},
	{latitude: 40.164832, longitude: -105.101852, zipCode: "80501"},
	{latitude: 40.169014, longitude: -105.210376, zipCode: "80503"},
	{latitude: 40.16564, longitude: -105.029231, zipCode: "80504"},
	{latitude: 40.2125, longitude: -105.6066, zipCode: "80510"},
	{latitude: 40.631157, longitude: -105.574043, zipCode: "80512"},
	{latitude: 40.299451, longitude: -105.118553, zipCode: "80513"},
	{latitude: 40.070612, longitude: -104.955294, zipCode: "80514"},
	{latitude: 40.462346, longitude: -105.382303, zipCode: "80515"},
	{latitude: 40.052867, longitude: -105.021054, zipCode: "80516"},
	{latitude: 40.397935, longitude: -105.614927, zipCode: "80517"},
	{latitude: 40.114073, longitude: -104.933438, zipCode: "80520"},
	{latitude: 40.593083, longitude: -105.127429, zipCode: "80521"},
	{latitude: 40.646854, longitude: -105.029519, zipCode: "80524"},
	{latitude: 40.527358, longitude: -105.041438, zipCode: "80525"},
	{latitude: 40.52219, longitude: -105.129441, zipCode: "80526"},
	{latitude: 40.499003, longitude: -105.005051, zipCode: "80528"},
	{latitude: 40.101551, longitude: -104.923044, zipCode: "80530"},
	{latitude: 40.483892, longitude: -105.465565, zipCode: "80532"},
	{latitude: 40.331863, longitude: -104.937467, zipCode: "80534"},
	{latitude: 40.736224, longitude: -105.191062, zipCode: "80535"},
	{latitude: 40.878827, longitude: -105.381223, zipCode: "80536"},
	{latitude: 40.38308, longitude: -105.200416, zipCode: "80537"},
	{latitude: 40.544612, longitude: -105.245108, zipCode: "80538"},
	{latitude: 40.224619, longitude: -105.400456, zipCode: "80540"},
	{latitude: 40.234051, longitude: -104.999017, zipCode: "80542"},
	{latitude: 40.349543, longitude: -104.857385, zipCode: "80543"},
	{latitude: 40.103105, longitude: -105.1715, zipCode: "80544"},
	{latitude: 40.842012, longitude: -105.702819, zipCode: "80545"},
	{latitude: 40.527112, longitude: -104.850247, zipCode: "80546"},
	{latitude: 40.52663, longitude: -104.965364, zipCode: "80547"},
	{latitude: 40.857542, longitude: -105.044126, zipCode: "80549"},
	{latitude: 40.488775, longitude: -104.899732, zipCode: "80550"},
	{latitude: 39.962169, longitude: -104.807922, zipCode: "80601"},
	{latitude: 39.966162, longitude: -104.908888, zipCode: "80602"},
	{latitude: 39.951146, longitude: -104.660237, zipCode: "80603"},
	{latitude: 40.673865, longitude: -104.591302, zipCode: "80610"},
	{latitude: 40.624941, longitude: -104.249087, zipCode: "80611"},
	{latitude: 40.878964, longitude: -104.823773, zipCode: "80612"},
	{latitude: 40.545658, longitude: -104.645698, zipCode: "80615"},
	{latitude: 40.375758, longitude: -104.713561, zipCode: "80620"},
	{latitude: 40.107188, longitude: -104.801281, zipCode: "80621"},
	{latitude: 40.538696, longitude: -104.441956, zipCode: "80622"},
	{latitude: 40.28534, longitude: -104.782547, zipCode: "80623"},
	{latitude: 40.435451, longitude: -104.423055, zipCode: "80624"},
	{latitude: 40.444516, longitude: -104.679555, zipCode: "80631"},
	{latitude: 40.40141, longitude: -104.792395, zipCode: "80634"},
	{latitude: 39.88755, longitude: -104.880816, zipCode: "80640"},
	{latitude: 40.051416, longitude: -104.612139, zipCode: "80642"},
	{latitude: 40.112927, longitude: -104.489176, zipCode: "80643"},
	{latitude: 40.334515, longitude: -104.430048, zipCode: "80644"},
	{latitude: 40.283314, longitude: -104.649185, zipCode: "80645"},
	{latitude: 40.475379, longitude: -104.697597, zipCode: "80646"},
	{latitude: 40.762507, longitude: -104.7305, zipCode: "80648"},
	{latitude: 40.400836, longitude: -104.185793, zipCode: "80649"},
	{latitude: 40.64873, longitude: -104.781215, zipCode: "80650"},
	{latitude: 40.241735, longitude: -104.80941, zipCode: "80651"},
	{latitude: 40.138876, longitude: -104.275168, zipCode: "80652"},
	{latitude: 40.446125, longitude: -103.977787, zipCode: "80653"},
	{latitude: 40.135588, longitude: -104.081882, zipCode: "80654"},
	{latitude: 40.125042, longitude: -103.817561, zipCode: "80701"},
	{latitude: 40.271114, longitude: -103.834267, zipCode: "80705"},
	{latitude: 40.073492, longitude: -103.187333, zipCode: "80720"},
	{latitude: 40.688165, longitude: -102.152977, zipCode: "80721"},
	{latitude: 40.476059, longitude: -103.200495, zipCode: "80722"},
	{latitude: 40.193681, longitude: -103.543329, zipCode: "80723"},
	{latitude: 40.923171, longitude: -102.776849, zipCode: "80726"},
	{latitude: 40.024443, longitude: -102.510758, zipCode: "80727"},
	{latitude: 40.639921, longitude: -102.87286, zipCode: "80728"},
	{latitude: 40.876288, longitude: -104.226608, zipCode: "80729"},
	{latitude: 40.587662, longitude: -102.59793, zipCode: "80731"},
	{latitude: 40.988467, longitude: -104.305251, zipCode: "80732"},
	{latitude: 40.357168, longitude: -103.474672, zipCode: "80733"},
	{latitude: 40.519891, longitude: -102.291952, zipCode: "80734"},
	{latitude: 39.709865, longitude: -102.244077, zipCode: "80735"},
	{latitude: 40.811815, longitude: -103.027237, zipCode: "80736"},
	{latitude: 40.895118, longitude: -102.198592, zipCode: "80737"},
	{latitude: 39.744369, longitude: -103.384859, zipCode: "80740"},
	{latitude: 40.566834, longitude: -103.476991, zipCode: "80741"},
	{latitude: 40.755781, longitude: -103.835018, zipCode: "80742"},
	{latitude: 40.215059, longitude: -102.956289, zipCode: "80743"},
	{latitude: 40.873626, longitude: -102.391968, zipCode: "80744"},
	{latitude: 40.895496, longitude: -103.412556, zipCode: "80745"},
	{latitude: 40.607064, longitude: -102.456953, zipCode: "80746"},
	{latitude: 40.933839, longitude: -103.18087, zipCode: "80747"},
	{latitude: 40.867058, longitude: -102.547276, zipCode: "80749"},
	{latitude: 40.414765, longitude: -103.604846, zipCode: "80750"},
	{latitude: 40.651901, longitude: -103.241063, zipCode: "80751"},
	{latitude: 40.665383, longitude: -103.636232, zipCode: "80754"},
	{latitude: 39.896543, longitude: -102.345821, zipCode: "80755"},
	{latitude: 39.791027, longitude: -103.58313, zipCode: "80757"},
	{latitude: 40.138836, longitude: -102.193094, zipCode: "80758"},
	{latitude: 40.111353, longitude: -102.690716, zipCode: "80759"},
	{latitude: 39.73228, longitude: -103.101271, zipCode: "80801"},
	{latitude: 38.827829, longitude: -102.150471, zipCode: "80802"},
	{latitude: 39.34684, longitude: -103.248069, zipCode: "80804"},
	{latitude: 39.296843, longitude: -102.437367, zipCode: "80805"},
	{latitude: 39.321667, longitude: -102.223231, zipCode: "80807"},
	{latitude: 39.008243, longitude: -104.303547, zipCode: "80808"},
	{latitude: 38.852008, longitude: -105.008613, zipCode: "80809"},
	{latitude: 38.822287, longitude: -102.428708, zipCode: "80810"},
	{latitude: 39.633425, longitude: -102.830967, zipCode: "80812"},
	{latitude: 38.754427, longitude: -105.120108, zipCode: "80813"},
	{latitude: 38.944862, longitude: -105.189664, zipCode: "80814"},
	{latitude: 39.389193, longitude: -103.098983, zipCode: "80815"},
	{latitude: 38.857741, longitude: -105.315864, zipCode: "80816"},
	{latitude: 38.588209, longitude: -104.67753, zipCode: "80817"},
	{latitude: 39.421692, longitude: -103.464845, zipCode: "80818"},
	{latitude: 38.947428, longitude: -104.998103, zipCode: "80819"},
	{latitude: 38.777049, longitude: -105.566978, zipCode: "80820"},
	{latitude: 39.00028, longitude: -103.447221, zipCode: "80821"},
	{latitude: 39.677784, longitude: -102.678662, zipCode: "80822"},
	{latitude: 38.663464, longitude: -103.419186, zipCode: "80823"},
	{latitude: 39.672236, longitude: -102.499647, zipCode: "80824"},
	{latitude: 38.833241, longitude: -102.827694, zipCode: "80825"},
	{latitude: 39.218744, longitude: -103.691289, zipCode: "80826"},
	{latitude: 39.056483, longitude: -105.467359, zipCode: "80827"},
	{latitude: 39.264079, longitude: -103.704966, zipCode: "80828"},
	{latitude: 38.829101, longitude: -104.932973, zipCode: "80829"},
	{latitude: 39.114217, longitude: -103.912818, zipCode: "80830"},
	{latitude: 38.981118, longitude: -104.499945, zipCode: "80831"},
	{latitude: 38.896512, longitude: -103.766539, zipCode: "80832"},
	{latitude: 38.763233, longitude: -103.96509, zipCode: "80833"},
	{latitude: 39.296552, longitude: -102.880716, zipCode: "80834"},
	{latitude: 39.218796, longitude: -104.022559, zipCode: "80835"},
	{latitude: 39.303192, longitude: -102.582488, zipCode: "80836"},
	{latitude: 39.000068, longitude: -104.864468, zipCode: "80840"},
	{latitude: 38.700164, longitude: -105.097304, zipCode: "80860"},
	{latitude: 39.323701, longitude: -102.771452, zipCode: "80861"},
	{latitude: 38.922172, longitude: -103.058029, zipCode: "80862"},
	{latitude: 39.032718, longitude: -105.054396, zipCode: "80863"},
	{latitude: 38.699006, longitude: -104.187552, zipCode: "80864"},
	{latitude: 38.683656, longitude: -104.82224, zipCode: "80902"},
	{latitude: 38.832791, longitude: -104.813816, zipCode: "80903"},
	{latitude: 38.862565, longitude: -104.87047, zipCode: "80904"},
	{latitude: 38.81891, longitude: -104.838348, zipCode: "80905"},
	{latitude: 38.752594, longitude: -104.878211, zipCode: "80906"},
	{latitude: 38.878994, longitude: -104.821148, zipCode: "80907"},
	{latitude: 39.047585, longitude: -104.69054, zipCode: "80908"},
	{latitude: 38.852566, longitude: -104.774326, zipCode: "80909"},
	{latitude: 38.812066, longitude: -104.77458, zipCode: "80910"},
	{latitude: 38.752825, longitude: -104.719732, zipCode: "80911"},
	{latitude: 38.687426, longitude: -104.748914, zipCode: "80913"},
	{latitude: 38.824014, longitude: -104.705448, zipCode: "80914"},
	{latitude: 38.849836, longitude: -104.716971, zipCode: "80915"},
	{latitude: 38.802461, longitude: -104.708714, zipCode: "80916"},
	{latitude: 38.886837, longitude: -104.74047, zipCode: "80917"},
	{latitude: 38.910393, longitude: -104.780362, zipCode: "80918"},
	{latitude: 38.929852, longitude: -104.878821, zipCode: "80919"},
	{latitude: 38.955706, longitude: -104.772275, zipCode: "80920"},
	{latitude: 39.060887, longitude: -104.819136, zipCode: "80921"},
	{latitude: 38.88995, longitude: -104.700832, zipCode: "80922"},
	{latitude: 38.926735, longitude: -104.714341, zipCode: "80923"},
	{latitude: 38.966933, longitude: -104.723123, zipCode: "80924"},
	{latitude: 38.77004, longitude: -104.643867, zipCode: "80925"},
	{latitude: 38.634719, longitude: -104.902972, zipCode: "80926"},
	{latitude: 38.9283, longitude: -104.658773, zipCode: "80927"},
	{latitude: 38.630401, longitude: -104.401637, zipCode: "80928"},
	{latitude: 38.824594, longitude: -104.608368, zipCode: "80929"},
	{latitude: 38.805059, longitude: -104.506438, zipCode: "80930"},
	{latitude: 38.906503, longitude: -104.656877, zipCode: "80938"},
	{latitude: 38.872785, longitude: -104.675754, zipCode: "80939"},
	{latitude: 38.878533, longitude: -104.657577, zipCode: "80951"},
	{latitude: 38.295329, longitude: -104.537238, zipCode: "81001"},
	{latitude: 38.277652, longitude: -104.656426, zipCode: "81003"},
	{latitude: 38.044054, longitude: -104.71441, zipCode: "81004"},
	{latitude: 38.212315, longitude: -104.831773, zipCode: "81005"},
	{latitude: 38.239909, longitude: -104.477851, zipCode: "81006"},
	{latitude: 38.385809, longitude: -104.795882, zipCode: "81007"},
	{latitude: 38.405766, longitude: -104.57872, zipCode: "81008"},
	{latitude: 37.942304, longitude: -104.835772, zipCode: "81019"},
	{latitude: 37.465135, longitude: -104.609994, zipCode: "81020"},
	{latitude: 38.419198, longitude: -103.376492, zipCode: "81021"},
	{latitude: 38.024844, longitude: -104.434721, zipCode: "81022"},
	{latitude: 38.066322, longitude: -104.926481, zipCode: "81023"},
	{latitude: 37.229315, longitude: -104.711619, zipCode: "81024"},
	{latitude: 38.455047, longitude: -104.355296, zipCode: "81025"},
	{latitude: 37.246664, longitude: -103.723169, zipCode: "81027"},
	{latitude: 37.082909, longitude: -102.508878, zipCode: "81029"},
	{latitude: 38.104859, longitude: -103.511496, zipCode: "81030"},
	{latitude: 38.19577, longitude: -103.855605, zipCode: "81033"},
	{latitude: 38.415124, longitude: -102.792829, zipCode: "81036"},
	{latitude: 37.93907, longitude: -104.085204, zipCode: "81039"},
	{latitude: 37.783222, longitude: -105.227208, zipCode: "81040"},
	{latitude: 37.852383, longitude: -102.397941, zipCode: "81041"},
	{latitude: 38.119748, longitude: -102.223113, zipCode: "81043"},
	{latitude: 37.859663, longitude: -102.947678, zipCode: "81044"},
	{latitude: 38.565411, longitude: -103.154447, zipCode: "81045"},
	{latitude: 37.295922, longitude: -104.368605, zipCode: "81046"},
	{latitude: 38.009822, longitude: -102.190671, zipCode: "81047"},
	{latitude: 37.304307, longitude: -103.389831, zipCode: "81049"},
	{latitude: 37.855707, longitude: -103.471739, zipCode: "81050"},
	{latitude: 37.974424, longitude: -102.638977, zipCode: "81052"},
	{latitude: 37.911665, longitude: -103.190484, zipCode: "81054"},
	{latitude: 37.430009, longitude: -105.104414, zipCode: "81055"},
	{latitude: 38.123016, longitude: -102.901912, zipCode: "81057"},
	{latitude: 38.10851, longitude: -103.886689, zipCode: "81058"},
	{latitude: 37.563258, longitude: -104.001465, zipCode: "81059"},
	{latitude: 38.29689, longitude: -103.964339, zipCode: "81062"},
	{latitude: 38.43071, longitude: -103.77106, zipCode: "81063"},
	{latitude: 37.224707, longitude: -102.999283, zipCode: "81064"},
	{latitude: 38.01618, longitude: -103.725034, zipCode: "81067"},
	{latitude: 37.891792, longitude: -104.889437, zipCode: "81069"},
	{latitude: 38.433234, longitude: -102.260787, zipCode: "81071"},
	{latitude: 37.425411, longitude: -102.705136, zipCode: "81073"},
	{latitude: 38.343221, longitude: -103.601595, zipCode: "81076"},
	{latitude: 38.019499, longitude: -103.613443, zipCode: "81077"},
	{latitude: 37.065471, longitude: -104.155847, zipCode: "81081"},
	{latitude: 37.185365, longitude: -104.413603, zipCode: "81082"},
	{latitude: 37.564028, longitude: -102.428082, zipCode: "81084"},
	{latitude: 37.391644, longitude: -102.412798, zipCode: "81087"},
	{latitude: 37.630413, longitude: -104.674625, zipCode: "81089"},
	{latitude: 37.365902, longitude: -102.230461, zipCode: "81090"},
	{latitude: 37.14909, longitude: -104.964703, zipCode: "81091"},
	{latitude: 38.215278, longitude: -102.908255, zipCode: "81092"},
	{latitude: 37.469311, longitude: -105.85273, zipCode: "81101"},
	{latitude: 37.195313, longitude: -106.34935, zipCode: "81120"},
	{latitude: 37.016916, longitude: -107.419824, zipCode: "81121"},
	{latitude: 37.301809, longitude: -107.537448, zipCode: "81122"},
	{latitude: 37.411212, longitude: -105.56571, zipCode: "81123"},
	{latitude: 37.285443, longitude: -106.106869, zipCode: "81124"},
	{latitude: 37.819261, longitude: -106.069857, zipCode: "81125"},
	{latitude: 37.161451, longitude: -105.347251, zipCode: "81126"},
	{latitude: 37.079692, longitude: -106.620963, zipCode: "81128"},
	{latitude: 37.104955, longitude: -106.021658, zipCode: "81129"},
	{latitude: 37.701575, longitude: -107.096886, zipCode: "81130"},
	{latitude: 37.865412, longitude: -105.679141, zipCode: "81131"},
	{latitude: 37.714226, longitude: -106.416175, zipCode: "81132"},
	{latitude: 37.45001, longitude: -105.32842, zipCode: "81133"},
	{latitude: 37.71951, longitude: -105.813477, zipCode: "81136"},
	{latitude: 37.051911, longitude: -107.613601, zipCode: "81137"},
	{latitude: 37.023023, longitude: -105.627388, zipCode: "81138"},
	{latitude: 37.299115, longitude: -106.161365, zipCode: "81140"},
	{latitude: 37.16141, longitude: -105.893647, zipCode: "81141"},
	{latitude: 38.158406, longitude: -105.849977, zipCode: "81143"},
	{latitude: 37.553535, longitude: -106.139482, zipCode: "81144"},
	{latitude: 37.646122, longitude: -105.668323, zipCode: "81146"},
	{latitude: 37.286627, longitude: -107.085416, zipCode: "81147"},
	{latitude: 37.171822, longitude: -105.985415, zipCode: "81148"},
	{latitude: 38.100089, longitude: -106.346144, zipCode: "81149"},
	{latitude: 37.239069, longitude: -105.711645, zipCode: "81151"},
	{latitude: 37.134542, longitude: -105.395987, zipCode: "81152"},
	{latitude: 37.634046, longitude: -106.667927, zipCode: "81154"},
	{latitude: 38.28645, longitude: -106.065517, zipCode: "81155"},
	{latitude: 38.555478, longitude: -106.107468, zipCode: "81201"},
	{latitude: 38.808683, longitude: -106.677952, zipCode: "81210"},
	{latitude: 38.972891, longitude: -106.380522, zipCode: "81211"},
	{latitude: 38.542383, longitude: -105.423488, zipCode: "81212"},
	{latitude: 38.297911, longitude: -107.477679, zipCode: "81220"},
	{latitude: 38.362378, longitude: -105.14243, zipCode: "81221"},
	{latitude: 38.354894, longitude: -105.816135, zipCode: "81222"},
	{latitude: 38.364528, longitude: -105.612647, zipCode: "81223"},
	{latitude: 38.880077, longitude: -106.932779, zipCode: "81224"},
	{latitude: 38.962294, longitude: -107.016317, zipCode: "81225"},
	{latitude: 38.337906, longitude: -105.089415, zipCode: "81226"},
	{latitude: 38.549326, longitude: -106.2901, zipCode: "81227"},
	{latitude: 39.031633, longitude: -106.27699, zipCode: "81228"},
	{latitude: 38.395065, longitude: -106.953683, zipCode: "81230"},
	{latitude: 38.27535, longitude: -105.609007, zipCode: "81232"},
	{latitude: 38.492323, longitude: -105.813776, zipCode: "81233"},
	{latitude: 37.965061, longitude: -107.405174, zipCode: "81235"},
	{latitude: 38.676257, longitude: -106.263703, zipCode: "81236"},
	{latitude: 38.616312, longitude: -106.621254, zipCode: "81237"},
	{latitude: 38.536682, longitude: -106.608252, zipCode: "81239"},
	{latitude: 38.500352, longitude: -105.034304, zipCode: "81240"},
	{latitude: 38.638258, longitude: -106.490872, zipCode: "81241"},
	{latitude: 38.471839, longitude: -106.072905, zipCode: "81242"},
	{latitude: 38.265999, longitude: -107.192094, zipCode: "81243"},
	{latitude: 38.36392, longitude: -105.164927, zipCode: "81244"},
	{latitude: 38.419207, longitude: -106.500053, zipCode: "81248"},
	{latitude: 39.142548, longitude: -106.437579, zipCode: "81251"},
	{latitude: 38.110643, longitude: -105.427521, zipCode: "81252"},
	{latitude: 38.169467, longitude: -105.066493, zipCode: "81253"},
	{latitude: 37.529427, longitude: -107.821502, zipCode: "81301"},
	{latitude: 37.133408, longitude: -107.843803, zipCode: "81303"},
	{latitude: 37.720141, longitude: -108.735147, zipCode: "81320"},
	{latitude: 37.31297, longitude: -108.734537, zipCode: "81321"},
	{latitude: 37.702727, longitude: -108.126639, zipCode: "81323"},
	{latitude: 37.641979, longitude: -109.009593, zipCode: "81324"},
	{latitude: 37.919329, longitude: -108.712763, zipCode: "81325"},
	{latitude: 37.145589, longitude: -108.116569, zipCode: "81326"},
	{latitude: 37.516777, longitude: -108.656346, zipCode: "81327"},
	{latitude: 37.270759, longitude: -108.329659, zipCode: "81328"},
	{latitude: 37.234851, longitude: -108.478286, zipCode: "81330"},
	{latitude: 37.445868, longitude: -108.907291, zipCode: "81331"},
	{latitude: 37.700344, longitude: -107.991227, zipCode: "81332"},
	{latitude: 37.146129, longitude: -108.61295, zipCode: "81334"},
	{latitude: 37.543801, longitude: -108.719791, zipCode: "81335"},
	{latitude: 38.514973, longitude: -107.766148, zipCode: "81401"},
	{latitude: 38.314564, longitude: -107.92836, zipCode: "81403"},
	{latitude: 38.80969, longitude: -107.976469, zipCode: "81410"},
	{latitude: 38.251835, longitude: -108.97886, zipCode: "81411"},
	{latitude: 38.975741, longitude: -107.899073, zipCode: "81413"},
	{latitude: 38.625512, longitude: -107.627585, zipCode: "81415"},
	{latitude: 38.640468, longitude: -108.254583, zipCode: "81416"},
	{latitude: 38.899354, longitude: -108.05804, zipCode: "81418"},
	{latitude: 38.883071, longitude: -107.747726, zipCode: "81419"},
	{latitude: 38.320239, longitude: -108.70826, zipCode: "81422"},
	{latitude: 38.009319, longitude: -108.427263, zipCode: "81423"},
	{latitude: 38.345022, longitude: -108.502319, zipCode: "81424"},
	{latitude: 38.458292, longitude: -108.118672, zipCode: "81425"},
	{latitude: 37.866128, longitude: -107.820786, zipCode: "81426"},
	{latitude: 38.025834, longitude: -107.609747, zipCode: "81427"},
	{latitude: 38.962987, longitude: -107.599578, zipCode: "81428"},
	{latitude: 38.37922, longitude: -108.882861, zipCode: "81429"},
	{latitude: 38.053745, longitude: -108.067189, zipCode: "81430"},
	{latitude: 38.143531, longitude: -108.514118, zipCode: "81431"},
	{latitude: 38.129867, longitude: -107.745344, zipCode: "81432"},
	{latitude: 37.722006, longitude: -107.579393, zipCode: "81433"},
	{latitude: 38.981711, longitude: -107.43059, zipCode: "81434"},
	{latitude: 37.868751, longitude: -107.925117, zipCode: "81435"},
	{latitude: 39.071844, longitude: -108.546, zipCode: "81501"},
	{latitude: 39.034026, longitude: -108.486598, zipCode: "81503"},
	{latitude: 39.120298, longitude: -108.488532, zipCode: "81504"},
	{latitude: 39.214122, longitude: -108.588404, zipCode: "81505"},
	{latitude: 39.124877, longitude: -108.558662, zipCode: "81506"},
	{latitude: 39.009885, longitude: -108.651873, zipCode: "81507"},
	{latitude: 39.111083, longitude: -108.433844, zipCode: "81520"},
	{latitude: 39.156637, longitude: -108.878833, zipCode: "81521"},
	{latitude: 38.685456, longitude: -108.921083, zipCode: "81522"},
	{latitude: 38.924718, longitude: -108.889477, zipCode: "81523"},
	{latitude: 39.290535, longitude: -108.732843, zipCode: "81524"},
	{latitude: 39.259524, longitude: -108.967298, zipCode: "81525"},
	{latitude: 39.083037, longitude: -108.345615, zipCode: "81526"},
	{latitude: 38.798468, longitude: -108.469982, zipCode: "81527"},
	{latitude: 39.587562, longitude: -107.338712, zipCode: "81601"},
	{latitude: 40.289242, longitude: -108.842569, zipCode: "81610"},
	{latitude: 39.158173, longitude: -106.668632, zipCode: "81611"},
	{latitude: 39.051104, longitude: -106.845014, zipCode: "81612"},
	{latitude: 39.213371, longitude: -106.942734, zipCode: "81615"},
	{latitude: 39.61303, longitude: -106.503056, zipCode: "81620"},
	{latitude: 39.37811, longitude: -106.87636, zipCode: "81621"},
	{latitude: 39.246027, longitude: -107.162912, zipCode: "81623"},
	{latitude: 39.159217, longitude: -107.745149, zipCode: "81624"},
	{latitude: 40.704673, longitude: -107.736157, zipCode: "81625"},
	{latitude: 39.45801, longitude: -108.56434, zipCode: "81630"},
	{latitude: 39.626454, longitude: -106.771255, zipCode: "81631"},
	{latitude: 39.622675, longitude: -106.607079, zipCode: "81632"},
	{latitude: 40.387427, longitude: -108.46898, zipCode: "81633"},
	{latitude: 39.535315, longitude: -108.066676, zipCode: "81635"},
	{latitude: 39.777826, longitude: -107.105814, zipCode: "81637"},
	{latitude: 40.300403, longitude: -107.642739, zipCode: "81638"},
	{latitude: 40.564993, longitude: -107.246656, zipCode: "81639"},
	{latitude: 40.668299, longitude: -108.514046, zipCode: "81640"},
	{latitude: 40.109264, longitude: -107.818214, zipCode: "81641"},
	{latitude: 39.312809, longitude: -106.567699, zipCode: "81642"},
	{latitude: 39.124092, longitude: -108.149294, zipCode: "81643"},
	{latitude: 39.455587, longitude: -106.458361, zipCode: "81645"},
	{latitude: 39.1044, longitude: -108.005195, zipCode: "81646"},
	{latitude: 39.579088, longitude: -107.532937, zipCode: "81647"},
	{latitude: 39.904489, longitude: -108.791994, zipCode: "81648"},
	{latitude: 39.484255, longitude: -106.291641, zipCode: "81649"},
	{latitude: 39.751037, longitude: -108.113722, zipCode: "81650"},
	{latitude: 39.466243, longitude: -107.673259, zipCode: "81652"},
	{latitude: 40.895748, longitude: -107.24223, zipCode: "81653"},
	{latitude: 39.205565, longitude: -107.045797, zipCode: "81654"},
	{latitude: 39.753051, longitude: -106.685878, zipCode: "81655"},
	{latitude: 39.279456, longitude: -106.7871, zipCode: "81656"},
	{latitude: 39.649325, longitude: -106.325837, zipCode: "81657"},
	{latitude: 41.100082, longitude: -104.924884, zipCode: "82001"},
	{latitude: 41.146344, longitude: -104.867879, zipCode: "82005"},
	{latitude: 41.084121, longitude: -104.683151, zipCode: "82007"},
	{latitude: 41.383479, longitude: -104.853477, zipCode: "82009"},
	{latitude: 41.446986, longitude: -104.266331, zipCode: "82050"},
	{latitude: 41.603073, longitude: -105.648828, zipCode: "82051"},
	{latitude: 41.120008, longitude: -105.346553, zipCode: "82052"},
	{latitude: 41.240864, longitude: -104.340467, zipCode: "82053"},
	{latitude: 41.064273, longitude: -104.36052, zipCode: "82054"},
	{latitude: 41.370798, longitude: -106.256463, zipCode: "82055"},
	{latitude: 42.153086, longitude: -105.608898, zipCode: "82058"},
	{latitude: 41.053423, longitude: -105.178649, zipCode: "82059"},
	{latitude: 41.28812, longitude: -104.497508, zipCode: "82060"},
	{latitude: 41.492118, longitude: -105.143201, zipCode: "82061"},
	{latitude: 40.770156, longitude: -105.970518, zipCode: "82063"},
	{latitude: 41.219644, longitude: -105.844164, zipCode: "82070"},
	{latitude: 41.427295, longitude: -105.550267, zipCode: "82072"},
	{latitude: 41.174864, longitude: -105.437857, zipCode: "82073"},
	{latitude: 41.533638, longitude: -104.48082, zipCode: "82081"},
	{latitude: 41.264882, longitude: -104.090072, zipCode: "82082"},
	{latitude: 41.724667, longitude: -105.990857, zipCode: "82083"},
	{latitude: 41.050832, longitude: -105.515933, zipCode: "82084"},
	{latitude: 44.519967, longitude: -110.649087, zipCode: "82190"},
	{latitude: 42.001919, longitude: -105.129783, zipCode: "82201"},
	{latitude: 41.711626, longitude: -104.758303, zipCode: "82210"},
	{latitude: 42.261018, longitude: -104.535651, zipCode: "82212"},
	{latitude: 42.494688, longitude: -105.004445, zipCode: "82213"},
	{latitude: 42.264246, longitude: -104.751287, zipCode: "82214"},
	{latitude: 42.466858, longitude: -104.70063, zipCode: "82215"},
	{latitude: 41.759812, longitude: -104.139524, zipCode: "82217"},
	{latitude: 41.936235, longitude: -104.140039, zipCode: "82218"},
	{latitude: 42.477914, longitude: -104.483044, zipCode: "82219"},
	{latitude: 41.638038, longitude: -104.328472, zipCode: "82221"},
	{latitude: 43.167545, longitude: -104.646704, zipCode: "82222"},
	{latitude: 42.12379, longitude: -104.389798, zipCode: "82223"},
	{latitude: 42.815996, longitude: -104.919629, zipCode: "82224"},
	{latitude: 42.961282, longitude: -104.319456, zipCode: "82225"},
	{latitude: 42.717565, longitude: -104.730863, zipCode: "82227"},
	{latitude: 42.877643, longitude: -104.985904, zipCode: "82229"},
	{latitude: 42.159346, longitude: -104.131944, zipCode: "82240"},
	{latitude: 42.647281, longitude: -104.136182, zipCode: "82242"},
	{latitude: 41.991964, longitude: -104.409516, zipCode: "82243"},
	{latitude: 41.897536, longitude: -104.439041, zipCode: "82244"},
	{latitude: 41.983168, longitude: -107.478998, zipCode: "82301"},
	{latitude: 42.445655, longitude: -107.877513, zipCode: "82310"},
	{latitude: 41.092985, longitude: -107.653534, zipCode: "82321"},
	{latitude: 42.24648, longitude: -107.548191, zipCode: "82322"},
	{latitude: 41.029544, longitude: -107.520699, zipCode: "82323"},
	{latitude: 41.66053, longitude: -106.430211, zipCode: "82324"},
	{latitude: 41.152307, longitude: -106.785328, zipCode: "82325"},
	{latitude: 42.141638, longitude: -106.630301, zipCode: "82327"},
	{latitude: 42.042894, longitude: -106.106129, zipCode: "82329"},
	{latitude: 41.399861, longitude: -106.819184, zipCode: "82331"},
	{latitude: 41.064102, longitude: -107.301653, zipCode: "82332"},
	{latitude: 41.953662, longitude: -106.977324, zipCode: "82334"},
	{latitude: 41.761698, longitude: -106.825523, zipCode: "82335"},
	{latitude: 41.698283, longitude: -108.208018, zipCode: "82336"},
	{latitude: 44.011214, longitude: -108.169952, zipCode: "82401"},
	{latitude: 44.351803, longitude: -108.155391, zipCode: "82410"},
	{latitude: 44.449929, longitude: -108.476582, zipCode: "82411"},
	{latitude: 44.790361, longitude: -108.553046, zipCode: "82412"},
	{latitude: 44.403789, longitude: -109.606717, zipCode: "82414"},
	{latitude: 44.918902, longitude: -108.464543, zipCode: "82420"},
	{latitude: 44.886983, longitude: -108.60215, zipCode: "82421"},
	{latitude: 44.49769, longitude: -108.379467, zipCode: "82422"},
	{latitude: 44.987313, longitude: -108.587815, zipCode: "82423"},
	{latitude: 44.516609, longitude: -108.036197, zipCode: "82426"},
	{latitude: 44.298594, longitude: -107.590462, zipCode: "82428"},
	{latitude: 43.804187, longitude: -108.17914, zipCode: "82430"},
	{latitude: 44.871763, longitude: -108.157894, zipCode: "82431"},
	{latitude: 44.214963, longitude: -107.847847, zipCode: "82432"},
	{latitude: 44.062254, longitude: -109.104718, zipCode: "82433"},
	{latitude: 44.345688, longitude: -108.295425, zipCode: "82434"},
	{latitude: 44.819966, longitude: -109.051004, zipCode: "82435"},
	{latitude: 44.717507, longitude: -108.867737, zipCode: "82440"},
	{latitude: 44.551786, longitude: -107.61138, zipCode: "82441"},
	{latitude: 43.914059, longitude: -107.33342, zipCode: "82442"},
	{latitude: 43.717103, longitude: -108.475221, zipCode: "82443"},
	{latitude: 44.50397, longitude: -109.433833, zipCode: "82450"},
	{latitude: 43.027802, longitude: -108.237888, zipCode: "82501"},
	{latitude: 42.999206, longitude: -108.60909, zipCode: "82510"},
	{latitude: 43.030418, longitude: -109.244958, zipCode: "82512"},
	{latitude: 43.627771, longitude: -109.629169, zipCode: "82513"},
	{latitude: 43.066578, longitude: -109.023081, zipCode: "82514"},
	{latitude: 42.897138, longitude: -108.566831, zipCode: "82515"},
	{latitude: 43.212968, longitude: -108.827654, zipCode: "82516"},
	{latitude: 42.661426, longitude: -108.638471, zipCode: "82520"},
	{latitude: 43.410909, longitude: -108.854483, zipCode: "82523"},
	{latitude: 43.069925, longitude: -106.341585, zipCode: "82601"},
	{latitude: 42.914918, longitude: -106.707293, zipCode: "82604"},
	{latitude: 42.771582, longitude: -106.186063, zipCode: "82609"},
	{latitude: 42.538666, longitude: -107.064701, zipCode: "82620"},
	{latitude: 43.329656, longitude: -107.307406, zipCode: "82630"},
	{latitude: 43.022149, longitude: -105.39185, zipCode: "82633"},
	{latitude: 43.421808, longitude: -106.21834, zipCode: "82635"},
	{latitude: 42.828794, longitude: -106.158007, zipCode: "82636"},
	{latitude: 42.760002, longitude: -105.857058, zipCode: "82637"},
	{latitude: 43.058737, longitude: -107.254284, zipCode: "82638"},
	{latitude: 43.738005, longitude: -106.695553, zipCode: "82639"},
	{latitude: 43.564866, longitude: -106.129374, zipCode: "82640"},
	{latitude: 43.278653, longitude: -107.660846, zipCode: "82642"},
	{latitude: 43.422337, longitude: -106.278957, zipCode: "82643"},
	{latitude: 42.842884, longitude: -106.373906, zipCode: "82644"},
	{latitude: 42.977645, longitude: -106.768219, zipCode: "82646"},
	{latitude: 43.097998, longitude: -106.915896, zipCode: "82648"},
	{latitude: 43.278998, longitude: -108.01296, zipCode: "82649"},
	{latitude: 43.69408, longitude: -104.458957, zipCode: "82701"},
	{latitude: 44.693122, longitude: -104.306556, zipCode: "82710"},
	{latitude: 44.711467, longitude: -104.480627, zipCode: "82711"},
	{latitude: 44.525837, longitude: -104.123779, zipCode: "82712"},
	{latitude: 44.551895, longitude: -104.680045, zipCode: "82714"},
	{latitude: 44.53546, longitude: -105.642705, zipCode: "82716"},
	{latitude: 43.92249, longitude: -105.608852, zipCode: "82718"},
	{latitude: 44.795996, longitude: -104.670545, zipCode: "82720"},
	{latitude: 44.455642, longitude: -104.898384, zipCode: "82721"},
	{latitude: 43.991936, longitude: -104.430623, zipCode: "82723"},
	{latitude: 44.876792, longitude: -105.702593, zipCode: "82725"},
	{latitude: 44.334992, longitude: -105.201469, zipCode: "82727"},
	{latitude: 44.371182, longitude: -104.359823, zipCode: "82729"},
	{latitude: 44.044891, longitude: -104.714289, zipCode: "82730"},
	{latitude: 44.800584, longitude: -105.223923, zipCode: "82731"},
	{latitude: 43.723972, longitude: -105.582726, zipCode: "82732"},
	{latitude: 44.832499, longitude: -106.896325, zipCode: "82801"},
	{latitude: 44.668518, longitude: -106.041629, zipCode: "82831"},
	{latitude: 44.605821, longitude: -106.764278, zipCode: "82832"},
	{latitude: 44.605323, longitude: -107.162982, zipCode: "82833"},
	{latitude: 44.260573, longitude: -106.731065, zipCode: "82834"},
	{latitude: 44.756041, longitude: -106.359287, zipCode: "82835"},
	{latitude: 44.822519, longitude: -107.608741, zipCode: "82836"},
	{latitude: 44.726993, longitude: -106.277909, zipCode: "82837"},
	{latitude: 44.918115, longitude: -107.462869, zipCode: "82838"},
	{latitude: 44.960639, longitude: -107.171384, zipCode: "82839"},
	{latitude: 44.571412, longitude: -106.939501, zipCode: "82842"},
	{latitude: 44.806077, longitude: -107.195736, zipCode: "82844"},
	{latitude: 44.758205, longitude: -106.731271, zipCode: "82845"},
	{latitude: 41.379272, longitude: -108.978176, zipCode: "82901"},
	{latitude: 43.142442, longitude: -110.45682, zipCode: "82922"},
	{latitude: 42.614983, longitude: -109.406742, zipCode: "82923"},
	{latitude: 43.168041, longitude: -109.784367, zipCode: "82925"},
	{latitude: 41.549363, longitude: -109.884876, zipCode: "82929"},
	{latitude: 40.958206, longitude: -110.607498, zipCode: "82930"},
	{latitude: 42.124393, longitude: -109.385213, zipCode: "82932"},
	{latitude: 41.269258, longitude: -110.497834, zipCode: "82933"},
	{latitude: 41.612466, longitude: -109.995644, zipCode: "82934"},
	{latitude: 41.677722, longitude: -109.659962, zipCode: "82935"},
	{latitude: 41.054019, longitude: -110.180295, zipCode: "82936"},
	{latitude: 41.34708, longitude: -110.264865, zipCode: "82937"},
	{latitude: 41.094299, longitude: -109.897385, zipCode: "82938"},
	{latitude: 41.218922, longitude: -110.345567, zipCode: "82939"},
	{latitude: 42.933762, longitude: -109.690898, zipCode: "82941"},
	{latitude: 41.699569, longitude: -108.765057, zipCode: "82942"},
	{latitude: 41.70196, longitude: -109.183982, zipCode: "82943"},
	{latitude: 41.129601, longitude: -110.49642, zipCode: "82944"},
	{latitude: 41.720087, longitude: -108.938903, zipCode: "82945"},
	{latitude: 43.394562, longitude: -110.629831, zipCode: "83001"},
	{latitude: 43.590313, longitude: -110.532466, zipCode: "83011"},
	{latitude: 43.715608, longitude: -110.807167, zipCode: "83012"},
	{latitude: 44.048662, longitude: -110.426087, zipCode: "83013"},
	{latitude: 43.448603, longitude: -110.966859, zipCode: "83014"},
	{latitude: 43.592022, longitude: -110.825985, zipCode: "83025"},
	{latitude: 41.976485, longitude: -110.54741, zipCode: "83101"},
	{latitude: 42.725902, longitude: -110.849219, zipCode: "83110"},
	{latitude: 42.818977, longitude: -111.011357, zipCode: "83111"},
	{latitude: 42.874872, longitude: -110.84907, zipCode: "83112"},
	{latitude: 42.5542, longitude: -110.267543, zipCode: "83113"},
	{latitude: 42.10037, longitude: -110.959785, zipCode: "83114"},
	{latitude: 42.911509, longitude: -110.238712, zipCode: "83115"},
	{latitude: 41.759056, longitude: -110.549109, zipCode: "83116"},
	{latitude: 43.047804, longitude: -110.92798, zipCode: "83118"},
	{latitude: 42.643912, longitude: -110.988218, zipCode: "83119"},
	{latitude: 43.109389, longitude: -111.138878, zipCode: "83120"},
	{latitude: 41.849863, longitude: -110.540299, zipCode: "83121"},
	{latitude: 42.821735, longitude: -110.958386, zipCode: "83122"},
	{latitude: 42.236912, longitude: -110.268354, zipCode: "83123"},
	{latitude: 41.758808, longitude: -110.31588, zipCode: "83124"},
	{latitude: 42.581108, longitude: -110.904209, zipCode: "83126"},
	{latitude: 42.918972, longitude: -110.997753, zipCode: "83127"},
	{latitude: 43.040079, longitude: -110.722208, zipCode: "83128"},
	{latitude: 42.90602, longitude: -112.373628, zipCode: "83201"},
	{latitude: 42.968171, longitude: -112.364866, zipCode: "83202"},
	{latitude: 43.025242, longitude: -112.428141, zipCode: "83203"},
	{latitude: 42.805005, longitude: -112.523931, zipCode: "83204"},
	{latitude: 42.857816, longitude: -112.426196, zipCode: "83209"},
	{latitude: 43.020349, longitude: -112.869167, zipCode: "83210"},
	{latitude: 42.655458, longitude: -113.076465, zipCode: "83211"},
	{latitude: 42.505985, longitude: -112.54789, zipCode: "83212"},
	{latitude: 43.443926, longitude: -113.364262, zipCode: "83213"},
	{latitude: 42.539846, longitude: -112.307675, zipCode: "83214"},
	{latitude: 43.443135, longitude: -112.812428, zipCode: "83215"},
	{latitude: 42.769278, longitude: -111.927009, zipCode: "83217"},
	{latitude: 43.318029, longitude: -112.164538, zipCode: "83218"},
	{latitude: 42.344881, longitude: -111.382503, zipCode: "83220"},
	{latitude: 43.294732, longitude: -112.512503, zipCode: "83221"},
	{latitude: 42.183103, longitude: -111.404309, zipCode: "83223"},
	{latitude: 44.372441, longitude: -114.009103, zipCode: "83226"},
	{latitude: 44.158678, longitude: -114.495906, zipCode: "83227"},
	{latitude: 42.233621, longitude: -112.06023, zipCode: "83228"},
	{latitude: 42.134701, longitude: -111.971027, zipCode: "83232"},
	{latitude: 42.135042, longitude: -111.237956, zipCode: "83233"},
	{latitude: 42.422608, longitude: -112.101232, zipCode: "83234"},
	{latitude: 44.618424, longitude: -114.053247, zipCode: "83235"},
	{latitude: 43.191095, longitude: -111.872456, zipCode: "83236"},
	{latitude: 42.027452, longitude: -111.778558, zipCode: "83237"},
	{latitude: 42.381236, longitude: -111.099924, zipCode: "83238"},
	{latitude: 42.529656, longitude: -111.357857, zipCode: "83239"},
	{latitude: 42.514851, longitude: -111.765362, zipCode: "83241"},
	{latitude: 42.232852, longitude: -112.665036, zipCode: "83243"},
	{latitude: 43.902492, longitude: -112.951366, zipCode: "83244"},
	{latitude: 42.872883, longitude: -112.184887, zipCode: "83245"},
	{latitude: 42.605661, longitude: -112.031259, zipCode: "83246"},
	{latitude: 42.693011, longitude: -112.213168, zipCode: "83250"},
	{latitude: 43.944697, longitude: -113.944659, zipCode: "83251"},
	{latitude: 42.174414, longitude: -112.406564, zipCode: "83252"},
	{latitude: 44.535798, longitude: -113.754846, zipCode: "83253"},
	{latitude: 42.354653, longitude: -111.239581, zipCode: "83254"},
	{latitude: 43.711763, longitude: -113.459371, zipCode: "83255"},
	{latitude: 42.220389, longitude: -111.405131, zipCode: "83261"},
	{latitude: 43.216953, longitude: -112.844421, zipCode: "83262"},
	{latitude: 42.181249, longitude: -111.753213, zipCode: "83263"},
	{latitude: 42.442204, longitude: -112.91278, zipCode: "83271"},
	{latitude: 42.094673, longitude: -111.382157, zipCode: "83272"},
	{latitude: 43.348834, longitude: -112.097983, zipCode: "83274"},
	{latitude: 42.755078, longitude: -111.436316, zipCode: "83276"},
	{latitude: 43.074569, longitude: -112.681207, zipCode: "83277"},
	{latitude: 44.336299, longitude: -114.813696, zipCode: "83278"},
	{latitude: 42.309016, longitude: -111.959392, zipCode: "83281"},
	{latitude: 42.357766, longitude: -111.667812, zipCode: "83283"},
	{latitude: 43.072385, longitude: -111.303013, zipCode: "83285"},
	{latitude: 42.063423, longitude: -112.015748, zipCode: "83286"},
	{latitude: 42.021675, longitude: -111.457289, zipCode: "83287"},
	{latitude: 42.400555, longitude: -114.524876, zipCode: "83301"},
	{latitude: 42.063779, longitude: -114.733505, zipCode: "83302"},
	{latitude: 42.407951, longitude: -113.560796, zipCode: "83311"},
	{latitude: 42.113303, longitude: -113.636354, zipCode: "83312"},
	{latitude: 43.348356, longitude: -114.1992, zipCode: "83313"},
	{latitude: 42.971598, longitude: -114.948649, zipCode: "83314"},
	{latitude: 42.625527, longitude: -114.8828, zipCode: "83316"},
	{latitude: 42.439675, longitude: -113.815864, zipCode: "83318"},
	{latitude: 43.404258, longitude: -113.889395, zipCode: "83320"},
	{latitude: 42.345625, longitude: -114.847442, zipCode: "83321"},
	{latitude: 43.316817, longitude: -114.966954, zipCode: "83322"},
	{latitude: 42.474682, longitude: -113.449466, zipCode: "83323"},
	{latitude: 42.871953, longitude: -114.262189, zipCode: "83324"},
	{latitude: 42.589607, longitude: -114.236404, zipCode: "83325"},
	{latitude: 43.502552, longitude: -114.772135, zipCode: "83327"},
	{latitude: 42.570241, longitude: -114.616281, zipCode: "83328"},
	{latitude: 42.941821, longitude: -114.694121, zipCode: "83330"},
	{latitude: 42.797531, longitude: -114.928868, zipCode: "83332"},
	{latitude: 43.571854, longitude: -114.24087, zipCode: "83333"},
	{latitude: 42.42108, longitude: -114.292723, zipCode: "83334"},
	{latitude: 42.582942, longitude: -114.073821, zipCode: "83335"},
	{latitude: 42.56468, longitude: -113.82465, zipCode: "83336"},
	{latitude: 43.224071, longitude: -115.152058, zipCode: "83337"},
	{latitude: 42.72853, longitude: -114.432126, zipCode: "83338"},
	{latitude: 43.736102, longitude: -114.582188, zipCode: "83340"},
	{latitude: 42.435607, longitude: -114.374711, zipCode: "83341"},
	{latitude: 42.144967, longitude: -113.245397, zipCode: "83342"},
	{latitude: 42.411167, longitude: -114.132506, zipCode: "83344"},
	{latitude: 42.195629, longitude: -113.903113, zipCode: "83346"},
	{latitude: 42.654874, longitude: -113.794029, zipCode: "83347"},
	{latitude: 43.281182, longitude: -114.12129, zipCode: "83348"},
	{latitude: 43.110594, longitude: -114.196305, zipCode: "83349"},
	{latitude: 42.710955, longitude: -113.601781, zipCode: "83350"},
	{latitude: 43.050625, longitude: -114.412947, zipCode: "83352"},
	{latitude: 43.69066, longitude: -114.331612, zipCode: "83353"},
	{latitude: 43.670143, longitude: -114.32231, zipCode: "83354"},
	{latitude: 42.757435, longitude: -114.722206, zipCode: "83355"},
	{latitude: 43.542409, longitude: -111.877936, zipCode: "83401"},
	{latitude: 43.526347, longitude: -112.182045, zipCode: "83402"},
	{latitude: 43.426779, longitude: -112.015502, zipCode: "83404"},
	{latitude: 43.439076, longitude: -111.933623, zipCode: "83406"},
	{latitude: 43.861439, longitude: -110.935725, zipCode: "83414"},
	{latitude: 44.059467, longitude: -111.324799, zipCode: "83420"},
	{latitude: 44.001893, longitude: -111.547349, zipCode: "83421"},
	{latitude: 43.689055, longitude: -111.301074, zipCode: "83422"},
	{latitude: 44.283954, longitude: -112.302167, zipCode: "83423"},
	{latitude: 43.901882, longitude: -111.19763, zipCode: "83424"},
	{latitude: 43.932034, longitude: -112.224678, zipCode: "83425"},
	{latitude: 43.357538, longitude: -111.753549, zipCode: "83427"},
	{latitude: 43.392652, longitude: -111.120168, zipCode: "83428"},
	{latitude: 44.481672, longitude: -111.375346, zipCode: "83429"},
	{latitude: 43.688427, longitude: -112.032935, zipCode: "83431"},
	{latitude: 44.492859, longitude: -111.317901, zipCode: "83433"},
	{latitude: 43.756386, longitude: -112.006611, zipCode: "83434"},
	{latitude: 43.978916, longitude: -112.586433, zipCode: "83435"},
	{latitude: 43.866825, longitude: -111.455085, zipCode: "83436"},
	{latitude: 43.959665, longitude: -111.759258, zipCode: "83438"},
	{latitude: 43.808572, longitude: -111.80862, zipCode: "83440"},
	{latitude: 43.628711, longitude: -111.834027, zipCode: "83442"},
	{latitude: 43.530905, longitude: -111.545848, zipCode: "83443"},
	{latitude: 43.719372, longitude: -112.301574, zipCode: "83444"},
	{latitude: 44.037765, longitude: -111.74528, zipCode: "83445"},
	{latitude: 44.36515, longitude: -112.212801, zipCode: "83446"},
	{latitude: 43.84955, longitude: -111.711682, zipCode: "83448"},
	{latitude: 43.426475, longitude: -111.357387, zipCode: "83449"},
	{latitude: 43.844949, longitude: -112.395676, zipCode: "83450"},
	{latitude: 43.866555, longitude: -111.633321, zipCode: "83451"},
	{latitude: 43.770111, longitude: -111.329232, zipCode: "83452"},
	{latitude: 43.594581, longitude: -111.956873, zipCode: "83454"},
	{latitude: 43.605032, longitude: -111.135365, zipCode: "83455"},
	{latitude: 43.816379, longitude: -111.782607, zipCode: "83460"},
	{latitude: 45.300529, longitude: -113.817801, zipCode: "83462"},
	{latitude: 45.56955, longitude: -113.975467, zipCode: "83463"},
	{latitude: 44.480616, longitude: -113.198582, zipCode: "83464"},
	{latitude: 44.79274, longitude: -113.672383, zipCode: "83465"},
	{latitude: 45.418061, longitude: -114.036232, zipCode: "83466"},
	{latitude: 45.042699, longitude: -113.856993, zipCode: "83467"},
	{latitude: 44.922252, longitude: -113.592213, zipCode: "83468"},
	{latitude: 45.069679, longitude: -114.408056, zipCode: "83469"},
	{latitude: 46.251696, longitude: -116.925017, zipCode: "83501"},
	{latitude: 46.544148, longitude: -116.326467, zipCode: "83520"},
	{latitude: 45.913366, longitude: -116.473604, zipCode: "83522"},
	{latitude: 46.227361, longitude: -116.46752, zipCode: "83523"},
	{latitude: 46.351686, longitude: -116.642415, zipCode: "83524"},
	{latitude: 45.740081, longitude: -115.393231, zipCode: "83525"},
	{latitude: 46.147763, longitude: -116.412071, zipCode: "83526"},
	{latitude: 45.91803, longitude: -116.021573, zipCode: "83530"},
	{latitude: 46.115669, longitude: -116.240833, zipCode: "83533"},
	{latitude: 46.535362, longitude: -116.736279, zipCode: "83535"},
	{latitude: 46.212969, longitude: -116.022672, zipCode: "83536"},
	{latitude: 46.63317, longitude: -116.529463, zipCode: "83537"},
	{latitude: 46.256824, longitude: -114.846627, zipCode: "83539"},
	{latitude: 46.345204, longitude: -116.782679, zipCode: "83540"},
	{latitude: 46.496171, longitude: -116.500541, zipCode: "83541"},
	{latitude: 45.561255, longitude: -116.32483, zipCode: "83542"},
	{latitude: 46.273201, longitude: -116.23343, zipCode: "83543"},
	{latitude: 46.490917, longitude: -116.151412, zipCode: "83544"},
	{latitude: 46.426424, longitude: -116.421793, zipCode: "83545"},
	{latitude: 46.573071, longitude: -115.791519, zipCode: "83546"},
	{latitude: 45.308337, longitude: -116.344568, zipCode: "83547"},
	{latitude: 46.336718, longitude: -116.507654, zipCode: "83548"},
	{latitude: 45.361765, longitude: -116.266593, zipCode: "83549"},
	{latitude: 45.983939, longitude: -115.923312, zipCode: "83552"},
	{latitude: 46.393409, longitude: -115.897468, zipCode: "83553"},
	{latitude: 45.688206, longitude: -116.269206, zipCode: "83554"},
	{latitude: 46.076618, longitude: -116.716222, zipCode: "83555"},
	{latitude: 44.091006, longitude: -116.146459, zipCode: "83602"},
	{latitude: 42.747481, longitude: -115.681461, zipCode: "83604"},
	{latitude: 43.664057, longitude: -116.651729, zipCode: "83605"},
	{latitude: 43.710191, longitude: -116.73763, zipCode: "83607"},
	{latitude: 44.743366, longitude: -116.77357, zipCode: "83610"},
	{latitude: 45.343874, longitude: -115.052335, zipCode: "83611"},
	{latitude: 44.849742, longitude: -116.534073, zipCode: "83612"},
	{latitude: 44.705694, longitude: -116.042463, zipCode: "83615"},
	{latitude: 43.775558, longitude: -116.394954, zipCode: "83616"},
	{latitude: 43.936819, longitude: -116.505193, zipCode: "83617"},
	{latitude: 43.969273, longitude: -116.911632, zipCode: "83619"},
	{latitude: 44.101355, longitude: -115.884587, zipCode: "83622"},
	{latitude: 43.041564, longitude: -115.400212, zipCode: "83623"},
	{latitude: 42.924882, longitude: -116.148521, zipCode: "83624"},
	{latitude: 43.667568, longitude: -116.82535, zipCode: "83626"},
	{latitude: 42.913544, longitude: -115.537294, zipCode: "83627"},
	{latitude: 43.577972, longitude: -116.965309, zipCode: "83628"},
	{latitude: 43.911871, longitude: -116.130418, zipCode: "83629"},
	{latitude: 43.884695, longitude: -115.777315, zipCode: "83631"},
	{latitude: 44.527024, longitude: -116.408436, zipCode: "83632"},
	{latitude: 43.045206, longitude: -115.198933, zipCode: "83633"},
	{latitude: 43.446499, longitude: -116.32771, zipCode: "83634"},
	{latitude: 43.906938, longitude: -116.659563, zipCode: "83636"},
	{latitude: 44.103916, longitude: -115.155157, zipCode: "83637"},
	{latitude: 45.123654, longitude: -115.714849, zipCode: "83638"},
	{latitude: 43.399579, longitude: -116.89948, zipCode: "83639"},
	{latitude: 43.350004, longitude: -116.635708, zipCode: "83641"},
	{latitude: 43.573507, longitude: -116.401064, zipCode: "83642"},
	{latitude: 44.629195, longitude: -116.456067, zipCode: "83643"},
	{latitude: 43.747154, longitude: -116.584107, zipCode: "83644"},
	{latitude: 44.337832, longitude: -116.576452, zipCode: "83645"},
	{latitude: 43.649585, longitude: -116.431758, zipCode: "83646"},
	{latitude: 43.400748, longitude: -115.522903, zipCode: "83647"},
	{latitude: 43.049186, longitude: -115.86586, zipCode: "83648"},
	{latitude: 42.896316, longitude: -116.664341, zipCode: "83650"},
	{latitude: 43.586036, longitude: -116.627262, zipCode: "83651"},
	{latitude: 45.233872, longitude: -116.245041, zipCode: "83654"},
	{latitude: 43.951249, longitude: -116.788488, zipCode: "83655"},
	{latitude: 43.724975, longitude: -116.795398, zipCode: "83656"},
	{latitude: 44.236331, longitude: -116.302013, zipCode: "83657"},
	{latitude: 43.801737, longitude: -116.92799, zipCode: "83660"},
	{latitude: 44.105708, longitude: -116.704889, zipCode: "83661"},
	{latitude: 43.961533, longitude: -115.973898, zipCode: "83666"},
	{latitude: 43.724752, longitude: -116.488562, zipCode: "83669"},
	{latitude: 44.018634, longitude: -116.312919, zipCode: "83670"},
	{latitude: 44.374148, longitude: -116.957099, zipCode: "83672"},
	{latitude: 43.656041, longitude: -116.907523, zipCode: "83676"},
	{latitude: 45.039515, longitude: -115.257998, zipCode: "83677"},
	{latitude: 43.498853, longitude: -116.604299, zipCode: "83686"},
	{latitude: 43.609972, longitude: -116.529149, zipCode: "83687"},
	{latitude: 43.665484, longitude: -116.14435, zipCode: "83702"},
	{latitude: 43.664123, longitude: -116.241473, zipCode: "83703"},
	{latitude: 43.627161, longitude: -116.287653, zipCode: "83704"},
	{latitude: 43.554774, longitude: -116.221422, zipCode: "83705"},
	{latitude: 43.591442, longitude: -116.194224, zipCode: "83706"},
	{latitude: 43.548356, longitude: -116.285218, zipCode: "83709"},
	{latitude: 43.617164, longitude: -116.093176, zipCode: "83712"},
	{latitude: 43.639, longitude: -116.33746, zipCode: "83713"},
	{latitude: 43.706504, longitude: -116.276717, zipCode: "83714"},
	{latitude: 43.875935, longitude: -115.160367, zipCode: "83716"},
	{latitude: 43.617707, longitude: -116.199731, zipCode: "83720"},
	{latitude: 43.604822, longitude: -116.205619, zipCode: "83725"},
	{latitude: 47.959008, longitude: -116.645781, zipCode: "83801"},
	{latitude: 47.277988, longitude: -115.61877, zipCode: "83802"},
	{latitude: 48.021094, longitude: -116.372648, zipCode: "83803"},
	{latitude: 48.045283, longitude: -116.971344, zipCode: "83804"},
	{latitude: 48.832637, longitude: -116.499445, zipCode: "83805"},
	{latitude: 46.877578, longitude: -116.374066, zipCode: "83806"},
	{latitude: 47.320639, longitude: -116.064352, zipCode: "83808"},
	{latitude: 48.062337, longitude: -116.581152, zipCode: "83809"},
	{latitude: 47.542489, longitude: -116.450803, zipCode: "83810"},
	{latitude: 48.110531, longitude: -116.159009, zipCode: "83811"},
	{latitude: 47.019444, longitude: -116.284061, zipCode: "83812"},
	{latitude: 48.115223, longitude: -116.675933, zipCode: "83813"},
	{latitude: 47.69066, longitude: -116.654917, zipCode: "83814"},
	{latitude: 47.726282, longitude: -116.789948, zipCode: "83815"},
	{latitude: 48.523392, longitude: -116.846526, zipCode: "83821"},
	{latitude: 48.171288, longitude: -116.999708, zipCode: "83822"},
	{latitude: 46.820946, longitude: -116.540911, zipCode: "83823"},
	{latitude: 47.10929, longitude: -116.943362, zipCode: "83824"},
	{latitude: 48.247679, longitude: -116.607832, zipCode: "83825"},
	{latitude: 48.983727, longitude: -116.151874, zipCode: "83826"},
	{latitude: 46.778344, longitude: -116.173678, zipCode: "83827"},
	{latitude: 47.078864, longitude: -116.352745, zipCode: "83830"},
	{latitude: 46.552294, longitude: -116.909567, zipCode: "83832"},
	{latitude: 47.525342, longitude: -116.743955, zipCode: "83833"},
	{latitude: 47.008809, longitude: -116.727499, zipCode: "83834"},
	{latitude: 47.79695, longitude: -116.660251, zipCode: "83835"},
	{latitude: 48.244312, longitude: -116.192345, zipCode: "83836"},
	{latitude: 47.528666, longitude: -116.090583, zipCode: "83837"},
	{latitude: 47.622772, longitude: -116.180527, zipCode: "83839"},
	{latitude: 48.312613, longitude: -116.515056, zipCode: "83840"},
	{latitude: 48.200063, longitude: -116.769838, zipCode: "83841"},
	{latitude: 47.444837, longitude: -116.546548, zipCode: "83842"},
	{latitude: 46.722245, longitude: -116.940158, zipCode: "83843"},
	{latitude: 46.728763, longitude: -117.019905, zipCode: "83844"},
	{latitude: 48.742885, longitude: -116.095552, zipCode: "83845"},
	{latitude: 47.485189, longitude: -115.734588, zipCode: "83846"},
	{latitude: 48.578903, longitude: -116.429984, zipCode: "83847"},
	{latitude: 48.72267, longitude: -116.914992, zipCode: "83848"},
	{latitude: 47.538098, longitude: -115.991959, zipCode: "83849"},
	{latitude: 47.443958, longitude: -116.207333, zipCode: "83850"},
	{latitude: 47.296344, longitude: -116.899761, zipCode: "83851"},
	{latitude: 48.317847, longitude: -116.533723, zipCode: "83852"},
	{latitude: 47.726224, longitude: -116.976167, zipCode: "83854"},
	{latitude: 46.994784, longitude: -116.888154, zipCode: "83855"},
	{latitude: 48.332471, longitude: -116.898893, zipCode: "83856"},
	{latitude: 46.886607, longitude: -116.811269, zipCode: "83857"},
	{latitude: 47.855304, longitude: -116.903837, zipCode: "83858"},
	{latitude: 48.180541, longitude: -116.536223, zipCode: "83860"},
	{latitude: 47.249038, longitude: -116.586899, zipCode: "83861"},
	{latitude: 48.4349, longitude: -116.504282, zipCode: "83864"},
	{latitude: 47.142833, longitude: -116.44001, zipCode: "83866"},
	{latitude: 47.506245, longitude: -115.946984, zipCode: "83867"},
	{latitude: 47.516331, longitude: -116.182813, zipCode: "83868"},
	{latitude: 47.982345, longitude: -116.892358, zipCode: "83869"},
	{latitude: 47.138599, longitude: -116.853614, zipCode: "83870"},
	{latitude: 46.7419, longitude: -116.742752, zipCode: "83871"},
	{latitude: 46.852373, longitude: -116.973137, zipCode: "83872"},
	{latitude: 47.582915, longitude: -115.843529, zipCode: "83873"},
	{latitude: 47.643153, longitude: -115.804985, zipCode: "83874"},
	{latitude: 47.455719, longitude: -116.948921, zipCode: "83876"},
	{latitude: 40.345248, longitude: -110.273487, zipCode: "84001"},
	{latitude: 40.431158, longitude: -110.288359, zipCode: "84002"},
	{latitude: 40.471654, longitude: -111.68059, zipCode: "84003"},
	{latitude: 40.488843, longitude: -111.727776, zipCode: "84004"},
	{latitude: 40.322736, longitude: -111.999426, zipCode: "84005"},
	{latitude: 40.596926, longitude: -112.1238, zipCode: "84006"},
	{latitude: 40.345116, longitude: -110.203186, zipCode: "84007"},
	{latitude: 40.03344, longitude: -109.193763, zipCode: "84008"},
	{latitude: 40.553454, longitude: -112.018129, zipCode: "84009"},
	{latitude: 40.873122, longitude: -111.835036, zipCode: "84010"},
	{latitude: 40.295983, longitude: -112.094474, zipCode: "84013"},
	{latitude: 40.936646, longitude: -111.833312, zipCode: "84014"},
	{latitude: 41.120356, longitude: -112.060775, zipCode: "84015"},
	{latitude: 40.956239, longitude: -111.214298, zipCode: "84017"},
	{latitude: 41.221884, longitude: -111.448409, zipCode: "84018"},
	{latitude: 40.497673, longitude: -111.866118, zipCode: "84020"},
	{latitude: 40.108226, longitude: -110.533757, zipCode: "84021"},
	{latitude: 40.350464, longitude: -112.640285, zipCode: "84022"},
	{latitude: 40.903341, longitude: -109.217325, zipCode: "84023"},
	{latitude: 41.007673, longitude: -111.447161, zipCode: "84024"},
	{latitude: 40.975812, longitude: -111.899335, zipCode: "84025"},
	{latitude: 40.294056, longitude: -109.845098, zipCode: "84026"},
	{latitude: 40.15519, longitude: -110.777931, zipCode: "84027"},
	{latitude: 41.937391, longitude: -111.389504, zipCode: "84028"},
	{latitude: 40.572887, longitude: -112.953253, zipCode: "84029"},
	{latitude: 40.650103, longitude: -110.656708, zipCode: "84031"},
	{latitude: 40.369309, longitude: -111.187015, zipCode: "84032"},
	{latitude: 40.979974, longitude: -111.49149, zipCode: "84033"},
	{latitude: 39.992371, longitude: -113.878933, zipCode: "84034"},
	{latitude: 40.408831, longitude: -109.292489, zipCode: "84035"},
	{latitude: 40.699219, longitude: -111.020425, zipCode: "84036"},
	{latitude: 41.02248, longitude: -111.948762, zipCode: "84037"},
	{latitude: 41.892017, longitude: -111.263545, zipCode: "84038"},
	{latitude: 40.440132, longitude: -109.812742, zipCode: "84039"},
	{latitude: 41.091695, longitude: -111.897185, zipCode: "84040"},
	{latitude: 41.069929, longitude: -111.981255, zipCode: "84041"},
	{latitude: 40.341125, longitude: -111.724706, zipCode: "84042"},
	{latitude: 40.411552, longitude: -111.87428, zipCode: "84043"},
	{latitude: 40.736085, longitude: -112.121213, zipCode: "84044"},
	{latitude: 40.292383, longitude: -111.928756, zipCode: "84045"},
	{latitude: 40.952558, longitude: -109.796276, zipCode: "84046"},
	{latitude: 40.615317, longitude: -111.891815, zipCode: "84047"},
	{latitude: 40.504123, longitude: -111.513399, zipCode: "84049"},
	{latitude: 41.024099, longitude: -111.664031, zipCode: "84050"},
	{latitude: 40.389463, longitude: -110.427015, zipCode: "84051"},
	{latitude: 40.166509, longitude: -109.997739, zipCode: "84052"},
	{latitude: 40.434984, longitude: -109.986655, zipCode: "84053"},
	{latitude: 40.838644, longitude: -111.91785, zipCode: "84054"},
	{latitude: 40.745066, longitude: -111.149113, zipCode: "84055"},
	{latitude: 41.129126, longitude: -111.99193, zipCode: "84056"},
	{latitude: 40.314256, longitude: -111.709495, zipCode: "84057"},
	{latitude: 40.274761, longitude: -111.714706, zipCode: "84058"},
	{latitude: 40.303539, longitude: -111.748581, zipCode: "84059"},
	{latitude: 40.644391, longitude: -111.506642, zipCode: "84060"},
	{latitude: 40.764039, longitude: -111.318822, zipCode: "84061"},
	{latitude: 40.383842, longitude: -111.681871, zipCode: "84062"},
	{latitude: 40.211814, longitude: -109.694218, zipCode: "84063"},
	{latitude: 41.682918, longitude: -111.112032, zipCode: "84064"},
	{latitude: 40.493936, longitude: -111.943725, zipCode: "84065"},
	{latitude: 40.346286, longitude: -110.067282, zipCode: "84066"},
	{latitude: 41.171885, longitude: -112.048033, zipCode: "84067"},
	{latitude: 40.365189, longitude: -112.485465, zipCode: "84069"},
	{latitude: 40.577114, longitude: -111.889106, zipCode: "84070"},
	{latitude: 40.353814, longitude: -112.267902, zipCode: "84071"},
	{latitude: 40.364041, longitude: -110.632985, zipCode: "84072"},
	{latitude: 40.345859, longitude: -110.41634, zipCode: "84073"},
	{latitude: 40.582074, longitude: -112.303592, zipCode: "84074"},
	{latitude: 41.056525, longitude: -112.069852, zipCode: "84075"},
	{latitude: 40.447153, longitude: -109.840165, zipCode: "84076"},
	{latitude: 40.465526, longitude: -109.510856, zipCode: "84078"},
	{latitude: 40.033229, longitude: -112.451892, zipCode: "84080"},
	{latitude: 40.602762, longitude: -112.038247, zipCode: "84081"},
	{latitude: 40.340345, longitude: -111.361662, zipCode: "84082"},
	{latitude: 40.817789, longitude: -113.739568, zipCode: "84083"},
	{latitude: 40.621392, longitude: -111.966054, zipCode: "84084"},
	{latitude: 40.578587, longitude: -109.924987, zipCode: "84085"},
	{latitude: 41.417628, longitude: -111.201226, zipCode: "84086"},
	{latitude: 40.889116, longitude: -111.92823, zipCode: "84087"},
	{latitude: 40.59507, longitude: -111.960812, zipCode: "84088"},
	{latitude: 40.551993, longitude: -111.7148, zipCode: "84092"},
	{latitude: 40.594711, longitude: -111.828542, zipCode: "84093"},
	{latitude: 40.571647, longitude: -111.861939, zipCode: "84094"},
	{latitude: 40.558566, longitude: -111.941674, zipCode: "84095"},
	{latitude: 40.487037, longitude: -112.099983, zipCode: "84096"},
	{latitude: 40.304692, longitude: -111.673174, zipCode: "84097"},
	{latitude: 40.746854, longitude: -111.532877, zipCode: "84098"},
	{latitude: 40.756311, longitude: -111.899962, zipCode: "84101"},
	{latitude: 40.75989, longitude: -111.863618, zipCode: "84102"},
	{latitude: 40.803408, longitude: -111.847108, zipCode: "84103"},
	{latitude: 40.749441, longitude: -111.981537, zipCode: "84104"},
	{latitude: 40.738346, longitude: -111.859829, zipCode: "84105"},
	{latitude: 40.706768, longitude: -111.855726, zipCode: "84106"},
	{latitude: 40.658017, longitude: -111.884519, zipCode: "84107"},
	{latitude: 40.80904, longitude: -111.727516, zipCode: "84108"},
	{latitude: 40.705812, longitude: -111.704544, zipCode: "84109"},
	{latitude: 40.755812, longitude: -111.884261, zipCode: "84111"},
	{latitude: 40.765254, longitude: -111.841362, zipCode: "84112"},
	{latitude: 40.762733, longitude: -111.832849, zipCode: "84113"},
	{latitude: 40.777946, longitude: -111.888256, zipCode: "84114"},
	{latitude: 40.714767, longitude: -111.892689, zipCode: "84115"},
	{latitude: 40.867238, longitude: -112.025095, zipCode: "84116"},
	{latitude: 40.66167, longitude: -111.835629, zipCode: "84117"},
	{latitude: 40.656001, longitude: -112.038127, zipCode: "84118"},
	{latitude: 40.700258, longitude: -111.946425, zipCode: "84119"},
	{latitude: 40.69686, longitude: -112.001744, zipCode: "84120"},
	{latitude: 40.616981, longitude: -111.686812, zipCode: "84121"},
	{latitude: 40.658368, longitude: -111.921749, zipCode: "84123"},
	{latitude: 40.677751, longitude: -111.797558, zipCode: "84124"},
	{latitude: 40.7052, longitude: -112.04257, zipCode: "84128"},
	{latitude: 40.653577, longitude: -111.962063, zipCode: "84129"},
	{latitude: 40.766735, longitude: -111.887272, zipCode: "84138"},
	{latitude: 40.7715, longitude: -111.891064, zipCode: "84150"},
	{latitude: 40.770444, longitude: -111.901105, zipCode: "84180"},
	{latitude: 41.613619, longitude: -112.127191, zipCode: "84301"},
	{latitude: 41.584713, longitude: -112.051612, zipCode: "84302"},
	{latitude: 41.821842, longitude: -111.979131, zipCode: "84304"},
	{latitude: 41.943813, longitude: -112.06436, zipCode: "84305"},
	{latitude: 41.784766, longitude: -112.053817, zipCode: "84306"},
	{latitude: 41.480119, longitude: -112.537206, zipCode: "84307"},
	{latitude: 41.966388, longitude: -111.967628, zipCode: "84308"},
	{latitude: 41.713846, longitude: -112.099135, zipCode: "84309"},
	{latitude: 41.335387, longitude: -111.846823, zipCode: "84310"},
	{latitude: 41.820132, longitude: -112.110569, zipCode: "84311"},
	{latitude: 41.7773, longitude: -112.156094, zipCode: "84312"},
	{latitude: 41.367926, longitude: -113.682487, zipCode: "84313"},
	{latitude: 41.649382, longitude: -112.092944, zipCode: "84314"},
	{latitude: 41.167508, longitude: -112.137773, zipCode: "84315"},
	{latitude: 41.778746, longitude: -112.448024, zipCode: "84316"},
	{latitude: 41.307551, longitude: -111.620162, zipCode: "84317"},
	{latitude: 41.801665, longitude: -111.804226, zipCode: "84318"},
	{latitude: 41.593349, longitude: -111.660033, zipCode: "84319"},
	{latitude: 41.952943, longitude: -111.852109, zipCode: "84320"},
	{latitude: 41.774813, longitude: -111.712761, zipCode: "84321"},
	{latitude: 41.758224, longitude: -111.812556, zipCode: "84322"},
	{latitude: 41.494331, longitude: -111.941164, zipCode: "84324"},
	{latitude: 41.737236, longitude: -111.997879, zipCode: "84325"},
	{latitude: 41.672326, longitude: -111.817761, zipCode: "84326"},
	{latitude: 41.865561, longitude: -111.997136, zipCode: "84327"},
	{latitude: 41.539368, longitude: -111.844414, zipCode: "84328"},
	{latitude: 41.804208, longitude: -113.476254, zipCode: "84329"},
	{latitude: 41.906277, longitude: -112.170239, zipCode: "84330"},
	{latitude: 41.94356, longitude: -112.35869, zipCode: "84331"},
	{latitude: 41.70314, longitude: -111.81202, zipCode: "84332"},
	{latitude: 41.910272, longitude: -111.7985, zipCode: "84333"},
	{latitude: 41.78959, longitude: -112.15188, zipCode: "84334"},
	{latitude: 41.823969, longitude: -111.883495, zipCode: "84335"},
	{latitude: 41.877442, longitude: -112.858319, zipCode: "84336"},
	{latitude: 41.690849, longitude: -112.282644, zipCode: "84337"},
	{latitude: 41.913878, longitude: -111.935809, zipCode: "84338"},
	{latitude: 41.625291, longitude: -111.938952, zipCode: "84339"},
	{latitude: 41.402988, longitude: -112.178864, zipCode: "84340"},
	{latitude: 41.770678, longitude: -111.812392, zipCode: "84341"},
	{latitude: 41.218748, longitude: -112.032586, zipCode: "84401"},
	{latitude: 41.214976, longitude: -111.889608, zipCode: "84403"},
	{latitude: 41.269519, longitude: -112.117658, zipCode: "84404"},
	{latitude: 41.149885, longitude: -111.956861, zipCode: "84405"},
	{latitude: 41.192579, longitude: -111.939483, zipCode: "84408"},
	{latitude: 41.327146, longitude: -111.965471, zipCode: "84414"},
	{latitude: 39.530532, longitude: -110.810886, zipCode: "84501"},
	{latitude: 37.527347, longitude: -109.411511, zipCode: "84511"},
	{latitude: 37.169691, longitude: -109.565719, zipCode: "84512"},
	{latitude: 39.258422, longitude: -111.008306, zipCode: "84513"},
	{latitude: 38.910823, longitude: -109.35248, zipCode: "84515"},
	{latitude: 39.128434, longitude: -111.067081, zipCode: "84516"},
	{latitude: 39.388873, longitude: -110.876886, zipCode: "84518"},
	{latitude: 39.530686, longitude: -110.418374, zipCode: "84520"},
	{latitude: 39.417033, longitude: -110.762446, zipCode: "84521"},
	{latitude: 38.86031, longitude: -111.223206, zipCode: "84522"},
	{latitude: 39.058485, longitude: -111.220112, zipCode: "84523"},
	{latitude: 39.073151, longitude: -110.22313, zipCode: "84525"},
	{latitude: 39.681544, longitude: -110.96517, zipCode: "84526"},
	{latitude: 39.402323, longitude: -111.075783, zipCode: "84528"},
	{latitude: 39.711527, longitude: -110.773264, zipCode: "84529"},
	{latitude: 38.244079, longitude: -109.404321, zipCode: "84530"},
	{latitude: 37.059844, longitude: -109.832962, zipCode: "84531"},
	{latitude: 38.517666, longitude: -109.385155, zipCode: "84532"},
	{latitude: 37.405415, longitude: -110.537252, zipCode: "84533"},
	{latitude: 37.215644, longitude: -109.266301, zipCode: "84534"},
	{latitude: 37.849881, longitude: -109.296406, zipCode: "84535"},
	{latitude: 37.130407, longitude: -110.218555, zipCode: "84536"},
	{latitude: 39.299637, longitude: -111.173357, zipCode: "84537"},
	{latitude: 39.5761, longitude: -110.400681, zipCode: "84539"},
	{latitude: 39.216507, longitude: -109.520457, zipCode: "84540"},
	{latitude: 39.742906, longitude: -110.48845, zipCode: "84542"},
	{latitude: 40.227242, longitude: -111.69581, zipCode: "84601"},
	{latitude: 40.254108, longitude: -111.650082, zipCode: "84602"},
	{latitude: 40.318664, longitude: -111.576954, zipCode: "84604"},
	{latitude: 40.214921, longitude: -111.626174, zipCode: "84606"},
	{latitude: 38.922437, longitude: -111.924302, zipCode: "84620"},
	{latitude: 39.05142, longitude: -111.835095, zipCode: "84621"},
	{latitude: 39.098174, longitude: -111.813528, zipCode: "84622"},
	{latitude: 39.453838, longitude: -111.58845, zipCode: "84623"},
	{latitude: 39.382623, longitude: -112.59373, zipCode: "84624"},
	{latitude: 39.936023, longitude: -111.984892, zipCode: "84626"},
	{latitude: 39.355361, longitude: -111.585664, zipCode: "84627"},
	{latitude: 39.935745, longitude: -112.1343, zipCode: "84628"},
	{latitude: 39.765512, longitude: -111.452879, zipCode: "84629"},
	{latitude: 39.310615, longitude: -111.834217, zipCode: "84630"},
	{latitude: 38.997719, longitude: -112.396775, zipCode: "84631"},
	{latitude: 39.64495, longitude: -111.646511, zipCode: "84632"},
	{latitude: 39.939789, longitude: -111.891247, zipCode: "84633"},
	{latitude: 39.17805, longitude: -111.834167, zipCode: "84634"},
	{latitude: 39.39071, longitude: -112.721528, zipCode: "84635"},
	{latitude: 39.139593, longitude: -112.338879, zipCode: "84636"},
	{latitude: 38.721755, longitude: -112.487146, zipCode: "84637"},
	{latitude: 39.501927, longitude: -112.257709, zipCode: "84638"},
	{latitude: 39.507173, longitude: -111.900107, zipCode: "84639"},
	{latitude: 39.46475, longitude: -112.392214, zipCode: "84640"},
	{latitude: 39.256568, longitude: -111.552177, zipCode: "84642"},
	{latitude: 39.128724, longitude: -111.684878, zipCode: "84643"},
	{latitude: 38.848875, longitude: -112.33208, zipCode: "84644"},
	{latitude: 39.860513, longitude: -111.840052, zipCode: "84645"},
	{latitude: 39.541055, longitude: -111.608795, zipCode: "84646"},
	{latitude: 39.527088, longitude: -111.40656, zipCode: "84647"},
	{latitude: 39.686596, longitude: -111.868172, zipCode: "84648"},
	{latitude: 39.346577, longitude: -112.330014, zipCode: "84649"},
	{latitude: 39.957762, longitude: -111.693744, zipCode: "84651"},
	{latitude: 39.017239, longitude: -111.875876, zipCode: "84652"},
	{latitude: 40.047985, longitude: -111.668488, zipCode: "84653"},
	{latitude: 38.864166, longitude: -111.678983, zipCode: "84654"},
	{latitude: 39.971313, longitude: -111.80682, zipCode: "84655"},
	{latitude: 39.235172, longitude: -112.108787, zipCode: "84656"},
	{latitude: 38.818586, longitude: -111.938937, zipCode: "84657"},
	{latitude: 40.040637, longitude: -111.419114, zipCode: "84660"},
	{latitude: 39.439773, longitude: -111.485741, zipCode: "84662"},
	{latitude: 40.192659, longitude: -111.489219, zipCode: "84663"},
	{latitude: 40.126231, longitude: -111.577176, zipCode: "84664"},
	{latitude: 39.192121, longitude: -111.616247, zipCode: "84665"},
	{latitude: 39.500344, longitude: -111.664838, zipCode: "84667"},
	{latitude: 38.590731, longitude: -111.800446, zipCode: "84701"},
	{latitude: 37.469176, longitude: -112.510553, zipCode: "84710"},
	{latitude: 38.725549, longitude: -112.048812, zipCode: "84711"},
	{latitude: 38.136558, longitude: -111.975577, zipCode: "84712"},
	{latitude: 38.332558, longitude: -112.516895, zipCode: "84713"},
	{latitude: 37.838191, longitude: -113.765957, zipCode: "84714"},
	{latitude: 38.332217, longitude: -111.603131, zipCode: "84715"},
	{latitude: 37.944418, longitude: -111.44226, zipCode: "84716"},
	{latitude: 37.579509, longitude: -112.083025, zipCode: "84718"},
	{latitude: 37.660881, longitude: -112.781467, zipCode: "84719"},
	{latitude: 37.593409, longitude: -113.292337, zipCode: "84720"},
	{latitude: 37.768677, longitude: -113.133595, zipCode: "84721"},
	{latitude: 37.484812, longitude: -113.555594, zipCode: "84722"},
	{latitude: 38.175495, longitude: -112.249497, zipCode: "84723"},
	{latitude: 38.674153, longitude: -112.150144, zipCode: "84724"},
	{latitude: 37.540352, longitude: -113.780206, zipCode: "84725"},
	{latitude: 37.753358, longitude: -111.580362, zipCode: "84726"},
	{latitude: 39.333131, longitude: -113.912398, zipCode: "84728"},
	{latitude: 37.382639, longitude: -112.66015, zipCode: "84729"},
	{latitude: 38.736397, longitude: -112.019284, zipCode: "84730"},
	{latitude: 38.186485, longitude: -112.763384, zipCode: "84731"},
	{latitude: 38.445321, longitude: -111.904087, zipCode: "84732"},
	{latitude: 37.29713, longitude: -113.805681, zipCode: "84733"},
	{latitude: 38.36269, longitude: -110.576846, zipCode: "84734"},
	{latitude: 37.64493, longitude: -112.449555, zipCode: "84735"},
	{latitude: 37.663277, longitude: -111.787795, zipCode: "84736"},
	{latitude: 37.029351, longitude: -113.246397, zipCode: "84737"},
	{latitude: 37.216322, longitude: -113.698554, zipCode: "84738"},
	{latitude: 38.641996, longitude: -112.222964, zipCode: "84739"},
	{latitude: 38.284281, longitude: -112.264387, zipCode: "84740"},
	{latitude: 37.188452, longitude: -112.249236, zipCode: "84741"},
	{latitude: 37.517737, longitude: -113.158779, zipCode: "84742"},
	{latitude: 38.208247, longitude: -112.185731, zipCode: "84743"},
	{latitude: 38.626126, longitude: -111.918708, zipCode: "84744"},
	{latitude: 37.208408, longitude: -113.262655, zipCode: "84745"},
	{latitude: 37.238607, longitude: -113.350607, zipCode: "84746"},
	{latitude: 38.446277, longitude: -111.637732, zipCode: "84747"},
	{latitude: 38.354346, longitude: -111.499498, zipCode: "84749"},
	{latitude: 38.438672, longitude: -112.192102, zipCode: "84750"},
	{latitude: 38.37009, longitude: -112.920624, zipCode: "84751"},
	{latitude: 38.251007, longitude: -112.88724, zipCode: "84752"},
	{latitude: 37.965227, longitude: -113.858043, zipCode: "84753"},
	{latitude: 38.610772, longitude: -112.117106, zipCode: "84754"},
	{latitude: 37.226514, longitude: -112.7853, zipCode: "84755"},
	{latitude: 37.742488, longitude: -113.54774, zipCode: "84756"},
	{latitude: 37.46457, longitude: -113.269305, zipCode: "84757"},
	{latitude: 37.326515, longitude: -112.780576, zipCode: "84758"},
	{latitude: 37.926071, longitude: -112.390081, zipCode: "84759"},
	{latitude: 37.949582, longitude: -112.661783, zipCode: "84760"},
	{latitude: 37.852329, longitude: -112.866017, zipCode: "84761"},
	{latitude: 37.497245, longitude: -112.73698, zipCode: "84762"},
	{latitude: 37.146693, longitude: -113.069504, zipCode: "84763"},
	{latitude: 37.785331, longitude: -111.932454, zipCode: "84764"},
	{latitude: 37.337765, longitude: -114.017951, zipCode: "84765"},
	{latitude: 38.598516, longitude: -112.345688, zipCode: "84766"},
	{latitude: 37.201399, longitude: -112.985805, zipCode: "84767"},
	{latitude: 37.13839, longitude: -113.629601, zipCode: "84770"},
	{latitude: 37.793286, longitude: -112.947567, zipCode: "84772"},
	{latitude: 38.25075, longitude: -111.470479, zipCode: "84773"},
	{latitude: 37.243249, longitude: -113.280893, zipCode: "84774"},
	{latitude: 38.289608, longitude: -111.03342, zipCode: "84775"},
	{latitude: 37.62961, longitude: -112.08248, zipCode: "84776"},
	{latitude: 37.334886, longitude: -113.122148, zipCode: "84779"},
	{latitude: 37.133167, longitude: -113.473684, zipCode: "84780"},
	{latitude: 37.396842, longitude: -113.475054, zipCode: "84781"},
	{latitude: 37.379912, longitude: -113.667644, zipCode: "84782"},
	{latitude: 37.26383, longitude: -113.682839, zipCode: "84783"},
	{latitude: 37.018671, longitude: -112.969318, zipCode: "84784"},
	{latitude: 37.046092, longitude: -113.557306, zipCode: "84790"},
	{latitude: 33.45052, longitude: -112.078343, zipCode: "85003"},
	{latitude: 33.451609, longitude: -112.06988, zipCode: "85004"},
	{latitude: 33.465234, longitude: -112.048771, zipCode: "85006"},
	{latitude: 33.446529, longitude: -112.090319, zipCode: "85007"},
	{latitude: 33.463884, longitude: -111.987763, zipCode: "85008"},
	{latitude: 33.444546, longitude: -112.128675, zipCode: "85009"},
	{latitude: 33.507577, longitude: -112.070981, zipCode: "85012"},
	{latitude: 33.511112, longitude: -112.084731, zipCode: "85013"},
	{latitude: 33.507223, longitude: -112.05769, zipCode: "85014"},
	{latitude: 33.508637, longitude: -112.102056, zipCode: "85015"},
	{latitude: 33.510166, longitude: -112.031684, zipCode: "85016"},
	{latitude: 33.508782, longitude: -112.123068, zipCode: "85017"},
	{latitude: 33.519784, longitude: -111.993594, zipCode: "85018"},
	{latitude: 33.511534, longitude: -112.143319, zipCode: "85019"},
	{latitude: 33.566061, longitude: -112.055642, zipCode: "85020"},
	{latitude: 33.561061, longitude: -112.094924, zipCode: "85021"},
	{latitude: 33.627136, longitude: -112.049222, zipCode: "85022"},
	{latitude: 33.634118, longitude: -112.093163, zipCode: "85023"},
	{latitude: 33.689593, longitude: -112.044699, zipCode: "85024"},
	{latitude: 33.448905, longitude: -111.973547, zipCode: "85026"},
	{latitude: 33.677473, longitude: -112.10228, zipCode: "85027"},
	{latitude: 33.581606, longitude: -112.008136, zipCode: "85028"},
	{latitude: 33.595414, longitude: -112.10975, zipCode: "85029"},
	{latitude: 33.493971, longitude: -112.166926, zipCode: "85031"},
	{latitude: 33.62592, longitude: -112.002503, zipCode: "85032"},
	{latitude: 33.493589, longitude: -112.214037, zipCode: "85033"},
	{latitude: 33.430385, longitude: -112.014921, zipCode: "85034"},
	{latitude: 33.471668, longitude: -112.195393, zipCode: "85035"},
	{latitude: 33.486707, longitude: -112.268258, zipCode: "85037"},
	{latitude: 33.405566, longitude: -112.025492, zipCode: "85040"},
	{latitude: 33.386995, longitude: -112.109574, zipCode: "85041"},
	{latitude: 33.355545, longitude: -112.052242, zipCode: "85042"},
	{latitude: 33.433065, longitude: -112.197172, zipCode: "85043"},
	{latitude: 33.339721, longitude: -112.006194, zipCode: "85044"},
	{latitude: 33.298893, longitude: -112.105877, zipCode: "85045"},
	{latitude: 33.301983, longitude: -112.028911, zipCode: "85048"},
	{latitude: 33.687374, longitude: -111.998931, zipCode: "85050"},
	{latitude: 33.558759, longitude: -112.132498, zipCode: "85051"},
	{latitude: 33.629595, longitude: -112.131846, zipCode: "85053"},
	{latitude: 33.681025, longitude: -111.944882, zipCode: "85054"},
	{latitude: 33.745359, longitude: -112.174635, zipCode: "85083"},
	{latitude: 33.752614, longitude: -112.090157, zipCode: "85085"},
	{latitude: 33.822515, longitude: -112.130286, zipCode: "85086"},
	{latitude: 33.925005, longitude: -112.148875, zipCode: "85087"},
	{latitude: 33.356986, longitude: -111.363091, zipCode: "85118"},
	{latitude: 33.386823, longitude: -111.508244, zipCode: "85119"},
	{latitude: 33.386881, longitude: -111.561667, zipCode: "85120"},
	{latitude: 33.137154, longitude: -111.914311, zipCode: "85121"},
	{latitude: 32.916728, longitude: -111.747967, zipCode: "85122"},
	{latitude: 32.713078, longitude: -111.678371, zipCode: "85123"},
	{latitude: 32.962844, longitude: -111.509688, zipCode: "85128"},
	{latitude: 32.653843, longitude: -111.577149, zipCode: "85131"},
	{latitude: 32.926956, longitude: -111.214093, zipCode: "85132"},
	{latitude: 32.994887, longitude: -110.779872, zipCode: "85135"},
	{latitude: 33.136377, longitude: -111.02257, zipCode: "85137"},
	{latitude: 33.017663, longitude: -111.991284, zipCode: "85138"},
	{latitude: 32.923874, longitude: -112.206708, zipCode: "85139"},
	{latitude: 33.248065, longitude: -111.489238, zipCode: "85140"},
	{latitude: 32.641808, longitude: -111.434533, zipCode: "85141"},
	{latitude: 33.192391, longitude: -111.66594, zipCode: "85142"},
	{latitude: 33.156022, longitude: -111.519755, zipCode: "85143"},
	{latitude: 32.541314, longitude: -111.391056, zipCode: "85145"},
	{latitude: 33.097774, longitude: -111.724833, zipCode: "85147"},
	{latitude: 32.904833, longitude: -111.955451, zipCode: "85172"},
	{latitude: 33.268271, longitude: -111.136483, zipCode: "85173"},
	{latitude: 32.984984, longitude: -111.446934, zipCode: "85191"},
	{latitude: 32.962942, longitude: -110.678531, zipCode: "85192"},
	{latitude: 32.813284, longitude: -111.840448, zipCode: "85193"},
	{latitude: 32.901117, longitude: -111.628048, zipCode: "85194"},
	{latitude: 33.433545, longitude: -111.8504, zipCode: "85201"},
	{latitude: 33.379757, longitude: -111.875018, zipCode: "85202"},
	{latitude: 33.450359, longitude: -111.802894, zipCode: "85203"},
	{latitude: 33.39572, longitude: -111.786608, zipCode: "85204"},
	{latitude: 33.433165, longitude: -111.71357, zipCode: "85205"},
	{latitude: 33.397432, longitude: -111.7165, zipCode: "85206"},
	{latitude: 33.453269, longitude: -111.636673, zipCode: "85207"},
	{latitude: 33.402405, longitude: -111.632073, zipCode: "85208"},
	{latitude: 33.378291, longitude: -111.634444, zipCode: "85209"},
	{latitude: 33.389733, longitude: -111.843505, zipCode: "85210"},
	{latitude: 33.320607, longitude: -111.630757, zipCode: "85212"},
	{latitude: 33.448413, longitude: -111.7662, zipCode: "85213"},
	{latitude: 33.511896, longitude: -111.579396, zipCode: "85215"},
	{latitude: 33.323511, longitude: -111.876159, zipCode: "85224"},
	{latitude: 33.317828, longitude: -111.830541, zipCode: "85225"},
	{latitude: 33.275114, longitude: -111.92509, zipCode: "85226"},
	{latitude: 33.352445, longitude: -111.812562, zipCode: "85233"},
	{latitude: 33.365502, longitude: -111.73966, zipCode: "85234"},
	{latitude: 33.322922, longitude: -111.719613, zipCode: "85236"},
	{latitude: 33.214898, longitude: -111.866427, zipCode: "85248"},
	{latitude: 33.227059, longitude: -111.7997, zipCode: "85249"},
	{latitude: 33.52713, longitude: -111.889428, zipCode: "85250"},
	{latitude: 33.494489, longitude: -111.920714, zipCode: "85251"},
	{latitude: 33.544242, longitude: -111.958823, zipCode: "85253"},
	{latitude: 33.614603, longitude: -111.949671, zipCode: "85254"},
	{latitude: 33.668188, longitude: -111.822512, zipCode: "85255"},
	{latitude: 33.500609, longitude: -111.835063, zipCode: "85256"},
	{latitude: 33.463212, longitude: -111.913762, zipCode: "85257"},
	{latitude: 33.557918, longitude: -111.892948, zipCode: "85258"},
	{latitude: 33.601041, longitude: -111.809497, zipCode: "85259"},
	{latitude: 33.608066, longitude: -111.889177, zipCode: "85260"},
	{latitude: 33.85797, longitude: -111.80515, zipCode: "85262"},
	{latitude: 33.933095, longitude: -111.559501, zipCode: "85263"},
	{latitude: 33.687759, longitude: -111.487369, zipCode: "85264"},
	{latitude: 33.762367, longitude: -111.919091, zipCode: "85266"},
	{latitude: 33.606701, longitude: -111.737015, zipCode: "85268"},
	{latitude: 33.42744, longitude: -111.934004, zipCode: "85281"},
	{latitude: 33.393984, longitude: -111.932906, zipCode: "85282"},
	{latitude: 33.364638, longitude: -111.931604, zipCode: "85283"},
	{latitude: 33.338285, longitude: -111.933906, zipCode: "85284"},
	{latitude: 33.272273, longitude: -111.833863, zipCode: "85286"},
	{latitude: 33.305282, longitude: -111.736462, zipCode: "85295"},
	{latitude: 33.332328, longitude: -111.749802, zipCode: "85296"},
	{latitude: 33.280561, longitude: -111.7333, zipCode: "85297"},
	{latitude: 33.239818, longitude: -111.728198, zipCode: "85298"},
	{latitude: 33.533729, longitude: -112.17854, zipCode: "85301"},
	{latitude: 33.56809, longitude: -112.178089, zipCode: "85302"},
	{latitude: 33.533976, longitude: -112.220843, zipCode: "85303"},
	{latitude: 33.594547, longitude: -112.179512, zipCode: "85304"},
	{latitude: 33.529964, longitude: -112.255657, zipCode: "85305"},
	{latitude: 33.623562, longitude: -112.175283, zipCode: "85306"},
	{latitude: 33.530363, longitude: -112.310045, zipCode: "85307"},
	{latitude: 33.660382, longitude: -112.184964, zipCode: "85308"},
	{latitude: 33.535553, longitude: -112.376355, zipCode: "85309"},
	{latitude: 33.700977, longitude: -112.158084, zipCode: "85310"},
	{latitude: 33.835575, longitude: -113.232201, zipCode: "85320"},
	{latitude: 32.361993, longitude: -112.584724, zipCode: "85321"},
	{latitude: 33.159113, longitude: -112.872037, zipCode: "85322"},
	{latitude: 33.419233, longitude: -112.326866, zipCode: "85323"},
	{latitude: 34.121566, longitude: -112.037863, zipCode: "85324"},
	{latitude: 33.962832, longitude: -113.869413, zipCode: "85325"},
	{latitude: 33.229169, longitude: -112.582866, zipCode: "85326"},
	{latitude: 33.252628, longitude: -114.62036, zipCode: "85328"},
	{latitude: 33.431771, longitude: -112.29641, zipCode: "85329"},
	{latitude: 33.884228, longitude: -111.939601, zipCode: "85331"},
	{latitude: 34.207147, longitude: -112.921018, zipCode: "85332"},
	{latitude: 32.976231, longitude: -113.310151, zipCode: "85333"},
	{latitude: 33.593912, longitude: -114.363356, zipCode: "85334"},
	{latitude: 33.585408, longitude: -112.326811, zipCode: "85335"},
	{latitude: 32.508773, longitude: -114.743963, zipCode: "85336"},
	{latitude: 32.975552, longitude: -112.865818, zipCode: "85337"},
	{latitude: 33.372975, longitude: -112.410829, zipCode: "85338"},
	{latitude: 33.240894, longitude: -112.142689, zipCode: "85339"},
	{latitude: 33.516382, longitude: -112.423586, zipCode: "85340"},
	{latitude: 31.972569, longitude: -112.719502, zipCode: "85341"},
	{latitude: 33.944517, longitude: -112.451343, zipCode: "85342"},
	{latitude: 33.337759, longitude: -112.690863, zipCode: "85343"},
	{latitude: 33.911407, longitude: -114.257178, zipCode: "85344"},
	{latitude: 33.572326, longitude: -112.248142, zipCode: "85345"},
	{latitude: 33.609914, longitude: -114.158252, zipCode: "85346"},
	{latitude: 32.785966, longitude: -113.806891, zipCode: "85347"},
	{latitude: 33.699178, longitude: -113.663856, zipCode: "85348"},
	{latitude: 32.47645, longitude: -114.683518, zipCode: "85349"},
	{latitude: 32.563568, longitude: -114.705499, zipCode: "85350"},
	{latitude: 33.605791, longitude: -112.28406, zipCode: "85351"},
	{latitude: 32.691195, longitude: -113.896256, zipCode: "85352"},
	{latitude: 33.417511, longitude: -112.276974, zipCode: "85353"},
	{latitude: 33.40894, longitude: -113.070116, zipCode: "85354"},
	{latitude: 33.57457, longitude: -112.452923, zipCode: "85355"},
	{latitude: 32.689354, longitude: -114.185176, zipCode: "85356"},
	{latitude: 34.020672, longitude: -113.450652, zipCode: "85357"},
	{latitude: 34.548522, longitude: -113.574386, zipCode: "85360"},
	{latitude: 33.752261, longitude: -112.629383, zipCode: "85361"},
	{latitude: 34.285469, longitude: -112.843233, zipCode: "85362"},
	{latitude: 33.584969, longitude: -112.304916, zipCode: "85363"},
	{latitude: 32.704556, longitude: -114.656304, zipCode: "85364"},
	{latitude: 33.017596, longitude: -114.110521, zipCode: "85365"},
	{latitude: 32.660831, longitude: -114.393618, zipCode: "85367"},
	{latitude: 33.967316, longitude: -114.390048, zipCode: "85371"},
	{latitude: 33.669222, longitude: -112.300263, zipCode: "85373"},
	{latitude: 33.644893, longitude: -112.395034, zipCode: "85374"},
	{latitude: 33.671383, longitude: -112.357187, zipCode: "85375"},
	{latitude: 33.823214, longitude: -111.913271, zipCode: "85377"},
	{latitude: 33.644551, longitude: -112.319905, zipCode: "85378"},
	{latitude: 33.602126, longitude: -112.374041, zipCode: "85379"},
	{latitude: 33.609138, longitude: -112.230287, zipCode: "85381"},
	{latitude: 33.655591, longitude: -112.247623, zipCode: "85382"},
	{latitude: 33.798406, longitude: -112.305179, zipCode: "85383"},
	{latitude: 33.709159, longitude: -112.446694, zipCode: "85387"},
	{latitude: 33.604213, longitude: -112.431618, zipCode: "85388"},
	{latitude: 33.903622, longitude: -112.875667, zipCode: "85390"},
	{latitude: 33.47657, longitude: -112.309428, zipCode: "85392"},
	{latitude: 33.485001, longitude: -112.405856, zipCode: "85395"},
	{latitude: 33.53652, longitude: -112.702463, zipCode: "85396"},
	{latitude: 33.559004, longitude: -110.769126, zipCode: "85501"},
	{latitude: 33.086348, longitude: -110.26669, zipCode: "85530"},
	{latitude: 32.870869, longitude: -109.780728, zipCode: "85531"},
	{latitude: 33.409566, longitude: -110.842059, zipCode: "85532"},
	{latitude: 33.287357, longitude: -109.380485, zipCode: "85533"},
	{latitude: 32.722226, longitude: -109.153128, zipCode: "85534"},
	{latitude: 32.98647, longitude: -109.912689, zipCode: "85535"},
	{latitude: 33.147511, longitude: -109.989395, zipCode: "85536"},
	{latitude: 33.335717, longitude: -110.929878, zipCode: "85539"},
	{latitude: 33.003983, longitude: -109.380872, zipCode: "85540"},
	{latitude: 34.223305, longitude: -111.347339, zipCode: "85541"},
	{latitude: 33.324677, longitude: -110.310846, zipCode: "85542"},
	{latitude: 32.953734, longitude: -109.89878, zipCode: "85543"},
	{latitude: 34.383579, longitude: -111.477279, zipCode: "85544"},
	{latitude: 33.565248, longitude: -111.140415, zipCode: "85545"},
	{latitude: 32.699939, longitude: -109.588569, zipCode: "85546"},
	{latitude: 33.548457, longitude: -110.509039, zipCode: "85550"},
	{latitude: 32.737814, longitude: -109.562282, zipCode: "85551"},
	{latitude: 32.818661, longitude: -109.785582, zipCode: "85552"},
	{latitude: 33.793838, longitude: -111.223484, zipCode: "85553"},
	{latitude: 33.996399, longitude: -110.99877, zipCode: "85554"},
	{latitude: 31.583502, longitude: -111.331128, zipCode: "85601"},
	{latitude: 32.167495, longitude: -110.411875, zipCode: "85602"},
	{latitude: 31.432669, longitude: -109.919056, zipCode: "85603"},
	{latitude: 32.307852, longitude: -109.47082, zipCode: "85605"},
	{latitude: 32.005295, longitude: -109.930093, zipCode: "85606"},
	{latitude: 31.489773, longitude: -109.336869, zipCode: "85607"},
	{latitude: 31.45693, longitude: -109.580927, zipCode: "85608"},
	{latitude: 32.069297, longitude: -110.096907, zipCode: "85609"},
	{latitude: 31.741414, longitude: -109.671897, zipCode: "85610"},
	{latitude: 31.644094, longitude: -110.511801, zipCode: "85611"},
	{latitude: 31.535877, longitude: -110.413721, zipCode: "85613"},
	{latitude: 31.8113, longitude: -110.931085, zipCode: "85614"},
	{latitude: 31.398136, longitude: -110.210651, zipCode: "85615"},
	{latitude: 31.701985, longitude: -110.322671, zipCode: "85616"},
	{latitude: 31.577488, longitude: -109.718764, zipCode: "85617"},
	{latitude: 32.776312, longitude: -110.694166, zipCode: "85618"},
	{latitude: 32.395744, longitude: -110.736666, zipCode: "85619"},
	{latitude: 31.33849, longitude: -109.944654, zipCode: "85620"},
	{latitude: 31.387974, longitude: -110.899729, zipCode: "85621"},
	{latitude: 31.803004, longitude: -111.091051, zipCode: "85622"},
	{latitude: 32.653993, longitude: -110.784488, zipCode: "85623"},
	{latitude: 31.52042, longitude: -110.722998, zipCode: "85624"},
	{latitude: 31.876026, longitude: -109.664234, zipCode: "85625"},
	{latitude: 31.359301, longitude: -109.568278, zipCode: "85626"},
	{latitude: 32.007788, longitude: -110.235535, zipCode: "85627"},
	{latitude: 31.959623, longitude: -111.109271, zipCode: "85629"},
	{latitude: 31.886839, longitude: -110.179831, zipCode: "85630"},
	{latitude: 32.581306, longitude: -110.562511, zipCode: "85631"},
	{latitude: 32.058428, longitude: -109.183668, zipCode: "85632"},
	{latitude: 31.607377, longitude: -111.530499, zipCode: "85633"},
	{latitude: 32.01236, longitude: -112.021352, zipCode: "85634"},
	{latitude: 31.553108, longitude: -110.171088, zipCode: "85635"},
	{latitude: 31.719434, longitude: -110.690906, zipCode: "85637"},
	{latitude: 31.666024, longitude: -110.059506, zipCode: "85638"},
	{latitude: 31.771716, longitude: -111.68229, zipCode: "85639"},
	{latitude: 31.569034, longitude: -111.044692, zipCode: "85640"},
	{latitude: 32.021138, longitude: -110.64626, zipCode: "85641"},
	{latitude: 32.460684, longitude: -110.000342, zipCode: "85643"},
	{latitude: 31.698208, longitude: -111.142576, zipCode: "85645"},
	{latitude: 31.621042, longitude: -111.050259, zipCode: "85646"},
	{latitude: 31.496389, longitude: -111.001338, zipCode: "85648"},
	{latitude: 31.498887, longitude: -110.201319, zipCode: "85650"},
	{latitude: 32.39879, longitude: -111.386333, zipCode: "85653"},
	{latitude: 32.415139, longitude: -111.154573, zipCode: "85654"},
	{latitude: 32.515937, longitude: -111.152624, zipCode: "85658"},
	{latitude: 32.217037, longitude: -110.971338, zipCode: "85701"},
	{latitude: 32.33942, longitude: -110.984997, zipCode: "85704"},
	{latitude: 32.271205, longitude: -110.991724, zipCode: "85705"},
	{latitude: 32.147828, longitude: -110.935368, zipCode: "85706"},
	{latitude: 32.180177, longitude: -110.889632, zipCode: "85707"},
	{latitude: 32.175766, longitude: -110.860159, zipCode: "85708"},
	{latitude: 32.141344, longitude: -110.990119, zipCode: "85709"},
	{latitude: 32.214388, longitude: -110.823663, zipCode: "85710"},
	{latitude: 32.215025, longitude: -110.883461, zipCode: "85711"},
	{latitude: 32.252876, longitude: -110.886584, zipCode: "85712"},
	{latitude: 32.19457, longitude: -111.014995, zipCode: "85713"},
	{latitude: 32.169961, longitude: -110.946558, zipCode: "85714"},
	{latitude: 32.24671, longitude: -110.836157, zipCode: "85715"},
	{latitude: 32.242195, longitude: -110.923528, zipCode: "85716"},
	{latitude: 32.313135, longitude: -110.921964, zipCode: "85718"},
	{latitude: 32.246222, longitude: -110.948769, zipCode: "85719"},
	{latitude: 32.231069, longitude: -110.949568, zipCode: "85721"},
	{latitude: 32.181859, longitude: -110.96499, zipCode: "85723"},
	{latitude: 32.240787, longitude: -110.945605, zipCode: "85724"},
	{latitude: 32.204341, longitude: -110.945294, zipCode: "85726"},
	{latitude: 32.189785, longitude: -110.720475, zipCode: "85730"},
	{latitude: 32.067613, longitude: -110.867172, zipCode: "85734"},
	{latitude: 32.103886, longitude: -111.334504, zipCode: "85735"},
	{latitude: 31.870886, longitude: -111.366976, zipCode: "85736"},
	{latitude: 32.414615, longitude: -110.946561, zipCode: "85737"},
	{latitude: 32.612171, longitude: -111.008938, zipCode: "85739"},
	{latitude: 32.335292, longitude: -111.041758, zipCode: "85741"},
	{latitude: 32.386275, longitude: -111.055323, zipCode: "85742"},
	{latitude: 32.302288, longitude: -111.181839, zipCode: "85743"},
	{latitude: 32.248976, longitude: -111.061335, zipCode: "85745"},
	{latitude: 32.069621, longitude: -111.090488, zipCode: "85746"},
	{latitude: 32.093168, longitude: -110.772312, zipCode: "85747"},
	{latitude: 32.219012, longitude: -110.752137, zipCode: "85748"},
	{latitude: 32.281851, longitude: -110.731649, zipCode: "85749"},
	{latitude: 32.29795, longitude: -110.844898, zipCode: "85750"},
	{latitude: 32.468351, longitude: -110.981507, zipCode: "85755"},
	{latitude: 32.07033, longitude: -110.906256, zipCode: "85756"},
	{latitude: 32.128356, longitude: -111.122305, zipCode: "85757"},
	{latitude: 34.314806, longitude: -110.024014, zipCode: "85901"},
	{latitude: 34.156052, longitude: -110.159067, zipCode: "85902"},
	{latitude: 34.092017, longitude: -110.420286, zipCode: "85911"},
	{latitude: 34.414631, longitude: -109.910227, zipCode: "85912"},
	{latitude: 33.858568, longitude: -109.178184, zipCode: "85920"},
	{latitude: 33.510722, longitude: -109.129127, zipCode: "85922"},
	{latitude: 34.323125, longitude: -110.340346, zipCode: "85923"},
	{latitude: 34.566622, longitude: -109.672357, zipCode: "85924"},
	{latitude: 34.01885, longitude: -109.352493, zipCode: "85925"},
	{latitude: 33.769253, longitude: -109.979275, zipCode: "85926"},
	{latitude: 33.990796, longitude: -109.47879, zipCode: "85927"},
	{latitude: 34.517988, longitude: -110.557473, zipCode: "85928"},
	{latitude: 34.171165, longitude: -109.965072, zipCode: "85929"},
	{latitude: 34.034763, longitude: -109.770668, zipCode: "85930"},
	{latitude: 34.370005, longitude: -110.915647, zipCode: "85931"},
	{latitude: 33.968777, longitude: -109.180738, zipCode: "85932"},
	{latitude: 34.357093, longitude: -110.509263, zipCode: "85933"},
	{latitude: 34.302485, longitude: -110.244323, zipCode: "85934"},
	{latitude: 34.159121, longitude: -109.927354, zipCode: "85935"},
	{latitude: 34.591725, longitude: -109.290423, zipCode: "85936"},
	{latitude: 34.569735, longitude: -110.014345, zipCode: "85937"},
	{latitude: 34.163398, longitude: -109.414295, zipCode: "85938"},
	{latitude: 34.431428, longitude: -110.085261, zipCode: "85939"},
	{latitude: 34.252948, longitude: -109.701772, zipCode: "85940"},
	{latitude: 33.891524, longitude: -110.063212, zipCode: "85941"},
	{latitude: 34.773906, longitude: -110.034184, zipCode: "85942"},
	{latitude: 35.357736, longitude: -111.771155, zipCode: "86001"},
	{latitude: 35.242516, longitude: -111.202037, zipCode: "86003"},
	{latitude: 35.380939, longitude: -111.373463, zipCode: "86004"},
	{latitude: 35.087726, longitude: -111.619198, zipCode: "86005"},
	{latitude: 35.189512, longitude: -111.654712, zipCode: "86011"},
	{latitude: 35.222777, longitude: -111.814066, zipCode: "86015"},
	{latitude: 35.752462, longitude: -111.641148, zipCode: "86016"},
	{latitude: 34.94067, longitude: -111.681081, zipCode: "86017"},
	{latitude: 35.238727, longitude: -111.969784, zipCode: "86018"},
	{latitude: 35.992798, longitude: -111.497817, zipCode: "86020"},
	{latitude: 36.940135, longitude: -113.146245, zipCode: "86021"},
	{latitude: 36.770728, longitude: -112.55829, zipCode: "86022"},
	{latitude: 36.050484, longitude: -112.013386, zipCode: "86023"},
	{latitude: 34.670921, longitude: -111.190888, zipCode: "86024"},
	{latitude: 34.892186, longitude: -110.065756, zipCode: "86025"},
	{latitude: 35.032476, longitude: -109.815501, zipCode: "86028"},
	{latitude: 34.970555, longitude: -110.01311, zipCode: "86029"},
	{latitude: 35.853914, longitude: -110.799175, zipCode: "86030"},
	{latitude: 35.363492, longitude: -110.066576, zipCode: "86031"},
	{latitude: 35.07961, longitude: -110.283924, zipCode: "86032"},
	{latitude: 36.677302, longitude: -110.256301, zipCode: "86033"},
	{latitude: 35.770876, longitude: -110.138147, zipCode: "86034"},
	{latitude: 35.405431, longitude: -111.023813, zipCode: "86035"},
	{latitude: 36.788898, longitude: -111.815496, zipCode: "86036"},
	{latitude: 34.910517, longitude: -111.489339, zipCode: "86038"},
	{latitude: 35.972151, longitude: -110.569445, zipCode: "86039"},
	{latitude: 36.659592, longitude: -111.385712, zipCode: "86040"},
	{latitude: 35.861758, longitude: -110.384151, zipCode: "86042"},
	{latitude: 35.809701, longitude: -110.533441, zipCode: "86043"},
	{latitude: 36.735526, longitude: -110.80265, zipCode: "86044"},
	{latitude: 36.141967, longitude: -111.123676, zipCode: "86045"},
	{latitude: 35.436907, longitude: -112.176239, zipCode: "86046"},
	{latitude: 35.180686, longitude: -110.620994, zipCode: "86047"},
	{latitude: 36.286604, longitude: -112.143353, zipCode: "86052"},
	{latitude: 36.758183, longitude: -111.056391, zipCode: "86053"},
	{latitude: 36.674955, longitude: -110.61808, zipCode: "86054"},
	{latitude: 34.59808, longitude: -112.420191, zipCode: "86301"},
	{latitude: 34.497122, longitude: -112.432618, zipCode: "86303"},
	{latitude: 34.769794, longitude: -112.756958, zipCode: "86305"},
	{latitude: 34.554217, longitude: -112.45286, zipCode: "86313"},
	{latitude: 34.598238, longitude: -112.330783, zipCode: "86314"},
	{latitude: 34.674028, longitude: -112.289062, zipCode: "86315"},
	{latitude: 35.374687, longitude: -112.623878, zipCode: "86320"},
	{latitude: 34.553561, longitude: -113.088961, zipCode: "86321"},
	{latitude: 34.472349, longitude: -111.807627, zipCode: "86322"},
	{latitude: 34.766056, longitude: -112.404241, zipCode: "86323"},
	{latitude: 34.820131, longitude: -112.107922, zipCode: "86324"},
	{latitude: 34.733452, longitude: -111.898858, zipCode: "86325"},
	{latitude: 34.698565, longitude: -112.013827, zipCode: "86326"},
	{latitude: 34.556792, longitude: -112.110908, zipCode: "86327"},
	{latitude: 34.485888, longitude: -112.276637, zipCode: "86329"},
	{latitude: 34.760088, longitude: -112.116994, zipCode: "86331"},
	{latitude: 34.303998, longitude: -112.617848, zipCode: "86332"},
	{latitude: 34.323041, longitude: -112.08197, zipCode: "86333"},
	{latitude: 34.94801, longitude: -112.539593, zipCode: "86334"},
	{latitude: 34.64831, longitude: -111.663313, zipCode: "86335"},
	{latitude: 34.878547, longitude: -111.858031, zipCode: "86336"},
	{latitude: 35.241004, longitude: -113.051203, zipCode: "86337"},
	{latitude: 34.572993, longitude: -112.757506, zipCode: "86338"},
	{latitude: 34.192694, longitude: -112.319545, zipCode: "86343"},
	{latitude: 34.778463, longitude: -111.785141, zipCode: "86351"},
	{latitude: 35.113668, longitude: -113.738093, zipCode: "86401"},
	{latitude: 34.476908, longitude: -114.344135, zipCode: "86403"},
	{latitude: 34.59005, longitude: -114.307833, zipCode: "86404"},
	{latitude: 34.382386, longitude: -114.080626, zipCode: "86406"},
	{latitude: 35.461006, longitude: -114.01806, zipCode: "86409"},
	{latitude: 35.447534, longitude: -113.7437, zipCode: "86411"},
	{latitude: 35.169837, longitude: -114.256898, zipCode: "86413"},
	{latitude: 35.013382, longitude: -114.549707, zipCode: "86426"},
	{latitude: 35.282882, longitude: -114.477507, zipCode: "86429"},
	{latitude: 35.425209, longitude: -114.247385, zipCode: "86431"},
	{latitude: 36.799245, longitude: -113.723952, zipCode: "86432"},
	{latitude: 34.928582, longitude: -114.358558, zipCode: "86433"},
	{latitude: 35.656412, longitude: -113.592776, zipCode: "86434"},
	{latitude: 36.005447, longitude: -112.945058, zipCode: "86435"},
	{latitude: 34.738969, longitude: -114.416933, zipCode: "86436"},
	{latitude: 35.395096, longitude: -113.597852, zipCode: "86437"},
	{latitude: 34.68319, longitude: -113.987734, zipCode: "86438"},
	{latitude: 34.889709, longitude: -114.542181, zipCode: "86440"},
	{latitude: 35.56535, longitude: -114.300069, zipCode: "86441"},
	{latitude: 35.089613, longitude: -114.560868, zipCode: "86442"},
	{latitude: 35.948754, longitude: -114.444872, zipCode: "86443"},
	{latitude: 35.9054, longitude: -114.060636, zipCode: "86444"},
	{latitude: 35.687485, longitude: -114.555935, zipCode: "86445"},
	{latitude: 35.143098, longitude: -109.609484, zipCode: "86502"},
	{latitude: 36.216887, longitude: -109.682415, zipCode: "86503"},
	{latitude: 35.860391, longitude: -109.139361, zipCode: "86504"},
	{latitude: 35.623754, longitude: -109.706962, zipCode: "86505"},
	{latitude: 35.354086, longitude: -109.258761, zipCode: "86506"},
	{latitude: 36.424039, longitude: -109.28343, zipCode: "86507"},
	{latitude: 35.352067, longitude: -109.08707, zipCode: "86508"},
	{latitude: 36.227221, longitude: -110.272115, zipCode: "86510"},
	{latitude: 35.739875, longitude: -109.368886, zipCode: "86511"},
	{latitude: 35.095074, longitude: -109.243165, zipCode: "86512"},
	{latitude: 36.905887, longitude: -109.325806, zipCode: "86514"},
	{latitude: 35.707431, longitude: -108.984322, zipCode: "86515"},
	{latitude: 36.044337, longitude: -109.971428, zipCode: "86520"},
	{latitude: 36.823128, longitude: -109.865633, zipCode: "86535"},
	{latitude: 36.421077, longitude: -109.633858, zipCode: "86538"},
	{latitude: 35.941437, longitude: -109.443978, zipCode: "86540"},
	{latitude: 36.544317, longitude: -109.142198, zipCode: "86544"},
	{latitude: 36.717772, longitude: -109.540775, zipCode: "86545"},
	{latitude: 36.548824, longitude: -109.47439, zipCode: "86547"},
	{latitude: 36.228313, longitude: -109.220314, zipCode: "86556"},
	{latitude: 35.414284, longitude: -106.38284, zipCode: "87001"},
	{latitude: 34.635642, longitude: -106.828652, zipCode: "87002"},
	{latitude: 35.368875, longitude: -106.597242, zipCode: "87004"},
	{latitude: 35.265519, longitude: -108.003, zipCode: "87005"},
	{latitude: 34.484499, longitude: -106.843476, zipCode: "87006"},
	{latitude: 34.965422, longitude: -107.429815, zipCode: "87007"},
	{latitude: 35.14945, longitude: -106.402006, zipCode: "87008"},
	{latitude: 35.399804, longitude: -106.139052, zipCode: "87010"},
	{latitude: 34.082253, longitude: -105.972281, zipCode: "87011"},
	{latitude: 36.127297, longitude: -106.651627, zipCode: "87012"},
	{latitude: 35.893886, longitude: -107.335622, zipCode: "87013"},
	{latitude: 35.172546, longitude: -107.406978, zipCode: "87014"},
	{latitude: 35.075906, longitude: -106.182984, zipCode: "87015"},
	{latitude: 34.755154, longitude: -106.01559, zipCode: "87016"},
	{latitude: 36.309675, longitude: -106.733986, zipCode: "87017"},
	{latitude: 36.108441, longitude: -107.530966, zipCode: "87018"},
	{latitude: 35.014369, longitude: -107.925009, zipCode: "87020"},
	{latitude: 35.192118, longitude: -107.878974, zipCode: "87021"},
	{latitude: 34.910012, longitude: -106.694816, zipCode: "87022"},
	{latitude: 34.600498, longitude: -106.767483, zipCode: "87023"},
	{latitude: 35.673525, longitude: -106.749408, zipCode: "87024"},
	{latitude: 35.834344, longitude: -106.617257, zipCode: "87025"},
	{latitude: 34.981149, longitude: -107.207734, zipCode: "87026"},
	{latitude: 36.127313, longitude: -106.947373, zipCode: "87027"},
	{latitude: 34.354216, longitude: -106.782522, zipCode: "87028"},
	{latitude: 36.419337, longitude: -107.043099, zipCode: "87029"},
	{latitude: 34.779132, longitude: -106.725659, zipCode: "87031"},
	{latitude: 34.867859, longitude: -106.041992, zipCode: "87032"},
	{latitude: 34.922209, longitude: -107.634373, zipCode: "87034"},
	{latitude: 34.952042, longitude: -105.909388, zipCode: "87035"},
	{latitude: 34.372063, longitude: -106.239212, zipCode: "87036"},
	{latitude: 36.188025, longitude: -107.78041, zipCode: "87037"},
	{latitude: 35.087627, longitude: -107.412347, zipCode: "87038"},
	{latitude: 35.137707, longitude: -107.412885, zipCode: "87040"},
	{latitude: 35.60168, longitude: -106.320833, zipCode: "87041"},
	{latitude: 34.828325, longitude: -106.686225, zipCode: "87042"},
	{latitude: 35.278891, longitude: -106.430926, zipCode: "87043"},
	{latitude: 35.712493, longitude: -106.65656, zipCode: "87044"},
	{latitude: 35.397086, longitude: -108.015496, zipCode: "87045"},
	{latitude: 36.285987, longitude: -106.915086, zipCode: "87046"},
	{latitude: 35.250846, longitude: -106.288772, zipCode: "87047"},
	{latitude: 35.239575, longitude: -106.627061, zipCode: "87048"},
	{latitude: 35.131868, longitude: -107.629093, zipCode: "87049"},
	{latitude: 35.036407, longitude: -107.852784, zipCode: "87051"},
	{latitude: 35.528464, longitude: -106.370598, zipCode: "87052"},
	{latitude: 35.575114, longitude: -106.882524, zipCode: "87053"},
	{latitude: 35.161578, longitude: -105.885105, zipCode: "87056"},
	{latitude: 34.996756, longitude: -106.300636, zipCode: "87059"},
	{latitude: 34.738681, longitude: -106.736554, zipCode: "87060"},
	{latitude: 34.705002, longitude: -106.381477, zipCode: "87061"},
	{latitude: 34.461861, longitude: -106.697221, zipCode: "87062"},
	{latitude: 34.554978, longitude: -105.890557, zipCode: "87063"},
	{latitude: 36.183894, longitude: -106.538655, zipCode: "87064"},
	{latitude: 34.892448, longitude: -106.645912, zipCode: "87068"},
	{latitude: 35.022532, longitude: -105.740476, zipCode: "87070"},
	{latitude: 35.608277, longitude: -106.395497, zipCode: "87072"},
	{latitude: 35.644968, longitude: -106.363648, zipCode: "87083"},
	{latitude: 35.096687, longitude: -106.643394, zipCode: "87101"},
	{latitude: 35.082357, longitude: -106.646768, zipCode: "87102"},
	{latitude: 35.103044, longitude: -106.674464, zipCode: "87104"},
	{latitude: 34.9943, longitude: -106.684771, zipCode: "87105"},
	{latitude: 35.007139, longitude: -106.60982, zipCode: "87106"},
	{latitude: 35.1378, longitude: -106.642543, zipCode: "87107"},
	{latitude: 35.072343, longitude: -106.578318, zipCode: "87108"},
	{latitude: 35.151667, longitude: -106.574947, zipCode: "87109"},
	{latitude: 35.108194, longitude: -106.578147, zipCode: "87110"},
	{latitude: 35.140776, longitude: -106.514586, zipCode: "87111"},
	{latitude: 35.100609, longitude: -106.516275, zipCode: "87112"},
	{latitude: 35.19597, longitude: -106.573215, zipCode: "87113"},
	{latitude: 35.197465, longitude: -106.681757, zipCode: "87114"},
	{latitude: 35.048917, longitude: -106.561112, zipCode: "87116"},
	{latitude: 34.997595, longitude: -106.520858, zipCode: "87117"},
	{latitude: 35.140539, longitude: -106.760969, zipCode: "87120"},
	{latitude: 35.017046, longitude: -106.888308, zipCode: "87121"},
	{latitude: 35.188529, longitude: -106.493544, zipCode: "87122"},
	{latitude: 35.061361, longitude: -106.47123, zipCode: "87123"},
	{latitude: 35.26992, longitude: -106.795643, zipCode: "87124"},
	{latitude: 35.330173, longitude: -106.710088, zipCode: "87144"},
	{latitude: 35.007689, longitude: -106.860341, zipCode: "87151"},
	{latitude: 35.521594, longitude: -108.765319, zipCode: "87301"},
	{latitude: 35.403244, longitude: -108.731483, zipCode: "87305"},
	{latitude: 35.746651, longitude: -108.595868, zipCode: "87310"},
	{latitude: 35.606614, longitude: -108.500209, zipCode: "87311"},
	{latitude: 35.429092, longitude: -108.345406, zipCode: "87312"},
	{latitude: 35.798151, longitude: -108.221042, zipCode: "87313"},
	{latitude: 34.662552, longitude: -108.550822, zipCode: "87315"},
	{latitude: 35.489015, longitude: -108.526183, zipCode: "87316"},
	{latitude: 35.58023, longitude: -108.752428, zipCode: "87317"},
	{latitude: 35.477281, longitude: -108.939644, zipCode: "87319"},
	{latitude: 35.807587, longitude: -108.871347, zipCode: "87320"},
	{latitude: 35.065303, longitude: -108.405061, zipCode: "87321"},
	{latitude: 35.485328, longitude: -108.662496, zipCode: "87322"},
	{latitude: 35.45684, longitude: -108.186127, zipCode: "87323"},
	{latitude: 35.983933, longitude: -108.572204, zipCode: "87325"},
	{latitude: 35.27705, longitude: -108.8829, zipCode: "87326"},
	{latitude: 35.071983, longitude: -108.788665, zipCode: "87327"},
	{latitude: 36.03035, longitude: -109.032494, zipCode: "87328"},
	{latitude: 35.385165, longitude: -108.457911, zipCode: "87347"},
	{latitude: 34.829377, longitude: -108.409286, zipCode: "87357"},
	{latitude: 36.133635, longitude: -108.712048, zipCode: "87364"},
	{latitude: 35.535889, longitude: -108.13794, zipCode: "87365"},
	{latitude: 35.671097, longitude: -108.78887, zipCode: "87375"},
	{latitude: 36.738158, longitude: -108.183294, zipCode: "87401"},
	{latitude: 36.796608, longitude: -108.151748, zipCode: "87402"},
	{latitude: 36.858227, longitude: -107.895466, zipCode: "87410"},
	{latitude: 36.644965, longitude: -107.547462, zipCode: "87412"},
	{latitude: 36.460675, longitude: -107.964476, zipCode: "87413"},
	{latitude: 36.827164, longitude: -108.097457, zipCode: "87415"},
	{latitude: 36.585149, longitude: -108.451585, zipCode: "87416"},
	{latitude: 36.750143, longitude: -108.351987, zipCode: "87417"},
	{latitude: 36.950686, longitude: -108.150215, zipCode: "87418"},
	{latitude: 36.919306, longitude: -107.640257, zipCode: "87419"},
	{latitude: 36.862541, longitude: -108.935813, zipCode: "87420"},
	{latitude: 36.776813, longitude: -108.527439, zipCode: "87421"},
	{latitude: 36.273138, longitude: -108.713751, zipCode: "87455"},
	{latitude: 36.452072, longitude: -108.924767, zipCode: "87461"},
	{latitude: 36.315966, longitude: -108.244437, zipCode: "87499"},
	{latitude: 35.708182, longitude: -105.851021, zipCode: "87501"},
	{latitude: 35.619795, longitude: -105.866981, zipCode: "87505"},
	{latitude: 35.824563, longitude: -105.95535, zipCode: "87506"},
	{latitude: 35.621812, longitude: -106.108636, zipCode: "87507"},
	{latitude: 35.52063, longitude: -105.945842, zipCode: "87508"},
	{latitude: 36.361964, longitude: -106.476353, zipCode: "87510"},
	{latitude: 36.088858, longitude: -105.975214, zipCode: "87511"},
	{latitude: 36.923365, longitude: -105.343529, zipCode: "87512"},
	{latitude: 36.583677, longitude: -105.701239, zipCode: "87513"},
	{latitude: 36.529556, longitude: -105.600602, zipCode: "87514"},
	{latitude: 36.557083, longitude: -106.377119, zipCode: "87515"},
	{latitude: 36.177805, longitude: -106.46628, zipCode: "87516"},
	{latitude: 36.390291, longitude: -105.792712, zipCode: "87517"},
	{latitude: 36.520228, longitude: -106.547028, zipCode: "87518"},
	{latitude: 36.764696, longitude: -105.635034, zipCode: "87519"},
	{latitude: 36.875021, longitude: -106.627345, zipCode: "87520"},
	{latitude: 36.017989, longitude: -105.612452, zipCode: "87521"},
	{latitude: 35.964027, longitude: -105.843072, zipCode: "87522"},
	{latitude: 36.01112, longitude: -105.862884, zipCode: "87523"},
	{latitude: 36.969739, longitude: -105.537486, zipCode: "87524"},
	{latitude: 36.59849, longitude: -105.498054, zipCode: "87525"},
	{latitude: 36.151131, longitude: -105.83841, zipCode: "87527"},
	{latitude: 36.785461, longitude: -106.878876, zipCode: "87528"},
	{latitude: 36.445855, longitude: -105.666136, zipCode: "87529"},
	{latitude: 36.340759, longitude: -106.201773, zipCode: "87530"},
	{latitude: 36.228167, longitude: -105.901692, zipCode: "87531"},
	{latitude: 35.989751, longitude: -106.12563, zipCode: "87532"},
	{latitude: 35.566771, longitude: -105.747189, zipCode: "87535"},
	{latitude: 36.142126, longitude: -106.259898, zipCode: "87537"},
	{latitude: 35.493179, longitude: -105.537676, zipCode: "87538"},
	{latitude: 36.385561, longitude: -105.980171, zipCode: "87539"},
	{latitude: 35.406897, longitude: -105.873481, zipCode: "87540"},
	{latitude: 36.104014, longitude: -105.657806, zipCode: "87543"},
	{latitude: 35.766966, longitude: -106.295094, zipCode: "87544"},
	{latitude: 35.810977, longitude: -106.207688, zipCode: "87547"},
	{latitude: 36.175928, longitude: -106.185341, zipCode: "87548"},
	{latitude: 36.293273, longitude: -105.964597, zipCode: "87549"},
	{latitude: 36.699368, longitude: -106.661441, zipCode: "87551"},
	{latitude: 35.670149, longitude: -105.613853, zipCode: "87552"},
	{latitude: 36.220387, longitude: -105.734355, zipCode: "87553"},
	{latitude: 36.548538, longitude: -106.070143, zipCode: "87554"},
	{latitude: 36.81216, longitude: -105.557233, zipCode: "87556"},
	{latitude: 36.295569, longitude: -105.635107, zipCode: "87557"},
	{latitude: 36.673943, longitude: -105.455517, zipCode: "87558"},
	{latitude: 35.236716, longitude: -105.526282, zipCode: "87560"},
	{latitude: 35.406624, longitude: -105.655221, zipCode: "87562"},
	{latitude: 36.61565, longitude: -105.617949, zipCode: "87564"},
	{latitude: 35.515858, longitude: -105.472867, zipCode: "87565"},
	{latitude: 36.067572, longitude: -106.077432, zipCode: "87566"},
	{latitude: 35.994015, longitude: -106.018691, zipCode: "87567"},
	{latitude: 35.350327, longitude: -105.305158, zipCode: "87569"},
	{latitude: 36.351554, longitude: -105.436519, zipCode: "87571"},
	{latitude: 35.771712, longitude: -105.707218, zipCode: "87573"},
	{latitude: 36.642865, longitude: -106.595419, zipCode: "87575"},
	{latitude: 36.640015, longitude: -106.003009, zipCode: "87577"},
	{latitude: 36.047441, longitude: -105.770114, zipCode: "87578"},
	{latitude: 36.086354, longitude: -105.531922, zipCode: "87579"},
	{latitude: 36.559553, longitude: -105.574603, zipCode: "87580"},
	{latitude: 36.505251, longitude: -106.153042, zipCode: "87581"},
	{latitude: 36.179124, longitude: -105.994457, zipCode: "87582"},
	{latitude: 35.282182, longitude: -105.35227, zipCode: "87583"},
	{latitude: 35.542113, longitude: -104.933487, zipCode: "87701"},
	{latitude: 36.382439, longitude: -105.230236, zipCode: "87710"},
	{latitude: 35.238165, longitude: -105.13906, zipCode: "87711"},
	{latitude: 35.883099, longitude: -105.16602, zipCode: "87712"},
	{latitude: 36.170787, longitude: -105.361135, zipCode: "87713"},
	{latitude: 36.464267, longitude: -105.058928, zipCode: "87714"},
	{latitude: 36.010924, longitude: -105.422539, zipCode: "87715"},
	{latitude: 36.597713, longitude: -105.239551, zipCode: "87718"},
	{latitude: 36.145776, longitude: -105.275569, zipCode: "87722"},
	{latitude: 36.058822, longitude: -105.375556, zipCode: "87723"},
	{latitude: 35.172012, longitude: -104.985298, zipCode: "87724"},
	{latitude: 36.603784, longitude: -104.594635, zipCode: "87728"},
	{latitude: 36.303412, longitude: -104.817842, zipCode: "87729"},
	{latitude: 36.108829, longitude: -104.231521, zipCode: "87730"},
	{latitude: 35.700849, longitude: -105.399029, zipCode: "87731"},
	{latitude: 35.976454, longitude: -105.465516, zipCode: "87732"},
	{latitude: 35.75266, longitude: -103.854816, zipCode: "87733"},
	{latitude: 36.169098, longitude: -105.091094, zipCode: "87734"},
	{latitude: 36.048689, longitude: -105.119781, zipCode: "87735"},
	{latitude: 35.97805, longitude: -105.180592, zipCode: "87736"},
	{latitude: 36.767149, longitude: -104.363941, zipCode: "87740"},
	{latitude: 35.871632, longitude: -105.444275, zipCode: "87742"},
	{latitude: 35.974399, longitude: -104.020342, zipCode: "87743"},
	{latitude: 35.790752, longitude: -105.266773, zipCode: "87745"},
	{latitude: 35.655214, longitude: -104.111228, zipCode: "87746"},
	{latitude: 36.354478, longitude: -104.436408, zipCode: "87747"},
	{latitude: 36.577591, longitude: -105.127259, zipCode: "87749"},
	{latitude: 35.824979, longitude: -104.927063, zipCode: "87750"},
	{latitude: 35.994218, longitude: -104.65737, zipCode: "87752"},
	{latitude: 35.863313, longitude: -104.954298, zipCode: "87753"},
	{latitude: 33.932613, longitude: -107.073841, zipCode: "87801"},
	{latitude: 33.937649, longitude: -108.58682, zipCode: "87820"},
	{latitude: 33.949882, longitude: -108.072146, zipCode: "87821"},
	{latitude: 34.183485, longitude: -106.958998, zipCode: "87823"},
	{latitude: 33.828581, longitude: -108.981719, zipCode: "87824"},
	{latitude: 33.949242, longitude: -107.655484, zipCode: "87825"},
	{latitude: 34.362281, longitude: -108.137414, zipCode: "87827"},
	{latitude: 34.205751, longitude: -106.92055, zipCode: "87828"},
	{latitude: 34.236618, longitude: -108.658481, zipCode: "87829"},
	{latitude: 33.708322, longitude: -108.732689, zipCode: "87830"},
	{latitude: 34.283021, longitude: -106.887208, zipCode: "87831"},
	{latitude: 33.842141, longitude: -106.834787, zipCode: "87832"},
	{latitude: 33.160855, longitude: -107.016787, zipCode: "87901"},
	{latitude: 32.827149, longitude: -107.351899, zipCode: "87930"},
	{latitude: 32.923922, longitude: -107.344226, zipCode: "87931"},
	{latitude: 32.791109, longitude: -107.278867, zipCode: "87933"},
	{latitude: 33.254706, longitude: -107.22865, zipCode: "87935"},
	{latitude: 32.753234, longitude: -107.260214, zipCode: "87936"},
	{latitude: 32.591271, longitude: -107.195218, zipCode: "87937"},
	{latitude: 33.373336, longitude: -107.337249, zipCode: "87939"},
	{latitude: 32.64207, longitude: -107.006626, zipCode: "87940"},
	{latitude: 32.706565, longitude: -107.214959, zipCode: "87941"},
	{latitude: 33.060968, longitude: -107.348906, zipCode: "87942"},
	{latitude: 33.450136, longitude: -107.69103, zipCode: "87943"},
	{latitude: 32.290212, longitude: -106.753138, zipCode: "88001"},
	{latitude: 32.382005, longitude: -106.457106, zipCode: "88002"},
	{latitude: 32.278723, longitude: -106.745667, zipCode: "88003"},
	{latitude: 32.250185, longitude: -106.949226, zipCode: "88004"},
	{latitude: 32.22305, longitude: -106.987591, zipCode: "88005"},
	{latitude: 32.389107, longitude: -106.975113, zipCode: "88007"},
	{latitude: 31.818657, longitude: -106.757282, zipCode: "88008"},
	{latitude: 31.912585, longitude: -108.536315, zipCode: "88009"},
	{latitude: 32.37716, longitude: -106.576316, zipCode: "88011"},
	{latitude: 32.588854, longitude: -106.771269, zipCode: "88012"},
	{latitude: 31.780985, longitude: -108.827111, zipCode: "88020"},
	{latitude: 31.987939, longitude: -106.80368, zipCode: "88021"},
	{latitude: 32.80274, longitude: -108.188137, zipCode: "88022"},
	{latitude: 32.722804, longitude: -108.088252, zipCode: "88023"},
	{latitude: 32.065401, longitude: -106.618009, zipCode: "88024"},
	{latitude: 33.108583, longitude: -108.725916, zipCode: "88025"},
	{latitude: 32.752019, longitude: -108.162996, zipCode: "88026"},
	{latitude: 32.036233, longitude: -106.681433, zipCode: "88027"},
	{latitude: 32.966912, longitude: -108.670549, zipCode: "88028"},
	{latitude: 31.844879, longitude: -107.694722, zipCode: "88029"},
	{latitude: 32.17345, longitude: -107.727421, zipCode: "88030"},
	{latitude: 32.591736, longitude: -107.965081, zipCode: "88034"},
	{latitude: 33.126399, longitude: -108.357592, zipCode: "88038"},
	{latitude: 33.293104, longitude: -108.575461, zipCode: "88039"},
	{latitude: 31.597235, longitude: -108.354972, zipCode: "88040"},
	{latitude: 32.810436, longitude: -107.882355, zipCode: "88041"},
	{latitude: 32.811929, longitude: -107.565713, zipCode: "88042"},
	{latitude: 32.602923, longitude: -108.116404, zipCode: "88043"},
	{latitude: 31.980785, longitude: -107.100742, zipCode: "88044"},
	{latitude: 32.331117, longitude: -108.700286, zipCode: "88045"},
	{latitude: 32.267757, longitude: -106.79682, zipCode: "88046"},
	{latitude: 32.214414, longitude: -106.715531, zipCode: "88047"},
	{latitude: 32.144679, longitude: -106.655035, zipCode: "88048"},
	{latitude: 33.051365, longitude: -108.05469, zipCode: "88049"},
	{latitude: 32.999584, longitude: -108.919849, zipCode: "88051"},
	{latitude: 32.450264, longitude: -106.601839, zipCode: "88052"},
	{latitude: 32.895982, longitude: -108.213797, zipCode: "88053"},
	{latitude: 32.727788, longitude: -108.740985, zipCode: "88055"},
	{latitude: 31.840794, longitude: -109.003105, zipCode: "88056"},
	{latitude: 32.628543, longitude: -108.406412, zipCode: "88061"},
	{latitude: 31.799062, longitude: -106.550459, zipCode: "88063"},
	{latitude: 32.71371, longitude: -108.302292, zipCode: "88065"},
	{latitude: 32.138064, longitude: -106.616106, zipCode: "88072"},
	{latitude: 32.219357, longitude: -106.293671, zipCode: "88081"},
	{latitude: 34.499488, longitude: -103.257721, zipCode: "88101"},
	{latitude: 34.382137, longitude: -103.316944, zipCode: "88103"},
	{latitude: 34.922782, longitude: -103.134424, zipCode: "88112"},
	{latitude: 33.804492, longitude: -103.088842, zipCode: "88113"},
	{latitude: 33.488489, longitude: -103.417431, zipCode: "88114"},
	{latitude: 33.931766, longitude: -103.34467, zipCode: "88115"},
	{latitude: 33.894862, longitude: -103.819006, zipCode: "88116"},
	{latitude: 34.229125, longitude: -103.659365, zipCode: "88118"},
	{latitude: 34.359273, longitude: -104.368696, zipCode: "88119"},
	{latitude: 34.899377, longitude: -103.424962, zipCode: "88120"},
	{latitude: 34.679819, longitude: -103.977198, zipCode: "88121"},
	{latitude: 34.536174, longitude: -103.65183, zipCode: "88124"},
	{latitude: 33.705861, longitude: -103.225418, zipCode: "88125"},
	{latitude: 33.811266, longitude: -103.372019, zipCode: "88126"},
	{latitude: 34.131526, longitude: -103.326663, zipCode: "88130"},
	{latitude: 33.907343, longitude: -103.166813, zipCode: "88132"},
	{latitude: 34.371703, longitude: -104.016711, zipCode: "88134"},
	{latitude: 34.559992, longitude: -103.077686, zipCode: "88135"},
	{latitude: 34.244075, longitude: -104.815925, zipCode: "88136"},
	{latitude: 33.668801, longitude: -104.496734, zipCode: "88201"},
	{latitude: 33.29067, longitude: -104.067489, zipCode: "88203"},
	{latitude: 32.774587, longitude: -104.224156, zipCode: "88210"},
	{latitude: 32.314867, longitude: -104.420014, zipCode: "88220"},
	{latitude: 33.21917, longitude: -104.397563, zipCode: "88230"},
	{latitude: 32.424271, longitude: -103.261279, zipCode: "88231"},
	{latitude: 33.057714, longitude: -104.146125, zipCode: "88232"},
	{latitude: 32.674675, longitude: -103.446805, zipCode: "88240"},
	{latitude: 32.812901, longitude: -103.161796, zipCode: "88242"},
	{latitude: 32.764127, longitude: -103.224949, zipCode: "88244"},
	{latitude: 32.701984, longitude: -104.955846, zipCode: "88250"},
	{latitude: 32.155238, longitude: -103.378041, zipCode: "88252"},
	{latitude: 32.983812, longitude: -104.55055, zipCode: "88253"},
	{latitude: 32.627878, longitude: -104.404823, zipCode: "88254"},
	{latitude: 32.810923, longitude: -103.99922, zipCode: "88255"},
	{latitude: 32.193262, longitude: -103.888887, zipCode: "88256"},
	{latitude: 32.993024, longitude: -103.382844, zipCode: "88260"},
	{latitude: 33.126398, longitude: -103.403889, zipCode: "88262"},
	{latitude: 32.107048, longitude: -104.096767, zipCode: "88263"},
	{latitude: 32.862974, longitude: -103.709883, zipCode: "88264"},
	{latitude: 32.584198, longitude: -103.346423, zipCode: "88265"},
	{latitude: 33.296728, longitude: -103.392341, zipCode: "88267"},
	{latitude: 32.176322, longitude: -104.379342, zipCode: "88268"},
	{latitude: 33.749089, longitude: -105.851999, zipCode: "88301"},
	{latitude: 32.735184, longitude: -106.040383, zipCode: "88310"},
	{latitude: 32.812102, longitude: -105.990607, zipCode: "88311"},
	{latitude: 33.431883, longitude: -105.64395, zipCode: "88312"},
	{latitude: 33.110333, longitude: -105.867203, zipCode: "88314"},
	{latitude: 33.770884, longitude: -105.370793, zipCode: "88316"},
	{latitude: 32.860606, longitude: -105.721271, zipCode: "88317"},
	{latitude: 34.228811, longitude: -105.512507, zipCode: "88318"},
	{latitude: 34.780375, longitude: -105.446147, zipCode: "88321"},
	{latitude: 33.485337, longitude: -105.564843, zipCode: "88323"},
	{latitude: 33.422541, longitude: -105.526952, zipCode: "88324"},
	{latitude: 32.855647, longitude: -105.853305, zipCode: "88325"},
	{latitude: 32.867091, longitude: -106.096608, zipCode: "88330"},
	{latitude: 33.331009, longitude: -105.275935, zipCode: "88336"},
	{latitude: 32.996446, longitude: -105.884495, zipCode: "88337"},
	{latitude: 33.537195, longitude: -105.384696, zipCode: "88338"},
	{latitude: 32.958055, longitude: -105.323386, zipCode: "88339"},
	{latitude: 33.176135, longitude: -105.583242, zipCode: "88340"},
	{latitude: 33.473475, longitude: -105.84559, zipCode: "88341"},
	{latitude: 32.389294, longitude: -106.108507, zipCode: "88342"},
	{latitude: 33.235068, longitude: -105.067785, zipCode: "88343"},
	{latitude: 32.508945, longitude: -105.217761, zipCode: "88344"},
	{latitude: 33.352439, longitude: -105.671005, zipCode: "88345"},
	{latitude: 33.348146, longitude: -105.540707, zipCode: "88346"},
	{latitude: 32.755123, longitude: -105.677393, zipCode: "88347"},
	{latitude: 33.414894, longitude: -105.402258, zipCode: "88348"},
	{latitude: 32.801462, longitude: -105.809662, zipCode: "88349"},
	{latitude: 32.613868, longitude: -105.65282, zipCode: "88350"},
	{latitude: 33.47152, longitude: -105.097724, zipCode: "88351"},
	{latitude: 33.165891, longitude: -105.993861, zipCode: "88352"},
	{latitude: 34.499108, longitude: -105.103307, zipCode: "88353"},
	{latitude: 32.738816, longitude: -105.494552, zipCode: "88354"},
	{latitude: 33.362914, longitude: -105.669404, zipCode: "88355"},
	{latitude: 35.074583, longitude: -103.780275, zipCode: "88401"},
	{latitude: 35.895504, longitude: -103.316361, zipCode: "88410"},
	{latitude: 35.247343, longitude: -103.17642, zipCode: "88411"},
	{latitude: 36.779584, longitude: -104.025877, zipCode: "88414"},
	{latitude: 36.362784, longitude: -103.343082, zipCode: "88415"},
	{latitude: 35.477253, longitude: -104.209466, zipCode: "88416"},
	{latitude: 34.930955, longitude: -104.244496, zipCode: "88417"},
	{latitude: 36.75136, longitude: -103.641609, zipCode: "88418"},
	{latitude: 36.899379, longitude: -103.541874, zipCode: "88419"},
	{latitude: 35.326882, longitude: -104.497305, zipCode: "88421"},
	{latitude: 36.345284, longitude: -103.991476, zipCode: "88422"},
	{latitude: 36.439537, longitude: -103.664381, zipCode: "88424"},
	{latitude: 35.518317, longitude: -103.447525, zipCode: "88426"},
	{latitude: 34.781751, longitude: -103.764187, zipCode: "88427"},
	{latitude: 35.670117, longitude: -103.209111, zipCode: "88430"},
	{latitude: 35.212488, longitude: -104.336778, zipCode: "88431"},
	{latitude: 35.120075, longitude: -103.335512, zipCode: "88434"},
	{latitude: 34.885885, longitude: -104.824923, zipCode: "88435"},
	{latitude: 36.158639, longitude: -103.158755, zipCode: "88436"},
	{latitude: 35.493775, longitude: -104.378213, zipCode: "88439"},
	{latitude: 37.337655, longitude: -115.302378, zipCode: "89001"},
	{latitude: 35.99846, longitude: -114.961803, zipCode: "89002"},
	{latitude: 36.946108, longitude: -116.764346, zipCode: "89003"},
	{latitude: 36.041627, longitude: -115.412576, zipCode: "89004"},
	{latitude: 35.999628, longitude: -114.85204, zipCode: "89005"},
	{latitude: 36.666657, longitude: -114.226747, zipCode: "89007"},
	{latitude: 37.286087, longitude: -114.513105, zipCode: "89008"},
	{latitude: 37.824694, longitude: -118.079197, zipCode: "89010"},
	{latitude: 36.084816, longitude: -114.96318, zipCode: "89011"},
	{latitude: 36.011917, longitude: -115.043158, zipCode: "89012"},
	{latitude: 37.368945, longitude: -117.262121, zipCode: "89013"},
	{latitude: 36.061545, longitude: -115.057671, zipCode: "89014"},
	{latitude: 36.03889, longitude: -114.928316, zipCode: "89015"},
	{latitude: 37.702215, longitude: -115.333645, zipCode: "89017"},
	{latitude: 36.570645, longitude: -115.668006, zipCode: "89018"},
	{latitude: 35.769491, longitude: -115.515911, zipCode: "89019"},
	{latitude: 36.562525, longitude: -116.41983, zipCode: "89020"},
	{latitude: 36.640213, longitude: -114.471026, zipCode: "89021"},
	{latitude: 38.518412, longitude: -117.014924, zipCode: "89022"},
	{latitude: 36.683211, longitude: -114.789729, zipCode: "89025"},
	{latitude: 35.749227, longitude: -115.284749, zipCode: "89026"},
	{latitude: 36.814532, longitude: -114.093334, zipCode: "89027"},
	{latitude: 35.129747, longitude: -114.655672, zipCode: "89029"},
	{latitude: 36.212305, longitude: -115.123134, zipCode: "89030"},
	{latitude: 36.258457, longitude: -115.170743, zipCode: "89031"},
	{latitude: 36.222386, longitude: -115.173585, zipCode: "89032"},
	{latitude: 36.809235, longitude: -114.149779, zipCode: "89034"},
	{latitude: 35.26574, longitude: -114.872449, zipCode: "89039"},
	{latitude: 36.349077, longitude: -114.52233, zipCode: "89040"},
	{latitude: 37.798162, longitude: -114.358597, zipCode: "89042"},
	{latitude: 38.277492, longitude: -114.407217, zipCode: "89043"},
	{latitude: 35.93987, longitude: -115.056285, zipCode: "89044"},
	{latitude: 38.873308, longitude: -117.098251, zipCode: "89045"},
	{latitude: 35.513924, longitude: -114.810598, zipCode: "89046"},
	{latitude: 37.757726, longitude: -117.63865, zipCode: "89047"},
	{latitude: 36.163631, longitude: -115.98748, zipCode: "89048"},
	{latitude: 38.390984, longitude: -116.399315, zipCode: "89049"},
	{latitude: 35.979874, longitude: -115.107944, zipCode: "89052"},
	{latitude: 35.920973, longitude: -115.190516, zipCode: "89054"},
	{latitude: 36.391556, longitude: -116.057568, zipCode: "89060"},
	{latitude: 36.07011, longitude: -115.906249, zipCode: "89061"},
	{latitude: 36.500819, longitude: -115.63956, zipCode: "89070"},
	{latitude: 36.03637, longitude: -115.081186, zipCode: "89074"},
	{latitude: 36.258477, longitude: -115.107492, zipCode: "89081"},
	{latitude: 36.297486, longitude: -115.156932, zipCode: "89084"},
	{latitude: 36.309602, longitude: -115.198175, zipCode: "89085"},
	{latitude: 36.2836, longitude: -115.109962, zipCode: "89086"},
	{latitude: 36.172788, longitude: -115.122324, zipCode: "89101"},
	{latitude: 36.14534, longitude: -115.186607, zipCode: "89102"},
	{latitude: 36.11236, longitude: -115.212148, zipCode: "89103"},
	{latitude: 36.151188, longitude: -115.10937, zipCode: "89104"},
	{latitude: 36.182066, longitude: -115.162487, zipCode: "89106"},
	{latitude: 36.171241, longitude: -115.209288, zipCode: "89107"},
	{latitude: 36.205088, longitude: -115.223683, zipCode: "89108"},
	{latitude: 36.131622, longitude: -115.169932, zipCode: "89109"},
	{latitude: 36.173653, longitude: -115.055479, zipCode: "89110"},
	{latitude: 36.060204, longitude: -115.262325, zipCode: "89113"},
	{latitude: 36.253702, longitude: -115.042936, zipCode: "89115"},
	{latitude: 36.140961, longitude: -115.281091, zipCode: "89117"},
	{latitude: 36.077788, longitude: -115.213011, zipCode: "89118"},
	{latitude: 36.08452, longitude: -115.146071, zipCode: "89119"},
	{latitude: 36.080652, longitude: -115.095868, zipCode: "89120"},
	{latitude: 36.12141, longitude: -115.091367, zipCode: "89121"},
	{latitude: 36.103965, longitude: -115.037826, zipCode: "89122"},
	{latitude: 36.035216, longitude: -115.149123, zipCode: "89123"},
	{latitude: 36.391806, longitude: -115.68302, zipCode: "89124"},
	{latitude: 36.196784, longitude: -115.264406, zipCode: "89128"},
	{latitude: 36.23325, longitude: -115.290253, zipCode: "89129"},
	{latitude: 36.253482, longitude: -115.226706, zipCode: "89130"},
	{latitude: 36.306143, longitude: -115.243024, zipCode: "89131"},
	{latitude: 36.202627, longitude: -115.307808, zipCode: "89134"},
	{latitude: 36.095302, longitude: -115.37165, zipCode: "89135"},
	{latitude: 36.202093, longitude: -115.364999, zipCode: "89138"},
	{latitude: 36.034324, longitude: -115.211139, zipCode: "89139"},
	{latitude: 35.988147, longitude: -115.207423, zipCode: "89141"},
	{latitude: 36.148113, longitude: -115.031001, zipCode: "89142"},
	{latitude: 36.321693, longitude: -115.292872, zipCode: "89143"},
	{latitude: 36.177489, longitude: -115.319431, zipCode: "89144"},
	{latitude: 36.167493, longitude: -115.276239, zipCode: "89145"},
	{latitude: 36.14325, longitude: -115.226789, zipCode: "89146"},
	{latitude: 36.112892, longitude: -115.27987, zipCode: "89147"},
	{latitude: 36.063355, longitude: -115.296763, zipCode: "89148"},
	{latitude: 36.270796, longitude: -115.294623, zipCode: "89149"},
	{latitude: 36.159032, longitude: -114.981742, zipCode: "89156"},
	{latitude: 36.104781, longitude: -115.176621, zipCode: "89158"},
	{latitude: 36.03739, longitude: -115.508236, zipCode: "89161"},
	{latitude: 36.337311, longitude: -115.468547, zipCode: "89166"},
	{latitude: 36.124089, longitude: -115.141811, zipCode: "89169"},
	{latitude: 35.987827, longitude: -115.295352, zipCode: "89178"},
	{latitude: 35.891605, longitude: -115.327935, zipCode: "89179"},
	{latitude: 35.996194, longitude: -115.157976, zipCode: "89183"},
	{latitude: 36.247282, longitude: -115.004186, zipCode: "89191"},
	{latitude: 39.531134, longitude: -114.767404, zipCode: "89301"},
	{latitude: 39.474204, longitude: -117.140299, zipCode: "89310"},
	{latitude: 39.236499, longitude: -114.140629, zipCode: "89311"},
	{latitude: 38.862677, longitude: -115.641802, zipCode: "89314"},
	{latitude: 39.185485, longitude: -114.844867, zipCode: "89315"},
	{latitude: 39.671344, longitude: -116.013637, zipCode: "89316"},
	{latitude: 38.904533, longitude: -115.016277, zipCode: "89317"},
	{latitude: 39.438588, longitude: -114.817216, zipCode: "89318"},
	{latitude: 39.279172, longitude: -114.987342, zipCode: "89319"},
	{latitude: 39.230418, longitude: -120.002174, zipCode: "89402"},
	{latitude: 39.227048, longitude: -119.474346, zipCode: "89403"},
	{latitude: 41.841663, longitude: -118.878658, zipCode: "89404"},
	{latitude: 40.450473, longitude: -119.469236, zipCode: "89405"},
	{latitude: 39.358998, longitude: -117.946764, zipCode: "89406"},
	{latitude: 39.670843, longitude: -119.006409, zipCode: "89408"},
	{latitude: 38.7265, longitude: -117.949713, zipCode: "89409"},
	{latitude: 38.783116, longitude: -119.55929, zipCode: "89410"},
	{latitude: 39.018363, longitude: -119.853038, zipCode: "89411"},
	{latitude: 41.121163, longitude: -119.704982, zipCode: "89412"},
	{latitude: 39.073447, longitude: -119.922857, zipCode: "89413"},
	{latitude: 41.061054, longitude: -117.220629, zipCode: "89414"},
	{latitude: 38.517193, longitude: -118.788741, zipCode: "89415"},
	{latitude: 40.574412, longitude: -118.041481, zipCode: "89418"},
	{latitude: 40.342885, longitude: -118.287699, zipCode: "89419"},
	{latitude: 38.508039, longitude: -118.180527, zipCode: "89420"},
	{latitude: 42.044517, longitude: -117.947009, zipCode: "89421"},
	{latitude: 38.430363, longitude: -118.180623, zipCode: "89422"},
	{latitude: 38.997135, longitude: -119.735334, zipCode: "89423"},
	{latitude: 40.108309, longitude: -119.448215, zipCode: "89424"},
	{latitude: 41.658303, longitude: -117.881592, zipCode: "89425"},
	{latitude: 41.477569, longitude: -117.61734, zipCode: "89426"},
	{latitude: 38.899942, longitude: -118.645827, zipCode: "89427"},
	{latitude: 39.256879, longitude: -119.630973, zipCode: "89428"},
	{latitude: 39.356861, longitude: -119.269648, zipCode: "89429"},
	{latitude: 38.795686, longitude: -119.317837, zipCode: "89430"},
	{latitude: 39.538324, longitude: -119.749794, zipCode: "89431"},
	{latitude: 39.597562, longitude: -119.778137, zipCode: "89433"},
	{latitude: 39.540617, longitude: -119.543993, zipCode: "89434"},
	{latitude: 39.617274, longitude: -119.704343, zipCode: "89436"},
	{latitude: 39.501156, longitude: -119.468805, zipCode: "89437"},
	{latitude: 40.80374, longitude: -117.127161, zipCode: "89438"},
	{latitude: 39.523352, longitude: -119.990384, zipCode: "89439"},
	{latitude: 39.296058, longitude: -119.662018, zipCode: "89440"},
	{latitude: 39.68139, longitude: -119.687547, zipCode: "89441"},
	{latitude: 39.693035, longitude: -119.344768, zipCode: "89442"},
	{latitude: 38.739834, longitude: -119.38272, zipCode: "89444"},
	{latitude: 41.121069, longitude: -118.008548, zipCode: "89445"},
	{latitude: 40.878797, longitude: -117.953217, zipCode: "89446"},
	{latitude: 38.829718, longitude: -119.11477, zipCode: "89447"},
	{latitude: 38.998185, longitude: -119.914964, zipCode: "89448"},
	{latitude: 38.955158, longitude: -119.911473, zipCode: "89449"},
	{latitude: 39.241348, longitude: -119.938309, zipCode: "89450"},
	{latitude: 39.269009, longitude: -119.945726, zipCode: "89451"},
	{latitude: 38.902253, longitude: -119.792493, zipCode: "89460"},
	{latitude: 39.19947, longitude: -118.571012, zipCode: "89496"},
	{latitude: 39.526012, longitude: -119.812637, zipCode: "89501"},
	{latitude: 39.494062, longitude: -119.742329, zipCode: "89502"},
	{latitude: 39.541037, longitude: -119.840128, zipCode: "89503"},
	{latitude: 39.698837, longitude: -119.826268, zipCode: "89506"},
	{latitude: 39.78062, longitude: -119.913755, zipCode: "89508"},
	{latitude: 39.497313, longitude: -119.826926, zipCode: "89509"},
	{latitude: 39.926336, longitude: -119.710793, zipCode: "89510"},
	{latitude: 39.425376, longitude: -119.871891, zipCode: "89511"},
	{latitude: 39.550957, longitude: -119.801071, zipCode: "89512"},
	{latitude: 39.478968, longitude: -119.857134, zipCode: "89519"},
	{latitude: 39.371633, longitude: -119.675912, zipCode: "89521"},
	{latitude: 39.526594, longitude: -119.915771, zipCode: "89523"},
	{latitude: 39.542443, longitude: -119.815919, zipCode: "89557"},
	{latitude: 39.12893, longitude: -119.725413, zipCode: "89701"},
	{latitude: 39.136939, longitude: -119.869938, zipCode: "89703"},
	{latitude: 39.275258, longitude: -119.787456, zipCode: "89704"},
	{latitude: 39.090042, longitude: -119.836881, zipCode: "89705"},
	{latitude: 39.222737, longitude: -119.701574, zipCode: "89706"},
	{latitude: 41.165012, longitude: -115.757235, zipCode: "89801"},
	{latitude: 40.56473, longitude: -115.596546, zipCode: "89815"},
	{latitude: 40.428841, longitude: -116.961096, zipCode: "89820"},
	{latitude: 40.555728, longitude: -116.415688, zipCode: "89821"},
	{latitude: 40.4922, longitude: -116.188965, zipCode: "89822"},
	{latitude: 40.950306, longitude: -115.160662, zipCode: "89823"},
	{latitude: 41.904428, longitude: -114.709644, zipCode: "89825"},
	{latitude: 41.930723, longitude: -115.410766, zipCode: "89826"},
	{latitude: 40.735333, longitude: -115.34339, zipCode: "89828"},
	{latitude: 41.321657, longitude: -114.177126, zipCode: "89830"},
	{latitude: 41.715996, longitude: -116.098342, zipCode: "89831"},
	{latitude: 41.978749, longitude: -116.191836, zipCode: "89832"},
	{latitude: 40.482842, longitude: -115.331629, zipCode: "89833"},
	{latitude: 41.408342, longitude: -116.228124, zipCode: "89834"},
	{latitude: 41.276395, longitude: -114.873835, zipCode: "89835"},
	{latitude: 40.84892, longitude: -114.157677, zipCode: "89883"},
	{latitude: 33.973665, longitude: -118.249039, zipCode: "90001"},
	{latitude: 33.948987, longitude: -118.24678, zipCode: "90002"},
	{latitude: 33.964077, longitude: -118.273684, zipCode: "90003"},
	{latitude: 34.076219, longitude: -118.310963, zipCode: "90004"},
	{latitude: 34.059374, longitude: -118.308962, zipCode: "90005"},
	{latitude: 34.048094, longitude: -118.294045, zipCode: "90006"},
	{latitude: 34.028165, longitude: -118.284829, zipCode: "90007"},
	{latitude: 34.010724, longitude: -118.342883, zipCode: "90008"},
	{latitude: 34.060987, longitude: -118.299884, zipCode: "90010"},
	{latitude: 34.00709, longitude: -118.258681, zipCode: "90011"},
	{latitude: 34.065975, longitude: -118.238642, zipCode: "90012"},
	{latitude: 34.044685, longitude: -118.240022, zipCode: "90013"},
	{latitude: 34.043006, longitude: -118.2519, zipCode: "90014"},
	{latitude: 34.039309, longitude: -118.266255, zipCode: "90015"},
	{latitude: 34.028331, longitude: -118.354338, zipCode: "90016"},
	{latitude: 34.053098, longitude: -118.264505, zipCode: "90017"},
	{latitude: 34.028632, longitude: -118.317347, zipCode: "90018"},
	{latitude: 34.049841, longitude: -118.33846, zipCode: "90019"},
	{latitude: 34.066382, longitude: -118.309831, zipCode: "90020"},
	{latitude: 34.029036, longitude: -118.23787, zipCode: "90021"},
	{latitude: 34.023728, longitude: -118.156275, zipCode: "90022"},
	{latitude: 34.022502, longitude: -118.199613, zipCode: "90023"},
	{latitude: 34.065645, longitude: -118.435284, zipCode: "90024"},
	{latitude: 34.045421, longitude: -118.445873, zipCode: "90025"},
	{latitude: 34.078991, longitude: -118.263804, zipCode: "90026"},
	{latitude: 34.127603, longitude: -118.296381, zipCode: "90027"},
	{latitude: 34.099811, longitude: -118.326837, zipCode: "90028"},
	{latitude: 34.089875, longitude: -118.294631, zipCode: "90029"},
	{latitude: 34.084197, longitude: -118.208567, zipCode: "90031"},
	{latitude: 34.078294, longitude: -118.185785, zipCode: "90032"},
	{latitude: 34.05038, longitude: -118.211991, zipCode: "90033"},
	{latitude: 34.030578, longitude: -118.399613, zipCode: "90034"},
	{latitude: 34.051426, longitude: -118.385765, zipCode: "90035"},
	{latitude: 34.070291, longitude: -118.349191, zipCode: "90036"},
	{latitude: 34.00266, longitude: -118.287507, zipCode: "90037"},
	{latitude: 34.088017, longitude: -118.327168, zipCode: "90038"},
	{latitude: 34.111885, longitude: -118.261033, zipCode: "90039"},
	{latitude: 33.994523, longitude: -118.149952, zipCode: "90040"},
	{latitude: 34.137412, longitude: -118.207607, zipCode: "90041"},
	{latitude: 34.114708, longitude: -118.192098, zipCode: "90042"},
	{latitude: 33.988505, longitude: -118.336358, zipCode: "90043"},
	{latitude: 33.952653, longitude: -118.292013, zipCode: "90044"},
	{latitude: 33.942387, longitude: -118.416062, zipCode: "90045"},
	{latitude: 34.10781, longitude: -118.362247, zipCode: "90046"},
	{latitude: 33.95543, longitude: -118.309568, zipCode: "90047"},
	{latitude: 34.072877, longitude: -118.372655, zipCode: "90048"},
	{latitude: 34.09254, longitude: -118.491064, zipCode: "90049"},
	{latitude: 33.978304, longitude: -118.258907, zipCode: "90052"},
	{latitude: 33.993581, longitude: -118.368839, zipCode: "90056"},
	{latitude: 34.061735, longitude: -118.276757, zipCode: "90057"},
	{latitude: 34.002844, longitude: -118.2164, zipCode: "90058"},
	{latitude: 33.927469, longitude: -118.248796, zipCode: "90059"},
	{latitude: 33.92128, longitude: -118.274186, zipCode: "90061"},
	{latitude: 34.003493, longitude: -118.309093, zipCode: "90062"},
	{latitude: 34.045248, longitude: -118.18578, zipCode: "90063"},
	{latitude: 34.035309, longitude: -118.426486, zipCode: "90064"},
	{latitude: 34.109595, longitude: -118.228689, zipCode: "90065"},
	{latitude: 34.002028, longitude: -118.430656, zipCode: "90066"},
	{latitude: 34.056437, longitude: -118.41369, zipCode: "90067"},
	{latitude: 34.124179, longitude: -118.327351, zipCode: "90068"},
	{latitude: 34.093953, longitude: -118.381531, zipCode: "90069"},
	{latitude: 34.052379, longitude: -118.255209, zipCode: "90071"},
	{latitude: 34.05733, longitude: -118.459534, zipCode: "90073"},
	{latitude: 34.108023, longitude: -118.456964, zipCode: "90077"},
	{latitude: 34.040587, longitude: -118.255404, zipCode: "90079"},
	{latitude: 34.021543, longitude: -118.285574, zipCode: "90089"},
	{latitude: 33.975307, longitude: -118.418708, zipCode: "90094"},
	{latitude: 34.071183, longitude: -118.443404, zipCode: "90095"},
	{latitude: 33.970343, longitude: -118.171368, zipCode: "90201"},
	{latitude: 34.100517, longitude: -118.41463, zipCode: "90210"},
	{latitude: 34.064956, longitude: -118.382981, zipCode: "90211"},
	{latitude: 34.06216, longitude: -118.401882, zipCode: "90212"},
	{latitude: 33.87978, longitude: -118.229679, zipCode: "90220"},
	{latitude: 33.885811, longitude: -118.206459, zipCode: "90221"},
	{latitude: 33.91224, longitude: -118.237607, zipCode: "90222"},
	{latitude: 33.997862, longitude: -118.393617, zipCode: "90230"},
	{latitude: 34.019323, longitude: -118.391902, zipCode: "90232"},
	{latitude: 33.955729, longitude: -118.118346, zipCode: "90240"},
	{latitude: 33.940582, longitude: -118.129472, zipCode: "90241"},
	{latitude: 33.921793, longitude: -118.140588, zipCode: "90242"},
	{latitude: 33.917145, longitude: -118.401554, zipCode: "90245"},
	{latitude: 33.891769, longitude: -118.297006, zipCode: "90247"},
	{latitude: 33.87834, longitude: -118.2841, zipCode: "90248"},
	{latitude: 33.90139, longitude: -118.315697, zipCode: "90249"},
	{latitude: 33.913651, longitude: -118.350946, zipCode: "90250"},
	{latitude: 33.864259, longitude: -118.399303, zipCode: "90254"},
	{latitude: 33.979248, longitude: -118.217438, zipCode: "90255"},
	{latitude: 33.888534, longitude: -118.349564, zipCode: "90260"},
	{latitude: 33.923831, longitude: -118.201352, zipCode: "90262"},
	{latitude: 34.039281, longitude: -118.708146, zipCode: "90263"},
	{latitude: 34.070245, longitude: -118.851042, zipCode: "90265"},
	{latitude: 33.891787, longitude: -118.402814, zipCode: "90266"},
	{latitude: 33.988557, longitude: -118.187688, zipCode: "90270"},
	{latitude: 34.092567, longitude: -118.534363, zipCode: "90272"},
	{latitude: 33.7801, longitude: -118.372412, zipCode: "90274"},
	{latitude: 33.753134, longitude: -118.367973, zipCode: "90275"},
	{latitude: 33.830006, longitude: -118.387124, zipCode: "90277"},
	{latitude: 33.873605, longitude: -118.370264, zipCode: "90278"},
	{latitude: 33.944159, longitude: -118.192761, zipCode: "90280"},
	{latitude: 34.107752, longitude: -118.616218, zipCode: "90290"},
	{latitude: 33.993396, longitude: -118.465193, zipCode: "90291"},
	{latitude: 33.976078, longitude: -118.452066, zipCode: "90292"},
	{latitude: 33.947305, longitude: -118.439841, zipCode: "90293"},
	{latitude: 33.956464, longitude: -118.358681, zipCode: "90301"},
	{latitude: 33.97535, longitude: -118.35609, zipCode: "90302"},
	{latitude: 33.936291, longitude: -118.332639, zipCode: "90303"},
	{latitude: 33.93736, longitude: -118.358795, zipCode: "90304"},
	{latitude: 33.958134, longitude: -118.330905, zipCode: "90305"},
	{latitude: 34.016099, longitude: -118.492454, zipCode: "90401"},
	{latitude: 34.034666, longitude: -118.504039, zipCode: "90402"},
	{latitude: 34.030755, longitude: -118.492101, zipCode: "90403"},
	{latitude: 34.026649, longitude: -118.473835, zipCode: "90404"},
	{latitude: 34.011057, longitude: -118.472152, zipCode: "90405"},
	{latitude: 33.833744, longitude: -118.314022, zipCode: "90501"},
	{latitude: 33.833181, longitude: -118.292062, zipCode: "90502"},
	{latitude: 33.839463, longitude: -118.353699, zipCode: "90503"},
	{latitude: 33.86728, longitude: -118.331642, zipCode: "90504"},
	{latitude: 33.807882, longitude: -118.347957, zipCode: "90505"},
	{latitude: 33.884696, longitude: -118.329998, zipCode: "90506"},
	{latitude: 34.007937, longitude: -118.030324, zipCode: "90601"},
	{latitude: 33.974242, longitude: -118.02233, zipCode: "90602"},
	{latitude: 33.944866, longitude: -117.992338, zipCode: "90603"},
	{latitude: 33.927691, longitude: -118.013828, zipCode: "90604"},
	{latitude: 33.952444, longitude: -118.0214, zipCode: "90605"},
	{latitude: 33.977272, longitude: -118.066363, zipCode: "90606"},
	{latitude: 33.845223, longitude: -118.015292, zipCode: "90620"},
	{latitude: 33.874224, longitude: -117.993368, zipCode: "90621"},
	{latitude: 33.850616, longitude: -118.039565, zipCode: "90623"},
	{latitude: 33.818477, longitude: -118.038307, zipCode: "90630"},
	{latitude: 33.942708, longitude: -117.952483, zipCode: "90631"},
	{latitude: 33.902045, longitude: -118.008961, zipCode: "90638"},
	{latitude: 33.905746, longitude: -118.014907, zipCode: "90639"},
	{latitude: 34.015444, longitude: -118.111012, zipCode: "90640"},
	{latitude: 33.90779, longitude: -118.082487, zipCode: "90650"},
	{latitude: 33.989524, longitude: -118.089295, zipCode: "90660"},
	{latitude: 33.933565, longitude: -118.062611, zipCode: "90670"},
	{latitude: 33.80114, longitude: -117.995327, zipCode: "90680"},
	{latitude: 33.867605, longitude: -118.080631, zipCode: "90701"},
	{latitude: 33.867745, longitude: -118.069471, zipCode: "90703"},
	{latitude: 33.399325, longitude: -118.435329, zipCode: "90704"},
	{latitude: 33.887821, longitude: -118.12725, zipCode: "90706"},
	{latitude: 33.798905, longitude: -118.300239, zipCode: "90710"},
	{latitude: 33.849034, longitude: -118.147902, zipCode: "90712"},
	{latitude: 33.848022, longitude: -118.112746, zipCode: "90713"},
	{latitude: 33.841027, longitude: -118.079141, zipCode: "90715"},
	{latitude: 33.830473, longitude: -118.072959, zipCode: "90716"},
	{latitude: 33.793456, longitude: -118.317054, zipCode: "90717"},
	{latitude: 33.795012, longitude: -118.062692, zipCode: "90720"},
	{latitude: 33.898883, longitude: -118.166629, zipCode: "90723"},
	{latitude: 33.732353, longitude: -118.278151, zipCode: "90731"},
	{latitude: 33.737321, longitude: -118.313433, zipCode: "90732"},
	{latitude: 33.754334, longitude: -118.073267, zipCode: "90740"},
	{latitude: 33.721015, longitude: -118.07617, zipCode: "90742"},
	{latitude: 33.72953, longitude: -118.092436, zipCode: "90743"},
	{latitude: 33.777214, longitude: -118.259604, zipCode: "90744"},
	{latitude: 33.820268, longitude: -118.264125, zipCode: "90745"},
	{latitude: 33.859231, longitude: -118.252312, zipCode: "90746"},
	{latitude: 33.863785, longitude: -118.251354, zipCode: "90747"},
	{latitude: 33.803166, longitude: -118.167132, zipCode: "90755"},
	{latitude: 33.751279, longitude: -118.20682, zipCode: "90802"},
	{latitude: 33.754191, longitude: -118.132363, zipCode: "90803"},
	{latitude: 33.781686, longitude: -118.148365, zipCode: "90804"},
	{latitude: 33.864617, longitude: -118.180567, zipCode: "90805"},
	{latitude: 33.80887, longitude: -118.178486, zipCode: "90806"},
	{latitude: 33.830205, longitude: -118.181181, zipCode: "90807"},
	{latitude: 33.823943, longitude: -118.111335, zipCode: "90808"},
	{latitude: 33.819841, longitude: -118.222378, zipCode: "90810"},
	{latitude: 33.782259, longitude: -118.196793, zipCode: "90813"},
	{latitude: 33.771581, longitude: -118.143361, zipCode: "90814"},
	{latitude: 33.795067, longitude: -118.115724, zipCode: "90815"},
	{latitude: 33.778436, longitude: -118.118648, zipCode: "90822"},
	{latitude: 33.768308, longitude: -118.200762, zipCode: "90831"},
	{latitude: 33.784566, longitude: -118.121313, zipCode: "90840"},
	{latitude: 34.194489, longitude: -118.138263, zipCode: "91001"},
	{latitude: 34.135159, longitude: -118.026685, zipCode: "91006"},
	{latitude: 34.128284, longitude: -118.047732, zipCode: "91007"},
	{latitude: 34.153159, longitude: -117.968818, zipCode: "91008"},
	{latitude: 34.141012, longitude: -117.958661, zipCode: "91010"},
	{latitude: 34.217935, longitude: -118.202255, zipCode: "91011"},
	{latitude: 34.1528, longitude: -118.000482, zipCode: "91016"},
	{latitude: 34.21137, longitude: -118.230688, zipCode: "91020"},
	{latitude: 34.175276, longitude: -118.046017, zipCode: "91024"},
	{latitude: 34.108957, longitude: -118.156615, zipCode: "91030"},
	{latitude: 34.257696, longitude: -118.338455, zipCode: "91040"},
	{latitude: 34.315503, longitude: -118.264847, zipCode: "91042"},
	{latitude: 34.211677, longitude: -118.240922, zipCode: "91046"},
	{latitude: 34.146771, longitude: -118.138922, zipCode: "91101"},
	{latitude: 34.167361, longitude: -118.164144, zipCode: "91103"},
	{latitude: 34.165356, longitude: -118.123751, zipCode: "91104"},
	{latitude: 34.13946, longitude: -118.166649, zipCode: "91105"},
	{latitude: 34.139402, longitude: -118.128658, zipCode: "91106"},
	{latitude: 34.159007, longitude: -118.087353, zipCode: "91107"},
	{latitude: 34.122372, longitude: -118.114234, zipCode: "91108"},
	{latitude: 34.137644, longitude: -118.124952, zipCode: "91125"},
	{latitude: 34.17051, longitude: -118.289463, zipCode: "91201"},
	{latitude: 34.183302, longitude: -118.264478, zipCode: "91202"},
	{latitude: 34.153338, longitude: -118.262974, zipCode: "91203"},
	{latitude: 34.135557, longitude: -118.26094, zipCode: "91204"},
	{latitude: 34.13658, longitude: -118.245839, zipCode: "91205"},
	{latitude: 34.160359, longitude: -118.213769, zipCode: "91206"},
	{latitude: 34.167939, longitude: -118.244241, zipCode: "91207"},
	{latitude: 34.185665, longitude: -118.239497, zipCode: "91208"},
	{latitude: 34.144653, longitude: -118.258061, zipCode: "91210"},
	{latitude: 34.235728, longitude: -118.246655, zipCode: "91214"},
	{latitude: 34.123286, longitude: -118.758541, zipCode: "91301"},
	{latitude: 34.122556, longitude: -118.669821, zipCode: "91302"},
	{latitude: 34.19829, longitude: -118.602203, zipCode: "91303"},
	{latitude: 34.227791, longitude: -118.644607, zipCode: "91304"},
	{latitude: 34.209532, longitude: -118.577563, zipCode: "91306"},
	{latitude: 34.200485, longitude: -118.664852, zipCode: "91307"},
	{latitude: 34.292495, longitude: -118.602424, zipCode: "91311"},
	{latitude: 34.158542, longitude: -118.516597, zipCode: "91316"},
	{latitude: 34.173377, longitude: -118.947977, zipCode: "91320"},
	{latitude: 34.363715, longitude: -118.487039, zipCode: "91321"},
	{latitude: 34.238208, longitude: -118.55029, zipCode: "91324"},
	{latitude: 34.237519, longitude: -118.517712, zipCode: "91325"},
	{latitude: 34.281775, longitude: -118.555697, zipCode: "91326"},
	{latitude: 34.244522, longitude: -118.526677, zipCode: "91330"},
	{latitude: 34.255294, longitude: -118.419703, zipCode: "91331"},
	{latitude: 34.200175, longitude: -118.540958, zipCode: "91335"},
	{latitude: 34.28667, longitude: -118.434817, zipCode: "91340"},
	{latitude: 34.326584, longitude: -118.382261, zipCode: "91342"},
	{latitude: 34.238802, longitude: -118.482296, zipCode: "91343"},
	{latitude: 34.289467, longitude: -118.504699, zipCode: "91344"},
	{latitude: 34.265879, longitude: -118.456748, zipCode: "91345"},
	{latitude: 34.430599, longitude: -118.502774, zipCode: "91350"},
	{latitude: 34.433727, longitude: -118.458061, zipCode: "91351"},
	{latitude: 34.230854, longitude: -118.365856, zipCode: "91352"},
	{latitude: 34.458422, longitude: -118.55414, zipCode: "91354"},
	{latitude: 34.40185, longitude: -118.570014, zipCode: "91355"},
	{latitude: 34.154999, longitude: -118.547559, zipCode: "91356"},
	{latitude: 34.213305, longitude: -118.874135, zipCode: "91360"},
	{latitude: 34.137283, longitude: -118.882044, zipCode: "91361"},
	{latitude: 34.192431, longitude: -118.819486, zipCode: "91362"},
	{latitude: 34.154803, longitude: -118.594816, zipCode: "91364"},
	{latitude: 34.177311, longitude: -118.615704, zipCode: "91367"},
	{latitude: 34.185015, longitude: -118.576088, zipCode: "91371"},
	{latitude: 34.184724, longitude: -118.766517, zipCode: "91377"},
	{latitude: 34.373221, longitude: -118.607971, zipCode: "91381"},
	{latitude: 34.520101, longitude: -118.668276, zipCode: "91384"},
	{latitude: 34.402519, longitude: -118.363463, zipCode: "91387"},
	{latitude: 34.576515, longitude: -118.492764, zipCode: "91390"},
	{latitude: 34.178483, longitude: -118.431791, zipCode: "91401"},
	{latitude: 34.222506, longitude: -118.444689, zipCode: "91402"},
	{latitude: 34.147757, longitude: -118.464738, zipCode: "91403"},
	{latitude: 34.202494, longitude: -118.448048, zipCode: "91405"},
	{latitude: 34.197454, longitude: -118.488978, zipCode: "91406"},
	{latitude: 34.178517, longitude: -118.45963, zipCode: "91411"},
	{latitude: 34.149493, longitude: -118.431882, zipCode: "91423"},
	{latitude: 34.149588, longitude: -118.489708, zipCode: "91436"},
	{latitude: 34.193527, longitude: -118.294006, zipCode: "91501"},
	{latitude: 34.176817, longitude: -118.309323, zipCode: "91502"},
	{latitude: 34.213006, longitude: -118.317736, zipCode: "91504"},
	{latitude: 34.175833, longitude: -118.347536, zipCode: "91505"},
	{latitude: 34.169706, longitude: -118.323548, zipCode: "91506"},
	{latitude: 34.168253, longitude: -118.372286, zipCode: "91601"},
	{latitude: 34.150818, longitude: -118.368159, zipCode: "91602"},
	{latitude: 34.140187, longitude: -118.394603, zipCode: "91604"},
	{latitude: 34.207341, longitude: -118.400973, zipCode: "91605"},
	{latitude: 34.185767, longitude: -118.388323, zipCode: "91606"},
	{latitude: 34.165783, longitude: -118.399795, zipCode: "91607"},
	{latitude: 34.138735, longitude: -118.350857, zipCode: "91608"},
	{latitude: 34.137828, longitude: -117.600301, zipCode: "91701"},
	{latitude: 34.281525, longitude: -117.80009, zipCode: "91702"},
	{latitude: 34.09741, longitude: -117.968269, zipCode: "91706"},
	{latitude: 33.952084, longitude: -117.639784, zipCode: "91708"},
	{latitude: 33.942969, longitude: -117.725648, zipCode: "91709"},
	{latitude: 34.005097, longitude: -117.682525, zipCode: "91710"},
	{latitude: 34.128066, longitude: -117.714773, zipCode: "91711"},
	{latitude: 34.097333, longitude: -117.906707, zipCode: "91722"},
	{latitude: 34.084747, longitude: -117.886844, zipCode: "91723"},
	{latitude: 34.078991, longitude: -117.854411, zipCode: "91724"},
	{latitude: 34.100402, longitude: -117.581282, zipCode: "91730"},
	{latitude: 34.078573, longitude: -118.043456, zipCode: "91731"},
	{latitude: 34.072407, longitude: -118.014524, zipCode: "91732"},
	{latitude: 34.045809, longitude: -118.053756, zipCode: "91733"},
	{latitude: 34.155238, longitude: -117.578097, zipCode: "91737"},
	{latitude: 34.156386, longitude: -117.516936, zipCode: "91739"},
	{latitude: 34.119367, longitude: -117.855054, zipCode: "91740"},
	{latitude: 34.163363, longitude: -117.834121, zipCode: "91741"},
	{latitude: 34.0313, longitude: -117.936777, zipCode: "91744"},
	{latitude: 33.999005, longitude: -117.972146, zipCode: "91745"},
	{latitude: 34.043304, longitude: -117.986859, zipCode: "91746"},
	{latitude: 33.978293, longitude: -117.902317, zipCode: "91748"},
	{latitude: 34.148058, longitude: -117.766635, zipCode: "91750"},
	{latitude: 33.996484, longitude: -117.531335, zipCode: "91752"},
	{latitude: 34.052922, longitude: -118.143465, zipCode: "91754"},
	{latitude: 34.050965, longitude: -118.114394, zipCode: "91755"},
	{latitude: 34.263699, longitude: -117.699574, zipCode: "91759"},
	{latitude: 34.035602, longitude: -117.591528, zipCode: "91761"},
	{latitude: 34.059409, longitude: -117.669298, zipCode: "91762"},
	{latitude: 34.07206, longitude: -117.698288, zipCode: "91763"},
	{latitude: 34.074087, longitude: -117.605618, zipCode: "91764"},
	{latitude: 33.985166, longitude: -117.812005, zipCode: "91765"},
	{latitude: 34.042286, longitude: -117.756106, zipCode: "91766"},
	{latitude: 34.083086, longitude: -117.737997, zipCode: "91767"},
	{latitude: 34.064363, longitude: -117.79093, zipCode: "91768"},
	{latitude: 34.065108, longitude: -118.082791, zipCode: "91770"},
	{latitude: 34.107485, longitude: -117.808752, zipCode: "91773"},
	{latitude: 34.115261, longitude: -118.088311, zipCode: "91775"},
	{latitude: 34.088952, longitude: -118.094468, zipCode: "91776"},
	{latitude: 34.101509, longitude: -118.055859, zipCode: "91780"},
	{latitude: 34.141146, longitude: -117.655583, zipCode: "91784"},
	{latitude: 34.10525, longitude: -117.66207, zipCode: "91786"},
	{latitude: 34.018195, longitude: -117.854821, zipCode: "91789"},
	{latitude: 34.066964, longitude: -117.937007, zipCode: "91790"},
	{latitude: 34.061624, longitude: -117.892235, zipCode: "91791"},
	{latitude: 34.022581, longitude: -117.902359, zipCode: "91792"},
	{latitude: 34.090924, longitude: -118.127761, zipCode: "91801"},
	{latitude: 34.074736, longitude: -118.145959, zipCode: "91803"},
	{latitude: 32.813624, longitude: -116.726969, zipCode: "91901"},
	{latitude: 32.673217, longitude: -117.006101, zipCode: "91902"},
	{latitude: 32.678648, longitude: -116.307485, zipCode: "91905"},
	{latitude: 32.65805, longitude: -116.476042, zipCode: "91906"},
	{latitude: 32.637096, longitude: -117.061758, zipCode: "91910"},
	{latitude: 32.607352, longitude: -117.05036, zipCode: "91911"},
	{latitude: 32.622895, longitude: -116.986892, zipCode: "91913"},
	{latitude: 32.66604, longitude: -116.95424, zipCode: "91914"},
	{latitude: 32.623602, longitude: -116.949804, zipCode: "91915"},
	{latitude: 32.911035, longitude: -116.632006, zipCode: "91916"},
	{latitude: 32.641996, longitude: -116.753767, zipCode: "91917"},
	{latitude: 32.837596, longitude: -116.580305, zipCode: "91931"},
	{latitude: 32.565354, longitude: -117.114567, zipCode: "91932"},
	{latitude: 32.648992, longitude: -116.152565, zipCode: "91934"},
	{latitude: 32.694344, longitude: -116.775514, zipCode: "91935"},
	{latitude: 32.759266, longitude: -116.997091, zipCode: "91941"},
	{latitude: 32.777267, longitude: -117.021344, zipCode: "91942"},
	{latitude: 32.733147, longitude: -117.034068, zipCode: "91945"},
	{latitude: 32.864953, longitude: -116.473036, zipCode: "91948"},
	{latitude: 32.669067, longitude: -117.093487, zipCode: "91950"},
	{latitude: 32.846766, longitude: -116.519775, zipCode: "91962"},
	{latitude: 32.647953, longitude: -116.596357, zipCode: "91963"},
	{latitude: 32.726237, longitude: -116.994318, zipCode: "91977"},
	{latitude: 32.698921, longitude: -116.930959, zipCode: "91978"},
	{latitude: 32.585187, longitude: -116.63254, zipCode: "91980"},
	{latitude: 33.285274, longitude: -117.197777, zipCode: "92003"},
	{latitude: 33.197705, longitude: -116.167463, zipCode: "92004"},
	{latitude: 33.023046, longitude: -117.275581, zipCode: "92007"},
	{latitude: 33.14248, longitude: -117.313444, zipCode: "92008"},
	{latitude: 33.095339, longitude: -117.245769, zipCode: "92009"},
	{latitude: 33.165381, longitude: -117.281427, zipCode: "92010"},
	{latitude: 33.106764, longitude: -117.296327, zipCode: "92011"},
	{latitude: 32.963232, longitude: -117.250367, zipCode: "92014"},
	{latitude: 32.778844, longitude: -116.871415, zipCode: "92019"},
	{latitude: 32.795605, longitude: -116.969754, zipCode: "92020"},
	{latitude: 32.835732, longitude: -116.874617, zipCode: "92021"},
	{latitude: 33.056868, longitude: -117.257577, zipCode: "92024"},
	{latitude: 33.077814, longitude: -117.033131, zipCode: "92025"},
	{latitude: 33.210965, longitude: -117.124788, zipCode: "92026"},
	{latitude: 33.131601, longitude: -116.976937, zipCode: "92027"},
	{latitude: 33.386469, longitude: -117.224957, zipCode: "92028"},
	{latitude: 33.08553, longitude: -117.123967, zipCode: "92029"},
	{latitude: 33.032413, longitude: -116.265177, zipCode: "92036"},
	{latitude: 32.854642, longitude: -117.251772, zipCode: "92037"},
	{latitude: 32.90891, longitude: -116.891972, zipCode: "92040"},
	{latitude: 33.193069, longitude: -117.359676, zipCode: "92054"},
	{latitude: 33.411196, longitude: -117.433259, zipCode: "92055"},
	{latitude: 33.199582, longitude: -117.298764, zipCode: "92056"},
	{latitude: 33.25462, longitude: -117.283311, zipCode: "92057"},
	{latitude: 33.263974, longitude: -117.344777, zipCode: "92058"},
	{latitude: 33.382822, longitude: -117.051995, zipCode: "92059"},
	{latitude: 33.350376, longitude: -116.864443, zipCode: "92060"},
	{latitude: 33.300352, longitude: -116.930524, zipCode: "92061"},
	{latitude: 32.984352, longitude: -117.013537, zipCode: "92064"},
	{latitude: 33.040373, longitude: -116.830075, zipCode: "92065"},
	{latitude: 33.215412, longitude: -116.535834, zipCode: "92066"},
	{latitude: 33.020908, longitude: -117.190346, zipCode: "92067"},
	{latitude: 33.175303, longitude: -117.157621, zipCode: "92069"},
	{latitude: 33.188575, longitude: -116.74328, zipCode: "92070"},
	{latitude: 32.850685, longitude: -116.995314, zipCode: "92071"},
	{latitude: 32.997248, longitude: -117.26058, zipCode: "92075"},
	{latitude: 33.117065, longitude: -117.180807, zipCode: "92078"},
	{latitude: 33.164549, longitude: -117.24055, zipCode: "92081"},
	{latitude: 33.251828, longitude: -117.002794, zipCode: "92082"},
	{latitude: 33.198084, longitude: -117.248364, zipCode: "92083"},
	{latitude: 33.227283, longitude: -117.200085, zipCode: "92084"},
	{latitude: 33.313998, longitude: -116.629537, zipCode: "92086"},
	{latitude: 33.052389, longitude: -117.182817, zipCode: "92091"},
	{latitude: 32.875545, longitude: -117.236144, zipCode: "92092"},
	{latitude: 32.882118, longitude: -117.237083, zipCode: "92093"},
	{latitude: 33.128156, longitude: -117.156194, zipCode: "92096"},
	{latitude: 32.717807, longitude: -117.172086, zipCode: "92101"},
	{latitude: 32.715981, longitude: -117.117495, zipCode: "92102"},
	{latitude: 32.747816, longitude: -117.167332, zipCode: "92103"},
	{latitude: 32.741933, longitude: -117.127814, zipCode: "92104"},
	{latitude: 32.740404, longitude: -117.089868, zipCode: "92105"},
	{latitude: 32.709885, longitude: -117.237857, zipCode: "92106"},
	{latitude: 32.739055, longitude: -117.245404, zipCode: "92107"},
	{latitude: 32.773983, longitude: -117.142706, zipCode: "92108"},
	{latitude: 32.786772, longitude: -117.234207, zipCode: "92109"},
	{latitude: 32.765244, longitude: -117.200391, zipCode: "92110"},
	{latitude: 32.806482, longitude: -117.168966, zipCode: "92111"},
	{latitude: 32.694637, longitude: -117.119756, zipCode: "92113"},
	{latitude: 32.707751, longitude: -117.055297, zipCode: "92114"},
	{latitude: 32.761549, longitude: -117.071905, zipCode: "92115"},
	{latitude: 32.764841, longitude: -117.123124, zipCode: "92116"},
	{latitude: 32.824893, longitude: -117.199714, zipCode: "92117"},
	{latitude: 32.698795, longitude: -117.20911, zipCode: "92118"},
	{latitude: 32.817888, longitude: -117.031956, zipCode: "92119"},
	{latitude: 32.793994, longitude: -117.073796, zipCode: "92120"},
	{latitude: 32.897123, longitude: -117.201647, zipCode: "92121"},
	{latitude: 32.856798, longitude: -117.210156, zipCode: "92122"},
	{latitude: 32.809615, longitude: -117.134201, zipCode: "92123"},
	{latitude: 32.828255, longitude: -117.085262, zipCode: "92124"},
	{latitude: 32.91199, longitude: -117.143421, zipCode: "92126"},
	{latitude: 33.018765, longitude: -117.124098, zipCode: "92127"},
	{latitude: 32.996967, longitude: -117.072979, zipCode: "92128"},
	{latitude: 32.963536, longitude: -117.126221, zipCode: "92129"},
	{latitude: 32.947616, longitude: -117.205825, zipCode: "92130"},
	{latitude: 32.899687, longitude: -117.047318, zipCode: "92131"},
	{latitude: 32.713672, longitude: -117.172039, zipCode: "92132"},
	{latitude: 32.725055, longitude: -117.145415, zipCode: "92134"},
	{latitude: 32.694976, longitude: -117.194354, zipCode: "92135"},
	{latitude: 32.683013, longitude: -117.122978, zipCode: "92136"},
	{latitude: 32.680031, longitude: -117.048886, zipCode: "92139"},
	{latitude: 32.741279, longitude: -117.197714, zipCode: "92140"},
	{latitude: 32.868496, longitude: -117.143172, zipCode: "92145"},
	{latitude: 32.724745, longitude: -117.21875, zipCode: "92147"},
	{latitude: 32.579101, longitude: -116.966528, zipCode: "92154"},
	{latitude: 32.658116, longitude: -117.156946, zipCode: "92155"},
	{latitude: 32.874072, longitude: -117.231563, zipCode: "92161"},
	{latitude: 32.555577, longitude: -117.051191, zipCode: "92173"},
	{latitude: 32.583904, longitude: -116.933475, zipCode: "92179"},
	{latitude: 32.774983, longitude: -117.074228, zipCode: "92182"},
	{latitude: 33.709453, longitude: -116.236967, zipCode: "92201"},
	{latitude: 33.751601, longitude: -116.241426, zipCode: "92203"},
	{latitude: 33.703465, longitude: -116.325898, zipCode: "92210"},
	{latitude: 33.765401, longitude: -116.334205, zipCode: "92211"},
	{latitude: 33.939919, longitude: -116.839331, zipCode: "92220"},
	{latitude: 32.783449, longitude: -114.54953, zipCode: "92222"},
	{latitude: 33.937821, longitude: -116.98137, zipCode: "92223"},
	{latitude: 33.717266, longitude: -114.660084, zipCode: "92225"},
	{latitude: 32.99005, longitude: -115.336852, zipCode: "92227"},
	{latitude: 33.908785, longitude: -116.768782, zipCode: "92230"},
	{latitude: 32.687636, longitude: -115.564374, zipCode: "92231"},
	{latitude: 33.147384, longitude: -115.500405, zipCode: "92233"},
	{latitude: 33.814131, longitude: -116.465548, zipCode: "92234"},
	{latitude: 33.702765, longitude: -116.07244, zipCode: "92236"},
	{latitude: 33.768064, longitude: -115.21693, zipCode: "92239"},
	{latitude: 33.95154, longitude: -116.520104, zipCode: "92240"},
	{latitude: 33.84634, longitude: -116.283302, zipCode: "92241"},
	{latitude: 34.188779, longitude: -114.306484, zipCode: "92242"},
	{latitude: 32.770197, longitude: -115.59859, zipCode: "92243"},
	{latitude: 32.726068, longitude: -115.48215, zipCode: "92249"},
	{latitude: 32.777373, longitude: -115.127981, zipCode: "92250"},
	{latitude: 32.86923, longitude: -115.714039, zipCode: "92251"},
	{latitude: 34.147976, longitude: -116.284218, zipCode: "92252"},
	{latitude: 33.656743, longitude: -116.292162, zipCode: "92253"},
	{latitude: 33.543837, longitude: -115.998275, zipCode: "92254"},
	{latitude: 34.07206, longitude: -116.617666, zipCode: "92256"},
	{latitude: 33.336237, longitude: -115.525689, zipCode: "92257"},
	{latitude: 33.92155, longitude: -116.559564, zipCode: "92258"},
	{latitude: 32.734784, longitude: -115.946436, zipCode: "92259"},
	{latitude: 33.69723, longitude: -116.406257, zipCode: "92260"},
	{latitude: 33.862959, longitude: -116.556682, zipCode: "92262"},
	{latitude: 33.712892, longitude: -116.497107, zipCode: "92264"},
	{latitude: 33.199348, longitude: -114.915318, zipCode: "92266"},
	{latitude: 34.297218, longitude: -114.207105, zipCode: "92267"},
	{latitude: 34.16146, longitude: -116.550014, zipCode: "92268"},
	{latitude: 33.763838, longitude: -116.430388, zipCode: "92270"},
	{latitude: 32.803021, longitude: -115.697968, zipCode: "92273"},
	{latitude: 33.470242, longitude: -116.113717, zipCode: "92274"},
	{latitude: 33.207817, longitude: -115.880817, zipCode: "92275"},
	{latitude: 33.832316, longitude: -116.386425, zipCode: "92276"},
	{latitude: 34.12777, longitude: -115.690134, zipCode: "92277"},
	{latitude: 34.235057, longitude: -116.058265, zipCode: "92278"},
	{latitude: 34.129347, longitude: -114.985225, zipCode: "92280"},
	{latitude: 33.050343, longitude: -115.612651, zipCode: "92281"},
	{latitude: 33.962575, longitude: -116.658296, zipCode: "92282"},
	{latitude: 32.891546, longitude: -114.772943, zipCode: "92283"},
	{latitude: 34.160076, longitude: -116.424717, zipCode: "92284"},
	{latitude: 34.337655, longitude: -116.513672, zipCode: "92285"},
	{latitude: 34.651787, longitude: -117.526105, zipCode: "92301"},
	{latitude: 34.548749, longitude: -115.690805, zipCode: "92304"},
	{latitude: 34.14382, longitude: -116.868376, zipCode: "92305"},
	{latitude: 34.571231, longitude: -117.133092, zipCode: "92307"},
	{latitude: 34.420222, longitude: -117.156134, zipCode: "92308"},
	{latitude: 35.249196, longitude: -116.156236, zipCode: "92309"},
	{latitude: 35.334411, longitude: -116.763014, zipCode: "92310"},
	{latitude: 34.965648, longitude: -117.001509, zipCode: "92311"},
	{latitude: 34.031317, longitude: -117.314989, zipCode: "92313"},
	{latitude: 34.254118, longitude: -116.796378, zipCode: "92314"},
	{latitude: 34.21713, longitude: -116.941614, zipCode: "92315"},
	{latitude: 34.057066, longitude: -117.391179, zipCode: "92316"},
	{latitude: 34.245076, longitude: -117.208732, zipCode: "92317"},
	{latitude: 33.98738, longitude: -117.050284, zipCode: "92320"},
	{latitude: 34.250041, longitude: -117.147272, zipCode: "92321"},
	{latitude: 34.254171, longitude: -117.322576, zipCode: "92322"},
	{latitude: 34.025186, longitude: -117.288849, zipCode: "92324"},
	{latitude: 34.246699, longitude: -117.276027, zipCode: "92325"},
	{latitude: 34.867867, longitude: -116.835914, zipCode: "92327"},
	{latitude: 36.371785, longitude: -116.979764, zipCode: "92328"},
	{latitude: 34.907091, longitude: -115.364459, zipCode: "92332"},
	{latitude: 34.26066, longitude: -116.982149, zipCode: "92333"},
	{latitude: 34.087333, longitude: -117.465158, zipCode: "92335"},
	{latitude: 34.136862, longitude: -117.460336, zipCode: "92336"},
	{latitude: 34.049665, longitude: -117.471472, zipCode: "92337"},
	{latitude: 34.787721, longitude: -116.251208, zipCode: "92338"},
	{latitude: 34.083932, longitude: -116.837368, zipCode: "92339"},
	{latitude: 34.236643, longitude: -117.062838, zipCode: "92341"},
	{latitude: 34.760294, longitude: -117.335913, zipCode: "92342"},
	{latitude: 34.391804, longitude: -117.406566, zipCode: "92344"},
	{latitude: 34.360972, longitude: -117.313039, zipCode: "92345"},
	{latitude: 34.124049, longitude: -117.177573, zipCode: "92346"},
	{latitude: 34.950014, longitude: -117.258047, zipCode: "92347"},
	{latitude: 34.05294, longitude: -117.261599, zipCode: "92350"},
	{latitude: 34.267686, longitude: -117.186721, zipCode: "92352"},
	{latitude: 34.050788, longitude: -117.250153, zipCode: "92354"},
	{latitude: 34.496251, longitude: -116.89253, zipCode: "92356"},
	{latitude: 34.246232, longitude: -117.567638, zipCode: "92358"},
	{latitude: 34.085289, longitude: -117.076311, zipCode: "92359"},
	{latitude: 34.711078, longitude: -114.581982, zipCode: "92363"},
	{latitude: 35.359598, longitude: -115.438772, zipCode: "92364"},
	{latitude: 34.896688, longitude: -116.560418, zipCode: "92365"},
	{latitude: 34.664388, longitude: -117.314875, zipCode: "92368"},
	{latitude: 34.139411, longitude: -117.219664, zipCode: "92369"},
	{latitude: 34.438338, longitude: -117.542594, zipCode: "92371"},
	{latitude: 34.448675, longitude: -117.634916, zipCode: "92372"},
	{latitude: 34.011947, longitude: -117.159702, zipCode: "92373"},
	{latitude: 34.068515, longitude: -117.175322, zipCode: "92374"},
	{latitude: 34.110537, longitude: -117.380021, zipCode: "92376"},
	{latitude: 34.152363, longitude: -117.404068, zipCode: "92377"},
	{latitude: 34.231995, longitude: -117.224887, zipCode: "92378"},
	{latitude: 34.206218, longitude: -117.112272, zipCode: "92382"},
	{latitude: 36.043338, longitude: -116.119168, zipCode: "92384"},
	{latitude: 34.213444, longitude: -117.230588, zipCode: "92385"},
	{latitude: 34.237036, longitude: -116.825291, zipCode: "92386"},
	{latitude: 35.824572, longitude: -116.274755, zipCode: "92389"},
	{latitude: 34.239689, longitude: -117.236669, zipCode: "92391"},
	{latitude: 34.479015, longitude: -117.408834, zipCode: "92392"},
	{latitude: 34.563464, longitude: -117.353579, zipCode: "92394"},
	{latitude: 34.504828, longitude: -117.298985, zipCode: "92395"},
	{latitude: 34.354089, longitude: -117.753718, zipCode: "92397"},
	{latitude: 34.982793, longitude: -116.85162, zipCode: "92398"},
	{latitude: 34.032312, longitude: -117.010577, zipCode: "92399"},
	{latitude: 34.105906, longitude: -117.291814, zipCode: "92401"},
	{latitude: 34.178633, longitude: -117.238926, zipCode: "92404"},
	{latitude: 34.145781, longitude: -117.302217, zipCode: "92405"},
	{latitude: 34.225162, longitude: -117.388487, zipCode: "92407"},
	{latitude: 34.083619, longitude: -117.26846, zipCode: "92408"},
	{latitude: 34.111575, longitude: -117.263691, zipCode: "92410"},
	{latitude: 34.122809, longitude: -117.325808, zipCode: "92411"},
	{latitude: 34.105054, longitude: -117.286848, zipCode: "92415"},
	{latitude: 33.996523, longitude: -117.374179, zipCode: "92501"},
	{latitude: 33.882884, longitude: -117.451725, zipCode: "92503"},
	{latitude: 33.908353, longitude: -117.401342, zipCode: "92504"},
	{latitude: 33.926666, longitude: -117.492804, zipCode: "92505"},
	{latitude: 33.934761, longitude: -117.366882, zipCode: "92506"},
	{latitude: 33.991122, longitude: -117.306821, zipCode: "92507"},
	{latitude: 33.890389, longitude: -117.328257, zipCode: "92508"},
	{latitude: 34.003266, longitude: -117.442004, zipCode: "92509"},
	{latitude: 33.896337, longitude: -117.277065, zipCode: "92518"},
	{latitude: 33.96714, longitude: -117.326372, zipCode: "92521"},
	{latitude: 33.633056, longitude: -117.370461, zipCode: "92530"},
	{latitude: 33.690557, longitude: -117.302435, zipCode: "92532"},
	{latitude: 33.491994, longitude: -116.816945, zipCode: "92536"},
	{latitude: 33.527867, longitude: -116.64762, zipCode: "92539"},
	{latitude: 33.698645, longitude: -116.979161, zipCode: "92543"},
	{latitude: 33.645934, longitude: -116.881628, zipCode: "92544"},
	{latitude: 33.731119, longitude: -117.035872, zipCode: "92545"},
	{latitude: 33.763908, longitude: -117.109032, zipCode: "92548"},
	{latitude: 33.803818, longitude: -116.679273, zipCode: "92549"},
	{latitude: 33.88274, longitude: -117.224878, zipCode: "92551"},
	{latitude: 33.92335, longitude: -117.245333, zipCode: "92553"},
	{latitude: 33.910996, longitude: -117.113765, zipCode: "92555"},
	{latitude: 33.972548, longitude: -117.258259, zipCode: "92557"},
	{latitude: 33.630418, longitude: -116.58229, zipCode: "92561"},
	{latitude: 33.53124, longitude: -117.343999, zipCode: "92562"},
	{latitude: 33.580016, longitude: -117.14736, zipCode: "92563"},
	{latitude: 33.826142, longitude: -117.084467, zipCode: "92567"},
	{latitude: 33.779946, longitude: -117.322571, zipCode: "92570"},
	{latitude: 33.842129, longitude: -117.194982, zipCode: "92571"},
	{latitude: 33.807607, longitude: -117.015163, zipCode: "92582"},
	{latitude: 33.796911, longitude: -116.931371, zipCode: "92583"},
	{latitude: 33.661431, longitude: -117.182284, zipCode: "92584"},
	{latitude: 33.742167, longitude: -117.167238, zipCode: "92585"},
	{latitude: 33.707681, longitude: -117.200428, zipCode: "92586"},
	{latitude: 33.693929, longitude: -117.250504, zipCode: "92587"},
	{latitude: 33.48294, longitude: -117.226084, zipCode: "92590"},
	{latitude: 33.534332, longitude: -117.109211, zipCode: "92591"},
	{latitude: 33.510939, longitude: -117.021505, zipCode: "92592"},
	{latitude: 33.616349, longitude: -117.260346, zipCode: "92595"},
	{latitude: 33.668913, longitude: -117.047314, zipCode: "92596"},
	{latitude: 33.736498, longitude: -117.734933, zipCode: "92602"},
	{latitude: 33.629259, longitude: -117.79408, zipCode: "92603"},
	{latitude: 33.690689, longitude: -117.789137, zipCode: "92604"},
	{latitude: 33.692523, longitude: -117.831206, zipCode: "92606"},
	{latitude: 33.683363, longitude: -117.65408, zipCode: "92610"},
	{latitude: 33.660018, longitude: -117.824428, zipCode: "92612"},
	{latitude: 33.677951, longitude: -117.83457, zipCode: "92614"},
	{latitude: 33.641973, longitude: -117.841046, zipCode: "92617"},
	{latitude: 33.667307, longitude: -117.73358, zipCode: "92618"},
	{latitude: 33.706601, longitude: -117.756948, zipCode: "92620"},
	{latitude: 33.456649, longitude: -117.666808, zipCode: "92624"},
	{latitude: 33.601966, longitude: -117.864254, zipCode: "92625"},
	{latitude: 33.679591, longitude: -117.908531, zipCode: "92626"},
	{latitude: 33.648112, longitude: -117.921203, zipCode: "92627"},
	{latitude: 33.475061, longitude: -117.70563, zipCode: "92629"},
	{latitude: 33.644929, longitude: -117.684312, zipCode: "92630"},
	{latitude: 33.608229, longitude: -117.726099, zipCode: "92637"},
	{latitude: 33.661029, longitude: -117.967948, zipCode: "92646"},
	{latitude: 33.723228, longitude: -118.008692, zipCode: "92647"},
	{latitude: 33.679045, longitude: -118.013011, zipCode: "92648"},
	{latitude: 33.720242, longitude: -118.045383, zipCode: "92649"},
	{latitude: 33.57148, longitude: -117.772889, zipCode: "92651"},
	{latitude: 33.594012, longitude: -117.700734, zipCode: "92653"},
	{latitude: 33.746029, longitude: -117.987602, zipCode: "92655"},
	{latitude: 33.575201, longitude: -117.730352, zipCode: "92656"},
	{latitude: 33.595058, longitude: -117.829131, zipCode: "92657"},
	{latitude: 33.634626, longitude: -117.874882, zipCode: "92660"},
	{latitude: 33.603594, longitude: -117.912672, zipCode: "92661"},
	{latitude: 33.605086, longitude: -117.892319, zipCode: "92662"},
	{latitude: 33.625204, longitude: -117.928767, zipCode: "92663"},
	{latitude: 33.372936, longitude: -117.520963, zipCode: "92672"},
	{latitude: 33.466908, longitude: -117.614016, zipCode: "92673"},
	{latitude: 33.499774, longitude: -117.653704, zipCode: "92675"},
	{latitude: 33.749488, longitude: -117.616386, zipCode: "92676"},
	{latitude: 33.528654, longitude: -117.701253, zipCode: "92677"},
	{latitude: 33.651816, longitude: -117.507104, zipCode: "92678"},
	{latitude: 33.649438, longitude: -117.561405, zipCode: "92679"},
	{latitude: 33.752428, longitude: -117.99387, zipCode: "92683"},
	{latitude: 33.61873, longitude: -117.6108, zipCode: "92688"},
	{latitude: 33.611945, longitude: -117.665867, zipCode: "92691"},
	{latitude: 33.612175, longitude: -117.640452, zipCode: "92692"},
	{latitude: 33.51501, longitude: -117.580613, zipCode: "92694"},
	{latitude: 33.649727, longitude: -117.849085, zipCode: "92697"},
	{latitude: 33.748622, longitude: -117.858714, zipCode: "92701"},
	{latitude: 33.74852, longitude: -117.906162, zipCode: "92703"},
	{latitude: 33.721131, longitude: -117.90511, zipCode: "92704"},
	{latitude: 33.766007, longitude: -117.786763, zipCode: "92705"},
	{latitude: 33.766002, longitude: -117.881693, zipCode: "92706"},
	{latitude: 33.709935, longitude: -117.870431, zipCode: "92707"},
	{latitude: 33.710582, longitude: -117.951129, zipCode: "92708"},
	{latitude: 33.736808, longitude: -117.819678, zipCode: "92780"},
	{latitude: 33.728377, longitude: -117.797472, zipCode: "92782"},
	{latitude: 33.844983, longitude: -117.952151, zipCode: "92801"},
	{latitude: 33.808314, longitude: -117.923689, zipCode: "92802"},
	{latitude: 33.81801, longitude: -117.975193, zipCode: "92804"},
	{latitude: 33.830053, longitude: -117.905683, zipCode: "92805"},
	{latitude: 33.83796, longitude: -117.870494, zipCode: "92806"},
	{latitude: 33.848733, longitude: -117.788357, zipCode: "92807"},
	{latitude: 33.856247, longitude: -117.740232, zipCode: "92808"},
	{latitude: 33.931943, longitude: -117.854942, zipCode: "92821"},
	{latitude: 33.928535, longitude: -117.796074, zipCode: "92823"},
	{latitude: 33.87951, longitude: -117.896036, zipCode: "92831"},
	{latitude: 33.868772, longitude: -117.929093, zipCode: "92832"},
	{latitude: 33.880509, longitude: -117.961132, zipCode: "92833"},
	{latitude: 33.900573, longitude: -117.914667, zipCode: "92835"},
	{latitude: 33.787165, longitude: -117.931889, zipCode: "92840"},
	{latitude: 33.786833, longitude: -117.982041, zipCode: "92841"},
	{latitude: 33.763963, longitude: -117.931538, zipCode: "92843"},
	{latitude: 33.765599, longitude: -117.969506, zipCode: "92844"},
	{latitude: 33.782907, longitude: -118.026373, zipCode: "92845"},
	{latitude: 33.925506, longitude: -117.547703, zipCode: "92860"},
	{latitude: 33.817985, longitude: -117.811293, zipCode: "92861"},
	{latitude: 33.826393, longitude: -117.851307, zipCode: "92865"},
	{latitude: 33.784537, longitude: -117.844791, zipCode: "92866"},
	{latitude: 33.815667, longitude: -117.799162, zipCode: "92867"},
	{latitude: 33.787967, longitude: -117.876343, zipCode: "92868"},
	{latitude: 33.795479, longitude: -117.764943, zipCode: "92869"},
	{latitude: 33.881158, longitude: -117.854783, zipCode: "92870"},
	{latitude: 33.882614, longitude: -117.528205, zipCode: "92879"},
	{latitude: 33.92539, longitude: -117.607254, zipCode: "92880"},
	{latitude: 33.82388, longitude: -117.570026, zipCode: "92881"},
	{latitude: 33.862874, longitude: -117.600092, zipCode: "92882"},
	{latitude: 33.75801, longitude: -117.480258, zipCode: "92883"},
	{latitude: 33.895411, longitude: -117.800829, zipCode: "92886"},
	{latitude: 33.88728, longitude: -117.733928, zipCode: "92887"},
	{latitude: 34.362376, longitude: -119.351268, zipCode: "93001"},
	{latitude: 34.283668, longitude: -119.223196, zipCode: "93003"},
	{latitude: 34.278191, longitude: -119.164558, zipCode: "93004"},
	{latitude: 34.227888, longitude: -119.079553, zipCode: "93010"},
	{latitude: 34.203394, longitude: -118.991442, zipCode: "93012"},
	{latitude: 34.440133, longitude: -119.503306, zipCode: "93013"},
	{latitude: 34.408873, longitude: -118.870202, zipCode: "93015"},
	{latitude: 34.305181, longitude: -118.883929, zipCode: "93021"},
	{latitude: 34.403276, longitude: -119.298355, zipCode: "93022"},
	{latitude: 34.509095, longitude: -119.25191, zipCode: "93023"},
	{latitude: 34.205097, longitude: -119.17532, zipCode: "93030"},
	{latitude: 34.160093, longitude: -119.135801, zipCode: "93033"},
	{latitude: 34.186287, longitude: -119.228885, zipCode: "93035"},
	{latitude: 34.248141, longitude: -119.153454, zipCode: "93036"},
	{latitude: 34.602043, longitude: -119.019792, zipCode: "93040"},
	{latitude: 34.163189, longitude: -119.186677, zipCode: "93041"},
	{latitude: 33.255655, longitude: -119.503588, zipCode: "93042"},
	{latitude: 34.170161, longitude: -119.202929, zipCode: "93043"},
	{latitude: 34.372031, longitude: -119.096695, zipCode: "93060"},
	{latitude: 34.308048, longitude: -118.686969, zipCode: "93063"},
	{latitude: 34.245272, longitude: -118.699849, zipCode: "93064"},
	{latitude: 34.262388, longitude: -118.772219, zipCode: "93065"},
	{latitude: 34.297699, longitude: -119.014558, zipCode: "93066"},
	{latitude: 34.423101, longitude: -119.596155, zipCode: "93067"},
	{latitude: 34.419259, longitude: -119.708049, zipCode: "93101"},
	{latitude: 34.437367, longitude: -119.679284, zipCode: "93103"},
	{latitude: 34.52904, longitude: -119.767895, zipCode: "93105"},
	{latitude: 34.417243, longitude: -119.851292, zipCode: "93106"},
	{latitude: 34.448974, longitude: -119.605427, zipCode: "93108"},
	{latitude: 34.406408, longitude: -119.726927, zipCode: "93109"},
	{latitude: 34.4395, longitude: -119.767827, zipCode: "93110"},
	{latitude: 34.449185, longitude: -119.804782, zipCode: "93111"},
	{latitude: 34.479453, longitude: -120.084094, zipCode: "93117"},
	{latitude: 35.856859, longitude: -119.507526, zipCode: "93201"},
	{latitude: 36.318686, longitude: -119.70752, zipCode: "93202"},
	{latitude: 35.119995, longitude: -118.845899, zipCode: "93203"},
	{latitude: 35.913203, longitude: -120.082138, zipCode: "93204"},
	{latitude: 35.573416, longitude: -118.486441, zipCode: "93205"},
	{latitude: 35.443913, longitude: -119.479136, zipCode: "93206"},
	{latitude: 35.895294, longitude: -118.595627, zipCode: "93207"},
	{latitude: 36.129777, longitude: -118.639777, zipCode: "93208"},
	{latitude: 36.167464, longitude: -120.438556, zipCode: "93210"},
	{latitude: 36.014226, longitude: -119.562985, zipCode: "93212"},
	{latitude: 35.779464, longitude: -119.191935, zipCode: "93215"},
	{latitude: 35.857141, longitude: -119.04493, zipCode: "93218"},
	{latitude: 35.888238, longitude: -119.294957, zipCode: "93219"},
	{latitude: 35.378735, longitude: -118.781845, zipCode: "93220"},
	{latitude: 36.296394, longitude: -119.029935, zipCode: "93221"},
	{latitude: 34.886245, longitude: -119.220436, zipCode: "93222"},
	{latitude: 36.301112, longitude: -119.207875, zipCode: "93223"},
	{latitude: 35.255235, longitude: -119.625226, zipCode: "93224"},
	{latitude: 34.786607, longitude: -119.029849, zipCode: "93225"},
	{latitude: 35.735652, longitude: -118.738373, zipCode: "93226"},
	{latitude: 36.344445, longitude: -119.418562, zipCode: "93227"},
	{latitude: 36.29223, longitude: -119.622676, zipCode: "93230"},
	{latitude: 36.196092, longitude: -120.084807, zipCode: "93234"},
	{latitude: 36.385067, longitude: -119.223124, zipCode: "93235"},
	{latitude: 36.474499, longitude: -118.904268, zipCode: "93237"},
	{latitude: 35.850772, longitude: -118.49771, zipCode: "93238"},
	{latitude: 36.032769, longitude: -119.986409, zipCode: "93239"},
	{latitude: 35.617547, longitude: -118.452285, zipCode: "93240"},
	{latitude: 35.24526, longitude: -118.90528, zipCode: "93241"},
	{latitude: 36.441137, longitude: -119.730278, zipCode: "93242"},
	{latitude: 34.839984, longitude: -118.814946, zipCode: "93243"},
	{latitude: 36.38634, longitude: -118.989715, zipCode: "93244"},
	{latitude: 36.286104, longitude: -119.834059, zipCode: "93245"},
	{latitude: 36.317668, longitude: -119.927482, zipCode: "93246"},
	{latitude: 36.201735, longitude: -119.083478, zipCode: "93247"},
	{latitude: 35.621697, longitude: -119.887747, zipCode: "93249"},
	{latitude: 35.669076, longitude: -119.201969, zipCode: "93250"},
	{latitude: 35.370603, longitude: -119.69734, zipCode: "93251"},
	{latitude: 34.802883, longitude: -119.359267, zipCode: "93252"},
	{latitude: 34.946218, longitude: -119.802837, zipCode: "93254"},
	{latitude: 35.67733, longitude: -118.101417, zipCode: "93255"},
	{latitude: 35.971731, longitude: -119.313511, zipCode: "93256"},
	{latitude: 35.980858, longitude: -118.889315, zipCode: "93257"},
	{latitude: 36.058332, longitude: -119.152094, zipCode: "93258"},
	{latitude: 35.819086, longitude: -118.661189, zipCode: "93260"},
	{latitude: 35.81148, longitude: -119.116738, zipCode: "93261"},
	{latitude: 36.436989, longitude: -118.671533, zipCode: "93262"},
	{latitude: 35.487248, longitude: -119.278324, zipCode: "93263"},
	{latitude: 36.217713, longitude: -118.693606, zipCode: "93265"},
	{latitude: 36.171729, longitude: -119.856304, zipCode: "93266"},
	{latitude: 36.143623, longitude: -119.073877, zipCode: "93267"},
	{latitude: 35.167567, longitude: -119.410017, zipCode: "93268"},
	{latitude: 35.942448, longitude: -119.058349, zipCode: "93270"},
	{latitude: 36.440041, longitude: -118.88028, zipCode: "93271"},
	{latitude: 36.047354, longitude: -119.344428, zipCode: "93272"},
	{latitude: 36.177934, longitude: -119.379975, zipCode: "93274"},
	{latitude: 35.317597, longitude: -119.409306, zipCode: "93276"},
	{latitude: 36.301334, longitude: -119.372586, zipCode: "93277"},
	{latitude: 35.652242, longitude: -119.4464, zipCode: "93280"},
	{latitude: 35.592099, longitude: -118.297698, zipCode: "93283"},
	{latitude: 35.749127, longitude: -118.493401, zipCode: "93285"},
	{latitude: 36.46271, longitude: -119.093242, zipCode: "93286"},
	{latitude: 35.723801, longitude: -118.920662, zipCode: "93287"},
	{latitude: 36.391777, longitude: -119.372842, zipCode: "93291"},
	{latitude: 36.366243, longitude: -119.211636, zipCode: "93292"},
	{latitude: 35.384336, longitude: -119.020562, zipCode: "93301"},
	{latitude: 35.339439, longitude: -119.023655, zipCode: "93304"},
	{latitude: 35.389875, longitude: -118.98518, zipCode: "93305"},
	{latitude: 35.468193, longitude: -118.779751, zipCode: "93306"},
	{latitude: 35.24512, longitude: -118.9411, zipCode: "93307"},
	{latitude: 35.547493, longitude: -118.946868, zipCode: "93308"},
	{latitude: 35.343046, longitude: -119.06504, zipCode: "93309"},
	{latitude: 35.153729, longitude: -119.168362, zipCode: "93311"},
	{latitude: 35.391845, longitude: -119.119548, zipCode: "93312"},
	{latitude: 35.165449, longitude: -119.043514, zipCode: "93313"},
	{latitude: 35.393866, longitude: -119.244846, zipCode: "93314"},
	{latitude: 35.235871, longitude: -120.615914, zipCode: "93401"},
	{latitude: 35.295232, longitude: -120.835468, zipCode: "93402"},
	{latitude: 35.300465, longitude: -120.734843, zipCode: "93405"},
	{latitude: 35.306628, longitude: -120.669175, zipCode: "93407"},
	{latitude: 35.321107, longitude: -120.718897, zipCode: "93408"},
	{latitude: 35.323566, longitude: -120.697375, zipCode: "93409"},
	{latitude: 35.304456, longitude: -120.656938, zipCode: "93410"},
	{latitude: 35.176011, longitude: -120.476757, zipCode: "93420"},
	{latitude: 35.465349, longitude: -120.689439, zipCode: "93422"},
	{latitude: 35.198617, longitude: -120.786068, zipCode: "93424"},
	{latitude: 35.840139, longitude: -120.975186, zipCode: "93426"},
	{latitude: 34.642959, longitude: -120.239126, zipCode: "93427"},
	{latitude: 35.585, longitude: -121.041474, zipCode: "93428"},
	{latitude: 34.867662, longitude: -120.539904, zipCode: "93429"},
	{latitude: 35.484767, longitude: -120.944173, zipCode: "93430"},
	{latitude: 35.47852, longitude: -120.481435, zipCode: "93432"},
	{latitude: 35.120888, longitude: -120.619664, zipCode: "93433"},
	{latitude: 34.940269, longitude: -120.62007, zipCode: "93434"},
	{latitude: 34.599926, longitude: -120.376532, zipCode: "93436"},
	{latitude: 34.760644, longitude: -120.508877, zipCode: "93437"},
	{latitude: 34.768631, longitude: -120.25826, zipCode: "93440"},
	{latitude: 34.733126, longitude: -120.094969, zipCode: "93441"},
	{latitude: 35.401398, longitude: -120.799935, zipCode: "93442"},
	{latitude: 35.035913, longitude: -120.502562, zipCode: "93444"},
	{latitude: 35.044075, longitude: -120.620707, zipCode: "93445"},
	{latitude: 35.659068, longitude: -120.717584, zipCode: "93446"},
	{latitude: 35.160934, longitude: -120.653342, zipCode: "93449"},
	{latitude: 36.085423, longitude: -120.818522, zipCode: "93450"},
	{latitude: 35.932541, longitude: -120.560861, zipCode: "93451"},
	{latitude: 35.746485, longitude: -121.223348, zipCode: "93452"},
	{latitude: 35.241345, longitude: -120.219268, zipCode: "93453"},
	{latitude: 34.923013, longitude: -120.259463, zipCode: "93454"},
	{latitude: 34.848314, longitude: -120.482883, zipCode: "93455"},
	{latitude: 34.953245, longitude: -120.492119, zipCode: "93458"},
	{latitude: 34.673317, longitude: -119.743616, zipCode: "93460"},
	{latitude: 35.63334, longitude: -120.268391, zipCode: "93461"},
	{latitude: 34.606422, longitude: -120.135431, zipCode: "93463"},
	{latitude: 35.542716, longitude: -120.733796, zipCode: "93465"},
	{latitude: 35.065798, longitude: -118.221782, zipCode: "93501"},
	{latitude: 35.108958, longitude: -117.956461, zipCode: "93505"},
	{latitude: 34.450906, longitude: -118.227614, zipCode: "93510"},
	{latitude: 37.891866, longitude: -118.554569, zipCode: "93512"},
	{latitude: 37.03614, longitude: -118.335671, zipCode: "93513"},
	{latitude: 37.492115, longitude: -118.390408, zipCode: "93514"},
	{latitude: 35.008132, longitude: -117.642563, zipCode: "93516"},
	{latitude: 38.278077, longitude: -119.274809, zipCode: "93517"},
	{latitude: 35.385111, longitude: -118.491168, zipCode: "93518"},
	{latitude: 35.314205, longitude: -117.960728, zipCode: "93519"},
	{latitude: 36.316298, longitude: -117.592333, zipCode: "93522"},
	{latitude: 34.995292, longitude: -117.88399, zipCode: "93523"},
	{latitude: 34.908021, longitude: -117.827817, zipCode: "93524"},
	{latitude: 36.857125, longitude: -118.258145, zipCode: "93526"},
	{latitude: 35.922932, longitude: -118.078936, zipCode: "93527"},
	{latitude: 35.371876, longitude: -117.641803, zipCode: "93528"},
	{latitude: 37.777706, longitude: -119.188102, zipCode: "93529"},
	{latitude: 36.493858, longitude: -117.890389, zipCode: "93530"},
	{latitude: 35.23178, longitude: -118.613067, zipCode: "93531"},
	{latitude: 34.689848, longitude: -118.533689, zipCode: "93532"},
	{latitude: 34.71766, longitude: -118.15232, zipCode: "93534"},
	{latitude: 34.698392, longitude: -117.889945, zipCode: "93535"},
	{latitude: 34.73767, longitude: -118.387424, zipCode: "93536"},
	{latitude: 38.018646, longitude: -119.059726, zipCode: "93541"},
	{latitude: 34.475278, longitude: -117.969599, zipCode: "93543"},
	{latitude: 34.488206, longitude: -117.75661, zipCode: "93544"},
	{latitude: 36.610541, longitude: -118.026281, zipCode: "93545"},
	{latitude: 37.549126, longitude: -118.839814, zipCode: "93546"},
	{latitude: 36.292726, longitude: -117.865352, zipCode: "93549"},
	{latitude: 34.410334, longitude: -118.059957, zipCode: "93550"},
	{latitude: 34.589618, longitude: -118.227381, zipCode: "93551"},
	{latitude: 34.583523, longitude: -118.027343, zipCode: "93552"},
	{latitude: 34.445239, longitude: -117.894868, zipCode: "93553"},
	{latitude: 35.369218, longitude: -117.759126, zipCode: "93554"},
	{latitude: 35.582384, longitude: -117.683597, zipCode: "93555"},
	{latitude: 35.339454, longitude: -117.585133, zipCode: "93558"},
	{latitude: 34.878782, longitude: -118.318326, zipCode: "93560"},
	{latitude: 35.098666, longitude: -118.516706, zipCode: "93561"},
	{latitude: 35.701221, longitude: -117.307544, zipCode: "93562"},
	{latitude: 34.370922, longitude: -117.813363, zipCode: "93563"},
	{latitude: 35.112906, longitude: -118.570979, zipCode: "93581"},
	{latitude: 34.597861, longitude: -117.815573, zipCode: "93591"},
	{latitude: 37.390775, longitude: -119.732363, zipCode: "93601"},
	{latitude: 37.06684, longitude: -119.388942, zipCode: "93602"},
	{latitude: 36.488679, longitude: -118.998074, zipCode: "93603"},
	{latitude: 37.347908, longitude: -119.529808, zipCode: "93604"},
	{latitude: 37.176219, longitude: -119.249523, zipCode: "93605"},
	{latitude: 36.798715, longitude: -120.016273, zipCode: "93606"},
	{latitude: 36.504987, longitude: -120.338664, zipCode: "93608"},
	{latitude: 36.526166, longitude: -119.863891, zipCode: "93609"},
	{latitude: 37.087285, longitude: -120.288867, zipCode: "93610"},
	{latitude: 36.826668, longitude: -119.680572, zipCode: "93611"},
	{latitude: 36.814849, longitude: -119.711909, zipCode: "93612"},
	{latitude: 37.217571, longitude: -119.731069, zipCode: "93614"},
	{latitude: 36.503739, longitude: -119.283846, zipCode: "93615"},
	{latitude: 36.65468, longitude: -119.594333, zipCode: "93616"},
	{latitude: 36.522118, longitude: -119.386628, zipCode: "93618"},
	{latitude: 36.914176, longitude: -119.593979, zipCode: "93619"},
	{latitude: 37.043497, longitude: -120.620508, zipCode: "93620"},
	{latitude: 36.746633, longitude: -119.101388, zipCode: "93621"},
	{latitude: 36.785618, longitude: -120.625382, zipCode: "93622"},
	{latitude: 37.476596, longitude: -119.674433, zipCode: "93623"},
	{latitude: 36.378545, longitude: -120.130151, zipCode: "93624"},
	{latitude: 36.624229, longitude: -119.671752, zipCode: "93625"},
	{latitude: 37.043286, longitude: -119.691306, zipCode: "93626"},
	{latitude: 36.510942, longitude: -120.106292, zipCode: "93627"},
	{latitude: 36.770844, longitude: -118.848499, zipCode: "93628"},
	{latitude: 36.716574, longitude: -120.126955, zipCode: "93630"},
	{latitude: 36.480065, longitude: -119.517339, zipCode: "93631"},
	{latitude: 36.729122, longitude: -118.956235, zipCode: "93633"},
	{latitude: 37.223609, longitude: -119.000936, zipCode: "93634"},
	{latitude: 37.020187, longitude: -120.8817, zipCode: "93635"},
	{latitude: 36.984877, longitude: -119.877519, zipCode: "93636"},
	{latitude: 36.918079, longitude: -120.185933, zipCode: "93637"},
	{latitude: 37.043434, longitude: -120.033693, zipCode: "93638"},
	{latitude: 36.672698, longitude: -120.439737, zipCode: "93640"},
	{latitude: 36.705482, longitude: -119.039956, zipCode: "93641"},
	{latitude: 37.205054, longitude: -119.518434, zipCode: "93643"},
	{latitude: 37.418018, longitude: -119.592567, zipCode: "93644"},
	{latitude: 37.152109, longitude: -119.620136, zipCode: "93645"},
	{latitude: 36.632274, longitude: -119.289353, zipCode: "93646"},
	{latitude: 36.60076, longitude: -119.175485, zipCode: "93647"},
	{latitude: 36.620623, longitude: -119.520582, zipCode: "93648"},
	{latitude: 36.840197, longitude: -119.800121, zipCode: "93650"},
	{latitude: 37.002388, longitude: -119.514858, zipCode: "93651"},
	{latitude: 36.595012, longitude: -119.904577, zipCode: "93652"},
	{latitude: 37.256031, longitude: -119.909059, zipCode: "93653"},
	{latitude: 36.658709, longitude: -119.401274, zipCode: "93654"},
	{latitude: 36.450944, longitude: -119.936819, zipCode: "93656"},
	{latitude: 36.816858, longitude: -119.458702, zipCode: "93657"},
	{latitude: 36.609339, longitude: -120.165168, zipCode: "93660"},
	{latitude: 36.536573, longitude: -119.638651, zipCode: "93662"},
	{latitude: 37.049821, longitude: -119.190726, zipCode: "93664"},
	{latitude: 36.963314, longitude: -120.650315, zipCode: "93665"},
	{latitude: 36.546208, longitude: -119.336177, zipCode: "93666"},
	{latitude: 36.965663, longitude: -119.316835, zipCode: "93667"},
	{latitude: 36.657616, longitude: -120.293101, zipCode: "93668"},
	{latitude: 37.290909, longitude: -119.548365, zipCode: "93669"},
	{latitude: 36.485557, longitude: -119.254153, zipCode: "93670"},
	{latitude: 36.452353, longitude: -119.482381, zipCode: "93673"},
	{latitude: 36.724723, longitude: -119.192779, zipCode: "93675"},
	{latitude: 36.749601, longitude: -119.78781, zipCode: "93701"},
	{latitude: 36.739443, longitude: -119.754209, zipCode: "93702"},
	{latitude: 36.768856, longitude: -119.762411, zipCode: "93703"},
	{latitude: 36.80031, longitude: -119.800906, zipCode: "93704"},
	{latitude: 36.787297, longitude: -119.827321, zipCode: "93705"},
	{latitude: 36.655905, longitude: -119.920259, zipCode: "93706"},
	{latitude: 36.824105, longitude: -119.76271, zipCode: "93710"},
	{latitude: 36.836551, longitude: -119.833849, zipCode: "93711"},
	{latitude: 36.862209, longitude: -119.760793, zipCode: "93720"},
	{latitude: 36.732808, longitude: -119.783829, zipCode: "93721"},
	{latitude: 36.799747, longitude: -119.87762, zipCode: "93722"},
	{latitude: 36.789756, longitude: -119.952559, zipCode: "93723"},
	{latitude: 36.627211, longitude: -119.731888, zipCode: "93725"},
	{latitude: 36.793666, longitude: -119.759715, zipCode: "93726"},
	{latitude: 36.751612, longitude: -119.695397, zipCode: "93727"},
	{latitude: 36.757302, longitude: -119.818282, zipCode: "93728"},
	{latitude: 36.903919, longitude: -119.747335, zipCode: "93730"},
	{latitude: 36.753613, longitude: -119.645581, zipCode: "93737"},
	{latitude: 36.816381, longitude: -119.746115, zipCode: "93740"},
	{latitude: 36.76743, longitude: -119.796035, zipCode: "93741"},
	{latitude: 36.647856, longitude: -121.630305, zipCode: "93901"},
	{latitude: 36.685068, longitude: -121.605992, zipCode: "93905"},
	{latitude: 36.722676, longitude: -121.633667, zipCode: "93906"},
	{latitude: 36.766781, longitude: -121.666722, zipCode: "93907"},
	{latitude: 36.626032, longitude: -121.602085, zipCode: "93908"},
	{latitude: 36.052246, longitude: -121.514405, zipCode: "93920"},
	{latitude: 36.553641, longitude: -121.922271, zipCode: "93921"},
	{latitude: 36.405775, longitude: -121.796018, zipCode: "93923"},
	{latitude: 36.415603, longitude: -121.637651, zipCode: "93924"},
	{latitude: 36.588482, longitude: -121.399217, zipCode: "93925"},
	{latitude: 36.52587, longitude: -121.396735, zipCode: "93926"},
	{latitude: 36.275587, longitude: -121.391924, zipCode: "93927"},
	{latitude: 36.082739, longitude: -121.470787, zipCode: "93928"},
	{latitude: 36.199798, longitude: -121.082308, zipCode: "93930"},
	{latitude: 35.989755, longitude: -121.064359, zipCode: "93932"},
	{latitude: 36.676966, longitude: -121.781451, zipCode: "93933"},
	{latitude: 36.562251, longitude: -121.805604, zipCode: "93940"},
	{latitude: 36.597649, longitude: -121.87331, zipCode: "93943"},
	{latitude: 36.600652, longitude: -121.918535, zipCode: "93944"},
	{latitude: 36.619101, longitude: -121.925439, zipCode: "93950"},
	{latitude: 36.587441, longitude: -121.944816, zipCode: "93953"},
	{latitude: 36.172975, longitude: -120.902682, zipCode: "93954"},
	{latitude: 36.614789, longitude: -121.788022, zipCode: "93955"},
	{latitude: 36.405625, longitude: -121.323055, zipCode: "93960"},
	{latitude: 36.624716, longitude: -121.646487, zipCode: "93962"},
	{latitude: 37.513447, longitude: -122.296824, zipCode: "94002"},
	{latitude: 37.688826, longitude: -122.408935, zipCode: "94005"},
	{latitude: 37.571918, longitude: -122.375028, zipCode: "94010"},
	{latitude: 37.690686, longitude: -122.447738, zipCode: "94014"},
	{latitude: 37.681313, longitude: -122.480852, zipCode: "94015"},
	{latitude: 37.505997, longitude: -122.473318, zipCode: "94018"},
	{latitude: 37.455666, longitude: -122.407966, zipCode: "94019"},
	{latitude: 37.268977, longitude: -122.221545, zipCode: "94020"},
	{latitude: 37.250345, longitude: -122.249636, zipCode: "94021"},
	{latitude: 37.35536, longitude: -122.144935, zipCode: "94022"},
	{latitude: 37.350388, longitude: -122.098686, zipCode: "94024"},
	{latitude: 37.451252, longitude: -122.182343, zipCode: "94025"},
	{latitude: 37.457072, longitude: -122.201058, zipCode: "94027"},
	{latitude: 37.374883, longitude: -122.212675, zipCode: "94028"},
	{latitude: 37.59971, longitude: -122.4033, zipCode: "94030"},
	{latitude: 37.553301, longitude: -122.495834, zipCode: "94037"},
	{latitude: 37.53572, longitude: -122.473676, zipCode: "94038"},
	{latitude: 37.380272, longitude: -122.085221, zipCode: "94040"},
	{latitude: 37.388239, longitude: -122.075351, zipCode: "94041"},
	{latitude: 37.411913, longitude: -122.068919, zipCode: "94043"},
	{latitude: 37.606598, longitude: -122.47723, zipCode: "94044"},
	{latitude: 37.247137, longitude: -122.273493, zipCode: "94060"},
	{latitude: 37.461147, longitude: -122.236948, zipCode: "94061"},
	{latitude: 37.422531, longitude: -122.28582, zipCode: "94062"},
	{latitude: 37.493306, longitude: -122.195606, zipCode: "94063"},
	{latitude: 37.533418, longitude: -122.249558, zipCode: "94065"},
	{latitude: 37.625389, longitude: -122.432356, zipCode: "94066"},
	{latitude: 37.496469, longitude: -122.272556, zipCode: "94070"},
	{latitude: 37.331714, longitude: -122.341466, zipCode: "94074"},
	{latitude: 37.655175, longitude: -122.418384, zipCode: "94080"},
	{latitude: 37.388617, longitude: -122.017933, zipCode: "94085"},
	{latitude: 37.371634, longitude: -122.023202, zipCode: "94086"},
	{latitude: 37.351615, longitude: -122.03668, zipCode: "94087"},
	{latitude: 37.412402, longitude: -122.015605, zipCode: "94089"},
	{latitude: 37.779562, longitude: -122.419293, zipCode: "94102"},
	{latitude: 37.772983, longitude: -122.411343, zipCode: "94103"},
	{latitude: 37.791412, longitude: -122.402129, zipCode: "94104"},
	{latitude: 37.791575, longitude: -122.393733, zipCode: "94105"},
	{latitude: 37.758547, longitude: -122.39954, zipCode: "94107"},
	{latitude: 37.791886, longitude: -122.408517, zipCode: "94108"},
	{latitude: 37.794485, longitude: -122.42227, zipCode: "94109"},
	{latitude: 37.750021, longitude: -122.415201, zipCode: "94110"},
	{latitude: 37.798359, longitude: -122.399201, zipCode: "94111"},
	{latitude: 37.720546, longitude: -122.443166, zipCode: "94112"},
	{latitude: 37.758208, longitude: -122.435556, zipCode: "94114"},
	{latitude: 37.786029, longitude: -122.437366, zipCode: "94115"},
	{latitude: 37.745399, longitude: -122.486065, zipCode: "94116"},
	{latitude: 37.770051, longitude: -122.44428, zipCode: "94117"},
	{latitude: 37.780146, longitude: -122.46153, zipCode: "94118"},
	{latitude: 37.778591, longitude: -122.495912, zipCode: "94121"},
	{latitude: 37.760459, longitude: -122.483894, zipCode: "94122"},
	{latitude: 37.802045, longitude: -122.433969, zipCode: "94123"},
	{latitude: 37.728995, longitude: -122.381315, zipCode: "94124"},
	{latitude: 37.736027, longitude: -122.457207, zipCode: "94127"},
	{latitude: 37.621955, longitude: -122.383797, zipCode: "94128"},
	{latitude: 37.79734, longitude: -122.464466, zipCode: "94129"},
	{latitude: 37.819655, longitude: -122.36866, zipCode: "94130"},
	{latitude: 37.745917, longitude: -122.441473, zipCode: "94131"},
	{latitude: 37.722214, longitude: -122.484083, zipCode: "94132"},
	{latitude: 37.801962, longitude: -122.411, zipCode: "94133"},
	{latitude: 37.718849, longitude: -122.411297, zipCode: "94134"},
	{latitude: 37.772534, longitude: -122.388384, zipCode: "94158"},
	{latitude: 37.74071, longitude: -122.380921, zipCode: "94188"},
	{latitude: 37.444218, longitude: -122.149855, zipCode: "94301"},
	{latitude: 37.457883, longitude: -122.115097, zipCode: "94303"},
	{latitude: 37.397253, longitude: -122.166979, zipCode: "94304"},
	{latitude: 37.418256, longitude: -122.170871, zipCode: "94305"},
	{latitude: 37.4162, longitude: -122.130858, zipCode: "94306"},
	{latitude: 37.575172, longitude: -122.319881, zipCode: "94401"},
	{latitude: 37.540103, longitude: -122.330103, zipCode: "94402"},
	{latitude: 37.538449, longitude: -122.305447, zipCode: "94403"},
	{latitude: 37.555999, longitude: -122.267679, zipCode: "94404"},
	{latitude: 37.774712, longitude: -122.278097, zipCode: "94501"},
	{latitude: 37.739495, longitude: -122.239465, zipCode: "94502"},
	{latitude: 38.187913, longitude: -122.22365, zipCode: "94503"},
	{latitude: 37.871268, longitude: -121.596984, zipCode: "94505"},
	{latitude: 37.806426, longitude: -121.902424, zipCode: "94506"},
	{latitude: 37.85429, longitude: -122.006599, zipCode: "94507"},
	{latitude: 38.585784, longitude: -122.444202, zipCode: "94508"},
	{latitude: 37.992003, longitude: -121.812566, zipCode: "94509"},
	{latitude: 38.113205, longitude: -122.119708, zipCode: "94510"},
	{latitude: 38.02813, longitude: -121.629851, zipCode: "94511"},
	{latitude: 38.13115, longitude: -121.821736, zipCode: "94512"},
	{latitude: 37.924899, longitude: -121.686724, zipCode: "94513"},
	{latitude: 37.825646, longitude: -121.634353, zipCode: "94514"},
	{latitude: 38.635718, longitude: -122.615085, zipCode: "94515"},
	{latitude: 37.827495, longitude: -122.1663, zipCode: "94516"},
	{latitude: 37.891077, longitude: -121.893056, zipCode: "94517"},
	{latitude: 37.950521, longitude: -122.022078, zipCode: "94518"},
	{latitude: 37.988804, longitude: -122.012765, zipCode: "94519"},
	{latitude: 37.993028, longitude: -122.035251, zipCode: "94520"},
	{latitude: 37.95291, longitude: -121.969695, zipCode: "94521"},
	{latitude: 37.954163, longitude: -122.075772, zipCode: "94523"},
	{latitude: 38.048073, longitude: -122.229208, zipCode: "94525"},
	{latitude: 37.814734, longitude: -121.984525, zipCode: "94526"},
	{latitude: 37.841477, longitude: -121.958154, zipCode: "94528"},
	{latitude: 37.920742, longitude: -122.300635, zipCode: "94530"},
	{latitude: 37.956648, longitude: -121.77779, zipCode: "94531"},
	{latitude: 38.280745, longitude: -122.00638, zipCode: "94533"},
	{latitude: 38.241946, longitude: -122.118272, zipCode: "94534"},
	{latitude: 38.270937, longitude: -121.937017, zipCode: "94535"},
	{latitude: 37.570508, longitude: -121.98125, zipCode: "94536"},
	{latitude: 37.501368, longitude: -121.956753, zipCode: "94538"},
	{latitude: 37.516292, longitude: -121.911891, zipCode: "94539"},
	{latitude: 37.673757, longitude: -122.087823, zipCode: "94541"},
	{latitude: 37.65532, longitude: -122.025826, zipCode: "94542"},
	{latitude: 37.633507, longitude: -122.056772, zipCode: "94544"},
	{latitude: 37.629761, longitude: -122.124238, zipCode: "94545"},
	{latitude: 37.745399, longitude: -122.103127, zipCode: "94546"},
	{latitude: 38.006586, longitude: -122.25716, zipCode: "94547"},
	{latitude: 37.974295, longitude: -121.651441, zipCode: "94548"},
	{latitude: 37.901137, longitude: -122.119081, zipCode: "94549"},
	{latitude: 37.519983, longitude: -121.575595, zipCode: "94550"},
	{latitude: 37.750512, longitude: -121.749249, zipCode: "94551"},
	{latitude: 37.705778, longitude: -122.007585, zipCode: "94552"},
	{latitude: 37.978299, longitude: -122.157109, zipCode: "94553"},
	{latitude: 37.547594, longitude: -122.09822, zipCode: "94555"},
	{latitude: 37.831971, longitude: -122.105922, zipCode: "94556"},
	{latitude: 38.389135, longitude: -122.188412, zipCode: "94558"},
	{latitude: 38.251125, longitude: -122.29609, zipCode: "94559"},
	{latitude: 37.504253, longitude: -122.03191, zipCode: "94560"},
	{latitude: 37.993485, longitude: -121.691219, zipCode: "94561"},
	{latitude: 38.430651, longitude: -122.408853, zipCode: "94562"},
	{latitude: 37.873867, longitude: -122.205312, zipCode: "94563"},
	{latitude: 37.990555, longitude: -122.272499, zipCode: "94564"},
	{latitude: 38.008595, longitude: -121.928315, zipCode: "94565"},
	{latitude: 37.648731, longitude: -121.848024, zipCode: "94566"},
	{latitude: 38.667018, longitude: -122.407012, zipCode: "94567"},
	{latitude: 37.715966, longitude: -121.901141, zipCode: "94568"},
	{latitude: 38.044092, longitude: -122.191943, zipCode: "94569"},
	{latitude: 38.152071, longitude: -121.759773, zipCode: "94571"},
	{latitude: 38.023858, longitude: -122.232788, zipCode: "94572"},
	{latitude: 38.458864, longitude: -122.428831, zipCode: "94573"},
	{latitude: 38.542448, longitude: -122.419923, zipCode: "94574"},
	{latitude: 37.840961, longitude: -122.109502, zipCode: "94575"},
	{latitude: 38.549256, longitude: -122.4758, zipCode: "94576"},
	{latitude: 37.716659, longitude: -122.169274, zipCode: "94577"},
	{latitude: 37.706765, longitude: -122.125565, zipCode: "94578"},
	{latitude: 37.6865, longitude: -122.157215, zipCode: "94579"},
	{latitude: 37.678001, longitude: -122.133367, zipCode: "94580"},
	{latitude: 37.765347, longitude: -121.915432, zipCode: "94582"},
	{latitude: 37.771693, longitude: -121.989829, zipCode: "94583"},
	{latitude: 38.191832, longitude: -121.938269, zipCode: "94585"},
	{latitude: 37.567125, longitude: -121.852246, zipCode: "94586"},
	{latitude: 37.590008, longitude: -122.075187, zipCode: "94587"},
	{latitude: 37.792648, longitude: -121.862807, zipCode: "94588"},
	{latitude: 38.141467, longitude: -122.24687, zipCode: "94589"},
	{latitude: 38.095789, longitude: -122.249689, zipCode: "94590"},
	{latitude: 38.127191, longitude: -122.196855, zipCode: "94591"},
	{latitude: 38.090998, longitude: -122.285418, zipCode: "94592"},
	{latitude: 37.872087, longitude: -122.068971, zipCode: "94595"},
	{latitude: 37.889009, longitude: -122.036778, zipCode: "94596"},
	{latitude: 37.917697, longitude: -122.071818, zipCode: "94597"},
	{latitude: 37.916916, longitude: -122.014917, zipCode: "94598"},
	{latitude: 38.399485, longitude: -122.380082, zipCode: "94599"},
	{latitude: 37.77576, longitude: -122.218572, zipCode: "94601"},
	{latitude: 37.805235, longitude: -122.204229, zipCode: "94602"},
	{latitude: 37.739266, longitude: -122.175548, zipCode: "94603"},
	{latitude: 37.761408, longitude: -122.150198, zipCode: "94605"},
	{latitude: 37.791366, longitude: -122.244513, zipCode: "94606"},
	{latitude: 37.808842, longitude: -122.304697, zipCode: "94607"},
	{latitude: 37.836844, longitude: -122.289143, zipCode: "94608"},
	{latitude: 37.834816, longitude: -122.264195, zipCode: "94609"},
	{latitude: 37.812329, longitude: -122.240377, zipCode: "94610"},
	{latitude: 37.831917, longitude: -122.215793, zipCode: "94611"},
	{latitude: 37.807709, longitude: -122.266857, zipCode: "94612"},
	{latitude: 37.78072, longitude: -122.183029, zipCode: "94613"},
	{latitude: 37.844821, longitude: -122.238552, zipCode: "94618"},
	{latitude: 37.792612, longitude: -122.160084, zipCode: "94619"},
	{latitude: 37.75113, longitude: -122.210178, zipCode: "94621"},
	{latitude: 37.866321, longitude: -122.286348, zipCode: "94702"},
	{latitude: 37.863715, longitude: -122.275684, zipCode: "94703"},
	{latitude: 37.866417, longitude: -122.260922, zipCode: "94704"},
	{latitude: 37.865205, longitude: -122.238384, zipCode: "94705"},
	{latitude: 37.889698, longitude: -122.295858, zipCode: "94706"},
	{latitude: 37.89836, longitude: -122.27909, zipCode: "94707"},
	{latitude: 37.903067, longitude: -122.26194, zipCode: "94708"},
	{latitude: 37.878291, longitude: -122.266252, zipCode: "94709"},
	{latitude: 37.863923, longitude: -122.306465, zipCode: "94710"},
	{latitude: 37.871957, longitude: -122.252198, zipCode: "94720"},
	{latitude: 37.949377, longitude: -122.397358, zipCode: "94801"},
	{latitude: 37.95684, longitude: -122.282358, zipCode: "94803"},
	{latitude: 37.915484, longitude: -122.337828, zipCode: "94804"},
	{latitude: 37.942209, longitude: -122.323545, zipCode: "94805"},
	{latitude: 37.978427, longitude: -122.336418, zipCode: "94806"},
	{latitude: 37.980709, longitude: -122.500606, zipCode: "94901"},
	{latitude: 38.02213, longitude: -122.55881, zipCode: "94903"},
	{latitude: 37.947225, longitude: -122.551775, zipCode: "94904"},
	{latitude: 37.886768, longitude: -122.462619, zipCode: "94920"},
	{latitude: 38.356806, longitude: -122.950945, zipCode: "94922"},
	{latitude: 38.339415, longitude: -123.034525, zipCode: "94923"},
	{latitude: 37.966904, longitude: -122.738816, zipCode: "94924"},
	{latitude: 37.931846, longitude: -122.507738, zipCode: "94925"},
	{latitude: 38.34593, longitude: -122.693507, zipCode: "94928"},
	{latitude: 38.254528, longitude: -122.962371, zipCode: "94929"},
	{latitude: 37.969793, longitude: -122.61533, zipCode: "94930"},
	{latitude: 38.325172, longitude: -122.716977, zipCode: "94931"},
	{latitude: 38.017502, longitude: -122.688148, zipCode: "94933"},
	{latitude: 38.116482, longitude: -122.930353, zipCode: "94937"},
	{latitude: 38.030237, longitude: -122.729513, zipCode: "94938"},
	{latitude: 37.938252, longitude: -122.535948, zipCode: "94939"},
	{latitude: 38.203971, longitude: -122.891482, zipCode: "94940"},
	{latitude: 37.924775, longitude: -122.600183, zipCode: "94941"},
	{latitude: 38.122687, longitude: -122.553142, zipCode: "94945"},
	{latitude: 38.074734, longitude: -122.695265, zipCode: "94946"},
	{latitude: 38.1062, longitude: -122.624985, zipCode: "94947"},
	{latitude: 38.060094, longitude: -122.50641, zipCode: "94949"},
	{latitude: 38.021161, longitude: -122.761734, zipCode: "94950"},
	{latitude: 38.322548, longitude: -122.644894, zipCode: "94951"},
	{latitude: 38.234553, longitude: -122.760385, zipCode: "94952"},
	{latitude: 38.23501, longitude: -122.557369, zipCode: "94954"},
	{latitude: 38.021454, longitude: -122.81817, zipCode: "94956"},
	{latitude: 37.963421, longitude: -122.561808, zipCode: "94957"},
	{latitude: 37.997023, longitude: -122.578338, zipCode: "94960"},
	{latitude: 38.00761, longitude: -122.664748, zipCode: "94963"},
	{latitude: 37.940595, longitude: -122.490712, zipCode: "94964"},
	{latitude: 37.837347, longitude: -122.527482, zipCode: "94965"},
	{latitude: 37.925882, longitude: -122.659833, zipCode: "94970"},
	{latitude: 38.233029, longitude: -122.896959, zipCode: "94971"},
	{latitude: 38.336642, longitude: -122.939307, zipCode: "94972"},
	{latitude: 38.012591, longitude: -122.6394, zipCode: "94973"},
	{latitude: 37.438672, longitude: -122.011582, zipCode: "95002"},
	{latitude: 37.0067, longitude: -121.878207, zipCode: "95003"},
	{latitude: 36.874755, longitude: -121.630732, zipCode: "95004"},
	{latitude: 37.098363, longitude: -122.089295, zipCode: "95005"},
	{latitude: 37.171267, longitude: -122.149524, zipCode: "95006"},
	{latitude: 37.110125, longitude: -122.114345, zipCode: "95007"},
	{latitude: 37.279173, longitude: -121.955565, zipCode: "95008"},
	{latitude: 36.976355, longitude: -121.954723, zipCode: "95010"},
	{latitude: 36.788828, longitude: -121.734889, zipCode: "95012"},
	{latitude: 37.215315, longitude: -121.739614, zipCode: "95013"},
	{latitude: 37.308354, longitude: -122.081882, zipCode: "95014"},
	{latitude: 37.094684, longitude: -122.237466, zipCode: "95017"},
	{latitude: 37.083874, longitude: -122.059898, zipCode: "95018"},
	{latitude: 36.935853, longitude: -121.784476, zipCode: "95019"},
	{latitude: 37.01621, longitude: -121.559418, zipCode: "95020"},
	{latitude: 36.919993, longitude: -121.322188, zipCode: "95023"},
	{latitude: 37.221646, longitude: -121.983242, zipCode: "95030"},
	{latitude: 37.18014, longitude: -121.901553, zipCode: "95032"},
	{latitude: 37.158275, longitude: -121.973678, zipCode: "95033"},
	{latitude: 37.440397, longitude: -121.874693, zipCode: "95035"},
	{latitude: 37.13182, longitude: -121.681247, zipCode: "95037"},
	{latitude: 36.827745, longitude: -121.76529, zipCode: "95039"},
	{latitude: 37.050534, longitude: -122.058279, zipCode: "95041"},
	{latitude: 36.518177, longitude: -120.982913, zipCode: "95043"},
	{latitude: 36.825807, longitude: -121.518565, zipCode: "95045"},
	{latitude: 37.099308, longitude: -121.59924, zipCode: "95046"},
	{latitude: 37.350431, longitude: -121.952405, zipCode: "95050"},
	{latitude: 37.348311, longitude: -121.984435, zipCode: "95051"},
	{latitude: 37.348462, longitude: -121.937271, zipCode: "95053"},
	{latitude: 37.3931, longitude: -121.964796, zipCode: "95054"},
	{latitude: 36.9932, longitude: -122.0984, zipCode: "95060"},
	{latitude: 36.973969, longitude: -121.989846, zipCode: "95062"},
	{latitude: 36.991596, longitude: -122.058868, zipCode: "95064"},
	{latitude: 37.032242, longitude: -121.984402, zipCode: "95065"},
	{latitude: 37.067198, longitude: -122.014521, zipCode: "95066"},
	{latitude: 37.254929, longitude: -122.052181, zipCode: "95070"},
	{latitude: 37.04068, longitude: -121.93174, zipCode: "95073"},
	{latitude: 36.793553, longitude: -121.191975, zipCode: "95075"},
	{latitude: 36.945781, longitude: -121.756058, zipCode: "95076"},
	{latitude: 37.346559, longitude: -121.909747, zipCode: "95110"},
	{latitude: 37.283853, longitude: -121.826869, zipCode: "95111"},
	{latitude: 37.344599, longitude: -121.88344, zipCode: "95112"},
	{latitude: 37.334013, longitude: -121.890443, zipCode: "95113"},
	{latitude: 37.350471, longitude: -121.852554, zipCode: "95116"},
	{latitude: 37.310072, longitude: -121.962184, zipCode: "95117"},
	{latitude: 37.255364, longitude: -121.891912, zipCode: "95118"},
	{latitude: 37.226971, longitude: -121.788682, zipCode: "95119"},
	{latitude: 37.185746, longitude: -121.842738, zipCode: "95120"},
	{latitude: 37.304611, longitude: -121.809773, zipCode: "95121"},
	{latitude: 37.329224, longitude: -121.834821, zipCode: "95122"},
	{latitude: 37.244238, longitude: -121.831604, zipCode: "95123"},
	{latitude: 37.256831, longitude: -121.922815, zipCode: "95124"},
	{latitude: 37.295599, longitude: -121.894071, zipCode: "95125"},
	{latitude: 37.326889, longitude: -121.916797, zipCode: "95126"},
	{latitude: 37.371671, longitude: -121.787097, zipCode: "95127"},
	{latitude: 37.315884, longitude: -121.936431, zipCode: "95128"},
	{latitude: 37.305958, longitude: -122.00072, zipCode: "95129"},
	{latitude: 37.277676, longitude: -121.986238, zipCode: "95130"},
	{latitude: 37.387267, longitude: -121.897948, zipCode: "95131"},
	{latitude: 37.418446, longitude: -121.787978, zipCode: "95132"},
	{latitude: 37.371723, longitude: -121.860556, zipCode: "95133"},
	{latitude: 37.430021, longitude: -121.945263, zipCode: "95134"},
	{latitude: 37.223357, longitude: -121.525695, zipCode: "95135"},
	{latitude: 37.271379, longitude: -121.852512, zipCode: "95136"},
	{latitude: 37.245612, longitude: -121.748865, zipCode: "95138"},
	{latitude: 37.224877, longitude: -121.763856, zipCode: "95139"},
	{latitude: 37.351498, longitude: -121.640881, zipCode: "95140"},
	{latitude: 37.333429, longitude: -121.781668, zipCode: "95148"},
	{latitude: 37.959736, longitude: -121.288287, zipCode: "95202"},
	{latitude: 37.954302, longitude: -121.32932, zipCode: "95203"},
	{latitude: 37.973736, longitude: -121.319246, zipCode: "95204"},
	{latitude: 37.964578, longitude: -121.259841, zipCode: "95205"},
	{latitude: 37.908471, longitude: -121.395306, zipCode: "95206"},
	{latitude: 38.002125, longitude: -121.324979, zipCode: "95207"},
	{latitude: 38.046863, longitude: -121.352802, zipCode: "95209"},
	{latitude: 38.031786, longitude: -121.297886, zipCode: "95210"},
	{latitude: 37.980264, longitude: -121.311149, zipCode: "95211"},
	{latitude: 38.049939, longitude: -121.23297, zipCode: "95212"},
	{latitude: 37.949395, longitude: -121.157059, zipCode: "95215"},
	{latitude: 38.037428, longitude: -121.512498, zipCode: "95219"},
	{latitude: 38.200231, longitude: -121.235031, zipCode: "95220"},
	{latitude: 38.072286, longitude: -120.548466, zipCode: "95221"},
	{latitude: 38.05133, longitude: -120.590173, zipCode: "95222"},
	{latitude: 38.446323, longitude: -119.812958, zipCode: "95223"},
	{latitude: 38.231324, longitude: -120.322688, zipCode: "95224"},
	{latitude: 38.202552, longitude: -120.864148, zipCode: "95225"},
	{latitude: 38.236881, longitude: -120.866044, zipCode: "95226"},
	{latitude: 38.210353, longitude: -121.051256, zipCode: "95227"},
	{latitude: 37.907268, longitude: -120.688285, zipCode: "95228"},
	{latitude: 37.99765, longitude: -120.894682, zipCode: "95230"},
	{latitude: 37.873793, longitude: -121.290146, zipCode: "95231"},
	{latitude: 38.360526, longitude: -120.577243, zipCode: "95232"},
	{latitude: 38.186187, longitude: -120.368557, zipCode: "95233"},
	{latitude: 38.044271, longitude: -121.039254, zipCode: "95236"},
	{latitude: 38.153954, longitude: -121.146561, zipCode: "95237"},
	{latitude: 38.123544, longitude: -121.159078, zipCode: "95240"},
	{latitude: 38.137068, longitude: -121.384546, zipCode: "95242"},
	{latitude: 38.302747, longitude: -120.609465, zipCode: "95245"},
	{latitude: 38.249708, longitude: -120.488857, zipCode: "95246"},
	{latitude: 38.136093, longitude: -120.443942, zipCode: "95247"},
	{latitude: 38.317709, longitude: -120.461112, zipCode: "95248"},
	{latitude: 38.18995, longitude: -120.619555, zipCode: "95249"},
	{latitude: 38.080062, longitude: -120.45358, zipCode: "95251"},
	{latitude: 38.158628, longitude: -120.843247, zipCode: "95252"},
	{latitude: 38.147169, longitude: -121.195284, zipCode: "95253"},
	{latitude: 38.199838, longitude: -120.964242, zipCode: "95254"},
	{latitude: 38.419852, longitude: -120.480023, zipCode: "95255"},
	{latitude: 38.379926, longitude: -120.468017, zipCode: "95257"},
	{latitude: 38.172065, longitude: -121.308915, zipCode: "95258"},
	{latitude: 37.306149, longitude: -120.655999, zipCode: "95301"},
	{latitude: 37.470288, longitude: -120.671083, zipCode: "95303"},
	{latitude: 37.680641, longitude: -121.381561, zipCode: "95304"},
	{latitude: 37.815927, longitude: -120.361092, zipCode: "95305"},
	{latitude: 37.362898, longitude: -120.119424, zipCode: "95306"},
	{latitude: 37.555815, longitude: -120.953858, zipCode: "95307"},
	{latitude: 37.87142, longitude: -120.432387, zipCode: "95309"},
	{latitude: 38.085839, longitude: -120.37855, zipCode: "95310"},
	{latitude: 37.705686, longitude: -120.091035, zipCode: "95311"},
	{latitude: 37.421071, longitude: -120.652983, zipCode: "95312"},
	{latitude: 37.417958, longitude: -121.051042, zipCode: "95313"},
	{latitude: 37.422791, longitude: -120.774354, zipCode: "95315"},
	{latitude: 37.539625, longitude: -120.668689, zipCode: "95316"},
	{latitude: 37.12738, longitude: -120.506433, zipCode: "95317"},
	{latitude: 37.666045, longitude: -119.807549, zipCode: "95318"},
	{latitude: 37.629004, longitude: -120.885131, zipCode: "95319"},
	{latitude: 37.818556, longitude: -121.006905, zipCode: "95320"},
	{latitude: 37.931691, longitude: -119.836151, zipCode: "95321"},
	{latitude: 37.18197, longitude: -121.019969, zipCode: "95322"},
	{latitude: 37.604536, longitude: -120.68369, zipCode: "95323"},
	{latitude: 37.393149, longitude: -120.898989, zipCode: "95324"},
	{latitude: 37.480803, longitude: -120.244862, zipCode: "95325"},
	{latitude: 37.589098, longitude: -120.855436, zipCode: "95326"},
	{latitude: 37.84753, longitude: -120.486557, zipCode: "95327"},
	{latitude: 37.558049, longitude: -120.90941, zipCode: "95328"},
	{latitude: 37.67763, longitude: -120.41805, zipCode: "95329"},
	{latitude: 37.81345, longitude: -121.317273, zipCode: "95330"},
	{latitude: 37.244674, longitude: -120.242741, zipCode: "95333"},
	{latitude: 37.349677, longitude: -120.751284, zipCode: "95334"},
	{latitude: 38.124887, longitude: -120.093146, zipCode: "95335"},
	{latitude: 37.830267, longitude: -121.20102, zipCode: "95336"},
	{latitude: 37.743039, longitude: -121.23838, zipCode: "95337"},
	{latitude: 37.507011, longitude: -119.983789, zipCode: "95338"},
	{latitude: 37.340087, longitude: -120.406406, zipCode: "95340"},
	{latitude: 37.231045, longitude: -120.501072, zipCode: "95341"},
	{latitude: 37.590894, longitude: -119.96346, zipCode: "95345"},
	{latitude: 38.070475, longitude: -120.173353, zipCode: "95346"},
	{latitude: 37.420522, longitude: -120.499608, zipCode: "95348"},
	{latitude: 37.67173, longitude: -121.007285, zipCode: "95350"},
	{latitude: 37.624124, longitude: -120.997319, zipCode: "95351"},
	{latitude: 37.639029, longitude: -120.964772, zipCode: "95354"},
	{latitude: 37.672906, longitude: -120.946594, zipCode: "95355"},
	{latitude: 37.718573, longitude: -121.032309, zipCode: "95356"},
	{latitude: 37.671153, longitude: -120.886268, zipCode: "95357"},
	{latitude: 37.611634, longitude: -121.107858, zipCode: "95358"},
	{latitude: 37.326002, longitude: -121.038891, zipCode: "95360"},
	{latitude: 37.79219, longitude: -120.785662, zipCode: "95361"},
	{latitude: 37.373888, longitude: -121.251453, zipCode: "95363"},
	{latitude: 38.2247, longitude: -119.759979, zipCode: "95364"},
	{latitude: 37.301183, longitude: -120.331482, zipCode: "95365"},
	{latitude: 37.762766, longitude: -121.125277, zipCode: "95366"},
	{latitude: 37.7297, longitude: -120.946758, zipCode: "95367"},
	{latitude: 37.713269, longitude: -121.085404, zipCode: "95368"},
	{latitude: 37.525404, longitude: -120.40425, zipCode: "95369"},
	{latitude: 37.984401, longitude: -120.348066, zipCode: "95370"},
	{latitude: 37.991289, longitude: -120.262791, zipCode: "95372"},
	{latitude: 37.316807, longitude: -120.855753, zipCode: "95374"},
	{latitude: 38.225689, longitude: -119.960116, zipCode: "95375"},
	{latitude: 37.736819, longitude: -121.433998, zipCode: "95376"},
	{latitude: 37.674004, longitude: -121.516857, zipCode: "95377"},
	{latitude: 37.966386, longitude: -120.212648, zipCode: "95379"},
	{latitude: 37.47423, longitude: -120.875248, zipCode: "95380"},
	{latitude: 37.527558, longitude: -120.851106, zipCode: "95382"},
	{latitude: 38.092995, longitude: -120.232807, zipCode: "95383"},
	{latitude: 37.609821, longitude: -121.248495, zipCode: "95385"},
	{latitude: 37.66729, longitude: -120.622092, zipCode: "95386"},
	{latitude: 37.554375, longitude: -121.244651, zipCode: "95387"},
	{latitude: 37.421299, longitude: -120.599587, zipCode: "95388"},
	{latitude: 37.784127, longitude: -119.428263, zipCode: "95389"},
	{latitude: 37.78079, longitude: -121.598048, zipCode: "95391"},
	{latitude: 38.449137, longitude: -122.793752, zipCode: "95401"},
	{latitude: 38.503685, longitude: -122.754057, zipCode: "95403"},
	{latitude: 38.525733, longitude: -122.690443, zipCode: "95404"},
	{latitude: 38.438136, longitude: -122.669057, zipCode: "95405"},
	{latitude: 38.394656, longitude: -122.745932, zipCode: "95407"},
	{latitude: 38.468485, longitude: -122.582138, zipCode: "95409"},
	{latitude: 39.23494, longitude: -123.699854, zipCode: "95410"},
	{latitude: 38.712606, longitude: -123.314146, zipCode: "95412"},
	{latitude: 39.020509, longitude: -123.367549, zipCode: "95415"},
	{latitude: 39.687908, longitude: -123.650699, zipCode: "95417"},
	{latitude: 38.423469, longitude: -122.958181, zipCode: "95419"},
	{latitude: 39.359358, longitude: -123.766011, zipCode: "95420"},
	{latitude: 38.583569, longitude: -123.18267, zipCode: "95421"},
	{latitude: 38.985515, longitude: -122.642535, zipCode: "95422"},
	{latitude: 39.067376, longitude: -122.62972, zipCode: "95423"},
	{latitude: 38.823053, longitude: -123.063859, zipCode: "95425"},
	{latitude: 38.833425, longitude: -122.734061, zipCode: "95426"},
	{latitude: 39.253239, longitude: -123.569247, zipCode: "95427"},
	{latitude: 39.824803, longitude: -123.179609, zipCode: "95428"},
	{latitude: 39.756049, longitude: -123.358701, zipCode: "95429"},
	{latitude: 38.461425, longitude: -123.047186, zipCode: "95430"},
	{latitude: 38.349069, longitude: -122.516176, zipCode: "95431"},
	{latitude: 39.108492, longitude: -123.64542, zipCode: "95432"},
	{latitude: 39.003149, longitude: -122.874798, zipCode: "95435"},
	{latitude: 38.486828, longitude: -122.905617, zipCode: "95436"},
	{latitude: 39.443949, longitude: -123.68592, zipCode: "95437"},
	{latitude: 38.490556, longitude: -122.775906, zipCode: "95439"},
	{latitude: 38.721626, longitude: -123.047292, zipCode: "95441"},
	{latitude: 38.378218, longitude: -122.516487, zipCode: "95442"},
	{latitude: 39.050131, longitude: -122.739631, zipCode: "95443"},
	{latitude: 38.432624, longitude: -122.869241, zipCode: "95444"},
	{latitude: 38.83252, longitude: -123.472532, zipCode: "95445"},
	{latitude: 38.519427, longitude: -123.010679, zipCode: "95446"},
	{latitude: 38.622985, longitude: -122.889121, zipCode: "95448"},
	{latitude: 38.936137, longitude: -123.119445, zipCode: "95449"},
	{latitude: 38.509805, longitude: -123.208042, zipCode: "95450"},
	{latitude: 38.922136, longitude: -122.789564, zipCode: "95451"},
	{latitude: 38.429598, longitude: -122.536744, zipCode: "95452"},
	{latitude: 39.045931, longitude: -122.941077, zipCode: "95453"},
	{latitude: 39.784048, longitude: -123.49579, zipCode: "95454"},
	{latitude: 39.258886, longitude: -123.759164, zipCode: "95456"},
	{latitude: 38.924036, longitude: -122.549083, zipCode: "95457"},
	{latitude: 39.109008, longitude: -122.777635, zipCode: "95458"},
	{latitude: 38.976628, longitude: -123.604854, zipCode: "95459"},
	{latitude: 39.305245, longitude: -123.748616, zipCode: "95460"},
	{latitude: 38.754006, longitude: -122.55211, zipCode: "95461"},
	{latitude: 38.473361, longitude: -123.021541, zipCode: "95462"},
	{latitude: 39.18956, longitude: -123.580591, zipCode: "95463"},
	{latitude: 39.126246, longitude: -122.847075, zipCode: "95464"},
	{latitude: 38.417342, longitude: -123.046012, zipCode: "95465"},
	{latitude: 39.056924, longitude: -123.505651, zipCode: "95466"},
	{latitude: 38.812954, longitude: -122.548099, zipCode: "95467"},
	{latitude: 38.918887, longitude: -123.605684, zipCode: "95468"},
	{latitude: 39.398936, longitude: -123.075253, zipCode: "95469"},
	{latitude: 39.299023, longitude: -123.239243, zipCode: "95470"},
	{latitude: 38.532224, longitude: -122.97359, zipCode: "95471"},
	{latitude: 38.398815, longitude: -122.861923, zipCode: "95472"},
	{latitude: 38.255842, longitude: -122.460908, zipCode: "95476"},
	{latitude: 38.684685, longitude: -123.368051, zipCode: "95480"},
	{latitude: 39.136804, longitude: -123.261089, zipCode: "95482"},
	{latitude: 39.20847, longitude: -122.92572, zipCode: "95485"},
	{latitude: 38.474137, longitude: -123.024203, zipCode: "95486"},
	{latitude: 39.727177, longitude: -123.789174, zipCode: "95488"},
	{latitude: 39.47932, longitude: -123.34816, zipCode: "95490"},
	{latitude: 38.530486, longitude: -122.811835, zipCode: "95492"},
	{latitude: 39.222323, longitude: -122.985488, zipCode: "95493"},
	{latitude: 38.896447, longitude: -123.343183, zipCode: "95494"},
	{latitude: 38.725274, longitude: -123.458314, zipCode: "95497"},
	{latitude: 40.80201, longitude: -124.144824, zipCode: "95501"},
	{latitude: 40.738403, longitude: -124.107815, zipCode: "95503"},
	{latitude: 40.166028, longitude: -123.584144, zipCode: "95511"},
	{latitude: 40.286741, longitude: -123.662286, zipCode: "95514"},
	{latitude: 40.963005, longitude: -124.052501, zipCode: "95519"},
	{latitude: 40.843356, longitude: -124.045334, zipCode: "95521"},
	{latitude: 40.820835, longitude: -124.050022, zipCode: "95524"},
	{latitude: 40.953782, longitude: -123.824438, zipCode: "95525"},
	{latitude: 40.461528, longitude: -123.676487, zipCode: "95526"},
	{latitude: 40.950917, longitude: -123.421121, zipCode: "95527"},
	{latitude: 40.496986, longitude: -123.930811, zipCode: "95528"},
	{latitude: 41.862668, longitude: -124.130542, zipCode: "95531"},
	{latitude: 40.501241, longitude: -124.209928, zipCode: "95536"},
	{latitude: 40.727152, longitude: -124.219177, zipCode: "95537"},
	{latitude: 41.868335, longitude: -124.154257, zipCode: "95538"},
	{latitude: 40.566787, longitude: -124.125976, zipCode: "95540"},
	{latitude: 40.093254, longitude: -123.759831, zipCode: "95542"},
	{latitude: 41.888836, longitude: -123.802766, zipCode: "95543"},
	{latitude: 40.261551, longitude: -124.086134, zipCode: "95545"},
	{latitude: 41.185995, longitude: -123.731494, zipCode: "95546"},
	{latitude: 40.569463, longitude: -124.077504, zipCode: "95547"},
	{latitude: 41.589864, longitude: -124.06069, zipCode: "95548"},
	{latitude: 40.666676, longitude: -123.908382, zipCode: "95549"},
	{latitude: 40.78112, longitude: -123.835974, zipCode: "95550"},
	{latitude: 40.674987, longitude: -124.231146, zipCode: "95551"},
	{latitude: 40.344419, longitude: -123.417217, zipCode: "95552"},
	{latitude: 40.231152, longitude: -123.868597, zipCode: "95553"},
	{latitude: 40.296384, longitude: -123.777138, zipCode: "95554"},
	{latitude: 41.302551, longitude: -123.977637, zipCode: "95555"},
	{latitude: 41.307717, longitude: -123.587415, zipCode: "95556"},
	{latitude: 40.33758, longitude: -124.261177, zipCode: "95558"},
	{latitude: 40.190281, longitude: -123.748297, zipCode: "95559"},
	{latitude: 40.142727, longitude: -123.838819, zipCode: "95560"},
	{latitude: 40.4412, longitude: -124.100415, zipCode: "95562"},
	{latitude: 40.894251, longitude: -123.56406, zipCode: "95563"},
	{latitude: 40.79088, longitude: -124.204748, zipCode: "95564"},
	{latitude: 40.455186, longitude: -124.015198, zipCode: "95565"},
	{latitude: 41.950683, longitude: -124.097094, zipCode: "95567"},
	{latitude: 41.484857, longitude: -123.491487, zipCode: "95568"},
	{latitude: 40.361364, longitude: -123.862122, zipCode: "95569"},
	{latitude: 41.113752, longitude: -124.08264, zipCode: "95570"},
	{latitude: 40.365541, longitude: -123.94428, zipCode: "95571"},
	{latitude: 40.914926, longitude: -123.668723, zipCode: "95573"},
	{latitude: 39.858717, longitude: -123.666166, zipCode: "95585"},
	{latitude: 39.969684, longitude: -123.75537, zipCode: "95587"},
	{latitude: 40.061443, longitude: -123.979797, zipCode: "95589"},
	{latitude: 40.087565, longitude: -123.153498, zipCode: "95595"},
	{latitude: 38.424865, longitude: -120.830278, zipCode: "95601"},
	{latitude: 38.991491, longitude: -121.103544, zipCode: "95602"},
	{latitude: 38.911346, longitude: -121.08576, zipCode: "95603"},
	{latitude: 39.238526, longitude: -120.071431, zipCode: "95604"},
	{latitude: 38.593521, longitude: -121.539867, zipCode: "95605"},
	{latitude: 38.756412, longitude: -122.193271, zipCode: "95606"},
	{latitude: 38.830616, longitude: -122.126037, zipCode: "95607"},
	{latitude: 38.626935, longitude: -121.331191, zipCode: "95608"},
	{latitude: 38.694923, longitude: -121.272207, zipCode: "95610"},
	{latitude: 38.384706, longitude: -121.578721, zipCode: "95612"},
	{latitude: 38.81263, longitude: -120.891712, zipCode: "95613"},
	{latitude: 38.886238, longitude: -120.979699, zipCode: "95614"},
	{latitude: 38.310755, longitude: -121.541817, zipCode: "95615"},
	{latitude: 38.554147, longitude: -121.798514, zipCode: "95616"},
	{latitude: 38.543019, longitude: -121.690538, zipCode: "95618"},
	{latitude: 38.680605, longitude: -120.814261, zipCode: "95619"},
	{latitude: 38.404935, longitude: -121.754379, zipCode: "95620"},
	{latitude: 38.695494, longitude: -121.308624, zipCode: "95621"},
	{latitude: 38.632532, longitude: -120.844015, zipCode: "95623"},
	{latitude: 38.43415, longitude: -121.305874, zipCode: "95624"},
	{latitude: 38.357311, longitude: -121.908639, zipCode: "95625"},
	{latitude: 38.733794, longitude: -121.489006, zipCode: "95626"},
	{latitude: 38.734513, longitude: -122.025346, zipCode: "95627"},
	{latitude: 38.652096, longitude: -121.254384, zipCode: "95628"},
	{latitude: 38.512788, longitude: -120.687523, zipCode: "95629"},
	{latitude: 38.662781, longitude: -121.140107, zipCode: "95630"},
	{latitude: 39.054944, longitude: -120.753975, zipCode: "95631"},
	{latitude: 38.274451, longitude: -121.259201, zipCode: "95632"},
	{latitude: 38.849203, longitude: -120.822384, zipCode: "95633"},
	{latitude: 38.900629, longitude: -120.706782, zipCode: "95634"},
	{latitude: 38.910748, longitude: -120.908621, zipCode: "95635"},
	{latitude: 38.611654, longitude: -120.48682, zipCode: "95636"},
	{latitude: 38.853015, longitude: -122.27287, zipCode: "95637"},
	{latitude: 38.342681, longitude: -121.070152, zipCode: "95638"},
	{latitude: 38.40854, longitude: -121.495365, zipCode: "95639"},
	{latitude: 38.341564, longitude: -120.939989, zipCode: "95640"},
	{latitude: 38.155571, longitude: -121.604816, zipCode: "95641"},
	{latitude: 38.343506, longitude: -120.763948, zipCode: "95642"},
	{latitude: 38.894035, longitude: -121.796808, zipCode: "95645"},
	{latitude: 38.631743, longitude: -120.015362, zipCode: "95646"},
	{latitude: 38.922812, longitude: -121.312005, zipCode: "95648"},
	{latitude: 38.809175, longitude: -121.171375, zipCode: "95650"},
	{latitude: 38.820884, longitude: -120.93519, zipCode: "95651"},
	{latitude: 38.662638, longitude: -121.400828, zipCode: "95652"},
	{latitude: 38.697075, longitude: -121.978414, zipCode: "95653"},
	{latitude: 38.549228, longitude: -121.278598, zipCode: "95655"},
	{latitude: 38.881183, longitude: -121.169014, zipCode: "95658"},
	{latitude: 38.858745, longitude: -121.589913, zipCode: "95659"},
	{latitude: 38.678867, longitude: -121.3805, zipCode: "95660"},
	{latitude: 38.741274, longitude: -121.249266, zipCode: "95661"},
	{latitude: 38.689174, longitude: -121.218435, zipCode: "95662"},
	{latitude: 38.857372, longitude: -121.182526, zipCode: "95663"},
	{latitude: 38.801281, longitude: -121.037856, zipCode: "95664"},
	{latitude: 38.395176, longitude: -120.652755, zipCode: "95665"},
	{latitude: 38.541376, longitude: -120.329097, zipCode: "95666"},
	{latitude: 38.733395, longitude: -120.791347, zipCode: "95667"},
	{latitude: 38.827555, longitude: -121.495182, zipCode: "95668"},
	{latitude: 38.489518, longitude: -120.891642, zipCode: "95669"},
	{latitude: 38.604268, longitude: -121.280327, zipCode: "95670"},
	{latitude: 38.694464, longitude: -121.153753, zipCode: "95671"},
	{latitude: 38.72226, longitude: -120.992955, zipCode: "95672"},
	{latitude: 38.690518, longitude: -121.465662, zipCode: "95673"},
	{latitude: 38.953949, longitude: -121.481896, zipCode: "95674"},
	{latitude: 38.545458, longitude: -120.742909, zipCode: "95675"},
	{latitude: 38.87433, longitude: -121.713844, zipCode: "95676"},
	{latitude: 38.79292, longitude: -121.232006, zipCode: "95677"},
	{latitude: 38.765353, longitude: -121.288473, zipCode: "95678"},
	{latitude: 38.916595, longitude: -122.285276, zipCode: "95679"},
	{latitude: 38.241412, longitude: -121.57832, zipCode: "95680"},
	{latitude: 38.998092, longitude: -121.354198, zipCode: "95681"},
	{latitude: 38.598969, longitude: -120.963066, zipCode: "95682"},
	{latitude: 38.511992, longitude: -121.096261, zipCode: "95683"},
	{latitude: 38.566104, longitude: -120.501215, zipCode: "95684"},
	{latitude: 38.434012, longitude: -120.762015, zipCode: "95685"},
	{latitude: 38.157794, longitude: -121.520223, zipCode: "95686"},
	{latitude: 38.333133, longitude: -121.920151, zipCode: "95687"},
	{latitude: 38.421343, longitude: -122.030124, zipCode: "95688"},
	{latitude: 38.470889, longitude: -120.615679, zipCode: "95689"},
	{latitude: 38.203404, longitude: -121.626239, zipCode: "95690"},
	{latitude: 38.627951, longitude: -121.593287, zipCode: "95691"},
	{latitude: 39.043066, longitude: -121.409804, zipCode: "95692"},
	{latitude: 38.398531, longitude: -121.21951, zipCode: "95693"},
	{latitude: 38.57166, longitude: -122.064168, zipCode: "95694"},
	{latitude: 38.694038, longitude: -121.852932, zipCode: "95695"},
	{latitude: 38.732881, longitude: -121.807195, zipCode: "95697"},
	{latitude: 38.816357, longitude: -121.91077, zipCode: "95698"},
	{latitude: 38.436774, longitude: -120.857624, zipCode: "95699"},
	{latitude: 39.226549, longitude: -120.767022, zipCode: "95701"},
	{latitude: 38.989027, longitude: -120.987775, zipCode: "95703"},
	{latitude: 38.749032, longitude: -120.690916, zipCode: "95709"},
	{latitude: 39.081347, longitude: -120.93239, zipCode: "95713"},
	{latitude: 39.201159, longitude: -120.836798, zipCode: "95714"},
	{latitude: 39.276651, longitude: -120.644012, zipCode: "95715"},
	{latitude: 39.142594, longitude: -120.842689, zipCode: "95717"},
	{latitude: 38.850937, longitude: -120.251267, zipCode: "95720"},
	{latitude: 38.81614, longitude: -120.095999, zipCode: "95721"},
	{latitude: 39.009801, longitude: -121.034707, zipCode: "95722"},
	{latitude: 38.761976, longitude: -120.413495, zipCode: "95726"},
	{latitude: 39.294653, longitude: -120.401407, zipCode: "95728"},
	{latitude: 38.840287, longitude: -120.150294, zipCode: "95735"},
	{latitude: 39.039122, longitude: -120.977494, zipCode: "95736"},
	{latitude: 38.562201, longitude: -121.2047, zipCode: "95742"},
	{latitude: 38.746924, longitude: -121.166434, zipCode: "95746"},
	{latitude: 38.778421, longitude: -121.368829, zipCode: "95747"},
	{latitude: 38.331652, longitude: -121.436979, zipCode: "95757"},
	{latitude: 38.429667, longitude: -121.445213, zipCode: "95758"},
	{latitude: 38.683688, longitude: -121.064727, zipCode: "95762"},
	{latitude: 38.819738, longitude: -121.278321, zipCode: "95765"},
	{latitude: 38.698692, longitude: -121.699462, zipCode: "95776"},
	{latitude: 38.59318, longitude: -121.483098, zipCode: "95811"},
	{latitude: 38.580568, longitude: -121.49559, zipCode: "95814"},
	{latitude: 38.605628, longitude: -121.445453, zipCode: "95815"},
	{latitude: 38.575407, longitude: -121.464803, zipCode: "95816"},
	{latitude: 38.550605, longitude: -121.457026, zipCode: "95817"},
	{latitude: 38.554487, longitude: -121.497058, zipCode: "95818"},
	{latitude: 38.566862, longitude: -121.437563, zipCode: "95819"},
	{latitude: 38.53491, longitude: -121.444202, zipCode: "95820"},
	{latitude: 38.625693, longitude: -121.384916, zipCode: "95821"},
	{latitude: 38.512844, longitude: -121.494215, zipCode: "95822"},
	{latitude: 38.474726, longitude: -121.442636, zipCode: "95823"},
	{latitude: 38.517435, longitude: -121.44144, zipCode: "95824"},
	{latitude: 38.591897, longitude: -121.408467, zipCode: "95825"},
	{latitude: 38.543848, longitude: -121.378284, zipCode: "95826"},
	{latitude: 38.54836, longitude: -121.327497, zipCode: "95827"},
	{latitude: 38.489426, longitude: -121.395969, zipCode: "95828"},
	{latitude: 38.489546, longitude: -121.323452, zipCode: "95829"},
	{latitude: 38.490508, longitude: -121.284171, zipCode: "95830"},
	{latitude: 38.495787, longitude: -121.530256, zipCode: "95831"},
	{latitude: 38.447413, longitude: -121.496138, zipCode: "95832"},
	{latitude: 38.619049, longitude: -121.517552, zipCode: "95833"},
	{latitude: 38.635212, longitude: -121.518608, zipCode: "95834"},
	{latitude: 38.668491, longitude: -121.525602, zipCode: "95835"},
	{latitude: 38.693765, longitude: -121.598941, zipCode: "95837"},
	{latitude: 38.645723, longitude: -121.445254, zipCode: "95838"},
	{latitude: 38.660272, longitude: -121.348164, zipCode: "95841"},
	{latitude: 38.687038, longitude: -121.348865, zipCode: "95842"},
	{latitude: 38.715329, longitude: -121.363675, zipCode: "95843"},
	{latitude: 38.584047, longitude: -121.375846, zipCode: "95864"},
	{latitude: 39.223939, longitude: -121.49405, zipCode: "95901"},
	{latitude: 39.121296, longitude: -121.371098, zipCode: "95903"},
	{latitude: 39.462883, longitude: -120.829783, zipCode: "95910"},
	{latitude: 39.000399, longitude: -122.074234, zipCode: "95912"},
	{latitude: 39.629004, longitude: -122.192151, zipCode: "95913"},
	{latitude: 39.407586, longitude: -121.378772, zipCode: "95914"},
	{latitude: 39.991368, longitude: -121.230731, zipCode: "95915"},
	{latitude: 39.651415, longitude: -121.381635, zipCode: "95916"},
	{latitude: 39.435859, longitude: -121.763625, zipCode: "95917"},
	{latitude: 39.301229, longitude: -121.337929, zipCode: "95918"},
	{latitude: 39.434438, longitude: -121.265555, zipCode: "95919"},
	{latitude: 39.449794, longitude: -121.936372, zipCode: "95920"},
	{latitude: 39.46673, longitude: -121.071865, zipCode: "95922"},
	{latitude: 40.183804, longitude: -121.136688, zipCode: "95923"},
	{latitude: 39.484108, longitude: -121.192649, zipCode: "95925"},
	{latitude: 39.745772, longitude: -121.843923, zipCode: "95926"},
	{latitude: 39.681488, longitude: -121.83721, zipCode: "95928"},
	{latitude: 39.725787, longitude: -121.850213, zipCode: "95929"},
	{latitude: 39.548715, longitude: -121.159878, zipCode: "95930"},
	{latitude: 39.246934, longitude: -122.012177, zipCode: "95932"},
	{latitude: 40.0601, longitude: -120.907957, zipCode: "95934"},
	{latitude: 39.379344, longitude: -121.192261, zipCode: "95935"},
	{latitude: 39.580756, longitude: -120.774346, zipCode: "95936"},
	{latitude: 38.884065, longitude: -121.998485, zipCode: "95937"},
	{latitude: 39.604713, longitude: -121.795288, zipCode: "95938"},
	{latitude: 39.561685, longitude: -122.611698, zipCode: "95939"},
	{latitude: 39.516948, longitude: -121.273873, zipCode: "95941"},
	{latitude: 39.915994, longitude: -121.667031, zipCode: "95942"},
	{latitude: 39.575214, longitude: -122.034536, zipCode: "95943"},
	{latitude: 39.54016, longitude: -120.851449, zipCode: "95944"},
	{latitude: 39.193267, longitude: -120.980122, zipCode: "95945"},
	{latitude: 39.209159, longitude: -121.213824, zipCode: "95946"},
	{latitude: 40.159399, longitude: -120.844084, zipCode: "95947"},
	{latitude: 39.339264, longitude: -121.770785, zipCode: "95948"},
	{latitude: 39.10267, longitude: -121.133852, zipCode: "95949"},
	{latitude: 39.043447, longitude: -121.918099, zipCode: "95950"},
	{latitude: 39.722512, longitude: -122.004433, zipCode: "95951"},
	{latitude: 39.259738, longitude: -121.77633, zipCode: "95953"},
	{latitude: 39.854189, longitude: -121.577866, zipCode: "95954"},
	{latitude: 39.294516, longitude: -122.201715, zipCode: "95955"},
	{latitude: 39.88414, longitude: -121.115945, zipCode: "95956"},
	{latitude: 39.052285, longitude: -121.825177, zipCode: "95957"},
	{latitude: 39.564204, longitude: -121.797937, zipCode: "95958"},
	{latitude: 39.351529, longitude: -120.870549, zipCode: "95959"},
	{latitude: 39.441443, longitude: -121.016626, zipCode: "95960"},
	{latitude: 39.041333, longitude: -121.562835, zipCode: "95961"},
	{latitude: 39.323405, longitude: -121.272549, zipCode: "95962"},
	{latitude: 39.747832, longitude: -122.30336, zipCode: "95963"},
	{latitude: 39.593945, longitude: -121.581363, zipCode: "95965"},
	{latitude: 39.475369, longitude: -121.414133, zipCode: "95966"},
	{latitude: 39.435177, longitude: -121.551779, zipCode: "95968"},
	{latitude: 39.720742, longitude: -121.638663, zipCode: "95969"},
	{latitude: 39.410269, longitude: -122.05203, zipCode: "95970"},
	{latitude: 39.929228, longitude: -120.924431, zipCode: "95971"},
	{latitude: 39.422772, longitude: -121.337648, zipCode: "95972"},
	{latitude: 39.876526, longitude: -121.859642, zipCode: "95973"},
	{latitude: 39.497297, longitude: -121.755698, zipCode: "95974"},
	{latitude: 39.224325, longitude: -121.153696, zipCode: "95975"},
	{latitude: 39.176595, longitude: -121.291692, zipCode: "95977"},
	{latitude: 39.908439, longitude: -121.529616, zipCode: "95978"},
	{latitude: 39.290302, longitude: -122.475519, zipCode: "95979"},
	{latitude: 39.652827, longitude: -121.039944, zipCode: "95981"},
	{latitude: 39.173231, longitude: -121.805673, zipCode: "95982"},
	{latitude: 40.058261, longitude: -120.738932, zipCode: "95983"},
	{latitude: 39.982778, longitude: -121.127623, zipCode: "95984"},
	{latitude: 39.361516, longitude: -120.765192, zipCode: "95986"},
	{latitude: 39.103611, longitude: -122.247003, zipCode: "95987"},
	{latitude: 39.497191, longitude: -122.287568, zipCode: "95988"},
	{latitude: 39.015503, longitude: -121.611202, zipCode: "95991"},
	{latitude: 39.082497, longitude: -121.680114, zipCode: "95993"},
	{latitude: 40.577176, longitude: -122.454715, zipCode: "96001"},
	{latitude: 40.527769, longitude: -122.318645, zipCode: "96002"},
	{latitude: 40.667279, longitude: -122.319538, zipCode: "96003"},
	{latitude: 41.133532, longitude: -120.842566, zipCode: "96006"},
	{latitude: 40.456801, longitude: -122.308203, zipCode: "96007"},
	{latitude: 40.722641, longitude: -122.109784, zipCode: "96008"},
	{latitude: 41.05604, longitude: -121.020802, zipCode: "96009"},
	{latitude: 40.95028, longitude: -123.235155, zipCode: "96010"},
	{latitude: 41.055897, longitude: -121.891889, zipCode: "96011"},
	{latitude: 40.973657, longitude: -121.692399, zipCode: "96013"},
	{latitude: 41.388135, longitude: -122.780858, zipCode: "96014"},
	{latitude: 41.51178, longitude: -120.87766, zipCode: "96015"},
	{latitude: 40.894208, longitude: -121.508834, zipCode: "96016"},
	{latitude: 41.10596, longitude: -122.312517, zipCode: "96017"},
	{latitude: 40.687354, longitude: -122.39945, zipCode: "96019"},
	{latitude: 40.306787, longitude: -121.261874, zipCode: "96020"},
	{latitude: 39.923479, longitude: -122.270454, zipCode: "96021"},
	{latitude: 40.336308, longitude: -122.447977, zipCode: "96022"},
	{latitude: 41.909076, longitude: -121.976846, zipCode: "96023"},
	{latitude: 40.547045, longitude: -122.881185, zipCode: "96024"},
	{latitude: 41.200136, longitude: -122.319045, zipCode: "96025"},
	{latitude: 41.389877, longitude: -123.231074, zipCode: "96027"},
	{latitude: 41.007447, longitude: -121.457463, zipCode: "96028"},
	{latitude: 39.939297, longitude: -122.489191, zipCode: "96029"},
	{latitude: 41.175624, longitude: -123.198176, zipCode: "96031"},
	{latitude: 41.585132, longitude: -122.979631, zipCode: "96032"},
	{latitude: 40.740573, longitude: -122.592563, zipCode: "96033"},
	{latitude: 41.444019, longitude: -122.60496, zipCode: "96034"},
	{latitude: 40.04156, longitude: -122.185031, zipCode: "96035"},
	{latitude: 41.544922, longitude: -122.920964, zipCode: "96037"},
	{latitude: 41.6071, longitude: -122.545715, zipCode: "96038"},
	{latitude: 41.668928, longitude: -123.376416, zipCode: "96039"},
	{latitude: 40.789806, longitude: -121.474515, zipCode: "96040"},
	{latitude: 40.513498, longitude: -123.196046, zipCode: "96041"},
	{latitude: 41.933028, longitude: -122.674967, zipCode: "96044"},
	{latitude: 40.610365, longitude: -123.428901, zipCode: "96046"},
	{latitude: 40.479226, longitude: -122.692468, zipCode: "96047"},
	{latitude: 40.830359, longitude: -123.023489, zipCode: "96048"},
	{latitude: 41.853472, longitude: -122.951015, zipCode: "96050"},
	{latitude: 40.916328, longitude: -122.310822, zipCode: "96051"},
	{latitude: 40.68268, longitude: -122.786454, zipCode: "96052"},
	{latitude: 41.261057, longitude: -121.118551, zipCode: "96054"},
	{latitude: 40.099121, longitude: -121.998375, zipCode: "96055"},
	{latitude: 41.067151, longitude: -121.317746, zipCode: "96056"},
	{latitude: 41.271859, longitude: -121.919695, zipCode: "96057"},
	{latitude: 41.727048, longitude: -121.907369, zipCode: "96058"},
	{latitude: 40.433747, longitude: -121.829421, zipCode: "96059"},
	{latitude: 40.347865, longitude: -121.487779, zipCode: "96061"},
	{latitude: 40.555597, longitude: -122.091265, zipCode: "96062"},
	{latitude: 40.370374, longitude: -121.606194, zipCode: "96063"},
	{latitude: 41.754703, longitude: -122.365621, zipCode: "96064"},
	{latitude: 40.890167, longitude: -121.956786, zipCode: "96065"},
	{latitude: 41.322137, longitude: -122.321022, zipCode: "96067"},
	{latitude: 41.102992, longitude: -121.19834, zipCode: "96068"},
	{latitude: 40.685336, longitude: -122.016069, zipCode: "96069"},
	{latitude: 40.694474, longitude: -121.457269, zipCode: "96071"},
	{latitude: 40.598601, longitude: -122.19493, zipCode: "96073"},
	{latitude: 39.862061, longitude: -122.590966, zipCode: "96074"},
	{latitude: 40.362328, longitude: -121.876387, zipCode: "96075"},
	{latitude: 40.391542, longitude: -122.937883, zipCode: "96076"},
	{latitude: 40.082374, longitude: -122.176071, zipCode: "96078"},
	{latitude: 40.16538, longitude: -122.441718, zipCode: "96080"},
	{latitude: 40.795834, longitude: -121.868694, zipCode: "96084"},
	{latitude: 41.740818, longitude: -123.077331, zipCode: "96085"},
	{latitude: 41.924212, longitude: -123.260809, zipCode: "96086"},
	{latitude: 40.633134, longitude: -122.58707, zipCode: "96087"},
	{latitude: 40.51087, longitude: -121.876112, zipCode: "96088"},
	{latitude: 40.007351, longitude: -122.119339, zipCode: "96090"},
	{latitude: 41.043873, longitude: -122.800791, zipCode: "96091"},
	{latitude: 39.970579, longitude: -121.970304, zipCode: "96092"},
	{latitude: 40.765478, longitude: -122.941428, zipCode: "96093"},
	{latitude: 41.514922, longitude: -122.350943, zipCode: "96094"},
	{latitude: 40.636311, longitude: -121.876399, zipCode: "96096"},
	{latitude: 41.756147, longitude: -122.659466, zipCode: "96097"},
	{latitude: 41.456252, longitude: -120.511306, zipCode: "96101"},
	{latitude: 39.780156, longitude: -120.670755, zipCode: "96103"},
	{latitude: 41.460183, longitude: -120.129865, zipCode: "96104"},
	{latitude: 39.688645, longitude: -120.143996, zipCode: "96105"},
	{latitude: 39.735482, longitude: -120.543862, zipCode: "96106"},
	{latitude: 38.408277, longitude: -119.548075, zipCode: "96107"},
	{latitude: 41.78726, longitude: -120.401722, zipCode: "96108"},
	{latitude: 39.994178, longitude: -120.101708, zipCode: "96109"},
	{latitude: 41.241917, longitude: -120.124629, zipCode: "96110"},
	{latitude: 39.415013, longitude: -120.017132, zipCode: "96111"},
	{latitude: 41.892497, longitude: -120.171944, zipCode: "96112"},
	{latitude: 40.188076, longitude: -120.145684, zipCode: "96113"},
	{latitude: 40.292605, longitude: -120.488614, zipCode: "96114"},
	{latitude: 41.691471, longitude: -120.079321, zipCode: "96115"},
	{latitude: 41.257291, longitude: -120.446007, zipCode: "96116"},
	{latitude: 40.533462, longitude: -120.235356, zipCode: "96117"},
	{latitude: 39.639597, longitude: -120.247144, zipCode: "96118"},
	{latitude: 41.040421, longitude: -120.553555, zipCode: "96119"},
	{latitude: 38.742445, longitude: -119.869382, zipCode: "96120"},
	{latitude: 40.164393, longitude: -120.380042, zipCode: "96121"},
	{latitude: 39.820738, longitude: -120.47627, zipCode: "96122"},
	{latitude: 40.845738, longitude: -120.322219, zipCode: "96123"},
	{latitude: 39.638793, longitude: -120.467942, zipCode: "96124"},
	{latitude: 39.613559, longitude: -120.602378, zipCode: "96125"},
	{latitude: 39.549726, longitude: -120.403579, zipCode: "96126"},
	{latitude: 40.348899, longitude: -120.406017, zipCode: "96128"},
	{latitude: 39.835122, longitude: -120.360049, zipCode: "96129"},
	{latitude: 40.549051, longitude: -120.634032, zipCode: "96130"},
	{latitude: 40.900067, longitude: -120.513882, zipCode: "96132"},
	{latitude: 38.620964, longitude: -119.505184, zipCode: "96133"},
	{latitude: 41.835365, longitude: -121.44499, zipCode: "96134"},
	{latitude: 39.812985, longitude: -120.20928, zipCode: "96135"},
	{latitude: 40.34623, longitude: -120.081321, zipCode: "96136"},
	{latitude: 40.469325, longitude: -121.229871, zipCode: "96137"},
	{latitude: 39.232018, longitude: -120.0964, zipCode: "96140"},
	{latitude: 39.098928, longitude: -120.205231, zipCode: "96141"},
	{latitude: 38.998631, longitude: -120.103881, zipCode: "96142"},
	{latitude: 39.260575, longitude: -120.026291, zipCode: "96143"},
	{latitude: 39.180998, longitude: -120.151396, zipCode: "96145"},
	{latitude: 39.215897, longitude: -120.24517, zipCode: "96146"},
	{latitude: 39.249777, longitude: -120.05759, zipCode: "96148"},
	{latitude: 38.87332, longitude: -120.068481, zipCode: "96150"},
	{latitude: 38.762986, longitude: -120.087069, zipCode: "96155"},
	{latitude: 39.347714, longitude: -120.22471, zipCode: "96161"},
	{latitude: 21.406053, longitude: -157.884952, zipCode: "96701"},
	{latitude: 22.13614, longitude: -159.371914, zipCode: "96703"},
	{latitude: 19.294365, longitude: -155.840633, zipCode: "96704"},
	{latitude: 21.899532, longitude: -159.568546, zipCode: "96705"},
	{latitude: 21.344782, longitude: -158.022298, zipCode: "96706"},
	{latitude: 21.363106, longitude: -158.082206, zipCode: "96707"},
	{latitude: 20.88283, longitude: -156.232168, zipCode: "96708"},
	{latitude: 19.842479, longitude: -155.247512, zipCode: "96710"},
	{latitude: 21.651861, longitude: -157.982756, zipCode: "96712"},
	{latitude: 20.73415, longitude: -156.106979, zipCode: "96713"},
	{latitude: 22.13125, longitude: -159.520972, zipCode: "96714"},
	{latitude: 21.910263, longitude: -159.600932, zipCode: "96716"},
	{latitude: 21.557794, longitude: -157.90534, zipCode: "96717"},
	{latitude: 20.240185, longitude: -155.833027, zipCode: "96719"},
	{latitude: 19.65981, longitude: -155.232473, zipCode: "96720"},
	{latitude: 22.18838, longitude: -159.460894, zipCode: "96722"},
	{latitude: 19.596712, longitude: -155.901448, zipCode: "96725"},
	{latitude: 19.421466, longitude: -155.810917, zipCode: "96726"},
	{latitude: 20.105628, longitude: -155.486032, zipCode: "96727"},
	{latitude: 19.86198, longitude: -155.129014, zipCode: "96728"},
	{latitude: 21.17788, longitude: -157.12032, zipCode: "96729"},
	{latitude: 21.538215, longitude: -157.844346, zipCode: "96730"},
	{latitude: 21.678079, longitude: -157.969207, zipCode: "96731"},
	{latitude: 20.877263, longitude: -156.460289, zipCode: "96732"},
	{latitude: 21.395084, longitude: -157.758188, zipCode: "96734"},
	{latitude: 19.107314, longitude: -155.780612, zipCode: "96737"},
	{latitude: 19.871941, longitude: -155.802163, zipCode: "96738"},
	{latitude: 19.75682, longitude: -155.919968, zipCode: "96740"},
	{latitude: 21.955528, longitude: -159.515192, zipCode: "96741"},
	{latitude: 21.176668, longitude: -156.965748, zipCode: "96742"},
	{latitude: 20.02661, longitude: -155.783672, zipCode: "96743"},
	{latitude: 21.447639, longitude: -157.82885, zipCode: "96744"},
	{latitude: 22.092806, longitude: -159.352501, zipCode: "96746"},
	{latitude: 21.916529, longitude: -159.631835, zipCode: "96747"},
	{latitude: 21.131173, longitude: -156.835237, zipCode: "96748"},
	{latitude: 19.621282, longitude: -154.989735, zipCode: "96749"},
	{latitude: 19.522551, longitude: -155.916577, zipCode: "96750"},
	{latitude: 22.10668, longitude: -159.305017, zipCode: "96751"},
	{latitude: 22.116819, longitude: -159.680753, zipCode: "96752"},
	{latitude: 20.727291, longitude: -156.440699, zipCode: "96753"},
	{latitude: 22.188693, longitude: -159.403313, zipCode: "96754"},
	{latitude: 20.19059, longitude: -155.772657, zipCode: "96755"},
	{latitude: 21.904822, longitude: -159.453084, zipCode: "96756"},
	{latitude: 21.154453, longitude: -156.977379, zipCode: "96757"},
	{latitude: 21.460185, longitude: -158.059966, zipCode: "96759"},
	{latitude: 19.55233, longitude: -155.05481, zipCode: "96760"},
	{latitude: 20.919732, longitude: -156.616806, zipCode: "96761"},
	{latitude: 21.618531, longitude: -157.9439, zipCode: "96762"},
	{latitude: 20.836482, longitude: -156.927377, zipCode: "96763"},
	{latitude: 19.993996, longitude: -155.247917, zipCode: "96764"},
	{latitude: 21.925575, longitude: -159.499138, zipCode: "96765"},
	{latitude: 22.011419, longitude: -159.405069, zipCode: "96766"},
	{latitude: 20.829164, longitude: -156.297222, zipCode: "96768"},
	{latitude: 21.958619, longitude: -159.61846, zipCode: "96769"},
	{latitude: 21.13432, longitude: -157.210856, zipCode: "96770"},
	{latitude: 19.525888, longitude: -155.143981, zipCode: "96771"},
	{latitude: 19.065548, longitude: -155.668201, zipCode: "96772"},
	{latitude: 19.912071, longitude: -155.276904, zipCode: "96773"},
	{latitude: 19.968674, longitude: -155.315061, zipCode: "96774"},
	{latitude: 20.002196, longitude: -155.375449, zipCode: "96776"},
	{latitude: 19.227605, longitude: -155.466558, zipCode: "96777"},
	{latitude: 19.427903, longitude: -155.08911, zipCode: "96778"},
	{latitude: 20.909974, longitude: -156.384337, zipCode: "96779"},
	{latitude: 19.965975, longitude: -155.224929, zipCode: "96780"},
	{latitude: 19.805841, longitude: -155.200161, zipCode: "96781"},
	{latitude: 21.451242, longitude: -157.915616, zipCode: "96782"},
	{latitude: 19.843088, longitude: -155.106084, zipCode: "96783"},
	{latitude: 19.478177, longitude: -155.230158, zipCode: "96785"},
	{latitude: 21.537569, longitude: -158.022383, zipCode: "96786"},
	{latitude: 21.483875, longitude: -157.934284, zipCode: "96789"},
	{latitude: 20.711696, longitude: -156.294358, zipCode: "96790"},
	{latitude: 21.56364, longitude: -158.185065, zipCode: "96791"},
	{latitude: 21.468625, longitude: -158.166906, zipCode: "96792"},
	{latitude: 20.89556, longitude: -156.529838, zipCode: "96793"},
	{latitude: 21.335013, longitude: -157.710924, zipCode: "96795"},
	{latitude: 21.961892, longitude: -159.667851, zipCode: "96796"},
	{latitude: 21.40845, longitude: -158.017646, zipCode: "96797"},
	{latitude: -14.322923, longitude: -170.752349, zipCode: "96799"},
	{latitude: 21.316548, longitude: -157.845053, zipCode: "96813"},
	{latitude: 21.291214, longitude: -157.844002, zipCode: "96814"},
	{latitude: 21.27216, longitude: -157.822286, zipCode: "96815"},
	{latitude: 21.29122, longitude: -157.790863, zipCode: "96816"},
	{latitude: 21.355506, longitude: -157.821647, zipCode: "96817"},
	{latitude: 21.342518, longitude: -157.939826, zipCode: "96818"},
	{latitude: 21.347117, longitude: -157.877317, zipCode: "96819"},
	{latitude: 21.336329, longitude: -157.920711, zipCode: "96820"},
	{latitude: 21.311172, longitude: -157.75023, zipCode: "96821"},
	{latitude: 21.320468, longitude: -157.810364, zipCode: "96822"},
	{latitude: 21.292852, longitude: -157.692266, zipCode: "96825"},
	{latitude: 21.291518, longitude: -157.826869, zipCode: "96826"},
	{latitude: 21.301329, longitude: -157.814276, zipCode: "96848"},
	{latitude: 21.303792, longitude: -157.862662, zipCode: "96850"},
	{latitude: 21.335083, longitude: -157.940482, zipCode: "96853"},
	{latitude: 21.488059, longitude: -158.05302, zipCode: "96857"},
	{latitude: 21.344563, longitude: -157.883446, zipCode: "96858"},
	{latitude: 21.36005, longitude: -157.889776, zipCode: "96859"},
	{latitude: 21.356893, longitude: -157.947265, zipCode: "96860"},
	{latitude: 21.436909, longitude: -157.745463, zipCode: "96863"},
	{latitude: 13.452852, longitude: 144.747191, zipCode: "96910"},
	{latitude: 13.478311, longitude: 144.815058, zipCode: "96913"},
	{latitude: 13.374452, longitude: 144.708125, zipCode: "96915"},
	{latitude: 13.265897, longitude: 144.689161, zipCode: "96916"},
	{latitude: 13.282464, longitude: 144.742688, zipCode: "96917"},
	{latitude: 13.355917, longitude: 144.657305, zipCode: "96928"},
	{latitude: 13.567326, longitude: 144.878059, zipCode: "96929"},
	{latitude: 15.186894, longitude: 145.754396, zipCode: "96950"},
	{latitude: 14.152493, longitude: 145.212904, zipCode: "96951"},
	{latitude: 15.009768, longitude: 145.621127, zipCode: "96952"},
	{latitude: 44.990352, longitude: -120.60398, zipCode: "97001"},
	{latitude: 45.240483, longitude: -122.793841, zipCode: "97002"},
	{latitude: 45.503723, longitude: -122.864778, zipCode: "97003"},
	{latitude: 45.254962, longitude: -122.449377, zipCode: "97004"},
	{latitude: 45.491429, longitude: -122.803974, zipCode: "97005"},
	{latitude: 45.52598, longitude: -122.855888, zipCode: "97006"},
	{latitude: 45.442813, longitude: -122.871155, zipCode: "97007"},
	{latitude: 45.460435, longitude: -122.802481, zipCode: "97008"},
	{latitude: 45.422994, longitude: -122.33277, zipCode: "97009"},
	{latitude: 45.387422, longitude: -122.03674, zipCode: "97011"},
	{latitude: 45.220824, longitude: -122.66828, zipCode: "97013"},
	{latitude: 45.643396, longitude: -121.78935, zipCode: "97014"},
	{latitude: 45.416832, longitude: -122.533128, zipCode: "97015"},
	{latitude: 46.052109, longitude: -123.262509, zipCode: "97016"},
	{latitude: 45.17301, longitude: -122.368645, zipCode: "97017"},
	{latitude: 45.897546, longitude: -122.812402, zipCode: "97018"},
	{latitude: 45.482455, longitude: -122.190722, zipCode: "97019"},
	{latitude: 45.222642, longitude: -122.838847, zipCode: "97020"},
	{latitude: 45.38544, longitude: -121.120556, zipCode: "97021"},
	{latitude: 45.346725, longitude: -122.319961, zipCode: "97022"},
	{latitude: 45.204013, longitude: -122.200657, zipCode: "97023"},
	{latitude: 45.544172, longitude: -122.439611, zipCode: "97024"},
	{latitude: 45.10466, longitude: -122.937725, zipCode: "97026"},
	{latitude: 45.38565, longitude: -122.592827, zipCode: "97027"},
	{latitude: 45.288449, longitude: -121.807281, zipCode: "97028"},
	{latitude: 45.300418, longitude: -120.813181, zipCode: "97029"},
	{latitude: 45.509906, longitude: -122.433165, zipCode: "97030"},
	{latitude: 45.610947, longitude: -121.601374, zipCode: "97031"},
	{latitude: 45.179495, longitude: -122.785599, zipCode: "97032"},
	{latitude: 45.20048, longitude: -120.584073, zipCode: "97033"},
	{latitude: 45.409482, longitude: -122.685688, zipCode: "97034"},
	{latitude: 45.413601, longitude: -122.725252, zipCode: "97035"},
	{latitude: 45.067048, longitude: -121.027621, zipCode: "97037"},
	{latitude: 45.095438, longitude: -122.558954, zipCode: "97038"},
	{latitude: 45.47118, longitude: -120.618953, zipCode: "97039"},
	{latitude: 45.617111, longitude: -121.388115, zipCode: "97040"},
	{latitude: 45.419804, longitude: -121.595831, zipCode: "97041"},
	{latitude: 45.207722, longitude: -122.537224, zipCode: "97042"},
	{latitude: 45.320278, longitude: -122.536461, zipCode: "97045"},
	{latitude: 46.014428, longitude: -122.974795, zipCode: "97048"},
	{latitude: 45.370656, longitude: -121.87327, zipCode: "97049"},
	{latitude: 45.67358, longitude: -120.781632, zipCode: "97050"},
	{latitude: 45.888686, longitude: -122.920711, zipCode: "97051"},
	{latitude: 45.827979, longitude: -122.883286, zipCode: "97053"},
	{latitude: 45.960809, longitude: -122.92737, zipCode: "97054"},
	{latitude: 45.361095, longitude: -122.157888, zipCode: "97055"},
	{latitude: 45.799539, longitude: -122.96885, zipCode: "97056"},
	{latitude: 45.004221, longitude: -120.751607, zipCode: "97057"},
	{latitude: 45.552489, longitude: -121.151799, zipCode: "97058"},
	{latitude: 45.531327, longitude: -122.36909, zipCode: "97060"},
	{latitude: 45.365223, longitude: -122.762255, zipCode: "97062"},
	{latitude: 45.224901, longitude: -121.301127, zipCode: "97063"},
	{latitude: 45.859077, longitude: -123.235525, zipCode: "97064"},
	{latitude: 45.607642, longitude: -120.644619, zipCode: "97065"},
	{latitude: 45.323984, longitude: -121.990453, zipCode: "97067"},
	{latitude: 45.351714, longitude: -122.66902, zipCode: "97068"},
	{latitude: 45.308221, longitude: -122.770991, zipCode: "97070"},
	{latitude: 45.134191, longitude: -122.826487, zipCode: "97071"},
	{latitude: 45.476351, longitude: -122.890808, zipCode: "97078"},
	{latitude: 45.478314, longitude: -122.390727, zipCode: "97080"},
	{latitude: 45.445315, longitude: -122.527925, zipCode: "97086"},
	{latitude: 45.426416, longitude: -122.442992, zipCode: "97089"},
	{latitude: 45.090196, longitude: -123.228702, zipCode: "97101"},
	{latitude: 45.789443, longitude: -123.942252, zipCode: "97102"},
	{latitude: 46.13334, longitude: -123.698966, zipCode: "97103"},
	{latitude: 45.654355, longitude: -123.136366, zipCode: "97106"},
	{latitude: 45.560392, longitude: -123.887436, zipCode: "97107"},
	{latitude: 45.27423, longitude: -123.675455, zipCode: "97108"},
	{latitude: 45.73774, longitude: -123.194646, zipCode: "97109"},
	{latitude: 45.891851, longitude: -123.951192, zipCode: "97110"},
	{latitude: 45.284527, longitude: -123.195204, zipCode: "97111"},
	{latitude: 45.275046, longitude: -123.876355, zipCode: "97112"},
	{latitude: 45.507876, longitude: -123.042958, zipCode: "97113"},
	{latitude: 45.187855, longitude: -123.07657, zipCode: "97114"},
	{latitude: 45.277411, longitude: -123.027128, zipCode: "97115"},
	{latitude: 45.58087, longitude: -123.165766, zipCode: "97116"},
	{latitude: 45.631941, longitude: -123.335885, zipCode: "97117"},
	{latitude: 45.556365, longitude: -123.916492, zipCode: "97118"},
	{latitude: 45.471666, longitude: -123.221412, zipCode: "97119"},
	{latitude: 46.203665, longitude: -123.981224, zipCode: "97121"},
	{latitude: 45.161422, longitude: -123.82578, zipCode: "97122"},
	{latitude: 45.444372, longitude: -122.976426, zipCode: "97123"},
	{latitude: 45.569201, longitude: -122.947143, zipCode: "97124"},
	{latitude: 45.663043, longitude: -123.216945, zipCode: "97125"},
	{latitude: 45.246263, longitude: -123.111527, zipCode: "97127"},
	{latitude: 45.211741, longitude: -123.281699, zipCode: "97128"},
	{latitude: 45.706794, longitude: -123.93868, zipCode: "97130"},
	{latitude: 45.711405, longitude: -123.824306, zipCode: "97131"},
	{latitude: 45.324219, longitude: -122.98733, zipCode: "97132"},
	{latitude: 45.689392, longitude: -123.013035, zipCode: "97133"},
	{latitude: 45.461766, longitude: -123.970593, zipCode: "97134"},
	{latitude: 45.19858, longitude: -123.961925, zipCode: "97135"},
	{latitude: 45.630592, longitude: -123.920769, zipCode: "97136"},
	{latitude: 45.219509, longitude: -122.948493, zipCode: "97137"},
	{latitude: 45.905454, longitude: -123.708754, zipCode: "97138"},
	{latitude: 45.35308, longitude: -122.865845, zipCode: "97140"},
	{latitude: 45.507531, longitude: -123.737139, zipCode: "97141"},
	{latitude: 45.743491, longitude: -123.303052, zipCode: "97144"},
	{latitude: 45.84373, longitude: -123.951649, zipCode: "97145"},
	{latitude: 46.12514, longitude: -123.911368, zipCode: "97146"},
	{latitude: 45.685605, longitude: -123.88613, zipCode: "97147"},
	{latitude: 45.361441, longitude: -123.259852, zipCode: "97148"},
	{latitude: 45.112951, longitude: -123.939045, zipCode: "97149"},
	{latitude: 45.508551, longitude: -122.688283, zipCode: "97201"},
	{latitude: 45.482012, longitude: -122.644451, zipCode: "97202"},
	{latitude: 45.603552, longitude: -122.737916, zipCode: "97203"},
	{latitude: 45.518371, longitude: -122.673946, zipCode: "97204"},
	{latitude: 45.52009, longitude: -122.702169, zipCode: "97205"},
	{latitude: 45.481598, longitude: -122.598295, zipCode: "97206"},
	{latitude: 45.528666, longitude: -122.678981, zipCode: "97208"},
	{latitude: 45.531124, longitude: -122.683951, zipCode: "97209"},
	{latitude: 45.545484, longitude: -122.736853, zipCode: "97210"},
	{latitude: 45.585627, longitude: -122.637259, zipCode: "97211"},
	{latitude: 45.544256, longitude: -122.643248, zipCode: "97212"},
	{latitude: 45.538194, longitude: -122.600014, zipCode: "97213"},
	{latitude: 45.513962, longitude: -122.644031, zipCode: "97214"},
	{latitude: 45.51505, longitude: -122.597392, zipCode: "97215"},
	{latitude: 45.513946, longitude: -122.558527, zipCode: "97216"},
	{latitude: 45.596784, longitude: -122.695414, zipCode: "97217"},
	{latitude: 45.576236, longitude: -122.600873, zipCode: "97218"},
	{latitude: 45.454231, longitude: -122.698526, zipCode: "97219"},
	{latitude: 45.550787, longitude: -122.55956, zipCode: "97220"},
	{latitude: 45.496555, longitude: -122.730453, zipCode: "97221"},
	{latitude: 45.440273, longitude: -122.615888, zipCode: "97222"},
	{latitude: 45.44029, longitude: -122.776604, zipCode: "97223"},
	{latitude: 45.4057, longitude: -122.795689, zipCode: "97224"},
	{latitude: 45.503048, longitude: -122.770132, zipCode: "97225"},
	{latitude: 45.544151, longitude: -122.677095, zipCode: "97227"},
	{latitude: 45.549242, longitude: -122.809952, zipCode: "97229"},
	{latitude: 45.557757, longitude: -122.505268, zipCode: "97230"},
	{latitude: 45.687631, longitude: -122.824202, zipCode: "97231"},
	{latitude: 45.528929, longitude: -122.643927, zipCode: "97232"},
	{latitude: 45.515307, longitude: -122.502168, zipCode: "97233"},
	{latitude: 45.483, longitude: -122.512338, zipCode: "97236"},
	{latitude: 45.489938, longitude: -122.690453, zipCode: "97239"},
	{latitude: 45.513258, longitude: -122.675435, zipCode: "97258"},
	{latitude: 45.482967, longitude: -122.557619, zipCode: "97266"},
	{latitude: 45.408427, longitude: -122.612867, zipCode: "97267"},
	{latitude: 44.951839, longitude: -123.006246, zipCode: "97301"},
	{latitude: 44.903176, longitude: -123.064432, zipCode: "97302"},
	{latitude: 45.030354, longitude: -123.023689, zipCode: "97303"},
	{latitude: 45.006596, longitude: -123.111691, zipCode: "97304"},
	{latitude: 45.015712, longitude: -122.929101, zipCode: "97305"},
	{latitude: 44.842904, longitude: -123.094751, zipCode: "97306"},
	{latitude: 44.875911, longitude: -122.974514, zipCode: "97310"},
	{latitude: 44.902416, longitude: -122.906292, zipCode: "97317"},
	{latitude: 44.65345, longitude: -123.139526, zipCode: "97321"},
	{latitude: 44.627553, longitude: -123.017613, zipCode: "97322"},
	{latitude: 44.33862, longitude: -123.62776, zipCode: "97324"},
	{latitude: 44.833821, longitude: -122.85223, zipCode: "97325"},
	{latitude: 44.637919, longitude: -123.665781, zipCode: "97326"},
	{latitude: 44.375669, longitude: -122.947812, zipCode: "97327"},
	{latitude: 44.371153, longitude: -122.289743, zipCode: "97329"},
	{latitude: 44.647997, longitude: -123.259576, zipCode: "97330"},
	{latitude: 44.56339, longitude: -123.281237, zipCode: "97331"},
	{latitude: 44.468069, longitude: -123.293831, zipCode: "97333"},
	{latitude: 44.637754, longitude: -122.880457, zipCode: "97335"},
	{latitude: 44.356759, longitude: -122.850832, zipCode: "97336"},
	{latitude: 44.9352, longitude: -123.339152, zipCode: "97338"},
	{latitude: 44.819359, longitude: -124.043333, zipCode: "97341"},
	{latitude: 44.739311, longitude: -121.879087, zipCode: "97342"},
	{latitude: 44.575254, longitude: -123.75149, zipCode: "97343"},
	{latitude: 44.868832, longitude: -123.465969, zipCode: "97344"},
	{latitude: 44.411545, longitude: -122.578909, zipCode: "97345"},
	{latitude: 44.769479, longitude: -122.378538, zipCode: "97346"},
	{latitude: 45.078353, longitude: -123.659383, zipCode: "97347"},
	{latitude: 44.383294, longitude: -123.122261, zipCode: "97348"},
	{latitude: 44.658055, longitude: -121.987865, zipCode: "97350"},
	{latitude: 44.815295, longitude: -123.129486, zipCode: "97351"},
	{latitude: 44.749395, longitude: -123.027593, zipCode: "97352"},
	{latitude: 44.532241, longitude: -122.821044, zipCode: "97355"},
	{latitude: 44.752661, longitude: -123.825581, zipCode: "97357"},
	{latitude: 44.81199, longitude: -122.399639, zipCode: "97358"},
	{latitude: 44.750447, longitude: -122.482368, zipCode: "97360"},
	{latitude: 44.778573, longitude: -123.326057, zipCode: "97361"},
	{latitude: 45.062012, longitude: -122.75654, zipCode: "97362"},
	{latitude: 44.998839, longitude: -123.986967, zipCode: "97364"},
	{latitude: 44.671297, longitude: -124.030569, zipCode: "97365"},
	{latitude: 44.581254, longitude: -124.047978, zipCode: "97366"},
	{latitude: 44.914756, longitude: -123.974231, zipCode: "97367"},
	{latitude: 45.024543, longitude: -123.903115, zipCode: "97368"},
	{latitude: 44.76005, longitude: -124.056774, zipCode: "97369"},
	{latitude: 44.564899, longitude: -123.47559, zipCode: "97370"},
	{latitude: 44.990634, longitude: -123.191125, zipCode: "97371"},
	{latitude: 45.057999, longitude: -122.77642, zipCode: "97373"},
	{latitude: 44.69286, longitude: -122.774937, zipCode: "97374"},
	{latitude: 44.986965, longitude: -122.598275, zipCode: "97375"},
	{latitude: 44.498235, longitude: -123.975013, zipCode: "97376"},
	{latitude: 44.46479, longitude: -123.103063, zipCode: "97377"},
	{latitude: 45.084883, longitude: -123.422867, zipCode: "97378"},
	{latitude: 44.786018, longitude: -123.929969, zipCode: "97380"},
	{latitude: 44.939485, longitude: -122.720739, zipCode: "97381"},
	{latitude: 44.79942, longitude: -122.722525, zipCode: "97383"},
	{latitude: 44.791099, longitude: -122.620074, zipCode: "97384"},
	{latitude: 44.865853, longitude: -122.72355, zipCode: "97385"},
	{latitude: 44.359177, longitude: -122.727043, zipCode: "97386"},
	{latitude: 44.880014, longitude: -124.018204, zipCode: "97388"},
	{latitude: 44.532672, longitude: -123.090331, zipCode: "97389"},
	{latitude: 44.355893, longitude: -123.862682, zipCode: "97390"},
	{latitude: 44.58498, longitude: -123.884047, zipCode: "97391"},
	{latitude: 44.795611, longitude: -122.930376, zipCode: "97392"},
	{latitude: 44.398974, longitude: -124.016407, zipCode: "97394"},
	{latitude: 45.104718, longitude: -123.549185, zipCode: "97396"},
	{latitude: 44.067988, longitude: -123.080181, zipCode: "97401"},
	{latitude: 44.047736, longitude: -123.230905, zipCode: "97402"},
	{latitude: 44.035412, longitude: -123.052413, zipCode: "97403"},
	{latitude: 44.105226, longitude: -123.133025, zipCode: "97404"},
	{latitude: 43.953602, longitude: -123.155864, zipCode: "97405"},
	{latitude: 42.615873, longitude: -124.021266, zipCode: "97406"},
	{latitude: 44.142995, longitude: -123.058241, zipCode: "97408"},
	{latitude: 42.801618, longitude: -123.11932, zipCode: "97410"},
	{latitude: 43.058954, longitude: -124.346773, zipCode: "97411"},
	{latitude: 44.20493, longitude: -123.54782, zipCode: "97412"},
	{latitude: 44.053948, longitude: -121.945845, zipCode: "97413"},
	{latitude: 42.967578, longitude: -124.1561, zipCode: "97414"},
	{latitude: 42.13783, longitude: -124.133433, zipCode: "97415"},
	{latitude: 43.037299, longitude: -123.695025, zipCode: "97416"},
	{latitude: 42.94021, longitude: -123.214865, zipCode: "97417"},
	{latitude: 44.180828, longitude: -123.413145, zipCode: "97419"},
	{latitude: 43.346779, longitude: -124.105673, zipCode: "97420"},
	{latitude: 43.185814, longitude: -124.174163, zipCode: "97423"},
	{latitude: 43.746815, longitude: -123.053386, zipCode: "97424"},
	{latitude: 43.89893, longitude: -123.029954, zipCode: "97426"},
	{latitude: 42.967743, longitude: -123.054406, zipCode: "97429"},
	{latitude: 44.155053, longitude: -123.711683, zipCode: "97430"},
	{latitude: 43.869538, longitude: -122.84149, zipCode: "97431"},
	{latitude: 43.104294, longitude: -123.429446, zipCode: "97432"},
	{latitude: 43.621458, longitude: -122.702625, zipCode: "97434"},
	{latitude: 43.690188, longitude: -123.328152, zipCode: "97435"},
	{latitude: 43.643348, longitude: -123.586975, zipCode: "97436"},
	{latitude: 44.099083, longitude: -123.38707, zipCode: "97437"},
	{latitude: 43.944242, longitude: -122.682456, zipCode: "97438"},
	{latitude: 44.073537, longitude: -124.0275, zipCode: "97439"},
	{latitude: 43.751829, longitude: -124.166146, zipCode: "97441"},
	{latitude: 42.780374, longitude: -123.425369, zipCode: "97442"},
	{latitude: 43.250484, longitude: -123.004257, zipCode: "97443"},
	{latitude: 42.440347, longitude: -124.327441, zipCode: "97444"},
	{latitude: 44.260727, longitude: -123.054648, zipCode: "97446"},
	{latitude: 43.277863, longitude: -122.49955, zipCode: "97447"},
	{latitude: 44.210894, longitude: -123.279343, zipCode: "97448"},
	{latitude: 43.563367, longitude: -124.040505, zipCode: "97449"},
	{latitude: 42.908908, longitude: -124.374673, zipCode: "97450"},
	{latitude: 43.853108, longitude: -123.307095, zipCode: "97451"},
	{latitude: 43.874112, longitude: -122.750081, zipCode: "97452"},
	{latitude: 43.980396, longitude: -123.850469, zipCode: "97453"},
	{latitude: 44.218056, longitude: -122.819137, zipCode: "97454"},
	{latitude: 43.968553, longitude: -122.919656, zipCode: "97455"},
	{latitude: 44.343303, longitude: -123.355, zipCode: "97456"},
	{latitude: 43.064513, longitude: -123.229471, zipCode: "97457"},
	{latitude: 43.048844, longitude: -124.008543, zipCode: "97458"},
	{latitude: 43.484217, longitude: -124.182735, zipCode: "97459"},
	{latitude: 44.098278, longitude: -123.505515, zipCode: "97461"},
	{latitude: 43.486928, longitude: -123.41831, zipCode: "97462"},
	{latitude: 43.740681, longitude: -122.387167, zipCode: "97463"},
	{latitude: 42.763521, longitude: -124.344681, zipCode: "97465"},
	{latitude: 42.848945, longitude: -124.085233, zipCode: "97466"},
	{latitude: 43.738923, longitude: -123.907887, zipCode: "97467"},
	{latitude: 42.922517, longitude: -123.417703, zipCode: "97469"},
	{latitude: 43.250676, longitude: -123.24241, zipCode: "97470"},
	{latitude: 43.21907, longitude: -123.487894, zipCode: "97471"},
	{latitude: 43.693676, longitude: -123.914793, zipCode: "97473"},
	{latitude: 42.842229, longitude: -124.402796, zipCode: "97476"},
	{latitude: 44.058465, longitude: -123.011597, zipCode: "97477"},
	{latitude: 44.089122, longitude: -122.842278, zipCode: "97478"},
	{latitude: 43.403997, longitude: -123.196293, zipCode: "97479"},
	{latitude: 44.128446, longitude: -123.832726, zipCode: "97480"},
	{latitude: 43.115279, longitude: -123.56598, zipCode: "97481"},
	{latitude: 42.933641, longitude: -122.845947, zipCode: "97484"},
	{latitude: 43.351883, longitude: -123.528156, zipCode: "97486"},
	{latitude: 43.988857, longitude: -123.3872, zipCode: "97487"},
	{latitude: 44.128572, longitude: -122.41733, zipCode: "97488"},
	{latitude: 44.11146, longitude: -122.60682, zipCode: "97489"},
	{latitude: 44.00792, longitude: -123.605229, zipCode: "97490"},
	{latitude: 43.803028, longitude: -122.515672, zipCode: "97492"},
	{latitude: 43.904594, longitude: -124.023424, zipCode: "97493"},
	{latitude: 43.330557, longitude: -123.327782, zipCode: "97494"},
	{latitude: 43.281204, longitude: -123.314335, zipCode: "97495"},
	{latitude: 43.066483, longitude: -123.472707, zipCode: "97496"},
	{latitude: 42.665706, longitude: -123.425495, zipCode: "97497"},
	{latitude: 44.273368, longitude: -124.009002, zipCode: "97498"},
	{latitude: 43.591682, longitude: -123.237036, zipCode: "97499"},
	{latitude: 42.285729, longitude: -122.897456, zipCode: "97501"},
	{latitude: 42.408529, longitude: -122.945969, zipCode: "97502"},
	{latitude: 42.604691, longitude: -122.923719, zipCode: "97503"},
	{latitude: 42.328207, longitude: -122.797427, zipCode: "97504"},
	{latitude: 42.169559, longitude: -122.511785, zipCode: "97520"},
	{latitude: 42.633855, longitude: -122.434112, zipCode: "97522"},
	{latitude: 42.104149, longitude: -123.644574, zipCode: "97523"},
	{latitude: 42.467469, longitude: -122.647705, zipCode: "97524"},
	{latitude: 42.430826, longitude: -123.059798, zipCode: "97525"},
	{latitude: 42.535703, longitude: -123.338257, zipCode: "97526"},
	{latitude: 42.380766, longitude: -123.386568, zipCode: "97527"},
	{latitude: 42.140414, longitude: -123.111983, zipCode: "97530"},
	{latitude: 42.210128, longitude: -123.644729, zipCode: "97531"},
	{latitude: 42.518364, longitude: -123.656431, zipCode: "97532"},
	{latitude: 42.019254, longitude: -123.699385, zipCode: "97534"},
	{latitude: 42.267976, longitude: -122.812339, zipCode: "97535"},
	{latitude: 42.781908, longitude: -122.494698, zipCode: "97536"},
	{latitude: 42.552527, longitude: -123.145949, zipCode: "97537"},
	{latitude: 42.314375, longitude: -123.667583, zipCode: "97538"},
	{latitude: 42.576422, longitude: -122.786215, zipCode: "97539"},
	{latitude: 42.192697, longitude: -122.817256, zipCode: "97540"},
	{latitude: 42.775859, longitude: -122.745256, zipCode: "97541"},
	{latitude: 42.377249, longitude: -123.560325, zipCode: "97543"},
	{latitude: 42.207015, longitude: -123.300113, zipCode: "97544"},
	{latitude: 42.443391, longitude: -122.074145, zipCode: "97601"},
	{latitude: 42.132624, longitude: -121.664612, zipCode: "97603"},
	{latitude: 42.977733, longitude: -122.206736, zipCode: "97604"},
	{latitude: 42.276396, longitude: -119.90391, zipCode: "97620"},
	{latitude: 42.507326, longitude: -121.285253, zipCode: "97621"},
	{latitude: 42.391276, longitude: -121.006931, zipCode: "97622"},
	{latitude: 42.198886, longitude: -121.312645, zipCode: "97623"},
	{latitude: 42.548485, longitude: -121.849636, zipCode: "97624"},
	{latitude: 42.315005, longitude: -121.58392, zipCode: "97625"},
	{latitude: 42.724806, longitude: -122.013922, zipCode: "97626"},
	{latitude: 42.070151, longitude: -122.072244, zipCode: "97627"},
	{latitude: 42.30592, longitude: -120.387701, zipCode: "97630"},
	{latitude: 42.036014, longitude: -121.405479, zipCode: "97632"},
	{latitude: 42.03414, longitude: -121.562829, zipCode: "97633"},
	{latitude: 42.133724, longitude: -121.802382, zipCode: "97634"},
	{latitude: 41.929992, longitude: -120.294195, zipCode: "97635"},
	{latitude: 42.657898, longitude: -120.578432, zipCode: "97636"},
	{latitude: 42.472998, longitude: -119.960274, zipCode: "97637"},
	{latitude: 43.149706, longitude: -121.019853, zipCode: "97638"},
	{latitude: 42.428528, longitude: -121.491604, zipCode: "97639"},
	{latitude: 42.986303, longitude: -120.536402, zipCode: "97640"},
	{latitude: 43.28819, longitude: -120.531731, zipCode: "97641"},
	{latitude: 43.997653, longitude: -121.034186, zipCode: "97701"},
	{latitude: 43.982794, longitude: -121.209726, zipCode: "97702"},
	{latitude: 44.176389, longitude: -121.382887, zipCode: "97703"},
	{latitude: 43.826223, longitude: -121.492124, zipCode: "97707"},
	{latitude: 43.897567, longitude: -121.858605, zipCode: "97709"},
	{latitude: 42.143383, longitude: -118.538755, zipCode: "97710"},
	{latitude: 44.706235, longitude: -120.667902, zipCode: "97711"},
	{latitude: 43.77784, longitude: -120.40625, zipCode: "97712"},
	{latitude: 43.58683, longitude: -118.896086, zipCode: "97720"},
	{latitude: 42.526347, longitude: -118.446082, zipCode: "97721"},
	{latitude: 43.00734, longitude: -118.727222, zipCode: "97722"},
	{latitude: 44.522652, longitude: -121.645373, zipCode: "97730"},
	{latitude: 43.089622, longitude: -121.855238, zipCode: "97731"},
	{latitude: 43.485397, longitude: -118.323574, zipCode: "97732"},
	{latitude: 43.420396, longitude: -121.926184, zipCode: "97733"},
	{latitude: 44.53136, longitude: -121.335136, zipCode: "97734"},
	{latitude: 43.390117, longitude: -120.992389, zipCode: "97735"},
	{latitude: 42.674267, longitude: -118.901909, zipCode: "97736"},
	{latitude: 43.499532, longitude: -121.761312, zipCode: "97737"},
	{latitude: 43.484045, longitude: -119.156464, zipCode: "97738"},
	{latitude: 43.672452, longitude: -121.488187, zipCode: "97739"},
	{latitude: 44.658094, longitude: -121.038736, zipCode: "97741"},
	{latitude: 44.833779, longitude: -120.290466, zipCode: "97750"},
	{latitude: 44.131635, longitude: -119.780362, zipCode: "97751"},
	{latitude: 44.130545, longitude: -120.200874, zipCode: "97752"},
	{latitude: 44.197653, longitude: -120.985556, zipCode: "97753"},
	{latitude: 44.128712, longitude: -120.515361, zipCode: "97754"},
	{latitude: 44.281118, longitude: -121.191786, zipCode: "97756"},
	{latitude: 43.452315, longitude: -119.770927, zipCode: "97758"},
	{latitude: 44.48994, longitude: -121.778037, zipCode: "97759"},
	{latitude: 44.384504, longitude: -121.245576, zipCode: "97760"},
	{latitude: 44.860771, longitude: -121.294898, zipCode: "97761"},
	{latitude: 45.670827, longitude: -118.823832, zipCode: "97801"},
	{latitude: 45.672446, longitude: -118.396069, zipCode: "97810"},
	{latitude: 45.575157, longitude: -120.249832, zipCode: "97812"},
	{latitude: 45.853015, longitude: -118.527975, zipCode: "97813"},
	{latitude: 44.796293, longitude: -117.783263, zipCode: "97814"},
	{latitude: 44.728775, longitude: -118.60596, zipCode: "97817"},
	{latitude: 45.763786, longitude: -119.775772, zipCode: "97818"},
	{latitude: 44.485996, longitude: -117.763025, zipCode: "97819"},
	{latitude: 44.14382, longitude: -119.194763, zipCode: "97820"},
	{latitude: 45.250865, longitude: -120.196512, zipCode: "97823"},
	{latitude: 45.354301, longitude: -117.75647, zipCode: "97824"},
	{latitude: 44.385126, longitude: -119.496273, zipCode: "97825"},
	{latitude: 45.664296, longitude: -119.232316, zipCode: "97826"},
	{latitude: 45.615978, longitude: -117.857558, zipCode: "97827"},
	{latitude: 45.534674, longitude: -117.146773, zipCode: "97828"},
	{latitude: 44.966605, longitude: -120.183114, zipCode: "97830"},
	{latitude: 44.933827, longitude: -118.072967, zipCode: "97833"},
	{latitude: 45.029675, longitude: -117.231861, zipCode: "97834"},
	{latitude: 45.899223, longitude: -118.771941, zipCode: "97835"},
	{latitude: 45.321152, longitude: -119.47462, zipCode: "97836"},
	{latitude: 44.519548, longitude: -118.061902, zipCode: "97837"},
	{latitude: 45.850974, longitude: -119.287822, zipCode: "97838"},
	{latitude: 45.573379, longitude: -119.606853, zipCode: "97839"},
	{latitude: 44.856099, longitude: -116.941231, zipCode: "97840"},
	{latitude: 45.46824, longitude: -117.940325, zipCode: "97841"},
	{latitude: 45.610676, longitude: -116.799565, zipCode: "97842"},
	{latitude: 45.504563, longitude: -119.880612, zipCode: "97843"},
	{latitude: 45.88415, longitude: -119.548836, zipCode: "97844"},
	{latitude: 44.414444, longitude: -118.90375, zipCode: "97845"},
	{latitude: 45.27778, longitude: -117.114947, zipCode: "97846"},
	{latitude: 44.722177, longitude: -119.580554, zipCode: "97848"},
	{latitude: 45.308936, longitude: -118.156042, zipCode: "97850"},
	{latitude: 44.776928, longitude: -119.117972, zipCode: "97856"},
	{latitude: 45.359229, longitude: -117.52324, zipCode: "97857"},
	{latitude: 45.524732, longitude: -118.431925, zipCode: "97859"},
	{latitude: 45.922063, longitude: -118.316063, zipCode: "97862"},
	{latitude: 44.805075, longitude: -119.471458, zipCode: "97864"},
	{latitude: 44.461466, longitude: -119.18004, zipCode: "97865"},
	{latitude: 45.078037, longitude: -117.993416, zipCode: "97867"},
	{latitude: 45.409294, longitude: -118.853582, zipCode: "97868"},
	{latitude: 44.397563, longitude: -118.633979, zipCode: "97869"},
	{latitude: 44.787989, longitude: -117.186563, zipCode: "97870"},
	{latitude: 44.1283, longitude: -118.995, zipCode: "97873"},
	{latitude: 44.757804, longitude: -119.848466, zipCode: "97874"},
	{latitude: 45.815394, longitude: -119.148634, zipCode: "97875"},
	{latitude: 45.522336, longitude: -118.028055, zipCode: "97876"},
	{latitude: 44.810954, longitude: -118.395946, zipCode: "97877"},
	{latitude: 45.088669, longitude: -118.882506, zipCode: "97880"},
	{latitude: 45.907338, longitude: -119.349551, zipCode: "97882"},
	{latitude: 45.128645, longitude: -117.651842, zipCode: "97883"},
	{latitude: 44.455341, longitude: -118.222235, zipCode: "97884"},
	{latitude: 45.650491, longitude: -117.509759, zipCode: "97885"},
	{latitude: 45.802308, longitude: -118.27241, zipCode: "97886"},
	{latitude: 43.589056, longitude: -117.098305, zipCode: "97901"},
	{latitude: 42.913311, longitude: -117.49413, zipCode: "97902"},
	{latitude: 44.271554, longitude: -117.598441, zipCode: "97903"},
	{latitude: 43.896317, longitude: -118.488634, zipCode: "97904"},
	{latitude: 44.581872, longitude: -117.465428, zipCode: "97905"},
	{latitude: 43.46499, longitude: -117.811858, zipCode: "97906"},
	{latitude: 44.419501, longitude: -117.345514, zipCode: "97907"},
	{latitude: 44.240035, longitude: -117.931435, zipCode: "97908"},
	{latitude: 44.210938, longitude: -117.379018, zipCode: "97909"},
	{latitude: 42.854644, longitude: -117.50912, zipCode: "97910"},
	{latitude: 43.876363, longitude: -118.055064, zipCode: "97911"},
	{latitude: 43.634378, longitude: -117.277911, zipCode: "97913"},
	{latitude: 44.111552, longitude: -117.079858, zipCode: "97914"},
	{latitude: 43.549479, longitude: -118.123502, zipCode: "97917"},
	{latitude: 43.962589, longitude: -117.389698, zipCode: "97918"},
	{latitude: 44.066001, longitude: -117.750402, zipCode: "97920"},
	{latitude: 47.30968, longitude: -122.264368, zipCode: "98001"},
	{latitude: 47.30834, longitude: -122.215422, zipCode: "98002"},
	{latitude: 47.305205, longitude: -122.314161, zipCode: "98003"},
	{latitude: 47.618337, longitude: -122.205341, zipCode: "98004"},
	{latitude: 47.612771, longitude: -122.167621, zipCode: "98005"},
	{latitude: 47.553023, longitude: -122.151845, zipCode: "98006"},
	{latitude: 47.613825, longitude: -122.144365, zipCode: "98007"},
	{latitude: 47.606529, longitude: -122.111468, zipCode: "98008"},
	{latitude: 47.315643, longitude: -121.998898, zipCode: "98010"},
	{latitude: 47.757352, longitude: -122.198698, zipCode: "98011"},
	{latitude: 47.841376, longitude: -122.198939, zipCode: "98012"},
	{latitude: 47.699745, longitude: -121.845156, zipCode: "98014"},
	{latitude: 47.737043, longitude: -121.934247, zipCode: "98019"},
	{latitude: 47.80059, longitude: -122.374193, zipCode: "98020"},
	{latitude: 47.792571, longitude: -122.208431, zipCode: "98021"},
	{latitude: 47.169491, longitude: -121.684569, zipCode: "98022"},
	{latitude: 47.311491, longitude: -122.36281, zipCode: "98023"},
	{latitude: 47.573424, longitude: -121.899182, zipCode: "98024"},
	{latitude: 47.434394, longitude: -121.952354, zipCode: "98025"},
	{latitude: 47.837569, longitude: -122.333895, zipCode: "98026"},
	{latitude: 47.514016, longitude: -121.993456, zipCode: "98027"},
	{latitude: 47.749858, longitude: -122.247244, zipCode: "98028"},
	{latitude: 47.557623, longitude: -122.01152, zipCode: "98029"},
	{latitude: 47.367214, longitude: -122.198051, zipCode: "98030"},
	{latitude: 47.405902, longitude: -122.191923, zipCode: "98031"},
	{latitude: 47.391942, longitude: -122.257192, zipCode: "98032"},
	{latitude: 47.675982, longitude: -122.193578, zipCode: "98033"},
	{latitude: 47.715809, longitude: -122.213748, zipCode: "98034"},
	{latitude: 47.809708, longitude: -122.280394, zipCode: "98036"},
	{latitude: 47.838719, longitude: -122.284543, zipCode: "98037"},
	{latitude: 47.419378, longitude: -121.958008, zipCode: "98038"},
	{latitude: 47.634866, longitude: -122.240269, zipCode: "98039"},
	{latitude: 47.564004, longitude: -122.231214, zipCode: "98040"},
	{latitude: 47.364009, longitude: -122.116743, zipCode: "98042"},
	{latitude: 47.794329, longitude: -122.302607, zipCode: "98043"},
	{latitude: 47.476623, longitude: -121.682885, zipCode: "98045"},
	{latitude: 47.259534, longitude: -122.247477, zipCode: "98047"},
	{latitude: 47.547343, longitude: -121.939703, zipCode: "98050"},
	{latitude: 47.34621, longitude: -121.881291, zipCode: "98051"},
	{latitude: 47.682254, longitude: -122.12045, zipCode: "98052"},
	{latitude: 47.668736, longitude: -122.015096, zipCode: "98053"},
	{latitude: 47.447472, longitude: -122.200356, zipCode: "98055"},
	{latitude: 47.51256, longitude: -122.188902, zipCode: "98056"},
	{latitude: 47.471944, longitude: -122.219389, zipCode: "98057"},
	{latitude: 47.440607, longitude: -122.126349, zipCode: "98058"},
	{latitude: 47.494409, longitude: -122.130221, zipCode: "98059"},
	{latitude: 47.597225, longitude: -121.779633, zipCode: "98065"},
	{latitude: 47.431901, longitude: -121.374119, zipCode: "98068"},
	{latitude: 47.407347, longitude: -122.473485, zipCode: "98070"},
	{latitude: 47.760777, longitude: -122.130501, zipCode: "98072"},
	{latitude: 47.622544, longitude: -122.04421, zipCode: "98074"},
	{latitude: 47.58609, longitude: -122.037436, zipCode: "98075"},
	{latitude: 47.752379, longitude: -122.060283, zipCode: "98077"},
	{latitude: 47.860067, longitude: -122.267267, zipCode: "98087"},
	{latitude: 47.290224, longitude: -122.125648, zipCode: "98092"},
	{latitude: 47.610902, longitude: -122.336422, zipCode: "98101"},
	{latitude: 47.634441, longitude: -122.32369, zipCode: "98102"},
	{latitude: 47.673874, longitude: -122.33471, zipCode: "98103"},
	{latitude: 47.600458, longitude: -122.332226, zipCode: "98104"},
	{latitude: 47.659861, longitude: -122.284977, zipCode: "98105"},
	{latitude: 47.544034, longitude: -122.349874, zipCode: "98106"},
	{latitude: 47.667527, longitude: -122.377551, zipCode: "98107"},
	{latitude: 47.535065, longitude: -122.305931, zipCode: "98108"},
	{latitude: 47.630583, longitude: -122.345596, zipCode: "98109"},
	{latitude: 47.646967, longitude: -122.534561, zipCode: "98110"},
	{latitude: 47.63752, longitude: -122.287651, zipCode: "98112"},
	{latitude: 47.685753, longitude: -122.283703, zipCode: "98115"},
	{latitude: 47.576382, longitude: -122.397387, zipCode: "98116"},
	{latitude: 47.687754, longitude: -122.385942, zipCode: "98117"},
	{latitude: 47.542036, longitude: -122.268537, zipCode: "98118"},
	{latitude: 47.636846, longitude: -122.370011, zipCode: "98119"},
	{latitude: 47.615192, longitude: -122.350581, zipCode: "98121"},
	{latitude: 47.609503, longitude: -122.295593, zipCode: "98122"},
	{latitude: 47.716883, longitude: -122.300564, zipCode: "98125"},
	{latitude: 47.551914, longitude: -122.373669, zipCode: "98126"},
	{latitude: 47.740485, longitude: -122.342826, zipCode: "98133"},
	{latitude: 47.581061, longitude: -122.338152, zipCode: "98134"},
	{latitude: 47.536602, longitude: -122.392466, zipCode: "98136"},
	{latitude: 47.585627, longitude: -122.29196, zipCode: "98144"},
	{latitude: 47.500147, longitude: -122.361235, zipCode: "98146"},
	{latitude: 47.444948, longitude: -122.325833, zipCode: "98148"},
	{latitude: 47.606211, longitude: -122.333792, zipCode: "98154"},
	{latitude: 47.754413, longitude: -122.300477, zipCode: "98155"},
	{latitude: 47.450455, longitude: -122.308014, zipCode: "98158"},
	{latitude: 47.605962, longitude: -122.332029, zipCode: "98164"},
	{latitude: 47.451576, longitude: -122.352986, zipCode: "98166"},
	{latitude: 47.489569, longitude: -122.29996, zipCode: "98168"},
	{latitude: 47.604569, longitude: -122.335359, zipCode: "98174"},
	{latitude: 47.742088, longitude: -122.371177, zipCode: "98177"},
	{latitude: 47.498764, longitude: -122.245065, zipCode: "98178"},
	{latitude: 47.446563, longitude: -122.272106, zipCode: "98188"},
	{latitude: 47.653833, longitude: -122.308348, zipCode: "98195"},
	{latitude: 47.391319, longitude: -122.314971, zipCode: "98198"},
	{latitude: 47.651367, longitude: -122.406278, zipCode: "98199"},
	{latitude: 47.994464, longitude: -122.186162, zipCode: "98201"},
	{latitude: 47.941999, longitude: -122.23408, zipCode: "98203"},
	{latitude: 47.902665, longitude: -122.255861, zipCode: "98204"},
	{latitude: 47.987223, longitude: -122.224193, zipCode: "98207"},
	{latitude: 47.900198, longitude: -122.187044, zipCode: "98208"},
	{latitude: 48.684945, longitude: -122.195194, zipCode: "98220"},
	{latitude: 48.454172, longitude: -122.603874, zipCode: "98221"},
	{latitude: 48.570667, longitude: -122.817466, zipCode: "98222"},
	{latitude: 48.21712, longitude: -121.95354, zipCode: "98223"},
	{latitude: 47.728877, longitude: -121.563797, zipCode: "98224"},
	{latitude: 48.758248, longitude: -122.50652, zipCode: "98225"},
	{latitude: 48.796704, longitude: -122.432388, zipCode: "98226"},
	{latitude: 48.705945, longitude: -122.398326, zipCode: "98229"},
	{latitude: 48.953455, longitude: -122.705013, zipCode: "98230"},
	{latitude: 48.564396, longitude: -122.409902, zipCode: "98232"},
	{latitude: 48.500911, longitude: -122.345655, zipCode: "98233"},
	{latitude: 48.463237, longitude: -122.231223, zipCode: "98235"},
	{latitude: 47.948393, longitude: -122.412822, zipCode: "98236"},
	{latitude: 48.533808, longitude: -121.710652, zipCode: "98237"},
	{latitude: 48.335597, longitude: -122.34451, zipCode: "98238"},
	{latitude: 48.198192, longitude: -122.654078, zipCode: "98239"},
	{latitude: 48.948225, longitude: -122.620684, zipCode: "98240"},
	{latitude: 48.204009, longitude: -121.140498, zipCode: "98241"},
	{latitude: 48.613869, longitude: -123.006955, zipCode: "98243"},
	{latitude: 48.77253, longitude: -121.909628, zipCode: "98244"},
	{latitude: 48.66915, longitude: -122.912045, zipCode: "98245"},
	{latitude: 48.897231, longitude: -122.313728, zipCode: "98247"},
	{latitude: 48.865625, longitude: -122.623172, zipCode: "98248"},
	{latitude: 48.014236, longitude: -122.542794, zipCode: "98249"},
	{latitude: 48.553849, longitude: -123.085857, zipCode: "98250"},
	{latitude: 47.911547, longitude: -121.606422, zipCode: "98251"},
	{latitude: 48.061797, longitude: -121.714468, zipCode: "98252"},
	{latitude: 48.083955, longitude: -122.572471, zipCode: "98253"},
	{latitude: 48.523516, longitude: -121.991998, zipCode: "98255"},
	{latitude: 47.821525, longitude: -121.406544, zipCode: "98256"},
	{latitude: 48.406025, longitude: -122.512147, zipCode: "98257"},
	{latitude: 48.034269, longitude: -122.081489, zipCode: "98258"},
	{latitude: 48.035925, longitude: -122.45263, zipCode: "98260"},
	{latitude: 48.482491, longitude: -122.88396, zipCode: "98261"},
	{latitude: 48.696599, longitude: -122.681092, zipCode: "98262"},
	{latitude: 48.523789, longitude: -122.065597, zipCode: "98263"},
	{latitude: 48.94992, longitude: -122.454803, zipCode: "98264"},
	{latitude: 48.974043, longitude: -122.03499, zipCode: "98266"},
	{latitude: 48.390147, longitude: -121.265831, zipCode: "98267"},
	{latitude: 48.056723, longitude: -122.146904, zipCode: "98270"},
	{latitude: 48.089968, longitude: -122.237681, zipCode: "98271"},
	{latitude: 47.857847, longitude: -121.885309, zipCode: "98272"},
	{latitude: 48.402754, longitude: -122.376148, zipCode: "98273"},
	{latitude: 48.348771, longitude: -122.145467, zipCode: "98274"},
	{latitude: 47.913018, longitude: -122.302253, zipCode: "98275"},
	{latitude: 48.92775, longitude: -122.321038, zipCode: "98276"},
	{latitude: 48.321988, longitude: -122.627027, zipCode: "98277"},
	{latitude: 48.340604, longitude: -122.673992, zipCode: "98278"},
	{latitude: 48.636443, longitude: -122.818196, zipCode: "98279"},
	{latitude: 48.596133, longitude: -122.877668, zipCode: "98280"},
	{latitude: 48.984669, longitude: -123.059496, zipCode: "98281"},
	{latitude: 48.212509, longitude: -122.499121, zipCode: "98282"},
	{latitude: 48.800551, longitude: -121.30355, zipCode: "98283"},
	{latitude: 48.546832, longitude: -122.135222, zipCode: "98284"},
	{latitude: 48.568814, longitude: -122.957539, zipCode: "98286"},
	{latitude: 47.621403, longitude: -121.326488, zipCode: "98288"},
	{latitude: 47.955367, longitude: -121.978502, zipCode: "98290"},
	{latitude: 48.22555, longitude: -122.30507, zipCode: "98292"},
	{latitude: 47.838377, longitude: -121.766185, zipCode: "98294"},
	{latitude: 48.983371, longitude: -122.195069, zipCode: "98295"},
	{latitude: 47.840783, longitude: -122.101084, zipCode: "98296"},
	{latitude: 48.702059, longitude: -123.026515, zipCode: "98297"},
	{latitude: 47.149874, longitude: -122.681692, zipCode: "98303"},
	{latitude: 46.884601, longitude: -121.736645, zipCode: "98304"},
	{latitude: 48.024446, longitude: -124.23633, zipCode: "98305"},
	{latitude: 47.589689, longitude: -122.617462, zipCode: "98310"},
	{latitude: 47.627363, longitude: -122.642445, zipCode: "98311"},
	{latitude: 47.565804, longitude: -122.76154, zipCode: "98312"},
	{latitude: 47.562471, longitude: -122.638719, zipCode: "98314"},
	{latitude: 47.72455, longitude: -122.716011, zipCode: "98315"},
	{latitude: 47.696342, longitude: -123.055901, zipCode: "98320"},
	{latitude: 47.152606, longitude: -122.071738, zipCode: "98321"},
	{latitude: 46.97986, longitude: -121.985335, zipCode: "98323"},
	{latitude: 47.966814, longitude: -122.75969, zipCode: "98325"},
	{latitude: 48.159044, longitude: -124.500534, zipCode: "98326"},
	{latitude: 47.106258, longitude: -122.661608, zipCode: "98327"},
	{latitude: 46.858049, longitude: -122.164147, zipCode: "98328"},
	{latitude: 47.360808, longitude: -122.744139, zipCode: "98329"},
	{latitude: 46.757092, longitude: -122.153221, zipCode: "98330"},
	{latitude: 47.745828, longitude: -124.180639, zipCode: "98331"},
	{latitude: 47.372234, longitude: -122.595793, zipCode: "98332"},
	{latitude: 47.257686, longitude: -122.646552, zipCode: "98333"},
	{latitude: 47.296947, longitude: -122.623661, zipCode: "98335"},
	{latitude: 46.597949, longitude: -122.077799, zipCode: "98336"},
	{latitude: 47.561042, longitude: -122.638765, zipCode: "98337"},
	{latitude: 47.034315, longitude: -122.276854, zipCode: "98338"},
	{latitude: 48.02766, longitude: -122.746448, zipCode: "98339"},
	{latitude: 47.910095, longitude: -122.57106, zipCode: "98340"},
	{latitude: 47.755273, longitude: -122.511484, zipCode: "98342"},
	{latitude: 47.700204, longitude: -122.624293, zipCode: "98345"},
	{latitude: 47.826022, longitude: -122.527151, zipCode: "98346"},
	{latitude: 47.270073, longitude: -122.758454, zipCode: "98349"},
	{latitude: 47.906498, longitude: -124.622368, zipCode: "98350"},
	{latitude: 47.19966, longitude: -122.774258, zipCode: "98351"},
	{latitude: 47.53864, longitude: -122.492613, zipCode: "98353"},
	{latitude: 47.251994, longitude: -122.317289, zipCode: "98354"},
	{latitude: 46.703839, longitude: -122.039922, zipCode: "98355"},
	{latitude: 46.581909, longitude: -122.271384, zipCode: "98356"},
	{latitude: 48.333855, longitude: -124.635404, zipCode: "98357"},
	{latitude: 48.044909, longitude: -122.687459, zipCode: "98358"},
	{latitude: 47.431079, longitude: -122.574916, zipCode: "98359"},
	{latitude: 47.051413, longitude: -122.15324, zipCode: "98360"},
	{latitude: 46.53842, longitude: -121.504864, zipCode: "98361"},
	{latitude: 48.030261, longitude: -123.329376, zipCode: "98362"},
	{latitude: 48.003513, longitude: -123.822854, zipCode: "98363"},
	{latitude: 47.854353, longitude: -122.586113, zipCode: "98364"},
	{latitude: 47.884858, longitude: -122.701425, zipCode: "98365"},
	{latitude: 47.541253, longitude: -122.577029, zipCode: "98366"},
	{latitude: 47.462142, longitude: -122.666209, zipCode: "98367"},
	{latitude: 48.041261, longitude: -122.826664, zipCode: "98368"},
	{latitude: 47.754025, longitude: -122.626294, zipCode: "98370"},
	{latitude: 47.199455, longitude: -122.320212, zipCode: "98371"},
	{latitude: 47.206693, longitude: -122.262642, zipCode: "98372"},
	{latitude: 47.148479, longitude: -122.324136, zipCode: "98373"},
	{latitude: 47.129077, longitude: -122.263923, zipCode: "98374"},
	{latitude: 47.104262, longitude: -122.326892, zipCode: "98375"},
	{latitude: 47.846837, longitude: -122.852262, zipCode: "98376"},
	{latitude: 46.508777, longitude: -121.819187, zipCode: "98377"},
	{latitude: 47.601806, longitude: -122.879419, zipCode: "98380"},
	{latitude: 48.288607, longitude: -124.45685, zipCode: "98381"},
	{latitude: 48.023295, longitude: -123.106268, zipCode: "98382"},
	{latitude: 47.66291, longitude: -122.718605, zipCode: "98383"},
	{latitude: 47.137939, longitude: -122.095041, zipCode: "98385"},
	{latitude: 47.060925, longitude: -122.388909, zipCode: "98387"},
	{latitude: 47.209498, longitude: -122.684297, zipCode: "98388"},
	{latitude: 47.211481, longitude: -122.230571, zipCode: "98390"},
	{latitude: 47.177103, longitude: -122.166173, zipCode: "98391"},
	{latitude: 47.73238, longitude: -122.565945, zipCode: "98392"},
	{latitude: 47.320469, longitude: -122.78968, zipCode: "98394"},
	{latitude: 47.109838, longitude: -122.000292, zipCode: "98396"},
	{latitude: 47.25383, longitude: -122.444295, zipCode: "98402"},
	{latitude: 47.2658, longitude: -122.459139, zipCode: "98403"},
	{latitude: 47.208981, longitude: -122.411638, zipCode: "98404"},
	{latitude: 47.245647, longitude: -122.472399, zipCode: "98405"},
	{latitude: 47.264107, longitude: -122.512591, zipCode: "98406"},
	{latitude: 47.291951, longitude: -122.517727, zipCode: "98407"},
	{latitude: 47.197399, longitude: -122.446518, zipCode: "98408"},
	{latitude: 47.21303, longitude: -122.474947, zipCode: "98409"},
	{latitude: 47.262706, longitude: -122.48196, zipCode: "98416"},
	{latitude: 47.223229, longitude: -122.446405, zipCode: "98418"},
	{latitude: 47.260637, longitude: -122.400209, zipCode: "98421"},
	{latitude: 47.290368, longitude: -122.392395, zipCode: "98422"},
	{latitude: 47.235351, longitude: -122.354196, zipCode: "98424"},
	{latitude: 47.116718, longitude: -122.568752, zipCode: "98430"},
	{latitude: 47.092304, longitude: -122.608054, zipCode: "98433"},
	{latitude: 47.135003, longitude: -122.492082, zipCode: "98438"},
	{latitude: 47.130862, longitude: -122.502192, zipCode: "98439"},
	{latitude: 47.205005, longitude: -122.372661, zipCode: "98443"},
	{latitude: 47.152323, longitude: -122.448851, zipCode: "98444"},
	{latitude: 47.137427, longitude: -122.404715, zipCode: "98445"},
	{latitude: 47.131879, longitude: -122.375825, zipCode: "98446"},
	{latitude: 47.145262, longitude: -122.443631, zipCode: "98447"},
	{latitude: 47.251298, longitude: -122.537437, zipCode: "98465"},
	{latitude: 47.228971, longitude: -122.538054, zipCode: "98466"},
	{latitude: 47.203279, longitude: -122.567719, zipCode: "98467"},
	{latitude: 47.159592, longitude: -122.557929, zipCode: "98498"},
	{latitude: 47.16625, longitude: -122.506083, zipCode: "98499"},
	{latitude: 46.978514, longitude: -122.875905, zipCode: "98501"},
	{latitude: 47.0812, longitude: -123.024841, zipCode: "98502"},
	{latitude: 47.02332, longitude: -122.793549, zipCode: "98503"},
	{latitude: 47.073195, longitude: -122.973645, zipCode: "98505"},
	{latitude: 47.108292, longitude: -122.870623, zipCode: "98506"},
	{latitude: 46.953394, longitude: -123.048034, zipCode: "98512"},
	{latitude: 46.99337, longitude: -122.733221, zipCode: "98513"},
	{latitude: 47.098919, longitude: -122.785426, zipCode: "98516"},
	{latitude: 46.988577, longitude: -123.828523, zipCode: "98520"},
	{latitude: 47.366613, longitude: -122.835817, zipCode: "98524"},
	{latitude: 47.474411, longitude: -123.993988, zipCode: "98526"},
	{latitude: 46.596413, longitude: -123.911888, zipCode: "98527"},
	{latitude: 47.447252, longitude: -122.896217, zipCode: "98528"},
	{latitude: 46.809954, longitude: -122.87544, zipCode: "98530"},
	{latitude: 46.742709, longitude: -122.901618, zipCode: "98531"},
	{latitude: 46.626308, longitude: -123.052671, zipCode: "98532"},
	{latitude: 46.66116, longitude: -122.395587, zipCode: "98533"},
	{latitude: 47.103805, longitude: -124.117485, zipCode: "98535"},
	{latitude: 47.19494, longitude: -124.104095, zipCode: "98536"},
	{latitude: 46.807051, longitude: -123.580894, zipCode: "98537"},
	{latitude: 46.482768, longitude: -123.146135, zipCode: "98538"},
	{latitude: 46.637769, longitude: -123.273227, zipCode: "98539"},
	{latitude: 47.06085, longitude: -123.389802, zipCode: "98541"},
	{latitude: 46.533037, longitude: -122.725418, zipCode: "98542"},
	{latitude: 46.738971, longitude: -123.026988, zipCode: "98544"},
	{latitude: 47.321272, longitude: -122.914006, zipCode: "98546"},
	{latitude: 46.783992, longitude: -124.037789, zipCode: "98547"},
	{latitude: 47.540911, longitude: -123.173864, zipCode: "98548"},
	{latitude: 47.112596, longitude: -123.923008, zipCode: "98550"},
	{latitude: 47.31622, longitude: -123.946557, zipCode: "98552"},
	{latitude: 47.5334, longitude: -123.063352, zipCode: "98555"},
	{latitude: 46.885644, longitude: -123.140966, zipCode: "98556"},
	{latitude: 47.043242, longitude: -123.269956, zipCode: "98557"},
	{latitude: 46.936152, longitude: -122.560492, zipCode: "98558"},
	{latitude: 46.958822, longitude: -123.327364, zipCode: "98559"},
	{latitude: 47.309541, longitude: -123.422799, zipCode: "98560"},
	{latitude: 47.237991, longitude: -124.199826, zipCode: "98562"},
	{latitude: 47.211055, longitude: -123.609674, zipCode: "98563"},
	{latitude: 46.449477, longitude: -122.41965, zipCode: "98564"},
	{latitude: 46.578391, longitude: -122.907094, zipCode: "98565"},
	{latitude: 47.406546, longitude: -123.969588, zipCode: "98566"},
	{latitude: 46.862611, longitude: -123.301202, zipCode: "98568"},
	{latitude: 46.990692, longitude: -124.143351, zipCode: "98569"},
	{latitude: 46.630713, longitude: -122.650947, zipCode: "98570"},
	{latitude: 47.202082, longitude: -124.198329, zipCode: "98571"},
	{latitude: 46.541337, longitude: -123.328312, zipCode: "98572"},
	{latitude: 47.454482, longitude: -123.801164, zipCode: "98575"},
	{latitude: 46.846725, longitude: -122.648425, zipCode: "98576"},
	{latitude: 46.641849, longitude: -123.610389, zipCode: "98577"},
	{latitude: 46.788204, longitude: -123.12547, zipCode: "98579"},
	{latitude: 46.986627, longitude: -122.510337, zipCode: "98580"},
	{latitude: 46.358779, longitude: -123.068659, zipCode: "98581"},
	{latitude: 46.523681, longitude: -122.625742, zipCode: "98582"},
	{latitude: 47.000882, longitude: -123.486663, zipCode: "98583"},
	{latitude: 47.247915, longitude: -123.153261, zipCode: "98584"},
	{latitude: 46.573437, longitude: -122.444631, zipCode: "98585"},
	{latitude: 46.544556, longitude: -123.840333, zipCode: "98586"},
	{latitude: 47.322856, longitude: -124.15296, zipCode: "98587"},
	{latitude: 47.446808, longitude: -123.032753, zipCode: "98588"},
	{latitude: 46.833445, longitude: -122.795317, zipCode: "98589"},
	{latitude: 46.710981, longitude: -123.996186, zipCode: "98590"},
	{latitude: 46.442319, longitude: -122.747383, zipCode: "98591"},
	{latitude: 47.33395, longitude: -123.076235, zipCode: "98592"},
	{latitude: 46.402675, longitude: -123.010116, zipCode: "98593"},
	{latitude: 46.897192, longitude: -124.107841, zipCode: "98595"},
	{latitude: 46.492252, longitude: -122.952658, zipCode: "98596"},
	{latitude: 46.844262, longitude: -122.492927, zipCode: "98597"},
	{latitude: 45.938373, longitude: -122.437855, zipCode: "98601"},
	{latitude: 45.869291, longitude: -121.279683, zipCode: "98602"},
	{latitude: 46.055519, longitude: -122.414757, zipCode: "98603"},
	{latitude: 45.805756, longitude: -122.510771, zipCode: "98604"},
	{latitude: 45.777018, longitude: -121.664181, zipCode: "98605"},
	{latitude: 45.729197, longitude: -122.45605, zipCode: "98606"},
	{latitude: 45.642413, longitude: -122.379611, zipCode: "98607"},
	{latitude: 46.071811, longitude: -122.865709, zipCode: "98609"},
	{latitude: 45.77542, longitude: -122.019081, zipCode: "98610"},
	{latitude: 46.305145, longitude: -122.900687, zipCode: "98611"},
	{latitude: 46.250472, longitude: -123.287184, zipCode: "98612"},
	{latitude: 45.754201, longitude: -120.972338, zipCode: "98613"},
	{latitude: 46.283303, longitude: -123.919747, zipCode: "98614"},
	{latitude: 46.075128, longitude: -122.183189, zipCode: "98616"},
	{latitude: 45.640493, longitude: -121.134015, zipCode: "98617"},
	{latitude: 45.971638, longitude: -121.268098, zipCode: "98619"},
	{latitude: 45.870446, longitude: -120.779305, zipCode: "98620"},
	{latitude: 46.400335, longitude: -123.543675, zipCode: "98621"},
	{latitude: 45.799594, longitude: -121.493459, zipCode: "98623"},
	{latitude: 46.33813, longitude: -123.964974, zipCode: "98624"},
	{latitude: 46.111708, longitude: -122.569586, zipCode: "98625"},
	{latitude: 46.17216, longitude: -122.773533, zipCode: "98626"},
	{latitude: 45.848608, longitude: -121.066798, zipCode: "98628"},
	{latitude: 45.877319, longitude: -122.619409, zipCode: "98629"},
	{latitude: 46.388005, longitude: -124.021855, zipCode: "98631"},
	{latitude: 46.204917, longitude: -123.062646, zipCode: "98632"},
	{latitude: 45.750031, longitude: -121.185577, zipCode: "98635"},
	{latitude: 46.393932, longitude: -123.762275, zipCode: "98638"},
	{latitude: 45.640468, longitude: -121.966293, zipCode: "98639"},
	{latitude: 46.631904, longitude: -124.042597, zipCode: "98640"},
	{latitude: 46.570991, longitude: -124.041625, zipCode: "98641"},
	{latitude: 45.803287, longitude: -122.701656, zipCode: "98642"},
	{latitude: 46.291997, longitude: -123.653638, zipCode: "98643"},
	{latitude: 46.319301, longitude: -124.05319, zipCode: "98644"},
	{latitude: 46.331865, longitude: -122.760081, zipCode: "98645"},
	{latitude: 46.315241, longitude: -123.401371, zipCode: "98647"},
	{latitude: 45.705945, longitude: -121.945583, zipCode: "98648"},
	{latitude: 46.315533, longitude: -122.532291, zipCode: "98649"},
	{latitude: 45.946837, longitude: -121.532395, zipCode: "98650"},
	{latitude: 45.739967, longitude: -121.583528, zipCode: "98651"},
	{latitude: 45.661204, longitude: -122.718844, zipCode: "98660"},
	{latitude: 45.640067, longitude: -122.625018, zipCode: "98661"},
	{latitude: 45.689134, longitude: -122.577391, zipCode: "98662"},
	{latitude: 45.654667, longitude: -122.662337, zipCode: "98663"},
	{latitude: 45.61951, longitude: -122.577179, zipCode: "98664"},
	{latitude: 45.681881, longitude: -122.652658, zipCode: "98665"},
	{latitude: 45.863751, longitude: -121.14605, zipCode: "98670"},
	{latitude: 45.610395, longitude: -122.275481, zipCode: "98671"},
	{latitude: 45.819522, longitude: -121.472434, zipCode: "98672"},
	{latitude: 45.680823, longitude: -120.95157, zipCode: "98673"},
	{latitude: 45.951267, longitude: -122.665541, zipCode: "98674"},
	{latitude: 45.873992, longitude: -122.321688, zipCode: "98675"},
	{latitude: 45.67296, longitude: -122.483238, zipCode: "98682"},
	{latitude: 45.603287, longitude: -122.51017, zipCode: "98683"},
	{latitude: 45.630556, longitude: -122.514839, zipCode: "98684"},
	{latitude: 45.715209, longitude: -122.693161, zipCode: "98685"},
	{latitude: 45.723305, longitude: -122.62187, zipCode: "98686"},
	{latitude: 47.431512, longitude: -120.345038, zipCode: "98801"},
	{latitude: 47.491932, longitude: -120.16375, zipCode: "98802"},
	{latitude: 47.734239, longitude: -120.373833, zipCode: "98811"},
	{latitude: 48.131489, longitude: -119.806042, zipCode: "98812"},
	{latitude: 48.006302, longitude: -119.616949, zipCode: "98813"},
	{latitude: 48.195344, longitude: -120.294989, zipCode: "98814"},
	{latitude: 47.528586, longitude: -120.479708, zipCode: "98815"},
	{latitude: 48.0554, longitude: -120.331968, zipCode: "98816"},
	{latitude: 47.792389, longitude: -119.993594, zipCode: "98817"},
	{latitude: 48.584492, longitude: -119.825853, zipCode: "98819"},
	{latitude: 47.552197, longitude: -120.563703, zipCode: "98821"},
	{latitude: 47.97905, longitude: -120.623327, zipCode: "98822"},
	{latitude: 47.338298, longitude: -119.635157, zipCode: "98823"},
	{latitude: 47.068516, longitude: -119.866543, zipCode: "98824"},
	{latitude: 47.783145, longitude: -120.843168, zipCode: "98826"},
	{latitude: 48.856329, longitude: -119.752106, zipCode: "98827"},
	{latitude: 47.310078, longitude: -120.178259, zipCode: "98828"},
	{latitude: 48.297285, longitude: -119.805681, zipCode: "98829"},
	{latitude: 47.880724, longitude: -119.533889, zipCode: "98830"},
	{latitude: 47.936759, longitude: -120.136253, zipCode: "98831"},
	{latitude: 47.331263, longitude: -119.027962, zipCode: "98832"},
	{latitude: 48.768736, longitude: -120.507298, zipCode: "98833"},
	{latitude: 48.120335, longitude: -120.066403, zipCode: "98834"},
	{latitude: 47.471923, longitude: -120.438475, zipCode: "98836"},
	{latitude: 47.146993, longitude: -119.315908, zipCode: "98837"},
	{latitude: 48.29181, longitude: -119.513783, zipCode: "98840"},
	{latitude: 48.379699, longitude: -119.289234, zipCode: "98841"},
	{latitude: 47.740379, longitude: -120.087144, zipCode: "98843"},
	{latitude: 48.927274, longitude: -119.228032, zipCode: "98844"},
	{latitude: 47.439304, longitude: -119.935355, zipCode: "98845"},
	{latitude: 48.014632, longitude: -119.971215, zipCode: "98846"},
	{latitude: 47.424424, longitude: -120.618215, zipCode: "98847"},
	{latitude: 47.168643, longitude: -119.842939, zipCode: "98848"},
	{latitude: 48.54099, longitude: -119.409523, zipCode: "98849"},
	{latitude: 47.405284, longitude: -120.038663, zipCode: "98850"},
	{latitude: 47.413886, longitude: -119.43223, zipCode: "98851"},
	{latitude: 48.365489, longitude: -120.669753, zipCode: "98852"},
	{latitude: 47.466322, longitude: -119.282052, zipCode: "98853"},
	{latitude: 48.674509, longitude: -119.253008, zipCode: "98855"},
	{latitude: 48.343268, longitude: -120.263948, zipCode: "98856"},
	{latitude: 46.990068, longitude: -119.024761, zipCode: "98857"},
	{latitude: 47.678077, longitude: -119.888544, zipCode: "98858"},
	{latitude: 48.797856, longitude: -118.955073, zipCode: "98859"},
	{latitude: 47.464012, longitude: -119.115018, zipCode: "98860"},
	{latitude: 48.598872, longitude: -120.22241, zipCode: "98862"},
	{latitude: 46.6652, longitude: -120.338543, zipCode: "98901"},
	{latitude: 46.598048, longitude: -120.534558, zipCode: "98902"},
	{latitude: 46.530038, longitude: -120.940959, zipCode: "98903"},
	{latitude: 46.640252, longitude: -120.781887, zipCode: "98908"},
	{latitude: 46.430723, longitude: -120.316813, zipCode: "98921"},
	{latitude: 47.226222, longitude: -120.865422, zipCode: "98922"},
	{latitude: 46.666086, longitude: -120.717225, zipCode: "98923"},
	{latitude: 47.282645, longitude: -121.277777, zipCode: "98925"},
	{latitude: 46.988457, longitude: -120.354859, zipCode: "98926"},
	{latitude: 46.269222, longitude: -119.892008, zipCode: "98930"},
	{latitude: 46.332713, longitude: -120.156231, zipCode: "98932"},
	{latitude: 46.402929, longitude: -120.637565, zipCode: "98933"},
	{latitude: 46.980641, longitude: -120.419425, zipCode: "98934"},
	{latitude: 46.131947, longitude: -120.073684, zipCode: "98935"},
	{latitude: 46.532769, longitude: -120.164849, zipCode: "98936"},
	{latitude: 46.792772, longitude: -121.226151, zipCode: "98937"},
	{latitude: 46.388363, longitude: -120.096655, zipCode: "98938"},
	{latitude: 46.501013, longitude: -120.470706, zipCode: "98939"},
	{latitude: 47.362385, longitude: -121.039385, zipCode: "98940"},
	{latitude: 47.224663, longitude: -121.000243, zipCode: "98941"},
	{latitude: 46.801335, longitude: -120.66481, zipCode: "98942"},
	{latitude: 47.183995, longitude: -120.965412, zipCode: "98943"},
	{latitude: 46.507007, longitude: -119.672018, zipCode: "98944"},
	{latitude: 47.068304, longitude: -120.756392, zipCode: "98946"},
	{latitude: 46.687597, longitude: -120.80919, zipCode: "98947"},
	{latitude: 46.237785, longitude: -120.368797, zipCode: "98948"},
	{latitude: 46.815145, longitude: -119.971371, zipCode: "98950"},
	{latitude: 46.456028, longitude: -120.513604, zipCode: "98951"},
	{latitude: 46.350332, longitude: -120.816955, zipCode: "98952"},
	{latitude: 46.441503, longitude: -120.232789, zipCode: "98953"},
	{latitude: 47.638729, longitude: -117.585826, zipCode: "99001"},
	{latitude: 47.917122, longitude: -117.274929, zipCode: "99003"},
	{latitude: 47.419011, longitude: -117.599877, zipCode: "99004"},
	{latitude: 47.844047, longitude: -117.363446, zipCode: "99005"},
	{latitude: 47.951494, longitude: -117.506357, zipCode: "99006"},
	{latitude: 47.503335, longitude: -117.933438, zipCode: "99008"},
	{latitude: 48.026086, longitude: -117.252446, zipCode: "99009"},
	{latitude: 47.618653, longitude: -117.64842, zipCode: "99011"},
	{latitude: 47.381309, longitude: -117.191336, zipCode: "99012"},
	{latitude: 47.88114, longitude: -117.835284, zipCode: "99013"},
	{latitude: 47.561161, longitude: -117.596644, zipCode: "99014"},
	{latitude: 47.597264, longitude: -117.121002, zipCode: "99016"},
	{latitude: 47.184114, longitude: -117.886748, zipCode: "99017"},
	{latitude: 47.291678, longitude: -117.152079, zipCode: "99018"},
	{latitude: 47.637085, longitude: -117.074721, zipCode: "99019"},
	{latitude: 47.563185, longitude: -117.497051, zipCode: "99020"},
	{latitude: 47.849534, longitude: -117.187271, zipCode: "99021"},
	{latitude: 47.59978, longitude: -117.708985, zipCode: "99022"},
	{latitude: 47.564726, longitude: -117.144555, zipCode: "99023"},
	{latitude: 47.793859, longitude: -117.075344, zipCode: "99025"},
	{latitude: 47.800969, longitude: -117.633772, zipCode: "99026"},
	{latitude: 47.710128, longitude: -117.12756, zipCode: "99027"},
	{latitude: 47.706909, longitude: -117.829566, zipCode: "99029"},
	{latitude: 47.477797, longitude: -117.112936, zipCode: "99030"},
	{latitude: 47.413753, longitude: -117.329774, zipCode: "99031"},
	{latitude: 47.327317, longitude: -118.033606, zipCode: "99032"},
	{latitude: 47.230554, longitude: -117.097758, zipCode: "99033"},
	{latitude: 47.886178, longitude: -117.762403, zipCode: "99034"},
	{latitude: 47.520054, longitude: -117.271957, zipCode: "99036"},
	{latitude: 47.636616, longitude: -117.199994, zipCode: "99037"},
	{latitude: 47.338133, longitude: -117.231303, zipCode: "99039"},
	{latitude: 47.898469, longitude: -118.003678, zipCode: "99040"},
	{latitude: 48.316361, longitude: -117.937275, zipCode: "99101"},
	{latitude: 46.79218, longitude: -117.25036, zipCode: "99102"},
	{latitude: 47.735811, longitude: -118.924379, zipCode: "99103"},
	{latitude: 46.956914, longitude: -118.072907, zipCode: "99105"},
	{latitude: 48.292416, longitude: -117.69864, zipCode: "99109"},
	{latitude: 48.008305, longitude: -117.581111, zipCode: "99110"},
	{latitude: 46.855891, longitude: -117.451152, zipCode: "99111"},
	{latitude: 46.584474, longitude: -117.196143, zipCode: "99113"},
	{latitude: 48.655198, longitude: -117.735834, zipCode: "99114"},
	{latitude: 47.674057, longitude: -119.377651, zipCode: "99115"},
	{latitude: 48.014782, longitude: -118.935774, zipCode: "99116"},
	{latitude: 47.648785, longitude: -118.570121, zipCode: "99117"},
	{latitude: 48.897363, longitude: -118.637784, zipCode: "99118"},
	{latitude: 48.509196, longitude: -117.314915, zipCode: "99119"},
	{latitude: 48.936195, longitude: -118.469204, zipCode: "99121"},
	{latitude: 47.687561, longitude: -118.207492, zipCode: "99122"},
	{latitude: 47.886491, longitude: -119.054823, zipCode: "99123"},
	{latitude: 48.001499, longitude: -118.953675, zipCode: "99124"},
	{latitude: 46.961364, longitude: -117.747257, zipCode: "99125"},
	{latitude: 48.7301, longitude: -117.971921, zipCode: "99126"},
	{latitude: 47.097949, longitude: -117.081329, zipCode: "99128"},
	{latitude: 48.010724, longitude: -118.156673, zipCode: "99129"},
	{latitude: 46.999152, longitude: -117.18554, zipCode: "99130"},
	{latitude: 48.297748, longitude: -118.094973, zipCode: "99131"},
	{latitude: 48.01013, longitude: -119.136483, zipCode: "99133"},
	{latitude: 47.451045, longitude: -118.296869, zipCode: "99134"},
	{latitude: 47.661126, longitude: -119.091245, zipCode: "99135"},
	{latitude: 46.674693, longitude: -117.948898, zipCode: "99136"},
	{latitude: 48.14812, longitude: -118.115758, zipCode: "99137"},
	{latitude: 48.231375, longitude: -118.390882, zipCode: "99138"},
	{latitude: 48.716605, longitude: -117.25263, zipCode: "99139"},
	{latitude: 48.111429, longitude: -118.646173, zipCode: "99140"},
	{latitude: 48.717122, longitude: -118.167964, zipCode: "99141"},
	{latitude: 46.765536, longitude: -117.848245, zipCode: "99143"},
	{latitude: 47.368101, longitude: -118.49041, zipCode: "99144"},
	{latitude: 48.957496, longitude: -118.29774, zipCode: "99146"},
	{latitude: 47.859324, longitude: -118.462023, zipCode: "99147"},
	{latitude: 48.107298, longitude: -117.571356, zipCode: "99148"},
	{latitude: 47.224937, longitude: -117.467951, zipCode: "99149"},
	{latitude: 48.806233, longitude: -118.610771, zipCode: "99150"},
	{latitude: 48.664465, longitude: -118.061215, zipCode: "99151"},
	{latitude: 48.83693, longitude: -117.399892, zipCode: "99152"},
	{latitude: 48.910859, longitude: -117.165651, zipCode: "99153"},
	{latitude: 47.391292, longitude: -118.355776, zipCode: "99154"},
	{latitude: 48.311477, longitude: -119.006425, zipCode: "99155"},
	{latitude: 48.161494, longitude: -117.190043, zipCode: "99156"},
	{latitude: 48.918184, longitude: -117.796302, zipCode: "99157"},
	{latitude: 47.133008, longitude: -117.24013, zipCode: "99158"},
	{latitude: 47.32684, longitude: -118.76587, zipCode: "99159"},
	{latitude: 48.868941, longitude: -118.301224, zipCode: "99160"},
	{latitude: 46.903644, longitude: -117.148073, zipCode: "99161"},
	{latitude: 46.730384, longitude: -117.218395, zipCode: "99163"},
	{latitude: 48.608711, longitude: -118.667275, zipCode: "99166"},
	{latitude: 48.411697, longitude: -118.133525, zipCode: "99167"},
	{latitude: 47.119773, longitude: -118.378417, zipCode: "99169"},
	{latitude: 47.252956, longitude: -117.397216, zipCode: "99170"},
	{latitude: 47.098248, longitude: -117.65389, zipCode: "99171"},
	{latitude: 48.040922, longitude: -117.857659, zipCode: "99173"},
	{latitude: 47.007232, longitude: -117.356887, zipCode: "99174"},
	{latitude: 47.09219, longitude: -117.412253, zipCode: "99176"},
	{latitude: 46.494979, longitude: -117.125923, zipCode: "99179"},
	{latitude: 48.305211, longitude: -117.3065, zipCode: "99180"},
	{latitude: 48.144796, longitude: -117.766339, zipCode: "99181"},
	{latitude: 47.726994, longitude: -118.711068, zipCode: "99185"},
	{latitude: 47.662482, longitude: -117.436729, zipCode: "99201"},
	{latitude: 47.657011, longitude: -117.378966, zipCode: "99202"},
	{latitude: 47.62955, longitude: -117.403247, zipCode: "99203"},
	{latitude: 47.647242, longitude: -117.426704, zipCode: "99204"},
	{latitude: 47.696825, longitude: -117.444879, zipCode: "99205"},
	{latitude: 47.62765, longitude: -117.254607, zipCode: "99206"},
	{latitude: 47.689203, longitude: -117.388974, zipCode: "99207"},
	{latitude: 47.785815, longitude: -117.452348, zipCode: "99208"},
	{latitude: 47.665317, longitude: -117.307293, zipCode: "99212"},
	{latitude: 47.686361, longitude: -117.216702, zipCode: "99216"},
	{latitude: 47.740709, longitude: -117.261376, zipCode: "99217"},
	{latitude: 47.758924, longitude: -117.409465, zipCode: "99218"},
	{latitude: 47.597087, longitude: -117.317796, zipCode: "99223"},
	{latitude: 47.647672, longitude: -117.534951, zipCode: "99224"},
	{latitude: 47.753833, longitude: -117.417864, zipCode: "99251"},
	{latitude: 47.666041, longitude: -117.402348, zipCode: "99258"},
	{latitude: 46.377835, longitude: -118.951726, zipCode: "99301"},
	{latitude: 46.324787, longitude: -119.506156, zipCode: "99320"},
	{latitude: 46.858962, longitude: -119.893382, zipCode: "99321"},
	{latitude: 45.947031, longitude: -120.155532, zipCode: "99322"},
	{latitude: 46.189604, longitude: -118.871824, zipCode: "99323"},
	{latitude: 46.043727, longitude: -118.385451, zipCode: "99324"},
	{latitude: 46.668839, longitude: -118.841759, zipCode: "99326"},
	{latitude: 46.275681, longitude: -117.870851, zipCode: "99328"},
	{latitude: 46.139705, longitude: -118.14695, zipCode: "99329"},
	{latitude: 46.494519, longitude: -119.030287, zipCode: "99330"},
	{latitude: 46.697027, longitude: -118.139386, zipCode: "99333"},
	{latitude: 46.647174, longitude: -118.439679, zipCode: "99335"},
	{latitude: 46.214052, longitude: -119.17573, zipCode: "99336"},
	{latitude: 46.052007, longitude: -119.098495, zipCode: "99337"},
	{latitude: 46.173626, longitude: -119.274012, zipCode: "99338"},
	{latitude: 46.906596, longitude: -118.642171, zipCode: "99341"},
	{latitude: 46.59672, longitude: -119.164729, zipCode: "99343"},
	{latitude: 46.822362, longitude: -119.171787, zipCode: "99344"},
	{latitude: 45.933504, longitude: -119.655171, zipCode: "99345"},
	{latitude: 46.030682, longitude: -119.303226, zipCode: "99346"},
	{latitude: 46.42335, longitude: -117.574429, zipCode: "99347"},
	{latitude: 46.402889, longitude: -118.435437, zipCode: "99348"},
	{latitude: 46.731147, longitude: -119.700541, zipCode: "99349"},
	{latitude: 46.142479, longitude: -119.736752, zipCode: "99350"},
	{latitude: 46.245767, longitude: -119.291463, zipCode: "99352"},
	{latitude: 46.313808, longitude: -119.387768, zipCode: "99353"},
	{latitude: 46.332253, longitude: -119.299105, zipCode: "99354"},
	{latitude: 45.829079, longitude: -120.360018, zipCode: "99356"},
	{latitude: 46.89131, longitude: -119.661625, zipCode: "99357"},
	{latitude: 46.513035, longitude: -118.161895, zipCode: "99359"},
	{latitude: 46.090544, longitude: -118.667563, zipCode: "99360"},
	{latitude: 46.236679, longitude: -118.143236, zipCode: "99361"},
	{latitude: 46.101061, longitude: -118.313625, zipCode: "99362"},
	{latitude: 46.045569, longitude: -118.885254, zipCode: "99363"},
	{latitude: 46.785258, longitude: -118.332223, zipCode: "99371"},
	{latitude: 46.087207, longitude: -117.249856, zipCode: "99401"},
	{latitude: 46.221144, longitude: -117.35126, zipCode: "99402"},
	{latitude: 46.375542, longitude: -117.2903, zipCode: "99403"},
	{latitude: 61.219998, longitude: -149.85784, zipCode: "99501"},
	{latitude: 61.163652, longitude: -149.996643, zipCode: "99502"},
	{latitude: 62.043689, longitude: -158.174466, zipCode: "99503"},
	{latitude: 61.190578, longitude: -149.607154, zipCode: "99504"},
	{latitude: 61.284745, longitude: -149.653973, zipCode: "99505"},
	{latitude: 61.271958, longitude: -149.807145, zipCode: "99506"},
	{latitude: 61.115132, longitude: -149.616122, zipCode: "99507"},
	{latitude: 61.2014, longitude: -149.817502, zipCode: "99508"},
	{latitude: 61.214772, longitude: -149.885658, zipCode: "99513"},
	{latitude: 61.116381, longitude: -149.890375, zipCode: "99515"},
	{latitude: 61.079332, longitude: -149.709777, zipCode: "99516"},
	{latitude: 61.190346, longitude: -149.939108, zipCode: "99517"},
	{latitude: 61.159532, longitude: -149.884764, zipCode: "99518"},
	{latitude: 70.01736, longitude: -149.829741, zipCode: "99519"},
	{latitude: 61.184682, longitude: -149.983704, zipCode: "99530"},
	{latitude: 61.009658, longitude: -149.317707, zipCode: "99540"},
	{latitude: 51.85567, longitude: -176.668632, zipCode: "99546"},
	{latitude: 52.223414, longitude: -174.203358, zipCode: "99547"},
	{latitude: 56.271969, longitude: -158.789108, zipCode: "99548"},
	{latitude: 56.965241, longitude: -158.586416, zipCode: "99549"},
	{latitude: 57.867012, longitude: -152.856248, zipCode: "99550"},
	{latitude: 60.920984, longitude: -161.402087, zipCode: "99551"},
	{latitude: 60.906997, longitude: -161.220873, zipCode: "99552"},
	{latitude: 54.117318, longitude: -165.830835, zipCode: "99553"},
	{latitude: 62.710997, longitude: -164.649719, zipCode: "99554"},
	{latitude: 59.288961, longitude: -158.664343, zipCode: "99555"},
	{latitude: 59.834171, longitude: -151.576142, zipCode: "99556"},
	{latitude: 61.468883, longitude: -155.215247, zipCode: "99557"},
	{latitude: 62.631627, longitude: -160.215106, zipCode: "99558"},
	{latitude: 60.772862, longitude: -161.871467, zipCode: "99559"},
	{latitude: 60.151845, longitude: -164.251542, zipCode: "99561"},
	{latitude: 61.550446, longitude: -165.356458, zipCode: "99563"},
	{latitude: 56.295019, longitude: -158.393324, zipCode: "99564"},
	{latitude: 56.304267, longitude: -158.500259, zipCode: "99565"},
	{latitude: 61.270907, longitude: -142.605516, zipCode: "99566"},
	{latitude: 61.299272, longitude: -149.021202, zipCode: "99567"},
	{latitude: 60.271797, longitude: -150.605205, zipCode: "99568"},
	{latitude: 58.804992, longitude: -158.528543, zipCode: "99569"},
	{latitude: 55.266171, longitude: -162.804744, zipCode: "99571"},
	{latitude: 60.529893, longitude: -149.952595, zipCode: "99572"},
	{latitude: 61.569192, longitude: -145.670475, zipCode: "99573"},
	{latitude: 61.117521, longitude: -147.60689, zipCode: "99574"},
	{latitude: 61.796698, longitude: -158.08102, zipCode: "99575"},
	{latitude: 59.361044, longitude: -158.822923, zipCode: "99576"},
	{latitude: 61.17425, longitude: -149.284329, zipCode: "99577"},
	{latitude: 60.216265, longitude: -162.041999, zipCode: "99578"},
	{latitude: 58.224788, longitude: -157.456539, zipCode: "99579"},
	{latitude: 59.345782, longitude: -157.487023, zipCode: "99580"},
	{latitude: 62.822098, longitude: -164.708371, zipCode: "99581"},
	{latitude: 54.887802, longitude: -163.408322, zipCode: "99583"},
	{latitude: 61.871555, longitude: -162.06087, zipCode: "99585"},
	{latitude: 62.690716, longitude: -144.237987, zipCode: "99586"},
	{latitude: 60.965808, longitude: -148.876643, zipCode: "99587"},
	{latitude: 62.707758, longitude: -146.719388, zipCode: "99588"},
	{latitude: 59.237008, longitude: -161.57936, zipCode: "99589"},
	{latitude: 62.896051, longitude: -160.112989, zipCode: "99590"},
	{latitude: 56.600936, longitude: -169.681052, zipCode: "99591"},
	{latitude: 62.185115, longitude: -159.858776, zipCode: "99602"},
	{latitude: 59.538519, longitude: -151.186927, zipCode: "99603"},
	{latitude: 61.509167, longitude: -166.111471, zipCode: "99604"},
	{latitude: 60.869131, longitude: -149.471513, zipCode: "99605"},
	{latitude: 59.737057, longitude: -154.902414, zipCode: "99606"},
	{latitude: 61.54027, longitude: -160.348983, zipCode: "99607"},
	{latitude: 57.590981, longitude: -154.363413, zipCode: "99608"},
	{latitude: 60.86382, longitude: -162.536598, zipCode: "99609"},
	{latitude: 60.280964, longitude: -151.273, zipCode: "99610"},
	{latitude: 60.83481, longitude: -150.724223, zipCode: "99611"},
	{latitude: 55.084993, longitude: -162.316806, zipCode: "99612"},
	{latitude: 59.070918, longitude: -155.377509, zipCode: "99613"},
	{latitude: 59.937456, longitude: -164.062221, zipCode: "99614"},
	{latitude: 57.682717, longitude: -153.378849, zipCode: "99615"},
	{latitude: 63.02919, longitude: -163.536654, zipCode: "99620"},
	{latitude: 60.793231, longitude: -161.430844, zipCode: "99621"},
	{latitude: 59.877575, longitude: -163.177317, zipCode: "99622"},
	{latitude: 61.439281, longitude: -149.922743, zipCode: "99623"},
	{latitude: 57.489377, longitude: -153.853499, zipCode: "99624"},
	{latitude: 59.068246, longitude: -156.901021, zipCode: "99625"},
	{latitude: 61.517121, longitude: -160.35465, zipCode: "99626"},
	{latitude: 63.548519, longitude: -154.575648, zipCode: "99627"},
	{latitude: 58.739523, longitude: -158.914501, zipCode: "99628"},
	{latitude: 60.370179, longitude: -166.268173, zipCode: "99630"},
	{latitude: 60.596322, longitude: -149.638299, zipCode: "99631"},
	{latitude: 62.11233, longitude: -163.664502, zipCode: "99632"},
	{latitude: 58.78516, longitude: -156.885872, zipCode: "99633"},
	{latitude: 60.678696, longitude: -162.015137, zipCode: "99634"},
	{latitude: 61.068571, longitude: -153.003192, zipCode: "99635"},
	{latitude: 59.485309, longitude: -157.309089, zipCode: "99636"},
	{latitude: 60.529752, longitude: -165.106622, zipCode: "99637"},
	{latitude: 53.007863, longitude: -168.718502, zipCode: "99638"},
	{latitude: 59.985333, longitude: -151.507668, zipCode: "99639"},
	{latitude: 60.718588, longitude: -155.572329, zipCode: "99640"},
	{latitude: 60.881739, longitude: -162.464569, zipCode: "99641"},
	{latitude: 57.218798, longitude: -153.329674, zipCode: "99643"},
	{latitude: 57.910127, longitude: -152.382358, zipCode: "99644"},
	{latitude: 61.62442, longitude: -148.982077, zipCode: "99645"},
	{latitude: 59.879392, longitude: -154.070926, zipCode: "99647"},
	{latitude: 55.934456, longitude: -159.155754, zipCode: "99648"},
	{latitude: 57.505137, longitude: -157.285832, zipCode: "99649"},
	{latitude: 61.94257, longitude: -162.884603, zipCode: "99650"},
	{latitude: 58.952583, longitude: -161.747032, zipCode: "99651"},
	{latitude: 61.515547, longitude: -149.97553, zipCode: "99652"},
	{latitude: 60.15673, longitude: -154.33961, zipCode: "99653"},
	{latitude: 61.605123, longitude: -149.422671, zipCode: "99654"},
	{latitude: 59.742402, longitude: -161.887147, zipCode: "99655"},
	{latitude: 61.865263, longitude: -157.35707, zipCode: "99656"},
	{latitude: 61.728028, longitude: -161.678432, zipCode: "99657"},
	{latitude: 62.091622, longitude: -163.248973, zipCode: "99658"},
	{latitude: 63.458499, longitude: -162.134486, zipCode: "99659"},
	{latitude: 57.179076, longitude: -170.322824, zipCode: "99660"},
	{latitude: 55.262272, longitude: -160.709422, zipCode: "99661"},
	{latitude: 61.841929, longitude: -165.583279, zipCode: "99662"},
	{latitude: 59.40119, longitude: -151.624586, zipCode: "99663"},
	{latitude: 60.133829, longitude: -149.500604, zipCode: "99664"},
	{latitude: 62.667392, longitude: -159.535075, zipCode: "99665"},
	{latitude: 62.440625, longitude: -165.145536, zipCode: "99666"},
	{latitude: 61.697681, longitude: -152.140403, zipCode: "99667"},
	{latitude: 61.653861, longitude: -157.10302, zipCode: "99668"},
	{latitude: 60.360071, longitude: -151.017855, zipCode: "99669"},
	{latitude: 58.675534, longitude: -156.994126, zipCode: "99670"},
	{latitude: 63.473394, longitude: -162.259981, zipCode: "99671"},
	{latitude: 60.569633, longitude: -150.761371, zipCode: "99672"},
	{latitude: 61.591292, longitude: -148.061929, zipCode: "99674"},
	{latitude: 62.982698, longitude: -156.083829, zipCode: "99675"},
	{latitude: 62.571112, longitude: -149.60647, zipCode: "99676"},
	{latitude: 60.894438, longitude: -146.668561, zipCode: "99677"},
	{latitude: 59.263241, longitude: -160.472561, zipCode: "99678"},
	{latitude: 61.10435, longitude: -160.937231, zipCode: "99679"},
	{latitude: 60.345143, longitude: -162.663729, zipCode: "99680"},
	{latitude: 60.60713, longitude: -165.128009, zipCode: "99681"},
	{latitude: 61.190862, longitude: -151.184237, zipCode: "99682"},
	{latitude: 62.415358, longitude: -150.407836, zipCode: "99683"},
	{latitude: 64.073219, longitude: -160.2064, zipCode: "99684"},
	{latitude: 53.873112, longitude: -166.483834, zipCode: "99685"},
	{latitude: 61.04283, longitude: -146.151875, zipCode: "99686"},
	{latitude: 61.886957, longitude: -149.620229, zipCode: "99688"},
	{latitude: 59.484561, longitude: -139.419308, zipCode: "99689"},
	{latitude: 60.462401, longitude: -164.83464, zipCode: "99690"},
	{latitude: 62.728975, longitude: -153.582886, zipCode: "99691"},
	{latitude: 53.883787, longitude: -166.544899, zipCode: "99692"},
	{latitude: 60.850605, longitude: -148.033815, zipCode: "99693"},
	{latitude: 61.619917, longitude: -149.826544, zipCode: "99694"},
	{latitude: 55.918199, longitude: -159.493298, zipCode: "99695"},
	{latitude: 67.37543, longitude: -149.728458, zipCode: "99701"},
	{latitude: 64.650615, longitude: -147.052322, zipCode: "99702"},
	{latitude: 64.834706, longitude: -147.622949, zipCode: "99703"},
	{latitude: 64.288447, longitude: -149.202428, zipCode: "99704"},
	{latitude: 64.773899, longitude: -147.267614, zipCode: "99705"},
	{latitude: 64.009705, longitude: -144.681574, zipCode: "99706"},
	{latitude: 65.155019, longitude: -146.25258, zipCode: "99707"},
	{latitude: 64.87735, longitude: -148.14857, zipCode: "99709"},
	{latitude: 65.058583, longitude: -145.925646, zipCode: "99712"},
	{latitude: 64.381305, longitude: -146.530601, zipCode: "99714"},
	{latitude: 66.563762, longitude: -152.767992, zipCode: "99720"},
	{latitude: 68.149752, longitude: -151.697066, zipCode: "99721"},
	{latitude: 68.165374, longitude: -145.401362, zipCode: "99722"},
	{latitude: 71.253861, longitude: -156.798246, zipCode: "99723"},
	{latitude: 66.318235, longitude: -146.795494, zipCode: "99724"},
	{latitude: 64.861187, longitude: -148.023914, zipCode: "99725"},
	{latitude: 66.75531, longitude: -151.414738, zipCode: "99726"},
	{latitude: 65.976517, longitude: -161.139178, zipCode: "99727"},
	{latitude: 63.243983, longitude: -147.978063, zipCode: "99729"},
	{latitude: 65.648991, longitude: -145.614841, zipCode: "99730"},
	{latitude: 64.079431, longitude: -141.971999, zipCode: "99732"},
	{latitude: 65.838617, longitude: -144.175331, zipCode: "99733"},
	{latitude: 70.422628, longitude: -149.299665, zipCode: "99734"},
	{latitude: 66.069488, longitude: -162.764572, zipCode: "99736"},
	{latitude: 63.4435, longitude: -145.295615, zipCode: "99737"},
	{latitude: 65.149633, longitude: -142.654406, zipCode: "99738"},
	{latitude: 64.968406, longitude: -162.827159, zipCode: "99739"},
	{latitude: 67.636544, longitude: -144.680484, zipCode: "99740"},
	{latitude: 64.741422, longitude: -156.873394, zipCode: "99741"},
	{latitude: 63.752679, longitude: -171.68834, zipCode: "99742"},
	{latitude: 63.926835, longitude: -149.9883, zipCode: "99743"},
	{latitude: 64.330823, longitude: -149.053453, zipCode: "99744"},
	{latitude: 66.051578, longitude: -154.232791, zipCode: "99745"},
	{latitude: 65.705524, longitude: -156.310953, zipCode: "99746"},
	{latitude: 70.118453, longitude: -143.686818, zipCode: "99747"},
	{latitude: 64.314143, longitude: -158.778761, zipCode: "99748"},
	{latitude: 66.978607, longitude: -160.431777, zipCode: "99749"},
	{latitude: 67.74279, longitude: -164.530444, zipCode: "99750"},
	{latitude: 66.923402, longitude: -156.904071, zipCode: "99751"},
	{latitude: 67.28686, longitude: -161.184862, zipCode: "99752"},
	{latitude: 64.941084, longitude: -161.145458, zipCode: "99753"},
	{latitude: 64.902073, longitude: -157.693531, zipCode: "99754"},
	{latitude: 63.75455, longitude: -149.211207, zipCode: "99755"},
	{latitude: 64.701264, longitude: -151.212006, zipCode: "99756"},
	{latitude: 63.790253, longitude: -152.461794, zipCode: "99757"},
	{latitude: 65.151781, longitude: -149.372384, zipCode: "99758"},
	{latitude: 69.737117, longitude: -162.83412, zipCode: "99759"},
	{latitude: 64.41719, longitude: -149.150356, zipCode: "99760"},
	{latitude: 67.62087, longitude: -163.057747, zipCode: "99761"},
	{latitude: 64.739455, longitude: -164.654688, zipCode: "99762"},
	{latitude: 66.82821, longitude: -161.034669, zipCode: "99763"},
	{latitude: 62.685441, longitude: -141.822756, zipCode: "99764"},
	{latitude: 64.730679, longitude: -158.131037, zipCode: "99765"},
	{latitude: 68.276605, longitude: -165.986279, zipCode: "99766"},
	{latitude: 65.499413, longitude: -150.153341, zipCode: "99767"},
	{latitude: 65.26045, longitude: -153.567369, zipCode: "99768"},
	{latitude: 63.679706, longitude: -170.483324, zipCode: "99769"},
	{latitude: 66.611235, longitude: -160.097617, zipCode: "99770"},
	{latitude: 64.356124, longitude: -161.196462, zipCode: "99771"},
	{latitude: 65.840938, longitude: -165.900648, zipCode: "99772"},
	{latitude: 66.880505, longitude: -157.149979, zipCode: "99773"},
	{latitude: 66.023711, longitude: -149.080307, zipCode: "99774"},
	{latitude: 64.857468, longitude: -147.834584, zipCode: "99775"},
	{latitude: 63.328691, longitude: -143.486599, zipCode: "99776"},
	{latitude: 65.184004, longitude: -152.055102, zipCode: "99777"},
	{latitude: 65.252359, longitude: -166.352133, zipCode: "99778"},
	{latitude: 63.639378, longitude: -141.51097, zipCode: "99780"},
	{latitude: 67.468627, longitude: -146.064642, zipCode: "99781"},
	{latitude: 70.647539, longitude: -159.939272, zipCode: "99782"},
	{latitude: 65.612081, longitude: -168.078096, zipCode: "99783"},
	{latitude: 64.6828, longitude: -163.405534, zipCode: "99784"},
	{latitude: 65.343196, longitude: -166.508946, zipCode: "99785"},
	{latitude: 67.090944, longitude: -157.887097, zipCode: "99786"},
	{latitude: 66.646753, longitude: -143.789298, zipCode: "99788"},
	{latitude: 70.213483, longitude: -150.989674, zipCode: "99789"},
	{latitude: 65.459488, longitude: -148.10749, zipCode: "99790"},
	{latitude: 70.480786, longitude: -157.325459, zipCode: "99791"},
	{latitude: 58.37291, longitude: -134.178445, zipCode: "99801"},
	{latitude: 57.378398, longitude: -134.279042, zipCode: "99820"},
	{latitude: 58.265381, longitude: -134.400144, zipCode: "99824"},
	{latitude: 58.087681, longitude: -136.288309, zipCode: "99825"},
	{latitude: 58.41229, longitude: -135.808626, zipCode: "99826"},
	{latitude: 58.820812, longitude: -136.211459, zipCode: "99827"},
	{latitude: 58.024766, longitude: -135.30821, zipCode: "99829"},
	{latitude: 56.771079, longitude: -134.089981, zipCode: "99830"},
	{latitude: 57.965619, longitude: -136.071325, zipCode: "99832"},
	{latitude: 56.840409, longitude: -133.187084, zipCode: "99833"},
	{latitude: 56.960285, longitude: -135.03733, zipCode: "99835"},
	{latitude: 56.248273, longitude: -134.6522, zipCode: "99836"},
	{latitude: 59.560379, longitude: -135.338279, zipCode: "99840"},
	{latitude: 57.955765, longitude: -135.517468, zipCode: "99841"},
	{latitude: 58.488208, longitude: -135.484316, zipCode: "99850"},
	{latitude: 55.655512, longitude: -131.331901, zipCode: "99901"},
	{latitude: 55.733699, longitude: -132.104533, zipCode: "99903"},
	{latitude: 55.916873, longitude: -132.695503, zipCode: "99918"},
	{latitude: 55.645685, longitude: -132.49613, zipCode: "99919"},
	{latitude: 55.515474, longitude: -132.701922, zipCode: "99921"},
	{latitude: 55.307528, longitude: -133.046815, zipCode: "99922"},
	{latitude: 56.000518, longitude: -130.037474, zipCode: "99923"},
	{latitude: 55.550203, longitude: -132.945947, zipCode: "99925"},
	{latitude: 55.138352, longitude: -131.470425, zipCode: "99926"},
	{latitude: 56.331881, longitude: -133.60642, zipCode: "99927"},
	{latitude: 56.221499, longitude: -131.923588, zipCode: "99929"},
	{latitude: 18.180555, longitude: -66.749961, zipCode: "00601"},
	{latitude: 18.361945, longitude: -67.175597, zipCode: "00602"},
	{latitude: 18.457399, longitude: -67.124867, zipCode: "00603"},
	{latitude: 18.158327, longitude: -66.932928, zipCode: "00606"},
	{latitude: 18.29396, longitude: -67.127182, zipCode: "00610"},
	{latitude: 18.276316, longitude: -66.807165, zipCode: "00611"},
	{latitude: 18.416727, longitude: -66.70009, zipCode: "00612"},
	{latitude: 18.420412, longitude: -66.671979, zipCode: "00616"},
	{latitude: 18.446889, longitude: -66.561154, zipCode: "00617"},
	{latitude: 17.988103, longitude: -67.160357, zipCode: "00622"},
	{latitude: 18.084371, longitude: -67.158765, zipCode: "00623"},
	{latitude: 18.051808, longitude: -66.721711, zipCode: "00624"},
	{latitude: 18.412507, longitude: -66.863811, zipCode: "00627"},
	{latitude: 18.184294, longitude: -66.830795, zipCode: "00631"},
	{latitude: 18.1636, longitude: -67.080097, zipCode: "00636"},
	{latitude: 18.080103, longitude: -66.947975, zipCode: "00637"},
	{latitude: 18.280188, longitude: -66.513111, zipCode: "00638"},
	{latitude: 18.268074, longitude: -66.701785, zipCode: "00641"},
	{latitude: 18.432897, longitude: -66.286499, zipCode: "00646"},
	{latitude: 17.963613, longitude: -66.947127, zipCode: "00647"},
	{latitude: 18.349103, longitude: -66.578175, zipCode: "00650"},
	{latitude: 18.44844, longitude: -66.59411, zipCode: "00652"},
	{latitude: 17.988642, longitude: -66.90169, zipCode: "00653"},
	{latitude: 18.040426, longitude: -66.789672, zipCode: "00656"},
	{latitude: 18.406773, longitude: -66.809288, zipCode: "00659"},
	{latitude: 18.134695, longitude: -67.116199, zipCode: "00660"},
	{latitude: 18.468301, longitude: -67.015749, zipCode: "00662"},
	{latitude: 18.21033, longitude: -66.591616, zipCode: "00664"},
	{latitude: 18.003422, longitude: -67.03581, zipCode: "00667"},
	{latitude: 18.276925, longitude: -66.869587, zipCode: "00669"},
	{latitude: 18.233585, longitude: -66.987585, zipCode: "00670"},
	{latitude: 18.424145, longitude: -66.487895, zipCode: "00674"},
	{latitude: 18.381597, longitude: -67.080884, zipCode: "00676"},
	{latitude: 18.332568, longitude: -67.227022, zipCode: "00677"},
	{latitude: 18.430503, longitude: -66.930479, zipCode: "00678"},
	{latitude: 18.183493, longitude: -67.133054, zipCode: "00680"},
	{latitude: 18.224864, longitude: -67.153891, zipCode: "00682"},
	{latitude: 18.1078, longitude: -67.037263, zipCode: "00683"},
	{latitude: 18.336887, longitude: -66.972929, zipCode: "00685"},
	{latitude: 18.318017, longitude: -66.420392, zipCode: "00687"},
	{latitude: 18.382311, longitude: -66.626416, zipCode: "00688"},
	{latitude: 18.493543, longitude: -67.096915, zipCode: "00690"},
	{latitude: 18.410652, longitude: -66.336202, zipCode: "00692"},
	{latitude: 18.425961, longitude: -66.398098, zipCode: "00693"},
	{latitude: 18.483127, longitude: -66.39236, zipCode: "00694"},
	{latitude: 18.072916, longitude: -66.85119, zipCode: "00698"},
	{latitude: 18.248401, longitude: -66.130662, zipCode: "00703"},
	{latitude: 17.973168, longitude: -66.229011, zipCode: "00704"},
	{latitude: 18.12896, longitude: -66.266691, zipCode: "00705"},
	{latitude: 18.014055, longitude: -65.920751, zipCode: "00707"},
	{latitude: 17.995867, longitude: -66.055529, zipCode: "00714"},
	{latitude: 17.990485, longitude: -66.566143, zipCode: "00715"},
	{latitude: 17.985014, longitude: -66.606721, zipCode: "00716"},
	{latitude: 18.002639, longitude: -66.611087, zipCode: "00717"},
	{latitude: 18.23201, longitude: -65.756036, zipCode: "00718"},
	{latitude: 18.28992, longitude: -66.25344, zipCode: "00719"},
	{latitude: 18.216608, longitude: -66.423682, zipCode: "00720"},
	{latitude: 18.019337, longitude: -66.001862, zipCode: "00723"},
	{latitude: 18.220689, longitude: -66.03958, zipCode: "00725"},
	{latitude: 18.211921, longitude: -66.073355, zipCode: "00727"},
	{latitude: 17.991071, longitude: -66.662968, zipCode: "00728"},
	{latitude: 18.334918, longitude: -65.887078, zipCode: "00729"},
	{latitude: 18.030882, longitude: -66.611928, zipCode: "00730"},
	{latitude: 18.109947, longitude: -66.635622, zipCode: "00731"},
	{latitude: 18.251772, longitude: -65.666882, zipCode: "00735"},
	{latitude: 18.103624, longitude: -66.151667, zipCode: "00736"},
	{latitude: 18.304458, longitude: -65.698711, zipCode: "00738"},
	{latitude: 18.175543, longitude: -66.162102, zipCode: "00739"},
	{latitude: 18.333961, longitude: -65.631674, zipCode: "00740"},
	{latitude: 18.163855, longitude: -65.754042, zipCode: "00741"},
	{latitude: 18.352176, longitude: -65.810929, zipCode: "00745"},
	{latitude: 18.003779, longitude: -66.262905, zipCode: "00751"},
	{latitude: 18.144582, longitude: -65.972253, zipCode: "00754"},
	{latitude: 17.991428, longitude: -66.402337, zipCode: "00757"},
	{latitude: 18.124207, longitude: -65.42307, zipCode: "00765"},
	{latitude: 18.130718, longitude: -66.472244, zipCode: "00766"},
	{latitude: 18.069489, longitude: -65.896029, zipCode: "00767"},
	{latitude: 18.096641, longitude: -66.358335, zipCode: "00769"},
	{latitude: 18.187148, longitude: -65.871189, zipCode: "00771"},
	{latitude: 18.438944, longitude: -65.90522, zipCode: "00772"},
	{latitude: 18.342888, longitude: -65.723109, zipCode: "00773"},
	{latitude: 18.326608, longitude: -65.307769, zipCode: "00775"},
	{latitude: 18.224327, longitude: -65.909085, zipCode: "00777"},
	{latitude: 18.264076, longitude: -65.97851, zipCode: "00778"},
	{latitude: 18.094076, longitude: -66.565781, zipCode: "00780"},
	{latitude: 18.224878, longitude: -66.224482, zipCode: "00782"},
	{latitude: 18.300221, longitude: -66.328927, zipCode: "00783"},
	{latitude: 18.010714, longitude: -66.133615, zipCode: "00784"},
	{latitude: 18.135725, longitude: -66.228698, zipCode: "00786"},
	{latitude: 18.142603, longitude: -65.812812, zipCode: "00791"},
	{latitude: 18.200018, longitude: -66.309282, zipCode: "00794"},
	{latitude: 18.04645, longitude: -66.495142, zipCode: "00795"},
	{latitude: 18.342939, longitude: -64.925102, zipCode: "00802"},
	{latitude: 17.736627, longitude: -64.708215, zipCode: "00820"},
	{latitude: 18.338559, longitude: -64.73653, zipCode: "00830"},
	{latitude: 17.72471, longitude: -64.848522, zipCode: "00840"},
	{latitude: 17.726822, longitude: -64.792245, zipCode: "00850"},
	{latitude: 17.747525, longitude: -64.787439, zipCode: "00851"},
	{latitude: 18.466594, longitude: -66.107982, zipCode: "00901"},
	{latitude: 18.46446, longitude: -66.094995, zipCode: "00906"},
	{latitude: 18.452271, longitude: -66.087625, zipCode: "00907"},
	{latitude: 18.441246, longitude: -66.068766, zipCode: "00909"},
	{latitude: 18.453738, longitude: -66.055689, zipCode: "00911"},
	{latitude: 18.445067, longitude: -66.059942, zipCode: "00912"},
	{latitude: 18.464629, longitude: -66.04459, zipCode: "00913"},
	{latitude: 18.437179, longitude: -66.045571, zipCode: "00915"},
	{latitude: 18.420674, longitude: -66.050105, zipCode: "00917"},
	{latitude: 18.422249, longitude: -66.069081, zipCode: "00918"},
	{latitude: 18.422439, longitude: -66.09214, zipCode: "00920"},
	{latitude: 18.392088, longitude: -66.087109, zipCode: "00921"},
	{latitude: 18.410804, longitude: -66.037358, zipCode: "00923"},
	{latitude: 18.400422, longitude: -66.012567, zipCode: "00924"},
	{latitude: 18.400271, longitude: -66.05059, zipCode: "00925"},
	{latitude: 18.348601, longitude: -66.053929, zipCode: "00926"},
	{latitude: 18.388202, longitude: -66.070033, zipCode: "00927"},
	{latitude: 18.413, longitude: -66.119829, zipCode: "00934"},
	{latitude: 18.409879, longitude: -66.227441, zipCode: "00949"},
	{latitude: 18.467225, longitude: -66.237929, zipCode: "00950"},
	{latitude: 18.42805, longitude: -66.259772, zipCode: "00951"},
	{latitude: 18.428724, longitude: -66.188931, zipCode: "00952"},
	{latitude: 18.360729, longitude: -66.251527, zipCode: "00953"},
	{latitude: 18.321137, longitude: -66.170419, zipCode: "00956"},
	{latitude: 18.366147, longitude: -66.188962, zipCode: "00957"},
	{latitude: 18.385785, longitude: -66.155654, zipCode: "00959"},
	{latitude: 18.416918, longitude: -66.142693, zipCode: "00960"},
	{latitude: 18.409907, longitude: -66.163361, zipCode: "00961"},
	{latitude: 18.439988, longitude: -66.1355, zipCode: "00962"},
	{latitude: 18.430508, longitude: -66.110917, zipCode: "00965"},
	{latitude: 18.397875, longitude: -66.113935, zipCode: "00966"},
	{latitude: 18.406063, longitude: -66.101232, zipCode: "00968"},
	{latitude: 18.366226, longitude: -66.110047, zipCode: "00969"},
	{latitude: 18.318884, longitude: -66.11919, zipCode: "00971"},
	{latitude: 18.33618, longitude: -65.994101, zipCode: "00976"},
	{latitude: 18.438359, longitude: -65.999169, zipCode: "00979"},
	{latitude: 18.408744, longitude: -65.991273, zipCode: "00982"},
	{latitude: 18.41722, longitude: -65.981317, zipCode: "00983"},
	{latitude: 18.410774, longitude: -65.947927, zipCode: "00985"},
	{latitude: 18.343988, longitude: -65.943754, zipCode: "00987"},
	{latitude: 42.062368, longitude: -72.625754, zipCode: "01001"},
	{latitude: 42.377212, longitude: -72.464795, zipCode: "01002"},
	{latitude: 42.391883, longitude: -72.523584, zipCode: "01003"},
	{latitude: 42.418884, longitude: -72.112077, zipCode: "01005"},
	{latitude: 42.283935, longitude: -72.400409, zipCode: "01007"},
	{latitude: 42.192024, longitude: -72.964076, zipCode: "01008"},
	{latitude: 42.211386, longitude: -72.341809, zipCode: "01009"},
	{latitude: 42.128176, longitude: -72.205352, zipCode: "01010"},
	{latitude: 42.290128, longitude: -72.966516, zipCode: "01011"},
	{latitude: 42.375425, longitude: -72.858192, zipCode: "01012"},
	{latitude: 42.142009, longitude: -72.595496, zipCode: "01013"},
	{latitude: 42.172611, longitude: -72.56192, zipCode: "01020"},
	{latitude: 42.197187, longitude: -72.541872, zipCode: "01022"},
	{latitude: 42.470032, longitude: -72.925707, zipCode: "01026"},
	{latitude: 42.295008, longitude: -72.751877, zipCode: "01027"},
	{latitude: 42.062378, longitude: -72.498111, zipCode: "01028"},
	{latitude: 42.176271, longitude: -73.035292, zipCode: "01029"},
	{latitude: 42.072925, longitude: -72.686972, zipCode: "01030"},
	{latitude: 42.327809, longitude: -72.191968, zipCode: "01031"},
	{latitude: 42.457613, longitude: -72.81478, zipCode: "01032"},
	{latitude: 42.244949, longitude: -72.500143, zipCode: "01033"},
	{latitude: 42.093912, longitude: -72.961103, zipCode: "01034"},
	{latitude: 42.356491, longitude: -72.568632, zipCode: "01035"},
	{latitude: 42.071188, longitude: -72.417718, zipCode: "01036"},
	{latitude: 42.376036, longitude: -72.197364, zipCode: "01037"},
	{latitude: 42.379925, longitude: -72.604045, zipCode: "01038"},
	{latitude: 42.411304, longitude: -72.690951, zipCode: "01039"},
	{latitude: 42.211658, longitude: -72.642448, zipCode: "01040"},
	{latitude: 42.291259, longitude: -72.874803, zipCode: "01050"},
	{latitude: 42.356301, longitude: -72.713584, zipCode: "01053"},
	{latitude: 42.468898, longitude: -72.484579, zipCode: "01054"},
	{latitude: 42.183577, longitude: -72.457812, zipCode: "01056"},
	{latitude: 42.093596, longitude: -72.321494, zipCode: "01057"},
	{latitude: 42.321251, longitude: -72.630395, zipCode: "01060"},
	{latitude: 42.323033, longitude: -72.701789, zipCode: "01062"},
	{latitude: 42.319584, longitude: -72.639539, zipCode: "01063"},
	{latitude: 42.413039, longitude: -72.623639, zipCode: "01066"},
	{latitude: 42.353887, longitude: -72.060685, zipCode: "01068"},
	{latitude: 42.188862, longitude: -72.30648, zipCode: "01069"},
	{latitude: 42.519909, longitude: -72.925354, zipCode: "01070"},
	{latitude: 42.160064, longitude: -72.87384, zipCode: "01071"},
	{latitude: 42.456968, longitude: -72.417244, zipCode: "01072"},
	{latitude: 42.226949, longitude: -72.741588, zipCode: "01073"},
	{latitude: 42.380823, longitude: -72.103895, zipCode: "01074"},
	{latitude: 42.256532, longitude: -72.580248, zipCode: "01075"},
	{latitude: 42.05257, longitude: -72.777353, zipCode: "01077"},
	{latitude: 42.185745, longitude: -72.33246, zipCode: "01079"},
	{latitude: 42.185727, longitude: -72.361558, zipCode: "01080"},
	{latitude: 42.061313, longitude: -72.234411, zipCode: "01081"},
	{latitude: 42.299749, longitude: -72.279164, zipCode: "01082"},
	{latitude: 42.20684, longitude: -72.198619, zipCode: "01083"},
	{latitude: 42.361724, longitude: -72.874987, zipCode: "01084"},
	{latitude: 42.153503, longitude: -72.771602, zipCode: "01085"},
	{latitude: 42.3827, longitude: -72.640361, zipCode: "01088"},
	{latitude: 42.125451, longitude: -72.649734, zipCode: "01089"},
	{latitude: 42.191868, longitude: -72.235108, zipCode: "01092"},
	{latitude: 42.445258, longitude: -72.645058, zipCode: "01093"},
	{latitude: 42.369762, longitude: -72.139283, zipCode: "01094"},
	{latitude: 42.138527, longitude: -72.420703, zipCode: "01095"},
	{latitude: 42.410549, longitude: -72.765765, zipCode: "01096"},
	{latitude: 42.165868, longitude: -72.830985, zipCode: "01097"},
	{latitude: 42.397559, longitude: -72.943392, zipCode: "01098"},
	{latitude: 42.104418, longitude: -72.59008, zipCode: "01103"},
	{latitude: 42.132772, longitude: -72.567411, zipCode: "01104"},
	{latitude: 42.095246, longitude: -72.577603, zipCode: "01105"},
	{latitude: 42.045695, longitude: -72.563323, zipCode: "01106"},
	{latitude: 42.12106, longitude: -72.607068, zipCode: "01107"},
	{latitude: 42.082947, longitude: -72.55951, zipCode: "01108"},
	{latitude: 42.119674, longitude: -72.549726, zipCode: "01109"},
	{latitude: 42.094587, longitude: -72.525219, zipCode: "01118"},
	{latitude: 42.124978, longitude: -72.51121, zipCode: "01119"},
	{latitude: 42.092296, longitude: -72.489135, zipCode: "01128"},
	{latitude: 42.118671, longitude: -72.488203, zipCode: "01129"},
	{latitude: 42.101894, longitude: -72.590907, zipCode: "01144"},
	{latitude: 42.15186, longitude: -72.509131, zipCode: "01151"},
	{latitude: 42.454302, longitude: -73.282074, zipCode: "01201"},
	{latitude: 42.62556, longitude: -73.119828, zipCode: "01220"},
	{latitude: 42.062743, longitude: -73.317498, zipCode: "01222"},
	{latitude: 42.304286, longitude: -73.111494, zipCode: "01223"},
	{latitude: 42.503728, longitude: -73.201636, zipCode: "01224"},
	{latitude: 42.565107, longitude: -73.149142, zipCode: "01225"},
	{latitude: 42.479819, longitude: -73.153776, zipCode: "01226"},
	{latitude: 42.29008, longitude: -73.354218, zipCode: "01229"},
	{latitude: 42.173196, longitude: -73.32429, zipCode: "01230"},
	{latitude: 42.425677, longitude: -73.063028, zipCode: "01235"},
	{latitude: 42.274499, longitude: -73.386155, zipCode: "01236"},
	{latitude: 42.549804, longitude: -73.261144, zipCode: "01237"},
	{latitude: 42.300457, longitude: -73.231006, zipCode: "01238"},
	{latitude: 42.382693, longitude: -73.266632, zipCode: "01240"},
	{latitude: 42.333734, longitude: -73.249199, zipCode: "01242"},
	{latitude: 42.367907, longitude: -73.054094, zipCode: "01243"},
	{latitude: 42.117456, longitude: -73.278635, zipCode: "01244"},
	{latitude: 42.193307, longitude: -73.218499, zipCode: "01245"},
	{latitude: 42.695699, longitude: -73.093852, zipCode: "01247"},
	{latitude: 42.206683, longitude: -73.113214, zipCode: "01253"},
	{latitude: 42.382019, longitude: -73.365492, zipCode: "01254"},
	{latitude: 42.1029, longitude: -73.125991, zipCode: "01255"},
	{latitude: 42.594166, longitude: -73.02232, zipCode: "01256"},
	{latitude: 42.085244, longitude: -73.367483, zipCode: "01257"},
	{latitude: 42.09092, longitude: -73.470197, zipCode: "01258"},
	{latitude: 42.06926, longitude: -73.228949, zipCode: "01259"},
	{latitude: 42.261673, longitude: -73.27516, zipCode: "01260"},
	{latitude: 42.310055, longitude: -73.324468, zipCode: "01262"},
	{latitude: 42.234822, longitude: -73.197083, zipCode: "01264"},
	{latitude: 42.312354, longitude: -73.388044, zipCode: "01266"},
	{latitude: 42.671617, longitude: -73.246981, zipCode: "01267"},
	{latitude: 42.519837, longitude: -73.04969, zipCode: "01270"},
	{latitude: 42.626761, longitude: -72.60153, zipCode: "01301"},
	{latitude: 42.513947, longitude: -72.821517, zipCode: "01330"},
	{latitude: 42.570237, longitude: -72.182903, zipCode: "01331"},
	{latitude: 42.689307, longitude: -72.582289, zipCode: "01337"},
	{latitude: 42.575889, longitude: -72.80665, zipCode: "01338"},
	{latitude: 42.61045, longitude: -72.887735, zipCode: "01339"},
	{latitude: 42.68553, longitude: -72.731135, zipCode: "01340"},
	{latitude: 42.507177, longitude: -72.701525, zipCode: "01341"},
	{latitude: 42.543889, longitude: -72.609458, zipCode: "01342"},
	{latitude: 42.64773, longitude: -72.969767, zipCode: "01343"},
	{latitude: 42.613418, longitude: -72.426428, zipCode: "01344"},
	{latitude: 42.658496, longitude: -72.806057, zipCode: "01346"},
	{latitude: 42.563223, longitude: -72.509775, zipCode: "01347"},
	{latitude: 42.56746, longitude: -72.467542, zipCode: "01349"},
	{latitude: 42.727772, longitude: -72.985346, zipCode: "01350"},
	{latitude: 42.53988, longitude: -72.521689, zipCode: "01351"},
	{latitude: 42.624101, longitude: -72.508607, zipCode: "01354"},
	{latitude: 42.458551, longitude: -72.327191, zipCode: "01355"},
	{latitude: 42.677091, longitude: -72.453876, zipCode: "01360"},
	{latitude: 42.605437, longitude: -72.292074, zipCode: "01364"},
	{latitude: 42.491583, longitude: -72.180876, zipCode: "01366"},
	{latitude: 42.692882, longitude: -72.919244, zipCode: "01367"},
	{latitude: 42.676687, longitude: -72.184052, zipCode: "01368"},
	{latitude: 42.593376, longitude: -72.726928, zipCode: "01370"},
	{latitude: 42.475481, longitude: -72.615403, zipCode: "01373"},
	{latitude: 42.466691, longitude: -72.546751, zipCode: "01375"},
	{latitude: 42.591441, longitude: -72.544674, zipCode: "01376"},
	{latitude: 42.679971, longitude: -72.339882, zipCode: "01378"},
	{latitude: 42.527853, longitude: -72.398403, zipCode: "01379"},
	{latitude: 42.588496, longitude: -72.406298, zipCode: "01380"},
	{latitude: 42.584925, longitude: -71.816862, zipCode: "01420"},
	{latitude: 42.673052, longitude: -71.920335, zipCode: "01430"},
	{latitude: 42.676293, longitude: -71.832523, zipCode: "01431"},
	{latitude: 42.566588, longitude: -71.575126, zipCode: "01432"},
	{latitude: 42.53206, longitude: -71.61374, zipCode: "01434"},
	{latitude: 42.602059, longitude: -72.087015, zipCode: "01436"},
	{latitude: 42.561088, longitude: -72.036074, zipCode: "01438"},
	{latitude: 42.588337, longitude: -71.986069, zipCode: "01440"},
	{latitude: 42.611636, longitude: -71.564743, zipCode: "01450"},
	{latitude: 42.501908, longitude: -71.568201, zipCode: "01451"},
	{latitude: 42.495111, longitude: -72.002339, zipCode: "01452"},
	{latitude: 42.519967, longitude: -71.763319, zipCode: "01453"},
	{latitude: 42.535931, longitude: -71.490569, zipCode: "01460"},
	{latitude: 42.582607, longitude: -71.720464, zipCode: "01462"},
	{latitude: 42.670643, longitude: -71.602858, zipCode: "01463"},
	{latitude: 42.573654, longitude: -71.640986, zipCode: "01464"},
	{latitude: 42.542767, longitude: -72.068345, zipCode: "01468"},
	{latitude: 42.664794, longitude: -71.695055, zipCode: "01469"},
	{latitude: 42.5546, longitude: -71.90544, zipCode: "01473"},
	{latitude: 42.673143, longitude: -71.748378, zipCode: "01474"},
	{latitude: 42.66816, longitude: -72.055644, zipCode: "01475"},
	{latitude: 42.198867, longitude: -71.845722, zipCode: "01501"},
	{latitude: 42.384929, longitude: -71.633889, zipCode: "01503"},
	{latitude: 42.039991, longitude: -71.532406, zipCode: "01504"},
	{latitude: 42.355049, longitude: -71.716157, zipCode: "01505"},
	{latitude: 42.180807, longitude: -72.107888, zipCode: "01506"},
	{latitude: 42.133935, longitude: -71.968025, zipCode: "01507"},
	{latitude: 42.411887, longitude: -71.690005, zipCode: "01510"},
	{latitude: 42.209682, longitude: -72.040178, zipCode: "01515"},
	{latitude: 42.053072, longitude: -71.752241, zipCode: "01516"},
	{latitude: 42.108325, longitude: -72.113692, zipCode: "01518"},
	{latitude: 42.203404, longitude: -71.679739, zipCode: "01519"},
	{latitude: 42.334177, longitude: -71.854277, zipCode: "01520"},
	{latitude: 42.046783, longitude: -72.180212, zipCode: "01521"},
	{latitude: 42.375634, longitude: -71.867382, zipCode: "01522"},
	{latitude: 42.483569, longitude: -71.675585, zipCode: "01523"},
	{latitude: 42.246029, longitude: -71.917566, zipCode: "01524"},
	{latitude: 42.098995, longitude: -71.642964, zipCode: "01525"},
	{latitude: 42.192246, longitude: -71.777649, zipCode: "01527"},
	{latitude: 42.036642, longitude: -71.578779, zipCode: "01529"},
	{latitude: 42.326183, longitude: -72.133431, zipCode: "01531"},
	{latitude: 42.323342, longitude: -71.646236, zipCode: "01532"},
	{latitude: 42.138896, longitude: -71.645003, zipCode: "01534"},
	{latitude: 42.267564, longitude: -72.066876, zipCode: "01535"},
	{latitude: 42.231309, longitude: -71.692642, zipCode: "01536"},
	{latitude: 42.158966, longitude: -71.897051, zipCode: "01537"},
	{latitude: 42.113727, longitude: -71.86085, zipCode: "01540"},
	{latitude: 42.454125, longitude: -71.87754, zipCode: "01541"},
	{latitude: 42.204251, longitude: -71.908907, zipCode: "01542"},
	{latitude: 42.388285, longitude: -71.969865, zipCode: "01543"},
	{latitude: 42.284767, longitude: -71.714228, zipCode: "01545"},
	{latitude: 42.059737, longitude: -72.033908, zipCode: "01550"},
	{latitude: 42.175395, longitude: -71.674223, zipCode: "01560"},
	{latitude: 42.445825, longitude: -71.688484, zipCode: "01561"},
	{latitude: 42.247211, longitude: -71.991867, zipCode: "01562"},
	{latitude: 42.447924, longitude: -71.776045, zipCode: "01564"},
	{latitude: 42.103214, longitude: -72.079545, zipCode: "01566"},
	{latitude: 42.177529, longitude: -71.606322, zipCode: "01568"},
	{latitude: 42.055921, longitude: -71.631128, zipCode: "01569"},
	{latitude: 42.049253, longitude: -71.846219, zipCode: "01570"},
	{latitude: 42.053778, longitude: -71.935075, zipCode: "01571"},
	{latitude: 42.268426, longitude: -71.613309, zipCode: "01581"},
	{latitude: 42.375357, longitude: -71.789363, zipCode: "01583"},
	{latitude: 42.236908, longitude: -72.162221, zipCode: "01585"},
	{latitude: 42.116108, longitude: -71.664614, zipCode: "01588"},
	{latitude: 42.132051, longitude: -71.750318, zipCode: "01590"},
	{latitude: 42.269189, longitude: -71.850728, zipCode: "01602"},
	{latitude: 42.243826, longitude: -71.843569, zipCode: "01603"},
	{latitude: 42.253485, longitude: -71.767932, zipCode: "01604"},
	{latitude: 42.289683, longitude: -71.787793, zipCode: "01605"},
	{latitude: 42.313887, longitude: -71.794185, zipCode: "01606"},
	{latitude: 42.225974, longitude: -71.788894, zipCode: "01607"},
	{latitude: 42.26209, longitude: -71.801428, zipCode: "01608"},
	{latitude: 42.285114, longitude: -71.829987, zipCode: "01609"},
	{latitude: 42.247049, longitude: -71.808366, zipCode: "01610"},
	{latitude: 42.240977, longitude: -71.877035, zipCode: "01611"},
	{latitude: 42.296798, longitude: -71.928991, zipCode: "01612"},
	{latitude: 42.319587, longitude: -71.4428, zipCode: "01701"},
	{latitude: 42.282379, longitude: -71.436623, zipCode: "01702"},
	{latitude: 42.522816, longitude: -71.430271, zipCode: "01718"},
	{latitude: 42.485985, longitude: -71.520985, zipCode: "01719"},
	{latitude: 42.483953, longitude: -71.438495, zipCode: "01720"},
	{latitude: 42.257755, longitude: -71.473526, zipCode: "01721"},
	{latitude: 42.499332, longitude: -71.281901, zipCode: "01730"},
	{latitude: 42.464648, longitude: -71.28483, zipCode: "01731"},
	{latitude: 42.439933, longitude: -71.601885, zipCode: "01740"},
	{latitude: 42.536807, longitude: -71.361324, zipCode: "01741"},
	{latitude: 42.462911, longitude: -71.364496, zipCode: "01742"},
	{latitude: 42.292114, longitude: -71.499197, zipCode: "01745"},
	{latitude: 42.195951, longitude: -71.45343, zipCode: "01746"},
	{latitude: 42.123007, longitude: -71.531404, zipCode: "01747"},
	{latitude: 42.224096, longitude: -71.540489, zipCode: "01748"},
	{latitude: 42.389071, longitude: -71.545864, zipCode: "01749"},
	{latitude: 42.349617, longitude: -71.547214, zipCode: "01752"},
	{latitude: 42.425955, longitude: -71.456256, zipCode: "01754"},
	{latitude: 42.09387, longitude: -71.544519, zipCode: "01756"},
	{latitude: 42.158692, longitude: -71.521419, zipCode: "01757"},
	{latitude: 42.284822, longitude: -71.348811, zipCode: "01760"},
	{latitude: 42.231947, longitude: -71.372963, zipCode: "01770"},
	{latitude: 42.302877, longitude: -71.530828, zipCode: "01772"},
	{latitude: 42.425506, longitude: -71.310812, zipCode: "01773"},
	{latitude: 42.429688, longitude: -71.512514, zipCode: "01775"},
	{latitude: 42.383367, longitude: -71.42107, zipCode: "01776"},
	{latitude: 42.356275, longitude: -71.361942, zipCode: "01778"},
	{latitude: 42.488703, longitude: -71.154383, zipCode: "01801"},
	{latitude: 42.503227, longitude: -71.201713, zipCode: "01803"},
	{latitude: 42.648044, longitude: -71.161751, zipCode: "01810"},
	{latitude: 42.54933, longitude: -71.251725, zipCode: "01821"},
	{latitude: 42.59079, longitude: -71.355182, zipCode: "01824"},
	{latitude: 42.679723, longitude: -71.30068, zipCode: "01826"},
	{latitude: 42.676149, longitude: -71.499772, zipCode: "01827"},
	{latitude: 42.796313, longitude: -71.053436, zipCode: "01830"},
	{latitude: 42.791114, longitude: -71.132859, zipCode: "01832"},
	{latitude: 42.726226, longitude: -70.983318, zipCode: "01833"},
	{latitude: 42.7539, longitude: -71.015935, zipCode: "01834"},
	{latitude: 42.751327, longitude: -71.094421, zipCode: "01835"},
	{latitude: 42.706763, longitude: -71.160403, zipCode: "01840"},
	{latitude: 42.715079, longitude: -71.1483, zipCode: "01841"},
	{latitude: 42.689988, longitude: -71.16039, zipCode: "01843"},
	{latitude: 42.742641, longitude: -71.178651, zipCode: "01844"},
	{latitude: 42.673909, longitude: -71.091334, zipCode: "01845"},
	{latitude: 42.656045, longitude: -71.303309, zipCode: "01850"},
	{latitude: 42.627999, longitude: -71.336757, zipCode: "01851"},
	{latitude: 42.632978, longitude: -71.295616, zipCode: "01852"},
	{latitude: 42.649481, longitude: -71.348229, zipCode: "01854"},
	{latitude: 42.838603, longitude: -71.011997, zipCode: "01860"},
	{latitude: 42.578543, longitude: -71.295592, zipCode: "01862"},
	{latitude: 42.630484, longitude: -71.397895, zipCode: "01863"},
	{latitude: 42.578401, longitude: -71.084448, zipCode: "01864"},
	{latitude: 42.535183, longitude: -71.105423, zipCode: "01867"},
	{latitude: 42.611893, longitude: -71.227226, zipCode: "01876"},
	{latitude: 42.667762, longitude: -71.42882, zipCode: "01879"},
	{latitude: 42.504361, longitude: -71.064061, zipCode: "01880"},
	{latitude: 42.585541, longitude: -71.44025, zipCode: "01886"},
	{latitude: 42.564647, longitude: -71.164516, zipCode: "01887"},
	{latitude: 42.452752, longitude: -71.144319, zipCode: "01890"},
	{latitude: 42.460419, longitude: -70.946379, zipCode: "01901"},
	{latitude: 42.471039, longitude: -70.941535, zipCode: "01902"},
	{latitude: 42.491447, longitude: -70.971441, zipCode: "01904"},
	{latitude: 42.462378, longitude: -70.972343, zipCode: "01905"},
	{latitude: 42.469675, longitude: -71.012785, zipCode: "01906"},
	{latitude: 42.474145, longitude: -70.906597, zipCode: "01907"},
	{latitude: 42.428234, longitude: -70.926071, zipCode: "01908"},
	{latitude: 42.851277, longitude: -70.955792, zipCode: "01913"},
	{latitude: 42.570442, longitude: -70.867693, zipCode: "01915"},
	{latitude: 42.683108, longitude: -71.027188, zipCode: "01921"},
	{latitude: 42.757313, longitude: -70.914241, zipCode: "01922"},
	{latitude: 42.574174, longitude: -70.950516, zipCode: "01923"},
	{latitude: 42.640451, longitude: -70.771165, zipCode: "01929"},
	{latitude: 42.620291, longitude: -70.677956, zipCode: "01930"},
	{latitude: 42.585723, longitude: -70.984051, zipCode: "01937"},
	{latitude: 42.686464, longitude: -70.839594, zipCode: "01938"},
	{latitude: 42.534146, longitude: -71.038333, zipCode: "01940"},
	{latitude: 42.576636, longitude: -70.767154, zipCode: "01944"},
	{latitude: 42.504317, longitude: -70.851542, zipCode: "01945"},
	{latitude: 42.610322, longitude: -71.006295, zipCode: "01949"},
	{latitude: 42.812358, longitude: -70.891095, zipCode: "01950"},
	{latitude: 42.773351, longitude: -70.850211, zipCode: "01951"},
	{latitude: 42.844794, longitude: -70.841499, zipCode: "01952"},
	{latitude: 42.53431, longitude: -70.969443, zipCode: "01960"},
	{latitude: 42.564101, longitude: -70.824723, zipCode: "01965"},
	{latitude: 42.640699, longitude: -70.62025, zipCode: "01966"},
	{latitude: 42.721495, longitude: -70.886637, zipCode: "01969"},
	{latitude: 42.529248, longitude: -70.868017, zipCode: "01970"},
	{latitude: 42.626223, longitude: -70.85723, zipCode: "01982"},
	{latitude: 42.641387, longitude: -70.943383, zipCode: "01983"},
	{latitude: 42.600668, longitude: -70.88326, zipCode: "01984"},
	{latitude: 42.799565, longitude: -70.964428, zipCode: "01985"},
	{latitude: 42.076682, longitude: -71.47449, zipCode: "02019"},
	{latitude: 42.175737, longitude: -71.125385, zipCode: "02021"},
	{latitude: 42.236444, longitude: -70.814309, zipCode: "02025"},
	{latitude: 42.246872, longitude: -71.179462, zipCode: "02026"},
	{latitude: 42.23416, longitude: -71.291167, zipCode: "02030"},
	{latitude: 42.156882, longitude: -71.216187, zipCode: "02032"},
	{latitude: 42.061338, longitude: -71.245802, zipCode: "02035"},
	{latitude: 42.084858, longitude: -71.410571, zipCode: "02038"},
	{latitude: 42.216098, longitude: -70.88127, zipCode: "02043"},
	{latitude: 42.28587, longitude: -70.879517, zipCode: "02045"},
	{latitude: 42.145534, longitude: -70.69505, zipCode: "02047"},
	{latitude: 42.017302, longitude: -71.21642, zipCode: "02048"},
	{latitude: 42.115139, longitude: -70.710331, zipCode: "02050"},
	{latitude: 42.184599, longitude: -71.305307, zipCode: "02052"},
	{latitude: 42.156203, longitude: -71.430345, zipCode: "02053"},
	{latitude: 42.173103, longitude: -71.363756, zipCode: "02054"},
	{latitude: 42.116244, longitude: -71.329274, zipCode: "02056"},
	{latitude: 42.164569, longitude: -70.818844, zipCode: "02061"},
	{latitude: 42.187389, longitude: -71.192477, zipCode: "02062"},
	{latitude: 42.202273, longitude: -70.758184, zipCode: "02066"},
	{latitude: 42.1076, longitude: -71.181828, zipCode: "02067"},
	{latitude: 42.106425, longitude: -71.268951, zipCode: "02071"},
	{latitude: 42.119006, longitude: -71.10365, zipCode: "02072"},
	{latitude: 42.147403, longitude: -71.257972, zipCode: "02081"},
	{latitude: 42.219645, longitude: -71.216768, zipCode: "02090"},
	{latitude: 42.052671, longitude: -71.356858, zipCode: "02093"},
	{latitude: 42.355097, longitude: -71.065737, zipCode: "02108"},
	{latitude: 42.366919, longitude: -71.050416, zipCode: "02109"},
	{latitude: 42.358254, longitude: -71.051927, zipCode: "02110"},
	{latitude: 42.35068, longitude: -71.060527, zipCode: "02111"},
	{latitude: 42.365264, longitude: -71.055222, zipCode: "02113"},
	{latitude: 42.363651, longitude: -71.066888, zipCode: "02114"},
	{latitude: 42.339422, longitude: -71.094531, zipCode: "02115"},
	{latitude: 42.350979, longitude: -71.076591, zipCode: "02116"},
	{latitude: 42.33786, longitude: -71.069805, zipCode: "02118"},
	{latitude: 42.324066, longitude: -71.085188, zipCode: "02119"},
	{latitude: 42.332134, longitude: -71.096606, zipCode: "02120"},
	{latitude: 42.305403, longitude: -71.086971, zipCode: "02121"},
	{latitude: 42.295088, longitude: -71.052533, zipCode: "02122"},
	{latitude: 42.285922, longitude: -71.075046, zipCode: "02124"},
	{latitude: 42.315682, longitude: -71.055555, zipCode: "02125"},
	{latitude: 42.277436, longitude: -71.089968, zipCode: "02126"},
	{latitude: 42.335301, longitude: -71.038216, zipCode: "02127"},
	{latitude: 42.361519, longitude: -71.007393, zipCode: "02128"},
	{latitude: 42.379657, longitude: -71.061487, zipCode: "02129"},
	{latitude: 42.308175, longitude: -71.113471, zipCode: "02130"},
	{latitude: 42.282323, longitude: -71.124746, zipCode: "02131"},
	{latitude: 42.279082, longitude: -71.160449, zipCode: "02132"},
	{latitude: 42.358352, longitude: -71.063858, zipCode: "02133"},
	{latitude: 42.358786, longitude: -71.129581, zipCode: "02134"},
	{latitude: 42.349688, longitude: -71.153964, zipCode: "02135"},
	{latitude: 42.254749, longitude: -71.127072, zipCode: "02136"},
	{latitude: 42.379637, longitude: -71.135152, zipCode: "02138"},
	{latitude: 42.362391, longitude: -71.102336, zipCode: "02139"},
	{latitude: 42.392157, longitude: -71.133996, zipCode: "02140"},
	{latitude: 42.3703, longitude: -71.08256, zipCode: "02141"},
	{latitude: 42.362505, longitude: -71.08096, zipCode: "02142"},
	{latitude: 42.3814, longitude: -71.09672, zipCode: "02143"},
	{latitude: 42.400077, longitude: -71.123088, zipCode: "02144"},
	{latitude: 42.391577, longitude: -71.08991, zipCode: "02145"},
	{latitude: 42.42938, longitude: -71.058706, zipCode: "02148"},
	{latitude: 42.405938, longitude: -71.054649, zipCode: "02149"},
	{latitude: 42.396824, longitude: -71.031348, zipCode: "02150"},
	{latitude: 42.41829, longitude: -71.001251, zipCode: "02151"},
	{latitude: 42.377382, longitude: -70.977026, zipCode: "02152"},
	{latitude: 42.42384, longitude: -71.107673, zipCode: "02155"},
	{latitude: 42.367226, longitude: -71.125092, zipCode: "02163"},
	{latitude: 42.240016, longitude: -71.015205, zipCode: "02169"},
	{latitude: 42.266415, longitude: -71.015576, zipCode: "02170"},
	{latitude: 42.29147, longitude: -71.025822, zipCode: "02171"},
	{latitude: 42.455723, longitude: -71.059019, zipCode: "02176"},
	{latitude: 42.474208, longitude: -71.097665, zipCode: "02180"},
	{latitude: 42.206188, longitude: -71.00232, zipCode: "02184"},
	{latitude: 42.241559, longitude: -71.082437, zipCode: "02186"},
	{latitude: 42.204662, longitude: -70.957633, zipCode: "02188"},
	{latitude: 42.211968, longitude: -70.929639, zipCode: "02189"},
	{latitude: 42.166731, longitude: -70.952363, zipCode: "02190"},
	{latitude: 42.245077, longitude: -70.946528, zipCode: "02191"},
	{latitude: 42.347476, longitude: -71.082033, zipCode: "02199"},
	{latitude: 42.361445, longitude: -71.059453, zipCode: "02203"},
	{latitude: 42.349403, longitude: -71.055621, zipCode: "02205"},
	{latitude: 42.348036, longitude: -71.039283, zipCode: "02210"},
	{latitude: 42.347635, longitude: -71.103082, zipCode: "02215"},
	{latitude: 42.078371, longitude: -71.042304, zipCode: "02301"},
	{latitude: 42.088907, longitude: -70.998375, zipCode: "02302"},
	{latitude: 42.132461, longitude: -71.054013, zipCode: "02322"},
	{latitude: 41.972376, longitude: -70.978773, zipCode: "02324"},
	{latitude: 41.990101, longitude: -70.962408, zipCode: "02325"},
	{latitude: 41.875179, longitude: -70.746715, zipCode: "02330"},
	{latitude: 42.044407, longitude: -70.70606, zipCode: "02332"},
	{latitude: 42.037116, longitude: -70.942178, zipCode: "02333"},
	{latitude: 42.002634, longitude: -70.863706, zipCode: "02338"},
	{latitude: 42.122956, longitude: -70.85631, zipCode: "02339"},
	{latitude: 42.05024, longitude: -70.867359, zipCode: "02341"},
	{latitude: 42.140346, longitude: -70.997861, zipCode: "02343"},
	{latitude: 41.878004, longitude: -70.869267, zipCode: "02346"},
	{latitude: 41.834384, longitude: -70.956826, zipCode: "02347"},
	{latitude: 42.021013, longitude: -70.845791, zipCode: "02350"},
	{latitude: 42.115764, longitude: -70.956597, zipCode: "02351"},
	{latitude: 42.060539, longitude: -71.119671, zipCode: "02356"},
	{latitude: 42.063974, longitude: -71.077368, zipCode: "02357"},
	{latitude: 42.067804, longitude: -70.805713, zipCode: "02359"},
	{latitude: 41.882062, longitude: -70.631289, zipCode: "02360"},
	{latitude: 41.987196, longitude: -70.741942, zipCode: "02364"},
	{latitude: 41.848753, longitude: -70.661176, zipCode: "02366"},
	{latitude: 41.9591, longitude: -70.802753, zipCode: "02367"},
	{latitude: 42.178993, longitude: -71.052654, zipCode: "02368"},
	{latitude: 42.129251, longitude: -70.908736, zipCode: "02370"},
	{latitude: 42.00569, longitude: -71.078041, zipCode: "02375"},
	{latitude: 42.021617, longitude: -71.026717, zipCode: "02379"},
	{latitude: 42.078974, longitude: -70.93939, zipCode: "02382"},
	{latitude: 42.459061, longitude: -71.217728, zipCode: "02420"},
	{latitude: 42.438547, longitude: -71.239573, zipCode: "02421"},
	{latitude: 42.325483, longitude: -71.135045, zipCode: "02445"},
	{latitude: 42.343499, longitude: -71.122244, zipCode: "02446"},
	{latitude: 42.40153, longitude: -71.252997, zipCode: "02451"},
	{latitude: 42.395609, longitude: -71.219947, zipCode: "02452"},
	{latitude: 42.368685, longitude: -71.241103, zipCode: "02453"},
	{latitude: 42.298309, longitude: -71.27302, zipCode: "02457"},
	{latitude: 42.353585, longitude: -71.188192, zipCode: "02458"},
	{latitude: 42.314779, longitude: -71.192017, zipCode: "02459"},
	{latitude: 42.351824, longitude: -71.20849, zipCode: "02460"},
	{latitude: 42.317362, longitude: -71.206508, zipCode: "02461"},
	{latitude: 42.331675, longitude: -71.255947, zipCode: "02462"},
	{latitude: 42.312975, longitude: -71.218882, zipCode: "02464"},
	{latitude: 42.349533, longitude: -71.227339, zipCode: "02465"},
	{latitude: 42.345514, longitude: -71.247661, zipCode: "02466"},
	{latitude: 42.314321, longitude: -71.152778, zipCode: "02467"},
	{latitude: 42.329175, longitude: -71.231583, zipCode: "02468"},
	{latitude: 42.369451, longitude: -71.177925, zipCode: "02472"},
	{latitude: 42.420949, longitude: -71.15637, zipCode: "02474"},
	{latitude: 42.41648, longitude: -71.173487, zipCode: "02476"},
	{latitude: 42.395317, longitude: -71.180284, zipCode: "02478"},
	{latitude: 42.310514, longitude: -71.277769, zipCode: "02481"},
	{latitude: 42.293274, longitude: -71.296442, zipCode: "02482"},
	{latitude: 42.276483, longitude: -71.243714, zipCode: "02492"},
	{latitude: 42.360502, longitude: -71.303433, zipCode: "02493"},
	{latitude: 42.299563, longitude: -71.231215, zipCode: "02494"},
	{latitude: 41.749522, longitude: -70.594928, zipCode: "02532"},
	{latitude: 41.669004, longitude: -70.61528, zipCode: "02534"},
	{latitude: 41.337961, longitude: -70.761674, zipCode: "02535"},
	{latitude: 41.596295, longitude: -70.567471, zipCode: "02536"},
	{latitude: 41.728421, longitude: -70.43581, zipCode: "02537"},
	{latitude: 41.777378, longitude: -70.642447, zipCode: "02538"},
	{latitude: 41.377655, longitude: -70.52208, zipCode: "02539"},
	{latitude: 41.569466, longitude: -70.627202, zipCode: "02540"},
	{latitude: 41.707839, longitude: -70.54552, zipCode: "02542"},
	{latitude: 41.472422, longitude: -70.785049, zipCode: "02543"},
	{latitude: 41.713138, longitude: -70.622283, zipCode: "02553"},
	{latitude: 41.290114, longitude: -70.096294, zipCode: "02554"},
	{latitude: 41.639447, longitude: -70.624515, zipCode: "02556"},
	{latitude: 41.446403, longitude: -70.561761, zipCode: "02557"},
	{latitude: 41.746275, longitude: -70.651689, zipCode: "02558"},
	{latitude: 41.690243, longitude: -70.616283, zipCode: "02559"},
	{latitude: 41.767811, longitude: -70.532854, zipCode: "02561"},
	{latitude: 41.787984, longitude: -70.527592, zipCode: "02562"},
	{latitude: 41.751133, longitude: -70.502518, zipCode: "02563"},
	{latitude: 41.271898, longitude: -69.984545, zipCode: "02564"},
	{latitude: 41.449404, longitude: -70.61636, zipCode: "02568"},
	{latitude: 41.75143, longitude: -70.699352, zipCode: "02571"},
	{latitude: 41.394406, longitude: -70.656447, zipCode: "02575"},
	{latitude: 41.772221, longitude: -70.762948, zipCode: "02576"},
	{latitude: 41.257578, longitude: -70.005294, zipCode: "02584"},
	{latitude: 41.661161, longitude: -70.293197, zipCode: "02601"},
	{latitude: 41.706756, longitude: -70.312489, zipCode: "02630"},
	{latitude: 41.747513, longitude: -70.066223, zipCode: "02631"},
	{latitude: 41.659097, longitude: -70.346877, zipCode: "02632"},
	{latitude: 41.687361, longitude: -69.98016, zipCode: "02633"},
	{latitude: 41.624054, longitude: -70.439397, zipCode: "02635"},
	{latitude: 41.705726, longitude: -70.270928, zipCode: "02637"},
	{latitude: 41.730245, longitude: -70.197392, zipCode: "02638"},
	{latitude: 41.668067, longitude: -70.136979, zipCode: "02639"},
	{latitude: 41.753951, longitude: -70.155393, zipCode: "02641"},
	{latitude: 41.8416, longitude: -69.974973, zipCode: "02642"},
	{latitude: 41.75354, longitude: -69.934661, zipCode: "02643"},
	{latitude: 41.683488, longitude: -70.510786, zipCode: "02644"},
	{latitude: 41.701286, longitude: -70.061663, zipCode: "02645"},
	{latitude: 41.670596, longitude: -70.071449, zipCode: "02646"},
	{latitude: 41.630127, longitude: -70.310144, zipCode: "02647"},
	{latitude: 41.670375, longitude: -70.412035, zipCode: "02648"},
	{latitude: 41.617623, longitude: -70.492713, zipCode: "02649"},
	{latitude: 41.701947, longitude: -69.961453, zipCode: "02650"},
	{latitude: 42.049534, longitude: -70.094987, zipCode: "02652"},
	{latitude: 41.769125, longitude: -69.97358, zipCode: "02653"},
	{latitude: 41.627984, longitude: -70.392013, zipCode: "02655"},
	{latitude: 42.059829, longitude: -70.200407, zipCode: "02657"},
	{latitude: 41.681474, longitude: -70.023529, zipCode: "02659"},
	{latitude: 41.708526, longitude: -70.156582, zipCode: "02660"},
	{latitude: 41.89754, longitude: -69.969907, zipCode: "02663"},
	{latitude: 41.674776, longitude: -70.194279, zipCode: "02664"},
	{latitude: 41.985387, longitude: -70.037739, zipCode: "02666"},
	{latitude: 41.923389, longitude: -70.021044, zipCode: "02667"},
	{latitude: 41.714328, longitude: -70.356, zipCode: "02668"},
	{latitude: 41.660103, longitude: -70.170681, zipCode: "02670"},
	{latitude: 41.670336, longitude: -70.111068, zipCode: "02671"},
	{latitude: 41.6357, longitude: -70.313634, zipCode: "02672"},
	{latitude: 41.652948, longitude: -70.247383, zipCode: "02673"},
	{latitude: 41.70395, longitude: -70.231552, zipCode: "02675"},
	{latitude: 41.785113, longitude: -71.059047, zipCode: "02702"},
	{latitude: 41.931653, longitude: -71.294503, zipCode: "02703"},
	{latitude: 41.427083, longitude: -70.885662, zipCode: "02713"},
	{latitude: 41.805594, longitude: -71.157707, zipCode: "02715"},
	{latitude: 41.758629, longitude: -70.987625, zipCode: "02717"},
	{latitude: 41.862486, longitude: -71.01169, zipCode: "02718"},
	{latitude: 41.633667, longitude: -70.871362, zipCode: "02719"},
	{latitude: 41.727137, longitude: -71.109858, zipCode: "02720"},
	{latitude: 41.674429, longitude: -71.148532, zipCode: "02721"},
	{latitude: 41.692208, longitude: -71.132132, zipCode: "02723"},
	{latitude: 41.683068, longitude: -71.177888, zipCode: "02724"},
	{latitude: 41.720043, longitude: -71.188272, zipCode: "02725"},
	{latitude: 41.7597, longitude: -71.144592, zipCode: "02726"},
	{latitude: 41.706162, longitude: -70.750164, zipCode: "02738"},
	{latitude: 41.665644, longitude: -70.815876, zipCode: "02739"},
	{latitude: 41.638171, longitude: -70.938398, zipCode: "02740"},
	{latitude: 41.709599, longitude: -70.898111, zipCode: "02743"},
	{latitude: 41.606252, longitude: -70.913632, zipCode: "02744"},
	{latitude: 41.702696, longitude: -70.947057, zipCode: "02745"},
	{latitude: 41.663913, longitude: -70.94757, zipCode: "02746"},
	{latitude: 41.658, longitude: -71.01346, zipCode: "02747"},
	{latitude: 41.553084, longitude: -70.971453, zipCode: "02748"},
	{latitude: 41.972567, longitude: -71.334375, zipCode: "02760"},
	{latitude: 42.012838, longitude: -71.336188, zipCode: "02762"},
	{latitude: 41.966866, longitude: -71.308574, zipCode: "02763"},
	{latitude: 41.851929, longitude: -71.153598, zipCode: "02764"},
	{latitude: 41.965341, longitude: -71.18178, zipCode: "02766"},
	{latitude: 41.940996, longitude: -71.048499, zipCode: "02767"},
	{latitude: 41.846878, longitude: -71.24487, zipCode: "02769"},
	{latitude: 41.760864, longitude: -70.838956, zipCode: "02770"},
	{latitude: 41.839798, longitude: -71.318047, zipCode: "02771"},
	{latitude: 41.758163, longitude: -71.214181, zipCode: "02777"},
	{latitude: 41.838061, longitude: -71.077615, zipCode: "02779"},
	{latitude: 41.909112, longitude: -71.118346, zipCode: "02780"},
	{latitude: 41.599176, longitude: -71.082371, zipCode: "02790"},
	{latitude: 41.530554, longitude: -71.084996, zipCode: "02791"},
	{latitude: 41.95213, longitude: -71.456233, zipCode: "02802"},
	{latitude: 41.433033, longitude: -71.770061, zipCode: "02804"},
	{latitude: 41.733037, longitude: -71.319676, zipCode: "02806"},
	{latitude: 41.176783, longitude: -71.577054, zipCode: "02807"},
	{latitude: 41.410654, longitude: -71.73851, zipCode: "02808"},
	{latitude: 41.67573, longitude: -71.273331, zipCode: "02809"},
	{latitude: 41.477712, longitude: -71.658167, zipCode: "02812"},
	{latitude: 41.394216, longitude: -71.669822, zipCode: "02813"},
	{latitude: 41.895784, longitude: -71.705238, zipCode: "02814"},
	{latitude: 41.778591, longitude: -71.66239, zipCode: "02815"},
	{latitude: 41.696499, longitude: -71.622662, zipCode: "02816"},
	{latitude: 41.628605, longitude: -71.658874, zipCode: "02817"},
	{latitude: 41.642919, longitude: -71.485719, zipCode: "02818"},
	{latitude: 41.575313, longitude: -71.587124, zipCode: "02822"},
	{latitude: 41.733362, longitude: -71.543668, zipCode: "02823"},
	{latitude: 41.999441, longitude: -71.562125, zipCode: "02824"},
	{latitude: 41.782067, longitude: -71.726833, zipCode: "02825"},
	{latitude: 41.987043, longitude: -71.648018, zipCode: "02826"},
	{latitude: 41.698748, longitude: -71.739118, zipCode: "02827"},
	{latitude: 41.877692, longitude: -71.560911, zipCode: "02828"},
	{latitude: 41.893373, longitude: -71.589036, zipCode: "02829"},
	{latitude: 41.975622, longitude: -71.656388, zipCode: "02830"},
	{latitude: 41.746418, longitude: -71.584915, zipCode: "02831"},
	{latitude: 41.506767, longitude: -71.728006, zipCode: "02832"},
	{latitude: 41.485769, longitude: -71.785311, zipCode: "02833"},
	{latitude: 41.510088, longitude: -71.378366, zipCode: "02835"},
	{latitude: 41.454039, longitude: -71.620233, zipCode: "02836"},
	{latitude: 41.518769, longitude: -71.167247, zipCode: "02837"},
	{latitude: 41.965413, longitude: -71.479359, zipCode: "02838"},
	{latitude: 41.945128, longitude: -71.642556, zipCode: "02839"},
	{latitude: 41.476681, longitude: -71.319627, zipCode: "02840"},
	{latitude: 41.507459, longitude: -71.330024, zipCode: "02841"},
	{latitude: 41.517086, longitude: -71.273419, zipCode: "02842"},
	{latitude: 41.588565, longitude: -71.459811, zipCode: "02852"},
	{latitude: 41.822761, longitude: -71.633605, zipCode: "02857"},
	{latitude: 41.964154, longitude: -71.650109, zipCode: "02858"},
	{latitude: 41.959073, longitude: -71.757323, zipCode: "02859"},
	{latitude: 41.870562, longitude: -71.388681, zipCode: "02860"},
	{latitude: 41.878603, longitude: -71.353131, zipCode: "02861"},
	{latitude: 41.890064, longitude: -71.393481, zipCode: "02863"},
	{latitude: 41.96746, longitude: -71.420572, zipCode: "02864"},
	{latitude: 41.915455, longitude: -71.449815, zipCode: "02865"},
	{latitude: 41.586609, longitude: -71.259211, zipCode: "02871"},
	{latitude: 41.596965, longitude: -71.320932, zipCode: "02872"},
	{latitude: 41.533129, longitude: -71.780545, zipCode: "02873"},
	{latitude: 41.509056, longitude: -71.474417, zipCode: "02874"},
	{latitude: 41.45937, longitude: -71.637829, zipCode: "02875"},
	{latitude: 41.997213, longitude: -71.584405, zipCode: "02876"},
	{latitude: 41.606983, longitude: -71.182634, zipCode: "02878"},
	{latitude: 41.428836, longitude: -71.538376, zipCode: "02879"},
	{latitude: 41.482429, longitude: -71.52651, zipCode: "02881"},
	{latitude: 41.417088, longitude: -71.47227, zipCode: "02882"},
	{latitude: 41.726404, longitude: -71.259027, zipCode: "02885"},
	{latitude: 41.701615, longitude: -71.455217, zipCode: "02886"},
	{latitude: 41.748233, longitude: -71.407942, zipCode: "02888"},
	{latitude: 41.701286, longitude: -71.377971, zipCode: "02889"},
	{latitude: 41.357774, longitude: -71.789616, zipCode: "02891"},
	{latitude: 41.499451, longitude: -71.601574, zipCode: "02892"},
	{latitude: 41.679601, longitude: -71.521114, zipCode: "02893"},
	{latitude: 41.44425, longitude: -71.699304, zipCode: "02894"},
	{latitude: 42.001708, longitude: -71.499947, zipCode: "02895"},
	{latitude: 41.975186, longitude: -71.544069, zipCode: "02896"},
	{latitude: 41.51764, longitude: -71.668521, zipCode: "02898"},
	{latitude: 41.819233, longitude: -71.410245, zipCode: "02903"},
	{latitude: 41.858334, longitude: -71.436294, zipCode: "02904"},
	{latitude: 41.785273, longitude: -71.396227, zipCode: "02905"},
	{latitude: 41.839468, longitude: -71.389175, zipCode: "02906"},
	{latitude: 41.798593, longitude: -71.42463, zipCode: "02907"},
	{latitude: 41.839025, longitude: -71.43925, zipCode: "02908"},
	{latitude: 41.818122, longitude: -71.451776, zipCode: "02909"},
	{latitude: 41.774999, longitude: -71.435594, zipCode: "02910"},
	{latitude: 41.854888, longitude: -71.472812, zipCode: "02911"},
	{latitude: 41.825524, longitude: -71.403275, zipCode: "02912"},
	{latitude: 41.813577, longitude: -71.366468, zipCode: "02914"},
	{latitude: 41.772177, longitude: -71.354407, zipCode: "02915"},
	{latitude: 41.842661, longitude: -71.352438, zipCode: "02916"},
	{latitude: 41.906288, longitude: -71.523811, zipCode: "02917"},
	{latitude: 41.843839, longitude: -71.435018, zipCode: "02918"},
	{latitude: 41.82744, longitude: -71.519879, zipCode: "02919"},
	{latitude: 41.767344, longitude: -71.465508, zipCode: "02920"},
	{latitude: 41.762906, longitude: -71.514814, zipCode: "02921"},
	{latitude: 42.874824, longitude: -71.600533, zipCode: "03031"},
	{latitude: 42.993501, longitude: -71.363966, zipCode: "03032"},
	{latitude: 42.749263, longitude: -71.67567, zipCode: "03033"},
	{latitude: 43.056895, longitude: -71.340635, zipCode: "03034"},
	{latitude: 42.98453, longitude: -71.255104, zipCode: "03036"},
	{latitude: 43.136321, longitude: -71.255077, zipCode: "03037"},
	{latitude: 42.892761, longitude: -71.276763, zipCode: "03038"},
	{latitude: 43.051027, longitude: -71.088254, zipCode: "03042"},
	{latitude: 42.994135, longitude: -71.816098, zipCode: "03043"},
	{latitude: 43.000369, longitude: -71.119162, zipCode: "03044"},
	{latitude: 43.021515, longitude: -71.563462, zipCode: "03045"},
	{latitude: 43.112473, longitude: -71.607479, zipCode: "03046"},
	{latitude: 42.93874, longitude: -71.879424, zipCode: "03047"},
	{latitude: 42.742874, longitude: -71.761755, zipCode: "03048"},
	{latitude: 42.749624, longitude: -71.585402, zipCode: "03049"},
	{latitude: 42.760497, longitude: -71.409393, zipCode: "03051"},
	{latitude: 42.846081, longitude: -71.468283, zipCode: "03052"},
	{latitude: 42.868588, longitude: -71.387247, zipCode: "03053"},
	{latitude: 42.852034, longitude: -71.519876, zipCode: "03054"},
	{latitude: 42.818645, longitude: -71.673354, zipCode: "03055"},
	{latitude: 42.908885, longitude: -71.694199, zipCode: "03057"},
	{latitude: 42.741087, longitude: -71.458266, zipCode: "03060"},
	{latitude: 42.722323, longitude: -71.501176, zipCode: "03062"},
	{latitude: 42.7821, longitude: -71.518366, zipCode: "03063"},
	{latitude: 42.77938, longitude: -71.47492, zipCode: "03064"},
	{latitude: 42.976408, longitude: -71.686737, zipCode: "03070"},
	{latitude: 42.746257, longitude: -71.874434, zipCode: "03071"},
	{latitude: 42.730992, longitude: -71.337075, zipCode: "03076"},
	{latitude: 43.032583, longitude: -71.203144, zipCode: "03077"},
	{latitude: 42.788267, longitude: -71.221725, zipCode: "03079"},
	{latitude: 42.896409, longitude: -71.768331, zipCode: "03082"},
	{latitude: 42.826723, longitude: -71.875416, zipCode: "03084"},
	{latitude: 42.832231, longitude: -71.759615, zipCode: "03086"},
	{latitude: 42.811092, longitude: -71.302688, zipCode: "03087"},
	{latitude: 42.989027, longitude: -71.466111, zipCode: "03101"},
	{latitude: 43.011907, longitude: -71.491063, zipCode: "03102"},
	{latitude: 42.956116, longitude: -71.442385, zipCode: "03103"},
	{latitude: 43.009552, longitude: -71.439782, zipCode: "03104"},
	{latitude: 43.081242, longitude: -71.446616, zipCode: "03106"},
	{latitude: 42.963734, longitude: -71.40035, zipCode: "03109"},
	{latitude: 42.935584, longitude: -71.536871, zipCode: "03110"},
	{latitude: 44.069466, longitude: -71.420016, zipCode: "03215"},
	{latitude: 43.450724, longitude: -71.7906, zipCode: "03216"},
	{latitude: 43.725967, longitude: -71.642579, zipCode: "03217"},
	{latitude: 43.336884, longitude: -71.273836, zipCode: "03218"},
	{latitude: 43.474455, longitude: -71.482208, zipCode: "03220"},
	{latitude: 43.258129, longitude: -71.960236, zipCode: "03221"},
	{latitude: 43.643715, longitude: -71.799277, zipCode: "03222"},
	{latitude: 43.85544, longitude: -71.685492, zipCode: "03223"},
	{latitude: 43.350746, longitude: -71.547517, zipCode: "03224"},
	{latitude: 43.363816, longitude: -71.24173, zipCode: "03225"},
	{latitude: 43.702775, longitude: -71.510443, zipCode: "03226"},
	{latitude: 43.817193, longitude: -71.460578, zipCode: "03227"},
	{latitude: 43.202191, longitude: -71.696789, zipCode: "03229"},
	{latitude: 43.52812, longitude: -71.864467, zipCode: "03230"},
	{latitude: 43.450897, longitude: -71.754096, zipCode: "03231"},
	{latitude: 43.424004, longitude: -71.931192, zipCode: "03233"},
	{latitude: 43.204812, longitude: -71.348563, zipCode: "03234"},
	{latitude: 43.448973, longitude: -71.674166, zipCode: "03235"},
	{latitude: 43.431581, longitude: -71.387657, zipCode: "03237"},
	{latitude: 43.98164, longitude: -71.887014, zipCode: "03238"},
	{latitude: 43.574691, longitude: -71.951919, zipCode: "03240"},
	{latitude: 43.707854, longitude: -71.814686, zipCode: "03241"},
	{latitude: 43.169407, longitude: -71.831201, zipCode: "03242"},
	{latitude: 43.528306, longitude: -71.769192, zipCode: "03243"},
	{latitude: 43.119926, longitude: -71.921889, zipCode: "03244"},
	{latitude: 43.761761, longitude: -71.582529, zipCode: "03245"},
	{latitude: 43.576535, longitude: -71.482263, zipCode: "03246"},
	{latitude: 43.519499, longitude: -71.375838, zipCode: "03249"},
	{latitude: 44.094813, longitude: -71.631436, zipCode: "03251"},
	{latitude: 43.631211, longitude: -71.498585, zipCode: "03253"},
	{latitude: 43.71695, longitude: -71.365972, zipCode: "03254"},
	{latitude: 43.314026, longitude: -72.024551, zipCode: "03255"},
	{latitude: 43.623436, longitude: -71.632547, zipCode: "03256"},
	{latitude: 43.417721, longitude: -71.990609, zipCode: "03257"},
	{latitude: 43.265259, longitude: -71.408835, zipCode: "03258"},
	{latitude: 43.878172, longitude: -71.499151, zipCode: "03259"},
	{latitude: 43.368032, longitude: -71.917724, zipCode: "03260"},
	{latitude: 43.211856, longitude: -71.223393, zipCode: "03261"},
	{latitude: 43.999889, longitude: -71.714447, zipCode: "03262"},
	{latitude: 43.288501, longitude: -71.309368, zipCode: "03263"},
	{latitude: 43.738775, longitude: -71.702625, zipCode: "03264"},
	{latitude: 43.782873, longitude: -71.884739, zipCode: "03266"},
	{latitude: 43.386099, longitude: -71.772917, zipCode: "03268"},
	{latitude: 43.529459, longitude: -71.600507, zipCode: "03269"},
	{latitude: 43.301982, longitude: -71.926953, zipCode: "03273"},
	{latitude: 43.170019, longitude: -71.420707, zipCode: "03275"},
	{latitude: 43.431031, longitude: -71.575998, zipCode: "03276"},
	{latitude: 43.30893, longitude: -71.835506, zipCode: "03278"},
	{latitude: 43.965803, longitude: -71.859117, zipCode: "03279"},
	{latitude: 43.190051, longitude: -72.093871, zipCode: "03280"},
	{latitude: 43.078287, longitude: -71.703508, zipCode: "03281"},
	{latitude: 43.858721, longitude: -71.923718, zipCode: "03282"},
	{latitude: 43.472936, longitude: -72.013121, zipCode: "03284"},
	{latitude: 43.947877, longitude: -71.635785, zipCode: "03285"},
	{latitude: 43.443284, longitude: -71.922446, zipCode: "03287"},
	{latitude: 43.129847, longitude: -71.131859, zipCode: "03290"},
	{latitude: 43.179773, longitude: -71.14197, zipCode: "03291"},
	{latitude: 43.979296, longitude: -71.672741, zipCode: "03293"},
	{latitude: 43.238596, longitude: -71.556261, zipCode: "03301"},
	{latitude: 43.311717, longitude: -71.665381, zipCode: "03303"},
	{latitude: 43.133177, longitude: -71.537242, zipCode: "03304"},
	{latitude: 43.333988, longitude: -71.446658, zipCode: "03307"},
	{latitude: 42.969069, longitude: -72.301965, zipCode: "03431"},
	{latitude: 43.063603, longitude: -71.978209, zipCode: "03440"},
	{latitude: 42.780686, longitude: -72.447028, zipCode: "03441"},
	{latitude: 43.020284, longitude: -71.903705, zipCode: "03442"},
	{latitude: 42.869403, longitude: -72.466172, zipCode: "03443"},
	{latitude: 42.889279, longitude: -72.069849, zipCode: "03444"},
	{latitude: 43.020616, longitude: -72.214374, zipCode: "03445"},
	{latitude: 42.858271, longitude: -72.29636, zipCode: "03446"},
	{latitude: 42.753205, longitude: -72.153028, zipCode: "03447"},
	{latitude: 43.041227, longitude: -72.262369, zipCode: "03448"},
	{latitude: 42.983841, longitude: -72.001124, zipCode: "03449"},
	{latitude: 42.948536, longitude: -72.068986, zipCode: "03450"},
	{latitude: 42.809298, longitude: -72.504822, zipCode: "03451"},
	{latitude: 42.829254, longitude: -72.059901, zipCode: "03452"},
	{latitude: 42.901767, longitude: -72.172914, zipCode: "03455"},
	{latitude: 43.138544, longitude: -72.208769, zipCode: "03456"},
	{latitude: 42.999482, longitude: -72.121703, zipCode: "03457"},
	{latitude: 42.868369, longitude: -71.940102, zipCode: "03458"},
	{latitude: 42.756663, longitude: -72.013247, zipCode: "03461"},
	{latitude: 42.892609, longitude: -72.390167, zipCode: "03462"},
	{latitude: 43.080924, longitude: -72.11013, zipCode: "03464"},
	{latitude: 42.828798, longitude: -72.188943, zipCode: "03465"},
	{latitude: 42.881907, longitude: -72.508281, zipCode: "03466"},
	{latitude: 42.968075, longitude: -72.430856, zipCode: "03467"},
	{latitude: 42.771338, longitude: -72.354224, zipCode: "03470"},
	{latitude: 44.343351, longitude: -71.795118, zipCode: "03561"},
	{latitude: 44.511426, longitude: -71.197822, zipCode: "03570"},
	{latitude: 44.260435, longitude: -71.609203, zipCode: "03574"},
	{latitude: 44.313668, longitude: -71.402828, zipCode: "03575"},
	{latitude: 44.896332, longitude: -71.378109, zipCode: "03576"},
	{latitude: 44.936589, longitude: -71.04518, zipCode: "03579"},
	{latitude: 44.163446, longitude: -71.693295, zipCode: "03580"},
	{latitude: 44.367172, longitude: -71.087115, zipCode: "03581"},
	{latitude: 44.565047, longitude: -71.442984, zipCode: "03582"},
	{latitude: 44.4028, longitude: -71.456598, zipCode: "03583"},
	{latitude: 44.49028, longitude: -71.547516, zipCode: "03584"},
	{latitude: 44.225276, longitude: -71.90269, zipCode: "03585"},
	{latitude: 44.21841, longitude: -71.80024, zipCode: "03586"},
	{latitude: 44.607509, longitude: -71.237069, zipCode: "03588"},
	{latitude: 44.715053, longitude: -71.52754, zipCode: "03590"},
	{latitude: 45.116236, longitude: -71.26111, zipCode: "03592"},
	{latitude: 44.301396, longitude: -71.299834, zipCode: "03593"},
	{latitude: 44.305266, longitude: -71.497376, zipCode: "03595"},
	{latitude: 44.744941, longitude: -71.381331, zipCode: "03597"},
	{latitude: 44.378946, longitude: -71.628862, zipCode: "03598"},
	{latitude: 43.245774, longitude: -72.312958, zipCode: "03601"},
	{latitude: 43.140833, longitude: -72.347571, zipCode: "03602"},
	{latitude: 43.247906, longitude: -72.373709, zipCode: "03603"},
	{latitude: 43.132288, longitude: -72.389804, zipCode: "03604"},
	{latitude: 43.236564, longitude: -72.184869, zipCode: "03605"},
	{latitude: 43.19806, longitude: -72.262227, zipCode: "03607"},
	{latitude: 43.076442, longitude: -72.395967, zipCode: "03608"},
	{latitude: 43.143935, longitude: -72.446448, zipCode: "03609"},
	{latitude: 44.182955, longitude: -71.987011, zipCode: "03740"},
	{latitude: 43.676857, longitude: -72.007091, zipCode: "03741"},
	{latitude: 43.360727, longitude: -72.326929, zipCode: "03743"},
	{latitude: 43.472988, longitude: -72.311749, zipCode: "03745"},
	{latitude: 43.603314, longitude: -72.118598, zipCode: "03748"},
	{latitude: 43.721023, longitude: -72.185207, zipCode: "03750"},
	{latitude: 43.4413, longitude: -72.081635, zipCode: "03751"},
	{latitude: 43.272092, longitude: -72.101033, zipCode: "03752"},
	{latitude: 43.51659, longitude: -72.149093, zipCode: "03753"},
	{latitude: 43.732434, longitude: -72.139155, zipCode: "03755"},
	{latitude: 44.035225, longitude: -72.050198, zipCode: "03765"},
	{latitude: 43.632568, longitude: -72.234816, zipCode: "03766"},
	{latitude: 43.816634, longitude: -72.094295, zipCode: "03768"},
	{latitude: 43.531294, longitude: -72.258811, zipCode: "03770"},
	{latitude: 44.296605, longitude: -71.980684, zipCode: "03771"},
	{latitude: 43.371345, longitude: -72.202789, zipCode: "03773"},
	{latitude: 44.099753, longitude: -71.985475, zipCode: "03774"},
	{latitude: 43.883333, longitude: -72.033845, zipCode: "03777"},
	{latitude: 43.961851, longitude: -72.001293, zipCode: "03779"},
	{latitude: 44.041804, longitude: -71.990934, zipCode: "03780"},
	{latitude: 43.560058, longitude: -72.299348, zipCode: "03781"},
	{latitude: 43.390095, longitude: -72.093136, zipCode: "03782"},
	{latitude: 43.638325, longitude: -72.30649, zipCode: "03784"},
	{latitude: 44.035925, longitude: -71.846557, zipCode: "03785"},
	{latitude: 43.074812, longitude: -70.805443, zipCode: "03801"},
	{latitude: 43.46473, longitude: -71.200177, zipCode: "03809"},
	{latitude: 43.51256, longitude: -71.294893, zipCode: "03810"},
	{latitude: 42.839113, longitude: -71.167192, zipCode: "03811"},
	{latitude: 44.08113, longitude: -71.297237, zipCode: "03812"},
	{latitude: 44.127498, longitude: -71.060691, zipCode: "03813"},
	{latitude: 43.770937, longitude: -71.181505, zipCode: "03814"},
	{latitude: 43.698285, longitude: -71.243027, zipCode: "03816"},
	{latitude: 43.888984, longitude: -71.240584, zipCode: "03817"},
	{latitude: 43.95945, longitude: -71.274251, zipCode: "03818"},
	{latitude: 42.944016, longitude: -71.132306, zipCode: "03819"},
	{latitude: 43.18636, longitude: -70.883623, zipCode: "03820"},
	{latitude: 43.174607, longitude: -70.941453, zipCode: "03823"},
	{latitude: 43.117061, longitude: -70.918807, zipCode: "03824"},
	{latitude: 43.215566, longitude: -71.03898, zipCode: "03825"},
	{latitude: 42.88808, longitude: -71.129845, zipCode: "03826"},
	{latitude: 42.903677, longitude: -70.99555, zipCode: "03827"},
	{latitude: 43.614118, longitude: -70.993555, zipCode: "03830"},
	{latitude: 43.90696, longitude: -71.04752, zipCode: "03832"},
	{latitude: 42.97878, longitude: -70.97968, zipCode: "03833"},
	{latitude: 43.366924, longitude: -71.062503, zipCode: "03835"},
	{latitude: 43.842036, longitude: -71.075161, zipCode: "03836"},
	{latitude: 43.422251, longitude: -71.336087, zipCode: "03837"},
	{latitude: 44.110671, longitude: -71.223276, zipCode: "03838"},
	{latitude: 43.268772, longitude: -70.985508, zipCode: "03839"},
	{latitude: 43.039732, longitude: -70.845622, zipCode: "03840"},
	{latitude: 42.878739, longitude: -71.178272, zipCode: "03841"},
	{latitude: 42.939601, longitude: -70.836728, zipCode: "03842"},
	{latitude: 42.924811, longitude: -70.886242, zipCode: "03844"},
	{latitude: 44.105358, longitude: -71.115819, zipCode: "03845"},
	{latitude: 44.18762, longitude: -71.155763, zipCode: "03846"},
	{latitude: 44.07198, longitude: -71.123943, zipCode: "03847"},
	{latitude: 42.913731, longitude: -71.073595, zipCode: "03848"},
	{latitude: 43.89008, longitude: -71.132913, zipCode: "03849"},
	{latitude: 43.698034, longitude: -71.302598, zipCode: "03850"},
	{latitude: 43.437283, longitude: -71.025251, zipCode: "03851"},
	{latitude: 43.495003, longitude: -70.986034, zipCode: "03852"},
	{latitude: 43.638609, longitude: -71.29438, zipCode: "03853"},
	{latitude: 43.062147, longitude: -70.717071, zipCode: "03854"},
	{latitude: 43.463687, longitude: -71.15214, zipCode: "03855"},
	{latitude: 43.038509, longitude: -70.967954, zipCode: "03856"},
	{latitude: 43.070913, longitude: -70.946939, zipCode: "03857"},
	{latitude: 42.870369, longitude: -71.045915, zipCode: "03858"},
	{latitude: 44.042077, longitude: -71.122992, zipCode: "03860"},
	{latitude: 43.119202, longitude: -71.007082, zipCode: "03861"},
	{latitude: 42.979934, longitude: -70.829441, zipCode: "03862"},
	{latitude: 43.689932, longitude: -71.10344, zipCode: "03864"},
	{latitude: 42.845597, longitude: -71.09326, zipCode: "03865"},
	{latitude: 43.301679, longitude: -70.991769, zipCode: "03867"},
	{latitude: 43.324241, longitude: -70.940178, zipCode: "03868"},
	{latitude: 43.219815, longitude: -70.843107, zipCode: "03869"},
	{latitude: 43.019572, longitude: -70.760523, zipCode: "03870"},
	{latitude: 42.980948, longitude: -70.777362, zipCode: "03871"},
	{latitude: 43.570526, longitude: -71.043346, zipCode: "03872"},
	{latitude: 42.934018, longitude: -71.183605, zipCode: "03873"},
	{latitude: 42.883123, longitude: -70.860823, zipCode: "03874"},
	{latitude: 43.903632, longitude: -71.192066, zipCode: "03875"},
	{latitude: 43.253476, longitude: -70.887944, zipCode: "03878"},
	{latitude: 43.746733, longitude: -71.04191, zipCode: "03882"},
	{latitude: 43.808767, longitude: -71.320045, zipCode: "03883"},
	{latitude: 43.275384, longitude: -71.169956, zipCode: "03884"},
	{latitude: 43.014993, longitude: -70.902602, zipCode: "03885"},
	{latitude: 43.864038, longitude: -71.296068, zipCode: "03886"},
	{latitude: 43.483007, longitude: -71.070641, zipCode: "03887"},
	{latitude: 43.81501, longitude: -71.209375, zipCode: "03890"},
	{latitude: 43.610385, longitude: -71.177826, zipCode: "03894"},
	{latitude: 43.906901, longitude: -71.327623, zipCode: "03897"},
	{latitude: 43.29932, longitude: -70.842062, zipCode: "03901"},
	{latitude: 43.214916, longitude: -70.628806, zipCode: "03902"},
	{latitude: 43.146551, longitude: -70.774365, zipCode: "03903"},
	{latitude: 43.109963, longitude: -70.733833, zipCode: "03904"},
	{latitude: 43.004852, longitude: -70.604355, zipCode: "03905"},
	{latitude: 43.352283, longitude: -70.779445, zipCode: "03906"},
	{latitude: 43.256404, longitude: -70.612837, zipCode: "03907"},
	{latitude: 43.230612, longitude: -70.752424, zipCode: "03908"},
	{latitude: 43.16646, longitude: -70.679004, zipCode: "03909"},
	{latitude: 43.172952, longitude: -70.609686, zipCode: "03910"},
	{latitude: 43.135668, longitude: -70.637836, zipCode: "03911"},
	{latitude: 43.536651, longitude: -70.940212, zipCode: "04001"},
	{latitude: 43.489069, longitude: -70.661906, zipCode: "04002"},
	{latitude: 43.733675, longitude: -69.971397, zipCode: "04003"},
	{latitude: 43.492102, longitude: -70.486566, zipCode: "04005"},
	{latitude: 43.443994, longitude: -70.342944, zipCode: "04006"},
	{latitude: 44.044224, longitude: -69.863781, zipCode: "04008"},
	{latitude: 44.047513, longitude: -70.743319, zipCode: "04009"},
	{latitude: 43.930799, longitude: -70.918584, zipCode: "04010"},
	{latitude: 43.896431, longitude: -69.973487, zipCode: "04011"},
	{latitude: 43.951979, longitude: -70.513228, zipCode: "04015"},
	{latitude: 43.73532, longitude: -70.081008, zipCode: "04017"},
	{latitude: 43.69042, longitude: -70.110321, zipCode: "04019"},
	{latitude: 43.767186, longitude: -70.808863, zipCode: "04020"},
	{latitude: 43.791796, longitude: -70.264762, zipCode: "04021"},
	{latitude: 43.996209, longitude: -70.813737, zipCode: "04022"},
	{latitude: 43.83632, longitude: -70.685245, zipCode: "04024"},
	{latitude: 43.399909, longitude: -70.912785, zipCode: "04027"},
	{latitude: 43.887546, longitude: -70.655358, zipCode: "04029"},
	{latitude: 43.589061, longitude: -70.696276, zipCode: "04030"},
	{latitude: 43.844826, longitude: -70.089953, zipCode: "04032"},
	{latitude: 44.101677, longitude: -70.942769, zipCode: "04037"},
	{latitude: 43.701353, longitude: -70.462982, zipCode: "04038"},
	{latitude: 43.90492, longitude: -70.367727, zipCode: "04039"},
	{latitude: 44.142935, longitude: -70.824936, zipCode: "04040"},
	{latitude: 43.848997, longitude: -70.830764, zipCode: "04041"},
	{latitude: 43.631019, longitude: -70.614369, zipCode: "04042"},
	{latitude: 43.395947, longitude: -70.570221, zipCode: "04043"},
	{latitude: 43.429822, longitude: -70.474142, zipCode: "04046"},
	{latitude: 43.733357, longitude: -70.894042, zipCode: "04047"},
	{latitude: 43.688481, longitude: -70.781883, zipCode: "04048"},
	{latitude: 43.745798, longitude: -70.6998, zipCode: "04049"},
	{latitude: 43.687321, longitude: -70.153766, zipCode: "04050"},
	{latitude: 44.192471, longitude: -70.885803, zipCode: "04051"},
	{latitude: 43.975769, longitude: -70.639298, zipCode: "04055"},
	{latitude: 43.66059, longitude: -70.858688, zipCode: "04056"},
	{latitude: 44.0956, longitude: -70.701768, zipCode: "04057"},
	{latitude: 43.634661, longitude: -70.743574, zipCode: "04061"},
	{latitude: 43.794689, longitude: -70.405329, zipCode: "04062"},
	{latitude: 43.500389, longitude: -70.395326, zipCode: "04063"},
	{latitude: 43.52506, longitude: -70.388252, zipCode: "04064"},
	{latitude: 43.765658, longitude: -69.966888, zipCode: "04066"},
	{latitude: 43.837898, longitude: -70.946568, zipCode: "04068"},
	{latitude: 43.903914, longitude: -70.182018, zipCode: "04069"},
	{latitude: 43.891232, longitude: -70.497102, zipCode: "04071"},
	{latitude: 43.550411, longitude: -70.466341, zipCode: "04072"},
	{latitude: 43.411936, longitude: -70.737326, zipCode: "04073"},
	{latitude: 43.589627, longitude: -70.366191, zipCode: "04074"},
	{latitude: 43.554017, longitude: -70.832796, zipCode: "04076"},
	{latitude: 43.790756, longitude: -69.981037, zipCode: "04079"},
	{latitude: 43.464119, longitude: -70.814287, zipCode: "04083"},
	{latitude: 43.762147, longitude: -70.566686, zipCode: "04084"},
	{latitude: 43.77855, longitude: -70.627634, zipCode: "04085"},
	{latitude: 43.964977, longitude: -69.955624, zipCode: "04086"},
	{latitude: 43.56861, longitude: -70.744281, zipCode: "04087"},
	{latitude: 44.202838, longitude: -70.722272, zipCode: "04088"},
	{latitude: 43.331432, longitude: -70.645586, zipCode: "04090"},
	{latitude: 43.839321, longitude: -70.744838, zipCode: "04091"},
	{latitude: 43.70792, longitude: -70.352602, zipCode: "04092"},
	{latitude: 43.63626, longitude: -70.538842, zipCode: "04093"},
	{latitude: 43.62861, longitude: -70.913904, zipCode: "04095"},
	{latitude: 43.788137, longitude: -70.156478, zipCode: "04096"},
	{latitude: 43.845606, longitude: -70.241251, zipCode: "04097"},
	{latitude: 43.662607, longitude: -70.257949, zipCode: "04101"},
	{latitude: 43.654353, longitude: -70.297611, zipCode: "04102"},
	{latitude: 43.695422, longitude: -70.288652, zipCode: "04103"},
	{latitude: 43.740472, longitude: -70.277172, zipCode: "04105"},
	{latitude: 43.631402, longitude: -70.285989, zipCode: "04106"},
	{latitude: 43.587173, longitude: -70.236476, zipCode: "04107"},
	{latitude: 43.636765, longitude: -70.186808, zipCode: "04108"},
	{latitude: 43.663285, longitude: -70.222003, zipCode: "04109"},
	{latitude: 43.741369, longitude: -70.18137, zipCode: "04110"},
	{latitude: 44.084537, longitude: -70.249649, zipCode: "04210"},
	{latitude: 44.679659, longitude: -70.846517, zipCode: "04216"},
	{latitude: 44.393037, longitude: -70.837425, zipCode: "04217"},
	{latitude: 44.400747, longitude: -70.55811, zipCode: "04219"},
	{latitude: 44.34262, longitude: -70.34744, zipCode: "04220"},
	{latitude: 44.490587, longitude: -70.294547, zipCode: "04221"},
	{latitude: 43.956021, longitude: -70.132418, zipCode: "04222"},
	{latitude: 44.561357, longitude: -70.413727, zipCode: "04224"},
	{latitude: 44.625548, longitude: -70.708226, zipCode: "04226"},
	{latitude: 44.568652, longitude: -70.294791, zipCode: "04227"},
	{latitude: 44.263058, longitude: -70.875477, zipCode: "04231"},
	{latitude: 44.616266, longitude: -70.193834, zipCode: "04234"},
	{latitude: 44.189754, longitude: -70.145088, zipCode: "04236"},
	{latitude: 44.4945, longitude: -70.734421, zipCode: "04237"},
	{latitude: 44.190757, longitude: -70.384306, zipCode: "04238"},
	{latitude: 44.516897, longitude: -70.202187, zipCode: "04239"},
	{latitude: 44.089513, longitude: -70.172095, zipCode: "04240"},
	{latitude: 44.022179, longitude: -70.117387, zipCode: "04250"},
	{latitude: 44.036688, longitude: -70.058799, zipCode: "04252"},
	{latitude: 44.411638, longitude: -70.216186, zipCode: "04253"},
	{latitude: 44.445337, longitude: -70.138076, zipCode: "04254"},
	{latitude: 44.332714, longitude: -70.660557, zipCode: "04255"},
	{latitude: 44.104897, longitude: -70.40468, zipCode: "04256"},
	{latitude: 44.55316, longitude: -70.495015, zipCode: "04257"},
	{latitude: 44.13794, longitude: -70.336334, zipCode: "04258"},
	{latitude: 44.233505, longitude: -70.014723, zipCode: "04259"},
	{latitude: 43.957508, longitude: -70.296011, zipCode: "04260"},
	{latitude: 44.565568, longitude: -70.962661, zipCode: "04261"},
	{latitude: 44.295351, longitude: -70.133074, zipCode: "04263"},
	{latitude: 44.280563, longitude: -70.037314, zipCode: "04265"},
	{latitude: 44.224773, longitude: -70.770864, zipCode: "04267"},
	{latitude: 44.225867, longitude: -70.610021, zipCode: "04268"},
	{latitude: 44.117335, longitude: -70.527478, zipCode: "04270"},
	{latitude: 44.260967, longitude: -70.498732, zipCode: "04271"},
	{latitude: 44.048106, longitude: -70.393563, zipCode: "04274"},
	{latitude: 44.702596, longitude: -70.647585, zipCode: "04275"},
	{latitude: 44.55992, longitude: -70.625264, zipCode: "04276"},
	{latitude: 44.116831, longitude: -70.062582, zipCode: "04280"},
	{latitude: 44.248001, longitude: -70.490816, zipCode: "04281"},
	{latitude: 44.267103, longitude: -70.246528, zipCode: "04282"},
	{latitude: 44.339898, longitude: -70.047246, zipCode: "04284"},
	{latitude: 44.688598, longitude: -70.454056, zipCode: "04285"},
	{latitude: 44.403191, longitude: -70.860193, zipCode: "04286"},
	{latitude: 44.073433, longitude: -69.970748, zipCode: "04287"},
	{latitude: 44.319278, longitude: -70.522647, zipCode: "04289"},
	{latitude: 44.460915, longitude: -70.451923, zipCode: "04290"},
	{latitude: 44.380796, longitude: -70.448594, zipCode: "04292"},
	{latitude: 44.637914, longitude: -70.275434, zipCode: "04294"},
	{latitude: 44.35191, longitude: -69.747615, zipCode: "04330"},
	{latitude: 44.259968, longitude: -69.554864, zipCode: "04341"},
	{latitude: 44.086173, longitude: -69.736988, zipCode: "04342"},
	{latitude: 44.260548, longitude: -69.827805, zipCode: "04344"},
	{latitude: 44.195091, longitude: -69.793771, zipCode: "04345"},
	{latitude: 44.237286, longitude: -69.751247, zipCode: "04346"},
	{latitude: 44.287241, longitude: -69.81804, zipCode: "04347"},
	{latitude: 44.210864, longitude: -69.499152, zipCode: "04348"},
	{latitude: 44.434007, longitude: -70.067034, zipCode: "04349"},
	{latitude: 44.172142, longitude: -69.9345, zipCode: "04350"},
	{latitude: 44.342427, longitude: -69.861935, zipCode: "04351"},
	{latitude: 44.466488, longitude: -69.96045, zipCode: "04352"},
	{latitude: 44.198014, longitude: -69.619689, zipCode: "04353"},
	{latitude: 44.396114, longitude: -69.43063, zipCode: "04354"},
	{latitude: 44.384388, longitude: -69.9502, zipCode: "04355"},
	{latitude: 44.116833, longitude: -69.829607, zipCode: "04357"},
	{latitude: 44.414473, longitude: -69.535271, zipCode: "04358"},
	{latitude: 44.179541, longitude: -69.761284, zipCode: "04359"},
	{latitude: 44.554819, longitude: -69.999683, zipCode: "04360"},
	{latitude: 44.315485, longitude: -69.571186, zipCode: "04363"},
	{latitude: 44.309991, longitude: -69.963093, zipCode: "04364"},
	{latitude: 44.848517, longitude: -68.850405, zipCode: "04401"},
	{latitude: 45.229183, longitude: -69.596521, zipCode: "04406"},
	{latitude: 44.986935, longitude: -68.209958, zipCode: "04408"},
	{latitude: 45.08826, longitude: -68.906681, zipCode: "04410"},
	{latitude: 44.89448, longitude: -68.546752, zipCode: "04411"},
	{latitude: 44.784461, longitude: -68.734559, zipCode: "04412"},
	{latitude: 45.565868, longitude: -67.815407, zipCode: "04413"},
	{latitude: 45.412909, longitude: -69.089801, zipCode: "04414"},
	{latitude: 45.352922, longitude: -69.058231, zipCode: "04415"},
	{latitude: 44.623798, longitude: -68.749742, zipCode: "04416"},
	{latitude: 45.214319, longitude: -68.388895, zipCode: "04417"},
	{latitude: 45.116253, longitude: -68.50918, zipCode: "04418"},
	{latitude: 44.800454, longitude: -69.003117, zipCode: "04419"},
	{latitude: 44.409006, longitude: -68.815533, zipCode: "04421"},
	{latitude: 45.077652, longitude: -69.031432, zipCode: "04422"},
	{latitude: 45.669238, longitude: -67.862669, zipCode: "04424"},
	{latitude: 45.186261, longitude: -69.145532, zipCode: "04426"},
	{latitude: 44.980473, longitude: -69.010748, zipCode: "04427"},
	{latitude: 44.8058, longitude: -68.554003, zipCode: "04428"},
	{latitude: 44.721239, longitude: -68.617075, zipCode: "04429"},
	{latitude: 45.65364, longitude: -68.59734, zipCode: "04430"},
	{latitude: 44.564528, longitude: -68.667723, zipCode: "04431"},
	{latitude: 44.763866, longitude: -69.128068, zipCode: "04434"},
	{latitude: 44.963225, longitude: -69.125005, zipCode: "04435"},
	{latitude: 44.598927, longitude: -68.92833, zipCode: "04438"},
	{latitude: 45.687909, longitude: -69.308516, zipCode: "04441"},
	{latitude: 45.473244, longitude: -69.696479, zipCode: "04442"},
	{latitude: 45.238115, longitude: -69.401935, zipCode: "04443"},
	{latitude: 44.72898, longitude: -68.949964, zipCode: "04444"},
	{latitude: 45.315895, longitude: -68.739564, zipCode: "04448"},
	{latitude: 44.992636, longitude: -68.888475, zipCode: "04449"},
	{latitude: 44.915921, longitude: -68.927942, zipCode: "04450"},
	{latitude: 45.57744, longitude: -68.211789, zipCode: "04451"},
	{latitude: 45.191408, longitude: -68.787687, zipCode: "04453"},
	{latitude: 45.515606, longitude: -67.533996, zipCode: "04454"},
	{latitude: 45.299422, longitude: -68.259742, zipCode: "04455"},
	{latitude: 44.876816, longitude: -68.996651, zipCode: "04456"},
	{latitude: 45.445526, longitude: -68.534454, zipCode: "04457"},
	{latitude: 45.588735, longitude: -68.339985, zipCode: "04459"},
	{latitude: 45.640874, longitude: -68.524835, zipCode: "04460"},
	{latitude: 44.993378, longitude: -68.575945, zipCode: "04461"},
	{latitude: 45.995839, longitude: -68.928348, zipCode: "04462"},
	{latitude: 45.286832, longitude: -68.895109, zipCode: "04463"},
	{latitude: 45.297218, longitude: -69.499149, zipCode: "04464"},
	{latitude: 45.028344, longitude: -68.719249, zipCode: "04468"},
	{latitude: 44.902505, longitude: -68.666208, zipCode: "04469"},
	{latitude: 45.916545, longitude: -67.856824, zipCode: "04471"},
	{latitude: 44.57632, longitude: -68.669915, zipCode: "04472"},
	{latitude: 44.88168, longitude: -68.738642, zipCode: "04473"},
	{latitude: 44.688778, longitude: -68.762805, zipCode: "04474"},
	{latitude: 45.192986, longitude: -68.594777, zipCode: "04475"},
	{latitude: 44.482703, longitude: -68.705997, zipCode: "04476"},
	{latitude: 45.728188, longitude: -69.768032, zipCode: "04478"},
	{latitude: 45.124208, longitude: -69.287058, zipCode: "04479"},
	{latitude: 45.242742, longitude: -69.10464, zipCode: "04481"},
	{latitude: 45.34797, longitude: -69.60251, zipCode: "04485"},
	{latitude: 45.387737, longitude: -68.104471, zipCode: "04487"},
	{latitude: 44.87164, longitude: -69.113926, zipCode: "04488"},
	{latitude: 44.91075, longitude: -68.691209, zipCode: "04489"},
	{latitude: 45.394222, longitude: -67.828259, zipCode: "04490"},
	{latitude: 45.562301, longitude: -67.521056, zipCode: "04491"},
	{latitude: 45.336791, longitude: -67.66879, zipCode: "04492"},
	{latitude: 45.278143, longitude: -68.535627, zipCode: "04493"},
	{latitude: 45.477433, longitude: -68.33898, zipCode: "04495"},
	{latitude: 44.650751, longitude: -68.908918, zipCode: "04496"},
	{latitude: 45.726568, longitude: -68.058358, zipCode: "04497"},
	{latitude: 43.887982, longitude: -69.826723, zipCode: "04530"},
	{latitude: 44.098468, longitude: -69.629176, zipCode: "04535"},
	{latitude: 43.902263, longitude: -69.627303, zipCode: "04537"},
	{latitude: 43.847464, longitude: -69.629626, zipCode: "04538"},
	{latitude: 43.973542, longitude: -69.501972, zipCode: "04539"},
	{latitude: 43.886691, longitude: -69.484076, zipCode: "04541"},
	{latitude: 44.022351, longitude: -69.490756, zipCode: "04543"},
	{latitude: 43.81989, longitude: -69.591972, zipCode: "04544"},
	{latitude: 43.978605, longitude: -69.332584, zipCode: "04547"},
	{latitude: 43.833341, longitude: -69.749066, zipCode: "04548"},
	{latitude: 43.984533, longitude: -69.435489, zipCode: "04551"},
	{latitude: 44.052277, longitude: -69.567522, zipCode: "04553"},
	{latitude: 43.862733, longitude: -69.512324, zipCode: "04554"},
	{latitude: 44.101248, longitude: -69.482658, zipCode: "04555"},
	{latitude: 43.975989, longitude: -69.610952, zipCode: "04556"},
	{latitude: 43.888194, longitude: -69.519131, zipCode: "04558"},
	{latitude: 43.770829, longitude: -69.819164, zipCode: "04562"},
	{latitude: 44.013922, longitude: -69.258706, zipCode: "04563"},
	{latitude: 43.928794, longitude: -69.469747, zipCode: "04564"},
	{latitude: 43.886152, longitude: -69.558041, zipCode: "04568"},
	{latitude: 43.808546, longitude: -69.630765, zipCode: "04570"},
	{latitude: 43.896656, longitude: -69.67417, zipCode: "04571"},
	{latitude: 44.122085, longitude: -69.383353, zipCode: "04572"},
	{latitude: 43.951357, longitude: -69.55233, zipCode: "04573"},
	{latitude: 44.260639, longitude: -69.391524, zipCode: "04574"},
	{latitude: 43.85215, longitude: -69.662201, zipCode: "04575"},
	{latitude: 43.813339, longitude: -69.662868, zipCode: "04576"},
	{latitude: 43.982445, longitude: -69.692754, zipCode: "04578"},
	{latitude: 43.959656, longitude: -69.769351, zipCode: "04579"},
	{latitude: 44.685803, longitude: -68.394798, zipCode: "04605"},
	{latitude: 44.540726, longitude: -67.712364, zipCode: "04606"},
	{latitude: 44.467048, longitude: -68.086866, zipCode: "04607"},
	{latitude: 44.39747, longitude: -68.263808, zipCode: "04609"},
	{latitude: 44.463625, longitude: -67.622031, zipCode: "04611"},
	{latitude: 44.261725, longitude: -68.381404, zipCode: "04612"},
	{latitude: 44.368835, longitude: -68.027614, zipCode: "04613"},
	{latitude: 44.400549, longitude: -68.520047, zipCode: "04614"},
	{latitude: 44.275705, longitude: -68.534676, zipCode: "04616"},
	{latitude: 44.365013, longitude: -68.72709, zipCode: "04617"},
	{latitude: 45.142952, longitude: -67.217529, zipCode: "04619"},
	{latitude: 44.751766, longitude: -67.964777, zipCode: "04622"},
	{latitude: 44.699244, longitude: -67.713619, zipCode: "04623"},
	{latitude: 44.423951, longitude: -67.985876, zipCode: "04624"},
	{latitude: 44.250726, longitude: -68.209723, zipCode: "04625"},
	{latitude: 44.669023, longitude: -67.22199, zipCode: "04626"},
	{latitude: 44.230534, longitude: -68.791797, zipCode: "04627"},
	{latitude: 44.87399, longitude: -67.274401, zipCode: "04628"},
	{latitude: 44.866496, longitude: -67.474781, zipCode: "04630"},
	{latitude: 44.910939, longitude: -67.009163, zipCode: "04631"},
	{latitude: 44.619991, longitude: -68.220437, zipCode: "04634"},
	{latitude: 44.112753, longitude: -68.357532, zipCode: "04635"},
	{latitude: 44.524572, longitude: -68.28525, zipCode: "04640"},
	{latitude: 44.330584, longitude: -68.809947, zipCode: "04642"},
	{latitude: 44.539862, longitude: -67.826998, zipCode: "04643"},
	{latitude: 44.064664, longitude: -68.62081, zipCode: "04645"},
	{latitude: 44.259993, longitude: -68.228607, zipCode: "04646"},
	{latitude: 44.654537, longitude: -67.631543, zipCode: "04648"},
	{latitude: 44.559882, longitude: -67.504242, zipCode: "04649"},
	{latitude: 44.296718, longitude: -68.715318, zipCode: "04650"},
	{latitude: 44.819713, longitude: -67.062328, zipCode: "04652"},
	{latitude: 44.201439, longitude: -68.327441, zipCode: "04653"},
	{latitude: 44.689769, longitude: -67.500124, zipCode: "04654"},
	{latitude: 44.655762, longitude: -67.378971, zipCode: "04655"},
	{latitude: 44.982106, longitude: -67.443459, zipCode: "04657"},
	{latitude: 44.507249, longitude: -67.877817, zipCode: "04658"},
	{latitude: 44.335753, longitude: -68.345729, zipCode: "04660"},
	{latitude: 44.306926, longitude: -68.287236, zipCode: "04662"},
	{latitude: 44.543669, longitude: -68.135604, zipCode: "04664"},
	{latitude: 44.961077, longitude: -67.220627, zipCode: "04666"},
	{latitude: 44.983542, longitude: -67.101382, zipCode: "04667"},
	{latitude: 45.195566, longitude: -67.694117, zipCode: "04668"},
	{latitude: 44.411663, longitude: -67.998309, zipCode: "04669"},
	{latitude: 45.067341, longitude: -67.149953, zipCode: "04671"},
	{latitude: 44.296423, longitude: -68.667657, zipCode: "04673"},
	{latitude: 44.257024, longitude: -68.458219, zipCode: "04674"},
	{latitude: 44.300258, longitude: -68.245826, zipCode: "04675"},
	{latitude: 44.34688, longitude: -68.634365, zipCode: "04676"},
	{latitude: 44.472889, longitude: -68.180863, zipCode: "04677"},
	{latitude: 44.2797, longitude: -68.318243, zipCode: "04679"},
	{latitude: 44.478414, longitude: -67.947062, zipCode: "04680"},
	{latitude: 44.150464, longitude: -68.645327, zipCode: "04681"},
	{latitude: 44.235863, longitude: -68.823941, zipCode: "04683"},
	{latitude: 44.467462, longitude: -68.507329, zipCode: "04684"},
	{latitude: 44.157979, longitude: -68.441976, zipCode: "04685"},
	{latitude: 44.996537, longitude: -67.694027, zipCode: "04686"},
	{latitude: 44.760322, longitude: -67.255047, zipCode: "04691"},
	{latitude: 44.376372, longitude: -68.093638, zipCode: "04693"},
	{latitude: 45.115083, longitude: -67.477541, zipCode: "04694"},
	{latitude: 46.191023, longitude: -67.860968, zipCode: "04730"},
	{latitude: 46.571018, longitude: -68.491485, zipCode: "04732"},
	{latitude: 45.743133, longitude: -68.410914, zipCode: "04733"},
	{latitude: 46.485401, longitude: -67.922885, zipCode: "04734"},
	{latitude: 46.378842, longitude: -68.026941, zipCode: "04735"},
	{latitude: 46.911495, longitude: -68.029189, zipCode: "04736"},
	{latitude: 46.616369, longitude: -69.521899, zipCode: "04737"},
	{latitude: 46.977255, longitude: -68.599017, zipCode: "04739"},
	{latitude: 46.633304, longitude: -67.847623, zipCode: "04740"},
	{latitude: 46.776241, longitude: -67.859197, zipCode: "04742"},
	{latitude: 47.160789, longitude: -68.731119, zipCode: "04743"},
	{latitude: 47.274118, longitude: -68.39823, zipCode: "04745"},
	{latitude: 47.251015, longitude: -68.142962, zipCode: "04746"},
	{latitude: 45.967691, longitude: -68.208404, zipCode: "04747"},
	{latitude: 46.954967, longitude: -67.857437, zipCode: "04750"},
	{latitude: 47.321708, longitude: -68.298213, zipCode: "04756"},
	{latitude: 46.572049, longitude: -68.199139, zipCode: "04757"},
	{latitude: 46.559272, longitude: -67.853225, zipCode: "04758"},
	{latitude: 46.333559, longitude: -67.91755, zipCode: "04760"},
	{latitude: 46.117239, longitude: -67.975572, zipCode: "04761"},
	{latitude: 46.976391, longitude: -68.114962, zipCode: "04762"},
	{latitude: 46.07385, longitude: -68.113532, zipCode: "04763"},
	{latitude: 46.366887, longitude: -68.34101, zipCode: "04764"},
	{latitude: 46.077239, longitude: -68.671353, zipCode: "04765"},
	{latitude: 46.892463, longitude: -68.21578, zipCode: "04766"},
	{latitude: 46.796816, longitude: -69.054521, zipCode: "04768"},
	{latitude: 46.688952, longitude: -67.991914, zipCode: "04769"},
	{latitude: 47.235732, longitude: -68.317305, zipCode: "04772"},
	{latitude: 47.267434, longitude: -68.223039, zipCode: "04773"},
	{latitude: 47.124038, longitude: -69.014592, zipCode: "04774"},
	{latitude: 45.818415, longitude: -68.311139, zipCode: "04776"},
	{latitude: 45.844459, longitude: -68.472574, zipCode: "04777"},
	{latitude: 47.116172, longitude: -68.307655, zipCode: "04779"},
	{latitude: 46.170677, longitude: -68.267045, zipCode: "04780"},
	{latitude: 47.150199, longitude: -68.613495, zipCode: "04781"},
	{latitude: 47.035682, longitude: -68.187133, zipCode: "04783"},
	{latitude: 47.120057, longitude: -68.035813, zipCode: "04785"},
	{latitude: 46.794921, longitude: -68.192051, zipCode: "04786"},
	{latitude: 46.533465, longitude: -67.966076, zipCode: "04787"},
	{latitude: 44.134088, longitude: -69.132629, zipCode: "04841"},
	{latitude: 44.238406, longitude: -69.064039, zipCode: "04843"},
	{latitude: 44.241178, longitude: -69.188513, zipCode: "04847"},
	{latitude: 44.298888, longitude: -68.915242, zipCode: "04848"},
	{latitude: 44.316402, longitude: -69.026412, zipCode: "04849"},
	{latitude: 43.859145, longitude: -68.879348, zipCode: "04851"},
	{latitude: 43.767426, longitude: -69.310059, zipCode: "04852"},
	{latitude: 44.136346, longitude: -68.866756, zipCode: "04853"},
	{latitude: 44.046257, longitude: -69.092537, zipCode: "04854"},
	{latitude: 43.931932, longitude: -69.287741, zipCode: "04855"},
	{latitude: 44.180604, longitude: -69.122109, zipCode: "04856"},
	{latitude: 44.046511, longitude: -69.154059, zipCode: "04858"},
	{latitude: 43.996564, longitude: -69.159454, zipCode: "04859"},
	{latitude: 43.944324, longitude: -69.243196, zipCode: "04860"},
	{latitude: 44.107714, longitude: -69.171425, zipCode: "04861"},
	{latitude: 44.262706, longitude: -69.277723, zipCode: "04862"},
	{latitude: 44.068815, longitude: -68.853314, zipCode: "04863"},
	{latitude: 44.12801, longitude: -69.243885, zipCode: "04864"},
	{latitude: 44.541078, longitude: -69.573081, zipCode: "04901"},
	{latitude: 44.508426, longitude: -69.445189, zipCode: "04910"},
	{latitude: 44.779702, longitude: -69.970678, zipCode: "04911"},
	{latitude: 44.96526, longitude: -69.666935, zipCode: "04912"},
	{latitude: 44.463502, longitude: -69.037571, zipCode: "04915"},
	{latitude: 44.487731, longitude: -69.838639, zipCode: "04917"},
	{latitude: 44.55034, longitude: -69.866468, zipCode: "04918"},
	{latitude: 45.105646, longitude: -69.805224, zipCode: "04920"},
	{latitude: 44.581023, longitude: -69.143576, zipCode: "04921"},
	{latitude: 44.673999, longitude: -69.38646, zipCode: "04922"},
	{latitude: 45.044494, longitude: -69.449898, zipCode: "04923"},
	{latitude: 44.782176, longitude: -69.546316, zipCode: "04924"},
	{latitude: 45.212387, longitude: -69.903429, zipCode: "04925"},
	{latitude: 44.480319, longitude: -69.515208, zipCode: "04926"},
	{latitude: 44.665474, longitude: -69.540766, zipCode: "04927"},
	{latitude: 44.946982, longitude: -69.258861, zipCode: "04928"},
	{latitude: 44.773091, longitude: -69.301537, zipCode: "04929"},
	{latitude: 45.028226, longitude: -69.326494, zipCode: "04930"},
	{latitude: 44.697571, longitude: -69.143703, zipCode: "04932"},
	{latitude: 45.355011, longitude: -70.65743, zipCode: "04936"},
	{latitude: 44.651655, longitude: -69.661275, zipCode: "04937"},
	{latitude: 44.661856, longitude: -70.103104, zipCode: "04938"},
	{latitude: 45.077258, longitude: -69.158771, zipCode: "04939"},
	{latitude: 44.617983, longitude: -70.078802, zipCode: "04940"},
	{latitude: 44.470053, longitude: -69.284182, zipCode: "04941"},
	{latitude: 45.042514, longitude: -69.593256, zipCode: "04942"},
	{latitude: 44.879823, longitude: -69.5205, zipCode: "04943"},
	{latitude: 44.686891, longitude: -69.643928, zipCode: "04944"},
	{latitude: 45.576628, longitude: -70.277907, zipCode: "04945"},
	{latitude: 45.046803, longitude: -70.269258, zipCode: "04947"},
	{latitude: 44.369093, longitude: -69.336, zipCode: "04949"},
	{latitude: 44.826354, longitude: -69.799713, zipCode: "04950"},
	{latitude: 44.59806, longitude: -69.058489, zipCode: "04951"},
	{latitude: 44.412796, longitude: -69.154791, zipCode: "04952"},
	{latitude: 44.867214, longitude: -69.218836, zipCode: "04953"},
	{latitude: 44.647207, longitude: -69.993337, zipCode: "04955"},
	{latitude: 44.798568, longitude: -70.096757, zipCode: "04956"},
	{latitude: 44.714264, longitude: -69.863539, zipCode: "04957"},
	{latitude: 44.909057, longitude: -69.927233, zipCode: "04958"},
	{latitude: 45.151993, longitude: -70.160662, zipCode: "04961"},
	{latitude: 44.589005, longitude: -69.887304, zipCode: "04963"},
	{latitude: 44.94362, longitude: -70.826469, zipCode: "04964"},
	{latitude: 44.843151, longitude: -69.368356, zipCode: "04965"},
	{latitude: 44.785649, longitude: -70.535355, zipCode: "04966"},
	{latitude: 44.769837, longitude: -69.433753, zipCode: "04967"},
	{latitude: 44.785282, longitude: -69.236277, zipCode: "04969"},
	{latitude: 45.007913, longitude: -70.618162, zipCode: "04970"},
	{latitude: 44.938018, longitude: -69.397198, zipCode: "04971"},
	{latitude: 44.368252, longitude: -69.186292, zipCode: "04973"},
	{latitude: 44.470906, longitude: -68.928662, zipCode: "04974"},
	{latitude: 44.625378, longitude: -69.588327, zipCode: "04975"},
	{latitude: 44.789271, longitude: -69.667734, zipCode: "04976"},
	{latitude: 44.641819, longitude: -69.79989, zipCode: "04978"},
	{latitude: 44.93938, longitude: -69.808142, zipCode: "04979"},
	{latitude: 44.514019, longitude: -68.83905, zipCode: "04981"},
	{latitude: 45.107462, longitude: -70.393952, zipCode: "04982"},
	{latitude: 44.925633, longitude: -70.286451, zipCode: "04983"},
	{latitude: 44.696586, longitude: -70.267203, zipCode: "04984"},
	{latitude: 45.377118, longitude: -69.898984, zipCode: "04985"},
	{latitude: 44.54946, longitude: -69.211292, zipCode: "04986"},
	{latitude: 44.674148, longitude: -69.251844, zipCode: "04987"},
	{latitude: 44.570148, longitude: -69.362441, zipCode: "04988"},
	{latitude: 44.429335, longitude: -69.648294, zipCode: "04989"},
	{latitude: 44.659798, longitude: -70.156068, zipCode: "04992"},
	{latitude: 43.683726, longitude: -72.376002, zipCode: "05001"},
	{latitude: 43.647933, longitude: -72.343999, zipCode: "05009"},
	{latitude: 43.39914, longitude: -72.408007, zipCode: "05030"},
	{latitude: 43.721064, longitude: -72.58047, zipCode: "05031"},
	{latitude: 43.801549, longitude: -72.657747, zipCode: "05032"},
	{latitude: 43.996652, longitude: -72.175828, zipCode: "05033"},
	{latitude: 43.591594, longitude: -72.619742, zipCode: "05034"},
	{latitude: 43.611552, longitude: -72.686042, zipCode: "05035"},
	{latitude: 44.023908, longitude: -72.587891, zipCode: "05036"},
	{latitude: 43.457608, longitude: -72.484184, zipCode: "05037"},
	{latitude: 43.991158, longitude: -72.457292, zipCode: "05038"},
	{latitude: 44.063272, longitude: -72.313076, zipCode: "05039"},
	{latitude: 44.069889, longitude: -72.198637, zipCode: "05040"},
	{latitude: 43.956573, longitude: -72.538613, zipCode: "05041"},
	{latitude: 44.244001, longitude: -72.110803, zipCode: "05042"},
	{latitude: 43.805933, longitude: -72.212129, zipCode: "05043"},
	{latitude: 43.91798, longitude: -72.180212, zipCode: "05045"},
	{latitude: 44.232453, longitude: -72.257516, zipCode: "05046"},
	{latitude: 43.580615, longitude: -72.427711, zipCode: "05048"},
	{latitude: 44.264058, longitude: -72.063843, zipCode: "05050"},
	{latitude: 44.081968, longitude: -72.116868, zipCode: "05051"},
	{latitude: 43.596986, longitude: -72.359722, zipCode: "05052"},
	{latitude: 43.713657, longitude: -72.48491, zipCode: "05053"},
	{latitude: 43.777633, longitude: -72.324007, zipCode: "05055"},
	{latitude: 43.508847, longitude: -72.693634, zipCode: "05056"},
	{latitude: 43.881581, longitude: -72.272307, zipCode: "05058"},
	{latitude: 43.638175, longitude: -72.430399, zipCode: "05059"},
	{latitude: 43.967072, longitude: -72.708604, zipCode: "05060"},
	{latitude: 43.928435, longitude: -72.573175, zipCode: "05061"},
	{latitude: 43.503098, longitude: -72.579519, zipCode: "05062"},
	{latitude: 43.790882, longitude: -72.416502, zipCode: "05065"},
	{latitude: 43.684641, longitude: -72.540071, zipCode: "05067"},
	{latitude: 43.780804, longitude: -72.548104, zipCode: "05068"},
	{latitude: 44.169638, longitude: -72.142554, zipCode: "05069"},
	{latitude: 43.851602, longitude: -72.343286, zipCode: "05070"},
	{latitude: 43.563714, longitude: -72.56482, zipCode: "05071"},
	{latitude: 43.883435, longitude: -72.386962, zipCode: "05072"},
	{latitude: 43.628412, longitude: -72.461574, zipCode: "05073"},
	{latitude: 43.845413, longitude: -72.286056, zipCode: "05075"},
	{latitude: 44.144967, longitude: -72.223957, zipCode: "05076"},
	{latitude: 43.908796, longitude: -72.459306, zipCode: "05077"},
	{latitude: 43.956765, longitude: -72.326539, zipCode: "05079"},
	{latitude: 44.137207, longitude: -72.090153, zipCode: "05081"},
	{latitude: 43.918433, longitude: -72.267806, zipCode: "05083"},
	{latitude: 43.729655, longitude: -72.451522, zipCode: "05084"},
	{latitude: 44.108949, longitude: -72.30073, zipCode: "05086"},
	{latitude: 43.675387, longitude: -72.30674, zipCode: "05088"},
	{latitude: 43.495601, longitude: -72.442254, zipCode: "05089"},
	{latitude: 43.648253, longitude: -72.55055, zipCode: "05091"},
	{latitude: 43.161506, longitude: -72.485605, zipCode: "05101"},
	{latitude: 43.158836, longitude: -72.560237, zipCode: "05141"},
	{latitude: 43.39758, longitude: -72.580345, zipCode: "05142"},
	{latitude: 43.252694, longitude: -72.643102, zipCode: "05143"},
	{latitude: 43.184942, longitude: -72.633977, zipCode: "05146"},
	{latitude: 43.244927, longitude: -72.81059, zipCode: "05148"},
	{latitude: 43.401856, longitude: -72.699211, zipCode: "05149"},
	{latitude: 43.33715, longitude: -72.529077, zipCode: "05150"},
	{latitude: 43.395317, longitude: -72.496973, zipCode: "05151"},
	{latitude: 43.232886, longitude: -72.937383, zipCode: "05152"},
	{latitude: 43.439639, longitude: -72.636542, zipCode: "05153"},
	{latitude: 43.133846, longitude: -72.5286, zipCode: "05154"},
	{latitude: 43.175084, longitude: -72.823352, zipCode: "05155"},
	{latitude: 43.310627, longitude: -72.461623, zipCode: "05156"},
	{latitude: 43.109004, longitude: -72.473476, zipCode: "05158"},
	{latitude: 43.321441, longitude: -72.802903, zipCode: "05161"},
	{latitude: 42.869216, longitude: -73.128532, zipCode: "05201"},
	{latitude: 43.103623, longitude: -73.173204, zipCode: "05250"},
	{latitude: 43.265371, longitude: -73.081661, zipCode: "05251"},
	{latitude: 43.081946, longitude: -73.066773, zipCode: "05252"},
	{latitude: 43.261773, longitude: -72.997972, zipCode: "05253"},
	{latitude: 43.160037, longitude: -73.067098, zipCode: "05254"},
	{latitude: 43.137766, longitude: -73.023673, zipCode: "05255"},
	{latitude: 42.969542, longitude: -73.26273, zipCode: "05257"},
	{latitude: 42.811616, longitude: -73.26265, zipCode: "05260"},
	{latitude: 42.768893, longitude: -73.211264, zipCode: "05261"},
	{latitude: 42.973719, longitude: -73.075343, zipCode: "05262"},
	{latitude: 42.838685, longitude: -72.659985, zipCode: "05301"},
	{latitude: 43.16888, longitude: -72.972731, zipCode: "05340"},
	{latitude: 42.974544, longitude: -72.801051, zipCode: "05341"},
	{latitude: 42.777497, longitude: -72.794821, zipCode: "05342"},
	{latitude: 43.102673, longitude: -72.820311, zipCode: "05343"},
	{latitude: 42.838938, longitude: -72.732683, zipCode: "05344"},
	{latitude: 42.987484, longitude: -72.664676, zipCode: "05345"},
	{latitude: 43.03772, longitude: -72.536529, zipCode: "05346"},
	{latitude: 42.790943, longitude: -72.97264, zipCode: "05350"},
	{latitude: 42.930206, longitude: -72.726973, zipCode: "05351"},
	{latitude: 42.80825, longitude: -73.076331, zipCode: "05352"},
	{latitude: 43.069273, longitude: -72.68046, zipCode: "05353"},
	{latitude: 42.764133, longitude: -72.522086, zipCode: "05354"},
	{latitude: 43.019478, longitude: -72.812148, zipCode: "05355"},
	{latitude: 42.975906, longitude: -72.864609, zipCode: "05356"},
	{latitude: 42.776387, longitude: -72.729629, zipCode: "05358"},
	{latitude: 43.150712, longitude: -72.724798, zipCode: "05359"},
	{latitude: 43.04185, longitude: -72.950605, zipCode: "05360"},
	{latitude: 42.787251, longitude: -72.87783, zipCode: "05361"},
	{latitude: 42.935221, longitude: -72.660424, zipCode: "05362"},
	{latitude: 42.884428, longitude: -72.900891, zipCode: "05363"},
	{latitude: 44.475181, longitude: -73.217857, zipCode: "05401"},
	{latitude: 44.444866, longitude: -73.173468, zipCode: "05403"},
	{latitude: 44.495587, longitude: -73.184857, zipCode: "05404"},
	{latitude: 44.474157, longitude: -73.196366, zipCode: "05405"},
	{latitude: 44.505238, longitude: -73.272419, zipCode: "05408"},
	{latitude: 44.495652, longitude: -73.165216, zipCode: "05439"},
	{latitude: 44.957882, longitude: -73.291974, zipCode: "05440"},
	{latitude: 44.773789, longitude: -72.787344, zipCode: "05441"},
	{latitude: 44.772519, longitude: -72.690391, zipCode: "05442"},
	{latitude: 44.139548, longitude: -73.039822, zipCode: "05443"},
	{latitude: 44.620499, longitude: -72.887348, zipCode: "05444"},
	{latitude: 44.305737, longitude: -73.235553, zipCode: "05445"},
	{latitude: 44.554142, longitude: -73.225492, zipCode: "05446"},
	{latitude: 44.94219, longitude: -72.703176, zipCode: "05447"},
	{latitude: 44.757432, longitude: -72.87831, zipCode: "05448"},
	{latitude: 44.894578, longitude: -72.795246, zipCode: "05450"},
	{latitude: 44.527171, longitude: -73.064704, zipCode: "05452"},
	{latitude: 44.710289, longitude: -73.028877, zipCode: "05454"},
	{latitude: 44.801687, longitude: -72.967393, zipCode: "05455"},
	{latitude: 44.21389, longitude: -73.262094, zipCode: "05456"},
	{latitude: 44.958722, longitude: -72.921995, zipCode: "05457"},
	{latitude: 44.716287, longitude: -73.299627, zipCode: "05458"},
	{latitude: 44.956529, longitude: -73.0172, zipCode: "05459"},
	{latitude: 44.322332, longitude: -73.094511, zipCode: "05461"},
	{latitude: 44.299412, longitude: -72.954889, zipCode: "05462"},
	{latitude: 44.871325, longitude: -73.3492, zipCode: "05463"},
	{latitude: 44.677814, longitude: -72.814778, zipCode: "05464"},
	{latitude: 44.466436, longitude: -72.919828, zipCode: "05465"},
	{latitude: 44.645908, longitude: -73.159221, zipCode: "05468"},
	{latitude: 44.828013, longitude: -72.577719, zipCode: "05471"},
	{latitude: 44.112977, longitude: -73.158362, zipCode: "05472"},
	{latitude: 44.241845, longitude: -73.19471, zipCode: "05473"},
	{latitude: 44.835081, longitude: -73.225658, zipCode: "05474"},
	{latitude: 44.955228, longitude: -72.643138, zipCode: "05476"},
	{latitude: 44.381972, longitude: -72.979626, zipCode: "05477"},
	{latitude: 44.791567, longitude: -73.117763, zipCode: "05478"},
	{latitude: 44.808335, longitude: -73.139602, zipCode: "05481"},
	{latitude: 44.392674, longitude: -73.243434, zipCode: "05482"},
	{latitude: 44.88972, longitude: -72.980935, zipCode: "05483"},
	{latitude: 44.904048, longitude: -72.97568, zipCode: "05485"},
	{latitude: 44.627092, longitude: -73.309226, zipCode: "05486"},
	{latitude: 44.210332, longitude: -72.993373, zipCode: "05487"},
	{latitude: 44.923364, longitude: -73.144286, zipCode: "05488"},
	{latitude: 44.567695, longitude: -72.902763, zipCode: "05489"},
	{latitude: 44.119779, longitude: -73.326924, zipCode: "05491"},
	{latitude: 44.737917, longitude: -72.762322, zipCode: "05492"},
	{latitude: 44.603324, longitude: -73.015994, zipCode: "05494"},
	{latitude: 44.429166, longitude: -73.09631, zipCode: "05495"},
	{latitude: 44.29264, longitude: -72.624688, zipCode: "05602"},
	{latitude: 44.340334, longitude: -72.497461, zipCode: "05640"},
	{latitude: 44.18548, longitude: -72.453593, zipCode: "05641"},
	{latitude: 44.409107, longitude: -72.293692, zipCode: "05647"},
	{latitude: 44.383292, longitude: -72.492477, zipCode: "05648"},
	{latitude: 44.138925, longitude: -72.370594, zipCode: "05649"},
	{latitude: 44.400382, longitude: -72.438165, zipCode: "05650"},
	{latitude: 44.282307, longitude: -72.489262, zipCode: "05651"},
	{latitude: 44.723893, longitude: -72.610411, zipCode: "05652"},
	{latitude: 44.714927, longitude: -72.472701, zipCode: "05653"},
	{latitude: 44.138351, longitude: -72.486668, zipCode: "05654"},
	{latitude: 44.624262, longitude: -72.570417, zipCode: "05655"},
	{latitude: 44.648124, longitude: -72.68884, zipCode: "05656"},
	{latitude: 44.364975, longitude: -72.354905, zipCode: "05658"},
	{latitude: 44.261643, longitude: -72.765399, zipCode: "05660"},
	{latitude: 44.532336, longitude: -72.620258, zipCode: "05661"},
	{latitude: 44.147616, longitude: -72.691229, zipCode: "05663"},
	{latitude: 44.167124, longitude: -72.653179, zipCode: "05664"},
	{latitude: 44.338194, longitude: -72.438856, zipCode: "05666"},
	{latitude: 44.278653, longitude: -72.402403, zipCode: "05667"},
	{latitude: 44.053298, longitude: -72.755586, zipCode: "05669"},
	{latitude: 44.177237, longitude: -72.506059, zipCode: "05670"},
	{latitude: 44.491806, longitude: -72.712611, zipCode: "05672"},
	{latitude: 44.199052, longitude: -72.840932, zipCode: "05673"},
	{latitude: 44.112526, longitude: -72.87403, zipCode: "05674"},
	{latitude: 44.076165, longitude: -72.422633, zipCode: "05675"},
	{latitude: 44.299184, longitude: -72.847232, zipCode: "05676"},
	{latitude: 44.401904, longitude: -72.698172, zipCode: "05677"},
	{latitude: 44.157314, longitude: -72.477642, zipCode: "05678"},
	{latitude: 44.108944, longitude: -72.531912, zipCode: "05679"},
	{latitude: 44.541146, longitude: -72.472944, zipCode: "05680"},
	{latitude: 44.458085, longitude: -72.379129, zipCode: "05681"},
	{latitude: 44.393305, longitude: -72.56349, zipCode: "05682"},
	{latitude: 43.624582, longitude: -72.910192, zipCode: "05701"},
	{latitude: 43.38845, longitude: -72.785262, zipCode: "05730"},
	{latitude: 43.752116, longitude: -73.294096, zipCode: "05731"},
	{latitude: 43.646595, longitude: -73.21144, zipCode: "05732"},
	{latitude: 43.810474, longitude: -73.09028, zipCode: "05733"},
	{latitude: 43.981724, longitude: -73.331937, zipCode: "05734"},
	{latitude: 43.652757, longitude: -73.161222, zipCode: "05735"},
	{latitude: 43.608152, longitude: -73.014368, zipCode: "05736"},
	{latitude: 43.777668, longitude: -72.89685, zipCode: "05737"},
	{latitude: 43.523441, longitude: -72.860171, zipCode: "05738"},
	{latitude: 43.344936, longitude: -73.028738, zipCode: "05739"},
	{latitude: 43.420081, longitude: -72.910801, zipCode: "05742"},
	{latitude: 43.6776, longitude: -73.304843, zipCode: "05743"},
	{latitude: 43.694013, longitude: -73.080457, zipCode: "05744"},
	{latitude: 43.718925, longitude: -72.790075, zipCode: "05746"},
	{latitude: 44.003669, longitude: -72.858203, zipCode: "05747"},
	{latitude: 43.905404, longitude: -72.918236, zipCode: "05748"},
	{latitude: 43.657594, longitude: -72.784406, zipCode: "05751"},
	{latitude: 43.997783, longitude: -73.178295, zipCode: "05753"},
	{latitude: 43.482727, longitude: -73.124025, zipCode: "05757"},
	{latitude: 43.451487, longitude: -72.793361, zipCode: "05758"},
	{latitude: 43.53211, longitude: -72.9648, zipCode: "05759"},
	{latitude: 43.800615, longitude: -73.289701, zipCode: "05760"},
	{latitude: 43.334617, longitude: -73.13932, zipCode: "05761"},
	{latitude: 43.80423, longitude: -72.836897, zipCode: "05762"},
	{latitude: 43.730162, longitude: -72.995256, zipCode: "05763"},
	{latitude: 43.528324, longitude: -73.194344, zipCode: "05764"},
	{latitude: 43.648333, longitude: -73.034445, zipCode: "05765"},
	{latitude: 43.984937, longitude: -72.996775, zipCode: "05766"},
	{latitude: 43.879408, longitude: -72.831685, zipCode: "05767"},
	{latitude: 43.296861, longitude: -73.219222, zipCode: "05768"},
	{latitude: 43.932071, longitude: -73.119822, zipCode: "05769"},
	{latitude: 43.890204, longitude: -73.329851, zipCode: "05770"},
	{latitude: 43.76435, longitude: -72.739204, zipCode: "05772"},
	{latitude: 43.427094, longitude: -72.949941, zipCode: "05773"},
	{latitude: 43.428719, longitude: -73.170257, zipCode: "05774"},
	{latitude: 43.354273, longitude: -73.222094, zipCode: "05775"},
	{latitude: 43.252854, longitude: -73.197348, zipCode: "05776"},
	{latitude: 43.556432, longitude: -73.048858, zipCode: "05777"},
	{latitude: 43.876397, longitude: -73.212573, zipCode: "05778"},
	{latitude: 44.404723, longitude: -71.983074, zipCode: "05819"},
	{latitude: 44.763154, longitude: -72.375244, zipCode: "05820"},
	{latitude: 44.318773, longitude: -72.108914, zipCode: "05821"},
	{latitude: 44.754263, longitude: -72.15968, zipCode: "05822"},
	{latitude: 44.439085, longitude: -71.826104, zipCode: "05824"},
	{latitude: 44.858403, longitude: -72.25285, zipCode: "05825"},
	{latitude: 44.634876, longitude: -72.406149, zipCode: "05826"},
	{latitude: 44.676706, longitude: -72.362574, zipCode: "05827"},
	{latitude: 44.437504, longitude: -72.124822, zipCode: "05828"},
	{latitude: 44.967287, longitude: -72.068061, zipCode: "05829"},
	{latitude: 44.9721, longitude: -71.986969, zipCode: "05830"},
	{latitude: 44.594146, longitude: -71.898695, zipCode: "05832"},
	{latitude: 44.837389, longitude: -71.977498, zipCode: "05833"},
	{latitude: 44.518599, longitude: -72.230774, zipCode: "05836"},
	{latitude: 44.663758, longitude: -71.80518, zipCode: "05837"},
	{latitude: 44.673683, longitude: -72.207014, zipCode: "05839"},
	{latitude: 44.598729, longitude: -71.747173, zipCode: "05840"},
	{latitude: 44.617792, longitude: -72.289292, zipCode: "05841"},
	{latitude: 44.590054, longitude: -72.245075, zipCode: "05842"},
	{latitude: 44.503373, longitude: -72.341593, zipCode: "05843"},
	{latitude: 44.800059, longitude: -72.331931, zipCode: "05845"},
	{latitude: 44.816691, longitude: -71.835318, zipCode: "05846"},
	{latitude: 44.793437, longitude: -72.458451, zipCode: "05847"},
	{latitude: 44.563804, longitude: -72.035658, zipCode: "05850"},
	{latitude: 44.546295, longitude: -72.048869, zipCode: "05851"},
	{latitude: 44.896128, longitude: -71.941243, zipCode: "05853"},
	{latitude: 44.927008, longitude: -72.19244, zipCode: "05855"},
	{latitude: 44.928911, longitude: -72.3127, zipCode: "05857"},
	{latitude: 44.538162, longitude: -71.800799, zipCode: "05858"},
	{latitude: 44.95827, longitude: -72.438189, zipCode: "05859"},
	{latitude: 44.80266, longitude: -72.103996, zipCode: "05860"},
	{latitude: 44.318185, longitude: -72.216116, zipCode: "05862"},
	{latitude: 44.640739, longitude: -72.110885, zipCode: "05866"},
	{latitude: 44.665071, longitude: -72.035973, zipCode: "05867"},
	{latitude: 44.942293, longitude: -72.354214, zipCode: "05868"},
	{latitude: 44.682332, longitude: -71.952739, zipCode: "05871"},
	{latitude: 44.870982, longitude: -72.025024, zipCode: "05872"},
	{latitude: 44.405064, longitude: -72.216725, zipCode: "05873"},
	{latitude: 44.883477, longitude: -72.484927, zipCode: "05874"},
	{latitude: 44.705368, longitude: -72.270894, zipCode: "05875"},
	{latitude: 44.940719, longitude: -71.654624, zipCode: "05901"},
	{latitude: 45.009587, longitude: -71.487927, zipCode: "05902"},
	{latitude: 44.93502, longitude: -71.588126, zipCode: "05903"},
	{latitude: 44.416182, longitude: -71.706874, zipCode: "05904"},
	{latitude: 44.702779, longitude: -71.677771, zipCode: "05905"},
	{latitude: 44.483097, longitude: -71.720449, zipCode: "05906"},
	{latitude: 44.954734, longitude: -71.82734, zipCode: "05907"},
	{latitude: 41.79404, longitude: -72.85398, zipCode: "06001"},
	{latitude: 41.846675, longitude: -72.736981, zipCode: "06002"},
	{latitude: 41.681578, longitude: -72.940749, zipCode: "06010"},
	{latitude: 41.749079, longitude: -72.948657, zipCode: "06013"},
	{latitude: 41.901899, longitude: -72.547432, zipCode: "06016"},
	{latitude: 42.036731, longitude: -73.311594, zipCode: "06018"},
	{latitude: 41.857509, longitude: -72.902766, zipCode: "06019"},
	{latitude: 41.862788, longitude: -72.909934, zipCode: "06020"},
	{latitude: 42.005797, longitude: -73.064184, zipCode: "06021"},
	{latitude: 41.865935, longitude: -72.927452, zipCode: "06022"},
	{latitude: 41.612592, longitude: -72.720093, zipCode: "06023"},
	{latitude: 42.008206, longitude: -73.283732, zipCode: "06024"},
	{latitude: 41.929719, longitude: -72.745378, zipCode: "06026"},
	{latitude: 42.00493, longitude: -72.9157, zipCode: "06027"},
	{latitude: 41.914149, longitude: -72.445568, zipCode: "06029"},
	{latitude: 41.955257, longitude: -73.316785, zipCode: "06031"},
	{latitude: 41.731051, longitude: -72.857096, zipCode: "06032"},
	{latitude: 41.707463, longitude: -72.538932, zipCode: "06033"},
	{latitude: 41.960563, longitude: -72.804593, zipCode: "06035"},
	{latitude: 41.605451, longitude: -72.778497, zipCode: "06037"},
	{latitude: 41.961998, longitude: -73.444375, zipCode: "06039"},
	{latitude: 41.761415, longitude: -72.525608, zipCode: "06040"},
	{latitude: 41.802645, longitude: -72.521039, zipCode: "06042"},
	{latitude: 41.764589, longitude: -72.437575, zipCode: "06043"},
	{latitude: 41.665179, longitude: -72.769865, zipCode: "06051"},
	{latitude: 41.655606, longitude: -72.803134, zipCode: "06052"},
	{latitude: 41.690145, longitude: -72.79194, zipCode: "06053"},
	{latitude: 41.841053, longitude: -72.998664, zipCode: "06057"},
	{latitude: 41.969773, longitude: -73.178439, zipCode: "06058"},
	{latitude: 41.89053, longitude: -72.888298, zipCode: "06059"},
	{latitude: 42.014741, longitude: -72.85926, zipCode: "06060"},
	{latitude: 41.875207, longitude: -72.967042, zipCode: "06061"},
	{latitude: 41.673639, longitude: -72.859729, zipCode: "06062"},
	{latitude: 41.935708, longitude: -72.972921, zipCode: "06063"},
	{latitude: 41.974681, longitude: -73.007663, zipCode: "06065"},
	{latitude: 41.837561, longitude: -72.45904, zipCode: "06066"},
	{latitude: 41.656624, longitude: -72.661779, zipCode: "06067"},
	{latitude: 42.007238, longitude: -73.422228, zipCode: "06068"},
	{latitude: 41.860763, longitude: -73.449215, zipCode: "06069"},
	{latitude: 41.874682, longitude: -72.813825, zipCode: "06070"},
	{latitude: 41.992633, longitude: -72.453725, zipCode: "06071"},
	{latitude: 41.984664, longitude: -72.487589, zipCode: "06072"},
	{latitude: 41.660572, longitude: -72.568477, zipCode: "06073"},
	{latitude: 41.83809, longitude: -72.577836, zipCode: "06074"},
	{latitude: 41.984442, longitude: -72.263453, zipCode: "06076"},
	{latitude: 41.991585, longitude: -72.651762, zipCode: "06078"},
	{latitude: 42.041768, longitude: -73.397479, zipCode: "06079"},
	{latitude: 41.950485, longitude: -72.635178, zipCode: "06080"},
	{latitude: 41.907559, longitude: -72.76818, zipCode: "06081"},
	{latitude: 41.983993, longitude: -72.555553, zipCode: "06082"},
	{latitude: 41.883019, longitude: -72.358869, zipCode: "06084"},
	{latitude: 41.7489, longitude: -72.889435, zipCode: "06085"},
	{latitude: 41.917911, longitude: -72.583371, zipCode: "06088"},
	{latitude: 41.835348, longitude: -72.824272, zipCode: "06089"},
	{latitude: 41.946863, longitude: -72.864044, zipCode: "06090"},
	{latitude: 42.026452, longitude: -72.980213, zipCode: "06091"},
	{latitude: 41.873452, longitude: -72.860524, zipCode: "06092"},
	{latitude: 42.013241, longitude: -72.717313, zipCode: "06093"},
	{latitude: 41.873832, longitude: -72.676805, zipCode: "06095"},
	{latitude: 41.92779, longitude: -72.651616, zipCode: "06096"},
	{latitude: 41.927213, longitude: -73.109336, zipCode: "06098"},
	{latitude: 41.765103, longitude: -72.670756, zipCode: "06103"},
	{latitude: 41.773262, longitude: -72.704433, zipCode: "06105"},
	{latitude: 41.748587, longitude: -72.696006, zipCode: "06106"},
	{latitude: 41.75202, longitude: -72.759365, zipCode: "06107"},
	{latitude: 41.780375, longitude: -72.623945, zipCode: "06108"},
	{latitude: 41.702484, longitude: -72.669301, zipCode: "06109"},
	{latitude: 41.732455, longitude: -72.73375, zipCode: "06110"},
	{latitude: 41.686764, longitude: -72.730593, zipCode: "06111"},
	{latitude: 41.793336, longitude: -72.695823, zipCode: "06112"},
	{latitude: 41.740854, longitude: -72.670695, zipCode: "06114"},
	{latitude: 41.789101, longitude: -72.763127, zipCode: "06117"},
	{latitude: 41.748711, longitude: -72.609519, zipCode: "06118"},
	{latitude: 41.763521, longitude: -72.727113, zipCode: "06119"},
	{latitude: 41.787198, longitude: -72.664837, zipCode: "06120"},
	{latitude: 41.708239, longitude: -72.208548, zipCode: "06226"},
	{latitude: 41.632936, longitude: -72.367347, zipCode: "06231"},
	{latitude: 41.732982, longitude: -72.374658, zipCode: "06232"},
	{latitude: 41.793886, longitude: -71.953265, zipCode: "06234"},
	{latitude: 41.79089, longitude: -72.12954, zipCode: "06235"},
	{latitude: 41.693707, longitude: -72.305671, zipCode: "06237"},
	{latitude: 41.780212, longitude: -72.343758, zipCode: "06238"},
	{latitude: 41.79404, longitude: -71.854535, zipCode: "06239"},
	{latitude: 41.853901, longitude: -71.847371, zipCode: "06241"},
	{latitude: 41.89559, longitude: -72.093304, zipCode: "06242"},
	{latitude: 41.84487, longitude: -71.805029, zipCode: "06243"},
	{latitude: 41.767801, longitude: -72.075026, zipCode: "06247"},
	{latitude: 41.688585, longitude: -72.408671, zipCode: "06248"},
	{latitude: 41.632955, longitude: -72.24009, zipCode: "06249"},
	{latitude: 41.772955, longitude: -72.198446, zipCode: "06250"},
	{latitude: 41.621212, longitude: -72.142609, zipCode: "06254"},
	{latitude: 41.991087, longitude: -71.901952, zipCode: "06255"},
	{latitude: 41.732152, longitude: -72.157361, zipCode: "06256"},
	{latitude: 41.88566, longitude: -71.963527, zipCode: "06258"},
	{latitude: 41.871502, longitude: -71.987646, zipCode: "06259"},
	{latitude: 41.908027, longitude: -71.870452, zipCode: "06260"},
	{latitude: 42.019311, longitude: -71.948384, zipCode: "06262"},
	{latitude: 41.837285, longitude: -71.904243, zipCode: "06263"},
	{latitude: 41.698634, longitude: -72.104795, zipCode: "06264"},
	{latitude: 41.673661, longitude: -72.172813, zipCode: "06266"},
	{latitude: 41.798793, longitude: -72.248368, zipCode: "06268"},
	{latitude: 41.808938, longitude: -72.251617, zipCode: "06269"},
	{latitude: 41.958388, longitude: -71.84392, zipCode: "06277"},
	{latitude: 41.897123, longitude: -72.171427, zipCode: "06278"},
	{latitude: 41.897267, longitude: -72.251848, zipCode: "06279"},
	{latitude: 41.690986, longitude: -72.130094, zipCode: "06280"},
	{latitude: 41.973476, longitude: -72.014137, zipCode: "06281"},
	{latitude: 41.94887, longitude: -72.08482, zipCode: "06282"},
	{latitude: 41.348837, longitude: -72.102029, zipCode: "06320"},
	{latitude: 41.63557, longitude: -72.077527, zipCode: "06330"},
	{latitude: 41.69396, longitude: -72.009118, zipCode: "06331"},
	{latitude: 41.722809, longitude: -71.902416, zipCode: "06332"},
	{latitude: 41.386032, longitude: -72.229092, zipCode: "06333"},
	{latitude: 41.544925, longitude: -72.173849, zipCode: "06334"},
	{latitude: 41.434707, longitude: -72.058533, zipCode: "06335"},
	{latitude: 41.580577, longitude: -72.195867, zipCode: "06336"},
	{latitude: 41.464584, longitude: -71.972828, zipCode: "06338"},
	{latitude: 41.441939, longitude: -71.990545, zipCode: "06339"},
	{latitude: 41.354409, longitude: -72.040266, zipCode: "06340"},
	{latitude: 41.648256, longitude: -72.069227, zipCode: "06350"},
	{latitude: 41.589788, longitude: -71.948385, zipCode: "06351"},
	{latitude: 41.455998, longitude: -72.138986, zipCode: "06353"},
	{latitude: 41.708766, longitude: -71.855386, zipCode: "06354"},
	{latitude: 41.365673, longitude: -71.97196, zipCode: "06355"},
	{latitude: 41.317659, longitude: -72.21856, zipCode: "06357"},
	{latitude: 41.470969, longitude: -71.872525, zipCode: "06359"},
	{latitude: 41.548006, longitude: -72.089454, zipCode: "06360"},
	{latitude: 41.518652, longitude: -72.006597, zipCode: "06365"},
	{latitude: 41.467094, longitude: -72.189397, zipCode: "06370"},
	{latitude: 41.362386, longitude: -72.323754, zipCode: "06371"},
	{latitude: 41.685382, longitude: -71.805355, zipCode: "06373"},
	{latitude: 41.679654, longitude: -71.912168, zipCode: "06374"},
	{latitude: 41.406338, longitude: -72.123236, zipCode: "06375"},
	{latitude: 41.294494, longitude: -72.252858, zipCode: "06376"},
	{latitude: 41.730545, longitude: -71.819313, zipCode: "06377"},
	{latitude: 41.383211, longitude: -71.908408, zipCode: "06378"},
	{latitude: 41.363472, longitude: -71.853197, zipCode: "06379"},
	{latitude: 41.563417, longitude: -72.051631, zipCode: "06380"},
	{latitude: 41.462643, longitude: -72.124869, zipCode: "06382"},
	{latitude: 41.579192, longitude: -71.831228, zipCode: "06384"},
	{latitude: 41.36173, longitude: -72.156576, zipCode: "06385"},
	{latitude: 41.744107, longitude: -71.909865, zipCode: "06387"},
	{latitude: 41.560396, longitude: -72.123465, zipCode: "06389"},
	{latitude: 41.287945, longitude: -71.932551, zipCode: "06390"},
	{latitude: 41.344249, longitude: -73.069825, zipCode: "06401"},
	{latitude: 41.443685, longitude: -73.051925, zipCode: "06403"},
	{latitude: 41.279949, longitude: -72.78595, zipCode: "06405"},
	{latitude: 41.348139, longitude: -72.42936, zipCode: "06409"},
	{latitude: 41.511827, longitude: -72.903617, zipCode: "06410"},
	{latitude: 41.411728, longitude: -72.487146, zipCode: "06412"},
	{latitude: 41.29561, longitude: -72.529125, zipCode: "06413"},
	{latitude: 41.577044, longitude: -72.552334, zipCode: "06414"},
	{latitude: 41.550291, longitude: -72.348616, zipCode: "06415"},
	{latitude: 41.616609, longitude: -72.66156, zipCode: "06416"},
	{latitude: 41.367819, longitude: -72.477442, zipCode: "06417"},
	{latitude: 41.326124, longitude: -73.082574, zipCode: "06418"},
	{latitude: 41.374419, longitude: -72.578328, zipCode: "06419"},
	{latitude: 41.489351, longitude: -72.25838, zipCode: "06420"},
	{latitude: 41.468922, longitude: -72.684525, zipCode: "06422"},
	{latitude: 41.456609, longitude: -72.391351, zipCode: "06423"},
	{latitude: 41.560158, longitude: -72.502765, zipCode: "06424"},
	{latitude: 41.351136, longitude: -72.397164, zipCode: "06426"},
	{latitude: 41.331777, longitude: -72.696816, zipCode: "06437"},
	{latitude: 41.450777, longitude: -72.509946, zipCode: "06438"},
	{latitude: 41.41568, longitude: -72.399699, zipCode: "06439"},
	{latitude: 41.465625, longitude: -72.582638, zipCode: "06441"},
	{latitude: 41.342069, longitude: -72.43407, zipCode: "06442"},
	{latitude: 41.344479, longitude: -72.624543, zipCode: "06443"},
	{latitude: 41.563395, longitude: -72.930341, zipCode: "06444"},
	{latitude: 41.636439, longitude: -72.454002, zipCode: "06447"},
	{latitude: 41.535812, longitude: -72.775686, zipCode: "06450"},
	{latitude: 41.541906, longitude: -72.823409, zipCode: "06451"},
	{latitude: 41.514163, longitude: -72.717844, zipCode: "06455"},
	{latitude: 41.539735, longitude: -72.548288, zipCode: "06456"},
	{latitude: 41.544209, longitude: -72.657501, zipCode: "06457"},
	{latitude: 41.549892, longitude: -72.661536, zipCode: "06459"},
	{latitude: 41.218799, longitude: -73.045626, zipCode: "06460"},
	{latitude: 41.239865, longitude: -73.075394, zipCode: "06461"},
	{latitude: 41.565463, longitude: -72.900239, zipCode: "06467"},
	{latitude: 41.339236, longitude: -73.222828, zipCode: "06468"},
	{latitude: 41.506893, longitude: -72.448279, zipCode: "06469"},
	{latitude: 41.395083, longitude: -73.317663, zipCode: "06470"},
	{latitude: 41.333791, longitude: -72.771853, zipCode: "06471"},
	{latitude: 41.393435, longitude: -72.771944, zipCode: "06472"},
	{latitude: 41.381465, longitude: -72.856371, zipCode: "06473"},
	{latitude: 41.299897, longitude: -72.382734, zipCode: "06475"},
	{latitude: 41.284951, longitude: -73.024637, zipCode: "06477"},
	{latitude: 41.444033, longitude: -73.148235, zipCode: "06478"},
	{latitude: 41.57473, longitude: -72.911945, zipCode: "06479"},
	{latitude: 41.598834, longitude: -72.589071, zipCode: "06480"},
	{latitude: 41.536737, longitude: -72.697938, zipCode: "06481"},
	{latitude: 41.409, longitude: -73.24263, zipCode: "06482"},
	{latitude: 41.385466, longitude: -73.083641, zipCode: "06483"},
	{latitude: 41.304577, longitude: -73.139215, zipCode: "06484"},
	{latitude: 41.475986, longitude: -73.229692, zipCode: "06488"},
	{latitude: 41.614168, longitude: -72.869915, zipCode: "06489"},
	{latitude: 41.462725, longitude: -72.805069, zipCode: "06492"},
	{latitude: 41.303695, longitude: -72.478017, zipCode: "06498"},
	{latitude: 41.306193, longitude: -72.926613, zipCode: "06510"},
	{latitude: 41.31727, longitude: -72.926373, zipCode: "06511"},
	{latitude: 41.278267, longitude: -72.875251, zipCode: "06512"},
	{latitude: 41.318943, longitude: -72.869021, zipCode: "06513"},
	{latitude: 41.376803, longitude: -72.942752, zipCode: "06514"},
	{latitude: 41.328039, longitude: -72.97084, zipCode: "06515"},
	{latitude: 41.272573, longitude: -72.964967, zipCode: "06516"},
	{latitude: 41.34944, longitude: -72.907149, zipCode: "06517"},
	{latitude: 41.430879, longitude: -72.91199, zipCode: "06518"},
	{latitude: 41.293122, longitude: -72.932102, zipCode: "06519"},
	{latitude: 41.422964, longitude: -72.994187, zipCode: "06524"},
	{latitude: 41.363536, longitude: -73.003891, zipCode: "06525"},
	{latitude: 41.15438, longitude: -73.210932, zipCode: "06604"},
	{latitude: 41.161599, longitude: -73.217622, zipCode: "06605"},
	{latitude: 41.21211, longitude: -73.206673, zipCode: "06606"},
	{latitude: 41.170744, longitude: -73.168038, zipCode: "06607"},
	{latitude: 41.186475, longitude: -73.181251, zipCode: "06608"},
	{latitude: 41.209221, longitude: -73.164656, zipCode: "06610"},
	{latitude: 41.272891, longitude: -73.211899, zipCode: "06611"},
	{latitude: 41.264945, longitude: -73.300479, zipCode: "06612"},
	{latitude: 41.228477, longitude: -73.129081, zipCode: "06614"},
	{latitude: 41.163027, longitude: -73.128108, zipCode: "06615"},
	{latitude: 41.556461, longitude: -73.045886, zipCode: "06702"},
	{latitude: 41.588739, longitude: -73.035267, zipCode: "06704"},
	{latitude: 41.549741, longitude: -72.993435, zipCode: "06705"},
	{latitude: 41.532209, longitude: -73.025052, zipCode: "06706"},
	{latitude: 41.550619, longitude: -73.071234, zipCode: "06708"},
	{latitude: 41.569601, longitude: -73.04596, zipCode: "06710"},
	{latitude: 41.499247, longitude: -72.975634, zipCode: "06712"},
	{latitude: 41.59503, longitude: -72.968514, zipCode: "06716"},
	{latitude: 41.723861, longitude: -73.26286, zipCode: "06750"},
	{latitude: 41.635759, longitude: -73.211422, zipCode: "06751"},
	{latitude: 41.520294, longitude: -73.361093, zipCode: "06752"},
	{latitude: 41.825193, longitude: -73.344628, zipCode: "06753"},
	{latitude: 41.754627, longitude: -73.368216, zipCode: "06754"},
	{latitude: 41.649317, longitude: -73.480094, zipCode: "06755"},
	{latitude: 41.847077, longitude: -73.237799, zipCode: "06756"},
	{latitude: 41.739665, longitude: -73.467273, zipCode: "06757"},
	{latitude: 41.676348, longitude: -73.227358, zipCode: "06758"},
	{latitude: 41.760643, longitude: -73.219153, zipCode: "06759"},
	{latitude: 41.524691, longitude: -73.123016, zipCode: "06762"},
	{latitude: 41.694323, longitude: -73.209998, zipCode: "06763"},
	{latitude: 41.488649, longitude: -73.051751, zipCode: "06770"},
	{latitude: 41.601267, longitude: -73.422807, zipCode: "06776"},
	{latitude: 41.691307, longitude: -73.334403, zipCode: "06777"},
	{latitude: 41.718478, longitude: -73.104452, zipCode: "06778"},
	{latitude: 41.596057, longitude: -73.081442, zipCode: "06779"},
	{latitude: 41.642653, longitude: -73.041508, zipCode: "06782"},
	{latitude: 41.555532, longitude: -73.296026, zipCode: "06783"},
	{latitude: 41.579418, longitude: -73.49769, zipCode: "06784"},
	{latitude: 41.708536, longitude: -73.449546, zipCode: "06785"},
	{latitude: 41.672727, longitude: -73.018042, zipCode: "06786"},
	{latitude: 41.67108, longitude: -73.085046, zipCode: "06787"},
	{latitude: 41.8342, longitude: -73.13147, zipCode: "06790"},
	{latitude: 41.761345, longitude: -73.060443, zipCode: "06791"},
	{latitude: 41.633712, longitude: -73.288916, zipCode: "06793"},
	{latitude: 41.649315, longitude: -73.32513, zipCode: "06794"},
	{latitude: 41.623892, longitude: -73.126195, zipCode: "06795"},
	{latitude: 41.873953, longitude: -73.333633, zipCode: "06796"},
	{latitude: 41.561632, longitude: -73.206096, zipCode: "06798"},
	{latitude: 41.369778, longitude: -73.389503, zipCode: "06801"},
	{latitude: 41.463218, longitude: -73.393311, zipCode: "06804"},
	{latitude: 41.056732, longitude: -73.592023, zipCode: "06807"},
	{latitude: 41.377849, longitude: -73.457463, zipCode: "06810"},
	{latitude: 41.422581, longitude: -73.478352, zipCode: "06811"},
	{latitude: 41.480318, longitude: -73.486863, zipCode: "06812"},
	{latitude: 41.076202, longitude: -73.48008, zipCode: "06820"},
	{latitude: 41.173039, longitude: -73.280818, zipCode: "06824"},
	{latitude: 41.196583, longitude: -73.243254, zipCode: "06825"},
	{latitude: 41.037603, longitude: -73.624339, zipCode: "06830"},
	{latitude: 41.088724, longitude: -73.658838, zipCode: "06831"},
	{latitude: 41.160393, longitude: -73.500124, zipCode: "06840"},
	{latitude: 41.127423, longitude: -73.445264, zipCode: "06850"},
	{latitude: 41.138233, longitude: -73.401526, zipCode: "06851"},
	{latitude: 41.066116, longitude: -73.438002, zipCode: "06853"},
	{latitude: 41.090896, longitude: -73.428788, zipCode: "06854"},
	{latitude: 41.07174, longitude: -73.390002, zipCode: "06855"},
	{latitude: 41.024446, longitude: -73.57115, zipCode: "06870"},
	{latitude: 41.317675, longitude: -73.499837, zipCode: "06877"},
	{latitude: 41.030093, longitude: -73.583719, zipCode: "06878"},
	{latitude: 41.142752, longitude: -73.343614, zipCode: "06880"},
	{latitude: 41.227413, longitude: -73.367061, zipCode: "06883"},
	{latitude: 41.148565, longitude: -73.28778, zipCode: "06890"},
	{latitude: 41.305415, longitude: -73.392584, zipCode: "06896"},
	{latitude: 41.211277, longitude: -73.440977, zipCode: "06897"},
	{latitude: 41.053635, longitude: -73.538437, zipCode: "06901"},
	{latitude: 41.059347, longitude: -73.544384, zipCode: "06902"},
	{latitude: 41.136018, longitude: -73.571142, zipCode: "06903"},
	{latitude: 41.087781, longitude: -73.543575, zipCode: "06905"},
	{latitude: 41.072342, longitude: -73.522189, zipCode: "06906"},
	{latitude: 41.101431, longitude: -73.519581, zipCode: "06907"},
	{latitude: 40.583961, longitude: -74.269704, zipCode: "07001"},
	{latitude: 40.662495, longitude: -74.110201, zipCode: "07002"},
	{latitude: 40.809141, longitude: -74.187142, zipCode: "07003"},
	{latitude: 40.882508, longitude: -74.304593, zipCode: "07004"},
	{latitude: 40.93035, longitude: -74.411123, zipCode: "07005"},
	{latitude: 40.851187, longitude: -74.282865, zipCode: "07006"},
	{latitude: 40.58379, longitude: -74.227457, zipCode: "07008"},
	{latitude: 40.858017, longitude: -74.229784, zipCode: "07009"},
	{latitude: 40.822052, longitude: -73.987938, zipCode: "07010"},
	{latitude: 40.880427, longitude: -74.143246, zipCode: "07011"},
	{latitude: 40.846899, longitude: -74.160537, zipCode: "07012"},
	{latitude: 40.869405, longitude: -74.173062, zipCode: "07013"},
	{latitude: 40.831339, longitude: -74.135419, zipCode: "07014"},
	{latitude: 40.65755, longitude: -74.303632, zipCode: "07016"},
	{latitude: 40.771918, longitude: -74.207082, zipCode: "07017"},
	{latitude: 40.755733, longitude: -74.217917, zipCode: "07018"},
	{latitude: 40.82465, longitude: -73.973793, zipCode: "07020"},
	{latitude: 40.828102, longitude: -74.276226, zipCode: "07021"},
	{latitude: 40.82111, longitude: -74.003032, zipCode: "07022"},
	{latitude: 40.641701, longitude: -74.385692, zipCode: "07023"},
	{latitude: 40.85064, longitude: -73.971007, zipCode: "07024"},
	{latitude: 40.879797, longitude: -74.10825, zipCode: "07026"},
	{latitude: 40.651331, longitude: -74.323152, zipCode: "07027"},
	{latitude: 40.804798, longitude: -74.204569, zipCode: "07028"},
	{latitude: 40.74362, longitude: -74.154901, zipCode: "07029"},
	{latitude: 40.745251, longitude: -74.027926, zipCode: "07030"},
	{latitude: 40.786256, longitude: -74.12622, zipCode: "07031"},
	{latitude: 40.752332, longitude: -74.123093, zipCode: "07032"},
	{latitude: 40.678089, longitude: -74.288114, zipCode: "07033"},
	{latitude: 40.87948, longitude: -74.380104, zipCode: "07034"},
	{latitude: 40.927501, longitude: -74.308334, zipCode: "07035"},
	{latitude: 40.624711, longitude: -74.239362, zipCode: "07036"},
	{latitude: 40.785828, longitude: -74.3291, zipCode: "07039"},
	{latitude: 40.733607, longitude: -74.271159, zipCode: "07040"},
	{latitude: 40.722448, longitude: -74.301546, zipCode: "07041"},
	{latitude: 40.813487, longitude: -74.219011, zipCode: "07042"},
	{latitude: 40.844837, longitude: -74.200502, zipCode: "07043"},
	{latitude: 40.832468, longitude: -74.242863, zipCode: "07044"},
	{latitude: 40.906942, longitude: -74.365608, zipCode: "07045"},
	{latitude: 40.890853, longitude: -74.442032, zipCode: "07046"},
	{latitude: 40.793644, longitude: -74.025027, zipCode: "07047"},
	{latitude: 40.76804, longitude: -74.235692, zipCode: "07050"},
	{latitude: 40.785755, longitude: -74.265058, zipCode: "07052"},
	{latitude: 40.853767, longitude: -74.401574, zipCode: "07054"},
	{latitude: 40.857552, longitude: -74.129089, zipCode: "07055"},
	{latitude: 40.853084, longitude: -74.106323, zipCode: "07057"},
	{latitude: 40.869901, longitude: -74.344241, zipCode: "07058"},
	{latitude: 40.634588, longitude: -74.519044, zipCode: "07059"},
	{latitude: 40.616667, longitude: -74.422042, zipCode: "07060"},
	{latitude: 40.631253, longitude: -74.403884, zipCode: "07062"},
	{latitude: 40.605118, longitude: -74.444522, zipCode: "07063"},
	{latitude: 40.567422, longitude: -74.244933, zipCode: "07064"},
	{latitude: 40.607152, longitude: -74.280531, zipCode: "07065"},
	{latitude: 40.62065, longitude: -74.309862, zipCode: "07066"},
	{latitude: 40.589329, longitude: -74.311415, zipCode: "07067"},
	{latitude: 40.821737, longitude: -74.310147, zipCode: "07068"},
	{latitude: 40.642845, longitude: -74.436233, zipCode: "07069"},
	{latitude: 40.820314, longitude: -74.106041, zipCode: "07070"},
	{latitude: 40.797113, longitude: -74.11298, zipCode: "07071"},
	{latitude: 40.826431, longitude: -74.062335, zipCode: "07072"},
	{latitude: 40.817097, longitude: -74.085024, zipCode: "07073"},
	{latitude: 40.838144, longitude: -74.055077, zipCode: "07074"},
	{latitude: 40.850183, longitude: -74.087068, zipCode: "07075"},
	{latitude: 40.633029, longitude: -74.372899, zipCode: "07076"},
	{latitude: 40.554147, longitude: -74.25306, zipCode: "07077"},
	{latitude: 40.742264, longitude: -74.327574, zipCode: "07078"},
	{latitude: 40.748811, longitude: -74.261512, zipCode: "07079"},
	{latitude: 40.574413, longitude: -74.4148, zipCode: "07080"},
	{latitude: 40.697966, longitude: -74.334436, zipCode: "07081"},
	{latitude: 40.926724, longitude: -74.345512, zipCode: "07082"},
	{latitude: 40.695266, longitude: -74.269078, zipCode: "07083"},
	{latitude: 40.76801, longitude: -74.017008, zipCode: "07086"},
	{latitude: 40.767224, longitude: -74.032429, zipCode: "07087"},
	{latitude: 40.718342, longitude: -74.283764, zipCode: "07088"},
	{latitude: 40.651644, longitude: -74.343447, zipCode: "07090"},
	{latitude: 40.680722, longitude: -74.360292, zipCode: "07092"},
	{latitude: 40.786704, longitude: -74.008909, zipCode: "07093"},
	{latitude: 40.781958, longitude: -74.067649, zipCode: "07094"},
	{latitude: 40.552857, longitude: -74.286939, zipCode: "07095"},
	{latitude: 40.735659, longitude: -74.173605, zipCode: "07102"},
	{latitude: 40.738852, longitude: -74.195264, zipCode: "07103"},
	{latitude: 40.767713, longitude: -74.16835, zipCode: "07104"},
	{latitude: 40.723066, longitude: -74.138636, zipCode: "07105"},
	{latitude: 40.741768, longitude: -74.230452, zipCode: "07106"},
	{latitude: 40.762918, longitude: -74.186559, zipCode: "07107"},
	{latitude: 40.723104, longitude: -74.200386, zipCode: "07108"},
	{latitude: 40.793481, longitude: -74.161159, zipCode: "07109"},
	{latitude: 40.820616, longitude: -74.15625, zipCode: "07110"},
	{latitude: 40.723859, longitude: -74.232522, zipCode: "07111"},
	{latitude: 40.711391, longitude: -74.211683, zipCode: "07112"},
	{latitude: 40.697812, longitude: -74.17081, zipCode: "07114"},
	{latitude: 40.671272, longitude: -74.177358, zipCode: "07201"},
	{latitude: 40.652464, longitude: -74.216853, zipCode: "07202"},
	{latitude: 40.652211, longitude: -74.260158, zipCode: "07203"},
	{latitude: 40.665312, longitude: -74.266555, zipCode: "07204"},
	{latitude: 40.695889, longitude: -74.2288, zipCode: "07205"},
	{latitude: 40.652083, longitude: -74.183756, zipCode: "07206"},
	{latitude: 40.672913, longitude: -74.224047, zipCode: "07208"},
	{latitude: 40.718808, longitude: -74.044634, zipCode: "07302"},
	{latitude: 40.717303, longitude: -74.076352, zipCode: "07304"},
	{latitude: 40.693004, longitude: -74.06965, zipCode: "07305"},
	{latitude: 40.736294, longitude: -74.071128, zipCode: "07306"},
	{latitude: 40.751842, longitude: -74.053976, zipCode: "07307"},
	{latitude: 40.72847, longitude: -74.033684, zipCode: "07310"},
	{latitude: 40.719076, longitude: -74.032619, zipCode: "07311"},
	{latitude: 41.032669, longitude: -74.133826, zipCode: "07401"},
	{latitude: 41.020753, longitude: -74.332842, zipCode: "07403"},
	{latitude: 40.987841, longitude: -74.378188, zipCode: "07405"},
	{latitude: 40.904526, longitude: -74.119514, zipCode: "07407"},
	{latitude: 40.93583, longitude: -74.117505, zipCode: "07410"},
	{latitude: 41.108997, longitude: -74.588641, zipCode: "07416"},
	{latitude: 41.007526, longitude: -74.205653, zipCode: "07417"},
	{latitude: 41.242216, longitude: -74.486108, zipCode: "07418"},
	{latitude: 41.154156, longitude: -74.57001, zipCode: "07419"},
	{latitude: 41.029333, longitude: -74.302841, zipCode: "07420"},
	{latitude: 41.17374, longitude: -74.352613, zipCode: "07421"},
	{latitude: 41.173751, longitude: -74.446963, zipCode: "07422"},
	{latitude: 40.9995, longitude: -74.096583, zipCode: "07423"},
	{latitude: 40.882319, longitude: -74.205876, zipCode: "07424"},
	{latitude: 41.082067, longitude: -74.183061, zipCode: "07430"},
	{latitude: 40.995809, longitude: -74.141262, zipCode: "07432"},
	{latitude: 41.040778, longitude: -74.44191, zipCode: "07435"},
	{latitude: 41.031022, longitude: -74.240502, zipCode: "07436"},
	{latitude: 41.029821, longitude: -74.521772, zipCode: "07438"},
	{latitude: 41.08009, longitude: -74.597626, zipCode: "07439"},
	{latitude: 40.946468, longitude: -74.29304, zipCode: "07440"},
	{latitude: 41.002734, longitude: -74.286742, zipCode: "07442"},
	{latitude: 40.967407, longitude: -74.306967, zipCode: "07444"},
	{latitude: 41.059136, longitude: -74.145931, zipCode: "07446"},
	{latitude: 40.981591, longitude: -74.113506, zipCode: "07450"},
	{latitude: 40.959471, longitude: -74.125202, zipCode: "07452"},
	{latitude: 41.097039, longitude: -74.262921, zipCode: "07456"},
	{latitude: 40.995886, longitude: -74.314532, zipCode: "07457"},
	{latitude: 41.046658, longitude: -74.096482, zipCode: "07458"},
	{latitude: 41.114264, longitude: -74.493844, zipCode: "07460"},
	{latitude: 41.247022, longitude: -74.610522, zipCode: "07461"},
	{latitude: 41.198457, longitude: -74.495575, zipCode: "07462"},
	{latitude: 41.013367, longitude: -74.125217, zipCode: "07463"},
	{latitude: 41.04927, longitude: -74.292337, zipCode: "07465"},
	{latitude: 40.945855, longitude: -74.245077, zipCode: "07470"},
	{latitude: 41.08587, longitude: -74.37839, zipCode: "07480"},
	{latitude: 40.999093, longitude: -74.168849, zipCode: "07481"},
	{latitude: 40.911998, longitude: -74.170965, zipCode: "07501"},
	{latitude: 40.919659, longitude: -74.195028, zipCode: "07502"},
	{latitude: 40.897548, longitude: -74.154121, zipCode: "07503"},
	{latitude: 40.912433, longitude: -74.140689, zipCode: "07504"},
	{latitude: 40.917008, longitude: -74.171936, zipCode: "07505"},
	{latitude: 40.956957, longitude: -74.158561, zipCode: "07506"},
	{latitude: 40.955021, longitude: -74.183692, zipCode: "07508"},
	{latitude: 40.903415, longitude: -74.219779, zipCode: "07512"},
	{latitude: 40.904592, longitude: -74.148966, zipCode: "07513"},
	{latitude: 40.924786, longitude: -74.144542, zipCode: "07514"},
	{latitude: 40.923569, longitude: -74.179225, zipCode: "07522"},
	{latitude: 40.931711, longitude: -74.15687, zipCode: "07524"},
	{latitude: 40.889398, longitude: -74.045698, zipCode: "07601"},
	{latitude: 40.874293, longitude: -74.029737, zipCode: "07603"},
	{latitude: 40.861513, longitude: -74.073114, zipCode: "07604"},
	{latitude: 40.863391, longitude: -73.988466, zipCode: "07605"},
	{latitude: 40.864801, longitude: -74.048115, zipCode: "07606"},
	{latitude: 40.902885, longitude: -74.063457, zipCode: "07607"},
	{latitude: 40.849609, longitude: -74.061893, zipCode: "07608"},
	{latitude: 40.968782, longitude: -73.916896, zipCode: "07620"},
	{latitude: 40.922334, longitude: -73.998001, zipCode: "07621"},
	{latitude: 40.97289, longitude: -73.960315, zipCode: "07624"},
	{latitude: 40.940018, longitude: -73.958313, zipCode: "07626"},
	{latitude: 40.954103, longitude: -73.954921, zipCode: "07627"},
	{latitude: 40.945239, longitude: -73.992428, zipCode: "07628"},
	{latitude: 40.97499, longitude: -74.023248, zipCode: "07630"},
	{latitude: 40.891197, longitude: -73.972515, zipCode: "07631"},
	{latitude: 40.884634, longitude: -73.944654, zipCode: "07632"},
	{latitude: 40.991681, longitude: -73.980202, zipCode: "07640"},
	{latitude: 40.962737, longitude: -73.99747, zipCode: "07641"},
	{latitude: 41.007127, longitude: -74.045119, zipCode: "07642"},
	{latitude: 40.843431, longitude: -74.034239, zipCode: "07643"},
	{latitude: 40.877915, longitude: -74.0825, zipCode: "07644"},
	{latitude: 41.054594, longitude: -74.047298, zipCode: "07645"},
	{latitude: 40.934161, longitude: -74.019453, zipCode: "07646"},
	{latitude: 41.009421, longitude: -73.941984, zipCode: "07647"},
	{latitude: 40.992046, longitude: -73.950551, zipCode: "07648"},
	{latitude: 40.956653, longitude: -74.032862, zipCode: "07649"},
	{latitude: 40.847017, longitude: -73.997061, zipCode: "07650"},
	{latitude: 40.947299, longitude: -74.070169, zipCode: "07652"},
	{latitude: 41.036183, longitude: -74.044293, zipCode: "07656"},
	{latitude: 40.832677, longitude: -74.014088, zipCode: "07657"},
	{latitude: 40.854705, longitude: -74.019926, zipCode: "07660"},
	{latitude: 40.927354, longitude: -74.039611, zipCode: "07661"},
	{latitude: 40.906496, longitude: -74.078005, zipCode: "07662"},
	{latitude: 40.903099, longitude: -74.093591, zipCode: "07663"},
	{latitude: 40.890317, longitude: -74.011478, zipCode: "07666"},
	{latitude: 40.918309, longitude: -73.950523, zipCode: "07670"},
	{latitude: 41.003484, longitude: -74.001753, zipCode: "07675"},
	{latitude: 40.988306, longitude: -74.064693, zipCode: "07676"},
	{latitude: 41.025977, longitude: -74.061061, zipCode: "07677"},
	{latitude: 40.360509, longitude: -74.078952, zipCode: "07701"},
	{latitude: 40.32645, longitude: -74.057373, zipCode: "07702"},
	{latitude: 40.314341, longitude: -74.046746, zipCode: "07703"},
	{latitude: 40.36194, longitude: -74.038775, zipCode: "07704"},
	{latitude: 40.237603, longitude: -74.006722, zipCode: "07711"},
	{latitude: 40.246895, longitude: -74.054502, zipCode: "07712"},
	{latitude: 40.402402, longitude: -74.036306, zipCode: "07716"},
	{latitude: 40.191418, longitude: -74.015105, zipCode: "07717"},
	{latitude: 40.419871, longitude: -74.085174, zipCode: "07718"},
	{latitude: 40.168908, longitude: -74.073226, zipCode: "07719"},
	{latitude: 40.201604, longitude: -74.012056, zipCode: "07720"},
	{latitude: 40.435334, longitude: -74.235966, zipCode: "07721"},
	{latitude: 40.287799, longitude: -74.166807, zipCode: "07722"},
	{latitude: 40.250486, longitude: -74.002164, zipCode: "07723"},
	{latitude: 40.298625, longitude: -74.074178, zipCode: "07724"},
	{latitude: 40.286506, longitude: -74.342547, zipCode: "07726"},
	{latitude: 40.205491, longitude: -74.157535, zipCode: "07727"},
	{latitude: 40.225793, longitude: -74.285785, zipCode: "07728"},
	{latitude: 40.424164, longitude: -74.173732, zipCode: "07730"},
	{latitude: 40.149537, longitude: -74.203987, zipCode: "07731"},
	{latitude: 40.430451, longitude: -73.990424, zipCode: "07732"},
	{latitude: 40.374964, longitude: -74.173849, zipCode: "07733"},
	{latitude: 40.444152, longitude: -74.136812, zipCode: "07734"},
	{latitude: 40.450022, longitude: -74.2235, zipCode: "07735"},
	{latitude: 40.406738, longitude: -74.063828, zipCode: "07737"},
	{latitude: 40.335361, longitude: -74.132723, zipCode: "07738"},
	{latitude: 40.336952, longitude: -74.034535, zipCode: "07739"},
	{latitude: 40.295372, longitude: -73.989899, zipCode: "07740"},
	{latitude: 40.32349, longitude: -74.254572, zipCode: "07746"},
	{latitude: 40.41325, longitude: -74.257674, zipCode: "07747"},
	{latitude: 40.3963, longitude: -74.115274, zipCode: "07748"},
	{latitude: 40.335895, longitude: -73.985608, zipCode: "07750"},
	{latitude: 40.35965, longitude: -74.261462, zipCode: "07751"},
	{latitude: 40.210761, longitude: -74.074499, zipCode: "07753"},
	{latitude: 40.26347, longitude: -74.023623, zipCode: "07755"},
	{latitude: 40.21182, longitude: -74.006944, zipCode: "07756"},
	{latitude: 40.315996, longitude: -74.017637, zipCode: "07757"},
	{latitude: 40.434236, longitude: -74.100665, zipCode: "07758"},
	{latitude: 40.371452, longitude: -74.003922, zipCode: "07760"},
	{latitude: 40.153409, longitude: -74.033732, zipCode: "07762"},
	{latitude: 40.287904, longitude: -74.020019, zipCode: "07764"},
	{latitude: 40.901125, longitude: -74.564783, zipCode: "07801"},
	{latitude: 40.878088, longitude: -74.601176, zipCode: "07803"},
	{latitude: 40.928674, longitude: -74.798862, zipCode: "07820"},
	{latitude: 40.966037, longitude: -74.754687, zipCode: "07821"},
	{latitude: 41.138775, longitude: -74.708337, zipCode: "07822"},
	{latitude: 40.823262, longitude: -75.046014, zipCode: "07823"},
	{latitude: 40.982962, longitude: -74.961996, zipCode: "07825"},
	{latitude: 41.163459, longitude: -74.815409, zipCode: "07826"},
	{latitude: 41.280958, longitude: -74.730511, zipCode: "07827"},
	{latitude: 40.884959, longitude: -74.755657, zipCode: "07828"},
	{latitude: 40.719283, longitude: -74.794021, zipCode: "07830"},
	{latitude: 40.739211, longitude: -74.946813, zipCode: "07831"},
	{latitude: 40.944578, longitude: -75.067346, zipCode: "07832"},
	{latitude: 40.892819, longitude: -75.069436, zipCode: "07833"},
	{latitude: 40.88646, longitude: -74.487584, zipCode: "07834"},
	{latitude: 40.843893, longitude: -74.709761, zipCode: "07836"},
	{latitude: 40.88795, longitude: -74.911692, zipCode: "07838"},
	{latitude: 40.860045, longitude: -74.831795, zipCode: "07840"},
	{latitude: 40.944622, longitude: -74.500876, zipCode: "07842"},
	{latitude: 40.939227, longitude: -74.659506, zipCode: "07843"},
	{latitude: 40.908616, longitude: -74.969576, zipCode: "07844"},
	{latitude: 40.966823, longitude: -74.876834, zipCode: "07846"},
	{latitude: 40.887434, longitude: -74.619738, zipCode: "07847"},
	{latitude: 41.097903, longitude: -74.680163, zipCode: "07848"},
	{latitude: 40.964142, longitude: -74.612574, zipCode: "07849"},
	{latitude: 40.906766, longitude: -74.659352, zipCode: "07850"},
	{latitude: 41.200384, longitude: -74.856406, zipCode: "07851"},
	{latitude: 40.883268, longitude: -74.668029, zipCode: "07852"},
	{latitude: 40.787399, longitude: -74.78213, zipCode: "07853"},
	{latitude: 40.916422, longitude: -74.626763, zipCode: "07856"},
	{latitude: 40.897942, longitude: -74.693366, zipCode: "07857"},
	{latitude: 41.057287, longitude: -74.802737, zipCode: "07860"},
	{latitude: 40.807571, longitude: -74.957451, zipCode: "07863"},
	{latitude: 40.793806, longitude: -74.898347, zipCode: "07865"},
	{latitude: 40.954989, longitude: -74.488006, zipCode: "07866"},
	{latitude: 40.84164, longitude: -74.578522, zipCode: "07869"},
	{latitude: 40.804243, longitude: -74.820672, zipCode: "07870"},
	{latitude: 41.046696, longitude: -74.627989, zipCode: "07871"},
	{latitude: 40.928908, longitude: -74.709458, zipCode: "07874"},
	{latitude: 40.85447, longitude: -74.657357, zipCode: "07876"},
	{latitude: 40.871598, longitude: -74.47824, zipCode: "07878"},
	{latitude: 40.880001, longitude: -74.876253, zipCode: "07880"},
	{latitude: 41.123325, longitude: -74.934412, zipCode: "07881"},
	{latitude: 40.75543, longitude: -75.01087, zipCode: "07882"},
	{latitude: 40.910409, longitude: -74.599964, zipCode: "07885"},
	{latitude: 40.714923, longitude: -74.366372, zipCode: "07901"},
	{latitude: 40.682268, longitude: -74.554143, zipCode: "07920"},
	{latitude: 40.656019, longitude: -74.678657, zipCode: "07921"},
	{latitude: 40.67659, longitude: -74.427022, zipCode: "07922"},
	{latitude: 40.72577, longitude: -74.591213, zipCode: "07924"},
	{latitude: 40.801262, longitude: -74.570122, zipCode: "07926"},
	{latitude: 40.823725, longitude: -74.456354, zipCode: "07927"},
	{latitude: 40.724971, longitude: -74.415086, zipCode: "07928"},
	{latitude: 40.781309, longitude: -74.68359, zipCode: "07930"},
	{latitude: 40.681417, longitude: -74.616536, zipCode: "07931"},
	{latitude: 40.777461, longitude: -74.392845, zipCode: "07932"},
	{latitude: 40.695179, longitude: -74.456871, zipCode: "07933"},
	{latitude: 40.729737, longitude: -74.666509, zipCode: "07934"},
	{latitude: 40.735441, longitude: -74.451312, zipCode: "07935"},
	{latitude: 40.818553, longitude: -74.363742, zipCode: "07936"},
	{latitude: 40.667279, longitude: -74.552164, zipCode: "07939"},
	{latitude: 40.760535, longitude: -74.41948, zipCode: "07940"},
	{latitude: 40.780656, longitude: -74.601816, zipCode: "07945"},
	{latitude: 40.677709, longitude: -74.508538, zipCode: "07946"},
	{latitude: 40.846026, longitude: -74.482314, zipCode: "07950"},
	{latitude: 40.779235, longitude: -74.502462, zipCode: "07960"},
	{latitude: 40.781113, longitude: -74.435151, zipCode: "07961"},
	{latitude: 40.809387, longitude: -74.571554, zipCode: "07970"},
	{latitude: 40.696919, longitude: -74.403853, zipCode: "07974"},
	{latitude: 40.72806, longitude: -74.479344, zipCode: "07976"},
	{latitude: 40.711342, longitude: -74.651625, zipCode: "07977"},
	{latitude: 40.711205, longitude: -74.730643, zipCode: "07979"},
	{latitude: 40.676166, longitude: -74.494548, zipCode: "07980"},
	{latitude: 40.824413, longitude: -74.426241, zipCode: "07981"},
	{latitude: 39.54788, longitude: -75.346384, zipCode: "08001"},
	{latitude: 39.934605, longitude: -75.025389, zipCode: "08002"},
	{latitude: 39.885852, longitude: -74.971145, zipCode: "08003"},
	{latitude: 39.755333, longitude: -74.830512, zipCode: "08004"},
	{latitude: 39.755802, longitude: -74.306609, zipCode: "08005"},
	{latitude: 39.753505, longitude: -74.104953, zipCode: "08006"},
	{latitude: 39.865083, longitude: -75.053762, zipCode: "08007"},
	{latitude: 39.623538, longitude: -74.206089, zipCode: "08008"},
	{latitude: 39.760656, longitude: -74.93286, zipCode: "08009"},
	{latitude: 40.053506, longitude: -74.918438, zipCode: "08010"},
	{latitude: 39.975279, longitude: -74.713802, zipCode: "08011"},
	{latitude: 39.783815, longitude: -75.056068, zipCode: "08012"},
	{latitude: 39.812923, longitude: -75.36731, zipCode: "08014"},
	{latitude: 39.931536, longitude: -74.54372, zipCode: "08015"},
	{latitude: 40.068064, longitude: -74.832904, zipCode: "08016"},
	{latitude: 39.765587, longitude: -74.530053, zipCode: "08019"},
	{latitude: 39.794221, longitude: -75.222232, zipCode: "08020"},
	{latitude: 39.806156, longitude: -75.000012, zipCode: "08021"},
	{latitude: 40.06759, longitude: -74.705741, zipCode: "08022"},
	{latitude: 39.688108, longitude: -75.496899, zipCode: "08023"},
	{latitude: 39.833646, longitude: -74.969001, zipCode: "08026"},
	{latitude: 39.828521, longitude: -75.294384, zipCode: "08027"},
	{latitude: 39.692129, longitude: -75.124675, zipCode: "08028"},
	{latitude: 39.840937, longitude: -75.067211, zipCode: "08029"},
	{latitude: 39.889564, longitude: -75.118494, zipCode: "08030"},
	{latitude: 39.866356, longitude: -75.09467, zipCode: "08031"},
	{latitude: 39.780545, longitude: -75.059612, zipCode: "08032"},
	{latitude: 39.892421, longitude: -75.036651, zipCode: "08033"},
	{latitude: 39.9061, longitude: -75.000375, zipCode: "08034"},
	{latitude: 39.879127, longitude: -75.065918, zipCode: "08035"},
	{latitude: 39.977956, longitude: -74.836334, zipCode: "08036"},
	{latitude: 39.631681, longitude: -74.773893, zipCode: "08037"},
	{latitude: 39.470004, longitude: -75.490291, zipCode: "08038"},
	{latitude: 39.685659, longitude: -75.277309, zipCode: "08039"},
	{latitude: 40.038525, longitude: -74.684101, zipCode: "08041"},
	{latitude: 40.013657, longitude: -74.662029, zipCode: "08042"},
	{latitude: 39.84857, longitude: -74.952843, zipCode: "08043"},
	{latitude: 39.867211, longitude: -75.029156, zipCode: "08045"},
	{latitude: 40.02795, longitude: -74.886984, zipCode: "08046"},
	{latitude: 39.95385, longitude: -74.803026, zipCode: "08048"},
	{latitude: 39.854575, longitude: -75.038137, zipCode: "08049"},
	{latitude: 39.712132, longitude: -74.231888, zipCode: "08050"},
	{latitude: 39.784025, longitude: -75.175979, zipCode: "08051"},
	{latitude: 39.952381, longitude: -74.994896, zipCode: "08052"},
	{latitude: 39.856677, longitude: -74.90081, zipCode: "08053"},
	{latitude: 39.948992, longitude: -74.900247, zipCode: "08054"},
	{latitude: 39.862695, longitude: -74.822311, zipCode: "08055"},
	{latitude: 39.783683, longitude: -75.255302, zipCode: "08056"},
	{latitude: 39.979658, longitude: -74.941308, zipCode: "08057"},
	{latitude: 39.884825, longitude: -75.092667, zipCode: "08059"},
	{latitude: 40.010005, longitude: -74.792207, zipCode: "08060"},
	{latitude: 39.79887, longitude: -75.205365, zipCode: "08061"},
	{latitude: 39.716095, longitude: -75.219267, zipCode: "08062"},
	{latitude: 39.867297, longitude: -75.185621, zipCode: "08063"},
	{latitude: 39.93735, longitude: -74.616269, zipCode: "08064"},
	{latitude: 40.002615, longitude: -75.035273, zipCode: "08065"},
	{latitude: 39.835173, longitude: -75.233532, zipCode: "08066"},
	{latitude: 39.739193, longitude: -75.411406, zipCode: "08067"},
	{latitude: 39.961394, longitude: -74.665922, zipCode: "08068"},
	{latitude: 39.703142, longitude: -75.450676, zipCode: "08069"},
	{latitude: 39.619125, longitude: -75.516069, zipCode: "08070"},
	{latitude: 39.731654, longitude: -75.134257, zipCode: "08071"},
	{latitude: 39.544683, longitude: -75.412568, zipCode: "08072"},
	{latitude: 40.009508, longitude: -74.866845, zipCode: "08073"},
	{latitude: 39.716166, longitude: -75.16344, zipCode: "08074"},
	{latitude: 40.029676, longitude: -74.948997, zipCode: "08075"},
	{latitude: 40.000162, longitude: -74.991632, zipCode: "08077"},
	{latitude: 39.853411, longitude: -75.075502, zipCode: "08078"},
	{latitude: 39.542408, longitude: -75.431684, zipCode: "08079"},
	{latitude: 39.757353, longitude: -75.122043, zipCode: "08080"},
	{latitude: 39.732942, longitude: -74.976657, zipCode: "08081"},
	{latitude: 39.841307, longitude: -75.028613, zipCode: "08083"},
	{latitude: 39.82899, longitude: -75.015536, zipCode: "08084"},
	{latitude: 39.753766, longitude: -75.33104, zipCode: "08085"},
	{latitude: 39.851212, longitude: -75.185663, zipCode: "08086"},
	{latitude: 39.603959, longitude: -74.382181, zipCode: "08087"},
	{latitude: 39.837315, longitude: -74.695267, zipCode: "08088"},
	{latitude: 39.713093, longitude: -74.831034, zipCode: "08089"},
	{latitude: 39.797986, longitude: -75.148526, zipCode: "08090"},
	{latitude: 39.807116, longitude: -74.924178, zipCode: "08091"},
	{latitude: 39.647182, longitude: -74.284599, zipCode: "08092"},
	{latitude: 39.861571, longitude: -75.132975, zipCode: "08093"},
	{latitude: 39.650601, longitude: -74.959311, zipCode: "08094"},
	{latitude: 39.649298, longitude: -74.858006, zipCode: "08095"},
	{latitude: 39.829402, longitude: -75.129032, zipCode: "08096"},
	{latitude: 39.81329, longitude: -75.15065, zipCode: "08097"},
	{latitude: 39.639301, longitude: -75.329801, zipCode: "08098"},
	{latitude: 39.952116, longitude: -75.121149, zipCode: "08102"},
	{latitude: 39.935202, longitude: -75.112703, zipCode: "08103"},
	{latitude: 39.916459, longitude: -75.111355, zipCode: "08104"},
	{latitude: 39.95034, longitude: -75.096278, zipCode: "08105"},
	{latitude: 39.890992, longitude: -75.073329, zipCode: "08106"},
	{latitude: 39.908665, longitude: -75.084445, zipCode: "08107"},
	{latitude: 39.915048, longitude: -75.062244, zipCode: "08108"},
	{latitude: 39.94933, longitude: -75.04953, zipCode: "08109"},
	{latitude: 39.971893, longitude: -75.057876, zipCode: "08110"},
	{latitude: 39.423563, longitude: -74.493025, zipCode: "08201"},
	{latitude: 39.09664, longitude: -74.739757, zipCode: "08202"},
	{latitude: 39.421713, longitude: -74.392873, zipCode: "08203"},
	{latitude: 38.971748, longitude: -74.916127, zipCode: "08204"},
	{latitude: 39.482, longitude: -74.452637, zipCode: "08205"},
	{latitude: 39.119187, longitude: -74.823188, zipCode: "08210"},
	{latitude: 38.940414, longitude: -74.964284, zipCode: "08212"},
	{latitude: 39.59012, longitude: -74.581951, zipCode: "08215"},
	{latitude: 39.577032, longitude: -74.718816, zipCode: "08217"},
	{latitude: 39.339936, longitude: -74.56447, zipCode: "08221"},
	{latitude: 39.26952, longitude: -74.657118, zipCode: "08223"},
	{latitude: 39.599334, longitude: -74.467435, zipCode: "08224"},
	{latitude: 39.35834, longitude: -74.536312, zipCode: "08225"},
	{latitude: 39.263784, longitude: -74.607753, zipCode: "08226"},
	{latitude: 39.201062, longitude: -74.706753, zipCode: "08230"},
	{latitude: 39.387525, longitude: -74.515196, zipCode: "08232"},
	{latitude: 39.388393, longitude: -74.620728, zipCode: "08234"},
	{latitude: 39.488059, longitude: -74.533973, zipCode: "08240"},
	{latitude: 39.534798, longitude: -74.476099, zipCode: "08241"},
	{latitude: 39.01738, longitude: -74.886908, zipCode: "08242"},
	{latitude: 39.151013, longitude: -74.696319, zipCode: "08243"},
	{latitude: 39.315511, longitude: -74.598278, zipCode: "08244"},
	{latitude: 39.176509, longitude: -74.819028, zipCode: "08245"},
	{latitude: 39.180965, longitude: -74.765806, zipCode: "08246"},
	{latitude: 39.048975, longitude: -74.786219, zipCode: "08247"},
	{latitude: 39.194963, longitude: -74.657546, zipCode: "08248"},
	{latitude: 39.028823, longitude: -74.928921, zipCode: "08251"},
	{latitude: 38.998347, longitude: -74.843302, zipCode: "08260"},
	{latitude: 39.261356, longitude: -74.809092, zipCode: "08270"},
	{latitude: 39.438613, longitude: -75.260646, zipCode: "08302"},
	{latitude: 39.531437, longitude: -74.89681, zipCode: "08310"},
	{latitude: 39.327866, longitude: -75.204035, zipCode: "08311"},
	{latitude: 39.661422, longitude: -75.083899, zipCode: "08312"},
	{latitude: 39.216102, longitude: -74.93774, zipCode: "08314"},
	{latitude: 39.280176, longitude: -74.937365, zipCode: "08316"},
	{latitude: 39.400688, longitude: -74.829634, zipCode: "08317"},
	{latitude: 39.557653, longitude: -75.177717, zipCode: "08318"},
	{latitude: 39.370409, longitude: -74.810816, zipCode: "08319"},
	{latitude: 39.38508, longitude: -75.1606, zipCode: "08320"},
	{latitude: 39.223596, longitude: -75.143169, zipCode: "08321"},
	{latitude: 39.611102, longitude: -75.048771, zipCode: "08322"},
	{latitude: 39.396273, longitude: -75.367551, zipCode: "08323"},
	{latitude: 39.225767, longitude: -75.004014, zipCode: "08324"},
	{latitude: 39.538415, longitude: -74.927717, zipCode: "08326"},
	{latitude: 39.255595, longitude: -74.949509, zipCode: "08327"},
	{latitude: 39.579668, longitude: -75.056375, zipCode: "08328"},
	{latitude: 39.286852, longitude: -75.005708, zipCode: "08329"},
	{latitude: 39.469337, longitude: -74.747162, zipCode: "08330"},
	{latitude: 39.366217, longitude: -75.026853, zipCode: "08332"},
	{latitude: 39.3933, longitude: -74.907257, zipCode: "08340"},
	{latitude: 39.521486, longitude: -74.958651, zipCode: "08341"},
	{latitude: 39.636348, longitude: -75.16756, zipCode: "08343"},
	{latitude: 39.561745, longitude: -74.986037, zipCode: "08344"},
	{latitude: 39.288457, longitude: -75.143586, zipCode: "08345"},
	{latitude: 39.569548, longitude: -74.852086, zipCode: "08346"},
	{latitude: 39.309933, longitude: -74.971365, zipCode: "08348"},
	{latitude: 39.260936, longitude: -75.059259, zipCode: "08349"},
	{latitude: 39.4907, longitude: -74.873641, zipCode: "08350"},
	{latitude: 39.475445, longitude: -75.127242, zipCode: "08352"},
	{latitude: 39.462418, longitude: -75.29244, zipCode: "08353"},
	{latitude: 39.492354, longitude: -75.003447, zipCode: "08360"},
	{latitude: 39.449369, longitude: -74.958827, zipCode: "08361"},
	{latitude: 39.377337, longitude: -74.45107, zipCode: "08401"},
	{latitude: 39.326385, longitude: -74.504473, zipCode: "08402"},
	{latitude: 39.318497, longitude: -74.542405, zipCode: "08403"},
	{latitude: 39.342046, longitude: -74.482626, zipCode: "08406"},
	{latitude: 40.166258, longitude: -74.552543, zipCode: "08501"},
	{latitude: 40.448217, longitude: -74.653244, zipCode: "08502"},
	{latitude: 40.135589, longitude: -74.707255, zipCode: "08505"},
	{latitude: 40.187207, longitude: -74.425013, zipCode: "08510"},
	{latitude: 40.043738, longitude: -74.547654, zipCode: "08511"},
	{latitude: 40.324357, longitude: -74.525526, zipCode: "08512"},
	{latitude: 40.134245, longitude: -74.491732, zipCode: "08514"},
	{latitude: 40.119177, longitude: -74.662894, zipCode: "08515"},
	{latitude: 40.115503, longitude: -74.804028, zipCode: "08518"},
	{latitude: 40.258126, longitude: -74.53931, zipCode: "08520"},
	{latitude: 40.397716, longitude: -74.77952, zipCode: "08525"},
	{latitude: 40.108665, longitude: -74.358602, zipCode: "08527"},
	{latitude: 40.387108, longitude: -74.618915, zipCode: "08528"},
	{latitude: 40.37002, longitude: -74.910161, zipCode: "08530"},
	{latitude: 40.015589, longitude: -74.485083, zipCode: "08533"},
	{latitude: 40.329422, longitude: -74.792789, zipCode: "08534"},
	{latitude: 40.231908, longitude: -74.432033, zipCode: "08535"},
	{latitude: 40.333076, longitude: -74.586628, zipCode: "08536"},
	{latitude: 40.364728, longitude: -74.656314, zipCode: "08540"},
	{latitude: 40.354517, longitude: -74.66056, zipCode: "08542"},
	{latitude: 40.346492, longitude: -74.657848, zipCode: "08544"},
	{latitude: 40.279953, longitude: -74.622116, zipCode: "08550"},
	{latitude: 40.447111, longitude: -74.837285, zipCode: "08551"},
	{latitude: 40.400347, longitude: -74.638922, zipCode: "08553"},
	{latitude: 40.112984, longitude: -74.781093, zipCode: "08554"},
	{latitude: 40.220742, longitude: -74.470155, zipCode: "08555"},
	{latitude: 40.413411, longitude: -74.703745, zipCode: "08558"},
	{latitude: 40.438986, longitude: -74.983625, zipCode: "08559"},
	{latitude: 40.315492, longitude: -74.857016, zipCode: "08560"},
	{latitude: 40.247399, longitude: -74.581329, zipCode: "08561"},
	{latitude: 40.061957, longitude: -74.591878, zipCode: "08562"},
	{latitude: 40.219081, longitude: -74.766843, zipCode: "08608"},
	{latitude: 40.225747, longitude: -74.740906, zipCode: "08609"},
	{latitude: 40.193643, longitude: -74.715963, zipCode: "08610"},
	{latitude: 40.183615, longitude: -74.740319, zipCode: "08611"},
	{latitude: 40.24934, longitude: -74.789968, zipCode: "08618"},
	{latitude: 40.242301, longitude: -74.696408, zipCode: "08619"},
	{latitude: 40.170087, longitude: -74.651309, zipCode: "08620"},
	{latitude: 40.264418, longitude: -74.827047, zipCode: "08628"},
	{latitude: 40.220644, longitude: -74.731246, zipCode: "08629"},
	{latitude: 40.254018, longitude: -74.763385, zipCode: "08638"},
	{latitude: 40.013969, longitude: -74.575016, zipCode: "08640"},
	{latitude: 40.027985, longitude: -74.587819, zipCode: "08641"},
	{latitude: 40.28449, longitude: -74.717487, zipCode: "08648"},
	{latitude: 40.225833, longitude: -74.660096, zipCode: "08690"},
	{latitude: 40.208319, longitude: -74.600928, zipCode: "08691"},
	{latitude: 40.077069, longitude: -74.19851, zipCode: "08701"},
	{latitude: 40.144999, longitude: -74.125502, zipCode: "08720"},
	{latitude: 39.907407, longitude: -74.184817, zipCode: "08721"},
	{latitude: 39.928405, longitude: -74.202189, zipCode: "08722"},
	{latitude: 40.038584, longitude: -74.1116, zipCode: "08723"},
	{latitude: 40.091032, longitude: -74.114395, zipCode: "08724"},
	{latitude: 40.10841, longitude: -74.06217, zipCode: "08730"},
	{latitude: 39.859886, longitude: -74.292741, zipCode: "08731"},
	{latitude: 39.941804, longitude: -74.143842, zipCode: "08732"},
	{latitude: 40.026868, longitude: -74.363674, zipCode: "08733"},
	{latitude: 39.862916, longitude: -74.166413, zipCode: "08734"},
	{latitude: 39.996988, longitude: -74.077635, zipCode: "08735"},
	{latitude: 40.120018, longitude: -74.053257, zipCode: "08736"},
	{latitude: 40.024765, longitude: -74.059612, zipCode: "08738"},
	{latitude: 39.928358, longitude: -74.127773, zipCode: "08740"},
	{latitude: 39.936339, longitude: -74.167247, zipCode: "08741"},
	{latitude: 40.080812, longitude: -74.059117, zipCode: "08742"},
	{latitude: 40.133372, longitude: -74.041501, zipCode: "08750"},
	{latitude: 39.949634, longitude: -74.079516, zipCode: "08751"},
	{latitude: 39.83664, longitude: -74.090181, zipCode: "08752"},
	{latitude: 39.979668, longitude: -74.150739, zipCode: "08753"},
	{latitude: 40.008534, longitude: -74.221768, zipCode: "08755"},
	{latitude: 39.960591, longitude: -74.260843, zipCode: "08757"},
	{latitude: 39.794543, longitude: -74.253337, zipCode: "08758"},
	{latitude: 39.961826, longitude: -74.359828, zipCode: "08759"},
	{latitude: 40.629609, longitude: -74.89049, zipCode: "08801"},
	{latitude: 40.684264, longitude: -75.023286, zipCode: "08802"},
	{latitude: 40.646656, longitude: -75.09576, zipCode: "08804"},
	{latitude: 40.570639, longitude: -74.537551, zipCode: "08805"},
	{latitude: 40.592434, longitude: -74.61884, zipCode: "08807"},
	{latitude: 40.646036, longitude: -74.924609, zipCode: "08809"},
	{latitude: 40.368285, longitude: -74.490999, zipCode: "08810"},
	{latitude: 40.598731, longitude: -74.478793, zipCode: "08812"},
	{latitude: 40.429119, longitude: -74.416287, zipCode: "08816"},
	{latitude: 40.514655, longitude: -74.393104, zipCode: "08817"},
	{latitude: 40.576806, longitude: -74.36575, zipCode: "08820"},
	{latitude: 40.520721, longitude: -74.688338, zipCode: "08821"},
	{latitude: 40.521995, longitude: -74.866644, zipCode: "08822"},
	{latitude: 40.438609, longitude: -74.565211, zipCode: "08823"},
	{latitude: 40.420727, longitude: -74.553458, zipCode: "08824"},
	{latitude: 40.514189, longitude: -75.027157, zipCode: "08825"},
	{latitude: 40.716574, longitude: -74.912125, zipCode: "08826"},
	{latitude: 40.668424, longitude: -74.968377, zipCode: "08827"},
	{latitude: 40.378417, longitude: -74.423384, zipCode: "08828"},
	{latitude: 40.670111, longitude: -74.890212, zipCode: "08829"},
	{latitude: 40.570142, longitude: -74.316371, zipCode: "08830"},
	{latitude: 40.334341, longitude: -74.432502, zipCode: "08831"},
	{latitude: 40.511399, longitude: -74.318496, zipCode: "08832"},
	{latitude: 40.645542, longitude: -74.816261, zipCode: "08833"},
	{latitude: 40.541791, longitude: -74.588588, zipCode: "08835"},
	{latitude: 40.596376, longitude: -74.55732, zipCode: "08836"},
	{latitude: 40.514281, longitude: -74.343859, zipCode: "08837"},
	{latitude: 40.543139, longitude: -74.358981, zipCode: "08840"},
	{latitude: 40.497692, longitude: -74.670505, zipCode: "08844"},
	{latitude: 40.574627, longitude: -74.498259, zipCode: "08846"},
	{latitude: 40.594266, longitude: -75.096659, zipCode: "08848"},
	{latitude: 40.449151, longitude: -74.440313, zipCode: "08850"},
	{latitude: 40.389909, longitude: -74.544087, zipCode: "08852"},
	{latitude: 40.527314, longitude: -74.742043, zipCode: "08853"},
	{latitude: 40.539658, longitude: -74.462791, zipCode: "08854"},
	{latitude: 40.392789, longitude: -74.330397, zipCode: "08857"},
	{latitude: 40.682681, longitude: -74.737038, zipCode: "08858"},
	{latitude: 40.458172, longitude: -74.303799, zipCode: "08859"},
	{latitude: 40.520494, longitude: -74.272943, zipCode: "08861"},
	{latitude: 40.537238, longitude: -74.312222, zipCode: "08863"},
	{latitude: 40.708226, longitude: -75.147073, zipCode: "08865"},
	{latitude: 40.574456, longitude: -74.966101, zipCode: "08867"},
	{latitude: 40.571344, longitude: -74.646026, zipCode: "08869"},
	{latitude: 40.455736, longitude: -74.351468, zipCode: "08872"},
	{latitude: 40.499667, longitude: -74.531844, zipCode: "08873"},
	{latitude: 40.589554, longitude: -74.685411, zipCode: "08876"},
	{latitude: 40.46641, longitude: -74.278625, zipCode: "08879"},
	{latitude: 40.553947, longitude: -74.527455, zipCode: "08880"},
	{latitude: 40.445652, longitude: -74.378459, zipCode: "08882"},
	{latitude: 40.393613, longitude: -74.392737, zipCode: "08884"},
	{latitude: 40.68764, longitude: -75.100726, zipCode: "08886"},
	{latitude: 40.521516, longitude: -74.795943, zipCode: "08887"},
	{latitude: 40.609021, longitude: -74.754216, zipCode: "08889"},
	{latitude: 40.539047, longitude: -74.575409, zipCode: "08890"},
	{latitude: 40.483638, longitude: -74.44246, zipCode: "08901"},
	{latitude: 40.441513, longitude: -74.491214, zipCode: "08902"},
	{latitude: 40.500795, longitude: -74.427911, zipCode: "08904"},
];

export default ZipcodeToCoordinates;
