import { useState, useEffect } from 'react';
import { Group, Text, Rect } from 'react-konva';
import { animated } from '@react-spring/konva';
import { useSpring } from '@react-spring/web';
import { REACTION_EMOJIS } from '../../constants/emojis';

// Create animated versions of Konva components
const AnimatedGroup = animated(Group);
const AnimatedRect = animated(Rect);

// Particle component for the explosion effect
const Particle = ({ letter, startX, startY, angle, speed }) => {
    const [pos, setPos] = useState({ x: startX, y: startY, opacity: 1 });

    useEffect(() => {
        let frame;
        let time = 0;

        const animate = () => {
            time += 0.03;
            setPos({
                x: startX + Math.cos(angle) * speed * time * 15,
                y: startY + Math.sin(angle) * speed * time * 15 - (time * time * 20),
                opacity: Math.max(0, 1 - time)
            });

            if (time < 1.0) {
                frame = requestAnimationFrame(animate);
            }
        };

        frame = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(frame);
    }, [startX, startY, angle, speed]);

    return (
        <Text
            text={letter}
            x={pos.x}
            y={pos.y}
            fontSize={12}
            opacity={pos.opacity}
            fill="#666666"
        />
    );
};

const ReactBar = ({ posterWidth, onSelectionChange, initialSelection, url = 'example.com', shareURL }) => {
    const barWidth = 250;
    const xOffset = (posterWidth - barWidth) / 2;
    const [selectedIndex, setSelectedIndex] = useState(initialSelection);
    const [reactionCounts, setReactionCounts] = useState({});
    const [hasChanges, setHasChanges] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [particles, setParticles] = useState([]);

    // Animation spring with bouncier feel
    const [{ height, opacity }] = useSpring(() => ({
        from: { height: 0, opacity: 0 },
        to: { height: 40, opacity: 1 },
        config: {
            mass: 1.5,      // More mass for bigger bounce
            tension: 220,   // Increased tension to maintain speed
            friction: 22    // Slightly reduced friction for more bounce
        },
        delay: 150,
    }));

    useEffect(() => {
        const loadReactions = async () => {
            try {
                setIsLoading(true);
                const response = await fetch('/data/testReactions.json');
                const data = await response.json();
                setReactionCounts(data.reactionCounts);
            } catch (error) {
                console.error('Error loading reaction counts:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadReactions();
    }, []);

    // Save changes when component unmounts
    useEffect(() => {
        return () => {
            if (hasChanges) {
                console.log('Saving reactions to backend:', reactionCounts);
            }
        };
    }, [reactionCounts, hasChanges]);

    // All hooks must be called before any conditional returns
    if (isLoading) {
        return null;
    }

    const createExplosion = (index) => {
        const letter = REACTION_EMOJIS[index];
        const particleCount = 8;
        const startX = index * 60 + 35;
        const startY = 0;

        const newParticles = Array.from({ length: particleCount }).map((_, i) => ({
            id: Date.now() + i,
            letter,
            startX,
            startY,
            angle: (Math.PI * 2 * i) / particleCount - Math.PI / 2,
            speed: 0.5 + Math.random() * 0.5
        }));

        setParticles(newParticles);
        setTimeout(() => setParticles([]), 1000);
    };

    const handleReactBarClick = (index) => {
        const clickedLetter = REACTION_EMOJIS[index];

        // Batch all state updates together
        setReactionCounts(prevCounts => {
            const newCounts = { ...prevCounts };

            if (selectedIndex === index) {
                newCounts[clickedLetter] = Math.max(0, (newCounts[clickedLetter] || 0) - 1);
            } else {
                if (selectedIndex !== null) {
                    const prevLetter = REACTION_EMOJIS[selectedIndex];
                    newCounts[prevLetter] = Math.max(0, (newCounts[prevLetter] || 0) - 1);
                }
                newCounts[clickedLetter] = (newCounts[clickedLetter] || 0) + 1;
            }

            setHasChanges(true);
            return newCounts;
        });

        // Handle other state updates after setReactionCounts
        if (selectedIndex === index) {
            setSelectedIndex(null);
            onSelectionChange(null);
        } else {
            setSelectedIndex(index);
            onSelectionChange(index);
            createExplosion(index);
        }
    };

    const getFullUrl = (url) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return `https://${url}`;
        }
        return url;
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Check out this poster!',
                    text: 'I found this cool event on cork.social!',
                    url: getFullUrl(shareURL)
                });
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Error sharing:', error);
                    // Fallback to opening in new tab if sharing fails
                    window.open(getFullUrl(shareURL), '_blank');
                }
            }
        } else {
            // Fallback for browsers that don't support Web Share API
            window.open(getFullUrl(shareURL), '_blank');
        }
    };

    return (
        <AnimatedGroup x={xOffset} className="react-bar" opacity={opacity}>
            <Group y={-25} x={85}>
                <Rect
                    width={80}
                    height={20}
                    fill="#f0f0f0"
                    cornerRadius={10}
                    shadowColor="black"
                    shadowBlur={5}
                    shadowOpacity={0.1}
                    shadowOffset={{ x: 1, y: 1 }}
                />
                <Text
                    text="🔗 event link"
                    fontSize={12}
                    fill="#2196f3"
                    width={80}
                    align="center"
                    y={4}
                    onClick={() => window.open(getFullUrl(url), '_blank')}
                    onTap={() => window.open(getFullUrl(url), '_blank')}
                />
            </Group>

            <Group y={-25}>
            <Rect
                    width={80}
                    height={20}
                    fill="#f0f0f0"
                    cornerRadius={10}
                    shadowColor="black"
                    shadowBlur={5}
                    shadowOpacity={0.1}
                    shadowOffset={{ x: 1, y: 1 }}
                />
                <Text
                    text="📩 share"
                    fontSize={12}
                    fill="#2196f3"
                    width={80}
                    align="center"
                    y={4}
                    onClick={handleShare}
                    onTap={handleShare}
                />
            </Group>

            <Group y={-25} x={170}>
                <Rect
                    width={80}
                    height={20}
                    fill="#f0f0f0"
                    cornerRadius={10}
                    shadowColor="black"
                    shadowBlur={5}
                    shadowOpacity={0.1}
                    shadowOffset={{ x: 1, y: 1 }}
                />
                <Text
                    text="📍 my area"
                    fontSize={12}
                    fill="#2196f3"
                    width={80}
                    align="center"
                    y={4}
                    onClick={() => window.location.href = '/'}
                    onTap={() => window.location.href = '/'}
                />
            </Group>

            <AnimatedRect
                width={barWidth}
                height={height}
                fill="#f0f0f0"
                cornerRadius={20}
                shadowColor="black"
                shadowBlur={10}
                shadowOpacity={0.2}
                shadowOffset={{ x: 2, y: 2 }}
            />
            {REACTION_EMOJIS.map((letter, index) => (
                <AnimatedGroup
                    key={index}
                    x={index * 60 + 10}
                    y={5}
                    opacity={opacity}
                    onClick={() => handleReactBarClick(index)}
                    onTap={() => handleReactBarClick(index)}
                >
                    <Rect
                        width={50}
                        height={30}
                        fill={selectedIndex === index ? "#e3f2fd" : "white"}
                        cornerRadius={15}
                        shadowColor="black"
                        shadowBlur={5}
                        shadowOpacity={0.1}
                        shadowOffset={{ x: 1, y: 1 }}
                    />
                    <Group x={8} y={5}>
                        <Text
                            text={letter}
                            fontSize={16}
                            x={0}
                        />
                        <Text
                            text={reactionCounts[letter] || 0}
                            fontSize={12}
                            x={20}
                            y={3}
                            fill="#666666"
                        />
                    </Group>
                    {selectedIndex === index && (
                        <Rect
                            width={50}
                            height={2}
                            fill="#2196f3"
                            y={28}
                            cornerRadius={1}
                        />
                    )}
                </AnimatedGroup>
            ))}

            {/* Add particles layer */}
            {particles.map(particle => (
                <Particle
                    key={particle.id}
                    letter={particle.letter}
                    startX={particle.startX}
                    startY={particle.startY}
                    angle={particle.angle}
                    speed={particle.speed}
                />
            ))}
        </AnimatedGroup>
    );
};

export default ReactBar;
