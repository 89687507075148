// src/components/Poster.js
import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Image as KonvaImage, Text, Group, Rect } from 'react-konva';
import useImage from 'use-image';
import ReactBar from '../ui/ReactBar';
import { REACTION_EMOJIS } from '../../constants/emojis';

const PosterReactParticle = ({ letter, startX, startY }) => {
  const [pos, setPos] = useState({ x: startX, y: startY, opacity: 1 });

  useEffect(() => {
    let frame;
    let time = 0;
    
    const animate = () => {
      time += 0.02;
      setPos({
        x: startX + (Math.sin(time * 3) * 20), // Gentle side-to-side motion
        y: startY - (time * 50), // Float upward
        opacity: Math.max(0, 1 - time)
      });

      if (time < 1.5) {
        frame = requestAnimationFrame(animate);
      }
    };

    frame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(frame);
  }, [startX, startY]);

  return (
    <Text
      text={letter}
      x={pos.x}
      y={pos.y}
      fontSize={36}
      opacity={pos.opacity}
      fill="#2196f3"
    />
  );
};

const Poster = React.memo(({
  src,
  id,
  x,
  y,
  fastenerSrc,
  onPosterTouchStart,
  onPosterTouchEnd,
  zIndex,
  lastTouchedPosterId,
  latitude,
  longitude
}) => {
  const [image, status] = useImage(src);
  const [fastenerImage] = useImage(fastenerSrc);
  const [dimensions, setDimensions] = useState({ width: 250, height: 350 });
  const [showReactBar, setShowReactBar] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [particles, setParticles] = useState([]);

  const isLongPressRef = useRef(false);
  const groupRef = useRef();
  const reactBarGroupRef = useRef();

  const fastenerWidth = 50;
  const fastenerHeight = 50;

  const rotationRef = useRef(Math.random() * 14 - 7);

  useEffect(() => {
    if (image) {
      const maxWidth = 350;
      const maxHeight = 350;
      let newWidth = image.width;
      let newHeight = image.height;

      // Calculate the scaling factor
      const scale = Math.min(
        maxWidth / image.width,
        maxHeight / image.height,
        1 // This ensures we only scale down, never up
      );

      // Apply the scaling
      newWidth = image.width * scale;
      newHeight = image.height * scale;

      setDimensions({ width: newWidth, height: newHeight });
    }
  }, [image]);


  useEffect(() => {
    if (zIndex && groupRef.current) {
      groupRef.current.zIndex(zIndex);
      if (reactBarGroupRef.current) {
        reactBarGroupRef.current.zIndex(zIndex + 1); // Ensure ReactBar is always on top
      }
    }
  }, [zIndex, showReactBar]);

  useEffect(() => {
    // Hide ReactBar if another poster is selected or if this is the welcome poster
    if (lastTouchedPosterId !== id) {
      setShowReactBar(false);
    }
  }, [lastTouchedPosterId, id]);


  useEffect(() => {
    if (status === 'failed') {
      console.error(`Failed to load image from source: ${src}`);
    }
  }, [status, src]);

  useEffect(() => {
    const handleReaction = (event) => {
      const reaction = event.detail;
      if (reaction.posterId === id) {
        const newParticle = {
          id: Date.now(),
          letter: REACTION_EMOJIS[reaction.reactionIndex],
          startX: dimensions.width / 2,
          startY: dimensions.height / 2
        };
        
        setParticles(prev => [...prev, newParticle]);
        
        setTimeout(() => {
          setParticles(prev => prev.filter(p => p.id !== newParticle.id));
        }, 1500);
      }
    };

    window.addEventListener('posterReaction', handleReaction);
    return () => window.removeEventListener('posterReaction', handleReaction);
  }, [id, dimensions.width, dimensions.height]);

  return (
    <>
      <Group
        x={x}
        y={y}
        ref={groupRef}
        onMouseDown={() => onPosterTouchStart(id, setShowReactBar, latitude, longitude)}
        onMouseUp={() => onPosterTouchEnd(isLongPressRef, setShowReactBar)}
        onTouchStart={() => onPosterTouchStart(id, setShowReactBar, latitude, longitude)}
        onTouchEnd={() => onPosterTouchEnd(isLongPressRef, setShowReactBar)}
        rotation={rotationRef.current}>
        {status === 'failed' ? (
          <Text text="Image failed to load." fontSize={16} fill="red" />
        ) : (
          <>
            <Rect
              width={dimensions.width + 4}
              height={dimensions.height + 4}
              x={-2}
              y={-2}
              shadowColor="rgba(0,0,0,0.3)"
              shadowBlur={3}
              shadowOffset={{ x: 2, y: 2 }}
              shadowOpacity={1}
              fill="white"
              cornerRadius={2}
            />
            <KonvaImage
              image={image}
              width={dimensions.width}
              height={dimensions.height}
              draggable={false}
            />
            <KonvaImage
              image={fastenerImage}
              width={fastenerWidth}
              height={fastenerHeight}
              x={(dimensions.width - fastenerWidth) / 2}
              y={-fastenerHeight / 2}
              draggable={false}
            />
          </>
        )}
        {particles.map(particle => (
          <PosterReactParticle
            key={particle.id}
            letter={particle.letter}
            startX={particle.startX}
            startY={particle.startY}
          />
        ))}
      </Group>
      {showReactBar && (
        <Group
          x={x}
          y={y - 50}
          rotation={rotationRef.current}
          ref={reactBarGroupRef}
        >
          <ReactBar 
            posterWidth={dimensions.width} 
            onSelectionChange={setSelectedReaction}
            initialSelection={selectedReaction}
            url={'google.com'}
            shareURL={`${window.location.origin}/share?lat=${latitude}&long=${longitude}&posterId=${id}`}
          />
        </Group>
      )}
    </>
  );
}, (prevProps, nextProps) => {
  // Only re-render if these props change
  return (
    prevProps.x === nextProps.x &&
    prevProps.y === nextProps.y &&
    prevProps.zIndex === nextProps.zIndex &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    prevProps.lastTouchedPosterId === nextProps.lastTouchedPosterId  // Add this line
  );
});

Poster.displayName = 'Poster';

export default Poster;