import React from 'react';
import '../../App.css';

const LocationFetchScreen = ({onZipcodeSubmit, locationFetchMessage}) => {
    return (
        <div className="location-screen">
            <div className="location-wrapper"></div>
            <div className="location-container">
                <h1 className="welcome-title">Welcome to Cork!</h1>
                <p className="location-message">{`Welcome to your neighborhood corkboard! Find events and local businesses flyers around you. Please allow location access to continue or input your zipcode below.`}</p>
                <p className='location-fetch-message'>{locationFetchMessage}</p>
                <form name="zipcodeForm" onSubmit={onZipcodeSubmit} className="location-form">
                    <input 
                        name="zipcodeInput"
                        placeholder="Enter your zipcode"
                        className="location-input"
                    />
                    <button type="submit" className="location-button">
                        Continue
                    </button>
                </form>
            </div>
        </div>
    );
}

export default LocationFetchScreen;