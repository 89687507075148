import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Image, Group } from 'react-konva';
import PropTypes from 'prop-types';

const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};


const Background = ({ imageSrc, stagePos, stageSize }) => {
    const [image, setImage] = useState(null);
    // Cache for storing tile components
    const tileCache = useRef(new Map());
    // Ref to store previous calculation parameters
    const prevCalc = useRef({ startX: 0, startY: 0, endX: 0, endY: 0, scale: 1 });
    
    // Memoize boundary calculations
    const boundaries = useMemo(() => {
        const stage = window.Konva.stages[0];
        const currentScale = stage ? stage.scaleX() : 1;
        
        const bufferMultiplier = isMobile() ? 6 : 3;
        const bufferX = stageSize.width * bufferMultiplier;
        const bufferY = stageSize.height * bufferMultiplier;
        
        const leftBoundary = (-stagePos.x - bufferX) / currentScale;
        const rightBoundary = (-stagePos.x + stageSize.width + bufferX) / currentScale;
        const topBoundary = (-stagePos.y - bufferY) / currentScale;
        const bottomBoundary = (-stagePos.y + stageSize.height + bufferY) / currentScale;
        
        return { leftBoundary, rightBoundary, topBoundary, bottomBoundary, currentScale };
    }, [stagePos.x, stagePos.y, stageSize.width, stageSize.height]);

    // Memoize tile calculations and reuse existing tiles
    const tiles = useMemo(() => {
        if (!image) return [];

        const { leftBoundary, rightBoundary, topBoundary, bottomBoundary, currentScale } = boundaries;
        const tileWidth = image.width;
        const tileHeight = image.height;
        
        // Add overlap (e.g., 1% of tile size)
        const overlapX = tileWidth * 0.01;
        const overlapY = tileHeight * 0.01;

        const startX = Math.floor(leftBoundary / tileWidth) * tileWidth;
        const startY = Math.floor(topBoundary / tileHeight) * tileHeight;
        const endX = Math.ceil(rightBoundary / tileWidth) * tileWidth;
        const endY = Math.ceil(bottomBoundary / tileHeight) * tileHeight;

        // Define buffer ranges (e.g., one tile width/height)
        const bufferRange = {
            x: tileWidth,
            y: tileHeight,
            scale: 0.1 // 10% scale difference threshold
        };

        // Check if we're within acceptable range of previous calculation
        const prev = prevCalc.current;
        if (
            Math.abs(prev.startX - startX) <= bufferRange.x &&
            Math.abs(prev.startY - startY) <= bufferRange.y &&
            Math.abs(prev.endX - endX) <= bufferRange.x &&
            Math.abs(prev.endY - endY) <= bufferRange.y &&
            Math.abs(prev.scale - currentScale) <= bufferRange.scale
        ) {
            return Array.from(tileCache.current.values());
        }

        // Update previous calculation values
        prevCalc.current = { startX, startY, endX, endY, scale: currentScale };

        // Clear tiles that are no longer visible
        const newTileKeys = new Set();
        const newTiles = [];

        for (let x = startX; x <= endX; x += tileWidth) {
            for (let y = startY; y <= endY; y += tileHeight) {
                const key = `${x}-${y}`;
                newTileKeys.add(key);

                // Reuse existing tile or create new one
                if (tileCache.current.has(key)) {
                    newTiles.push(tileCache.current.get(key));
                } else {
                    const tile = (
                        <Image
                            key={key}
                            image={image}
                            x={x - overlapX/2}  // Shift left by half the overlap
                            y={y - overlapY/2}  // Shift up by half the overlap
                            width={tileWidth + overlapX}  // Add overlap to width
                            height={tileHeight + overlapY}  // Add overlap to height
                        />
                    );
                    tileCache.current.set(key, tile);
                    newTiles.push(tile);
                }
            }
        }

        // Clean up cached tiles that are no longer visible
        for (const [key] of tileCache.current) {
            if (!newTileKeys.has(key)) {
                tileCache.current.delete(key);
            }
        }

        return newTiles;
    }, [image, boundaries]);

    // Image loading effect remains the same
    useEffect(() => {
        const img = new window.Image();
        img.src = imageSrc;

        img.onload = () => {
            setImage(img);
        };

        img.onerror = (e) => {
            console.error('Error loading background image', e);
        };

        return () => {
            img.onload = null;
            img.onerror = null;
        };
    }, [imageSrc]);

    if (!image) {
        return null;
    }

    return <Group listening={false}>
        {tiles}
    </Group>;
};

Background.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    stagePos: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }).isRequired,
    stageSize: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }).isRequired,
};

export default Background;
