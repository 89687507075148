import React, { useEffect } from 'react';
import InfiniteCanvas from './components/canvas/InfiniteCanvas';
import './App.css';
import { useWebSocket } from './hooks/useWebSocket';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function App() {
  // Initialize the WebSocket connection and get the sendReaction function
  const { sendReaction } = useWebSocket();
  
  // Add an effect to verify the WebSocket is working
  useEffect(() => {
    console.log('WebSocket initialized');
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Default route - uses user's location */}
        <Route path="/" element={<InfiniteCanvas />} />
        
        {/* Share route - uses URL parameters */}
        <Route path="/share" element={<InfiniteCanvas />} />
        
        {/* Catch all invalid routes and redirect to home */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
