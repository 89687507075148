import { useRef, useCallback, useState } from 'react';
import { throttle, getDistance, getMiddlePoint } from '../utils/utils';

// Add these constants at the top
const SCALE_LIMITS = {
    MIN: 0.25,
    MAX: 3
};

export const useGestures = ({ stageRef, scaleRef, positionRef, isAnimatingRef, updatePositionRef, updatePositionRefOnly, updatePositionState, syncPositionState, setScale }) => {
    //set states
    const [highestZIndex, setHighestZIndex] = useState(99);
    const [lastTouchedPosterId, setLastTouchedPosterId] = useState(null);

    // Gesture-specific refs
    const prevTouchesRef = useRef([]);
    const isZoomingRef = useRef(false);
    const velocityRef = useRef({ x: 0, y: 0 });
    const lastPositionRef = useRef(null);
    const lastTimeRef = useRef(null);
    const inertiaAnimationRef = useRef(null);
    const zoomEndTimeoutRef = useRef(null);
    const longPressTimeoutRef = useRef(null);


    // Inertia handling
    const applyInertia = useCallback(() => {
        const friction = 0.85;
        const stopThreshold = 0.05;

        // Get the starting position from the stage
        if (stageRef.current) {
            positionRef.current = stageRef.current.position();
        }

        const animate = () => {
            velocityRef.current.x *= friction;
            velocityRef.current.y *= friction;

            const newPos = {
                x: positionRef.current.x + velocityRef.current.x,
                y: positionRef.current.y + velocityRef.current.y
            };

            updatePositionRefOnly(newPos);

            if (stageRef.current) {
                stageRef.current.position(newPos);
                stageRef.current.batchDraw();
            }

            if (Math.abs(velocityRef.current.x) > stopThreshold ||
                Math.abs(velocityRef.current.y) > stopThreshold) {
                inertiaAnimationRef.current = requestAnimationFrame(animate);
            } else {
                updatePositionState(newPos);
            }
        };

        inertiaAnimationRef.current = requestAnimationFrame(animate);
    }, [updatePositionRefOnly, updatePositionState]);

    // Wheel zoom handling
    const handleWheel = useCallback((e) => {
        if (isAnimatingRef.current) return;
        e.evt.preventDefault();

        const scaleBy = 1.2;
        const stage = e.target.getStage();
        const oldScale = scaleRef.current;
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        let newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
        newScale = Math.max(SCALE_LIMITS.MIN, Math.min(SCALE_LIMITS.MAX, newScale));

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };

        if (newScale !== oldScale) {
            setScale(newScale);
            updatePositionRefOnly(newPos);
            stage.scale({ x: newScale, y: newScale });
            stage.position(newPos);
            stage.batchDraw();

            requestAnimationFrame(() => {
                updatePositionState(newPos);
            });
        }
    }, [scaleRef, updatePositionRefOnly, updatePositionState, setScale, isAnimatingRef]);

    // Touch handlers
    const handleTouchStart = useCallback((e) => {
        if (isAnimatingRef.current) {
            //console.log('Touch blocked - animating');
            return;
        }
        e.evt.preventDefault();

        const touches = e.evt.touches;
        if (touches.length === 2) {
            isZoomingRef.current = true;
            prevTouchesRef.current = [touches[0], touches[1]];
            if (stageRef.current) {
                stageRef.current.draggable(false);
            }
        } else if (touches.length === 1) {
            isZoomingRef.current = false;
            prevTouchesRef.current = [touches[0]];

            if (inertiaAnimationRef.current) {
                cancelAnimationFrame(inertiaAnimationRef.current);
                inertiaAnimationRef.current = null;
                velocityRef.current = { x: 0, y: 0 };
            }

            if (stageRef.current) {
                stageRef.current.draggable(true);
                lastPositionRef.current = {
                    x: touches[0].clientX,
                    y: touches[0].clientY
                };
                lastTimeRef.current = performance.now();
            }
        }
    }, [isAnimatingRef]);

    const handlePinchZoom = useCallback((e) => {
        const touches = e.evt.touches;
        isZoomingRef.current = true;

        const touch1 = touches[0];
        const touch2 = touches[1];

        // If we don't have previous touches stored, store the current ones and exit
        if (!prevTouchesRef.current.length) {
            prevTouchesRef.current = [touch1, touch2];
            return;
        }

        const prevTouch1 = prevTouchesRef.current[0];
        const prevTouch2 = prevTouchesRef.current[1];

        const oldDist = getDistance(prevTouch1, prevTouch2);
        const newDist = getDistance(touch1, touch2);

        // Increase the multiplier from 0.2 to 0.5 for more pronounced zooming
        const scaleFactor = 1 + (newDist - oldDist) / oldDist * 0.75; // Increased multiplier
        let newScale = scaleRef.current * scaleFactor;
        newScale = Math.max(SCALE_LIMITS.MIN, Math.min(SCALE_LIMITS.MAX, newScale));

        const center = getMiddlePoint(touch1, touch2);
        const mousePointTo = {
            x: (center.x - positionRef.current.x) / scaleRef.current,
            y: (center.y - positionRef.current.y) / scaleRef.current,
        };

        const newPos = {
            x: center.x - mousePointTo.x * newScale,
            y: center.y - mousePointTo.y * newScale,
        };

        // Removed the threshold check to allow smaller scale changes
        setScale(newScale);
        updatePositionRefOnly(newPos);

        if (stageRef.current) {
            stageRef.current.scale({ x: newScale, y: newScale });
            stageRef.current.position(newPos);
            stageRef.current.batchDraw();
        }

        prevTouchesRef.current = [touch1, touch2];
    }, [scaleRef, updatePositionRefOnly, setScale]);

    const handlePan = useCallback((e) => {
        const currentTouch = e.evt.touches[0];
        const currentTime = performance.now();

        if (lastPositionRef.current && lastTimeRef.current) {
            const deltaX = currentTouch.clientX - lastPositionRef.current.x;
            const deltaY = currentTouch.clientY - lastPositionRef.current.y;

            const newPos = {
                x: positionRef.current.x + deltaX,
                y: positionRef.current.y + deltaY
            };

            updatePositionRefOnly(newPos);

            if (stageRef.current) {
                stageRef.current.position(newPos);
                stageRef.current.batchDraw();
            }
        }

        lastPositionRef.current = {
            x: currentTouch.clientX,
            y: currentTouch.clientY
        };
        lastTimeRef.current = currentTime;
    }, [updatePositionRefOnly]);


    const handleTouchMove = useCallback(
        throttle((e) => {
            if (isAnimatingRef.current) return;
            e.evt.preventDefault();

            const touches = e.evt.touches;
            if (touches.length === 2) {
                handlePinchZoom(e);
            } else if (touches.length === 1 && !isZoomingRef.current) {
                handlePan(e);
            }
        }, 8),
        [handlePinchZoom, handlePan]);

    const handleTouchEnd = useCallback((e) => {
        e.evt.preventDefault();

        if (isZoomingRef.current) {
            if (zoomEndTimeoutRef.current) {
                clearTimeout(zoomEndTimeoutRef.current);
            }
            zoomEndTimeoutRef.current = setTimeout(() => {
                isZoomingRef.current = false;
            }, 100);
        }

        // Sync position state before applying inertia
        if (stageRef.current) {
            const finalPos = stageRef.current.position();
            updatePositionState(finalPos);
            positionRef.current = finalPos; // Ensure ref is in sync
        }

        if (e.evt.touches.length < 2) {
            prevTouchesRef.current = [];
            if (!isZoomingRef.current && (velocityRef.current.x !== 0 || velocityRef.current.y !== 0)) {
                applyInertia();
            }
        }
    }, [updatePositionState, applyInertia]);

    // Update handleDragEnd
    const handleDragEnd = useCallback(() => {
        if (stageRef.current) {
            const newPos = stageRef.current.position();
            updatePositionState(newPos);

            if (lastPositionRef.current && lastTimeRef.current) {
                const timeDiff = performance.now() - lastTimeRef.current;
                if (timeDiff > 0) {
                    velocityRef.current = {
                        x: (newPos.x - lastPositionRef.current.x) / timeDiff * 16,
                        y: (newPos.y - lastPositionRef.current.y) / timeDiff * 16
                    };
                    applyInertia();
                }
            }
        }
    }, [updatePositionState, applyInertia]);

    // Add a new handler for drag start
    const handleDragStart = useCallback((e) => {
        // Cancel any existing inertia
        if (inertiaAnimationRef.current) {
            cancelAnimationFrame(inertiaAnimationRef.current);
            inertiaAnimationRef.current = null;
            velocityRef.current = { x: 0, y: 0 };
        }

        if (stageRef.current) {
            lastPositionRef.current = stageRef.current.position();
            lastTimeRef.current = performance.now();
        }
    }, []);

    const cleanup = useCallback(() => {
        if (zoomEndTimeoutRef.current) {
            clearTimeout(zoomEndTimeoutRef.current);
        }
        if (longPressTimeoutRef.current) {
            clearTimeout(longPressTimeoutRef.current);
        }
        if (inertiaAnimationRef.current) {
            cancelAnimationFrame(inertiaAnimationRef.current);
        }
    }, []);

    const handleAnimationEnd = useCallback(() => {
        if (stageRef.current) {
            stageRef.current.draggable(true);
        }
    }, []);

    const handlePosterTouch = useCallback((posterId, latitude, longitude) => {
        console.log("Touch event for:", posterId);
        console.log("Current highest zIndex:", highestZIndex);
        console.log("Current lastTouchedPosterId:", lastTouchedPosterId);

        // First, update the lastTouchedPosterId
        setLastTouchedPosterId(posterId);

        // Then immediately update the z-index
        requestAnimationFrame(() => {
            setHighestZIndex(prevZIndex => {
                const newZIndex = Math.max(prevZIndex + 1, 1000);
                console.log("New zIndex set to:", newZIndex);
                return newZIndex;
            });
        });

        // Force stage update
        if (stageRef.current) {
            stageRef.current.batchDraw();
        }
    }, [lastTouchedPosterId, stageRef]);

    const handlePosterTouchStart = useCallback((id, setShowReactBar, latitude, longitude) => {
        handlePosterTouch(id, latitude, longitude);  // This updates the z-index
        
        // Set timeout for long press
        longPressTimeoutRef.current = setTimeout(() => {
            if (id !== 'welcomeposter') {
                setShowReactBar(true);
            }
        }, 250);
        
    }, [handlePosterTouch]);

    const handlePosterTouchEnd = useCallback((isLongPressRef) => {
        cleanup();  // This will clear the longPressTimeout
        
        // If the touch ended before the timeout, hide the ReactBar
        if (!isLongPressRef.current) {
        }
        isLongPressRef.current = false;
    }, [cleanup]);

    const handleStageClick = useCallback((e) => {       
        // Check if we're clicking/tapping the stage background
        const clickedTarget = e.target;
        const isBackground = clickedTarget === e.target.getStage() || 
                           clickedTarget.name() === 'background-layer';
        
        if (isBackground) {
            setLastTouchedPosterId(null);
        }
    }, []);

    return {
        handleWheel,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
        handleDragStart,
        handleDragEnd,
        handlePosterTouch,
        handleAnimationEnd,
        handlePosterTouchStart,
        handlePosterTouchEnd,
        cleanup,
        isZoomingRef,
        highestZIndex,
        lastTouchedPosterId,
        handleStageClick
    };
};
