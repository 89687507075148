import React from 'react';
import '../../App.css';

const HelpSystem = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return (
    <div className="help-icon" onClick={onClose}>
      ?
    </div>
    );
  }

  return (
    <>
      <div className="help-icon" onClick={onClose}>
        ?
      </div>
      <div className="help-overlay" onClick={onClose}>
        <div className="help-popup" onClick={e => e.stopPropagation()}>
          <div className="help-close" onClick={onClose}>×</div>
          <ul className="help-list">
          <li>
            <p>cork.social is your community bulletin board! If you would like to add your poster please visit:</p>
            <a 
              href="https://cork.social/submitposter" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <strong>cork.social/submitposter</strong>
            </a>
            <p>This will require a Google account to sign in.</p>
          </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default HelpSystem;