const MOBILE_DEVICES = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
const LATITUDE_NORTH_BORDER = 42.023131;
const LATITUDE_SOUTH_BORDER = 41.644286;
const LONGITUDE_EAST_BORDER = -87.524402;
const LONGITUDE_WEST_BORDER = -87.940101;
export const ZIPCODES_INSCOPE = [
    // North Side
    "60645", "60626", "60660", "60640", "60625", "60630", "60634", "60641", "60618", 
    "60613", "60657", "60614", "60647", "60651", "60622", "60642", 
    // Downtown/Central
    "60610", "60611", "60654", "60601", "60602", "60603", "60604", "60605", "60606", 
    "60607", "60616", "60661",
    // South Side
    "60608", "60609", "60612", "60615", "60616", "60617", "60619", "60620", "60621",
    "60623", "60624", "60628", "60629", "60632", "60633", "60636", "60637", "60638",
    "60643", "60649", "60652", "60653", "60655", "60659",
    // Far North/Northwest
    "60631", "60646", "60656", "60666", "60678", "60680", "60681", "60682", "60684",
    // Special location
    "ilovealina"
];

export const isMobile = () => {
  return MOBILE_DEVICES.test(navigator.userAgent);
};

// Utility function to throttle events
export const throttle = (func, limit) => {
  let inThrottle;
  return function (...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

// Utility function to debounce events
export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    // Clear previous timeout
    clearTimeout(timeoutId);
    // Set new timeout
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const calculatePixelsPerHundredFeet = (posters, userLat, userLon) => {
  const BASE_PIXELS = 20;  // Your default value
  const POSTERS_PER_SQUARE_MILE = 15;

  // Calculate the maximum distances from user in both lat and lon
  const distances = posters.map(poster => ({
    latDist: Math.abs(poster.latitude - userLat),
    lonDist: Math.abs(poster.longitude - userLon)
  }));

  // Find the maximum distances
  const maxLatDist = Math.max(...distances.map(d => d.latDist));
  const maxLonDist = Math.max(...distances.map(d => d.lonDist));

  // Use the larger of the two distances to determine scale
  const maxDist = Math.max(maxLatDist, maxLonDist);
  
  // Calculate scale factor based on both count and spread
  const posterCount = posters.length;
  const spreadFactor = 1 - (maxDist * 5); // Adjust multiplier as needed
  const countFactor = 1 - (posterCount / POSTERS_PER_SQUARE_MILE); // Adjust based on ideal density
  
  // Combine both factors (you can adjust weights)
  const combinedFactor = (spreadFactor * 0.6) + (countFactor * 0.4);
  
  return BASE_PIXELS * combinedFactor;
};

export const gpsToCanvasCoords = (lat, lon, userLat, userLon, pixelsPerHundredFeet) => {
  // Constants for conversion
  const FEET_PER_DEGREE_LAT = 364000;
  const FEET_PER_DEGREE_LON = 288200;
  const PIXELS_PER_FOOT = pixelsPerHundredFeet / 100;

  // Convert lat/lon differences to feet
  const feetNS = (lat - userLat) * FEET_PER_DEGREE_LAT;  // ≈ -3825.64 feet
  const feetEW = (lon - userLon) * FEET_PER_DEGREE_LON;  // ≈ -3168.47 feet
  // Calculate straight-line distance in feet using Pythagorean theorem
  const distanceInFeet = Math.sqrt(feetNS * feetNS + feetEW * feetEW);

  return {
    x: feetEW * PIXELS_PER_FOOT,
    y: -feetNS * PIXELS_PER_FOOT,
    distance: distanceInFeet
  };
};

export const distributePosters = (posters, userLat, userLon, radiusGroupSize = 350) => {
  // Create a deterministic starting angle from user coordinates
  const getUserStartAngle = (radiusKey) => {
    // Combine user coordinates and radius into a single number
    const seed = (userLat * 1000 + userLon * 1000 + radiusKey) % (2 * Math.PI);
    return seed;
  };

  // Step 1: Group posters by radius bands
  const radiusGroups = {};
  posters.forEach(poster => {
    const radius = Math.max(Math.sqrt(poster.x * poster.x + poster.y * poster.y), 350);
    const radiusKey = Math.round(radius / radiusGroupSize) * radiusGroupSize;
    radiusGroups[radiusKey] = radiusGroups[radiusKey] || [];
    radiusGroups[radiusKey].push({
      ...poster,
      radius
    });
  });

  let result = [];
  Object.entries(radiusGroups)
    .sort(([a], [b]) => Number(a) - Number(b))
    .forEach(([radiusKey, group]) => {
      const postersInGroup = group.length;
      
      // Get deterministic starting angle for this radius group
      const groupStartAngle = getUserStartAngle(Number(radiusKey));
      
      group.forEach((poster, index) => {
        // Calculate evenly spaced angle starting from the deterministic angle
        const baseTheta = groupStartAngle + ((2 * Math.PI * index) / postersInGroup);
        
        // Random offset within ±5 degrees
        const randomOffset = (Math.random() - 0.5) * (Math.PI / 36);
        const finalTheta = baseTheta + randomOffset;
        
        result.push({
          ...poster,
          x_new: poster.radius * Math.cos(finalTheta),
          y_new: poster.radius * Math.sin(finalTheta)
        });
      });
    });

  return result;
};

export const getDistance = (p1, p2) => {
  // Add null checks
  if (!p1 || !p2) return 0;
  if (!p1.clientX || !p2.clientX) return 0;

  return Math.sqrt(
    Math.pow(p2.clientX - p1.clientX, 2) + Math.pow(p2.clientY - p1.clientY, 2)
  );
};

export const getMiddlePoint = (p1, p2) => {
  // Add null checks
  if (!p1 || !p2) return { x: 0, y: 0 };
  if (!p1.clientX || !p2.clientX) return { x: 0, y: 0 };

  return {
    x: (p1.clientX + p2.clientX) / 2,
    y: (p1.clientY + p2.clientY) / 2,
  };
};

export const isZipcodeInScope = (zipcode) => {
  return ZIPCODES_INSCOPE.includes(zipcode);
}

export const isCoordinateInScope = (latitude, longitude) => {
  return (LATITUDE_SOUTH_BORDER <= latitude && latitude <= LATITUDE_NORTH_BORDER) &&
    (LONGITUDE_WEST_BORDER <= longitude && longitude <= LONGITUDE_EAST_BORDER);
}