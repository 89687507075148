import { useState, useCallback } from 'react';
import ZipcodeToCoordinates from '../constants/ZipcodeToCoordinates';
import { isZipcodeInScope, isCoordinateInScope } from '../utils/utils';

export const useLocation = () => {
  const [zipcode, setZipcode] = useState(null);
  const [locationFetchMessage, setLocationFetchMessage] = useState('Requesting position...');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getCurrentLocation = useCallback(() => {
    //console.log('Starting location request...');

    return new Promise((resolve) => {
      if ("geolocation" in navigator) {
        //console.log('Geolocation available, requesting position...');
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocationFetchMessage('Position received!');
            const { latitude, longitude } = position.coords;
            //console.log(`User location: Lat: ${latitude}, Long: ${longitude}`);
            
            if (isCoordinateInScope(latitude, longitude)) {
              resolve({ latitude, longitude });
            } else {
              const message = `Sorry, we are not in your area yet! We're currently in beta for the Wicker Park, Logan Square, and Bucktown areas.`;
              setLocationFetchMessage(message);
            }
          },
          (error) => {
            console.error("Location error:", error.message);
            if (zipcode !== null) {
              const position = ZipcodeToCoordinates.find(loc => loc.zipCode === zipcode);
              resolve({ 
                latitude: position.latitude, 
                longitude: position.longitude 
              });
            }
          },
          {
            enableHighAccuracy: false,
            timeout: 100000,
            maximumAge: 1000 * 60
          }
        );
      } else {
        const error = new Error("Geolocation is not supported, waiting for zipcode...");
        console.error(error);
        if (zipcode !== null) {
          const position = ZipcodeToCoordinates.find(loc => loc.zipCode === zipcode);
          resolve({ 
            latitude: position.latitude, 
            longitude: position.longitude 
          });
        }
      }
    });
  }, [zipcode]);

  const getCurrentZipcodeLocation = useCallback(() => {
    return new Promise((resolve) => {
      if (zipcode !== null) {
        const position = ZipcodeToCoordinates.find(loc => loc.zipCode === zipcode);
        const latitude = position.latitude;
        const longitude = position.longitude;
        //console.log(`User location: Lat: ${latitude}, Long: ${longitude}`);
        resolve({ latitude, longitude });
      }
    });
  }, [zipcode]);

  const handleZipcodeSubmit = useCallback((event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const zipcodeInput = formData.get("zipcodeInput");
    const location = ZipcodeToCoordinates.find(loc => loc.zipCode === zipcodeInput);
    const zipcodeInScope = isZipcodeInScope(zipcodeInput);

    if (location && zipcodeInScope) {
      setZipcode(zipcodeInput);
      setLocationFetchMessage("Location submitted successfully!");
    } else {
      setLocationFetchMessage(`Sorry, we are not in your area yet! We're currently in beta for the Wicker Park, Logan Square, and Bucktown areas.`);
    }
  }, []);

  const getLocation = useCallback(async (isShareMode, sharedLat, sharedLong) => {
    try {
      // If in share mode and we have coordinates, use them directly
      if (isShareMode && sharedLat && sharedLong) {
        return {
          latitude: parseFloat(sharedLat),
          longitude: parseFloat(sharedLong)
        };
      }

      // Otherwise, use existing location logic
      const location = await Promise.race([
        getCurrentLocation(),
        getCurrentZipcodeLocation()
      ]);
      return location;
    } catch (err) {
      throw err;
    }
  }, [getCurrentLocation, getCurrentZipcodeLocation]);

  return {
    zipcode,
    locationFetchMessage,
    loading,
    error,
    getLocation,
    handleZipcodeSubmit,
    setLocationFetchMessage,
    setLoading,
    setError
  };
};
