import '../../App.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Stage, Layer, Group, FastLayer } from 'react-konva';
import { useLocation } from '../../hooks/useLocation.js';
import { useCanvas } from '../../hooks/useCanvas.js';
import Poster from './Poster.js';
import Background from './Background.js'
import HelpSystem from '../ui/HelpSystem.js'
import LocationFetchScreen from '../ui/LocationFetchScreen.js';
import { isMobile } from '../../utils/utils.js';
import { useGestures } from '../../hooks/useGestures.js';
import { gpsToCanvasCoords, distributePosters, calculatePixelsPerHundredFeet } from '../../utils/utils.js';
import DistanceCircles from './DistanceCircles';
import { useSearchParams } from 'react-router-dom';


const InfiniteCanvas = () => {
  const {
    zipcode,
    locationFetchMessage,
    loading,
    error,
    getLocation,
    handleZipcodeSubmit,
    setLoading,
    setError
  } = useLocation();

  const {
    stageRef,
    scaleRef,
    isAnimatingRef,
    positionRef,
    stageSize,
    position,
    setScale,
    updatePositionRef,
    syncPositionState,
    updatePositionState,
    updatePositionRefOnly,
    handleResize,
    cleanup,
  } = useCanvas();

  const {
    handleWheel,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleDragStart,
    handleDragEnd,
    handlePosterTouchStart,
    handlePosterTouchEnd,
    highestZIndex,
    lastTouchedPosterId,
    cleanup: cleanupGestures,
    handleAnimationEnd,
    handleStageClick
  } = useGestures({
    stageRef,
    scaleRef,
    positionRef,
    isAnimatingRef,
    updatePositionRef,
    updatePositionRefOnly,
    updatePositionState,
    syncPositionState,
    setScale
  });

  //Set all initial states
  const [posters, setPosters] = useState([]);
  const [showHelp, setShowHelp] = useState(false);
  const [isInitialZooming, setIsInitialZooming] = useState(true);

  //Set all initial refs
  const inertiaAnimationRef = useRef(null);
  const zoomEndTimeoutRef = useRef(null);
  const initialAnimationRef = useRef(null);
  const isFirstRenderRef = useRef(true);
  const isInitializingRef = useRef(false);
  const isDomReadyRef = useRef(false);
  const pixelsPerHundredFeet = useRef(0);
  const pixelsPerMile = useRef(0);


  const [searchParams] = useSearchParams();

  // Define isShareMode based on URL parameters
  const isShareMode = searchParams.has('lat') &&
    searchParams.has('long') &&
    searchParams.has('posterId');

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    initializeApp();
  }, [zipcode]);

  const initializeApp = async () => {
    try {
      console.log('Starting initializeApp');
      // Get URL parameters
      const urlLat = searchParams.get('lat');
      const urlLong = searchParams.get('long');
      const urlPosterId = searchParams.get('posterId');
      console.log('URL params:', { urlLat, urlLong, urlPosterId, isShareMode });

      // Pass share mode info to getLocation
      const location = await getLocation(isShareMode, urlLat, urlLong);
      console.log('Location received:', location);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/posters/${location.latitude}/${location.longitude}`);
      console.log('API response received');

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Data parsed, poster count:', data.length);

      pixelsPerHundredFeet.current = calculatePixelsPerHundredFeet(data, location.latitude, location.longitude);
      pixelsPerMile.current = Math.abs(pixelsPerHundredFeet.current * 52.80);

      const transformedPosters = data.map(poster => {
        const canvasCoords = gpsToCanvasCoords(
          poster.latitude,
          poster.longitude,
          location.latitude,
          location.longitude,
          pixelsPerHundredFeet.current
        );

        return {
          ...poster,
          x: canvasCoords.x,
          y: canvasCoords.y,
          distance: canvasCoords.distance
        };
      });

      const distributedPosters = distributePosters(transformedPosters, location.latitude, location.longitude);
      const sharedPoster = urlPosterId
        ? distributedPosters.find(poster => poster.id === urlPosterId)
        : null;

      const welcomePoster = {
        id: 'welcomeposter',
        image_url: '/data/images/welcome-poster-big.png',
        x_new: -125,
        y_new: -175,
        fastener_type: '/data/images/pins/star_pin.png',
        zIndex: 99
      };


      //when in share mode, swap the welcome poster position with the shared poster
      if (isShareMode && sharedPoster) {
        const welcomePosterOriginalX = welcomePoster.x_new;
        const welcomePosterOriginalY = welcomePoster.y_new;
        const sharedPosterOriginalX = sharedPoster.x_new;
        const sharedPosterOriginalY = sharedPoster.y_new;


        welcomePoster.x_new = sharedPosterOriginalX;
        welcomePoster.y_new = sharedPosterOriginalY;
        welcomePoster.zIndex = 0;

        sharedPoster.x_new = welcomePosterOriginalX;
        sharedPoster.y_new = welcomePosterOriginalY;
        sharedPoster.zIndex = 99;


        setPosters([
          ...(distributedPosters.filter(poster => poster.id !== urlPosterId)),
          welcomePoster,
          sharedPoster
        ]);
        
      } else {
        setPosters([
          ...distributedPosters,
          welcomePoster
        ]);

      }



      setLoading(false);
      console.log('Loading set to false');

      requestAnimationFrame(() => {
        isDomReadyRef.current = true;
        setTimeout(() => performInitialZoomAnimation(), 100);
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  // Add cleanup to component unmount
  useEffect(() => {
    return () => {
      if (zoomEndTimeoutRef.current) {
        clearTimeout(zoomEndTimeoutRef.current);
      }
      if (inertiaAnimationRef.current) {
        cancelAnimationFrame(inertiaAnimationRef.current);
      }
    };
  }, []);

  const performInitialZoomAnimation = useCallback(() => {
    if (!stageRef.current || !isFirstRenderRef.current || isInitializingRef.current || !isDomReadyRef.current) {
      return;
    }

    isInitializingRef.current = true;
    isAnimatingRef.current = true;

    const startScale = isMobile() ? .1 : .2;
    const endScale = isMobile() ? 1.5 : 2.5;
    const duration = 3000;
    const pauseDuration = 1500;

    if (initialAnimationRef.current) {
      cancelAnimationFrame(initialAnimationRef.current);
      initialAnimationRef.current = null;
    }
    setScale(startScale);
    stageRef.current.scale({ x: startScale, y: startScale });
    stageRef.current.batchDraw();

    setTimeout(() => {
      let animationStartTime = null;

      const animate = (currentTime) => {
        if (!animationStartTime) {
          animationStartTime = currentTime;
          initialAnimationRef.current = requestAnimationFrame(animate);
          return;
        }

        const elapsed = currentTime - animationStartTime;
        const progress = Math.min(elapsed / duration, 1);

        const eased = 1 - Math.pow(1 - progress, 3);
        const currentScale = startScale + (endScale - startScale) * eased;

        setScale(currentScale);
        stageRef.current.scale({ x: currentScale, y: currentScale });
        stageRef.current.batchDraw();

        if (progress < 1) {
          initialAnimationRef.current = requestAnimationFrame(animate);
        } else {
          isFirstRenderRef.current = false;
          initialAnimationRef.current = null;
          isInitializingRef.current = false;
          isAnimatingRef.current = false;
          setIsInitialZooming(false);
          handleAnimationEnd();
        }
      };

      initialAnimationRef.current = requestAnimationFrame(animate);
    }, pauseDuration);
  }, [setScale, handleAnimationEnd]);

  // Add cleanup for the animation
  useEffect(() => {
    return () => {
      if (initialAnimationRef.current) {
        cancelAnimationFrame(initialAnimationRef.current);
        initialAnimationRef.current = null;
      }
    };
  }, []);


  useEffect(() => {
    return () => {
      cleanup(); // canvas cleanup
      cleanupGestures(); // gestures cleanup
    };
  }, [cleanup, cleanupGestures]);

  if (loading) {
    return <LocationFetchScreen onZipcodeSubmit={handleZipcodeSubmit} locationFetchMessage={locationFetchMessage} />;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <>
      <HelpSystem
        isOpen={showHelp}
        onClose={() => setShowHelp(!showHelp)}
      />

      <Stage
        ref={stageRef}
        width={stageSize.width}
        height={stageSize.height}
        x={position.x}
        y={position.y}
        onWheel={handleWheel}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleStageClick}
        onTap={handleStageClick}
        draggable={!isAnimatingRef.current}
        scaleX={scaleRef.current}
        scaleY={scaleRef.current}
      >
        <Layer>
          <Group>
            <Background
              imageSrc={'/data/images/background/cork-background-tile.webp'}
              stagePos={position}
              stageSize={stageSize}
            />
          </Group>
        </Layer>
        <DistanceCircles
          pixelsPerMile={pixelsPerMile.current}
        />
        <Layer>
          {/* Regular posters */}
          {posters.map((poster) => (
            <Poster
              key={poster.id}
              id={poster.id}
              src={poster.image_url}
              x={poster.x_new}
              y={poster.y_new}
              fastenerSrc={poster.fastener_type}
              onPosterTouchStart={handlePosterTouchStart}
              onPosterTouchEnd={handlePosterTouchEnd}
              zIndex={lastTouchedPosterId === poster.id ? highestZIndex : poster.zIndex || 0}
              lastTouchedPosterId={lastTouchedPosterId}
              latitude={poster.latitude}
              longitude={poster.longitude}
            />
          ))}
        </Layer>
      </Stage>
      {isInitialZooming && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          pointerEvents: 'none',
          zIndex: 1000,
          transition: 'opacity 0.3s ease-out',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{
            color: 'white',
            fontSize: '24px',
            fontWeight: '500',
            textAlign: 'center',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif'
          }}>
            Loading your corkboard...
          </div>
        </div>
      )}
    </>
  );
};
export default InfiniteCanvas;

